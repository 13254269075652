import angular from 'angular';

import { ShipmentInfoInsuranceBreakdownComponent } from './insurance-breakdown.component';

const MODULE_NAME = 'app.manage-shipments.shipment-information.insurance-breakdown';

angular
  .module(MODULE_NAME, [])
  .component('esShipmentInfoInsuranceBreakdown', ShipmentInfoInsuranceBreakdownComponent);

export default MODULE_NAME;
