import angular from 'angular';

import { AngularChurnReasonsDialog } from './ChurnReasonsDialog';

const MODULE_NAME = 'app.data-collect-flows';

angular
  .module(MODULE_NAME, [])
  .component('esChurnReasonsDialog', AngularChurnReasonsDialog);

export default MODULE_NAME;
