import {
  ICheckoutModalService,
  IIncartInstallationModal,
  IStore,
  IStoreService,
  ITokenInstallationModal,
  StoreCheckoutDiagnostics,
  StoreDiscount,
  StorePickupFeeSetting,
  TaxesDisplayOption,
} from 'typings/store';
import { IBoxObject, IBoxService } from 'typings/boxes';
import { IApiConfig } from 'typings/core/config/api';
import { IShopifyService, IShoplineService } from 'typings/platform';
import { IItemCategoryService } from 'typings/item-category';
import { ICountryService } from 'typings/auth/services/country';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';
import { IShowChangePlanObject } from 'typings/subscription';
import {
  DefinedSubscriptionPlanId,
  FlexibleSubscriptionPlanId,
  FeatureKey,
  FEATURE_KEY,
} from '@client/data/subscription';

import { COUNTRY_ID } from '@client/data/country';
import { ValueOf } from 'type-fest';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { IUserSession } from 'typings/user-session';
import iconCheckoutUrl from '@assets/images/dashboard/connect/icon-checkout.svg';
import iconTaxUrl from '@assets/images/dashboard/connect/icon-tax.svg';
import shopifyTaxDutyImageUrl from '@assets/images/dashboard/connect/shopify-duties-and-import-taxes.png';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './checkout-card.module.scss';
import template from './checkout-card.html?raw';
import PICKUP_FEE_OPTIONS from './pickup-fee-options';

type CheckoutSettingsAction = 'install' | 'enable' | 'settings';
type DimensionKey = 'l' | 'w' | 'h' | 'unit' | 'box';

interface FormData {
  processing_days: string;
  showTaxes: boolean;
  showAmount: boolean;
  usePlatformCalculation: boolean;
  countriesForPlatformCalculation: string[];
  showDeliveryTime: boolean;
  display_courier_badges: boolean;
  display_courier_rating: boolean;
  enablePickupFees: boolean;
  includeAllPickupFees: boolean;
  pickup_fees_options: StorePickupFeeSetting[];
}

class StoreSettingsCheckoutCardController implements IComponentController {
  style = style;
  iconCheckoutUrl = iconCheckoutUrl;
  iconTaxUrl = iconTaxUrl;
  shopifyTaxDutyImageUrl = shopifyTaxDutyImageUrl;
  showingPreactivationChecklist = false;
  showingConfigForm = false;
  showingTokenInstructions = true;
  showingUninstallConfirmation = false;
  showingSeeExampleModal = false;
  showingShopifySetupModal = false;
  showingCountriesModal = false;
  busy = {
    gettingCheckoutDiagnosticData: false,
    activating: false,
    deactivating: false,
  };
  form: FormData = {
    processing_days: '',
    usePlatformCalculation: false,
    countriesForPlatformCalculation: [],
    showTaxes: false,
    showAmount: true,
    showDeliveryTime: true,
    display_courier_badges: true,
    display_courier_rating: true,
    enablePickupFees: false,
    includeAllPickupFees: false,
    pickup_fees_options: [],
  };
  selectedInCountryModal: string[] = [];
  ratesAtCheckoutStatus = 'unavailable';
  taxAndDutyAtCheckoutStatus = 'unavailable';
  action = 'activate';
  supportUrlBase?: string;
  currency = '';
  boxes: IBoxObject[] = [];
  countryAlpha2s: string[] = [];
  CheckoutDiagnosticData?: StoreCheckoutDiagnostics;
  descriptionWrappers = {
    b: (chunk: string) => `<span class="strong text-ink-900">${chunk}</span>`,
  };
  limitWrappers = {
    blue: (chunk: string) => `<span class="strong text-blue-700">${chunk}</span>`,
  };
  showModal: Partial<IShowChangePlanObject> = {
    enterpriseCall: false,
    upgrade: false,
  };
  activeStore: IStore | undefined;
  suggestedPlanId?: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  FEATURE_KEY: typeof FEATURE_KEY;
  mixPanelTriggerSource = '';

  static $inject = [
    '$state',
    '$rootScope',
    '$translate',
    '$window',
    '$timeout',
    'API',
    'StoreService',
    'ShopifyService',
    'ShoplineService',
    'ItemCategoryService',
    'BoxService',
    'CountryService',
    'UserSession',
    'OnboardingChecklistService',
    'AppCuesService',
    'MixpanelService',
    'CheckoutModalService',
    'incartInstallationModal',
    'tokenInstallationModal',
    'SubscriptionService',
    'UserRightsService',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $rootScope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private $window: ng.IWindowService,
    private $timeout: ng.ITimeoutService,
    private API: IApiConfig,
    private StoreService: IStoreService,
    private ShopifyService: IShopifyService,
    private ShoplineService: IShoplineService,
    private ItemCategoryService: IItemCategoryService,
    private BoxService: IBoxService,
    private CountryService: ICountryService,
    private UserSession: IUserSession,
    private OnboardingChecklistService: OnboardingChecklistService,
    private AppCuesService: AppCuesService,
    private MixpanelService: MixpanelService,
    private CheckoutModalService: ICheckoutModalService,
    private incartInstallationModal: IIncartInstallationModal,
    private tokenInstallationModal: ITokenInstallationModal,
    private SubscriptionService: SubscriptionService,
    private UserRightsService: UserRightsService
  ) {
    this.FEATURE_KEY = FEATURE_KEY;
  }

  $onInit() {
    this.activeStore = this.StoreService.activeStore;
    this.ratesAtCheckoutStatus = this.determineRatesAtCheckoutStatus();
    this.taxAndDutyAtCheckoutStatus = this.determineTaxAndDutyAtCheckoutStatus();
    this.action = this.determineFeatureAction();
    this.supportUrlBase = `${this.API.help}/hc/en-us/articles`;
    this.currency = this.UserSession.company.currency;
    this.BoxService.getCustomBoxesSelectOptions().then((boxes: IBoxObject[]) => {
      this.boxes = boxes;
    });
    this.countryAlpha2s = this.CountryService.countries.map(({ alpha2 }) => alpha2);

    if (this.StoreService.activeStore) {
      const {
        processing_days: processingDays,
        use_platform_import_taxes_and_duties: usePlatformImport,
        display_taxes: displayTaxes,
        display_courier_badges: displayCourierBadges,
        display_courier_rating: displayCourierRating,
        include_pickup_fees: includePickupFees,
      } = this.StoreService.activeStore;
      const pickupFeesOptions =
        PICKUP_FEE_OPTIONS[this.UserSession.company.country_id as ValueOf<typeof COUNTRY_ID>]?.map(
          (option): StorePickupFeeSetting => ({
            ...option,
            is_included: !!this.StoreService.activeStore?.include_pickup_fees?.options?.find(
              ({ umbrella_name }) => option.umbrella_name === umbrella_name
            )?.is_included,
          })
        ) || [];

      this.form = {
        processing_days: processingDays?.toString() || '0',
        usePlatformCalculation: !!usePlatformImport?.is_enabled,
        countriesForPlatformCalculation: usePlatformImport?.enabled_country_alpha2s || [],
        showTaxes:
          this.subscriptionPlanAllowsTaxesAtCheckout &&
          !!displayTaxes &&
          ['show_with_amount', 'show_without_amount'].includes(displayTaxes),
        showAmount: displayTaxes === 'show_with_amount',
        showDeliveryTime: !this.StoreService.activeStore.hide_delivery_time,
        display_courier_badges: !!displayCourierBadges,
        display_courier_rating: !!displayCourierRating,
        enablePickupFees: !!includePickupFees?.is_enabled,
        includeAllPickupFees: !!includePickupFees?.include_all,
        pickup_fees_options: pickupFeesOptions,
      };
    }

    if (!this.SubscriptionService.currentSubscription) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: this.UserSession.company.id,
      });
    }

    // Immediately after activation, display checkout features
    if (this.$window.localStorage.getItem('scroll-to-shipping-at-checkout')) {
      this.showingConfigForm = true;

      this.getCheckoutDiagnosticData();

      setTimeout(() => {
        document
          .querySelector('es-store-settings-checkout-card')
          ?.scrollIntoView({ behavior: 'smooth' });
        this.$window.localStorage.removeItem('scroll-to-shipping-at-checkout');
      }, 500);
    }
  }

  determineRatesAtCheckoutStatus(): string {
    const store = this.StoreService.activeStore;

    if (!this.platformAllowsDynamicRates) {
      return 'unavailable';
    }

    if (
      (!this.subscriptionPlanAllowsRatesAtCheckout &&
        !this.UserSession.company.dashboard_settings.beta_feature_rates_at_checkout) ||
      !this.storeHasCheckoutEnabled
    ) {
      return 'inactive';
    }

    if (store?.platform?.name === 'WooCommerce') {
      return 'generated';
    }

    return 'active';
  }

  determineTaxAndDutyAtCheckoutStatus(): string {
    return this.form?.showTaxes ? 'active' : 'inactive';
  }

  determineFeatureAction(): CheckoutSettingsAction {
    const store = this.StoreService.activeStore;
    const hasToken = store?.oauth_applications?.production?.token;
    const inputCredentials = store?.platform?.credentials_retrieval_method === 'user_input';
    const oauthCredentials = store?.platform?.credentials_retrieval_method === 'oauth';

    if (oauthCredentials && !store?.is_rates_enabled) return 'install';
    if (inputCredentials && hasToken) return 'enable';
    return 'settings';
  }

  determineFeatureMessage(): string | undefined {
    const store = this.StoreService.activeStore;
    const hasToken = store?.oauth_applications?.production?.token;
    const platformAllowsDynamicRates = store?.platform?.actions?.dynamic_rates;
    const inputCredentials = store?.platform?.credentials_retrieval_method === 'user_input';
    const oauthCredentials = store?.platform?.credentials_retrieval_method === 'oauth';

    // Incart is activated
    if (
      (this.platformAllowsDynamicRates && this.storeHasCheckoutEnabled && oauthCredentials) ||
      (inputCredentials && store?.platform?.group === 'Shopmatic') // BUG?: even if store.platform?.actions?.dynamic_rates is false?
    ) {
      return 'rates-activated';
    }

    // For woocommerce: token might be generated
    if (
      store?.oauth_applications &&
      platformAllowsDynamicRates &&
      hasToken &&
      inputCredentials &&
      store?.platform?.group !== 'Shopmatic'
    ) {
      return 'not-installed-with-token';
    }

    // Incart is not yet activated
    if (
      platformAllowsDynamicRates &&
      ((!this.storeHasCheckoutEnabled && oauthCredentials) || (!hasToken && inputCredentials))
    ) {
      return 'rates-not-installed-with-oauth';
    }

    return undefined;
  }

  activate() {
    if (!this.StoreService.activeStore) return;

    const createCarrierServiceCallback = this.platformIsShopline
      ? this.ShoplineService.createCarrierService
      : this.ShopifyService.createCarrierServiceOrUpgradeShopify;

    this.busy.activating = true;
    this.MixpanelService.track('Connect - Checkout - Activate');

    createCarrierServiceCallback(this.StoreService.activeStore)
      .then((response) => {
        this.$state.go(
          'app.single-store',
          { id: response.store.id, platform: this.platformName },
          { reload: true }
        );

        this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.InstallCheckout);
        this.AppCuesService.track('Store | Activate Checkout');
        this.$rootScope.$broadcast('store-dynamic-rates-updated');
      })
      .catch((err) => {
        const error = err.data;
        if (error) {
          this.StoreService.activeStore = error.store;

          toastError(error.msg);

          if (!error.store.is_rates_enabled && error.store.auth_state === 'verified') {
            this.incartInstallationModal.open();
          }
        }
      })
      .finally(() => {
        this.busy.activating = false;
      });
  }

  deactivate() {
    if (!this.StoreService.activeStore) return;

    this.busy.deactivating = true;
    this.MixpanelService.track('Connect - Checkout - Deactivate');

    const deleteCarrierServiceCallback = this.platformIsShopline
      ? this.ShoplineService.deleteCarrierService
      : this.ShopifyService.deleteCarrierService;

    deleteCarrierServiceCallback(this.StoreService.activeStore)
      .then((response) => {
        this.busy.deactivating = false;
        if (!this.StoreService.activeStore) return;

        this.StoreService.activeStore.is_rates_enabled = response.is_rates_enabled;

        this.$rootScope.$broadcast('store-dynamic-rates-updated');
        this.$state.go('app.single-store', { id: response.store.id }, { reload: true });
        toastSuccess(this.$translate.instant('store.notifications.rates-at-checkout-deactivated'));
      })
      .catch(() => {
        this.busy.deactivating = false;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  update(key: keyof IStore, value: string) {
    this.save({ [key]: parseFloat(value) });
  }

  toggle(key: keyof IStore, value: boolean) {
    this.save({ [key]: value });
  }

  updateCountries(value: string[]) {
    this.selectedInCountryModal = value;
  }

  updateCountriesForPlatformCalculation(value: string[]) {
    this.save({
      use_platform_import_taxes_and_duties: {
        is_enabled: this.form.usePlatformCalculation,
        enabled_country_alpha2s: value,
      },
    });
    this.form.countriesForPlatformCalculation = value;
    this.showingCountriesModal = false;
  }

  updateShowDeliveryTime(value: boolean) {
    this.save({ hide_delivery_time: !value });
  }

  getSuggestedPlanId(featureKey: FeatureKey) {
    if (
      this.SubscriptionService.isCurrentSubscriptionLoaded &&
      this.SubscriptionService.isPlansDetailLoaded
    ) {
      return this.SubscriptionService.getSuggestedPlanIdByFeatureKey(featureKey);
    }

    return undefined;
  }

  checkSubscriptionPlanAllowsTaxesAtCheckout() {
    if (this.subscriptionPlanAllowsTaxesAtCheckout) return;

    const isEnterprisePlan =
      this.SubscriptionService.currentSubscription?.plan?.id ===
      FlexibleSubscriptionPlanId.Enterprise;

    if (isEnterprisePlan) {
      this.showModal.enterpriseCall = true;
    } else {
      this.mixPanelTriggerSource = 'Display Tax & Duty at Checkout';
      this.suggestedPlanId = this.getSuggestedPlanId('tax_and_duty_at_checkout');
      this.showModal.upgrade = true;
    }
  }

  updateShowTaxes(value: boolean) {
    this.form.showTaxes = value;
    this.save({
      display_taxes: (value ? 'show_with_amount' : 'hide') as TaxesDisplayOption,
    });
  }

  updateShowAmount(value: boolean) {
    this.form.showAmount = value;
    this.save({
      display_taxes: (value ? 'show_with_amount' : 'show_without_amount') as TaxesDisplayOption,
    });
  }

  updateUsePlatformCalculation(value: boolean) {
    this.form.usePlatformCalculation = value;
    const alpha2s = this.form.countriesForPlatformCalculation.length
      ? this.form.countriesForPlatformCalculation
      : this.countryAlpha2s;
    this.save({
      use_platform_import_taxes_and_duties: {
        is_enabled: !!value,
        enabled_country_alpha2s: alpha2s,
      },
    });

    if (value) this.showingShopifySetupModal = true;
  }

  updateIncludeAllPickupFees(value: boolean) {
    this.form.includeAllPickupFees = value;
    this.form.pickup_fees_options.forEach((option) => {
      // eslint-disable-next-line no-param-reassign
      option.is_included = !!value;
    });

    this.save({
      include_pickup_fees: {
        is_enabled: !!this.form.enablePickupFees,
        include_all: !!value,
        options: this.form.pickup_fees_options,
      },
    });
  }

  updateAllPickupFees(value: boolean) {
    this.save({
      include_pickup_fees: {
        is_enabled: !!value,
        include_all: !!this.form.includeAllPickupFees,
        options: this.form.pickup_fees_options,
      },
    });
  }

  updatePickupFees(index: number, value: boolean) {
    this.form.pickup_fees_options[index].is_included = !!value;
    this.save({
      include_pickup_fees: {
        is_enabled: !!this.form.enablePickupFees,
        include_all: !!this.form.includeAllPickupFees,
        options: this.form.pickup_fees_options,
      },
    });
  }

  save(changes: Partial<IStore>) {
    if (!this.StoreService.activeStore) return;

    const { id } = this.StoreService.activeStore;
    this.StoreService.updateStore({ id, ...changes })
      .then((data) => {
        this.StoreService.activeStore = { ...this.StoreService.activeStore, ...data.store };
        toastSuccess(this.$translate.instant('store.notifications.rates-settings-update'));
        this.$rootScope.$broadcast('store-dynamic-rates-updated');
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  toggleActivate() {
    if (this.platformAllowsDynamicRatesToggle && !this.storeHasCheckoutEnabled) {
      if (this.subscriptionPlanAllowsRatesAtCheckout) {
        if (!this.showingPreactivationChecklist) {
          this.getCheckoutDiagnosticData();
          this.MixpanelService.track('Connect - Checkout - Open Checklist');
        }

        this.showingPreactivationChecklist = !this.showingPreactivationChecklist;
        return;
      }

      const isEnterprisePlan =
        this.SubscriptionService.currentSubscription?.plan?.id ===
        FlexibleSubscriptionPlanId.Enterprise;

      if (isEnterprisePlan) {
        this.showModal.enterpriseCall = true;
      } else {
        this.mixPanelTriggerSource = 'Rates at Checkout';
        this.suggestedPlanId = this.getSuggestedPlanId(this.FEATURE_KEY.RatesAtCheckout);
        this.showModal.upgrade = true;
      }
    } else if (this.isNotShopifyAndFreePlan) {
      this.mixPanelTriggerSource = 'Rates at Checkout';
      this.showModal.upgrade = true;
      this.MixpanelService.track('Shipping at Checkout - Activate CTA - Clicked', {
        platform_name: this.StoreService.activeStore?.platform?.css_class,
      });
    }
  }

  successUpgradePlanHandler() {
    this.MixpanelService.track('Shipping at Checkout - Upgrade Modal - Try Free Trial', {
      platform_name: this.StoreService.activeStore?.platform?.css_class,
    });
  }

  seeMorePlansHandler() {
    this.MixpanelService.track('Shipping at Checkout - Upgrade Modal - See Plans', {
      platform_name: this.StoreService.activeStore?.platform?.css_class,
    });
  }

  toggleConfigForm() {
    if (!this.showingConfigForm) this.getCheckoutDiagnosticData();

    this.showingConfigForm = !this.showingConfigForm;

    if (this.showingConfigForm) {
      this.AppCuesService.track('Store | Expand Checkout Settings', null, true);
    }
  }

  toggleShowTokenAccordion() {
    this.showingTokenInstructions = !this.showingTokenInstructions;
  }

  toggleUninstallConfirmation() {
    this.showingUninstallConfirmation = !this.showingUninstallConfirmation;
  }

  openModal(modalName: string, discount: StoreDiscount) {
    this.$timeout(() => {
      if (discount) {
        this.MixpanelService.track('Connect - Store - Settings - Free Shipping - Edit Rule');
      } else {
        this.MixpanelService.track('Connect - Store - Settings - Click Add Free Shipping');
      }
      this.CheckoutModalService.open(modalName, discount);
    }, 300);
  }

  openCreateTokenModal() {
    if (this.StoreService.activeStore?.platform) {
      this.MixpanelService.track('Add Dynamic Rates', {
        platform: this.StoreService.activeStore.platform.css_class,
      });
    }

    this.tokenInstallationModal.open();
  }

  toggleExampleModal() {
    this.showingSeeExampleModal = !this.showingSeeExampleModal;
  }

  toggleShopifySetupModal() {
    this.showingShopifySetupModal = !this.showingShopifySetupModal;
  }

  toggleCountriesModal() {
    this.selectedInCountryModal = this.form.countriesForPlatformCalculation;
    this.showingCountriesModal = !this.showingCountriesModal;
  }

  scrollTo(query: string) {
    document.querySelector(query)?.scrollIntoView({ behavior: 'smooth' });

    if (query === 'es-store-settings-products-card') {
      this.$rootScope.$emit('editing-fallbacks');
    }
  }

  getCheckoutDiagnosticData() {
    if (!this.StoreService.activeStore) return;

    this.busy.gettingCheckoutDiagnosticData = true;
    this.StoreService.getCheckoutDiagnosticData(this.StoreService.activeStore.id)
      .then((response) => {
        this.CheckoutDiagnosticData = response;
      })
      .catch(() => {
        toastError(this.$translate.instant('store.notifications.diagnostic-error'));
      })
      .finally(() => {
        this.busy.gettingCheckoutDiagnosticData = false;
      });
  }

  isPlanBadgeVisible(featureKey: FeatureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  get storeHasCheckoutEnabled(): boolean {
    const store = this.StoreService.activeStore;
    const hasToken = store?.oauth_applications?.production?.token;
    const oauthCredentials = store?.platform?.credentials_retrieval_method === 'oauth';
    const inputCredentials = store?.platform?.credentials_retrieval_method === 'user_input';
    return (
      this.subscriptionPlanAllowsRatesAtCheckout &&
      !!((store?.is_rates_enabled && oauthCredentials) || (hasToken && inputCredentials))
    );
  }

  get showingDescription(): boolean {
    const store = this.StoreService.activeStore;
    const allowsRates = !!store?.platform?.actions?.dynamic_rates;
    const hasToken = store?.oauth_applications?.production?.token;
    const oauthCredentials = store?.platform?.credentials_retrieval_method === 'oauth';
    const inputCredentials = store?.platform?.credentials_retrieval_method === 'user_input';
    const isShopmatic = store?.platform?.group === 'Shopmatic';

    return (
      (allowsRates && this.storeHasCheckoutEnabled && oauthCredentials) ||
      (inputCredentials && isShopmatic) ||
      (store?.oauth_applications && allowsRates && hasToken && inputCredentials && !isShopmatic) ||
      (allowsRates &&
        ((!this.storeHasCheckoutEnabled && oauthCredentials) || (!hasToken && inputCredentials)))
    );
  }

  get platformName(): string {
    return this.StoreService.activeStore?.platform?.name || 'your store';
  }

  get platformScope(): string | null {
    return this.StoreService.activeStore?.platform?.scope || null;
  }

  get platformAllowsDynamicRates(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.dynamic_rates;
  }

  get platformAllowsDynamicRatesToggle(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.dynamic_rates_toggle;
  }

  get platformAllowsOrderSync(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.get_orders;
  }

  get platformAllowsTaxDisplay(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.dynamic_rates_tax_toggle;
  }

  get platformCanCalculateTaxDuty(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions
      ?.platform_import_taxes_and_duties_toggle;
  }

  get platformAllowsHidingDeliveryTime(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.hide_delivery_time_toggle;
  }

  get platformAllowsCourierBadgesDisplay(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.dynamic_rates_badges_toggle;
  }

  get platformAllowsCourierRatingsDisplay(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.dynamic_rates_rating_toggle;
  }

  get platformIsShopmatic(): boolean {
    return this.StoreService.activeStore?.platform?.group === 'Shopmatic';
  }

  get platformIsShopify(): boolean {
    return this.StoreService.activeStore?.platform?.group === 'Shopify';
  }

  get platformIsShopline(): boolean {
    return this.StoreService.activeStore?.platform?.group === 'Shopline';
  }

  get taxAndDuties() {
    if (!this.storeHasCheckoutEnabled) {
      return { display: false, showAmount: false };
    }

    const displayTaxes = this.StoreService.activeStore?.display_taxes;
    return {
      display:
        this.subscriptionPlanAllowsTaxesAtCheckout &&
        !!displayTaxes &&
        ['show_with_amount', 'show_without_amount'].includes(displayTaxes),
      showAmount: displayTaxes === 'show_with_amount',
    };
  }

  get oauthToken(): string {
    return this.StoreService.activeStore?.oauth_applications?.production?.token || '';
  }

  get hasFixedOriginForShipping(): boolean {
    return !!(
      this.storeHasCheckoutEnabled &&
      this.UserSession.hasFixedOriginForShipping() &&
      this.StoreService.activeStore?.platform?.credentials_retrieval_method === 'oauth'
    );
  }

  get hasSavedBoxes(): boolean {
    return !!(
      this.CheckoutDiagnosticData?.active_saved_box_count ||
      this.CheckoutDiagnosticData?.active_flat_rate_box_count
    );
  }

  get hasValidProducts(): boolean {
    return !!(
      this.CheckoutDiagnosticData?.products_count &&
      !this.CheckoutDiagnosticData?.products_missing_info_count
    );
  }

  get hasShippingRules(): boolean {
    return (
      Array.isArray(this.StoreService.activeStore?.shipping_rules) &&
      !!this.StoreService.activeStore?.shipping_rules.length
    );
  }

  get hasFallbackProductValues(): boolean {
    const store = this.StoreService.activeStore;
    return !!(
      this.ItemCategoryService.itemCategories.some(
        (category) => category.id === this.StoreService.activeStore?.default_category_id
      ) &&
      store?.default_item_weight &&
      this.hasDimensionFallback
    );
  }

  get hasDimensionFallback(): boolean {
    const store = this.StoreService.activeStore;

    switch (store?.fallback_type) {
      case 'box':
        return !!this.fallbackBoxName;

      case 'product':
        return !!(
          store.default_item_length &&
          store.default_item_width &&
          store.default_item_height
        );

      default:
        return false;
    }
  }

  get defaultCategoryName(): string | undefined {
    return this.ItemCategoryService.itemCategories.find(
      (category) => category.id === this.StoreService.activeStore?.default_category_id
    )?.name;
  }

  get defaultItemWeight(): number | undefined | null {
    return this.StoreService.activeStore?.default_item_weight;
  }

  get dimensionFallbackType(): string {
    return this.StoreService.activeStore?.fallback_type || '';
  }

  get defaultItemDimensions(): {
    [key in DimensionKey]: number | string | boolean | undefined | null;
  } {
    return {
      unit: this.UserSession.company.dimensions_unit,
      l: this.StoreService.activeStore?.default_item_length,
      w: this.StoreService.activeStore?.default_item_width,
      h: this.StoreService.activeStore?.default_item_height,
      box: this.fallbackBoxName,
    };
  }

  get fallbackBoxName(): string {
    return (
      this.boxes.find(({ id }) => id === this.StoreService.activeStore?.default_package_id)?.name ||
      ''
    );
  }

  get weightUnit(): string | null {
    return this.UserSession.company.weight_unit;
  }

  get hasRestrictiveShippingRules(): boolean {
    return !!this.CheckoutDiagnosticData?.checkout_restrictive_shipping_rule_count;
  }

  get hasShippingRulesUserRole(): boolean {
    return this.UserSession.hasUserRole('shipping_rules');
  }

  get hasWooCommerceToken(): boolean {
    return !!(
      this.StoreService.activeStore?.oauth_applications?.production?.token &&
      this.StoreService.activeStore?.platform?.group === 'WooCommerceGroup'
    );
  }

  get pickupOfferedInCountry(): boolean {
    return (
      this.UserSession.isUSCompany() ||
      this.UserSession.isHKCompany() ||
      this.UserSession.isSGCompany() ||
      this.UserSession.isGBCompany()
    );
  }

  get supportUrl(): string {
    return `${this.supportUrlBase}/115003453151-Checkout`;
  }

  get platformSupportUrl(): string {
    // eslint-disable-next-line default-case
    switch (this.StoreService.activeStore?.platform?.name) {
      case 'Shopify':
        return `${this.supportUrlBase}/360019789212-Shipping-Zones-Checkout-Rates-Shopify`;
      case 'BigCommerce':
        return `${this.supportUrlBase}/360040094491-BigCommerce-Installing-Rates-at-Checkout `;
      case 'WooCommerce':
        return `${this.supportUrlBase}/360020937892-Woocommerce-Installing-Rates-at-Checkout`;
      case 'Magento':
        return `${this.supportUrlBase}/360020943472-Shipping-Zones-Checkout-Rates-Magento-2`;
    }

    return this.supportUrl;
  }

  get boxSummary(): string {
    const savedBoxCount = this.CheckoutDiagnosticData?.active_saved_box_count;
    const courierBoxCount = this.CheckoutDiagnosticData?.active_flat_rate_box_count;

    if (savedBoxCount) {
      return courierBoxCount ? 'store.api.boxes-both' : 'store.api.boxes-saved';
    }
    return courierBoxCount ? 'store.api.boxes-courier' : 'store.api.boxes-none';
  }

  get productSummary(): string {
    if (this.CheckoutDiagnosticData?.products_count === 0) return 'store.features.products-none';
    return this.CheckoutDiagnosticData?.products_missing_info_count
      ? 'store.features.products-incomplete'
      : 'store.features.products-complete';
  }

  get shippingRuleCount(): number {
    return (
      (Array.isArray(this.StoreService.activeStore?.shipping_rules) &&
        this.StoreService.activeStore?.shipping_rules.length) ||
      0
    );
  }

  get shippingDiscounts(): StoreDiscount[] {
    return this.StoreService.activeStore?.shipping_discounts || [];
  }

  get subscriptionPlanAllowsRatesAtCheckout(): boolean {
    return !!this.SubscriptionService.currentSubscription?.all_features?.rates_at_checkout
      ?.is_accessible;
  }

  get subscriptionPlanAllowsTaxesAtCheckout(): boolean {
    return !!this.SubscriptionService.currentSubscription?.all_features?.tax_and_duty_at_checkout
      ?.is_accessible;
  }

  get isFree(): boolean {
    return this.SubscriptionService.currentPlanName === 'Free';
  }

  get isNotShopifyAndFreePlan(): boolean {
    return (
      this.activeStore?.platform?.name !== 'Shopify' &&
      !this.subscriptionPlanAllowsRatesAtCheckout &&
      this.isFree
    );
  }

  get canCheckout(): boolean {
    return this.UserRightsService.canCheckout;
  }

  get isDeactivateButtonDisabled(): boolean {
    return (
      !this.platformAllowsDynamicRatesToggle || !this.UserRightsService.canDeactivateRatesAtCheckout
    );
  }

  get canEditRatesAtCheckout(): boolean {
    return this.UserRightsService.canEditRatesAtCheckout;
  }

  get isTaxesAtCheckoutDisabled(): boolean {
    return !this.subscriptionPlanAllowsTaxesAtCheckout || !this.canEditRatesAtCheckout;
  }
}

const StoreSettingsCheckoutCardComponent: ng.IComponentOptions = {
  controller: StoreSettingsCheckoutCardController,
  template,
  bindings: {
    onClickTaxExample: '<',
    onClickRateExample: '<',
  },
};

export { StoreSettingsCheckoutCardComponent };
