import { IApiConfig } from 'typings/core/config/api';

class UserRoleResource {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public resource: any;

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = this.$resource(
      `${this.API.endpoint}/companies/:company_id/users/:user_id/user_roles`,
      {
        company_id: '@_company_id',
        user_id: '@_user_id',
      },
      {
        update: {
          method: 'PATCH',
        },
      }
    );
  }
}

export { UserRoleResource };
