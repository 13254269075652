import { LazyExoticComponent } from 'react';
import { ModalContainerProps } from '@/components/ModalContainer/types';

// eslint-disable-next-line
export type ComponentDataType = any;

type ComponentTypeLazy = Pick<ModalContainerProps, 'isOpen' | 'handleClose'> & ComponentDataType;

export type ComponentType = LazyExoticComponent<(props: ComponentTypeLazy) => React.JSX.Element>;

export type ModalsType = {
  component: ComponentType;
  data?: ComponentDataType;
  guid?: string;
  close: () => void;
};

type Listener = (d: ModalsType[]) => void;

const createModalsStore = () => {
  let modals = [] as ModalsType[];
  const listeners = new Set<Listener>();
  return {
    getModals: () => modals,
    closeLastOpened: () => {
      if (!modals.length) return;
      modals = modals.slice(0, -1);
    },
    openModal: (
      component: ComponentType,
      data?: ComponentDataType
    ): { close: () => void } | null => {
      if (component === modals[modals.length - 1]?.component) return null;
      const guid = `modal-${Math.random().toString(36)}`;
      const close = () => {
        modals = modals.filter((f) => f.guid !== guid);
        listeners.forEach((notify) => notify(modals));
      };
      modals = [
        ...modals,
        {
          component,
          data,
          guid,
          close,
        },
      ];
      listeners.forEach((notify) => notify(modals));
      return {
        close,
      };
    },
    subscribe: (listener: Listener) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
};

const store = createModalsStore();

export default store;

export const openModal = (component: ComponentType, data?: ComponentDataType) =>
  store.openModal(component, data);

export const closeLastModal = () => store.closeLastOpened();
