import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        id="icon shape"
        d="M10 17.5C8.51664 17.5 7.0666 17.0601 5.83323 16.236C4.59986 15.4119 3.63856 14.2406 3.07091 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53683C2.9335 7.08197 3.64781 5.7456 4.6967 4.6967C5.7456 3.64781 7.08197 2.9335 8.53683 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.07091C14.2406 3.63856 15.4119 4.59986 16.236 5.83323C17.0601 7.0666 17.5 8.51664 17.5 10C17.4979 11.9885 16.707 13.8949 15.301 15.301C13.8949 16.707 11.9885 17.4979 10 17.5ZM8.929 8.929C8.64482 8.929 8.37228 9.04189 8.17134 9.24284C7.97039 9.44378 7.8575 9.71632 7.8575 10.0005C7.8575 10.2847 7.97039 10.5572 8.17134 10.7582C8.37228 10.9591 8.64482 11.072 8.929 11.072V13.215C8.92953 13.4988 9.04257 13.7708 9.24334 13.9714C9.4441 14.172 9.71621 14.2847 10 14.285H11.071C11.3552 14.285 11.6277 14.1721 11.8287 13.9712C12.0296 13.7702 12.1425 13.4977 12.1425 13.2135C12.1425 12.9293 12.0296 12.6568 11.8287 12.4558C11.6277 12.2549 11.3552 12.142 11.071 12.142V10C11.0707 9.71604 10.9578 9.44378 10.757 9.24298C10.5562 9.04219 10.284 8.92927 10 8.929H8.929ZM10 5.714C9.7879 5.71381 9.5805 5.77653 9.40406 5.89425C9.22761 6.01197 9.09006 6.17938 9.0088 6.3753C8.92754 6.57123 8.90622 6.78685 8.94755 6.99489C8.98888 7.20294 9.091 7.39404 9.24099 7.54402C9.39097 7.694 9.58207 7.79612 9.79011 7.83745C9.99815 7.87878 10.2138 7.85747 10.4097 7.77621C10.6056 7.69495 10.773 7.55739 10.8908 7.38095C11.0085 7.20451 11.0712 6.99711 11.071 6.785C11.0707 6.50104 10.9578 6.22878 10.757 6.02798C10.5562 5.82719 10.284 5.71427 10 5.714Z"
        fill="#919FAB"
      />
    </SvgIcon>
  );
}
