import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { BillingInterval } from '@client/data/subscription';
import { IPlan } from '../types';

interface IPlanPriceProps {
  plan: IPlan;
  currency: string;
  billingInterval: BillingInterval;
}

export default function PlanPrice({ plan, currency, billingInterval }: IPlanPriceProps) {
  const theme = useTheme();

  const billingIntervalI18nKey =
    billingInterval === BillingInterval.Year ? 'global.year' : 'global.month-abbr';

  return (
    <Box
      fontWeight={600}
      fontSize={theme.utils.fz.smaller}
      color={plan.isCurrent ? theme.palette.teal.dark : theme.palette.ink.main}
    >
      {!!plan.rate && !plan.isCustom && (
        <>
          <span className="p-x-2">
            <FormattedNumber
              value={plan.rate}
              currency={currency}
              style="currency"
              minimumFractionDigits={0}
            />
          </span>

          <Box
            component="span"
            fontSize={theme.utils.fz.smallest}
            fontWeight={400}
            color={theme.palette.ink.light}
          >
            /
            <FormattedMessage id={billingIntervalI18nKey} />
          </Box>
        </>
      )}

      {plan.isCustom && (
        <span>
          <FormattedMessage id="global.custom-price" />
        </span>
      )}
    </Box>
  );
}
