import angular from 'angular';

import addProductsModalStores from './add-products-modal-stores';
import addProductsModalActions from './add-products-modal-actions';
import addProductsModalManualInput from './add-products-modal-manual-input';
import addProductsModalImport from './add-products-modal-import';
import { AddProductsModalComponent } from './add-products-modal.component';

const MODULE_NAME = 'app.product-listing.add-products-modal';

angular
  .module(MODULE_NAME, [
    addProductsModalStores,
    addProductsModalActions,
    addProductsModalManualInput,
    addProductsModalImport,
  ])
  .component('esProductListingAddProductsModal', AddProductsModalComponent);

export default MODULE_NAME;
