import { ProductListingModal } from '@client/src/data/product-listing';
import { IProductListingLoading } from 'typings/dashboard/services/product-listing';
import { IComponentController } from 'angular';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import style from './add-products.module.scss';
import template from './add-products.html?raw';
import { ProductListingService } from '../product-listing.service';

class AddProducts implements IComponentController {
  style = style;

  static $inject = ['ProductListingService', 'AppCuesService'];
  constructor(
    private ProductListingService: ProductListingService,
    private AppCuesService: AppCuesService
  ) {}

  get loading(): IProductListingLoading {
    return this.ProductListingService.loading;
  }

  showAddProductsModal(): void {
    this.ProductListingService.activeModal = ProductListingModal.AddProducts;
    this.AppCuesService.track("Products | Selected 'Add Product'", null, true);
  }
}

const AddProductsComponent: ng.IComponentOptions = {
  controller: AddProducts,
  template,
  bindings: {},
};

export { AddProductsComponent };
