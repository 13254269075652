import { IApiConfig } from 'typings/core/config/api';
import { queryClient } from '@client/src/global/context/QueryClientProvider';
import { Dimensions, IBoxDetail, IBoxService } from 'typings/boxes';
import { IUserSession } from 'typings/user-session';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';
import { BoxCollectionData } from '@client/core/corelogic/models/BoxCollectionData';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './box-add.module.scss';
import template from './box-add.html?raw';

class BoxAddController {
  style = style;
  autoSelection = true;
  boxName = '';
  dimensions: Dimensions | undefined;
  weight: number | undefined;
  addBoxForm: HTMLFormElement | undefined;
  loading = false;
  close?: ({ box }: { box: IBoxDetail }) => void;
  error = '';

  static $inject = [
    'API',
    'BoxService',
    'UserSession',
    'MixpanelService',
    'OnboardingChecklistService',
    'AppCuesService',
    'UserRightsService',
    '$translate',
  ];
  constructor(
    public API: IApiConfig,
    private BoxService: IBoxService,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService,
    private OnboardingChecklistService: OnboardingChecklistService,
    private AppCuesService: AppCuesService,
    private UserRightsService: UserRightsService,
    private $translate: angular.translate.ITranslateService
  ) {
    this.API = API;
    this.BoxService = BoxService;
    this.UserSession = UserSession;
    this.MixpanelService = MixpanelService;
    this.OnboardingChecklistService = OnboardingChecklistService;
    this.AppCuesService = AppCuesService;
  }

  $onInit() {
    this.MixpanelService.track('Boxes - Add - Start');
  }

  onBoxNameChange(value: string) {
    this.boxName = value;
  }

  onAutoSelectionChange(value: boolean) {
    this.autoSelection = value;
  }

  onDimensionsChange(value: Dimensions) {
    this.dimensions = value;
  }

  onWeightChange(value: number) {
    this.weight = value;
  }

  onSubmit() {
    if (this.addBoxForm?.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading = true;

    const payload = {
      box: {
        name: this.boxName,
        outer_length: this.dimensions?.length,
        outer_width: this.dimensions?.width,
        outer_height: this.dimensions?.height,
        weight: this.weight,
        auto_selected: this.autoSelection,
      },
    };

    this.BoxService.createBox(payload)
      .then((data) => {
        this._updateCacheBoxData(data.box);
        this.close?.({ box: data.box });
        toastSuccess(this.$translate.instant('toast.create-success', { noun: this.boxName }));
        this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.AddBoxes);
        this.MixpanelService.track('Boxes - Add - Success');
        this.AppCuesService.track('Boxes | Box Added');
      })
      .catch((error) => {
        this.loading = false;
        this.error = error.data ? error.data.status : 'toast.default-error';
        toastError(this.$translate.instant('toast.default-error'));
        this.MixpanelService.track('Boxes - Add - Fail');
      });
  }

  get boxDimensionsUnit() {
    return this.UserSession.getCompanyDimensionsUnit();
  }

  get boxWeightUnit() {
    return this.UserSession.getCompanyWeightUnit();
  }

  get canCreateBox(): boolean {
    return this.UserRightsService.canCreateBox;
  }

  _savedBoxCollectionMapper(box: IBoxDetail) {
    return {
      id: box.id,
      name: box.name,
      weight: box.weight,
      height: box.outer_height,
      width: box.outer_width,
      length: box.outer_length,
      isFlatRate: false,
    };
  }

  _updateCacheBoxData(box: IBoxDetail) {
    const shipmentBoxesQueryData: BoxCollectionData[] | undefined = queryClient.getQueryData([
      'shipmentBoxes',
    ]);

    if (shipmentBoxesQueryData) {
      const [savedBoxCollection, ...restBoxCollections] = shipmentBoxesQueryData;

      const newSavedBox = this._savedBoxCollectionMapper(box);

      const updateSavedBoxCollection = {
        name: savedBoxCollection.name,
        boxes: [...savedBoxCollection.boxes, newSavedBox],
      };

      const updatedQueryData = [updateSavedBoxCollection, ...restBoxCollections];

      queryClient.setQueryData(['shipmentBoxes'], updatedQueryData);
    }
  }
}

const BoxAddComponent = {
  template,
  controller: BoxAddController,
  bindings: {
    close: '&',
    dismiss: '&',
  },
};

export { BoxAddComponent };
