import template from './delete-store-modal.html?raw';

(function () {
  'use strict';

  deleteStoreModal.$inject = ['$uibModal'];
  function deleteStoreModal($uibModal) {
    var service = this;

    service.open = function (params) {
      var modalInstance = $uibModal.open({
        template,
        controller: 'deleteStoreModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        openedClass: 'easyship-modal-default',
        resolve: {
          params: function () {
            return params;
          },
        },
      });
    };
  }

  angular.module('app.service.deleteStoreModal').service('deleteStoreModal', deleteStoreModal);
})();
