import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { BillingInterval } from '@client/data/subscription';
import { Typography, useTheme } from '@material-ui/core';
import { PlanRanking } from 'typings/subscription';
import Slider from '@material-ui/core/Slider';
import { useSubscriptionService } from '@client/src/global/context/SubscriptionProvider';
import { ICategorizedPlan, IPlan } from '../types';

import PlanItem from './PlanItem';

const LEGACY_PLAN_DISCOUNT = '50%';

interface TableHeaderProps {
  plans: IPlan[];
  categorizedPlan: ICategorizedPlan;
  billingInterval: BillingInterval;
  selectedScalePlan: IPlan | undefined;
  smallestRankingInScalePlans: PlanRanking;
  onPlanSelect: (planId: number) => void;
  onScaleChange: (planRanking: PlanRanking) => void;
}

export default function TableHeader({
  plans,
  categorizedPlan,
  billingInterval,
  selectedScalePlan,
  smallestRankingInScalePlans,
  onPlanSelect,
  onScaleChange,
}: TableHeaderProps) {
  const theme = useTheme();
  const { currentSubscription, isCurrentLegacyPlan } = useSubscriptionService();

  const isSubscribedToLegacyPlan = useMemo(
    () => isCurrentLegacyPlan(currentSubscription, false),
    [currentSubscription, isCurrentLegacyPlan]
  );

  const isPlanVersionOutDated = useMemo<boolean>(() => {
    const currentPlanId = currentSubscription?.plan?.id;
    const currentPlanVersion = currentSubscription?.plan?.version;
    const latestPlan = plans?.find((plan) => plan.id === currentPlanId);

    return latestPlan?.version !== currentPlanVersion;
  }, [currentSubscription?.plan?.id, currentSubscription?.plan?.version, plans]);

  const hasTaxedPlan = useMemo(() => plans.some((plan) => plan.isTaxed), [plans]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  function handleScaleChange(_: React.ChangeEvent<{}>, value: number | number[]) {
    typeof value === 'number' && onScaleChange(value);
  }

  return (
    <thead>
      <tr>
        <td className="p-b-15">
          {isSubscribedToLegacyPlan && (
            <Typography
              variant="body1"
              style={{
                color: theme.palette.teal.dark,
              }}
            >
              <FormattedMessage
                id="subscription.table.discount-applied"
                values={{ percent: LEGACY_PLAN_DISCOUNT }}
              />
            </Typography>
          )}
        </td>

        {categorizedPlan.standardPlans.map((standardPlan) => (
          <td key={standardPlan.id} className="p-b-15">
            <PlanItem
              plan={standardPlan}
              billingInterval={billingInterval}
              onPlanSelect={onPlanSelect}
              isPlanVersionOutDated={isPlanVersionOutDated}
              hasTaxedPlan={hasTaxedPlan}
            />
          </td>
        ))}

        {selectedScalePlan && (
          <td className="p-b-15">
            <PlanItem
              plan={selectedScalePlan}
              billingInterval={billingInterval}
              onPlanSelect={onPlanSelect}
              isPlanVersionOutDated={isPlanVersionOutDated}
              hasTaxedPlan={hasTaxedPlan}
            >
              <Slider
                defaultValue={smallestRankingInScalePlans}
                value={selectedScalePlan.ranking}
                aria-labelledby="discrete-slider"
                min={smallestRankingInScalePlans}
                max={smallestRankingInScalePlans + categorizedPlan.scalePlans.length - 1}
                onChange={handleScaleChange}
              />
            </PlanItem>
          </td>
        )}
      </tr>
    </thead>
  );
}
