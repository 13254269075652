import { ISubscriptionObject, IPlansDetail, IShowChangePlanObject } from 'typings/subscription';
import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './legacy-trial-end-modal.html?raw';
import style from './legacy-trial-end-modal.module.scss';

class LegacyTrialEndModal implements IComponentController {
  style = style;
  currentPlanId = 0;
  showChangePlanModal: Partial<IShowChangePlanObject> = {
    compare: false,
    upgrade: false,
    downgrade: false,
    enterpriseCall: false,
    callScheduledCard: false,
  };

  static $inject = ['SubscriptionService'];
  constructor(private SubscriptionService: SubscriptionService) {}

  closeLegacyTrialEndModal(): void {
    this.SubscriptionService.showLegacyTrialEndModal = false;
  }

  continueCurrentPlan(planId: number | null): void {
    if (planId) {
      this.currentPlanId = planId;
      this.closeLegacyTrialEndModal();
      this.showChangePlanModal.upgrade = true;
    }
  }

  changePlan(planId: number | null): void {
    if (planId) {
      this.currentPlanId = planId;
      this.closeLegacyTrialEndModal();
      this.showChangePlanModal.compare = true;
    }
  }

  get showLegacyTrialEndModal(): boolean {
    return this.SubscriptionService.showLegacyTrialEndModal;
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get plansDetail(): IPlansDetail | null {
    return this.SubscriptionService.plansDetail;
  }

  get isSubscriptionAvailable(): boolean {
    return this.SubscriptionService.isSubscriptionAvailable;
  }
}

const LegacyTrialEndModalComponent: ng.IComponentOptions = {
  controller: LegacyTrialEndModal,
  template,
  bindings: {},
};

export { LegacyTrialEndModalComponent };
