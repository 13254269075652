import { IInputGroupRadioModel } from 'typings/form';
import { IStoreService } from 'typings/store';
import { IGroupedArray, IHelperService } from 'typings/helper';
import { IPlatformService } from 'typings/platform';
import { IAdvancedSearchStorePlatformOption } from 'typings/advanced-search';

import {
  MultiselectComponentModelValue,
  MultiselectAttributes,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './store-platform-multi-select-group.module.scss';
import template from './store-platform-multi-select-group.html?raw';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class StorePlatformMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchStorePlatformOption>
  implements IComponentController
{
  style = style;
  busy = false;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchStorePlatformOption>[] = [];

  static $inject = [
    '$scope',
    '$q',
    '$translate',
    'StoreService',
    'PlatformService',
    'HelperService',
    '$timeout',
    'ShipmentListManageService',
  ];
  constructor(
    $scope: ng.IScope,
    private $q: ng.IQService,
    private $translate: angular.translate.ITranslateService,
    private StoreService: IStoreService,
    private PlatformService: IPlatformService,
    private HelperService: IHelperService,
    private $timeout: ng.ITimeoutService,
    private ShipmentListManageService: ShipmentListManageService
  ) {
    super($scope);
    this.fetchStorePlatform = this.fetchStorePlatform.bind(this);
  }

  $onInit(): void {
    const noun = this.$translate.instant('shipments.filter.store-platform.radio-title');
    const $t = (key: string) => {
      return this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    };
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];

    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    this.onChangesAssignAndTrigger(changes, MultiselectAttributes.Name, this.fetchStorePlatform);
  }

  private fetchStorePlatform(): void {
    this.busy = true;
    this.$q
      .all([this.StoreService.getStores(), this.PlatformService.getPlatformGroups()])
      .then((res) => {
        // Timeout for component to have a smooth render
        this.$timeout(() => {
          if (res) {
            const concatStore = this.ShipmentListManageService.prepareStorePlatFormData(res);
            this.esModelOptions = this.HelperService.groupArrayByKey(
              concatStore,
              MultiselectAttributes.Group,
              {
                isDisplay: true,
              }
            );
            this.watchAndTrigger(MultiselectAttributes.Name);
          }
          this.busy = false;
        }, 300);
      })
      .catch(() => {
        this.busy = false;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }
}

const StorePlatformMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: StorePlatformMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { StorePlatformMultiSelectGroupComponent };
