import angular from 'angular';
import angularInview from 'angular-inview';

import { AngularPickAndPack } from '@client/core/components/wrappers/create-shipment/PickAndPack/AngularPickAndPack';
import shipmentInformation from './shipment-information';
import { ManifestModalMainComponent } from './manifest-modal/manifest-modal-main/manifest-modal-main.component';
import { ManifestListComponent } from './manifest-modal/manifest-list/manifest-list.component';
import { ManifestListRowComponent } from './manifest-modal/manifest-list-row/manifest-list-row.component';
import { ManifestConfirmComponent } from './manifest-modal/manifest-confirm/manifest-confirm.component';
import { ManifestModal } from './manifest-modal/manifest-modal.service';
import { ManifestService } from './manifest-modal/manifest.service';
import { ManifestTrackingService } from './manifest-modal/tracking/tracking.service';
import { ShipmentInfoEditTagsDropdownComponent } from './shipment-info-edit-tags-dropdown/shipment-info-edit-tags-dropdown.component';
import { ManageShipmentsLandingComponent } from './landing/manage-shipments-landing.component';
import { AngularInsuranceClaimDialog } from './InsuranceClaimDialog';
import { AngularMarkAsDeliveredDialog } from './MarkAsDeliveredDialog';
import { AngularPrintPreviewModal } from './PrintPreviewModal/PrintPreviewModal';
import { AngularShipmentsTable } from './ShipmentsTable/ShipmentsTable';
import { AngularShipmentsTableColumnSettings } from './ShipmentsTableColumnSettings/ShipmentsTableColumnSettings';

import './return-modal';

const MODULE_NAME = 'app.manage-shipments';

angular
  .module(MODULE_NAME, [
    shipmentInformation,
    angularInview,
    'app.global.dashboard-component-library',
    'core.service.mixpanel',
    'app.service.ShipmentList',
    'app.service.WalkthroughService',
    'app.manifest',
    'app.returns-modal',
    'app.components',
    'core.components',
  ])
  .component('manifestModalMain', ManifestModalMainComponent)
  .component('manifestList', ManifestListComponent)
  .component('manifestListRow', ManifestListRowComponent)
  .component('manifestConfirm', ManifestConfirmComponent)
  .component('esShipmentInfoEditTagsDropdown', ShipmentInfoEditTagsDropdownComponent)
  .component('esManageShipmentsLanding', ManageShipmentsLandingComponent)
  .component('esInsuranceClaimDialog', AngularInsuranceClaimDialog)
  .component('esMarkAsDeliveredDialog', AngularMarkAsDeliveredDialog)
  .component('esPrintPreviewModal', AngularPrintPreviewModal)
  .component('esShipmentsTable', AngularShipmentsTable)
  .component('esShipmentsTableColumnSettings', AngularShipmentsTableColumnSettings)
  .component('esPickAndPack', AngularPickAndPack)
  .service('ManifestModal', ManifestModal)
  .service('ManifestService', ManifestService)
  .service('ManifestTrackingService', ManifestTrackingService);

export default MODULE_NAME;
