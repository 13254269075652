import angular from 'angular';

import { AddressyInputForm } from './addressy-input-form-component';

const MODULE_NAME = 'app.global.addressy-input-form';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', 'app.service.AddressyService'])
  .component('esAddressyInputForm', AddressyInputForm);

export default MODULE_NAME;
