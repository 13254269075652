import React from 'react';
import { IShipmentListItem } from 'typings/shipment';
import { Row } from '@tanstack/react-table';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Chip } from 'easyship-components';
import CellLayout from './CellLayout';

export function TaxDutyCell({ row }: { row: Row<IShipmentListItem> }) {
  const shipment = row.original || {};

  const UserSession = useUserSession();
  const { currency: userCurrency } = UserSession.company;

  const currency = shipment.currency || userCurrency;
  const incoterms = shipment.effective_incoterms;
  const isDomestic = shipment.origin_country?.alpha2 === shipment.destination_country?.alpha2;

  const isDDP = shipment?.effective_incoterms === 'DDP';
  const totalImportTaxes = isDDP
    ? shipment.import_tax_charge + shipment.import_duty_charge + shipment.ddp_handling_fee
    : shipment.estimated_import_tax + shipment.estimated_import_duty;

  return (
    <CellLayout className="gap-1 flex-col items-start justify-center p-[8px]">
      {totalImportTaxes > 0 && (
        <Chip color={!isDDP ? 'ink' : 'green'}>
          <FormattedNumber value={totalImportTaxes} style="currency" currency={currency} />
        </Chip>
      )}
      <div className="text-ink-900 italic font-normal text-[13px]">
        {totalImportTaxes ? (
          <FormattedMessage id={`shipments.list.incoterms.${incoterms.toLowerCase()}`} />
        ) : (
          <FormattedMessage id={isDomestic ? 'global.not-applicable' : 'global.not-required'} />
        )}
      </div>
    </CellLayout>
  );
}
