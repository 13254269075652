(function () {
  'use strict';

  DestinationAddresses.$inject = ['$resource', 'API'];
  function DestinationAddresses($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/destination_addresses?q=:search_term&country_alpha2=:country_alpha2',
      {
        search_term: '@_search_term',
        country_alpha2: '@_country_alpha2',
        category: '@_category',
      },
      {
        query: {
          method: 'GET',
        },
      }
    );
  }

  angular
    .module('app.factory.DestinationAddresses', [])
    .factory('DestinationAddresses', DestinationAddresses);
})();
