import template from './thermal-invoice-modal.html?raw';

class ThermalInvoiceModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open() {
    return this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
    });
  }
}

export { ThermalInvoiceModal };
