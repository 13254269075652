import { toastError } from '@client/core/components/react/Toastify';

(function () {
  addressFormCaCtrl.$inject = ['PostalCodeService', '$translate'];
  function addressFormCaCtrl(PostalCodeService, $translate) {
    const ctrl = this;

    ctrl.postalCodeBusy = false;

    ctrl.retrieveState = function () {
      const isPostalCodeFormatValid = ctrl.fieldValidators.postal_code.validate(
        ctrl.address.postal_code
      );
      if (!isPostalCodeFormatValid) {
        ctrl.address.state = null;
        return;
      }

      const sanitizedPostalCode = ctrl.address.postal_code.replace(/\s/g, '');

      ctrl.postalCodeBusy = true;

      const params = {
        postal_code: sanitizedPostalCode,
        country_id: ctrl.address.country_id,
      };

      PostalCodeService.getState(params)
        .then(function (res) {
          ctrl.address.state = res.state.name;
        })
        .catch(function () {
          ctrl.address.state = null;
          toastError($translate.instant('account.addresses.invalid-postal-code'));
        })
        .finally(function () {
          ctrl.postalCodeBusy = false;
        });
    };
  }

  angular
    .module('app.component.address-form-ca')
    .controller('addressFormCaCtrl', addressFormCaCtrl);
})();
