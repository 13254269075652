import { IUserSession } from 'typings/user-session';
import { PickupActions, IPickupsPickups, IPickupsAction } from 'typings/pickup';
import { ICheckoutService } from 'typings/checkout';

import { pickupTableColumnStyle } from '@client/src/data/pickups';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './item-list.html?raw';
import style from './item-list.module.scss';
import { PickupsPageService } from '../pickups-page.service';

class ItemList implements IComponentController {
  style = style;
  pickupTableColumnStyle = pickupTableColumnStyle;
  busy: {
    [key: string]: boolean;
  } = {};

  static $inject = [
    '$state',
    '$translate',
    'UserSession',
    'PickupsPageService',
    'CheckoutService',
    'MixpanelService',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private PickupsPageService: PickupsPageService,
    private CheckoutService: ICheckoutService,
    private MixpanelService: MixpanelService
  ) {}

  get pickups(): IPickupsPickups[] {
    return this.PickupsPageService.pickups;
  }

  get companyWeightUnit(): string {
    return this.UserSession.getCompanyWeightUnit();
  }

  get isBusyPerformingAction(): boolean {
    return !!Object.keys(this.busy).length;
  }

  esShowEditModal(value: any): void {
    // esShowEditModal expression bindings, need to add this in order for typescript to successfully compile
  }

  esShowContactSupportModal(value: any): void {
    // esShowContactSupportModal expression bindings, need to add this in order for typescript to successfully compile
  }

  esShowCancelModal(value: any): void {
    // esShowCancelModal expression bindings, need to add this in order for typescript to successfully compile
  }

  getActionIcon(action: PickupActions): string {
    let actionIconClass = '';

    switch (action) {
      case 'mark_as_resolved':
        actionIconClass = 'icon-circle-tick';
        break;
      case 'edit_pickup':
        actionIconClass = 'icon-pencil';
        break;
      case 'cancel':
        actionIconClass = 'icon-circle-cross';
        break;
      case 'ask_for_support':
        actionIconClass = 'icon-phone';
        break;
      case 'reschedule':
        actionIconClass = `icon-delivery ${this.style.iconPickup}`;
        break;
      // no default
    }

    return actionIconClass;
  }

  onActionClick(action: IPickupsAction, pickup: IPickupsPickups): void {
    if (!action.active || this.isBusyPerformingAction) return;

    let actionRequest;
    const busyKey = `${pickup.pickup_id}_${action.name}`;

    this.busy[busyKey] = true;

    switch (action.name) {
      case 'mark_as_resolved':
        actionRequest = this.markAsResolved(pickup.pickup_id);
        break;
      case 'edit_pickup':
        actionRequest = this.editPickupInfo(pickup.pickup_id);
        break;
      case 'cancel':
        this.cancel(pickup.pickup_id);
        delete this.busy[busyKey];
        break;
      case 'ask_for_support':
        actionRequest = this.askForSupport(pickup.pickup_id);
        break;
      case 'reschedule':
        actionRequest = this.reschedule(pickup.pickup_id);
        break;
      // no default
    }

    this.MixpanelService.track(
      `Pickups - Action - ${this.$translate.instant(`pickups.action.${action.name}`)}`,
      { preferred_timeslot: pickup.preferred_timeslot }
    );

    if (actionRequest) {
      actionRequest.finally(() => {
        delete this.busy[busyKey];
      });
    }
  }

  isReported(state: string) {
    return state.includes('_reported');
  }

  private markAsResolved(pickupId: string): ng.IPromise<void> {
    return this.PickupsPageService.markAsResolved(pickupId)
      .then((response) => {
        toastSuccess(this.$translate.instant('pickups.notification.resolved'));

        if (!this.pickups.length) return;

        const pickup = this.pickups.find((p) => p.pickup_id === pickupId);

        if (pickup) {
          pickup.display_handover_state = response.pickup.display_handover_state;
          pickup.display_request_state = response.pickup.display_request_state;
          pickup.actions = response.pickup.actions;
        }

        this.PickupsPageService.updateTotals();
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private cancel(pickupId: string): void {
    this.esShowCancelModal({ pickupId });
  }

  private askForSupport(pickupId: string): ng.IPromise<void> {
    return this.PickupsPageService.getSupportInfo(pickupId)
      .then(({ pickup }) => {
        this.esShowContactSupportModal({ pickup });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private reschedule(pickupId: string): ng.IPromise<void> {
    return this.CheckoutService.prepareReschedulePickup(pickupId)
      .then((data) => {
        this.CheckoutService.initCheckout(data, { isReschedulePickup: true });

        this.$state.go('app.reschedule-pickup.handover', {
          comingFromPickupsPage: true,
        });
      })
      .catch(({ data }) => {
        if (data.message) {
          toastError(this.$translate.instant(data.message));
        }

        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private editPickupInfo(pickupId: string): ng.IPromise<void> {
    return this.PickupsPageService.editPickupInfo(pickupId)
      .then(({ pickup }) => {
        this.esShowEditModal({ pickup });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }
}

const ItemListComponent: ng.IComponentOptions = {
  controller: ItemList,
  template,
  bindings: {
    esShowEditModal: '&',
    esShowCancelModal: '&',
    esShowContactSupportModal: '&',
  },
};

export { ItemListComponent };
