import React from 'react';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import FormLabel, { FormLabelProps } from '@client/core/components/react/FormLabel';
import COLORS from '@client/src/colors';

interface SwitchProps extends MuiSwitchProps, Pick<FormLabelProps, 'labelPlacement'> {
  label?: React.ReactNode;
  yesLabel?: React.ReactNode;
  noLabel?: React.ReactNode;
  helperIcon?: React.ReactElement;
}

function Switch(
  { checked, label, labelPlacement, noLabel, yesLabel, helperIcon, ...props }: SwitchProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const noStyle: React.CSSProperties = {};
  const yesStyle: React.CSSProperties = {};
  const labelStyle: React.CSSProperties = {};

  if (checked) {
    noStyle.color = COLORS.inkLight;
  } else {
    yesStyle.color = COLORS.inkLight;
  }

  if (props.disabled) {
    labelStyle.color = COLORS.inkLight;
    yesStyle.color = COLORS.inkLight;
    noStyle.color = COLORS.inkLight;
  }

  return (
    <FormLabel
      style={labelStyle}
      label={label}
      labelPlacement={labelPlacement}
      helperIcon={helperIcon}
    >
      <div
        className={`text-base m-0 h-[40px] inline-flex items-center gap-2 ${
          props.disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
      >
        <span style={noStyle}>{noLabel || 'No'}</span>
        <MuiSwitch ref={ref} checked={checked} color="primary" {...props} />
        <span style={yesStyle}>{yesLabel || 'Yes'}</span>
      </div>
    </FormLabel>
  );
}

export default React.forwardRef(Switch);
