import { Skeleton } from 'easyship-components';
import React from 'react';

export default function ShipmentCellSkeleton() {
  return (
    <div className="flex flex-col gap-1 w-full">
      <Skeleton width="85%" rounded />
      <Skeleton width="50%" rounded />
    </div>
  );
}
