import { ICheckoutService } from 'typings/checkout';
import { IHelperService } from 'typings/helper';
import { ICreditCardData } from 'typings/payment-source';
import {
  IChangePlanParams,
  IChangePlanRequest,
  IChangePlanResponse,
  IDowngradePlanParams,
  IDowngradePlanRequest,
  IDowngradePlanResponse,
  IFeature,
  IPlansDetail,
  IPlansDetailParams,
  IApiPrice,
  ISubscriptionObject,
  ISubscriptionParams,
  IPlan,
  PlanPricing,
  SubscriptionPeriod,
  ISubscriptionFeature,
  IShowChangePlanObject,
  PlanRanking,
} from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import { IUserStatusService } from 'typings/user-status';
import { ICourierAccountObject } from 'typings/courier';
import {
  BillingInterval,
  SubscriptionStates,
  DefinedSubscriptionPlanId,
  FlexibleSubscriptionPlanId,
  SubscriptionPlanIds,
  FEATURE_KEY,
  FeatureKey,
  PlanSlug,
  BillingFrequency,
} from '@client/data/subscription';

import { zendeskChatInit } from '@client/src/initializers/auth-full-login-success/zendesk-chat/zendesk-chat';
import dayjs from '@client/core/plugins/dayjs';
import { formatDate, getDateDifferenceByDays, parseUTC } from '@client/src/utils/date';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError } from '@client/core/components/react/Toastify';
import FeatureList from './FeatureList';
import { SubscriptionResource } from './subscription.resource';
import { PaymentSourceService } from '../payment-source/payment-source.service';

const LEGACY_CODE = '50_OFF_LEGACY';
const WELCOME_LEGACY_MODAL_KEY = 'welcomeLegacyModal';
const WELCOME_ENTERPRISE_MODAL_KEY = 'welcomeEnterpriseModal';
const SHIPMENT_DONE_MODAL_KEY = 'shipmentDoneModal';

export enum Units {
  Month = 'monthly',
  Year = 'yearly',
  Day = 'day',
  Days = 'days',
}

class SubscriptionService {
  readonly legacyDiscountRate = 0.5;
  private _topUpNeeded = false;
  private _plansDetail: IPlansDetail | null = null;
  private _currentSubscription: ISubscriptionObject | null = null;
  private _currentSubscriptionPromise: ng.IPromise<ISubscriptionObject> | null = null;
  private _subscriptionStates = SubscriptionStates;
  private _showPaymentFailedModal = false;
  public showChangePlanIntegrationModals: Partial<IShowChangePlanObject> = {
    upgrade: false,
    compare: false,
  };
  private _showTrialEndModal = false;
  private _showWelcomeLegacyModal = false;
  private _showWelcomeEnterpriseModal = false;
  private _showLegacyTrialEndModal = false;
  public suggestedPlanId: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId | undefined;
  public triggerSource: string | undefined;
  private readonly whiteListCompanies: string[] = [
    'CHK211241',
    'CHK206790',
    'CHK206209',
    'CHK204977',
    'CHK204488',
    'CHK198492',
    'CHK197452',
    'CHK195118',
    'CHK194027',
    'CHK194003',
    'CHK189531',
    'CHK189525',
    'CHK189316',
    'CHK179572',
    'CHK179284',
    'CHK173297',
    'CHK172589',
    'CHK172007',
    'CHK171653',
    'CHK170715',
    'CHK164373',
    'CHK157058',
    'CHK152574',
    'CHK138729',
    'CHK136922',
    'CHK131747',
    'CHK129719',
    'CHK129189',
    'CHK128406',
    'CHK128276',
    'CHK122899',
    'CHK109932',
    'CHK104966',
    'CHK18824',
    'CHK08659',
    'CHK08064',
    'CHK169631',
    'CHK06052',
    'CHK105575',
    'CHK02138',
    'CHK01045',
    'CHK110834',
    'CHK106367',
    'CHK03087',
    'CHK04879',
    'CHK108255',
  ];

  static $inject = [
    '$timeout',
    '$window',
    '$translate',
    'UserSession',
    'HelperService',
    'CheckoutService',
    'UserStatusService',
    'PaymentSourceService',
    'SubscriptionResource',
    'MixpanelService',
    'StripeService',
  ];
  constructor(
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private HelperService: IHelperService,
    private CheckoutService: ICheckoutService,
    private UserStatusService: IUserStatusService,
    private PaymentSourceService: PaymentSourceService,
    private SubscriptionResource: SubscriptionResource,
    private MixpanelService: MixpanelService,
    private StripeService: any
  ) {
    this.isPlanBadgeVisible = this.isPlanBadgeVisible.bind(this);
    this.getPlanNameByFeatureKey = this.getPlanNameByFeatureKey.bind(this);
    this.enableZendeskChat = this.enableZendeskChat.bind(this);
    this.handleZendeskOnload = this.handleZendeskOnload.bind(this);
    this.isFeatureAccessible = this.isFeatureAccessible.bind(this);
  }

  get isCurrentSubscriptionLoaded(): boolean {
    return !!this.currentSubscription;
  }

  get isPlansDetailLoaded(): boolean {
    return !!this.plansDetail;
  }

  get planIds(): typeof SubscriptionPlanIds {
    return SubscriptionPlanIds;
  }

  get subscriptionStates(): typeof SubscriptionStates {
    return this._subscriptionStates;
  }

  get plansDetail(): IPlansDetail | null {
    return this._plansDetail;
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this._currentSubscription;
  }

  get shipmentsPerMonth(): ISubscriptionFeature | null {
    if (this._currentSubscription) {
      return this._currentSubscription.all_features.shipments_per_month ?? null;
    }

    return null;
  }

  get isMaxShipments(): boolean {
    return this.isFeatureMaxLimitReached(FEATURE_KEY.ShipmentsPerMonth);
  }

  get isMaxCourier(): boolean {
    return this.isFeatureMaxLimitReached(FEATURE_KEY.Lyoc);
  }

  get isMaxMembers(): boolean {
    return this.isFeatureMaxLimitReached(FEATURE_KEY.TeamMembers);
  }

  get isChatSupportAvailable(): boolean {
    const hasCompanyLiveChatSettings = this.UserSession.company?.show_live_chat;
    const hasChatSupport = this.isFeatureAccessible(FEATURE_KEY.ChatSupport);

    return hasCompanyLiveChatSettings || hasChatSupport;
  }

  get canUsePayOnScanFeatures(): boolean {
    return !!(
      this.UserSession.getCompanyDashboardSettings().beta_feature_automated_return_shipments ||
      (this.UserSession.hasReturnFeatureSupport() &&
        this._currentSubscription?.all_features.automated_return)
    );
  }

  get isFreePlan(): boolean {
    if (
      this.currentSubscription &&
      this.currentSubscription.plan &&
      this.currentSubscription.plan.id === DefinedSubscriptionPlanId.Free
    ) {
      return true;
    }

    return false;
  }

  get isPlusPlan(): boolean {
    if (
      this.currentSubscription &&
      this.currentSubscription.plan &&
      this.currentSubscription.plan.id === DefinedSubscriptionPlanId.Plus
    ) {
      return true;
    }

    return false;
  }

  get isPremierPlan(): boolean {
    if (
      this._currentSubscription &&
      this._currentSubscription.plan &&
      this._currentSubscription.plan.id === DefinedSubscriptionPlanId.Premier
    ) {
      return true;
    }

    return false;
  }

  get isEnterprisePlan(): boolean {
    if (this._currentSubscription?.plan?.id === FlexibleSubscriptionPlanId.Enterprise) {
      return true;
    }

    return false;
  }

  get freeTrialAmount(): number {
    if (this._currentSubscription && this._currentSubscription.trial_end_at) {
      const trialEndAtDate = parseUTC(this._currentSubscription.trial_end_at);
      return getDateDifferenceByDays(trialEndAtDate, new Date());
    }

    return 0;
  }

  get remainingDaysBeforeTrialEnd(): string {
    return `${this.freeTrialAmount} ${this.freeTrialAmount > 1 ? Units.Days : Units.Day}`;
  }

  get showPaymentFailedModal(): boolean {
    return this._showPaymentFailedModal;
  }

  set showPaymentFailedModal(state: boolean) {
    this._showPaymentFailedModal = state;
  }

  get isLegacyTrialEnd(): boolean {
    const easyshipCompanyId = this.UserSession.getEasyshipCompanyId();

    if (
      // Have subscription object
      this.currentSubscription &&
      // Has a legacy voucher
      this.isCurrentLegacyPlan(this.currentSubscription, false) &&
      // Not a Free plan
      !this.isFreePlan &&
      // Trial period has ended
      this.currentSubscriptionPeriod === 'END_OF_TRIAL' &&
      // Subscription state is failed
      this.currentSubscription.subscription_state === SubscriptionStates.Failed &&
      // Not whitelisted companies
      easyshipCompanyId &&
      !this.isWhiteListCompanies(easyshipCompanyId)
    ) {
      return true;
    }

    return false;
  }

  get showTrialEndModal(): boolean {
    return this._showTrialEndModal;
  }

  set showTrialEndModal(state: boolean) {
    this._showTrialEndModal = state;
  }

  get showWelcomeLegacyModal(): boolean {
    return this._showWelcomeLegacyModal;
  }

  set showWelcomeLegacyModal(state: boolean) {
    this._showWelcomeLegacyModal = state;
  }

  get showWelcomeEnterpriseModal(): boolean {
    return this._showWelcomeEnterpriseModal;
  }

  set showWelcomeEnterpriseModal(state: boolean) {
    this._showWelcomeEnterpriseModal = state;
  }

  get showLegacyTrialEndModal(): boolean {
    return this._showLegacyTrialEndModal;
  }

  set showLegacyTrialEndModal(state: boolean) {
    this._showLegacyTrialEndModal = state;
  }

  get isSubscriptionAvailable(): boolean {
    return !!(
      this.UserSession?.user?.shipping_country?.features?.subscription?.enabled &&
      this._currentSubscription?.id &&
      this._currentSubscription?.plan?.id !== FlexibleSubscriptionPlanId.Gold
    );
  }

  get isAuthorizeShipmentOverdraft(): boolean {
    if (
      this._currentSubscription &&
      this._currentSubscription.plan &&
      this._currentSubscription.plan.authorize_shipment_overdraft
    ) {
      return true;
    }

    return false;
  }

  get currentSubscriptionPeriod(): SubscriptionPeriod | undefined {
    if (!this._currentSubscription) {
      return undefined;
    }

    if (this.freeTrialAmount > 0) {
      return 'TRIAL';
    }
    if (
      this._currentSubscription.trial_end_at &&
      this._currentSubscription.subscription_state === SubscriptionStates.Failed &&
      !this._currentSubscription.payment_source_id
    ) {
      return 'END_OF_TRIAL';
    }
    return 'SUBSCRIBED';
  }

  isWhiteListCompanies(companyId: string): boolean {
    return this.whiteListCompanies.includes(companyId);
  }

  suggestedPlanForMoreShipments(
    totalShipments = 0
  ): DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId {
    const getNextRankingPlanId = this.getNextRankingPlanId();
    const shipmentsPerMonth = this.findFeatureDetailByKey(FEATURE_KEY.ShipmentsPerMonth);
    if (!this.isPlansDetailLoaded || !this.isCurrentSubscriptionLoaded || !shipmentsPerMonth) {
      return getNextRankingPlanId;
    }
    if (this.currentSubscription?.plan?.id === FlexibleSubscriptionPlanId.Enterprise) {
      return FlexibleSubscriptionPlanId.Enterprise;
    }

    const currentShipmentCount =
      (this._currentSubscription?.all_features?.shipments_per_month?.current_count ?? 0) +
      totalShipments;
    const [suggestedPlanRanking] =
      Object.entries(shipmentsPerMonth.data).find(
        ([ranking, limit]) => typeof limit === 'number' && limit > currentShipmentCount
      ) ?? [];
    const suggestedPlan = suggestedPlanRanking
      ? this.findPlanByPlanRanking(Number(suggestedPlanRanking))
      : null;

    return suggestedPlan?.id ?? getNextRankingPlanId;
  }

  isFeatureAccessible(featureKey: FeatureKey): boolean {
    return this.currentSubscription?.all_features?.[featureKey]?.is_accessible ?? false;
  }

  findFeatureDetailByKey(featureKey: FeatureKey): IFeature | null {
    const feature = this.plansDetail?.features?.find((feature) => feature.key === featureKey);

    if (this.isPlansDetailLoaded && !feature) {
      throw new Error(
        `[SubscriptionService] findFeatureDetailByKey: can not find the feature with key ${featureKey}`
      );
    }

    return feature ?? null;
  }

  findPlanByPlanSlug(planSlug: PlanSlug): IPlan | null {
    const plan = this.plansDetail?.plans?.find((plan) => plan.slug === planSlug);

    if (this.isPlansDetailLoaded && !plan) {
      throw new Error(
        `[SubscriptionService] findPlanByPlanSlug: can not find the plan with slug ${planSlug}`
      );
    }

    return plan ?? null;
  }

  findPlanByPlanRanking(planRanking: PlanRanking): IPlan | null {
    const plan = this.plansDetail?.plans?.find((plan) => plan.ranking === planRanking);

    if (this.isPlansDetailLoaded && !plan) {
      throw new Error(
        `[SubscriptionService] findPlanByPlanRanking: can not find the plan with rank ${planRanking}`
      );
    }

    return plan ?? null;
  }

  findPlanByPlanId(planId: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId): IPlan | null {
    const plan = this.plansDetail?.plans?.find((plan) => plan.id === planId);

    if (this.isPlansDetailLoaded && !plan) {
      throw new Error(
        `[SubscriptionService] findPlanByPlanId: can not find the plan with id ${planId}`
      );
    }

    return plan ?? null;
  }

  isFeatureMaxLimitReached(featureKey: FeatureKey): boolean {
    const subscriptionFeature = this._currentSubscription?.all_features[featureKey];
    if (!subscriptionFeature) return false;

    const { current_count: currentCount, limit } = subscriptionFeature;

    if (limit === undefined || currentCount === undefined || limit === -1) return false;

    return currentCount >= limit;
  }

  // Start Api Request Method

  fetchPlansDetail(params: IPlansDetailParams): ng.IPromise<IPlansDetail> {
    return this.SubscriptionResource.plansDetail(params)
      .then((data: IPlansDetail) => {
        if (data) {
          const features = new FeatureList(data.features);
          // eslint-disable-next-line no-param-reassign
          data.features = features.getForUserCountry();
          this._plansDetail = data;
        }
        return data;
      })
      .catch((e) => {
        toastError(e);
        throw e;
      });
  }

  checkCurrentLegacyTrialEndState(): void {
    if (this.isLegacyTrialEnd) {
      this.updateLegacyTrialEndModalState();
    } else {
      this.checkCurrentPaymentFailState();
    }
  }

  checkCurrentPaymentFailState(): void {
    const companyId = this.UserSession.getCompanyId();

    if (!this._currentSubscription && this.UserSession.company && companyId) {
      this.fetchCurrentSubscription({
        company_id: companyId,
      });
    } else if (this._currentSubscription) {
      this.updatePaymentFailedModalState(this._currentSubscription);
    }
  }

  fetchCurrentSubscription(
    params: ISubscriptionParams,
    triggerPaymentFailed = true
  ): ng.IPromise<ISubscriptionObject> {
    if (this._currentSubscription) {
      return Promise.resolve(this._currentSubscription);
    }
    if (this._currentSubscriptionPromise) {
      return this._currentSubscriptionPromise;
    }

    this._currentSubscriptionPromise = this.SubscriptionResource.currentSubscription(params);

    this._currentSubscriptionPromise
      .then((data: ISubscriptionObject) => {
        if (data) {
          this._currentSubscription = data;

          if (triggerPaymentFailed && !this.showLegacyTrialEndModal) {
            this.updatePaymentFailedModalState(data);
          }
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this._currentSubscriptionPromise = null;
      });
    return this._currentSubscriptionPromise;
  }

  postChangePlan(
    params: IChangePlanParams,
    payload: IChangePlanRequest
  ): ng.IPromise<IChangePlanResponse> {
    return this.SubscriptionResource.changePlan(params, payload)
      .then((data: IChangePlanResponse) => {
        const currentPlanId = this.currentSubscription?.plan?.id;
        if (
          localStorage.getItem(WELCOME_ENTERPRISE_MODAL_KEY) &&
          // Also check if plan changed so we don't show the users the Enterprise modal again
          currentPlanId !== data.subscription.plan?.id
        ) {
          localStorage.removeItem(WELCOME_ENTERPRISE_MODAL_KEY);
        }

        if (localStorage.getItem(WELCOME_LEGACY_MODAL_KEY)) {
          localStorage.removeItem(WELCOME_LEGACY_MODAL_KEY);
        }

        return data;
      })
      .catch((e) => {
        throw e;
      });
  }

  async actionChangePlan(
    paymentSourceId: string | null,
    companyId: string,
    selectedPlanId: DefinedSubscriptionPlanId,
    unit: BillingFrequency
  ): Promise<void> {
    try {
      const { subscription } = await this.postChangePlan(
        {
          company_id: companyId,
        },
        {
          plan_id: selectedPlanId,
          billing_frequency: unit,
          payment_source_id: paymentSourceId,
        }
      );

      if (subscription) {
        const { payment_intent: paymentIntent, id, plan } = subscription;

        // the plan upgrade was successful
        const currentPlanId = plan?.id;
        this.MixpanelService.track('Subscription - Payment - Confirmed', {
          upgrade: currentPlanId
            ? currentPlanId < selectedPlanId
            : 'unknown, missing current plan id',
        });

        // Does the select subscription payment requires 3DS?
        if (paymentIntent && id) {
          const stripe = await this.StripeService.getInstance();

          // https://stripe.com/docs/js/payment_intents/confirm_card_payment
          // When called, it will confirm the PaymentIntent with data you provide
          // and carry out 3DS or other next actions if they are required.
          const result = await stripe.confirmCardPayment(paymentIntent.client_secret);

          if (result.error) {
            throw { data: { error_code: '3ds_incomplete' } } as any;
          }

          // Tell our API Stripe's 3DS was successful
          await this.SubscriptionResource.complete3DS({ company_id: companyId });
        }
      }
    } catch (error) {
      await this.fetchCurrentSubscription({ company_id: companyId });

      if (error instanceof Error) {
        toastError(error.message);
      }

      if (transformToErrorCodes(error).includes('access_denied')) {
        toastError(this.$translate.instant('subscription.update-billing.errors.access_denied'));
      }

      throw error;
    }
  }

  getDowngradePlan(
    params: IDowngradePlanParams,
    payload: IDowngradePlanRequest
  ): ng.IPromise<IDowngradePlanResponse> {
    return this.SubscriptionResource.downgradePlan(params, payload)
      .then((data: IDowngradePlanResponse) => {
        return data;
      })
      .catch((e) => {
        throw e;
      });
  }

  hasInsufficientFunds(): boolean {
    const hasOverdraft = this.UserSession.company.is_authorized_overdraft;
    const displayableTotalToTopUp = this.HelperService.doesKeyValueExists(
      this.CheckoutService.data,
      'displayableTotalToTopUp'
    );

    if (this.UserStatusService.availableBalance && displayableTotalToTopUp) {
      this._topUpNeeded =
        displayableTotalToTopUp > this.UserStatusService.availableBalance &&
        this._isBalanceChargeable(displayableTotalToTopUp);
    }

    return this._topUpNeeded && !hasOverdraft;
  }

  _isBalanceChargeable(totalToPay: number): boolean {
    if (!this.UserStatusService.availableBalance) {
      return false;
    }
    return (
      totalToPay >=
      Math.abs(this.UserStatusService.availableBalance) + this.UserSession.getMinimumChargeAmount()
    );
  }

  updatePaymentFailedModalState(data: ISubscriptionObject): void {
    if (data && data.subscription_state === this.subscriptionStates.Failed) {
      this.PaymentSourceService.getCreditCards()
        .then(() => {
          this.showTrialEndOrPaymentFailedModal();
        })
        .catch(() => {
          toastError(this.$translate.instant('toast.default-error'));
        });
    }
  }

  updateLegacyTrialEndModalState(): void {
    this.showLegacyTrialEndModal = this.isLegacyTrialEnd;
  }

  showTrialEndOrPaymentFailedModal(): void {
    if (this.currentSubscriptionPeriod === 'END_OF_TRIAL') {
      if (this.isLegacyTrialEnd) {
        this.showLegacyTrialEndModal = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !this.showTrialEndModal &&
          this.MixpanelService.track('Subscription - Trial Expired - Open');
        this.showTrialEndModal = true;
      }
    } else if (this.currentSubscriptionPeriod === 'SUBSCRIBED') {
      this._showPaymentFailedModal = true;
      this.MixpanelService.track('Subscription - Payment Failed - Open');
    }
  }

  displayLegacyModal(): void {
    if (localStorage.getItem(WELCOME_LEGACY_MODAL_KEY) === null) {
      const companyId = this.UserSession.getCompanyId();

      if (this._currentSubscription) {
        this.checkLegacyModalCondition(this._currentSubscription);
      } else if (this.UserSession && companyId) {
        this.fetchCurrentSubscription({
          company_id: companyId,
        }).then((data) => {
          this.checkLegacyModalCondition(data);
        });
      }
    }
  }

  displayEnterpriseWelcomeModal(): void {
    if (localStorage.getItem(WELCOME_ENTERPRISE_MODAL_KEY) === null) {
      const companyId = this.UserSession.getCompanyId();

      if (this._currentSubscription) {
        this.checkEnterpriseWelcomeModalCondition();
      } else if (this.UserSession && companyId) {
        this.fetchCurrentSubscription({
          company_id: companyId,
        }).then(() => {
          this.checkEnterpriseWelcomeModalCondition();
        });
      }
    }
  }

  get isShipmentDoneModalSeen(): boolean {
    if (localStorage.getItem(SHIPMENT_DONE_MODAL_KEY)) {
      return true;
    }

    return false;
  }

  saveShipmentDoneModalSeen(): void {
    localStorage.setItem(SHIPMENT_DONE_MODAL_KEY, 'true');
  }

  checkLegacyModalCondition(currentSub: ISubscriptionObject): void {
    if (this.isCurrentLegacyPlan(currentSub, true)) {
      this._showWelcomeLegacyModal = true;

      this.MixpanelService.track('Subscription - Legacy - Open');
    }

    localStorage.setItem(WELCOME_LEGACY_MODAL_KEY, 'true');
  }

  checkEnterpriseWelcomeModalCondition(): void {
    if (this.isEnterprisePlan) {
      this._showWelcomeEnterpriseModal = true;
    }

    localStorage.setItem(WELCOME_ENTERPRISE_MODAL_KEY, 'true');
  }

  /** LegacyPlan means the plan was subscribed with a specific coupon code */
  isCurrentLegacyPlan(
    currentSub: ISubscriptionObject | null,
    isCheckSubscriptionState: boolean
  ): boolean {
    if (
      currentSub?.plan &&
      (isCheckSubscriptionState
        ? currentSub.subscription_state !== this.subscriptionStates.Failed
        : true) &&
      currentSub?.coupon?.id === LEGACY_CODE
    ) {
      return true;
    }

    return false;
  }

  get isCurrentPlanChangeable(): boolean {
    return !this.isEnterprisePlan || !this._currentSubscription?.plan_commitment;
  }

  get isPlanVersionOutdated(): boolean {
    const { next_plan: nextPlan, plan: currentPlan } = this.currentSubscription ?? {};
    const subscriptionPlan = nextPlan ?? currentPlan;

    if (!subscriptionPlan) return false;

    const latestPlan = this.plansDetail?.plans?.find((p) => p.id === subscriptionPlan.id);

    return latestPlan?.version !== subscriptionPlan.version;
  }

  get formattedMigrateDate(): string {
    const migrationDate = this.currentSubscription?.migration_date;

    const isBeforeNow = dayjs(migrationDate).isBefore(dayjs());

    if (!migrationDate || isBeforeNow) {
      return '';
    }

    return formatDate(migrationDate);
  }

  destroyCurrentSubscription(): void {
    this._currentSubscription = null;
  }

  // End Api Request Method

  // Start Helper Method

  _isCustomTax(tax: number | string): boolean {
    return typeof tax === 'string' && tax.toLowerCase() === 'custom';
  }

  returnAvailableFeatures(
    selectedPlanId: DefinedSubscriptionPlanId,
    features: IFeature[]
  ): IFeature[] {
    const availableFeatures: IFeature[] = [];
    features.forEach((feature) => {
      const featureStatus = feature.data[selectedPlanId];
      if (
        featureStatus !== false &&
        featureStatus !== 'freemium-pricing.features.account-manager-toggle.disable'
      ) {
        availableFeatures.push(feature);
      }
    });

    return availableFeatures;
  }

  getPrice(planId?: DefinedSubscriptionPlanId, currentInterval?: BillingInterval): PlanPricing {
    // eslint-disable-next-line no-param-reassign
    currentInterval =
      currentInterval || this.currentSubscription?.plan?.interval || BillingInterval.Month;
    const isDiscounted = this.currentSubscription?.coupon?.id === LEGACY_CODE;
    const UNKNOWN_PRICE = {
      baseRate: 0,
      rate: 0,
      taxAmount: 0,
      currentInterval,
      isTaxed: false,
      isDiscounted,
    };

    const isCurrentCustom = !Object.values(DefinedSubscriptionPlanId).includes(
      this.currentSubscription?.plan?.id as any
    );

    if (!planId && isCurrentCustom) return UNKNOWN_PRICE;

    // eslint-disable-next-line no-param-reassign
    planId = planId || (this.currentSubscription?.plan?.id as DefinedSubscriptionPlanId);
    const selectedPlan = this._plansDetail?.plans?.find(({ id }) => id === planId);

    if (!selectedPlan) return UNKNOWN_PRICE;

    const {
      monthly,
      yearly,
      monthly_sales_tax: monthlySalesTax,
      yearly_sales_tax: yearlySalesTax,
    } = selectedPlan.price;
    const baseRate = (currentInterval === BillingInterval.Month ? monthly : yearly) as number;
    const baseTax =
      ((currentInterval === BillingInterval.Month ? monthlySalesTax : yearlySalesTax) as number) ||
      0;

    return {
      baseRate,
      rate: baseRate * (isDiscounted ? 1 - this.legacyDiscountRate : 1),
      taxAmount: baseTax * (isDiscounted ? 1 - this.legacyDiscountRate : 1),
      taxName: this._plansDetail?.sales_tax_name || '',
      currentInterval,
      isTaxed: !!baseTax,
      isDiscounted,
    };
  }

  getTax(
    billingPeriod: string,
    price: IApiPrice,
    taxName: string
  ): { name?: string; amount?: number } {
    if (!taxName || this._isCustomTax(taxName)) return {};

    const amount =
      (billingPeriod === BillingInterval.Month && (price.monthly_sales_tax as number)) ||
      (billingPeriod === BillingInterval.Year && (price.yearly_sales_tax as number));
    return amount ? { name: taxName, amount } : {};
  }

  returnEasyShipCouriersCount(couriersAccount: ICourierAccountObject[]): number {
    let couriersCount = 0;
    couriersAccount.forEach((account: ICourierAccountObject) => {
      if (account.number_of_couriers) {
        couriersCount += account.number_of_couriers;
      }
    });

    return couriersCount;
  }

  findDefaultCard(creditCards: ICreditCardData[]): ICreditCardData | null {
    return creditCards.find((card) => card.default_for_shipments) || null;
  }

  findDefaultSubscriptionCard(creditCards: ICreditCardData[]): ICreditCardData | null {
    return creditCards.find((card) => card.default_for_subscription) || null;
  }

  enableZendeskChat(): void {
    zendeskChatInit(this.handleZendeskOnload);
  }

  private handleZendeskOnload() {
    if (!this.$window.zE) return;

    this.$window.zE('messenger', 'hide');

    if (this.isChatSupportAvailable) {
      this.$window.zE('messenger', 'loginUser', (callback: (token: string) => void) => {
        this.UserSession.zendeskMessagingJwt && callback(this.UserSession.zendeskMessagingJwt);
      });
      this.$window.zE('messenger', 'show');
    }
  }

  isPlanBadgeVisible(featureKey: FeatureKey): boolean {
    const feature = this._currentSubscription?.all_features[featureKey];

    if (
      !feature ||
      !feature.plan?.slug ||
      feature.plan.slug === 'free' ||
      !this.isSubscriptionAvailable
    ) {
      return false;
    }

    return this.currentSubscriptionPeriod === 'TRIAL' || !feature.is_accessible;
  }

  getPlanSlugByFeatureKey(featureKey: FeatureKey): PlanSlug | null {
    const feature = this._currentSubscription?.all_features[featureKey];
    if (!feature) return null;

    return feature?.plan?.slug ?? null;
  }

  /** The plan name is the translated plan slug. */
  getPlanNameByPlanSlug(planSlug: PlanSlug | undefined | null): string {
    const planSlugI18nKey = planSlug ? `freemium-pricing.category.${planSlug}` : '';
    return this.$translate.instant(planSlugI18nKey);
  }

  get currentPlanName(): string {
    return this.getPlanNameByPlanSlug(this.currentSubscription?.plan?.slug);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey): string {
    const planSlug = this.getPlanSlugByFeatureKey(featureKey);
    return this.getPlanNameByPlanSlug(planSlug);
  }

  getSuggestedPlanIdByFeatureKey(
    featureKey: FeatureKey
  ): DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId {
    const planSlug = this.getPlanSlugByFeatureKey(featureKey);
    const suggestedPlan = planSlug && this.findPlanByPlanSlug(planSlug);
    const nextRankingPlanId = this.getNextRankingPlanId();

    return suggestedPlan?.id ?? nextRankingPlanId;
  }

  getNextRankingPlanId(defaultPlanId = 0): number {
    const enterprisePlan = this.findPlanByPlanSlug('enterprise');

    if (!enterprisePlan || !this.currentSubscription?.plan) {
      return defaultPlanId;
    }

    const nextLevelPlanRanking = Math.min(
      enterprisePlan.ranking,
      this.currentSubscription.plan.ranking + 1
    );
    const nextRankingPlan = this.findPlanByPlanRanking(nextLevelPlanRanking);

    return nextRankingPlan?.id ?? defaultPlanId;
  }

  listenToSubscriptionExpiration(): void {
    if (this.currentSubscriptionPeriod !== 'END_OF_TRIAL') return;

    const trialEndAt = this.currentSubscription?.trial_end_at;

    if (!trialEndAt) return;

    const remainingTimeInMillisecond = parseUTC(trialEndAt).getTime() - new Date().getTime();

    this.$timeout(() => {
      this.$window.zE?.('messenger', 'hide');
    }, remainingTimeInMillisecond);
  }

  /**
   * @returns: true if the modal is visible, false otherwise
   */
  openUpgradeModalIfFeatureNotAccessible(
    featureKey: FeatureKey,
    mixPanelTriggerSource?: string
  ): boolean {
    const isUpgradeModalVisible = !this.isFeatureAccessible(featureKey);

    this.triggerSource = mixPanelTriggerSource;
    if (!isUpgradeModalVisible) return false;

    if (this.isEnterprisePlan) {
      this.showChangePlanIntegrationModals = {
        enterpriseCall: true,
      };
    } else {
      this.suggestedPlanId = this.getSuggestedPlanIdByFeatureKey(featureKey);
      this.showChangePlanIntegrationModals = {
        compare: false,
        upgrade: true,
      };
    }

    return true;
  }

  // End Helper Method
}

export { SubscriptionService };
