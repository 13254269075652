import { toastError } from '@client/core/components/react/Toastify';
import autoBoxSelectionImageUrl from '@assets/images/dashboard/boxes/auto-box-selection.svg';
import template from './boxes.html?raw';
import style from './boxes.module.scss';

class Boxes {
  autoBoxSelectionImageUrl = autoBoxSelectionImageUrl;

  static $inject = [
    '$q',
    'BoxModalService',
    'BoxService',
    'FlatRateBoxService',
    'MixpanelService',
    'AppCuesService',
    '$state',
    '$scope',
  ];

  constructor(
    $q,
    BoxModalService,
    BoxService,
    FlatRateBoxService,
    MixpanelService,
    AppCuesService,
    $state,
    $scope,
    $translate
  ) {
    this.$q = $q;
    this.BoxModalService = BoxModalService;
    this.BoxService = BoxService;
    this.FlatRateBoxService = FlatRateBoxService;
    this.MixpanelService = MixpanelService;
    this.AppCuesService = AppCuesService;
    this.$state = $state;
    this.$scope = $scope;
    this.$translate = $translate;

    this.style = style;
    this.loading = true;
    this.openAutoSelectionModal = false;
    this.closeAutoSelectionExplainer = this.closeAutoSelectionExplainer.bind(this);
  }

  $onInit() {
    this.$q
      .all([
        this.BoxService.getBoxes(),
        this.FlatRateBoxService.getBoxes({ include: 'auto_selected' }),
      ])
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading = false;
      });

    this.MixpanelService.track('Boxes - Visit Page');
  }

  openUpdateDeleteBoxModal(box) {
    this.BoxModalService.open('update', box);
  }

  openUpdateFlatRateBoxes(box) {
    this.BoxModalService.open('flat-rate-box-update', box);
  }

  openAddNewBoxModal() {
    this.BoxModalService.open('add');
    this.AppCuesService.track("Boxes | Selected 'Add New Box'", null, true);
  }

  openAutoSelectionExplainer() {
    this.openAutoSelectionModal = true;
    this.$scope.$apply();
  }

  closeAutoSelectionExplainer() {
    this.openAutoSelectionModal = false;
    this.$scope.$apply();
  }
}

const BoxesComponent = {
  template,
  controller: Boxes,
};

export { BoxesComponent };
