import React, { memo } from 'react';
import ShipmentsTableHeaderCell from './ShipmentsTableHeaderCell';
import { ShipmentsTableSelectAllProps } from './d';
import RoundedCorner from './RoundedCorner';

const ShipmentsTableSelectAll = memo(
  ({ selectedCount, totalCount, toggleSelection }: ShipmentsTableSelectAllProps) => {
    const isChecked = totalCount > 0 && selectedCount === totalCount;

    return (
      <div
        data-test-id="shipments-table-select-all"
        className="flex items-center  rounded bg-white h-10 pr-[10px] mb-2"
      >
        <ShipmentsTableHeaderCell className="flex-grow-0 min-w-5 w-5 hover:bg-white cursor-pointer sticky left-0 pl-[10px] group">
          <RoundedCorner classNameInner="group-hover:bg-white" />
          <RoundedCorner position="bottom-left" classNameInner="group-hover:bg-white" />
        </ShipmentsTableHeaderCell>
        <div className="flex items-center justify-start sticky left-5">
          <div>
            <strong>
              {selectedCount}/{totalCount}
            </strong>{' '}
            shipments selected.
          </div>
          <button type="button" className="text-blue-500 pl-3" onClick={toggleSelection}>
            {isChecked ? 'Clear selection' : `Select all ${totalCount} shipments`}
          </button>
        </div>
      </div>
    );
  }
);

export default ShipmentsTableSelectAll;
