(function() {
  'use strict';

  function handoverOptionsListCtrl() {
    var ctrl = this;

    ctrl.$onInit = function() {
      if (ctrl.courierHandoverOptions)
        ctrl.courierHandoverOptionsList = sanitizeCourierHandoverOptions(
          ctrl.courierHandoverOptions
        );
    };

    function sanitizeCourierHandoverOptions(handoverOptions) {
      var arrayOptions = handoverOptions.split(',');
      var finalArray = [];

      if (_includes(arrayOptions, 'dropoff')) {
        finalArray.push('dropoff');
      }

      if (_includes(arrayOptions, 'free_pickup') && _includes(arrayOptions, 'paid_pickup')) {
        finalArray.push('free_and_paid_pickup');
      } else {
        if (_includes(arrayOptions, 'free_pickup')) finalArray.push('free_pickup');
        if (_includes(arrayOptions, 'paid_pickup')) finalArray.push('paid_pickup');
      }

      return finalArray;
    }

    function _includes(array, string) {
      return array.indexOf(string) > -1;
    }
  }

  handoverOptionsListCtrl.$inject = [];

  angular
    .module('app.component.handover-options-list')
    .controller('handoverOptionsListCtrl', handoverOptionsListCtrl);
})();
