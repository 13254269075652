import { useQuery } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';
import { IUser } from 'typings/user-session';

const companyUsersComparator = (a: IUser, b: IUser) => {
  if (a.role.id !== b.role.id) return a.role.id - b.role.id;
  return a.first_name > b.first_name ? 1 : -1;
};

export default function useCompanyUsersQuery() {
  const { companyGateway } = useGateways();

  return useQuery({
    queryKey: ['companyUsers'],
    queryFn: () => companyGateway.getCompanyUsers(),
    select: (users: IUser[]) => users.sort(companyUsersComparator),
  });
}
