import template from './rating-email-preview-modal.html?raw';

class RatingEmailPreviewModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open() {
    this.$uibModal.open({
      template,
      size: 'lg',
      openedClass: 'easyship-modal-no-padding',
    });
  }
}

export { RatingEmailPreviewModal };
