import {
  IProductListingTotals,
  IProductListingSaveProducts,
} from 'typings/dashboard/services/product-listing';
import { IApiConfig } from 'typings/core/config/api';
import { SuggestedHsCodes } from '@client/core/corelogic/models/HsCode';
import {
  CategorySelection,
  HsCodeSelection,
  CategoryHsCodeSelection,
} from '@client/src/global/components/category-hs-code/types';

import { ICountry, ICountryService } from 'typings/auth/services/country';
import { IComponentController } from 'angular';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './edit-products-modal-bulk-edit.module.scss';
import template from './edit-products-modal-bulk-edit.html?raw';
import { ProductListingService } from '../../product-listing.service';

interface IDimensions {
  length: number;
  width: number;
  height: number;
}

interface IFormData {
  id: string;
  identifier: string;
  name: string;
  category_id: number | null;
  hs_code: string | null;
  origin_country_id: string;
  dimensions: IDimensions;
  dimensions_unit: string;
  weight: number;
  weight_unit: string;
  pick_location: string;
  contains_liquids?: boolean;
  contains_battery_pi966?: boolean;
  contains_battery_pi967?: boolean;
}

class EditProductsModalBulkEdit implements IComponentController {
  style = style;
  esMissingInformation = false;
  columnWidth = {
    count: 40,
    identifier: 120,
    category: 150,
    countryOrigin: 120,
    dimensions: 200,
    weight: 100,
    pickLocation: 120,
  };
  loading = {
    list: true,
    submit: false,
  };
  products: IFormData[] = [];
  totals: IProductListingTotals = {
    missing_info_products_count: 0,
    total_products_count: 0,
  };
  countries: Partial<ICountry>[] = [];
  categoryHsCodeSelectedValue: CategoryHsCodeSelection[] = [];
  suggestedHsCodes: SuggestedHsCodes = {};

  static $inject = [
    '$translate',
    'API',
    'ProductListingService',
    'CountryService',
    'UserRightsService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private API: IApiConfig,
    private ProductListingService: ProductListingService,
    private CountryService: ICountryService,
    private UserRightsService: UserRightsService
  ) {}

  $onInit(): void {
    const getProducts = this.esMissingInformation
      ? this.ProductListingService.getMissingProducts()
      : this.ProductListingService.getProductsToUpdate();

    getProducts
      .then(({ products, totals }) => {
        this.products = products.map(
          ({
            id,
            identifier,
            name,
            category,
            hs_code,
            origin_country_id,
            length,
            width,
            height,
            dimensions_unit,
            weight,
            weight_unit,
            pick_location,
          }) =>
            ({
              id,
              identifier,
              name,
              category_id: category.id,
              hs_code,
              origin_country_id,
              dimensions: {
                length,
                width,
                height,
              },
              dimensions_unit,
              weight,
              weight_unit,
              pick_location,
            } as IFormData)
        );

        this.categoryHsCodeSelectedValue = products.map((product) => {
          if (product.hs_code && product.hs_code_description) {
            return {
              hsCodeNumber: product.hs_code,
              hsCodeDescription: product.hs_code_description,
            };
          }
          if (product.category.id) {
            return { categoryId: product.category.id };
          }

          return null;
        });

        products.forEach(({ hs_code, hs_code_description }) => {
          if (hs_code && hs_code_description) {
            this.suggestedHsCodes[hs_code] = hs_code_description;
          }
        });

        this.totals = totals;
      })
      .finally(() => {
        this.loading = {
          list: false,
          submit: false,
        };
      });

    this.CountryService.getCountries().then(({ countries }) => {
      this.countries = [
        { simplified_name: this.$translate.instant('global.unspecified') },
        ...countries,
      ];
    });
  }

  get helpUrl(): string {
    return this.API.help;
  }

  onFieldChange(value: string | number | IDimensions, field: keyof IFormData, index: number): void {
    (this.products[index][field] as string | number | IDimensions) = value;

    if (field === 'dimensions' && typeof value !== 'string' && typeof value !== 'number') {
      this.products[index].dimensions = value;
    }
  }

  onCategorySelect({ categoryId }: CategorySelection, index: number): void {
    this.products[index].category_id = categoryId;
    this.products[index].hs_code = null;

    this.categoryHsCodeSelectedValue[index] = { categoryId };
  }

  onHsCodeSelect({ hsCodeNumber, hsCodeDescription }: HsCodeSelection, index: number): void {
    this.products[index].hs_code = hsCodeNumber;
    this.products[index].category_id = null;

    this.categoryHsCodeSelectedValue[index] = { hsCodeNumber, hsCodeDescription };
    this.suggestedHsCodes[hsCodeNumber] = hsCodeDescription;
  }

  onSubmit(form: ng.IFormController): void {
    if (form.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    const products = this.products.reduce<IProductListingSaveProducts[]>((accumulator, product) => {
      const {
        id,
        identifier,
        name,
        category_id: categoryId,
        hs_code: hsCode,
        origin_country_id: originCountryId,
        dimensions: { length, width, height },
        weight,
        pick_location: pickLocation,
      } = product;

      accumulator.push({
        id,
        identifier,
        name,
        category_id: categoryId,
        hs_code: hsCode,
        origin_country_id: originCountryId ? Number(originCountryId) : null,
        length,
        width,
        height,
        weight,
        pick_location: pickLocation,
      });

      return accumulator;
    }, []);

    const payload = {
      products,
    };

    this.loading.submit = true;

    this.ProductListingService.update(payload)
      .then(() => {
        toastSuccess(this.$translate.instant('product-listing.warnings.create-success'));
        this.ProductListingService.hideModal();

        this.ProductListingService.getProductList();
        this.ProductListingService.resetCheckState();
      })
      .catch(({ data }: { data: Record<string, Record<string, string[]>>[] }) => {
        const listBody = data
          .map((item) =>
            Object.entries(item)
              .filter(([_key, { name }]) => !!name)
              .map(([key]) => `<li>${key}</li>`)
              .join('')
          )
          .join('');
        toastError(
          this.$translate.instant('toast.update-error', { details: `<ul>${listBody}</ul>` })
        );
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  get isSaveButtonDisabled(): boolean {
    return this.loading.list || this.loading.submit || !this.UserRightsService.canEditProduct;
  }
}

const EditProductsModalBulkEditComponent: ng.IComponentOptions = {
  controller: EditProductsModalBulkEdit,
  template,
  bindings: {
    esMissingInformation: '<',
    esOnBack: '&',
  },
};

export { EditProductsModalBulkEditComponent };
