import angular from 'angular';

import viewName from './view-name';
import viewsDropdown from './views-dropdown';
import customViewModal from './custom-view-modal';
import deleteViewModal from './delete-view-modal';
import sortBy from './sort-by';

const MODULE_NAME = 'app.global.components.shipments-views';

angular.module(MODULE_NAME, [viewName, viewsDropdown, customViewModal, deleteViewModal, sortBy]);

export default MODULE_NAME;
