import { IUserSession } from 'typings/user-session';
import { ICreditCardData } from 'typings/payment-source';
import { IPaymentService } from 'typings/payment-service';
import {
  IDowngradePlanResponse,
  ISubscriptionFeature,
  ISubscriptionObject,
} from 'typings/subscription';
import {
  BillingFrequency,
  DefinedSubscriptionPlanId,
  FEATURE_NAME,
  FeatureKey,
} from '@client/data/subscription';
import { PaymentFormId } from '@client/data/payment-form-id';

import { IComponentController } from 'angular';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { SubscriptionPaymentService } from '@client/src/global/services/subscription/subscription-payment.service';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './downgrade-plan-card.module.scss';
import template from './downgrade-plan-card.html?raw';
import image from './downgrade-plan-card.svg?svgo';

class DowngradePlanCard implements IComponentController {
  style = style;
  image = image;
  esSelectedPlanId = 1;
  esRightText = '';
  esLeftText = '';
  esInfo = '';
  esToggleState = false;
  paymentState = true;
  busyPaying = false;
  creditCardErrorCode = '';
  downgradePlan: IDowngradePlanResponse | null = null;
  defaultCard: ICreditCardData | null = null;
  wrappers = {
    red: (chunk: string) => `<span class="text-red-500">${chunk}</span>`,
  };
  paymentFormId = PaymentFormId.DowngradePlan;
  triggerDowngradeReasonsDialog = false;
  downgradingPlan = false;
  currency = '';

  static $inject = [
    '$window',
    '$location',
    '$filter',
    '$translate',
    'UserSession',
    'SubscriptionService',
    'PaymentSourceService',
    'PaymentService',
    'SubscriptionPaymentService',
  ];
  constructor(
    private $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private $filter: ng.IFilterFunction,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private SubscriptionService: SubscriptionService,
    private PaymentSourceService: PaymentSourceService,
    private PaymentService: IPaymentService,
    private SubscriptionPaymentService: SubscriptionPaymentService
  ) {}

  esRightButtonAction(): void {
    // esRightAction expression bindings, need to add this in order for typescript to successfully compile
  }

  esToggleChurnReasonsDialog(): void {
    // esToggleChurnReasonsDialog expression bindings, need to add this in order for typescript to successfully compile
  }

  $onInit(): void {
    if (this.esSelectedPlanId === undefined) {
      throw new Error(
        'Easyship Subscription Downgrade Plan Card: An es-selected-plan-id binding must be provided.'
      );
    }

    if (this.UserSession) {
      const { company } = this.UserSession;
      this.currency = this.UserSession.getCompanyCurrency();
      this._fetchDowngradePlan(this.esSelectedPlanId, company.id);

      if (!this.SubscriptionService.currentSubscription) {
        this.SubscriptionService.fetchCurrentSubscription({ company_id: company.id }).then(() => {
          this._fetchCards();
        });
      } else {
        this._fetchCards();
      }

      this.PaymentService.unregisterPaymentForm(PaymentFormId.DowngradePlan);
    }
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get unitPlan(): BillingFrequency {
    return this.esToggleState ? BillingFrequency.Year : BillingFrequency.Month;
  }

  get FreePlanId(): DefinedSubscriptionPlanId {
    return DefinedSubscriptionPlanId.Free;
  }

  get unsupportedFeatures(): ISubscriptionFeature[] {
    if (!this.SubscriptionService.currentSubscription || !this.downgradePlan?.subscription) {
      return [];
    }

    const currentFeatures = this.SubscriptionService.currentSubscription.all_features;
    const downgradeFeatures = this.downgradePlan.subscription.all_features;

    // NOTICE: This logic cannot handle users that are in the end-of-trial period because
    // their currentFeature will be treated as the "Free" plan. Instead, we will need to query
    // the features from plansDetail to check if the feature is accessible or not in the future.
    const missingFeatures = Object.entries(currentFeatures).reduce<ISubscriptionFeature[]>(
      (result, [featureKey, currentFeature]) => {
        const downGradeFeature = downgradeFeatures[featureKey as FeatureKey];
        if (currentFeature.is_accessible && downGradeFeature?.is_accessible === false) {
          return result.concat(currentFeature);
        }
        return result;
      },
      []
    );

    // Filter out the features if the feature is disabled by feature flag
    const filteredMissingFeaturesByFeatureFlag = this.filterFeaturesByFlag(missingFeatures);

    return filteredMissingFeaturesByFeatureFlag;
  }

  private filterFeaturesByFlag(missingFeatures: ISubscriptionFeature[]): ISubscriptionFeature[] {
    const isCustomizeTableDisabled =
      !this.UserSession.getFeatureFlagsByFlagName('smb_pick_and_pack');
    const isPickAndPackDisabled = !this.UserSession.getFeatureFlagsByFlagName('smb_pick_and_pack');
    const filteredMissingFeatures = missingFeatures.filter((feature) => {
      if (isCustomizeTableDisabled && feature.name === FEATURE_NAME.NEW_SHIPMENTS_TABLE) {
        return false;
      }
      if (isPickAndPackDisabled && feature.name === FEATURE_NAME.SMB_PICK_AND_PACK) {
        return false;
      }
      return true;
    });

    return filteredMissingFeatures;
  }

  get remainingDaysBeforeTrialEnd(): string {
    return this.SubscriptionService.remainingDaysBeforeTrialEnd;
  }

  get defaultCardBrand(): string {
    if (this.defaultCard && this.defaultCard.brand) {
      return this.defaultCard.brand.toUpperCase();
    }

    return '';
  }

  get isSelectedFreePlan(): boolean {
    return this.esSelectedPlanId === DefinedSubscriptionPlanId.Free;
  }

  get isCurrentLegacyPlan(): boolean {
    return this.SubscriptionService.isCurrentLegacyPlan(this.currentSubscription, false);
  }

  get isSelectedFreePlanAndLegacyTrialEnd(): boolean {
    return this.isSelectedFreePlan && this.isCurrentLegacyPlan;
  }

  get displayAmount(): number | string {
    if (
      this.downgradePlan &&
      this.downgradePlan.subscription &&
      this.downgradePlan.subscription.next_plan
    ) {
      const { amount } = this.downgradePlan.subscription.next_plan;
      const discounted = this.SubscriptionService.isCurrentLegacyPlan(
        this.currentSubscription,
        false
      )
        ? amount * this.SubscriptionService.legacyDiscountRate
        : amount;
      return this.$filter('intlCurrency')(discounted, this.currency);
    }

    return '';
  }

  get downgradedLabellingFee(): number {
    return this.downgradePlan?.subscription.all_features.lyoc_labelling_fee?.limit ?? 0;
  }

  get displayedDowngradedLabellingFee(): string {
    return this.$filter('intlCurrency')(this.downgradedLabellingFee, this.currency, {
      minimumFractionDigits: 0,
    });
  }

  get isFreeTrialAvailable(): boolean {
    return this.SubscriptionService.freeTrialAmount > 0;
  }

  get currentPlanName(): string {
    return this.SubscriptionService.currentPlanName;
  }

  get downgradePlanName(): string {
    return this.SubscriptionService.getPlanNameByPlanSlug(
      this.downgradePlan?.subscription?.next_plan?.slug
    );
  }

  _handlePaymentErrors(err: Error): void {
    this.busyPaying = false;
    const [errorCode] = transformToErrorCodes(err);
    this.creditCardErrorCode = errorCode || 'other';
    toastError(this.$translate.instant('account.payment.notifications.stripe-error'));
  }

  async onCardFormSubmit(): Promise<void> {
    if (this.busyPaying) {
      return;
    }

    this.busyPaying = true;
    this.creditCardErrorCode = '';

    this.PaymentService.submitPaymentForm(PaymentFormId.DowngradePlan)
      .then((paymentFormResult) => {
        const companyId = this.UserSession.getCompanyId();
        if (paymentFormResult && companyId) {
          this.SubscriptionPaymentService.paymentFormResultAction(
            paymentFormResult,
            companyId,
            this.esSelectedPlanId,
            this.unitPlan
          )
            .then(() => {
              this.busyPaying = false;
              this.esRightButtonAction();
              this.refreshPage();
            })
            .catch((err) => this._handlePaymentErrors(err));
        } else {
          this.busyPaying = false;
          toastError(this.$translate.instant('account.payment.notifications.stripe-error'));
        }
      })
      .catch((err) => this._handlePaymentErrors(err));
  }

  _fetchDowngradePlan(planId: DefinedSubscriptionPlanId, companyId: string): void {
    this.SubscriptionService.getDowngradePlan(
      {
        company_id: companyId,
      },
      {
        plan_id: planId,
        billing_frequency: BillingFrequency[this.esToggleState ? 'Year' : 'Month'],
      }
    )
      .then((data: IDowngradePlanResponse) => {
        if (data) {
          this.downgradePlan = data;
        }
      })
      .catch((e) => {
        toastError(this.$translate.instant(e));
        throw e;
      });
  }

  _fetchCards(): void {
    this.PaymentSourceService.getCreditCards()
      .then(() => {
        if (this.PaymentSourceService.creditCards.length <= 0) {
          this.paymentState = false;
        } else {
          if (this.SubscriptionService.currentSubscription) {
            this.defaultCard = this.SubscriptionService.findDefaultSubscriptionCard(
              this.PaymentSourceService.creditCards
            );
          } else {
            this.defaultCard = this.SubscriptionService.findDefaultCard(
              this.PaymentSourceService.creditCards
            );
          }

          if (!this.defaultCard) {
            this.paymentState = false;
          }
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  async actionChangePlanFlow(cardId: string | null): Promise<void> {
    const companyId = this.UserSession.getCompanyId();

    if (companyId) {
      try {
        await this.SubscriptionService.actionChangePlan(
          cardId,
          companyId,
          this.esSelectedPlanId,
          this.unitPlan
        );
        this.esRightButtonAction();

        if (this.triggerDowngradeReasonsDialog) {
          this.esToggleChurnReasonsDialog();
        } else {
          this.refreshPage();
        }
      } catch (error) {
        toastError(this.$translate.instant('toast.default-error'));
        throw error;
      }
    } else {
      toastError(this.$translate.instant('toast.default-error'));
    }
  }

  async downgradeAction(card?: ICreditCardData): Promise<void> {
    this.downgradingPlan = true;

    if (this.isSelectedFreePlan || this.isFreeTrialAvailable) {
      this.triggerDowngradeReasonsDialog = true;
      await this.actionChangePlanFlow(null);
    } else if (this.paymentState && card) {
      this.busyPaying = true;
      await this.actionChangePlanFlow(card.id);
    } else {
      await this.onCardFormSubmit();
    }

    this.downgradingPlan = false;
  }

  // Refresh page to ensure that dashboard features match the new plan
  private refreshPage(): void {
    if (this.downgradePlan?.subscription?.next_plan) {
      this.$window.location.href = `${this.$location.path()}?downgrade_plan=${
        this.downgradePlanName
      }`;
    }
  }
}

const DowngradePlanCardComponent: ng.IComponentOptions = {
  controller: DowngradePlanCard,
  template,
  bindings: {
    esSelectedPlanId: '<',
    esCancelSubscription: '<',
    esEndTrial: '<',
    esToggleState: '<',
    esRightText: '<',
    esRightClass: '<',
    esLeftText: '<',
    esLeftButtonAction: '&',
    esLeftClass: '<',
    esCloseTitleAction: '&',
    esShowCloseButton: '<',
    esInfo: '<',
    esRightButtonAction: '&',
    esRightButtonLoading: '<',
    esToggleChurnReasonsDialog: '&',
  },
};

export { DowngradePlanCardComponent };
