import angular from 'angular';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './printing-options.html?raw';
import style from './printing-options.module.scss';

class PrintingOptions {
  static $inject = [
    'UserSession',
    '$state',
    'CompanyService',
    'MixpanelService',
    'SubscriptionService',
    '$translate',
  ];

  constructor(
    UserSession,
    $state,
    CompanyService,
    MixpanelService,
    SubscriptionService,
    $translate
  ) {
    this.CompanyService = CompanyService;
    this.UserSession = UserSession;
    this.$state = $state;
    this.MixpanelService = MixpanelService;
    this.busy = {};
    this.style = style;
    this.lastChangedOption = '';
    this.SubscriptionService = SubscriptionService;
    this.$translate = $translate;
  }

  $onInit() {
    const company = angular.copy(this.UserSession.company);
    this.printingOptions = this._extractPrintingOptionsFromCompanyData(company);
    this.MixpanelService.track('Print Option - Visit page');
  }

  onPrintingOptionsFormChange(data) {
    const previousOptions = this.printingOptions;

    this.busy.update = true;
    this.printingOptions = { ...this.printingOptions, ...data };
    this.lastChangedOption = Object.keys(data)[0];

    return this.CompanyService.updateCompany(data)
      .then(() => {
        this._track(data);
        toastSuccess(this.$translate.instant('printing-options.notifications.update-success'));
      })
      .catch((error) => {
        this.printingOptions = previousOptions;

        const [errorCode] = transformToErrorCodes(error);

        if (errorCode === 'subscription_access_denied') {
          // handle error in form.component.js
          throw error;
        }

        if (errorCode) {
          toastError(
            this.$translate.instant('printing-options.notifications.update-failure', {
              status: this.$translate.instant(
                `printing-options.errors.${errorCode}`,
                'messageformat'
              ),
            })
          );
        }
      })
      .finally(() => {
        this.busy.update = false;
      });
  }

  _extractPrintingOptionsFromCompanyData({
    label_format,
    packing_slip_format,
    commercial_invoice_format,
    download_format,
    auto_generate_manifests,
    auto_download_zip,
    group_by_sku,
    pick_list_templates,
    pick_list_sort_by,
    label_customization,
  }) {
    return {
      label_format,
      packing_slip_format,
      commercial_invoice_format,
      download_format,
      auto_generate_manifests,
      auto_download_zip,
      group_by_sku,
      pick_list_templates,
      pick_list_sort_by,
      label_customization,
    };
  }

  _track(data) {
    if (data.label_format) {
      this.MixpanelService.track('Print Option - Change - Label Format', {
        selection: data.label_format,
      });
    }

    if (data.packing_slip_format) {
      this.MixpanelService.track('Print Option - Change - Packing Slip Format', {
        selection: data.packing_slip_format,
      });
    }

    if (data.commercial_invoice_format) {
      this.MixpanelService.track('Print Option - Change - Commercial Invoice', {
        selection: data.commercial_invoice_format,
      });
    }

    if (data.download_format) {
      this.MixpanelService.track('Print Option - Change - PDF Grouping', {
        selection: data.download_format,
      });
    }

    if (typeof data.auto_generate_manifests === 'boolean') {
      this.MixpanelService.track('Print Option - Change - Auto Manifest', {
        checkbox: data.auto_generate_manifests,
      });
    }
  }
}

const PrintingOptionsComponent = {
  controller: PrintingOptions,
  template,
};

export { PrintingOptionsComponent };
