import React, { useMemo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@client/core/components/react/Button';
import Alert from '@client/core/components/react/Alert';
import Select from '@client/core/components/react/Select';
import Input from '@client/core/components/react/Input';
import FormLabel from '@client/core/components/react/FormLabel';
import { API } from '@client/core/config/api.constant';
import COLORS from '@client/src/colors';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import { fallbackKey } from '@client/data/platform';
import { IStore, IStoreService, IEditableStore } from 'typings/store';
import { IItemCategoryService } from 'typings/item-category';
import { IBoxService, IBoxObject } from 'typings/boxes';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

interface IFallbackSettingsFields {
  defaultCategoryId: number | '';
  defaultPackageId: string | undefined;
  defaultDimensionsLength: number | '';
  defaultDimensionsWidth: number | '';
  defaultDimensionsHeight: number | '';
  defaultProductWeight: number | '';
}
interface FallbackSettingsDialogProps {
  open: boolean;
  store: IStore;
  scenarioName?: string;
  dimensionsDisabled?: boolean;
  $injector: angular.auto.IInjectorService;
  onSuccess: () => Promise<void>;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    '& .MuiIconButton-root': {
      padding: 12,
    },
  },
  platformLogo: {
    height: 60,
    maxWidth: 180,
  },
  dialogTitle: {
    padding: 40,
    paddingBottom: 0,
    borderBottom: 'none',
  },
  dialogContent: {
    padding: 40,
    paddingTop: 0,
  },
  alertMessage: {
    marginTop: 24,
    marginBottom: 24,
  },
  dialogActions: {
    border: 'none',
  },
}));

export default function FallbackSettingsDialog(props: FallbackSettingsDialogProps) {
  const { open, store, scenarioName, dimensionsDisabled, $injector, onSuccess, onClose } = props;

  const shouldDimensionsEmpty = store?.fallback_type === 'no_fallback';

  const { control, handleSubmit, reset, setError } = useForm<IFallbackSettingsFields>({
    defaultValues: {
      defaultCategoryId:
        store?.default_category_id && !shouldDimensionsEmpty ? store.default_category_id : '',
      defaultPackageId:
        store?.default_package_id && !shouldDimensionsEmpty ? store.default_package_id : '',
      defaultDimensionsLength:
        store?.default_item_length && !shouldDimensionsEmpty ? store.default_item_length : '',
      defaultDimensionsWidth:
        store?.default_item_width && !shouldDimensionsEmpty ? store.default_item_width : '',
      defaultDimensionsHeight:
        store?.default_item_height && !shouldDimensionsEmpty ? store.default_item_height : '',
      defaultProductWeight:
        store?.default_item_weight && !shouldDimensionsEmpty ? store.default_item_weight : '',
    },
  });

  const StoreService = $injector.get('StoreService') as IStoreService;
  const { activeStore } = StoreService;

  const ItemCategoryService = $injector.get('ItemCategoryService') as IItemCategoryService;
  const { itemCategories } = ItemCategoryService;

  const BoxService = $injector.get('BoxService') as IBoxService;

  const [isLoading, setIsLoading] = useState(false);
  const [boxOptions, setBoxOptions] = useState<IBoxObject[]>([]);

  const classes = useStyles();
  const { formatMessage, formatNumberToParts } = useIntl();

  const userSession = useUserSession();
  const { weightUnit } = userSession.company;
  const localizedUnit = formatNumberToParts(0, {
    style: 'unit',
    unit: userSession.company.dimensionsUnitEcma,
  })[2].value;

  const isDimensionsVisible = !dimensionsDisabled && store?.fallback_type !== 'product';

  const platformSlug = useMemo(() => {
    return store?.platform?.css_class && store.platform.css_class !== 'other-api-platform'
      ? store.platform.css_class
      : fallbackKey;
  }, [store]);
  const platformLogoUrl = new URL(
    `../../../../../assets/images/logos/platform-logo-detailed/${platformSlug}-detailed.svg`,
    import.meta.url
  ).href;

  const getBoxOptions = async () => {
    const options = await BoxService.getCustomBoxesSelectOptions().then((boxes: IBoxObject[]) => {
      return boxes.filter((box) => box.id);
    });

    setBoxOptions(options);
  };

  useEffect(() => {
    getBoxOptions();
  }, []);

  useEffect(() => {
    reset({
      defaultCategoryId:
        store?.default_category_id && !shouldDimensionsEmpty ? store.default_category_id : '',
      defaultPackageId:
        store?.default_package_id && !shouldDimensionsEmpty ? store.default_package_id : '',
      defaultDimensionsLength:
        store?.default_item_length && !shouldDimensionsEmpty ? store.default_item_length : '',
      defaultDimensionsWidth:
        store?.default_item_width && !shouldDimensionsEmpty ? store.default_item_width : '',
      defaultDimensionsHeight:
        store?.default_item_height && !shouldDimensionsEmpty ? store.default_item_height : '',
      defaultProductWeight:
        store?.default_item_weight && !shouldDimensionsEmpty ? store.default_item_weight : '',
    });
  }, [
    reset,
    store?.default_category_id,
    store?.default_item_height,
    store?.default_item_length,
    store?.default_item_weight,
    store?.default_item_width,
    store?.default_package_id,
    shouldDimensionsEmpty,
  ]);

  useEffect(() => {
    if (open) {
      MixpanelService.track(`${scenarioName} - Fallback Settings Modal - Displayed`, {
        platform: platformSlug,
        is_new_store: false,
      });
    }
  }, [open]);

  const handleClose = () => {
    MixpanelService.track(`${scenarioName} - Fallback Settings Modal - Dismissed`, {
      platform: platformSlug,
    });

    onClose();
  };

  const submit: SubmitHandler<IFallbackSettingsFields> = (values) => {
    const {
      defaultCategoryId,
      defaultPackageId,
      defaultProductWeight,
      defaultDimensionsLength,
      defaultDimensionsWidth,
      defaultDimensionsHeight,
    } = values;

    const { id } = store;
    if (!defaultCategoryId) {
      setError('defaultCategoryId', { type: 'required' });
    }

    if (!defaultProductWeight) {
      setError('defaultProductWeight', { type: 'required' });
    }

    if (!defaultCategoryId || !defaultProductWeight) {
      toastError(formatMessage({ id: 'toast.incomplete-form' }));
      return;
    }

    const isEditingActiveStore = activeStore?.id === id;
    const isDefaultPackageSelected = !!defaultPackageId;
    const hasLength = typeof defaultDimensionsLength === 'number' && !!defaultDimensionsLength;
    const hasWidth = typeof defaultDimensionsWidth === 'number' && !!defaultDimensionsWidth;
    const hasHeight = typeof defaultDimensionsHeight === 'number' && !!defaultDimensionsHeight;
    const shouldIncludeDimensions = isDimensionsVisible && hasLength && hasWidth && hasHeight;
    const shouldDefaultBox =
      isEditingActiveStore &&
      (isDefaultPackageSelected || shouldIncludeDimensions || activeStore?.fallback_type === 'box');

    const changes: IEditableStore = {
      id,
      default_category_id: Number(defaultCategoryId),
      default_package_id: isDefaultPackageSelected ? defaultPackageId : undefined,
      default_item_weight: typeof defaultProductWeight === 'number' ? defaultProductWeight : null,
      default_box_length:
        shouldIncludeDimensions && !isDefaultPackageSelected ? defaultDimensionsLength : undefined,
      default_box_width:
        shouldIncludeDimensions && !isDefaultPackageSelected ? defaultDimensionsWidth : undefined,
      default_box_height:
        shouldIncludeDimensions && !isDefaultPackageSelected ? defaultDimensionsHeight : undefined,
      fallback_type: shouldDefaultBox ? 'box' : undefined,
      default_package_type: shouldDefaultBox ? 'Box' : undefined,
    };

    setIsLoading(true);

    MixpanelService.track(`${scenarioName} - Fallback Settings Modal - Submit Attempt`, {
      platform: platformSlug,
    });

    StoreService.updateStore(changes)
      .then((data) => {
        if (isEditingActiveStore) {
          StoreService.activeStore = { ...activeStore, ...data.store };
        }

        toastSuccess(formatMessage({ id: 'store.notifications.fallback-settings-update' }));

        MixpanelService.track(`${scenarioName} - Fallback Settings Modal - Submit Success`, {
          platform: platformSlug,
        });

        onSuccess();
      })
      .catch(() => {
        toastError(formatMessage({ id: 'store.notifications.fallback-error' }));

        MixpanelService.track(`${scenarioName} - Fallback Settings Modal - Error`, {
          platform: platformSlug,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} className={classes.root} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(submit)}>
        <DialogTitle className={classes.dialogTitle}>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item xs>
              <img src={platformLogoUrl} className={classes.platformLogo} />
            </Grid>
            <div className="flex-1 text-center text-lg border-l border-sky-700">
              <FormattedMessage id="store.checkout.fallback-heading" />
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Alert className={classes.alertMessage}>
                <FormattedMessage id="connect.form.finish-setup-sync-warning" />{' '}
                <a
                  href={`${API.help}/hc/en-us/articles/360046434912-Store-Fallback-Details-`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="global.learn-more" />
                </a>
              </Alert>
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="defaultCategoryId"
                render={({ field, fieldState }) => (
                  <Select
                    native
                    label={formatMessage({ id: 'store.checkout.category' })}
                    inputProps={{
                      style: field.value ? {} : { color: COLORS.inkLight },
                    }}
                    error={!!fieldState.error}
                    {...field}
                  >
                    <option value="" disabled>
                      {formatMessage({ id: 'store.checkout.category-select' })}
                    </option>
                    {itemCategories.map((item) => (
                      <option value={item.id} key={item.slug}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            {isDimensionsVisible && (
              <Grid item style={{ width: 'fit-content' }}>
                <FormLabel
                  label={formatMessage({ id: 'connect.form.dimensions-default.label-dims' })}
                >
                  {boxOptions.length > 0 ? (
                    <Controller
                      control={control}
                      name="defaultPackageId"
                      render={({ field }) => (
                        <Select
                          native
                          inputProps={{
                            style: field.value ? {} : { color: COLORS.inkLight },
                          }}
                          {...field}
                        >
                          <option value="">{formatMessage({ id: 'global.choose-one' })}</option>
                          {boxOptions.map((item: IBoxObject) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  ) : (
                    <Grid container alignItems="center">
                      <Box width={60} clone>
                        <Grid item>
                          <Controller
                            control={control}
                            name="defaultDimensionsLength"
                            render={({ field }) => (
                              <Input
                                type="number"
                                placeholder="0"
                                inputProps={{
                                  min: '0.01',
                                  step: '0.01',
                                  style: { padding: 8 },
                                }}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e.target.value ? Number(e.target.value) : '');
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Box>
                      <span className="text-sky-700 text-base m-1">x</span>
                      <Box width={60} clone>
                        <Grid item>
                          <Controller
                            control={control}
                            name="defaultDimensionsWidth"
                            render={({ field }) => (
                              <Input
                                type="number"
                                placeholder="0"
                                inputProps={{
                                  min: '0.01',
                                  step: '0.01',
                                  style: { padding: 8 },
                                }}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e.target.value ? Number(e.target.value) : '');
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Box>
                      <span className="text-sky-700 text-base m-1">x</span>
                      <Box width={60} clone>
                        <Grid item>
                          <Controller
                            control={control}
                            name="defaultDimensionsHeight"
                            render={({ field }) => (
                              <Input
                                type="number"
                                placeholder="0"
                                inputProps={{
                                  min: '0.01',
                                  step: '0.01',
                                  style: { padding: 8 },
                                }}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e.target.value ? Number(e.target.value) : '');
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Box>
                      <span className="text-sky-700 text-base m-1">{localizedUnit}</span>
                    </Grid>
                  )}
                </FormLabel>
              </Grid>
            )}
            <Grid item style={{ width: 'fit-content' }}>
              <Controller
                control={control}
                name="defaultProductWeight"
                render={({ field, fieldState }) => (
                  <Input
                    label={formatMessage({ id: 'connect.form.fallback-item-weight' })}
                    type="number"
                    placeholder="0"
                    inputProps={{
                      min: '0.01',
                      step: '0.01',
                    }}
                    error={!!fieldState.error}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value ? Number(e.target.value) : '');
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="text-ink-100">{weightUnit}</span>
                      </InputAdornment>
                    }
                    style={{ width: 120 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container justifyContent="space-between">
            <Button onClick={handleClose} color="primary" style={{ color: COLORS.inkNormal }}>
              <FormattedMessage id="connect.form.do-it-later" />
            </Button>
            <Button type="submit" loading={isLoading} color="primary" variant="contained">
              <FormattedMessage id="registration.finish" />
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function WrappedFallbackSettingsDialog(props: FallbackSettingsDialogProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <FallbackSettingsDialog {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}
