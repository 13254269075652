(function () {
  'use strict';

  var services = [
    'ngResource',
    'app.service.AbTestsService',
    'app.service.Action',
    'app.service.AddressService',
    'app.service.AddressyService',
    'app.service.AmazonService',
    'app.service.analyticsDetailsModal',
    'app.service.checkoutService',
    'app.service.CompanyService',
    'app.service.CountryService',
    'app.service.CourierService',
    'app.service.createNewAddressModal',
    'app.service.deleteStoreModal',
    'app.service.EndpointService',
    'app.service.incart-installation-modal',
    'app.service.token-installation-modal',
    'app.service.ItemCategoryService',
    'app.service.MessagingService',
    'app.service.MetadataService',
    'app.service.edit-courier',
    'app.service.PlatformService',
    'app.service.PostalCodeService',
    'app.service.Rule',
    'app.service.show-shipment',
    'app.service.ShipmentAction',
    'app.service.ShipmentCache',
    'app.service.ShipmentHelpers',
    'app.service.ShipmentList',
    'app.service.StoreService',
    'app.service.uibRoutingOptions',
    'app.service.BatchService',
    'app.service.WelcomeService',
    'app.service.WalkthroughService',
    'app.service.WalkthroughTooltipService',
    'app.service.walkthrough-modal',
    'app.service.fix-shipment-modal',
    'app.service.BuyOnGoogleService',
    'app.service.ChanneladvisorService',
    'app.service.EbayService',
    'app.service.GrouponService',
    'app.service.LazadaService',
    'app.service.MatahariMallService',
    'app.service.OnBuyService',
    'app.service.ShoplineService',
    'app.service.ShopifyService',
    'app.service.SquareService',
    'app.service.TikTokService',
    'app.service.TradegeckoService',
    'app.service.WoocommerceService',
    'app.service.WixService',
    'app.service.DestinationAddressesService',
    'app.service.LuxotticaService',
  ];

  angular.module('app.services', services);
})();
