import template from './create-new-address-modal.html?raw';

(function () {
  'use strict';

  createNewAddressModal.$inject = ['$uibModal'];
  function createNewAddressModal($uibModal) {
    var service = this;

    service.open = function (stateParams, modalParams) {
      service.modalInstance = $uibModal.open({
        template,
        controller: 'createNewAddressModalCtrl',
        size: 'lg',
        openedClass: 'easyship-modal-no-padding',
        resolve: {
          stateParams: function () {
            return stateParams;
          },
          modalParams: function () {
            return modalParams;
          },
        },
      });
    };

    service.close = function () {
      service.modalInstance.close();
    };
  }

  angular
    .module('app.service.createNewAddressModal')
    .service('createNewAddressModal', createNewAddressModal);
})();
