import angular from 'angular';

import { BulkEditModalComponent } from './bulk-edit-modal.component';

const MODULE_NAME = 'app.create-shipments.bulk-edit-modal';

angular
  .module(MODULE_NAME, [
    'app.global.services.shipment-list',
    'app.global.services.bulk-edit',
    'app.global.box',
    'app.factory.UserSession',
  ])
  .component('esCreateShipmentsBulkEditModal', BulkEditModalComponent);

export default MODULE_NAME;
