(function () {
  'use strict';

  AmazonService.$inject = ['Auth', 'PlatformApi', 'UserSession', 'StoreService'];
  function AmazonService(Auth, PlatformApi, UserSession, StoreService) {
    var service = this;

    service.authenticate = function (platform_id) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.amazon.authenticate(
          { company_id: UserSession.company.id },
          { platform_id: platform_id }
        ).$promise;
      });
    };

    service.connectStore = function (newStore) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.amazon
          .connect({ company_id: UserSession.company.id }, { store: newStore })
          .$promise.then(function (response) {
            StoreService.saveStoreValueToLocal(response.store);
            return response;
          });
      });
    };
  }

  angular.module('app.service.AmazonService', []).service('AmazonService', AmazonService);
})();
