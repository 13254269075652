import { IGroupedArray } from 'typings/helper';
import { IInputGroupRadioModel } from 'typings/form';
import { IAdvancedSearchMultiselectObject } from 'typings/advanced-search';

import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import style from './label-status-multi-select-group.module.scss';
import template from './label-status-multi-select-group.html?raw';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

const DEFAULT_FILTERS: IGroupedArray<IAdvancedSearchMultiselectObject>[] = [
  {
    display: 'All states',
    id: 'all-label',
    value: '',
    options: [
      { display: 'Pending', value: 'pending', isDisplay: true },
      { display: 'Generated', value: 'generated', isDisplay: true },
      { display: 'Printed', value: 'printed', isDisplay: true },
    ],
  },
];

class LabelStatusMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchMultiselectObject>
  implements IComponentController
{
  style = style;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [
    {
      title: 'Label Status is any of',
      value: MultiselectComponentModelValue.AnyOf,
    },
    {
      title: 'Label Status is none of',
      value: MultiselectComponentModelValue.NoneOf,
    },
    {
      title: 'Label Status is known',
      value: MultiselectComponentModelValue.Known,
    },
    {
      title: 'Label Status is unknown',
      value: MultiselectComponentModelValue.Unknown,
    },
  ];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions = [];

  static $inject = ['$scope'];
  constructor($scope: ng.IScope) {
    super($scope);
  }

  $onInit(): void {
    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    this.triggerChangeIfKnowOrUnknowModel(changes);
    this.assignDefaultSelectValue(this.esModelOptions || DEFAULT_FILTERS);
  }
}

const LabelStatusMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: LabelStatusMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { LabelStatusMultiSelectGroupComponent };
