import { IComponentController } from 'angular';
import template from './beginning-premier-card.html?raw';
import style from './beginning-premier-card.module.scss';
import image from './beginning-premier-card.svg?svgo';

enum DefaultData {
  RightText = 'Got it',
  LeftText = 'See more plans',
  PeriodTitle = '6 Months',
  PeriodContent = '6 months',
  BillingDate = '-',
  DiscountRate = '50%',
}

class BeginningPremierCard implements IComponentController {
  style = style;
  esRightText = '';
  esLeftText = '';
  esPeriodTitle = '';
  esPeriodContent = '';
  esBillingDate = '';
  esDiscountRate = '';
  image: string = image;

  $onInit() {
    if (!this.esRightText) {
      this.esRightText = DefaultData.RightText;
    }

    if (!this.esLeftText) {
      this.esLeftText = DefaultData.LeftText;
    }

    if (!this.esPeriodTitle) {
      this.esPeriodTitle = DefaultData.PeriodTitle;
    }

    if (!this.esPeriodContent) {
      this.esPeriodContent = DefaultData.PeriodContent;
    }

    if (!this.esBillingDate) {
      this.esBillingDate = DefaultData.BillingDate;
    }

    if (!this.esDiscountRate) {
      this.esDiscountRate = DefaultData.DiscountRate;
    }
  }
}

const BeginningPremierCardComponent: ng.IComponentOptions = {
  controller: BeginningPremierCard,
  template,
  bindings: {
    esRightText: '<',
    esRightAction: '&',
    esLeftText: '<',
    esLeftAction: '&',
    esCloseAction: '&',
    esShowCloseButton: '<',
    esPeriodTitle: '<',
    esPeriodContent: '<',
    esBillingDate: '<',
    esDiscountRate: '<',
  },
};

export { BeginningPremierCardComponent };
