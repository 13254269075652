import {
  IRequestFeatureParams,
  IRequestFeaturePayload,
  IRequestFeatureResponse,
} from 'typings/request-feature';
import { RequestFeatureResource } from './request-feature.resource';

class RequestFeatureService {
  static $inject = ['RequestFeatureResource'];
  constructor(private RequestFeatureResource: RequestFeatureResource) {}

  submitRequest(
    params: IRequestFeatureParams,
    payload: IRequestFeaturePayload
  ): ng.IPromise<IRequestFeatureResponse> {
    return this.RequestFeatureResource.submitRequest(params, payload);
  }
}

export { RequestFeatureService };
