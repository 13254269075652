import React from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import ModalComponent from '@/components/ModalContainer';

function Wrapper() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ModalComponent />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

// Export as an Angular component using react2angular
export const AngularModalOpenManage = react2angular(Wrapper, [], []);
