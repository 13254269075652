import angular from 'angular';

import { AccountRoutes } from './index.routes';
import { CreditCardComponent } from './add-credit/credit-card/credit-card.component';
import './companyAccount';
import './profile';
import './billing';
import './addresses';
import subscriptionModule from './subscription';
import paymentModule from './payment';
import teamModule from './team';
import '@client/core/services/mixpanel';

angular
  .module('app.account', [
    'core.service.mixpanel',
    'core.components',
    'app.account.profile',
    'app.account.company',
    'app.account.billing',
    'app.account.addresses',
    subscriptionModule,
    paymentModule,
    teamModule,
  ])
  .config(AccountRoutes)
  .component('accountCreditCard', CreditCardComponent);
