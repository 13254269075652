import angular from 'angular';

import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import subscriptionService from '@client/src/global/services/subscription';

import { TrackingPageComponent } from './tracking-page.component';

const MODULE_NAME = 'app.tools.tracking-page';

angular
  .module(MODULE_NAME, [
    'core.service.mixpanel',
    'app.global.user',
    'app.factory.UserSession',
    'app.service.CompanyService',
    'core.components',
    onboardingChecklistService,
    subscriptionService,
  ])
  .component('trackingPage', TrackingPageComponent);

export default MODULE_NAME;
