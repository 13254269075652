import template from './table-head.html?raw';
import style from './table-head.module.scss';

class TableHead {
  constructor() {
    this.style = style;
  }
}

const TableHeadComponent = {
  template,
  controller: TableHead,
  bindings: {},
};

export { TableHeadComponent };
