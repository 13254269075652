import template from './box-card.html?raw';
import style from './box-card.module.scss';
import boxIcon from './box.svg?svgo';

class BoxCardController {
  static $inject = ['$sce', 'BoxService', 'UserSession'];

  constructor($sce, BoxService, UserSession) {
    this.$sce = $sce;
    this.BoxService = BoxService;
    this.UserSession = UserSession;

    this.style = style;
  }

  $onInit() {
    this.type = this.type || 'own';

    this.boxIcon = this.$sce.trustAsHtml(boxIcon);
  }

  getVolumeDisplayText(box) {
    const unit = this.UserSession.isCompanyUsingImperialDimensions() ? 'inch' : 'centimeter';
    this.UserSession.user?.dashboard_settings?.language?.code ?? 'en';
    const volumeWithUnit = new Intl.NumberFormat(locale, { style: 'unit', unit }).format(
      box.volume
    );
    return this.$sce.trustAsHtml(`${volumeWithUnit}<sup>3</sup>`);
  }

  getAutoSelectedFlatRateCount(boxes) {
    return boxes.filter((box) => {
      return box.auto_selected;
    }).length;
  }
}

const BoxCardComponent = {
  controller: BoxCardController,
  template,
  bindings: {
    type: '@',
    box: '<',
    onClick: '&',
  },
};

export { BoxCardComponent };
