import React, { lazy, Suspense, useRef } from 'react';
import { react2angular } from 'react2angular';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { SubscriptionServiceProvider } from '@client/src/global/context/SubscriptionProvider';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { ShipmentGatewayProvider } from '@client/src/manage-shipments/providers/ShipmentGatewayProvider';
import { Pulse } from 'easyship-components';
import { ShipmentDetailsDrawerProps } from './types';

const ShipmentDetailsDrawer = lazy(() => import('./ShipmentDetailsDrawer'));

function WrappedShipmentDetailsDrawer(props: ShipmentDetailsDrawerProps) {
  const { isOpen = false } = props || {};
  const refNeedToImportLazy = useRef(false);
  refNeedToImportLazy.current = refNeedToImportLazy.current || isOpen;
  const needToImportLazy = refNeedToImportLazy.current;
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <SubscriptionServiceProvider>
          <ShipmentGatewayProvider>
            {needToImportLazy && (
              <Suspense fallback={<Pulse />}>
                <ShipmentDetailsDrawer {...props} />
              </Suspense>
            )}
          </ShipmentGatewayProvider>
        </SubscriptionServiceProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularShipmentDetailsDrawer = react2angular(WrappedShipmentDetailsDrawer, [
  'shipmentId',
  'isOpen',
  'selectedSection',
  'hasPrev',
  'hasNext',
  'navigationDisabled',
  'onClickAway',
  'onClose',
  'onNavigation',
  'onFeatureUnavailable',
  'onRouteToCouriers',
  'onPrepareSingleShipment',
]);
