import { IAddressyResponse, IAddressyService } from 'typings/core/services/addressy';
import { IAddress, LoqateSuggestion } from 'typings/address';
import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import template from './addressy-input-form.html?raw';
import style from './addressy-input-form.style.module.scss';

class AddressyInputController implements IComponentController {
  style = style;
  esAddress?: IAddress;
  esAddressyInfo?: LoqateSuggestion;
  esAddressValid = false;
  esRequired = false;
  showDropdown = false;
  busy = {
    addressy: false,
  };
  error = {
    noAddresses: false,
  };
  addressesContainer = [] as IAddressyResponse[];

  static $inject = ['AddressyService', '$element', '$translate'];
  constructor(
    private AddressyService: IAddressyService,
    private $element: ng.IAugmentedJQuery,
    private $translate: angular.translate.ITranslateService
  ) {}

  searchForAddress(fullAddress: string, id = ''): void {
    this.busy.addressy = true;
    this.error.noAddresses = false;
    this.esAddressValid = false;

    this.AddressyService.getAddressyData(fullAddress, id)
      .then((suggestions) => {
        if (suggestions.length === 0 || suggestions[0].Error === '1005') {
          this.error.noAddresses = true;
          this.addressesContainer = [];
          return;
        }

        this.showDropdown = true;
        this.addressesContainer = suggestions;
      })
      .catch(() => {
        this.error.noAddresses = true;
        this.addressesContainer = [];
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.addressy = false;
      });
  }

  handleAddressClick(addressInfo: LoqateSuggestion, search: string): void {
    if (addressInfo.Type === 'Address') {
      this.assignAddressToAddressyInfo(addressInfo);
    } else {
      this.searchForAddress(search, addressInfo.Id);
      if (this.$element.find('#addressy-form-output').length > 0) {
        this.$element.find('#addressy-form-output')[0].scrollTop = 0;
      }
    }
  }

  handleOffClick(): void {
    this.showDropdown = false;
  }

  assignAddressToAddressyInfo(addressInfo: LoqateSuggestion): void {
    if (!this.esAddress) return;

    this.esAddressValid = true;
    this.esAddressyInfo = addressInfo;
    this.esAddress.fullAddress = `${addressInfo.Text} ${addressInfo.Description}`;
    this.esAddress.addressy_id = addressInfo.Id;

    this.showDropdown = false;
    this.addressesContainer = [];
  }
}

const AddressyInputForm: ng.IComponentOptions = {
  bindings: {
    esAddress: '=',
    esAddressyInfo: '=',
    esRequired: '@',
    esAddressValid: '=',
  },
  template,
  controller: AddressyInputController,
};

export { AddressyInputForm };
