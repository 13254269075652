import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './update-courier.html?raw';
import style from './update-courier.module.scss';
import { smartpostHubIDs, smartpostEndorsements } from './constants';

class UpdateCourier {
  static $inject = [
    '$state',
    '$translate',
    '$filter',
    'API',
    'CourierAccounts',
    'CourierDetails',
    'CourierService',
    'UserSession',
    'MixpanelService',
    'SubscriptionService',
    'AddressService',
  ];

  constructor(
    $state,
    $translate,
    $filter,
    API,
    CourierAccounts,
    CourierDetails,
    CourierService,
    UserSession,
    MixpanelService,
    SubscriptionService,
    AddressService
  ) {
    this.style = style;
    this.$state = $state;
    this.$filter = $filter;
    this.$translate = $translate;
    this.API = API;
    this.CourierAccounts = CourierAccounts;
    this.CourierDetails = CourierDetails;
    this.CourierService = CourierService;
    this.UserSession = UserSession;
    this.MixpanelService = MixpanelService;
    this.SubscriptionService = SubscriptionService;
    this.AddressService = AddressService;

    this.smartpostHubIDs = smartpostHubIDs;
    this.smartpostEndorsements = smartpostEndorsements;
    this.settings = {
      courier_settings: {},
    };
    this.account = {};
    this.busy = {
      gettingCourierInfo: true,
      updatingCourierInfo: false,
      updatingCourierSettings: false,
      updatingEtdSettings: false,
      updatingSmartPostSettings: false,
      uploadingLetterhead: false,
      uploadingSignature: false,
      deletingLetterhead: false,
      deletingSignature: false,
    };
    this.AddressService.hasSenderAddressInCountry('US').then((hasUsAddress) => {
      this.hasUsAddress = hasUsAddress;
    });
    this.translations = {
      settings: this.$translate.instant('global.setting').toLowerCase(),
    };
  }

  $onInit() {
    this._getCourierAccountDetails();
    this._setupSettingsCurrencyInput();

    if (!this.SubscriptionService.currentSubscription) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: this.UserSession.company.id,
      });
    }

    if (!this.SubscriptionService.plansDetail) {
      this.SubscriptionService.fetchPlansDetail({
        country_id: this.UserSession.company.country_id,
      });
    }
    this.wrappers = {
      resendFedexInstructions: {
        'resend-instructions': (chunk) => {
          return `<a ng-click="$ctrl.resendFedexInstructions()">${chunk}</a>`;
        },
      },
      royalMail: {
        suggestions: {
          'go-to-automations': (chunk) => {
            return `<a ui-sref="automations">${chunk}</a>`;
          },
        },
        contact: {
          'go-to-contact': (chunk) => {
            return `<a ng-href="{{$ctrl.API.homepage}}/contact" target="_blank">${chunk}</a>`;
          },
        },
      },
    };
  }

  _getCourierAccountDetails() {
    this.CourierAccounts.getCourierInfo(this.courier)
      .then(
        ({
          account,
          couriers,
          settings,
          accountStatePillAttributes,
          is_active,
          brokers,
          fedex_etd_letterhead,
          fedex_etd_signature,
        }) => {
          this.couriers = couriers;
          this.accountStatePillAttributes = accountStatePillAttributes;
          this.isActive = is_active;

          if (this.courier.type === 'company') {
            if (this.courier.umbrella_name === 'FedEx') {
              this.CourierDetails.fedexBrokers = brokers.filter((broker) => broker.is_active);
              this.etd = {
                fedex_etd_letterhead,
                fedex_etd_signature,
              };
              this._buildUploadEtdEndpointUrl(this.courier.id);
            }
            this.account = account;
            this.settings = this._getSettingsDetails(settings);
            this.ddpFee = this.$filter('intlCurrency')(
              settings.retail_ddp_handling_fee,
              this.UserSession.getCompanyCurrency()
            ).replace(/\s/, ' ');
          }
        }
      )
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.gettingCourierInfo = false;
      });
  }

  _buildUploadEtdEndpointUrl(courierId) {
    const endpointUrl = `companies/${this.UserSession.company.id}/courier_accounts/${courierId}/upload_asset?type=`;
    this.letterheadUrl = `${this.API.endpoint}/${endpointUrl}fedex_etd_letterhead`;
    this.signatureUrl = `${this.API.endpoint}/${endpointUrl}fedex_etd_signature`;
  }

  _getSettingsDetails({ pickup_fee = 0, ddp_handling_fee = 0, courier_settings }) {
    return {
      pickup_fee: pickup_fee.toString(),
      ddp_handling_fee: ddp_handling_fee.toString(),
      courier_settings,
    };
  }

  _setupSettingsCurrencyInput() {
    this.amountValidation = {
      maxDigits: 8,
      maxDecimals: 2,
      allowNegative: false,
      showThousandSeperator: true,
    };
  }

  _letterheadSignatureUpdate(data, imageKey) {
    const courierAccount = {
      settings: this.settings,
      [imageKey]: {
        asset_id: null,
        asset_url: data.data.asset_url,
        confirm_asset_id: data.data.asset_id,
        delete_asset_id: null,
      },
    };

    this.CourierAccounts.updateCourierAccount({ id: this.courier.id, courierAccount })
      .then((response) => {
        this.etd = {
          fedex_etd_letterhead: response.fedex_etd_letterhead,
          fedex_etd_signature: response.fedex_etd_signature,
        };
      })
      .catch((error) => {
        this._toastError(error);
      })
      .finally(() => {
        if (imageKey === 'fedex_etd_letterhead') {
          this.busy.uploadingLetterhead = false;
        } else {
          this.busy.uploadingSignature = false;
        }
      });
  }

  _letterheadSignatureDelete(imageKey) {
    const courierAccount = {
      settings: this.settings,
      [imageKey]: {
        asset_id: this.etd[imageKey].asset_id,
        asset_url: null,
        confirm_asset_id: null,
        delete_asset_id: this.etd[imageKey].asset_id,
      },
    };

    this.CourierAccounts.updateCourierAccount({ id: this.courier.id, courierAccount })
      .then((response) => {
        this.etd = {
          fedex_etd_letterhead: response.fedex_etd_letterhead,
          fedex_etd_signature: response.fedex_etd_signature,
        };

        if (!this.settings.courier_settings.electronic_invoices_enabled) return;

        this.settings.courier_settings.electronic_invoices_enabled = false;

        this.busy.updatingEtdSettings = true;

        this.CourierAccounts.updateCourierSettings({
          id: this.courier.id,
          settings: this.settings,
        })
          .then(() => {
            toastSuccess(this.$translate.instant('courier.connect.form.etd-off'));
          })
          .catch((error) => {
            this._toastError(error);
          })
          .finally(() => {
            this.busy.updatingEtdSettings = false;
          });
      })
      .catch((error) => {
        this._toastError(error);
      })
      .finally(() => {
        this.busy.deletingLetterhead = false;
        this.busy.deletingSignature = false;
      });
  }

  _toastError(error) {
    if (error.data && error.data.status) {
      toastError(error.data && error.data.status);
    } else {
      toastError(this.$translate.instant('toast.default-error'));
    }
  }

  showSettings(courier) {
    const courierToHideSetting = ['USPS', 'Australia Post MyPost Business'];
    return !courierToHideSetting.includes(courier.umbrella_name);
  }

  showPassword(courier) {
    const courierToHidePassword = [
      'USPS',
      'SkyPostal',
      'Royal Mail',
      'UPS',
      'Postage Force',
      'Canada Post',
      'Australia Post MyPost Business',
      'DHL',
      'Passport',
    ];

    return !courierToHidePassword.includes(courier.umbrella_name);
  }

  hasShippingRulesUserRole() {
    return this.UserSession.hasUserRole('shipping_rules');
  }

  hasPendingCertification(courier = {}) {
    return (
      courier.auth_state === 'partially_verified' &&
      courier.account_state === 'pending' &&
      ['FedEx', 'Royal Mail'].includes(courier.umbrella_name)
    );
  }

  isEasyshipCourierAndNoPendingCertification() {
    return this.courier.type === 'easyship' && !this.hasPendingCertification(this.courier);
  }

  isCompanyCourierAndNoPendingCertification() {
    return this.courier.type === 'company' && !this.hasPendingCertification(this.courier);
  }

  onShippingRulesClick() {
    this.MixpanelService.track('Courier - Service - Click Shipping Rules');
    this.CourierDetails.close();
    this.$state.go('automations');
  }

  disableAccountForm() {
    return this.accountForm.$pristine || this.accountForm.$invalid || this.busy.updatingCourierInfo;
  }

  onControlsChange(value, key) {
    this.account[key] = value;
  }

  onSettingsControlsChange(value, key) {
    this.settings[key] = value;
  }

  onCourierSettingsChange(value, key) {
    this.settings.courier_settings[key] = value;

    if (!['smart_post_enabled', 'electronic_invoices_enabled'].includes(key)) return;

    this.busy.updatingEtdSettings = true;

    this.CourierAccounts.updateCourierSettings({ id: this.courier.id, settings: this.settings })
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.settings },
            'messageformat'
          )
        );

        const mixpanelMessage = `Courier - ${
          key === 'electronic_invoices_enabled' ? 'ETD' : 'SmartPost'
        } - Toggle`;
        this.MixpanelService.track(mixpanelMessage, {
          courier_name: this.courier.umbrella_name,
        });
      })
      .catch((error) => {
        this._toastError(error);
      })
      .finally(() => {
        this.busy.updatingEtdSettings = false;
      });
  }

  onSmartPostSettingsChange(value, key) {
    this.account[key] = value;

    this.busy.updatingSmartPostSettings = true;

    const shortAccount = this._getAccountUpdateApiModel();

    this.CourierAccounts.updateCourierInfo({ id: this.courier.id, account: shortAccount })
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.settings },
            'messageformat'
          )
        );

        this.MixpanelService.track('Courier - SmartPost - Update Saved', {
          courier_name: this.courier.umbrella_name,
        });
      })
      .catch((error) => {
        this._toastError(error);
      })
      .finally(() => {
        this.busy.updatingSmartPostSettings = false;
      });
  }

  onSubmitAccountForm() {
    const account = this._formatUpdateAccountPayload(this.account);
    const payload = { id: this.courier.id, account };

    this.busy.updatingCourierInfo = true;

    this.CourierAccounts.updateCourierInfo(payload)
      .then((response) => {
        const { account: courierAccount } = response;
        this.courier.nickname = courierAccount && courierAccount.nickname;
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.settings },
            'messageformat'
          )
        );
        this.CourierService.getCouriers({ refetch: true });
        this.accountForm.$setPristine();

        this.MixpanelService.track('Courier - Service - Update Saved', {
          courier_name: this.courier.umbrella_name,
        });
      })
      .catch((error) => {
        this._toastError(error);
      })
      .finally(() => {
        this.busy.updatingCourierInfo = false;
      });
  }

  _formatUpdateAccountPayload(account) {
    if (!account.password) return { nickname: account.nickname };

    return this._getAccountUpdateApiModel();
  }

  disableSettingsForm() {
    return this.busy.updatingCourierSettings;
  }

  onSubmitSettingsForm() {
    const payload = { id: this.courier.id, settings: this.settings };

    this.busy.updatingCourierSettings = true;

    this.CourierAccounts.updateCourierSettings(payload)
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.settings },
            'messageformat'
          )
        );
        this.settingsForm.$setPristine();

        this.MixpanelService.track('Courier - Service - Update Saved', {
          courier_name: this.courier.umbrella_name,
        });
      })
      .catch((error) => {
        this._toastError(error);
      })
      .finally(() => {
        this.busy.updatingCourierSettings = false;
      });
  }

  resendFedexInstructions() {
    const payload = { id: this.courier.id };

    this.CourierAccounts.sendNextStepsEmail(payload)
      .then(() => {
        toastSuccess(
          this.$translate.instant('toast.email-file', {
            email: this.UserSession.user.email,
          })
        );
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  chooseBroker(broker) {
    this.CourierDetails.selectedBroker = broker;
  }

  addFedexBrokerModal() {
    this.CourierDetails.fedexBrokerFormShow = 'create-fedex-broker';
  }

  onStartUploadImage(key) {
    if (key === 'fedex_etd_letterhead') {
      this.busy.uploadingLetterhead = true;
    } else {
      this.busy.uploadingSignature = true;
    }
  }

  onFinishUploadLetterhead(error, response) {
    if (error) {
      this.busy.uploadingLetterhead = false;
      this._toastError(error);
    } else {
      this._letterheadSignatureUpdate(response, 'fedex_etd_letterhead');
    }

    this.MixpanelService.track('Company Courier Update - ETD - Upload Letterhead');
  }

  onFinishUploadSignature(error, response) {
    if (error) {
      this.busy.uploadingSignature = false;
      this._toastError(error);
    } else {
      this._letterheadSignatureUpdate(response, 'fedex_etd_signature');
    }

    this.MixpanelService.track('Company Courier Update - ETD - Upload Signature');
  }

  onDelete(key) {
    if (key === 'fedex_etd_letterhead') {
      this.busy.deletingLetterhead = true;
    } else {
      this.busy.deletingSignature = true;
    }

    this._letterheadSignatureDelete(key);
    const imageName = key === 'fedex_etd_letterhead' ? 'Letterhead' : 'Signature';
    this.MixpanelService.track(`Company Courier Update - ETD - Delete ${imageName}`);
  }

  isFedexGroundEconomySectionVisible() {
    return this.isCourierFedex() && this.isCourierCountryUS();
  }

  isCourierFedex() {
    return this.courier.umbrella_name === 'FedEx';
  }

  isCourierCountryUS() {
    return this.courier.country_alpha2 === 'US';
  }

  getFedexETDMaxFileSizeText() {
    const textId = this.UserSession.isNewFedexLyocEnabled()
      ? 'courier.update.etd.max-file-size-for-2024-lyoc-flow'
      : 'courier.update.etd.max-file-size';

    return this.$translate.instant(textId);
  }

  getFedexETDMaxFileSize() {
    const newMaxSizeInMB = 5;
    const oldMaxSizeInMB = 0.03;
    const size = this.UserSession.isNewFedexLyocEnabled() ? newMaxSizeInMB : oldMaxSizeInMB;

    return size;
  }

  _getAccountUpdateApiModel = () => {
    return {
      nickname: this.account.nickname,
      smart_post_hub_id: this.account.smart_post_hub_id,
      smart_post_endorsement: this.account.smart_post_endorsement,
    };
  };

  get planName() {
    return this.SubscriptionService.getPlanNameByFeatureKey('lyoc');
  }
}

const UpdateCourierComponent = {
  controller: UpdateCourier,
  template,
  bindings: {
    courier: '<',
    toDelete: '&',
  },
};

export { UpdateCourierComponent };
