import angular from 'angular';
import { IUserRole } from 'typings/user-session';
import { UserRolePort, UserRoleRequest } from '@client/src/account/team/gateways/ports/userRole';
import { UserRoleResource } from '@client/src/global/services/user-role/user-role.resource';

export default class NgUserRolesGateway implements UserRolePort {
  private userRoleApi: UserRoleResource;

  constructor() {
    this.userRoleApi = angular.element(document.body).injector().get('UserRoleResource');
  }

  getUserRoles({ userId, companyId }: Omit<UserRoleRequest, 'userRoles'>): Promise<IUserRole[]> {
    return new Promise((resolve, reject) => {
      this.userRoleApi.resource
        .get({ user_id: userId, company_id: companyId })
        .$promise.then((response: { user_roles: IUserRole[] }) => {
          resolve(response.user_roles);
        })
        .catch(reject);
    });
  }

  updateUserRoles({ userId, companyId, userRoles }: UserRoleRequest): Promise<void> {
    return this.userRoleApi.resource.update(
      {
        user_id: userId,
        company_id: companyId,
      },
      {
        user_roles: userRoles,
      }
    ).$promise;
  }
}
