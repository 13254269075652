import React, { ReactElement, useState } from 'react';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { react2angular } from 'react2angular';
import uberFreightImageUrl from '@assets/images/logos/platform-logo-full/uber-freight.svg';
import ReactRootProviders from '../../providers/ReactRootProviders';
import useRecaptcha from '../../hooks/useRecaptcha';
import { ForgetPasswordForm } from '../../platforms/uber-freight/ForgetPasswordForm';

export function UberFreightForgetPasswordPage() {
  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  function handleResetPasswordSuccess(email: string): void {
    setIsResetSuccess(true);
    setUserEmail(email);
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-[446px] grid grid-cols-1 gap-y-5 justify-items-center">
        <img className="w-[266px]" src={uberFreightImageUrl} alt="Uber Freight Logo" />
        {isResetSuccess ? (
          <span>
            <FormattedMessage id="login.reset-password.sent" values={{ email: userEmail }} />
          </span>
        ) : (
          <>
            <div className="w-[446px]">
              <ForgetPasswordForm
                onResetPasswordError={fetchAndSetRecaptcha}
                recaptchaToken={recaptchaToken}
                onResetPasswordSuccess={handleResetPasswordSuccess}
              />
            </div>
            <div className="w-full text-left">
              <span>
                No Account Yet?{' '}
                <Link href="/uber-freight/signup">
                  <FormattedMessage id="login.get-started" />
                </Link>{' '}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function WrapperUberFreightForgetPasswordPage(): ReactElement {
  return (
    <ReactRootProviders>
      <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_KEY_RECAPTCHA ?? ''}>
        <UberFreightForgetPasswordPage />
      </GoogleReCaptchaProvider>
    </ReactRootProviders>
  );
}
export const AngularUberFreightForgetPasswordPage = react2angular(
  WrapperUberFreightForgetPasswordPage,
  []
);
