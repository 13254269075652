import { IUserSession } from 'typings/user-session';
import { IShipmentGetPicklistPayload, IShipmentGetPicklistSuccessResponse } from 'typings/shipment';
import { ShipmentListResource } from '../shipment-list/shipment-list.resource';

class PicklistService {
  static $inject = ['UserSession', 'ShipmentListResource'];
  constructor(
    private UserSession: IUserSession,
    private ShipmentListResource: ShipmentListResource
  ) {}

  getPicklist(
    payload: IShipmentGetPicklistPayload
  ): ng.IPromise<IShipmentGetPicklistSuccessResponse> {
    const companyId = this.UserSession.getCompanyId() as string;
    const params = {
      company_id: companyId,
      company_type: 'cloud',
    };

    return this.ShipmentListResource.getPicklist(params, payload);
  }
}

export { PicklistService };
