import React from 'react';
import { FormattedMessage } from 'react-intl';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper, { PopperProps } from '@client/core/components/react/Popper';

interface EditorVersionPopperProps extends Omit<PopperProps, 'children'> {
  onFeedbackSelection: () => void;
  onSwitchBack: () => void;
}

export default function EditorVersionPopper({
  onFeedbackSelection,
  onSwitchBack,
  ...props
}: EditorVersionPopperProps): JSX.Element {
  return (
    <Popper noPadding transition disablePortal style={{ width: 185 }} {...props}>
      <MenuList autoFocusItem disableListWrap variant="menu">
        <MenuItem divider disableGutters disabled style={{ opacity: 1, pointerEvents: 'all' }}>
          <div className="text-xs text-ink-500">
            <FormattedMessage id="dashboard-header.shipments.viewer-selector.description" />
            <a
              href={`${import.meta.env.VITE_APP_HELP}/hc/en-us/articles/4417161482253`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="global.learn-more" />
            </a>
          </div>
        </MenuItem>
        <MenuItem disableGutters onClick={onFeedbackSelection}>
          <FormattedMessage id="dashboard-header.shipments.viewer-selector.give-feedback" />
        </MenuItem>
        {/* TODO: Remove the classic shipment related components(DASH-565) */}
        {/* <MenuItem disableGutters onClick={onSwitchBack}>
          <FormattedMessage id="dashboard-header.shipments.viewer-selector.switch-back" />
        </MenuItem> */}
      </MenuList>
    </Popper>
  );
}
