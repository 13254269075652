import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './add-remove-statuses.html?raw';
import style from './add-remove-statuses.module.scss';

class AddRemoveStatusesController {
  static $inject = ['$rootScope', '$translate', 'StoreService', 'MixpanelService'];

  constructor($rootScope, $translate, StoreService, MixpanelService) {
    this.$rootScope = $rootScope;
    this.$translate = $translate;
    this.StoreService = StoreService;
    this.MixpanelService = MixpanelService;

    this.style = style;
    this.loading = false;
    this.form = {
      'Fulfilment Statuses': { options: [] },
      'Payment Statuses': { options: [] },
    };
  }

  prepareForm = () => {
    this.form['Fulfilment Statuses'].options = Object.entries(
      this.StoreService.activeStore.available_fetch_statuses.fulfillment_statuses
        ? this.StoreService.activeStore.available_fetch_statuses.fulfillment_statuses.options
        : []
    ).map(([id, name]) => ({
      id,
      name,
      isDisplay: true,
      selected: this.StoreService.activeStore.fulfillment_statuses_to_fetch.includes(id),
    }));
    this.form['Fulfilment Statuses'].selected = this.form['Fulfilment Statuses'].options.every(
      ({ selected }) => selected
    );

    this.form['Payment Statuses'].options = Object.entries(
      this.StoreService.activeStore.available_fetch_statuses.payment_statuses
        ? this.StoreService.activeStore.available_fetch_statuses.payment_statuses.options
        : []
    ).map(([id, name]) => ({
      id,
      name,
      isDisplay: true,
      selected: this.StoreService.activeStore.payment_statuses_to_fetch.includes(id),
    }));
    this.form['Payment Statuses'].selected = this.form['Payment Statuses'].options.every(
      ({ selected }) => selected
    );
  };

  getSelectedIds = (selected, option) => {
    if (option.selected) selected.push(option.id);
    return selected;
  };

  update = (storePatch, successMessageId) => {
    this.loading = true;

    this.MixpanelService.track(`Store - Update Sync Settings`, { setting: 'Statuses' });

    this.StoreService.updateStore(storePatch)
      .then(() => {
        this.prepareForm();
        toastSuccess(this.$translate.instant(successMessageId));
        this.$rootScope.$broadcast('store-synced-statuses-updated');
        this.close();
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading = false;
      });
  };

  $onInit() {
    this.prepareForm();
  }

  reset() {
    const fulfillmentStatuses =
      this.StoreService.activeStore.available_fetch_statuses.fulfillment_statuses;
    const paymentStatuses = this.StoreService.activeStore.available_fetch_statuses.payment_statuses;

    const fulfillmentDefaults = fulfillmentStatuses?.default_values || null;
    const paymentDefaults = paymentStatuses?.default_values || null;

    const storePatch = {
      // ...this.StoreService.activeStore,
      id: this.StoreService.activeStore.id,
      fulfillment_statuses_to_fetch: fulfillmentDefaults,
      payment_statuses_to_fetch: paymentDefaults,
    };
    this.update(storePatch, 'store.notifications.status-sync-reset');
  }

  onSubmit() {
    const storePatch = {
      // ...this.StoreService.activeStore,
      id: this.StoreService.activeStore.id,
      fulfillment_statuses_to_fetch: this.form['Fulfilment Statuses'].options.reduce(
        this.getSelectedIds,
        []
      ),
      payment_statuses_to_fetch: this.form['Payment Statuses'].options.reduce(
        this.getSelectedIds,
        []
      ),
    };
    this.update(storePatch, 'store.notifications.status-sync-updated');
  }

  get atLeastOneEach() {
    return (
      (this.form['Fulfilment Statuses'].options.some(({ selected }) => selected) ||
        this.form['Fulfilment Statuses'].options.length === 0) &&
      (this.form['Payment Statuses'].options.some(({ selected }) => selected) ||
        this.form['Payment Statuses'].options.length === 0)
    );
  }
}

const AddRemoveStatusesComponent = {
  template,
  controller: AddRemoveStatusesController,
  bindings: {
    close: '&',
  },
};

export { AddRemoveStatusesComponent };
