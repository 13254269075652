import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { EasyshipLocale } from '@client/core/corelogic/models/EasyshipLocale';
import { theme } from '@client/src/theme';
import { Toastify } from '@client/core/components/react/Toastify';
import QueryClientProvider from '@client/src/global/context/QueryClientProvider';
import { AuthGatewayProvider } from './AuthGatewayProvider';
import I18nProvider from './I18nProvider';

interface ReactRootProvidersProps {
  selectedLocale?: EasyshipLocale;
  children: React.ReactNode;
}

export default function ReactRootProviders({ children, selectedLocale }: ReactRootProvidersProps) {
  return (
    <ThemeProvider theme={theme}>
      <AuthGatewayProvider>
        <QueryClientProvider>
          <I18nProvider selectedLocale={selectedLocale}>
            {children}

            {/* NOTE: currently the Toastify is registered by <es-toastify> in app.js */}
            {/* <Toastify /> */}
          </I18nProvider>
        </QueryClientProvider>
      </AuthGatewayProvider>
    </ThemeProvider>
  );
}
