import React, { ReactElement, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { react2angular } from 'react2angular';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import uberFreightImageUrl from '@assets/images/logos/platform-logo-full/uber-freight.svg';
import useRecaptcha from '../../hooks/useRecaptcha';
import { LoginForm } from '../../platforms/uber-freight/LoginForm';
import ReactRootProviders from '../../providers/ReactRootProviders';

let didInit = false;
export function UberFreightLoginPage() {
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      MixpanelService.track('Login Page - Uber Freight - Arrived');
    }
  }, []);

  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();

  function handleOnLoginError(): void {
    fetchAndSetRecaptcha();
    MixpanelService.track('Login Page - Uber Freight - Login Failed');
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-[446px] flex flex-col items-center">
        <img className="mb-10 w-[266px]" src={uberFreightImageUrl} alt="Uber Freight Logo" />
        <div className="w-[446px] mb-5">
          <LoginForm onLoginError={handleOnLoginError} recaptchaToken={recaptchaToken} />
        </div>
        <div className="w-full text-left">
          <span>
            No Account Yet?{' '}
            <Link href="/uber-freight/signup">
              <FormattedMessage id="login.get-started" />
            </Link>{' '}
          </span>
        </div>
      </div>
    </div>
  );
}

export function WrappedUberFreightLoginPage(): ReactElement {
  return (
    <ReactRootProviders>
      <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_KEY_RECAPTCHA ?? ''}>
        <UberFreightLoginPage />
      </GoogleReCaptchaProvider>
    </ReactRootProviders>
  );
}
export const AngularUberFreightLoginPage = react2angular(WrappedUberFreightLoginPage, []);
