import _ from 'lodash';
import moment from 'moment';
import { IUserSession } from 'typings/user-session';
import { IApiConfig } from 'typings/core/config/api';
import { ICompanyService } from 'typings/company';
import {
  ICreditCardData,
  IPaymentSourcePayResult,
  IPaymentSourceResponse,
  IPaymentSourceResult,
  ICreditCardPaymentParams,
  ICreditCardUpdatePayload,
  ICreditCardResponse,
} from 'typings/payment-source';
import { PaymentResource } from '@client/src/global/transaction-record/payment.resource';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError } from '@client/core/components/react/Toastify';

enum Platform {
  PayPal = 'PayPal',
}

class PaymentSourceService {
  creditCards: ICreditCardData[] = [];
  selectedCard: ICreditCardData | null = null;
  reloadingCards = false;
  paymentAmount: number | null = null;
  shipmentIds: string[] | null = null;
  saveCreditCard = false;
  platform = Platform;
  translations: Record<string, string> = {};
  isAchEnabled = false;

  static $inject = [
    '$q',
    '$timeout',
    '$translate',
    'PaymentSource',
    'UserSession',
    'StripeService',
    'PaymentIntentService',
    '$state',
    'PaymentResource',
    'API',
    'MixpanelService',
    'CompanyService',
  ];
  constructor(
    private $q: ng.IQService,
    private $timeout: ng.ITimeoutService,
    private $translate: angular.translate.ITranslateService,
    private PaymentSource: any,
    private UserSession: IUserSession,
    private StripeService: any,
    private PaymentIntentService: any,
    private $state: ng.ui.IStateService,
    private PaymentResource: PaymentResource,
    private API: IApiConfig,
    private MixpanelService: MixpanelService,
    private CompanyService: ICompanyService
  ) {
    this.translations = {
      card: this.$translate
        .instant('global.pluralize.credit-card', { COUNT: 1 }, 'messageformat')
        .toLowerCase(),
      cards: this.$translate
        .instant('global.pluralize.credit-card', { COUNT: 2 }, 'messageformat')
        .toLowerCase(),
    };
  }

  get haveValidPaymentSource(): boolean {
    if (!this.creditCards || this.creditCards.length <= 0) {
      return false;
    }

    return this.creditCards.some((card) => !card.expired);
  }

  /**
   * [getCreditCards] Gets the all the credit card associated with the current company
   * @return {Array} [Returns an array of credit card details]
   */
  getCreditCards(): ng.IPromise<IPaymentSourceResult> {
    this.translations = {
      card: this.$translate
        .instant('global.pluralize.credit-card', { COUNT: 1 }, 'messageformat')
        .toLowerCase(),
      cards: this.$translate
        .instant('global.pluralize.credit-card', { COUNT: 2 }, 'messageformat')
        .toLowerCase(),
    };

    // Always empty the creditCards array before fetching by security and to avoid glitches in the FE
    this.creditCards = [];

    return this.$q((resolve, reject) => {
      this.PaymentSource.query(
        {
          company_id: this.UserSession.company.id,
        },
        (res: IPaymentSourceResponse) => {
          this.isAchEnabled = res.ach_enabled ?? false;
          this.creditCards = res.payment_sources;

          if (this.creditCards.length === 0) {
            this.selectedCard = null;
          }

          this.creditCards = this._markIfExpired(this.creditCards);

          this._markCardAsSelected(this.creditCards);

          resolve({
            isAchEnabled: this.isAchEnabled,
          });
        },
        (err: unknown) => {
          toastError(
            this.$translate.instant('toast.fetch-error', { noun: this.translations.cards })
          );
          reject(err);
        }
      );
    });
  }

  getCreditCard(cardId: string): ng.IPromise<ICreditCardResponse> {
    return this.$q((resolve, reject) => {
      this.PaymentSource.query(
        {
          company_id: this.UserSession.company.id,
          id: cardId,
        },
        (res: ICreditCardResponse) => {
          resolve(res);
        },
        (err: unknown) => {
          toastError(
            this.$translate.instant('toast.fetch-error', { noun: this.translations.card })
          );
          reject(err);
        }
      );
    });
  }

  /**
   * [selectCard] Selects the card to be used for payment
   * @param  {Object} card [Passes the card object and sets it to be the current active one]
   * @return {N/A}
   */
  selectCard(card: ICreditCardData | null): ng.IPromise<void> {
    return this.$q((resolve, reject) => {
      if (card && card.expired) {
        reject();
      } else {
        this.selectedCard = card || null;
        resolve();
      }
    });
  }

  getDefaultCard(): ICreditCardData | undefined | object {
    if (!this.creditCards || !this.creditCards.length) {
      return {};
    }

    return this.creditCards.find((card) => {
      return card.default_for_shipments;
    });
  }

  /**
   * [addCard] Push a card into the creditCards array and sort them by creation date
   * @param {Object} paymentSource: credit card to push
   * @return {[N/A]}
   */
  addCard(paymentSource: ICreditCardData): void {
    const existingCardIndex = _.findIndex(this.creditCards, { id: paymentSource.id });

    if (existingCardIndex > -1) {
      this.reloadingCards = true;
      this.creditCards.splice(existingCardIndex, 1);
      this.creditCards.splice(existingCardIndex, 0, paymentSource);

      this.$timeout(() => {
        this.reloadingCards = false;
      }, 1000);
    } else {
      this.creditCards.push(paymentSource);
      this.creditCards = _.sortBy(this.creditCards, 'created_at').reverse();
    }
  }

  findCardBy(key: keyof ICreditCardData, value: any): ICreditCardData | undefined | object {
    if (!this.creditCards.length) {
      return {};
    }

    return this.creditCards.find((e) => e[key] === value);
  }

  updateCard(cardId: string, payload: ICreditCardUpdatePayload): ng.IPromise<ICreditCardResponse> {
    return this.$q((resolve, reject) => {
      this.PaymentSource.update(
        {
          company_id: this.UserSession.company.id,
          id: cardId,
        },
        payload,
        (res: ICreditCardResponse) => {
          resolve(res);
        },
        (err: unknown) => {
          toastError(
            this.$translate.instant('toast.update-error', { noun: this.translations.card })
          );
          reject(err);
        }
      );
    });
  }

  /**
   * [deactivatePaymentSource]
   * @param  {[String]} paymentSourceId [The id of the payment_source card object]
   * @param  {[Object]} payment_source    [The payload that is being sent]
   * @return {[N/A]}
   */
  deactivatePaymentSource(paymentSourceId: string): ng.IPromise<any> {
    this.selectedCard = null;

    return this.$q((resolve, reject) => {
      this.PaymentSource.deactivate(
        {
          company_id: this.UserSession.company.id,
          id: paymentSourceId,
        },
        {},
        (res: ICreditCardData) => {
          this._removeCardFromArray(paymentSourceId);

          this._markCardAsSelected(this.creditCards);

          resolve(res);
        },
        (err: unknown) => {
          this._markCardAsSelected(this.creditCards);
          toastError(
            this.$translate.instant('toast.delete-error', { noun: this.translations.card })
          );
          reject(err);
        }
      );
    });
  }

  async pay(
    params: ICreditCardPaymentParams = { paymentMethod: {} }
  ): Promise<IPaymentSourcePayResult> {
    try {
      const stripe = await this.StripeService.getInstance();

      if (!stripe || (!params.source_id && !params.paymentMethod)) {
        return {
          valid: false,
        };
      }

      // 1. create a payment intent in the backend
      const createPaymentIntentResult = await this.PaymentIntentService.createPaymentIntent({
        payment_method_id: params.source_id || (params.paymentMethod && params.paymentMethod.id),
        amount: this.paymentAmount,
        shipment_ids: this.shipmentIds,
        origin_page: this._getPageName(this.$state.current.name),
        setup_future_usage: this.saveCreditCard ? 'off_session' : null,
      });

      // 1.5. Many transactions are immediately approved in the first step
      if (!createPaymentIntentResult.requires_action) {
        return { valid: true, paymentMethod: params.paymentMethod };
      }

      // 2. use handleCardAction from Stripe.js to detect fraud and trigger Radar and 3D Secure
      const handleCardResult = await stripe.handleCardAction(
        createPaymentIntentResult.payment_intent_client_secret
      );
      if (handleCardResult.error) throw handleCardResult.error;

      // 3. Proceed with the payment
      return (
        this.PaymentIntentService.confirmPaymentIntent({
          payment_intent_id: handleCardResult.paymentIntent?.id,
          origin_page: this._getPageName(this.$state.current.name),
        })
          // 4. Give success response
          .then((result: unknown) => ({
            result,
            valid: true,
            paymentMethod: params.paymentMethod,
          }))
          .finally(() => {
            this.paymentAmount = null;
            this.shipmentIds = null;
          })
      );
    } catch (e) {
      this.CompanyService.getCurrentStatus().then((res) => {
        if (res.company.require_admin_contact) {
          this.CompanyService.showContactAdminModal = true;
        }
      });

      throw e;
    }
  }

  /**
   * [_removeCardFromArray description]
   * @param  {String} paymentSourceId [ID of the card object]
   * @return {N/A}
   */
  _removeCardFromArray(paymentSourceId: string): void {
    const cardIndex = _.findIndex(this.creditCards, { source_id: paymentSourceId });
    this.creditCards.splice(cardIndex, 1);
  }

  /**
   * [_removeExpired]  Removes all cards that are expired from the array
   * @param  {Array} cards [Array of cards associated with the company]
   * @return {Array}       [All active cards]
   */
  _removeExpired(cards: ICreditCardData[]): ICreditCardData[] {
    return _.reject(cards, 'expired');
  }

  /**
   * [_markCardAsSelected]
   * @param  {Array} cards [Array of all remaining active cards]
   * @return {N/A}
   */
  _markCardAsSelected(cards: ICreditCardData[]): undefined {
    if (cards.length === 0) {
      this.selectedCard = null;
      return;
    }

    const defaultCard = _.find(this._removeExpired(cards), 'default_for_shipments');

    this.selectedCard = defaultCard || cards[0];
  }

  /**
   * [markIfExpired]  checks to see if any of the cards are expired and marks them
   * @param  {Array}  cards [An array of the cards associated with the company]
   * @return {Array}        [Returns an array of cards with the ones that are expired marked]
   */
  _markIfExpired(cards: ICreditCardData[]): ICreditCardData[] {
    return _.map(cards, this._compareDates);
  }

  /**
   * [compareDates]   Compares the current date in YYYY MM against the expiry date of the card
   * @param  {Object} card [Pass the card object for comparison]
   * @return {N/A}
   */
  _compareDates(card: ICreditCardData): ICreditCardData {
    const today = moment(new Date()).format('YYYY MM');
    const expDate = moment(`${card.exp_year} ${card.exp_month}`, 'YYYY MM').format('YYYY MM');
    if (today > expDate) {
      // eslint-disable-next-line no-param-reassign
      card.expired = true;
    }

    return card;
  }

  _getPageName(stateName?: string): string {
    switch (stateName) {
      // when users buy shipment in the basic flow
      case 'order-summary':
        return 'Basic Shipment';

      // when users buy shipment in the advanced flow
      case 'app.order-summary':
        return 'Advanced Shipment';

      // when users create a return label on the shipments page
      case 'app.shipments':
        return 'Manage Shipments';

      // when users add credit on the billing page
      case 'app.account.payment':
        return 'Payment Methods';

      // unknown page
      default:
        return 'Billing';
    }
  }

  // NOTE: Used for PayPal transactions
  // Adding credit by credit card calls PaymentResource.savePayment() directly
  addCredits(
    amount: null | number,
    platform: string,
    mixpanelDescription: string
  ): ng.IPromise<void> {
    return this.PaymentResource.savePayment(
      {
        company_id: this.UserSession.getCompanyId(),
      },
      {
        payment: {
          callback_url: `${this.API.dashboard}/account/add-credit`,
          total: amount,
          description: `${this.UserSession.getCompanyCurrency()} ${amount}`,
          currency: this.UserSession.getCompanyCurrency(),
          platform,
        },
      }
    )
      .then((data: any) => {
        this.MixpanelService.track(mixpanelDescription, {
          amount,
          type: platform,
        });

        if (!data.payment?.approval_url)
          throw new Error('Payment response was missing a redirect URL');

        window.location.href = data.payment.approval_url;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }
}

export { PaymentSourceService };
