import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';
import Adjustments from '@client/core/corelogic/models/Adjustments';

interface UseAdjustmentsQueryParams extends UseQueryOptions<Adjustments> {
  companyId: string;
}

export function useAdjustmentsQuery({ companyId, ...params }: UseAdjustmentsQueryParams) {
  const { adjustmentsGateway } = useGateways();

  return useQuery({
    queryKey: ['adjustments', companyId],
    queryFn: () => adjustmentsGateway.fetchShippingAdjustments(companyId),
    ...params,
  });
}
