import { IUserSession } from 'typings/user-session';
import {
  IShipmentListCustomViewsItem,
  IShipmentListCustomViewsPayload,
  IShipmentListScope,
  IShipmentListCustomViewsResponse,
} from 'typings/shipment';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { ShipmentViewsResource } from './shipment-views.resource';

class ShipmentViewsService {
  static $inject = ['$translate', 'ShipmentViewsResource', 'UserSession'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private ShipmentViewsResource: ShipmentViewsResource,
    private UserSession: IUserSession
  ) {}

  getCustomViews(
    pageContext: IShipmentListScope
  ): ng.IPromise<void | IShipmentListCustomViewsResponse> {
    return this.ShipmentViewsResource.getCustomViews({
      company_id: this.UserSession.getCompanyId() || '',
      page_context: pageContext,
    }).catch((res) => {
      toastError(res.data.errors?.join('; '));
      throw res.data;
    });
  }

  createCustomViews(
    payload: IShipmentListCustomViewsPayload
  ): ng.IPromise<IShipmentListCustomViewsItem | void> {
    return this.ShipmentViewsResource.createCustomViews(
      {
        company_id: this.UserSession.getCompanyId() || '',
      },
      payload
    ).catch((res) => {
      toastError(res.data.errors?.join('; '));
      throw res.data;
    });
  }

  deleteCustomViews(viewId: string): ng.IPromise<void> {
    return this.ShipmentViewsResource.deleteCustomViews({
      company_id: this.UserSession.getCompanyId() || '',
      view_id: viewId,
    }).catch((res) => {
      toastError(res.data.errors?.join('; '));
      throw res.data;
    });
  }

  updateCustomViews(
    viewId: string,
    payload: IShipmentListCustomViewsPayload
  ): ng.IPromise<IShipmentListCustomViewsItem | void> {
    return this.ShipmentViewsResource.updateCustomViews(
      {
        company_id: this.UserSession.getCompanyId() || '',
        view_id: viewId,
      },
      payload
    )
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.$translate.instant('global.custom-view').toLowerCase() },
            'messageformat'
          )
        );
      })
      .catch((res) => {
        toastError(res.data.errors?.join('; '));
        throw res.data;
      });
  }
}

export { ShipmentViewsService };
