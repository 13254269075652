/* eslint-disable no-param-reassign */
import { IDeferred } from 'angular';
import { IUrlConfig } from 'typings/core/config/url';
import {
  SignupStateName,
  IncompleteStateName,
  LeadStateName,
  EasyshipLeadStateName,
  FulfillmentLeadStateName,
  PlatformLeadStateName,
  PlatformAuthStateName,
  RegistrationFlowStateName,
  RegistrationFlowStep,
  MobileTips,
  StateParamsPlatform,
  InvalidStep,
} from '@client/auth/data/state';
import { IAuthService } from 'typings/core/services/auth';
import { IRegistrationFetchParams } from 'typings/auth/services/registration';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { IApiConfig } from '@typings/core/config/api';
import { RegistrationService } from './global/services/registration/registration.service';
import { StepsNavigationService } from './global/services/steps-navigation/steps-navigation.service';

const fetchRegistration = (
  $window: ng.IWindowService,
  $state: ng.ui.IStateService,
  UrlPath: IUrlConfig,
  RegistrationService: RegistrationService,
  StepsNavigationService: StepsNavigationService,
  deferred: IDeferred<unknown>,
  params: IRegistrationFetchParams
) => {
  RegistrationService.fetch(params)
    .then(({ registration }) => {
      if (registration.complete) {
        $window.location.href = UrlPath.DASHBOARD;
      } else {
        RegistrationService.normalizeAndUpdateData(registration.data);
        RegistrationService.platform = registration.flow;
        StepsNavigationService.updateAccessibleAndFieldBySteps(registration.fields_by_steps);
        StepsNavigationService.lastStepReached = registration.last_step_reached;

        deferred.resolve();
      }
    })
    .catch(() => {
      $state.go(EasyshipLeadStateName);
      deferred.reject('Registration id not found.');
    });
};

checkLogin.$inject = [
  '$q',
  '$window',
  '$state',
  '$stateParams',
  '$cookies',
  'UrlPath',
  'RegistrationService',
  'StepsNavigationService',
  'MixpanelService',
  'API',
];
function checkLogin(
  $q: ng.IQService,
  $window: ng.IWindowService,
  $state: ng.ui.IStateService,
  $stateParams: ng.ui.IStateParamsService,
  $cookies: ng.IWindowService,
  UrlPath: IUrlConfig,
  RegistrationService: RegistrationService,
  StepsNavigationService: StepsNavigationService,
  MixpanelService: MixpanelService,
  API: IApiConfig
) {
  const deferred = $q.defer();
  const credentials = $cookies.get(API.credentials);

  const params = {
    id: $stateParams.id,
  };

  if (credentials) {
    RegistrationService.completeWithCredential(params)
      .then(({ redirect_url }) => {
        if (redirect_url) {
          const parsedUrl = new window.URL(redirect_url);
          const squareState = parsedUrl.searchParams.get('state');
          if (squareState) $window.localStorage.setItem('squareState', squareState);

          $window.location.href = `${redirect_url}?new=true`;
        } else {
          deferred.reject();
        }
      })
      .catch((err) => {
        if (err.data?.data?.redirect_url) {
          const { redirect_url: redirectUrl } = err.data.data;
          $window.location.href = redirectUrl.endsWith('connect')
            ? `${redirectUrl}?failed=true`
            : redirectUrl;
        } else {
          fetchRegistration(
            $window,
            $state,
            UrlPath,
            RegistrationService,
            StepsNavigationService,
            deferred,
            params
          );
        }
      });
  } else {
    fetchRegistration(
      $window,
      $state,
      UrlPath,
      RegistrationService,
      StepsNavigationService,
      deferred,
      params
    );
  }

  return deferred.promise;
}

fetchAndValidateRegistration.$inject = [
  '$q',
  '$window',
  '$state',
  '$stateParams',
  'UrlPath',
  'RegistrationService',
  'StepsNavigationService',
];
function fetchAndValidateRegistration(
  $q: ng.IQService,
  $window: ng.IWindowService,
  $state: ng.ui.IStateService,
  $stateParams: ng.ui.IStateParamsService,
  UrlPath: IUrlConfig,
  RegistrationService: RegistrationService,
  StepsNavigationService: StepsNavigationService
) {
  const deferred = $q.defer();
  const params = {
    id: $stateParams.id,
  };

  fetchRegistration(
    $window,
    $state,
    UrlPath,
    RegistrationService,
    StepsNavigationService,
    deferred,
    params
  );

  return deferred.promise;
}

validateLastStepReached.$inject = [
  '$q',
  '$state',
  '$stateParams',
  'RegistrationService',
  'StepsNavigationService',
];
function validateLastStepReached(
  $q: ng.IQService,
  $state: ng.ui.IStateService,
  $stateParams: ng.ui.IStateParamsService,
  RegistrationService: RegistrationService,
  StepsNavigationService: StepsNavigationService
) {
  const deferred = $q.defer();
  const { lastStepReached } = StepsNavigationService;
  const currentStepStateName = `${RegistrationFlowStateName}.${$stateParams.step}`;
  const lastStepReachedStateName = `${RegistrationFlowStateName}.${lastStepReached}`;
  const currentStepIndex = StepsNavigationService.getStepIndex(currentStepStateName);
  const lastStepReachedStepIndex = StepsNavigationService.getStepIndex(lastStepReachedStateName);

  if (lastStepReached && (currentStepIndex < 0 || currentStepIndex > lastStepReachedStepIndex)) {
    $state.go(lastStepReachedStateName, {
      id: $stateParams.id,
      platform: RegistrationService.platform,
    });

    deferred.reject('Steps invalid.');
  } else {
    deferred.resolve();
  }

  return deferred.promise;
}

AuthRoutes.$inject = ['$stateProvider', '$urlRouterProvider', 'UrlPath'];
function AuthRoutes(
  $stateProvider: ng.ui.IStateProvider,
  $urlRouterProvider: ng.ui.IUrlRouterProvider,
  UrlPath: IUrlConfig
) {
  $stateProvider
    .state('auth', {
      url: '?ref&voucher&promo_code&irclickid&MediaId&parcelcast_plan&postageforce_plan',
      abstract: true,
    })
    .state(SignupStateName, {
      component: 'signupLayout',
    })
    .state(LeadStateName, {
      component: 'signup',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            // @ts-expect-error: no type definition
            return import('./signup')
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(EasyshipLeadStateName, {
      url: `${UrlPath.SIGNUP}?plan&billing_frequency`,
      resolve: {
        routerGuard: [
          '$q',
          '$window',
          'Auth',
          ($q: ng.IQService, $window: ng.IWindowService, Auth: IAuthService) => {
            const deferred = $q.defer();

            Auth.retrieveSession()
              .then(() => {
                $window.location.href = UrlPath.DASHBOARD;
              })
              .catch(() => {
                deferred.resolve();
              });

            return deferred.promise;
          },
        ],
      },
    })
    .state(FulfillmentLeadStateName, {
      url: `/{platform:${StateParamsPlatform.Fulfillment}|${StateParamsPlatform.SkyPostal}|${StateParamsPlatform.ParcelCast}|${StateParamsPlatform.PostageForce}|${StateParamsPlatform.Packifi}|${StateParamsPlatform.Kickstarter}|${StateParamsPlatform.Indiegogo}|${StateParamsPlatform.Duda}|${StateParamsPlatform.UberFreight}|${StateParamsPlatform.Shopline}|${StateParamsPlatform.Labelgo}}/signup`,
    })
    .state(PlatformLeadStateName, {
      url: '/{platform}/registration/:id/welcome',
      resolve: {
        checkLogin,
      },
    })
    .state(IncompleteStateName, {
      url: `/registration/:id/incomplete`,
      resolve: {
        fetchAndValidateRegistration,
        validateLastStepReached,
      },
    })
    .state(RegistrationFlowStateName, {
      url: '/{platform}/registration/:id',
      component: 'registrationFlowFormLayout',
      resolve: {
        fetchAndValidateRegistration,
        validateLastStepReached,
      },
    })
    .state(`${RegistrationFlowStateName}.${RegistrationFlowStep.User}`, {
      url: `/${RegistrationFlowStep.User}`,
      params: {
        step: RegistrationFlowStep.User,
        plan: null,
        billingFrequency: null,
      },
      component: 'userInformation',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './registration-flow/user-information'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${RegistrationFlowStateName}.${RegistrationFlowStep.Company}`, {
      url: `/${RegistrationFlowStep.Company}`,
      params: {
        step: RegistrationFlowStep.Company,
        plan: null,
        billingFrequency: null,
      },
      component: 'company',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './registration-flow/company'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${RegistrationFlowStateName}.${RegistrationFlowStep.Address}`, {
      url: `/${RegistrationFlowStep.Address}`,
      params: {
        step: RegistrationFlowStep.Address,
        plan: null,
        billingFrequency: null,
      },
      component: 'shippingAddress',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './registration-flow/shipping-address'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${RegistrationFlowStateName}.${RegistrationFlowStep.Settings}`, {
      url: `/${RegistrationFlowStep.Settings}`,
      params: {
        step: RegistrationFlowStep.Settings,
        plan: null,
        billingFrequency: null,
      },
      component: 'settings',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './registration-flow/settings'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${RegistrationFlowStateName}.${RegistrationFlowStep.CourierAccount}`, {
      url: `/${RegistrationFlowStep.CourierAccount}`,
      params: {
        step: RegistrationFlowStep.CourierAccount,
        plan: null,
        billingFrequency: null,
      },
      component: 'courierAccount',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './registration-flow/courier-account'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${RegistrationFlowStateName}.${InvalidStep.Country}`, {
      url: `/${InvalidStep.Country}/:countryId`,
      params: {
        plan: null,
        billingFrequency: null,
      },
      component: 'invalidCountry',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './registration-flow/invalid-country'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${RegistrationFlowStateName}.${RegistrationFlowStep.Drivers}`, {
      url: `/${RegistrationFlowStep.Drivers}`,
      params: {
        step: RegistrationFlowStep.Drivers,
        plan: null,
        billingFrequency: null,
      },
      component: 'drivers',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './registration-flow/drivers'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(MobileTips, {
      url: '/registration/mobile-tips',
      component: 'mobileTips',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            // @ts-expect-error: no type definition
            return import('./mobile-tips')
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${PlatformAuthStateName}-website`, {
      url: `/{platform:${StateParamsPlatform.Website}}/auth/:email/:planId/:billingFrequency/:recaptchaResponse?locale`,
      component: 'websiteAuth',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './platform-auth/website'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${PlatformAuthStateName}-shopify`, {
      component: 'shopifyAuth',
      url: `/{platform:${StateParamsPlatform.Shopify}}/auth?hmac&locale&shop&timestamp&code&state`,
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './platform-auth/shopify'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${PlatformAuthStateName}-bigcommerce`, {
      component: 'bigcommerceAuth',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './platform-auth/bigcommerce'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${PlatformAuthStateName}-bigcommerce.auth`, {
      url: `/{platform:${StateParamsPlatform.BigCommerce}}/auth?code&context&scope`,
    })
    .state(`${PlatformAuthStateName}-bigcommerce.load`, {
      url: `/{platform:${StateParamsPlatform.BigCommerce}}/load?signed_payload`,
    })
    .state(`${PlatformAuthStateName}-tradegecko`, {
      url: `/{platform:${StateParamsPlatform.TradeGecko}}/auth?code`,
      component: 'tradegeckoAuth',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './platform-auth/tradegecko'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    })
    .state(`${PlatformAuthStateName}-weebly`, {
      url: `/{platform:${StateParamsPlatform.Weebly}}/auth?authorization_code&site_id&user_id`,
      component: 'weeblyAuth',
      resolve: {
        $ocLazyLoad: [
          '$ocLazyLoad',
          ($ocLazyLoad: oc.ILazyLoad) => {
            return import(
              // @ts-expect-error: no type definition
              './platform-auth/weebly'
            )
              .then(({ module }) => {
                $ocLazyLoad.load(module);
              })
              .catch((err) => {
                throw new Error(`Ooops, something went wrong, ${err}`);
              });
          },
        ],
      },
    });

  $urlRouterProvider.otherwise(UrlPath.SIGNUP);
}

export { AuthRoutes };
