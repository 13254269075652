import template from './shipment-cost-cell.html?raw';
import style from './shipment-cost-cell.module.scss';

class ShipmentCostCell {
  static $inject = ['UserSession'];

  constructor(UserSession) {
    this.UserSession = UserSession;

    this.style = style;
    this.taxCharge = 0;
  }

  $onInit() {
    this.companyCurrency = this.UserSession.getCompanyCurrency();
    this.taxName = this._getTaxName();
    this.totalCharge = this._getTotalCharge();
  }

  $onChanges() {
    this.totalCharge = this._getTotalCharge();
  }

  _getTotalCharge() {
    return this.shipment.total_charge || this._calculateTotalCharge();
  }

  _calculateTotalCharge() {
    return Object.keys(this.shipment).reduce((accumulator, feeName) => {
      const fee = this.shipment[feeName] || 0;

      accumulator += fee;

      return accumulator;
    }, 0);
  }

  _getTaxName() {
    if (this.shipment.sales_tax + this.shipment.provincial_sales_tax === 0) return '';

    if (this.UserSession.user.shipping_country.name === 'Canada') {
      return 'taxes';
    }

    const taxName = [];

    const { sales_tax_name, provincial_sales_tax_name } =
      this.UserSession.user.shipping_country.finance;

    if (sales_tax_name) {
      taxName.push(sales_tax_name);
    }

    if (provincial_sales_tax_name) {
      taxName.push(provincial_sales_tax_name);
    }

    return taxName.join(' & ');
  }
}

const ShipmentCostCellComponent = {
  controller: ShipmentCostCell,
  template,
  bindings: {
    shipment: '<',
    esLargeText: '<',
  },
};

export { ShipmentCostCellComponent };
