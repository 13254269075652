import { Brokers } from 'typings/brokers';
import { IApiConfig } from 'typings/core/config/api';

class BrokersResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor($resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/brokers/:broker_id/:controller`,
      {},
      {
        update: {
          method: 'PATCH',
        },
        deactivate: {
          method: 'PATCH',
        },
      }
    );
  }

  createBroker(payload: Brokers.ICreateFedexBrokersPayload): ng.IPromise<Brokers.IBroker> {
    return this.resource.save(payload).$promise;
  }

  updateBroker(
    params: Brokers.IFedexBrokersParams,
    payload: Brokers.IBrokerPayload
  ): ng.IPromise<Brokers.IBroker> {
    return this.resource.update(params, payload).$promise;
  }

  deactivateBroker(params: Brokers.IFedexBrokersParams): ng.IPromise<void> {
    return this.resource.deactivate({ ...params, controller: 'deactivate' }, {}).$promise;
  }

  getBrokers(params: Brokers.IFedexGetBrokersParams): ng.IPromise<Brokers.IBrokers> {
    return this.resource.get(params).$promise;
  }
}

export { BrokersResource };
