import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  SubscriptionServiceProvider,
  useSubscriptionService,
} from '@client/src/global/context/SubscriptionProvider';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@client/core/components/react/Chip';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { Link, makeStyles } from '@material-ui/core';

import { FEATURE_KEY } from '@client/data/subscription';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import LearnMore from '../../../global/dashboard-component-library/learn-more/index';

interface TaxAndDutyExplainerDialogProps extends DialogProps {
  onClose: () => void;
  onLinkClick: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '16px',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  dialogContent: {
    padding: '40px 36px 34px 50px',
  },
}));

export default function TaxAndDutyExplainerDialog({
  onLinkClick,
  ...props
}: TaxAndDutyExplainerDialogProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { getPlanNameByFeatureKey, isPlanBadgeVisible } = useSubscriptionService();

  const planLabel = useMemo(() => {
    return getPlanNameByFeatureKey(FEATURE_KEY.TaxAndDutyAtCheckout);
  }, []);

  return (
    <Dialog {...props} className={classes.root} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <span className="text-xl">
                <FormattedMessage id="store.tax-duty-dialog.header" />
              </span>
            </Grid>

            {isPlanBadgeVisible(FEATURE_KEY.TaxAndDutyAtCheckout) && (
              <Chip label={planLabel} color="teal" variant="rounded" />
            )}
          </Grid>
          <Grid item>
            <IconButton aria-label="close modal" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column" spacing={4}>
          <Grid container item justifyContent="space-between">
            <Grid item xs={3}>
              <p className="leading-6">
                <FormattedMessage
                  id="store.tax-duty-dialog.content-one"
                  values={{
                    a: (chunks: string) => <Link onClick={onLinkClick}>{chunks}</Link>,
                  }}
                />
              </p>
              <p className="leading-6">
                <FormattedMessage id="store.tax-duty-dialog.content-two" />
              </p>
              <p className="leading-6">
                <FormattedMessage id="store.tax-duty-dialog.content-three" />
              </p>
            </Grid>
            <Grid item xs={8}>
              <Grid container item justifyContent="center">
                <img
                  alt="tax-duty"
                  src="/images/dashboard/single-store/insurance-checkout/tax-duty.png"
                  width="100%"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <LearnMore
              esSectionName={formatMessage({ id: 'store.tax-duty-dialog.header' })}
              esSupportLink="/hc/en-us/articles/115003453111-Duties-Taxes-at-Checkout"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export function WrappedTaxAndDutyExplainerDialog(props: TaxAndDutyExplainerDialogProps) {
  return (
    <ReactRootProviders>
      <SubscriptionServiceProvider>
        <TaxAndDutyExplainerDialog {...props} />
      </SubscriptionServiceProvider>
    </ReactRootProviders>
  );
}
