import template from './request-pickup.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config(config);

  config.$inject = ['$stateProvider'];
  function config($stateProvider) {
    $stateProvider.state('app.request-pickup', {
      url: '^/request-pickup?shipment_id',
      template,
      controller: 'RequestPickupCtrl as vm',
      data: {
        requiredUserRight: 'menu.createShipments.multiple',
      },
    });
  }
})();
