(function () {
  'use strict';

  function addressFormNlCtrl() {
    var ctrl = this; // eslint-disable-line no-unused-vars
  }

  addressFormNlCtrl.$inject = [];

  angular
    .module('app.component.address-form-nl')
    .controller('addressFormNlCtrl', addressFormNlCtrl);
})();
