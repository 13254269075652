HelpGuideRoutes.$inject = ['$stateProvider'];
function HelpGuideRoutes($stateProvider) {
  $stateProvider.state('app.help-guide', {
    url: '^/help?appcue&checkouthelp',
    component: 'helpGuide',
    resolve: {
      previousState: [
        'uibRoutingOptions',
        'MixpanelService',
        'UserSession',
        (uibRoutingOptions, MixpanelService, UserSession) => {
          const { name, url } = uibRoutingOptions.previousState();

          if (!name) return;

          MixpanelService.track('Help Guide - Visit Page', {
            previous_page_name: name,
            previous_page_url: url,
            persona: UserSession.getUserPersona(),
          });
        },
      ],
    },
    data: {
      requiredUserRight: 'menu.help',
    },
  });
}

export { HelpGuideRoutes };
