
import angular from 'angular';

import { B13aFilingComponent } from './b13a-filing.component';

const MODULE_NAME = 'app.create-shipments.basic.b13a-filing';

angular
  .module(MODULE_NAME, [])
  .component('esB13aFiling', B13aFilingComponent);

export default MODULE_NAME;
