'use strict';
import template from './order-summary.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.order-summary', {
      url: '^/order-summary?shipment_id',
      template,
      controller: 'OrderSummaryCtrl',
      data: {
        requiredUserRight: 'menu.createShipments.multiple',
      },
    });
  },
]);
