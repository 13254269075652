import { zendeskChatOnLogout } from './auth-logout-success/zendesk-chat/zendesk-chat';

authLogoutSuccess.$inject = ['$rootScope', 'AUTH_EVENTS'];
function authLogoutSuccess($rootScope, AUTH_EVENTS) {
  $rootScope.$on(AUTH_EVENTS.logoutSuccess, () => {
    zendeskChatOnLogout();
  });
}

export { authLogoutSuccess };
