import React, { lazy, memo, Suspense } from 'react';
import Price from '@client/src/create-shipments/shipment-details-drawer/ShipmentDetailsDrawerToolbar/PriceSummary/Price';
import Tax from '@client/src/create-shipments/shipment-details-drawer/ShipmentDetailsDrawerToolbar/PriceSummary/Tax';
import { ArrowTooltip, Pulse } from 'easyship-components';
import CellLayout from './CellLayout';
import { CellProps } from './d';

const TaxToolTipContent = lazy(
  () => import('@client/src/manage-shipments/ShipmentsTable/TaxToolTipContent')
);

export const TotalCostCell = memo(({ row }: CellProps) => {
  const shipment = row.original;
  const totalCost = Number(shipment.total_charge);
  const currency = shipment.currency ?? '';
  const tax = {
    provincialSales: Number(shipment.provincial_sales_tax),
    provincialSalesTaxName: shipment.provincial_sales_tax_name as string,
    sales: Number(shipment.sales_tax),
    salesTaxName: shipment.sales_tax_name as string,
  };

  return (
    <CellLayout className="justify-end text-right">
      {!!shipment.total_charge && (
        <ArrowTooltip
          className="items-center w-full"
          maxWidth="extra-wide"
          placement="left"
          label={
            <Suspense
              fallback={
                <div className="w-[240px] h-[280px]">
                  <Pulse show />
                </div>
              }
            >
              <TaxToolTipContent shipment={shipment} />
            </Suspense>
          }
        >
          <div className="min-w-[96px] px-2 flex flex-col justify-center items-end h-full">
            <div className="leading-6 flex gap-1 items-center mb-1">
              <Price currencyCode={currency} totalCharge={totalCost} />
            </div>
            <div className="font-normal leading-5 text-ink-900 sm">
              <Tax tax={tax} fontSize="sm" />
            </div>
          </div>
        </ArrowTooltip>
      )}
    </CellLayout>
  );
});
