(function () {
  'use strict';

  Sso.$inject = ['$resource', 'API'];
  function Sso($resource, API) {
    var endpointUrl = API.endpoint;
    // Should pass in a return_to parameter obtained from client upon redirect to SSO login page
    return $resource(
      endpointUrl + '/sso/:client',
      {
        client: '@_client',
      },
      {
        fetchWithSessionToken: {
          method: 'GET',
        },
      }
    );
  }

  angular.module('app.factory.Sso', []).factory('Sso', Sso);
})();
