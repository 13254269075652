import axios from 'axios';

(function () {
  'use strict';
  var TIMEOUT = 1100;

  AbTestsService.$inject = ['API'];
  function AbTestsService(API) {
    var service = this;

    service.getAbTestVariation = function (params) {
      return axios
        .get(API.endpoint + '/ab_tests/' + params.key + '/ab_test_assignment/variation', {
          timeout: TIMEOUT,
          timeoutErrorMessage: 'TIMEOUT',
          params: {
            force: params.force,
            variation: params.variation,
          },
        })
        .then(function (result) {
          return result.data;
        });
    };

    service.saveAbTestAssignment = function (payload) {
      var abTestKey = payload.abTestKey;
      delete payload.abTestKey;

      return axios.post(API.endpoint + '/ab_tests/' + abTestKey + '/ab_test_assignment', payload);
    };
  }

  angular.module('app.service.AbTestsService', []).service('AbTestsService', AbTestsService);
})();
