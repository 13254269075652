import { ShipmentFormData } from '@client/core/corelogic/models/Shipment';
import { FieldNamesMarkedBoolean, get } from 'react-hook-form';

const MODIFIED_FIELDS_META_DATA = {
  shipment: {
    path: 'order',
    sectionName: 'Shipment details',
    fields: [
      { path: 'platform.name', fieldName: 'Platform name' },
      { path: 'platform.orderNumber', fieldName: 'Order number' },
      { path: 'sellerNotes', fieldName: 'Seller notes' },
      { path: 'includeReturnLabel', fieldName: 'Return label' },
      { path: 'tags', fieldName: 'Tags' },
      { path: 'isInsured', fieldName: 'Insurance' },
      { path: 'optionalService', fieldName: 'Additional services' },
    ],
  },
  receiver: {
    path: 'receiverAddress',
    sectionName: 'Receiver details',
    fields: [
      { path: 'receiverName', fieldName: 'Name' },
      { path: 'companyName', fieldName: 'Company name' },
      { path: 'countryId', fieldName: 'Country' },
      { path: 'city', fieldName: 'City' },
      { path: 'postalCode', fieldName: 'Post code' },
      { path: 'state', fieldName: 'State' },
      { path: 'phoneNumber', fieldName: 'Phone' },
      { path: 'email', fieldName: 'Email' },
      { path: 'addressLine1', fieldName: 'Line 1' },
      { path: 'addressLine2', fieldName: 'Line 2' },
      { path: 'taxId', fieldName: 'Tax ID' },
      { path: 'isSetAsResidentialAddress', fieldName: 'Is residential' },
    ],
  },
  sender: {
    path: 'senderAddress',
    sectionName: 'Sender details',
    fields: [
      { path: 'companyName', fieldName: 'Company name' },
      { path: 'phoneNumber', fieldName: 'Phone' },
      { path: 'email', fieldName: 'Email' },
      { path: 'countryId', fieldName: 'Country' },
      { path: 'city', fieldName: 'City' },
      { path: 'postalCode', fieldName: 'Post code' },
      { path: 'state', fieldName: 'State' },
      { path: 'addressLine1', fieldName: 'Line 1' },
      { path: 'addressLine2', fieldName: 'Line 2' },
      { path: 'senderName', fieldName: 'Name' },
      { path: 'useForLater', fieldName: 'Save address' },
      { path: 'useExistingAddress', fieldName: 'Use existing address' },
    ],
  },
} as const;

interface MapDirtyFieldsToMixpanelDataParams {
  dirtyFields: FieldNamesMarkedBoolean<ShipmentFormData>;
  section: keyof typeof MODIFIED_FIELDS_META_DATA;
}
export function mapDirtyFieldsToMixpanelData({
  dirtyFields,
  section,
}: MapDirtyFieldsToMixpanelDataParams): string[] {
  const modifiedFields: string[] = [];

  const metaData = MODIFIED_FIELDS_META_DATA[section];

  metaData.fields.forEach(({ path, fieldName }) => {
    const hasModified = get(dirtyFields[metaData.path], path);
    if (!hasModified) return;

    if (hasModified === true) {
      modifiedFields.push(fieldName);
    } else if (
      typeof hasModified === 'object' &&
      Object.values(hasModified).some((f) => f === true)
    ) {
      modifiedFields.push(fieldName);
    }
  });

  return modifiedFields.map((field) => `${metaData.sectionName} / ${field}`);
}

export function mapSenderAddressModifiedFieldsToMixpanelData({
  dirtyFields,
}: {
  dirtyFields: FieldNamesMarkedBoolean<ShipmentFormData>;
}): string[] {
  // Only change the selected existing address
  if (dirtyFields.senderAddress?.id) {
    return ['Address from address book'];
  }

  const modifiedFields = mapDirtyFieldsToMixpanelData({
    dirtyFields,
    section: 'sender',
  });

  return modifiedFields;
}
