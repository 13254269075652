import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import ProgressIndicator from '@client/core/components/react/ProgressIndicator';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface RescheduleHandoverMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onRescheduleHandover: () => void;
}

export default function RescheduleHandoverMenuItem({
  divider = false,
  shipmentListItem,
  onRescheduleHandover,
}: RescheduleHandoverMenuItemProps) {
  const [isRescheduleHandoverLoading, setIsRescheduleHandoverLoading] = useState(false);
  const { is_valid_to_reschedule_pickup: isValidToReschedulePickup } = shipmentListItem;

  async function handleRescheduleHandover() {
    setIsRescheduleHandoverLoading(true);
    await onRescheduleHandover();
    setIsRescheduleHandoverLoading(false);
  }

  if (!isValidToReschedulePickup) {
    return null;
  }

  return (
    <ProgressIndicator show={isRescheduleHandoverLoading}>
      <MenuItem
        onClick={handleRescheduleHandover}
        divider={divider}
        disabled={isRescheduleHandoverLoading}
      >
        <div style={baseStyleIconBox}>
          <i
            className="icon-delivery"
            style={{ color: baseStyleIcon.colorGrey, transform: 'scaleX(-1)', fontSize: '24px' }}
          />
        </div>
        <span className="text-base">
          <FormattedMessage id="shipments.speed-dial.reschedule-handover" />
        </span>
      </MenuItem>
    </ProgressIndicator>
  );
}
