(function () {
  'use strict';

  WixService.$inject = ['$cookies', 'PlatformApi', 'StoreService', 'UserSession', 'Auth'];
  function WixService($cookies, PlatformApi, StoreService, UserSession, Auth) {
    var service = this;

    service.authenticate = function () {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.wix.authenticate({ company_id: UserSession.company.id }, {}).$promise;
      });
    };

    service.connectStore = function (newStore) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.wix
          .connect({ company_id: UserSession.company.id }, { store: newStore })
          .$promise.then(function (response) {
            StoreService.saveStoreValueToLocal(response.store);
            return response;
          });
      });
    };
  }

  angular.module('app.service.WixService', []).service('WixService', WixService);
})();
