import angular from 'angular';

import '@client/core/components/forms/input';
import '@client/core/components/forms/checkbox';

import '@client/app/services/CountryService/CountryService.service';

// TODO: Remove imports when dependencies of the dependencies have been properly mapped. Webpack needs to have access to these files in the meantime but it should not be handled at the component level
import '@client/core/config'; // CountryService.service
import '@client/app/factories/Country/Country.service'; // CountryService.service
import '@client/app/factories/HkDistrict/HkDistrict.service'; // CountryService.service

import { CountrySelectorComponent } from './country-selector.component';

angular
  .module('app.global.dashboard-component-library.country-selector', [
    'core.components.input',
    'core.components.checkbox',
    'app.service.CountryService',
  ])
  .component('easyshipCountrySelector', CountrySelectorComponent);
