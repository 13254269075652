import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Info, Refresh } from 'easyship-components/icons';
import CellLayout from './CellLayout';

export default function RefreshCell() {
  return (
    <CellLayout className="flex flex-col min-w-[188px] gap-2 justify-center px-2">
      <div className="flex items-center gap-[2px]">
        <Info className="text-ink-100" />
        <span className="text-sm font-bold text-ink-900">
          <FormattedMessage id="global.recalculation-required" />
        </span>
      </div>
      <div className="flex items-center text-blue-500 leading-5 gap-[2px]">
        <Refresh />
        <FormattedMessage id="global.refresh-list" />
      </div>
    </CellLayout>
  );
}
