import { IStore } from 'typings/store';
import { EcommercePlatform, Platform } from '@client/data/platform';
import angular from 'angular';
import { IAuthService } from 'typings/core/services/auth';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { OauthData } from '../../models/data/store-connect-data';
import {
  StorePayload,
  OauthPayload,
  RedirectStorePayload,
  CredentialsStorePayload,
  WarehouseStoreConnectPayload,
} from '../../models/payload/store-connect-payloads';
import { ConnectStorePort } from '../../ports/connect-store';

export default class NgConnectStoreGateway implements ConnectStorePort {
  private platformName: Platform;
  private authService: IAuthService;
  private oauthPlatformApi: any;
  private storePlatformApi: any;
  private redirectStorePlatformApi: any;
  private credentialsStorePlatformApi: any;

  public constructor(platformName: Platform) {
    this.platformName = platformName;
    this.oauthPlatformApi = angular.element(document.body).injector().get<any>('OauthPlatformApi');
    this.storePlatformApi = angular.element(document.body).injector().get<any>('StorePlatformApi');
    this.redirectStorePlatformApi = angular
      .element(document.body)
      .injector()
      .get<any>('RedirectStorePlatformApi');
    this.credentialsStorePlatformApi = angular
      .element(document.body)
      .injector()
      .get<any>('CredentialsStorePlatformApi');
    this.authService = angular.element(document.body).injector().get<IAuthService>('Auth');
  }

  oAuthConnect(companyId: string, payload?: OauthPayload): Promise<OauthData> {
    return new Promise<OauthData>((resolve, reject) => {
      this.authService
        .retrieveSession()
        .then(() => {
          let selectedPlatform;
          selectedPlatform = this.platformName;
          if (this.platformName === EcommercePlatform.BuyOnGoogle) {
            selectedPlatform = 'buy_on_google';
          } else if (this.platformName === EcommercePlatform.Etsy) {
            selectedPlatform = 'etsy_group';
          }
          return this.oauthPlatformApi.authenticate(
            { company_id: companyId, platform_name: selectedPlatform },
            { platform_id: payload?.platform_id, url: payload?.url }
          ).$promise;
        })
        .then(resolve)
        .catch(reject);
    });
  }

  storeConnect(companyId: string, payload: StorePayload): Promise<IStore> {
    return new Promise<IStore>((resolve, reject) => {
      this.storePlatformApi
        .create({ company_id: companyId }, { store: payload })
        .$promise.then((res: any) => {
          resolve(res.store);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  credentialsStoreConnect(companyId: string, payload: CredentialsStorePayload): Promise<IStore> {
    let selectedPlatform: string;
    selectedPlatform = this.platformName;

    if (this.platformName === EcommercePlatform.OnBuy) {
      selectedPlatform = 'on_buy';
    } else if (this.platformName === EcommercePlatform.Dear) {
      selectedPlatform = 'dear_inventory';
    }

    return new Promise<IStore>((resolve, reject) => {
      this.credentialsStorePlatformApi
        .save({ company_id: companyId, platform_name: selectedPlatform }, { store: payload })
        .$promise.then((res: any) => {
          resolve(res.store);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  redirectStoreConnect(
    companyId: string,
    platform: string,
    payload?: RedirectStorePayload
  ): Promise<string> {
    if (platform === 'ebay') {
      return new Promise<string>((resolve, reject) => {
        this.redirectStorePlatformApi
          .save({ company_id: companyId, platform }, { platform_id: payload?.platform_id })
          .$promise.then((res: any) => {
            const url = `https://signin.ebay.com/ws/eBayISAPI.dll?SignIn&RUName=${res.body.ru_name}&SessID=${res.body.session_id}&ruparams=vars=${res.body.session_id}|${payload?.platform_id}`;
            resolve(url);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    }

    return new Promise<string>((resolve, reject) => {
      this.redirectStorePlatformApi
        .get({ company_id: companyId, platform })
        .$promise.then((res: any) => {
          resolve(res.url);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  warehouseStoreConnect(
    companyId: string,
    platform: string,
    payload?: WarehouseStoreConnectPayload
  ): Promise<IStore[]> {
    return new Promise<IStore[]>((resolve, reject) => {
      this.redirectStorePlatformApi
        .save({ company_id: companyId, platform }, { [platform]: { ...payload } })
        .$promise.then((res: any) => {
          resolve(res.stores);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
