class HubspotService {
  identify(arg) {
    if (!window._hsq || import.meta.env.VITE_APP_ENVIRONMENT !== 'production' || !arg) return;

    const payload = typeof arg === 'string' ? { email: arg } : arg;

    return window._hsq.push(['identify', payload]);
  }
}

export { HubspotService };
