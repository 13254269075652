import angular from 'angular';

import subscriptionService from '@client/src/global/services/subscription';

import { SubscriptionModalControllerComponent } from './subscription-modal-controller.component';

const MODULE_NAME = 'app.global.components.subscription-modal-controller';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', subscriptionService])
  .component('esSubscriptionModalController', SubscriptionModalControllerComponent);

export default MODULE_NAME;
