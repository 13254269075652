import { CourierLogo, CourierAccountState, CourierAuthState } from '@client/data/courier';
// pill colors
const DANGER = 'danger';
const WARNING = 'warning';
const INFO = 'info';

class CourierAccounts {
  static $inject = [
    '$q',
    '$timeout',
    '$translate',
    'CourierAccountsResource',
    'UserSession',
    'SubscriptionService',
  ];

  constructor($q, $timeout, $translate, CourierAccountsResource, UserSession, SubscriptionService) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.$translate = $translate;
    this.CourierAccountsResource = CourierAccountsResource;
    this.UserSession = UserSession;
    this.SubscriptionService = SubscriptionService;

    this.companyCouriers = [];
    this.easyshipCouriers = [];

    this.getActiveCourierAccounts = this.getActiveCourierAccounts.bind(this);
    this.getReconnectUrl = this.getReconnectUrl.bind(this);
  }

  getPillAttributes(umbrellaName) {
    switch (umbrellaName) {
      case 'DHL':
      case 'FedEx':
        return {
          failed: { slug: 'incomplete', color: DANGER },
          partially_verified: { slug: 'incomplete', color: DANGER },
          inactive: { slug: 'inactive', color: DANGER },
          pending: { slug: 'pending-cert', color: INFO },
        };

      case 'UPS':
        return {
          failed: { slug: 'incomplete', color: DANGER },
          partially_verified: { slug: 'incomplete', color: DANGER },
          inactive: { slug: 'incomplete', color: DANGER },
          pending: { slug: 'incomplete', color: DANGER },
          is_expiring_soon: { slug: 'is_expiring_soon', color: WARNING },
        };

      default:
        return {
          failed: { slug: 'incomplete', color: DANGER },
          inactive: { slug: 'inactive', color: DANGER },
          pending: { slug: 'pending', color: WARNING },
          pending_upgrade: { slug: 'pending-upgrade', color: DANGER },
        };
    }
  }

  getActiveCourierAccounts(id) {
    const company_id = id || this.UserSession.company.id;

    return this.CourierAccountsResource.getListing({ company_id })
      .then((data) => {
        this.companyCouriers = data.company_courier_accounts
          .filter((courier) => courier.is_active)
          .map((courier) => {
            return this._processCourierInfo(courier);
          })
          .sort(this._sortAlphabetically);

        this.easyshipCouriers = data.easyship_courier_accounts
          .filter((courier) => courier.is_active && courier.number_of_couriers > 0)
          .sort(this._sortAlphabetically);

        return {
          company_courier_accounts: this.companyCouriers,
          easyship_courier_accounts: this.easyshipCouriers,
        };
      })
      .catch((error) => {
        throw error;
      });
  }

  _sortAlphabetically(a, b) {
    if (a.umbrella_name < b.umbrella_name) return -1;
    if (a.umbrella_name > b.umbrella_name) return 1;
    return 0;
  }

  getCourierInfo({ id }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.getDetails({ company_id, id })
      .then((courier) => {
        return this._processCourierInfo(courier);
      })
      .catch((error) => {
        throw error;
      });
  }

  // COUR-1553 to be removed after all old UPS accounts reconnected
  getReconnectUrl({ id }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.getReconnectUrl({
      company_id,
      id,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  _setPillAttributes(courierUmbrellaName, courierAccountState, courierAuthState, isExpiringSoon) {
    const pillAttributes = this.getPillAttributes(courierUmbrellaName);

    let pillState = '';

    if (courierUmbrellaName === 'UPS') {
      // COUR-1553 to be removed after all old UPS accounts reconnected
      if (isExpiringSoon) {
        pillState = 'is_expiring_soon';
      } else {
        pillState = courierAuthState;
      }
    } else {
      pillState =
        courierAuthState === CourierAuthState.Failed ||
        courierAuthState === CourierAuthState.PartiallyVerified
          ? courierAuthState
          : courierAccountState;
    }

    return {
      pillText:
        pillAttributes[pillState] &&
        this.$translate.instant(`courier.lyoc-courier.state.${pillAttributes[pillState].slug}`),
      pillColor: pillAttributes[pillState] && pillAttributes[pillState].color,
    };
  }

  _processCourierInfo(courier) {
    const hasCourierAccountState = !!courier.account_state;
    const isCourierAccountActive = courier.account_state === CourierAccountState.Active;
    const isCourierOnlyPartiallyVerified =
      courier.auth_state === CourierAuthState.PartiallyVerified;
    const shouldShowAccountState =
      hasCourierAccountState && (!isCourierAccountActive || isCourierOnlyPartiallyVerified);

    if (!shouldShowAccountState && courier.umbrella_name !== 'UPS') return courier;

    courier.accountStatePillAttributes = this._setPillAttributes(
      courier.umbrella_name,
      courier.account_state,
      courier.auth_state,
      courier.is_expiring_soon
    );

    return courier;
  }

  updateCourierAccount({ id, courierAccount }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.update({ company_id, id }, courierAccount)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateCourierInfo({ id, umbrella_name, account }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.updateAccount(
      { company_id, id },
      { umbrella_name, account }
    )
      .then((response) => {
        this.companyCouriers = this.companyCouriers.map((courier) => {
          courier.nickname = courier.id === id ? response.account.nickname : courier.nickname;

          return courier;
        });

        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  connectAuMyPostBusinessCode({ nickname, account }) {
    return this.CourierAccountsResource.connectAuMyPostBusinessCode(
      {
        company_id: this.UserSession.company.id,
      },
      { nickname, account }
    )
      .then((response) => {
        $window.localStorage.removeItem('myPostBusinessUserAccountName');
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateCourierSettings({ id, settings }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.updateSettings({ company_id, id }, { settings });
  }

  addCourier({ umbrella_name, account, access_license_text }) {
    const company_id = this.UserSession.company.id;

    const payload = access_license_text ? { account, access_license_text } : { account };

    return this.CourierAccountsResource.connectAccount({ company_id, umbrella_name }, payload)
      .then((courier) => {
        /**
         * Check if the courier that is trying to add already exist
         * if do not exist then add a new courier
         * else update the courier
         */
        const incompleteCourierIndex = this.companyCouriers.findIndex(({ id }) => {
          return id === courier.id;
        });

        if (incompleteCourierIndex === -1) {
          this.companyCouriers.push(this._processCourierInfo(courier));
        } else {
          this.companyCouriers[incompleteCourierIndex] = this._processCourierInfo(courier);
        }

        this.UserSession.hasCompanyCourierAccounts = true;

        this.SubscriptionService.fetchCurrentSubscription({
          company_id,
        });

        return courier;
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteCourier({ id }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.deactivateAccount({ company_id, id })
      .then((response) => {
        this.companyCouriers = this.companyCouriers.filter((courier) => courier.id !== id);

        if (!this.companyCouriers.length) {
          this.UserSession.hasCompanyCourierAccounts = false;
        }

        this.SubscriptionService.fetchCurrentSubscription({
          company_id,
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  sendNextStepsEmail({ id }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.sendNextStepsEmail({ company_id, id }, {})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  getCourierAccountName(courierAccounts, id) {
    return courierAccounts.company_courier_accounts.find((account) => {
      return account.id === id;
    }).nickname;
  }

  getAccessLicenseAgreement() {
    return this.CourierAccountsResource.getAccessLicenseAgreement({
      company_id: this.UserSession.company.id,
    });
  }

  hasCompanyUps() {
    return this.companyCouriers.some((account) => {
      return account.logo_url === CourierLogo.Ups;
    });
  }

  hasCompanyFedex() {
    return this.companyCouriers.some((account) => {
      return (
        account.logo_url === CourierLogo.Fedex &&
        account.account_state === CourierAccountState.Active &&
        account.auth_state === CourierAuthState.Verified
      );
    });
  }

  hasAuPostMyPostBusiness() {
    return this.companyCouriers.some((account) => {
      return (
        account.logo_url === CourierLogo.MyPost &&
        account.account_state === CourierAccountState.Active &&
        account.auth_state === CourierAuthState.Verified
      );
    });
  }

  getAccountsFromSpecificCourier(courierName) {
    return this.companyCouriers.filter((courier) => courier.logo_url === courierName);
  }

  getFedExActiveAccount() {
    return this.companyCouriers.find((courier) => {
      return (
        courier.logo_url === CourierLogo.Fedex &&
        courier.account_state === CourierAccountState.Active &&
        courier.auth_state === CourierAuthState.Verified
      );
    });
  }

  generatePinCodeForCourierVerification({ id, account }) {
    const company_id = this.UserSession.company.id;

    return this.CourierAccountsResource.generatePinCode({ company_id, id }, { account });
  }
}

export { CourierAccounts };
