import { toastError } from '@client/core/components/react/Toastify';

(function () {
  addressFormUsBackupCtrl.$inject = ['PostalCodeService', '$translate'];
  function addressFormUsBackupCtrl(PostalCodeService, $translate) {
    const ctrl = this;

    ctrl.retrieveState = function () {
      const isPostalCodeFormatValid = ctrl.fieldValidators.postal_code.validate(
        ctrl.address.postal_code
      );
      if (!isPostalCodeFormatValid) {
        ctrl.address.state = null;
        return;
      }

      ctrl.postalCodeBusy = true;

      const params = {
        postal_code: ctrl.address.postal_code,
        country_id: ctrl.address.country_id,
      };

      PostalCodeService.getState(params)
        .then(function (res) {
          ctrl.address.state = res.state.name;
          ctrl.address.city = res.city;
        })
        .catch(function () {
          ctrl.address.state = null;
          toastError($translate.instant('account.addresses.invalid-postal-code'));
        })
        .finally(function () {
          ctrl.postalCodeBusy = false;
        });
    };
  }

  angular
    .module('app.component.address-form-us-backup')
    .controller('addressFormUsBackupCtrl', addressFormUsBackupCtrl);
})();
