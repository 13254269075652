import { INotification, INotificationParams } from 'typings/notification-centre';
import { IUserSession } from 'typings/user-session';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError } from '@client/core/components/react/Toastify';
import { NotificationResource } from './notification-centre.resource';

enum NotificationEventName {
  SingleRead = 'Single read',
  AllRead = 'All read',
  SingleDelete = 'Single delete',
  AllDelete = 'All delete',
  SingleCta = 'Single CTA',
}

export class NotificationCentreService {
  private _unreadCount = 0;
  private _notifications: INotification[] = [];

  static $inject = ['UserSession', 'NotificationResource', 'MixpanelService', '$translate'];
  constructor(
    private UserSession: IUserSession,
    private NotificationResource: NotificationResource,
    private MixpanelService: MixpanelService,
    private $translate: angular.translate.ITranslateService
  ) {}

  fetchNotifications(): ng.IPromise<void> {
    return this.NotificationResource.getNotifications({
      user_id: this.UserSession.getUserId(),
    })
      .then((data) => {
        this._notifications = data.notifications;
      })
      .catch((e) => {
        toastError(this.$translate.instant('toast.default-error'));
        throw e;
      });
  }

  fetchUnreadCount(): ng.IPromise<void> {
    return this.NotificationResource.getUnreadCount({
      user_id: this.UserSession.getUserId(),
    })
      .then((data) => {
        this._unreadCount = data.unread_count;
      })
      .catch((e) => {
        toastError(this.$translate.instant('toast.default-error'));
        throw e;
      });
  }

  readNotification(params?: INotificationParams): ng.IPromise<void> {
    return this.NotificationResource.readNotification(this.normalizeRequestParam(params))
      .then(() => {
        const eventName =
          params && params.notification_id
            ? NotificationEventName.SingleRead
            : NotificationEventName.AllRead;
        this.mixpanelRequest(eventName);
      })
      .catch((e) => {
        toastError(this.$translate.instant('toast.default-error'));
        throw e;
      });
  }

  deleteNotifications(params?: INotificationParams): ng.IPromise<void> {
    return this.NotificationResource.deleteNotifications(this.normalizeRequestParam(params))
      .then(() => {
        const eventName =
          params && params.notification_id
            ? NotificationEventName.SingleDelete
            : NotificationEventName.AllDelete;
        this.mixpanelRequest(eventName);
      })
      .catch((e) => {
        toastError(this.$translate.instant('toast.default-error'));
        throw e;
      });
  }

  removeNotification(notiId: string): void {
    const index = this.notifications.findIndex((notification) => notification.id === notiId);
    if (index || index === 0) {
      if (!this._notifications[index].read_at) {
        this.decreaseUnreadCount();
      }
      this._notifications.splice(index, 1);
    }
  }

  unshiftNotification(notification: INotification): void {
    this._notifications.unshift(notification);
  }

  increaseUnreadCount(): void {
    this._unreadCount += 1;
  }

  decreaseUnreadCount(): void {
    if (this._unreadCount > 0) {
      this._unreadCount -= 1;
    }
  }

  resetUnreadCount(): void {
    this._unreadCount = 0;
  }

  markAllRead(): void {
    this._notifications.forEach((notification) => {
      const modifyNotification = notification;
      if (!modifyNotification.read_at) {
        modifyNotification.read_at = new Date().toISOString();
      }
      return modifyNotification;
    });
    this.resetUnreadCount();
  }

  markOneAsRead(notiId: string): void {
    this._notifications = this.notifications.map((notification) => {
      const modifyNotification = notification;
      if (modifyNotification.id === notiId) {
        modifyNotification.read_at = new Date().toISOString();
      }
      return modifyNotification;
    });
    this.decreaseUnreadCount();
  }

  onMixpanelCta(ctaText: string): void {
    this.mixpanelRequest(NotificationEventName.SingleCta, {
      action_name: ctaText,
    });
  }

  get unreadCount(): number {
    return this._unreadCount;
  }

  get notifications(): INotification[] {
    return this._notifications;
  }

  get isAvailable(): boolean {
    // Keep this method in case if we have more countries in the future.
    return true;
  }

  private normalizeRequestParam(params?: INotificationParams): INotificationParams {
    const requestParams: INotificationParams = {
      user_id: this.UserSession.getUserId(),
    };
    if (params && params.notification_id) {
      requestParams.notification_id = params.notification_id;
    }

    return requestParams;
  }

  private mixpanelRequest(name: NotificationEventName, value?: { action_name: string }): void {
    const textRequest = `Notification - ${name}`;
    if (value) {
      this.MixpanelService.track(textRequest, value);
    } else {
      this.MixpanelService.track(textRequest);
    }
  }
}
