import angular from 'angular';
import { DeleteViewModalComponent } from './delete-view-modal.component';

const MODULE_NAME = 'app.global.shipments-views-delete-view-modal';

angular
  .module(MODULE_NAME, ['app.global.services.shipment-views'])
  .component('esShipmentsViewsDeleteViewModal', DeleteViewModalComponent);

export default MODULE_NAME;
