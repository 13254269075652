import type { IData, UndefinableCustomVariables } from './types';

class SurveySparrowService {
  static $inject = ['$window'];
  constructor(private $window: ng.IWindowService) {}

  mount(data: IData) {
    if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
      const customVariables = this.transformToCustomVariables(data);

      this.$window.SparrowLauncher('popup', {
        email: data.user.email,
        variables: customVariables,
        disableDebugLog: true,
        createdAt: new Date(data.user.created_at).getTime(),
      });
    }
  }

  private transformToCustomVariables({
    user,
    company,
    activity,
    storeData,
  }: IData): UndefinableCustomVariables {
    const storeGroups = storeData?.stores
      .map((store) => store.platform?.group)
      .filter((item, index, array) => array.indexOf(item) === index)
      .join(',');

    const customVariables: UndefinableCustomVariables = {
      user_id: user.id,
      user_signup_date: user.created_at,
      role: user.role?.name,
      first_name: user.first_name,
      last_name: user.last_name,
      persona: user.persona || undefined,
      locale: user.dashboard_settings?.language?.code,
      company_name: company.name,
      company_signup_date: company.created_at,
      company_type: company.service,
      easyship_company_id: company.easyship_company_id,
      company_id: company.id,
      origin_country: company.country_name,
      api_saved: storeGroups,
      checkout_successful: activity?.checkout_successful?.toString(),
      first_label_cancelled_date: activity?.first_label_cancelled_date,
      first_label_generated_date: activity?.first_label_generated_date,
      first_shipment_created_date: activity?.first_shipment_created_date,
      first_shipment_delivered_date: activity?.first_shipment_delivered_date,
      has_auto_sync: activity?.has_auto_sync?.toString(),
      has_branded_email: activity?.has_branded_email?.toString(),
      has_branded_page: activity?.has_branded_page?.toString(),
      has_late_shipment: activity?.has_late_shipment?.toString(),
      has_lost_shipment: activity?.has_lost_shipment?.toString(),
      has_lyoc: activity?.has_lyoc?.toString(),
      has_missed_pickup: activity?.has_missed_pickup?.toString(),
      has_subscription_payment_method: activity?.has_subscription_payment_method?.toString(),
      insured_shipments_count: activity?.insured_shipments_count,
      labels_generated_count_l30d: activity?.labels_generated_count_l30d,
      labels_generated_count_l7d: activity?.labels_generated_count_l7d,
      labels_generated_count_total: activity?.labels_generated_count_total,
      last_label_generated_date: activity?.last_label_generated_date,
      last_shipment_created_date: activity?.last_shipment_created_date,
      orders_last_30_days: activity?.orders_last_30_days,
      products_count: activity?.products_count,
      saved_boxes_count: activity?.saved_boxes_count,
      shipping_rules_count: activity?.shipping_rules_count,
      subscription_plan_id: activity?.subscription_plan_id,
      subscription_plan_status: activity?.subscription_plan_status,
      trial_expiry_date: activity?.trial_expiry_date || undefined,
    };

    return customVariables;
  }
}

export { SurveySparrowService };
