import {
  BarCodeListener,
  BarCodeListenerProps,
  BarCodeListenersByHandlerName,
  KeyShortcutsListener,
} from '@/types/events';
import angular from 'angular';

/** bar code  */
const MIN_BARCODE_LENGTH = 4;
const MAX_BARCODE_DELAY_TIME_MS = 200;
const MIN_TIME_READING_ONE_CHAR_BARCODE = 25;

const barCodeArrayListeners = [] as BarCodeListener[];

export const unsubscribeBarCodeListener = (listener: BarCodeListener) => {
  const index = barCodeArrayListeners.indexOf(listener);
  if (index === -1) return;
  barCodeArrayListeners.splice(index, 1);
};

export const subscribeBarCodeListener = (listener: BarCodeListener) => {
  unsubscribeBarCodeListener(listener);
  barCodeArrayListeners.push(listener);
};

/**  barCodeListenersByName  */

const barCodeMapListenerByHandlerName = new Map<string, BarCodeListenersByHandlerName>();

export const unsubscribeBarCodeByHandlerNameListener = (name: string) => {
  if (!name) return;
  barCodeMapListenerByHandlerName.delete(name);
};

export const subscribeBarCodeByHandlerNameListener = (
  name: string,
  listener: BarCodeListenersByHandlerName
) => {
  if (!name || !listener) return;
  barCodeMapListenerByHandlerName.set(name, listener);
};

/** end barcode listener by name */

const processBarCode = (value: string) => {
  if (!barCodeArrayListeners.length) return;
  const barCode = {
    value,
  };
  const listener = barCodeArrayListeners[barCodeArrayListeners.length - 1];
  listener(barCode);
};

/** * Key Shortcuts listeners */
const MIN_TIME_KEY_PRESSED = 50;
const MAX_TIME_KEY_PRESSED = 1000;

const mapKeyPressedListener = new Map<string, KeyShortcutsListener[]>();

export const unsubscribeKeyListener = (key: string | string[], listener: KeyShortcutsListener) => {
  const keys = (Array.isArray(key) ? key : [key]).map((k) => k.toUpperCase());
  keys.forEach((key) => {
    const keyListeners = mapKeyPressedListener.get(key);
    if (!keyListeners) return;
    const index = keyListeners.indexOf(listener);
    if (index === -1) return;
    keyListeners.splice(index, 1);
    if (!keyListeners.length) mapKeyPressedListener.delete(key);
  });
};

export const subscribeKeyListener = (key: string | string[], listener: KeyShortcutsListener) => {
  const keys = (Array.isArray(key) ? key : [key]).map((k) => k.toUpperCase());
  keys.forEach((key) => {
    let keyListeners = mapKeyPressedListener.get(key);
    if (!keyListeners) {
      keyListeners = [];
      mapKeyPressedListener.set(key, keyListeners);
    }
    unsubscribeKeyListener(key, listener);
    keyListeners.push(listener);
  });
};

/** process  bar code */
const mapKeyDownEvents = new Map<string, KeyboardEvent>();
let keysEvents = [] as KeyboardEvent[];

const removeOutOfDelay = (timeStamp: number) => {
  if (!keysEvents.length) return;
  const timeGap = Math.max(
    keysEvents.length * MIN_TIME_READING_ONE_CHAR_BARCODE,
    MAX_BARCODE_DELAY_TIME_MS
  );
  const time = timeStamp - timeGap;
  const index = keysEvents.findIndex((s) => s.timeStamp > time);
  if (index === 0) return;
  if (index === -1) {
    keysEvents = [];
    return;
  }
  keysEvents = keysEvents.slice(index);
};

const keysEventsAsBarCode = () => {
  if (keysEvents.length < MIN_BARCODE_LENGTH) return;

  const barCodeTarget = (keysEvents[0].target as HTMLElement)?.dataset?.barCode;
  const isSameSource = keysEvents.every(
    (e) => barCodeTarget === (e.target as HTMLElement)?.dataset?.barCode
  );
  if (!isSameSource) return;

  const keys = keysEvents.map((event) => event.key);
  const barCodes = keys.join('').toUpperCase();
  keys.forEach((key) => {
    mapKeyDownEvents.delete(key.toUpperCase());
  });

  const fn =
    (barCodeTarget && barCodeMapListenerByHandlerName.get(barCodeTarget)) || processBarCode;
  fn(barCodes);
  keysEvents = [];
};

/** barCodeTimer  */
let barCodeTimer: ReturnType<typeof setTimeout> | null = null;

const clearBarCodeTimeOut = () => {
  if (barCodeTimer) {
    clearTimeout(barCodeTimer);
    barCodeTimer = null;
  }
};

const setBarCodeTimout = () => {
  barCodeTimer = setTimeout(() => {
    barCodeTimer = null;
    keysEventsAsBarCode();
  }, MAX_BARCODE_DELAY_TIME_MS);
};

export const barCodeProcessReset = () => {
  keysEvents = [];
  clearBarCodeTimeOut();
};

const init = () => {
  window.addEventListener('keyup', (event: KeyboardEvent) => {
    if (!event?.key) return;
    const eventKey = event.key?.toUpperCase();
    const keyDownEvent = mapKeyDownEvents.get(eventKey);
    if (keyDownEvent) {
      const time = event.timeStamp - keyDownEvent.timeStamp;
      if (time > MIN_TIME_KEY_PRESSED && time < MAX_TIME_KEY_PRESSED) {
        const listeners = mapKeyPressedListener.get(eventKey);
        if (listeners?.length) {
          listeners[listeners.length - 1](event);
          barCodeProcessReset();
        }
      }
      mapKeyDownEvents.delete(eventKey);
    }
  });

  window.addEventListener('keydown', (event: KeyboardEvent) => {
    if (!event?.key) return;
    if (keysEvents.length) removeOutOfDelay(event.timeStamp);
    const isBarCodeHandler = !!(event.target as HTMLElement)?.dataset?.barCode;
    if (event.key.length === 1) {
      if (!isBarCodeHandler) mapKeyDownEvents.set(event.key.toUpperCase(), event);
      keysEvents.push(event);
      clearBarCodeTimeOut();
      if (keysEvents.length >= MIN_BARCODE_LENGTH) setBarCodeTimout();
    }
  });
};
init();

const processBarCodeByScanService = ({ value }: BarCodeListenerProps) => {
  const service: {
    processBarCodeScanning: (data: string) => void;
  } = angular.element(document.body).injector().get('ScanService');
  service?.processBarCodeScanning(value);
};

export const addDefaultBarCodeListener = () => {
  subscribeBarCodeListener(processBarCodeByScanService);
};
