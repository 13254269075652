import template from './flat-rates-confirmation.html?raw';
import style from './flat-rates-confirmation.module.scss';

class FlatRatesConfirmationController {
  static $inject = ['$timeout', 'API'];

  constructor($timeout, API) {
    this.$timeout = $timeout;
    this.API = API;

    this.style = style;
    this.loading = false;
  }

  onBack() {
    this.close();

    this.$timeout(() => {
      this.openShipmentRatesModal();
    }, 300);
  }

  onSubmit() {
    this.loading = true;

    this.changeCourier()
      .then(() => {
        this.close();
      })
      .catch(() => {
        this.close();

        this.$timeout(() => {
          this.openShipmentRatesModal();
        }, 300);
      });
  }
}

const FlatRatesConfirmationComponent = {
  template,
  controller: FlatRatesConfirmationController,
  bindings: {
    close: '&',
    changeCourier: '&',
    openShipmentRatesModal: '&',
  },
};

export { FlatRatesConfirmationComponent };
