import angular from 'angular';

import '@client/src/global/User';
import '@client/app/factories/UserSession/UserSession.service';
import '@client/app/services/StoreService/StoreService.service';
import '@client/src/global/Report';
import mixpanelService from '@client/core/services/mixpanel';
import { OnboardingChecklistService } from './onboarding-checklist.service';

const MODULE_NAME = 'app.global.services.onboarding-checklist';

angular
  .module(MODULE_NAME, [
    mixpanelService,
    'app.global.user',
    'app.factory.UserSession',
    'app.service.StoreService',
  ])
  .service('OnboardingChecklistService', OnboardingChecklistService);

export default MODULE_NAME;
