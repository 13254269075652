import React, { useState, useMemo, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import COLORS from '@client/src/colors';
import Button from '@client/core/components/react/Button';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { Platform } from '@client/data/platform';
import PlatformButton from './PlatformButton';
import { useOnboarding } from '../../hooks/OnboardingProvider';

const useStyles = makeStyles((theme) => ({
  platformSection: {
    maxWidth: 720,
    margin: 'auto',
  },
  platformContainer: {
    marginBottom: 12,
  },
  submitButton: {
    padding: '30px 108px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 60px',
    },
    '&.disabled': {
      color: 'white',
    },
  },
}));

function PlatformSection() {
  const [isAllOptionsVisible, setAllOptionsVisible] = useState<boolean>(false);

  const classes = useStyles();
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { isCrowdfunding, selectedPersona, selectedPlatforms, publicPlatforms, sendUpdateRequest } =
    useOnboarding();

  const isDisabled = selectedPlatforms.length === 0;

  const hasMixpanelSent = useRef(false);

  const platformList = useMemo(() => {
    return publicPlatforms
      .filter((platform) => {
        return (
          isAllOptionsVisible ||
          (isCrowdfunding && platform.crowdfunding) ||
          (!isCrowdfunding && !platform.crowdfunding && platform.featured)
        );
      })
      .map((platform) => {
        return {
          displayName: platform.display_name as string,
          platformName: platform.css_class as Platform,
        };
      });
  }, [isAllOptionsVisible, isCrowdfunding, publicPlatforms]);

  const handleAllOptionsVisible = () => {
    MixpanelService.track(`Onboarding - Declared Platforms - See All Clicked`);

    setAllOptionsVisible(true);
  };

  const handleNoneClick = () => {
    const params = {
      platforms: ['none_of_listed'] as Platform[],
    };

    MixpanelService.track(`Onboarding - Declared Platforms - Selected`, {
      persona: selectedPersona,
      platforms: 'none_of_listed',
    });

    sendUpdateRequest(params);
  };

  const handleSkip = () => {
    const params = {
      platforms: ['selection_skipped'] as Platform[],
    };

    MixpanelService.track(`Onboarding - Declared Platforms - Skipped`);

    sendUpdateRequest(params);
  };

  const handleSubmit = () => {
    const params = {
      platforms: selectedPlatforms,
    };

    MixpanelService.track(`Onboarding - Declared Platforms - Selected`, {
      persona: selectedPersona,
      platforms_selected_count: selectedPlatforms.length,
      platforms: selectedPlatforms.join(','),
    });

    sendUpdateRequest(params);
  };

  const sendMixpanelArriveEvent = () => {
    if (!hasMixpanelSent.current) {
      MixpanelService.track(`Onboarding - Declared Platforms - Arrive`);
      hasMixpanelSent.current = true;
    }
  };

  useEffect(() => {
    sendMixpanelArriveEvent();
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent={isScreenSm ? 'flex-start' : 'center'}
      alignItems="center"
      className={classes.platformSection}
    >
      <Grid item>
        <Box marginBottom={isScreenSm ? 2 : 4}>
          <Typography
            variant="h2"
            style={{ fontSize: isScreenSm ? 20 : 28, color: COLORS.inkDarkest }}
          >
            <FormattedMessage id="onboarding.platform.header" />
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box marginBottom={isScreenSm ? 4 : 8}>
          <Typography variant="h4" align={isScreenSm ? 'center' : 'left'}>
            <FormattedMessage id="onboarding.platform.description" />
          </Typography>
        </Box>
      </Grid>
      <Grid container justifyContent="center" className={classes.platformContainer}>
        {platformList.map((platformProps) => (
          <PlatformButton key={platformProps.platformName} {...platformProps} />
        ))}
      </Grid>
      <Grid item>
        <Box marginBottom={isScreenSm ? 4 : 12}>
          <Button onClick={isAllOptionsVisible ? handleNoneClick : handleAllOptionsVisible}>
            <FormattedMessage
              id={
                isAllOptionsVisible
                  ? 'onboarding.platform.button.none'
                  : 'onboarding.platform.button.integration'
              }
            />
            {!isAllOptionsVisible && (
              <i className="icon-chevron-down text__color-ink-light m-l-12" />
            )}
          </Button>
        </Box>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
        <Grid item>
          <Button onClick={handleSkip}>
            <FormattedMessage
              id={
                isCrowdfunding && !isAllOptionsVisible
                  ? 'onboarding.platform.button.none'
                  : 'global.skip'
              }
            />
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.submitButton, { disabled: isDisabled })}
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            <FormattedMessage id="onboarding.platform.button.submit" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PlatformSection;
