import angular from 'angular';
import { react2angular } from 'react2angular';

import ShipmentActionDropdown, { WrappedShipmentActionDropdown } from './ShipmentActionDropdown';

export default ShipmentActionDropdown;

export const AngularShipmentActionDropdown = react2angular(WrappedShipmentActionDropdown, [
  'onViewDetails',
  'onCancelShipment',
  'onPrintAllLabels',
  'onPrintCommercialInvoice',
  'onDuplicateShipment',
  'onRescheduleHandover',
  'onScheduleHandover',
  'onCreateReturnLabel',
  'onMarkAsDelivered',
  'onEditShipment',
  'onStartInsuranceClaim',
  'markAsDeliveredModalIsOpen',
  'markAsDeliveredModalOnClose',
  'markAsDeliveredModalOnConfirm',
  'shipmentListItem',
]);
export const shipmentActionDropdownModuleName = 'app.components.shipment-action-dropdown';

angular
  .module(shipmentActionDropdownModuleName, [])
  .component('esShipmentActionDropdown', AngularShipmentActionDropdown);
