import angular from 'angular';

import { BulkEditService } from './bulk-edit.service';

const MODULE_NAME = 'app.global.services.bulk-edit';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', 'ngResource'])
  .service('BulkEditService', BulkEditService);

export default MODULE_NAME;
