import template from './platform-redirect.html?raw';

('use strict');

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.platform-redirect', {
      url: '^/redirect',
      template,
      controller: 'platformRedirectCtrl as vm',
    });
  },
]);
