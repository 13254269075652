import COLORS from '@client/src/colors';
import Box from '@material-ui/core/Box';
import React from 'react';

const icons = {
  warning: <Box component="i" color={COLORS.yellowDark} className="icon-info" />,
  error: <Box component="i" color={COLORS.redDark} className="icon-info" />,
};

export interface AlertIconProps {
  severity: keyof typeof icons;
}

export default function AlertIcon({ severity }: AlertIconProps) {
  return icons[severity];
}
