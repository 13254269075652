import { IShowPaymentMethodModal } from 'typings/payment-method';
import { PaymentType } from '@client/data/payment';
import { IComponentController } from 'angular';
import style from './add-credits-flow.module.scss';
import template from './add-credits-flow.html?raw';

class AddCreditsFlow implements IComponentController {
  style = style;
  esShowModal: IShowPaymentMethodModal = {
    choosePayment: false,
    creditCard: false,
    paypal: false,
    bankTransfer: false,
  };
  busyAdding = false;

  $onInit() {
    if (!this.esShowModal) {
      throw new Error('Add credit flow: An es Show Modal binding must be provided.');
    }
  }

  closePaymentMethod(): void {
    this.esShowModal.choosePayment = false;
  }

  closeAddByCard(): void {
    this.esShowModal.creditCard = false;
  }

  closeAddByPaypal(): void {
    this.esShowModal.paypal = false;
  }

  closeAddByBank(): void {
    this.esShowModal.bankTransfer = false;
  }

  choosePaymentMethod(paymentType: string): void {
    switch (paymentType) {
      case PaymentType.Paypal:
        this.closePaymentMethod();
        this.esShowModal.paypal = true;
        break;
      case PaymentType.CreditCard:
        this.closePaymentMethod();
        this.esShowModal.creditCard = true;
        break;
      case PaymentType.BankTransfer:
        this.closePaymentMethod();
        this.esShowModal.bankTransfer = true;
        break;
    }
  }
}

const AddCreditsFlowComponent: ng.IComponentOptions = {
  controller: AddCreditsFlow,
  template,
  bindings: {
    esShowModal: '=?',
  },
};

export { AddCreditsFlowComponent };
