import debounce from 'lodash.debounce';

import template from './tabs.html?raw';
import style from './tabs.module.scss';

class Tabs {
  static $inject = ['$element', '$timeout', '$window'];

  constructor($element, $timeout, $window) {
    this.style = style;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$window = $window;
  }

  $onInit() {
    this.resizeEventListener = debounce(this._resizeEventListener.bind(this), 250);

    // Ensure dom is ready first
    this.$timeout(() => {
      this._updateBorderRadius();
      this._updateIndicatorState(this.esSelected);
      this.$window.addEventListener('resize', this.resizeEventListener);
    });
  }

  $onChanges() {
    // Ensure dom is ready first
    this.$timeout(() => {
      this._updateIndicatorState(this.esSelected);
    });
  }

  $onDestroy() {
    this.$window.removeEventListener('resize', this.resizeEventListener);
  }

  onChange(tab) {
    this._updateIndicatorState(tab);
    this.esHandleChange({ value: tab });
  }

  _resizeEventListener() {
    this.$timeout(() => {
      this._updateBorderRadius();
      this._updateIndicatorState(this.esSelected);
    });
  }

  // Updates border radius to match card-title. Checks whether the last tab touches the end of the card, and updates the border radius if so
  _updateBorderRadius() {
    const cardTitleEl = this._getCardTitleEl();

    if (cardTitleEl) {
      const cardTitleStyle = this.$window.getComputedStyle(cardTitleEl);
      const firstTabEl = this.$element.find('easyship-tab')[0];
      const lastTabEl = angular.element(
        `[id="${this.esOptions[this.esOptions.length - 1]}--tab"]`
      )[0];

      const lastTabRect = lastTabEl.getBoundingClientRect();
      const cardTitleRect = cardTitleEl.getBoundingClientRect();

      firstTabEl.style.borderTopLeftRadius = cardTitleStyle.borderTopLeftRadius;

      // Sets the last tab's borderTopRightRadius to match the container if the last tab is touching the right side of the container
      lastTabEl.style.borderTopRightRadius =
        Math.round(lastTabRect.right) === Math.round(cardTitleRect.right)
          ? cardTitleStyle.borderTopRightRadius
          : '0px';
    }
  }

  // Get card title element if tabs is nested inside
  _getCardTitleEl() {
    const cardTitle = angular.element('card-title');

    return cardTitle && cardTitle.find(this.$element)[0] ? cardTitle[0] : null;
  }

  // Renders the highlight indicator for the tabs and updates the position to match the selected tab's width and position
  _updateIndicatorState(tab) {
    const currentTabEl = angular.element(`[id="${tab}--tab"]`)[0];
    const containerEl = this.$element[0];

    const currentTabRect = currentTabEl.getBoundingClientRect();
    const containerRect = containerEl.getBoundingClientRect();
    const containerStyle = this.$window.getComputedStyle(containerEl);

    this.indicatorLeft =
      currentTabRect.left - containerRect.left - parseFloat(containerStyle.paddingLeft);
    this.indicatorWidth = currentTabRect.width;
  }
}

const TabsComponent = {
  controller: Tabs,
  template,
  bindings: {
    esSelected: '<',
    esOptions: '<',
    esHandleChange: '&',
  },
};

export { TabsComponent };
