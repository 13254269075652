import template from './table-row.html?raw';
import style from './table-row.module.scss';

class TableRow {
  static $inject = ['$transclude', '$element'];

  constructor($transclude, $element) {
    this.style = style;
    this.$transclude = $transclude;
    this.$element = $element;
  }

  $postLink() {
    if (this._isTableHead()) return;

    this.checkboxInput = this.$element[0].querySelector("input[type='checkbox']");
    this.hasCheckbox = !!this.checkboxInput;
  }

  handleClick($event) {
    const divAncestor = $event.target.closest('div');
    const isTargetCheckbox = /checkbox/i.test(divAncestor.className);

    if (isTargetCheckbox || this._isTableHead() || !this.hasCheckbox) return;

    $event.preventDefault();
    $event.stopPropagation();

    if (this.onCheck) this.onCheck({ value: !this.checkboxInput.checked });
    if (this.onClick) this.onClick();
  }

  _isTableHead() {
    return this.$element[0].parentNode.nodeName === 'TABLE-HEAD';
  }
}

const TableRowComponent = {
  template,
  controller: TableRow,
  transclude: true,
  bindings: {
    onCheck: '&',
    onClick: '&',
  },
};

export { TableRowComponent };
