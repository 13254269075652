import { z } from 'zod';

const localStorageExpirySchema = z.object({
  value: z.string(),
  expiryDateTime: z.string().datetime(),
});

export class LocalStorageExpiry {
  set(key: string, value: string, expiryDateTime: string): void {
    const item = localStorageExpirySchema.safeParse({
      value,
      expiryDateTime,
    });
    if (item.success) {
      localStorage.setItem(key, JSON.stringify(item.data));
    }
  }

  get(key: string): string | null {
    const storageItem = localStorage.getItem(key);
    if (!storageItem) {
      return null;
    }
    const result = localStorageExpirySchema.safeParse(JSON.parse(storageItem));
    if (!result.success) {
      return null;
    }

    const now = new Date();

    const expiryDateTimeMillisecond = new Date(result.data.expiryDateTime).getTime();

    if (now.getTime() > expiryDateTimeMillisecond) {
      localStorage.removeItem(key);
      return null;
    }
    return result.data.value;
  }
}
