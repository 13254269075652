import angular from 'angular';

import { EditProductsModalBulkEditComponent } from './edit-products-modal-bulk-edit.component';

const MODULE_NAME = 'app.product-listing.edit-products-modal.bulk-edit';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingEditProductsModalBulkEdit', EditProductsModalBulkEditComponent);

export default MODULE_NAME;
