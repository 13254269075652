import template from './rate-tooltip.html?raw';
import style from './rate-tooltip.module.scss';

class RateTooltip {
  static $inject = ['$filter', 'UserSession'];

  constructor($filter, UserSession) {
    this.style = style;
    this.$filter = $filter;
    this.UserSession = UserSession;

    this.taxes = [];
    this.taxesCharge = 0;
    this.currency = this.UserSession.getCompanyCurrency();
    this.translationTags = {
      currency: this._currency.bind(this),
    };
    this.wrappers = {
      a(chunk) {
        return `<a href="https://support.easyship.com/hc/en-us/articles/360000183211-Volumetric-Weight-and-Dimensions" target="_blank" rel="noopener">${chunk}</a>`;
      },
    };
  }

  $onInit() {
    this.isDomestic =
      this.options?.isDomestic ||
      (this.rate?.origin_country?.alpha2 &&
        this.rate.origin_country.alpha2 === this.rate.destination_country?.alpha2);
  }

  get courierAlert() {
    return this.rate.courier_alerts?.join(' ');
  }

  _currency(value) {
    return this.$filter('intlCurrency')(value, this.currency);
  }

  _buildTooltipValues() {
    this.fees = this._buildFeesList(this.options);
    this.totalCharge = this.esTotalCharge || this.rate.total_charge;
    this.totalPaid = this.paid && this.paid.total_charge;
    this._getTaxes(this.options);
    this.showUninsurableMessage = this._isShipmentUninsurable(this.rate);
    this.IsNotEligibleForDiscount = this._isShipmentNotEligibleForDiscount(this.rate);
    this.isLYOC = this._isCompanyOwnCourierSelected(this.rate);
    this.showIossNote = this._showIossNote();
    this.calculationMethod = this.rate.chargeable_weight_calculation_method;
    this.calculationUnitSlug = `shipments.list.cost.${this.calculationMethod}`;
    this.weightUnit = this.UserSession.getCompanyWeightUnit();
  }

  _showIossNote() {
    // TBD: Stricter typing between shipment (which includes regulatory_identifiers) & rate (which does not)
    const regulatoryIdentifiersIsIoss =
      (this.rate.regulatory_identifiers && this.rate.regulatory_identifiers.ioss) ||
      (this.options && this.options.regulatoryIdentifiersIsIoss);

    return regulatoryIdentifiersIsIoss && this.rate.import_tax_non_chargeable > 0;
  }

  _isShipmentUninsurable(shipment) {
    return shipment.is_insured && shipment.insurance_fee === 0;
  }

  _buildFeesList(options = {}) {
    const {
      shipment_charge,
      shipping_cost,
      fuel_surcharge,
      remote_area_surcharge,
      warehouse_handling_fee,
      additional_services_surcharge,
      other_surcharges,
      residential_fee_applied,
      oversized_surcharge,
      insurance_fee,
      import_tax_charge,
      import_duty_charge,
      ddp_handling_fee,
      pickup_fee,
      cost_rank,
      residential_discounted_fee,
      discount,
    } = this.rate;

    const { setAsResidential, identifiedAsResidential } = options;
    const residential_surcharge = this._getResidentialCharge(
      cost_rank,
      residential_discounted_fee,
      residential_fee_applied,
      setAsResidential,
      identifiedAsResidential
    );

    const fees = {
      'shipping-cost': [
        {
          slug: 'shipping',
          value: shipment_charge || shipping_cost,
          paid: this.paid && (this.paid.shipment_charge || this.paid.shipping_cost),
        },
        {
          slug: 'discount',
          value: this._getDiscountAmount(discount),
          paid: this.paid && this._getDiscountAmount(this.paid.discount),
          translationVariables: discount,
        },
      ],
      'shipping-cost-charges': [
        { slug: 'fuel', value: fuel_surcharge, paid: this.paid && this.paid.fuel_surcharge },
        { slug: 'pickup', value: pickup_fee, paid: this.paid && this.paid.pickup_fee },
      ],
      'courier-surcharges': [
        {
          slug: 'remote',
          value: remote_area_surcharge,
          paid: this.paid && this.paid.remote_area_surcharge,
        },
        {
          slug: 'residential',
          value: residential_surcharge,
          paid: this.paid && this.paid.residential_surcharge,
        },
        {
          slug: 'oversized',
          value: oversized_surcharge,
          paid: this.paid && this.paid.oversized_surcharge,
        },
        {
          slug: 'additional',
          value: additional_services_surcharge,
          paid: this.paid && this.paid.additional_services_surcharge,
        },
      ],
      insurance: [
        { slug: 'insurance', value: insurance_fee, paid: this.paid && this.paid.insurance_fee },
      ],
      'warehouse-charges': [
        {
          slug: 'warehouse',
          value: warehouse_handling_fee,
          paid: this.paid && this.paid.warehouse_handling_fee,
        },
      ],
      'taxes-and-duties': [
        { slug: 'tax', value: import_tax_charge, paid: this.paid && this.paid.import_tax_charge },
        {
          slug: 'duty',
          value: import_duty_charge,
          paid: this.paid && this.paid.import_duty_charge,
        },
        {
          slug: 'ddp-handling',
          value: ddp_handling_fee,
          paid: this.paid && this.paid.ddp_handling_fee,
        },
      ],
    };

    if (other_surcharges && other_surcharges.details) {
      const namesOfPaidSurcharges =
        this.paid &&
        this.paid.other_surcharges &&
        this.paid.other_surcharges.details.map(function (surcharge) {
          return surcharge.name;
        });

      other_surcharges.details.forEach(function (surcharge) {
        const paidFeeIndex = namesOfPaidSurcharges
          ? namesOfPaidSurcharges.indexOf(surcharge.name)
          : -1;

        fees['courier-surcharges'].push({
          name: surcharge.name,
          value: surcharge.fee,
          paid:
            paidFeeIndex > -1 &&
            this.paid &&
            this.paid.other_surcharges &&
            this.paid.other_surcharges.details[paidFeeIndex].fee,
        });
      });
    }

    return this._addGroupsHaveCharges(fees);
  }

  _getDiscountAmount(discount = {}) {
    const amount = discount && discount.amount;
    return amount ? -amount : null;
  }

  _addGroupsHaveCharges(fees = {}) {
    Object.keys(fees).forEach((key) => {
      const values = fees[key].map((v) => v.value);

      const sum = values.reduce((accumulator, value) => {
        return value && !Number.isNaN(value) ? accumulator + value : accumulator;
      }, 0);

      fees[key].hasCharges = sum > 0; // eslint-disable-line no-param-reassign
    });

    return fees;
  }

  _getResidentialCharge(
    cost_rank,
    residential_discounted_fee,
    residential_fee_applied,
    setAsResidential,
    identifiedAsResidential
  ) {
    // if identifiedAsResidential always has a value
    // if it is false it means that we know for sure that there will not be any charge applied
    // other values are 'true' or 'not_checked' which both means there is a potential charge
    if (!identifiedAsResidential) return null;

    // cost_rank will be present in the courier comparison
    if (cost_rank && setAsResidential) return residential_discounted_fee;

    // else it's a shipment row
    return residential_fee_applied;
  }

  $onChanges() {
    this._buildTooltipValues();
  }

  _getTaxes(options = {}) {
    this.taxes = [];

    const { sales_tax_name, provincial_sales_tax_name } =
      this.UserSession.user.shipping_country.finance;

    const { sales_tax, provincial_sales_tax, sales_tax_residential_discounted_fee_not_applied } =
      this.rate;

    // Include the sales tax of the residential surcharge if the residential surcharge has been added
    let residentialTax = 0;
    if (options.includeResidentialTax) {
      residentialTax = sales_tax_residential_discounted_fee_not_applied;
    }

    if (sales_tax_name) {
      this.taxes.push({
        label: sales_tax_name,
        value: (sales_tax || 0) + residentialTax,
        paid:
          this.paid &&
          (this.paid.sales_tax || 0) +
            (options.includeResidentialTax
              ? this.paid.sales_tax_residential_discounted_fee_not_applied
              : 0),
      });
    }

    if (provincial_sales_tax_name) {
      this.taxes.push({
        label: provincial_sales_tax_name,
        value: provincial_sales_tax || 0,
        paid: this.paid && (this.paid.provincial_sales_tax || 0),
      });
    }

    this.taxesCharge = this.taxes.reduce((accumulator, tax) => accumulator + tax.value, 0);
    this.taxesPaid = this.taxes.reduce((accumulator, tax) => accumulator + tax.paid, 0);
  }

  _isShipmentNotEligibleForDiscount(shipment = {}) {
    // If there is a discount but no value -> not eligible
    return shipment.discount && shipment.discount.code && shipment.discount.amount <= 0;
  }

  _isCompanyOwnCourierSelected(shipment = {}) {
    return shipment.courier && !shipment.courier.is_easyship_courier;
  }

  getChargeWithoutTax() {
    return this.totalCharge - this.taxesCharge;
  }

  getPaidWithoutTax() {
    return this.totalPaid - this.taxesPaid;
  }

  getPillColor() {
    switch (this.calculationMethod) {
      case 'actual_weight':
        return 'dark';
      case 'volumetric_weight':
        return 'warning';
      default:
        return 'info';
    }
  }
}

const RateTooltipComponent = {
  template,
  controller: RateTooltip,
  bindings: {
    rate: '<',
    paid: '<',
    esTotalCharge: '<',
    placement: '@',
    options: '<',
  },
};

export { RateTooltipComponent };
