import {
  IChangePlanParams,
  IChangePlanRequest,
  IChangePlanResponse,
  IComplete3DSParams,
  IDowngradePlanParams,
  IDowngradePlanRequest,
  IDowngradePlanResponse,
  IPlansDetail,
  IPlansDetailParams,
  ISubscriptionObject,
  ISubscriptionParams,
} from 'typings/subscription';
import { IApiConfig } from 'typings/core/config/api';

class SubscriptionResource {
  plansDetailEndpoint = '';
  currentSubscriptionEndpoint = '';
  changePlanEndpoint = '';
  downgradePlanEndpoint = '';
  applyUpgradeCodeEndpoint = '';
  complete3DSEndpoint = '';

  static $inject = ['$resource', 'API'];
  constructor(private $resource: any, private API: IApiConfig) {
    this.plansDetailEndpoint = `${this.API.endpoint}/plans`;
    this.currentSubscriptionEndpoint = `${this.API.endpoint}/companies/:company_id/current_subscription`;
    this.changePlanEndpoint = `${this.API.endpoint}/companies/:company_id/subscriptions`;
    this.downgradePlanEndpoint = `${this.API.endpoint}/companies/:company_id/subscriptions/preview`;
    // TODO Replaced with a real api when it is ready
    this.applyUpgradeCodeEndpoint = `${this.API.endpoint}/companies/:company_id/subscriptions/upgrade_code`;
    this.complete3DSEndpoint = `${this.API.endpoint}/companies/:company_id/subscriptions/complete_3ds`;
  }

  plansDetail(params: IPlansDetailParams): ng.IPromise<IPlansDetail> {
    return this.$resource(
      this.plansDetailEndpoint,
      {},
      { plansDetail: { method: 'GET', params, cache: true } }
    ).plansDetail(params).$promise;
  }

  currentSubscription(params: ISubscriptionParams): ng.IPromise<ISubscriptionObject> {
    return this.$resource(this.currentSubscriptionEndpoint, params).get().$promise;
  }

  changePlan(
    params: IChangePlanParams,
    payload: IChangePlanRequest
  ): ng.IPromise<IChangePlanResponse> {
    return this.$resource(this.changePlanEndpoint, params, {
      upgrade: {
        method: 'POST',
      },
    }).upgrade(payload).$promise;
  }

  downgradePlan(
    params: IDowngradePlanParams,
    payload: IDowngradePlanRequest
  ): ng.IPromise<IDowngradePlanResponse> {
    return this.$resource(this.downgradePlanEndpoint, params, {
      downgrade: {
        method: 'GET',
      },
    }).downgrade(payload).$promise;
  }

  complete3DS(params: IComplete3DSParams): ng.IPromise<ISubscriptionObject> {
    return this.$resource(this.complete3DSEndpoint, params, {
      confirm: {
        method: 'POST',
      },
    }).confirm().$promise;
  }
}

export { SubscriptionResource };
