import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IPlan } from '../types';

interface IPlanTaxProps {
  plan: IPlan;
  currency: string;
}

export default function PlanTax({ plan, currency }: IPlanTaxProps) {
  const theme = useTheme();
  const { formatNumber } = useIntl();

  if (plan.isCustom || !plan.isTaxed) return null;

  return (
    <Box fontSize={theme.utils.fz.tiny} fontWeight={400} color={theme.palette.ink.main}>
      <FormattedMessage
        id="subscription.table.tax-header"
        values={{
          amount: formatNumber(plan.totalAmount, {
            currency,
            style: 'currency',
          }),
          name: plan.taxName,
        }}
      />
    </Box>
  );
}
