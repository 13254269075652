import { toastError } from '@client/core/components/react/Toastify';

(function () {
  ShipmentList.$inject = [
    '$q',
    '$state',
    '$timeout',
    '$translate',
    'ShipmentCache',
    'UserSession',
    'EndpointService',
    'HelperService',
    'ShipmentListResource',
  ];
  function ShipmentList(
    $q,
    $state,
    $timeout,
    $translate,
    ShipmentCache,
    UserSession,
    EndpointService,
    HelperService,
    ShipmentListResource
  ) {
    // eslint-disable-next-line no-shadow
    const ShipmentList = this;

    ShipmentList.excludedShipments = [];
    ShipmentList.checkedShipments = [];
    ShipmentList.updatedShipments = {};
    ShipmentList.validCheckedCount = 0;
    ShipmentList.numberOfShipmentItems = 0;
    ShipmentList.totalPriceOfShipments = null;
    ShipmentList.isBusy = false;
    ShipmentList.busy = {
      fetching: false,
      shipping: false,
      deleting: false,
      deletingSingle: false,
    };
    ShipmentList.commonWhiteListAttributes = [
      'keyword',
      'offset',
      'limit',
      'scope',
      'created_at',
      'order_created_at',
      'label_paid_at',
      'shipment_items_count',
      'is_return',
      'insurance_scope',
      'warehouse_state',
      'pickup_id',
      'batch_id',
      'manifest_id',
      'package_id',
      'country_id',
      'courier_id',
      'last_status_message_id',
      'store_state',
      'store_id',
      'platform_name',
      'sku',
      'is_valid_to_ship',
      'order_tags',
    ];
    ShipmentList.queryWhiteListAttributes = ['sidebar'].concat(
      ShipmentList.commonWhiteListAttributes
    );
    ShipmentList.payloadWhiteListAttributes = [
      'limit',
      'offset',
      'scope',
      'include',
      'by_search',
    ].concat(ShipmentList.commonWhiteListAttributes);

    ShipmentList.reset = function () {
      ShipmentCache.reset();
      ShipmentList.excludedShipments = [];
      ShipmentList.checkedShipments = [];
      ShipmentList.updatedShipments = {};
      ShipmentList.validCheckedCount = 0;
      ShipmentList.numberOfShipmentItems = 0;
      ShipmentList.totalPriceOfShipments = null;
      ShipmentList.isBusy = false;
      ShipmentList.busy = {
        fetching: false,
        shipping: false,
        deleting: false,
        deletingSingle: false,
      };
    };

    /**
     * @description       Perform a GET request for Shipments and run ShipmentList.insert on success
     *
     * @param  {Object}   listParams: Object containing params specifically related to the ShipmentList,
     *                    such as whether or not to tick the shipments in the view when they are loaded.
     *                    A key variable is the "origin" variable, which tells us which state made the request.
     *                    When the request is finished we will check the origin against the current page to see
     *                    if we should insert the data into the view (calling insert).
     * @param  {Object}   apiParams: Set the API params to use in the GET request, such as "include: shipment_items"
     *                    or "scope: orders_page".
     * @param  {[type]}   origin [description]
     * @param  {[type]}   action [description]
     *
     * @return {Boolean}  Boolean value to determine if there were 0 results from the GET request. If there
     *                    were no results, then we exit out of the function and don't run insert.
     */
    ShipmentList.fetch = function (listParams, apiParams) {
      return $q(function (resolve) {
        ShipmentList.busy.fetching = true;

        const filterApiParams = angular.copy(apiParams);
        if (filterApiParams.valid_to_ship || filterApiParams.valid_to_ship === false) {
          filterApiParams.is_valid_to_ship = {
            operator: 'is_equal_to',
            type: 'boolean',
            values: [JSON.parse(filterApiParams.valid_to_ship.toLowerCase())],
          };
        }

        if (
          typeof filterApiParams.last_status_message_id === 'string' ||
          filterApiParams.last_status_message_id === 0
        ) {
          filterApiParams.last_status_message_id = _normalizeMultiselectValueData(
            filterApiParams.last_status_message_id,
            true
          );
        }

        if (typeof filterApiParams.batch_id === 'string' || filterApiParams.batch_id === 0) {
          filterApiParams.batch_id = _normalizeMultiselectValueData(filterApiParams.batch_id);
        }

        if (typeof filterApiParams.country_id === 'number' && filterApiParams.country_id) {
          filterApiParams.country_id = {
            operator: 'is_any_of',
            type: 'multi_select',
            values: [filterApiParams.country_id],
          };
        }

        if (typeof filterApiParams.courier_id === 'string' && filterApiParams.courier_id) {
          filterApiParams.courier_id = _normalizeMultiselectValueData(filterApiParams.courier_id);
        }

        if (typeof filterApiParams.platform_name === 'string' && filterApiParams.platform_name) {
          filterApiParams.platform_name = _normalizeMultiselectValueData(
            filterApiParams.platform_name
          );
        }

        if (typeof filterApiParams.store_id === 'string' && filterApiParams.store_id) {
          filterApiParams.store_id = _normalizeMultiselectValueData(filterApiParams.store_id);
        }

        const payload = ShipmentList.filterShipmentPayload(filterApiParams);
        ShipmentListResource.getShipmentTotal(
          {
            company_id: UserSession.getCompanyId(),
          },
          payload
        )
          .then(function (res) {
            ShipmentCache.totals = res.totals;
            resolve(ShipmentList.getShipmentList(listParams, payload, res.totals));
          })
          .catch(function () {
            _errorFetchShipment();
          });
      });
    };

    ShipmentList.fetchShipmentTotal = function (payload) {
      return ShipmentListResource.getShipmentTotal(
        {
          company_id: UserSession.getCompanyId(),
        },
        payload
      )
        .then(function (res) {
          ShipmentCache.totals = res.totals;
        })
        .catch(function () {
          _errorFetchShipment();
        });
    };

    ShipmentList.getShipmentList = function (listParams, payload) {
      return $q(function (resolve) {
        ShipmentListResource.getShipmentList(
          {
            company_id: UserSession.getCompanyId(),
          },
          payload
        )
          .then(function (data) {
            if (data && $state.current.name === listParams.origin) {
              const response = {
                origin: listParams.origin,
                totals: ShipmentCache.totals,
              };
              if (data.shipments.length === 0) {
                response.shipments = [];
              } else {
                response.shipments = data.shipments;
              }

              resolve(response);
            }

            $timeout(function () {
              ShipmentList.busy.fetching = false;
            });
          })
          .catch(function () {
            _errorFetchShipment();
          });
      });
    };

    function _errorFetchShipment() {
      toastError(
        $translate.instant('toast.fetch-error', {
          noun: $translate
            .instant('global.pluralize.shipment', { COUNT: 2 }, 'messageformat')
            .toLowerCase(),
        })
      );
      $timeout(function () {
        ShipmentList.busy.fetching = false;
      });
    }

    function _normalizeMultiselectValueData(data, isNumber) {
      let values = data.split(',');
      if (isNumber) {
        values = values.map(Number);
      }

      return {
        operator: 'is_any_of',
        type: 'multi_select',
        values,
      };
    }

    ShipmentList.insertMany = function (listParams) {
      const stateSplit = listParams.origin.split('.');
      const originState = `${stateSplit[0]}.${stateSplit[1]}`;
      // Create a unique check for when users are making the "insert" request from a createModal
      // If a User makes the request from "app.multiple.createModal.fileUpload" then we just check if they are on "app.multiple"
      if (
        $state.current.name === listParams.origin ||
        ($state.current.name === originState && listParams.origin.indexOf('createModal') !== -1) ||
        listParams.origin === 'app.home'
      ) {
        $timeout(function () {
          ShipmentList.reset();
          ShipmentCache.reset();
          $state.go('app.multiple', { page: '1', query: {} }, { reload: true });
        });

        ShipmentList.isBusy = false;
      } else {
        ShipmentList.isBusy = false;
      }
    };

    ShipmentList.insertOne = function (listParams, shipment) {
      ShipmentCache.totals.orders_count += 1;

      if (shipment.is_valid_to_ship) {
        ShipmentCache.totals.valid_to_ship_count += 1;
        ShipmentCache.totals.valid_to_ship_cost += shipment.total_charge;
      }

      const count = 1;

      // If you are on Page 1, you can insert the new Shipment directly into the page
      if (ShipmentCache.pages[1]) {
        ShipmentCache.pages[count].shipments.unshift(shipment);

        // If selectAll => Push the shipment ID to excludedShipments
        if (ShipmentCache.selected.all) {
          shipment.isShipmentChecked = false;
          ShipmentList.excludedShipments.push(shipment.id);
        }

        // After adding the shipment, Page 1 now has more than UserSession.getItemsPerPageValue('multiple') shipments
        if (
          ShipmentCache.pages[2] &&
          ShipmentCache.pages[1].shipments.length > UserSession.getItemsPerPageValue('multiple')
        ) {
          let shipmentToMove = ShipmentCache.pages[count].shipments.pop();

          for (let i = 2; i <= Object.keys(ShipmentCache.pages).length; i++) {
            if (ShipmentCache.pages[i]) {
              ShipmentCache.pages[i].shipments.unshift(shipmentToMove);
              shipmentToMove = ShipmentCache.pages[count + 1].shipments.pop();

              // The shipment to move is now on a page which is not cached
              // Uncheck it and remove it from checkedShipments
              if (!ShipmentCache.pages[i + 1]) {
                if (shipmentToMove.isShipmentChecked) {
                  const checkedIndex = ShipmentList.checkedShipments.indexOf(shipmentToMove);

                  if (checkedIndex > -1) {
                    ShipmentList.checkedShipments.splice(checkedIndex, 1);
                  }
                }

                ShipmentList.updateCosts();
              }
            } else {
              break;
            }
          }
        } else if (
          ShipmentCache.pages[1].shipments.length > UserSession.getItemsPerPageValue('multiple')
        ) {
          // Page 2 is not cached, and Page 1 now has more than UserSession.getItemsPerPageValue('multiple') shipments
          // Remove it from the page
          const shipmentToRemove = ShipmentCache.pages[count].shipments.pop();

          if (shipmentToRemove.isShipmentChecked) {
            const checkedShipmentIndex = ShipmentList.checkedShipments.indexOf(shipmentToRemove);

            if (checkedShipmentIndex > -1) {
              ShipmentList.checkedShipments.splice(checkedShipmentIndex, 1);
            }
          }
        }

        updateShipmentList();
        ShipmentList.isBusy = false;
      } else {
        // No Page 1 cached => Go to Page 1
        ShipmentList.isBusy = false;

        $timeout(function () {
          ShipmentList.reset();
          $state.go('app.multiple', { page: '1' });
        });
      }
    };

    ShipmentList.updateShipment = function (easyshipId, shipment) {
      return $q(function (resolve) {
        const oldShipment = _.find(
          ShipmentCache.pages[ShipmentCache.currentPage].shipments,
          function (o) {
            return o.easyship_shipment_id === easyshipId;
          }
        );
        const checkedIndex = _.findIndex(ShipmentList.checkedShipments, function (o) {
          return o.easyship_shipment_id === easyshipId;
        });
        const cachedPageIndex = _.findIndex(
          ShipmentCache.pages[ShipmentCache.currentPage].shipments,
          function (o) {
            return o.easyship_shipment_id === easyshipId;
          }
        );

        // Set the checked state to the same as it was previously
        shipment.isShipmentChecked = oldShipment.isShipmentChecked;

        // If the shipment was checked, replace it in the checkedShipments Array
        if (shipment.isShipmentChecked) {
          // Was Valid => Now Invalid (Update selected.validCount)
          if (_isShipmentValid(oldShipment) && !_isShipmentValid(shipment)) {
            ShipmentCache.decreaseValidCount(1);
          }

          // Was Invalid => Now Valid (Update selected.validCount)
          else if (!_isShipmentValid(oldShipment) && _isShipmentValid(shipment)) {
            ShipmentCache.increaseValidCount(1);
          }

          ShipmentList.checkedShipments.splice(checkedIndex, 1, shipment);
        }

        // Replace the existing Shipment OR push it to the top of the Array
        if (cachedPageIndex > -1)
          ShipmentCache.pages[ShipmentCache.currentPage].shipments[cachedPageIndex] = shipment;

        ShipmentList.updateCosts();

        resolve(cachedPageIndex);
      });
    };

    /**
     * Delete all the selected Shipments
     *
     * @param  {Object} listParams: Params for the Shipment GET request
     *
     * @return {*}
     */
    ShipmentList.deleteShipments = function (listParams, shipmentIds) {
      return $q(function (resolve) {
        const paramsCopy = angular.copy(EndpointService.params);
        const query = _.omit(paramsCopy, [
          'company_id',
          'company_type',
          'include',
          'limit',
          'offset',
          'scope',
        ]);

        // Check if the user had made a search when running deleteShipments
        if (Object.keys(query).length > 0) {
          // Wait for shipments to re-index
          setTimeout(function () {
            ShipmentList.reset();
            ShipmentCache.reset();
            $state.go(listParams.origin, { page: ShipmentCache.currentPage }, { reload: true });
          }, 1000);

          return;
        }

        const totalsOptions = {
          orders_all: 'orders_count',
          shipments_all: 'in_scope_count',
          pending: 'in_scope_count',
          rejected: 'in_scope_count',
          to_download: 'in_scope_count',
        };

        ShipmentCache.pages[ShipmentCache.currentPage].isGlobalChecked = false;

        // Concat all the Shipments into 1 Array to re-split the pages after deleting several Shipments
        let allShipments = [];
        // Concat all the Shipments
        angular.forEach(Object.keys(ShipmentCache.pages), function (page) {
          allShipments = allShipments.concat(ShipmentCache.pages[page].shipments);
        });

        // Remove the deleted Shipments from the Array of all Shipments (from the view)
        angular.forEach(shipmentIds, function (id) {
          const shipmentIdx = _.findIndex(allShipments, function (shipment) {
            return shipment.id === id;
          });

          // BEFORE running shipment check for invalid/checked => check if shipment Idx > -1
          // for selectAll, the Array of shipmentIds can be in a different order to the page
          // page: 0,1,2 & all: 1,5,0,3,2,4 => it will find the first shipmentId (1) and then throw
          // an error as it cannot find 5 in the current page => cannot find property of an undefined
          // Object

          // Check if Shipment is checked & remove from checkedShipments
          if (shipmentIdx > -1) {
            if (allShipments.length > 0 && allShipments[shipmentIdx].isShipmentChecked) {
              ShipmentList.checkedShipments.splice(
                _.findIndex(ShipmentList.checkedShipments, function () {
                  return allShipments[shipmentIdx].id === id;
                }),
                1
              );

              if (_isShipmentValid(allShipments[shipmentIdx])) ShipmentCache.decreaseValidCount(1);
            }

            allShipments.splice(shipmentIdx, 1);
          }
        });

        // Re-split the allShipments Array back into pages
        const newPages = _.map(allShipments, function (item, index) {
          return index % UserSession.getItemsPerPageValue('multiple') === 0
            ? allShipments.slice(index, index + UserSession.getItemsPerPageValue('multiple'))
            : null;
        }).filter(function (item) {
          return item;
        });

        // Before looping through the cached Pages, we can calculate the new offset needed if we need to make a
        // GET request for more Shipments
        const offset =
          (Number(Object.keys(ShipmentCache.pages)[0]) - 1) *
            UserSession.getItemsPerPageValue('multiple') +
          allShipments.length;

        // Loop through all cached Pages to check for any required changes to the user's view
        processArray(Object.keys(ShipmentCache.pages), function (page, index) {
          page = Number(page);

          // Set the currentPage to the equivalent newPage or an empty Array if it no longer exists
          const currentPageLength = newPages[index] ? newPages[index].length : 0;
          const currentPage = newPages[index] ? newPages[index] : [];

          // Check the equivalent newPage to see if there is another page after it
          // If so, we will have to check to see if we need to fetch more Shipments or alter the
          // Shiments Array further.
          if (
            !newPages[index + 1] &&
            currentPageLength < UserSession.getItemsPerPageValue('multiple')
          ) {
            // Last page
            // Check if there are more Shipments to fetch by comparing the required offset against
            // the total number of Orders available
            if (offset < ShipmentCache.totals[totalsOptions[EndpointService.params.scope]]) {
              // More Shipments to fetch
              const apiParams = angular.copy(EndpointService.params);
              // Only fetch enough to populate the remainder of the Page
              apiParams.limit = UserSession.getItemsPerPageValue('multiple') - currentPageLength;
              apiParams.offset = offset;

              ShipmentList.fetch(listParams, apiParams).then(function (response) {
                // Push the new Shipments to the end of the Page
                if (response.origin === $state.current.name && response.shipments.length > 0) {
                  // Push the new Shipments to the required page and force push it into the cached Pages
                  ShipmentCache.pages[page].shipments = currentPage.concat(response.shipments);

                  // Check all of the Pages to see if one page is now empty. If so, remove it from the Cache
                  processArray(Object.keys(ShipmentCache.pages), function (page) {
                    // Check if page is empty
                    if (ShipmentCache.pages[Number(page)].shipments.length === 0) {
                      ShipmentCache.removePage(Number(page));
                    }
                  }).then(function () {
                    checkLastAvailablePage();
                  });
                }
              });
            } else {
              // No more Shipments to fetch. Check if the Page is now empty and remove it from the Cache.
              if (currentPage.length === 0) {
                ShipmentCache.removePage(page);
              } else {
                ShipmentCache.pages[page].shipments = newPages[index];
              }

              checkLastAvailablePage();
            } // ./End fetch more Shipments check

            // eslint-disable-next-line no-inner-declarations
            function checkLastAvailablePage() {
              // The Pages should now be correctly populated and in sync with the database, so we will check that
              // the User's original Page still exists. The page will not exist if, for example, you delete
              // all the Shipments from the last Page. There are 2 cases to cover:
              // - User deletes all shipments from the last page
              //    => Calculate the last available page and go to that Page
              // - User deletes all shipments from page 1
              //    => Reload page 1 in order to initialize it as an empty page
              // Note: If you delete all shipments from any other random page, the above code will automatically
              // fetch the next Shipments in order to re-populate the page.
              let lastAvailablePage;
              ShipmentCache.totals[totalsOptions[EndpointService.params.scope]] === 0
                ? (lastAvailablePage = 1)
                : (lastAvailablePage = Math.ceil(
                    ShipmentCache.totals[totalsOptions[EndpointService.params.scope]] /
                      UserSession.getItemsPerPageValue('multiple')
                  ));
              if (
                !ShipmentCache.pages[ShipmentCache.currentPage] &&
                (lastAvailablePage !== ShipmentCache.currentPage || lastAvailablePage === 1)
              ) {
                $state.go('app.multiple', { page: lastAvailablePage }, { reload: true });
                resolve();
              } else {
                // User's Page still exists so just update the costs and Cache variables.
                ShipmentList.updateCosts();
                ShipmentCache.calcShipmentRange(ShipmentCache.currentPage);
              }
            }
          } else {
            // Not the last page. Simply force push the Page into the cached Pages.
            ShipmentCache.pages[page].shipments = newPages[index];
          }
        }).then(function () {
          if (ShipmentCache.selected.all) {
            ShipmentCache.selected.all = false;
            ShipmentCache.selected[ShipmentCache.selected.shipmentValidKey] = 0;
            ShipmentList.excludedShipments = [];
            ShipmentList.checkedShipments = [];
            ShipmentList.validCheckedCount = 0;
          }

          resolve();
        });
      });
    };

    /**
     * Check/uncheck a Shipment in the array using the checkbox
     *
     * @param  {Object} shipment:   Shipment to be checked/unchecked
     * @param  {String} origin:     Where the request is coming from
     *
     * @return {*}
     */
    ShipmentList.check = function (checkParams) {
      const shipment =
        ShipmentCache.pages[checkParams.pageNum].shipments[checkParams.shipmentIndex];
      const arrayOfCheckedShipmentsIds = _.map(ShipmentList.checkedShipments, 'id');
      const arrayOfExcludedShipmentsIds = ShipmentList.excludedShipments;
      if (!shipment) return;

      const excludedIndex = arrayOfExcludedShipmentsIds.indexOf(shipment.id);
      const checkedIndex = arrayOfCheckedShipmentsIds.indexOf(shipment.id);

      // Shipment is being checked
      if (
        shipment.isShipmentChecked ||
        (excludedIndex === -1 && checkedIndex === -1 && !shipment.isShipmentChecked)
      ) {
        // If selectAll => Try to remove this shipment from excludedShipments
        if (ShipmentCache.selected.all && excludedIndex > -1 && shipment.isShipmentChecked) {
          ShipmentList.excludedShipments.splice(excludedIndex, 1);
          _increaseCountsByOne(shipment);
        }

        if (checkedIndex === -1) {
          // Not selectAll => Update the checkedShipments Array
          ShipmentList.checkedShipments.push(shipment);
          _increaseCountsByOne(shipment);
          if (!shipment.isShipmentChecked) shipment.isShipmentChecked = true;
        }
      }
      // Shipment is being unchecked
      else {
        // If selectAll => Add this shipment to excludedShipments
        if (ShipmentCache.selected.all && excludedIndex === -1) {
          ShipmentList.excludedShipments.push(shipment.id);
          _decreaseCountsByOne(shipment);
        } else if (checkedIndex > -1) {
          // Not selectAll => Update the checkedShipments Array
          ShipmentList.checkedShipments.splice(checkedIndex, 1);
          ShipmentCache.pages[checkParams.pageNum].isGlobalChecked = false;

          _decreaseCountsByOne(shipment);
        }
      }

      // Update the costs
      if (checkParams.origin === 'app.multiple') ShipmentList.updateCosts();
    };

    /**
     * [checkAllValidToShip] Check all shipments valid to ship
     * @param  {[type]} checkParams [description]
     * @return {[type]}             [description]
     */
    ShipmentList.checkAllValidToShip = function (checkParams) {
      if (ShipmentCache.selected.all) return;
      ShipmentCache.pages[checkParams.pageNum].shipments.forEach(function (shipment, index) {
        if (shipment.is_valid_to_ship)
          ShipmentList.check({
            pageNum: ShipmentCache.currentPage,
            shipmentIndex: index,
            invalidOrder: true,
            origin: checkParams.origin,
          });
      });
    };

    /**
     * [checkAllValidToPrint] Check all shipments valid to print
     * @param  {[type]} checkParams [description]
     * @return {[type]}             [description]
     */
    ShipmentList.checkAllValidToPrint = function (checkParams) {
      if (ShipmentCache.selected.all) return;
      ShipmentCache.pages[checkParams.pageNum].shipments.forEach(function (shipment, index) {
        if (shipment.is_valid_to_print)
          ShipmentList.check({
            pageNum: ShipmentCache.currentPage,
            shipmentIndex: index,
            invalidOrder: true,
            origin: checkParams.origin,
          });
      });
    };

    /**
     * Check/uncheck all Shipments in the array
     *
     * @param  {Object} listParams: Params for the Shipment GET request
     */
    ShipmentList.checkAll = function (checkParams) {
      const page = ShipmentCache.pages[checkParams.pageNum];

      page.shipments.forEach(function (element, index) {
        const shipment = page.shipments[index];
        const excludedIndex = ShipmentList.excludedShipments.indexOf(shipment.id);
        const checkedIndex = _.map(ShipmentList.checkedShipments, 'id').indexOf(shipment.id);

        const isPresentInExcludedShipmentArray = excludedIndex > -1;
        const isNotPresentInExcludedShipmentArray = !isPresentInExcludedShipmentArray;

        const isPresentInCheckedShipmentArray = checkedIndex > -1;
        const isNotPresentInCheckedShipmentArray = !isPresentInCheckedShipmentArray;

        // Check the shipment
        if (checkParams.deselectAll) {
          shipment.isShipmentChecked = false;
        } else if (page.isGlobalChecked && !shipment.isShipmentChecked) {
          if (ShipmentCache.selected.all && isPresentInExcludedShipmentArray) {
            // If selectAll => Try to remove this shipment from excludedShipments
            ShipmentList.excludedShipments.splice(excludedIndex, 1);
            if (!checkParams.selectAll) {
              _increaseCountsByOne(shipment);
            }
          }

          if (isNotPresentInCheckedShipmentArray) {
            // Not selectAll => Update the checkedShipments Array
            shipment.isShipmentChecked = true;
            ShipmentList.checkedShipments.push(shipment);
            if (!checkParams.selectAll) {
              _increaseCountsByOne(shipment);
            }
          }
        }
        // Uncheck the shipment
        else if (!page.isGlobalChecked && shipment.isShipmentChecked) {
          if (ShipmentCache.selected.all && isNotPresentInExcludedShipmentArray) {
            // If selectAll => Add this shipment to excludedShipments
            ShipmentList.excludedShipments.push(shipment.id);
            _decreaseCountsByOne(shipment);
          }

          if (isPresentInCheckedShipmentArray) {
            // Not selectAll => Update the checkedShipments Array
            shipment.isShipmentChecked = false;
            ShipmentList.checkedShipments.splice(checkedIndex, 1);
            _decreaseCountsByOne(shipment);
          }
        }
      });

      // Update all the costs
      if (checkParams.invalidOrder) ShipmentList.updateCosts();
    };

    ShipmentList.selectAll = function () {
      ShipmentCache.selected.all = true;

      const printableKeyAccessor = mapTotalsAccessorKey(ShipmentCache.selected.shipmentValidKey);
      const reschedulableKeyAccessor = mapTotalsAccessorKey(
        ShipmentCache.selected.shipmentReschedulableKey
      );

      ShipmentCache.selected[ShipmentCache.selected.shipmentValidKey] = angular.copy(
        ShipmentCache.totals[printableKeyAccessor]
      );

      ShipmentCache.selected[ShipmentCache.selected.shipmentReschedulableKey] = angular.copy(
        ShipmentCache.totals[reschedulableKeyAccessor]
      );

      Object.keys(ShipmentCache.pages).forEach(function (pageNum) {
        ShipmentCache.pages[pageNum].isGlobalChecked = true;
        ShipmentList.checkAll({ pageNum, invalidOrder: true, selectAll: true });
      });
    };

    ShipmentList.deselectAll = function () {
      ShipmentCache.selected.all = false;
      ShipmentCache.selected[ShipmentCache.selected.shipmentValidKey] = 0;
      ShipmentCache.selected[ShipmentCache.selected.shipmentReschedulableKey] = 0;
      ShipmentList.excludedShipments = [];
      ShipmentList.checkedShipments = [];

      Object.keys(ShipmentCache.pages).forEach(function (pageNum) {
        ShipmentCache.pages[pageNum].isGlobalChecked = false;
        ShipmentList.checkAll({ pageNum, invalidOrder: true, deselectAll: true });
      });
    };

    ShipmentList.setUpdatedShipments = function () {
      ShipmentList.updatedShipments = _.reduce(
        arguments,
        function (result, value) {
          result[value] = { shown: false };

          return result;
        },
        {}
      );
    };

    /**
     * Map the count names in the totals object with the attributes present in the shipments
     */
    const countMap = {
      is_valid_to_ship: 'valid_to_ship_count',
      is_valid_to_print: 'printable_count',
      is_valid_to_reschedule_pickup: 'valid_to_reschedule_pickup_count',
      is_valid_to_schedule_pickup: 'valid_to_schedule_pickup_count',
    };

    /**
     * We want to match the two by building the attribute accessor in the totals from the one in ShipmentCache
     *
     * @param {String} string
     *
     * @returns {String}
     */
    function mapTotalsAccessorKey(string) {
      return countMap[string];
    }

    /**
     * Recalculate all the costs for the User's checked Shipments
     */
    ShipmentList.updateCosts = function () {
      ShipmentList.totalPriceOfShipments = 0;
      ShipmentList.numberOfShipmentItems = 0;

      angular.forEach(ShipmentList.checkedShipments, function (shipment) {
        ShipmentList.totalPriceOfShipments += shipment.total_charge;
        ShipmentList.numberOfShipmentItems += shipment.shipment_items_count;
      });
    };

    ShipmentList.advancedSearch = function (query, page, sectionName, urlParams, isApplyFilter) {
      sectionName = sectionName || '';

      EndpointService.reset();
      EndpointService.set(page, HelperService.kebabToSnakeCase(sectionName));
      ShipmentCache.reset();
      ShipmentList.reset();

      const params = { page: 1, query: angular.copy(_filterShipmentQuery(query)) };
      if (urlParams) {
        Object.keys(query).forEach(function (key) {
          if (urlParams[key]) {
            urlParams[key] = query[key];
          }
        });
        Object.assign(params, urlParams);
      }

      // Append sectionName if not `shipments_all`
      if (
        EndpointService.params &&
        EndpointService.params.scope &&
        EndpointService.params.scope !== 'shipments_all'
      ) {
        params.sectionName = EndpointService.params.scope;
      }

      return $state.go($state.current.name, params, {
        reload: true,
        inherit: !isApplyFilter,
      });
    };

    ShipmentList.refreshList = function (page) {
      if (!page) return;
      const stateName = `app.${page}`;

      $state.go(
        stateName,
        {
          page: ShipmentCache.currentPage,
          query: $state.params.query,
          reload: true,
        },
        { reload: true }
      );
    };

    function processArray(array, func) {
      const promiseArray = [];

      array.forEach(function (item, index) {
        promiseArray.push($q.when(func(item, index)));
      });

      return $q.all(promiseArray);
    }

    function updateShipmentList() {
      ShipmentList.updateCosts();
      ShipmentList.busy.deletingSingle = false;
      ShipmentCache.calcShipmentRange(ShipmentCache.currentPage);
    }

    function _isShipmentValid(shipment) {
      return shipment[ShipmentCache.selected.shipmentValidKey];
    }

    function _isShipmentReschedulable(shipment) {
      return shipment[ShipmentCache.selected.shipmentReschedulableKey];
    }

    function _increaseCountsByOne(shipment) {
      if (_isShipmentValid(shipment)) ShipmentCache.increaseValidCount(1);
      if (_isShipmentReschedulable(shipment)) ShipmentCache.increaseReschedulableCount(1);
    }

    function _decreaseCountsByOne(shipment) {
      if (_isShipmentValid(shipment)) ShipmentCache.decreaseValidCount(1);
      if (_isShipmentReschedulable(shipment)) ShipmentCache.decreaseReschedulableCount(1);
    }

    ShipmentList.filterShipmentPayload = function (data) {
      return HelperService.filterObjectByKeys(data, ShipmentList.payloadWhiteListAttributes);
    };

    function _filterShipmentQuery(data) {
      return HelperService.filterObjectByKeys(data, ShipmentList.queryWhiteListAttributes);
    }
  }

  angular
    .module('app.service.ShipmentList', ['app.global.services.shipment-list'])
    .service('ShipmentList', ShipmentList);
})();
