(function () {
  function fixShipmentCtrl(FixMonkeyService, fixShipmentModal) {
    const vm = this;

    vm.FixMonkeyService = FixMonkeyService;

    vm.closeModal = function () {
      if (FixMonkeyService.busy) return;

      fixShipmentModal.close();
    };
  }

  fixShipmentCtrl.$inject = ['FixMonkeyService', 'fixShipmentModal'];

  angular.module('app.service.fix-shipment-modal').controller('fixShipmentCtrl', fixShipmentCtrl);
})();
