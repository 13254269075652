import { showToastWithAction } from '@client/core/components/react/ToastWithAction';
import {
  IShipmentListCustomViewsItem,
  IShipmentListDefaultViewsItem,
  IShipmentListItem,
  IShipmentListManageTotalData,
  IShipmentListScope,
  IShipmentResource,
  IShipmentSortByOptions,
  ShipmentLastStatusMessageUpdateResponse,
  SortByAttribute,
  SortDirection,
} from 'typings/shipment';
import { IHelperService } from 'typings/helper';
import { IUserSession } from 'typings/user-session';

import { IShipmentListFiltersData } from '@client/src/global/services/shipment-list/shipment-list-normalize.service';
import { IEndpointService } from 'typings/endpoint';
import { DrawerMenuKey } from '@client/src/global/services/shipment-list/data/drawer';
import { TbcRadioModelValue } from '@client/src/global/services/shipment-list/data/radio-group';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { TagsService } from '@client/src/global/services/tags/tags.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { toastError } from '@client/core/components/react/Toastify';
import { PrintingOptionsService } from '@client/src/global/services/printing-options/printing-options.service';
import { PusherService } from '@client/src/global/services/pusher/pusher.service';
import { Channel } from 'pusher-js';
import { MixpanelEventContext } from '@client/core/services/mixpanel/constant';
import { Id as ToastId } from 'react-toastify';
import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';
import template from './manage-shipments-landing.html?raw';
import style from './manage-shipments-landing.module.scss';

const DEFAULT_LIST_LIMIT = 20;
const LABEL_REJECTED = 'Label Rejected';

class ManageShipmentsLanding implements IComponentController {
  style = style;
  keyword: string | null = null;
  viewsBusy = false;
  listLimit = DEFAULT_LIST_LIMIT;
  sortByOptions: IShipmentSortByOptions[] = [];
  hasReturnsPageBetaAccess = false;
  isReturnPage = false;
  shipmentTypePayload = {};
  claimShipment?: IShipmentListItem;
  private readonly rejectedLabelIds = [120, 121];
  private readonly pickupScheduledId = 107;
  private _nextTimeSlotToastId: ToastId = '';
  private liveShipmentChannel: Channel | null = null;
  private readonly LIVE_SHIPMENT_CHANNEL_NAME = 'private-live-shipment-information';
  private readonly SHIPMENT_LIVE_INFORMATION_UPDATE_EVENT_NAME = 'shipment-live-information-update';

  static $inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$rootScope',
    'UserSession',
    'HelperService',
    'ShipmentListManageService',
    'SubscriptionService',
    'AppCuesService',
    'MixpanelService',
    'EndpointService',
    'TagsService',
    '$translate',
    '$window',
    'PrintingOptionsService',
    'PusherService',
    'Shipment',
  ];

  constructor(
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IScope,
    private UserSession: IUserSession,
    private HelperService: IHelperService,
    private ShipmentListManageService: ShipmentListManageService,
    private SubscriptionService: SubscriptionService,
    private AppCuesService: AppCuesService,
    private MixpanelService: MixpanelService,
    private EndpointService: IEndpointService,
    private TagsService: TagsService,
    private $translate: angular.translate.ITranslateService,
    private $window: ng.IWindowService,
    private PrintingOptionsService: PrintingOptionsService,
    private PusherService: PusherService,
    private Shipment: IShipmentResource
  ) {
    this.closeClaimResponseModal = this.closeClaimResponseModal.bind(this);
  }

  $onInit(): void {
    this.isReturnPage = this.$state.current.name === 'app.returns';
    this.hasReturnsPageBetaAccess =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_returns_page;
    this.shipmentTypePayload =
      this.hasReturnsPageBetaAccess || this.isReturnPage
        ? {
            tbc: this.isReturnPage ? TbcRadioModelValue.Return : TbcRadioModelValue.Shipment,
          }
        : {};

    // Reset Default state
    this.ShipmentListManageService.resetAll(
      this.$stateParams.from_handover ? JSON.parse(this.$stateParams.from_handover) : false
    );

    const isDirectPrintPending =
      this.$stateParams.purchasedLabelSuccess &&
      this.$stateParams.direct_print_id &&
      this.$stateParams.sectionName === 'shipments-all';

    if (isDirectPrintPending) {
      this.checkDirectPrintStatus(this.$stateParams.direct_print_id);
      this.ShipmentListManageService.openPrintPreviewModal({
        shipmentId: this.$stateParams.direct_print_id,
        context: MixpanelEventContext.MANAGE_SHIPMENT,
        isGenerating: true,
        onClose: () => {
          this.$state.go('app.shipments', {
            direct_print_id: null,
          });
        },
      });
    }
    if (
      this.$stateParams.direct_print_id &&
      this.$stateParams.sectionName === 'shipments_all' &&
      !this.$stateParams.purchasedLabelSuccess
    ) {
      this.$state.go('app.shipments', {
        direct_print_id: null,
      });
    }

    // Set page number and limit
    if (this.$stateParams && this.$stateParams.page) {
      this.ShipmentListManageService.pageNum = parseInt(this.$stateParams.page, 10);
    }
    this.ShipmentListManageService.setLimitFromUserSession(
      this.isReturnPage ? 'returns' : 'shipments'
    );

    // Set sorting options
    this.sortByOptions = this.ShipmentListManageService.getSortingOption(
      this.UserSession.isCompanyEfulfilment()
    );

    const {
      batch_id: batchId,
      page: pageNum,
      last_status_message_id: lastStatusMessageId,
      easyship_shipment_ids: esShipmentIds,
    } = this.$stateParams;

    if (batchId && lastStatusMessageId) {
      this.ShipmentListManageService.setShipmentAndTotalBusy();
      this.fetchShipmentsWithPrepareData();
      this.fetchCustomViews();
    } else if (esShipmentIds) {
      if (pageNum) this.ShipmentListManageService.pageNum = pageNum;
      this.onApplyFilter({
        [DrawerMenuKey.ShipmentId]: {
          select: MultiselectComponentModelValue.AnyOf,
          tags: esShipmentIds,
        },
      });
    } else {
      this.fetchCustomViewsWithShipments();
    }
    const companyId = this.UserSession.getCompanyId();
    this.SubscriptionService.destroyCurrentSubscription();
    this.SubscriptionService.fetchCurrentSubscription({
      company_id: companyId,
    });
    this.handleRouterStateChange();
    this.watchShipments();
    this.watchClaimDialogStateChange();
  }

  $onDestroy(): void {
    this.PusherService.unsubscribe(this.LIVE_SHIPMENT_CHANNEL_NAME);
  }

  get showFilter(): boolean {
    return this.ShipmentListManageService.showFilter;
  }

  resetSearch(): void {
    this.$state.go(this.$state.current.name!, { batch_id: null, pickup_id: null });

    this.TagsService.resetSelections();

    if (!this.selectedView) {
      this.keyword = null;
      this.ShipmentListManageService.resetAll();
      this.fetchShipmentsUnfiltered();
      return;
    }

    this.ShipmentListManageService.setKeyword(this.selectedView.keyword || '');

    if (!this.selectedView.filters) {
      this.fetchShipmentsUnfiltered();

      return;
    }

    this.ShipmentListManageService.updateFilter(this.selectedView.filters);
    this.ShipmentListManageService.fetchShipmentEntitiesAndTotalWithFilter(this.searchScope);
  }

  onChangePage(value: number): void {
    this.resetListLimit();
    this.ShipmentListManageService.pageNum = value;
    this.fetchShipments();
  }

  onRowHeaderCheck(value: boolean): void {
    this.ShipmentListManageService.rowHeaderCheck(value);
  }

  selectAll(): void {
    this.ShipmentListManageService.selectAllShipments();
  }

  deselectAll(): void {
    this.ShipmentListManageService.deselectAllShipments();
  }

  onCheckboxChange(value: boolean, index: number): void {
    this.ShipmentListManageService.rowListCheck(value, index);
  }

  onSelectItemPerPage(value: number): void {
    this.ShipmentListManageService.resetShipmentsLimit(
      value,
      this.searchScope,
      this.shipmentInclude
    );
  }

  onInputChange(value: string): void {
    this.keyword = value === '' ? null : value;
  }

  onInputSearch(): void {
    this.$timeout(() => {
      this.ShipmentListManageService.setKeyword(this.keyword);
      this.ShipmentListManageService.resetApplyFilter();
      this.ShipmentListManageService.fetchShipmentTotalWithFilter(this.searchScope);
      this.fetchShipments();
    }, 300);
  }

  onInputActionClick(): void {
    this.MixpanelService.track('Filter Shipment - Open');

    this.ShipmentListManageService.showFilter = !this.ShipmentListManageService.showFilter;
    this.EndpointService.set('shipments', this.searchScope);
  }

  onFilterClose(): void {
    this.ShipmentListManageService.showFilter = false;
  }

  onApplyFilter(data?: IShipmentListFiltersData): void {
    this.resetListLimit();
    this.ShipmentListManageService.setKeyword(this.keyword);
    this.ShipmentListManageService.resetApplyFilter();
    if (data) {
      this.ShipmentListManageService.updateFilter(data);
      this.ShipmentListManageService.fetchShipmentEntitiesAndTotalWithFilter(this.searchScope);
    } else {
      this.fetchShipmentsUnfiltered();
    }

    this.$state.go(this.$state.current.name!, { batch_id: null });
  }

  onSelectView(viewId?: string): void {
    this.resetListLimit();

    if (viewId) {
      this.ShipmentListManageService.updateViewAndShipments(viewId, this.searchScope);
      this.$state.go(this.$state.current.name!, { view_id: viewId });
    } else {
      this.ShipmentListManageService.resetAll();
      this.ShipmentListManageService.setLimitFromUserSession(
        this.isReturnPage ? 'returns' : 'shipments'
      );
      this.fetchShipmentsUnfiltered();
      this.$state.go(this.$state.current.name!, { view_id: null });
    }
  }

  onSaveViewSuccess(view?: IShipmentListCustomViewsItem): void {
    this.fetchCustomViews();
    if (view) {
      this.ShipmentListManageService.updateSelectedView(view);
    }
  }

  onUpdateViewSuccess(view: IShipmentListCustomViewsItem): void {
    this.fetchCustomViews();
    this.ShipmentListManageService.updateSelectedView(view);
  }

  onDeleteView(viewId: string): void {
    if (viewId === this.selectedView?.id) {
      this.onSelectView();
    }
    this.fetchCustomViews();
  }

  onPageNameClick(): void {
    //
  }

  onApplySortBy(sortKey: SortByAttribute): void {
    this.resetListLimit();
    if (sortKey === this.sortBy) {
      const direction = this.isReverseSortOrder(sortKey) ? 'desc' : 'asc';
      if (this.sortDirection === direction) {
        this.ShipmentListManageService.resetSortBy();
      } else {
        this.ShipmentListManageService.applySortBy(sortKey, direction);
      }
    } else {
      this.ShipmentListManageService.applySortBy(
        sortKey,
        this.isReverseSortOrder(sortKey) ? 'asc' : 'desc'
      );
    }
    this.ShipmentListManageService.fetchShipmentEntitiesWithFilter(
      this.searchScope,
      this.shipmentInclude
    );
  }

  setAutoRetryAlertToaster(): void {
    this.$window.localStorage.setItem('autoRetryAlertToaster', 'true');
  }

  loadMore(last: boolean, inview: boolean) {
    if (last && inview && this.listLimit < this.limit) {
      this.listLimit += DEFAULT_LIST_LIMIT;
    }
  }

  closeClaimResponseModal() {
    const { claim, ...params } = this.$stateParams;

    this.claimShipment = undefined;
    this.$state.go('app.shipments', params, { inherit: false });
  }

  get totals(): IShipmentListManageTotalData | null {
    return this.ShipmentListManageService.totals;
  }

  get limit(): number {
    return this.ShipmentListManageService.limit;
  }

  get pageNum(): number {
    return this.ShipmentListManageService.pageNum;
  }

  get shipmentBusy(): boolean {
    return this.ShipmentListManageService.shipmentBusy;
  }

  get totalBusy(): boolean {
    return this.ShipmentListManageService.totalBusy;
  }

  get isEmptyShipment(): boolean {
    return this.shipments.length === 0;
  }

  get shipments(): IShipmentListItem[] {
    return this.ShipmentListManageService.currentShipments ?? [];
  }

  get rowHeaderModel(): boolean {
    const { currentEntity } = this.ShipmentListManageService;
    if (currentEntity && currentEntity.selected) {
      return currentEntity.selected;
    }

    return false;
  }

  get isNotEfulfilmentCompany(): boolean {
    return !this.UserSession.isCompanyEfulfilment();
  }

  get filterShipmentListData(): IShipmentListFiltersData | null {
    return this.ShipmentListManageService.filter;
  }

  get shipmentListKeyword(): string | null {
    return this.ShipmentListManageService.keyword;
  }

  get actionButtonType(): string | null {
    if (!this.filterShipmentListData) {
      return null;
    }

    const isFilterExist = (
      Object.keys(this.filterShipmentListData) as Array<keyof IShipmentListFiltersData>
    ).some((key) => {
      if (this.hasReturnsPageBetaAccess || this.isReturnPage) {
        return (
          this.filterShipmentListData &&
          key !== DrawerMenuKey.TBC &&
          this.filterShipmentListData[key]
        );
      }

      return this.filterShipmentListData && this.filterShipmentListData[key];
    });

    return isFilterExist ? 'teal' : null;
  }

  get selectedShipmentCount(): number {
    return this.ShipmentListManageService.selectedShipmentCount;
  }

  get excludedIds(): string[] {
    return this.ShipmentListManageService.excludedIds;
  }

  get totalShipmentCount(): number {
    return this.totals ? this.totals.in_scope_count : 0;
  }

  get isSelectedAllShipments(): boolean {
    return this.ShipmentListManageService.isSelectedAll;
  }

  get shipmentInclude(): string {
    return this.ShipmentListManageService.shipmentInclude;
  }

  get customViews(): IShipmentListCustomViewsItem[] | null {
    return this.ShipmentListManageService.customViews;
  }

  get defaultViews(): IShipmentListDefaultViewsItem[] | null {
    if (this.hasReturnsPageBetaAccess || this.isReturnPage) return null;

    return this.ShipmentListManageService.defaultViews;
  }

  get selectedView(): IShipmentListDefaultViewsItem | IShipmentListCustomViewsItem | null {
    return this.ShipmentListManageService.selectedView;
  }

  get isUsingView(): boolean {
    return !!this.ShipmentListManageService.selectedView;
  }

  get isCustomViewDifferent(): boolean {
    return this.ShipmentListManageService.isCurrentViewFilterInitialStateChanged(this.isReturnPage);
  }

  get keywordShipmentList(): string | null {
    return this.ShipmentListManageService.keyword;
  }

  get sortBy(): SortByAttribute | null {
    return this.ShipmentListManageService.sortBy;
  }

  get sortDirection(): SortDirection | null {
    return this.ShipmentListManageService.sortDirection;
  }

  get searchScope(): IShipmentListScope {
    return this.HelperService.kebabToSnakeCase(this.$stateParams.sectionName) as IShipmentListScope;
  }

  private async checkDirectPrintStatus(easyshipId: string) {
    try {
      const shipment = await this.getShipmentDetailsById(easyshipId);
      if (shipment.last_status_message?.name === LABEL_REJECTED) {
        this.handleDirectPrintLabelRejected();
      } else {
        this.subscribeToLiveShipmentChannel();
      }
    } catch {
      toastError(this.$translate.instant('global.shipment-not-found'));
    }
  }

  async getShipmentDetailsById(shipmentId: string): Promise<IShipmentListItem> {
    const { shipment } = await this.Shipment.query({
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: shipmentId,
      include:
        'shipment_items,list_of_couriers,status_records,checkpoints,returns,transaction_records,batches,chargeable_weight',
    }).$promise;

    return shipment;
  }

  private async fetchShipmentsWithPrepareData(): Promise<void> {
    try {
      this.ShipmentListManageService.updateFilter(this.shipmentTypePayload);

      // Apply filter from url
      this.ShipmentListManageService.setShipmentBusy();
      await this.ShipmentListManageService.applyFilterFromUrl(this.$stateParams);

      // Fetch total and entities
      this.ShipmentListManageService.fetchShipmentTotalWithFilter(this.searchScope);
      this.fetchShipments();
    } catch (err) {
      toastError(this.$translate.instant('toast.default-error'));
    }
  }

  private fetchShipments(): void {
    this.ShipmentListManageService.fetchShipmentEntitiesWithFilter(
      this.searchScope,
      this.shipmentInclude
    );
  }

  private fetchShipmentsUnfiltered(): void {
    this.ShipmentListManageService.updateFilter(this.shipmentTypePayload);
    this.ShipmentListManageService.fetchShipmentEntitiesAndTotalWithFilter(this.searchScope);
  }

  private fetchCustomViews() {
    return this.ShipmentListManageService.fetchCustomViews(
      this.isReturnPage ? 'returns' : 'shipments_all'
    );
  }

  private fetchCustomViewsWithShipments(): void {
    this.ShipmentListManageService.setShipmentAndTotalBusy();
    this.viewsBusy = true;
    this.fetchCustomViews()
      .then(() => {
        const { defaultViews, customViews } = this.ShipmentListManageService;

        this.ShipmentListManageService.updateViewAndShipmentOnInitPage(
          defaultViews,
          customViews,
          this.isReturnPage ? 'returns' : 'shipments_all',
          (id) => {
            this.updateViewAndShipments(id);
          },
          () => {
            this.fetchShipmentsWithPrepareData();
          },
          () => {
            this.viewsBusy = false;
          },
          this.ShipmentListManageService.selectedView?.id || this.$stateParams.view_id
        );
      })
      .catch(() => {
        this.viewsBusy = false;
      });
  }

  private updateViewAndShipments(viewId: string): void {
    this.ShipmentListManageService.updateViewAndShipments(
      viewId,
      this.searchScope,
      this.$stateParams
    );
    this.viewsBusy = false;
  }

  private isReverseSortOrder(sortKey: SortByAttribute): boolean {
    return this.ShipmentListManageService.isReverseSortOrder(sortKey);
  }

  private resetListLimit(): void {
    this.listLimit = DEFAULT_LIST_LIMIT;
  }

  private watchShipments(): void {
    this.$scope.$watchCollection(
      () => this.shipments,
      (res) => {
        if (res) {
          const messageId = res.find((shipment) =>
            this.rejectedLabelIds.includes(shipment.last_status_message_id)
          );
          if (messageId) {
            this.AppCuesService.track('Manage | Label Generated - Requires Edit', null, true);
          }

          this.$timeout(() => {
            // Ensure AppCues (re)triggers when the shipments are loaded
            // Certain AppCues flows would trigger too early and need the shipments table to be visible
            this.AppCuesService.page();

            // Delay is used as a workaround to ensure the HTML elements are loaded
          }, 0);

          const isAutoRetryAlertToaster =
            this.$window.localStorage.getItem('autoRetryAlertToaster');

          if (isAutoRetryAlertToaster) {
            this.isLatestShipmentsAutoRetried(res);
          }
          if (this.hasAutoRetryShipments(res) && !isAutoRetryAlertToaster) {
            this.showNextTimeSlotToaster();
          }
        }
      }
    );
  }

  private handleRouterStateChange() {
    if (!this.$state.params.claim || this.$state.params.claim === this.claimShipment?.id) return;

    this.ShipmentListManageService.fetchShipment(this.$state.params.claim)
      .then(({ shipment }) => {
        this.claimShipment = shipment;
      })
      .catch(() => toastError(this.$translate.instant('toast.default-error')));
  }

  private watchClaimDialogStateChange(): void {
    this.$rootScope.$on('$stateChangeSuccess', () => this.handleRouterStateChange());
  }

  private showNextTimeSlotToaster(): void {
    this._nextTimeSlotToastId = showToastWithAction(
      this.$translate.instant('pickups.reschedule.next-time-slot-explanation'),
      {
        actionLabel: this.$translate.instant('global.got-it'),
        toastId: this._nextTimeSlotToastId,
      }
    );
    this.setAutoRetryAlertToaster();
  }

  private hasAutoRetryShipments(shipments: IShipmentListItem[]): boolean {
    return shipments.some(
      (shipment) =>
        shipment.preferred_timeslot === false &&
        shipment.last_status_message_id === this.pickupScheduledId
    );
  }

  private isLatestShipmentsAutoRetried(shipments: IShipmentListItem[]): void {
    const lastShipmentsIdStrings = this.$window.localStorage.getItem('lastShipments');
    if (!lastShipmentsIdStrings) return;

    const foundShipments = shipments.filter((shipment) =>
      JSON.parse(lastShipmentsIdStrings).includes(shipment.id)
    );

    const autoRetried = this.hasAutoRetryShipments(foundShipments);
    if (!autoRetried) return;

    this.$window.localStorage.removeItem('lastShipments');
    this.$window.localStorage.removeItem('autoRetryAlertToaster');
    this.showNextTimeSlotToaster();
  }

  private handleDirectPrintLabelRejected() {
    this.ShipmentListManageService.closePrintPreviewModal();
    this.$state.go('app.shipments', {
      direct_print_id: null,
    });
    this.PusherService.unsubscribe(this.LIVE_SHIPMENT_CHANNEL_NAME);
    setTimeout(() => {
      toastError(this.$translate.instant('shipments.direct-print.rejected'), { autoClose: 5000 });
    }, 500);
  }

  private async onShipmentStatusUpdate(data: ShipmentLastStatusMessageUpdateResponse) {
    const labelUpdatedFromPusher = data.shipment.last_status_message?.name ?? '';
    const shipmentLabelState = data.shipment.label_state;

    if (data.shipment.id !== this.$stateParams.direct_print_id) {
      return;
    }

    if (labelUpdatedFromPusher === LABEL_REJECTED) {
      this.handleDirectPrintLabelRejected();
      return;
    }

    if (shipmentLabelState === 'generating') {
      this.ShipmentListManageService.openPrintPreviewModal({
        shipmentId: data.shipment.id,
        context: MixpanelEventContext.MANAGE_SHIPMENT,
        isGenerating: true,
        onClose: () => {
          this.$state.go('app.shipments', {
            direct_print_id: null,
          });
        },
      });
    } else if (this.PrintingOptionsService.printPreviewModalProps?.open) {
      this.ShipmentListManageService.openPrintPreviewModal({
        shipmentId: data.shipment.id,
        context: MixpanelEventContext.MANAGE_SHIPMENT,
        isGenerating: false,
        onClose: () => {
          this.$state.go('app.shipments', {
            direct_print_id: null,
          });
        },
      });
    }
  }

  private subscribeToLiveShipmentChannel() {
    this.liveShipmentChannel = this.PusherService.subscribeWithCompany(
      this.LIVE_SHIPMENT_CHANNEL_NAME
    );

    if (this.liveShipmentChannel) {
      this.PusherService.bind(
        this.liveShipmentChannel,
        this.SHIPMENT_LIVE_INFORMATION_UPDATE_EVENT_NAME,
        this.onShipmentStatusUpdate.bind(this)
      );
    }
  }
}

const ManageShipmentsLandingComponent: ng.IComponentOptions = {
  controller: ManageShipmentsLanding,
  template,
  bindings: {},
};

export { ManageShipmentsLandingComponent };
