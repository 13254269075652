import { PlatformData } from '@client/src/platform/models/platform';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PlatformModalContent } from '@client/src/connect/new-connect/components/PlatformModalContent';

interface PlatformModalFormLayoutProps {
  platform: PlatformData;
}

export function PlatformModalFormLayout({ platform }: PlatformModalFormLayoutProps) {
  return (
    <div className="w-full">
      <div className="flex items-center w-full mb-7">
        <div>
          <img
            alt="Platform logo"
            className="max-h-[60px] max-w-[160px] h-[60px] mr-5"
            src={`https://assets.easyship.com/app/platform-logos/detailed/${platform.cssClass}-detailed.svg`}
          />
        </div>
        <p className="mb-0 flex items-center pl-6 text-lg leading-6 min-h-[40px] text-ink-900 border-l border-sky-500">
          {platform.cssClass === 'api' ? (
            <FormattedMessage id="connect.api-integration" />
          ) : (
            <FormattedMessage id="sidebar.connect" />
          )}
        </p>
      </div>
      <PlatformModalContent platform={platform} />
    </div>
  );
}
