import { ICountryService, ICountryHkDistrict } from 'typings/auth/services/country';
import { IPickupsEditPickupInfo, IPickupEditAddress } from 'typings/pickup';

import { IComponentController } from 'angular';
import { AddressFieldValidatorsMap, IAddressService } from 'typings/address';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './edit-modal.html?raw';
import style from './edit-modal.module.scss';
import { PickupsPageService } from '../pickups-page.service';

type Steps = 'edit' | 'report-issue';

interface AddressFormContainer extends IPickupEditAddress {
  country_id: number;
}

interface EsPickup extends IPickupsEditPickupInfo {
  pickup_address: AddressFormContainer;
}

type Busy = {
  updatingPickup: boolean;
};

class EditModal implements IComponentController {
  style = style;
  esPickup: EsPickup | null = null;
  busy: Busy = {
    updatingPickup: false,
  };
  currentStep: Steps = 'edit';
  hkDistricts: ICountryHkDistrict[] | null = null;
  fieldValidators: AddressFieldValidatorsMap = {};

  static $inject = [
    '$translate',
    '$scope',
    'CountryService',
    'PickupsPageService',
    'AddressService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private $scope: ng.IScope,
    private CountryService: ICountryService,
    private PickupsPageService: PickupsPageService,
    private AddressService: IAddressService
  ) {}

  $onInit(): void {
    if (!this.esPickup) return;

    this.CountryService.getHkDistricts()
      .then((hkDistricts) => {
        this.hkDistricts = hkDistricts.map((district) => {
          return {
            ...district,
            ...{
              label: `${district.district} - ${district.area}`,
            },
          };
        });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });

    this.esPickup.pickup_address.country_id = this.esPickup.country_id;

    this.esPickup.error_messages = [...new Set(this.esPickup.error_messages)];

    this.$scope.$watch(
      () => this.esPickup?.pickup_address.country_id,
      (countryId) => {
        if (!this.esPickup?.pickup_address) return;

        this.AddressService.getFieldValidators(countryId).then((fieldValidators) => {
          this.fieldValidators = fieldValidators;
        });
      }
    );
  }

  esClose(): void {
    // esClose expression bindings, need to add this in order for typescript to successfully compile
  }

  onFieldChange(value: string, field: keyof IPickupEditAddress): void {
    if (!this.esPickup) return;

    this.esPickup.pickup_address[field] = value as never;
  }

  update(form: ng.IFormController): void {
    if (!this.esPickup) return;

    if (form.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));

      return;
    }

    this.busy.updatingPickup = true;

    this.PickupsPageService.updatePickupInfo(this.esPickup.id, {
      pickup_address: this.esPickup.pickup_address,
    })
      .then(() => {
        this.PickupsPageService.getPickups();

        toastSuccess(this.$translate.instant('pickups.edit-modal.success'));

        this.esClose();
      })
      .catch(({ data }) => {
        toastError(data?.errors);
      })
      .finally(() => {
        this.busy.updatingPickup = false;
      });
  }
}

const EditModalComponent: ng.IComponentOptions = {
  controller: EditModal,
  template,
  bindings: {
    esPickup: '<',
    esClose: '&',
  },
};

export { EditModalComponent };
