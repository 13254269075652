import angular from 'angular';
import { IInvitee } from 'typings/user-session';
import { DeleteCompanyInviteParams, InviteePort, SendCompanyInviteParams } from '../ports/invitee';

export default class NgInviteeGateway implements InviteePort {
  private inviteeApi: any;

  public constructor() {
    this.inviteeApi = angular.element(document.body).injector().get<any>('Invitee');
  }

  getCompanyInvitees(companyId: string): Promise<IInvitee[]> {
    return new Promise<IInvitee[]>((resolve, reject) => {
      this.inviteeApi
        .query({ company_id: companyId })
        .$promise.then((res: any) => {
          resolve(res.invitees);
        })
        .catch(reject);
    });
  }

  sendCompanyInvite({ email, companyId, userRoles }: SendCompanyInviteParams): Promise<IInvitee> {
    return this.inviteeApi.create(
      {
        company_id: companyId,
      },
      {
        invitee: {
          email,
          user_roles: userRoles,
        },
      }
    ).$promise;
  }

  deleteCompanyInvitee({ id, companyId }: DeleteCompanyInviteParams): Promise<void> {
    return this.inviteeApi.delete({
      company_id: companyId,
      id,
    }).$promise;
  }
}
