import { useQuery } from '@tanstack/react-query';
import { USE_DASHBOARD_SETTINGS_QUERY_KEY } from '@/hooks/queries/useDashboardSettings/types';
import { useGateways } from '../context/GatewayProvider';

export function useDashboardSettings() {
  const { userGateway } = useGateways();

  return useQuery({
    queryKey: [USE_DASHBOARD_SETTINGS_QUERY_KEY],
    queryFn: () => userGateway.getDashboardSettings(),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
