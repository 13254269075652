import { IComponentController } from 'angular';
import { IStore } from 'typings/store';
import template from './add-products-modal.html?raw';
import style from './add-products-modal.module.scss';

enum Steps {
  Store = 'store',
  Actions = 'action',
  Import = 'import',
  ManualInput = 'manual-input',
}

class AddProductsModal implements IComponentController {
  style = style;
  steps = Steps;
  currentStep: Steps = Steps.Store;
  store: IStore | null = null;

  onStoreSelect(store: IStore): void {
    this.currentStep = Steps.Actions;
    this.store = store;
  }

  onImportSelect(): void {
    this.currentStep = Steps.Import;
  }

  onManualInputSelect(): void {
    this.currentStep = Steps.ManualInput;
  }

  goToStores(): void {
    this.currentStep = Steps.Store;
  }

  goToActions(): void {
    this.currentStep = Steps.Actions;
  }
}

const AddProductsModalComponent: ng.IComponentOptions = {
  controller: AddProductsModal,
  template,
  bindings: {},
};

export { AddProductsModalComponent };
