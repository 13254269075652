(function () {
  'use strict';

  Batch.$inject = ['$resource', 'API'];
  function Batch($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/companies/:company_id/batches',
      {
        company_id: '@_company_id',
      },
      {
        query: {
          method: 'GET',
        },
      }
    );
  }

  angular.module('app.factory.Batch', []).factory('Batch', Batch);
})();
