import { IComponentController } from 'angular';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import template from './add-credits-by-card-modal-card.html?raw';
import style from './add-credits-by-card-modal-card.module.scss';

class AddCreditsByCardModalCard implements IComponentController {
  style = style;
  esCloseAction = () => {};
  stripeErrorMessage = '';
  busyAdding = false;

  static $inject = ['$scope', 'UserRightsService'];
  constructor(private $scope: ng.IScope, private UserRightsService: UserRightsService) {}

  $onInit() {
    this.$scope.$on('finished-adding-via-credit-card', ($event, { closeModal }) => {
      this.busyAdding = false;
      if (closeModal) this.esCloseAction();
    });

    this.$scope.$on('start-adding-via-credit-card', () => {
      this.busyAdding = true;
    });
  }

  addCredits(): void {
    this.$scope.$broadcast('add-credits-by-credit-card');
  }

  get canAddCredit(): boolean {
    return this.UserRightsService.canAddCredit;
  }
}

const AddCreditsByCardModalCardComponent: ng.IComponentOptions = {
  controller: AddCreditsByCardModalCard,
  template,
  bindings: {
    esCloseAction: '&',
  },
};

export { AddCreditsByCardModalCardComponent };
