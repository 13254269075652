import angular from 'angular';
import angularInview from 'angular-inview';

import { QueueListComponent } from './queue-list.component';

const MODULE_NAME = 'app.printing-options.queue-list';

angular
  .module(MODULE_NAME, [
    angularInview,
    'core.config',
    'app.factory.UserSession',
    'app.global.helper',
    'app.global.services.printing-options',
  ])
  .component('esPrintingOptionsQueueList', QueueListComponent);

export default MODULE_NAME;
