import { IApiConfig } from 'typings/core/config/api';

interface IApiGetDocumentsParams {
  company_id: string;
  category?: string;
  billed_at_from?: string;
  billed_at_to?: string;
  keyword?: string;
  limit: number;
  offset: number;
}

interface IApiDownloadDocumentsParams {
  company_id: string;
}

interface IApiDownloadDocumentsPayload {
  finance_documents_ids: string[];
}

class FinanceDocumentsResource {
  endpoint: string;
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(public $resource: ng.resource.IResourceService, public API: IApiConfig) {
    this.endpoint = `${API.endpoint}/companies/:company_id/finance_documents/:controller`;
    this.resource = $resource(
      this.endpoint,
      {},
      {
        download: {
          method: 'POST',
          params: {
            controller: 'download',
          },
        },
      }
    );
  }

  getDocuments(params: IApiGetDocumentsParams) {
    return this.resource.get(params).$promise;
  }

  downloadDocuments(params: IApiDownloadDocumentsParams, payload: IApiDownloadDocumentsPayload) {
    return this.resource.download(params, payload).$promise;
  }
}

export { FinanceDocumentsResource };
