import angular from 'angular';

import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import { AdvertisingModal } from './advertising-modal.service';
import { AdvertisingComponent } from './advertising.component';

angular
  .module('app.tools.advertising', [
    'app.global.user',
    'app.factory.UserSession',
    'app.service.CompanyService',
    'core.components',
    onboardingChecklistService,
  ])
  .component('advertising', AdvertisingComponent)
  .service('AdvertisingModal', AdvertisingModal);
