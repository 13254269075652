import { IPaymentService } from 'typings/payment-service';
import { ISubscriptionObject, IShowChangePlanObject } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import { PaymentFormId } from '@client/data/payment-form-id';

import {
  BillingInterval,
  DefinedSubscriptionPlanId,
  BillingFrequency,
} from '@client/data/subscription';
import { IApiConfig } from 'typings/core/config/api';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { SubscriptionPaymentService } from '@client/src/global/services/subscription/subscription-payment.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './upgrade-billing-info.module.scss';
import template from './upgrade-billing-info.html?raw';

class UpgradeBillingInfo implements IComponentController {
  style = style;
  esLeftText = '';
  esIsEndTrial = true;
  creditCardErrorCode = '';
  busyPaying = false;
  paymentFormId = PaymentFormId.UpdateBillingInfo;
  esToggleState = false;
  showModal: Partial<IShowChangePlanObject> = {
    upgrade: true,
    compare: false,
    downgrade: false,
    enterpriseCall: false,
    callScheduledCard: false,
  };
  billingPeriod = false;
  billingFrequency = BillingFrequency.Month;
  static $inject = [
    '$window',
    '$location',
    '$translate',
    'PaymentService',
    'SubscriptionService',
    'SubscriptionPaymentService',
    'UserSession',
    'MixpanelService',
    'API',
  ];
  constructor(
    private $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private $translate: angular.translate.ITranslateService,
    private PaymentService: IPaymentService,
    private SubscriptionService: SubscriptionService,
    private SubscriptionPaymentService: SubscriptionPaymentService,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService,
    private API: IApiConfig
  ) {}

  $onInit() {
    if (!this.esLeftText) {
      if (this.esIsEndTrial) {
        this.esLeftText = this.$translate.instant('subscription.buttons.more-plans');
      } else {
        this.esLeftText = this.$translate.instant('global.close');
      }
    }

    this.PaymentService.unregisterPaymentForm(PaymentFormId.UpdateBillingInfo);

    const companyId = this.UserSession.getCompanyId();
    if (!this.SubscriptionService.currentSubscription && companyId) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: companyId,
      });
    }

    this.billingFrequency =
      this.currentSubscription?.plan?.interval === BillingInterval.Year
        ? BillingFrequency.Year
        : BillingFrequency.Month;
  }

  get esRightText(): string {
    if (this.esIsEndTrial) {
      return this.$translate.instant('subscription.buttons.confirm-trial-subscription', {
        plan: this.$translate.instant(this.suggestedPlanSlugForEndOfTrial),
      });
    }
    return this.$translate.instant('subscription.buttons.update-billing');
  }

  get modalTitleText(): string {
    if (this.esIsEndTrial) {
      return this.$translate.instant('subscription.buttons.confirm-trial-subscription', {
        plan: this.$translate.instant(this.suggestedPlanSlugForEndOfTrial),
      });
    }
    return this.$translate.instant('subscription.info-card.update-billing');
  }

  esLeftAction() {
    // esLeftAction expression bindings, need to add this in order for typescript to successfully compile
  }

  esToggleAction(_state: { value: boolean }): void {
    // esToggleAction expression bindings, need to add this in order for typescript to successfully compile
  }

  _handlePaymentErrors(err: Error): void {
    this.busyPaying = false;
    const [errorCode] = transformToErrorCodes(err);
    this.creditCardErrorCode = errorCode || 'other';
    toastError(this.$translate.instant('account.payment.notifications.stripe-error'));

    this.esIsEndTrial &&
      this.MixpanelService.track('Subscription - Trial Expired - Confirm Subscription', {
        plan_id: this.suggestedPlanIdForEndOfTrial,
        payment_state: 'failed',
      });
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  async onCardFormSubmit(): Promise<void> {
    if (this.busyPaying) {
      return;
    }

    this.busyPaying = true;
    this.creditCardErrorCode = '';

    this.PaymentService.submitPaymentForm(PaymentFormId.UpdateBillingInfo)
      .then(async (paymentFormResult) => {
        const companyId = this.UserSession.getCompanyId();

        if (paymentFormResult && companyId && this.currentSubscription?.plan) {
          this.SubscriptionPaymentService.paymentFormResultAction(
            paymentFormResult,
            companyId,
            this.currentSubscription.plan.id as DefinedSubscriptionPlanId,
            this.billingFrequency
          )
            .then(() => {
              this.busyPaying = false;
              this.esIsEndTrial &&
                toastSuccess(
                  this.$translate.instant('subscription.upgrade-modal.confirm-trial-pay', {
                    plan: this.SubscriptionService.currentPlanName,
                  })
                );
              this.esIsEndTrial &&
                this.MixpanelService.track('Subscription - Trial Expired - Confirm Subscription', {
                  plan_id: this.suggestedPlanIdForEndOfTrial,
                  payment_state: 'successful',
                });

              this.esLeftAction();
              // refresh page so the current subscription gets refetched and updated
              this.$window.location.href = this.$location.path();
            })
            .catch((err) => this._handlePaymentErrors(err));
        } else {
          this.busyPaying = false;
          toastError(this.$translate.instant('toast.default-error'));
          this.esIsEndTrial &&
            this.MixpanelService.track('Subscription - Trial Expired - Confirm Subscription', {
              plan_id: this.suggestedPlanIdForEndOfTrial,
              payment_state: 'failed',
            });
        }
      })
      .catch((err) => this._handlePaymentErrors(err));

    this.MixpanelService.track('Subscription - Update Billing - Submit Card');
  }

  clearCardError() {
    this.creditCardErrorCode = '';
  }

  updateBillingInfoAction(): void {
    this.onCardFormSubmit();
  }

  toggleAction(value: boolean): void {
    this.esToggleState = value;
    this.billingFrequency = value ? BillingFrequency.Year : BillingFrequency.Month;
    this.esToggleAction({ value });
  }

  get suggestedPlanIdForEndOfTrial() {
    // For user in end-of-trial, the suggested plan id is the same as current subscription plan id
    return this.currentSubscription?.plan?.id;
  }

  get suggestedPlanSlugForEndOfTrial(): string {
    return this.SubscriptionService.currentPlanName;
  }

  get supportUrl(): string {
    return `${this.API.help}/hc/en-us/articles/360035582872-Easyship-Pricing-Plans-Breakdown`;
  }

  get togglePlan(): string {
    return this.esToggleState ? BillingInterval.Year : BillingInterval.Month;
  }
}

const UpgradeBillingInfoComponent: ng.IComponentOptions = {
  controller: UpgradeBillingInfo,
  template,
  bindings: {
    esRightAction: '&',
    esLeftText: '<',
    esLeftAction: '&',
    esCloseAction: '&',
    esShowCloseButton: '<',
    esIsEndTrial: '<',
    esToggleState: '=?',
    esToggleAction: '&',
  },
};

export { UpgradeBillingInfoComponent };
