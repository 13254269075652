import {
  IRegistrationLoginParams,
  IRegistrationLoginPayload,
  IRegistrationSuccessResponse,
  IRegistrationUpdateParams,
  IRegistrationFetchParams,
  IRegistrationPlatformPayload,
  IRegistrationPlatformParams,
  IRegistrationUpdatePayload,
  IRegistrationSignupPayload,
} from 'typings/auth/services/registration';
import { IApiConfig } from 'typings/core/config/api';

class RegistrationResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(public $resource: ng.resource.IResourceService, public API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/:flow/registrations/:id/:controller`,
      {},
      {
        signup: {
          method: 'POST',
          headers: { 'recaptcha-token': this.getToken },
        },
        update: { method: 'PATCH' },
        completeWithCredential: {
          method: 'POST',
          params: { id: '@id', controller: 'complete_with_bearer_token' },
        },
      }
    );
  }

  signup(
    params: IRegistrationLoginParams,
    payload: IRegistrationSignupPayload
  ): ng.IPromise<IRegistrationSuccessResponse> {
    return this.resource.signup(params, payload).$promise;
  }

  update(
    params: IRegistrationUpdateParams,
    payload: IRegistrationUpdatePayload
  ): ng.IPromise<IRegistrationSuccessResponse> {
    return this.resource.update(params, payload).$promise;
  }

  fetch(params: IRegistrationFetchParams): ng.IPromise<IRegistrationSuccessResponse> {
    return this.resource.get(params).$promise;
  }

  completeWithCredential(
    params: IRegistrationFetchParams
  ): ng.IPromise<IRegistrationSuccessResponse> {
    return this.resource.completeWithCredential(params).$promise;
  }

  platform(
    params: IRegistrationPlatformParams,
    payload: IRegistrationPlatformPayload
  ): ng.IPromise<IRegistrationSuccessResponse> {
    const resource = this.$resource(`${this.API.endpoint}/:platform/registrations/:id`);

    return resource.save(params, payload).$promise;
  }

  login(
    params: IRegistrationLoginParams,
    payload: IRegistrationLoginPayload
  ): ng.IPromise<IRegistrationSuccessResponse> {
    const mergeParams = { ...params, controller: 'login' };

    return this.resource.save(mergeParams, payload).$promise;
  }

  private getToken(req: any) {
    const { token } = req.params;
    delete req.params.token;
    return token;
  }
}

export { RegistrationResource };
