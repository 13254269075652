import template from './tools.html?raw';
import ToolsCtrl from './tools.controller';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  '$urlRouterProvider',
  ($stateProvider) => {
    $stateProvider.state('app.tools', {
      abstract: true,
      url: '^/tools',
      template,
      controller: ToolsCtrl,
      controllerAs: '$ctrl',
    });
  },
]);
