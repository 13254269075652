import angular from 'angular';
import { LabelFooterCustomizationComponent } from './label-footer-customization.component';

const MODULE_NAME = 'app.tools.printing-options.label-footer-customization';

angular
  .module(MODULE_NAME, [])
  .component('esLabelFooterCustomization', LabelFooterCustomizationComponent);

export default MODULE_NAME;
