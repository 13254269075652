import mixpanelBrowser from 'mixpanel-browser';
import mixpanelMock from './mixpanel.mock';

const token = import.meta.env.VITE_KEY_MIXPANEL;
const MP_FORCE = JSON.parse(window.localStorage.getItem('MIXPANEL_FORCE') ?? 'false');
const mixpanel =
  MP_FORCE || import.meta.env.VITE_APP_ENVIRONMENT === 'production'
    ? mixpanelBrowser.init(token ?? '', {}, 'MixpanelService')
    : mixpanelMock;

export interface IMixpanelParams {
  [index: string]: any;
}

const PageNameMap: Record<string, string> = {
  '/request-pickup': 'Create Shipments / Advanced / Request Pickup',
  '/order-summary': 'Create Shipments / Advanced / Order Summary',
  '/signup': 'Signup',
  '/basic/receiver-info': 'Create Shipments / Basic Flow / 1. Reciever Information',
  '/basic/add-shipment': 'Create Shipments / Basic Flow / 2. Shipment Information',
  '/basic/choose-courier': 'Create Shipments / Basic Flow / 3. Choose Courier',
  '/advanced/manual-input': 'Create Shipments / Advanced / Add Shipment',
  '/basic/your-info': 'Create Shipments / Basic Flow / 4. Handover Options & Add-ons',
  '/basic/order-summary': 'Create Shipments / Basic Flow / 5. Summary',
  // '/advanced': 'Create Shipments / Advanced',
  // '/shipments': 'Manage Shipments / All',
  // '/dashboard': 'Dashboard',
  // '/login': 'Login',
  // '/shipments/to_download': 'Manage Shipments / To Download',
  // '/quote': 'Get a Quote',
  // '/logout': 'Logout',
  // '/account/subscription': 'Account / Subscription',
  // '/shipments/pending': 'Manage Shipments / Pending',
  // '/shipments/rejected': 'Manage Shipments / Rejected',
  // '/tools/automations': 'Shipping Rules',
  // '/product-listing': 'Product Listing',
  // '/couriers': 'Courier',
  // '/account/payment': 'Account / Payment Methods',
  // '/account/statements': 'Account / Billing',
  // '/connect': 'Connect',
  // '/pickups': 'Pickups',
  // '/advanced/file-upload': 'Create Shipments / Advanced / File Upload',
  // '/account/addresses': 'Account / Addresses',
  // '/tools/printing-options': 'Settings / Printing Options',
  // '/account/company': 'Account / Company Information',
  // '/help': 'Help',
  // '/kickstarter/signup': 'Signup / Kickstarter',
  // '/tools/boxes': 'Settings / Boxes',
  // '/account/profile': 'Account / Profile',
  // '/tools/customer-emails': 'Settings / Customer Emails',
  // '/reschedule-pickup/handover': 'Manage Shipments / Reschedule Handover / 1. Handover Method',
  // '/reschedule-pickup/summary': 'Manage Shipments / Reschedule Handover / 2. Summary',
  // '/tools/packing-slip': 'Settings / Packing Slip',
  // '/account/team': 'Account / Team',
  // '/tools/tracking-page': 'Settings / Tracking Page',
  // '/webhooks': 'Connect / Webhooks',
  // '/tools/insurance': 'Settings / Insurance',
  // '/tools/notifications': 'Settings / Notifcations',
  // '/registration/mobile-tips': 'Signup / Mobile Tips',
  // '/password-new': 'Login / New Password',
  // '/signup/registration/:UUID/address': 'Signup / Step 3. Address Information',
  // '/shipments/to-download': 'Manage Shipments / To Download',
};

const replaceUUIDs = (str: string): string => {
  if (!str) {
    return str;
  }

  // Regular expression to match UUID pattern
  const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/gi;

  // Replace UUIDs in the string with ":UUID"
  return str.replace(uuidRegex, ':UUID');
};

// https://mixpanel.com/help/reference/javascript
class MixpanelService {
  trackPageView(params: IMixpanelParams = {}): void {
    const key = replaceUUIDs(window.location.pathname);
    const pageName = PageNameMap[key];
    if (pageName) {
      this.track('Page Viewed', {
        ...params,
        page_name: pageName,
      });
    }
  }

  track(eventName: string, params?: IMixpanelParams): void {
    mixpanel.track(eventName, { env: import.meta.env.VITE_APP_ENVIRONMENT, ...params });
  }

  identify(uniqueId: string): void {
    if (!uniqueId) return;
    // TODO: Check if it is necessary to pass a parameter since we are now using alias
    mixpanel.identify(uniqueId);
  }

  setPeople(params: IMixpanelParams): void {
    if (!params) return;
    // Allow to add our own properties to a user on Mixpanel for analytics
    mixpanel.people.set(params);
  }

  assignUniqueIdentifier(params: string): void {
    if (!params) return;
    // Mixpanel will assign a random id called Distinct ID for every visitor to ensure they can track usage through the session
    // Alias allow us to map one of our identifier to this Distinct ID so we can then find the related users in the DB when we need to run reports
    mixpanel.alias(params);
  }

  reset(): void {
    // Clears the aliases and properties on logout. Useful if a user logs out and logs in again in a different account.
    mixpanel.reset();
  }
}

export { MixpanelService };
export default new MixpanelService();
