import { IUserSession, IBankInfo } from 'typings/user-session';
import { IHelperService } from 'typings/helper';
import { IComponentController } from 'angular';
import template from './add-credits-bank-transfer-modal-card.html?raw';
import style from './add-credits-bank-transfer-modal-card.module.scss';

class AddCreditsBankTransferModalCard implements IComponentController {
  style = style;

  static $inject = ['UserSession', 'HelperService'];
  constructor(private UserSession: IUserSession, private HelperService: IHelperService) {}

  get companyBankDetails(): IBankInfo {
    return this.UserSession.getCompanyBankDetails();
  }

  snakeCaseToTitleCase(name: string): string {
    return this.HelperService.snakeToKebabCase(name);
  }
}

const AddCreditsBankTransferModalCardComponent: ng.IComponentOptions = {
  controller: AddCreditsBankTransferModalCard,
  template,
  bindings: {
    esCloseAction: '&',
  },
};

export { AddCreditsBankTransferModalCardComponent };
