import angular from 'angular';
import Broker from '@client/core/corelogic/models/Broker';
import IBrokerGateway from '@client/core/corelogic/ports/broker.interface';
import { BrokersService } from '@client/src/global/services/brokers/brokers.service';

export default class NgBrokerGateway implements IBrokerGateway {
  private brokerService: BrokersService;

  constructor() {
    this.brokerService = angular
      .element(document.body)
      .injector()
      .get<BrokersService>('BrokersService');
  }

  fetchAll(fedexAccountId: string): Promise<Broker[]> {
    return new Promise((resolve) =>
      this.brokerService.getBrokers({ courier_account_id: fedexAccountId }).then(({ brokers }) =>
        resolve(
          brokers.map((broker) => ({
            id: broker.id,
            name: broker.contact_name,
            companyName: broker.company_name,
            addressLine1: broker.address_line_1,
            state: broker.state,
            city: broker.city,
          }))
        )
      )
    );
  }
}
