import React from 'react';
import { Card, Button } from 'easyship-components';
import { FormattedMessage } from 'react-intl';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';

const TYPEFORM_FORM_ID = 'ZCi0ixa4';

export function RequestPlatform() {
  const { company, user } = useUserSession();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const typeform = (window as any).tf;

  const onClick = () => {
    const popup = typeform.createPopup(TYPEFORM_FORM_ID, {
      hidden: {
        email: user.email,
        company_id: company.easyshipCompanyId,
        first_name: user.firstName,
        last_name: user.lastName,
      },
      iFrameProps: {
        title: 'Request a Platform Integration',
      },
      medium: 'snippet',
    });
    popup.toggle();
  };

  return (
    <Card className="flex items-center justify-between p-4">
      <span className="text-lg text-ink-900">
        <FormattedMessage id="connect.request-platform.description" />
      </span>
      <Button color="primary" onClick={onClick}>
        <FormattedMessage id="connect.request-platform.action" />
      </Button>
    </Card>
  );
}
