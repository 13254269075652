import template from './webhook-modal-main.html?raw';

class WebhookModalMain {
  constructor() {}

  onCreate(webhook) {
    this.step = 'update';
    this.webhook = webhook;
  }

  onDelete(webhook) {
    this.step = 'delete';
    this.webhook = webhook;
  }

  onBack() {
    this.step = 'update';
  }
}

const WebhookModalMainComponent = {
  template,
  controller: WebhookModalMain,
  bindings: {
    step: '<',
    webhook: '<',
  },
};

export { WebhookModalMainComponent };
