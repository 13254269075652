import React from 'react';
import MuiAlert, { AlertProps as MuiAlertProps } from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import COLORS from '@client/src/colors';
import { react2angular } from 'react2angular';
import { theme } from '@client/src/theme';
import { ThemeProvider } from '@material-ui/core';

export interface AlertProps extends MuiAlertProps {
  iconStyle?: React.CSSProperties;
}

export default function Alert({
  severity = 'success',
  iconStyle = {},
  icon,
  children,
  ...props
}: AlertProps) {
  let iconColor: string = COLORS.tealNormal;

  switch (severity) {
    case 'success':
      iconColor = COLORS.tealNormal;
      break;
    case 'warning':
      iconColor = COLORS.yellowDark;
      break;
    case 'error':
      iconColor = COLORS.redDark;
      break;
    default:
      iconColor = COLORS.tealNormal;
  }

  return (
    <MuiAlert icon={false} severity={severity} {...props}>
      <Box display="flex">
        <Box className="MuiAlert-icon">
          {icon ?? (
            <i
              style={{ ...iconStyle, lineHeight: 'inherit', color: iconColor }}
              className="icon-info"
            />
          )}
        </Box>
        <Box>{children}</Box>
      </Box>
    </MuiAlert>
  );
}

function WrapperAngularAlert(props: Pick<AlertProps, 'severity' | 'children'>) {
  return (
    <ThemeProvider theme={theme}>
      <Alert {...props} />
    </ThemeProvider>
  );
}

export const AngularAlert = react2angular(WrapperAngularAlert, ['severity', 'children'], []);
