import { IComponentController } from 'angular';
import { IApiConfig } from 'typings/core/config/api';
import template from './empty-page.html?raw';
import style from './empty-page.module.scss';
import productListingIcon from '@assets/images/sidebar/icons/product-listing.svg';

class EmptyPage implements IComponentController {
  style = style;
  productListingIcon: string;

  static $inject = ['API', '$sce'];
  constructor(public API: IApiConfig, $sce: ng.ISCEService) {
    this.productListingIcon = $sce.trustAsHtml(productListingIcon);
  }
}

const EmptyPageComponent: ng.IComponentOptions = {
  controller: EmptyPage,
  template,
  bindings: {},
};

export { EmptyPageComponent };
