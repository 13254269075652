import { IComponentController, IOnChangesObject } from 'angular';

import { IApiConfig } from 'typings/core/config/api';
import { Restrictions } from 'typings/dashboard/services/restrictions';

import template from './tax-id-input.html?raw';
import style from './tax-id-input.module.scss';

class TaxIdInputController implements IComponentController {
  esModel = '';
  esOnChange(params: { value: string }): void {}
  esRequirements: Restrictions.IReceiverTaxIdRestrictions = {
    require: 'no_need',
    regexes: [],
  };
  esOnValidityChange(params: { valid: boolean }): void {}
  esLoading = false;

  style = style;
  regexValidationPatterns = [] as string[];

  static $inject = ['API'];
  constructor(private API: IApiConfig) {
    this.API = API;
  }

  $onInit() {
    this.esLoading = true;
  }

  $onChanges(changesObj: IOnChangesObject) {
    if (changesObj.esRequirements) {
      this.regexValidationPatterns =
        this.esRequirements.require === 'no_need' ? [] : this.esRequirements.regexes;
    }
  }

  updateTaxId(value: string): void {
    this.esOnChange({ value });
  }

  updateValidity(valid: boolean): void {
    this.esOnValidityChange({ valid });
  }
}

const TaxIdInputComponent = {
  controller: TaxIdInputController,
  template,
  bindings: {
    esModel: '<',
    esOnChange: '&',
    esRequirements: '<',
    esInitValidation: '<',
    esOnValidityChange: '&',
    esLoading: '<',
  },
};

export { TaxIdInputComponent };
