(function () {
  'use strict';

  angular
    .module('easyshipDashboardApp')
    .controller('UberFreightForgetPasswordCtrl', UberFreightForgetPasswordCtrl);

  UberFreightForgetPasswordCtrl.$inject = [];

  function UberFreightForgetPasswordCtrl() {
    (function init() {
      var spinnerElement = document.getElementById('app__initial-load');
      if (spinnerElement) spinnerElement.style.display = 'none';
    })();
  }
})();
