import angular from 'angular';

import intlCurrency from './intl-currency.filter';
import intlDate from './intl-date.filter';
import intlNumber from './intl-number.filter';
import trustAsHtml from './trust-as-html.filter';
import titleize from './titleize.filter';
import toCommaList from './to-comma-list.filter';

angular.module('core.filters', [
  intlCurrency,
  intlDate,
  intlNumber,
  trustAsHtml,
  titleize,
  toCommaList,
]);
