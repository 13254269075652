import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import React, { ReactElement } from 'react';

import COLORS from '@client/src/colors';

import { HelpContactSupportFeature } from './types';

const colorGradient = (color1: string, color2: string) => {
  return `transparent linear-gradient(180deg, ${color1} 0%, ${color2} 100%) 0% 0% no-repeat padding-box`;
};

const useStyles = makeStyles(() => ({
  iconBackground: {
    width: 86,
    height: 86,
    borderRadius: 15,
    marginBottom: 18,
    background: COLORS.skyNormal,

    '&.email': {
      background: colorGradient(COLORS.yellowNormal, COLORS.tealNewLight),
    },
    '&.chat': {
      background: colorGradient('#32C5FF6F', '#32C5FF'),
    },
    '&.call': {
      background: colorGradient('#DCF6ED', '#96E5C8'),
    },

    // Image size
    '& .email': {
      width: 67,
      height: 53,
    },
    '& .chat': {
      width: 53,
      height: 53,
    },
  },
}));

interface FeatureAvatarProps {
  feature: HelpContactSupportFeature;
  isAccessible: boolean;
}

export default function FeatureAvatar({
  feature,
  isAccessible,
}: FeatureAvatarProps): ReactElement<FeatureAvatarProps> {
  const classes = useStyles();
  let iconKey = feature;

  if (!isAccessible) {
    iconKey += '-disabled';
  }

  const imgUrl = new URL(
    `../../../../assets/images/dashboard/help-guide/${iconKey}.svg`,
    import.meta.url
  ).href;

  return (
    <Avatar
      variant="rounded"
      className={`${classes.iconBackground} ${isAccessible ? feature : ''}`}
    >
      <img src={imgUrl} alt={iconKey} />
    </Avatar>
  );
}
