(function () {
  'use strict';

  function WalkthroughData() {
    // Draft of flows data that will be stored in a yaml file in the backend
    this.getAllFlows = function () {
      return {
        'order-sync-with-missing-dimensions': {
          dependencies: null,
          active: false,
          snooze: false,
          pages: [],
          steps: {
            'order-sync-with-missing-dimensions-tutorial': {
              stepNumber: 1,
              done: false,
              active: false,
              type: 'modal',
            },
          },
        },
        'connect-store-incentive': {
          active: false,
          snooze: false,
          triggerOnExplicitMention: true,
          pages: [],
          steps: {
            'connect-store-incentive-modal': {
              stepNumber: 1,
              done: false,
              active: false,
              type: 'modal',
            },
          },
        },
        adjustment: {
          active: false,
          snooze: false,
          triggerOnExplicitMention: true,
          pages: [],
          steps: {
            'adjustments-modal': {
              stepNumber: 1,
              done: false,
              active: false,
              type: 'modal',
            },
          },
        },
      };
    };

    this.getAllWalkthroughPagesData = function () {
      return {
        multiple: {
          matchesPageCondition: function () {
            return true;
          },
        },
        shipments: {
          matchesPageCondition: function () {
            return true;
          },
        },
      };
    };

    return this;
  }

  angular
    .module('app.factory.WalkthroughData', ['app.global.services.shipment-list'])
    .factory('WalkthroughData', WalkthroughData);
})();
