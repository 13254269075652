import React from 'react';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export default function DrawerToolbar({ children, style, ...props }: AppBarProps) {
  return (
    <AppBar
      {...props}
      position="sticky"
      style={{ backgroundColor: 'white', color: 'inherit', ...style }}
    >
      <Toolbar style={{ minHeight: '100%' }} disableGutters>
        {children}
      </Toolbar>
    </AppBar>
  );
}
