import { IShipmentListItem } from 'typings/shipment';
import { IRateItem } from 'typings/rates';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';
import { IFlatRateBoxService } from 'typings/boxes';

import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './courier-information.html?raw';
import style from './courier-information.module.scss';

const SCOPE_ORDERS_ALL = 'orders_all';

class CourierInformation {
  style = style;
  shipmentIndex = 0;
  esShipment: IShipmentListItem | null = null;

  static $inject = [
    '$state',
    '$timeout',
    '$q',
    'ShipmentListAdvancedService',
    'FlatRateBoxService',
    'MixpanelService',
    'FlatRatesModal',
    'editShipmentCourier',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $q: ng.IQService,
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private FlatRateBoxService: IFlatRateBoxService,
    private MixpanelService: MixpanelService,
    private FlatRatesModal: any,
    private editShipmentCourier: any
  ) {}

  esCourierSelect(): void {
    // esCourierSelect expression bindings, need to add this in order for typescript to successfully
  }

  onCourierSelect(rate: IRateItem): void {
    if (this.esShipment && this.esShipment.price_comparison) {
      const flatRateBoxIds = this.esShipment?.parcels_attributes.reduce<string[]>(
        (agg, parcel) =>
          parcel.packageable_type === 'FlatRateBox' && parcel.packageable_id
            ? [...agg, parcel.packageable_id]
            : agg,
        []
      );
      const flatRateBoxes = flatRateBoxIds.map((id) => this.FlatRateBoxService.getBox(id));
      const showFlatRatesModal =
        flatRateBoxes.some(function (flatRateBox) {
          return flatRateBox?.logo_url !== rate.courier_logo;
        }) && !rate.courier_service_code.includes('USPS_Lightweight');

      if (showFlatRatesModal) {
        this.esCourierSelect();

        this.$timeout(() => {
          if (this.esShipment && this.esShipment.courier) {
            this.FlatRatesModal.open({
              changeCourier: () => {
                return this.overwriteCourier(rate);
              },
              openShipmentRatesModal: () => {
                if (this.esShipment && this.esShipment.price_comparison) {
                  this.editShipmentCourier.open({
                    rates: this.esShipment.price_comparison.rates,
                    sortRatesBy: 'total_charge',
                    shipmentIndex: this.shipmentIndex,
                    buyerShippingInfos: this.esShipment.buyer_selected_courier_data || {},
                    platformName: this.esShipment.platform_name,
                    buyerSelectedCourier: this.esShipment.buyer_selected_courier || {},
                    page: this.ShipmentListAdvancedService.pageNum,
                    hasMissingDimensions: this.esShipment.has_missing_dimensions,
                    shipmentId: this.esShipment.id,
                    setAsResidential: this.esShipment.set_as_residential,
                    identifiedAsResidential: this.esShipment.identified_as_residential,
                    residentialFeeApplied: this.esShipment.residential_fee_applied,
                  });
                }
              },
            });
          }
        }, 300);
      } else {
        this.overwriteCourier(rate);
      }
    }
  }

  get isQuotePage(): boolean {
    return this.$state.current.name === 'app.quote';
  }

  private overwriteCourier(rate: IRateItem): ng.IPromise<void> {
    if (this.esShipment) {
      return this.ShipmentListAdvancedService.overwriteCourier(this.esShipment.id, rate.courier_id)
        .then(({ shipment }) => {
          this.MixpanelService.track('Edit Shipment Courier - Success', {
            easyship_shipment_id: shipment.easyship_shipment_id,
            old_courier: this.esShipment?.courier?.name,
            old_price: this.esShipment?.shipment_charge_total,
            new_courier: shipment.courier?.name,
            new_price: shipment.shipment_charge_total,
          });

          const isNewShipmentsTableEnabled =
            this.ShipmentListAdvancedService.UserSession.getFeatureFlagsByFlagName(
              'new_shipments_table'
            );

          if (!isNewShipmentsTableEnabled) {
            this.ShipmentListAdvancedService.fetchShipmentTotalWithFilter();
            this.ShipmentListAdvancedService.modifyCurrentShipment(this.shipmentIndex, shipment);
          } else this.ShipmentListAdvancedService.fetchShipmentEntitiesWithFilter(SCOPE_ORDERS_ALL);
        })
        .catch(({ data }) => {
          if (data) {
            this.MixpanelService.track('Edit Shipment Courier - Failure', {
              easyship_shipment_id: this.esShipment?.easyship_shipment_id,
              error: data.errors,
            });
          }
        })
        .finally(() => {
          rate.busy = false;
          this.esCourierSelect();
        });
    }

    return this.$q((resolve, reject) => {
      reject();
    });
  }
}

const CourierInformationComponent = {
  template,
  controller: CourierInformation,
  bindings: {
    esShipment: '<',
    esCourierSelect: '&',
  },
};

export { CourierInformationComponent };
