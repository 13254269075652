class UserService {
  static $inject = ['User', 'UserSession'];

  constructor(User, UserSession) {
    this.User = User;
    this.UserSession = UserSession;
  }

  update(payload, userId) {
    return this.User.update(
      { company_id: this.UserSession.company.id, id: userId || this.UserSession.user.id },
      { user: payload }
    ).$promise;
  }

  refreshShippingCountries() {
    this.User.getCurrent().$promise.then(({ company: { shipping_countries } }) => {
      this.UserSession.company.shipping_countries = shipping_countries;
    });
  }

  updateAndGetUserDashboardSettings(page, key, value) {
    // {
    //   dashboard_settings: {
    //     multiple: {
    //       items_per_page: 10
    //     },
    //     shipments: {
    //       items_per_page: 50
    //     }
    //   }
    // }
    const { user } = this.UserSession;
    const userDashboardSettings = user.dashboard_settings || {};

    userDashboardSettings[page] = { ...userDashboardSettings[page], [key]: value };

    return userDashboardSettings;
  }

  scheduleCall(userId, formData) {
    return this.User.scheduleCall({ id: userId }, formData).$promise;
  }

  confirmEmail(userId, token) {
    return this.User.confirmEmail({ id: userId }, { email_confirmation_token: token }).$promise;
  }

  sendConfirm(userId) {
    return this.User.sendConfirm({ id: userId }).$promise;
  }

  getNotificationSettings(userId) {
    return this.User.getNotificationSettings({ id: userId }).$promise;
  }

  getHubspotVisitorToken() {
    return this.User.getHubspotVisitorToken().$promise;
  }

  resetPassword(payload, token) {
    return this.User.resetPassword({ token }, payload).$promise;
  }

  validateEmail(payload) {
    return this.User.validateEmail(payload).$promise;
  }
}

export { UserService };
