import angular from 'angular';

import { PickupService } from './pickup.service';
import { PickupResource } from './pickups.resource';

const MODULE_NAME = 'app.global.services.pickup';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', 'ngResource'])
  .service('PickupService', PickupService)
  .service('PickupResource', PickupResource);

export default MODULE_NAME;
