import { IUserSession, IUserRoleActions } from 'typings/user-session';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import {
  IOnboardingChecklist,
  OnboardingChecklistService,
} from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import template from './progress-button.html?raw';
import style from './progress-button.module.scss';

enum ModalType {
  Checklist = 'checklist',
  Confirmation = 'confirmation',
  Complete = 'complete',
}

class ProgressButton implements IComponentController {
  style = style;
  animateButton = false;
  activeModal: ModalType | null = null;

  static $inject = [
    '$scope',
    '$timeout',
    'MixpanelService',
    'OnboardingChecklistService',
    'UserSession',
  ];
  constructor(
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private MixpanelService: MixpanelService,
    private OnboardingChecklistService: OnboardingChecklistService,
    private UserSession: IUserSession
  ) {
    this.$scope.$watch('$ctrl.currentProgressPercentage', () => {
      if (this.OnboardingChecklistService.hasProgressPercentageChanged) {
        this.animateButton = true;

        this.$timeout(() => {
          this.animateButton = false;
        }, 1000);
      }
    });
    this.hideModal = this.hideModal.bind(this);
  }

  showModal(modal: ModalType): void {
    this.activeModal = modal;
  }

  hideModal(): void {
    this.activeModal = null;

    if (this.OnboardingChecklistService.isComplete) {
      this.OnboardingChecklistService.turnOffOnboarding();
    }

    this.MixpanelService.track('Checklist - Close Modal');
  }

  showChecklistModal(): void {
    this.MixpanelService.track('Checklist - Open Modal');

    this.showModal(ModalType.Checklist);
  }

  get checklist(): IOnboardingChecklist | undefined {
    return this.OnboardingChecklistService.checklist;
  }

  get showOnboarding(): boolean {
    return this.OnboardingChecklistService.showOnboarding;
  }

  get isComplete(): boolean {
    return this.OnboardingChecklistService.isComplete;
  }

  get currentProgressPercentage(): number {
    return this.OnboardingChecklistService.currentProgressPercentage;
  }

  get previousProgressPercentage(): number {
    return this.OnboardingChecklistService.previousProgressPercentage;
  }

  get userRoleActions(): IUserRoleActions {
    return this.UserSession?.user?.role?.actions;
  }

  get removeFalseRoleActionsAndTransformToArray(): any {
    return Object.keys(this.userRoleActions).filter(
      (key) => this.userRoleActions[key as keyof IUserRoleActions] !== false
    );
  }

  get showSetupProgressButton(): boolean {
    const canShowButton =
      !!this.checklist && !!this.showOnboarding && !!this.checkMenuList('setupProgress');

    if (this.UserSession.hasTeamRightsAndPermissionsBetaKey()) {
      return canShowButton && !!this.UserSession.isAccountOwner();
    }
    return canShowButton;
  }

  checkMenuList(item: string): boolean {
    const arraySubItems = this.removeFalseRoleActionsAndTransformToArray;
    const relatedSubItems = arraySubItems.filter((subItem: string) =>
      subItem.startsWith(`menu.${item}`)
    );
    return relatedSubItems.length > 0;
  }
}

const ProgressButtonComponent: ng.IComponentOptions = {
  controller: ProgressButton,
  template,
  bindings: {},
};

export { ProgressButtonComponent };
