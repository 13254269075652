import angular from 'angular';
import { react2angular } from 'react2angular';

import FallbackSettingsDialog, { WrappedFallbackSettingsDialog } from './FallbackSettingsDialog';

export default FallbackSettingsDialog;
export const AngularFallbackSettingsDialog = react2angular(
  WrappedFallbackSettingsDialog,
  ['open', 'store', 'scenarioName', 'dimensionsDisabled', 'onSuccess', 'onClose'],
  ['$injector']
);
