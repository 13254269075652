import { Checkbox } from 'easyship-components';
import React, { forwardRef, useEffect, useRef } from 'react';
import RoundedCorner from './RoundedCorner';
import ShipmentsTableHeaderCell from './ShipmentsTableHeaderCell';

export interface ShipmentsTableHeaderProps {
  children: React.ReactNode;
  isCheckboxChecked: boolean;
  isPartialSelected: boolean;
  onCheckboxChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const HEADER_TOP_STICKY = 82;

const ShipmentsTableHeader = forwardRef(
  (
    {
      children,
      isCheckboxChecked,
      onCheckboxChanged,
      isPartialSelected,
    }: ShipmentsTableHeaderProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const refLocal = useRef(null);

    useEffect(() => {
      let stateOfClass = -1;
      const fn = () => {
        if (!refLocal.current) return;
        const elem = refLocal.current as HTMLDivElement;
        const rect = elem.getBoundingClientRect();
        const classes = ['border-t', 'border-t-sky-500', 'visibility', 'rounded-none'];
        if (rect.y < HEADER_TOP_STICKY) {
          if (stateOfClass !== 1) {
            elem.classList.add(...classes);
            stateOfClass = 1;
          }
        } else if (stateOfClass) {
          elem.classList.remove(...classes);
          stateOfClass = 0;
        }
      };
      window.addEventListener('scroll', fn);
      return () => {
        window.removeEventListener('scroll', fn);
      };
    }, []);

    return (
      <div
        ref={ref}
        className="table-x-scroll-not-visible w-full min-w-full overflow-hidden top-[80px] sticky z-10"
      >
        <div
          ref={refLocal}
          data-test-id="shipments-table-header"
          className="w-full flex items-center bg-white min-h-[40px] pr-[10px] mb-2 z-10 rounded"
        >
          <ShipmentsTableHeaderCell className="flex-grow-0 w-auto min-w-0 hover:bg-[#d2f1f2] cursor-pointer sticky left-[0] pl-[10px] bg-white z-[5] group">
            <RoundedCorner classNameInner="group-hover:bg-[#d2f1f2]" className="not-visible" />
            <RoundedCorner
              position="bottom-left"
              classNameInner="group-hover:bg-[#d2f1f2]"
              className="not-visible"
            />
            <Checkbox
              indeterminate={isPartialSelected}
              data-test-id="shipments-table-header-checkbox"
              className="!outline-none cursor-pointer !m-0 hover:bg-transparent checked:before:bg-[#36a3ac] checked:before:border-none after:bg-white h-[40px] before:top-[.75rem] after:top-[.75rem]"
              checked={isCheckboxChecked}
              onChange={onCheckboxChanged}
            />
          </ShipmentsTableHeaderCell>
          {children}
          <div
            className={`fixed
                         w-screen left-0 right-0
                         top-[80px] min-h-[43px]
                         bg-white
                         z-[-2]
                         border-t border-t-sky-500
                         border-b border-b-sky-500
                         shadow-md
                         invisible be-visible
                         `}
          />
        </div>
      </div>
    );
  }
);

export default ShipmentsTableHeader;
