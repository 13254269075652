import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

interface ElevationScrollProps {
  children: React.ReactElement;
  target?: Node | Window;
}

export default function ElevationScroll({ children, target }: ElevationScrollProps) {
  const trigger = useScrollTrigger({
    target,
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
