import angular from 'angular';

import IHsCodeGateway, {
  IHsCodeSuggestionSearchField,
} from '@client/core/corelogic/ports/hsCode.interface';
import { IUserSession } from 'typings/user-session';
import { ApiConfig } from '@client/core/config/api.constant';
import { HsCode } from '@client/core/corelogic/models/HsCode';

interface HsCodeSuggestionResponse {
  hs_codes: HsCode[];
}

export default class NgHsCodeGateway implements IHsCodeGateway {
  private $http: angular.IHttpService;
  private API: ApiConfig;
  private UserSession: IUserSession;

  constructor() {
    this.$http = angular.element(document.body).injector().get('$http');
    this.API = angular.element(document.body).injector().get('API');
    this.UserSession = angular.element(document.body).injector().get('UserSession');
  }

  getHsCodeSuggestions(
    keyword: string,
    searchField: IHsCodeSuggestionSearchField
  ): Promise<HsCode[]> {
    return new Promise((resolve, reject) => {
      this.$http
        .post<HsCodeSuggestionResponse>(
          `${this.API.baseEndpoint}/v2/companies/${this.UserSession.company.id}/hs_codes/hs_code_suggestions`,
          {
            keyword,
            keyword_search_field: searchField,
          }
        )
        .then(({ data }) => {
          resolve(data.hs_codes);
        })
        .catch((error) => reject(error));
    });
  }
}
