import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export interface ShipmentsTableBodyProps {
  children: React.ReactNode;
  containers?: MutableRefObject<HTMLDivElement | null>[];
  tableRef: MutableRefObject<HTMLDivElement | null>;
}

/** The minimum number of points required for smooth movement ,  statistical research */
const MIN_NUMBER_POINTS_TO_MOVE = 4;

function ShipmentsTableBody({ children, containers = [], tableRef }: ShipmentsTableBodyProps) {
  const refTableBody = useRef<HTMLDivElement>(null);
  const refTableContainer = useRef<HTMLDivElement>(null);
  const refThumb = useRef<HTMLDivElement>(null);
  const refThumbContainer = useRef<HTMLDivElement>(null);
  const refBottomTable = useRef<HTMLDivElement>(null);
  const refContainers = useRef(containers);

  const refMoveTh = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(refMoveTh.current);
    };
  });

  refContainers.current = containers;

  const mouseState = useRef({
    x: 0,
    ratio: 0,
    pressed: false,
  });

  const calculateThumb = useCallback(() => {
    const content = refTableContainer.current as HTMLDivElement;
    const contentThumbContainer = refThumbContainer.current as HTMLDivElement;
    const { scrollLeft, scrollWidth, clientWidth } = content;

    const realScrollSpace = scrollWidth - clientWidth;
    if (realScrollSpace < 10) {
      if (contentThumbContainer.style.display !== 'none') {
        requestAnimationFrame(() => {
          contentThumbContainer.style.display = 'none';
          tableRef.current?.classList.remove('scrolled-left-table');
          refContainers.current.forEach((containerRef) => {
            if (containerRef.current) {
              const elem = containerRef.current as HTMLDivElement;
              if (elem.firstElementChild)
                (elem.firstElementChild as HTMLDivElement).style.width = `auto`;
            }
          });
        });
      }
      return;
    }

    if (contentThumbContainer.style.display === 'none')
      requestAnimationFrame(() => {
        contentThumbContainer.style.display = 'block';
      });
    const thumbWidth = Math.floor((clientWidth / scrollWidth) * 100);
    const thumbScrollSpace = (clientWidth * (100 - thumbWidth)) / 100;
    const ratio = thumbScrollSpace / realScrollSpace;
    const thumbPosition = Math.floor(ratio * scrollLeft);

    if (thumbPosition > MIN_NUMBER_POINTS_TO_MOVE) {
      tableRef.current?.classList.add('scrolled-left-table');
    } else tableRef.current?.classList.remove('scrolled-left-table');

    mouseState.current.ratio = ratio;
    const scrollArea = content.scrollWidth;
    requestAnimationFrame(() => {
      const content = refThumb.current as HTMLDivElement;
      content.style.width = `${thumbWidth}%`;
      content.style.left = `${thumbPosition}px`;
      refContainers.current.forEach((containerRef) => {
        if (containerRef.current) {
          const elem = containerRef.current as HTMLDivElement;
          if (elem.scrollWidth !== content.scrollWidth) {
            if (elem.firstElementChild)
              (elem.firstElementChild as HTMLDivElement).style.width = `${scrollArea}px`;
          }
        }
      });
    });
  }, [tableRef]);

  useEffect(() => {
    const contentContainer = refTableContainer.current as HTMLDivElement;
    const contentBody = refTableBody.current as HTMLDivElement;
    const contentThumb = refThumb.current as HTMLDivElement;

    const CUSTOM_SCROLL_EVENT = 'scrollCustomHorizontal';

    const getScrollLeft = (move: number) => {
      const scroll = contentContainer.scrollLeft + move;
      if (scroll < 0) return 0;
      const maxScroll = contentContainer.scrollWidth - contentContainer.clientWidth;
      return scroll > maxScroll ? maxScroll : scroll;
    };

    const onMoveHorizontal = (event: Event) => {
      const { move } = (event as CustomEvent<{ move: number }>).detail;
      if (!move) return;
      requestAnimationFrame(() => {
        const scrollLeft = getScrollLeft(move);
        contentContainer.scrollLeft = scrollLeft;
        refContainers.current.forEach((containerRef) => {
          if (containerRef.current) {
            const elem = containerRef.current as HTMLDivElement;
            elem.scrollLeft = scrollLeft;
          }
        });
      });
    };

    const dispatchEvent = (move: number) => {
      contentContainer.dispatchEvent(
        new CustomEvent(CUSTOM_SCROLL_EVENT, {
          detail: {
            move,
          },
        })
      );
    };
    const onMouseMoveHandler = (event: MouseEvent) => {
      if (!mouseState.current.pressed || !mouseState.current.ratio) return;
      const moveX = event.clientX - mouseState.current.x;
      if (Math.abs(moveX) < MIN_NUMBER_POINTS_TO_MOVE) return;
      mouseState.current = {
        ...mouseState.current,
        x: event.clientX,
      };
      const move = Math.ceil(moveX / mouseState.current.ratio);
      dispatchEvent(move);
    };

    const onMouseDownHandler = (event: MouseEvent) => {
      if (event.button !== 0) return;
      document.removeEventListener('mousemove', onMouseMoveHandler);
      document.addEventListener('mousemove', onMouseMoveHandler);
      mouseState.current = {
        ...mouseState.current,
        x: event.clientX,
        pressed: true,
      };
      contentThumb.style.cursor = 'grabbing';
    };

    const onMouseUpHandler = () => {
      document.removeEventListener('mousemove', onMouseMoveHandler);
      mouseState.current = {
        ...mouseState.current,
        x: 0,
        pressed: false,
      };
      contentThumb.style.cursor = '';
    };

    const onMouseWheel = (event: WheelEvent) => {
      if (event.shiftKey) {
        event.preventDefault();
        if (!event.deltaX) return;
        const isNegative = event.deltaX < 0;
        const moveLeft = getScrollLeft(event.deltaX);
        const realMove = Math.abs(contentContainer.scrollLeft - moveLeft);
        let numOfMove = Math.floor(realMove / MIN_NUMBER_POINTS_TO_MOVE) + 1;
        clearTimeout(refMoveTh.current);
        const emit = () => {
          if (numOfMove < 0) return;
          refMoveTh.current = setTimeout(() => {
            dispatchEvent(isNegative ? MIN_NUMBER_POINTS_TO_MOVE * -1 : MIN_NUMBER_POINTS_TO_MOVE);
            numOfMove -= 1;
            emit();
          }, 1);
        };
        emit();
      }
    };

    contentContainer.addEventListener('scroll', calculateThumb);
    contentContainer.addEventListener(CUSTOM_SCROLL_EVENT, onMoveHorizontal);
    contentContainer.addEventListener('wheel', onMouseWheel);
    contentThumb.addEventListener('mousedown', onMouseDownHandler);
    document.addEventListener('mouseup', onMouseUpHandler);

    const observerResize = new ResizeObserver(() => {
      calculateThumb();
    });

    observerResize.observe(contentContainer);
    observerResize.observe(contentBody);

    const observerView = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const content = refThumbContainer.current as HTMLDivElement;
        if (!entry?.isIntersecting) {
          content.classList.add('scroll-sticky');
        } else content.classList.remove('scroll-sticky');
      },
      {
        threshold: [0, 1],
        rootMargin: '-20px',
      }
    );

    observerView.observe(refBottomTable.current as HTMLDivElement);
    return () => {
      contentContainer.removeEventListener('scroll', calculateThumb);
      contentContainer.removeEventListener(CUSTOM_SCROLL_EVENT, onMoveHorizontal);
      contentContainer.removeEventListener('wheel', onMouseWheel);
      contentThumb.removeEventListener('mousedown', onMouseDownHandler);
      document.removeEventListener('mouseup', onMouseUpHandler);
      document.removeEventListener('mousemove', onMouseMoveHandler);
      observerResize.disconnect();
      observerView.disconnect();
    };
  }, [calculateThumb]);

  return (
    <>
      <div
        className="w-full min-w-full relative table-x-scroll-not-visible overflow-x-auto"
        ref={refTableContainer}
      >
        <div className="flex flex-col w-fit min-w-full gap-px rounded" ref={refTableBody}>
          {children}
        </div>
        <div ref={refBottomTable} className="w-full h-[1px] bg-transparent p-0 m-0" />
      </div>
      <div className="sticky bottom-[10px] w-full h-[20px] z-10" ref={refThumbContainer}>
        <div className="relative w-full h-full">
          <div className="h-[20px] py-[5px] absolute cursor-grab top-0" ref={refThumb}>
            <div className="h-full w-full bg-sky-700 border-ink-100 rounded-[14px]" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ShipmentsTableBody;
