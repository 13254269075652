import angular from 'angular';
import { NicknamedAddressComponent } from './nicknamed-address.component';

const MODULE_NAME = 'core.components.nicknamed-address';

angular
  .module(MODULE_NAME, ['pascalprecht.translate', 'app.service.AddressService'])
  .component('esNicknamedAddress', NicknamedAddressComponent);

export default MODULE_NAME;
