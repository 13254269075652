import angular from 'angular';
import { IOnboardingParams, IOnboardingResponse } from 'typings/onboarding';
import { OnboardingService } from '@client/src/global/services/onboarding/onboarding.service';
import { IOnboardingGateway } from '../../ports/onboarding';

export class NgOnboardingGateway implements IOnboardingGateway {
  private OnboardingService: OnboardingService;

  constructor() {
    this.OnboardingService = angular
      .element(document.body)
      .injector()
      .get<OnboardingService>('OnboardingService');
  }

  update(payload: IOnboardingParams): Promise<IOnboardingResponse> {
    return this.OnboardingService.update(payload);
  }
}
