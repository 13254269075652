import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import Tooltip from '@client/core/components/react/Tooltip';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface CancelShipmentMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onCancelShipment: () => void;
}

export default function CancelShipmentMenuItem({
  divider = false,
  shipmentListItem,
  onCancelShipment,
}: CancelShipmentMenuItemProps) {
  const { cancellable_by_user: isCancellableByUser, last_status_message: lastStatusMessage } =
    shipmentListItem;

  const strong = (chunks: string) => <strong>{chunks}</strong>;

  return (
    <Tooltip
      placement="left"
      interactive
      active={!isCancellableByUser}
      title={
        <p className="text-base m-0">
          <FormattedMessage
            id="shipments.menu-action-tooltip.cancellable-disabled"
            values={{
              shipmentStatus: lastStatusMessage?.name,
              strong,
            }}
          />
          <a
            href="https://support.easyship.com/hc/en-us/articles/360028822711-How-to-Cancel-Shipments"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="global.learn-more" />
          </a>
        </p>
      }
    >
      <div>
        <MenuItem
          onClick={onCancelShipment}
          disabled={!isCancellableByUser}
          divider={divider}
          className="warningMenuItem"
        >
          <div style={baseStyleIconBox}>
            <i className="icon-cross u-text-md" />
          </div>
          <span className='text-base'>
            <FormattedMessage id="shipments.speed-dial.cancel-shipment" />
          </span>
        </MenuItem>
      </div>
    </Tooltip>
  );
}
