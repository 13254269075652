import template from './package-information.html?raw';
import style from './package-information.module.scss';

class PackageInformation {
  static $inject = ['$timeout', 'UserSession'];

  constructor($timeout, UserSession) {
    this.$timeout = $timeout;
    this.UserSession = UserSession;
    this.style = style;
    this.validationErrors = {};
  }

  $onInit() {
    this.shipment = angular.copy(this.shipment);
  }

  saveParcels(parcels, preserveCourier) {
    this.shipment.parcels_attributes = parcels;
    this.shipment.preserveCourier = preserveCourier;
  }

  onSubmit() {
    this.hasTrySubmit = true;

    this.validationErrors.tags =
      this.UserSession.getCompanyDashboardSettings().beta_feature_require_order_tag &&
      (!this.shipment.order_tag_list || this.shipment.order_tag_list.length === 0);

    if (this.addShipmentForm.$invalid || this.validationErrors.tags) return;

    const invalidOunceInput = this.shipment.parcels_attributes.find(
      (parcel) => !parcel.display_weight
    );
    if (invalidOunceInput) {
      return;
    }

    this.busy = true;

    this.onSave({ shipment: this.shipment }).finally(() => {
      // Wait for shipment Reindex
      this.$timeout(() => {
        this.busy = false;
      }, this.esSaveTimeout || 0);
    });
  }
}

const PackageInformationComponent = {
  template,
  controller: PackageInformation,
  bindings: {
    onBack: '&',
    onSave: '&',
    shipment: '<',
    esSaveTimeout: '<',
  },
};

export { PackageInformationComponent };
