(function () {
  'use strict';

  var components = [
    'app.component.handover-options-list',
    'app.component.input-error-message',
    'app.component.address-forms-container',
    'app.component.address-form-contact-info',
    'app.component.address-form-au',
    'app.component.address-form-ca',
    'app.component.address-form-hk',
    'app.component.address-form-nl',
    'app.component.address-form-sg',
    'app.component.address-form-gb',
    'app.component.address-form-us-backup',
    'app.component.address-form-us-addressy',
    'app.component.address-form-default',
    'app.component.fix-shipment-modal-body',
    'app.component.fix-shipment-modal-body-edit-shipment',
    'app.component.fix-shipment-modal-body-pay-shipment',
    'app.component.fix-shipment-modal-body-cancel-shipment',
    'app.component.fix-shipment-modal-body-report-shipment',
    'app.component.fix-shipment-modal-body-success-monkey',
    'app.component.fix-shipment-modal-body-fail-monkey',
  ];

  angular.module('app.components', components);
})();
