import { toastError } from '@client/core/components/react/Toastify';
import template from './pay-shipment.html?raw';

(function () {
  const fixShipmentModalBodyPayShipment = {
    template,
    bindings: {
      shipmentData: '<',
      globalBusy: '<',
      priceComparison: '<',
    },
    controller: 'fixShipmentModalBodyPayShipmentCtrl as ctrl',
  };

  fixShipmentModalBodyPayShipmentCtrl.$inject = [
    '$rootScope',
    '$filter',
    '$translate',
    'API',
    'UserSession',
    'FixMonkeyService',
    'CompanyService',
    'PaymentService',
    'PaymentSourceService',
  ];

  function fixShipmentModalBodyPayShipmentCtrl(
    $rootScope,
    $filter,
    $translate,
    API,
    UserSession,
    FixMonkeyService,
    CompanyService,
    PaymentService,
    PaymentSourceService
  ) {
    const ctrl = this;

    ctrl.API = API;
    ctrl.FixMonkeyService = FixMonkeyService;
    ctrl.UserSession = UserSession;
    ctrl.PaymentService = PaymentService;
    ctrl.PaymentSourceService = PaymentSourceService;
    ctrl.paymentFormId = 'fix-monkey-payment-form';
    ctrl.busy = {};
    ctrl.creditCardErrorCode = '';

    /**
     * [$onInit] Checks which flow the user should see:
     * 1) Pay with existing credits (or overdraft if available)
     * 2) Pay with credit card
     * 3) Refund
     */
    ctrl.$onInit = function () {
      ctrl.payAmount = $filter('intlCurrency')(
        ctrl.priceComparison.total_to_top_up,
        UserSession.getCompanyCurrency()
      );
      ctrl.refundPayment = ctrl.priceComparison.total_to_top_up < 0;
      ctrl.sufficientFunds =
        ctrl.priceComparison.total_to_top_up === 0 || UserSession.company.is_authorized_overdraft;

      $rootScope.$on('payment-failed', function () {
        ctrl.busy.pay = false;
      });
    };

    /**
     * [back] Returns to edit shipment step. Disabled when form is loading.
     * @return {N/A}
     */
    ctrl.back = function () {
      if (ctrl.globalBusy || ctrl.busy.pay) return;
      FixMonkeyService.goToEditShipmentStep();
    };

    /**
     * [next] Main call-to-action. Scenarios:
     *  1) Company has sufficient credit and pays with credit or receives a refund
     *  2) Company has insufficient credit and must pay with credit card
     *    a) Company has selected a pre-existing credit card
     *    b) Company has opted to add a new credit card
     * @return {N/A}
     */
    // eslint-disable-next-line func-names, consistent-return
    ctrl.next = function () {
      ctrl.busy.pay = true;
      // 1) Company has sufficient credit or a refund should be processed
      if (ctrl.sufficientFunds || ctrl.refundPayment) return processPayment();

      ctrl.PaymentSourceService.paymentAmount =
        ctrl.priceComparison.difference && ctrl.priceComparison.difference.total_charge;

      ctrl.PaymentService.submitPaymentForm(ctrl.paymentFormId)
        .then(function (results) {
          const valid = results && results.valid;

          // for old cards
          const paymentSourceId = results && results.payment && results.payment.token;

          if (!valid) {
            ctrl.busy.pay = false;
            toastError($translate.instant('toast.default-error'));
            return;
          }

          // eslint-disable-next-line consistent-return
          return processPayment({ paymentSourceId });
        })
        .catch(function (res) {
          ctrl.creditCardErrorCode = res.data.error_code || 'unknown';
          toastError($translate.instant('toast.default-error'));
        })
        .finally(function () {
          ctrl.busy.pay = false;
        });
    };

    /**
     * Calls the fix_monkey#confirm endpoint and redirects to success / fail step
     * @param  {object} paymentOptions Contains the paymentSourceId and other meta data about the payment
     */
    function processPayment(paymentOptions) {
      const options = paymentOptions || {};
      const token = options.paymentSourceId;

      FixMonkeyService.payShipment(token).then(function (data) {
        CompanyService.updateStatus();

        if (data.label_state === 'generated') {
          FixMonkeyService.goToSuccessMonkeyStep();
        } else {
          FixMonkeyService.goToFailMonkeyStep();
        }
      });
    }
  }

  angular
    .module('app.component.fix-shipment-modal-body-pay-shipment')
    .component('fixShipmentModalBodyPayShipment', fixShipmentModalBodyPayShipment)
    .controller('fixShipmentModalBodyPayShipmentCtrl', fixShipmentModalBodyPayShipmentCtrl);
})();
