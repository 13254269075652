import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import COLORS from '@client/src/colors';
import accountReviewImageUrl from '@assets/images/dashboard/churn-reasons/account-review.svg';

export const useStyles = makeStyles({
  root: {
    textAlign: 'center',

    '& p': {
      color: COLORS.inkNormal,
      lineHeight: '20px',
    },
  },
  review: {
    width: '100%',
    height: 235,
    marginBottom: 15,
  },
});

export default function UnderReview() {
  const classes = useStyles();

  return (
    <Container className={classes.root} disableGutters>
      <img className={classes.review} src={accountReviewImageUrl} alt="" />
      <Typography variant="body2" paragraph>
        <FormattedMessage id="churn-reasons-flow.close-account-under-review.explaination" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="churn-reasons-flow.close-account-under-review.team-will-review" />
      </Typography>
    </Container>
  );
}
