import {
  ISubscriptionPaymentSetupIntentsParams,
  ISubscriptionPaymentSetupIntentsPayload,
  ISubscriptionPaymentSetupIntentsConfirmResponse,
  ISubscriptionPaymentSetupIntentsConfirmParams,
  IPaymentServiceFormResponseObject,
  ISetupIntentsConfirmResponse,
  ISetupIntentsResponse,
  ISubscriptionHandleCardSetupResponse,
} from 'typings/subscription';
import { IPaymentMethod, ICardInputInstance } from 'typings/payment-service';
import { IUserSession } from 'typings/user-session';
import { BillingFrequency, DefinedSubscriptionPlanId } from '@client/data/subscription';
import { SubscriptionService } from './subscription.service';
import { SubscriptionPaymentResource } from './subscription-payment.resource';

enum PaymentSetupStatus {
  Succeeded = 'succeeded',
}

enum IntentsResult {
  Success = 'success',
  Fail = 'fail',
}

class SubscriptionPaymentService {
  private _paymentSetupStatus = PaymentSetupStatus;
  private _intentsResult = IntentsResult;

  static $inject = [
    'SubscriptionService',
    'SubscriptionPaymentResource',
    'UserSession',
    'StripeService',
  ];
  constructor(
    private SubscriptionService: SubscriptionService,
    private SubscriptionPaymentResource: SubscriptionPaymentResource,
    private UserSession: IUserSession,
    private StripeService: any
  ) {}

  get paymentSetupStatus(): typeof PaymentSetupStatus {
    return this._paymentSetupStatus;
  }

  get intentsResult(): typeof IntentsResult {
    return this._intentsResult;
  }

  setupIntentsCreate(
    params: ISubscriptionPaymentSetupIntentsParams,
    paymentMethod: IPaymentMethod,
    cardInputInstance: ICardInputInstance
  ): ng.IPromise<ISetupIntentsResponse | ISetupIntentsConfirmResponse> {
    const paymentMethodId = paymentMethod.id;
    const payload: ISubscriptionPaymentSetupIntentsPayload = {
      payment_method_id: paymentMethodId,
    };

    return this.SubscriptionPaymentResource.setupIntents(params, payload)
      .then((data) => {
        return {
          ...data,
          card: paymentMethod.card,
          billing_details: paymentMethod.billing_details,
          payment_method_id: paymentMethodId,
          cardInputInstance,
        };
      })
      .catch((error) => {
        throw error;
      });
  }

  setupIntentsConfirm(
    params: ISubscriptionPaymentSetupIntentsConfirmParams
  ): ng.IPromise<ISubscriptionPaymentSetupIntentsConfirmResponse> {
    return this.SubscriptionPaymentResource.setupIntentsConfirm(params)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  async handleCardSetup(
    clientSecret: string,
    paymentMethodId: string
  ): Promise<ISubscriptionHandleCardSetupResponse> {
    const stripe = await this.StripeService.getInstance();
    const { setupIntent, error } = await stripe.handleCardSetup(clientSecret, {
      payment_method: paymentMethodId,
    });

    if (error) {
      throw error;
    } else {
      // The setup has succeeded. Display a success message.
      return setupIntent;
    }
  }

  async handleCardSetupIntentsConfirm(
    paymentFormResult: IPaymentServiceFormResponseObject
  ): Promise<ISubscriptionPaymentSetupIntentsConfirmResponse | null> {
    const { client_secret, payment_method_id } = paymentFormResult;
    if (payment_method_id) {
      const cardSetupResult = await this.handleCardSetup(client_secret, payment_method_id);
      const companyID = this.UserSession.getCompanyId();

      if (
        cardSetupResult &&
        cardSetupResult.status === this.paymentSetupStatus.Succeeded &&
        companyID
      ) {
        return this.setupIntentsConfirm({
          company_id: companyID,
          setup_intent_id: cardSetupResult.id,
        });
      }
    }

    return null;
  }

  async paymentFormResultAction(
    paymentFormResult: IPaymentServiceFormResponseObject,
    companyId: string,
    selectedPlanId: DefinedSubscriptionPlanId,
    unit: BillingFrequency
  ): Promise<void> {
    if (paymentFormResult.requires_action) {
      const result = await this.handleCardSetupIntentsConfirm(paymentFormResult);
      if (result) {
        await this.SubscriptionService.actionChangePlan(result.id, companyId, selectedPlanId, unit);
        return;
      }
    }

    if (paymentFormResult.card) {
      await this.SubscriptionService.actionChangePlan(
        paymentFormResult.id || paymentFormResult.card.id,
        companyId,
        selectedPlanId,
        unit
      );
    }
  }
}

export { SubscriptionPaymentService };
