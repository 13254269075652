(function () {
  CredentialsStorePlatformApi.$inject = ['$resource', 'API'];
  function CredentialsStorePlatformApi($resource, API) {
    const endpointUrl = API.endpoint;

    return $resource(`${endpointUrl}/companies/:company_id/:platform_name/stores`, {
      company_id: '@_company_id',
    });
  }
  angular
    .module('app.factory.CredentialsStorePlatformApi', [])
    .factory('CredentialsStorePlatformApi', CredentialsStorePlatformApi);
})();
