import { Restrictions } from 'typings/dashboard/services/restrictions';
import { RestrictionsResource } from './restrictions.resource';

interface IPromises {
  getShipmentRestrictions: Record<
    string,
    ng.IPromise<Restrictions.IShipmentGetSuccessResponse> | null
  >;
}

class RestrictionsService {
  static $inject = ['$q', '$timeout', 'RestrictionsResource'];
  constructor(
    private $q: ng.IQService,
    private $timeout: ng.ITimeoutService,
    private RestrictionsResource: RestrictionsResource
  ) {}

  promises: IPromises = {
    getShipmentRestrictions: {},
  };

  getShipmentRestrictions(
    params: Restrictions.IShipmentParams
  ): ng.IPromise<Restrictions.IShipmentGetSuccessResponse> {
    const key = JSON.stringify(params);
    if (this.promises.getShipmentRestrictions[key]) {
      return this.promises.getShipmentRestrictions[
        key
      ] as ng.IPromise<Restrictions.IShipmentGetSuccessResponse>;
    }

    this.promises.getShipmentRestrictions[key] = this.$q((resolve, reject) => {
      return this.RestrictionsResource.shipment({
        ...params,
      })
        .then((data) => {
          // Use timeout to reduce request
          this.$timeout(() => {
            this.promises.getShipmentRestrictions[key] = null;
          }, 1000);

          resolve(data);
        })
        .catch((err) => {
          this.promises.getShipmentRestrictions[key] = null;
          reject(err);
        });
    });

    return this.promises.getShipmentRestrictions[
      key
    ] as ng.IPromise<Restrictions.IShipmentGetSuccessResponse>;
  }
}

export { RestrictionsService };
