import React from 'react';
import MuiDrawer, { DrawerProps } from '@material-ui/core/Drawer';
import ClickAwayListener, { ClickAwayListenerProps } from '../ClickAwayListener';

interface EsDrawerProps extends DrawerProps {
  width?: number;
  onClickAway?: ClickAwayListenerProps['onClickAway'];
}

export default function Drawer(props: EsDrawerProps) {
  const {
    open = false,
    style,
    ModalProps,
    PaperProps,
    children,
    width,
    onClickAway,
    ...rest
  } = props;

  return (
    <ClickAwayListener onClickAway={(e) => onClickAway && onClickAway(e)} capture disableReactTree>
      <MuiDrawer
        anchor="right"
        open={open}
        style={{ ...style, position: 'initial' }}
        ModalProps={{ ...ModalProps, disableScrollLock: true }}
        PaperProps={{ ...PaperProps, style: { width: width || '1000px', overflow: 'visible' } }}
        {...rest}
      >
        {children}
      </MuiDrawer>
    </ClickAwayListener>
  );
}
