import { IComponentController } from 'angular';
import { IUserSession } from 'typings/user-session';
import { IUserService } from 'typings/user-service';
import { ICourierAccountsService } from 'typings/courier';

import template from './invitee-modal.html?raw';
import style from './invitee-modal.module.scss';

const OWNER_ROLE_ID = 10;

class InviteeModal implements IComponentController {
  translations: angular.translate.ITranslationTable = {};
  style = style;
  isInvitee: boolean;
  seenOnboarding: boolean | undefined;
  isModalOpened = false;

  static $inject = ['$translate', 'CourierAccounts', 'UserSession', 'UserService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private CourierAccounts: ICourierAccountsService,
    private UserSession: IUserSession,
    private UserService: IUserService
  ) {
    this.isInvitee = this.UserSession.user.role.id !== OWNER_ROLE_ID;
    this.seenOnboarding =
      this.UserSession.user.display_settings?.display_onboarding ||
      this.UserSession.user.display_settings?.desktop_orientation;
  }

  async $onInit(): Promise<void> {
    this.$translate(['welcome']).then((translations: angular.translate.ITranslationTable) => {
      this.translations = translations;
    });

    const activeCourierAccounts = await this.CourierAccounts.getActiveCourierAccounts().catch(
      () => null
    );
    const noEsCouriersInCountry =
      activeCourierAccounts &&
      activeCourierAccounts.easyship_courier_accounts &&
      activeCourierAccounts.easyship_courier_accounts.length === 0;
    const showingGoGlobalModal = !this.seenOnboarding && noEsCouriersInCountry;

    this.isModalOpened = !this.seenOnboarding && !showingGoGlobalModal && this.isInvitee;

    this.UserService.update({ display_settings: { desktop_orientation: true } });

    this.seenOnboarding = true;
  }

  closeModal(): void {
    this.isModalOpened = false;
  }
}

const InviteeModalComponent: ng.IComponentOptions = {
  controller: InviteeModal,
  template,
  bindings: {},
};

export { InviteeModalComponent };
