import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './redirection.html?raw';
import style from './redirection.module.scss';

class Redirection {
  static $inject = [
    '$stateParams',
    '$state',
    '$window',
    '$location',
    '$cookies',
    '$translate',
    'StoreService',
    'MixpanelService',
    'ShipmentAction',
    'UserSession',
    'ChanneladvisorService',
    'EtsyService',
    'LazadaService',
    'ShopifyService',
    'SquareService',
    'TradegeckoService',
    'AmazonService',
    'BuyOnGoogleService',
    'WixService',
    'AppCuesService',
    'Auth',
    '$rootScope',
    'PlatformApi',
  ];

  constructor(
    $stateParams,
    $state,
    $window,
    $location,
    $cookies,
    $translate,
    StoreService,
    MixpanelService,
    ShipmentAction,
    UserSession,
    ChanneladvisorService,
    EtsyService,
    LazadaService,
    ShopifyService,
    SquareService,
    TradegeckoService,
    AmazonService,
    BuyOnGoogleService,
    WixService,
    AppCuesService,
    Auth,
    $rootScope,
    PlatformApi
  ) {
    this.style = style;
    this.$state = $state;
    this.$window = $window;
    this.$location = $location;
    this.$cookies = $cookies;
    this.$stateParams = $stateParams;
    this.$translate = $translate;
    this.StoreService = StoreService;
    this.MixpanelService = MixpanelService;
    this.ShipmentAction = ShipmentAction;
    this.UserSession = UserSession;
    this.ChanneladvisorService = ChanneladvisorService;
    this.EtsyService = EtsyService;
    this.LazadaService = LazadaService;
    this.ShopifyService = ShopifyService;
    this.SquareService = SquareService;
    this.TradegeckoService = TradegeckoService;
    this.AmazonService = AmazonService;
    this.BuyOnGoogleService = BuyOnGoogleService;
    this.WixService = WixService;
    this.AppCuesService = AppCuesService;
    this.Auth = Auth;
    this.$rootScope = $rootScope;
    this.PlatformApi = PlatformApi;
  }

  $onInit() {
    setTimeout(this._hidePageSpinner());

    this.platformName =
      (this.$stateParams &&
        this.$stateParams.platformName &&
        this.$stateParams.platformName.toLowerCase()) ||
      '';

    this._processPlatform();
  }

  // Amazon
  _processAmazon() {
    const { state, selling_partner_id, spapi_oauth_code } = this.$location.search();
    if (!state || !selling_partner_id || !spapi_oauth_code) {
      this.$state.go('app.connect').then(() => {
        toastError(this.$translate.instant('connect.notifications.auth-failed'));
      });
      return;
    }

    const callback = this.AmazonService.connectStore;
    const payload = {
      state,
      selling_partner_id,
      spapi_oauth_code,
    };

    this._connectStoreHandler(callback, payload);
  }

  // BuyOnGoogle
  _processBuyOnGoogle() {
    const { state, code, scope } = this.$location.search();

    if (state !== this.$cookies.get('google_state')) {
      toastError(this.$translate.instant('toast.default-error'));
      return;
    }

    this.$cookies.remove('google_state');

    const callback = this.BuyOnGoogleService.connectStore;
    const payload = {
      code,
      scope,
    };

    this._connectStoreHandler(callback, payload);
  }
  // ./BuyOnGoogle

  // ChannelAdvisor
  _processChanneladvisor() {
    const channeladvisorGranted = {
      code: this.$location.search().code,
    };

    if (channeladvisorGranted.code) {
      this._saveChanneladvisorToken(channeladvisorGranted);
    }
  }

  _saveChanneladvisorToken(channeladvisorGranted) {
    this.ChanneladvisorService.saveChanneladvisorToken(channeladvisorGranted)
      .then((response) => this._multipleStoreKeyCreationCallback(response))
      .catch((error) => this._keyCreationFailedCallback(error));
  }
  // ./ChannelAdvisor

  // Etsy
  async _processEtsy() {
    const { code, state } = this.$location.search();
    const code_verifier = this.EtsyService.retrieveVerifier({ code, state });

    if (!code || !state || !code_verifier) {
      this.$state.go('app.connect').then(() => {
        toastError(this.$translate.instant('connect.notifications.auth-failed'));
      });
      return;
    }

    const payload = {
      code,
      code_verifier,
    };

    try {
      // Copy from EtsyService.connectStore
      // Auth and PlatformApi will be undefined there (not injected yet), so copy to here. Maybe a better solution?
      await this.Auth.retrieveSession();
      const response = await this.PlatformApi.etsy.connect(
        { company_id: this.UserSession.company.id },
        { store: payload }
      ).$promise;
      const { store } = response;

      this.StoreService.saveStoreValueToLocal(store);

      toastSuccess(this.$translate.instant('connect.notifications.added'));

      this.StoreService.sendStoreConnectedEvents(store);

      await this.StoreService.getStores();

      this.$state.go('app.single-store', { id: store.id, store });
    } catch (error) {
      this._keyCreationFailedCallback(error);
    }
  }
  // ./Etsy

  // Lazada
  _processLazada() {
    const lazadaGranted = {
      code: this.$location.search().code,
    };

    if (lazadaGranted.code) {
      this._saveLazadaToken(lazadaGranted);
    }
  }

  _saveLazadaToken(lazadaGranted) {
    this.LazadaService.saveLazadaToken(lazadaGranted)
      .then((response) => this._keyCreationCallback(response))
      .catch((error) => this._keyCreationFailedCallback(error));
  }
  // ./Lazada

  // Shopify
  _processShopify() {
    const { code, hmac, host, shop, state, timestamp } = this.$location.search();

    if (!state || !code) {
      // Params insufficient
      this.$state.go('app.connect').then(() => {
        toastError(this.$translate.instant('connect.notifications.auth-failed'));
      });
      return;
    }

    const callback = this.ShopifyService.checkValidShopifyResponse;

    const payload = {
      code,
      hmac,
      host,
      shop,
      state,
      timestamp,
    };

    this._connectStoreHandler(callback, payload);
  }

  _checkCreateCarrierService(store) {
    this.ShopifyService.checkCreateCarrierService(store)
      .then(() => {
        toastSuccess(this.$translate.instant('connect.notifications.rates-installed'));
      })
      .finally(() => this.$state.go('app.single-store', { id: store.id, store }));
  }
  // ./Shopify

  // Square
  _processSquare() {
    const { state, code } = this.SquareService.getParams(this.$window.location);

    if (state !== this.$window.localStorage.getItem('squareState')) {
      toastError(this.$translate.instant('toast.default-error'));
      return;
    }

    this.$window.localStorage.removeItem('squareState');

    const callback = this.SquareService.connectStore;
    const payload = {
      code,
      state,
    };

    this._connectStoreHandler(callback, payload);
  }
  // ./Square

  // Tradegecko
  _processTradegecko() {
    const tradegeckoGranted = {
      code: this.$location.search().code,
    };

    if (tradegeckoGranted.code) {
      this._saveTradegeckoToken(tradegeckoGranted);
    }
  }

  _saveTradegeckoToken(tradegeckoGranted) {
    this.TradegeckoService.saveTradegeckoToken(tradegeckoGranted)
      .then((response) => this._keyCreationCallback(response))
      .catch((error) => this._keyCreationFailedCallback(error));
  }
  // ./Tradegecko

  // Wix
  _processWix() {
    const { code, instanceId } = this.$location.search();

    if (!code || !instanceId) {
      // Params insufficient
      this.$state.go('app.connect').then(() => {
        toastError(this.$translate.instant('connect.notifications.auth-failed'));
      });
      return;
    }

    const callback = this.WixService.connectStore;
    const payload = {
      code,
      instanceId,
    };

    this._connectStoreHandler(callback, payload);
  }
  // ./Wix

  async _connectStoreHandler(callback, payload) {
    try {
      const response = await callback(payload);
      const { store } = response;

      toastSuccess(this.$translate.instant('connect.notifications.added'));
      this.StoreService.sendStoreConnectedEvents(store);

      await this.StoreService.getStores();

      this.$state.go('app.single-store', { id: store.id, store });
    } catch (error) {
      this._keyCreationFailedCallback(error);
    }
  }

  _processPlatform() {
    switch (this.platformName) {
      case 'amazon':
        return this._processAmazon();
      case 'buy_on_google':
        return this._processBuyOnGoogle();
      case 'channeladvisor':
        return this._processChanneladvisor();
      case 'etsy_group':
        return this._processEtsy();
      case 'lazada':
        return this._processLazada();
      case 'shopify':
        return this._processShopify();
      case 'square':
        return this._processSquare();
      case 'tradegecko':
        return this._processTradegecko();
      case 'wix':
        return this._processWix();
      default:
        return this.$state.go('app.connect');
    }
  }

  // Copied from platform-redirect.controller.js
  // -------- KEY CREATION ----------------------
  _keyCreationCallback(response) {
    const { store, flow } = response;

    // NOTE: Because this code is often run immediately after page load,
    // StoreService.stores not a reliable way to detect if the store is new.
    // Set the flow variable, whenever possible
    const index = _.findIndex(this.StoreService.stores, { id: store.id });

    if (index > -1 || flow) {
      this.StoreService.stores[index] = store;

      toastSuccess(this.$translate.instant('connect.notifications.updated'));

      // If the platform is Shopify, also try to install the Checkout feature
      if (this.$stateParams.platformName === 'shopify') {
        this._checkCreateCarrierService(store);
        return;
      }
    } else {
      this.StoreService.stores.unshift(store);
      toastSuccess(this.$translate.instant('connect.notifications.added'));
    }

    this.StoreService.sendStoreConnectedEvents(store);

    this.$state.go('app.single-store', { id: store.id, store });
  }

  _multipleStoreKeyCreationCallback(response) {
    this._updateStoreService(response.stores, response.failed_count);
  }

  _keyCreationFailedCallback(error) {
    if (error?.data?.message) {
      toastError(error.data.message);
    } else {
      // eslint-disable-next-line no-nested-ternary
      const translationId = !error
        ? 'connect.notifications.auth-failed'
        : 'connect.notifications.connect-error';

      const details =
        error.data &&
        ((error.data.username && error.data.username[0]) ||
          (error.data.validation_error && error.data.validation_error[0]) ||
          error.data.status);

      toastError(this.$translate.instant(translationId, { details }));
    }

    this.$state.go('app.connect');
  }

  _updateStoreService(stores, failedCount) {
    if (stores.length > 0) {
      this.StoreService.stores = stores.concat(this.StoreService.stores);
      toastSuccess(
        this.$translate.instant(
          `connect.notifications.added${stores.length > 1 ? '-multiple' : ''}`,
          { count: stores.length }
        )
      );

      this.StoreService.sendStoreConnectedEvents(stores[0]);
    }

    if (failedCount > 0) {
      toastError(
        this.$translate.instant(
          `connect.notifications.add-failed${failedCount > 1 ? '-multiple' : ''}`,
          { count: failedCount }
        )
      );
    }
  }

  // -------- ./KEY CREATION ----------------------

  _hidePageSpinner() {
    const spinnerElement = document.getElementById('app__initial-load');
    if (spinnerElement) spinnerElement.parentNode.removeChild(spinnerElement);
  }
}

const RedirectionComponent = {
  controller: Redirection,
  template,
};

export { RedirectionComponent };
