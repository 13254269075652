import axios from 'axios';
import { IFlowRecords } from 'typings/user-session';
import { convertToSnake } from '@client/src/utils/mappers';
import { UserPort } from '../../ports/user';
import { FlowRecordsPayload } from '../../models/payloads/user-payload';

// This is to replace all of the things in User.resource from Angular
export class UserGateway implements UserPort {
  updateFlowRecords(userId: string, payload: FlowRecordsPayload): Promise<IFlowRecords> {
    return axios.post(
      `${import.meta.env.VITE_APP_ENDPOINT}/users/${userId}/flow_records`,
      convertToSnake(payload)
    );
  }
}
