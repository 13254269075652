import { IApiConfig } from 'typings/core/config/api';
import { IShopifyService } from 'typings/platform';
import { IStoreService } from 'typings/store';

import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import style from './insurance-activation-modal.module.scss';
import template from './insurance-activation-modal.html?raw';

class StoreSettingsInsuranceActivationModalController implements IComponentController {
  style = style;
  busy = false;

  static $inject = ['$window', 'API', 'StoreService', 'ShopifyService'];
  constructor(
    private $window: ng.IWindowService,
    private API: IApiConfig,
    private StoreService: IStoreService,
    private ShopifyService: IShopifyService
  ) {}

  authorize(): void {
    const store = this.StoreService.activeStore;
    if (store) {
      this.ShopifyService.getShopifyKey(store)
        .then((redirectUrl) => {
          this.$window.location.href = redirectUrl as string;
        })
        .catch((error) => {
          toastError(error.message || error);
        });
    }
  }

  get productInstalled(): boolean {
    return !!this.StoreService.activeStore?.insurance_at_checkout_product_installed;
  }

  get snippetInstalled(): boolean {
    return !!this.StoreService.activeStore?.insurance_at_checkout_snippet_installed;
  }

  get supportUrl(): string {
    return `${this.API.help}/hc/en-us/articles/4402695345293-Insurance-at-Checkout-BETA`;
  }
}

const StoreSettingsInsuranceActivationModalComponent: ng.IComponentOptions = {
  controller: StoreSettingsInsuranceActivationModalController,
  template,
  bindings: {
    esShow: '<',
    esClose: '&',
    esShowExample: '&',
  },
};

export { StoreSettingsInsuranceActivationModalComponent };
