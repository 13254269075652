import { ShippingCountryData } from '@client/core/corelogic/models/ShippingCountryData';
import { ShippingCountryPort } from '@client/core/corelogic/ports/shippingCountry';
import angular from 'angular';
import { ICountryService } from 'typings/auth/services/country';

export default class NgShippingCountryGateway implements ShippingCountryPort {
  private countryService: ICountryService;

  constructor() {
    this.countryService = angular.element(document.body).injector().get('CountryService');
  }

  getShippingCountries(): Promise<ShippingCountryData[]> {
    return new Promise((resolve, reject) => {
      this.countryService
        .getShippingPublicCountries()
        .then(({ countries }) =>
          resolve(
            countries
              .filter((country) => country.allow_dashboard_signup === true)
              .map((country) => ({
                id: country.id,
                name: country.name,
                alpha2: country.alpha2,
              }))
          )
        )
        .catch((reason) => reject(reason));
    });
  }
}
