import React, { ReactElement } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardHeader, { CardHeaderProps as MuiCardHeaderProps } from '@material-ui/core/CardHeader';
import CardContent, {
  CardContentProps as MuiCardContentProps,
} from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import Input from '@client/core/components/react/Input';
import { ProductFormData } from '../reset-confirmation/ProductFormData';

interface ProductFulfillmentCardProps {
  CardHeaderProps: MuiCardHeaderProps;
  CardContentProps: MuiCardContentProps;
}

export default function ProductFulfillmentCard({
  CardHeaderProps,
  CardContentProps,
}: ProductFulfillmentCardProps): ReactElement {
  const { control } = useFormContext<ProductFormData>();
  const { formatMessage } = useIntl();

  return (
    <Card>
      <CardHeader {...CardHeaderProps} />
      <CardContent {...CardContentProps}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  label={formatMessage({ id: 'product-listing.details.pick-location' })}
                  {...field}
                  error={!!fieldState.error}
                  maxLength={80}
                  placeholder={formatMessage({
                    id: 'product-listing.details.pick-location-placeholder',
                  })}
                />
              )}
              control={control}
              rules={{ maxLength: 80 }}
              name="pick_location"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
