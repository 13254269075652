import { API } from '@client/core/config/api.constant';
import * as Sentry from '@sentry/browser';

function sentryInit() {
  Sentry.init({
    // You can find the DSN here: https://easyship.sentry.io/settings/projects/easyship-dashboard/keys/
    dsn: 'https://74e6ac1de81cc93a11b0a3257d9dac2a@o177970.ingest.sentry.io/4505871841427456',
    enabled: import.meta.env.VITE_APP_ENVIRONMENT === 'production',
    release: import.meta.env.VITE_APP_BUILD_VERSION,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', API.baseEndpoint],
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay(),
    ],
    beforeSend(event) {
      if (shouldByPassSentry(event)) {
        return null;
      }
      return event;
    },
    // Performance Monitoring
    tracesSampleRate: 0.06,

    // Profile Monitoring, the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    profilesSampleRate: 1.0, // 0.06 * 1.0 = 0.06

    // Session Replay
    replaysSessionSampleRate: 0.06, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      // Ignore errors that match any of the patterns
      'Non-Error promise rejection captured',
      'ResizeObserver loop',
    ],
    /* Ignore errors from these urls */
    denyUrls: [
      /sockjs-mt1\.pusher\.com/i,
      /script\.hotjar\.com/i,
      /api\.hubspot\.com/i,
      /easyship\.surveysparrow\.com/i,
      /api-js\.mixpanel\.com/i,
      /stats\.g\.doubleclick\.net/i,
      /forms\.hubspot\.com/i,
      /ekr\.zdassets\.com/i,
      /clarity\.ms/i,
      /hotjar\.io/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Safari extensions
      /^webkit-masked-url:\/\//i,
    ],
  });
}

// Reference: https://github.com/getsentry/sentry-javascript/issues/3147
function shouldByPassSentry(event: Sentry.Event | undefined): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (!frames || frames.length === 0) return false;

  return isErrorFromAnonymous(frames) || isErrorFromGoogleTagManager(frames);
}

function isErrorFromAnonymous(frames: Sentry.StackFrame[]): boolean {
  const firstFrame = frames[0];
  return firstFrame.filename === '<anonymous>';
}

function isErrorFromGoogleTagManager(frames: Sentry.StackFrame[]): boolean {
  return frames.some(
    (frame) =>
      typeof frame.filename === 'string' &&
      (frame.filename.startsWith('https://www.googletagmanager.com') ||
        frame.filename.startsWith('https://googleads.'))
  );
}

export { sentryInit };
