import template from './address-form-gb.html?raw';

(function () {
  'use strict';

  var addressFormGb = {
    template,
    bindings: {
      address: '=',
      fieldValidators: '<',
    },
    controller: 'addressFormGbCtrl as ctrl',
  };

  angular.module('app.component.address-form-gb').component('addressFormGb', addressFormGb);
})();
