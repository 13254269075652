import template from './fill-dimensions-message.component.html?raw';
import style from './fill-dimensions-message.module.scss';

class FillDimensionsMessageController {
  static $inject = ['$timeout', 'OptionalDimensionsModalService', 'MixpanelService'];

  constructor($timeout, OptionalDimensionsModalService, MixpanelService) {
    this.style = style;
    this.$timeout = $timeout;
    this.OptionalDimensionsModalService = OptionalDimensionsModalService;
    this.MixpanelService = MixpanelService;
    this.wrappers = {
      a(text) {
        return `<a ng-click="$ctrl.openMissingDimensionsModal()">${text}</a>`;
      },
    };
  }

  $onInit() {}

  openMissingDimensionsModal() {
    this.onClick();

    this.MixpanelService.track('Multiple - Edit Courier - Click Enter Dimensions');

    this.$timeout(() => {
      this.OptionalDimensionsModalService.open({
        modal: 'missing-dimensions',
        shipmentId: this.shipmentId,
      });
    }, 300);
  }
}

const FillDimensionsMessageComponent = {
  template,
  controller: FillDimensionsMessageController,
  bindings: {
    shipmentId: '<',
    onClick: '&',
  },
};

export { FillDimensionsMessageComponent };
