import React, { memo } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  classNameInner?: string;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
};
export const RoundedCorner = memo(({ className, classNameInner, position = 'top-left' }: Props) => {
  return (
    <div
      className={twMerge(
        'bg-sky-300 absolute z-10 size-[10px]',
        (position === 'top-left' && 'top-0 left-0') ||
          (position === 'bottom-left' && 'bottom-0 left-0 rotate-[-90deg]'),
        className
      )}
    >
      <div className={twMerge('size-[10px] bg-white rounded-tl-full', classNameInner)} />
    </div>
  );
});

export default RoundedCorner;
