export type Country = {
  alpha2: string;
  name: string;
  contactNumber: string;
};

export const COUNTRIES: Country[] = [
  {
    alpha2: 'au',
    name: 'Australia',
    contactNumber: '+61 3 4829 7409',
  },
  {
    alpha2: 'us',
    name: 'United States',
    contactNumber: '+1 (413) 320 4238',
  },
  {
    alpha2: 'gb',
    name: 'United Kingdom',
    contactNumber: '+44 1322 936267',
  },
  {
    alpha2: 'hk',
    name: 'Hong Kong',
    contactNumber: '+852 3001 5637',
  },
  {
    alpha2: 'sg',
    name: 'Singapore',
    contactNumber: '+65 3129 4408',
  },
];
