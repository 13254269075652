import { IFedexB13AFiling, IShipmentOrderData } from 'typings/shipment';
import { IComponentController } from 'angular';
import template from './b13a-filing.html?raw';
import style from './b13a-filing.module.scss';

import {
  B13A_FILING_SERVICE_NAME,
  B13A_FILING_NOT_REQUIRED,
  B13A_FILING_OPTIONS,
  B13aFilingOption,
} from './constant';

interface B13aFilingSelectOption {
  value: B13aFilingOption;
  label: string;
}

class B13aFiling implements IComponentController {
  style = style;
  b13aFilingOptions: B13aFilingSelectOption[] = [];
  esModel: IShipmentOrderData = {};
  esAdditionalServices: string[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    this.esModel = this.esModel || {};
    this.b13aFilingOptions = B13A_FILING_OPTIONS.map((option) => ({
      value: option,
      label: this.$translate.instant(`shipments.b13a-filing.options.${option}`),
    }));

    if (!this.esModel.fedex_b13a_filing_option) {
      this.esModel.fedex_b13a_filing_option = B13A_FILING_NOT_REQUIRED;
    }

    if (!this.esAdditionalServices.includes(B13A_FILING_SERVICE_NAME)) {
      this.esAdditionalServices.push(B13A_FILING_SERVICE_NAME);
    }
  }

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully
  }

  onInputChange(value: B13aFilingOption, field: keyof IFedexB13AFiling) {
    this.esModel[field] = value;

    this.esOnChange({ value: this.esModel });
  }
}

const B13aFilingComponent: ng.IComponentOptions = {
  controller: B13aFiling,
  template,
  bindings: {
    esModel: '<',
    esAdditionalServices: '<',
    esOnChange: '&',
  },
};

export { B13aFilingComponent };
