(function () {
  'use strict';

  Message.$inject = ['$resource', 'API'];
  function Message($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/:controller',
      {
        controller: '@_controller',
      },
      {
        create: {
          method: 'POST',
          params: {},
        },
      }
    );
  }

  angular.module('app.factory.Message', []).factory('Message', Message);
})();
