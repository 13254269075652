import { toastError } from '@client/core/components/react/Toastify';
import failedImageUrl from '@assets/images/dashboard/fix-shipment-modal/fail.svg';
import template from './failed.html?raw';
import style from './failed.module.scss';

class ReturnModalFailedReturn {
  failedImageUrl = failedImageUrl;

  static $inject = [
    'ReturnModal',
    'ReturnService',
    'UserSession',
    'FixMonkeyService',
    'fixShipmentModal',
    '$timeout',
  ];

  constructor(
    ReturnModal,
    ReturnService,
    UserSession,
    FixMonkeyService,
    fixShipmentModal,
    $timeout,
    $translate
  ) {
    this.style = style;
    this.ReturnModal = ReturnModal;
    this.ReturnService = ReturnService;
    this.UserSession = UserSession;
    this.FixMonkeyService = FixMonkeyService;
    this.fixShipmentModal = fixShipmentModal;
    this.$timeout = $timeout;
    this.$translate = $translate;

    this.failedData = {};
    this.busy = false;
  }

  $onInit() {
    this.failedData = this.ReturnService.failedData;
  }

  close() {
    this.ReturnModal.close();
  }

  goToEditShipment() {
    this.busy = true;

    // Include http_response to fetch error message from failed shipment
    const shipmentDetails = {
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: this.failedData.return_shipment_id,
      include: 'shipment_items,http_response',
    };

    this.FixMonkeyService.prepareShipment(shipmentDetails)
      .then(() => {
        this.FixMonkeyService.goToEditShipmentStep();
        this.ReturnModal.close();

        this.$timeout(() => {
          this.fixShipmentModal.open();
        }, 500);
      })
      .catch((err) => {
        toastError(this.$translate.instant('toast.default-error'));
        throw err;
      })
      .finally(() => {
        this.busy = false;
      });
  }
}

const ReturnModalFailedReturnComponent = {
  controller: ReturnModalFailedReturn,
  template,
  bindings: {
    shipment: '<',
  },
};

export { ReturnModalFailedReturnComponent };
