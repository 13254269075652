import { makeStyles } from '@material-ui/core/styles';
import COLORS from '@client/src/colors';

export const useStyles = makeStyles({
  root: {
    padding: '16px 12px',
    border: `1px solid ${COLORS.skyNormal}`,
    borderRadius: 8,
    marginBottom: 3,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',

    '&:hover': {
      backgroundColor: COLORS.skyLight,
      cursor: 'pointer',
    },

    '&:hover > div': {
      visibility: 'visible',
    },

    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,
      color: COLORS.white,
      position: 'relative',
      left: 1,
    },
  },
  arrowIcon: {
    height: 35,
    width: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    bottom: 7,
    borderRadius: 4,
    backgroundColor: COLORS.tealNormal,
    boxShadow: `0 5px 15px ${COLORS.tealNewLight}`,
    visibility: 'hidden',
  },
});
