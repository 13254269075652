class PaymentFormService {
  constructor() {
    this._paymentForms = {};
  }

  registerPaymentForm({ id, onSubmit, onReset }) {
    if (typeof id !== 'string') {
      throw new Error('PaymentFormService: An id must be provided.');
    }

    if (typeof onSubmit !== 'function') {
      throw new Error('PaymentFormService: onSubmit must be a function.');
    }

    if (typeof onReset !== 'function') {
      throw new Error('PaymentFormService: onReset must be a function.');
    }

    this._paymentForms = {
      ...this._paymentForms,
      [id]: { onSubmit, onReset },
    };
  }

  unregisterPaymentForm(id) {
    delete this._paymentForms[id];
  }

  submitPaymentForm(id) {
    if (this._isNotRegistered(id)) {
      throw new Error(`PaymentFormService: Form '${id}' has not been registered yet.`);
    }

    return this._paymentForms[id].onSubmit();
  }

  resetPaymentForm(id) {
    if (this._isNotRegistered(id)) {
      throw new Error(`PaymentFormService: Form '${id}' has not been registered yet.`);
    }

    return this._paymentForms[id].onReset();
  }

  _isNotRegistered(id) {
    return typeof this._paymentForms[id] === 'undefined';
  }
}

export { PaymentFormService };
