import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import headerTemplate from './summary-header.html?raw';
import template from './summary.html?raw';
import style from './summary.module.scss';

class ReschedulePickupSummary {
  static $inject = [
    '$state',
    '$stateParams',
    '$translate',
    'API',
    'UserSession',
    'EndpointService',
    'CheckoutService',
    'HelperService',
    'MixpanelService',
    'PaymentService',
    'UserStatusService',
    'PaymentSourceService',
    'ShipmentListManageService',
  ];

  constructor(
    $state,
    $stateParams,
    $translate,
    API,
    UserSession,
    EndpointService,
    CheckoutService,
    HelperService,
    MixpanelService,
    PaymentService,
    UserStatusService,
    PaymentSourceService,
    ShipmentListManageService
  ) {
    this.style = style;
    this.headerTemplate = headerTemplate;

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$translate = $translate;

    this.API = API;
    this.UserSession = UserSession;
    this.EndpointService = EndpointService;
    this.CheckoutService = CheckoutService;
    this.HelperService = HelperService;
    this.MixpanelService = MixpanelService;
    this.PaymentService = PaymentService;
    this.UserStatusService = UserStatusService;
    this.PaymentSourceService = PaymentSourceService;
    this.ShipmentListManageService = ShipmentListManageService;

    this.originState = this.$state.current.parent;
    this.paymentCurrency = this.UserSession.company.currency;
    this.busy = {};
    this.error = {};
    this.topUpNeeded = false;
    this.usesHybridPayment = false;
    this.paymentFormId = 'top-up-payment-form';
  }

  $postLink() {
    this.HelperService.compileAndAppendTemplateToTarget(
      this.headerTemplate,
      '#summary-header',
      this
    );
  }

  $onInit() {
    // GET params for this page
    // this.EndpointService.set('orders');

    // Redirects back to Manage Shipments if no params
    if (!this._hasCheckoutData()) {
      this._goToParentPage();
    }

    this.couriersSummary = this._buildSummaryData(this.CheckoutService.data.origins);
    this.hasInsufficientFunds = this._setHasInsufficientFunds();
  }

  /**
   * [prev]
   * @return {[type]} [description]
   */
  prev() {
    if (this.$stateParams.comingFromPickupsPage) {
      return this.$state.go('app.reschedule-pickup.handover', {
        comingFromPickupsPage: this.$stateParams.comingFromPickupsPage,
      });
    }

    return this.$state.go('app.reschedule-pickup.handover');
  }

  // (main action button) Button pay
  next() {
    if (this.busyPaying) return;

    this.busyPaying = true;

    if (this.hasInsufficientFunds) {
      this.PaymentSourceService.paymentAmount =
        this.CheckoutService.data.displayableTotalToTopUp ||
        this.CheckoutService.data.totals.total_to_top_up;

      this.PaymentService.submitPaymentForm(this.paymentFormId)
        .then((results) => {
          const valid = results && results.valid;
          const paymentSourceId = results && results.paymentSourceId;

          if (!valid) {
            throw new Error(
              'Some credit card information is incomplete or invalid. Please check that everything is correct.'
            );
          }

          this._pay({ paymentSourceId });
        })
        .catch((error) => {
          const errorMessage = error && error.message;

          if (errorMessage) {
            toastError(errorMessage);
          }

          this.busyPaying = false;
        });
    } else {
      this._pay();
    }
  }

  /**
   * Calls the process#confirm endpoint
   * On success redirects to the shipments page
   * @param  {object} paymentOptions Contains the paymentSourceId and other meta data about the payment
   *
   * call pay Orders
   * redirect
   */
  _pay(paymentOptions = {}) {
    const token = paymentOptions.paymentSourceId;
    const isReschedulePickup = true;
    const preferredTimeSlots = [];
    const noun = this.$translate.instant(
      'global.pluralize.pickup',
      { values: { count: 1 } },
      'messageformat'
    );

    this.CheckoutService.data.origins.forEach((origin) => {
      origin.couriers.forEach((courier) => {
        preferredTimeSlots.push(courier.pickup_preferred_timeslot);
      });
    });

    this.stripeErrorMessage = '';
    this._sendPaymentSubmitMixpanelEvent(
      preferredTimeSlots,
      this.CheckoutService.data,
      this.CheckoutService.payload
    );

    this.CheckoutService.payOrders(this.CheckoutService.payload, token, isReschedulePickup)
      .then(() => {
        this.orderSuccess = true;

        if (this.$stateParams.comingFromPickupsPage) {
          this.$state.go('app.pickups', { page: 1 });
        } else {
          this.$state.go('app.shipments', { page: 1 });
        }

        toastSuccess(this.$translate.instant('toast.reschedule-success', { noun }));

        const isRequestPickupTimeFailed = this.CheckoutService.data.origins.some((origin) =>
          origin.couriers.some((courier) => courier.pickup_window_flag_required)
        );

        this.MixpanelService.track('Reschedule Pickup Success', {
          preferred_timeslot: preferredTimeSlots,
          ...(isRequestPickupTimeFailed && { shipment_status_id: 154 }),
        });

        this.CheckoutService.clearCheckoutDataAndPayload();
      })
      .catch((err) => {
        toastError(this.$translate.instant('toast.reschedule-error'), {
          noun,
          details: err.data ? err.data.errors.join('. ') : '',
        });
      })
      .finally(() => {
        this.busyPaying = false;
      });
  }

  _hasCheckoutData() {
    return Object.keys(this.CheckoutService.data).length > 0;
  }

  _goToParentPage() {
    if (this.$stateParams.comingFromPickupsPage) {
      return this.$state.go('app.pickups', { comingFromReschedulePickupPage: true });
    }

    return this.$state.go('app.shipments', { page: 1 });
  }

  _setHasInsufficientFunds() {
    const hasOverdraft = this.UserSession.company.is_authorized_overdraft;
    const displayableTotalToTopUp = this.HelperService.doesKeyValueExists(
      this.CheckoutService.data,
      'displayableTotalToTopUp'
    );

    this.topUpNeeded =
      displayableTotalToTopUp > this.UserStatusService.availableBalance &&
      this._isBalanceChargeable(displayableTotalToTopUp);

    // For Mixpanel events: is user using Credit AND Credit Card?
    this.usesHybridPayment = this.topUpNeeded && this.UserStatusService.availableBalance > 0;

    return this.topUpNeeded && !hasOverdraft;
  }

  _isBalanceChargeable(totalToPay) {
    return (
      totalToPay >=
      Math.abs(this.UserStatusService.availableBalance) + this.UserSession.getMinimumChargeAmount()
    );
  }

  _buildSummaryData(origins) {
    const toPay = { couriers: [] };
    const freeOfCharge = { couriers: [] };

    if (!origins) return { freeOfCharge, toPay };

    origins.forEach((origin) =>
      origin.couriers.forEach((courier) => {
        if (courier.totalCost > 0 && courier.is_easyship_courier) {
          toPay.couriers.push(courier);
        } else {
          freeOfCharge.couriers.push(courier);
        }
      })
    );

    return { freeOfCharge, toPay };
  }

  _sendPaymentSubmitMixpanelEvent(preferredTimeSlots, data = {}) {
    const eventData = {
      preferred_timeslot: preferredTimeSlots,
      payment_method: this._getPaymentMethodForAnalytics(),
      number_of_orders: this._getTotalNumberOfShipments(data.origins),
      total_pay_at_easyship: data.totals && data.totals.total_pay_at_easyship,
      total_pay_at_courier: data.totals && data.totals.total_pay_at_courier,
      grand_total:
        data.totals && data.totals.total_pay_at_easyship + data.totals.total_pay_at_courier,
    };

    this.MixpanelService.track('Reschedule Pickup - Submit To Payment (Step 2) ', eventData);
  }

  _getTotalNumberOfShipments(origins) {
    let total = 0;

    origins.forEach((origin) =>
      origin?.couriers?.forEach((courier) => {
        if (courier.shipment_ids) total += courier.shipment_ids.length;
      })
    );

    return total;
  }

  _getPaymentMethodForAnalytics() {
    if (this.usesHybridPayment) {
      return 'hybrid';
    }
    if (this.topUpNeeded) {
      return 'credit_card';
    }
    return 'easyship_bucks';
  }
}

const ReschedulePickupSummaryComponent = {
  controller: ReschedulePickupSummary,
  template,
};

export { ReschedulePickupSummaryComponent };
