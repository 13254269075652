import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './split-modal-main.html?raw';
import style from './split-modal-main.module.scss';

class SplitModalMain {
  static $inject = [
    'API',
    '$state',
    'SplitModal',
    'ShipmentListAdvancedService',
    'ShipmentList',
    'ShipmentCache',
    'EndpointService',
    'MixpanelService',
    '$timeout',
    '$translate',
  ];

  constructor(
    API,
    $state,
    SplitModal,
    ShipmentListAdvancedService,
    ShipmentList,
    ShipmentCache,
    EndpointService,
    MixpanelService,
    $timeout,
    $translate
  ) {
    this.style = style;
    this.API = API;
    this.$state = $state;
    this.SplitModal = SplitModal;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.ShipmentList = ShipmentList;
    this.ShipmentCache = ShipmentCache;
    this.EndpointService = EndpointService;
    this.MixpanelService = MixpanelService;
    this.$timeout = $timeout;
    this.$translate = $translate;

    this.items = [];
    this.itemsToSplit = {};
    this.busy = false;
    this.error = null;
  }

  $onInit() {
    // Get all of the `parcel.shipment_items_attributes`
    this.items = this.shipment.parcels_attributes.reduce((accumulator, parcel) => {
      return [...accumulator, ...parcel.shipment_items_attributes];
    }, []);
  }

  onChangeQuantity(value, id) {
    this.itemsToSplit[id] = Number(value);
  }

  onSubmit() {
    this.MixpanelService.track('Multiple - Select Split - Confirm');

    const totalQuantityToSplit = this._getTotalQuantityToSplit(this.itemsToSplit);
    const splitOrderPayload = this._prepareSplitOrderPayload(this.itemsToSplit);

    if (!totalQuantityToSplit) {
      toastError(this.$translate.instant('split.error-select'));
      return;
    }

    if (totalQuantityToSplit === this.shipment.shipment_items_count) {
      toastError(this.$translate.instant('split.error-deselect'));
      return;
    }

    this.busy = true;

    this.ShipmentListAdvancedService.splitConfirm(splitOrderPayload, this.shipment.id)
      .then((response) => {
        // Need to add timeout for shipment reindex (Elastic search)
        this.$timeout(() => {
          this.busy = false;
          this.SplitModal.close();
          toastSuccess(this.$translate.instant('split.success'));
          this.ShipmentListAdvancedService.resetShipmentActionsConfirm();

          const { newly_split_shipment_ids } = response;
          if (newly_split_shipment_ids) {
            newly_split_shipment_ids.forEach((id) => {
              this.ShipmentListAdvancedService.setUpdatedShipments(id);
            });
          }

          this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter('orders_all');
        }, 1000);
      })
      .catch((error) => {
        this.busy = false;
        this.error =
          (error.data && error.data.status) || 'Sorry, something went wrong, please try again.';
      });
  }

  onCloseClick() {
    this.MixpanelService.track('Multiple - Split - Cancel');
    this.SplitModal.close();
  }

  _getTotalQuantityToSplit(items) {
    return Object.keys(items).reduce((accumulator, item) => {
      return accumulator + items[item];
    }, 0);
  }

  _prepareSplitOrderPayload(items) {
    return Object.keys(items).reduce((accumulator, item) => {
      accumulator.push({
        id: item,
        quantity: items[item],
      });

      return accumulator;
    }, []);
  }
}

const SplitModalMainComponent = {
  template,
  controller: SplitModalMain,
  bindings: {
    shipment: '<',
  },
};

export { SplitModalMainComponent };
