'use strict';
import template from './quote.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.quote', {
      url: '^/quote',
      template,
      controller: 'QuoteCtrl',
      controllerAs: 'vm',
      params: {
        enquiry: null,
        onboarding: false,
      },
      data: {
        requiredUserRight: 'menu.quote',
      },
    });
  },
]);
