import { IUserSession } from 'typings/user-session';
import {
  CollectDataResponse,
  CollectEventsResponse,
  CollectLinkResponse,
  CollectResendEmailResponse,
} from 'typings/collect';
import { ResendEmailPayload } from '@client/core/corelogic/models/Collect';
import { CollectResource } from './collect.resource';

class CollectService {
  static $inject = ['CollectResource', 'UserSession'];
  constructor(private CollectResource: CollectResource, private UserSession: IUserSession) {}

  getData(shipmentId: string): Promise<CollectDataResponse> {
    return this.CollectResource.getData({
      shipment_id: shipmentId,
      company_id: this.UserSession.getCompanyId(),
    });
  }

  getLink(shipmentId: string): Promise<CollectLinkResponse> {
    return this.CollectResource.getLink({
      shipment_id: shipmentId,
      company_id: this.UserSession.getCompanyId(),
    });
  }

  getEvents(shipmentId: string): Promise<CollectEventsResponse> {
    return this.CollectResource.getEvents({
      shipment_id: shipmentId,
      company_id: this.UserSession.getCompanyId(),
    });
  }

  resendEmail(params: ResendEmailPayload): Promise<CollectResendEmailResponse> {
    return this.CollectResource.resendEmail({
      shipment_id: params.shipmentId,
      company_id: this.UserSession.getCompanyId(),
      bcc: params.hasBcc,
      type: params.type,
    });
  }
}

export { CollectService };
