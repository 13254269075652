import { IInputGroupRadioModel } from 'typings/form';
import { IAdvancedSearchMultiselectObject } from 'typings/advanced-search';

import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import template from './warehouse-status-multi-select-group.html?raw';
import style from './warehouse-status-multi-select-group.module.scss';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

const DEFAULT_FILTERS: IAdvancedSearchMultiselectObject[] = [
  { display: 'pending', value: 'pending', isDisplay: true },
  { display: 'created', value: 'created', isDisplay: true },
  { display: 'failed', value: 'failed', isDisplay: true },
  { display: 'packed', value: 'packed', isDisplay: true },
  { display: 'cancelled', value: 'cancelled', isDisplay: true },
  { display: 'shipped', value: 'shipped', isDisplay: true },
];

class WarehouseStateMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchMultiselectObject>
  implements IComponentController
{
  style = style;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [
    {
      title: 'Warehouse State is any of',
      value: MultiselectComponentModelValue.AnyOf,
    },
    {
      title: 'Warehouse State is none of',
      value: MultiselectComponentModelValue.NoneOf,
    },
    {
      title: 'Warehouse State is known',
      value: MultiselectComponentModelValue.Known,
    },
    {
      title: 'Warehouse State is unknown',
      value: MultiselectComponentModelValue.Unknown,
    },
  ];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions = [];

  static $inject = ['$scope'];
  constructor($scope: ng.IScope) {
    super($scope);
  }

  $onInit(): void {
    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    this.triggerChangeIfKnowOrUnknowModel(changes);
    this.assignDefaultSelectValue(this.esModelOptions || DEFAULT_FILTERS);
  }
}

const WarehouseStatusMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: WarehouseStateMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { WarehouseStatusMultiSelectGroupComponent };
