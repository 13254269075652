import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { queryClient } from '@client/src/global/context/QueryClientProvider';
import template from './box-delete.html?raw';
import style from './box-delete.module.scss';

class BoxDeleteController {
  static $inject = ['$translate', 'BoxService', 'MixpanelService', 'UserRightsService'];

  constructor($translate, BoxService, MixpanelService, UserRightsService) {
    this.BoxService = BoxService;
    this.MixpanelService = MixpanelService;
    this.UserRightsService = UserRightsService;
    this.$translate = $translate;

    this.style = style;
  }

  onSubmit() {
    this.loading = true;

    this.BoxService.deleteBox(this.box.id)
      .then(() => {
        this._deleteCachedBoxData();
        this.close();
        toastSuccess(this.$translate.instant('toast.delete-success', { noun: this.box.name }));
        this.MixpanelService.track('Boxes - Edit - Delete - Confirm');
      })
      .catch(() => {
        this.loading = false;
        this.error = true;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  onCancel() {
    this.onCancel();
    this.MixpanelService.track('Boxes - Edit - Delete - Cancel');
  }

  _deleteCachedBoxData() {
    const shipmentBoxesQueryData = queryClient.getQueryData(['shipmentBoxes']);

    if (shipmentBoxesQueryData) {
      const savedBoxCollection = shipmentBoxesQueryData[0];

      queryClient.setQueryData(
        ['shipmentBoxes'],
        [
          {
            name: savedBoxCollection.name,
            boxes: savedBoxCollection.boxes.filter((box) => box.id !== this.box.id),
          },
          ...shipmentBoxesQueryData.slice(1),
        ]
      );
    }
  }

  get isDeleteDisabled() {
    return !this.UserRightsService.canDeleteBox || this.loading;
  }
}

const BoxDeleteComponent = {
  template,
  controller: BoxDeleteController,
  bindings: {
    box: '<',
    close: '&',
    onCancel: '&',
  },
};

export { BoxDeleteComponent };
