import React from 'react';

import { FormattedMessage } from 'react-intl';

export interface DeliveryTimeMessageProps {
  min: number;
  max: number;
}

export function DeliveryTimeMessage({ min, max }: DeliveryTimeMessageProps) {
  if (min !== max) {
    return <FormattedMessage id="global.range-working-days" values={{ start: min, end: max }} />;
  }

  if (min > 0) {
    return (
      <FormattedMessage id="global.pluralize.with-count.working-day" values={{ count: min }} />
    );
  }

  return <FormattedMessage id="global.not-available" />;
}
