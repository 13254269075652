import template from './advanced-search.html?raw';
import style from './advanced-search.module.scss';

class AdvancedSearch {
  static $inject = [
    'BillingService',
    'UserSession',
    'HelperService',
    'TransactionRecord',
    '$filter',
    '$translate',
  ];

  constructor(BillingService, UserSession, HelperService, TransactionRecord, $filter, $translate) {
    this.style = style;
    this.BillingService = BillingService;
    this.UserSession = UserSession;
    this.HelperService = HelperService;
    this.TransactionRecord = TransactionRecord;
    this.$filter = $filter;
    this.$translate = $translate;

    this.today = new Date();

    this.types = [
      { display: 'all', value: '' },
      { display: 'credit', value: 'credit' },
      { display: 'debit', value: 'debit' },
    ].map((type) => ({
      ...type,
      display: this.$translate.instant(`account.billing.advance-search.types.${type.display}`),
    }));

    this.categories = [
      { display: 'all', value: '' },
      { display: 'shipment', value: 'shipment' },
      { display: 'pickup', value: 'pickup' },
      { display: 'adjustment', value: 'adjustment' },
      { display: 'refund', value: 'refund,cash-refund' },
      { display: 'taxes ', value: 'taxes/duties' },
      { display: 'bank', value: 'bank' },
      { display: 'paypal', value: 'paypal' },
      { display: 'payment', value: 'payment' },
      { display: 'check', value: 'check' },
      { display: 'transfer', value: 'transfer' },
      { display: 'cash', value: 'cash' },
      { display: 'voucher', value: 'companyvoucher' },
      { display: 'labelling-fees', value: 'lyoc_labelling_fee' },
    ].map((category) => ({
      ...category,
      display: this.$translate.instant(
        `account.billing.advance-search.categories.${category.display}`
      ),
    }));

    this.statuses = [
      { display: 'all', value: '' },
      { display: 'pending', value: 'pending_next_settlement' },
    ].map((status) => ({
      ...status,
      display: this.$translate.instant(`account.billing.advance-search.statuses.${status.display}`),
    }));
  }

  $onInit() {
    this.TransactionRecord.getFinanceDocumentsFilter({
      category: 'invoice,credit_note',
    }).then((data) => {
      if (data) {
        const docs = data.finance_documents.map((document) => ({
          ...document,
          display: this.$translate.instant(
            'account.billing.advance-search.options.document',
            {
              ref: document.reference_number,
              count: document.count,
              date: this.$filter('intlDate')(document.date_to, { timeZone: undefined }),
              total: this.$filter('intlCurrency')(
                document.total,
                this.UserSession.getCompanyCurrency()
              ),
            },
            'messageformat'
          ),
        }));
        this.documentOptions = [
          { id: '', value: '', display: this.$translate.instant('global.all') },
          ...docs,
        ];
      }
    });
  }

  $onChanges() {
    this.showReset = this.BillingService.isFiltered && this.checkFilter(this.BillingService.filter);
  }

  onFilterChange(value, key) {
    this.BillingService.filter[key] = value;
    this.showReset = false;
  }

  checkFilter(filter) {
    const { keyword, ...filterQuery } = filter;

    return (
      this.HelperService.doesObjHaveKeys(filterQuery) &&
      !this.HelperService.areObjKeyLengthsNil(filterQuery)
    );
  }

  handleSearch() {
    if (this.showReset) this.onReset();
    else this.onSearch();
  }
}

const AdvancedSearchComponent = {
  template,
  controller: AdvancedSearch,
  bindings: {
    open: '<',
    section: '<',
    onSearch: '&',
    onReset: '&',
    onClose: '&',
  },
};

export { AdvancedSearchComponent };
