function generateRandomValue(length) {
  var cryptoObj = window.crypto || window.msCrypto; // For compatibility with older browsers
  var randomValues = new Uint8Array(length);
  cryptoObj.getRandomValues(randomValues);
  return Array.from(randomValues, function (byte) {
    return ('0' + byte.toString(16)).slice(-2);
  }).join('');
}

class PaymentIntentService {
  static $inject = ['$http', 'API', 'UserSession', 'PaymentIntentResource'];

  constructor($http, API, UserSession, PaymentIntentResource) {
    this.$http = $http;
    this.UserSession = UserSession;
    this.PaymentIntentResource = PaymentIntentResource;

    this.endpoint = API.endpoint;
    this._refreshKey();
  }

  _refreshKey() {
    this.idempotencyKey = generateRandomValue(20);
  }

  createPaymentIntent(payload) {
    return this.$http({
      method: 'POST',
      url: `${this.endpoint}/companies/${this.UserSession.company.id}/payment_intents`,
      headers: { 'Idempotency-Key': this.idempotencyKey },
      data: payload,
    })
      .then((response) => response.data)
      .finally(() => this._refreshKey());
  }

  confirmPaymentIntent(payload) {
    return this.PaymentIntentResource.confirm(
      {
        company_id: this.UserSession.company.id,
        id: payload.payment_intent_id,
      },
      {}
    ).$promise;
  }
}

export { PaymentIntentService };
