import { IComponentController } from 'angular';
import template from './incoterms-header-tooltip.html?raw';

class ShipmentListIncotermsHeaderTooltip implements IComponentController {}

const ShipmentListIncotermsHeaderTooltipComponent: ng.IComponentOptions = {
  controller: ShipmentListIncotermsHeaderTooltip,
  template,
};

export { ShipmentListIncotermsHeaderTooltipComponent };
