import angular from 'angular';
import mixpanelService from '@client/core/services/mixpanel';
import easyshipButton from '@client/core/components/buttons/button';
import easyshipProgress from '@client/core/components/utilities/progress';
import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import { ProgressButtonComponent } from './progress-button.component';

const MODULE_NAME = 'app.global.components.dashboard-sidebar.onboarding-checklist.progress-button';

angular
  .module(MODULE_NAME, [
    mixpanelService,
    easyshipButton,
    easyshipProgress,
    onboardingChecklistService,
  ])
  .component('esOnboardingChecklistProgressButton', ProgressButtonComponent);

export default MODULE_NAME;
