import { IAutoRechargeResponse, IUpdateAutoRechargePayload } from 'typings/auto-recharge';
import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';

class AutoRechargeResource {
  autoRechargeEndpoint = '';

  static $inject = ['$resource', 'API', 'UserSession'];
  constructor(
    private $resource: ng.resource.IResourceService,
    private API: IApiConfig,
    private UserSession: IUserSession
  ) {}

  resource = this.$resource(
    `${this.API.endpoint}/companies/:company_id/auto_recharge`,
    {},
    {
      get: {
        method: 'GET',
        params: {
          company_id: this.UserSession.company.id,
        },
      },
      save: {
        method: 'PATCH',
        params: {
          company_id: this.UserSession.company.id,
        },
      },
    }
  );

  retrieve(): ng.IPromise<IAutoRechargeResponse> {
    return this.resource.get().$promise;
  }

  update(params: IUpdateAutoRechargePayload): ng.IPromise<IUpdateAutoRechargePayload> {
    return this.resource.save(params).$promise;
  }
}

export { AutoRechargeResource };
