import { IShowAddPaymentModal } from 'typings/payment-method';
import { IPaymentService } from 'typings/payment-service';
import { PaymentFormId } from '@client/data/payment-form-id';

import { IComponentController } from 'angular';
import template from './add-payment-flow.html?raw';
import style from './add-payment-flow.module.scss';

class AddPaymentFlow implements IComponentController {
  style = style;
  esCloseModalCallback = () => {};
  esShowModal: IShowAddPaymentModal = {
    addCard: false,
  };

  static $inject = ['PaymentService'];
  constructor(private PaymentService: IPaymentService) {}

  $onInit() {
    if (!this.esShowModal) {
      throw new Error('Add payment flow: An es Show Modal binding must be provided.');
    }
  }

  closePaymentModal(): void {
    this.esShowModal.addCard = false;
    this.PaymentService.unregisterPaymentForm(PaymentFormId.AddCreditCard);
    this.esCloseModalCallback();
  }
}

const AddPaymentFlowComponent: ng.IComponentOptions = {
  controller: AddPaymentFlow,
  template,
  bindings: {
    esShowModal: '=?',
    esIsBeforeShipmentFlow: '<',
    esCloseModalCallback: '&',
  },
};

export { AddPaymentFlowComponent };
