import angular from 'angular';

import { InsuranceService } from './insurance.service';
import { InsuranceResource } from './insurance.resource';

const MODULE_NAME = 'app.global.services.insurance';

angular
  .module(MODULE_NAME, [])
  .service('InsuranceService', InsuranceService)
  .service('InsuranceResource', InsuranceResource);

export default MODULE_NAME;
