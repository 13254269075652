import { IRegistrationSuccessResponse } from 'typings/auth/services/registration';
import {
  IShopifyKeyResponse,
  IShopifyResourceSuccessResponse,
  IShopifyParams,
} from 'typings/auth/services/shopify-auth';

import { IAuthService } from 'typings/core/services/auth';
import { ShopifyResource } from './shopify-auth.resource';
import { RegistrationResource } from '../registration/registration.resource';

const PLATFORM = 'shopify';
const STATE_NAME = 'state';

class ShopifyAuthService {
  static $inject = [
    '$location',
    '$window',
    '$cookies',
    'Auth',
    'RegistrationResource',
    'ShopifyResource',
  ];
  constructor(
    private $location: ng.ILocationService,
    private $window: ng.IWindowService,
    private $cookies: angular.cookies.ICookiesService,
    private Auth: IAuthService,
    private RegistrationResource: RegistrationResource,
    private ShopifyResource: ShopifyResource
  ) {}

  authorize(): ng.IPromise<IShopifyResourceSuccessResponse> {
    const params = this.$location.search();

    return this.ShopifyResource.authorize(params);
  }

  redirect(params: IShopifyResourceSuccessResponse): void {
    const redirectURL = params.redirect_uri;
    // NOTE: Use for local development testing
    // const redirectURL = 'http://localhost:9000/shopify/auth';

    this.$window.location.href = `https://${params.shop}/admin/oauth/authorize?client_id=${params.client_id}&scope=${params.scope}&redirect_uri=${redirectURL}&state=${params.state}`;
  }

  createRegistration(): ng.IPromise<IRegistrationSuccessResponse> {
    return this.RegistrationResource.platform(
      { platform: PLATFORM },
      { ...this.$location.search(), state_generated: this.getState() }
    );
  }

  setState(state: string): void {
    this.$window.localStorage.setItem(STATE_NAME, state);
  }

  getState(): string {
    const state = this.$window.localStorage.getItem(STATE_NAME);
    this.$window.localStorage.removeItem(STATE_NAME);

    return state || '';
  }

  handleBackendRedirectUrl(sessionToken: string, redirectUrl: string, categoryId?: number): void {
    const url = new URL(redirectUrl);
    const nonce = url.searchParams.get('state');

    if (nonce) {
      this.$window.localStorage.setItem('nonce', nonce);
    }

    if (categoryId) {
      this.$window.localStorage.setItem('storeCategory', categoryId.toString());
    }

    if (sessionToken) {
      this.Auth.setCredentials(sessionToken);
      this.$cookies.putObject('ES_USED_DASHBOARD', true, { domain: 'easyship.com' });
    }
  }

  getShopifyKey(
    locationObject: IShopifyParams,
    companyId: string
  ): ng.IPromise<IShopifyKeyResponse> {
    return this.ShopifyResource.getShopifyKey(locationObject, companyId);
  }
}

export { ShopifyAuthService };
