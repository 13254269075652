import { useMutation } from '@tanstack/react-query';
import { CreateUpsCourierAccountPayload } from '../../models/payloads/ups-oauth-payload';
import { useUpsOauthGateway } from '../../providers/UpsOauthGatewayProvider';

interface CreateUpsCourierAccountParams {
  companyId: string;
  payload: CreateUpsCourierAccountPayload;
}

export default function useCreateUpsCourierAccountMutation() {
  const { upsOauthGateway } = useUpsOauthGateway();

  return useMutation({
    mutationFn: ({ companyId, payload }: CreateUpsCourierAccountParams) =>
      upsOauthGateway.createUpsCourierAccount(companyId, payload),
  });
}
