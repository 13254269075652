(function () {
  'use strict';

  angular.module('app.component.fix-shipment-modal-body-pay-shipment', [
    'app.global.fix-monkey',
    'app.manage-shipments',
    'core.components',
    'app.global.payment',
  ]);
})();
