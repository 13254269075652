(function () {
  'use strict';

  Invitee.$inject = ['$resource', 'API'];
  function Invitee($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/:companies/:company_id/invitees/:id/:controller/:email_confirmation_token',
      {
        company_id: '@_company_id',
      },
      {
        query: {
          method: 'GET',
          params: {
            companies: 'companies',
          },
        },
        create: {
          method: 'POST',
          params: {
            companies: 'companies',
          },
        },
        checkInvitationToken: {
          method: 'GET',
          params: {
            controller: 'check',
            email_confirmation_token: '@email_confirmation_token',
          },
        },
        confirm: {
          method: 'POST',
          params: {
            controller: 'confirm',
          },
        },
        delete: {
          method: 'DELETE',
          params: {
            companies: 'companies',
            id: '@_id',
          },
        },
      }
    );
  }

  angular.module('app.factory.Invitee', []).factory('Invitee', Invitee);
})();
