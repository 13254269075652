import angular from 'angular';
import '@client/auth/global/services/registration';
import { LogInAsComponent } from './log-in-as.component';

const MODULE_NAME = 'app.log-in-as';

angular
  .module(MODULE_NAME, ['auth.services.registration'])
  .component('logInAsComponent', LogInAsComponent);

export default MODULE_NAME;
