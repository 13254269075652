import angular from 'angular';
import 'angular-dynamic-number';

import { InputComponent } from './input.component';

const MODULE_NAME = 'core.components.input';

angular.module(MODULE_NAME, ['dynamicNumber']).component('easyshipInput', InputComponent);

export default MODULE_NAME;

