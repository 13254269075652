import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import { IconButton } from 'easyship-components';
import { Columns as ColumnsIcon } from 'easyship-components/icons';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { useIntl } from 'react-intl';
import { ShipmentsTableSettingsGatewayProvider } from '@client/src/manage-shipments/providers/ShipmentsTableSettingsGatewayProvider';
import { FEATURE_KEY, FeatureKey } from '@client/data/subscription';
import { ColumnSettingsModal } from './ColumnSettingsModal';

interface ShipmentsTableColumnSettingsProps {
  isTaxCollectEnabled: boolean;
  openUpgradeModal: ({
    triggerSource,
    featureKey,
  }: {
    triggerSource: string;
    featureKey: FeatureKey;
  }) => void;
  planName: string | null;
  isFeatureAccessible: boolean;
}

export function ShipmentsTableColumnSettings({
  isTaxCollectEnabled,
  openUpgradeModal,
  planName,
  isFeatureAccessible,
}: ShipmentsTableColumnSettingsProps) {
  const { formatMessage } = useIntl();
  const translate = (id: string) => formatMessage({ id });
  const [isColumnSettingsModalOpen, setIsColumnSettingsModalOpen] = useState(false);

  const toggleColumnSettingsModal = () => {
    setIsColumnSettingsModalOpen((prevState) => !prevState);
  };

  const title = translate('shipments.table.column-settings.title');

  return (
    <>
      <IconButton
        aria-label={title}
        color="default"
        className="w-[34px] h-[34px]"
        tooltipProps={{
          label: title,
        }}
        onClick={toggleColumnSettingsModal}
      >
        <ColumnsIcon />
      </IconButton>
      <ColumnSettingsModal
        open={isColumnSettingsModalOpen}
        onClose={toggleColumnSettingsModal}
        isTaxCollectEnabled={isTaxCollectEnabled}
        openUpgradeModal={() =>
          openUpgradeModal({
            triggerSource: 'Customize Table Column Setting',
            featureKey: FEATURE_KEY.CustomizeShipmentTable,
          })
        }
        planName={planName}
        isFeatureAccessible={isFeatureAccessible}
      />
    </>
  );
}

function WrappedShipmentsTableColumnSettings(props: ShipmentsTableColumnSettingsProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentsTableSettingsGatewayProvider>
          <ShipmentsTableColumnSettings {...props} />
        </ShipmentsTableSettingsGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularShipmentsTableColumnSettings = react2angular(
  WrappedShipmentsTableColumnSettings,
  ['isTaxCollectEnabled', 'openUpgradeModal', 'planName', 'isFeatureAccessible'],
  []
);
