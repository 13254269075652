(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('focus', focus);

  function focus() {
    var directive = {
      restrict: 'A',
      link: link,
    };

    return directive;

    function link($scope, elem, attrs) {
      // Key codes for numbers 0-9
      var supportedKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

      elem.bind('keyup', function (e) {
        // Check if the user typed 0-9
        if (supportedKeys.indexOf(e.keyCode) > -1) {
          elem.next()[0].focus();
        }
      });
    }
  }
})();
