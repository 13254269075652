import { useMutation } from '@tanstack/react-query';
import { useAuthGateway } from '../providers/AuthGatewayProvider';

interface LoginParams {
  email: string;
  password: string;
  token: string;
}

export default function useLoginMutation() {
  const { authGateway } = useAuthGateway();

  return useMutation({
    mutationFn: ({ email, password, token }: LoginParams) =>
      authGateway.login(email, password, token),
  });
}
