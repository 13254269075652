import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

interface TellUsMoreProps {
  onChange: (feedback: string) => void;
}

export default function TellUsMore({ onChange }: TellUsMoreProps) {
  const intl = useIntl();

  return (
    <>
      <Typography variant="body2" style={{ marginBottom: '25px' }}>
        <FormattedMessage id="churn-reasons-flow.tell-us-more.sub-header" />
      </Typography>
      <TextField
        hiddenLabel
        multiline
        rows={10}
        placeholder={intl.formatMessage({
          id: 'churn-reasons-flow.tell-us-more.text-field-placeholder',
        })}
        variant="outlined"
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </>
  );
}
