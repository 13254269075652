import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useGeolocationGateway } from '@client/src/global/context/GeolocationProvider';
import { GeolocationData } from './models/Geolocation';

export default function useGeolocationQuery(params?: UseQueryOptions<GeolocationData>) {
  const { geolocationGateway } = useGeolocationGateway();

  return useQuery({
    queryKey: ['geolocation'],
    queryFn: () => geolocationGateway.getGeolocation(),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...params,
  });
}
