import angular from 'angular';

import taxIdInput from './tax-id-input';
import taxIdMessage from './tax-id-message';

const MODULE_NAME = 'app.global.components.tax-id';

angular.module(MODULE_NAME, [taxIdInput, taxIdMessage]);

export default MODULE_NAME;
