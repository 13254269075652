import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './companyAccount.html?raw';
import style from './companyAccount.module.scss';

class CompanyAccount {
  static $inject = [
    '$state',
    '$timeout',
    '$window',
    '$translate',
    'UserSession',
    'CompanyService',
    'API',
    'MixpanelService',
    '$scope',
    '$cookies',
  ];

  constructor(
    $state,
    $timeout,
    $window,
    $translate,
    UserSession,
    CompanyService,
    API,
    MixpanelService,
    $scope,
    $cookies
  ) {
    this.$timeout = $timeout;
    this.$window = $window;
    this.$scope = $scope;
    this.$translate = $translate;
    this.style = style;
    this.UserSession = UserSession;
    this.CompanyService = CompanyService;
    this.API = API;
    this.MixpanelService = MixpanelService;
    this.loading = { form: false, upload: false };
    this.error = { form: null, upload: null };
    this.company = {
      id: this.UserSession.company.id,
      easyship_company_id: this.UserSession.company.easyship_company_id,
      logo: {
        asset_url: this.UserSession.company.logo.asset_url,
        asset_id: this.UserSession.company.logo.asset_id,
      },
      name: this.UserSession.company.name,
      display_name: this.UserSession.company.display_name,
      company_url: this.UserSession.company.company_url,
      cs_url: this.UserSession.company.cs_url,
      contact_email: this.UserSession.company.contact_email,
      phone_number: this.UserSession.company.phone_number,
      business_registration_number: this.UserSession.company.business_registration_number,
      eori_number: this.UserSession.company.eori_number,
      ioss: this.UserSession.company.ioss,
      sales_tax_number: this.UserSession.company.sales_tax_number,
      facebook_url: this.UserSession.company.facebook_url,
      twitter_url: this.UserSession.company.twitter_url,
      instagram_url: this.UserSession.company.instagram_url,
      youtube_url: this.UserSession.company.youtube_url,
    };
    this.business_registration_number_name =
      this.UserSession.user.shipping_country.finance.business_registration_number_name;
    this.sales_tax_number_name =
      this.UserSession.user.shipping_country.finance.sales_tax_number_name;
    this.iossMaxLength = 12;
    this.measurementSystems = [
      {
        name: 'imperial',
        dimensionsUnit: 'in',
        weightUnit: 'lb',
      },
      {
        name: 'metric',
        dimensionsUnit: 'cm',
        weightUnit: 'kg',
      },
    ].map((measurement) => ({
      ...measurement,
      name: this.$translate.instant(
        `account.company.measurement-system.options.${measurement.name}`
      ),
    }));
    this.selectedMeasurementSystem = null;
    this.reloadPage = false;

    this.toggleChurnReasonsDialog = this.toggleChurnReasonsDialog.bind(this);
    this.showingChurnReasonsDialog = false;
    this.$state = $state;
    this.$cookies = $cookies;
    this.logOutShowBanner = this.logOutShowBanner.bind(this);
  }

  $onInit() {
    this.uploadLogoUrl = this._setUploadLogoUrl();
    this.selectedMeasurementSystem =
      this.measurementSystems[
        this.measurementSystems.findIndex((measuringSystem) => {
          return measuringSystem.dimensionsUnit === this.UserSession.company.dimensions_unit;
        })
      ];

    if (this.isAccountOwner()) {
      this.accountOwnerEmail = this.UserSession.user.email;
    } else {
      this.CompanyService.getCompanyUsers().then((data) => {
        this.accountOwnerEmail = data.users.find((user) => user.role.id === 10).email;
      });
    }
  }

  _setUploadLogoUrl() {
    return `${this.API.endpoint}/companies/${this.company.id}/upload_asset?type=logo`;
  }

  onInputChange(value, key) {
    if (key === 'measurement_system') {
      this.selectedMeasurementSystem = value;
      this.company.dimensions_unit = value.dimensionsUnit;
      this.company.weight_unit = value.weightUnit;
      this.reloadPage = true;
      return;
    }

    this.company[key] = value;
  }

  onStartUploadLogo() {
    this.loading.upload = true;
  }

  onFinishUploadLogo(error, response) {
    if (error) {
      this.error.upload = error.data.status;
      return;
    }

    this.error.upload = null;
    this.company.logo.asset_url = response.data && response.data.asset_url; // sets the image preview
    this.company.logo.confirm_asset_id = response.data && response.data.asset_id; // prepares the post payload
    this.company.logo.delete_asset_id = this.company.logo.asset_id;
    this.loading.upload = false;
    this.MixpanelService.track('Account - Company - Upload Logo');
  }

  onDeleteLogo() {
    this.company.logo.asset_url = null;
    this.company.logo.confirm_asset_id = null; // avoid sending both confirm and delete
    this.company.logo.delete_asset_id = this.company.logo.asset_id; // prepares the post payload
    this.MixpanelService.track('Account - Company - Delete Logo');
  }

  onSubmit() {
    if (this.loading.upload) {
      toastError(this.$translate.instant('toast.upload-busy'));
      return;
    }

    if (this.companyForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading.form = true;

    this.CompanyService.updateCompany(this.company)
      .then((response) => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            {
              noun: this.$translate.instant('global.setting', { COUNT: 1 }).toLowerCase(),
            },
            'messageformat'
          )
        );
        this.error.form = null;
        this.company.logo.asset_id =
          response.company && response.company.logo && response.company.logo.asset_id;
        this.company.logo.confirm_asset_id = null;
        this.company.logo.delete_asset_id = null;
        this.MixpanelService.track('Account - Company - Save');

        if (this.reloadPage) {
          this.$timeout(() => {
            this.$window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        const defaultMessage = 'Your company details were not updated. Please try again.';

        this.error.form = (error.data && error.data.status) || defaultMessage;

        // Set company name field to error state. Use ngMaxLength as that will be validated when the user changes the input and will remove the error state
        if (error.data && error.data.status === 'Name has already been taken') {
          this.companyForm.$$controls
            .find((ngModelController) => ngModelController.$$attr.esId === 'company-name')
            .$setValidity('ngMaxLength', false);
        }
      })
      .finally(() => {
        this.loading.form = false;
      });
  }

  toggleChurnReasonsDialog() {
    this.showingChurnReasonsDialog = !this.showingChurnReasonsDialog;

    if (this.showingChurnReasonsDialog) {
      this.MixpanelService.track('Settings - Company - Close Account');
    } else {
      this.$scope.$apply();
      this.MixpanelService.track('Settings - Company - Close Account - Keep My Account');
    }
  }

  isAccountOwner() {
    return this.UserSession.isAccountOwner();
  }

  isUnderAccountClosingReview() {
    return this.UserSession.isUnderAccountClosingReview();
  }

  isCloudCompany() {
    return !this.UserSession.isCompanyEfulfilment();
  }

  async logOutShowBanner() {
    const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';

    await this.$cookies.remove(this.API.credentials, {
      secure: true,
      sameSite: 'none',
      partitioned: true,
      domain: cookieDomain,
      path: '/',
    });
    await setTimeout(() => {
      this.UserSession.redirectToLogin(true);
    }, 500);
  }
}

const CompanyAccountComponent = {
  controller: CompanyAccount,
  template,
};

export { CompanyAccountComponent };
