import { IComponentController } from 'angular';
import { IFormData } from 'typings/tracking-email';
import template from './tracking-email-preview-modal.html?raw';
import style from './tracking-email-preview-modal.module.scss';

class TrackingEmailPreviewModalController implements IComponentController {
  style = style;
  esOpen = false;
  esFormData = {} as IFormData;
  isUpdateVariation = false;

  selectUpdatePreview(): void {
    this.isUpdateVariation = true;
  }

  selectFirstTrackingPreview(): void {
    this.isUpdateVariation = false;
  }

  closeModal(): void {
    this.esOpen = false;
  }
}

const TrackingEmailPreviewModalComponent = {
  controller: TrackingEmailPreviewModalController,
  template,
  bindings: {
    esOpen: '=',
    esFormData: '=',
  },
};

export { TrackingEmailPreviewModalComponent };
