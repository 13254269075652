import angular from 'angular';

import { RequestFeatureService } from './request-feature.service';
import { RequestFeatureResource } from './request-feature.resource';

const MODULE_NAME = 'app.global.services.request-feature';

angular
  .module(MODULE_NAME, [])
  .service('RequestFeatureService', RequestFeatureService)
  .service('RequestFeatureResource', RequestFeatureResource);

export default MODULE_NAME;
