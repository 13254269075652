import { toastError } from '@client/core/components/react/Toastify';

/* eslint-disable no-param-reassign */
(function () {
  angular.module('easyshipDashboardApp').controller('SsoLuxotticaCtrl', SsoLuxotticaCtrl);

  SsoLuxotticaCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'AUTH_EVENTS',
    '$window',
    'Auth',
    'Luxottica',
    'MixpanelService',
  ];

  function SsoLuxotticaCtrl(
    $scope,
    $state,
    $stateParams,
    AUTH_EVENTS,
    $window,
    Auth,
    Luxottica,
    MixpanelService
  ) {
    const vm = this;

    vm.busy = false;

    (function init() {
      const sessionToken = Auth.getCredentials();

      if (sessionToken) {
        Auth.retrieveSession();
      } else {
        vm.busy = false;
      }

      if ($stateParams.error_message) {
        handleLoginFail($stateParams.error_message);
      } else if ($stateParams.token) {
        handleLoginSuccess($stateParams.token);
      }

      $scope.$on(AUTH_EVENTS.fullLoginSuccess, function () {
        $state.go('app.home');
      });
    })();

    vm.login = function () {
      vm.busy = true;

      MixpanelService.track('Luxottica Login Button Click');

      Luxottica.query()
        .$promise.then(function (response) {
          $window.location = response.redirect_url;
        })
        .catch(function (err) {
          toastError((err.data && err.data.error_message) || err);
        })
        .finally(function () {
          vm.busy = false;
        });
    };

    function handleLoginFail(errorMessage) {
      vm.errorMsg = errorMessage;
    }

    function handleLoginSuccess(token) {
      vm.busy = true;

      Auth.validateUserSsoCredentials(token, 'luxottica')
        .catch(function (err) {
          toastError((err.data && err.data.error_message) || err);
        })
        .finally(function () {
          vm.busy = false;
        });
    }
  }
})();
