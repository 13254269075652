(function () {
  'use strict';

  EbayService.$inject = [
    '$q',
    '$window',
    'PlatformApi',
    'UserSession',
    'HelperService',
    'StoreService',
  ];
  function EbayService($q, $window, PlatformApi, UserSession, HelperService, StoreService) {
    var service = this;

    service.getEbaySessionId = function (newStore) {
      return $q(function (resolve, reject) {
        PlatformApi.ebay.save(
          { company_id: UserSession.company.id },
          { platform_id: newStore.platform.id },
          function (response) {
            var session_id = response.body.session_id;
            var ruName = response.body.ru_name;
            var ebayUrl =
              'https://signin.ebay.com/ws/eBayISAPI.dll?SignIn&RUName=' +
              ruName +
              '&SessID=' +
              session_id +
              '&ruparams=vars=' +
              session_id +
              '|' +
              newStore.platform.id;

            StoreService.saveStoreValueToLocal(newStore);

            resolve(ebayUrl);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.getEbayToken = function (ebayGranted) {
      return $q(function (resolve, reject) {
        var ebayStore = angular.merge(
          {
            platform_id: ebayGranted.id,
            username: ebayGranted.username,
            session_id: ebayGranted.session_id,
          },
          StoreService.getStorePayloadFromLocalStorage()
        );

        StoreService.removeStoreValueFromLocal();

        PlatformApi.ebay.userToken(
          {
            company_id: UserSession.company.id,
          },
          ebayStore,
          function (response) {
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };
  }

  angular.module('app.service.EbayService', []).service('EbayService', EbayService);
})();
