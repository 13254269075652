import angular from 'angular';

import { ShipmentInfoOriginBreakdownComponent } from './origin-breakdown.component';

const MODULE_NAME = 'app.manage-shipments.shipment-information.claim-cta';

angular
  .module(MODULE_NAME, [])
  .component('esShipmentInfoOriginBreakdown', ShipmentInfoOriginBreakdownComponent);

export default MODULE_NAME ;
