(function () {
  'use strict';

  MetadataService.$inject = ['$rootScope'];
  function MetadataService($rootScope) {
    var service = this;

    service.meta = {};

    service.loadMetadata = function (metadata) {
      if (metadata) {
        service.meta.title = document.title = metadata.title || 'Easyship | Ship outside the box';
        service.meta.description =
          metadata.description ||
          'The easiest way to ship worldwide. Ship with more than 250+ couriers, saving up to 70%.';
        service.meta.ogImage1 = metadata.ogImage1 || 'https://www.easyship.com/share_image_1.png';
        service.meta.ogWidth = metadata.ogWidth || '1024';
        service.meta.ogHeight = metadata.ogHeight || '512';
        // service.meta.url = metadata.url || $location.absUrl();
        // service.meta.image = metadata.image || 'fallbackimage.jpg';
        // service.meta.ogpType = metadata.ogpType || 'website';
        // service.meta.twitterCard = metadata.twitterCard || 'summary_large_image';
        // service.meta.twitterSite = metadata.twitterSite || '@fallback_handle';
      }
    };

    service.setMetadata = function (metadata) {
      service.meta.title = document.title = metadata.title || 'Easyship | Ship outside the box';
      service.meta.description =
        metadata.description ||
        'The easiest way to ship worldwide. Ship with more than 250+ couriers, saving up to 70%.';
      service.meta.ogImage1 = metadata.ogImage1 || 'https://www.easyship.com/share_image_1.png';
      service.meta.ogWidth = metadata.ogWidth || '1024';
      service.meta.ogHeight = metadata.ogHeight || '512';
    };

    // Route change handler => Sets the metadata defined in the route
    // event, toState, toParams, fromState, fromParams
    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
      service.loadMetadata(toState.metadata);
    });
  }

  angular.module('app.service.MetadataService', []).service('MetadataService', MetadataService);
})();
