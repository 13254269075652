import angular from 'angular';
import { ICountry, ICountryCurrencyList, ICountryService } from 'typings/auth/services/country';
import { CountryData, SenderCountryData } from '@client/core/corelogic/models/Country';
import HKDistrict from '@client/core/corelogic/models/HKDistrict';
import { PostalCodeMatches } from '@client/core/corelogic/models/PostalCodeMatches';
import { IPostalCodeService } from 'typings/postal-code';
import { AddressEditData } from '@client/core/corelogic/models/Address';
import { ResidentialIdentification } from '@client/core/corelogic/models/Shipment';
import { Currency } from '@client/core/corelogic/models/Currency';
import { CheckResidentialService } from '@client/src/global/services/check-residential/check-residential.service';
import ICountryGateway from '../../corelogic/ports/country.interface';
import PostalCodeMatchesMapper from '../mappers/postalCodeMatches';

// Adapter implementation of the country gateway using angular service
export default class NgCountryGateway implements ICountryGateway {
  private countryService: ICountryService;
  private postalCodeService: IPostalCodeService;
  private checkResidentialService: CheckResidentialService;
  private countryPromise: ng.IPromise<{
    countries: ICountry[];
    currencies: ICountryCurrencyList[];
  }> | null = null;

  constructor() {
    this.countryService = angular.element(document.body).injector().get('CountryService');
    this.postalCodeService = angular
      .element(document.body)
      .injector()
      .get<IPostalCodeService>('PostalCodeService');
    this.checkResidentialService = angular
      .injector(['app.global.check-residential'])
      .get<CheckResidentialService>('CheckResidentialService');
  }

  getCountries(): Promise<ICountry[]> {
    return new Promise((resolve, reject) => {
      this.countryService
        .getCountries()
        .then(({ countries }) => resolve(countries))
        .catch((reason) => reject(reason));
    });
  }

  all(): Promise<CountryData[]> {
    this.countryPromise = this.countryService.getCountries();

    return new Promise((resolve, reject) => {
      this.countryService
        .getCountries()
        .then(({ countries }) =>
          resolve(
            countries.map((country) => {
              return {
                id: country.id,
                name: country.simplified_name,
                alpha2: country.alpha2,
                isStateRequired: country.requirements.requires_state,
                postalCodeValidation: {
                  isVisible: country.requirements.has_postal_code,
                  example: country.requirements.postal_code_examples || '',
                  regex: country.requirements.postal_code_regex || '',
                  mandatoryFromCountryIds:
                    country.requirements.postal_code_mandatory_from_origin || [],
                },
              };
            })
          )
        )
        .catch((reason) => reject(reason));
    });
  }

  getHKDistricts(): Promise<HKDistrict[]> {
    return new Promise((resolve, reject) => {
      this.countryService
        .getHkDistricts()
        .then((response) =>
          resolve(response.map((district) => ({ name: district.district, ...district })))
        )
        .catch((reason) => reject(reason));
    });
  }

  getPostalCodeMatches(countryId: number, postalCode: string): Promise<PostalCodeMatches> {
    return new Promise((resolve, reject) => {
      this.postalCodeService
        .getState({ country_id: countryId, postal_code: postalCode })
        .then((response) => {
          resolve(new PostalCodeMatchesMapper().fromAPI(response));
        })
        .catch((reason) => reject(reason));
    });
  }

  getResidentialIdentification(address: AddressEditData): Promise<ResidentialIdentification> {
    return new Promise((resolve, reject) => {
      this.checkResidentialService
        .getAddressResidentialStatus({
          address: {
            address_line_1: address.addressLine1,
            address_line_2: address.addressLine2,
            city: address.city,
            destination_country_id: address.countryId,
            postal_code: address.postalCode,
            state: address.state,
          },
        })
        .then((response) => resolve(this.mapToResidentialIdentification(response)))
        .catch((reason) => reject(reason));
    });
  }

  getAllCurrencies(): Promise<Currency[]> {
    return new Promise(async (resolve) => {
      if (this.countryPromise) {
        await this.countryPromise;
      }
      resolve(
        this.countryService.currencies.map((currency) => ({
          code: currency.currency_code,
        }))
      );
    });
  }

  getSenderCountries(): Promise<SenderCountryData[]> {
    return new Promise((resolve, reject) => {
      this.countryService
        .getShippingSupportedCountries()
        .then(({ countries }) =>
          resolve(
            countries.map((country) => ({
              id: country.id,
              name: country.simplified_name,
              hasPreNegotiatedCourierRates: country.hasPreNegotiatedCourierRates,
            }))
          )
        )
        .catch((reason) => reject(reason));
    });
  }

  getValidReceiverCountries(): Promise<CountryData[]> {
    return Promise.all([this.all(), this.countryService.getShippingPublicCountries()]).then(
      ([countries, shippingCountries]) =>
        countries.filter((country) => {
          const shippingCountry = shippingCountries.countries.find(
            (shippingCountry) => shippingCountry.id === country.id
          );
          return shippingCountry?.valid_shipment_destination;
        })
    );
  }

  private mapToResidentialIdentification(
    identifiedAsResidential: boolean | null
  ): ResidentialIdentification {
    switch (identifiedAsResidential) {
      case true:
        return 'positive';
      case false:
        return 'negative';
      default:
        return 'unknown';
    }
  }
}
