import { SIGNUP_EMAIL_REGEX } from '../data/regex';

export class Email {
  email: string;
  constructor(email: string) {
    this.email = email;
  }

  get isValid(): boolean {
    const emailRegex = SIGNUP_EMAIL_REGEX;
    const trimmedEmail = this.email.trim();
    return (
      emailRegex.test(this.email) && !(trimmedEmail.substring(trimmedEmail.length - 3) === 'con')
    );
  }
}
