import { IApiConfig } from 'typings/core/config/api';
import { IBigCommerceLoadResponse } from 'typings/auth/services/bigcommerce-auth';

class BigCommerceResource {
  static $inject = ['$resource', 'API'];
  constructor(public $resource: ng.resource.IResourceService, public API: IApiConfig) {}

  load(signed_payload: string): ng.IPromise<IBigCommerceLoadResponse> {
    const resource = this.$resource(`${this.API.endpoint}/big_commerce/registrations/load`, {});

    return resource.save({}, { signed_payload }).$promise;
  }
}

export { BigCommerceResource };
