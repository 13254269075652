import { IComponentController } from 'angular';
import template from './sidebar-input-group.html?raw';
import style from './sidebar-input-group.module.scss';

class SidebarInputGroup implements IComponentController {
  style = style;
  esModelRadio: string | null = null;
  esModelInput: string | null = null;

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  onRadioChange(value: string): void {
    this.esModelRadio = value;
    this.onValuesChange();
  }

  onInputChange = (value: string): void => {
    this.esModelInput = value;
    this.onValuesChange();
  };

  private onValuesChange(): void {
    this.esOnChange({
      value: {
        input: this.esModelInput,
        radio: this.esModelRadio,
      },
    });
  }
}

const SidebarInputGroupComponent: ng.IComponentOptions = {
  controller: SidebarInputGroup,
  template,
  bindings: {
    esLabel: '<',
    esOptionsRadio: '<',
    esModelRadio: '<',
    esModelInput: '<',
    esOnChange: '&',
    esIsHideInput: '<',
  },
  transclude: true,
};

export { SidebarInputGroupComponent };
