import template from './handover-options-list.html?raw';

(function () {
  'use strict';

  // courierHandoverOptions is a string with the following possible values: 'dropoff, free_pickup, paid_pickup'
  var handoverOptionsList = {
    template,
    bindings: {
      courierHandoverOptions: '<',
    },
    controller: 'handoverOptionsListCtrl as ctrl',
  };

  angular
    .module('app.component.handover-options-list', [])
    .component('handoverOptionsList', handoverOptionsList);
})();
