import React, { createContext, useContext, useMemo } from 'react';
import { PlatformPort } from '../ports/platform';
import NgPlatformGateway from '../gateways/api/NgPlatformGateway';
import FakePlatformGateway from '../gateways/fake/FakePlatformGateway';

export interface PlatformGatewayContextData {
  platformGateway: PlatformPort;
}

const PlatformGatewayContext = createContext<PlatformGatewayContextData | undefined>(undefined);

interface PlatformGatewayProviderProps {
  children: React.ReactNode;
}

function PlatformGatewayProvider({ children }: PlatformGatewayProviderProps) {
  const data: PlatformGatewayContextData = useMemo(
    () => ({
      platformGateway: new NgPlatformGateway(),
    }),
    []
  );

  return <PlatformGatewayContext.Provider value={data}>{children}</PlatformGatewayContext.Provider>;
}

interface FakePlatformGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<PlatformGatewayContextData>;
}

function FakePlatformGatewayProvider({ children, value }: FakePlatformGatewayProviderProps) {
  const data: PlatformGatewayContextData = useMemo(
    () => ({
      platformGateway: new FakePlatformGateway(),
      ...value,
    }),
    [value]
  );

  return <PlatformGatewayContext.Provider value={data}>{children}</PlatformGatewayContext.Provider>;
}

function usePlatformGateway() {
  const platformGatewayContext = useContext(PlatformGatewayContext);

  if (platformGatewayContext === undefined) {
    throw new Error('usePlatformGateway must be used within a PlatformGatewayProvider');
  }
  return platformGatewayContext;
}

export { PlatformGatewayProvider, FakePlatformGatewayProvider, usePlatformGateway };
