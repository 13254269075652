import React from 'react';
import ClickAwayListener, { ClickAwayListenerProps } from '@material-ui/core/ClickAwayListener';
import MuiPopper, { PopperProps as MuiPopperProps } from '@material-ui/core/Popper';
import Fade, { FadeProps } from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

export interface PopperProps extends MuiPopperProps {
  onClickAway?: ClickAwayListenerProps['onClickAway'];
  noPadding?: boolean;
  children: FadeProps['children'];
}

export default function Popper({
  open,
  anchorEl,
  onClickAway,
  noPadding,
  children,
  ...props
}: PopperProps): JSX.Element {
  function handleClickAway(e: React.MouseEvent<Document, MouseEvent>) {
    onClickAway && onClickAway(e);
  }

  return (
    <>
      {/* condition needed otherwise onClickAway gets triggered instantly on open */}
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <MuiPopper open={open} anchorEl={anchorEl} {...props} transition>
            {({ TransitionProps }) => (
              <Box
                p={noPadding ? 0 : 3}
                boxShadow="0 2px 15px rgba(40,78,102,.1)"
                clone
              >
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>{children}</Paper>
                </Fade>
              </Box>
            )}
          </MuiPopper>
        </ClickAwayListener>
      )}
    </>
  );
}
