import { react2angular } from 'react2angular';

import MarkAsDeliveredDialog, { WrappedMarkAsDeliveredDialog } from './MarkAsDeliveredDialog';

export default MarkAsDeliveredDialog;
export const AngularMarkAsDeliveredDialog = react2angular(WrappedMarkAsDeliveredDialog, [
  'isOpen',
  'onClose',
  'onConfirm',
]);
