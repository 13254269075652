import {
  IAutoRechargeData,
  IAutoRechargeResponse,
  IUpdateAutoRechargePayload,
} from 'typings/auto-recharge';
import { AutoRechargeResource } from './auto-recharge.resource';

class AutoRechargeService {
  private _autoRechargeData: IAutoRechargeData | null = null;

  static $inject = ['AutoRechargeResource'];
  constructor(private AutoRechargeResource: AutoRechargeResource) {}

  get autoRechargeData(): IAutoRechargeData | null {
    return this._autoRechargeData;
  }

  retrieveAutoRechargeData(): ng.IPromise<IAutoRechargeResponse> {
    return this.AutoRechargeResource.retrieve()
      .then((data: IAutoRechargeResponse) => {
        if (data) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { enabled, threshold_amount, amount, amount_limit } = data.auto_recharge;

          this._autoRechargeData = {
            isEnabled: enabled,
            threshold: threshold_amount,
            amount,
            limit: {
              min: amount_limit.min,
              max: amount_limit.max,
            },
          };
        }

        return data;
      })
      .catch((error: unknown) => {
        throw error;
      });
  }

  updateAutoRechargeSettings(payload: IUpdateAutoRechargePayload): ng.IPromise<void> {
    return this.AutoRechargeResource.update(payload)
      .then((data: IUpdateAutoRechargePayload) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { enabled, threshold_amount, amount } = data.auto_recharge;

        if (this._autoRechargeData) {
          this._autoRechargeData.isEnabled = enabled;
          this._autoRechargeData.threshold = threshold_amount;
          this._autoRechargeData.amount = amount;
        }
      })
      .catch((error: unknown) => {
        throw error;
      });
  }
}

export { AutoRechargeService };
