export interface HsCode {
  description: string;
  name: string;
}

export type SuggestedHsCodes = Record<string, string>;

/**
 * Add dot(.) after the 4th and 6th number eg: 1234.56.78
 * Naming reference: https://www.bansarchina.com/what-is-hs-code
 */
export const formatHsCodeNumber = (hsCodeNumber: string) => {
  const chapterAndHeading = hsCodeNumber.slice(0, 4);
  const subHeading = hsCodeNumber.slice(4, 6);
  const tariffItem = hsCodeNumber.slice(6);
  let formattedHsCodeNumber = chapterAndHeading;

  if (subHeading) {
    formattedHsCodeNumber += `.${subHeading}`;
  }

  if (tariffItem) {
    formattedHsCodeNumber += `.${tariffItem}`;
  }

  return formattedHsCodeNumber;
};

/**
 * Move the selected HS Code on top of the list first
 * then sort the other HS Code in ascending order
 */
export const sortSuggestedHsCodes = (
  suggestedHsCodes: SuggestedHsCodes,
  selectedHsCodeNumber: string | null
) => {
  return Object.entries(suggestedHsCodes).sort(([hsCodeNumber1], [hsCodeNumber2]) =>
    hsCodeNumber1 === selectedHsCodeNumber ||
    (hsCodeNumber1 < hsCodeNumber2 && hsCodeNumber2 !== selectedHsCodeNumber)
      ? -1
      : 1
  );
};
