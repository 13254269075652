import angular from 'angular';

import { PickupHandlersResource } from './pickup-handlers.resource';
import { PickupHandlersService } from './pickup-handlers.service';

const MODULE_NAME = 'app.global.services.pickup-handlers';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', 'ngResource'])
  .service('PickupHandlersResource', PickupHandlersResource)
  .service('PickupHandlersService', PickupHandlersService);

export default MODULE_NAME;
