import { ICompanyService } from 'typings/company';

import { IComponentController } from 'angular';
import style from './insurance-example-modal.module.scss';
import template from './insurance-example-modal.html?raw';

class StoreSettingsInsuranceExampleModalController implements IComponentController {
  style = style;
  trackingNumberEnabled = false;
  showingFeature = 'snippet';

  static $inject = ['CompanyService'];
  constructor(private CompanyService: ICompanyService) {
    this.CompanyService.getTrackingEmailBrandingData().then((res) => {
      this.trackingNumberEnabled = res.send_receiver_tracking;
    });
  }

  show(feature: string): void {
    this.showingFeature = feature;
  }
}

const StoreSettingsInsuranceExampleModalComponent: ng.IComponentOptions = {
  controller: StoreSettingsInsuranceExampleModalController,
  template,
  bindings: {
    esShow: '<',
    esClose: '&',
  },
};

export { StoreSettingsInsuranceExampleModalComponent };
