import { IEmailPreviewService } from 'typings/email';
import { IUserService } from 'typings/user-service';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { NotificationCentreService } from '@client/src/global/services/notification-centre/notification-centre.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './notifications.module.scss';
import template from './notifications.html?raw';

enum SettingsKey {
  ShipmentIncomplete = 'shipment_incomplete',
  LabelPurchased = 'label_purchased',
  LabelGenerated = 'label_generated',
  LabelFailed = 'label_failed',
  PickupConfirmed = 'pickup_confirmed',
  PickupFailed = 'pickup_failed',
  ShipmentRefunded = 'shipment_refunded',
  EasyshipTips = 'easyship_tip',
  OrderSync = 'order_sync',
}

interface INotificationSetting {
  key: SettingsKey;
  email: boolean;
  platform: boolean;
  hideSetting?: boolean;
  hideEmailSetting?: boolean;
  hideEmailPreview?: boolean;
}

class Notifications implements IComponentController {
  style = style;
  settings: INotificationSetting[] = [];
  translations: Record<string, string> = {};

  static $inject = [
    '$translate',
    'NotificationCentreService',
    'EmailPreviewService',
    'UserService',
    'UserSession',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private NotificationCentreService: NotificationCentreService,
    private EmailPreviewService: IEmailPreviewService,
    private UserService: IUserService,
    private UserSession: IUserSession
  ) {}

  $onInit(): void {
    this.$translate(['global.setting']).then((translations) => {
      this.translations = translations;
    });

    this.settings = [
      {
        key: SettingsKey.ShipmentIncomplete,
        email: false,
        platform: false,
      },
      {
        key: SettingsKey.LabelPurchased,
        email: false,
        platform: false,
      },
      {
        key: SettingsKey.LabelGenerated,
        email: false,
        platform: false,
      },
      {
        key: SettingsKey.LabelFailed,
        email: false,
        platform: false,
      },
      {
        key: SettingsKey.PickupConfirmed,
        email: false,
        platform: false,
      },
      {
        key: SettingsKey.PickupFailed,
        email: false,
        platform: false,
        hideSetting: this.UserSession.isCompanyEfulfilment(),
      },
      {
        key: SettingsKey.ShipmentRefunded,
        email: false,
        platform: false,
      },
      {
        key: SettingsKey.EasyshipTips,
        email: false,
        platform: false,
        hideEmailPreview: true,
        hideEmailSetting: true,
      },
      {
        key: SettingsKey.OrderSync,
        email: false,
        platform: false,
        hideEmailPreview: true,
        hideEmailSetting: true,
      },
    ];

    this.getNotificationSettings();
  }

  clearHistory(): void {
    this.NotificationCentreService.deleteNotifications().then(() => {
      this.NotificationCentreService.resetUnreadCount();
      toastSuccess(this.$translate.instant('settings.notifications.clear-success'));
    });
  }

  onEmailCheck(value: boolean, key: SettingsKey): void {
    this.updateNotificationUser({ [`${key}_email`]: value });
  }

  onPlatformCheck(value: boolean, key: SettingsKey): void {
    this.updateNotificationUser({ [`${key}_app`]: value });
  }

  preview(key: SettingsKey): void {
    this.EmailPreviewService.open(key);
  }

  getNotificationSettings(): void {
    this.UserService.getNotificationSettings(this.UserSession.getUserId()).then((data) => {
      this.assignUserSetting(data);
    });
  }

  get isNotificationAvailable(): boolean {
    return this.NotificationCentreService.isAvailable;
  }

  private updateNotificationUser(payload: { [key: string]: boolean }): void {
    this.UserService.update({
      notification_settings: payload,
    })
      .then((data) => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations['global.setting'].toLowerCase() },
            'messageformat'
          )
        );
        this.UserSession.update('user', data.user);
      })
      .catch((error: { status: string }) => {
        const details = typeof error?.status === 'string' ? error?.status : '';
        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.translations['global.setting'].toLowerCase(),
            details,
          })
        );
      });
  }

  private assignUserSetting(notificationsSettings: any): void {
    const {
      shipment_incomplete_email,
      shipment_incomplete_app,
      label_purchased_email,
      label_purchased_app,
      label_generated_email,
      label_generated_app,
      label_failed_email,
      label_failed_app,
      pickup_confirmed_email,
      pickup_confirmed_app,
      pickup_failed_email,
      pickup_failed_app,
      shipment_refunded_email,
      shipment_refunded_app,
      easyship_tip_app,
      order_sync_app,
    } = notificationsSettings;
    const shipmentIndex = this.findSettingIndex(SettingsKey.ShipmentIncomplete);
    this.settings[shipmentIndex].email = shipment_incomplete_email;
    this.settings[shipmentIndex].platform = shipment_incomplete_app;

    const labelPurchasedIndex = this.findSettingIndex(SettingsKey.LabelPurchased);
    this.settings[labelPurchasedIndex].email = label_purchased_email;
    this.settings[labelPurchasedIndex].platform = label_purchased_app;

    const labelGeneratedIndex = this.findSettingIndex(SettingsKey.LabelGenerated);
    this.settings[labelGeneratedIndex].email = label_generated_email;
    this.settings[labelGeneratedIndex].platform = label_generated_app;

    const labelFailedIndex = this.findSettingIndex(SettingsKey.LabelFailed);
    this.settings[labelFailedIndex].email = label_failed_email;
    this.settings[labelFailedIndex].platform = label_failed_app;

    const pickupConfirmedIndex = this.findSettingIndex(SettingsKey.PickupConfirmed);
    this.settings[pickupConfirmedIndex].email = pickup_confirmed_email;
    this.settings[pickupConfirmedIndex].platform = pickup_confirmed_app;

    const pickupFailedIndex = this.findSettingIndex(SettingsKey.PickupFailed);
    this.settings[pickupFailedIndex].email = pickup_failed_email;
    this.settings[pickupFailedIndex].platform = pickup_failed_app;

    const shipmentRefundedIndex = this.findSettingIndex(SettingsKey.ShipmentRefunded);
    this.settings[shipmentRefundedIndex].email = shipment_refunded_email;
    this.settings[shipmentRefundedIndex].platform = shipment_refunded_app;

    const easyshipTipsIndex = this.findSettingIndex(SettingsKey.EasyshipTips);
    this.settings[easyshipTipsIndex].platform = easyship_tip_app;

    const orderSyncIndex = this.findSettingIndex(SettingsKey.OrderSync);
    this.settings[orderSyncIndex].platform = order_sync_app;
  }

  private findSettingIndex(value: SettingsKey): number {
    return this.settings.findIndex((setting) => setting.key === value);
  }
}

const NotificationsComponent: ng.IComponentOptions = {
  controller: Notifications,
  template,
  bindings: {},
};

export { NotificationsComponent };
