import PlatformLogo from '@client/core/components/react/PlatformLogo';
import React, { memo, useEffect, useRef, useState } from 'react';
import { IShipmentListItem, IShipmentListLogsPriceCalculationItem } from 'typings/shipment';
import AlertIcon from '@client/src/create-shipments/shipment-details-drawer/ShipmentDetailsDrawerToolbar/Tabs/components/TabLabelTitle/AlertIcon';
import dayjs from '@client/core/plugins/dayjs';
import TruncateText, {
  notifyListener,
} from '@client/core/components/react/TruncateText/TruncateText';
import { useShipmentReceiverAlert } from '@client/src/manage-shipments/hooks/useShipmentAlert';
import CellLayout from './CellLayout';
import { CellProps } from './d';
import { useVisibleColumns } from '../ShipmentsTableColumnSettings/useVisibleColumns';

const ORDER_NUMBER_MIN_WIDTH = 94;

const isPackageError = (shipment: IShipmentListItem) => {
  const error = shipment.logs.price_calculation as IShipmentListLogsPriceCalculationItem;
  const { parcels = [] } = error || {};
  return Object.values(parcels).some((parcel) => !!parcel.total_actual_weight);
};

const isPackageWarning = (shipment: IShipmentListItem) =>
  !!shipment.display_missing_dimensions_warning;

const isItemError = (shipment: IShipmentListItem) => {
  const priceCalculation = shipment.logs.price_calculation as IShipmentListLogsPriceCalculationItem;
  const shipmentItems = priceCalculation.shipment_items;

  return (
    (!!shipmentItems && !!Object.values(shipmentItems).find((s) => !!s.declared_customs_value)) ||
    shipment.shipment_items_count < 0 ||
    (Number(shipment.total_customs_value) || 0) < 0
  );
};

const isCourierError = (shipment: IShipmentListItem) => !shipment?.courier?.logo_url;

export const OrderCell = memo(({ row }: CellProps) => {
  const shipment = row.original;
  const storeName = shipment.store.name;
  const platFormSlug = shipment.platform_name_slug ?? '';

  const orderNumber = shipment.platform_order_number || shipment.easyship_shipment_id;
  const createdAt = dayjs(shipment.order_created_at ?? shipment.created_at).format('DD MMM LT');

  const receiverAlert = useShipmentReceiverAlert(shipment);
  const { visibleColumns } = useVisibleColumns();

  const hasError =
    receiverAlert.hasError ||
    isPackageError(shipment) ||
    isItemError(shipment) ||
    isCourierError(shipment);
  const hasWarning = hasError ? false : receiverAlert.hasWarning || isPackageWarning(shipment);

  const cellLayoutRef = useRef<HTMLDivElement | null>(null);
  const [orderMaxWidth, setOrderMaxWidth] = useState(ORDER_NUMBER_MIN_WIDTH);

  const computeOrderNumberMaxWidth = () => {
    const ORDER_NUMBER_WIDTH_OFFSET = 58;
    const cellLayoutWidth = cellLayoutRef.current?.clientWidth || 0;

    if (cellLayoutWidth - ORDER_NUMBER_WIDTH_OFFSET <= ORDER_NUMBER_MIN_WIDTH) {
      setOrderMaxWidth(ORDER_NUMBER_MIN_WIDTH);
    } else {
      setOrderMaxWidth(cellLayoutWidth - ORDER_NUMBER_WIDTH_OFFSET);
    }
  };

  useEffect(() => {
    computeOrderNumberMaxWidth();
    notifyListener(); // to re-execute Tooltip displaying logic
  }, [visibleColumns]);

  return (
    <CellLayout
      className="flex px-2 min-w-[152px] gap-2 items-center bg-white group-hover:bg-teal-100 hover:!bg-[#d2f1f2] order-cell"
      ref={cellLayoutRef}
    >
      <PlatformLogo
        platformNameSlug={platFormSlug}
        size="sm"
        className="min-h-[16px] object-cover"
      />
      <div className="flex flex-col items-start justify-center gap-1">
        {storeName && (
          <div className="text-ink-500 text-[12px] font-normal leading-[18px]">{storeName}</div>
        )}
        <div className="flex items-center gap-1 max-w-full">
          <TruncateText
            label={orderNumber}
            className="direction-rtl text-ink-900"
            maxWidth={orderMaxWidth}
          />
          {(hasWarning || hasError) && <AlertIcon severity={hasError ? 'error' : 'warning'} />}
        </div>
        <div className="font-normal leading-5 text-ink-900 sm">{createdAt}</div>
      </div>
    </CellLayout>
  );
});
