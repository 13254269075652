import template from './shipment-id-cell.html?raw';
import style from './shipment-id-cell.module.scss';

class ShipmentIdCell {
  constructor() {
    this.style = style;
  }
}

const ShipmentIdCellComponent = {
  controller: ShipmentIdCell,
  template,
  bindings: {
    easyId: '<',
  },
};

export { ShipmentIdCellComponent };
