import template from './walkthrough-modal.html?raw';

(function () {
  'use strict';

  walkthroughModal.$inject = ['$uibModal'];
  function walkthroughModal($uibModal) {
    var service = this;

    service.open = function (params) {
      var params = params || {};

      var modalInstance = $uibModal.open({
        template,
        controller: 'walkthroughModalCtrl as vm',
        openedClass: 'easyship-modal-no-padding',
        backdrop: true,
        size: 'lg',
        resolve: {
          params: function () {
            return params;
          },
        },
      });
    };
  }

  angular.module('app.service.walkthrough-modal').service('walkthroughModal', walkthroughModal);
})();
