export type DialogType = 'close-account' | 'downgrade-plan' | 'inactive-account';
export type ChurnReason =
  | 'not_many_orders'
  | 'rates_not_competitive'
  | 'encountered_issue'
  | 'platform_not_as_expected'
  | 'no_value'
  | 'other'
  | 'need_for_short_period'
  | 'too_expensive';


export type ChurnIssue =
  | 'hard_setup_usage'
  | 'courier_no_show'
  | 'delivered_late'
  | 'lost_damaged'
  | 'technical_issue'
  | 'bad_experience_support'
  | 'other_issue';

export interface ChurnReasonsDialogProps {
  open: boolean;
  onClose: () => void;
  logOut: () => void;
  dialogOrigin: DialogType;
  downgradeAction: () => void;
  $injector: angular.auto.IInjectorService;
}

export enum ChurnReasonsDialogSteps {
  preface = 'close-account-preface',
  reasons = 'reasons',
  issues = 'issues',
  tellUsMore = 'tell-us-more',
  confirmation = 'close-account-confirmation',
  underReview = 'close-account-under-review',
}
