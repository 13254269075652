import template from './address-forms-container.html?raw';

(function () {
  'use strict';

  var addressFormsContainer = {
    template,
    bindings: {
      address: '=',
      addressValid: '=?',
      addressyInfo: '=',
      showUsBackupForm: '=',
      countryId: '<',
      showContactInfo: '<',
      showNickname: '<',
      postalCodeBusy: '=?',
      allowForeignAddress: '<',
      hideCountrySelector: '<',
      formType: '<',
      companyNameMaxLength: '<',
      fieldValidators: '=?',
    },
    controller: 'addressFormsContainerCtrl as ctrl',
  };

  angular
    .module('app.component.address-forms-container')
    .component('addressFormsContainer', addressFormsContainer);
})();
