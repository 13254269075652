import {
  StyleRules,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';
import {
  AlertClassKey,
  AutocompleteClassKey,
  ToggleButtonClassKey,
  ToggleButtonGroupClassKey,
} from '@material-ui/lab';
import { SkeletonClassKey } from '@material-ui/lab/Skeleton';
import COLORS from './colors';

const BOX_SHADOW = '0 4px 4px 0 rgba(40, 78, 102, 0.20)' as const;
const PAPER_STYLE = {
  boxShadow: '0 10px 20px 0 rgba(40, 78, 102, 0.15)',
} as const;

// The variables here should be the same as client/app/styles/variables.scss
export const FONT_SIZE = {
  tiny: 10,
  smallest: 13,
  smaller: 16,
  small: 20,
  normal: 25,
  large: 30,
  larger: 35,
  largest: 40,
  huge: 50,
} as const;

// Inspired from https://github.com/mui-org/material-ui/issues/12164#issuecomment-564041219
interface LabComponentsOptions {
  MuiAlert?: Partial<StyleRules<AlertClassKey>>;
  MuiSkeleton?: Partial<StyleRules<SkeletonClassKey>>;
  MuiToggleButtonGroup?: Partial<StyleRules<ToggleButtonGroupClassKey>>;
  MuiToggleButton?: Partial<StyleRules<ToggleButtonClassKey>>;
  MuiAutocomplete?: Partial<StyleRules<AutocompleteClassKey>>;
}

const labComponentsOverride: LabComponentsOptions = {
  MuiAutocomplete: {
    paper: {
      fontFamily: 'inherit',
    },
  },
  MuiAlert: {
    root: {
      padding: 15,
      borderRadius: 4,
    },
    standardSuccess: {
      backgroundColor: COLORS.tealLight,
    },
    standardWarning: {
      backgroundColor: COLORS.yellowLight,
    },
    standardError: {
      backgroundColor: COLORS.redLight,
    },
    icon: {
      padding: '0 0 0 3px',
      fontSize: 14,
      marginRight: 10,
    },
    message: {
      color: COLORS.inkDarkest,
      fontSize: 13,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      lineHeight: '20px',
    },
  },
  MuiSkeleton: {
    root: {
      opacity: 0.5,
    },
    text: {
      height: 20,
    },
  },
  MuiToggleButtonGroup: {
    root: {
      background: COLORS.white,
      width: '100%',
      padding: 8,
      borderRadius: 20,
    },
  },
  MuiToggleButton: {
    root: {
      borderRadius: '100px !important',
      borderStyle: 'none',
      height: 36,
      textTransform: 'none',
      color: `${COLORS.inkDarkest} !important`,
      '&.Mui-selected': {
        background:
          'transparent linear-gradient(270deg, #D2F1F2 0%, #A5E4E6 100%) 0% 0% no-repeat padding-box',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
};

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    utils: {
      fz: typeof FONT_SIZE;
    };
  }
  interface ThemeOptions {
    utils?: {
      fz: typeof FONT_SIZE;
    };
  }
}

export const theme = createMuiTheme({
  props: {
    MuiSelect: {
      variant: 'outlined',
    },
    MuiTooltip: {
      placement: 'top',
    },
    MuiLink: {
      underline: 'none',
    },
  },

  typography: {
    fontFamily: '"Karla-Regular", Helvetica, Arial, sans-serif',
    h1: {
      fontSize: 20,
      lineHeight: '32px',
      marginBottom: '0.5em',
      fontFamily: 'Karla-Bold',
    },
    h2: {
      fontSize: 20,
      lineHeight: '32px',
      marginBottom: '0.5em',
    },
    h3: {
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: 'Karla-Bold',
    },
    h4: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 13,
      lineHeight: '17px',
      fontFamily: 'Karla-Bold',
    },
    body2: {
      fontSize: 13,
      lineHeight: '17px',
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: '17px',
    },
    subtitle2: {
      fontSize: 11,
      lineHeight: '16px',
    },
    caption: {
      fontSize: 10,
      lineHeight: '14px',
    },
    button: {
      fontSize: 13,
      lineHeight: '18px',
      fontFamily: 'Karla-Bold',
    },
  },

  palette: {
    background: { default: COLORS.skyNormal },
    primary: {
      main: COLORS.greenNormal,
      dark: COLORS.greenDark,
      light: COLORS.greenLight,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.blueNormal,
      dark: COLORS.blueDark,
      light: COLORS.blueLight,
      contrastText: COLORS.white,
    },
    success: {
      main: COLORS.greenNormal,
      dark: COLORS.greenDark,
      light: COLORS.greenLight,
    },
    error: {
      main: COLORS.redNormal,
      dark: COLORS.redDark,
      light: COLORS.redLight,
    },

    teal: {
      main: COLORS.tealNormal,
      dark: COLORS.tealDark,
      light: COLORS.tealLight,
      contrastText: COLORS.white,
    },
    blue: {
      main: COLORS.blueNormal,
      dark: COLORS.blueDark,
      light: COLORS.blueLight,
    },
    green: {
      main: COLORS.greenNormal,
      dark: COLORS.greenDark,
      light: COLORS.greenLight,
    },
    yellow: {
      main: COLORS.yellowNormal,
      dark: COLORS.yellowDark,
      light: COLORS.yellowLight,
    },
    red: {
      main: COLORS.redNormal,
      dark: COLORS.redDark,
      light: COLORS.redLight,
    },
    ink: {
      main: COLORS.inkNormal,
      dark: COLORS.inkDark,
      light: COLORS.inkLight,
    },
    sky: {
      main: COLORS.skyNormal,
      dark: COLORS.skyDark,
      light: COLORS.skyLight,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  overrides: {
    ...labComponentsOverride,

    MuiTypography: {
      colorTextPrimary: {
        color: COLORS.inkDarkest,
      },
      colorTextSecondary: {
        color: COLORS.inkNormal,
      },
      gutterBottom: {
        marginBottom: 10,
      },
    },

    MuiAccordionDetails: {
      root: {
        display: 'block',
        fontSize: 13,
        lineHeight: '17px',
      },
    },

    MuiLinearProgress: {
      root: { backgroundColor: COLORS.tealLight },
      bar: { backgroundColor: COLORS.tealNormal },
    },

    MuiCard: {
      root: { boxShadow: '0px 2px 15px rgba(40, 78, 102, 0.1)' },
    },

    MuiCardContent: {
      root: {
        padding: 20,

        '&:last-child': {
          paddingBottom: 20,
        },
      },
    },

    MuiButtonGroup: {
      root: {
        boxShadow: '0px 2px 4px rgba(40, 78, 102, 0.2)',
      },
      grouped: {
        boxShadow: 'initial',
        background: `linear-gradient(white, ${COLORS.skyNormal})`,
        padding: 0,
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: COLORS.inkLight,
        fontSize: 18,
        '&:hover': {
          background: COLORS.white,
        },
      },
      groupedTextHorizontal: {
        '&:not(:last-child)': {
          borderRight: `1px solid ${COLORS.skyDark}`,
        },
      },
    },

    MuiTableRow: {
      root: {
        '&:first-child .MuiTableCell-body': {
          paddingTop: 12,
        },
        '&:last-child .MuiTableCell-body': {
          paddingBottom: 12,
        },
      },
      hover: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: `${COLORS.skyLight} !important`,
        },
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: 0,
        lineHeight: '17px',
      },
      sizeSmall: {
        paddingTop: 3,
        paddingBottom: 3,
      },
      head: {
        color: COLORS.inkNormal,
        lineHeight: '17px',

        '&.MuiTableCell-sizeSmall': {
          paddingTop: 6,
          paddingBottom: 6,
        },
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(33, 43, 54, 0.50)',
      },
    },

    MuiGrid: {
      root: {
        lineHeight: 1,
      },
    },

    MuiChip: {
      root: {
        height: 'auto',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '3px 0',
        borderRadius: 4,
        lineHeight: 1.1,
        fontSize: 12,
        fontFamily: 'Karla-Bold',
        color: COLORS.white,
      },
      label: {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },

    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'initial',
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        color: COLORS.inkDark,
        borderColor: COLORS.skyDark,
        borderWidth: 1,
        borderStyle: 'solid',
        width: '100%',
        backgroundColor: COLORS.white,

        '&.Mui-focused': {
          borderColor: COLORS.inkLight,
        },

        '&.Mui-disabled': {
          color: COLORS.inkDark,
          backgroundColor: COLORS.skyLight,
        },

        '&.Mui-error': {
          borderColor: COLORS.redDark,
          backgroundColor: COLORS.redLight,
        },
      },

      multiline: {
        padding: 0,
      },

      input: {
        padding: 10,
        minHeight: 18,
      },

      inputMultiline: {
        padding: 10,
      },

      notchedOutline: {
        borderWidth: '0 !important',
      },
    },

    MuiInputAdornment: {
      root: {
        backgroundColor: 'inherit',
      },
    },

    MuiInputLabel: {
      shrink: {
        transform: 'initial',
      },
    },

    MuiFormControlLabel: {
      root: {
        margin: `0 !important`,
        '& .MuiInputBase-root': {
          margin: 0,
        },
        '& .MuiCheckbox-root + .MuiFormControlLabel-label': {
          marginBottom: 0,
        },
      },
      labelPlacementTop: {
        display: 'flex',
        alignItems: 'initial',
        '& .MuiFormControlLabel-label': {
          marginBottom: 4,
        },
      },
      labelPlacementStart: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& .MuiFormControlLabel-label': {
          marginRight: 4,
        },
      },
      label: {
        fontFamily: 'inherit !important',
        color: `${COLORS.inkNormal} !important`,
      },
    },

    MuiFormControl: {
      root: {
        width: '100%',
      },
    },

    MuiFormLabel: {
      root: {
        color: COLORS.inkNormal,
        fontFamily: 'inherit',
        lineHeight: '17px',
        marginBottom: 4,
        '&$focused': {
          color: COLORS.inkNormal,
        },
        '&$error': {
          color: COLORS.redDark,
        },
      },
    },

    MuiInputBase: {
      root: {
        fontFamily: 'inherit',
        // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
        '& input[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
      multiline: {
        lineHeight: '20px',
      },
    },

    MuiRadio: {
      root: {
        fontSize: 16,
        fontFamily: 'inherit',
        '& .MuiIconButton-label': {
          width: 16,
          height: 16,
        },
      },
      colorSecondary: {
        color: `${COLORS.inkLight} !important`,
      },
    },

    MuiCheckbox: {
      root: {
        fontSize: 16,
        fontFamily: 'inherit',
        '& .MuiIconButton-label': {
          width: 16,
          height: 16,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: COLORS.skyDarkest,
          borderRadius: 4,
        },
        '&.Mui-checked .MuiIconButton-label': {
          borderColor: COLORS.inkLight,
        },
        '&.Mui-disabled .MuiIconButton-label': {
          backgroundColor: COLORS.skyNormal,
        },
      },
    },

    MuiSvgIcon: {
      root: {
        flexShrink: 1,
      },
      colorError: {
        color: COLORS.redDark,
      },
    },

    MuiMenuItem: {
      root: {
        fontFamily: 'inherit',
        paddingLeft: 12,
        paddingRight: 12,
      },
    },

    MuiButton: {
      root: {
        borderRadius: 4,
        fontFamily: 'Karla-Bold',
        transition: 'none',
      },
      contained: {
        height: 35,
        backgroundImage: `linear-gradient(-180deg, ${COLORS.white} 0%, ${COLORS.skyNormal} 100%)`,
        color: COLORS.inkNormal,
        boxShadow: '0px 2px 4px rgba(40, 78, 102, 0.2)',

        '&.Mui-disabled': {
          backgroundImage: 'none',
          backgroundColor: COLORS.skyDark,
          color: COLORS.white,
        },

        '&:hover': {
          backgroundImage: 'none',
          backgroundColor: COLORS.white,
          boxShadow: '0px 2px 4px rgba(40, 78, 102, 0.2)',
        },

        '&:active': {
          backgroundImage: 'none !important',
          boxShadow: BOX_SHADOW,
          backgroundColor: COLORS.skyDark,
        },
      },
      containedPrimary: {
        backgroundImage: `linear-gradient(-180deg, ${COLORS.greenNormal} 0%, ${COLORS.greenDark} 100%)`,
        color: COLORS.white,

        '&:hover': { backgroundColor: COLORS.greenNormal },
        '&:active': { backgroundColor: COLORS.greenDark },
      },
      containedSecondary: {
        backgroundImage: `linear-gradient(-180deg, ${COLORS.blueNormal} 0%, ${COLORS.blueDark} 100%)`,
        color: COLORS.white,

        '&:hover': { backgroundColor: COLORS.blueNormal },
        '&:active': { backgroundColor: COLORS.blueDark },
      },
      // containedError: {
      //   backgroundImage: `linear-gradient(-180deg, ${COLORS.redNormal} 0%, ${COLORS.redDark} 100%) !important`,
      //   color: COLORS.white,

      //   '&:hover': { backgroundColor: COLORS.redNormal },
      //   '&:active': { backgroundColor: COLORS.redDark },
      // },
      text: {
        padding: '8px 0',
        color: COLORS.inkNormal,
        boxShadow: 'none',

        '&:hover': {
          backgroundImage: 'none',
          backgroundColor: 'transparent',
          color: COLORS.inkFaded,
        },

        '&$disabled': {
          backgroundColor: COLORS.skyDark,
          backgroundImage: 'none',
          color: COLORS.white,
          boxShadow: 'none',
        },

        '&[aria-expanded]:hover': {
          backgroundColor: COLORS.skyLight,
          color: 'inherit',
        },
      },
      sizeSmall: {
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.5)',
        },
      },
      sizeLarge: {
        borderRadius: 8,
      },
    },

    MuiList: {
      root: {
        maxHeight: '100%',
        overflowY: 'auto',
        outline: 'none',

        // Non-selectable items; Only clickable, like an OS menu
        '&[role=menu]': {
          '& [class*="MuiMenuItem-root"]': {
            padding: '10px',
            whiteSpace: 'normal',
            color: COLORS.inkDarkest,

            '&:hover': {
              backgroundColor: COLORS.tealNormal,
              color: `${COLORS.white}`,

              '&.Mui-disabled': {
                backgroundColor: 'inherit',
                color: 'inherit',
              },

              '& [class^="icon-"]': {
                color: `${COLORS.white} !important`,
              },
            },

            '&.Mui-disabled': {
              backgroundColor: 'inherit',
              color: `${COLORS.skyDarkest} !important`,
              opacity: '1 !important',

              '& [class^="icon-"]': {
                color: `${COLORS.skyDarkest} !important`,
              },
            },

            '&.warningMenuItem': {
              color: `${COLORS.redDark}`,

              '&:hover': {
                backgroundColor: COLORS.redLight,
                color: `${COLORS.redDark} !important`,

                '& [class^="icon-"]': {
                  color: `${COLORS.redDark} !important`,
                },
              },
            },
          },
        },

        // Selectable items
        '&[role=listbox]': {
          '& [class*="MuiMenuItem-root"]': {
            padding: '10px',
            whiteSpace: 'normal',
            color: COLORS.inkDarkest,

            '&:hover': {
              backgroundColor: COLORS.skyLight,
              color: COLORS.inkDarkest,
            },
          },

          '&.Mui-disabled': {
            backgroundColor: 'inherit',
            color: `${COLORS.skyDarkest} !important`,

            '&:hover': {
              backgroundColor: 'inherit',
            },
          },

          '& .Mui-selected': {
            backgroundColor: COLORS.tealNormal,
            color: COLORS.white,

            '&:hover': {
              backgroundColor: `${COLORS.tealNormal} !important`,
            },
          },
        },
      },
    },

    MuiListItem: {
      gutters: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      divider: {
        borderBottom: `1px solid ${COLORS.skyNormal}`,
      },
    },

    MuiListItemText: {
      primary: {
        color: COLORS.inkNormal,
      },
      secondary: {
        color: COLORS.inkDarkest,
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: COLORS.skyDark,
      },
      light: {
        backgroundColor: COLORS.skyNormal,
      },
    },

    MuiTooltip: {
      tooltip: {
        boxShadow: '0px 10px 20px rgba(40, 78, 102, 0.15)',
        color: COLORS.inkNormal,
        fontSize: 13,
        lineHeight: '17px',
        backgroundColor: 'white',
        padding: '20px',
        maxWidth: 'inherit',

        '& a': {
          color: COLORS.blueNormal,
        },
      },
      arrow: { color: 'white' },
    },

    MuiSwitch: {
      root: {
        display: 'flex',
        padding: 0,
        height: 'initial',
        width: 'initial',
        overflow: 'initial',

        '&$disabled': {
          cursor: 'default',
        },

        '& $disabled + .MuiSwitch-track': {
          boxShadow: 'none',
          border: `1px solid ${COLORS.skyDarkest}`,
          backgroundColor: `${COLORS.skyLight} !important`,
        },
      },
      switchBase: {
        padding: 2,
        '&$checked': {
          transform: 'translateX(20px)',
          '& $thumb': { backgroundColor: COLORS.tealNormal },
        },
      },
      thumb: {
        width: 14,
        height: 14,
        margin: 1,
        backgroundColor: COLORS.skyDarkest,
        boxShadow: 'none',
      },
      track: {
        width: 40,
        height: 20,
        boxShadow: '0px 2px 15px rgba(40, 78, 102, 0.1)',
        borderRadius: 10,
        opacity: `1 !important`,
        backgroundColor: `${COLORS.white} !important`,
      },
    },

    MuiTabs: {
      indicator: {
        backgroundColor: COLORS.tealNormal,
      },
    },

    MuiTab: {
      root: {
        fontFamily: 'Karla-Bold',
        minWidth: 'initial !important',
        '&$selected': {
          color: COLORS.tealDark,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },

    MuiPaper: {
      elevation1: PAPER_STYLE,
      elevation2: PAPER_STYLE,
      elevation3: PAPER_STYLE,
      elevation4: PAPER_STYLE,
      elevation5: PAPER_STYLE,
      elevation6: PAPER_STYLE,
      elevation7: PAPER_STYLE,
      elevation8: PAPER_STYLE,
      elevation9: PAPER_STYLE,
      elevation10: PAPER_STYLE,
      elevation11: PAPER_STYLE,
      elevation12: PAPER_STYLE,
      elevation13: PAPER_STYLE,
      elevation14: PAPER_STYLE,
      elevation15: PAPER_STYLE,
      elevation16: PAPER_STYLE,
      elevation17: PAPER_STYLE,
      elevation18: PAPER_STYLE,
      elevation19: PAPER_STYLE,
      elevation20: PAPER_STYLE,
      elevation21: PAPER_STYLE,
      elevation22: PAPER_STYLE,
      elevation23: PAPER_STYLE,
      elevation24: PAPER_STYLE,
      rounded: {
        borderRadius: 8,
      },
    },

    MuiPopover: {
      paper: {
        borderRadius: 4,
      },
    },

    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },

    MuiStepIcon: {
      root: {
        color: COLORS.skyDarkest,
        '&.MuiStepIcon-active': { color: COLORS.tealNormal },
        '&.MuiStepIcon-completed': { color: COLORS.skyDarkest },
      },
    },

    MuiStepConnector: {
      line: {
        borderColor: COLORS.skyDarkest,
      },
    },

    MuiDialog: {
      paperWidthSm: {
        maxWidth: '520px',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: '15px 20px 15px 40px',
        borderBottom: `1px solid ${COLORS.skyNormal}`,
      },
    },

    MuiDialogContent: {
      root: {
        padding: '25px 40px',
      },
      dividers: {
        padding: '25px 40px',
        borderTop: `1px solid ${COLORS.skyNormal}`,
        borderBottom: `1px solid ${COLORS.skyNormal}`,
      },
    },

    MuiDialogActions: {
      root: {
        padding: '20px 40px',
        borderTop: `1px solid ${COLORS.skyNormal}`,
        justifyContent: 'flex-start',
      },
    },

    MuiSnackbarContent: {
      root: {
        backgroundColor: COLORS.inkDark,
        opacity: '0.85 !important',
        padding: '10px 20px',
      },
      message: {
        padding: 0,
        lineHeight: '20px',
      },
    },

    MuiLink: {
      root: {
        color: COLORS.blueNormal,
        '&:hover': { color: COLORS.blueDark },
      },
    },

    MuiSlider: {
      root: {
        height: 4,
        color: COLORS.tealNormal,
        padding: '8px 0',
      },
      rail: {
        backgroundColor: COLORS.skyDark,
        opacity: 1,
        height: 4,
        borderRadius: 2,
      },
      track: {
        backgroundColor: COLORS.tealNormal,
        height: 4,
        borderRadius: 2,
      },
      thumb: {
        backgroundImage: `linear-gradient(135deg, #96E5C8 0%, ${COLORS.blueDark} 100%)`,
        boxShadow: '0px 2px 15px #284E6633',
        border: `2px solid ${COLORS.white}`,
        width: 20,
        height: 20,
        marginTop: -9,
        '&:hover': {
          boxShadow: '0px 0px 0px 7px #2B909333',
        },
        '&.Mui-focusVisible': {
          boxShadow: '0px 0px 0px 7px #2B909333',
        },
      },
    },
  },

  utils: {
    fz: FONT_SIZE,
  },
});
