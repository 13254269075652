import { IShowDeleteRefundCreditsModal, IRefundDetails } from 'typings/refund-credits';
import { ICompanyService } from 'typings/company';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './refund-credits-flow.module.scss';
import template from './refund-credits-flow.html?raw';

class RefundCreditsFlow implements IComponentController {
  style = style;
  isLoading = false;
  esShowModal: IShowDeleteRefundCreditsModal = {
    confirmRefund: false,
    refundStatus: false,
    supportTeam: false,
  };
  refundDetails: IRefundDetails | null = null;

  static $inject = [
    '$state',
    '$translate',
    'CompanyService',
    'MixpanelService',
    'UserRightsService',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $translate: angular.translate.ITranslateService,
    private CompanyService: ICompanyService,
    private MixpanelService: MixpanelService,
    private UserRightsService: UserRightsService
  ) {}

  closeConfirmRefundModal(): void {
    this.esShowModal.confirmRefund = false;
    this.MixpanelService.track('Refund - Payment Methods - Click Back');
  }

  closeRefundStatusModal(): void {
    this.esShowModal.refundStatus = false;
    this.MixpanelService.track('Refund - Payment Methods - Click Got it (success)');
  }

  closeSupportTeamContactModal(): void {
    this.esShowModal.supportTeam = false;
    this.MixpanelService.track('Refund - Payment Methods - Click Got it (No transfer)');
  }

  confirmRefund(): void {
    this.isLoading = true;
    this.MixpanelService.track('Refund - Payment Methods - Click Proceed');

    this.CompanyService.refundCredits()
      .then((res) => {
        this.refundDetails = res;
        this.esShowModal.confirmRefund = false;

        if (this.refundDetails.total_non_refunded > 0 && !this.refundDetails.total_refunded) {
          this.esShowModal.supportTeam = true;
        } else {
          this.esShowModal.refundStatus = true;
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('account.payment.notifications.refund-error'));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  redirectToBillingsPage(): void {
    this.closeRefundStatusModal();
    this.$state.go('app.account.billing');
    this.MixpanelService.track('Refund - Payment Methods - Click See Receipts');
  }

  get canRefundCredit(): boolean {
    return this.UserRightsService.canRefundCredit;
  }
}

const RefundCreditsFlowComponent: ng.IComponentOptions = {
  controller: RefundCreditsFlow,
  template,
  bindings: {
    esShowModal: '<',
    esRefundDetails: '<',
  },
};

export { RefundCreditsFlowComponent };
