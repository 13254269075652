import { IUserSession } from 'typings/user-session';
import { PickupHandlers } from 'typings/pickup-handlers';
import { PickupHandlersResource } from './pickup-handlers.resource';

class PickupHandlersService {
  static $inject = ['UserSession', 'PickupHandlersResource'];
  constructor(
    private UserSession: IUserSession,
    private PickupHandlersResource: PickupHandlersResource
  ) {}

  get(): ng.IPromise<PickupHandlers.Handler[]> {
    const companyId = this.UserSession.getCompanyId() as string;

    return this.PickupHandlersResource.get({
      company_id: companyId,
      origin_country_ids: [this.UserSession.company.country_id],
    }).then(({ handlers }) => {
      return handlers;
    });
  }
}

export { PickupHandlersService };
