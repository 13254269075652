import { IComponentController } from 'angular';
import { PaymentType } from '@client/data/payment';
import { IUserSession, IFeaturesPayment } from 'typings/user-session';
import template from './choose-payment-method-modal-card.html?raw';
import style from './choose-payment-method-modal-card.module.scss';

class ChoosePaymentMethodModalCard implements IComponentController {
  style = style;
  paymentType = PaymentType;
  paymentSettings: IFeaturesPayment | null = null;

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  $onInit() {
    this.paymentSettings = this.UserSession.getCompanyPaymentSettings();
  }
}

const ChoosePaymentMethodModalCardComponent: ng.IComponentOptions = {
  controller: ChoosePaymentMethodModalCard,
  template,
  bindings: {
    esCloseAction: '&',
    esChoosePaymentMethod: '&',
  },
};

export { ChoosePaymentMethodModalCardComponent };
