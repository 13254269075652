import {
  IPickupsEditPickupInfo,
  IPickupsPageBusy,
  IPickupsPickups,
  IPickupsSupportInfo,
} from 'typings/pickup';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { IUserSession } from '@typings/user-session';
import template from './pickups.html?raw';
import style from './pickups.module.scss';
import { PickupsPageService } from './pickups-page.service';

enum PickupModal {
  Edit = 'edit',
  Cancel = 'cancel',
  ContactSupport = 'contact-support',
}

class PickupsController implements IComponentController {
  style = style;
  selectedPickup: IPickupsSupportInfo | IPickupsEditPickupInfo | null = null;
  selectedPickupId: string | null = null;
  selectedPickupErrorMessages: string[] = [];
  activeModal: PickupModal | null = null;
  isManuallyScheduledPickupEnabled = false;
  showScheduleButton = true;

  static $inject = [
    '$state',
    '$stateParams',
    '$timeout',
    'MixpanelService',
    'PickupsPageService',
    'UserSession',
  ];

  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $timeout: ng.ITimeoutService,
    private MixpanelService: MixpanelService,
    private PickupsPageService: PickupsPageService,
    private UserSession: IUserSession
  ) {
    this.isManuallyScheduledPickupEnabled = this.UserSession.getFeatureFlagsByFlagName(
      'smb_manually_scheduled_pickups'
    );
  }

  $onInit(): void {
    // Assign the filter service state to param values
    if (this.$stateParams.returnBackFromReschedulePickupPage) {
      const { currentPage, selectedState } = this.PickupsPageService;

      const queryParams = {
        page: 1,
        group: '',
      };

      if (currentPage) {
        queryParams.page = currentPage;
      }

      if (selectedState) {
        queryParams.group = selectedState;
      }

      this.$state.go(this.$state.current.name as ng.ui.IState, queryParams);
    }
    // Assign the filter query param values to service state on pageload
    else {
      const { page, group } = this.$stateParams;

      if (page) {
        this.PickupsPageService.currentPage = Number(page);
      }

      if (group) {
        this.PickupsPageService.selectedState = group;
      } else {
        this.PickupsPageService.selectedState = null;
      }
    }

    this.PickupsPageService.getPickups();
    this.PickupsPageService.updateTotals();

    this.MixpanelService.track(`Pickups - Visit page`);
  }

  get busy(): IPickupsPageBusy {
    return this.PickupsPageService.busy;
  }

  get pickups(): IPickupsPickups[] {
    return this.PickupsPageService.pickups;
  }

  get isFiltered(): boolean {
    return this.PickupsPageService.isFiltered;
  }

  get isEditModalActive(): boolean {
    return this.activeModal === PickupModal.Edit;
  }

  get containerClass() {
    if (this.isManuallyScheduledPickupEnabled && !this.pickups?.length)
      return this.style.containerEmptyPage;
    return this.style.container;
  }

  get isCancelModalActive(): boolean {
    return this.activeModal === PickupModal.Cancel;
  }

  get isContactSupportModalActive(): boolean {
    return this.activeModal === PickupModal.ContactSupport;
  }

  showEditModal(pickup: IPickupsEditPickupInfo): void {
    this.activeModal = PickupModal.Edit;
    this.selectedPickup = pickup;
  }

  showCancelModal(pickupId: string): void {
    this.activeModal = PickupModal.Cancel;
    this.selectedPickupId = pickupId;
  }

  showContactSupportModal(pickup: IPickupsSupportInfo): void {
    this.activeModal = PickupModal.ContactSupport;
    this.selectedPickup = pickup;
  }

  hideModal(): void {
    this.activeModal = null;
  }
}

const PickupsComponent = {
  controller: PickupsController,
  template,
};

export { PickupsComponent };
