(function () {
  'use strict';

  PaymentSource.$inject = ['$http', '$resource', 'API'];
  function PaymentSource($http, $resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/companies/:company_id/payment_sources/:id/:controller',
      {
        company_id: '@_company_id',
        id: '@_id',
      },
      {
        query: {
          method: 'GET',
          params: {
            controller: '',
          },
        },
        deactivate: {
          method: 'PATCH',
          params: {
            controller: 'deactivate',
          },
        },
        update: {
          method: 'PATCH',
        },
        setDefault: {
          method: 'PATCH',
          params: {
            controller: 'set_default',
          },
        },
      }
    );
  }

  angular.module('app.factory.PaymentSource', []).factory('PaymentSource', PaymentSource);
})();
