import angular from 'angular';

import '@client/core/components/utilities/progress';
import '@client/src/auth/pages/index';
import { SignupLayoutComponent } from './signup.component';

const MODULE_NAME = 'auth.global.layout.signup';

angular
  .module(MODULE_NAME, ['core.components.progress', 'app.auth.pages.auth-pages'])
  .component('signupLayout', SignupLayoutComponent);

export default MODULE_NAME;
