import template from './account.html?raw';

('use strict');

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  '$urlRouterProvider',
  ($stateProvider, $urlRouterProvider) => {
    $stateProvider.state('app.account', {
      abstract: true,
      url: '^/account',
      template,
      controller: 'AccountCtrl',
    });

    $urlRouterProvider.when('/account', '/account/company');
  },
]);
