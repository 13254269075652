import angular from 'angular';
import uibModal from 'angular-ui-bootstrap/src/modal';

import { SyncOrdersModalMainComponent } from './sync-order-modal-main/sync-orders-modal-main.component';
import { AddRemoveStatusesComponent } from './add-remove-statuses/add-remove-statuses.component';
import { AddRemoveCountriesComponent } from './add-remove-countries/add-remove-countries.component';
import { AddRemoveTagsComponent } from './add-remove-tags/add-remove-tags.component';
import { SyncOrdersModalService } from './sync-orders-modal/sync-orders-modal.service';

angular
  .module('app.connnect.store.sync-orders', [
    uibModal,
    'core.service.mixpanel',
    'app.service.StoreService',
    'app.factory.UserSession',
  ])
  .component('syncOrdersModalMain', SyncOrdersModalMainComponent)
  .component('esAddRemoveStatuses', AddRemoveStatusesComponent)
  .component('addRemoveCountries', AddRemoveCountriesComponent)
  .component('esAddRemoveTags', AddRemoveTagsComponent)
  .service('SyncOrdersModalService', SyncOrdersModalService);
