import webhookIconUrl from '@assets/images/sidebar/icons/webhook.svg';
import { toastError } from '@client/core/components/react/Toastify';
import template from './webhook-connect.html?raw';
import style from './webhook-connect.module.scss';

class WebhookConnect {
  webhookIconUrl = webhookIconUrl;

  static $inject = [
    'Webhooks',
    'WebhookModal',
    'HelperService',
    'MixpanelService',
    'API',
    '$translate',
  ];

  constructor(Webhooks, WebhookModal, HelperService, MixpanelService, API, $translate) {
    this.style = style;
    this.Webhooks = Webhooks;
    this.WebhookModal = WebhookModal;
    this.HelperService = HelperService;
    this.MixpanelService = MixpanelService;
    this.API = API;
    this.$translate = $translate;

    this.loading = false;
    this.availableEvents = [...Webhooks.availableEvents];
    this.webhookPayload = { event_types: {} };
    this.wrappers = {
      a: (text) => `<a ng-click="$ctrl.onRedirectSupportPage()">${text}</a>`,
    };
  }

  onRedirectSupportPage() {
    const supportUrl = `${this.API.help}/hc/en-us/articles/360039354771-Create-and-Edit-Webhooks`;

    window.open(supportUrl, '_blank');
    this.MixpanelService.track('Webhooks - Add New - Click Support');
  }

  onEndpointChange(value) {
    this.webhookPayload.endpoint = value;
  }

  onEventChange(value, event) {
    this.webhookPayload.event_types[event] = value;
  }

  onCancelClick() {
    this.WebhookModal.close();
    this.MixpanelService.track('Webhooks - Add New - Cancel');
  }

  onSubmitForm() {
    if (this.connectWebhookForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    if (!this._hasOneEventTypeBeenSelected()) {
      toastError(this.$translate.instant('webhook.notifications.none-selected'));
      return;
    }

    this.loading = true;

    this.Webhooks.createWebhook(this.webhookPayload)
      .then((response) => {
        this.onCreate({ webhook: response });
        this.MixpanelService.track('Webhooks - Add New - Create - Success', {
          event_types: this.webhookPayload.event_types,
        });
      })
      .catch((err) => {
        toastError(err.data?.error);
        this.MixpanelService.track('Webhooks - Add New - Create - Fail', {
          reason: err.data?.error ?? '',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // Checks the form for input with an id that starts with 'event'. Returns true on the first checkbox that has been checked
  _hasOneEventTypeBeenSelected() {
    const formControllers = this.connectWebhookForm.$$controls;
    for (let i = 0; i < formControllers.length; i++) {
      if (
        formControllers[i].$$attr.esId &&
        formControllers[i].$$attr.esId.startsWith('event') &&
        formControllers[i].$modelValue === true
      )
        return true;
    }

    return false;
  }
}

const WebhookConnectComponent = {
  template,
  controller: WebhookConnect,
  bindings: {
    onCreate: '&',
  },
};

export { WebhookConnectComponent };
