import angular, { IComponentController } from 'angular';

import { StringOptionsValue } from '@client/src/global/services/shipment-list/data/string-input';
import template from './item-description-input-group.html?raw';
import style from './item-description-input-group.module.scss';

interface SelectOption {
  value: StringOptionsValue;
  label: string;
}

class ItemDescriptionInputGroup implements IComponentController {
  style = style;
  esModelSelect: StringOptionsValue | null = null;
  esModelInput: string | null = null;
  esOnChange(value: any): void {}
  options: SelectOption[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    this.options = [
      StringOptionsValue.Contains,
      StringOptionsValue.NotContain,
      StringOptionsValue.OnlyContains,
    ].map((option) => ({
      value: option,
      label: this.$translate.instant(
        `shipments.filter.item-description.select-options.value.${option}`
      ),
    }));

    if (!this.esModelInput) {
      this.esModelInput = '';
    }

    if (!this.esModelSelect) {
      this.esModelSelect = StringOptionsValue.Contains;
    }
  }

  onSelectChange(value: StringOptionsValue): void {
    this.esModelSelect = value;
    this.onValuesChange();
  }

  onInputChange(value: string): void {
    this.esModelInput = value;
    this.onValuesChange();
  }

  private onValuesChange(): void {
    this.esOnChange({
      value: {
        select: this.esModelSelect,
        input: this.esModelInput,
      },
    });
  }
}

const ItemDescriptionInputGroupComponent: ng.IComponentOptions = {
  controller: ItemDescriptionInputGroup,
  template,
  bindings: {
    esModelSelect: '<',
    esModelInput: '<',
    esOnChange: '&',
  },
};

export { ItemDescriptionInputGroupComponent };
