/* eslint-disable no-param-reassign */
import platformLogo from '@client/src/global/dashboard-component-library/platform-logo';
import translateTags from '@client/core/directives//translate-tags';
import authGlobal from '@client/auth/global';
import { toast } from 'react-toastify';
import verificationComponents from '@client/src/verification/components';
import {
  initShield,
  getShieldSessionId,
} from '@client/src/initializers/auth-full-login-success/shield-loader/shield-loader';
import templateBlankComponent from './components/TemplateBlankComponent/index';
import { addDefaultBarCodeListener } from '../../app/utils/barCodeReaderShortcutsHandler';

angular
  .module('easyshipDashboardApp', [
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngAnimate',
    'oc.lazyLoad',
    'core.config',
    'core.filters',
    translateTags,
    platformLogo,
    authGlobal,
    verificationComponents,
    templateBlankComponent,
    'ui.router',
    'ui.router.state.events',
    'ui.bootstrap.module.accordion',
    'ui.bootstrap.module.collapse',
    'ui.bootstrap.module.datepicker',
    'ui.bootstrap.module.datepickerPopup',
    'ui.bootstrap.module.modal',
    'ui.bootstrap.module.progressbar',
    'ui.bootstrap.module.tooltip',
    'ngFileUpload',
    'dndLists',
    'ngclipboard',
    'dynamicNumber',
    'vcRecaptcha',
    'daterangepicker',
    'angular-bind-html-compile',
    'app.account',
    'app.services',
    'app.factories',
    'app.components',
    'app.create-shipments',
    'app.global',
    'app.initializers',
    'app.tools',
    'app.manage-shipments',
    'app.redirection',
    'app.log-in-as',
    'app.couriers',
    'app.reschedule-pickup',
    'app.global.order-summary',
    'app.webhooks',
    'app.connect',
    'app.analytics',
    'app.product-listing',
    'app.pickups',
    'pascalprecht.translate',
    'angular-inview',
    'app.help-guide',
    'app.data-collect-flows',
    'app.onboarding.page',
    'app.old-connect',
    'app.automation-components',
  ])
  .factory('asyncLoader', function () {
    return async function (options) {
      const res = await import(`../../assets/i18n/${options.key}.json`);
      return res.default;
    };
  })
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    '$httpProvider',
    '$cookiesProvider',
    '$animateProvider',
    '$compileProvider',
    'API',
    '$qProvider',
    '$translateProvider',
    '$translateSanitizationProvider',
    (
      $stateProvider,
      $urlRouterProvider,
      $locationProvider,
      $httpProvider,
      $cookiesProvider,
      $animateProvider,
      $compileProvider,
      API,
      $qProvider,
      $translateProvider,
      $translateSanitizationProvider
    ) => {
      const PARTNER_DOMAIN_LIST = ['ship.parcelcast.com'];

      // Remove debugging for production
      // To re-enable debugging on production, run `angular.reloadWithDebugInfo();` from the console
      if (API.environment === 'production') {
        $compileProvider.debugInfoEnabled(false);
        $qProvider.errorOnUnhandledRejections(false);
        window.addEventListener('unhandledrejection', (event) => {
          // Prevent the default handling (such as outputting the
          // error to the console)
          event.preventDefault();
        });
      }

      // Prevents cookies from being stored in different paths. Cookies stored in different paths are not accessible from the same page (e.g $cookies.getAll() only exposes the cookies stored in that path).
      // Reference: https://docs.angularjs.org/api/ngCookies/provider/$cookiesProvider
      $cookiesProvider.defaults.path = '/';

      let cookieDomain = API.domain;

      if (PARTNER_DOMAIN_LIST.indexOf(window.location.host) > -1) {
        cookieDomain = window.location.host;
      }

      $cookiesProvider.defaults.domain = cookieDomain;

      if (API.environment === 'development') {
        $cookiesProvider.defaults.samesite = 'lax';
      } else {
        $cookiesProvider.defaults.samesite = 'none';
        $cookiesProvider.defaults.secure = true;
      }

      initShield();

      function hideLogo() {
        const el = document.getElementById('app__initial-load');
        if (el) el.parentNode.removeChild(el);
        const metaViewport = document.querySelector('meta[name="viewport"]');
        if (metaViewport) {
          metaViewport.setAttribute('content', 'width=1440');
        }
      }

      $stateProvider
        .state('home', {
          url: '/',
          template: '<ui-view/>',
          redirectTo(trans) {
            const Auth = trans.$injector.get('Auth');
            return Auth.isLoggedIn() ? 'app.home' : 'home.auth.login';
          },
        })
        .state('app', {
          template:
            '<force-user-reload></force-user-reload>' +
            '<shipment-upload-progress></shipment-upload-progress>' +
            '<dashboard-sidebar></dashboard-sidebar>' +
            '<es-subscription-modal-controller></es-subscription-modal-controller>' +
            '<es-welcome-legacy-plan-modal></es-welcome-legacy-plan-modal>' +
            '<es-welcome-enterprise-plan-modal></es-welcome-enterprise-plan-modal>' +
            '<es-adjustments-modal></es-adjustments-modal>' +
            '<es-trial-expiry-banner></es-trial-expiry-banner>' +
            '<es-verification-required-banner></es-verification-required-banner>' +
            '<es-banner-modal-container></es-banner-modal-container>' +
            '<es-impersonation-banner></es-impersonation-banner>' +
            '<es-contact-admin-modal></es-contact-admin-modal>' +
            '<es-modals></es-modals>' +
            '<es-template-blank-component></es-template-blank-component>' +
            '<ui-view></ui-view>' +
            '<es-change-plan></es-change-plan>' +
            '<es-modals-react></es-modals-react>',
          resolve: {
            session: [
              '$q',
              '$state',
              'Auth',
              'UserSession',
              ($q, $state, Auth, UserSession) => {
                return $q(function (resolve) {
                  if (UserSession.company) {
                    hideLogo();
                    resolve();
                  } else {
                    Auth.retrieveSession()
                      .then(function (response) {
                        hideLogo();
                        resolve(response);
                      })
                      .catch(function () {
                        hideLogo();
                        UserSession.redirectToLogin();
                      });
                  }
                });
              },
            ],
          },
        });

      $urlRouterProvider
        .when('/multiple', '/advanced')
        .when('/single', '/basic/add-shipment')
        .when('/basic', '/basic/add-shipment')
        .when('/tools/packing-slip', '/tools/packing')
        .otherwise(function ($injector) {
          const Auth = $injector.get('Auth');
          return Auth.isLoggedIn() ? '/dashboard' : '/login';
        });

      // https://docs.angularjs.org/api/ng/provider/$locationProvider
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
        rewriteLinks: false,
      });

      $httpProvider.interceptors.push('httpInterceptor');

      // Manually restrict where $animate is applied for performance purposes
      $animateProvider.classNameFilter(
        /easyship-animated|easyship-smooth-show|easyship-smooth-show-slow|easyship-smooth-hide|easyship-smooth-reload|easyship-transition|rotate180|easyship-notification|easyship-slider/
      );

      $translateProvider.useLoader('asyncLoader');

      $translateProvider.preferredLanguage('en');
      $translateProvider.fallbackLanguage('en');

      $translateSanitizationProvider.addStrategy('customSanitizer', function (value, mode) {
        if (mode === 'params') {
          if (!value) return value;
          if (typeof value === 'string') {
            return value.replace(/{|}/g, '');
          }
          Object.keys(value).forEach(function (key) {
            const v = value[key];
            if (typeof v === 'string') {
              value[key] = v.replace(/{|}/g, '');
            }
          });
        }
        return value;
      });

      // $translateProvider.useMessageFormatInterpolation(); // to use messageformat by default
      $translateProvider.addInterpolation('$translateMessageFormatInterpolation'); // to use messageformat only when specified
      $translateProvider.useSanitizeValueStrategy(['sanitizeParameters', 'customSanitizer']);
    },
  ])
  .factory('httpInterceptor', [
    '$rootScope',
    '$q',
    '$location',
    '$cookies',
    '$window',
    '$state',
    'UserSession',
    'API',
    'AUTH_EVENTS',
    'AppBuild',
    (
      $rootScope,
      $q,
      $location,
      $cookies,
      $window,
      $state,
      UserSession,
      API,
      AUTH_EVENTS,
      AppBuild
    ) => {
      return {
        request(config) {
          // Do not inject header authorization if URL request is not ES API
          if (!config.url.includes(API.baseEndpoint)) {
            return config;
          }

          // Include Shield session ID in the header for all ES API requests
          const shieldSessionId = getShieldSessionId();

          if (shieldSessionId) {
            config.headers['X-Shield-Session-Id'] = shieldSessionId;
          }

          if (config.url.includes('login')) {
            return config;
          }

          // Include Easyship client name and version in the header for ES API requests except login
          config.headers['X-Easyship-Client-Name'] = 'Dashboard';
          config.headers['X-Easyship-Client-Version'] = AppBuild.buildVersion;

          // Check and get data from browser memory first as it is faster than getting data from cookie
          const credentials = UserSession.session_token || $cookies.get(API.credentials);

          // ES API authorization
          if (credentials) {
            config.headers.Authorization = `Bearer ${credentials}`;
          }

          // Tracing
          // Check if login by checking user session
          if (UserSession.user) {
            const user = UserSession.user && UserSession.user.id;
            const company = (UserSession.company && UserSession.company.id) || '';

            // Get the first 8 digits character of IDs if any
            const trimmedUserId = user.split('-')[0];
            const trimmedCompanyId = company.split('-')[0];

            // Generate 8 digits random alpha numeric characters https://stackoverflow.com/a/12502559/1123245
            const randomAlphaNumericCharacters = Math.random().toString(36).slice(5);

            config.headers = config.headers || {};

            config.headers[
              'X-Request-ID'
            ] = `${trimmedUserId}-${trimmedCompanyId}-${randomAlphaNumericCharacters}`;
          }

          // Check User rights
          if (UserSession.user && UserSession.company) {
            const isLuxUser = UserSession.isLuxUser();
            const isUberFreightUser = UserSession.isUberFreightUser();
            const isRedirectEnabled = isLuxUser || isUberFreightUser;

            // Makes sure the redirects just affect users with Dashboard customizations
            if (isRedirectEnabled) {
              const urlActionsDictionary = {
                'menu.account.addresses': '/account/addresses',
                'menu.account.billing': '/account/statements',
                'menu.account.company': '/account/company',
                'menu.account.paymentMethods': '/account/payment',
                'menu.account.profile': '/account/profile',
                'menu.account.subscriptions': '/account/subscription',
                'menu.account.team': '/account/team',
                'menu.analytics': '/dashboard',
                'menu.connect.all': ['/store', '/redirect'],
                'menu.connect.new': '/connect',
                'menu.connect.webhooks': '/webhooks',
                'menu.couriers': '/couriers',
                'menu.createShipments.multiple': ['/advanced', '/request-pickup', '/order-summary'],
                'menu.createShipments.single': '/basic',
                'menu.help': '/help',
                'menu.manageShipments.all': '/shipments',
                'menu.manageShipments.pending': '/shipments/pending',
                'menu.manageShipments.rejected': '/shipments/rejected',
                'menu.manageShipments.toDownload': '/shipments/to_download',
                'menu.pickups': ['/pickups', '/reschedule-pickup/handover'],
                'menu.shippingRules': '/tools/automations',
                'menu.products': '/product-listing',
                'menu.quote': '/quote',
                'menu.returns': '/returns',
                'menu.settings.boxes': '/tools/boxes',
                'menu.settings.emails': '/tools/customer-emails',
                'menu.settings.insurance': '/tools/insurance',
                'menu.settings.notifications': '/tools/notifications',
                'menu.settings.packingSlip': '/tools/packing',
                'menu.settings.printingOptions': '/tools/printing-options',
                'menu.settings.trackingPage': '/tools/tracking-page',
              };

              const parentElementsWithUrlRedirectDefaults = {
                '/shipments': 'app.shipments', //
                '/advanced': 'receiver-info', // /basic/receiver-info
              };

              const userAllowedActions =
                UserSession.user && UserSession.user.role && UserSession.user.role.actions;

              const allowedMenuItems = Object.keys(userAllowedActions).filter(function (key) {
                return (
                  userAllowedActions[key] !== false &&
                  Object.keys(userAllowedActions)[0].startsWith('menu.')
                );
              });

              const allowedUrls = _.flatten(
                allowedMenuItems.map(function (action) {
                  if (urlActionsDictionary[action]) {
                    return urlActionsDictionary[action];
                  }
                })
              );

              // Checks if current url is allowed
              const isCurrentUrlAllowed = function () {
                const currentUrl = window.location.pathname;
                return allowedUrls.some(function (item) {
                  return currentUrl.startsWith(item);
                });
              };

              // Finds out if the current url has a custom redirect and gets the redirect url

              const hasCustomRedirect = function () {
                const currentUrl = window.location.pathname;
                return Object.keys(parentElementsWithUrlRedirectDefaults).find(function (key) {
                  return currentUrl.startsWith(key);
                });
              };

              // Gets the state of custom redirect in angular format ex: app.shipments

              const getCustomRedirect = function () {
                return parentElementsWithUrlRedirectDefaults[hasCustomRedirect()];
              };

              const fallbackRedirects = {
                'menu.analytics': 'app.home', // /dashboard
                'menu.quote': 'app.quote', // /quote
                'menu.createShipments.multiple': 'app.multiple', // /advanced
                'menu.createShipments.single': 'receiver-info', // /basic/receiver-info
                'menu.manageShipments.all': 'app.shipments', // /shipments
              };

              const getFallbackRedirect = function () {
                const myFirstAllowedUserRight = Object.keys(fallbackRedirects).find(function (key) {
                  return allowedMenuItems.includes(key);
                });
                return fallbackRedirects[myFirstAllowedUserRight];
              };

              // Redirects to either one of the Fallback redirects or Custom redirect if current url is not allowed
              if (!isCurrentUrlAllowed()) {
                $state.go(
                  hasCustomRedirect() ? getCustomRedirect() : getFallbackRedirect(),
                  {},
                  { relative: false }
                );
              }
            }
          }

          return config;
        },
        // Intercept 401s and redirect you to login
        responseError(response) {
          if (response.status === 401) {
            const isLuxotticaSso = $window.localStorage.getItem('isLuxotticaSso');
            const isLuxUser = UserSession.isLuxUser() || !!isLuxotticaSso;
            const isSsoSession = $window.localStorage.getItem('ssoUrl');
            const isUberFreightLogin = $window.localStorage.getItem('isUberFreight');
            const cookieDomain =
              window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';

            $cookies.remove(API.credentials, {
              secure: true,
              sameSite: 'none',
              partitioned: true,
              domain: cookieDomain,
              path: '/',
            });
            UserSession.destroy();

            // Do not go to login if it is in the shopify registration flow
            // TODO: To be improved
            if (isLuxUser) {
              $window.localStorage.removeItem('isLuxotticaSso');
              $state.go('home.auth.luxottica');
            } else if (isSsoSession) {
              $state.go('home.auth.sso-session');
            } else if (isUberFreightLogin) {
              $state.go('home.uber-freight-login');
            } else if ($state.is('auth.landing')) {
              $location.path('/auth-landing');
            } else if ($state.is('home.uber-freight-login')) {
              $location.path('/uber-freight/login');
            } else if (
              $location.path().split('/')[2] !== 'registration' &&
              !$state.is('home.auth.sso') &&
              !$state.is('home.auth.luxottica') &&
              !$state.is('home.auth.sso-session') &&
              !$state.is('home.auth.verify')
            ) {
              $location.path('/login');
            }

            $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);

            // remove any stale tokens
            return $q.reject(response);
          }
          return $q.reject(response);
        },
      };
    },
  ])
  .run([
    '$timeout',
    '$rootScope',
    '$location',
    '$q',
    '$anchorScroll',
    '$state',
    '$stateParams',
    '$translate',
    'UserSession',
    '$uibModalStack',
    'API',
    'CourierService',
    'CompanyService',
    'MetadataService',
    'Auth',
    'StoreService',
    'CountryService',
    'WalkthroughService',
    'MixpanelService',
    'HotjarService',
    'HelperService',
    'PlatformService',
    'SubscriptionService',
    'CourierAccounts',
    'PusherService',
    'AppCuesService',
    'WelcomeService',
    'SurveySparrowService',
    'SentryService',
    'ScanService',
    (
      $timeout,
      $rootScope,
      $location,
      $q,
      $anchorScroll,
      $state,
      $stateParams,
      $translate,
      UserSession,
      $uibModalStack,
      API,
      CourierService,
      CompanyService,
      MetadataService,
      Auth,
      StoreService,
      CountryService,
      WalkthroughService,
      MixpanelService,
      HotjarService,
      HelperService,
      PlatformService,
      SubscriptionService,
      CourierAccounts,
      PusherService,
      AppCuesService,
      WelcomeService,
      SurveySparrowService,
      SentryService,
      ScanService
    ) => {
      $rootScope.$on('auth-full-login-success', function () {
        if ($state.is('home.auth.sso') || $state.is('home.auth.verify')) return;

        SentryService.configureScope({
          id: UserSession.user.id,
          email: UserSession.user.email,
          username: `${UserSession.user.first_name} ${UserSession.user.last_name}`,
          companyId: UserSession.company.easyship_company_id,
          companyName: UserSession.company.name,
        });

        MixpanelService.identify(UserSession.user.id);

        // Load CourierService on session creation
        CourierService.getCouriers();

        CountryService.getCountries().then(function () {
          MixpanelService.setPeople({
            $easyship_company_id: UserSession.company.easyship_company_id,
            $user_permissions: UserSession.getUserRoles()
              .map(function (userRole) {
                return userRole.name;
              })
              .join(),
            $email_domain: UserSession.user.email.split('@')[1],
          });
        });

        if (API.environment === 'production' && UserSession.user) {
          const userEmail = UserSession.user.email;

          if (window.ire) {
            window.ire('identify', {
              customerId: UserSession.company.easyship_company_id,
              customerEmail: HelperService.hashString(userEmail),
            });
          }
        }

        // Load tutorials data
        WalkthroughService.initData();

        const deferredCurrentSubscription = $q.defer();

        SubscriptionService.fetchCurrentSubscription(
          {
            company_id: UserSession.getCompanyId(),
          },
          false
        ).then(function () {
          deferredCurrentSubscription.resolve(SubscriptionService.currentSubscription);

          if (SubscriptionService.isSubscriptionAvailable) {
            SubscriptionService.checkCurrentLegacyTrialEndState();
            SubscriptionService.displayEnterpriseWelcomeModal();
          }

          SubscriptionService.enableZendeskChat();
          SubscriptionService.listenToSubscriptionExpiration();
        });

        SubscriptionService.fetchPlansDetail({
          country_id: UserSession.getCompanyCountryId(),
        });

        CourierAccounts.getActiveCourierAccounts()
          .then(function (response) {
            UserSession.hasEasyshipServices = !!(
              response.easyship_courier_accounts && response.easyship_courier_accounts.length
            );

            UserSession.hasCompanyCourierAccounts = !!(
              response.company_courier_accounts && response.company_courier_accounts.length
            );
          })
          .catch(function (error) {
            throw error;
          });

        if (!PusherService.client) {
          PusherService.initPusher();
        }

        const { user } = UserSession;
        const { company } = UserSession;

        if (user && company) {
          // Load Stores for sidebar
          PlatformService.getAllActivePlatforms();

          const deferredStores = $q.defer();
          const deferredCompanyActivity = $q.defer();

          StoreService.getStores().then(function (stores) {
            deferredStores.resolve(stores);

            CompanyService.getCompanyActivity()
              .then(function (activity) {
                const activityJson = activity.toJSON();
                AppCuesService.identifyAndBuildAppCuesData(user, company, activityJson, stores);
                deferredCompanyActivity.resolve(activityJson);

                // Prevent SurveySparrow survey from showing for Luxottica users
                if (!UserSession.isLuxUser()) {
                  SurveySparrowService.mount({
                    user,
                    company,
                    activity,
                    stores,
                  });
                }
              })
              .catch(function () {
                // When a user just signed up, the activity endpoint will throw 404
                AppCuesService.identifyAndBuildAppCuesData(user, company, null, stores);
                deferredCompanyActivity.resolve(null);
                // Prevent SurveySparrow survey from showing for Luxottica users
                if (!UserSession.isLuxUser()) {
                  SurveySparrowService.mount({
                    user,
                    company,
                    stores,
                  });
                }
              });
          });

          $q.all([
            deferredCurrentSubscription.promise,
            deferredStores.promise,
            deferredCompanyActivity.promise,
          ]).then(function (values) {
            const currentSubscription = values[0];
            const stores = values[1] && Array.isArray(values[1].stores) ? values[1].stores : [];
            const activity = values[2];
            const enabledFeatures = Object.keys(company.feature_flags)
              .filter((flag) => !!company.feature_flags[flag])
              .join();

            HotjarService.identify(user.id, {
              easyship_company_id: company.easyship_company_id,
              plan_name: currentSubscription.plan.name,
              signup_date: user.created_at,
              company_type: company.type,
              country: company.country_name,
              signup_expected_volume: company.expected_shipment_volume || '',
              platforms: stores
                .map((store) => store.platform?.name)
                .filter(Boolean)
                .join(),
              shipments_per_month_grouping: activity ? activity.shipments_per_month_grouping : '',
              enabled_feature_flags: enabledFeatures,
            });
          });

          WelcomeService.init();

          addDefaultBarCodeListener();
        }

        // Set display language
        if (user && user.dashboard_settings && user.dashboard_settings.language) {
          $translate.use(user.dashboard_settings.language.code);
        }
      });

      // Redirect to login if route requires auth and you're not logged in
      // Deprecation warning: replace $stateChangeStart with TransitionService.onStart instead
      $rootScope.$on('$stateChangeStart', function (event, next, toParams) {
        // If user was going to an authenticated url without being logged in
        // -> Save destination state and params to reuse it later
        // else clear the destination name and params to avoid confusion
        const isNotLoginURL =
          [
            'home.auth.login',
            'home.auth.logout',
            'auth.landing',
            'home.uber-freight-login',
          ].indexOf(next.name) === -1;

        if (['home.auth.luxottica', 'home.auth.sso-session'].indexOf(next.name) > -1) return;

        if (isNotLoginURL && (Auth.isLoggedIn() !== true || UserSession.user !== true)) {
          // Special case where if the value of next.name is equal to home then it needs to be
          // manually redirected app.home to prevent it from being stuck
          if (next.name === 'home') {
            $state.go('app.home');
          }

          $rootScope.returnToState = next.name;
          $rootScope.returnToParams = toParams;
        }

        // "Jump" login and go straight to the dashboard if the user is already logged in
        if (
          (next.name === 'home.auth.login' ||
            next.name === 'auth.landing' ||
            next.name === 'home.uber-freight-login') &&
          Auth.isLoggedIn() === true &&
          !$stateParams.userEmail
        ) {
          $location.path('/dashboard');
        }

        // Add redirect functionality when loading a state
        if (next.redirectTo) {
          event.preventDefault();
          $state.go(next.redirectTo, toParams);
        }

        // // Close modals - Prevent blank modals from being open/opened when pressing back
        const top = $uibModalStack.getTop();

        if (top) {
          if (
            (((top && !next.parent) || (top && next.parent.indexOf('Modal') === -1)) &&
              top.value.openedClass !== 'welcome-to-easyship-modal-container') ||
            (top.value.openedClass === 'welcome-to-easyship-modal-container' &&
              next.name.substring(0, 3) !== 'app')
          ) {
            $uibModalStack.dismiss(top.key);
          } else if (
            (((top && !next.parent) || (top && next.parent.indexOf('Modal') === -1)) &&
              top.value.openedClass !== 'onboarding-modal-container') ||
            (top.value.openedClass === 'onboarding-modal-container' &&
              next.name.substring(0, 3) !== 'app')
          ) {
            $uibModalStack.dismiss(top.key);
          }
        }
      });

      // Force scroll on top when new page is loaded
      // Deprecation warning: replace $stateChangeSuccess with TransitionService.onStart and Transition.promise, or Transition.onSuccess
      $rootScope.$on(
        '$stateChangeSuccess',
        function (_event, toState, toParams, fromState, fromParams) {
          toast.dismiss();
          MixpanelService.trackPageView({
            easyship_company_id: UserSession.company
              ? UserSession.company.easyship_company_id
              : undefined,
          });
          const blogSlug =
            toParams.category !== null && angular.isDefined(toParams.categorySlug)
              ? toParams.category.slug
              : toParams.categorySlug;

          if (fromState.name !== toState.name) {
            if (angular.isUndefined(fromParams.categorySlug)) {
              $anchorScroll();
            } else if (
              angular.isDefined(fromParams.categorySlug) &&
              fromParams.categorySlug !== blogSlug
            ) {
              $anchorScroll();
            }
          } else if (
            angular.isDefined(fromParams.categorySlug) &&
            fromParams.categorySlug !== blogSlug
          ) {
            $anchorScroll();
          }

          // Fetch & update UserStatus
          if (UserSession.company && toState.name.substring(0, 3) === 'app') {
            CompanyService.updateStatus();
          }

          if ($state.includes('app')) {
            MetadataService.setMetadata({
              title: 'Dashboard | Easyship',
            });
          }
        }
      );

      // https://docs.appcues.com/article/389-single-page-applications-spas
      $rootScope.$on('$locationChangeSuccess', function () {
        $timeout(function () {
          AppCuesService.page();

          if (window.HubSpotConversations && window.HubSpotConversations.widget) {
            window.HubSpotConversations.widget.refresh();
          }
        });
      });
    },
  ]);
