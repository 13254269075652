import template from './clickable-card.html?raw';
import style from './clickable-card.module.scss';

class ClickableCard {
  constructor() {
    this.style = style;
  }

  onKeyPress($event) {
    const { keyCode, preventDefault } = $event;
    const spaceBar = 32;
    const enter = 13;
    if (keyCode === spaceBar || keyCode === enter) {
      this.ngClick();
      preventDefault();
    }
  }

  onClick($event) {
    if (this.ngDisabled) $event.stopPropagation();
  }
}

const ClickableCardComponent = {
  controller: ClickableCard,
  template,
  transclude: true,
  bindings: {
    ngClick: '&',
    ngDisabled: '<',
    esSelected: '<',
    esStyle: '@',
  },
};

export { ClickableCardComponent };
