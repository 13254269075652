import { EasyshipLocale } from '@client/core/corelogic/models/EasyshipLocale';
import angular, { IComponentController, IFormController } from 'angular';
import { IEmailPreviewService } from 'typings/email';
import { IHelperService } from 'typings/helper';
import { IUserService } from 'typings/user-service';
import { IUser, IUserNotificationSettings, IUserSession } from 'typings/user-session';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { invalidateUseDashboardSettings } from '@/hooks/queries/useDashboardSettings/invalidate';
import template from './profile.html?raw';
import style from './profile.module.scss';

interface Language {
  name: string;
  enabled: boolean;
}

interface LanguageOption {
  code: EasyshipLocale;
  name: string;
}

type FormField = 'first_name' | 'last_name' | 'mobile_phone' | 'locale';
type ShowChangeModal = {
  password: boolean;
  email: boolean;
};

class Profile implements IComponentController {
  style = style;
  error = { profileForm: '' };
  translations: Record<string, string> = {};
  loading = { profileForm: false };
  user: Pick<IUser, 'first_name' | 'last_name' | 'mobile_phone' | 'dashboard_settings'>;
  receiveSenderSurvey = false;
  languageOptions: LanguageOption[];
  profileForm: IFormController | undefined;
  showChangeModal: ShowChangeModal = {
    password: false,
    email: false,
  };

  static $inject = [
    'UserSession',
    'EmailPreviewService',
    'MixpanelService',
    'HelperService',
    'UserService',
    '$translate',
  ];

  constructor(
    private UserSession: IUserSession,
    private EmailPreviewService: IEmailPreviewService,
    private MixpanelService: MixpanelService,
    private HelperService: IHelperService,
    private UserService: IUserService,
    private $translate: angular.translate.ITranslateService
  ) {
    this.style = style;
    const {
      first_name: firstName,
      last_name: lastName,
      mobile_phone: mobilePhone,
      dashboard_settings: dashboardSettings,
    } = angular.copy(this.UserSession.user);
    this.user = {
      first_name: firstName,
      last_name: lastName,
      mobile_phone: mobilePhone,
      dashboard_settings: dashboardSettings,
    };
    this.receiveSenderSurvey = false;

    const languages: Record<EasyshipLocale, Language> = {
      en: { name: 'English', enabled: true },
      fr: { name: 'Français', enabled: true },
      de: { name: 'Deutsch', enabled: true },
      es: { name: 'Español', enabled: true },
    };
    this.languageOptions = Object.entries(languages).reduce<LanguageOption[]>(
      (options, [key, lang]) =>
        lang.enabled ? [...options, { code: key as EasyshipLocale, name: lang.name }] : options,
      []
    );
  }

  $onInit() {
    this.$translate(['profile.update-failed', 'profile.update-password-failed']).then(
      (translations) => {
        this.translations = translations;
      }
    );
    this.UserService.getNotificationSettings(this.UserSession.getUserId()).then((data) => {
      this.receiveSenderSurvey = data.receive_sender_survey;
    });
  }

  onProfileInputChange(value: EasyshipLocale, key: FormField) {
    if (key === 'locale') {
      if (this.user.dashboard_settings?.language?.code) {
        this.user.dashboard_settings.language.code = value;
      }
    } else {
      this.user[key] = value;
    }
  }

  onSubmitProfileForm() {
    if (this.profileForm?.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading.profileForm = true;

    this.UserService.update(this.user)
      .then((response) => {
        toastSuccess(this.$translate.instant('profile.update-success'));
        this.UserSession.update('user', response.user);
        this.$translate.use(response.user.dashboard_settings?.language?.code || 'en');
        this.error.profileForm = '';
        this.MixpanelService.track('Account - Profile - Details');
      })
      .catch((error: any) => {
        let errorMessage = '';

        if (error.data) {
          Object.keys(error.data).forEach((key) => {
            errorMessage = `${errorMessage} ${this.HelperService.capitalize(key)} ${
              error.data[key]
            }.`;
          });
        }

        this.error.profileForm = errorMessage || this.translations['profile.update-failed'];
      })
      .finally(() => {
        this.loading.profileForm = false;
        invalidateUseDashboardSettings()
          .then()
          .catch(() => undefined);
      });
  }

  onNotificationsToggle(value: boolean, key: 'receive_sender_survey') {
    this.updateNotificationSettings({ [key]: value });

    const eventMapping = {
      receive_labels_confirmation: 'Label Generation',
      receive_pickup_confirmation: 'Pickup Request',
      receive_sender_survey: 'Client Survey',
    };
    const event = eventMapping[key];

    this.MixpanelService.track(`Account - Profile - ${event} Notification`, {
      selection: value,
    });
  }

  showPreviewEmail(name: string) {
    this.EmailPreviewService.open(name);
  }

  openChangeModal(type: keyof ShowChangeModal): void {
    this.showChangeModal[type] = true;
  }

  closeChangeModal(type: keyof ShowChangeModal): void {
    this.showChangeModal[type] = false;
  }

  updateNotificationSettings(notificationSettings: Partial<IUserNotificationSettings>) {
    this.UserService.update({ notification_settings: notificationSettings })
      .then(() => {
        toastSuccess(this.$translate.instant('profile.update-success'));
      })
      .catch(() => {
        toastError(this.$translate.instant('profile.update-failed'));
      });
  }

  get emailAddress() {
    return this.UserSession.user.email;
  }
}

const ProfileComponent = {
  template,
  controller: Profile,
};

export { ProfileComponent };
