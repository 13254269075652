import angular from 'angular';

import { BrokersService } from './brokers.service';
import { BrokersResource } from './brokers.resource';

const MODULE_NAME = 'app.global.services.brokers';

angular
  .module(MODULE_NAME, [])
  .service('BrokersService', BrokersService)
  .service('BrokersResource', BrokersResource);

export default MODULE_NAME;
