import { IComponentController, IOnChangesObject } from 'angular';

import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';

import { IInputGroupRadioModel } from 'typings/form';
import { IGroupedArray, IHelperService } from 'typings/helper';
import { IAdvancedSearchPickupHandlersObject } from 'typings/advanced-search';
import { PickupHandlersService } from '@client/src/global/services/pickup-handlers/pickup-handlers.service';
import { toastError } from '@client/core/components/react/Toastify';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

import template from './handlers-multi-select-group.html?raw';
import style from './handlers-multi-select-group.module.scss';

type Busy = {
  gettingHandlers: boolean;
};

class HandlersMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchPickupHandlersObject>
  implements IComponentController
{
  style = style;
  busy: Busy = {
    gettingHandlers: false,
  };
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchPickupHandlersObject>[] = [];

  static $inject = ['$scope', '$translate', 'PickupHandlersService', 'HelperService'];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private PickupHandlersService: PickupHandlersService,
    private HelperService: IHelperService
  ) {
    super($scope);
    this.fetchHandlers = this.fetchHandlers.bind(this);
    this.radioModel = [
      {
        title: this.$translate.instant('pickups.filters.menu.couriers.any'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: this.$translate.instant('pickups.filters.menu.couriers.none'),
        value: MultiselectComponentModelValue.NoneOf,
      },
    ];
  }

  $onInit(): void {
    this.assignDefaultRadioModel();
  }

  $onChanges(changes: IOnChangesObject): void {
    this.onChangesAssignAndTrigger(changes, 'handler_name', this.fetchHandlers);
  }

  private fetchHandlers(): void {
    this.busy.gettingHandlers = true;

    this.PickupHandlersService.get()
      .then((handlers) => {
        const handlersByGroup = handlers.reduce((accumulator, handler) => {
          accumulator.push({
            ...handler,
            group: 'All',
          });

          return accumulator;
        }, [] as IAdvancedSearchPickupHandlersObject[]);

        this.esModelOptions = this.HelperService.groupArrayByKey(handlersByGroup, 'group', {
          isDisplay: true,
        });

        this.watchAndTrigger('handler_name');

        this.busy.gettingHandlers = false;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
        this.esModelOptions = [];
      })
      .finally(() => {
        this.busy.gettingHandlers = false;
      });
  }
}

const HandlersMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: HandlersMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { HandlersMultiSelectGroupComponent };
