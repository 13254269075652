import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { ShipmentGatewayProvider } from '@client/src/manage-shipments/providers/ShipmentGatewayProvider';
import { SubscriptionServiceProvider } from '@client/src/global/context/SubscriptionProvider';

const PickAndPackComponent = lazy(() => import('@/pages/pick-and-pack'));

function WrappedComponent() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentGatewayProvider>
          <SubscriptionServiceProvider>
            <Suspense fallback={null}>
              <PickAndPackComponent />
            </Suspense>
          </SubscriptionServiceProvider>
        </ShipmentGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularPickAndPack = react2angular(WrappedComponent);
