import { IUserSession } from 'typings/user-session';
import { ITagsResponse } from 'typings/tags';
import { IComponentController } from 'angular';
import { TagsService } from '@client/src/global/services/tags/tags.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './tags-shipment-dropdown.module.scss';
import template from './tags-shipment-dropdown.html?raw';

class TagsShipmentDropdown implements IComponentController {
  style = style;
  esModel: string[] = [];
  esShowUnassignedTags = false;
  tags: string[] = [];
  showUnassignedTags = false;
  tagName = '';
  gettingTags = false;

  static $inject = ['$element', '$timeout', '$translate', 'UserSession', 'TagsService'];
  constructor(
    private $element: ng.IAugmentedJQuery,
    private $timeout: ng.ITimeoutService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private TagsService: TagsService
  ) {}

  $onInit() {
    this.getTags();
    this.esModel = this.esModel || [];
    this.esShowUnassignedTags = true;
  }

  esOnChange(_value: unknown): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully
  }

  esOnAddTag(_value?: unknown): void {
    // esOnAddTag expression bindings, need to add this in order for typescript to successfully
  }

  isSelected(tag: string): boolean {
    return this.esModel.includes(tag);
  }

  onTagInputChange(name: string): void {
    this.tagName = name;
  }

  onDropdownToggle(isOpen: boolean): void {
    if (!isOpen) return;

    this.$timeout(() => {
      this.$element.find('input')[0].focus();
    }, 100);

    this.showUnassignedTags = !!this.esShowUnassignedTags;
  }

  toggleUnassignedTags($event: KeyboardEvent): void {
    $event.stopPropagation();
    this.showUnassignedTags = !this.showUnassignedTags;
  }

  addTag($event: KeyboardEvent): void {
    if ($event.code === 'Enter') {
      $event.preventDefault();
      const tagNameLowerCase = this.tagName.toLowerCase().trim();
      const tagExists = this.tags.includes(tagNameLowerCase);

      if (tagExists) {
        this.selectTag($event, tagNameLowerCase);
        this.tagName = '';
      } else if (tagNameLowerCase) {
        const companyId = this.UserSession.getCompanyId() as string;

        this.TagsService.addTag(
          {
            company_id: companyId,
          },
          {
            tag: {
              name: tagNameLowerCase,
            },
          }
        )
          .then(() => {
            this.tags.unshift(tagNameLowerCase);
            this.selectTag($event, tagNameLowerCase);
            this.tagName = '';

            this.esOnAddTag({ value: this.esModel });
          })
          .catch(() => {
            toastError(this.$translate.instant('toast.default-error'));
          });
      }
    }
  }

  selectTag($event: KeyboardEvent, tag: string): void {
    $event.stopPropagation();

    const index = this.esModel.indexOf(tag);

    if (index > -1) {
      this.esModel.splice(index, 1);
    } else {
      this.esModel.push(tag);
    }

    this.esOnChange({ value: this.esModel });
  }

  isUnassigned(tag: string): boolean {
    return !this.esModel.includes(tag);
  }

  private getTags(): void {
    const companyId = this.UserSession.getCompanyId() as string;

    this.gettingTags = true;

    this.TagsService.getTags({
      company_id: companyId,
    })
      .then((response: ITagsResponse) => {
        this.tags = response.tags.sort();
      })
      .finally(() => {
        this.gettingTags = false;
      });
  }
}

const TagsShipmentDropdownComponent: ng.IComponentOptions = {
  controller: TagsShipmentDropdown,
  template,
  transclude: true,
  bindings: {
    esModel: '<',
    esOnChange: '&',
    esOnAddTag: '&',
    esError: '<',
    esEmptyPlaceholder: '@',
    esInputPlaceholder: '@',
    esShowUnassignedTags: '<',
    esUnassignedToggleLabel: '@',
  },
};

export { TagsShipmentDropdownComponent };
