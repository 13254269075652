import template from './transactions.html?raw';
import style from './transactions.module.scss';

class Transactions {
  isExporting = false;

  static $inject = [
    '$state',
    'BillingService',
    'UserSession',
    'Shipment',
    'showShipmentModal',
    'MixpanelService',
    '$window',
    'API',
  ];

  constructor(
    $state,
    BillingService,
    UserSession,
    Shipment,
    showShipmentModal,
    MixpanelService,
    $window,
    API
  ) {
    this.style = style;
    this.$state = $state;
    this.BillingService = BillingService;
    this.UserSession = UserSession;
    this.Shipment = Shipment;
    this.showShipmentModal = showShipmentModal;
    this.MixpanelService = MixpanelService;
    this.$window = $window;
    this.API = API;
  }

  handleKeywordSearch = () => {
    this.onSearch();
  };

  openFilter = () => {
    this.BillingService.toggleAdvancedSearch();
  };

  onSearchChange = (value) => {
    this.BillingService.filter.keyword = value;
  };

  exportCsv() {
    this.isExporting = true;
    const {
      transactionable_types,
      balance_type,
      keyword,
      from_date,
      to_date,
      finance_document_id,
    } = this.BillingService.filter;

    const transactionable_types_array = transactionable_types
      ? transactionable_types.split(',')
      : [];

    this.BillingService.exportTransactions(
      {
        company_id: this.UserSession.company.id,
      },
      {
        transactionable_types: transactionable_types_array,
        balance_type: balance_type || '',
        keyword: keyword || '',
        created_at_from: from_date ? from_date.toISOString() : '',
        created_at_to: to_date ? to_date.toISOString() : '',
        payment_recipient: 'Easyship',
        finance_document_id: finance_document_id || '',
      }
    ).finally(() => {
      this.isExporting = false;
    });
  }

  isEasyshipId(id) {
    return id ? id.startsWith('ES') : false;
  }

  openShipmentModal(shipmentId) {
    this.MixpanelService.track('View Shipment Details - Billings/Transactions', {
      easyship_shipment_id: shipmentId,
    });

    this.showShipmentModal.open(shipmentId, null, this.$state.current);
  }
}

const TransactionsComponent = {
  template,
  controller: Transactions,
  bindings: {
    onSearch: '&',
    transactions: '<',
    nextBillingDate: '@',
    loading: '<',
    error: '<',
    itemCount: '<',
  },
};

export { TransactionsComponent };
