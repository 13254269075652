import React from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';

import ClaimMessage from './ClaimMessage';

import { IClaimMessage } from 'typings/insurance';

interface ClaimHistoryProps {
  messages: IClaimMessage[];
}

export default function ClaimHistory({ messages }: ClaimHistoryProps) {
  return (
    <Box maxWidth={500}>
      <Typography variant="h3">
        <FormattedMessage id="insurance-claim.claim-dialog.history"></FormattedMessage>
      </Typography>
      <Box maxHeight={270} overflow="auto">
        <List>
          {messages.length ? (
            messages.map((message, index) => <ClaimMessage message={message} key={index} />)
          ) : (
            <Typography variant="body2">
              <FormattedMessage id="insurance-claim.claim-dialog.no-history"></FormattedMessage>
            </Typography>
          )}
        </List>
      </Box>
    </Box>
  );
}
