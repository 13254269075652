import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Box from '@material-ui/core/Box';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

import { FormattedDate, FormattedMessage } from 'react-intl';

import COLORS from '@client/src/colors';

import { IClaimMessage } from 'typings/insurance';

interface ClaimMessageProps {
  message: IClaimMessage;
}

export default function ClaimMessage({ message }: ClaimMessageProps) {
  const {
    created_at,
    user_role,
    user_first_name,
    user_last_name,
    buyer_name,
    content,
    claim_photos,
  } = message;

  return (
    <ListItem alignItems="flex-start" disableGutters>
      <ListItemAvatar style={{ margin: 0 }}>
        <Box textAlign="right" p="0 16px 0 8px">
          <Typography variant="body2" style={{ color: COLORS.inkNormal }}>
            <FormattedDate value={created_at} dateStyle="medium" timeStyle="short"></FormattedDate>
          </Typography>
          <Typography variant="body1" style={{ color: COLORS.blueDark }}>
            {user_role === 'system' && (
              <FormattedMessage id={`insurance-claim.claim-dialog.system`} />
            )}

            {user_role === 'agent' && (
              <FormattedMessage id={`insurance-claim.claim-dialog.agent`} />
            )}

            {user_role === 'claimee' && (
              <>{user_first_name ? `${user_first_name} ${user_last_name}` : buyer_name}</>
            )}
          </Typography>
        </Box>
      </ListItemAvatar>
      <div style={{ width: 263 }}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ maxWidth: '100%', overflowX: 'auto', whiteSpace: 'pre-line' }}
        >
          {content}
        </Typography>
        <ImageList rowHeight={60} gap={5} cols={3}>
          {claim_photos.map((photo) => (
            <ImageListItem key={photo.id}>
              <a href={photo.url} target="_blank">
                <img src={photo.thumb_url} alt="thumbnail" />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </ListItem>
  );
}
