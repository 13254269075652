import template from './textarea.html?raw';
import style from './textarea.module.scss';

class Textarea {
  static $inject = ['$element'];

  constructor($element) {
    this.style = style;
    this.$element = $element;
  }

  $onInit() {
    this._validateBinding();
  }

  hasLabel() {
    return !!angular.element('ng-transclude', this.$element).html();
  }

  onTextareaChange() {
    this.$ngModelController.$setDirty();
    this._validateLength(this.ngModel);
    this.ngChange({ value: this.ngModel });
  }

  _validateLength(value = '') {
    if (this.ngMinLength) {
      const isValid = value.length >= parseFloat(this.ngMinLength);

      this.$ngModelController.$setValidity('ngMinLength', isValid);
    }

    if (this.ngMaxLength) {
      const isValid = value.length <= parseFloat(this.ngMaxLength);

      this.$ngModelController.$setValidity('ngMaxLength', isValid);
      this.lengthValidationMessage = this.$ngModelController.$dirty
        ? this._buildLengthValidationMessage(value)
        : null;
    }
  }

  _buildLengthValidationMessage({ length }) {
    let dynamicNumber = length;

    if (this.ngMinLength) {
      dynamicNumber = length < this.ngMinLength ? this.ngMinLength : length;
    }

    return `${dynamicNumber} / ${this.ngMaxLength}`;
  }

  _validateBinding() {
    const { ngMinLength, ngMaxLength } = this;

    if (typeof ngMinLength !== 'undefined' && typeof parseFloat(ngMinLength) !== 'number') {
      throw new Error(`EasyshipTextarea: Only numbers are accepted for 'ngMinLength'`);
    }

    if (typeof ngMaxLength !== 'undefined' && typeof parseFloat(ngMaxLength) !== 'number') {
      throw new Error(`EasyshipTextarea: Only numbers are accepted for 'ngMaxLength'`);
    }
  }
}

const TextareaComponent = {
  controller: Textarea,
  template,
  transclude: true,
  require: {
    $ngModelController: 'ngModel',
  },
  bindings: {
    esId: '@',
    esPlaceholder: '@',
    esReadOnly: '<',
    esError: '<',
    ngModel: '<',
    ngChange: '&',
    ngDisabled: '<',
    ngMinLength: '@',
    ngMaxLength: '@',
  },
};

export { TextareaComponent };
