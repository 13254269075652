import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import template from './edit-products-modal.html?raw';
import style from './edit-products-modal.module.scss';
import { ProductListingService } from '../product-listing.service';

enum Steps {
  Actions = 'actions',
  BulkEdit = 'bulk-edit',
  Spreadsheet = 'spreadsheet',
}

class EditProductsModal implements IComponentController {
  style = style;
  steps = Steps;
  currentStep: Steps = Steps.Actions;

  static $inject = ['ProductListingService', 'MixpanelService', 'AppCuesService'];
  constructor(
    private ProductListingService: ProductListingService,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService
  ) {}

  $onInit(): void {
    if (this.ProductListingService.isOpenBulkEditor) {
      this.currentStep = Steps.BulkEdit;
    }
  }

  onBulkEditSelect(): void {
    this.currentStep = Steps.BulkEdit;
    this.MixpanelService.track('Products - Selected Bulk Editor');
    this.AppCuesService.track('Products | Selected Bulk Editor', null, true);
  }

  onSpreadsheetSelect(): void {
    this.currentStep = Steps.Spreadsheet;
  }

  goToActions(): void {
    this.currentStep = Steps.Actions;
  }
}

const EditProductsModalComponent: ng.IComponentOptions = {
  controller: EditProductsModal,
  template,
  bindings: {
    esMissingInformation: '<',
  },
};

export { EditProductsModalComponent };
