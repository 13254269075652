export enum NormalizeFilterKey {
  Keyword = 'keyword',
  Offset = 'offset',
  Limit = 'limit',
  Scope = 'scope',
  CreatedAt = 'created_at',
  OrderCreatedAt = 'order_created_at',
  LabelPaidAt = 'label_paid_at',
  ShipmentItemsCount = 'shipment_items_count',
  Weight = 'total_actual_weight',
  SkuCount = 'unique_sku_count',
  IsReturn = 'is_return',
  InsuranceScope = 'insurance_scope',
  WarehouseState = 'warehouse_state',
  PickupId = 'pickup_id',
  BatchId = 'batch_id',
  ManifestId = 'manifest_id',
  PackageId = 'package_id',
  DestinationCountryId = 'destination_country_id',
  DestinationStateAbbreviation = 'country_state_abbr',
  CourierId = 'courier_id',
  OriginAddressId = 'origin_address_id',
  Incoterms = 'incoterms_scope',
  LastStatusMessageId = 'last_status_message_id',
  StoreFulfillmentState = 'store_state',
  StoreId = 'store_id',
  PlatformName = 'platform_name',
  Sku = 'sku',
  IsValidToShip = 'is_valid_to_ship',
  Tags = 'order_tags',
  EtaDate = 'eta_date',
  ItemDescription = 'item_description',
  PickLocation = 'pick_location',
  CollectState = 'collect_state',
  MissingDimensions = 'is_missing_dimensions',
  MissingParcelWeight = 'total_actual_weight',
  MissingItemDescription = 'is_missing_item_description',
  MissingItemCategoryId = 'is_missing_item_category_and_hs_code',
  MissingItemDeclaredCustomsValue = 'is_missing_item_declared_customs_value',
  MissingShipmentItemsCount = 'shipment_items_count',
  MissingDestinationName = 'destination_name',
  MissingPhoneNumber = 'phone_number',
  MissingEmail = 'email_address',
  MissingPostalCode = 'postal_code',
  MissingCity = 'city',
  MissingState = 'state',
  MissingAddress = 'address_line_1',
  MissingReceiverTaxId = 'consignee_tax_id',
  OrderNumber = 'order_number',
  ShipmentId = 'easyship_shipment_id',
}

export enum InsuranceScopeValue {
  PremiumInsurance = 'premium_insurance',
  NoInsurance = 'no_insurance',
  CourierInsurance = 'courier_insurance',
}

export enum CollectStateScopeValue {
  None = 'none',
  ToRequest = 'to_be_requested',
  ToCollect = 'to_be_collected',
  Collected = 'collected',
}

export enum IncotermsScopeValue {
  PrePaid = 'ddp',
  PostPaid = 'ddu',
  DoesNotApply = 'no_taxes',
}

export enum NormalizeMultiselectAttr {
  Id = 'id',
  PickupId = 'pickup_id',
  Value = 'value',
  Boolean = 'boolean',
  Date = 'date',
  Number = 'number',
  String = 'string',
  MultiSelect = 'multi_select',
}

export enum FilterOrGroupId {
  MissingFields = 1,
}
