'use strict';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.single', {
      url: '^/basic',
      abstract: true,
      template: '<ui-view/>',
      data: {
        requiredUserRight: 'menu.createShipments.single',
      },
    });
  },
]);
