import { toastError, toastMessage } from '@client/core/components/react/Toastify';
import { IShipmentListItem, IShipmentResource } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';
import { MixpanelService } from '../mixpanel/mixpanel.service';

const EASYSHIP_ID_REGEX = /^ES[A-Z]{2}\d{7,}$/;
const MANAGE_SHIPMENT_SCOPES = ['shipments_all', 'pending', 'rejected', 'to_download'];

class ScanService {
  debouncedBarcode = '';

  static $inject = [
    '$state',
    'Shipment',
    'UserSession',
    '$translate',
    'MixpanelService',
    '$location',
  ];

  constructor(
    private $state: ng.ui.IStateService,
    private Shipment: IShipmentResource,
    private UserSession: IUserSession,
    private $translate: angular.translate.ITranslateService,
    private MixpanelService: MixpanelService,
    private $location: ng.ILocationService
  ) {
    this.processBarCodeScanning = this.processBarCodeScanning.bind(this);
  }

  public processBarCodeScanning = (barCode: string) => {
    if (barCode && EASYSHIP_ID_REGEX.test(barCode)) {
      toastMessage(this.$translate.instant('global.barcode-scanning'));
      this.fetchShipmentByEasyshipId(barCode);
    }
  };

  private async fetchShipmentByEasyshipId(easyshipId: string): Promise<void> {
    try {
      const shipment = await this.getShipmentDetails(easyshipId);
      const isManageShipmentScope = MANAGE_SHIPMENT_SCOPES.some((value) =>
        shipment.dashboard_scopes.includes(value)
      );

      if (
        shipment.dashboard_scopes?.includes('orders_all') &&
        this.UserSession.hasUserRole('create_shipments')
      ) {
        this.$state.go('app.multiple', { shipment_id: shipment.id });
      } else if (isManageShipmentScope && this.UserSession.hasUserRole('manage_shipments')) {
        this.$state.go('app.shipments', { shipment_id: easyshipId }, { reload: true });
      } else {
        toastError(this.$translate.instant('global.page-access-disabled'));
      }

      this.MixpanelService.track('Barcode Scanned', {
        page_name: this.$location.path(),
        label_purchased: !!shipment.label_paid_at,
        easyship_company_id: this.UserSession.company.easyship_company_id,
      });
    } catch (error) {
      toastError(this.$translate.instant('global.shipment-not-found'));
    } finally {
      this.debouncedBarcode = '';
    }
  }

  private async getShipmentDetails(shipmentId: string): Promise<IShipmentListItem> {
    const { shipment } = await this.Shipment.query({
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: shipmentId,
    }).$promise;
    return shipment;
  }
}

export { ScanService };
