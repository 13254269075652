import angular from 'angular';

import '@client/core/directives/ng-submit';
import deviceService from '@client/core/services/device';

import websiteApiService from '@client/core/services/website-api';
import signupLayout from './layout/signup';
import registrationFlowFormLayout from './layout/registration-flow-form';
import globalServices from './services';
import { IndexLayoutComponent } from './layout/index/index.component';
import '@client/app/services/CountryService/CountryService.service';
import '@client/app/factories/HkDistrict/HkDistrict.service';
import '@client/app/factories/Country/Country.service';

const MODULE_NAME = 'auth.global';

angular
  .module(MODULE_NAME, [
    'core.directives.ng-submit',
    'app.service.CountryService',
    'app.factory.Country',
    'app.factory.HkDistrict',
    websiteApiService,
    deviceService,
    signupLayout,
    registrationFlowFormLayout,
    globalServices,
  ])
  .component('indexLayout', IndexLayoutComponent);

export default MODULE_NAME;
