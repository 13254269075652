import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import template from './refund-status-modal-card.html?raw';
import style from './refund-status-modal-card.module.scss';

class RefundStatusModalCard implements IComponentController {
  style = style;
  currency = '';

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  $onInit() {
    this.currency = this.UserSession.getCompanyCurrency();
  }
}

const RefundStatusModalCardComponent: ng.IComponentOptions = {
  controller: RefundStatusModalCard,
  template,
  bindings: {
    esCloseModal: '&',
    esRefundDetails: '<',
    esRedirectToBillings: '&',
  },
};

export { RefundStatusModalCardComponent };
