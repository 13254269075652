import React, { createContext, useContext, useMemo } from 'react';
import { InviteePort } from '../gateways/ports/invitee';
import NgInviteeGateway from '../gateways/api/NgInviteeGateway';
import FakeInviteeGateway from '../gateways/fake/FakeInviteeGateway';

export interface InviteeGatewayContextData {
  inviteeGateway: InviteePort;
}

const InviteeGatewayContext = createContext<InviteeGatewayContextData | undefined>(undefined);

interface InviteeGatewayProviderProps {
  children: React.ReactNode;
}

function InviteeGatewayProvider({ children }: InviteeGatewayProviderProps) {
  const data: InviteeGatewayContextData = useMemo(
    () => ({
      inviteeGateway: new NgInviteeGateway(),
    }),
    []
  );

  return <InviteeGatewayContext.Provider value={data}>{children}</InviteeGatewayContext.Provider>;
}

interface FakeInviteeGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<InviteeGatewayContextData>;
}

function FakeInviteeGatewayProvider({ children, value }: FakeInviteeGatewayProviderProps) {
  const data: InviteeGatewayContextData = useMemo(
    () => ({
      inviteeGateway: new FakeInviteeGateway(),
      ...value,
    }),
    [value]
  );

  return <InviteeGatewayContext.Provider value={data}>{children}</InviteeGatewayContext.Provider>;
}

function useInviteeGateway() {
  const inviteeGatewayContext = useContext(InviteeGatewayContext);

  if (inviteeGatewayContext === undefined) {
    throw new Error('useInviteeStoreGateway must be used within a InviteeGatewayProvider');
  }
  return inviteeGatewayContext;
}

export { InviteeGatewayProvider, FakeInviteeGatewayProvider, useInviteeGateway };
