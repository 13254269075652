const ERROR_CODES = [
  'subscription_access_denied',
  'mark_as_delivered_unexpected_error',
  'access_denied',
  'update_billing_required',
  'too_many_requests',
  'keyword_too_long',
  '3ds_incomplete',
  'cannot_update_both_frequency_and_plan',
  'disallow_upgrade_failed_subscription',
  'password_incorrect',
] as const;

type ErrorCode = (typeof ERROR_CODES)[number];

export function transformToErrorCodes(error: any): ErrorCode[] {
  if (error?.data?.error_code) {
    if (ERROR_CODES.includes(error.data.error_code)) {
      return [error.data.error_code];
    }
    console.error(`[transformToErrorCodes] Unknown error code: ${error.data.error_code}`);
  }

  if (error?.data?.error_codes && Array.isArray(error.data.error_codes)) {
    if (error.data.error_codes.every((errorCode: ErrorCode) => ERROR_CODES.includes(errorCode))) {
      return error.data.error_codes;
    }

    const unknownErrorCodes = error.data.error_codes.filter(
      (errorCode: any) => !ERROR_CODES.includes(errorCode)
    );
    console.error(`[transformToErrorCodes] Unknown error code: ${unknownErrorCodes.join(', ')}`);
  }

  return [];
}
