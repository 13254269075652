import { IInputGroupRadioModel } from 'typings/form';
import { IManifestService } from 'typings/manifest';
import { IUserSession } from 'typings/user-session';
import { ICourierAccountsService } from 'typings/courier';
import { IGroupedArray, IHelperService } from 'typings/helper';
import { IAdvancedSearchManifestObject } from 'typings/advanced-search';

import {
  MultiselectComponentModelValue,
  MultiselectAttributes,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import style from './manifests-multi-select-group.module.scss';
import template from './manifests-multi-select-group.html?raw';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class ManifestsMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchManifestObject>
  implements IComponentController
{
  style = style;
  busy = false;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchManifestObject>[] = [];

  static $inject = [
    '$scope',
    '$translate',
    'Manifest',
    'UserSession',
    'CourierAccounts',
    'HelperService',
  ];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private Manifest: IManifestService,
    private UserSession: IUserSession,
    private CourierAccounts: ICourierAccountsService,
    private HelperService: IHelperService
  ) {
    super($scope);
    this.fetchCompanyManifests = this.fetchCompanyManifests.bind(this);
  }

  $onInit(): void {
    const noun = this.$translate.instant('global.manifest');
    const $t = (key: string) => {
      return this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    };
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];

    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    this.onChangesAssignAndTrigger(
      changes,
      MultiselectAttributes.DisplayText,
      this.fetchCompanyManifests
    );
  }

  private fetchCompanyManifests(): void {
    this.busy = true;
    this.CourierAccounts.getActiveCourierAccounts()
      .then((activeCourierAccounts) => {
        const isLinked = this.UserSession.hasManifest();
        if (activeCourierAccounts && isLinked) {
          this.Manifest.getManifestsFilterData()
            .then((filterData) => {
              let manifests = this.Manifest.buildManifestOptions(filterData, activeCourierAccounts);
              manifests = manifests.map((manifest) => {
                const modifyManifest = manifest;
                modifyManifest.group = MultiselectAttributes.All;
                return modifyManifest;
              });

              this.esModelOptions = this.HelperService.groupArrayByKey(
                manifests,
                MultiselectAttributes.Group,
                {
                  isDisplay: true,
                }
              );

              this.watchAndTrigger(MultiselectAttributes.DisplayText);
            })
            .catch(() => {
              this.errorRequestAction();
            })
            .finally(() => {
              this.busy = false;
            });
        }
      })
      .catch(() => {
        this.busy = false;
        this.errorRequestAction();
      });
  }

  private errorRequestAction(): void {
    toastError(this.$translate.instant('toast.default-error'));
    this.esModelOptions = [];
  }
}

const ManifestsMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: ManifestsMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { ManifestsMultiSelectGroupComponent };
