import { GeolocationData } from '@client/core/corelogic/models/Geolocation';
import { GeolocationPort } from '@client/core/corelogic/ports/geolocation';
import { WebsiteApiService } from '@client/core/services/website-api/website-api.service';
import angular from 'angular';

export default class NgGeolocationGateway implements GeolocationPort {
  private websiteApiService: WebsiteApiService;

  constructor() {
    this.websiteApiService = angular.element(document.body).injector().get('WebsiteApiService');
  }

  getGeolocation(): Promise<GeolocationData> {
    return new Promise((resolve, reject) => {
      this.websiteApiService
        .getIpGeolocation()
        .then(({ data }) =>
          resolve({
            country: data.country,
            countryCode: data.countryCode,
          })
        )
        .catch((reason) => reject(reason));
    });
  }
}
