const AUTH_EVENTS = {
  loginSuccess: 'auth-login-success',
  fullLoginSuccess: 'auth-full-login-success',
  loginFailed: 'auth-login-failed',
  logoutSuccess: 'auth-logout-success',
  sessionTimeout: 'auth-session-timeout',
  notAuthenticated: 'auth-not-authenticated',
  notAuthorized: 'auth-not-authorized',
};

export { AUTH_EVENTS };
