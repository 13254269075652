import { Channel } from 'pusher-js';

import { IUserSession } from 'typings/user-session';
import { Reports } from 'typings/reports';

import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import { showToastWithAction } from '@client/core/components/react/ToastWithAction';
import { Id, toast } from 'react-toastify';
import { PrintPreviewModalProps } from '@client/src/manage-shipments/PrintPreviewModal/PrintPreviewModal';
import template from './printing-options.html?raw';
import style from './printing-options.module.scss';
import { PusherService } from '../../services/pusher/pusher.service';
import { ShipmentListManageService } from '../../services/shipment-list/shipment-list-manage.service';
import { PrintingOptionsService } from '../../services/printing-options/printing-options.service';

class PrintingOptions implements IComponentController {
  style = style;
  channel: Channel | null = null;
  readonly channelName = 'reports-channel-download-report';
  readonly eventName = 'create';
  private documentSuccessPreparedToastId: Id | undefined;

  static $inject = [
    '$window',
    '$translate',
    'UserSession',
    'PusherService',
    'PrintingOptionsService',
    'ShipmentListManageService',
  ];
  constructor(
    private $window: ng.IWindowService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private PusherService: PusherService,
    private PrintingOptionsService: PrintingOptionsService,
    private ShipmentListManageService: ShipmentListManageService
  ) {}

  $onInit(): void {
    this.PrintingOptionsService.getReports(0);

    this.subscribeChannel();
  }

  $onDestroy(): void {
    this.PrintingOptionsService.showDocumentQueue = false;
    this.PusherService.unsubscribeWithCompany(this.channelName);
    toast.dismiss(this.documentSuccessPreparedToastId);
  }

  get showDocumentQueue(): boolean {
    return this.PrintingOptionsService.showDocumentQueue;
  }

  get showPrintPreview(): boolean {
    return this.PrintingOptionsService.showPrintPreview;
  }

  get printPreviewModalProps(): PrintPreviewModalProps | null {
    return this.PrintingOptionsService.printPreviewModalProps;
  }

  get hasPendingReports(): boolean {
    return this.hasReports(['calculating', 'initial', 'pending']);
  }

  get hasCompleteReports(): boolean {
    return this.hasReports(['complete']);
  }

  toggleDocumentQueue(): void {
    this.PrintingOptionsService.showDocumentQueue = !this.PrintingOptionsService.showDocumentQueue;
    toast.dismiss(this.documentSuccessPreparedToastId);
  }

  private hasReports(states: Reports.State[]): boolean {
    return !!this.PrintingOptionsService.reports.find((report) => {
      return states.includes(report.state) && !report.printed_at;
    });
  }

  private subscribeChannel(): void {
    this.channel = this.PusherService.subscribeWithCompany(this.channelName);

    const downloadAndShowNotification = (report: Reports.IReport) => {
      if (report.state === 'failed') {
        toastError(
          this.$translate.instant('shipments.printing-options.document-queue.notification-error')
        );
        return;
      }

      this.documentSuccessPreparedToastId = showToastWithAction(
        this.$translate.instant('shipments.printing-options.document-queue.notification-success'),
        {
          actionLabel: this.$translate.instant('shipments.printing-options.see-documents'),
          onActionLabelClick: () => {
            this.ShipmentListManageService.showFilter = false;
            this.PrintingOptionsService.showDocumentQueue = true;
            this.documentSuccessPreparedToastId = undefined;
          },
          toastId: this.documentSuccessPreparedToastId,
        }
      );

      // Download and mark the report as print
      if (report.zip_url && this.UserSession.company.auto_download_zip) {
        this.$window.location.assign(report.zip_url);

        this.PrintingOptionsService.markAsPrinted({
          printMethod: 'Download',
          reportId: report.id,
        }).then(({ printed_at }) => {
          // eslint-disable-next-line no-param-reassign
          report.printed_at = printed_at;
        });
      }
    };

    const notificationCallback = (data: { report: Reports.IReport }) => {
      if (data && data.report) {
        const report = this.PrintingOptionsService.reports.find((r) => {
          return r.id === data.report.id;
        });

        if (!report) return;

        // Update the state of the report
        Object.assign(report, data.report);

        if (['complete', 'failed'].includes(report.state)) {
          downloadAndShowNotification(report);
        }
      }
    };

    let previousStateCount: number | null = null;

    const unavailableCallback = () => {
      this.PrintingOptionsService.getReports(0).then((reports) => {
        const latestStateCount = reports.filter((report) => {
          return ['complete', 'failed'].includes(report.state);
        }).length;

        if (previousStateCount !== null && previousStateCount < latestStateCount) {
          const report = reports[0];

          downloadAndShowNotification(report);
        }

        previousStateCount = latestStateCount;
      });
    };

    if (this.channel) {
      this.PusherService.bind(
        this.channel,
        this.eventName,
        notificationCallback,
        unavailableCallback
      );
    }
  }
}

const PrintingOptionsComponent: ng.IComponentOptions = {
  controller: PrintingOptions,
  template,
  bindings: {},
};

export { PrintingOptionsComponent };
