import template from './toggle.html?raw';
import style from './toggle.module.scss';

class Toggle {
  static $inject = ['$transclude'];

  constructor($transclude) {
    this.style = style;
    this.$transclude = $transclude;
  }

  $onInit() {
    this._validateBindings();
  }

  hasTransclude(transclude) {
    return this.$transclude.isSlotFilled(transclude);
  }

  onKeyPress($event) {
    const { keyCode } = $event;
    const spaceBar = 32;

    if (keyCode === spaceBar) {
      this.ngModel = !this.ngModel;
      this.onChange();
    }

    // Prevents default scroll down behaviour from spacebar
    $event.preventDefault();
  }

  onChange() {
    this.ngChange({ value: this.ngModel });
  }

  _validateBindings() {
    if (typeof this.esId !== 'string' || !this.esId.length) {
      throw new Error('EasyshipToggle: An es-id binding must be provided');
    }
  }
}

const ToggleComponent = {
  controller: Toggle,
  template,
  transclude: {
    label: '?toggleLabel',
    on: '?toggleOn',
    off: '?toggleOff',
  },
  bindings: {
    esId: '@',
    ngModel: '<',
    ngDisabled: '<',
    esReadonly: '<',
    ngLoading: '<',
    ngChange: '&',
  },
};

export { ToggleComponent };
