import {
  ISubscriptionPaymentSetupIntentsParams,
  ISubscriptionPaymentSetupIntentsPayload,
  ISubscriptionPaymentSetupIntentsResponse,
  ISubscriptionPaymentSetupIntentsConfirmParams,
  ISubscriptionPaymentSetupIntentsConfirmResponse,
} from 'typings/subscription';
import { IApiConfig } from 'typings/core/config/api';

class SubscriptionPaymentResource {
  setupIntentsEndpoint = '';
  setupIntentsConfirmEndpoint = '';

  static $inject = ['$resource', 'API'];
  constructor(private $resource: any, private API: IApiConfig) {
    this.setupIntentsEndpoint = `${this.API.endpoint}/companies/:company_id/setup_intents`;
    this.setupIntentsConfirmEndpoint = `${this.API.endpoint}/companies/:company_id/setup_intents/:setup_intent_id/confirm`;
  }

  setupIntents(
    params: ISubscriptionPaymentSetupIntentsParams,
    payload: ISubscriptionPaymentSetupIntentsPayload
  ): ng.IPromise<
    ISubscriptionPaymentSetupIntentsResponse | ISubscriptionPaymentSetupIntentsConfirmResponse
  > {
    return this.$resource(this.setupIntentsEndpoint, params, {
      create: {
        method: 'POST',
      },
    }).create(payload).$promise;
  }

  setupIntentsConfirm(
    params: ISubscriptionPaymentSetupIntentsConfirmParams
  ): ng.IPromise<ISubscriptionPaymentSetupIntentsConfirmResponse> {
    return this.$resource(this.setupIntentsConfirmEndpoint, params, {
      confirm: {
        method: 'POST',
      },
    }).confirm().$promise;
  }
}

export { SubscriptionPaymentResource };
