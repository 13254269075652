import React from 'react';
import { FormattedMessage } from 'react-intl';

import OptionButton from '@client/core/components/react/OptionButton';

import { ChurnIssue } from './typings';

interface IssuesListProps {
  issues: ChurnIssue[];
  onSelect: (selection: { reason: ChurnIssue; index: number }) => void;
}

export default function IssuesList({ issues, onSelect }: IssuesListProps) {
  return (
    <>
      {issues.map((issue, zeroBasedIndex) => (
        <OptionButton
          key={issue}
          onClick={() => onSelect({ reason: issue, index: zeroBasedIndex + 1 })}
        >
          <FormattedMessage id={`churn-reasons-flow.issues.${issue}`} />
        </OptionButton>
      ))}
    </>
  );
}
