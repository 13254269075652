import { IComponentController } from 'angular';

import { IUserSession } from 'typings/user-session';
import { IUserService } from 'typings/user-service';
import { ICourierAccountsService } from 'typings/courier';

import { AbTestVariation, IABTestCookiePayload } from 'typings/ab-tests';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './go-global-modal.html?raw';
import style from './go-global-modal.module.scss';
import image from './lyoc.svg?svgo';

type ABTestSignupKey = 'new_signup_flow_1' | 'direct_to_quote';
const AB_TEST_SIGNUP_KEY: ABTestSignupKey = 'direct_to_quote';
class GoGlobalModal implements IComponentController {
  translations: angular.translate.ITranslationTable = {};
  style = style;
  image = image;
  country: string;
  seenOnboarding: boolean | undefined;
  isModalOpened = false;

  static $inject = [
    '$translate',
    '$state',
    'CourierAccounts',
    'UserSession',
    'UserService',
    'MixpanelService',
    '$cookies',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private $state: ng.ui.IStateService,
    private CourierAccounts: ICourierAccountsService,
    private UserSession: IUserSession,
    private UserService: IUserService,
    private MixpanelService: MixpanelService,
    private $cookies: angular.cookies.ICookiesService
  ) {
    this.country = this.UserSession.getCompanyCountryName() || 'your country';
    this.seenOnboarding =
      this.UserSession.user.display_settings?.display_onboarding ||
      this.UserSession.user.display_settings?.desktop_orientation;
  }

  async $onInit(): Promise<void> {
    this.$translate(['welcome']).then((translations: angular.translate.ITranslationTable) => {
      this.translations = translations;
    });

    const { easyship_courier_accounts: easyshipCourierAccounts } =
      await this.CourierAccounts.getActiveCourierAccounts();

    this.isModalOpened =
      !this.seenOnboarding &&
      easyshipCourierAccounts.length === 0 &&
      this.UserSession.hasUserRole('account_and_settings');

    if (this.isModalOpened) {
      this.MixpanelService.track('Welcome Actions - See Go Global');
      this.UserService.update({
        display_settings: { desktop_orientation: true },
      });

      this.seenOnboarding = true;
    }
  }

  closeModal(): void {
    this.isModalOpened = false;
  }

  goToCourierPage(): void {
    this.MixpanelService.track('Welcome Actions - Click Connect Courier Global');
    this.$state.go('app.couriers', { new: true });
  }

  getABTestsCookie() {
    return this.$cookies.getObject<IABTestCookiePayload | undefined>('AB_TESTS');
  }

  get signupVariation(): AbTestVariation | undefined {
    switch (AB_TEST_SIGNUP_KEY) {
      case 'new_signup_flow_1':
        return this.getABTestsCookie()?.new_signup_flow_1;
      case 'direct_to_quote':
        return this.getABTestsCookie()?.direct_to_quote;
      default:
        break;
    }
    return undefined;
  }
}

const GoGlobalModalComponent: ng.IComponentOptions = {
  controller: GoGlobalModal,
  template,
  bindings: {},
};

export { GoGlobalModalComponent };
