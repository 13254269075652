import template from './input.html?raw';
import style from './input.module.scss';

class Input {
  static $inject = ['$timeout', '$element', 'awnumFilter'];

  constructor($timeout, $element, awnumFilter) {
    this.style = style;
    this.$timeout = $timeout;
    this.$element = $element;
    this.awnumFilter = awnumFilter;
  }

  $onInit() {
    const { esInitValidation, esError } = this;

    this._validateBinding({ esNumberValidation: this.esNumberValidation });
    if (this.esType) this._setInputType();

    // validate ngModel when it is set programmatically
    // digest cycle is triggered so that the form directive is updated with validated on init
    if (esInitValidation) {
      this.$timeout(() => {
        this.onInputChange();
      });
    }

    // manually set input field as invalid when the ngModel is programmatically retrieved and validation fails from backend
    if (esInitValidation && esError) {
      this.$ngModelController.$setValidity('ngBackend', false);
      this.$ngModelController.$setDirty();
    }

    this._setAutocompleteAttribute();
  }

  $onChanges(changesObj) {
    const { ngModel, esNumberValidation } = changesObj;

    if (
      esNumberValidation &&
      esNumberValidation.currentValue &&
      !esNumberValidation.isFirstChange()
    ) {
      this._validateBinding({ esNumberValidation: esNumberValidation.currentValue });
    }

    if (this.esNumberValidation && ngModel && ngModel.currentValue && !ngModel.isFirstChange()) {
      const { viewValue } = this._formatNumber(ngModel.currentValue);

      if (this.ngModel !== viewValue) {
        this.ngModel = viewValue;
      }
    }

    this._validateNumber(this.ngModel);
    this._validateLength(this.ngModel);
    this._validateExpression(this.ngModel);

    this.esOnValidityChange({
      valid: this.$ngModelController.$valid,
    });

    // If an error is passed through the backend on initial ngModel retrieval, remove the error when user begins typing
    if (this.esInitValidation && this.esError && ngModel && !ngModel.isFirstChange()) {
      this.$ngModelController.$setValidity('ngBackend', true);

      this.esOnValidityChange({
        valid: this.$ngModelController.$valid,
      });
    }

    // set allowInvalid to be true as we want the modelValue even when it's invalid to be able to show error states properly
    this.ngModelOptions = this.ngModelOptions
      ? this.ngModelOptions
      : {
          updateOn: 'default blur',
          allowInvalid: true,
          debounce: { default: 250, blur: 0 },
        };
  }

  $postLink() {
    // Inject novalidate to form dynamically
    const $form = this.$element.closest('form');

    if ($form && !$form.attr('novalidate') && this.hasFormNoValidateProperties()) {
      this.$element.closest('form').attr('novalidate', true);
    }
  }

  hasFormNoValidateProperties() {
    return this.esMin || this.esMax || this.ngDecimals;
  }

  hasLabel() {
    return !!angular.element('ng-transclude', this.$element).text().trim();
  }

  onInputChange() {
    let value = this.ngModel;

    if (this.esNumberValidation && this.ngModel) {
      const { modelValue, viewValue } = this._formatNumber(this.ngModel);

      value = modelValue;
      this.ngModel = viewValue;
    }

    this.ngChange({ value });

    this.$ngModelController.$setDirty();
  }

  // Set input type through jQuery to prevent angular from setting its own built in validators onto the input component on compile time. This is to preserve autofill settings (all) and keyboard accessibility (mobile only)
  _setInputType() {
    this.$element.find('input').attr('type', this.esType);
  }

  _validateBinding({ esNumberValidation = {} }) {
    const {
      maxDigits,
      maxDecimals,
      allowNegative,
      allowFloat,
      showThousandSeparator,
      prepend,
      append,
    } = esNumberValidation;

    const { ngMinLength, ngMaxLength, esValidate } = this;

    if (typeof ngMinLength !== 'undefined' && typeof parseFloat(ngMinLength) !== 'number') {
      throw new Error(`EasyshipInput: Only numbers are accepted for 'ngMinLength'`);
    }

    if (typeof ngMaxLength !== 'undefined' && typeof parseFloat(ngMaxLength) !== 'number') {
      throw new Error(`EasyshipInput: Only numbers are accepted for 'ngMaxLength'`);
    }

    if (typeof esValidate !== 'undefined' && esValidate !== 'url') {
      throw new Error(`EasyshipInput: Only 'url' is available for validation`);
    }

    if (typeof maxDigits !== 'undefined' && typeof maxDigits !== 'number') {
      throw new Error(`EasyshipInput: Only integers are accepted for 'maxDigits'`);
    }

    if (typeof maxDecimals !== 'undefined' && typeof maxDecimals !== 'number') {
      throw new Error(`EasyshipInput: Only integers are accepted for 'maxDecimals'`);
    }

    if (typeof allowNegative !== 'undefined' && typeof allowNegative !== 'boolean') {
      throw new Error(`EasyshipInput: Only booleans are accepted for 'allowNegative'`);
    }

    if (typeof allowFloat !== 'undefined' && typeof allowFloat !== 'boolean') {
      throw new Error(`EasyshipInput: Only booleans are accepted for 'allowFloat'`);
    }

    if (
      typeof showThousandSeparator !== 'undefined' &&
      typeof showThousandSeparator !== 'boolean'
    ) {
      throw new Error(`EasyshipInput: Only booleans are accepted for 'showThousandSeparator'`);
    }

    if (typeof prepend !== 'undefined' && typeof prepend !== 'string') {
      throw new Error(`EasyshipInput: Only integers are accepted for 'prepend'`);
    }

    if (typeof append !== 'undefined' && typeof append !== 'string') {
      throw new Error(`EasyshipInput: Only integers are accepted for 'append'`);
    }
  }

  _formatNumber(value) {
    const { maxDigits, maxDecimals, allowNegative, allowFloat, showThousandSeparator } =
      this.esNumberValidation;

    /**
     * Angular Dynamic Number 1.9.4 parameters:
     * https://github.com/uhlryk/angular-dynamic-number/blob/v1.9.4/README.md#filter-options
     *
     * value, numFract, numSep, numRound, numFixed, numThousand, numThousandSep, numPrepend, numAppend
     */

    const fixedDecimals = typeof maxDecimals === 'number';
    const modelValue = this.awnumFilter(
      this._numberFilter({ value, maxDigits, allowNegative, allowFloat }),
      maxDecimals,
      null,
      null,
      fixedDecimals
    );

    const viewValue = this.awnumFilter(
      modelValue,
      maxDecimals,
      null,
      null,
      fixedDecimals,
      showThousandSeparator,
      null,
      null,
      null
    );

    return { modelValue, viewValue };
  }

  onInputKeydown($event) {
    this.esOnKeydown({ $event });
  }

  _validateNumber(value) {
    const intValue = parseFloat(value);
    const isNan = Number.isNaN(intValue);

    if (this.esMin !== undefined) {
      const validity = isNan || this.esMin === '' || parseFloat(this.esMin) <= intValue;

      this.$ngModelController.$setValidity('ngMin', validity);
    }

    if (this.esMax !== undefined) {
      const validity = isNan || this.esMax === '' || parseFloat(this.esMax) >= intValue;

      this.$ngModelController.$setValidity('ngMax', validity);
    }

    if (this.ngDecimals !== undefined) {
      const validity =
        isNan || this.ngDecimals === '' || this._getDecimalPlaces(intValue) <= this.ngDecimals;

      this.$ngModelController.$setValidity('ngDecimals', validity);
    }
  }

  _getDecimalPlaces(value) {
    if (Math.floor(value) === value || Number.isNaN(value)) return 0;

    return value.toString().split('.')[1].length || 0;
  }

  _validateLength(value = '') {
    if (value === null) return;

    if (this.ngMinLength) {
      const isValid = value.length >= parseFloat(this.ngMinLength);

      this.$ngModelController.$setValidity('ngMinLength', isValid);

      this.lengthValidationMessage =
        value.length > 0 && this.$ngModelController.$invalid && !this.ngMaxLength
          ? `Min. ${this.ngMinLength} characters`
          : null;
    }

    if (this.ngMaxLength) {
      const isValid = value.length <= parseFloat(this.ngMaxLength);

      this.$ngModelController.$setValidity('ngMaxLength', isValid);
      this.lengthValidationMessage =
        value.length > 0 && this.$ngModelController.$invalid
          ? this._buildLengthValidationMessage(value)
          : null;
    }
  }

  _buildLengthValidationMessage({ length }) {
    let dynamicNumber = length;

    if (this.ngMinLength) {
      dynamicNumber = length < this.ngMinLength ? this.ngMinLength : length;
    }

    return `${dynamicNumber} / ${this.ngMaxLength}`;
  }

  _numberFilter({ value = '', maxDigits, allowNegative, allowFloat }) {
    let numberValue = this._allowNegative(value, allowNegative);
    numberValue = this._allowFloat(numberValue, allowFloat);

    return this._maxDigits(numberValue, maxDigits);
  }

  _maxDigits(value, max) {
    const [digits, decimals] = typeof value === 'string' ? value.split('.') : [];

    if (!digits || typeof max !== 'number') {
      return value;
    }

    const sliceDigit = digits.slice(-max);

    if (decimals) {
      return `${sliceDigit}.${decimals}`;
    }

    return sliceDigit;
  }

  _allowNegative(value, allowNegative) {
    if (allowNegative === false && typeof value === 'string') {
      return value.replace(/^-/, '');
    }

    return value;
  }

  _allowFloat(value, allowFloat) {
    if (allowFloat === false && typeof value === 'string') {
      return value.replace(/\./g, '');
    }

    return value;
  }

  // Manually set validation expression keys as these validations are not from html5. We validate it ourselves instead of relying on html5 so that we can use custom styling to unify all the browsers. Otherwise Chrome, for instance, will display an ugly bubble.
  _validateExpression(value) {
    // disabled input fields should never be validated
    if (this.esValidation && this.ngDisabled) {
      return this._setValidationKeyToValid();
    }

    // validations should pass when input field is empty and user has chosen to not fill in an optional input field
    if (this.esValidation && !value && !this.$ngModelController.$$attr.required) {
      return this._setValidationKeyToValid();
    }

    if (this.esValidation === 'url') {
      const urlRegex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

      const isValid = urlRegex.test(value);

      this.$ngModelController.$setValidity('ngUrl', isValid);
    }

    // Check if email is valid and do not accept .con tld
    if (this.esValidation === 'email') {
      const emailRegex = /^\S+@\S+\.\S+$/;
      const isValid = emailRegex.test(value) && !(value.substr(value.length - 3) === 'con');

      this.$ngModelController.$setValidity('ngEmail', isValid);
    }

    if (this.esValidation === 'eori') {
      const eoriFormatOne = /^(GB|DE)(\d{12}|\d{15})$/i; // Starts with GB, then  12or15 digits etc GB987654321012 GB987654321012345
      const eoriFormatTwo = /^NL\d{9}$/i; // Starts with NL, then 9 digits ect.NL123456789
      const eoriFormatThree = /^FI\d{7}-[a-zA-Z0-9]{1}$/i; // Starting with FI, then 7 digits, then "-", then 1 digit of number/character etc.FI2711521-K FI2711521-3
      const eoriFormatForItaly = /^IT\d{11}$/i; // Starting with IT, then 11 digits etc.IT12345678901

      const isValid =
        eoriFormatOne.test(value) ||
        eoriFormatTwo.test(value) ||
        eoriFormatThree.test(value) ||
        eoriFormatForItaly.test(value);

      this.$ngModelController.$setValidity('ngEori', isValid);
    }

    if (this.esValidation === 'tel') {
      const telRegex = /^(\+?[\d\s\-\.\(\)#]|(ext))*$/;

      const isValid = telRegex.test(value);

      this.$ngModelController.$setValidity('ngTel', isValid);
    }

    if (this.esValidation === 'regex' && this.esRegexValidationPatterns) {
      if (this.esRegexValidationPatterns.length === 0) {
        return this.$ngModelController.$setValidity('ngRegex', true);
      }

      let isValid = false;

      if (value) {
        isValid = this.esRegexValidationPatterns.some((regex) => {
          return !!value.match(regex);
        });
      }

      this.$ngModelController.$setValidity('ngRegex', isValid);
    }
  }

  _setValidationKeyToValid() {
    const validationKey = `ng${this.esValidation.replace(/^\w/, (firstCharacter) =>
      firstCharacter.toUpperCase()
    )}`;
    return this.$ngModelController.$setValidity(validationKey, true);
  }

  _setAutocompleteAttribute() {
    if (this.esType === 'password' && !this.esAutocomplete) {
      this.esAutocomplete = 'off';
    }
  }
}

const InputComponent = {
  controller: Input,
  template,
  require: {
    $ngModelController: 'ngModel',
  },
  transclude: true,
  bindings: {
    esId: '@',
    esType: '@',
    esPlaceholder: '@',
    esMaxlength: '@',
    esHelp: '@',
    esAutocomplete: '@',
    esNumberValidation: '<',
    esValidation: '@',
    esRegexValidationPatterns: '<',
    esInitValidation: '<',
    esOnValidityChange: '&',
    esReadOnly: '<',
    esError: '<',
    esPrepend: '<',
    esAppend: '<',
    esColorDisplay: '<',
    ngModel: '<',
    ngModelOptions: '<',
    ngChange: '&',
    ngFocus: '&',
    ngBlur: '&',
    ngDisabled: '<',
    esMin: '@',
    esMax: '@',
    ngMinLength: '@',
    ngMaxLength: '@',
    ngDecimals: '@',
    esTooltipOptions: '<',
    esAlign: '@',
    ngRequired: '<',
    esOnKeydown: '&',
    esWarning: '<',
    esPrependIcon: '@',
    esPrependIconImg: '@',
    esPrependTooltip: '@',
    esLearnMoreLink: '@',
    esTooltipWidth: '@',
  },
};

export { InputComponent };
