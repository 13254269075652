import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import template from './default-weight-input.html?raw';
import style from './default-weight-input.module.scss';

class DefaultWeightInput implements IComponentController {
  style = style;

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  get weightUnit(): string {
    return this.UserSession.company.weight_unit || '';
  }
}

const DefaultWeightInputComponent: ng.IComponentOptions = {
  controller: DefaultWeightInput,
  template,
  bindings: {
    esModel: '<',
    esChange: '&',
    esRequired: '<',
  },
};

export { DefaultWeightInputComponent };
