(function () {
  'use strict';

  PlatformApi.$inject = ['$resource', 'API'];
  function PlatformApi($resource, API) {
    var endpointUrl = API.endpoint;

    return {
      amazon: $resource(
        endpointUrl + '/companies/:company_id/amazon/stores/:controller',
        { company_id: '@_company_id' },
        {
          authenticate: {
            method: 'POST',
            params: { controller: 'oauth_link' },
          },
          connect: {
            method: 'POST',
          },
        }
      ),
      buyOnGoogle: $resource(
        endpointUrl + '/companies/:company_id/buy_on_google/stores/:controller',
        { company_id: '@_company_id' },
        {
          authenticate: {
            method: 'POST',
            params: { controller: 'oauth_link' },
          },
          connect: {
            method: 'POST',
          },
        }
      ),

      channeladvisor: $resource(endpointUrl + '/companies/:company_id/stores/channeladvisor', {
        company_id: '@_company_id',
      }),

      dear: $resource(endpointUrl + '/companies/:company_id/dear_inventory/stores', {
        company_id: '@_company_id',
      }),

      ebay: $resource(
        endpointUrl + '/companies/:company_id/stores/ebay/:controller',
        {
          company_id: '@_company_id',
        },
        {
          userToken: {
            method: 'POST',
            params: {
              controller: 'user_token',
            },
          },
        }
      ),

      etsy: $resource(
        endpointUrl + '/companies/:company_id/etsy_group/stores/:controller',
        { company_id: '@_company_id' },
        {
          authenticate: {
            method: 'POST',
            params: { controller: 'oauth_link' },
          },
          connect: {
            method: 'POST',
          },
        }
      ),

      lazada: $resource(endpointUrl + '/companies/:company_id/stores/lazada', {
        company_id: '@_company_id',
      }),

      onbuy: $resource(endpointUrl + '/companies/:company_id/on_buy/stores', {
        company_id: '@_company_id',
      }),

      shopify: $resource(
        endpointUrl + '/companies/:company_id/stores/shopify/:controller',
        {},
        {
          getAPIKey: {
            method: 'POST',
            params: {
              controller: 'get_shopify_key',
            },
          },
          createAccessToken: {
            method: 'POST',
            params: {
              controller: 'get_access_token',
            },
          },
          createCarrierService: {
            method: 'POST',
            params: {
              controller: 'create_carrier_service',
            },
          },
          deleteCarrierService: {
            method: 'DELETE',
            params: {
              controller: 'delete_carrier_service',
            },
          },
        }
      ),

      shopline: $resource(
        endpointUrl + '/companies/:company_id/stores/shopline/:controller',
        { company_id: '@_company_id' },
        {
          authenticate: {
            method: 'POST',
            params: { controller: 'oauth_link' },
          },
          createCarrierService: {
            method: 'POST',
            params: {
              controller: 'create_carrier_service',
            },
          },
          deleteCarrierService: {
            method: 'DELETE',
            params: {
              controller: 'delete_carrier_service',
            },
          },
        }
      ),

      square: $resource(
        endpointUrl + '/companies/:company_id/square/stores/:controller',
        { company_id: '@_company_id' },
        {
          authenticate: {
            method: 'POST',
            params: { controller: 'oauth_link' },
          },
          connect: {
            method: 'POST',
          },
        }
      ),

      tiktok: $resource(
        endpointUrl + '/companies/:company_id/tiktok/stores/:controller',
        { company_id: '@_company_id' },
        {
          authenticate: {
            method: 'POST',
            params: { controller: 'oauth_link' },
          },
          connect: {
            method: 'POST',
          },
        }
      ),

      tradegecko: $resource(endpointUrl + '/companies/:company_id/stores/tradegecko', {
        company_id: '@_company_id',
      }),

      walmart: $resource(endpointUrl + '/companies/:company_id/walmart/stores', {
        company_id: '@_company_id',
      }),

      wix: $resource(
        endpointUrl + '/companies/:company_id/wix/stores/:controller',
        {
          company_id: '@_company_id',
        },
        {
          authenticate: {
            method: 'POST',
            params: { controller: 'oauth_link' },
          },
          connect: {
            method: 'POST',
          },
        }
      ),

      woocommerce: $resource(
        endpointUrl + '/companies/:company_id/stores/woocommerce/get_woocommerce_url',
        {
          company_id: '@_company_id',
        }
      ),
    };
  }

  angular.module('app.factory.PlatformApi', []).factory('PlatformApi', PlatformApi);
})();
