import template from './incart-installation-modal.html?raw';

(function () {
  'use strict';

  incartInstallationModal.$inject = ['$uibModal'];
  function incartInstallationModal($uibModal) {
    var service = this;

    service.open = function (params) {
      $uibModal.open({
        template,
        controller: 'incartInstallationModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        openedClass: 'easyship-modal-default',
        resolve: {
          params: function () {
            return params;
          },
        },
      });
    };
  }

  angular
    .module('app.service.incart-installation-modal')
    .service('incartInstallationModal', incartInstallationModal);
})();
