import angular from 'angular';

import { AngularAdjustmentsModal } from './AdjustmentsModal';
import { AdjustmentsModalComponent } from './adjustments-modal.component';

const MODULE_NAME = 'app.global.components.adjustments-modal';

angular
  .module(MODULE_NAME, [])
  .component('easyshipAdjustmentsModal', AngularAdjustmentsModal)
  .component('esAdjustmentsModal', AdjustmentsModalComponent);

export default MODULE_NAME;
