import angular from 'angular';
// eslint-disable-next-line import/extensions
import { AngularConnectCourierFormCanadaPost } from './index.tsx';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier.form.canada-post';

angular
  .module(MODULE_NAME, [])
  .component('esConnectCourierFormCanadaPost', AngularConnectCourierFormCanadaPost);

export default MODULE_NAME;
