/* eslint-disable max-classes-per-file */
import template from './checkout-modal.html?raw';

class CheckoutModalController {
  static $inject = ['CheckoutModalService', 'step', 'form'];

  constructor(CheckoutModalService, step, form) {
    this.CheckoutModalService = CheckoutModalService;
    this.step = step;
    this.form = form;
  }

  close() {
    this.CheckoutModalService.close();
  }
}

class CheckoutModalService {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(step, form) {
    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: CheckoutModalController,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        step: () => step,
        form: () => form,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { CheckoutModalService };
