(function () {
  'use strict';
  /**
   * formInitDirty sets the form and all his fields as $touched and $dirty when the html is loaded
   * Usage: <form name="myForm" form-init-dirty="[expression]">
   *        [expression] will be coerced in a boolean (line 26)
   */
  angular.module('easyshipDashboardApp').directive('formInitDirty', formInitDirty);

  function formInitDirty() {
    var directive = {
      require: '^form',
      restrict: 'A',
      link: link,
      scope: {
        formInitDirty: '=',
      },
    };

    return directive;

    function link(scope, element, attr, FormCtrl) {
      if (scope.formInitDirty.activate) {
        // Match using '$' to ignore any properties in the form Object that begin with '$'
        // This should return only the form fields
        angular.forEach(Object.keys(FormCtrl), function (key) {
          if (!key.match(/\$/)) {
            FormCtrl[key].$setDirty();
            FormCtrl[key].$setTouched();
          }
        });

        FormCtrl.$setDirty();
      }
    }
  }
})();
