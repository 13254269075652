import angular from 'angular';

import esShipmentStatusCell from './status';

import './action';
import './cost';
import './courier';
import './delivery';
import './handover';
import './id';
import './taxes';
import './tracking';
import './tracking-quality';
import './courier-quality';

angular.module('app.global.shipment-cells', [
  esShipmentStatusCell,
  'app.global.shipment-cells.action',
  'app.global.shipment-cells.cost',
  'app.global.shipment-cells.courier',
  'app.global.shipment-cells.delivery',
  'app.global.shipment-cells.handover',
  'app.global.shipment-cells.id',
  'app.global.shipment-cells.taxes',
  'app.global.shipment-cells.tracking',
  'app.global.shipment-cells.tracking-quality',
  'app.global.shipment-cells.courier-quality',
]);
