(function () {
  'use strict';

  angular
    .module('easyshipDashboardApp')
    .controller('DashboardHeaderRequestPickupCtrl', DashboardHeaderRequestPickupCtrl);

  DashboardHeaderRequestPickupCtrl.$inject = ['$scope', '$state', '$timeout'];
  function DashboardHeaderRequestPickupCtrl($scope, $state, $timeout) {
    var shipmentId = $state.params.shipment_id;

    $scope.backToOrders = function () {
      $timeout(function () {
        $state.go('app.multiple', {
          page: $scope.ShipmentListAdvancedService.pageNum,
          from_request_pickup: true,
          shipment_id: shipmentId,
        });
      });
    };
  }
})();
