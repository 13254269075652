import angular from 'angular';
import ngResource from 'angular-resource';

import '@client/core/config';

import { StatusMessage } from './StatusMessage.resource';
import { StatusMessageService } from './StatusMessage.service';

angular
  .module('app.global.status-message', [
    ngResource,
    'core.config',
    'app.factory.UserSession',
    'app.global.helper',
  ])
  .service('StatusMessageService', StatusMessageService)
  .factory('StatusMessage', StatusMessage);
