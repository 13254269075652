import template from './shipment-delivery-cell.html?raw';
import style from './shipment-delivery-cell.module.scss';

class ShipmentDeliveryCell {
  constructor() {
    this.style = style;
  }

  $onInit() {
    this.isValid =
      Number.isFinite(this.minDelivery) &&
      Number.isFinite(this.maxDelivery) &&
      this.minDelivery > 0 &&
      this.maxDelivery > 0;
  }
}

const ShipmentDeliveryCellComponent = {
  controller: ShipmentDeliveryCell,
  template,
  bindings: {
    minDelivery: '<',
    maxDelivery: '<',
  },
};

export { ShipmentDeliveryCellComponent };
