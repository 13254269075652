import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from './Tooltip';

interface StatProps {
  label: string | JSX.Element;
  horizontal?: boolean;
  noEllipsis?: boolean;
  noTypography?: boolean;
  children?: React.ReactNode;
  fixedWidth?: boolean;
}

export default function Stat({
  label,
  horizontal,
  noEllipsis,
  noTypography,
  children,
  fixedWidth,
}: StatProps) {
  const [valueEl, setValueEl] = useState<HTMLElement>();
  const hasEllipsis = !!valueEl && valueEl.offsetWidth < valueEl.scrollWidth;
  const isReactComponent = React.isValidElement(children);
  const content = (
    <Typography color="textPrimary" component="span" style={{ lineHeight: 1 }}>
      {children}
    </Typography>
  );

  return (
    <Grid
      container
      direction={horizontal ? 'row' : 'column'}
      alignItems={horizontal ? 'center' : 'flex-start'}
    >
      <Box pb={horizontal ? 0 : 1} pr={horizontal ? 1 : 0} clone>
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          style={{ lineHeight: 1, width: horizontal && fixedWidth ? 140 : 'auto' }}
        >
          {label}
        </Typography>
      </Box>
      {isReactComponent || noEllipsis ? (
        noTypography ? (
          children
        ) : (
          content
        )
      ) : (
        <Tooltip title={children ?? ''} active={hasEllipsis}>
          <Box {...{ ref: (el: HTMLElement) => setValueEl(el) }} className='truncate w-full' >
            {content}
          </Box>
        </Tooltip>
      )}
    </Grid>
  );
}
