import angular from 'angular';

import subscriptionService from '@client/src/global/services/subscription';

import { CourierAccounts } from './courier-accounts.service.js';
import { CourierAccountsResource } from './courier-accounts.resource.js';

const MODULE_NAME = 'app.global.courier-accounts';

angular
  .module(MODULE_NAME, [
    'core.config',
    'app.factory.UserSession',
    'app.global.dashboard-component-library',
    'ngResource',
    subscriptionService,
  ])
  .service('CourierAccounts', CourierAccounts)
  .service('CourierAccountsResource', CourierAccountsResource);

export default MODULE_NAME;
