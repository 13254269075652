import { IOnboardingParams, IOnboardingResponse } from 'typings/onboarding';
import { OnboardingResource } from './onboarding.resource';

class OnboardingService {
  static $inject = ['OnboardingResource'];
  constructor(private OnboardingResource: OnboardingResource) {}

  update(payload: IOnboardingParams): Promise<IOnboardingResponse> {
    return this.OnboardingResource.update(payload);
  }
}

export { OnboardingService };
