/* eslint-disable no-console */
import { Dict } from 'mixpanel-browser';

export default {
  trackPageView: (properties?: Dict) => console.debug('[Mixpanel Event] Page Viewed', properties),
  track: (eventName: string, properties?: Dict) =>
    console.debug('[Mixpanel Event]', eventName, properties),
  identify: (uniqueId: string) => console.debug('[Mixpanel Identifier]', uniqueId),
  people: {
    set: (prop: Dict) => console.debug('[Mixpanel Identity Update]', prop),
  },
  alias: (alias: string) => console.debug('[Mixpanel Alias]', alias),
  reset: () => console.debug('[Mixpanel Reset]'),
};
