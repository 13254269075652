import template from './edit-courier.html?raw';

(function () {
  'use strict';

  editShipmentCourier.$inject = ['$uibModal'];
  function editShipmentCourier($uibModal) {
    var service = this;

    service.open = function (params) {
      var modalInstance = $uibModal.open({
        template,
        controller: 'editShipmentCourierCtrl',
        size: 'lg',
        openedClass: 'easyship-modal-auto-width',
        resolve: {
          params: function () {
            return params;
          },
        },
      });
    };
  }

  angular.module('app.service.edit-courier').service('editShipmentCourier', editShipmentCourier);
})();
