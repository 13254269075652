import { ShipmentTax } from '@client/core/corelogic/models/Shipment';
import { useMemo } from 'react';

interface UseTaxProps {
  tax: ShipmentTax;
}

export default function useTax({ tax }: UseTaxProps) {
  const { sales, salesTaxName, provincialSales, provincialSalesTaxName } = tax;

  const taxName = useMemo(() => {
    const taxList = [];

    if (salesTaxName && sales) {
      taxList.push(salesTaxName);
    }

    if (provincialSalesTaxName && provincialSales) {
      taxList.push(provincialSalesTaxName);
    }

    return taxList.join(' & ');
  }, [provincialSales, provincialSalesTaxName, sales, salesTaxName]);

  return {
    taxName,
  };
}
