import angular from 'angular';

import { DeleteProductModalComponent } from './delete-product-modal.component';

const MODULE_NAME = 'app.product-listing.delete-product-modal';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingDeleteProductModal', DeleteProductModalComponent);

export default MODULE_NAME;
