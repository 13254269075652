import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';
import { PartialDeep } from 'type-fest';
import { IUser } from 'typings/user-session';

export default function useUpdateUserMutation() {
  const { userGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: PartialDeep<IUser>) => userGateway.updateUser(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['companyUsers'],
      });
    },
  });
}
