import {
  IShipmentListScope,
  IShipmentListCustomViewsPayload,
  IShipmentListCustomViewsItem,
  SortDirection,
  SortByAttribute,
} from 'typings/shipment';
import { IUserService } from 'typings/user-service';
import { IUserSession } from 'typings/user-session';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';
import { IShipmentListFiltersData } from '@client/src/global/services/shipment-list/shipment-list-normalize.service';

import { IComponentController } from 'angular';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { ShipmentViewsService } from '@client/src/global/services/shipment-views/shipment-views.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './custom-view-modal.html?raw';
import style from './custom-view-modal.module.scss';

class CustomViewModal implements IComponentController {
  style = style;
  busy = false;
  viewName: string | null = null;
  isDefault = false;
  esPageContext: IShipmentListScope | null = null;
  esModifyView: IShipmentListCustomViewsItem | null = null;
  esSelectedView: IShipmentListCustomViewsItem | null = null;

  static $inject = [
    '$translate',
    'ShipmentViewsService',
    'ShipmentListAdvancedService',
    'ShipmentListManageService',
    'UserService',
    'UserSession',
    '$timeout',
    '$element',
    'UserRightsService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private ShipmentViewsService: ShipmentViewsService,
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private ShipmentListManageService: ShipmentListManageService,
    private UserService: IUserService,
    private UserSession: IUserSession,
    private $timeout: ng.ITimeoutService,
    private $element: ng.IAugmentedJQuery,
    private UserRightsService: UserRightsService
  ) {}

  esOnSaveSuccess(_data?: { view: IShipmentListCustomViewsItem }): void {
    // esOnSaveSuccess expression bindings, need to add this in order for typescript to successfully compile
  }

  $onInit(): void {
    if (this.esModifyView) {
      this.viewName = this.esModifyView.name;
      this.isDefault =
        this.UserSession.getDefaultViews()[this.esPageContext as IShipmentListScope] ===
        this.esModifyView.id;
    }

    this.$timeout(() => {
      this.$element.find('#custom-view-name').focus();
    });
  }

  onInputNameChange(value: string): void {
    this.viewName = value;
  }

  onDefaultViewChange(value: boolean): void {
    this.isDefault = value;
  }

  save(form: ng.IFormController): void {
    if (form.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    if (this.viewName) {
      this.busy = true;
      let limitShipment = 10;
      let filterShipment = null;
      let keyword = null;
      let sortBy: SortByAttribute | null = null;
      let sortDirection: SortDirection | null = null;

      if (this.esPageContext) {
        if (this.esPageContext === 'orders_all') {
          limitShipment = this.ShipmentListAdvancedService.limit;
          filterShipment = this.ShipmentListAdvancedService.filter;
          keyword = this.ShipmentListAdvancedService.keyword;
          sortBy = this.ShipmentListAdvancedService.sortBy;
          sortDirection = this.ShipmentListAdvancedService.sortDirection;
        } else if (['shipments_all', 'returns'].includes(this.esPageContext)) {
          limitShipment = this.ShipmentListManageService.limit;
          filterShipment = this.ShipmentListManageService.filter;
          keyword = this.ShipmentListManageService.keyword;
          sortBy = this.ShipmentListManageService.sortBy;
          sortDirection = this.ShipmentListManageService.sortDirection;
        }

        if (this.esModifyView) {
          const payload: IShipmentListCustomViewsPayload = this.getViewPayload(
            this.viewName,
            this.esPageContext,
            limitShipment,
            filterShipment,
            keyword,
            sortBy,
            sortDirection,
            this.esModifyView
          );
          this.updateView(this.esModifyView.id, payload);
        } else {
          const payload: IShipmentListCustomViewsPayload = this.getViewPayload(
            this.viewName,
            this.esPageContext,
            limitShipment,
            filterShipment,
            keyword,
            sortBy,
            sortDirection
          );
          this.createView(payload);
        }
      }
    }
  }

  get isSaveButtonDisabled(): boolean {
    return (
      this.busy ||
      !this.UserRightsService.canSaveFilterView ||
      !this.UserRightsService.canUpdateFilterView
    );
  }

  private getViewPayload(
    viewName: string,
    context: IShipmentListScope,
    limit: number,
    filter: IShipmentListFiltersData | null,
    keyword: string | null,
    sortBy: SortByAttribute | null,
    sortDirection: SortDirection | null,
    modifyView?: IShipmentListCustomViewsItem
  ): IShipmentListCustomViewsPayload {
    const payload: IShipmentListCustomViewsPayload = {
      name: viewName,
      page_context: context,
      items_per_page: modifyView ? modifyView.items_per_page : limit,
      filters: filter,
      keyword,
    };

    if (sortBy) {
      let direction: SortDirection = 'desc';
      if (this.ShipmentListAdvancedService.isReverseSortOrder(sortBy)) {
        direction = 'asc';
      }
      payload.sort_criterias = {
        [sortBy]: sortDirection || direction,
      };
    }

    return payload;
  }

  private createView(payload: IShipmentListCustomViewsPayload): void {
    this.ShipmentViewsService.createCustomViews(payload)
      .then((res) => {
        if (res) {
          if (this.isDefault) {
            this.updateDefaultViewId(res.id);
          }
          this.esOnSaveSuccess({ view: res });
        }
        toastSuccess(
          this.$translate.instant('toast.create-success', {
            noun: this.$translate.instant('global.custom-view').toLowerCase(),
          })
        );
      })
      .finally(() => {
        this.busy = false;
      });
  }

  private updateView(viewId: string, payload: IShipmentListCustomViewsPayload): void {
    this.ShipmentViewsService.updateCustomViews(viewId, payload)
      .then(() => {
        if (this.isDefault) {
          this.updateDefaultViewId(viewId);
        } else if (this.isModifyDefaultViewId) {
          this.updateDefaultViewId(null);
        }

        if (this.esModifyView && this.esSelectedView && this.esSelectedView.id === viewId) {
          this.esOnSaveSuccess({
            view: {
              ...payload,
              id: viewId,
              company_id: this.esModifyView?.company_id,
              filters: this.esModifyView.filters,
            },
          });
        } else {
          this.esOnSaveSuccess();
        }
      })
      .finally(() => {
        this.busy = false;
      });
  }

  private get isModifyDefaultViewId(): boolean {
    if (this.esModifyView) {
      return (
        this.UserSession.getDefaultViews()[this.esPageContext as IShipmentListScope] ===
        this.esModifyView.id
      );
    }

    return false;
  }

  private updateDefaultViewId(viewId: string | null): void {
    const userDefaultViews = this.UserSession.getDefaultViews();

    this.UserService.update({
      dashboard_settings: {
        default_views: {
          ...userDefaultViews,
          [this.esPageContext as IShipmentListScope]: viewId || null,
        },
      },
    }).then((res) => {
      this.UserSession.update('user', res.user);
    });
  }
}

const CustomViewModalComponent: ng.IComponentOptions = {
  controller: CustomViewModal,
  template,
  bindings: {
    esOnCancel: '&',
    esOnSaveSuccess: '&',
    esPageContext: '<',
    esModifyView: '<',
    esSelectedView: '<',
  },
};

export { CustomViewModalComponent };
