import { toastError } from '@client/core/components/react/Toastify';
import template from './exclude-countries.html?raw';
import style from './exclude-countries.module.scss';

class ExcludeCountriesController {
  static $inject = ['CountryService', 'MixpanelService'];

  constructor(CountryService, MixpanelService) {
    this.CountryService = CountryService;
    this.MixpanelService = MixpanelService;
    this.style = style;
  }

  $onInit() {
    this.countries = this.form.destination_country_alpha2s;

    this.MixpanelService.track('Connect - Store - Settings - Free Shipping - Edit Country');
  }

  updateCountries(value) {
    this.countries = value;
  }

  onBack() {
    this.MixpanelService.track('Connect - Store - Settings - Free Shipping - Edit Country - Back');
    this.onCancel();
  }

  onSubmit() {
    if (!this.countries.length) {
      toastError(
        this.$translate.instant('toast.select-error', {
          noun: this.$translate
            .instant('global.pluralize.country', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );
      this.MixpanelService.track(
        'Connect - Store - Settings - Free Shipping - Edit Country - Failure'
      );

      return;
    }

    this.updateForm({ field: 'destination_country_alpha2s', value: this.countries });

    this.MixpanelService.track(
      'Connect - Store - Settings - Free Shipping - Edit Country - Success'
    );
    this.onCancel();
  }
}

const ExcludeCountriesComponent = {
  template,
  controller: ExcludeCountriesController,
  bindings: {
    form: '<',
    updateForm: '&',
    onCancel: '&',
  },
};

export { ExcludeCountriesComponent };
