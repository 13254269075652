import template from './shipment-courier-quality-cell.html?raw';
import style from './shipment-courier-quality-cell.module.scss';

class ShipmentCourierQualityCell {
  constructor() {
    this.style = style;
  }
}

const ShipmentCourierQualityCellComponent = {
  controller: ShipmentCourierQualityCell,
  template,
  bindings: {
    count: '<',
    rating: '<',
  },
};

export { ShipmentCourierQualityCellComponent };
