import { toastError } from '@client/core/components/react/Toastify';
import template from './webhooks.html?raw';
import style from './webhooks.module.scss';

class WebhooksPage {
  static $inject = ['Webhooks', 'WebhookModal', 'MixpanelService', '$translate'];

  constructor(Webhooks, WebhookModal, MixpanelService, $translate) {
    this.style = style;
    this.Webhooks = Webhooks;
    this.WebhookModal = WebhookModal;
    this.MixpanelService = MixpanelService;
    this.$translate = $translate;
    this.loading = true;
  }

  $onInit() {
    this.MixpanelService.track('Open Webhooks Page');
    this._getWebhooks();
  }

  _getWebhooks() {
    this.Webhooks.getWebhooks()
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onAddWebhook() {
    this.MixpanelService.track('Webhooks - Add New - Open');
    this.WebhookModal.open('connect');
  }

  onOpenWebhookDetails(webhook) {
    this.MixpanelService.track('Webhooks - Edit - Open');
    this.WebhookModal.open('update', webhook);
  }
}

const WebhooksComponent = {
  template,
  controller: WebhooksPage,
};

export { WebhooksComponent };
