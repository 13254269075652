import { IApiConfig } from 'typings/core/config/api';
import { IHelperService } from 'typings/helper';
import { Reports } from 'typings/reports';

import { IComponentController } from 'angular';
import {
  MarkAsPrintedParams,
  PrintingOptionsService,
} from '@client/src/global/services/printing-options/printing-options.service';
import { toast } from 'react-toastify';
import template from './queue-list.html?raw';
import style from './queue-list.module.scss';
import zipPending from './zip-pending.svg?svgo';
import zipReady from './zip-ready.svg?svgo';
import printPending from './print-pending.svg?svgo';
import printReady from './print-ready.svg?svgo';

type Busy = {
  gettingReports: boolean;
};

class QueueList implements IComponentController {
  style = style;
  esReports: Reports.IReport[] = [];
  listLimit = 0;
  listLimitReached = false;
  busy: Busy = {
    gettingReports: false,
  };
  zipPendingMarkup = zipPending;
  zipReadyMarkup = zipReady;
  printPendingMarkup = printPending;
  printReadyMarkup = printReady;

  static $inject = ['$filter', 'API', 'HelperService', 'PrintingOptionsService'];
  constructor(
    private $filter: ng.IFilterService,
    private API: IApiConfig,
    private HelperService: IHelperService,
    private PrintingOptionsService: PrintingOptionsService
  ) {}

  $onInit(): void {
    this.listLimitReached = this.esReports.length < this.defaultListLimit;
  }

  get defaultListLimit(): number {
    return this.PrintingOptionsService.limit;
  }

  get supportLink(): string {
    return `${this.API.help}/hc/en-us/articles/360000306131`;
  }

  hasPreviewDocuments(report: Reports.IReport): boolean {
    return (
      (report.generated_at && !!report.preview_documents?.length) ||
      !report.printed_at ||
      !!report.preview_documents?.length
    );
  }

  getPendingLabel(success: number, total: number): string {
    let label = 'Pending';

    if (success) {
      let percentage = (success / total) * 100;

      // We don't want to display 100 in the UI
      if (percentage === 100) {
        percentage = 99;
      }

      label += ` - ${this.$filter('number')(percentage, 0)} %`;
    }

    return label;
  }

  download(report: Reports.IReport): void {
    this.HelperService.openNewTab(report.zip_url as string);

    if (!report.printed_at) {
      this.markAsPrinted({
        reportId: report.id,
        printMethod: 'Download',
      });
    }
  }

  print(report: Reports.IReport): void {
    toast.dismiss();
    this.PrintingOptionsService.showDocumentQueue = false;
    this.PrintingOptionsService.showPrintPreview = true;
    this.PrintingOptionsService.previewDocuments = report.preview_documents;

    if (!report.printed_at) {
      this.markAsPrinted({
        reportId: report.id,
        printMethod: 'Print',
      });
    }
  }

  loadMore(last: boolean, inview: boolean): void {
    if (last && inview && !this.busy.gettingReports && !this.listLimitReached) {
      this.busy.gettingReports = true;
      this.listLimit += this.defaultListLimit;

      this.PrintingOptionsService.getReports(this.listLimit)
        .then((reports) => {
          if (reports.length < this.defaultListLimit) {
            this.listLimitReached = true;
          }
        })
        .finally(() => {
          this.busy.gettingReports = false;
        });
    }
  }

  private markAsPrinted(params: MarkAsPrintedParams): void {
    this.PrintingOptionsService.markAsPrinted({
      ...params,
    }).then(({ printed_at }) => {
      const report = this.PrintingOptionsService.reports.find((r) => {
        return r.id === params.reportId;
      });

      if (report) {
        report.printed_at = printed_at;
      }
    });
  }
}

const QueueListComponent: ng.IComponentOptions = {
  controller: QueueList,
  template,
  bindings: {
    esReports: '<',
  },
};

export { QueueListComponent };
