/* eslint-disable max-classes-per-file */
import template from './merge-all-modal.html?raw';

class MergeAllModalCtrl {
  static $inject = [
    'initialShipmentsCount',
    'mergedShipmentsCount',
    'MixpanelService',
    'MergeAllModal',
  ];

  constructor(initialShipmentsCount, mergedShipmentsCount, MixpanelService, MergeAllModal) {
    this.initialShipmentsCount = initialShipmentsCount;
    this.mergedShipmentsCount = mergedShipmentsCount;
    this.MixpanelService = MixpanelService;
    this.MergeAllModal = MergeAllModal;
  }

  close() {
    this.MixpanelService.track('Multiple - Merge All - Cancel');
    this.MergeAllModal.close();
  }
}

class MergeAllModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open({ initial_shipments_count, merged_shipments_count }) {
    this.modalReference = this.$uibModal.open({
      template,
      controller: MergeAllModalCtrl,
      controllerAs: '$ctrl',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        initialShipmentsCount: () => initial_shipments_count,
        mergedShipmentsCount: () => merged_shipments_count,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { MergeAllModal };
