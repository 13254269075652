'use strict';
import template from './choose-courier.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('choose-courier', {
      url: '/choose-courier',
      parent: 'app.single',
      template,
      controller: 'SingleShipmentChooseCourierCtrl',
      params: {
        shipment: null,
      },
    });
  },
]);
