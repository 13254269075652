import { NumberOfItemsInputOptionsValue } from '@client/src/global/services/shipment-list/data/number-input';
import { IComponentController } from 'angular';
import template from './sku-count-input-group.html?raw';
import style from './sku-count-input-group.module.scss';

enum InputTypeValue {
  From = 'from_number',
  To = 'to_number',
  Equal = 'equal_number',
}

interface RelationOption {
  value: NumberOfItemsInputOptionsValue;
  label: string;
}

interface IInputState {
  from: boolean;
  to: boolean;
  equal: boolean;
}

class SkuCountInputGroup implements IComponentController {
  style = style;
  esOnChange: (value: any) => void = () => {};
  esModelSelect: NumberOfItemsInputOptionsValue | null = null;
  isBetweenOption = false;
  isShowInput = false;
  esFromNumber: number | null = null;
  esToNumber: number | null = null;
  esEqualNumber: number | null = null;
  inputTypeValue = InputTypeValue;
  readonly numberValidation = {
    allowNegative: false,
    allowFloat: false,
  };
  options: RelationOption[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    this.options = [
      NumberOfItemsInputOptionsValue.EqualTo,
      NumberOfItemsInputOptionsValue.NotEqualTo,
      NumberOfItemsInputOptionsValue.GreaterThan,
      NumberOfItemsInputOptionsValue.GreaterThanEqualTo,
      NumberOfItemsInputOptionsValue.LessThan,
      NumberOfItemsInputOptionsValue.LessThanEqualTo,
      NumberOfItemsInputOptionsValue.Between,
    ].map((option) => ({
      value: option,
      label: this.$translate.instant(`global.relation.${option}`),
    }));
    if (!this.esModelSelect) {
      this.esModelSelect = NumberOfItemsInputOptionsValue.GreaterThanEqualTo;
      this.onValuesChange();
    }

    this.assignCurrentValue();
  }

  $onChanges(): void {
    this.assignCurrentValue();
  }

  onSelectChange(value: NumberOfItemsInputOptionsValue): void {
    switch (value) {
      case NumberOfItemsInputOptionsValue.Between:
        this.resetInputValue({
          from: true,
          to: true,
          equal: false,
        });
        break;

      default:
        this.resetInputValue({
          from: false,
          to: false,
          equal: true,
        });
        break;
    }
    this.esModelSelect = value;
    this.setBetweenOptionState();
    this.onValuesChange();
  }

  onInputChange(value: string, key: InputTypeValue): void {
    switch (key) {
      case InputTypeValue.From:
        this.esFromNumber = parseFloat(value);
        break;
      case InputTypeValue.To:
        this.esToNumber = parseFloat(value);
        break;
      case InputTypeValue.Equal:
        this.esEqualNumber = parseFloat(value);
        break;
      default:
        break;
    }

    this.onValuesChange();
  }

  private setBetweenOptionState(): void {
    switch (this.esModelSelect) {
      case NumberOfItemsInputOptionsValue.Between:
        this.isShowInput = true;
        this.isBetweenOption = true;
        break;
      default:
        this.isShowInput = true;
        this.isBetweenOption = false;
        break;
    }
  }

  private onValuesChange(): void {
    this.esOnChange({
      value: {
        select: this.esModelSelect,
        from: this.esFromNumber,
        to: this.esToNumber,
        equal: this.esEqualNumber,
      },
    });
  }

  private assignCurrentValue(): void {
    if (this.esModelSelect) {
      this.setBetweenOptionState();
    }
  }

  private resetInputValue(inputState: IInputState): void {
    if (!inputState.from) {
      this.esFromNumber = null;
    }

    if (!inputState.to) {
      this.esToNumber = null;
    }

    if (!inputState.equal) {
      this.esEqualNumber = null;
    }
  }
}

const SkuCountInputGroupComponent: ng.IComponentOptions = {
  controller: SkuCountInputGroup,
  template,
  bindings: {
    esModelSelect: '<',
    esEqualNumber: '<',
    esFromNumber: '<',
    esToNumber: '<',
    esOnChange: '&',
  },
};

export { SkuCountInputGroupComponent };
