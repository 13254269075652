(function () {
  'use strict';

  ChanneladvisorService.$inject = [
    '$q',
    '$window',
    'PlatformApi',
    'UserSession',
    'StoreService',
    'Auth',
  ];
  function ChanneladvisorService($q, $window, PlatformApi, UserSession, StoreService, Auth) {
    var service = this;

    service.getChanneladvisorUrl = function (store) {
      return PlatformApi.channeladvisor
        .get({ company_id: UserSession.company.id })
        .$promise.then(function (response) {
          StoreService.saveStoreValueToLocal(store);
          return response.url;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.saveChanneladvisorToken = function (channeladvisorGranted) {
      return $q(function (resolve, reject) {
        var channeladvisor = angular.merge(
          {
            code: channeladvisorGranted.code,
          },
          StoreService.getStorePayloadFromLocalStorage()
        );

        //Ping the session to be sure that UserSession exists
        Auth.retrieveSession().then(function () {
          PlatformApi.channeladvisor
            .save({ company_id: UserSession.company.id }, { channel_advisor: channeladvisor })
            .$promise.then(function (response) {
              StoreService.removeStoreValueFromLocal();
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      });
    };
  }

  angular
    .module('app.service.ChanneladvisorService', ['app.factory.PlatformApi'])
    .service('ChanneladvisorService', ChanneladvisorService);
})();
