import { IProductsUploadErrorResponse, IProductsUploadErrorMessage } from 'typings/product-listing';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './file-uploader.html?raw';
import style from './file-uploader.module.scss';
import { ProductListingService } from '../product-listing.service';

class FileUploader implements IComponentController {
  style = style;
  loading = false;
  esUploadEndpoint = '';
  errors: IProductsUploadErrorMessage[] = [];
  showErrorDetails = false;

  static $inject = [
    'ProductListingService',
    'MixpanelService',
    'AppCuesService',
    'OnboardingChecklistService',
    'UserRightsService',
    '$translate',
  ];
  constructor(
    private ProductListingService: ProductListingService,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService,
    private OnboardingChecklistService: OnboardingChecklistService,
    private UserRightsService: UserRightsService,
    private $translate: angular.translate.ITranslateService
  ) {}

  onStartUpload(): void {
    this.loading = true;
    toastSuccess(this.$translate.instant('product-listing.import.uploading'));
  }

  onFinishUpload(error: IProductsUploadErrorResponse): void {
    this.loading = false;

    if (error.data && (error.data.error || error.data.errors)) {
      this.errors = error.data.error || error.data.errors || [];

      return;
    }

    if (error.status === 500) {
      toastError(this.$translate.instant('toast.default-error'));
      return;
    }

    this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.InputDimensions);
    toastSuccess(this.$translate.instant('product-listing.warnings.create-success'));
    this.ProductListingService.hideModal();
    this.ProductListingService.getProductList().finally(() => {
      if (this.ProductListingService.products.length > 0) {
        this.AppCuesService.track('Products | Product created', null, true);
      }
    });

    this.MixpanelService.track('Products - Create', { method: 'CSV' });
  }

  toggleErrorDetails(): void {
    this.showErrorDetails = !this.showErrorDetails;
  }

  reupload(): void {
    this.errors = [];
    this.showErrorDetails = false;
  }

  get hasUploadUserRight(): boolean {
    return (
      this.UserRightsService.canCreateShipmentByUpload || this.UserRightsService.canEditProduct
    );
  }
}

const FileUploaderComponent: ng.IComponentOptions = {
  controller: FileUploader,
  template,
  transclude: true,
  bindings: {
    esUploadEndpoint: '<',
  },
};

export { FileUploaderComponent };
