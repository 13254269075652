import React from 'react';
import { FormattedNumber } from 'react-intl';
import { twMerge } from 'tailwind-merge';

interface PriceProps {
  currencyCode: string;
  totalCharge: number;
  className?: string;
}

export default function Price({ currencyCode, totalCharge, className }: PriceProps) {
  return (
    <div className={twMerge('text-lg font-bold text-ink-900 leading-4', className)}>
      <FormattedNumber value={totalCharge} style="currency" currency={currencyCode} />
    </div>
  );
}
