import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { AngularPickupsMainHeader } from '@client/core/components/wrappers/pickups/AngularPickupsMainHeader';
import { AngularEmptyPickupPage } from '@client/core/components/wrappers/pickups/AngularEmtpyPickupPage';
import filters from './filters';
import advancedSearch from './advanced-search';
import itemHeader from './item-header';
import itemList from './item-list';
import searchNotMatch from './search-not-match';
import footer from './footer';
import contactSupportModal from './contact-support-modal';
import cancelModal from './cancel-modal';
import editModal from './edit-modal';
import reportIssueModal from './report-issue-modal';
import { PickupsRoutes } from './index.routes';
import { PickupsComponent } from './pickups.component';
import { PickupsPageService } from './pickups-page.service';

const MODULE_NAME = 'app.pickups';

angular
  .module(MODULE_NAME, [
    uiRouter,
    filters,
    'app.factory.UserSession',
    advancedSearch,
    itemHeader,
    itemList,
    searchNotMatch,
    footer,
    contactSupportModal,
    cancelModal,
    editModal,
    reportIssueModal,
    'app.global.services.pickup',
  ])
  .config(PickupsRoutes)
  .component('pickups', PickupsComponent)
  .component('esPickupsMainHeader', AngularPickupsMainHeader)
  .component('esEmptyPickupPage', AngularEmptyPickupPage)
  .service('PickupsPageService', PickupsPageService);

export default MODULE_NAME;
