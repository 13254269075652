import { useGateways } from '@client/src/global/context/GatewayProvider';
import { useMutation } from '@tanstack/react-query';

export default function useDeleteCourierMutation() {
  const { courierGateway } = useGateways();

  const mutation = useMutation({
    mutationFn: (id: string) => courierGateway.deleteCourierAccount(id),
  });

  return mutation;
}
