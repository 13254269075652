import template from './shipment-tracking-quality-cell.html?raw';
import style from './shipment-tracking-quality-cell.module.scss';

class ShipmentTrackingQualityCell {
  constructor() {
    this.style = style;
  }
}

const ShipmentTrackingQualityCellComponent = {
  controller: ShipmentTrackingQualityCell,
  template,
  bindings: {
    rating: '<',
  },
};

export { ShipmentTrackingQualityCellComponent };
