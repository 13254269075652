import template from './dropdown-button.html?raw';
import menuTemplate from './dropdown-button-menu.html?raw';
import style from './dropdown-button.module.scss';

class DropdownButton {
  static $inject = ['$compile', '$rootScope', '$element', '$scope'];

  constructor($compile, $rootScope, $element, $scope) {
    this.style = style;
    this.$compile = $compile;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$element = $element;
    this.menuVisibility = false;
    this.id = Math.floor(Math.random() * 10000);
    this.clickEventName = `click.${this.id}`;
    this.tabEventName = `keydown.${this.id}`;
  }

  $onInit() {
    this._validateActionConfig();
  }

  $onChanges(changesObj) {
    if (this.menuScope && changesObj.esMenuActions && changesObj.esMenuActions.currentValue) {
      this.menuScope.$ctrl.actions = { ...changesObj.esMenuActions.currentValue };
    }

    if (this.menuScope && changesObj.esMenuFooter && changesObj.esMenuFooter.currentValue) {
      this.menuScope.$ctrl.footer = changesObj.esMenuFooter.currentValue;
    }
  }

  /**
   * Compiles the menu template and attaches it to the body element to ensure
   * that it sits on the stack above other elements on the page. A click
   * listener is also bound on the body for closing the menu.
   */
  $postLink() {
    this.menuScope = this._prepareMenuScope(this.esMenuActions, this.esMenuFooter, this.$element);
    this.$menu = this.$compile(menuTemplate)(this.menuScope);

    angular.element(this.$element).append(this.$menu);
    angular.element('body').on(this.clickEventName, ($event) => {
      const $target = angular.element($event.target);
      const $menuButton = this.$element.find(`easyship-button`);
      const targetIsNotMenuItem = $target.is(this.$menu.find('li'));
      const targetIsButton = !!$target.closest($menuButton).length;
      const targetIsAlternateButton =
        $target[0].parentElement && $target[0].parentElement.dataset.dropdownTrigger === 'true';

      if (!this.menuVisibility || targetIsButton || targetIsNotMenuItem || targetIsAlternateButton)
        return;

      this.$scope.$apply(() => {
        this.onMenuButtonClick();
      });
    });
  }

  /**
   * Manually clean up the click event and element that we've compiled.
   */
  $onDestroy() {
    angular.element('body').off(this.clickEventName);
    this.menuScope.$destroy();
    this.$menu.remove();
  }

  onMenuButtonClick() {
    this.menuVisibility = !this.menuVisibility;
    this.menuScope.$ctrl.menuVisibility = this.menuVisibility;

    if (this.menuVisibility) {
      let menuPositionBottom =
        // Height from top of the body to dropdown button with menu open
        // Compared with Height from top of the body to bottom fo the viewport
        this.$element.offset().top + this.$menu.outerHeight() + this.$element.outerHeight() <
        $(window).outerHeight() + $(window).scrollTop();

      // Forces the dropdown to only open top/bottom
      if (this.esForceDirection === 'bottom') {
        menuPositionBottom = true;
      } else if (this.esForceDirection === 'top') {
        menuPositionBottom = false;
      }

      this.menuScope.$ctrl.top = menuPositionBottom
        ? this.$element.offset().top + this.$element.outerHeight() - $(window).scrollTop()
        : this.$element.offset().top - this.$menu.outerHeight(true);

      this.menuScope.$ctrl.left =
        this.esAnchor === 'right'
          ? this.$element.offset().left - this.$menu.outerWidth() + this.$element.outerWidth()
          : this.$element.offset().left;

      this.menuScope.$ctrl.transformOrigin = menuPositionBottom ? 'top center' : 'bottom center';

      // ensure digest cycle has finished before focusing on the menu to prevent the window from jumping around when the top position of the menu has not been set by angularJS yet
      setTimeout(() => {
        this.$menu.focus();
      });

      this._bindMenuFocusEvent();
    } else {
      this._unbindMenuFocusEvent();
    }
  }

  /**
   * Preps a new scope object containing the menu's controller
   *
   * @param {MenuAction[]} esMenuActions User defined menu actions
   * @param {JQuery} $element Parent element for the menu to anchor to
   * @returns {Scope} AngularJS Scope obj containing the menu's data
   */
  _prepareMenuScope(esMenuActions, esMenuFooter, $element) {
    return Object.assign(this.$rootScope.$new(true), {
      $ctrl: {
        style,
        $parent: $element,
        actions: { ...esMenuActions },
        footer: esMenuFooter,
        menuVisibility: this.menuVisibility,
      },
    });
  }

  _validateActionConfig() {
    if (!this.esMenuActions) {
      throw new Error('EasyshipDropdownButton: An es-menu-actions binding must be provided.');
    }

    this.esMenuActions.forEach((config) => {
      const hasHref = typeof config.href === 'string';
      const hasAction = typeof config.action === 'function';
      const hasHtml = typeof config.html === 'string';

      if (hasHref && hasAction) {
        throw new Error(
          'EasyshipDropdownButton: A menu item cannot have both a href and an action.'
        );
      } else if (!hasHref && !hasAction && !hasHtml) {
        throw new Error(
          'EasyshipDropdownButton: A menu item must have either a href, action or an html.'
        );
      }
    });
  }

  /**
   * Set up keyboard navigation for the menu. When users tab away from the first
   * or last item in the menu, the menu is closed and the focus returns to the
   * button element.
   */
  _bindMenuFocusEvent() {
    this.$menu.on(`keydown.${this.id}`, (event) => {
      const { which, target } = event;
      const onTab = which === 9;
      const onUp = which === 38;
      const onDown = which === 40;

      if (!onTab && !onUp && !onDown) return;

      const $target = angular.element(target);
      const $parent = $target.parent();
      const $listItems = $target.closest('div').children();
      const isFirst = $parent.is($listItems.first());
      const isLast = $parent.is($listItems.last());

      // Check whether the dropdown menu should collapse
      if (
        (isLast && this._onNext(event)) ||
        (isFirst && this._onPrevious(event)) ||
        (this._onNext(event) && this._areAllItemsAfterTargetDisabled($listItems, $target)) ||
        (this._onPrevious(event) && this._areAllItemsBeforeTargetDisabled($listItems, $target))
      ) {
        event.preventDefault();

        this.$scope.$apply(() => {
          this.onMenuButtonClick();
          this.$element.find(`button`).last().focus();
        });
      } else {
        // Manually focus when user is using arrow keys
        if (onDown || onUp) {
          event.preventDefault();
          this._focusNextElement(onDown);
        }
      }
    });
  }

  /**
   * Checks whether all the menu items after the current focused item are disabled.
   * @param {jQuery} items All the menu items
   * @param {jQuery} focusedItem The focused menu item
   * @returns {boolean}
   */
  _areAllItemsAfterTargetDisabled(items, focusedItem) {
    const focusedItemDom = focusedItem[0];

    if (focusedItemDom.tagName === 'UL') return false;

    let itemIndex;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].firstElementChild;

      if (itemIndex > -1) {
        if (!this._isDisabled(item)) return false;
      }

      if (item.isEqualNode(focusedItemDom)) {
        itemIndex = i;
      }
    }

    return true;
  }

  /**
   * Checks whether all the menu items before the current focused item are disabled.
   * @param {jQuery} items All the menu items
   * @param {jQuery} focusedItem The focused menu item
   * @returns {boolean}
   */
  _areAllItemsBeforeTargetDisabled(items, focusedItem) {
    const focusedItemDom = focusedItem[0];

    if (focusedItemDom.tagName === 'UL') return false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].firstElementChild;

      if (item.isEqualNode(focusedItemDom)) break;
      if (!this._isDisabled(item)) return false;
    }

    return true;
  }

  /**
   * Checks whether an element is disabled
   * @param {HTMLElement} element Target element to be checked disabled state
   * @returns {boolean}
   */
  _isDisabled(element) {
    const isButton = element.tagName === 'BUTTON';
    return isButton ? !!element.getAttribute('disabled') : !element.getAttribute('href');
  }

  /**
   * Focuses on the next available element in the current dropdown menu
   * @param {number} onDown
   */
  _focusNextElement(onDown) {
    const focussableItems = 'a:not([disabled]), button:not([disabled])';
    const focussable = Array.from(this.$menu.find(focussableItems));
    const currentFocusedItemIndex = focussable.indexOf(document.activeElement);
    const nextItem =
      currentFocusedItemIndex > -1
        ? focussable[currentFocusedItemIndex + (onDown ? 1 : -1)]
        : focussable[0];
    nextItem.focus();
  }

  _onNext(event) {
    const { which, shiftKey } = event;
    const onTab = which === 9;
    const onDown = which === 40;

    return (!shiftKey && onTab) || onDown;
  }

  _onPrevious(event) {
    const { which, shiftKey } = event;
    const onTab = which === 9;
    const onUp = which === 38;

    return (shiftKey && onTab) || onUp;
  }

  _unbindMenuFocusEvent() {
    this.$menu.off(`keydown.${this.id}`);
  }
}

const DropdownButtonComponent = {
  controller: DropdownButton,
  template,
  transclude: true,
  bindings: {
    esType: '@',
    esMenuActions: '<',
    esMenuFooter: '<',
    esLoading: '<',
    esAnchor: '@',
    esCustomIcon: '@',
    esForceDirection: '@',
    ngDisabled: '<',
  },
};

export { DropdownButtonComponent };
