import { IApiConfig } from 'typings/core/config/api';
import { IShipmentListItem } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './shipment-list-cost-cell.html?raw';
import style from './shipment-list-cost-cell.module.scss';
import iconLiquidUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-liquid.svg';
import iconBatteryUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-battery.svg';

class ShipmentListCostCell implements IComponentController {
  style = style;
  iconLiquidUrl = iconLiquidUrl;
  iconBatteryUrl = iconBatteryUrl;
  esShipment: IShipmentListItem | null = null;
  companyCurrency = '';
  esIsShowDiscount = false;
  hasBetaFeatureDisplayDiscount = false;

  static $inject = ['API', 'UserSession', 'MixpanelService'];
  constructor(
    private API: IApiConfig,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService
  ) {}

  $onInit(): void {
    this.companyCurrency = this.UserSession.getCompanyCurrency();
    this.hasBetaFeatureDisplayDiscount =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_display_discount_price;
  }

  onBuyersNotesTrack(eventName: string) {
    this.MixpanelService.track(`Create Shipments - Buyers Note - ${eventName} Icon`, {
      easyship_shipment_id: this.esShipment?.easyship_shipment_id,
      platform: this.esShipment?.platform_name || '',
    });
  }

  get isShowRetailDiscount(): boolean {
    return (
      this.hasBetaFeatureDisplayDiscount &&
      this.esIsShowDiscount &&
      !!this.esShipment?.courier_umbrella_name &&
      !!this.esShipment?.retail_discount &&
      this.esShipment.retail_discount > 0
    );
  }

  get isTotalChargeNotEmpty(): boolean {
    return this.totalCharge !== null;
  }

  get totalCharge(): number | null {
    if (this.esShipment) {
      if (this.esShipment.total_charge) {
        return this.esShipment.total_charge;
      }
      return this.calculateTotalCharge(this.esShipment);
    }

    return null;
  }

  get taxName(): string | null {
    if (!this.esShipment) return null;

    const taxName = [];
    const {
      sales_tax: salesTax,
      provincial_sales_tax: provincialSalesTax,
      sales_tax_name: salesTaxName,
      provincial_sales_tax_name: provincialSalesTaxName,
    } = this.esShipment;

    if (salesTaxName && salesTax) {
      taxName.push(salesTaxName);
    }

    if (provincialSalesTaxName && provincialSalesTax) {
      taxName.push(provincialSalesTaxName);
    }

    return taxName.join(' & ');
  }

  get buyersNotesIconUrl(): string {
    return `${this.API.easyship_storage}/buyers-notes/icon-buyers-note.svg`;
  }

  get insuranceIconUrl(): string {
    return `${this.API.easyship_storage}/insurance/shield-${
      this.esShipment?.insurance_requested_by_buyer ? 'blue' : 'green'
    }.svg`;
  }

  private calculateTotalCharge(shipment: IShipmentListItem): number {
    return (Object.keys(shipment) as Array<keyof IShipmentListItem>).reduce(
      (accumulator, feeName) => {
        let totalCharge = accumulator;
        const fee = shipment[feeName] || 0;

        if (typeof fee === 'number') {
          totalCharge += fee;
        }

        return totalCharge;
      },
      0
    );
  }
}

const ShipmentListCostCellComponent: ng.IComponentOptions = {
  controller: ShipmentListCostCell,
  template,
  bindings: {
    esShipment: '<',
    esLargeText: '<',
    esIsShowDiscount: '<',
  },
};

export { ShipmentListCostCellComponent };
