import { IUserStatusService } from 'typings/user-status';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import template from './support-team-contact-modal-card.html?raw';
import style from './support-team-contact-modal-card.module.scss';
import supportContactImageUrl from '@assets/images/dashboard/refund-credits/support-contact.svg';

class SupportTeamContactModalCard implements IComponentController {
  style = style;
  supportContactImageUrl = supportContactImageUrl;
  currency = '';

  static $inject = ['UserSession', 'UserStatusService'];
  constructor(private UserSession: IUserSession, private UserStatusService: IUserStatusService) {}

  $onInit() {
    this.currency = this.UserSession.getCompanyCurrency();
  }

  get availableBalance(): number {
    return this.UserStatusService.availableBalance || 0;
  }
}

const SupportTeamContactModalCardComponent: ng.IComponentOptions = {
  controller: SupportTeamContactModalCard,
  template,
  bindings: {
    esCloseModal: '&',
  },
};

export { SupportTeamContactModalCardComponent };
