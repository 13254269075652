import { IBoxService, IBoxObject, IBoxParams, IFlatRateBoxService } from 'typings/boxes';
import { IInputGroupRadioModel } from 'typings/form';
import { IGroupedArray, IHelperService } from 'typings/helper';
import { IAdvancedSearchBoxObject } from 'typings/advanced-search';

import {
  MultiselectComponentModelValue,
  MultiselectAttributes,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import style from './boxes-multi-select-group.module.scss';
import template from './boxes-multi-select-group.html?raw';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class BoxesMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchBoxObject>
  implements IComponentController
{
  style = style;
  busy = false;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchBoxObject>[] = [];

  static $inject = [
    '$q',
    '$translate',
    'BoxService',
    'FlatRateBoxService',
    '$stateParams',
    'HelperService',
    '$scope',
  ];
  constructor(
    private $q: ng.IQService,
    private $translate: angular.translate.ITranslateService,
    private BoxService: IBoxService,
    private FlatRateBoxService: IFlatRateBoxService,
    private $stateParams: ng.ui.IStateParamsService,
    private HelperService: IHelperService,
    $scope: ng.IScope
  ) {
    super($scope);
    this.fetchBoxes = this.fetchBoxes.bind(this);
  }

  $onInit(): void {
    const noun = this.$translate.instant('global.box');
    this.radioModel = [
      {
        title: this.$translate.instant('global.relation.prefixed.is_any_of', { noun }),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: this.$translate.instant('global.relation.prefixed.is_none_of', { noun }),
        value: MultiselectComponentModelValue.NoneOf,
      },
      // Disable for Boxes
      // {
      //   title: 'Boxes is known',
      //   value: MultiselectComponentModelValue.Known,
      // },
      {
        title: this.$translate.instant('shipments.filter.box.radio-options.no-box'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];

    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    this.onChangesAssignAndTrigger(changes, MultiselectAttributes.Group, this.fetchBoxes);
  }

  getCompanyBoxes(queryParams: IBoxParams): ng.IPromise<{
    boxes: IAdvancedSearchBoxObject[];
    package: IBoxObject;
  } | void> {
    return this.$q
      .all([
        this.BoxService.getFilterBoxesSelectOptions(),
        this.FlatRateBoxService.getFilterFlatRateBoxesSelectOptions(),
      ])
      .then((response) => {
        const result: IAdvancedSearchBoxObject[] = [];
        const savedBoxes = response[0];
        const flatRateBoxes = response[1];
        flatRateBoxes.forEach((flatBox) => {
          const box = flatBox;
          box.id = box.flat_rate_box_id;
          return box;
        });
        const anyBoxesValue = '';

        return {
          boxes: result.concat(savedBoxes).concat(flatRateBoxes as IBoxObject[]),
          package: this.BoxService.selectBox(
            this.BoxService.boxes,
            queryParams.package_id || anyBoxesValue
          ),
        };
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private fetchBoxes(): void {
    const query = { ...this.$stateParams.query };
    this.busy = true;
    this.getCompanyBoxes(query)
      .then((res) => {
        if (res && res.boxes) {
          this.esModelOptions = this.groupBoxesObject(res.boxes);
          const noBoxIndex = this.esModelOptions.findIndex(
            (box) => box.id === this.BoxService.noBoxID
          );
          if (
            noBoxIndex &&
            this.esModelOptions &&
            this.esModelOptions[noBoxIndex] &&
            this.esModelOptions[noBoxIndex].options
          ) {
            const { options } = this.esModelOptions[noBoxIndex];
            if (options && options[0]) {
              this.esModelOptions[noBoxIndex].id = options[0].id;
              this.esModelOptions[noBoxIndex].display = options[0].name;
              if (options[0].value) {
                this.esModelOptions[noBoxIndex].value = options[0].value;
              }
              delete this.esModelOptions[noBoxIndex].options;
              this.esModelOptions[noBoxIndex].isOpen = false;
            }
          }
          this.watchAndTrigger(MultiselectAttributes.Name);
        }
      })
      .finally(() => {
        this.busy = false;
      });
  }

  private groupBoxesObject(boxes: IBoxObject[]): IGroupedArray<IAdvancedSearchBoxObject>[] {
    return this.HelperService.groupArrayByKey(boxes, MultiselectAttributes.Type, {
      isDisplay: true,
      isOpen: true,
    });
  }
}

const BoxesMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: BoxesMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { BoxesMultiSelectGroupComponent };
