(function () {
  'use strict';

  AddressSearch.$inject = ['$resource', 'API'];
  function AddressSearch($resource, API) {
    var baseEndpointUrl = API.baseEndpoint;

    return $resource(baseEndpointUrl + '/v2/companies/:company_id/shipments/address_suggestions', {
      company_id: '@_company_id',
    });
  }

  angular.module('app.factory.AddressSearch', []).factory('AddressSearch', AddressSearch);
})();
