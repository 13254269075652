import { IRegistrationSuccessResponse } from 'typings/auth/services/registration';
import { IBigCommerceLoadResponse } from 'typings/auth/services/bigcommerce-auth';
import { BigCommerceResource } from './bigcommerce-auth.resource';
import { RegistrationResource } from '../registration/registration.resource';

const PLATFORM = 'big_commerce';

class BigCommerceAuthService {
  static $inject = ['$location', 'RegistrationResource', 'BigCommerceResource'];

  constructor(
    public $location: ng.ILocationService,
    public RegistrationResource: RegistrationResource,
    private BigCommerceResource: BigCommerceResource
  ) {}

  createRegistration(): ng.IPromise<IRegistrationSuccessResponse> {
    const { code, context, scope } = this.$location.search();

    return this.RegistrationResource.platform(
      { platform: PLATFORM },
      {
        code,
        context,
        scope,
      }
    );
  }

  connectExistingRegistration(): ng.IPromise<IBigCommerceLoadResponse> {
    const { signed_payload } = this.$location.search();

    return this.BigCommerceResource.load(signed_payload);
  }
}

export { BigCommerceAuthService };
