import angular from 'angular';
import { react2angular } from 'react2angular';

import ShipmentActionPanel, { WrappedShipmentActionPanel } from './ShipmentActionPanel';

export default ShipmentActionPanel;

export const AngularShipmentActionPanel = react2angular(WrappedShipmentActionPanel, [
  'onCancelShipment',
  'onPrintAllLabels',
  'onPrintCommercialInvoice',
  'onDuplicateShipment',
  'onRescheduleHandover',
  'onScheduleHandover',
  'onCreateReturnLabel',
  'onMarkAsDelivered',
  'onEditShipment',
  'onStartInsuranceClaim',
  'markAsDeliveredModalIsOpen',
  'markAsDeliveredModalOnClose',
  'markAsDeliveredModalOnConfirm',
  'shipmentListItem',
]);
export const shipmentActionPanelModuleName = 'app.components.shipment-action-panel';

angular
  .module(shipmentActionPanelModuleName, [])
  .component('esShipmentActionPanel', AngularShipmentActionPanel);
