import { IUserSession } from 'typings/user-session';
import { ICompanyService } from 'typings/company';
import { ISubscriptionObject, IShowChangePlanObject } from 'typings/subscription';
import { IFormData, IUpdateTrackingEmail } from 'typings/tracking-email';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';

import { FeatureKey } from '@client/data/subscription';
import { ICommaListFilterParams } from '@client/core/filters/to-comma-list.filter';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './tracking-email.module.scss';
import template from './tracking-email.html?raw';

const TRACKING_EMAIL_FEATURE_KEY = 'branded_tracking_experience';

class TrackingEmail implements IComponentController {
  style = style;
  loading = {
    form: false,
  };
  trackingEmailForm: ng.IFormController | null = null;
  showModal: Partial<IShowChangePlanObject> = {
    compare: false,
    upgrade: false,
    downgrade: false,
    enterpriseCall: false,
    callScheduledCard: false,
  };
  esFormData: IFormData | null = null;
  hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  buttonBackgroundColor = '';
  buttonTextColor = '';
  defaultBtnBackgroundColor = '50D4A4';
  defaultBtnTextColor = 'FFFFFF';
  showTrackingUpdatesModal = false;
  listOptions: ICommaListFilterParams;
  openEmailPreviewModal = false;
  translations: Record<string, string> = {};
  wrappers = {
    a: (text: string) => `<a href="/account/company">${text}</a>`,
  };

  static $inject = [
    '$translate',
    'AdvertisingModal',
    'UserSession',
    'CompanyService',
    'MixpanelService',
    'OnboardingChecklistService',
    'SubscriptionService',
    'UserRightsService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private AdvertisingModal: any,
    public UserSession: IUserSession,
    private CompanyService: ICompanyService,
    private MixpanelService: MixpanelService,
    private OnboardingChecklistService: OnboardingChecklistService,
    private SubscriptionService: SubscriptionService,
    private UserRightsService: UserRightsService
  ) {
    this.listOptions = {
      conjunction: $translate.instant('global.or'),
      className: 'text-blue-700 strong',
    };
  }

  get hasNoTrackingOrIsFreePlan(): boolean {
    return (
      !this.esFormData?.send_receiver_tracking ||
      this.SubscriptionService.isFreePlan ||
      !this.canEditTrackingEmail
    );
  }

  get canEditTrackingEmail(): boolean {
    return this.UserRightsService.canEditTrackingEmail;
  }

  get isEditTrackingEmailDisabled(): boolean {
    return (
      !this.esFormData?.send_receiver_tracking ||
      !this.isFeatureAccessible ||
      !this.canEditTrackingEmail
    );
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get isFeatureAccessible(): boolean {
    return (
      this.SubscriptionService.currentSubscription?.all_features[TRACKING_EMAIL_FEATURE_KEY]
        ?.is_accessible ?? false
    );
  }

  get isFeatureBadgeVisible() {
    return this.SubscriptionService.isPlanBadgeVisible(TRACKING_EMAIL_FEATURE_KEY);
  }

  get isSaveButtonDisabled() {
    return (
      !this.esFormData?.send_receiver_tracking ||
      this.loading.form ||
      !this.isFeatureAccessible ||
      !this.canEditTrackingEmail
    );
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  $onInit() {
    this.initDefaultButtonColors();
    this.$translate(['global.setting']).then((translations) => {
      this.translations = translations;
    });
  }

  private initDefaultButtonColors() {
    this.buttonBackgroundColor = !this.esFormData?.tracking_email_btn_background_color
      ? this.defaultBtnBackgroundColor
      : this.esFormData.tracking_email_btn_background_color.substr(1);
    this.buttonTextColor = !this.esFormData?.tracking_email_btn_text_color
      ? this.defaultBtnTextColor
      : this.esFormData.tracking_email_btn_text_color.substr(1);
  }

  viewPreview(): void {
    this.openEmailPreviewModal = true;
    this.MixpanelService.track('Settings - Customer Emails - Preview Tracking Email');
  }

  onToggleChange(value: boolean, model: keyof IFormData): void {
    const data = { [model]: value };

    if (this.esFormData && typeof value === 'boolean') {
      this.esFormData[model] = value as never;
    }

    this._updateTrackingEmail(data, model);

    const eventMapping: any = {
      send_receiver_survey: 'Rating Email',
      send_receiver_tracking: 'Send Tracking',
      tracking_email_show_header: 'Header',
      tracking_email_show_contact: 'Contact Info',
      tracking_email_show_company_as_sender: 'Sender',
    };
    const event = eventMapping[model];

    this.MixpanelService.track(`Settings - Customer Emails - ${event}`, { selection: value });
  }

  onOpenCompareModal(): void {
    this.showModal.compare = true;
    this.MixpanelService.track('Subscription - Plans - Open', {
      trigger_source: 'Branded Tracking Email',
    });
  }

  onTextareaChange(value: string, model: keyof IFormData): void {
    if (this.esFormData && typeof value === 'string') {
      this.esFormData[model] = value as never;
    }
  }

  onButtonStyleChange(value: string, type: string): void {
    if (typeof value === 'string') {
      value = `#${value.replace(/^#+/, '')}`;

      if (type === 'background') {
        this.buttonBackgroundColor = value.substr(1);
      }

      if (type === 'text') {
        this.buttonTextColor = value.substr(1);
      }

      if (this.hexColorRegex.test(value)) {
        if (this.esFormData) {
          this.esFormData.tracking_email_btn_background_color = `#${this.buttonBackgroundColor}`;
          this.esFormData.tracking_email_btn_text_color = `#${this.buttonTextColor}`;
        }

        this._updateCompany({
          tracking_email_btn_background_color: `#${this.buttonBackgroundColor}`,
          tracking_email_btn_text_color: `#${this.buttonTextColor}`,
        });
      }
    }
  }

  resetDefaultColors() {
    if (this.hasNoTrackingOrIsFreePlan) return;

    this.buttonBackgroundColor = this.defaultBtnBackgroundColor;
    this.buttonTextColor = this.defaultBtnTextColor;

    if (this.esFormData) {
      this.esFormData.tracking_email_btn_background_color = `#${this.defaultBtnBackgroundColor}`;
      this.esFormData.tracking_email_btn_text_color = `#${this.defaultBtnTextColor}`;
    }

    this._updateCompany({
      tracking_email_btn_background_color: `#${this.buttonBackgroundColor}`,
      tracking_email_btn_text_color: `#${this.buttonTextColor}`,
    });
  }

  openTrackingUpdatesModal(): void {
    this.showTrackingUpdatesModal = true;
    this.MixpanelService.track('Settings - Customer Emails - Tracking Updates Edit');
  }

  openAdvertisingModal(): void {
    this.AdvertisingModal.open('tracking_email_ad', this.esFormData);
    this.MixpanelService.track('Settings - Customer Emails - Display Edit');
  }

  onSubmitForm(): void {
    if (!this.esFormData) return;

    if (this.trackingEmailForm && this.trackingEmailForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    const data = { tracking_email_footnote: this.esFormData.tracking_email_footnote };

    this.loading.form = true;
    this._updateTrackingEmail(data);
    this.MixpanelService.track('Settings - Customer Emails - Footer Save');
  }

  onFulfilDelayMethodChange(value: string): void {
    if (this.esFormData) {
      this.esFormData.tracking_email_delay_method = value;
      this.esFormData.tracking_email_delay_hours = this.esFormData.tracking_email_delay_hours
        ? this.esFormData.tracking_email_delay_hours
        : 0;

      const data = {
        tracking_email_delay_method: this.esFormData.tracking_email_delay_method,
        tracking_email_delay_hours: this.esFormData.tracking_email_delay_hours,
      };

      this._updateTrackingEmail(data);
    }
  }

  _updateCompany(data: IUpdateTrackingEmail) {
    this.CompanyService.updateCompany(data)
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations['global.setting'].toLowerCase() },
            'messageformat'
          )
        );
      })
      .catch((error: any) => {
        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.translations['global.setting'].toLowerCase(),
            details: error.data?.status,
          })
        );
      })
      .finally(() => {
        this.loading.form = false;
        this._mixpanelTrackSendAllData();
      });
  }

  _updateTrackingEmail(data: IUpdateTrackingEmail, model?: string): void {
    if (!this.esFormData) return;

    if (model === 'tracking_email_show_header') {
      this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.SetupBranding);
    }

    this._updateCompany(data);
  }

  _mixpanelTrackSendAllData() {
    this.MixpanelService.track('Settings - Customer Emails - Customize Tracking Email', {
      toggle_header: this.esFormData?.tracking_email_show_header,
      toggle_contact_info: this.esFormData?.tracking_email_show_contact,
      toggle_sender: this.esFormData?.tracking_email_show_company_as_sender,
      toggle_tracking_updates: this.esFormData?.tracking_email_send_by_statuses,
      cta_background: this.esFormData?.tracking_email_btn_background_color,
      cta_text: this.esFormData?.tracking_email_btn_text_color,
      ad_image: !!this.esFormData?.tracking_email_ad.id,
      ad_cta: !!this.esFormData?.tracking_email_advertising.call_to_action,
      ad_link: !!this.esFormData?.tracking_email_advertising.link_url,
      footnote: !!this.esFormData?.tracking_email_footnote,
    });
  }

  get trackingUpdatesStatuses(): string[] {
    return (
      this.esFormData?.tracking_email_send_by_statuses?.map((status) =>
        this.$translate.instant(`settings.customer-emails.triggers.${status}`)
      ) || []
    );
  }
}

const TrackingEmailComponent = {
  controller: TrackingEmail,
  template,
  bindings: {
    esFormData: '<',
  },
};

export { TrackingEmailComponent };
