import { IComponentController } from 'angular';
import { PaymentType } from '@client/data/payment';
import template from './payment-method-icon.html?raw';
import style from './payment-method-icon.module.scss';

class PaymentMethodIcon implements IComponentController {
  style = style;
  esPaymentType: PaymentType | '' = '';

  get iconFileUrl(): string {
    return new URL(`./assets/${this.esPaymentType}.svg`, import.meta.url).href;
  }
}

const PaymentMethodIconComponent: ng.IComponentOptions = {
  controller: PaymentMethodIcon,
  template,
  bindings: {
    esPaymentType: '<',
  },
};

export { PaymentMethodIconComponent };
