/* eslint-disable consistent-return */
import { toastError } from '@client/core/components/react/Toastify';
import poweredByStripeImageUrl from '@assets/images/dashboard/add-credit/powered-by-stripe.svg';
import template from './credit-card-form.html?raw';
import style from './credit-card-form.module.scss';

const PAYMENT_FORM_ID = 'top-up-payment-form';

class CreditCardForm {
  poweredByStripeImageUrl = poweredByStripeImageUrl;

  static $inject = [
    'PaymentSourceService',
    'PaymentFormService',
    'UserSession',
    'PaymentService',
    '$q',
    'API',
    'MixpanelService',
    '$scope',
    'SubscriptionPaymentService',
    'SubscriptionService',
    'HelperService',
    '$translate',
  ];

  constructor(
    PaymentSourceService,
    PaymentFormService,
    UserSession,
    PaymentService,
    $q,
    API,
    MixpanelService,
    $scope,
    SubscriptionPaymentService,
    SubscriptionService,
    HelperService,
    $translate
  ) {
    this.style = style;
    this.PaymentSourceService = PaymentSourceService;
    this.PaymentFormService = PaymentFormService;
    this.UserSession = UserSession;
    this.PaymentService = PaymentService;
    this.$q = $q;
    this.API = API;
    this.$scope = $scope;
    this.MixpanelService = MixpanelService;
    this.SubscriptionPaymentService = SubscriptionPaymentService;
    this.SubscriptionService = SubscriptionService;
    this.HelperService = HelperService;
    this.$translate = $translate;

    this.PaymentSourceService.saveCreditCard = false;
    this.showPaymentForm = false;
    this.loadingCards = false;
    this.submittingPayment = false;
    this.showAddress = this.UserSession.hasFixedOriginForShipping();
    this.allowDeleteCards = false;
    this.paymentFormId = this.paymentFormId || PAYMENT_FORM_ID;
  }

  $onInit() {
    if (!this.esIsAddCreditCardForm) {
      this._fetchCards();
    }

    this.PaymentService.registerPaymentForm(this.paymentFormId, () =>
      this.$q.resolve(this._submitForm())
    );

    const companyId = this.UserSession.getCompanyId();
    if (companyId) {
      this.SubscriptionService.fetchCurrentSubscription(
        {
          company_id: companyId,
        },
        false
      );
    } else {
      toastError(this.$translate.instant('toast.default-error'));
    }
    this.clickCardAction = false;
  }

  $onDestroy() {
    this.PaymentService.unregisterPaymentForm(this.paymentFormId);
  }

  $onChanges(changesObj) {
    if (changesObj.esIsThreeDsIncomplete?.currentValue) {
      this.backToSavedCard();
    }
  }

  onSelectCard(card) {
    this.PaymentSourceService.selectCard(card);
    this.showPaymentForm = false;

    this.esOnSelectCard({ card });
  }

  onSelectNewCard() {
    this.PaymentSourceService.selectCard(null);
    this.showPaymentForm = true;
    this.esClearCardError?.();
  }

  onSaveCreditCardChange(value) {
    this.PaymentSourceService.saveCreditCard = value;
  }

  onClickCard() {
    this.clickCardAction = true;

    if (this.esIsSubscriptionForm) {
      this.MixpanelService.track('Subscription - Update Billing - Select Card');
    }
  }

  canSaveCreditCard() {
    const paymentSettings = this.UserSession.getCompanyPaymentSettings();
    return paymentSettings && paymentSettings.save_credit_card;
  }

  _fetchCards() {
    this.loadingCards = true;
    this.clickCardAction = false;

    return this.PaymentSourceService.getCreditCards()
      .then(() => {
        if (!this.PaymentSourceService.haveValidPaymentSource) {
          this.PaymentSourceService.selectCard(null);
          this.showPaymentForm = true;
        } else {
          // Pick default card on load
          this.PaymentSourceService.selectedCard = this.PaymentSourceService.getDefaultCard();
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loadingCards = false;
      });
  }

  async _submitForm() {
    if (this.submittingPayment) return;

    /**
     * Validate the form, including the payment form component which
     * validates through Stripe.js
     */
    const { valid } = await this._validateForm();

    if (!valid) {
      this.$scope.$apply(() => {
        toastError(this.$translate.instant('toast.incomplete-form'));
      });

      return;
    }

    this.submittingPayment = true;
    let payment;
    try {
      /**
       * Submit the payment data to /payments and save the new credit card if
       * the user has indicated so
       */

      // Get the card selected
      const { selectedCard } = this.PaymentSourceService;

      // Set payment amount in service
      if (!this.PaymentSourceService.paymentAmount)
        this.PaymentSourceService.paymentAmount = this.paymentAmount;

      // If no card is selected -> proceed to the one time payment flow
      const usingCreditCardForm = selectedCard === null;

      if (usingCreditCardForm) {
        // submitPaymentForm will also proceed with the payment
        const paymentFormValue = await this.PaymentFormService.submitPaymentForm(
          this.paymentFormId
        );

        // Return value if form is using inside a subscription mode
        if (this.esIsSubscriptionForm || this.esIsAddCreditCardForm) {
          return paymentFormValue;
        }
        return paymentFormValue;
      }
      if (this.esIsSubscriptionForm && this.UserSession.getCompanyId()) {
        return {
          card: selectedCard,
        };
      }

      payment = await this._submitPayment(selectedCard);
      return payment;
    } finally {
      this.submittingPayment = false;
    }
  }

  async _validateForm() {
    const usingCreditCardForm = this.PaymentSourceService.selectedCard === null;
    let valid = true;

    if (usingCreditCardForm) {
      this.formController.$$controls.forEach((ngModelController) => {
        ngModelController.$commitViewValue();
        ngModelController.$setDirty();
      });

      valid = this.formController.$valid;
    }

    return { valid };
  }

  async _submitPayment(card = {}) {
    if (card.payment_intent_flow) {
      return this.PaymentSourceService.pay(card);
    }

    return {
      currency: this.paymentCurrency,
      description: `${this.paymentCurrency} ${this.paymentAmount}`,
      platform: 'Stripe',
      token: card.source_id,
      total: this.paymentAmount,
    };
  }

  getSelectedCard() {
    const { creditCards, haveValidPaymentSource, selectedCard } = this.PaymentSourceService;
    if (
      this.esIsSubscriptionForm &&
      this.SubscriptionService.currentSubscription &&
      !this.clickCardAction &&
      haveValidPaymentSource
    ) {
      const subscriptionCard = this.SubscriptionService.findDefaultSubscriptionCard(creditCards);
      if (subscriptionCard) {
        return subscriptionCard;
      }
    }
    return selectedCard;
  }

  backToSavedCard() {
    this._fetchCards();
    this.showPaymentForm = false;
  }
}

const CreditCardFormComponent = {
  controller: CreditCardForm,
  template,
  bindings: {
    paymentFormId: '@',
    ngDisabled: '<',
    esIsSubscriptionForm: '<',
    esIsAddCreditCardForm: '<',
    esIsThreeDsIncomplete: '<',
    esOnSelectCard: '&',
    onSubmit: '&',
    esClearCardError: '&',
  },
};

export { CreditCardFormComponent };
