import { IApiConfig } from 'typings/core/config/api';
import { States } from 'typings/states';

class StatesResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = this.$resource(
      `${this.API.endpoint}/states`,
      {},
      {
        getStates: {
          method: 'GET',
          cache: true,
        },
      }
    );
  }

  get(): ng.IPromise<States.IApiGetSuccessResponse> {
    return this.resource.getStates().$promise;
  }
}

export { StatesResource };
