import { IComponentController } from 'angular';
import template from './shipment-list-handover-cell.html?raw';
import style from './shipment-list-handover-cell.module.scss';

enum Status {
  PaidPickup = 'paid_pickup',
  FreePickup = 'free_pickup',
  Dropoff = 'dropoff',
  FreeAndPaidPickup = 'free_and_paid_pickup',
}

class ShipmentListHandoverCell implements IComponentController {
  style = style;
  status = Status;
  esCourierHandoverOptions: string | null = null;
  sanitizedOptions: string[] = [];

  $onChanges(): void {
    if (this.esCourierHandoverOptions) {
      this.sanitizedOptions = this.sanitizeCourierHandoverOptions(this.esCourierHandoverOptions);
    }
  }

  private sanitizeCourierHandoverOptions(handoverOptions: string): string[] {
    const arrayOptions = handoverOptions.split(',');
    const finalArray = [];

    if (arrayOptions.includes(Status.Dropoff)) {
      finalArray.push(Status.Dropoff);
    }

    if (arrayOptions.includes(Status.FreePickup) && arrayOptions.includes(Status.PaidPickup)) {
      finalArray.push(Status.FreeAndPaidPickup);
    } else if (arrayOptions.includes(Status.FreePickup)) {
      finalArray.push(Status.FreePickup);
    } else if (arrayOptions.includes(Status.PaidPickup)) {
      finalArray.push(Status.PaidPickup);
    }

    return finalArray;
  }
}

const ShipmentListHandoverCellComponent: ng.IComponentOptions = {
  controller: ShipmentListHandoverCell,
  template,
  bindings: {
    esCourierHandoverOptions: '<',
  },
};

export { ShipmentListHandoverCellComponent };
