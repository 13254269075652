import React, { createContext, useContext, useMemo } from 'react';
import { UserRolePort } from '../gateways/ports/userRole';
import NgUserRoleGateway from '../gateways/api/NgUserRoleGateway';
import FakeUserRoleGateway from '../gateways/fake/FakeUserRoleGateway';

export interface UserRoleGatewayContextData {
  userRoleGateway: UserRolePort;
}

const UserRoleGatewayContext = createContext<UserRoleGatewayContextData | undefined>(undefined);

interface UserRoleGatewayProviderProps {
  children: React.ReactNode;
}

function UserRoleGatewayProvider({ children }: UserRoleGatewayProviderProps) {
  const data: UserRoleGatewayContextData = useMemo(
    () => ({
      userRoleGateway: new NgUserRoleGateway(),
    }),
    []
  );

  return <UserRoleGatewayContext.Provider value={data}>{children}</UserRoleGatewayContext.Provider>;
}

interface FakeUserRoleGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<UserRoleGatewayContextData>;
}

function FakeUserRoleGatewayProvider({ children, value }: FakeUserRoleGatewayProviderProps) {
  const data: UserRoleGatewayContextData = useMemo(
    () => ({
      userRoleGateway: new FakeUserRoleGateway(),
      ...value,
    }),
    [value]
  );

  return <UserRoleGatewayContext.Provider value={data}>{children}</UserRoleGatewayContext.Provider>;
}

function useUserRoleGateway() {
  const userRoleGatewayContext = useContext(UserRoleGatewayContext);

  if (userRoleGatewayContext === undefined) {
    throw new Error('userRoleGatewayContext must be used within a UserRoleGatewayProvider');
  }
  return userRoleGatewayContext;
}

export { UserRoleGatewayProvider, FakeUserRoleGatewayProvider, useUserRoleGateway };
