import { IInputGroupRadioModel } from 'typings/form';
import { IGroupedArray } from 'typings/helper';
import { IAdvancedSearchMultiselectObject } from 'typings/advanced-search';

import { InsuranceScopeValue } from '@client/src/global/services/shipment-list/data/normalize';
import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import template from './insurance-multi-select-group.html?raw';
import style from './insurance-multi-select-group.module.scss';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class InsuranceMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchMultiselectObject>
  implements IComponentController
{
  style = style;
  defaultFilters: IGroupedArray<IAdvancedSearchMultiselectObject>[] = [];
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions = [];

  static $inject = ['$scope', '$translate'];
  constructor($scope: ng.IScope, private $translate: angular.translate.ITranslateService) {
    super($scope);
  }

  $onInit(): void {
    const noun = this.$translate.instant('global.insurance');
    this.radioModel = [
      {
        title: this.$translate.instant('global.relation.prefixed.is_any_of', { noun }),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: this.$translate.instant('global.relation.prefixed.is_none_of', { noun }),
        value: MultiselectComponentModelValue.NoneOf,
      },
      // Disable For Insurance Search
      // {
      //   title: 'Insurance is known',
      //   value: MultiselectComponentModelValue.Known,
      // },
      // {
      //   title: 'Insurance is unknown',
      //   value: MultiselectComponentModelValue.Unknown,
      // },
    ];
    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    if (this.defaultFilters.length === 0) {
      const $t = (key: string) => {
        return this.$translate.instant(`shipments.filter.insurance.checkbox-options.${key}`);
      };

      this.defaultFilters = [
        {
          display: this.$translate.instant('global.all'),
          value: '',
          options: [
            {
              display: $t('premium'),
              value: InsuranceScopeValue.PremiumInsurance,
              isDisplay: true,
            },
            {
              display: $t('not-insured'),
              value: InsuranceScopeValue.NoInsurance,
              isDisplay: true,
            },
            {
              display: $t('basic'),
              value: InsuranceScopeValue.CourierInsurance,
              isDisplay: true,
            },
          ],
        },
      ];
    }

    this.triggerChangeIfKnowOrUnknowModel(changes);
    this.assignDefaultSelectValue(this.esModelOptions || this.defaultFilters);
  }
}

const InsuranceMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: InsuranceMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { InsuranceMultiSelectGroupComponent };
