import { IApiConfig } from 'typings/core/config/api';
import { Restrictions } from 'typings/dashboard/services/restrictions';

const DEFAULT_PARAMS = { v: 'v1' };

class RestrictionsResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = this.$resource(
      `${this.API.baseEndpoint}/:v/restrictions/:controller`,
      DEFAULT_PARAMS,
      {}
    );
  }

  shipment(
    params: Restrictions.IShipmentParams
  ): ng.IPromise<Restrictions.IShipmentGetSuccessResponse> {
    const mergeParams = {
      controller: 'shipment',
      ...params,
    };

    return this.resource.get(mergeParams).$promise;
  }
}

export { RestrictionsResource };
