import { CrowdfundingPlatform, EcommercePlatform, OtherChannel } from '@client/data/platform';
import { PlatformModalContentProps } from '@client/src/connect/new-connect/components/PlatformModalContent/types';
import { ContactSupportModalDescription } from '@client/src/connect/new-connect/components/modal-description/ContactSupportModalDescription';
import React, { Fragment, Suspense, lazy } from 'react';

const PLATFORM_FORM_CONTENTS = {
  [EcommercePlatform.Amazon]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/AmazonForm')
  ),
  [OtherChannel.API]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/ApiForm')
  ),
  [CrowdfundingPlatform.BackerKit]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/BackerkitForm')
  ),
  [EcommercePlatform.BuyOnGoogle]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/BuyOnGoogleForm')
  ),
  [EcommercePlatform.ChannelAdvisor]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/ChannelAdvisorForm')
  ),
  [EcommercePlatform.Dear]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/DearInventoryForm')
  ),
  [EcommercePlatform.eBay]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/EbayForm')
  ),
  [EcommercePlatform.Etsy]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/EtsyForm')
  ),
  [EcommercePlatform.Groupon]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/GrouponForm')
  ),
  [EcommercePlatform.OnBuy]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/OnBuyForm')
  ),
  [EcommercePlatform.Sellbrite]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/SellbriteForm')
  ),
  [EcommercePlatform.Shopline]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/ShoplineForm')
  ),
  [EcommercePlatform.Shopmatic]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/ShopmaticForm')
  ),
  [EcommercePlatform.Shoptiq]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/ShoptiqForm')
  ),
  [EcommercePlatform.SkuVault]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/SkuVaultForm')
  ),
  [EcommercePlatform.Square]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/SquareForm')
  ),
  [EcommercePlatform.TikTok]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/TikTokForm')
  ),
  [EcommercePlatform.WalMart]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/WalmartForm')
  ),
  [EcommercePlatform.Wix]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/WixForm')
  ),
  [EcommercePlatform.ZohoInventory]: lazy(
    () => import('@client/src/connect/new-connect/components/forms/ZohoForm')
  ),
};

const PLATFORM_MODAL_DESCRIPTION_CONTENTS = {
  [EcommercePlatform.BigCommerce]: lazy(
    () =>
      import(
        '@client/src/connect/new-connect/components/modal-description/BigCommerceModalDescription'
      )
  ),
  [EcommercePlatform.Ecwid]: lazy(
    () =>
      import('@client/src/connect/new-connect/components/modal-description/EcwidModalDescription')
  ),
  [EcommercePlatform.Linnworks]: lazy(
    () =>
      import(
        '@client/src/connect/new-connect/components/modal-description/LinnworksModalDescription'
      )
  ),
  [EcommercePlatform.Magento]: lazy(
    () =>
      import('@client/src/connect/new-connect/components/modal-description/MagentoModalDescription')
  ),
  [EcommercePlatform.Shopify]: lazy(
    () =>
      import('@client/src/connect/new-connect/components/modal-description/ShopifyModalDescription')
  ),
  [EcommercePlatform.ShopifyPlus]: lazy(
    () =>
      import(
        '@client/src/connect/new-connect/components/modal-description/ShopifyPlusModalDescription'
      )
  ),
  [EcommercePlatform.Squarespace]: lazy(
    () =>
      import(
        '@client/src/connect/new-connect/components/modal-description/SquarespaceModalDescription'
      )
  ),
  [EcommercePlatform.Weebly]: lazy(
    () =>
      import('@client/src/connect/new-connect/components/modal-description/WeeblyModalDescription')
  ),
  [EcommercePlatform.WooCommerce]: lazy(
    () =>
      import(
        '@client/src/connect/new-connect/components/modal-description/WooCommerceModalDescription'
      )
  ),
  [EcommercePlatform.Zapier]: lazy(
    () =>
      import('@client/src/connect/new-connect/components/modal-description/ZapierModalDescription')
  ),
  [CrowdfundingPlatform.Kickstarter]: lazy(
    () =>
      import(
        '@client/src/connect/new-connect/components/modal-description/KickstarterModalDescription'
      )
  ),
};

const PLATFORM_CONTENTS = { ...PLATFORM_FORM_CONTENTS, ...PLATFORM_MODAL_DESCRIPTION_CONTENTS };

const CONTACT_SUPPORT_CONTENT_PLATFORMS = [
  EcommercePlatform.TradeGecko,
  EcommercePlatform.Zalora,
  EcommercePlatform.Jumia,
  EcommercePlatform.Linio,
  EcommercePlatform.MatahariMall,
  EcommercePlatform.Brightpearl,
];

export function PlatformModalContent({ platform }: PlatformModalContentProps) {
  const platformSlug = platform.cssClass;
  const Component =
    PLATFORM_CONTENTS[platformSlug as keyof typeof PLATFORM_CONTENTS] ||
    (CONTACT_SUPPORT_CONTENT_PLATFORMS.includes(platformSlug as EcommercePlatform) &&
      (() => <ContactSupportModalDescription platformName={platform?.displayName as string} />)) ||
    Fragment;

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  );
}
