import angular from 'angular';
import { AngularSplitShipmentExplanationModal } from './SplitShipmentExplanationModal';

const MODULE_NAME = 'app.automation-components';

angular
  .module(MODULE_NAME, [])
  .component('esSplitShipmentExplanationModal', AngularSplitShipmentExplanationModal);

export default MODULE_NAME;
