/* eslint-disable no-param-reassign */
import { toastError } from '@client/core/components/react/Toastify';
import template from './customer-shipment-rates.html?raw';

// TODO: Deprecate and use the es-customer-shipment-rates
(function () {
  const DEFAULT_QUANTITY = 1;

  angular.module('easyshipDashboardApp').directive('customerShipmentRates', customerShipmentRates);

  customerShipmentRates.$inject = [
    '$q',
    '$timeout',
    '$state',
    'UserSession',
    'Shipment',
    'ShipmentCache',
    'ShipmentList',
    'FlatRateBoxService',
    'MixpanelService',
    'editShipmentCourier',
    'FlatRatesModal',
  ];

  function customerShipmentRates(
    $q,
    $timeout,
    $state,
    UserSession,
    Shipment,
    ShipmentCache,
    ShipmentList,
    FlatRateBoxService,
    MixpanelService,
    editShipmentCourier,
    FlatRatesModal
  ) {
    const directive = {
      template,
      restrict: 'EA',
      scope: {
        unavailableServices: '=',
        isDomestic: '=',
        rates: '=',
        nested: '=',
        page: '=',
        shipmentIndex: '=',
        close: '&',
        shipment: '=',
        enquiry: '=',
        setAsResidential: '<',
        identifiedAsResidential: '<',
        residentialFeeApplied: '<',
      },
      link,
    };

    return directive;

    function link(scope) {
      scope.state = $state;
      scope.UserSession = UserSession;
      scope.ShipmentList = ShipmentList;
      scope.ShipmentCache = ShipmentCache;
      scope.overwritingCourier = false;
      scope.rateOrder = 'total_charge';

      scope.showUpsTrademarkMessage = function () {
        if (scope.rates) {
          return scope.rates.some(function (rate) {
            return rate.courier_logo === 'ups';
          });
        }
        return false;
      };

      scope.changeCourier = function (rate) {
        const shipment =
          scope.shipment || scope.ShipmentCache.pages[scope.page].shipments[scope.shipmentIndex];
        const flatRateBoxIds = shipment.parcels_attributes.reduce(function (agg, parcel) {
          if (parcel.packageable_type === 'FlatRateBox') agg.push(parcel.packageable_id);
          return agg;
        }, []);
        const flatRateBoxes = flatRateBoxIds.map(function (id) {
          return FlatRateBoxService.getBox(id);
        });
        const showFlatRatesModal =
          flatRateBoxes.some(function (flatRateBox) {
            return flatRateBox.logo_url !== rate.courier_logo;
          }) && rate.courier_service_code.indexOf('USPS_Lightweight') > -1;

        if (showFlatRatesModal) {
          scope.close();

          $timeout(function () {
            FlatRatesModal.open({
              changeCourier() {
                return scope.shipment
                  ? scope.overwriteCourierById(rate)
                  : scope.overwriteCourierByIndex(rate);
              },
              openShipmentRatesModal() {
                if (!scope.shipment) {
                  editShipmentCourier.open({
                    rates: shipment.price_comparison.rates,
                    sortRatesBy: scope.rateOrder,
                    shipmentIndex: scope.shipmentIndex,
                    buyerShippingInfos: shipment.buyer_selected_courier_data || {},
                    platformName: shipment.platform_name,
                    buyerSelectedCourier: shipment.buyer_selected_courier || {},
                    page: scope.page,
                    hasMissingDimensions: shipment.has_missing_dimensions,
                    shipmentId: shipment.id,
                    setAsResidential: shipment.set_as_residential,
                    identifiedAsResidential: shipment.identified_as_residential,
                    residentialFeeApplied: scope.residentialFeeApplied,
                  });
                }
              },
            });
          }, 300);
        } else {
          scope.shipment ? scope.overwriteCourierById(rate) : scope.overwriteCourierByIndex(rate);
        }
      };

      scope.companyIsNotEful = function () {
        return !scope.UserSession.isCompanyEfulfilment();
      };

      scope.overwriteCourierByIndex = function (rate) {
        scope.overwritingCourier = true;
        rate.busy = true;

        return $q(function (resolve, reject) {
          Shipment.overwriteCourier(
            {
              company_type: 'cloud',
              company_id: scope.UserSession.company.id,
              id: scope.ShipmentCache.pages[scope.ShipmentCache.currentPage].shipments[
                scope.shipmentIndex
              ].id,
            },
            { include: 'orders_totals,shipment_items,list_of_couriers,store', courier: rate },
            function (data) {
              scope.ShipmentCache.totals = data.totals;

              if (data.shipment) {
                MixpanelService.track('Edit Shipment Courier - Success', {
                  easyship_shipment_id: data.shipment.easyship_shipment_id,
                  old_courier:
                    scope.ShipmentCache.pages[scope.ShipmentCache.currentPage].shipments[
                      scope.shipmentIndex
                    ].courier.name,
                  old_price:
                    scope.ShipmentCache.pages[scope.ShipmentCache.currentPage].shipments[
                      scope.shipmentIndex
                    ].shipment_charge_total,
                  new_courier: data.shipment.courier.name,
                  new_price: data.shipment.shipment_charge_total,
                });
              }

              data.shipment.isShipmentChecked =
                scope.ShipmentCache.pages[scope.ShipmentCache.currentPage].shipments[
                  scope.shipmentIndex
                ].isShipmentChecked;
              data.shipment.showCouriers =
                scope.ShipmentCache.pages[scope.ShipmentCache.currentPage].shipments[
                  scope.shipmentIndex
                ].showCouriers;
              scope.ShipmentList.updateShipment(
                scope.ShipmentCache.pages[scope.ShipmentCache.currentPage].shipments[
                  scope.shipmentIndex
                ].easyship_shipment_id,
                data.shipment
              );
              scope.overwritingCourier = false;
              rate.busy = false;

              scope.close();

              resolve();
            },
            function (err) {
              if (err.data) {
                MixpanelService.track('Edit Shipment Courier - Failure', {
                  easyship_shipment_id:
                    scope.ShipmentCache.pages[scope.ShipmentCache.currentPage].shipments[
                      scope.shipmentIndex
                    ].easyship_shipment_id,
                  error: err.data.errors,
                });

                const errorMessage = err.data.errors
                  ? err.data.errors
                  : 'Unable to select this courier, please select a different one instead';
                toastError(errorMessage);
              }

              scope.overwritingCourier = false;
              rate.busy = false;

              reject();
            }
          );
        });
      };

      scope.overwriteCourierById = function (rate) {
        scope.overwritingCourier = true;
        rate.busy = true;

        return $q(function (resolve, reject) {
          Shipment.overwriteCourier(
            {
              company_type: 'cloud',
              company_id: scope.UserSession.company.id,
              id: scope.shipment.id,
            },
            { include: 'orders_totals,shipment_items,list_of_couriers,store', courier: rate },
            function (data) {
              if (data.shipment) {
                MixpanelService.track('Edit Shipment Courier - Success', {
                  easyship_shipment_id: data.shipment.easyship_shipment_id,
                  old_courier: scope.shipment.courier.name,
                  old_price: scope.shipment.shipment_charge_total,
                  new_courier: data.shipment.courier.name,
                  new_price: data.shipment.shipment_charge_total,
                });
              }

              scope.shipment = angular.copy(data.shipment);
              scope.overwritingCourier = false;
              rate.busy = false;

              resolve();
            },
            function (err) {
              if (err.data) {
                MixpanelService.track('Edit Shipment Courier - Failure', {
                  easyship_shipment_id: scope.shipment.easyship_shipment_id,
                  error: err.data.errors,
                });

                const errorMessage = err.data.errors
                  ? err.data.errors
                  : 'Unable to select this courier, please select a different one instead';
                toastError(errorMessage);
              }

              scope.overwritingCourier = false;
              rate.busy = false;

              reject();
            }
          );
        });
      };

      scope.sendToSingleShipment = function (courierId, isFlatRateBox) {
        const params = Object.assign(scope.enquiry, { quote_selected_courier_id: courierId });

        if (!params.useExistingAddress) {
          params.origin_address = {
            country_id: params.origin_country_id,
            postal_code: params.origin_postal_code,
            city: params.origin_city,
          };
        }

        delete params.origin_country_id;
        delete params.origin_postal_code;

        if (params.excluded_courier_admin_names) delete params.excluded_courier_admin_names;
        if (params.rc) delete params.rc;

        if (params.destination_state) {
          params.state = params.destination_state.name;
          delete params.destination_state;
        }

        if (params.destination_city) {
          params.city = params.destination_city;
          delete params.destination_city;
        }

        params.items[0].item_category_id = params.items[0].category_id;
        delete params.items[0].category_id;

        params.total_actual_weight = params.items[0].actual_weight;
        delete params.items[0].actual_weight;

        params.items[0].quantity = DEFAULT_QUANTITY;

        params.shipment_items_attributes = params.items;
        delete params.items;

        params.platform_name = 'Direct Sales';

        params.origin_page = $state.current.name;

        // This is done to allow the passage of shipment items array to single shipment without disrupting the BE ShipmentAction.update/save processes
        params.temp_shipment_items = params.shipment_items_attributes;
        delete params.shipment_items_attributes;

        // Make custom box if selected box is flat rate but service selected is not flat rate
        if (params.box.flat_rate_box_id && !isFlatRateBox) {
          delete params.box.flat_rate_box_id;
        }

        $state.go('receiver-info', { shipment: params });
      };

      scope.sortCouriersBy = function (field) {
        if (scope.rates.length > 0) {
          scope.rateOrder = field;
        }

        MixpanelService.track('Sort Courier By', {
          sort_courier_by: field,
        });
      };

      scope.isUserRoleSettingPricesActive = function () {
        return scope.UserSession.isUserRoleSettingPricesActive();
      };
    }
  }
})();
