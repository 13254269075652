import React from 'react';
import ReactRootProviders from '@app/contexts/providers/ReactRootProviders';
import { UserSessionProvider } from '@app/contexts/providers/UserSessionProvider';

import { FormProvider, useForm } from 'react-hook-form';
import { react2angular } from 'react2angular';
import {
  ConnectCourierFormCanadaPost,
  ConnectCourierFormCanadaPostProps,
} from '@app/pages/couriers/components/ConnectCourierComponent/CanadaPostForm';

function WrappedConnectCourierFormCanadaPost(props: ConnectCourierFormCanadaPostProps) {
  const methods = useForm({ mode: 'all' });

  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <FormProvider {...methods}>
          <ConnectCourierFormCanadaPost {...props} />
        </FormProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularConnectCourierFormCanadaPost = react2angular(
  WrappedConnectCourierFormCanadaPost,
  ['editMode', 'onBack', 'onDelete']
);
