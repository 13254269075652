import angular from 'angular';
import angularSvgRoundProgressbar from 'angular-svg-round-progressbar';

import { ProgressComponent } from './progress.component';

const MODULE_NAME = 'core.components.progress';

angular
  .module(MODULE_NAME, [angularSvgRoundProgressbar])
  .component('easyshipProgress', ProgressComponent);

export default MODULE_NAME;
