import { useEffect, useState } from 'react';
import { UpdateColumnSettingPayload } from '@typings/dashboard/shipment_table/settings';
import useShipmentsTableSettingsQuery from '../hooks/queries/useShipmentTableSettingsQuery';
import { ColumnVisibilityMap } from './utils';

const omitCollectionStatusColumn = (
  columns: ColumnVisibilityMap
): Omit<ColumnVisibilityMap, 'collection_status_column'> => {
  const { collection_status_column: c, ...updatedColumns } = columns;
  return updatedColumns;
};

export const useVisibleColumns = (isTaxCollectEnabled?: boolean) => {
  const { data: visibleColumns = {} as ColumnVisibilityMap } = useShipmentsTableSettingsQuery();
  const [visibleColumnsMap, setVisibleColumnsMap] =
    useState<UpdateColumnSettingPayload>(visibleColumns);
  useEffect(() => {
    if (!isTaxCollectEnabled) {
      const updatedColumns = isTaxCollectEnabled
        ? visibleColumns
        : omitCollectionStatusColumn(visibleColumns);
      setVisibleColumnsMap(updatedColumns);
      return;
    }

    setVisibleColumnsMap(visibleColumns);
  }, [visibleColumns, isTaxCollectEnabled]);

  return {
    visibleColumns,
    visibleColumnsMap,
    setVisibleColumnsMap,
  };
};
