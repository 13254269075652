(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('offClick', offClick);

  offClick.$inject = ['$parse', '$rootScope'];
  function offClick($parse, $rootScope) {
    var id = 0;
    var listeners = {};

    document.addEventListener('touchend', offClickEventHandler, true);
    document.addEventListener('mousedown', offClickEventHandler, true);

    function targetInFilter(target, elms) {
      if (!target || !elms) return false;
      for (var i = 0; i < elms.length; ++i) {
        if (elms[i].contains(target)) return true;
      }
      return false;
    }

    function offClickEventHandler(event) {
      var target = event.target || event.srcElement;
      angular.forEach(listeners, function (listener, i) {
        if (!(listener.elm.contains(target) || targetInFilter(target, listener.offClickFilter))) {
          $rootScope.$evalAsync(function () {
            listener.cb(listener.scope, {
              $event: event,
            });
          });
        }
      });
    }

    return {
      restrict: 'A',
      compile: function ($element, attr) {
        var fn = $parse(attr.offClick);
        return function (scope, element) {
          var elmId = id++;
          var offClickFilter, removeWatcher;

          offClickFilter = document.querySelectorAll(scope.$eval(attr.offClickFilter));

          if (attr.offClickIf) {
            removeWatcher = $rootScope.$watch(
              function () {
                return $parse(attr.offClickIf)(scope);
              },
              function (newVal) {
                newVal ? on() : off();
              }
            );
          } else {
            on();
          }

          attr.$observe('offClickFilter', function (value) {
            offClickFilter = document.querySelectorAll(scope.$eval(value));
          });

          scope.$on('$destroy', function () {
            off();
            if (removeWatcher) removeWatcher();
          });

          function on() {
            listeners[elmId] = {
              elm: element[0],
              cb: fn,
              scope: scope,
              offClickFilter: offClickFilter,
            };
          }

          function off() {
            delete listeners[elmId];
          }
        };
      },
    };
  }
})();
