import React, { createContext, useContext, useMemo } from 'react';
import { ShipmentsTableSettingsPort } from '../gateways/ports/shipmentsTableSettings';
import NgShipmentsTableSettingsGateway from '../gateways/api/NgShipmentsTableSettingsGateway';
import FakeShipmentsTableSettingsGateway from '../gateways/fake/FakeShipmentsTableSettingsGateway';

export interface ShipmentsTableSettingsContextData {
  shipmentsTableSettingsGateway: ShipmentsTableSettingsPort;
}

const ShipmentsTableSettingsGatewayContext = createContext<
  ShipmentsTableSettingsContextData | undefined
>(undefined);

interface ShipmentsTableSettingsGatewayProviderProps {
  children: React.ReactNode;
}

function ShipmentsTableSettingsGatewayProvider({
  children,
}: ShipmentsTableSettingsGatewayProviderProps) {
  const data: ShipmentsTableSettingsContextData = useMemo(
    () => ({
      shipmentsTableSettingsGateway: new NgShipmentsTableSettingsGateway(),
    }),
    []
  );

  return (
    <ShipmentsTableSettingsGatewayContext.Provider value={data}>
      {children}
    </ShipmentsTableSettingsGatewayContext.Provider>
  );
}

interface FakeShipmentsTableSettingsGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<ShipmentsTableSettingsContextData>;
}

function FakeShipmentsTableSettingsGatewayProvider({
  children,
  value,
}: FakeShipmentsTableSettingsGatewayProviderProps) {
  const data: ShipmentsTableSettingsContextData = useMemo(
    () => ({
      shipmentsTableSettingsGateway: new FakeShipmentsTableSettingsGateway(),
      ...value,
    }),
    [value]
  );

  return (
    <ShipmentsTableSettingsGatewayContext.Provider value={data}>
      {children}
    </ShipmentsTableSettingsGatewayContext.Provider>
  );
}

function useShipmentsTableSettingsGateway() {
  const shipmentsTableSettingsGatewayContext = useContext(ShipmentsTableSettingsGatewayContext);

  if (shipmentsTableSettingsGatewayContext === undefined) {
    throw new Error(
      'shipmentsTableSettingsGatewayContext must be used within a ShipmentsTableSettingsGatewayProvider'
    );
  }

  return shipmentsTableSettingsGatewayContext;
}

export {
  ShipmentsTableSettingsGatewayProvider,
  FakeShipmentsTableSettingsGatewayProvider,
  useShipmentsTableSettingsGateway,
};
