import angular from 'angular';

import easyshipProgress from '@client/core/components/utilities/progress';
import easyshipInput from '@client/core/components/forms/input';
import easyshipButton from '@client/core/components/buttons/button';
import helperService from '@client/core/services/helper';
import mixpanelService from '@client/core/services/mixpanel';
import growsumoService from '@client/src/global/growsumo';
import { RegistrationFLowFormLayoutComponent } from './registration-flow-form.component';

import '@client/app/factories/GoogleAnalytics/GoogleAnalytics.service';
import '@client/app/services/AbTestsService/AbTestsService.service';

const MODULE_NAME = 'auth.global.layout.registration-flow-form';

const legacyModules = ['app.factory.GoogleAnalytics', 'app.service.AbTestsService'];

angular
  .module(MODULE_NAME, [
    easyshipProgress,
    easyshipInput,
    easyshipButton,
    helperService,
    mixpanelService,
    growsumoService,
    ...legacyModules,
  ])
  .component('registrationFlowFormLayout', RegistrationFLowFormLayoutComponent);

export default MODULE_NAME;
