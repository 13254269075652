import { COUNTRY_ID } from '@client/data/country';
import { ValueOf } from 'type-fest';
import { StorePickupFeeSetting } from 'typings/store';

const PICKUP_FEE_OPTIONS: Partial<Record<ValueOf<typeof COUNTRY_ID>, StorePickupFeeSetting[]>> = {
  [COUNTRY_ID.UnitedStates]: [
    {
      umbrella_name: 'FedEx',
      is_included: true,
      displayName: 'FedEx',
      fee: 4,
    },
  ],
  [COUNTRY_ID.HongKong]: [
    {
      umbrella_name: 'UPS',
      is_included: true,
      displayName: 'UPS®️ (through SF Express)',
      fee: 25,
    },
  ],
  [COUNTRY_ID.Singapore]: [
    {
      umbrella_name: 'FedEx',
      is_included: true,
      displayName: 'FedEx (through Qxpress)',
      fee: 3.99,
    },
    {
      umbrella_name: 'DHL',
      is_included: true,
      displayName: 'DHL (through Qxpress)',
      fee: 3.99,
    },
    {
      umbrella_name: 'DHL eCommerce',
      is_included: true,
      displayName: 'DHL eCommerce (through Qxpress)',
      fee: 3.99,
    },
  ],
  [COUNTRY_ID.UnitedKingdom]: [
    {
      umbrella_name: 'Hermes',
      is_included: true,
      displayName: 'Hermes',
      fee: 0.45,
    },
    {
      umbrella_name: 'UPS',
      is_included: true,
      displayName: 'UPS®️',
      fee: 2.29,
    },
    {
      umbrella_name: 'P2P',
      is_included: true,
      displayName: 'P2P',
      fee: 0.45,
    },
    {
      umbrella_name: 'FedExCrossBorder',
      is_included: true,
      displayName: 'FedEx Cross Border',
      fee: 0.45,
    },
  ],
};

export default PICKUP_FEE_OPTIONS;
