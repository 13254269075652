import angular from 'angular';

import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import { ContactSalesModal } from './contact-sales-modal.service';
import { ContactSalesComponent } from './contact-sales/contact-sales.component';
import { ContactSalesSuccessComponent } from './contact-sales-success/contact-sales-success.component';

const MODULE_NAME = 'app.global.contact-sales';

angular
  .module(MODULE_NAME, [
    onboardingChecklistService,
    'app.global.user',
    'app.factory.UserSession',
    'core.components',
  ])
  .component('contactSales', ContactSalesComponent)
  .component('contactSalesSuccess', ContactSalesSuccessComponent)
  .service('ContactSalesModal', ContactSalesModal);

export default MODULE_NAME;
