'use strict';
import template from './invite-confirm.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('home.auth.invite-confirm', {
      url: 'invitation/:emailConfirmationToken',
      template,
      controller: 'InviteConfirmCtrl',
      authenticate: false,
    });
  },
]);
