import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import template from './shipment-list-courier-cell.html?raw';
import style from './shipment-list-courier-cell.module.scss';

class ShipmentListCourierCell implements IComponentController {
  style = style;
  esCourierName: string | null = null;
  esDropdown = false;
  esDisabled = false;
  esShipmentIndex: number | null = null;

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  esOnEditClick(value: any) {
    // esOnEditClick expression bindings, need to add this in order for typescript to successfully compile
  }

  onEditClick($event: MouseEvent): void {
    if (!this.esDropdown || this.esDisabled) return;

    this.esOnEditClick({ event: $event, index: this.esShipmentIndex });
  }

  get normalizeCourierName(): string | null {
    return this.esCourierName && this.esCourierName.replace(/'/g, '&#39;');
  }

  get isShipmentPanelViewActive(): boolean {
    return !!this.UserSession.showAdvancedShipmentPanelView();
  }

  get isTooltipEnable(): boolean {
    if (this.esCourierName) {
      return this.esCourierName.length > 65;
    }

    return false;
  }
}

const ShipmentListCourierCellComponent: ng.IComponentOptions = {
  controller: ShipmentListCourierCell,
  template,
  bindings: {
    esCourierName: '<',
    esDropdown: '<',
    esDisabled: '<',
    esLogoUrl: '<',
    esShipmentIndex: '<',
    esOnEditClick: '&',
    esHideBorder: '<',
    esLogoSize: '<',
    esCostRank: '<',
    esDeliveryTimeRank: '<',
    esValueRank: '<',
  },
};

export { ShipmentListCourierCellComponent };
