/* eslint-disable no-param-reassign */
import { toastError } from '@client/core/components/react/Toastify';

(function () {
  angular.module('easyshipDashboardApp').controller('LoginCtrl', LoginCtrl);

  LoginCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$rootScope',
    '$location',
    '$translate',
    '$timeout',
    'Auth',
    'UrlPath',
    'AUTH_EVENTS',
    'ApiKeys',
    'vcRecaptchaService',
  ];

  function LoginCtrl(
    $scope,
    $state,
    $stateParams,
    $rootScope,
    $location,
    $translate,
    $timeout,
    Auth,
    UrlPath,
    AUTH_EVENTS,
    ApiKeys,
    vcRecaptchaService
  ) {
    const vm = this;

    vm.UrlPath = UrlPath;
    vm.ApiKeys = ApiKeys;
    vm.vcRecaptchaService = vcRecaptchaService;
    vm.locale = 'en';
    vm.locales = [
      { code: 'en', name: 'English' },
      { code: 'fr', name: 'Français' },
      // { code: 'it', name: 'Italiano' },
      // { code: 'zh-Hant', name: '繁體中文' },
    ];

    const ignoredRoutes = ['home.auth.password-new'];

    (function init() {
      vm.user = {};
      vm.showClosedAccountMessage = $stateParams.accountClosed;

      if (Auth.isLoggedIn() === true && !$stateParams.userEmail) {
        $location.path('/dashboard');
      } else if ($stateParams.userEmail) {
        vm.user.email = $stateParams.userEmail;
      }

      sanitizeReturnToState();
    })();

    vm.isLocaleSelectorAvailable = function () {
      return Auth.showLocaleSelector;
    };

    vm.onInputChange = function (value, key) {
      vm.user[key] = value;
    };

    /**
     * [login] Triggers to login function allowing user to enter the dashboard
     * @return {N/A}
     */
    vm.login = function () {
      vm.busy = true;

      if (vm.loginForm.$error.required) {
        vm.busy = false;
        $timeout(() => {
          toastError($translate.instant('toast.incomplete-form'));
        });

        return;
      }

      if (vm.loginForm.$error.recaptcha) {
        vm.busy = false;
        vm.vcRecaptchaService.execute();
      }
    };

    vm.authenticate = function (recaptchaResponse) {
      vm.busy = true;

      Auth.login(vm.user, null, recaptchaResponse)
        .catch(function (reason) {
          $timeout(() => {
            if (reason !== "Your email and password don't match.") {
              toastError($translate.instant('toast.default-error'));
            }
          });
        })
        .finally(function () {
          vm.busy = false;
        });
    };

    /**
     * [goToResetPage] Redirects user to reset password page and pass email if applicable
     * @return {N/A}
     */
    vm.goToResetPage = function directToResetPageAndPassEmail() {
      $state.go('home.auth.password-reset', { userEmail: vm.user.email });
    };

    vm.setLocale = function (locale) {
      vm.locale = locale;
      $translate.use(locale);
    };

    // Set the recaptcha widget id once loaded, this can be use to reload the widget
    vm.setRecaptchaWidgetId = function (widgetId) {
      vm.widgetId = widgetId;
    };

    // Store the response on successful validation
    vm.setRecaptchaResponse = function (response) {
      vm.recaptchaResponse = response;
    };

    function _reloadRecaptcha() {
      vm.recaptchaResponse = null;

      vm.vcRecaptchaService.reload(vm.widgetId);
    }

    /**
     * [sanitizeReturnToState] Clears the returnToState and returnToParams if user is coming from the ignoredRoutes
     * @return {N/A}
     */
    function sanitizeReturnToState() {
      if (ignoredRoutes.indexOf($rootScope.returnToState) !== -1) {
        $rootScope.returnToState = null;
        $rootScope.returnToParams = null;
      }
    }

    // Intercept the failed event and shows the errors.
    // @param {Object} e
    // @param {Object} response
    $scope.$on(AUTH_EVENTS.loginFailed, function (e, data) {
      vm.busy = false;
      vm.loginFail = true;

      const status = data.response?.data?.status;
      if (status === "Your email and password don't match.") {
        vm.error = 'login.errors.no-match';
      } else if (status) {
        vm.error = status;
      }

      _reloadRecaptcha();
    });

    // Redirect after login
    // If there was a state destination saved, use it
    // else go to the dashboard homepage
    $scope.$on(AUTH_EVENTS.loginSuccess, function () {
      if ($rootScope.returnToState) {
        $state.go($rootScope.returnToState, $rootScope.returnToParams);

        // After redirection, clear variables
        $rootScope.returnToState = null;
        $rootScope.returnToParams = null;
      } else {
        $state.go('app.home');
      }
    });

    $scope.$on(AUTH_EVENTS.fullLoginSuccess, function () {
      if ($rootScope.returnToState) {
        $state.go($rootScope.returnToState, $rootScope.returnToParams);

        // After redirection, clear variables
        $rootScope.returnToState = null;
        $rootScope.returnToParams = null;
      } else {
        $state.go('app.home');
      }
    });
  }
})();
