const API = {
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  credentials: import.meta.env.VITE_APP_CREDENTIALS,
  domain: import.meta.env.VITE_APP_DOMAIN,
  endpoint: import.meta.env.VITE_APP_ENDPOINT,
  baseEndpoint: import.meta.env.VITE_APP_BASE_ENDPOINT,
  publicEndpoint: import.meta.env.VITE_APP_PUBLIC_ENDPOINT,
  homepage: import.meta.env.VITE_APP_HOMEPAGE, // FIXME: This should be move to url constant
  dashboard: import.meta.env.VITE_APP_DASHBOARD, // FIXME: This should be move to url constant
  cms_api: import.meta.env.VITE_APP_CMS_API,
  help: import.meta.env.VITE_APP_HELP, // FIXME: This should be move to url constant
  tracking: import.meta.env.VITE_APP_TRACKING, // FIXME: This should be move to url constant
  easyship_storage: import.meta.env.VITE_APP_STORAGE,
  website_api: import.meta.env.VITE_WEBSITE_API,
};

export { API };
