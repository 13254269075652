import React from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import COLORS from '@client/src/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useColorStyles = makeStyles<Theme, ChipProps>((theme) => ({
  default: {
    backgroundColor: theme.palette.ink.light,
    '&:focus': { backgroundColor: theme.palette.ink.light },
    '&:hover': { backgroundColor: ({ clickable }) => clickable && `${COLORS.inkFaded} !important` },
  },
  ink: {
    backgroundColor: theme.palette.ink.main,
    '&:focus': { backgroundColor: theme.palette.ink.main },
    '&:hover': { backgroundColor: ({ clickable }) => clickable && `${COLORS.inkLight} !important` },
  },
  teal: {
    backgroundColor: theme.palette.teal.main,
    '&:focus': { backgroundColor: theme.palette.teal.main },
    '&:hover': {
      backgroundColor: ({ clickable }) => clickable && `${theme.palette.teal.dark} !important`,
    },
  },
  blue: {
    backgroundColor: theme.palette.blue.main,
    '&:focus': { backgroundColor: theme.palette.blue.main },
    '&:hover': {
      backgroundColor: ({ clickable }) => clickable && `${theme.palette.blue.dark} !important`,
    },
  },
  green: {
    backgroundColor: theme.palette.green.main,
    '&:focus': { backgroundColor: theme.palette.green.main },
    '&:hover': {
      backgroundColor: ({ clickable }) => clickable && `${theme.palette.green.dark} !important`,
    },
  },
  yellow: {
    backgroundColor: theme.palette.yellow.main,
    '&:focus': { backgroundColor: theme.palette.yellow.main },
    '&:hover': {
      backgroundColor: ({ clickable }) => clickable && `${theme.palette.yellow.dark} !important`,
    },
  },
  red: {
    backgroundColor: theme.palette.red.main,
    '&:focus': { backgroundColor: theme.palette.red.main },
    '&:hover': {
      backgroundColor: ({ clickable }) => clickable && `${theme.palette.red.dark} !important`,
    },
  }
}));

const useSizeStyles = makeStyles(() => ({
  small: {
    fontSize: 11,
  },
  mini: {
    fontSize: 10,
  },
}));

interface ChipProps extends Omit<MuiChipProps, 'variant' | 'color' | 'size'> {
  variant?: 'default' | 'rounded';
  color?: 'default' | 'ink' | 'teal' | 'blue' | 'green' | 'yellow' | 'red';
  size?: 'default' | 'small' | 'mini';
  grid?: boolean;
}

export default function Chip({
  variant = 'default',
  color = 'default',
  size = 'default',
  grid,
  className,
  ...props
}: ChipProps): JSX.Element {
  const style: React.CSSProperties = props.style ?? {};
  const colorClasses = useColorStyles(props);
  const sizeClasses = useSizeStyles();
  const classList = [colorClasses[color]];
  const clickable = props.clickable !== false && props.onClick ? true : props.clickable;

  if (className) {
    classList.push(className);
  }

  if (variant === 'rounded') {
    style.borderRadius = '100px';
  }

  if (grid) {
    style.display = 'grid';
  }

  if (size !== 'default') {
    classList.push(sizeClasses[size]);
  }

  return (
    <MuiChip
      component="span"
      className={classList.join(' ')}
      style={style}
      clickable={clickable}
      {...props}
    />
  );
}
