import template from './click-to-copy-input-box.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('clickToCopyInputBox', clickToCopyInputBox);

  clickToCopyInputBox.$inject = ['$timeout'];
  function clickToCopyInputBox($timeout) {
    return {
      template,
      restrict: 'EA',
      scope: {
        inputContent: '@',
        placeholder: '@',
      },
      link: function (scope) {
        scope.copyToken = function () {
          scope.showCopied = true;

          $timeout(function () {
            scope.showCopied = false;
          }, 750);
        };
      },
    };
  }
})();
