import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import { toastMessage } from '@client/core/components/react/Toastify';
import { ArrowsPending } from 'easyship-components/icons';
import { useIntl } from 'react-intl';
import { UpsOauthGatewayProvider } from '../../providers/UpsOauthGatewayProvider';
import useSendCourierAccountTokenMutation from '../../hooks/mutations/useSendCourierAccountTokenMutation';
import CourierConnectResultModal from '../CourierConnectResultModal';

// This is just a blank component that is only functioning to call API needed in Angular side of code.
// The purpose is to minimize the usage of Angular service since this is reducing the Angular service calling
// by calling the API through React and axios
interface CourierBlankComponentProps {
  code: string;
  courierAccountId: string;
  onConnectionSuccess: () => void;
}

let didInit = false;

export function CourierBlankComponent({
  code,
  courierAccountId,
  onConnectionSuccess,
}: CourierBlankComponentProps) {
  const { mutateAsync: sendCourierAccountTokenMutateAsync, isLoading } =
    useSendCourierAccountTokenMutation();
  const { company } = useUserSession();
  const [nickname, setNickname] = useState<string | null>(null);

  const { formatMessage } = useIntl();

  // When UPS connection success it will redirect back to /couriers and show the modal window
  // To obtain nickname go to localStorage and get the element by courierAccountId
  const getNicknameFromLocalStorage = (id: string): string | null => {
    const data = localStorage.getItem(id);
    if (data) {
      const accountDetails = JSON.parse(data);
      return accountDetails.accountName;
    }
    return null;
  };
  const accountNickname = getNicknameFromLocalStorage(courierAccountId);
  const handleClose = () => {
    setNickname(null);
    if (accountNickname) {
      localStorage.removeItem(courierAccountId);
    }
  };

  useEffect(() => {
    const payload = { code };

    if (!didInit && accountNickname) {
      didInit = true;
      if (code && courierAccountId) {
        setNickname(accountNickname);

        toastMessage(formatMessage({ id: 'courier.lyoc-courier.ups-ready.connecting' }), {
          icon: <ArrowsPending className="animate-spin" fontSize="small" />,
        });

        sendCourierAccountTokenMutateAsync({
          companyId: company.id,
          payload,
          courierAccountId,
        }).then(() => {
          onConnectionSuccess();
        });
      }
    }
  });
  return (
    <div>
      {nickname && !isLoading && (
        <CourierConnectResultModal onClose={handleClose} nickname={nickname} />
      )}
    </div>
  );
}

export function WrappedCourierBlankComponent(props: CourierBlankComponentProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <UpsOauthGatewayProvider>
          <CourierBlankComponent {...props} />
        </UpsOauthGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularCourierBlankComponent = react2angular(WrappedCourierBlankComponent, [
  'code',
  'courierAccountId',
  'onConnectionSuccess',
]);
