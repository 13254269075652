import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { Box, Link } from '@material-ui/core';
import Accordion from '@client/core/components/react/Accordion';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import autoBoxExplanationImageUrl from '@assets/images/dashboard/boxes/auto-box-explanation.png';

interface AutoBoxExplainerProps {
  open: boolean;
  onClose: () => void;
}

export default function AutoBoxExplainer({
  open = true,
  onClose,
}: AutoBoxExplainerProps): JSX.Element {
  const [expanded, setExpanded] = useState('panel1');
  const { features, user } = useUserSession();

  const handleAccordionClick =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : '');
    };

  function supportLink(chunks: string) {
    if (features.canUseTeamRightsAndPermission && !user.hasUserRole('product_listing')) {
      return <span>{chunks}</span>;
    }
    return <Link href="/product-listing">{chunks}</Link>;
  }
  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div className="text-xl">
            <FormattedMessage id="settings.boxes.auto-box.how-it-works-title" />
          </div>
          <IconButton aria-label="close modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="!px-12 !py-16">
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <div className="mb-16 text-base">
              <FormattedMessage id="settings.boxes.auto-box.algorithm-selection" />
              <ul className="list-disc list-inside">
                <li>
                  <FormattedMessage id="settings.boxes.auto-box.fits-all-items" />
                </li>
                <li>
                  <FormattedMessage id="settings.boxes.auto-box.smallest-capacity-among-selected" />
                </li>
              </ul>
            </div>
            <img alt="auto box explanation" src={autoBoxExplanationImageUrl} width="80%" />
          </Grid>
          <Grid item xs={6}>
            <Accordion
              onChange={handleAccordionClick('panel1')}
              id="panel1"
              expanded={expanded === 'panel1'}
              title={<FormattedMessage id="settings.boxes.auto-box.faq.algorithm-apply.title" />}
            >
              <FormattedMessage id="settings.boxes.auto-box.faq.algorithm-apply.description" />
            </Accordion>
            <Accordion
              onChange={handleAccordionClick('panel2')}
              id="panel2"
              expanded={expanded === 'panel2'}
              title={
                <FormattedMessage id="settings.boxes.auto-box.faq.boxes-marked-fit-items.title" />
              }
            >
              <FormattedMessage
                id="settings.boxes.auto-box.faq.boxes-marked-fit-items.description"
                values={{ a: supportLink }}
              />
            </Accordion>
            <Accordion
              onChange={handleAccordionClick('panel3')}
              id="panel3"
              expanded={expanded === 'panel3'}
              title={<FormattedMessage id="settings.boxes.auto-box.faq.missing-dimensions.title" />}
            >
              <FormattedMessage
                id="settings.boxes.auto-box.faq.missing-dimensions.description"
                values={{ a: supportLink }}
              />
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export function WrappedAutoBoxExplainer(
  props: AutoBoxExplainerProps
): ReactElement<AutoBoxExplainerProps> {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <AutoBoxExplainer {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularAutoBoxExplainer = react2angular(WrappedAutoBoxExplainer, ['open', 'onClose']);
