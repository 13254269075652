import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import template from './confirmation-modal.html?raw';
import style from './confirmation-modal.module.scss';

class ConfirmationModal implements IComponentController {
  style = style;

  static $inject = ['MixpanelService', 'OnboardingChecklistService'];
  constructor(
    private MixpanelService: MixpanelService,
    private OnboardingChecklistService: OnboardingChecklistService
  ) {}

  esShowChecklistModal(): void {
    this.esShowChecklistModal();
  }

  esClose(): void {
    this.esClose();
  }

  turnOffOnboarding(): void {
    this.OnboardingChecklistService.turnOffOnboarding();
    this.MixpanelService.track("Checklist - Don't show - Confirm");
    this.esClose();
  }
}

const ConfirmationModalComponent: ng.IComponentOptions = {
  controller: ConfirmationModal,
  template,
  bindings: {
    esShowChecklistModal: '&',
    esClose: '&',
  },
};

export { ConfirmationModalComponent };
