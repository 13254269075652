(function () {
  'use strict';

  OnBuyService.$inject = ['$q', 'PlatformApi', 'UserSession', 'StoreService'];
  function OnBuyService($q, PlatformApi, UserSession, StoreService) {
    this.authenticate = function (store, credentials) {
      return $q(function (resolve, reject) {
        PlatformApi.onbuy.save(
          { company_id: UserSession.company.id },
          { store: Object.assign({ credentials: credentials }, store) },
          function (response) {
            StoreService.saveStoreValueToLocal(response.store);
            StoreService.stores.push(response.store);
            resolve(response);
          },
          reject
        );
      });
    };
  }

  angular.module('app.service.OnBuyService', []).service('OnBuyService', OnBuyService);
})();
