import React from 'react';
import { Box } from '@material-ui/core';
import countryList from 'flag-icons/country.json';

import { twMerge } from 'tailwind-merge';
import style from './CountryFlag.r.module.scss';

interface CountryFlagProps {
  alpha2?: string;
  className?: string;
}

interface CountryProperty {
  code: string;
  flag_1x1: string;
  flag_4x3: string;
  iso: boolean;
  name: string;
}

export default function CountryFlag({ alpha2, className }: CountryFlagProps) {
  const lowerCaseAlpha2 = alpha2?.toLowerCase();
  const isFlagAvailable =
    alpha2 && countryList.some((country: CountryProperty) => country.code === lowerCaseAlpha2);
  const flagClassName =
    style[
      isFlagAvailable
        ? `fi${alpha2.charAt(0).toUpperCase()}${alpha2.slice(1).toLowerCase()}`
        : `fi-xx`
    ];

  return (
    <Box
      component="span"
      style={{
        height: 16,
        width: 20,
        borderRadius: 2,
        boxShadow: isFlagAvailable ? '0px 0px 1px 0px rgba(0,0,0,0.15)' : 'none',
      }}
      className={twMerge(style.fi, style.fib, flagClassName, className)}
    />
  );
}
