ReschedulePickup.$inject = ['$resource', 'API'];
function ReschedulePickup($resource, API) {
  const endpointUrl = API.endpoint;

  return $resource(
    `${endpointUrl}/companies/:company_id/reschedule_pickup/:controller`,
    {
      id: '@_company_id',
    },
    {
      prepare: {
        method: 'POST',
        params: {
          controller: 'prepare',
        },
      },
      confirm: {
        method: 'POST',
        params: {
          controller: 'confirm',
        },
      },
    }
  );
}

export { ReschedulePickup };
