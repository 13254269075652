import angular from 'angular';

import { NotificationCentreRowComponent } from './notification-centre-row/notification-centre-row.component';
import { NotificationCentrePanelComponent } from './notification-centre-panel/notification-centre-panel.component';
import { NotificationCentreBellComponent } from './notification-centre-bell/notification-centre-bell.component';
import { NotificationCentreGroupComponent } from './notification-centre-group/notification-centre-group.component';

const MODULE_NAME = 'app.global.components.notification-centre';

const prefix = 'NotificationCentre';

angular
  .module(MODULE_NAME, [
    'ui.router',
    'core.filters',
    'app.global.services.notification-centre',
    'app.global.helper',
  ])
  .component(`es${prefix}Row`, NotificationCentreRowComponent)
  .component(`es${prefix}Panel`, NotificationCentrePanelComponent)
  .component(`es${prefix}Bell`, NotificationCentreBellComponent)
  .component(`es${prefix}Group`, NotificationCentreGroupComponent);

export default MODULE_NAME;
