import { Box, Button } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HelpContactSupportFeature } from '../types';

interface ICtaButtonProps {
  feature: HelpContactSupportFeature;
  onClick: () => void;
}
export default function CtaButton({ feature, onClick }: ICtaButtonProps) {
  return (
    <Box clone width={150}>
      <Button variant="contained" color="primary" onClick={onClick}>
        <FormattedMessage id={`help-guide.contact-support.${feature}.cta`} />
      </Button>
    </Box>
  );
}
