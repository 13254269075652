'use strict';
import template from '../../multiple-shipments/order-summary/order-summary.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('order-summary', {
      url: '/order-summary',
      parent: 'app.single',
      template,
      controller: 'OrderSummaryCtrl',
      params: {
        fullShipment: null,
      },
    });
  },
]);
