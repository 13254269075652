import { IComponentController } from 'angular';
import template from './one-action-card.html?raw';
import styleOneActionCard from './one-action-card.module.scss';

const CONTENT_PADDING = 50;
const BOTTOM_PADDING = 20;

class OneActionCard implements IComponentController {
  style = styleOneActionCard;
  esShowCloseButton = true;
  esButtonType = 'primary';
  esButtonFullWidth = true;
  esTitleCenter = true;
  esContentPadding = CONTENT_PADDING;
  esBottomPadding = 0;
  esCardWidth = 500;
  esButtonCenter = true;

  $onInit() {
    if (this.esShowCloseButton === undefined) {
      this.esShowCloseButton = true;
    }

    if (this.esTitleCenter === undefined) {
      this.esTitleCenter = true;
    }

    if (!this.esButtonType) {
      this.esButtonType = 'primary';
    }

    if (this.esButtonFullWidth === undefined) {
      this.esButtonFullWidth = true;
    }

    if (!this.esContentPadding) {
      this.esContentPadding = CONTENT_PADDING;
    }

    if (!this.esBottomPadding) {
      this.esBottomPadding = BOTTOM_PADDING;
    }

    if (!this.esCardWidth) {
      this.esCardWidth = 500;
    }

    if (this.esButtonCenter === undefined) {
      this.esButtonCenter = true;
    }
  }
}

const OneActionCardComponent: ng.IComponentOptions = {
  controller: OneActionCard,
  template,
  transclude: {
    title: '?actionCardTitle',
    content: '?actionCardContent',
  },
  bindings: {
    esButtonType: '<',
    esButtonText: '<',
    esButtonLeftIcon: '@',
    esButtonRightIcon: '@',
    esButtonFullWidth: '<',
    esButtonWidth: '<',
    esButtonCenter: '<',
    esButtonAction: '&',
    esButtonDisabled: '<',
    esButtonLoading: '<',
    esContentPadding: '<',
    esBottomPadding: '<',
    esCardWidth: '<',
    esSubmit: '<',
  },
};

export { OneActionCardComponent };
