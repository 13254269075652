import { PlatformData } from '@client/src/platform/models/platform';
import clsx from 'clsx';
import { Card } from 'easyship-components';
import React from 'react';

export interface PlatformCardProps {
  platform: PlatformData;
  onPlatformClicked(): void;
}

export function PlatformCard({ platform, onPlatformClicked }: PlatformCardProps) {
  function handleCardClicked(): void {
    onPlatformClicked();
  }

  const logoSrc = `https://assets.easyship.com/app/platform-logos/color/${platform.cssClass}-color.svg`;

  return (
    <Card
      className="flex items-center group gap-x-1"
      variant="outlined"
      onClick={handleCardClicked}
    >
      <div
        className={clsx(
          `flex items-center justify-center h-[69px] w-[69px] rounded-tl-md rounded-bl-md group-hover:platform-colors ${platform.cssClass}`
        )}
      >
        <img
          src={logoSrc}
          className="w-10 group-hover:brightness-0 group-hover:invert"
          alt={`${platform.displayName} logo`}
        />
      </div>

      <span className="text-base text-ink-900">{platform.displayName}</span>
    </Card>
  );
}
