import template from './item-row.html?raw';
import style from './item-row.module.scss';

class ItemRow {
  constructor() {
    this.style = style;
  }
}

const ItemRowComponent = {
  template,
  transclude: true,
  controller: ItemRow,
  bindings: {
    ngDisabled: '<',
  },
};

export { ItemRowComponent };
