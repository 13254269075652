import template from './incart-installation-success.html?raw';

(function () {
  'use strict';

  incartSuccessModal.$inject = ['$uibModal'];
  function incartSuccessModal($uibModal) {
    var service = this;

    service.open = function (params) {
      var modalInstance = $uibModal.open({
        template,
        controller: 'incartSuccessModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        openedClass: 'easyship-modal-default',
        resolve: {
          params: function () {
            return params;
          },
        },
      });
    };
  }

  angular
    .module('app.service.incart-installation-modal')
    .service('incartSuccessModal', incartSuccessModal);
})();
