import { IUserSession } from 'typings/user-session';
import { IUserService } from 'typings/user-service';
import {
  AddressFieldValidatorsMap,
  IAddress,
  IAddressService,
  IAddressUpdateError,
  LoqateSuggestion,
} from 'typings/address';
import { ICourierAccountsService, ICourierService } from 'typings/courier';
import { IPostalCodeService } from 'typings/postal-code';
import { ADDRESS_LENGTH_LIMIT } from '@client/data/address';

import { COUNTRY_ID } from '@client/data/country';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './address-editor-card.module.scss';
import template from './address-editor-card.html?raw';

class AddressEditorCardController implements IComponentController {
  style = style;
  editing = false;
  confirmingDeactivation = false;
  confirmingPostalCodeChange = false;
  postalCodeChangeConfirmed = false;
  // busy.postalCode is set by <address-forms-container> with two-way-binding
  busy = { settingDefault: false, saving: false, deactivating: false, postalCode: false };
  error = { postalCode: false, invalidPickup: false, stillDefault: false, deactivation: false };
  addressNotFoundMessage?: string;
  uspsPickupValidity?: string;
  doesntSplitSenderAndPickup = false;
  addressCopy!: IAddress;
  correctedAddresses?: IAddress[];
  correctedAddress?: IAddress;
  usingAddressy: boolean = import.meta.env.NODE_ENV !== 'development'; // limited by this.addressyAvailable
  addressyInfo?: LoqateSuggestion;
  oldAddressyId?: string;
  esAddress!: IAddress;
  esEditMode?: boolean;
  esCannotDelete?: boolean;
  esDeactivateMode?: boolean;
  esSetAsDefault?: (payload: { address: IAddress; useCase: string }) => void;
  esPromptDeactivate?: (address: IAddress) => void;
  allowForeignAddress = false;
  translations: Record<string, string> = {};
  fieldValidators: AddressFieldValidatorsMap = {};
  form: Partial<ng.IFormController> = {};

  static $inject = [
    '$scope',
    '$translate',
    'UserSession',
    'UserService',
    'AddressService',
    'PostalCodeService',
    'CourierService',
    'CourierAccounts',
    'MixpanelService',
  ];
  constructor(
    private $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private UserService: IUserService,
    private AddressService: IAddressService,
    private PostalCodeService: IPostalCodeService,
    private CourierService: ICourierService,
    private CourierAccounts: ICourierAccountsService,
    private MixpanelService: MixpanelService
  ) {
    this.translations = {
      address: this.$translate
        .instant('global.pluralize.address', { COUNT: 1 }, 'messageformat')
        .toLowerCase(),
    };
  }

  $onInit(): void {
    this.doesntSplitSenderAndPickup = this.UserSession.doesntSplitSenderAndPickup();
    this.allowForeignAddress =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_global_account;

    this.addressCopy = { ...this.esAddress };

    const shouldInitializePickupInstruction =
      this.AddressService.isPickupInstructionAvailable(this.esAddress.country_id) &&
      (!this.esAddress.pickup_instruction_slug ||
        this.esAddress.pickup_instruction_users_input === undefined);

    if (shouldInitializePickupInstruction) {
      this.addressCopy.pickup_instruction_slug = 'none';
      this.addressCopy.pickup_instruction_users_input = null;
    }

    this.$scope.$watch(
      () => this.addressCopy.country_id,
      (countryId) => {
        this.AddressService.getFieldValidators(countryId).then((fieldValidators) => {
          this.fieldValidators = fieldValidators;
        });
      }
    );
  }

  $onChanges(): void {
    if (this.error.stillDefault && !this.hasAnyDefaultValue) this.error.stillDefault = false;
  }

  /*
   * METHODS
   */
  confirmSave(): void {
    this.postalCodeChangeConfirmed = true;
    this.confirmingPostalCodeChange = false;

    if (this.busyAtAll) return;

    this.confirmAddress();
  }

  confirmAddress(): void {
    const hasNoInvalidFields = this.AddressService.runFieldValidators(
      this.fieldValidators,
      this.addressCopy
    );
    this.AddressService.touchAllForms(this.form);

    if (!hasNoInvalidFields) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    if (this.addressyAvailable && this.usingAddressy) {
      this.saveWithAddressy();
      return;
    }

    // Need to check that the postal code has entered is a real postal code if it is a USA company
    if (
      this.UserSession.hasFixedOriginForShipping() &&
      !this.PostalCodeService.validator.addressUsa
    ) {
      this.error.postalCode = true;
      return;
    }
    this.error.postalCode = false;
    if (!this.usingAddressy) delete this.addressCopy.addressy_id;

    // For the US we need to check if the zip code has changed and if the address is a pickup address
    // in order to show the confirmation modal (recalculating)
    if (
      // this.esAddress.default_values.pickup &&
      this.UserSession.hasFixedOriginForShipping() &&
      this.postalCodeHasChanged &&
      !this.postalCodeChangeConfirmed
    ) {
      this.confirmingPostalCodeChange = true;
      return;
    }

    this.AddressService.updateAddress(this.addressCopy)
      .then((res) => {
        if (!res) return;

        this.uspsPickupValidity = res.address?.usps_pickup_validation_state;
        // BUG: THIS IS NOT TRIGGERING <es-address-correction-modal>

        this.refreshCourierCaches();

        const eventData = this.AddressService.isPickupInstructionBetaFeatureEnabled()
          ? {
              pickup_instruction: this.AddressService.getMixpanelPickupInstructionValue(
                this.addressCopy.pickup_instruction_slug,
                this.addressCopy.pickup_instruction_users_input
              ),
            }
          : {};

        this.MixpanelService.track('Account - Edit Address', eventData);
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.address },
            'messageformat'
          )
        );
        this.editing = false;

        this.UserService.refreshShippingCountries();
      })
      .catch((err: any) => {
        this.addressNotFoundMessage = err.data.status;
        this.handleAddressCreationErrors(err.data);
      })
      .finally(() => {
        this.busy.saving = false;
      });
  }

  async saveWithAddressy(): Promise<void> {
    if (!this.addressCopy.addressy_id) return;

    this.busy.saving = true;

    const payload = {
      id: this.addressCopy.id,
      is_active: this.addressCopy.is_active,
      addressy_id: this.addressCopy.addressy_id,
      nickname: this.addressCopy.nickname,
      company_name: this.addressCopy.company_name,
      contact_email: this.addressCopy.contact_email,
      contact_name: this.addressCopy.contact_name,
      contact_phone: this.addressCopy.contact_phone,
      country_id: this.addressCopy.country_id,
    };

    this.AddressService.updateAddress(payload)
      .then((res) => {
        if (!res) return;

        this.uspsPickupValidity = res.address?.usps_pickup_validation_state;
        // BUG: THIS IS NOT TRIGGERING <es-address-correction-modal>

        this.refreshCourierCaches();
        this.MixpanelService.track('Account - Edit Address', {});
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.address },
            'messageformat'
          )
        );
        this.editing = false;
      })
      .catch((err: { data: IAddressUpdateError }) => {
        this.addressNotFoundMessage = err.data.status;
        this.handleAddressCreationErrors(err.data);
      })
      .finally(() => {
        this.busy.saving = false;
      });
  }

  handleAddressCreationErrors(errorResponse: IAddressUpdateError): void {
    if (!errorResponse?.response) return;

    switch (errorResponse.response_code) {
      case '31':
      case '32':
      case '22':
        if (errorResponse.response.suggested_addresses.length > 0) {
          this.correctedAddresses = this.AddressService.formatCorrectedAddresses(
            errorResponse.response.suggested_addresses
          );
        } else if (Object.keys(errorResponse.response.updated_fields).length > 0) {
          this.correctedAddresses = this.AddressService.formatCorrectedAddresses([
            errorResponse.response.output_address,
          ]);
        }
        break;
      default:
        this.addressNotFoundMessage = errorResponse.response.message;
        break;
    }
  }

  applyCorrection(_address: IAddress): void {
    // NOTE: I've tried to recreate the logic related to
    // client/app/directives/address-validation-steps/address-validation-step-skip
    // but I haven't been able to replicate the API error response that triggers it
  }

  cancelEdit(): void {
    this.addressCopy = { ...this.esAddress };
    this.editing = false;
  }

  toggleAddressyForm(): void {
    this.usingAddressy = !this.usingAddressy;
  }

  toggleDeactivationConfirmation(): void {
    if (!this.confirmingDeactivation && this.hasAnyDefaultValue) {
      this.error.stillDefault = true;
      return;
    }

    this.confirmingDeactivation = !this.confirmingDeactivation;
  }

  togglePostalCodeConfirmation(): void {
    this.confirmingPostalCodeChange = !this.confirmingPostalCodeChange;
  }

  deactivate(): void {
    if (!this.esAddress || this.hasAnyDefaultValue) return;

    this.busy.deactivating = true;
    this.AddressService.deactivateAddress(this.esAddress)
      .then(() => {
        this.refreshCourierCaches();
        this.MixpanelService.track('Account - Deactivate Address', {});
        toastSuccess(
          this.$translate.instant('toast.delete-success', { noun: this.translations.address })
        );

        this.UserService.refreshShippingCountries();
      })
      .catch(() => {
        this.error.deactivation = true;
        toastError(
          this.$translate.instant('toast.delete-error', { noun: this.translations.address })
        );
      })
      .finally(() => {
        this.busy.deactivating = false;
      });
  }

  private refreshCourierCaches(): void {
    this.CourierService.getCouriers({ refetch: true });
    this.CourierAccounts.getActiveCourierAccounts();
  }

  /*
   * GETTERS
   */
  get joinedAddress(): string {
    if (!this.esAddress) return '';

    const addressItems = [
      this.esAddress.line_1,
      this.esAddress.line_2,
      this.esAddress.line_3,
      this.esAddress.city,
      this.esAddress.state,
      this.esAddress.postal_code,
      this.esAddress.country.name,
    ];

    return addressItems.filter((text) => !!text).join(', ');
  }

  get addressyAvailable(): boolean {
    const availableCountries: number[] = [COUNTRY_ID.UnitedStates];

    return availableCountries.includes(this.addressCopy.country_id);
  }

  get hasAnyDefaultValue(): boolean {
    return Object.values(this.esAddress.default_values).some((value) => value);
  }

  get postalCodeHasChanged(): boolean {
    return this.esAddress.postal_code !== this.addressCopy.postal_code;
  }

  get busyAtAll(): boolean {
    return Object.values(this.busy).includes(true);
  }

  get tooLong(): boolean {
    const addressLength =
      (this.addressCopy.line_1?.length || 0) +
      (this.addressCopy.line_2?.length || 0) +
      (this.addressCopy.line_3?.length || 0);
    return addressLength > ADDRESS_LENGTH_LIMIT;
  }

  get isSaveButtonDisabled(): boolean {
    const isUsingSearchForm = this.addressyAvailable && this.usingAddressy;
    const hasSearchValueSelected = !!this.addressCopy.addressy_id;

    if (isUsingSearchForm) {
      return !hasSearchValueSelected;
    }
    return !!this.busyAtAll;
  }
}

const AddressEditorCardComponent: ng.IComponentOptions = {
  controller: AddressEditorCardController,
  template,
  bindings: {
    esAddress: '<',
    esSetAsDefault: '&',
  },
};

export { AddressEditorCardComponent };
