(function () {
  'use strict';

  ItemCategoryService.$inject = ['$q', '$translate', 'ItemCategory'];
  function ItemCategoryService($q, $translate, ItemCategory) {
    var service = this;

    service.itemCategories = [];

    service.getItemCategories = function () {
      return $q(function (resolve, reject) {
        if (service.itemCategories.length === 0) {
          ItemCategory.query(
            {},
            {},
            function (data) {
              var slugs = data.item_categories.map(function (category) {
                return 'global.product-categories.' + category.slug;
              });
              $translate(slugs).then(function (translations) {
                service.itemCategories = data.item_categories
                  .map(function (category) {
                    if (
                      translations['global.product-categories.' + category.slug] !==
                      'global.product-categories.' + category.slug
                    ) {
                      category.name = translations['global.product-categories.' + category.slug];
                    }

                    return category;
                  })
                  .sort(function (a, b) {
                    return a.name < b.name ? -1 : 1;
                  });

                resolve(service.itemCategories);
              });
            },
            function () {
              service.itemCategories = [];
              reject();
            }
          );
        } else {
          resolve(service.itemCategories);
        }
      });
    };

    /**
     * [getItemCategoryName] return the category name given its id
     * @param  {Integer} itemCatgoryId
     *
     * @return {String}  Category full name
     */
    service.getItemCategoryName = function (itemCatgoryId) {
      var match = _.find(service.itemCategories, { id: parseInt(itemCatgoryId, 10) });
      if (match) return match.name;
    };
  }

  angular
    .module('app.service.ItemCategoryService', ['app.factory.ItemCategory'])
    .service('ItemCategoryService', ItemCategoryService);
})();
