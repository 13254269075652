const ENV = import.meta.env.VITE_APP_ENVIRONMENT;
const API_KEY = import.meta.env.VITE_KEY_FINGERPRINT;

const PROXY_URL = `https://easyship.com/EsFdsKUCx2GqNoC9/qZFzm5lzLIzyKoK7?apiKey=${API_KEY ?? ''}`;
const ES_ENDPOINT = 'https://easyship.com/EsFdsKUCx2GqNoC9/XogINXdkmBYIl7in';

let fp = null;

async function initFingerprint() {
  if (fp) {
    return;
  }

  try {
    const FingerprintJS = await import(PROXY_URL);

    fp = await FingerprintJS.load({
      endpoint: [ES_ENDPOINT, FingerprintJS.defaultEndpoint],
    });
  } catch (error) {
    console.error('Error loading fingerprint:', error);
  }
}

async function getVisitorResult(id, action) {
  try {
    if (!fp && API_KEY) {
      await initFingerprint();
    }

    const visitor = await fp.get({
      linkedId: id,
      tag: {
        environment: ENV,
        trigger_action: action,
      },
    });
    return visitor;
  } catch (error) {
    console.error('Error getting visitor result:', error);
    return null;
  }
}

export { initFingerprint, getVisitorResult };
