import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { IRedirection } from '@client/src/couriers/models/data/lyoc-courier-account';
import { useUpsOauthGateway } from '../../providers/UpsOauthGatewayProvider';

interface UseUpsRedirectUrlQueryParams extends UseQueryOptions<IRedirection> {
  companyId: string;
  upsAccountId: string;
}

export function useUpsRedirectUrlQuery({
  companyId,
  upsAccountId,
  ...params
}: UseUpsRedirectUrlQueryParams) {
  const { upsOauthGateway } = useUpsOauthGateway();

  return useQuery({
    queryKey: ['upsRedirectUrl', companyId],
    queryFn: () =>
      upsOauthGateway.getUpsRedirectUrl(companyId, upsAccountId).then((res) => {
        return { authorizeUrl: res.authorize_url };
      }),
    ...params,
  });
}
