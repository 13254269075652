import template from './create-modal.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      var modalInstance;

      $stateProvider.state('createModal', {
        abstract: true,
        parent: 'app.multiple',
        url: '^/advanced',
        onEnter: [
          '$uibModal',
          '$state',
          '$rootScope',
          function ($uibModal, $state) {
            modalInstance = $uibModal.open({
              template,
              backdrop: true,
              windowClass: 'fade',
              openedClass: 'easyship-modal-multiple-shipments',
            });

            modalInstance.result.finally(function () {
              $state.go('app.multiple');
            });
          },
        ],
      });
    },
  ]);
})();
