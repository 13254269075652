import template from './shipment-action-cell.html?raw';
import style from './shipment-action-cell.module.scss';

class ShipmentActionCell {
  static $inject = ['$state', 'ShipmentCache', 'ShipmentAction', 'ShipmentHelpers'];

  constructor($state, ShipmentCache, ShipmentAction, ShipmentHelpers) {
    this.style = style;
    this.$state = $state;
    this.ShipmentCache = ShipmentCache;
    this.ShipmentAction = ShipmentAction;
    this.ShipmentHelpers = ShipmentHelpers;
  }

  $onChanges() {
    const page = this.ShipmentCache.currentPage;
    const itemIndex = this.shipmentIndex;

    this.shipmentCheckboxId = `shipment-select-${page}-${itemIndex}`;
    this.shipment = this.ShipmentCache.pages[page].shipments[itemIndex];
  }

  onCheckboxChange(value) {
    this.shipment.isShipmentChecked = value;

    const baseCheckParams = {
      pageNum: this.ShipmentCache.currentPage,
      shipmentIndex: this.shipmentIndex,
      origin: this.$state.current.name,
    };

    let params;

    if (this.$state.current.name === 'app.shipments') {
      params = { ...baseCheckParams, invalidByLabel: true };
    } else {
      params = { ...baseCheckParams, invalidOrder: true };
    }

    this.ShipmentAction.check(params);
  }
}

const ShipmentActionCellComponent = {
  controller: ShipmentActionCell,
  template,
  bindings: {
    shipmentIndex: '<',
    esShipment: '<',
  },
};

export { ShipmentActionCellComponent };
