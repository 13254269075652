import { IApiConfig } from 'typings/core/config/api';

import { IComponentController } from 'angular';
import { AutoRechargeService } from '@client/src/global/services/auto-recharge/auto-recharge.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import HotjarService, { TriggerSurveyEvent } from '@client/core/services/hotjar/hotjar.service';
import template from './auto-recharge-modal-card.html?raw';

class AutoRechargeModal implements IComponentController {
  isAutoRechargeOn = false;
  threshold: number | null = null;
  amount: number | null = null;
  esOnSave?: () => void;

  readonly numberValidation = {
    allowNegative: false,
  };
  static $inject = ['AutoRechargeService', 'API', '$translate'];
  constructor(
    private AutoRechargeService: AutoRechargeService,
    public API: IApiConfig,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit() {
    if (this.AutoRechargeService.autoRechargeData) {
      this.isAutoRechargeOn = this.AutoRechargeService.autoRechargeData.isEnabled;
      this.threshold = this.AutoRechargeService.autoRechargeData.threshold;
      this.amount = this.AutoRechargeService.autoRechargeData.amount;
    }
  }

  handleAutoRechargeToggle(value: boolean): void {
    this.isAutoRechargeOn = value;
  }

  onInputChange(value: string, key: string): void {
    switch (key) {
      case 'threshold':
        this.threshold = parseFloat(value);
        break;
      case 'amount':
        this.amount = parseFloat(value);
        break;
      default:
        break;
    }
  }

  handleSubmit(): void {
    const payload = {
      auto_recharge: {
        enabled: this.isAutoRechargeOn,
        threshold_amount: this.threshold || 0,
        amount: this.amount || 0,
      },
    };

    this.AutoRechargeService.updateAutoRechargeSettings(payload)
      .then(() => {
        toastSuccess(this.$translate.instant('account.payment.auto-recharge.settings-updated'));
        HotjarService.triggerSurveyEvent(TriggerSurveyEvent.AutoRechargeSaved);
        this.esOnSave?.();
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  get minAmount(): number {
    return this.AutoRechargeService.autoRechargeData?.limit.min || 0;
  }

  get maxAmount(): number {
    return this.AutoRechargeService.autoRechargeData?.limit.max || 0;
  }

  get isSubmitAllowed(): boolean {
    return (
      (this.isAutoRechargeOn &&
        this.threshold &&
        this.threshold >= this.minAmount &&
        this.amount &&
        this.amount >= this.minAmount &&
        this.amount <= this.maxAmount) ||
      !this.isAutoRechargeOn
    );
  }
}

const AutoRechargeModalCardComponent: ng.IComponentOptions = {
  controller: AutoRechargeModal,
  template,
  bindings: {
    esShow: '<',
    esOnSave: '&',
    esOnClose: '&',
  },
};

export { AutoRechargeModalCardComponent };
