(function () {
  'use strict';

  BuyOnGoogleService.$inject = ['$cookies', 'PlatformApi', 'StoreService', 'UserSession', 'Auth'];
  function BuyOnGoogleService($cookies, PlatformApi, StoreService, UserSession, Auth) {
    var service = this;

    service.authenticate = function () {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.buyOnGoogle
          .authenticate({ company_id: UserSession.company.id }, {})
          .$promise.then(function (response) {
            // Save state & session_token as cookies
            var stateMatch = /state=([\da-f]+)/i.exec(response.redirect_url);
            if (stateMatch) $cookies.put('google_state', stateMatch[1]);

            return response;
          });
      });
    };

    service.connectStore = function (newStore) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.buyOnGoogle
          .connect({ company_id: UserSession.company.id }, { store: newStore })
          .$promise.then(function (response) {
            StoreService.saveStoreValueToLocal(response.store);
            return response;
          });
      });
    };
  }

  angular
    .module('app.service.BuyOnGoogleService', [])
    .service('BuyOnGoogleService', BuyOnGoogleService);
})();
