import template from './analytics-details-modal.html?raw';

(function () {
  'use strict';

  analyticsDetailsModal.$inject = ['$uibModal'];
  function analyticsDetailsModal($uibModal) {
    var service = this;

    service.open = function (params) {
      service.modalInstance = $uibModal.open({
        template,
        controller: 'analyticsDetailsModalCtrl',
        controllerAs: 'vm',
        openedClass: 'easyship-modal-default',
        backdrop: true,
        windowClass: 'fade',
        resolve: {
          params: function () {
            return params;
          },
        },
      });
    };
  }

  angular
    .module('app.service.analyticsDetailsModal')
    .service('analyticsDetailsModal', analyticsDetailsModal);
})();
