import angular from 'angular';
import Adjustments from '@client/core/corelogic/models/Adjustments';
import { AdjustmentsService } from '@client/src/global/services/adjustments/adjustments.service';
import IAdjustmentsGateway from '@client/core/corelogic/ports/adjustments.interface';

export default class NgAdjustmentsGateway implements IAdjustmentsGateway {
  private AdjustmentsService: AdjustmentsService;

  constructor() {
    this.AdjustmentsService = angular
      .element(document.body)
      .injector()
      .get<AdjustmentsService>('AdjustmentsService');
  }

  fetchShippingAdjustments(companyId: string): Promise<Adjustments> {
    return new Promise((resolve) => {
      this.AdjustmentsService.getAdjustmentsData({
        company_id: companyId,
      }).then((data) => {
        resolve({
          payOnScan: {
            shipmentCount: data.shipments_count_pay_on_scan_pending_transactions,
            amount: data.pay_on_scan_pending_transactions,
          },
          additionalCharges: {
            shipmentCount: data.shipments_count_negative_pending_transactions,
            amount: data.negative_pending_transactions,
          },
          refundedCharges: {
            shipmentCount: data.shipments_count_positive_pending_transactions,
            amount: data.positive_pending_transactions,
          },
          totalCharges: data.total_to_be_charged,
          nextBillingDate: data.next_billing_date,
        });
      });
    });
  }
}
