import { IPickupsSupportInfo } from 'typings/pickup';
import { IUserSession } from 'typings/user-session';
import { IHelperService } from 'typings/helper';

import { IComponentController } from 'angular';
import { toastSuccess } from '@client/core/components/react/Toastify';
import template from './contact-support-modal.html?raw';
import style from './contact-support-modal.module.scss';

const EASYSHIP_CONTACT_EMAIL = 'support@easyship.com';

const SERVICES_WITH_HIDDEN_CONTACT_INFORMATION = [
  'DHL_DocumentsILS_CA',
  'DHL_ExpressWorldwideILS_CA',
  'UPS_Ground_US',
  'FedEx_InternationalPriorityOWC_AU',
  'FedEx_InternationalEconomyOWC_AU',
  'TNT_SensitiveExpressOWC_AU',
  'TNT_1200ExpressOWC_AU',
  'TNT_1000ExpressOWC_AU',
  'TNT_0900ExpressOWC_AU',
  'DHL_ExpressStashworks_SG',
  'DHL_ExpressStashworksQxpress_SG',
  'DHL_ExpressDGStashworksQxpress_SG',
  'FedEx_InternationalEconomyStashworks_SG',
  'FedEx_InternationalEconomyStashworksQxpress_SG',
  'FedEx_InternationalPriorityStashworks_SG',
  'FedEx_InternationalPriorityStashworksQxpress_SG',
  'FedEx_InternationalPriorityDGStashworksQxpress_SG',
  'DHL_ExpressWorldwideESG_HK',
  'DHL_ExpressWorldwideBatteryESG_HK',
  'DHL_ExpressWorldwideESGPromotion_HK',
  'Chronopost_Chrono13MyFlyingBox_FR',
  'Chronopost_Chrono13PickupMyFlyingBox_FR',
  'Chronopost_ChronoClassicInternationalMyFlyingBox_FR',
  'Chronopost_ExpressInternationalMyFlyingBox_FR',
  'Colissimo_AccessMyFlyingBox_FR',
  'Colissimo_ExpertMyFlyingBox_FR',
  'Colissimo_InternationalMyFlyingBox_FR',
  'DHL_EconomySelectMyFlyingBox_DE',
  'DHL_ExpressEconomySelectInternationalMyFlyingBox_FR',
  'DHL_ExpressEconomySelectInternationalMyFlyingBox_GB',
  'DHL_ExpressWorldwideMyFlyingBox_IT',
  'DHL_Express0900MyFlyingBox_ES',
  'DHL_Express0900MyFlyingBox_IT',
  'DHL_Express0900MyFlyingBox_DE',
  'DHL_Express0900InternationalMyFlyingBox_GB',
  'DHL_Express0900MyFlyingBox_NL',
  'DHL_Express0900InternationalMyFlyingBox_FR',
  'DHL_Express1030InternationalMyFlyingBox_ES',
  'DHL_Express1030InternationalMyFlyingBox_DE',
  'DHL_Express1030InternationalMyFlyingBox_IT',
  'DHL_Express1030MyFlyingBox_FR',
  'DHL_Express1200MyFlyingBox_IT',
  'DHL_Express1200MyFlyingBox_ES',
  'DHL_Express1200MyFlyingBox_DE',
  'DHL_Express1200MyFlyingBox_NL',
  'DHL_Express1200InternationalMyFlyingBox_FR',
  'DHL_Express1200InternationalMyFlyingBox_GB',
  'DHL_Express1800MyFlyingBox_IT',
  'DHL_Express1800MyFlyingBox_ES',
  'DHL_Express1800MyFlyingBox_DE',
  'DHL_Express1800MyFlyingBox_GB',
  'DHL_Express1800MyFlyingBox_NL',
  'DHL_Express1800MyFlyingBox_FR',
  'DHL_ExpressWorldwideMyFlyingBox_FR',
  'DHL_ExpressWorldwideMyFlyingBox_NL',
  'DHL_ExpressWorldwideMyFlyingBox_ES',
  'DHL_ExpressWorldwideMyFlyingBox_DE',
  'DHL_ExpressWorldwideMyFlyingBox_GB',
  'FedEx_InternationalEconomyMyFlyingBox_DE',
  'FedEx_InternationalPriorityMyFlyingBox_IT',
  'FedEx_InternationalPriorityMyFlyingBox_NL',
  'FedEx_InternationalPriorityMyFlyingBox_DE',
  'FedEx_InternationalPriorityMyFlyingBox_GB',
  'MondialRelay_InternationalHomeDeliveryMyFlyingBox_FR',
  'UPS_AccessPointStandardMyFlyingBox_FR',
  'UPS_ExpeditedMyFlyingBox_DE',
  'UPS_ExpeditedMyFlyingBox_FR',
  'UPS_ExpeditedMyFlyingBox_NL',
  'UPS_ExpressMyFlyingBox_DE',
  'UPS_ExpressMyFlyingBox_NL',
  'UPS_StandardITD_GB',
  'UPS_ExpressSaverITD_GB',
];

type Steps = 'contact-support' | 'report-issue';

class ContactSupportModal implements IComponentController {
  style = style;
  esPickup: IPickupsSupportInfo | null = null;
  currentStep: Steps = 'contact-support';
  isReported = false;

  static $inject = ['$translate', 'UserSession', 'HelperService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private HelperService: IHelperService
  ) {}

  $onInit(): void {
    if (!this.esPickup) return;

    if (this.esPickup.requested_at) {
      this.isReported = true;
    }
  }

  get companyWeightUnit(): string {
    return this.UserSession.getCompanyWeightUnit();
  }

  get hideContactInformation(): boolean {
    if (!this.esPickup) return false;

    return !!this.esPickup.service_names.find((serviceName) =>
      SERVICES_WITH_HIDDEN_CONTACT_INFORMATION.includes(serviceName)
    );
  }

  get contactInformation(): string {
    if (!this.esPickup) return '';

    const courierContactPhone = this.esPickup.courier_contact_phone;
    const courierContactEmail = this.esPickup.courier_contact_email;

    return courierContactPhone ?? courierContactEmail ?? EASYSHIP_CONTACT_EMAIL;
  }

  get isContactMessageVisible(): boolean {
    return !!this.esPickup?.courier_contact_phone;
  }

  esClose(): void {
    // esClose expression bindings, need to add this in order for typescript to successfully compile
  }

  copyContactInformation() {
    if (!this.esPickup) return;

    this.HelperService.copyToClipboard(this.contactInformation);
    toastSuccess(this.$translate.instant('global.copied'));
  }
}

const ContactSupportModalComponent: ng.IComponentOptions = {
  controller: ContactSupportModal,
  template,
  bindings: {
    esClose: '&',
    esPickup: '<',
  },
};

export { ContactSupportModalComponent };
