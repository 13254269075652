import angular from 'angular';

import { NotificationCentreService } from './notification-centre.service';
import { NotificationResource } from './notification-centre.resource';

const MODULE_NAME = 'app.global.services.notification-centre';

angular
  .module(MODULE_NAME, [
    'ngResource',
    'app.factory.UserSession',
    'app.global.services.pusher',
    'core.service.mixpanel',
  ])
  .service('NotificationCentreService', NotificationCentreService)
  .service('NotificationResource', NotificationResource);

export default MODULE_NAME;
