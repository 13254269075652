import template from './alert-message.html?raw';
import styles from './alert-message.module.scss';

class AlertMessage {
  constructor() {
    this.styles = styles;
  }
}

const AlertMessageComponent = {
  template,
  controller: AlertMessage,
  transclude: {
    action: '?alertAction',
  },
  bindings: {
    esType: '@',
  },
};

export { AlertMessageComponent };
