import angular from 'angular';
import _debounce from 'lodash.debounce';

import { IInputGroupValue, IInputGroupRadioModel } from 'typings/form';
import { IGroupedArray } from 'typings/helper';
import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';

export abstract class MultiSelectInputGroup<IOption extends { isDisplay?: boolean }> {
  abstract radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[];
  abstract esModelRadio: MultiselectComponentModelValue | null;
  abstract esModelInput: string | null;
  abstract esModelOptions: IGroupedArray<IOption>[];
  abstract $onInit(): void;
  optionsOffset = 0;
  filteredOptions: IGroupedArray<IOption>[] = [];
  filteredOptionsSecond: IGroupedArray<IOption>[] = [];

  constructor(private $scope: ng.IScope) {}

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  triggerChangeIfKnowOrUnknowModel(changes: any): void {
    if (this.isKnownOrUnknowModel(changes)) {
      this.triggerValuesChange();
    }
  }

  isKnownOrUnknowModel(changes: any): boolean {
    return (
      changes &&
      changes.esModelRadio &&
      changes.esModelRadio.previousValue !== changes.esModelRadio.currentValue &&
      (changes.esModelRadio.currentValue === MultiselectComponentModelValue.Known ||
        changes.esModelRadio.currentValue === MultiselectComponentModelValue.Unknown)
    );
  }

  onChange(value: IInputGroupValue<MultiselectComponentModelValue>): void {
    this.assignModelRadioInputValue(value);
    this.onValuesChange({ options: this.esModelOptions });
  }

  assignModelRadioInputValue(value: IInputGroupValue<MultiselectComponentModelValue>): void {
    this.esModelRadio = value.radio;
    this.esModelInput = value.input;
  }

  onValuesChange(object: any): void {
    this.esOnChange({
      value: {
        input: this.esModelInput,
        radio: this.esModelRadio,
        ...object,
      },
    });
  }

  filteringOptions<T>(
    groupOptions: IGroupedArray<T>[],
    filterKey: string,
    inputValue: string | number
  ): IGroupedArray<T>[] {
    const filterOptions = groupOptions.map((group) => {
      const isReversed = /^-/.test(filterKey);
      const strippedFilterKey = isReversed ? filterKey.slice(1) : filterKey;
      const currentGroup = group;
      const { options } = currentGroup;

      if (options && options.length > 0) {
        options
          .slice()
          .sort((a: any, b: any) =>
            a[strippedFilterKey] > b[strippedFilterKey] && !isReversed ? 1 : -1
          )
          .forEach((option: any) => {
            const currentOption = option;
            const currentValue = option[strippedFilterKey];
            currentOption.isDisplay = option.isDisplay;
            if (this.isNotDisplay(currentValue, inputValue)) {
              currentOption.isDisplay = false;
            }
          });
      } else {
        currentGroup.isDisplay = true;
        if (this.isNotDisplay(group.display, inputValue)) {
          currentGroup.isDisplay = false;
        }
      }

      return currentGroup;
    });

    return filterOptions;
  }

  watchAndUpdateFilter(
    options: IGroupedArray<IOption>[],
    filterKey: string,
    filterOrder?: number
  ): void {
    this.$scope.$watch('$ctrl.esModelInput', (value: string | number) => {
      if (options) {
        switch (filterOrder) {
          case 2:
            this.filteredOptionsSecond = this.filteringOptions<IOption>(options, filterKey, value);
            break;
          default:
            this.filteredOptions = this.filteringOptions<IOption>(options, filterKey, value);
            break;
        }
      }
    });
  }

  assignDefaultSelectValue(options: IGroupedArray<IOption>[]): void {
    this.esModelOptions = angular.copy(options);
    this.triggerValuesChange();
  }

  triggerValuesChange(): void {
    this.onValuesChange({
      options: this.esModelOptions,
    });
  }

  onChangesAssignAndTrigger(changes: any, displayAttr: string, callback: any): void {
    this.triggerChangeIfKnowOrUnknowModel(changes);

    if (!this.esModelOptions) {
      callback();
    } else {
      this.esModelOptions = angular.copy(this.esModelOptions);
      this.watchAndTrigger(displayAttr);
    }
  }

  watchAndTrigger(displayAttr: string): void {
    this.watchAndUpdateFilter(this.esModelOptions, displayAttr);
    this.triggerValuesChange();
  }

  watchAndRequest(callback: () => ng.IPromise<void>): void {
    this.$scope.$watch(
      '$ctrl.esModelInput',
      _debounce(() => callback().then(() => this.triggerValuesChange()), 300)
    );
  }

  assignDefaultRadioModel(): void {
    if (!this.esModelRadio) {
      this.esModelRadio = MultiselectComponentModelValue.AnyOf;
      this.triggerValuesChange();
    }
  }

  get showMultiSelect(): boolean {
    return (
      this.esModelRadio === MultiselectComponentModelValue.AnyOf ||
      this.esModelRadio === MultiselectComponentModelValue.NoneOf
    );
  }

  private isNotDisplay(currentValue: string | number, inputValue: string | number): boolean {
    return (
      (typeof currentValue === 'string' &&
        typeof inputValue === 'string' &&
        !currentValue.toLowerCase().includes(inputValue.toLowerCase())) ||
      (typeof currentValue === 'number' && currentValue !== inputValue)
    );
  }
}
