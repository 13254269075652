/* eslint-disable import/no-extraneous-dependencies */
import { Shield } from 'shield-js-npm';

const SITE_ID = import.meta.env.VITE_KEY_SHIELD_SITE_ID;

const shield = new Shield();
let shieldSessionId = null;

function initShield() {
  if (!SITE_ID) {
    return;
  }

  shield.init({ siteId: SITE_ID }).then(() => {
    shield
      .getDeviceIntelligence()
      .then((res) => {
        shieldSessionId = res.result.session_id;
      })
      .catch((err) => {
        console.error(err);
      });
  });
}

function getShieldSessionId() {
  return shieldSessionId;
}

export { initShield, getShieldSessionId };
