import { IComponentController } from 'angular';

import { IUserSession } from 'typings/user-session';
import { IBillingService, IFinancialDocuments } from 'typings/billing';

import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './subscription-invoices.html?raw';
import style from './subscription-invoices.module.scss';

class SubscriptionInvoices implements IComponentController {
  style = style;
  financialDocuments: IFinancialDocuments;
  esInvoices = [];

  static $inject = ['$translate', 'BillingService', 'UserSession'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    public BillingService: IBillingService,
    public UserSession: IUserSession
  ) {
    this.financialDocuments = BillingService.financialDocuments;
  }

  esOnSearch() {
    // expression bindings, need to add this in order for typescript to successfully compile
  }

  handleKeywordSearch(): void {
    this.esOnSearch();
  }

  openFilter(): void {
    this.BillingService.toggleAdvancedSearch();
  }

  onSearchChange(value: string): void {
    this.BillingService.filter.keyword = value;
  }

  downloadPdf(): void {
    const documentIds = this.BillingService.buildFinancialDocumentsPayload(this.financialDocuments);

    if (documentIds.length < 1) {
      toastError(
        this.$translate.instant('toast.select-error', {
          noun: this.$translate
            .instant('global.pluralize.invoice', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );
      return;
    }

    this.BillingService.downloadFinancialDocuments(documentIds)
      .then(() => {
        toastSuccess(
          this.$translate.instant('toast.email-file', {
            email: this.UserSession.user.email,
          })
        );
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  onPageCheckboxChange(value: string, id: string, page: number): void {
    if (!value && this.financialDocuments.selectedPages[page]) {
      this.financialDocuments.selectedPages[page] = value;
    }

    this.financialDocuments.selectedDocuments[id] = value;
  }

  onHeaderCheckboxChange(value: string, page: number): void {
    this.financialDocuments.selectedPages[page] = value;

    this._checkPage(value);
  }

  _checkPage(value: string): void {
    this.esInvoices.forEach(({ id }) => {
      this.financialDocuments.selectedDocuments[id] = value;
    });
  }
}

const SubscriptionInvoicesComponent = {
  template,
  controller: SubscriptionInvoices,
  bindings: {
    esOnSearch: '&',
    esInvoices: '<',
    esLoading: '<',
    esError: '<',
  },
};

export { SubscriptionInvoicesComponent };
