import template from './dashboard-multi-flow-steps.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('multiFlowSteps', multiFlowSteps);

  multiFlowSteps.$inject = ['$state', 'UserSession'];
  function multiFlowSteps($state, UserSession) {
    return {
      template,
      restrict: 'E',
      link: function (scope, element, attrs) {
        scope.state = $state;
        scope.UserSession = UserSession;
      },
    };
  }
})();
