import { IPickupsTotals, PickupGroup, IPickupsFilters } from 'typings/pickup';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './filters.html?raw';
import style from './filters.module.scss';
import { PickupsPageService } from '../pickups-page.service';

class Filters implements IComponentController {
  style = style;
  showAdvancedSearch = false;

  static $inject = ['PickupsPageService', 'MixpanelService', '$state'];
  constructor(
    private PickupsPageService: PickupsPageService,
    private MixpanelService: MixpanelService,
    private $state: ng.ui.IStateService
  ) {}

  get itemsPerPage(): number {
    return this.PickupsPageService.itemsPerPage;
  }

  get selectedState(): PickupGroup | null {
    return this.PickupsPageService.selectedState;
  }

  get totals(): IPickupsTotals | null {
    return this.PickupsPageService.totals;
  }

  get filters(): IPickupsFilters {
    return this.PickupsPageService.filters;
  }

  get hasAdvancedSearchFilters(): boolean {
    return !!Object.keys(this.PickupsPageService.filters).length;
  }

  get currentPage(): number {
    return this.PickupsPageService.currentPage;
  }

  filterState(state: PickupGroup): void {
    if (this.PickupsPageService.selectedState !== state) {
      this.PickupsPageService.selectedState = state;
      const mixpanelEventNames = {
        happening: 'Pickups happening',
        request_failed: 'Pickups failed',
        estimated_no_show: 'Estimated No Show',
      };

      this.MixpanelService.track(`Pickups - Main filter - ${mixpanelEventNames[state]}`);
      this.$state.go(this.$state.current.name as ng.ui.IState, { page: 1, group: state });
    } else {
      this.PickupsPageService.selectedState = null;

      this.$state.go(this.$state.current.name as ng.ui.IState, { page: 1, group: '' });
    }

    this.PickupsPageService.currentPage = 1;
    this.PickupsPageService.isFiltered = true;

    this.PickupsPageService.getPickups();
  }

  openFilter(): void {
    this.showAdvancedSearch = true;
  }

  closeFilter(): void {
    this.showAdvancedSearch = false;
  }

  onChangeItemsPerPage(itemsPerPage: number): void {
    this.PickupsPageService.currentPage = 1;
    this.PickupsPageService.itemsPerPage = itemsPerPage;
    this.PickupsPageService.isFiltered = true;

    this.PickupsPageService.getPickups();
  }

  onChangePage(page: number): void {
    this.PickupsPageService.currentPage = page;
    this.PickupsPageService.isFiltered = true;

    this.$state.go(this.$state.current.name as ng.ui.IState, { page });
  }
}

const FiltersComponent: ng.IComponentOptions = {
  controller: Filters,
  template,
};

export { FiltersComponent };
