import angular from 'angular';

import { ReportsResource } from './reports.resource';

const MODULE_NAME = 'app.global.services.reports';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', 'ngResource'])
  .service('ReportsResource', ReportsResource);

export default MODULE_NAME;
