(function () {
  'use strict';

  DestinationAddressesService.$inject = ['$q', 'DestinationAddresses', 'CountryService'];
  function DestinationAddressesService($q, DestinationAddresses, CountryService) {
    var service = this;

    service.luxotticaFlowOptions = [
      { code: 'damage', name: 'Damage', tag: 'damage' },
      { code: 'return', name: 'Return', tag: 'return' },
      { code: 'ftc', name: 'Frame to Come (RX)', tag: 'ftc' },
      { code: 'storeToStore', name: 'Store to Store', tag: 'store' },
      { code: 'storeToCustomer', name: 'Store to Customer', tag: 'customer' },
      { code: 'storeToVendor', name: 'Store to Vendor', tag: 'vendor' },
      { code: 'other', name: 'Others', tag: 'other' },
    ];

    service.luxotticaDefaultAddress = {
      company_name: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      line_1: '',
      line_2: '',
      postal_code: '',
      state: '',
      city: '',
      country_alpha2: 'US',
    };

    service.luxotticaReturnAddresses = [];
    service.luxotticaDamageAddresses = [];
    service.luxotticaFtcAddresses = [];
    service.glossary = {
      frames: 'Frames',
      rxJobs: 'Rx Jobs',
      rxOrder: 'Rx Order',
      contactLenses: 'Contact Lenses',
      other: 'Other (please specify)',
    };
    service.tagsGlossary = {
      frames: 'frames',
      rxJobs: 'rxjobs',
      rxOrder: 'rxorder',
      contactLenses: 'contactlenses',
      other: 'other',
    };
    service.luxotticaStoreToStoreCommodities = [
      { code: 'frames', name: service.glossary.frames, tag: service.tagsGlossary.frames },
      { code: 'rxJobs', name: service.glossary.rxJobs, tag: service.tagsGlossary.rxJobs },
      { code: 'other', name: service.glossary.other, tag: service.tagsGlossary.other },
    ];
    service.luxotticaStoreToCustomerCommodities = [
      { code: 'frames', name: service.glossary.frames, tag: service.tagsGlossary.frames },
      { code: 'rxOrder', name: service.glossary.rxOrder, tag: service.tagsGlossary.rxOrder },
      {
        code: 'contactLenses',
        name: service.glossary.contactLenses,
        tag: service.tagsGlossary.contactLenses,
      },
      { code: 'other', name: service.glossary.other, tag: service.tagsGlossary.other },
    ];
    service.luxotticaStoreToVendorCommodities = [
      { code: 'other', name: service.glossary.other, tag: service.tagsGlossary.other },
    ];
    service.defaultCommodity = 'frames';
    service.selectedLuxotticaFlow = null;
    service.selectedSearchAddress = {};
    service.selectedLuxotticaReturnAddress = undefined;
    service.selectedLuxotticaDamageAddress = undefined;
    service.selectedLuxotticaFtcAddress = undefined;
    service.selectedLuxotticaCommodity = service.defaultCommodity;
    service.luxotticaFlowSelectedCommodityList = {};

    service.resetSelectedSearchAddress = function () {
      service.selectedSearchAddress = {};
    };

    service.isLuxotticaSelectedAddress = function () {
      return Object.keys(service.selectedSearchAddress).length > 0;
    };

    service.isLuxotticaSelectedReturn = function () {
      return typeof service.selectedLuxotticaReturnAddress !== 'undefined';
    };

    service.isLuxotticaSelectedDamage = function() {
      return typeof service.selectedLuxotticaDamageAddress !== 'undefined';
    };

    service.isLuxotticaSelectedFtc = function () {
      return typeof service.selectedLuxotticaFtcAddress !== 'undefined';
    };

    service.resetLuxotticaCustomFlow = function () {
      service.selectedLuxotticaFlow = null;
    };

    service.resetLuxotticaShipmentMetadata = function (shipment) {
      shipment.metadata = {};
    };

    service.isLuxotticaCustomFormShow = function () {
      return ['return', 'damage', 'ftc', 'storeToStore'].indexOf(service.selectedLuxotticaFlow) > -1;
    };

    service.validateLuxotticaCustomForm = function () {
      switch (service.selectedLuxotticaFlow) {
        case 'return':
        case 'damage':
          return true;
        case 'ftc':
          return service.isLuxotticaSelectedFtc();
        case 'storeToStore':
          return service.isLuxotticaSelectedAddress();
      }
    };

    service.getSelectedLuxotticaFlowCommodityTag = function () {
      var selectedObject = {};
      var selectedCommodityTag = service.selectedLuxotticaCommodity;
      var composedTag = '';
      if (service.selectedLuxotticaFlow) {
        selectedObject = service.luxotticaFlowOptions.find(function (item) {
          return item.code === service.selectedLuxotticaFlow;
        });
      }
      if (service.selectedLuxotticaCommodity) {
        selectedCommodityTag = service.tagsGlossary[service.selectedLuxotticaCommodity];
      }
      composedTag = selectedObject.tag + '-' + selectedCommodityTag;
      return composedTag;
    };

    service.getSelectedLuxotticaReturnAddress = function (index) {
      return service.luxotticaReturnAddresses[index];
    };

    service.setSelectedLuxotticaReturnAddress = function (index) {
      service.selectedLuxotticaReturnAddress = index;
      return service.luxotticaReturnAddresses[index];
    };

    service.getSelectedLuxotticaDamageAddress = function(index) {
      return service.luxotticaDamageAddresses[index];
    };

    service.setSelectedLuxotticaDamageAddress = function(index) {
      service.selectedLuxotticaDamageAddress = index;
      return service.luxotticaDamageAddresses[index];
    };

    service.getSelectedLuxotticaFtcAddress = function (index) {
      return service.luxotticaFtcAddresses[index];
    };

    service.setSelectedLuxotticaFtcAddress = function (index) {
      service.selectedLuxotticaFtcAddress = index;
      return service.luxotticaFtcAddresses[index];
    };

    service.setSelectedLuxotticaFlow = function (string) {
      service.selectedLuxotticaFlow = string;
      service.setLuxotticaFlowSelectedCommodityList();
    };

    service.setSelectedLuxotticaCommodity = function (string) {
      service.selectedLuxotticaCommodity = string;
    };

    service.setAddressDataToShipment = function (shipmentObject, object) {
      var companyName = service.isStoreToStoreFlow()
        ? object.company_name + ' ' + object.name
        : object.name;
      shipmentObject.destination_company_name = companyName;
      shipmentObject.destination_name = object.contact_name;
      shipmentObject.phone_number = object.contact_phone;
      shipmentObject.email_address = object.contact_email;
      shipmentObject.address_line_1 = object.line_1;
      shipmentObject.address_line_2 = object.line_2;
      shipmentObject.postal_code = object.postal_code;
      shipmentObject.state = object.state;
      shipmentObject.city = object.city;
    };

    service.setDestinationCountryInfoToShipment = function (shipment, countryAlpha2) {
      shipment.destination_country = CountryService.findCountryByAlpha2(countryAlpha2);
      shipment.destination_country_id = shipment.destination_country.id;
    };

    service.setLuxotticaShipmentData = function (shipment, returnAddresses) {
      service.setAddressDataToShipment(shipment, returnAddresses);
      service.setDestinationCountryInfoToShipment(shipment, returnAddresses.country_alpha2);
    };

    service.setLuxotticaFlowSelectedCommodityList = function () {
      if (service.isStoreToStoreFlow()) {
        service.luxotticaFlowSelectedCommodityList = service.luxotticaStoreToStoreCommodities;
      } else if (service.isStoreToCustomerFlow()) {
        service.luxotticaFlowSelectedCommodityList = service.luxotticaStoreToCustomerCommodities;
      } else service.luxotticaFlowSelectedCommodityList = service.luxotticaStoreToVendorCommodities;
    };

    service.isReturnFlow = function () {
      return service.selectedLuxotticaFlow === 'return';
    };

    service.isDamageFlow = function() {
      return service.selectedLuxotticaFlow === 'damage';
    };

    service.isFtcFlow = function () {
      return service.selectedLuxotticaFlow === 'ftc';
    };

    service.isStoreToStoreFlow = function () {
      return service.selectedLuxotticaFlow === 'storeToStore';
    };

    service.isStoreToCustomerFlow = function () {
      return service.selectedLuxotticaFlow === 'storeToCustomer';
    };

    service.isStoreToVendorFlow = function () {
      return service.selectedLuxotticaFlow === 'storeToVendor';
    };

    service.isOtherFlow = function () {
      return service.selectedLuxotticaFlow === 'other';
    };

    function mapReturnAddresses(destinationAddresses) {
      return destinationAddresses.map(function (add, idx) {
        return transformToDisplayAddress(add, idx);
      });
    }

    service.queryDestinationAddressesByCountryAndFlow = function (countryAlpha2, flow) {
      return $q(function (resolve, reject) {
        service
          .queryDestinationAddresses(null, countryAlpha2, flow)
          .then(function (data) {
            if (data && data.destination_addresses) {
              if (flow === 'return') {
                service.luxotticaReturnAddresses = mapReturnAddresses(data.destination_addresses);
              } else if (flow === 'damage') {
                service.luxotticaDamageAddresses = mapReturnAddresses(data.destination_addresses);
              } else if (flow === 'ftc') {
                service.luxotticaFtcAddresses = mapReturnAddresses(data.destination_addresses);
              }
              resolve();
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });
    };

    service.queryDestinationAddresses = function (searchTerm, countryAlpha2, category) {
      var params = {
        search_term: searchTerm || null,
        country_alpha2: countryAlpha2 || null,
        category: category || null,
      };

      return $q(function (resolve, reject) {
        DestinationAddresses.query(
          params,
          {},
          function (response) {
            service.destination_addresses = response;
            resolve(response);
          },
          function (errors) {
            reject(errors);
          }
        );
      });
    };
  }

  function transformToDisplayAddress(addressObject, index) {
    var displayName = [
      addressObject.name,
      addressObject.line_1,
      addressObject.city,
      addressObject.state,
      addressObject.postal_code,
      addressObject.country_alpha2,
    ]
      .filter(Boolean)
      .join(', ');

    addressObject.index = index;
    addressObject.displayName = displayName;

    return addressObject;
  }

  angular
    .module('app.service.DestinationAddressesService', ['app.factory.DestinationAddresses'])
    .service('DestinationAddressesService', DestinationAddressesService);
})();
