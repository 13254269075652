import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import CountryFlag from '@client/core/components/react/CountryFlag';
import { Country } from './constants';

interface IContactNumbersProps {
  countries: Country[];
}

export default function ContactNumbers({ countries }: IContactNumbersProps) {
  return (
    <Grid container spacing={1}>
      {countries.map((country) => (
        <Grid key={country.alpha2} container item justifyContent="space-between">
          <Grid container item alignItems="center" spacing={1} xs>
            <Grid item>
              <CountryFlag alpha2={country.alpha2} />
            </Grid>
            <Grid item>{country.name}</Grid>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="right">
              {country.contactNumber}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
