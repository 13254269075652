import failImageUrl from '@assets/images/dashboard/fix-shipment-modal/fail.svg';
import template from './fail-monkey.html?raw';

(function () {
  const fixShipmentModalBodyFailMonkey = {
    template,
    bindings: {
      shipmentData: '<',
    },
    controller: 'fixShipmentModalBodyFailMonkeyCtrl as ctrl',
  };

  function fixShipmentModalBodyFailMonkeyCtrl(FixMonkeyService) {
    const ctrl = this;
    ctrl.failImageUrl = failImageUrl;

    ctrl.$onInit = function () {
      ctrl.courierName = _.get(ctrl.shipmentData, 'courier.name');
      ctrl.courierErrorMessage = _.get(ctrl.shipmentData, 'http_response.messages[0].content');
    };

    /**
     * [retry] Goes back to edit shipment step
     * @return {N/A}
     */
    ctrl.retry = function () {
      FixMonkeyService.goToEditShipmentStep();
    };
  }

  fixShipmentModalBodyFailMonkeyCtrl.$inject = ['FixMonkeyService'];

  angular
    .module('app.component.fix-shipment-modal-body-fail-monkey')
    .component('fixShipmentModalBodyFailMonkey', fixShipmentModalBodyFailMonkey)
    .controller('fixShipmentModalBodyFailMonkeyCtrl', fixShipmentModalBodyFailMonkeyCtrl);
})();
