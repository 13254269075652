import angular from 'angular';
import '@client/core/components/buttons/dropdown-button';
import '@client/app/factories/UserSession/UserSession.service';
import '@client/src/global/User';

// TODO: remove imports when dependancies of the dependancies have been properly mapped. Webpack needs to have access to these files in the meantime but it should not be handled at the component level
import '@client/app/factories/GoogleAnalytics/GoogleAnalytics.service';
import '@client/src/global/user-status';
import '@client/src/global/hubspot';

import { ItemsPerPageComponent } from './items-per-page.component';

const MODULE_NAME = 'core.components.items-per-page';

angular
  .module(MODULE_NAME, [
    'core.components.dropdown-button',
    'app.factory.UserSession',
    'app.global.user',
  ])
  .component('esItemsPerPage', ItemsPerPageComponent);

export default MODULE_NAME;
