import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import COLORS from '@client/src/colors';
import ProgressIndicator from './ProgressIndicator';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.dark,
  },
  containedError: {
    backgroundImage: `linear-gradient(-180deg, ${theme.palette.error.main} 0%, ${theme.palette.error.dark} 100%)`,
    color: 'white !important',

    '&:hover': {
      backgroundImage: 'none !important',
      backgroundColor: `${theme.palette.error.main} !important`,
    },

    '&:active': {
      backgroundImage: 'none !important',
      backgroundColor: `${theme.palette.error.dark} !important`,
    },
  },
  loading: {
    backgroundImage: 'none !important',
    boxShadow: '0 4px 4px 0 rgba(40, 78, 102, 0.20)',

    '& .MuiButton-label': {
      visibility: 'hidden',
    },
  },
}));

interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: MuiButtonProps['color'] | 'error';
  loading?: boolean;
}

function Button(
  { color = 'default', loading = false, className, style, ...props }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
): JSX.Element {
  const classes = useStyles();
  const styles: React.CSSProperties = style || {};
  const classList = className?.split(' ') || [];

  if (color === 'error') {
    if (props.variant === 'contained') {
      classList.push(classes.containedError);
    } else {
      classList.push(classes.error);
    }
  }

  if (loading && !props.disabled) {
    classList.push(classes.loading);

    if (props.variant === 'contained') {
      switch (color) {
        case 'primary':
          styles.backgroundColor = COLORS.greenDark;
          break;
        case 'secondary':
          styles.backgroundColor = COLORS.blueDark;
          break;
        case 'error':
          styles.backgroundColor = COLORS.redDark;
          break;
        default:
          styles.backgroundColor = COLORS.skyNormal;
      }
    }
  }

  return (
    <ProgressIndicator show={loading} size="sm">
      <MuiButton
        ref={ref}
        className={classList.join(' ')}
        color={color === 'error' ? 'default' : color}
        style={styles}
        {...props}
      />
    </ProgressIndicator>
  );
}

export default React.forwardRef(Button);
