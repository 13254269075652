import { pickupTableColumnStyle } from '@client/src/data/pickups';

import { IComponentController } from 'angular';
import template from './item-header.html?raw';
import style from './item-header.module.scss';

class ItemHeader implements IComponentController {
  style = style;
  pickupTableColumnStyle = pickupTableColumnStyle;
}

const ItemHeaderComponent: ng.IComponentOptions = {
  controller: ItemHeader,
  template,
  bindings: {},
};

export { ItemHeaderComponent };
