import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';
import { IShipmentListItem, IShipmentReturnShipments } from 'typings/shipment';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { InsuranceService } from '@client/src/global/services/insurance/insurance.service';
import template from './shipment-list-remarks-cell.html?raw';
import style from './shipment-list-remarks-cell.module.scss';
import payDirectImageUrl from '@assets/images/dashboard/courier/pay-direct.svg';
import residentialIncludedImageUrl from '@assets/images/dashboard/courier/residential-included.svg';
import residentialMayApplyImageUrl from '@assets/images/dashboard/courier/residential-may-apply.svg';
import iconLiquidUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-liquid.svg';
import iconBatteryUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-battery.svg';
import iconReturnUrl from '@assets/images/dashboard/create-shipments/icon-return.svg';

class ShipmentListRemarksCell implements IComponentController {
  payDirectImageUrl = payDirectImageUrl;
  residentialIncludedImageUrl = residentialIncludedImageUrl;
  residentialMayApplyImageUrl = residentialMayApplyImageUrl;
  iconLiquidUrl = iconLiquidUrl;
  iconBatteryUrl = iconBatteryUrl;
  iconReturnUrl = iconReturnUrl;
  esShipment = {} as IShipmentListItem;
  esResidentialDiscountedFee?: number;
  esPaymentRecipient?: string;
  esCourierName?: string;
  esRemarks?: string;
  esIsEasyshipCourier = false;
  esIdentifiedAsResidential?: string;
  esSetAsResidential = false;
  esResidentialFeeApplied?: number;
  esReturnShipments?: IShipmentReturnShipments[];
  esShowInsuranceRemark = false;
  esShowLiquidRemark = false;
  esShowBatteryRemark = false;

  style = style;
  companyService: 'cloud' | 'efulfilment';
  successfullyInsured = false;
  otherRemarks = '';
  isPayableToCourier = false;
  isEfulfilment = false;
  hasReturnsPageBetaAccess = false;

  static $inject = [
    '$state',
    '$translate',
    'API',
    'UserSession',
    'showShipmentModal',
    'InsuranceService',
    'MixpanelService',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $translate: angular.translate.ITranslateService,
    private API: IApiConfig,
    private UserSession: IUserSession,
    private showShipmentModal: any,
    private InsuranceService: InsuranceService,
    private MixpanelService: MixpanelService
  ) {
    this.companyService = this.UserSession.company.service;
    this.isEfulfilment = this.UserSession.isCompanyEfulfilment();
  }

  $onInit(): void {
    this.hasReturnsPageBetaAccess =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_returns_page;

    this.successfullyInsured = this.InsuranceService.isCovered(this.esShipment);
    this.isPayableToCourier = this.esPaymentRecipient === 'Courier';
  }

  showReturnShipment() {
    /**
     * Avoid changing the URL when return page is enabled,
     * TODO: Remove the entire when decided to show the return page to all of the users
     */
    if (!this.hasReturnsPageBetaAccess) {
      this.$state.go('app.shipments', { shipment_id: this.firstReturnShipmentId });
    }

    this.showShipmentModal.open(this.firstReturnShipmentId, null);
  }

  onBuyersNotesTrack(eventName: string) {
    this.MixpanelService.track(`Manage Shipments - Buyers Note - ${eventName} Icon`, {
      easyship_shipment_id: this.esShipment?.easyship_shipment_id,
      platform: this.esShipment?.platform_name || '',
    });
  }

  get buyersNotesIconUrl() {
    return `${this.API.easyship_storage}/buyers-notes/icon-buyers-note.svg`;
  }

  get insuranceIconUrl() {
    const color = this.esShipment.insurance_requested_by_buyer ? 'blue' : 'green';

    return `${this.API.easyship_storage}/insurance/shield-${color}.svg`;
  }

  get residentialSurchargeSlug() {
    if (!this.esIdentifiedAsResidential) return '';

    if (this.hasResidentialFee && this.esSetAsResidential) {
      return 'included';
    }

    return this.esResidentialDiscountedFee ? 'may-apply' : '';
  }

  get firstReturnShipmentId() {
    return this.esReturnShipments && this.esReturnShipments[0].easyship_shipment_id;
  }

  get containsLiquids() {
    return this.esShipment.contains_liquids;
  }

  get containsBattery() {
    return this.esShipment.contains_battery_pi967 || this.esShipment.contains_battery_pi966;
  }

  get getBatteryTooltipText() {
    if (!this.esShipment.contains_battery_pi967 && !this.esShipment.contains_battery_pi966) {
      return;
    }
    if (this.esShipment.contains_battery_pi967 && this.esShipment.contains_battery_pi966) {
      return 'shipments.category-hs-codes.contain-battery-pi966-pi967';
    }
    if (this.esShipment.contains_battery_pi966) {
      return 'shipments.category-hs-codes.contain-battery-pi966';
    }
    if (this.esShipment.contains_battery_pi967) {
      return 'shipments.category-hs-codes.contain-battery-pi967';
    }
  }

  private get hasResidentialFee(): number | null {
    return this.esResidentialFeeApplied || this.esResidentialDiscountedFee || null;
  }
}

const ShipmentListRemarksCellComponent: ng.IComponentOptions = {
  controller: ShipmentListRemarksCell,
  template,
  bindings: {
    esShipment: '<',
    esPaymentRecipient: '<',
    esCourierName: '<',
    esRemarks: '<',
    esIsEasyshipCourier: '<',
    esIdentifiedAsResidential: '<',
    esSetAsResidential: '<',
    esResidentialFeeApplied: '<',
    esResidentialDiscountedFee: '<',
    esReturnShipments: '<',
    esShowInsuranceRemark: '<',
    esShowLiquidRemark: '<',
    esShowBatteryRemark: '<',
  },
};

export { ShipmentListRemarksCellComponent };
