import { IComponentController } from 'angular';
import template from './payment-method-list-card.html?raw';
import style from './payment-method-list-card.module.scss';

class PaymentMethodListCard implements IComponentController {
  style = style;
  esPaymentType = '';
}

const PaymentMethodListCardComponent: ng.IComponentOptions = {
  controller: PaymentMethodListCard,
  template,
  bindings: {
    esPaymentType: '<',
    esOnClick: '&',
  },
};

export { PaymentMethodListCardComponent };
