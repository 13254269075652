import angular from 'angular';
import { API } from './api.constant';
import { API_KEYS } from './api-keys.constant';
import { APP_BUILD } from './app-build.constant';
import { URL } from './url.constant';
import { AUTH_EVENTS } from './auth-events.constant';

// TODO: Covert `constant` name and `attribute` to `SNAKE_UPPERCASE`
angular
  .module('core.config', [])
  .constant('API', API)
  .constant('ApiKeys', API_KEYS)
  .constant('AppBuild', APP_BUILD)
  .constant('UrlPath', URL)
  .constant('AUTH_EVENTS', AUTH_EVENTS);
