import { IComponentController } from 'angular';
import template from './row-button.html?raw';
import styleRowButton from './row-button.module.scss';

class RowButton implements IComponentController {
  style = styleRowButton;
}

const RowButtonComponent: ng.IComponentOptions = {
  controller: RowButton,
  template,
  transclude: true,
  bindings: {
    esButtonType: '@',
    esButtonClick: '&',
    esButtonText: '@',
    esButtonLoading: '<',
    esButtonIconLeft: '@',
    esButtonIconRight: '@',
    ngDisabled: '<',
  },
};

export { RowButtonComponent };
