import { IApiConfig } from 'typings/core/config/api';
import {
  IShipmentListParams,
  IShipmentListPayload,
  IShipmentListManageTotal,
  IShipmentListAdvancedTotal,
  IShipmentBulkEditParams,
  IShipmentBulkEditFieldsSuccessResponse,
  IShipmentBulkEditConfirmPayload,
  IShipmentBulkEditConfirmSelectAllPayload,
  IShipmentBulkEditConfirmSuccessResponse,
  IShipmentGetPicklistParams,
  IShipmentGetPicklistPayload,
  IShipmentGetPicklistSuccessResponse,
  IShipmentBulkCancelParams,
  IShipmentBulkCancelPayload,
  IShipmentBulkCancelTotalsSuccessResponse,
  IShipmentBulkCancelConfirmSuccessResponse,
  IShipmentCancelParams,
  IShipmentCancelSuccessResponse,
  IShipmentMarkAsDeliveredParam,
  IShipmentGetPackingSlipsParams,
  IShipmentGetPackingSlipsPayload,
  IShipmentGetPackingSlipsSuccessResponse,
  IShipmentListItem,
} from 'typings/shipment';

class ShipmentListResource {
  resource: any;
  resourceV1: any;
  private readonly shipmentPath = 'companies/:company_id/shipments/:id/:controller/:step';
  private readonly v2 = 'v2';
  private readonly v1 = 'v1';

  static $inject = ['$resource', 'API'];
  constructor($resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.baseEndpoint}/${this.v2}/${this.shipmentPath}`,
      {},
      {
        get: {
          method: 'POST',
        },
        getTotal: {
          method: 'POST',
          params: {
            controller: 'totals',
          },
        },
        update: {
          method: 'PATCH',
        },
      }
    );
    this.resourceV1 = $resource(
      `${this.API.baseEndpoint}/${this.v1}/:company_type/${this.shipmentPath}`,
      {},
      {
        update: {
          method: 'PATCH',
        },
        bulkEditFields: {
          method: 'GET',
          cache: true,
        },
        markAsDelivered: {
          method: 'POST',
          cache: true,
          params: {
            controller: 'mark_as_delivered',
          },
        },
      }
    );
  }

  getShipmentList(
    params: IShipmentListParams,
    payload: IShipmentListPayload
  ): ng.IPromise<{ shipments: IShipmentListItem[] }> {
    if (payload) {
      payload.by_search = true;
    }
    return this.resource.get(params, payload).$promise;
  }

  getShipmentTotal(
    params: IShipmentListParams,
    payload: IShipmentListPayload
  ): ng.IPromise<IShipmentListAdvancedTotal | IShipmentListManageTotal> {
    if (payload) {
      payload.by_search = true;
    }
    return this.resource.getTotal(params, payload).$promise;
  }

  cancel(params: IShipmentCancelParams): ng.IPromise<IShipmentCancelSuccessResponse> {
    const queryParams = {
      ...params,
      controller: 'cancel',
    };

    return this.resourceV1.update(queryParams, {}).$promise;
  }

  bulkEditFields(
    params: IShipmentBulkEditParams
  ): ng.IPromise<IShipmentBulkEditFieldsSuccessResponse> {
    const queryParams = {
      ...params,
      controller: 'bulk_edit',
      step: 'fields',
    };

    return this.resourceV1.bulkEditFields(queryParams).$promise;
  }

  bulkEditConfirm(
    params: IShipmentBulkEditParams,
    payload: IShipmentBulkEditConfirmPayload | IShipmentBulkEditConfirmSelectAllPayload
  ): ng.IPromise<IShipmentBulkEditConfirmSuccessResponse> {
    const queryParams = {
      ...params,
      controller: 'bulk_edit',
      step: 'confirm',
    };

    return this.resourceV1.update(queryParams, payload).$promise;
  }

  getPicklist(
    params: IShipmentGetPicklistParams,
    payload: IShipmentGetPicklistPayload
  ): ng.IPromise<IShipmentGetPicklistSuccessResponse> {
    const queryParams = {
      ...params,
      controller: 'get_pick_list',
    };

    return this.resourceV1.save(queryParams, payload).$promise;
  }

  getPackingSlips(
    params: IShipmentGetPackingSlipsParams,
    payload: IShipmentGetPackingSlipsPayload
  ): ng.IPromise<IShipmentGetPackingSlipsSuccessResponse> {
    const queryParams = {
      ...params,
      controller: 'get_packing_slips',
    };

    return this.resourceV1.save(queryParams, payload).$promise;
  }

  bulkCancelTotals(
    params: IShipmentBulkCancelParams,
    payload: IShipmentBulkCancelPayload
  ): ng.IPromise<IShipmentBulkCancelTotalsSuccessResponse> {
    const queryParams = {
      ...params,
      controller: 'bulk_cancel',
      step: 'totals',
    };

    return this.resourceV1.save(queryParams, payload).$promise;
  }

  bulkCancelConfirm(
    params: IShipmentBulkCancelParams,
    payload: IShipmentBulkCancelPayload
  ): ng.IPromise<IShipmentBulkCancelConfirmSuccessResponse> {
    const queryParams = {
      ...params,
      controller: 'bulk_cancel',
      step: 'confirm',
    };

    return this.resourceV1.save(queryParams, payload).$promise;
  }

  markAsDelivered(params: IShipmentMarkAsDeliveredParam): ng.IPromise<void> {
    const queryParams = {
      ...params,
      controller: 'mark_as_delivered',
    };
    return this.resourceV1.markAsDelivered(queryParams, {}).$promise;
  }
}

export { ShipmentListResource };
