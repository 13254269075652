import { CourierUmbrellaName } from '@client/data/courier';
import { IShippingCountryStandards } from '@/providers/UserSessionProvider';

export interface LyocAvailability {
  name: string;
  logoUrl: string;
  visible?: boolean;
  contact_sales?: boolean;
}

interface CourierData {
  selfServeLyoc: LyocAvailability[];
  salesTeamLyoc: LyocAvailability[];
}
function getVisibleLyocOptions(
  lyocOptions: Record<string, Omit<LyocAvailability, 'name' | 'logoUrl'>>
): [string, Omit<LyocAvailability, 'name' | 'logoUrl'>][] {
  return Object.entries(lyocOptions)
    .filter(([, { visible }]) => visible)
    .sort();
}

function mapLyocOptions(
  lyocOptions: [string, Omit<LyocAvailability, 'name' | 'logoUrl'>][]
): LyocAvailability[] {
  return lyocOptions.map(([logoUrl, lyocAvailability]) => ({
    ...lyocAvailability,
    name: CourierUmbrellaName[logoUrl],
    logoUrl,
  }));
}

function splitLyocOptions(mappedLyocOptions: LyocAvailability[]) {
  return mappedLyocOptions.reduce(
    (accumulator: CourierData, lyocOption) => {
      if (lyocOption.contact_sales) {
        // Comment out to not show couriers with contact_sales (changing flow),
        // leave it only for Janio
        if (lyocOption.logoUrl === 'janio') {
          accumulator.salesTeamLyoc.push(lyocOption);
        }
      } else {
        accumulator.selfServeLyoc.push(lyocOption);
      }

      return accumulator;
    },
    { selfServeLyoc: [], salesTeamLyoc: [] } as CourierData
  );
}

export function buildCourierData(selectedCountry: IShippingCountryStandards): LyocAvailability[] {
  const lyocOptions = selectedCountry?.features.lyoc.couriers;
  if (!lyocOptions) {
    return [];
  }

  const visibleLyocOptions = getVisibleLyocOptions(lyocOptions);
  const mappedLyocOptions = mapLyocOptions(visibleLyocOptions);
  const { selfServeLyoc, salesTeamLyoc } = splitLyocOptions(mappedLyocOptions);

  return [...selfServeLyoc, ...salesTeamLyoc];
}
