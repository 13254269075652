(function () {
  'use strict';

  Checkout.$inject = ['$resource', 'API'];
  function Checkout($resource, API) {
    var endpointUrl = API.endpoint;

    return {
      confirm: $resource(endpointUrl + '/companies/:company_id/checkout/confirm', {
        company_id: '@_company_id',
      }),
      prepare: $resource(endpointUrl + '/companies/:company_id/checkout/prepare', {
        company_id: '@_company_id',
      }),
    };
  }

  angular.module('app.factory.Checkout', []).factory('Checkout', Checkout);
})();
