(function () {
  'use strict';

  Luxottica.$inject = ['$resource', 'API'];
  function Luxottica($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/sso/netiq/luxottica/new',
      {},
      {
        query: {
          method: 'GET',
          params: {},
        },
      }
    );
  }

  LuxotticaCreateSession.$inject = ['$resource', 'API', 'Auth'];
  function LuxotticaCreateSession($resource, API, Auth) {
    var endpointUrl = API.endpoint;
    var sessionToken = Auth.getCredentials();
    return $resource(
      endpointUrl + '/luxottica/companies/:company_id/sessions',
      {
        company_id: '@_company_id',
      },
      {
        query: {
          method: 'POST',
          headers: { Authorization: 'Bearer ' + sessionToken },
        },
      }
    );
  }

  LuxotticaManagedShops.$inject = ['$resource', 'API', 'Auth'];
  function LuxotticaManagedShops($resource, API, Auth) {
    var endpointUrl = API.endpoint;
    var sessionToken = Auth.getCredentials();
    return $resource(
      endpointUrl + '/luxottica/companies',
      {},
      {
        query: {
          method: 'GET',
          headers: { Authorization: 'Bearer ' + sessionToken },
        },
      }
    );
  }

  angular
    .module('app.factory.Luxottica', ['ngResource'])
    .factory('Luxottica', Luxottica)
    .factory('LuxotticaCreateSession', LuxotticaCreateSession)
    .factory('LuxotticaManagedShops', LuxotticaManagedShops);
})();
