import { EasyshipLocale } from '@/types/locale';
import dayjs from './dayjs';

/* eslint-disable @typescript-eslint/no-explicit-any, no-plusplus, no-restricted-syntax, guard-for-in */
// @see: adapted from Bergi's code at https://stackoverflow.com/a/19101235

export const flatten = (data: Record<string, any>): Record<string, string> => {
  const result = {} as Record<string, any>;

  function recurse(cur: Record<string, any>, prop: string) {
    let i;
    let l;
    let p;
    let isEmpty;
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (i = 0, l = cur.length; i < l; i++) recurse(cur[i], `${prop}[${i}]`);
      if (l === 0) result[prop] = [];
    } else {
      isEmpty = true;
      for (p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}.${p}` : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }

  recurse(data, '');
  return result;
};

export const setLocale = (localeCodeInUse?: EasyshipLocale) => {
  const applyToDayJs = () => {
    dayjs.locale(localeCodeInUse);
    return localeCodeInUse;
  };

  switch (localeCodeInUse) {
    case 'de':
      import('dayjs/locale/de').then(applyToDayJs);
      break;

    case 'fr':
      import('dayjs/locale/fr').then(applyToDayJs);
      break;

    case 'es':
      import('dayjs/locale/es').then(applyToDayJs);
      break;

    default:
      import('dayjs/locale/en').then(applyToDayJs);
  }
};

export const loadLocaleMessages = async (locale: EasyshipLocale) => {
  const localeCodeInUse = locale ?? 'en';
  setLocale(localeCodeInUse);
  const fetched = await import(`@assets/i18n/${localeCodeInUse}.json`);
  return flatten({ ...fetched.default });
};
