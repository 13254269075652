import angular from 'angular';
import ICollectGateway from '@client/core/corelogic/ports/collect.interface';
import {
  CollectData,
  CollectEvents,
  CollectResendEmailResponse,
  ResendEmailPayload,
} from '@client/core/corelogic/models/Collect';
import { CollectService } from '@client/src/global/services/collect/collect.service';

export default class NgCollectGateway implements ICollectGateway {
  private collectService: CollectService;

  constructor() {
    this.collectService = angular
      .element(document.body)
      .injector()
      .get<CollectService>('CollectService');
  }

  getData(shipmentId: string): Promise<CollectData> {
    return new Promise((resolve) => {
      this.collectService.getData(shipmentId).then((data) =>
        resolve({
          state: data.state,
          courier: data.courier_selected,
          insurance: data.insurance_selected,
          incoterms: data.incoterms_selected,
          amountCharged: data.total_charged_to_customer
            ? `${data.display_currency} ${data.total_charged_to_customer}`
            : '-',
          emailRequestSentAt: data.email_request_sent_at,
          emailReminderSentAt: data.email_reminder_sent_at,
          emailConfirmationSentAt: data.email_confirmation_sent_at,
          collectedAt: data.collected_at,
        })
      );
    });
  }

  getLink(shipmentId: string): Promise<string> {
    return new Promise((resolve) => {
      this.collectService
        .getLink(shipmentId)
        .then((response) => resolve(response.survey_url || ''));
    });
  }

  getEvents(shipmentId: string): Promise<CollectEvents> {
    return new Promise((resolve) => {
      this.collectService.getEvents(shipmentId).then((response) => {
        const parsedEvents = response.events.map((event) => {
          return {
            type: event.type,
            description: event.data.state ?? event.data.email_type ?? '',
            createdAt: event.created_at,
          };
        });

        parsedEvents.sort((a, b) => {
          return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
        });

        const data = {
          events: parsedEvents,
          pagination: {
            currentPage: response.pagination.current_page,
            totalPages: response.pagination.total_pages,
            totalCount: response.pagination.total_count,
          },
        };
        resolve(data);
      });
    });
  }

  resendEmail(params: ResendEmailPayload): Promise<CollectResendEmailResponse> {
    return new Promise((resolve, reject) => {
      this.collectService
        .resendEmail(params)
        .then((response) => {
          resolve({ errors: response.errors ?? [] });
        })
        .catch((err) => reject(err));
    });
  }
}
