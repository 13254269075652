import template from './input-error-message.html?raw';

(function () {
  'use strict';

  var inputErrorMessage = {
    template,
    bindings: {
      message: '<',
    },
    transclude: true,
  };

  angular
    .module('app.component.input-error-message', [])
    .component('inputErrorMessage', inputErrorMessage);
})();
