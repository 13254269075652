class ShippingDiscountsService {
  static $inject = ['UserSession', 'ShippingDiscountsResource'];

  constructor(UserSession, ShippingDiscountsResource) {
    this.UserSession = UserSession;
    this.ShippingDiscountsResource = ShippingDiscountsResource;
  }

  create(storeId, payload) {
    return this.ShippingDiscountsResource.create(
      {
        company_id: this.UserSession.company.id,
        store_id: storeId,
      },
      payload
    )
      .$promise.then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  update(id, storeId, payload) {
    return this.ShippingDiscountsResource.update(
      {
        id,
        company_id: this.UserSession.company.id,
        store_id: storeId,
      },
      payload
    )
      .$promise.then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  delete(id, storeId) {
    return this.ShippingDiscountsResource.delete({
      id,
      company_id: this.UserSession.company.id,
      store_id: storeId,
    })
      .$promise.then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export { ShippingDiscountsService };
