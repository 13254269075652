import { IUserSession } from 'typings/user-session';
import {
  IShipmentListLogsPriceCalculation,
  IShipmentListLogsPriceCalculationTable,
} from 'typings/shipment';

import { IComponentController } from 'angular';
import template from './shipment-list-receiver-cell.html?raw';
import style from './shipment-list-receiver-cell.module.scss';

class ShipmentListReceiverCell implements IComponentController {
  style = style;
  esDropdown = false;
  esDisabled = false;
  esShipmentIndex: number | null = null;
  esConsignee = '';
  esCountry = {
    alpha2: '',
    name: '',
  };

  // eslint-disable-next-line
  esErrorLogs: IShipmentListLogsPriceCalculation | null = null;
  readonly shipmentsType = 'manage';

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  esOnEditClick(value: any): void {
    // esOnEditClick expression bindings, need to add this in order for typescript to successfully compile
  }

  onEditClick($event: MouseEvent): void {
    if (this.esDropdown && !this.esDisabled && this.esOnEditClick) {
      this.esOnEditClick({ event: $event, index: this.esShipmentIndex });
    }
  }
  isConsigneeTruncated(): boolean {
    if (this.esConsignee) {
      return this.esConsignee.length > 26;
    }
    return false;
  }

  isCountryTruncated(): boolean {
    if (this.esCountry) {
      return this.esCountry.name.toString().length > 28;
    }
    return false;
  }

  get addressErrors(): IShipmentListLogsPriceCalculationTable[] | Record<string, string> | null {
    if (!this.esErrorLogs) {
      return null;
    }

    if (Array.isArray(this.esErrorLogs)) {
      return this.esErrorLogs.filter((err) => err.category === 'address');
    }

    return this.esErrorLogs.address || null;
  }

  get hasAddressError(): boolean {
    if (!this.addressErrors) {
      return false;
    }
    if (Array.isArray(this.addressErrors)) {
      return this.addressErrors.length > 0;
    }

    return !!this.addressErrors;
  }

  get isShipmentPanelViewActive(): boolean {
    return !!this.UserSession.showAdvancedShipmentPanelView();
  }

  get normalizeReceiver(): string {
    let html = '';

    if (this.isConsigneeTruncated()) {
      html += `${this.esConsignee}<br>`;
    }

    if (this.isCountryTruncated()) {
      html += `${this.esCountry.name}`;
    }

    return html;
  }

  get isTooltipEnable(): boolean {
    if (this.isConsigneeTruncated() || this.isCountryTruncated()) {
      return true;
    }
    return false;
  }
}

const ShipmentListReceiverCellComponent: ng.IComponentOptions = {
  controller: ShipmentListReceiverCell,
  template,
  bindings: {
    esConsignee: '<',
    esCountry: '<',
    esErrorLogs: '<',
    esValidAddress: '<',
    esDropdown: '<',
    esShowReceiver: '<',
    esShowAddressWarning: '<',
    esIsMissingReceiverEmail: '<',
    esShipmentIndex: '<',
    esDisabled: '<',
    esShowTags: '<',
    esOnEditClick: '&',
    esType: '<',
    esOrderTagList: '<',
  },
};

export { ShipmentListReceiverCellComponent };
