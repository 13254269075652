import { IShipmentListItem } from 'typings/shipment';
import { IInsuredState } from 'typings/insurance';
import { InsuredStateKey, InsuredStates, NotInsured } from '@client/data/insurance';

function _detectNoLiabilityString(insurance_liability = ''): boolean {
  return insurance_liability.replace(/\s/g, '').toLowerCase() === 'noliability';
}

export function identifyInsuredStateKey(shipment: IShipmentListItem): InsuredStateKey {
  const { is_insured, insurance_fee, courier } = shipment;

  if (is_insured && insurance_fee !== 0) {
    // Shipment is insured and some insurance was charged
    return InsuredStateKey.Insured;
  }

  if (!courier) {
    return InsuredStateKey.NotInsured;
  }

  if (courier && !courier.is_easyship_courier) {
    // Shipment is not insured and is LYOC
    return InsuredStateKey.LyocNotInsured;
  }

  if (
    courier &&
    courier.insurance_liability &&
    !_detectNoLiabilityString(courier.insurance_liability)
  ) {
    return is_insured && insurance_fee === 0
      ? InsuredStateKey.InsuranceFallbackCourierLiability // In the case the user intended to have insurance but we could not cover it (no charge)
      : InsuredStateKey.CourierLiability; // Shipment is not insured, but the courier has some basic liability
  }

  return is_insured && insurance_fee === 0
    ? InsuredStateKey.InsuranceFallbackNotInsured // In the case the user intended to have insurance but we could not cover it (no charge)
    : InsuredStateKey.NotInsured; // Shipment not insured with no liability from courier
}

export default function identifyInsuredState(
  shipment: IShipmentListItem,
  baseUrl: string
): IInsuredState {
  const stateKey = identifyInsuredStateKey(shipment);
  const foundState = InsuredStates.find(({ key }) => key === stateKey) || NotInsured;

  if (foundState.helpPath && baseUrl) foundState.helpUrl = `${baseUrl}${foundState.helpPath}`;

  return foundState;
}
