import { ICountryService } from 'typings/auth/services/country';
import { IInputGroupRadioModel, IInputGroupValue } from 'typings/form';

import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import template from './countries-input-group.html?raw';
import style from './countries-input-group.module.scss';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class CountriesInputGroup extends MultiSelectInputGroup<any> implements IComponentController {
  style = style;
  busy = true;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];

  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput: string | null = null;
  esModelCountries: string[] = [];
  esModelOptions = [];

  static $inject = ['$scope', '$translate', 'CountryService', '$timeout'];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private CountryService: ICountryService,
    private $timeout: ng.ITimeoutService
  ) {
    super($scope);
  }

  $onInit(): void {
    const noun = this.$translate.instant('global.country');
    const $t = (key: string) => {
      return this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    };
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];

    this.assignDefaultRadioModel();
    this.$timeout(() => {
      this.fetchCountries();
    }, 300);
  }

  $onChanges(): void {}

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  onCountryChange(value: string[]): void {
    this.esModelCountries = value;
    this.onValuesChange({
      options: value,
    });
  }

  onRadioCountryChange(value: IInputGroupValue<MultiselectComponentModelValue>): void {
    this.assignModelRadioInputValue(value);
    this.onValuesChange({ options: this.esModelCountries });
  }

  private fetchCountries(): void {
    this.busy = true;
    this.CountryService.getCountriesByContinent().finally(() => {
      this.busy = false;
    });
  }
}

const CountriesInputGroupComponent: ng.IComponentOptions = {
  controller: CountriesInputGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelCountries: '<',
    esOnChange: '&',
  },
};

export { CountriesInputGroupComponent };
