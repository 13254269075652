import React, { createContext, useContext, useMemo } from 'react';
import { GeolocationPort } from '@client/core/corelogic/ports/geolocation';
import NgGeolocationGateway from '@client/core/adapters/geolocation/ngGeolocationGateway';
import FakeGeolocationGateway from '@client/core/adapters/geolocation/fakeGeolocationGateway';

export interface GeolocationGatewayContextData {
  geolocationGateway: GeolocationPort;
}

const GeolocationGatewayContext = createContext<GeolocationGatewayContextData | undefined>(
  undefined
);

interface GeolocationGatewayProviderProps {
  children: React.ReactNode;
}

function GeolocationGatewayProvider({ children }: GeolocationGatewayProviderProps) {
  const data: GeolocationGatewayContextData = useMemo(
    () => ({
      geolocationGateway: new NgGeolocationGateway(),
    }),
    []
  );

  return (
    <GeolocationGatewayContext.Provider value={data}>{children}</GeolocationGatewayContext.Provider>
  );
}

interface FakeGeolocationGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<GeolocationGatewayContextData>;
}

function FakeGeolocationGatewayProvider({ children, value }: FakeGeolocationGatewayProviderProps) {
  const data: GeolocationGatewayContextData = useMemo(
    () => ({
      geolocationGateway: new FakeGeolocationGateway(),
      ...value,
    }),
    [value]
  );

  return (
    <GeolocationGatewayContext.Provider value={data}>{children}</GeolocationGatewayContext.Provider>
  );
}

function useGeolocationGateway() {
  const authGatewayContext = useContext(GeolocationGatewayContext);

  if (authGatewayContext === undefined) {
    throw new Error('useAuthGateway must be used within a GeolocationGatewayProvider');
  }
  return authGatewayContext;
}

export { GeolocationGatewayProvider, FakeGeolocationGatewayProvider, useGeolocationGateway };
