import { IResidentialAddressPayload } from 'typings/check-residential';
import { IdentifiedAsResidential } from 'typings/shipment';
import { CheckResidentialResource } from './check-residential.resource';

class CheckResidentialService {
  supportedDestinations: number[];

  static $inject = ['CheckResidentialResource'];
  constructor(private CheckResidentialResource: CheckResidentialResource) {
    const US_ID = 234;
    const CA_ID = 39;
    this.supportedDestinations = [US_ID, CA_ID];
  }

  getAddressResidentialStatus(address: IResidentialAddressPayload): ng.IPromise<boolean | null> {
    return this.CheckResidentialResource.checkResidentialAddress({}, address).then((res) => {
      return this.mapResidentialResponse(res.identified_as_residential);
    });
  }

  destinationUsesResidentialAPI(countryId: number): boolean {
    return this.supportedDestinations.includes(countryId);
  }

  private mapResidentialResponse(response: IdentifiedAsResidential) {
    if (typeof response === 'boolean') {
      return response;
    }
    return null;
  }
}

export { CheckResidentialService };
