import template from './reschedule-pickup.html?raw';
import style from './reschedule-pickup.module.scss';

class ReschedulePickup {
  constructor() {
    this.style = style;
  }

  $onInit() {}
}

const ReschedulePickupComponent = {
  controller: ReschedulePickup,
  template,
};

export { ReschedulePickupComponent };
