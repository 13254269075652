import angular from 'angular';
import 'angular-bind-html-compile';

import '../button';

import { DropdownButtonComponent } from './dropdown-button.component';

angular
  .module('core.components.dropdown-button', [
    'core.components.button',
    'angular-bind-html-compile',
  ])
  .component('easyshipDropdownButton', DropdownButtonComponent);
