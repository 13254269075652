import template from './return-modal-main.html?raw';

class ReturnModalMain {
  static $inject = ['ReturnService'];

  constructor(ReturnService) {
    this.ReturnService = ReturnService;
  }
}

const ReturnModalMainComponent = {
  controller: ReturnModalMain,
  template,
  bindings: {
    shipment: '<',
  },
};

export { ReturnModalMainComponent };
