import React, { useEffect, useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import { Row } from '@tanstack/react-table';
import { Chip } from 'easyship-components';
import CellLayout from './CellLayout';
import ShipmentCellSkeleton from './ShipmentCellSkeleton';
import { ShipmentState } from './d';

export function TagsCell({ row }: { row: Row<IShipmentListItem> }) {
  const shipment = row.original;
  const tags = shipment.order_tag_list;
  const parentRef = useRef<HTMLDivElement>(null);
  const [hiddenTags, setHiddenTags] = useState<number>(0);
  const isRecalculating = shipment.shipment_state === ShipmentState.Calculating;

  useEffect(() => {
    const parentDiv = parentRef.current;
    setHiddenTags(0);
    if (tags?.length < 3) return;
    if (parentDiv) {
      const parentRect = parentDiv.getBoundingClientRect();
      const parentY = parentRect.y + parentRect.height;

      let exceededDivs = 0;
      if (parentDiv) {
        const childDivs = parentDiv.getElementsByClassName('Tags');
        for (let i = 0; i < childDivs.length; i++) {
          const childDiv = childDivs[i] as HTMLDivElement;
          const childRect = childDiv.getBoundingClientRect();
          const childY = childRect.y + childRect.height;
          if (childY > parentY) {
            exceededDivs += 1;
          }
        }
        setHiddenTags(exceededDivs);
      }
    }
  }, [tags]);

  return (
    <CellLayout>
      <div className="min-w-[152px] gap-1 px-2 flex flex-col justify-center" ref={parentRef}>
        {isRecalculating ? (
          <ShipmentCellSkeleton />
        ) : (
          <>
            <div className="max-w-[136px] flex gap-1 flex-wrap overflow-hidden h-[44px] items-center">
              {tags.length >= 1 &&
                tags.map((tag: string) => (
                  <div key={tag} className="Tags">
                    <Chip>{tag}</Chip>
                  </div>
                ))}
            </div>
            {!!hiddenTags && (
              <div className="flex text-left font-normal leading-5 text-ink-900 sm">
                <FormattedMessage
                  id="global.pluralize.with-count.more-tag"
                  values={{ count: hiddenTags }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </CellLayout>
  );
}
