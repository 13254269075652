import { ISubmitClaimPayload, IClaimPhoto } from 'typings/insurance';
import { IShipmentListItem } from 'typings/shipment';
import { IApiConfig } from 'typings/core/config/api';
import { ClaimStep } from '@client/data/insurance';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import { InsurancePhotoResource } from '@client/src/global/services/insurance-photo/insurance-photo.resource';
import { toastError } from '@client/core/components/react/Toastify';
import style from '../insurance-claim.module.scss';
import template from './claim-details.html?raw';

interface IMissingDetails {
  itemsAffected: boolean;
  claimDescription: boolean;
  certifyChecked: boolean;
}

class ClaimDetails implements IComponentController {
  esShowInsuranceClaim = {} as { [key: string]: boolean };
  showValidationErrors = false;
  claim = {} as ISubmitClaimPayload;
  submitting = false;
  esBusy = false;
  style = style;
  missingDetails = {} as IMissingDetails;
  esShipment = {} as IShipmentListItem;
  uploadPhotoEndpoint = '';
  photoBusy = false;
  photos: IClaimPhoto[] = [];
  esFlowStep?: ClaimStep;
  readonly numberValidation = {
    allowNegative: false,
    allowFloat: false,
  };

  static $inject = ['API', 'InsurancePhotoResource', 'UserSession', '$scope', '$translate'];
  constructor(
    public API: IApiConfig,
    private InsurancePhotoResource: InsurancePhotoResource,
    private UserSession: IUserSession,
    private $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService
  ) {
    this.onStartUpload = this.onStartUpload.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onFinishUpload = this.onFinishUpload.bind(this);
    this.onDeletePhoto = this.onDeletePhoto.bind(this);
  }
  // Prevent Typescript Error
  esTriggerSuccessStep() {
    //
  }

  esOnSubmit(_claimPayload: ISubmitClaimPayload) {
    //
  }

  $onInit() {
    if (this.esShipment?.claim?.claim_type) {
      this.claim = {
        id: this.esShipment?.claim?.id,
        user_description: '',
        check_certify: false,
      };
    }

    this.uploadPhotoEndpoint = `${this.API.endpoint}/companies/${this.UserSession.company.id}/policies/${this.esShipment.policy.id}/claim_photos`;

    if (this.isDamaged) {
      this.claim.selected_shipment_items =
        this.esShipment?.shipment_items_attributes?.map((item) => {
          return {
            shipment_item_id: item.id,
            quantity: 0,
          };
        }) || [];
    }
  }

  onStartUpload() {
    this.photoBusy = true;
    this.$scope.$apply();
  }

  onUploadError(message: string) {
    toastError(this.$translate.instant(message || 'insurance-claim.uploader-failed'));
    this.photoBusy = false;
  }

  onFinishUpload(photo: IClaimPhoto) {
    this.photos.push(photo);
    this.photoBusy = false;
  }

  onDeletePhoto(previewIndex: number) {
    this.photoBusy = true;

    this.InsurancePhotoResource.deletePhoto({
      policy_id: this.esShipment.policy.id,
      photo_id: this.photos[previewIndex].id,
      company_id: this.UserSession.company.id,
    }).then(() => {
      this.photos.splice(previewIndex, 1);
      this.photoBusy = false;
    });
  }

  get itemDescriptions(): string[] {
    const formatCurrency = (currency: string) =>
      new Intl.NumberFormat('en', {
        currency,
        style: 'currency',
      }).format;

    return (
      this.esShipment.shipment_items_attributes?.map((item, index) => {
        if (!this.claim.selected_shipment_items || !item.declared_customs_value) return '';

        const { quantity } = this.claim.selected_shipment_items[index];
        const desc = item.description
          ? `${item.description.charAt(0).toUpperCase() + item.description.slice(1)}`
          : '';
        const unitPrice = formatCurrency(item.declared_currency ?? '')(item.declared_customs_value);
        const totalPrice = formatCurrency(item.declared_currency ?? '')(
          item.declared_customs_value * quantity
        );

        return `${desc} (${unitPrice} x ${quantity} ▸ ${totalPrice})`;
      }) || []
    );
  }

  itemQuantityChange(value: number, index: number): void {
    if (!this.claim.selected_shipment_items) return;
    const itemQtyLimit = this.esShipment.claim?.selected_shipment_items[index].quantity_limit || 0;

    this.claim.selected_shipment_items[index].quantity = Math.max(0, Math.min(itemQtyLimit, value));
  }

  onChange(value: string | boolean, key: keyof ISubmitClaimPayload) {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'user_description':
        this.claim[key] = value as string;
        break;
      case 'check_certify':
        this.claim[key] = value as boolean;
        break;
    }
  }

  onIncrementorInputChange(index: number, value: number): void {
    if (this.claim.selected_shipment_items) {
      this.claim.selected_shipment_items[index].quantity = value;
    }
  }

  get isDamaged(): boolean {
    return this.esFlowStep === ClaimStep.Damaged;
  }

  get atleastOneItemSelected(): boolean {
    return !!(
      this.claim.selected_shipment_items &&
      this.claim.selected_shipment_items.some((item) => item.quantity > 0)
    );
  }

  get error(): string {
    // Gets the correct error message depending on what fields are missing.
    const isTooMuchText = this.claim.user_description && this.claim.user_description.length > 350;
    const isMissingInputs = !this.claim.user_description || !this.claim.check_certify;
    const isMissingRequiredSelection = this.isDamaged && !this.atleastOneItemSelected;

    if (isTooMuchText) return 'over-character-limit-error';
    if (isMissingInputs && isMissingRequiredSelection) return 'missing-items-and-fields-error';
    if (isMissingInputs) return 'only-missing-fields-error';
    if (isMissingRequiredSelection) return 'only-missing-items-error';
    return '';
  }

  submit() {
    if (this.esBusy || this.photoBusy) return;
    if (this.error) {
      this.showValidationErrors = true;
      return;
    }

    this.esOnSubmit(this.claim);
  }
}

const ClaimDetailsComponent: ng.IComponentOptions = {
  controller: ClaimDetails,
  template,
  bindings: {
    esClose: '&',
    esTriggerSelectStep: '&',
    esFlowStep: '<',
    esShipment: '<',
    esOnSubmit: '<',
    esBusy: '<',
  },
};

export { ClaimDetailsComponent };
