import { IComponentController } from 'angular';
import { IStore, IStoreService } from 'typings/store';
import { IUserSession } from 'typings/user-session';
import { toastError } from '@client/core/components/react/Toastify';
import template from './add-products-modal-stores.html?raw';
import style from './add-products-modal-stores.module.scss';

class AddProductsModalStores implements IComponentController {
  style = style;
  stores: IStore[] = [];
  esOnStoreSelect(value: any) {}

  static $inject = ['StoreService', '$state', 'UserSession', '$translate'];
  constructor(
    private StoreService: IStoreService,
    private $state: ng.ui.IStateService,
    private UserSession: IUserSession,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit(): void {
    this.stores = this.StoreService.stores.filter((store) => {
      return store && store.platform && store.platform.name !== 'API';
    });
  }

  goToActions(store: IStore | null): void {
    this.esOnStoreSelect({ store });
  }

  goToConnectPage(): void {
    if (!this.UserSession.hasUserRole('integrations')) {
      toastError(this.$translate.instant('global.page-access-disabled'));
      return;
    }
    this.$state.go('app.connect');
  }
}

const AddProductsModalStoresComponent: ng.IComponentOptions = {
  controller: AddProductsModalStores,
  template,
  bindings: {
    esOnStoreSelect: '&',
  },
};

export { AddProductsModalStoresComponent };
