import angular from 'angular';
import { WebhooksComponent } from './webhooks.component';
import { WebhookCardComponent } from './webhook-card/webhook-card.component';
import { WebhookConnectComponent } from './webhook-connect/webhook-connect.component';
import { WebhookUpdateComponent } from './webhook-update/webhook-update.component';
import { WebhookDeleteComponent } from './webhook-delete/webhook-delete.component';
import { WebhookModal } from './webhook-modal/webhook-modal.service';
import { WebhookModalMainComponent } from './webhook-modal-main/webhook-modal-main.component';

angular
  .module('app.webhooks', ['core.components', 'app.global.webhooks'])
  .component('webhooks', WebhooksComponent)
  .component('webhookCard', WebhookCardComponent)
  .component('webhookConnect', WebhookConnectComponent)
  .component('webhookUpdate', WebhookUpdateComponent)
  .component('webhookModalMain', WebhookModalMainComponent)
  .component('webhookDelete', WebhookDeleteComponent)
  .service('WebhookModal', WebhookModal);
