import angular from 'angular';
import { ProfileComponent } from './profile.component';
import { EmailPreview } from './email-preview/email-preview.service';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { ChangeEmailModalComponent } from './change-email-modal/change-email-modal.component';

const MODULE_NAME = 'app.account.profile';

angular
  .module(MODULE_NAME, [
    'app.factory.UserSession',
    'app.factory.Auth',
    'app.service.CompanyService',
    'core.service.mixpanel',
    'app.global.helper',
    'core.components',
  ])
  .component('profile', ProfileComponent)
  .component('esChangePasswordModal', ChangePasswordModalComponent)
  .component('esChangeEmailModal', ChangeEmailModalComponent)
  .service('EmailPreviewService', EmailPreview);

export default MODULE_NAME;
