import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './flat-rate-box-update.html?raw';
import style from './flat-rate-box-update.module.scss';

class FlatRateBoxUpdateController {
  static $inject = ['$translate', 'API', 'FlatRateBoxService', 'MixpanelService', 'BoxService'];

  constructor($translate, API, FlatRateBoxService, MixpanelService, BoxService) {
    this.API = API;
    this.BoxService = BoxService;
    this.FlatRateBoxService = FlatRateBoxService;
    this.MixpanelService = MixpanelService;
    this.$translate = $translate;

    this.style = style;
    this.loading = false;
  }

  $onInit() {
    this.courierBoxGroup = angular.copy(this.box);

    this.courierBoxGroup.boxes = this.courierBoxGroup.boxes
      .map(function (box) {
        if (box.inner_length && box.inner_width && box.inner_height) {
          // eslint-disable-next-line no-param-reassign
          box.volume = box.inner_length * box.inner_width * box.inner_height;
        }
        return box;
      })
      .sort(function (a, b) {
        return a.volume - b.volume;
      });

    this.MixpanelService.track('Flat rates boxes - Edit - Start');
  }

  onAutoSelectionChange(value, index) {
    this.courierBoxGroup.boxes[index].auto_selected = value;
  }

  onSubmit() {
    this.loading = true;

    this.FlatRateBoxService.updateBox(this.courierBoxGroup.boxes, this.courierBoxGroup.logo_url)
      .then(() => {
        this.close();
        toastSuccess(
          this.$translate.instant('global.update-success', {
            noun: this.courierBoxGroup.umbrella_name,
          })
        );
        this.MixpanelService.track('Flat rates boxes - Edit - Submit');
      })
      .catch(() => {
        this.loading = false;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  onCancel() {
    this.close();
  }
}

const FlatRateBoxUpdateComponent = {
  template,
  controller: FlatRateBoxUpdateController,
  bindings: {
    box: '<',
    close: '&',
    onDelete: '&',
  },
};

export { FlatRateBoxUpdateComponent };
