import ReactRootProviders from '@/providers/ReactRootProviders';
import { UserSessionProvider } from '@/providers//UserSessionProvider';
import { react2angular } from 'react2angular';
import React, { ReactElement } from 'react';
import VerificationRequiredBanner from '@/features/IdentityVerification';
import { VerificationRequiredModalProps } from '@/features/IdentityVerification/types';

export function WrappedVerificationRequiredBanner(
  props: VerificationRequiredModalProps
): ReactElement<VerificationRequiredModalProps> {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <VerificationRequiredBanner {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularVerificationRequiredBanner = react2angular(
  WrappedVerificationRequiredBanner,
  [],
  ['$injector']
);
