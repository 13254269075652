import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '@client/src/theme';
import { GatewayProvider } from './GatewayProvider';
import I18nProvider from './I18nProvider';
import QueryClientProvider from './QueryClientProvider';

interface ReactRootProvidersProps {
  children: React.ReactNode;
}

export default function ReactRootProviders({ children }: ReactRootProvidersProps) {
  return (
    <ThemeProvider theme={theme}>
      <GatewayProvider>
        <QueryClientProvider>
          <I18nProvider>
            {children}
            {/* NOTE: currently the Toastify is registered by <es-toastify> in app.js */}
            {/* <Toastify /> */}
          </I18nProvider>
        </QueryClientProvider>
      </GatewayProvider>
    </ThemeProvider>
  );
}
