import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';
import { ICountryService } from 'typings/auth/services/country';
import { IShipmentAddress, IShipmentListItem } from 'typings/shipment.d';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import template from './shipment-list-receiver-form.html?raw';
import style from './shipment-list-receiver-form.module.scss';

class ShipmentListReceiverForm implements IComponentController {
  style = style;
  receiverForm: ng.IFormController | null = null;
  busy = false;
  esIndex: number | null = null;

  static $inject = [
    'ShipmentListAdvancedService',
    'CountryService',
    'MixpanelService',
    'AppCuesService',
  ];
  constructor(
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private CountryService: ICountryService,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService
  ) {}

  $onInit(): void {
    this.CountryService.getUSStates();
  }

  closeAllShipmentField(isClearData = true): void {
    this.ShipmentListAdvancedService.closeAllField(this.esIndex, isClearData);
  }

  onOriginChange(changes: {
    originAddressId?: string;
    originCountryId?: number;
    originAddress?: IShipmentAddress;
  }): void {
    if (!this.selectedShipment) return;

    this.selectedShipment.origin_address_id = changes.originAddressId;
    this.selectedShipment.origin_address = changes.originAddress;
    this.selectedShipment.origin_country_id = changes.originCountryId;
  }

  updateShipment(): void {
    if ((this.receiverForm && this.receiverForm.$invalid) || !this.selectedShipment) {
      return;
    }

    this.busy = true;
    this.selectedShipment.recalculate_shipment = false;
    this.ShipmentListAdvancedService.updateShipmentByReceiverForm(this.selectedShipment)
      .then((data) => {
        if (this.esIndex || this.esIndex === 0) {
          this.ShipmentListAdvancedService.modifyShipmentAfterUpdate(this.esIndex, data.shipment);
        }

        // Mixpanel
        if (data.shipment) {
          this.MixpanelService.track('Edit Address - Success', {
            easyship_shipment_id: data.shipment.easyship_shipment_id,
          });

          const { price_comparison } = data.shipment;
          if (!(price_comparison && price_comparison.rates && price_comparison.rates.length > 0)) {
            this.AppCuesService.track('Advanced | Shipment No Rates', null, true);
          }
        }
      })
      .catch((error: any) => {
        if (this.selectedShipment) {
          this.MixpanelService.track('Edit Address - Failed', {
            easyship_shipment_id: this.selectedShipment.easyship_shipment_id,
            error: error.status,
            error_data: error.data,
          });
        }
      })
      .finally(() => {
        this.busy = false;
      });
  }

  get selectedShipment(): IShipmentListItem | null {
    return this.ShipmentListAdvancedService.selectedShipment;
  }
}

const ShipmentListReceiverFormComponent: ng.IComponentOptions = {
  controller: ShipmentListReceiverForm,
  template,
  bindings: {
    esIndex: '<',
  },
};

export { ShipmentListReceiverFormComponent };
