import Hotjar from '@hotjar/browser';

export enum TriggerSurveyEvent {
  PrintLabelAfterPurchase = 'Trigger Survey - Print Label After Purchase',
  LabelPurchasedPickPack = 'Trigger Survey - Label Purchased Pick & Pack',
  ShipmentsTableAfterCustomized = 'Trigger Survey - Table Customised',
  AutoRechargeSaved = 'Trigger Survey - Auto Recharge Saved',
}

const APP_ENV = import.meta.env.VITE_APP_ENVIRONMENT;
const SITE_ID = import.meta.env.VITE_HOTJAR_SITE_ID;
const HOTJAR_VERSION = 6;
let isInitialized = false;

if (APP_ENV === 'production' || APP_ENV === 'staging') {
  Hotjar.init(SITE_ID, HOTJAR_VERSION, {
    debug: APP_ENV === 'staging',
  });

  isInitialized = true;
}

class HotjarService {
  identify(userId: string, userInfo: Parameters<typeof Hotjar.identify>[1]) {
    if (isInitialized) {
      Hotjar.identify(userId, userInfo);
    }
  }

  triggerSurveyEvent(event: TriggerSurveyEvent) {
    if (isInitialized) {
      Hotjar.event(event);
    }
  }
}

export { HotjarService };
export default new HotjarService();
