/* eslint-disable no-param-reassign */
import { toastError } from '@client/core/components/react/Toastify';

(function () {
  angular.module('easyshipDashboardApp').controller('RequestPickupCtrl', RequestPickupCtrl);

  RequestPickupCtrl.$inject = [
    '$stateParams',
    '$state',
    '$scope',
    '$translate',
    'UserSession',
    'AddressService',
    'CheckoutService',
    'MixpanelService',
  ];

  function RequestPickupCtrl(
    $stateParams,
    $state,
    $scope,
    $translate,
    UserSession,
    AddressService,
    CheckoutService,
    MixpanelService
  ) {
    const vm = this;
    const isSingleShipmentFlow = $state.current.name === 'your-info';

    vm.CheckoutService = CheckoutService;
    vm.UserSession = UserSession;

    vm.busy = {};
    vm.error = {};

    const originState = $state.current.parent;

    // Redirects back to Multiple Shipments if no params
    if (!Object.keys(CheckoutService.data).length) {
      if (originState === 'app.single') {
        $state.go('add-shipment');
      } else {
        $state.go('app.multiple', { page: 1 });
      }

      return;
    }

    if (originState === 'app.single') {
      vm.isSingle = true;
    }

    (function init() {
      vm.busy.page = true;

      vm.showResidentialCoverageCard = CheckoutService.hasResidentialCoverageToBeApplied();
      vm.showInsuranceCard = CheckoutService.hasInsuranceToBeApplied();
      vm.showHandOverCard = !CheckoutService.doesNotHaveHandoverToChoose();
      vm.pageTitleTranslationSlug = `checkout.request-pickup.title${
        !vm.showHandOverCard ? '-only-add-ons' : ''
      }${!vm.showResidentialCoverageCard && !vm.showInsuranceCard ? '-only-handover' : ''}`;

      if (vm.showResidentialCoverageCard) {
        MixpanelService.track('Add ons - Residential Surcharge - Show Upsell Card', {});
      }

      AddressService.getAddressesAndFormat()
        .then(function (addresses) {
          vm.addresses = addresses;
          vm.busy.page = false;
        })
        .catch(function () {
          toastError($translate.instant('checkout.request-pickup.address-error'));
          vm.busy.page = false;
          vm.busyNext = false; // For single shipment
          vm.error.page = true;
        });
    })();

    $scope.next = function (data) {
      if (data?.schedule_pickup_later) {
        CheckoutService.payload.schedule_pickup_later = true;
        // clean selected
        CheckoutService.payload.origins.forEach((origin, originIndex) => {
          (origin.pickups || []).forEach((pickup, courierIndex) => {
            pickup.handover_option_id = null;
            pickup.handoverOptionCategory = null;
            pickup.handoverPickupFee = null;
            pickup.selected_date = null;
            pickup.time_slot_id = null;
            CheckoutService.recalculateCourierTotal(0, courierIndex, originIndex);
            CheckoutService.data.origins[originIndex].couriers[courierIndex].totals = angular.copy(
              CheckoutService.data.origins[originIndex].couriers[courierIndex].totals
            );
          });
        });
      } else delete CheckoutService.payload.schedule_pickup_later;
      if (!canGoToNextStep()) return;

      CheckoutService.recalculateTotalsToPay();
      sendMixpanelEvent();

      // eslint-disable-next-line consistent-return
      return isSingleShipmentFlow ? proceedSingle($stateParams.fullShipment) : proceedMultiple();
    };

    this.CheckoutService.next = $scope.next;

    $scope.prev = function () {
      $state.go('choose-courier', { shipment: $stateParams.fullShipment });
    };

    vm.onResidentialCoverageSelect = function (value) {
      // Update value
      vm.CheckoutService.payload.set_all_as_residential = value;

      // Ensure to reset the error state if it was present previously
      vm.CheckoutService.errors.residential = false;

      // Dispatch costs
      vm.CheckoutService.dispatchResidentialCoverageCost();
    };

    vm.onInsuranceSelect = function (value) {
      // Update value
      if (vm.CheckoutService.payload) {
        vm.CheckoutService.payload.set_all_as_insured = value;
      }

      // Ensure to reset the error state if it was present previously
      if (vm.CheckoutService.errors) {
        vm.CheckoutService.errors.insurance = false;
      }

      // Dispatch costs
      vm.CheckoutService.updateInsuranceCost(value);
    };

    function canGoToNextStep() {
      if (vm.busy.page) return false;

      CheckoutService.payload.origins.forEach(function (payloadOrigin) {
        AddressService.checkForPickupInstructionErrorInPayloadOrigin(payloadOrigin).then(function (
          pickupInstructionErrorState
        ) {
          const hasPickupInstructionError = Object.values(pickupInstructionErrorState).includes(
            true
          );

          if (hasPickupInstructionError) payloadOrigin.errors = pickupInstructionErrorState;
          else delete payloadOrigin.errors;
        });
      });

      const ready = CheckoutService.hasEveryInputsNeeded(CheckoutService.payload);

      if (!ready) toastError($translate.instant('toast.incomplete-form'));
      return ready;
    }

    function proceedSingle(shipment) {
      return $state.go('order-summary', { fullShipment: shipment });
    }

    function proceedMultiple() {
      return $state.go('app.order-summary', {
        shipment_id: $state.params.shipment_id,
      });
    }

    function sendMixpanelEvent() {
      const eventData = {};
      CheckoutService.payload.origins.forEach(function (origin, idx) {
        origin.pickups.forEach(function (pickup) {
          eventData[`${pickup.courierAdminName}_handover_available`] =
            pickup.availableHandoverOptions;

          eventData[`${pickup.courierAdminName}_handover_chosen`] = buildDetailedCategoryName(
            pickup.handoverOptionCategory,
            pickup.handoverPickupFee
          );

          eventData[`${pickup.courierAdminName}_pickup_fee`] = pickup.handoverPickupFee;
        });

        if (AddressService.isPickupInstructionBetaFeatureEnabled()) {
          eventData[`origin_${idx}_pickup_instruction`] =
            AddressService.getMixpanelPickupInstructionValue(
              origin.pickup_instruction_slug,
              origin.pickup_instruction_users_input
            );
        }
      });
    }

    function buildDetailedCategoryName(handoverOptionCategory, pickupFee) {
      if (handoverOptionCategory === 'pickup') {
        return pickupFee ? 'paid_pickup' : 'free_pickup';
      }
      return 'dropoff';
    }
  }
})();
