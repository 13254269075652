import { IFileWithMeta } from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
// import EXIF from 'exif-js';

const RE_IMAGE_TYPE = /^image\//;

export const getFilesFromEvent = (
  event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>
): Promise<File[]> => {
  return getDroppedOrSelectedFiles(event).then((chosenFiles: any[]) =>
    chosenFiles
      .map(({ fileObject }) => fileObject)
      .filter((fileObject) => RE_IMAGE_TYPE.test(fileObject.type))
  );

  // Potential Enhancement: Use this variation to access EXIF metadata
  // EXIF.getData(chosenFiles[0].fileObject, () => resolve(chosenFiles.map(({ fileObject }) => fileObject)));
};

// @see https://react-dropzone-uploader.js.org/docs/api#status-values
export const handleChangeStatus =
  (
    previews: string[],
    setPreviews: (previews: string[]) => void,
    setUploading: (busy: boolean) => void,
    _handleRemove: (index: number) => void,
    onResponse?: (response?: any) => void,
    onError?: (message: string) => void
  ) =>
  (file: IFileWithMeta, status: string) => {
    switch (status) {
      case 'preparing':
        setUploading(true);
        break;

      case 'getting_upload_params':
        if (file.meta.previewUrl) setPreviews([...previews, file.meta.previewUrl]);
        break;

      case 'error_upload':
        // TBD: better error handling
        // switch (file.xhr.status) {
        //   default:
        (onError ? onError : console.warn)(
          `Upload failed with status ${file.xhr?.status || 'unknown'}. Please try again later.`
        );
        // }
        break;

      case 'done':
        setUploading(false);
        if (onResponse && file.xhr?.responseText) onResponse(JSON.parse(file.xhr.responseText));
        break;

      case 'uploading':
      case 'removed':
    }
  };

export const handleSubmit = (_files: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
  allFiles.forEach((f) => f.remove());
};
