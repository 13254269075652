(function () {
  'use strict';

  angular.module('easyshipDashboardApp').controller('ConnectCtrl', ConnectCtrl);

  ConnectCtrl.$inject = ['$state', 'ContactSalesModal'];

  function ConnectCtrl($state, ContactSalesModal) {
    var vm = this;

    vm.onStoreCreated = function (store) {
      $state.go(
        'app.single-store',
        {
          id: store.id,
          store: store,
          refetch: true,
        },
        {
          reload: true,
        }
      );
    };

    vm.onPlatformCardClicked = function () {
      return ContactSalesModal.open('NetSuite');
    };
  }
})();
