import { IChangePlanModalProps } from '@typings/subscription';

interface ComponentListenerType {
  showChangePlanModal: (data: IChangePlanModalProps) => void;
}

export interface IChangePlanService {
  showChangePlanModal: (data: IChangePlanModalProps) => void;
  setComponentListener: (Comp: ComponentListenerType) => void;
}

class ChangePlanService implements IChangePlanService {
  ComponentListener: ComponentListenerType | null = null;

  constructor() {
    this.showChangePlanModal = this.showChangePlanModal.bind(this);
    this.setComponentListener = this.setComponentListener.bind(this);
  }

  setComponentListener(comp: ComponentListenerType) {
    this.ComponentListener = comp;
  }

  showChangePlanModal(data: IChangePlanModalProps) {
    this.ComponentListener?.showChangePlanModal(data);
  }
}

export { ChangePlanService };
