import angular from 'angular';

import { AddProductsModalManualInputComponent } from './add-products-modal-manual-input.component';

const MODULE_NAME = 'app.product-listing.add-products-modal.manual-input';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingAddProductsModalManualInput', AddProductsModalManualInputComponent);

export default MODULE_NAME;
