import angular from 'angular';
import { react2angular } from 'react2angular';

import { WrappedDisconnectedStoreAlert } from './DisconnectedStoreAlert';

const MODULE_NAME = 'app.global.components.disconnected-store-alert';

angular
  .module(MODULE_NAME, [])
  .component(
    'esDisconnectedStoreAlert',
    react2angular(WrappedDisconnectedStoreAlert, ['store', 'source', 'onDismissal'], ['$injector'])
  );

export default MODULE_NAME;
