import angular from 'angular';

import { ItemListComponent } from './item-list.component';

const MODULE_NAME = 'app.pickups.item-list';

angular
  .module(MODULE_NAME, ['ui.router', 'app.factory.UserSession', 'app.pickups'])
  .component('esPickupsItemList', ItemListComponent);

export default MODULE_NAME;
