import template from './copy-clipboard-input.html?raw';
import style from './copy-clipboard-input.module.scss';

import copyIcon from './assets/icon-copy.svg?svgo';

class CopyClipboardInput {
  static $inject = ['$sce', '$timeout', 'HelperService'];

  constructor($sce, $timeout, HelperService) {
    this.style = style;
    this.$sce = $sce;
    this.$timeout = $timeout;
    this.HelperService = HelperService;
  }

  $onInit() {
    this.copyIconMarkup = this.$sce.trustAsHtml(copyIcon);
  }

  copyText() {
    this.HelperService.copyToClipboard(this.ngModel);
    if (this.onClick) this.onClick();

    this.showCopied = true;

    this.$timeout(() => {
      this.showCopied = false;
    }, 500);
  }
}

const CopyClipboardInputComponent = {
  template,
  controller: CopyClipboardInput,
  transclude: true,
  bindings: {
    esId: '@',
    esReadOnly: '<',
    esHelp: '@',
    ngModel: '<',
    onClick: '&',
  },
};

export { CopyClipboardInputComponent };
