import { ICourierServiceOption } from 'typings/courier';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { ContactSalesModal } from './contact-sales-modal.service';

class ContactSalesModalCtrl {
  STEP_1 = 'contact';
  STEP_2 = 'success';
  step = this.STEP_1;

  static $inject = ['$timeout', 'location', 'courier', 'MixpanelService', 'ContactSalesModal'];
  constructor(
    public $timeout: ng.ITimeoutService,
    public location: string,
    public courier: ICourierServiceOption,
    private MixpanelService: MixpanelService,
    public ContactSalesModal: ContactSalesModal
  ) {
    this.MixpanelService = MixpanelService;
  }

  nextPage(): void {
    this.step = this.STEP_2;
  }

  close(): void {
    this.MixpanelService.track('Schedule Call', { close: true });
    this.ContactSalesModal.close();
  }
}

export { ContactSalesModalCtrl };
