export const RETURN_REASONS = [
  'COURTESY_RETURN_LABEL',
  'EXHIBITION_TRADE_SHOW',
  'FAULTY_ITEM',
  'FOLLOWING_REPAIR',
  'FOR_REPAIR',
  'ITEM_FOR_LOAN',
  'OTHER',
  'REJECTED',
  'REPLACEMENT',
  'TRIAL',
];
