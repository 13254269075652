import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { PopperPlacementType } from '@material-ui/core/Popper';
import { ChevronDown } from '../../../../src/components/icons/ChevronDown';

import { useStyles } from './styles';
import Button from '../Button';
import Popper from '../Popper';

interface DropdownButtonProps {
  children: JSX.Element;
  name: string;
  placement?: PopperPlacementType;
}

export default function DropdownButton({
  children,
  name,
  placement = 'bottom-end',
}: DropdownButtonProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(anchorEl ? null : event?.currentTarget);

  return (
    <>
      <Button
        onClick={handleClick}
        className={classes.root}
        aria-expanded={isOpen}
        endIcon={
          <ChevronDown
            style={{
              color: `${theme.palette.ink.light}`,
              transform: isOpen ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.5s ease-in-out',
            }}
          />
        }
      >
        {name}
      </Button>
      <Popper
        placement={placement}
        open={isOpen}
        anchorEl={anchorEl}
        noPadding
        onClickAway={() => setAnchorEl(null)}
      >
        {children}
      </Popper>
    </>
  );
}
