import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './success.html?raw';
import style from './success.module.scss';

class ReturnModalSuccessReturn {
  static $inject = ['ReturnService', 'API', '$filter', '$translate', 'MixpanelService'];

  constructor(ReturnService, API, $filter, $translate, MixpanelService) {
    this.style = style;
    this.ReturnService = ReturnService;
    this.MixpanelService = MixpanelService;
    this.API = API;
    this.$filter = $filter;
    this.$translate = $translate;

    this.emailPayload = { email_address: '' };
    this.consigneeNotifiedAt = '';
  }

  $onInit() {
    this.successData = this.ReturnService.successData;
    this.emailPayload.email_address = this.shipment.email_address;
  }

  onInputChange(value, key) {
    this.emailPayload[key] = value;
  }

  sendEmail() {
    if (this.emailForm.$invalid) return;

    this.busy = true;

    this.MixpanelService.track('Returns - Return Success - Send Email', {
      easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
    });

    this.ReturnService.sendEmail(this.successData.return_shipment_id, this.emailPayload)
      .then((data) => {
        if (data.consignee_notified_at) {
          // TBD
          this.consigneeNotifiedAt = this.$filter('intlDate')(data.consignee_notified_at, {
            dateStyle: 'medium',
            timeStyle: 'short',
            timeZone: undefined,
          });
        }

        toastSuccess(this.$translate.instant('return-modal.notifications.success'));
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy = false;
      });
  }

  sendOnCopyMixpanelEvent() {
    this.MixpanelService.track('Returns - Return Success - Copy Link', {
      easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
    });
  }
}

const ReturnModalSuccessReturnComponent = {
  controller: ReturnModalSuccessReturn,
  template,
  bindings: {
    shipment: '<',
  },
};

export { ReturnModalSuccessReturnComponent };
