import angular from 'angular';

import mixpanelService from '@client/core/services/mixpanel';
import easyshipProgress from '@client/core/components/utilities/progress';
import '@client/app/factories/UserSession/UserSession.service';
import contactSalesModal from '@client/src/global/contact-sales-modal';
import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import { ChecklistModalComponent } from './checklist-modal.component';

const MODULE_NAME = 'app.global.components.dashboard-sidebar.onboarding-checklist.checklist-modal';

angular
  .module(MODULE_NAME, [
    'ui.router',
    mixpanelService,
    easyshipProgress,
    'app.factory.UserSession',
    contactSalesModal,
    onboardingChecklistService,
    'app.global.app-cues',
  ])
  .component('esOnboardingChecklistModal', ChecklistModalComponent);

export default MODULE_NAME;
