import angular from 'angular';

import subscriptionService from '@client/src/global/services/subscription';
import helperService from '@client/core/services/helper';

import { BeginningPremierCardComponent } from './beginning-premier-card/beginning-premier-card.component';
import { WelcomeLegacyPlanModalComponent } from './modal/modal.component';

const MODULE_NAME = 'app.global.welcome-legacy-plan';

angular
  .module(MODULE_NAME, [subscriptionService, helperService])
  .component('esBeginningPremierCard', BeginningPremierCardComponent)
  .component('esWelcomeLegacyPlanModal', WelcomeLegacyPlanModalComponent);

export default MODULE_NAME;
