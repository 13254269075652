import { IComponentController } from 'angular';
import template from './payment-list-card.html?raw';
import style from './payment-list-card.module.scss';

class PaymentListCard implements IComponentController {
  style = style;
}

const PaymentListCardComponent: ng.IComponentOptions = {
  controller: PaymentListCard,
  template,
  bindings: {
    esCard: '<',
    esOnClick: '&',
  },
};

export { PaymentListCardComponent };
