import * as Sentry from '@sentry/browser';

export interface Params {
  /** The internal identifier for the user. */
  id: string;
  /** The username. Typically used as a better label than the internal id. */
  username: string;
  /** The user's email address. An alternative, or addition, to the username. */
  email: string;
  /** The user's company. */
  companyId: string;
  /** The user's company name. */
  companyName: string;
}

class SentryService {
  configureScope(params: Params): void {
    // https://docs.sentry.io/platforms/javascript/enriching-events/scopes/
    Sentry.configureScope((scope) => {
      scope.setTag('companyId', params.companyId);
      scope.setTag('companyName', params.companyName);
      // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user
      scope.setUser(params);
    });
  }
}

export { SentryService };

export default new SentryService();
