// PartnerStack integration
//
// https://docs.partnerstack.com/docs/growsumojs

class GrowsumoService {
  // Store data in the growsumo window object
  storeData(key, value) {
    if (!window.growsumo) return;
    growsumo.data[key] = value;
  }

  // Submit a new signup to growsumo
  createSignup() {
    if (!window.growsumo) return;
    growsumo.createSignup();
  }
}

export { GrowsumoService };
