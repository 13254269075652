import { IInputGroupRadioModel } from 'typings/form';
import { IBatchService } from 'typings/batch';
import { IGroupedArray } from 'typings/helper';
import { IAdvancedSearchBatchObject } from 'typings/advanced-search';

import {
  MultiselectComponentModelValue,
  MultiselectAttributes,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import style from './batches-multi-select-group.module.scss';
import template from './batches-multi-select-group.html?raw';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class BatchesMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchBatchObject>
  implements IComponentController
{
  style = style;
  busy = false;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchBatchObject>[] = [];

  static $inject = ['$scope', '$translate', 'BatchService'];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private BatchService: IBatchService
  ) {
    super($scope);
    this.fetchCompanyBatches = this.fetchCompanyBatches.bind(this);
  }

  $onInit(): void {
    const noun = this.$translate.instant('global.batch');
    const $t = (key: string) => {
      return this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    };
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];

    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    this.onChangesAssignAndTrigger(
      changes,
      MultiselectAttributes.DisplayText,
      this.fetchCompanyBatches
    );
  }

  private fetchCompanyBatches(): void {
    this.busy = true;
    this.BatchService.getBatches()
      .then((data) => {
        if (data && data.batches) {
          this.esModelOptions = this.BatchService.generateFilterBatchObject(data.batches);
          this.watchAndTrigger('-created_at');
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
        this.esModelOptions = [];
      })
      .finally(() => {
        this.busy = false;
      });
  }
}

const BatchesMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: BatchesMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { BatchesMultiSelectGroupComponent };
