import React from 'react';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Alert from '@client/core/components/react/Alert';
import ClaimStatus from '@client/core/components/react/ClaimStatus';
import Stat from '@client/core/components/react/Stat';

import { IClaim } from 'typings/insurance';

interface ClaimDetailsProps {
  claim: IClaim;
}

export default function ClaimDetails({ claim }: ClaimDetailsProps) {
  const { formatMessage } = useIntl();
  const displayState = claim.review_state === 'approved' ? claim.payment_state : claim.review_state;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Stat
          label={formatMessage({ id: 'insurance-claim.claim-dialog.claim-status' })}
          horizontal
          fixedWidth
        >
          <ClaimStatus state={displayState} />
        </Stat>
      </Grid>

      <Grid item>
        <Stat
          label={formatMessage({ id: 'insurance-claim.claim-dialog.submission-date' })}
          horizontal
          fixedWidth
        >
          <Typography variant="body2">
            <FormattedDate
              value={claim.provider_claim_filed_date}
              dateStyle="medium"
              timeStyle="short"
            ></FormattedDate>
          </Typography>
        </Stat>
      </Grid>

      <Grid item>
        <Stat
          label={formatMessage({ id: 'insurance-claim.claim-dialog.claim-id' })}
          horizontal
          fixedWidth
        >
          <Typography variant="body2">
            {claim.easyship_claim_id || formatMessage({ id: 'global.not-available' })}
          </Typography>
        </Stat>
      </Grid>

      <Grid item>
        <Stat
          label={formatMessage({ id: 'insurance-claim.claim-dialog.issue-type' })}
          horizontal
          fixedWidth
        >
          <Typography variant="body2">
            <FormattedMessage
              id={
                claim.claim_type
                  ? `shipments.modal.claim-summary.${claim.claim_type}`
                  : 'global.not-available'
              }
            />
          </Typography>
        </Stat>
      </Grid>

      <Grid item>
        <Stat
          label={formatMessage({ id: 'insurance-claim.claim-dialog.photo-evidence' })}
          horizontal
          fixedWidth
        >
          <Typography variant="body2">
            <FormattedMessage
              id="shipments.modal.claim-summary.photos"
              values={{ count: claim.all_photos.length }}
            />
          </Typography>
        </Stat>
      </Grid>

      {claim.claim_type === 'damaged_or_missing' && (
        <Grid item style={{ marginTop: 15 }}>
          <Alert severity="success">
            <FormattedMessage id="insurance-claim.claim-dialog.damage-notice" />
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}
