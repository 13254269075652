import { toastError } from '@client/core/components/react/Toastify';
import template from './address-validation-step-pickup.html?raw';

(function () {
  angular
    .module('easyshipDashboardApp')
    .directive('addressValidationStepUsps', addressValidationStepUsps);

  function addressValidationStepUsps() {
    const directive = {
      template,
      controller,
      restrict: 'EA',
      scope: {
        address: '=',
        goBack: '=',
        step: '=',
      },
    };

    return directive;
  }

  controller.$inject = ['$scope', '$translate', 'AddressService', 'createNewAddressModal'];

  function controller($scope, $translate, AddressService, createNewAddressModal) {
    $scope.busy = {};

    (function init() {
      $scope.currentAddress = $scope.address || {};
    })();

    $scope.confirmAddress = function (address) {
      if ($scope.address.old_addressy_id) {
        createNewAddressModal.close();
      } else {
        validateAddressState(address);
      }
    };

    function validateAddressState(address) {
      $scope.busy.valid = true;

      AddressService.validateAddress(address)
        .then(function () {
          $scope.busy.valid = false;

          // Only insert the address if user is creating a new address
          // if (!address.old_addressy_id && address.addressy_id) AddressService.addAddressToAddressArr(address);
          createNewAddressModal.close();
        })
        .catch(function () {
          $scope.busy.valid = false;
          toastError($translate.instant('toast.default-error'));
        });
    }
  }
})();
