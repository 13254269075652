export const getAmountClass = (amount: number) => {
  if (amount > 0) {
    return 'text-teal-900';
  }

  if (amount < 0) {
    return 'text-red-900';
  }

  return '';
};
