import template from './addresses.html?raw';

('use strict');

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.account.addresses', {
      url: '/addresses',
      template,
      controller: 'addressesCtrl',
      controllerAs: 'vm',
      data: {
        requiredUserRight: 'menu.account.addresses',
      },
    });
  },
]);
