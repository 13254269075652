(function () {
  'use strict';

  BatchService.$inject = ['$q', '$translate', 'UserSession', 'Batch', '$filter', 'HelperService'];
  function BatchService($q, $translate, UserSession, Batch, $filter, HelperService) {
    var service = this;
    service.batches = null;

    service.getBatches = function () {
      var params = {
        company_id: UserSession.company.id,
      };

      if (UserSession.company.easyship_company_id === 'CUS277455') {
        params.limit = 1500;
      }

      return $q(function (resolve, reject) {
        Batch.query(
          params,
          {},
          function (response) {
            service.batches = response;
            resolve(response);
          },
          function (errors) {
            reject(errors);
          }
        );
      });
    };

    service.fetchBatches = function () {
      return $q(function (resolve) {
        if (service.batches) {
          resolve(service.batches);
        } else {
          resolve(service.getBatches());
        }
      });
    };

    service.generateFilterBatchObject = function (batches, batchId) {
      var readableBatches = service.buildReadableTextForBatches(batches, true, batchId);

      return HelperService.groupArrayByKey(
        readableBatches,
        // 'group' = MultiselectAttributes.Group
        'group',
        {
          isDisplay: true,
        }
      );
    };

    service.buildReadableTextForBatches = function (batches, isGroup, batchId) {
      batches.forEach(function (batch) {
        batch.displayText = $translate.instant(
          'shipments.filter.batch.option',
          {
            id: batch.easyship_batch_id,
            date: $filter('intlDate')(batch.created_at, {
              dateStyle: 'medium',
              timeZone: undefined,
            }),
            count: batch.number_of_shipments,
          },
          'messageformat'
        );

        if (isGroup) {
          batch.group = 'All';
        }

        if (batchId && batchId.indexOf(batch.id) > -1) {
          batch.selected = true;
        }
      });

      return batches;
    };
  }

  angular
    .module('app.service.BatchService', ['app.factory.Batch'])
    .service('BatchService', BatchService);
})();
