import angular from 'angular';

import mixpanelService from '@client/core/services/mixpanel';
import { ConfirmationModalComponent } from './confirmation-modal.component';

const MODULE_NAME =
  'app.global.components.dashboard-sidebar.onboarding-checklist.confirmation-modal';

angular
  .module(MODULE_NAME, [
    'pascalprecht.translate',
    'app.global.services.onboarding-checklist',
    mixpanelService,
  ])
  .component('esOnboardingConfirmationModal', ConfirmationModalComponent);

export default MODULE_NAME;
