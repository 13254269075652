import angular from 'angular';

import { ItemDescriptionInputGroupComponent } from './item-description-input-group.component';

const MODULE_NAME = 'app.global.components.advanced-search.item-description-input-group';

angular
  .module(MODULE_NAME, [])
  .component('esAdvancedSearchItemDescriptionInputGroup', ItemDescriptionInputGroupComponent);

export default MODULE_NAME;
