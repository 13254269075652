import { IComponentController } from 'angular';
import { IUserService } from 'typings/user-service';
import { IUserSession } from 'typings/user-session';

import template from './ship-ulw-warning-modal.html?raw';
import style from './ship-ulw-warning-modal.module.scss';
import ulwNonRefundable from './ulw-non-refundable.svg?svgo';

class ShipUlwWarningModal implements IComponentController {
  esOnSubmit() {}

  style = style;
  ulwNonRefundable = ulwNonRefundable;
  doNotShowModal = false;

  static $inject = ['UserService', 'UserSession'];
  constructor(private UserService: IUserService, private UserSession: IUserSession) {}

  submit() {
    if (this.doNotShowModal) {
      this.UserService.update({
        hide_usps_lightweight_message: true,
      }).then(() => {
        this.UserSession.user.hide_usps_lightweight_message = true;
      });
    }

    this.esOnSubmit();
  }
}

const ShipUlwWarningModalComponent: ng.IComponentOptions = {
  controller: ShipUlwWarningModal,
  template,
  bindings: {
    esShow: '<',
    esClose: '&',
    esOnSubmit: '&',
  },
};

export { ShipUlwWarningModalComponent };
