import { DateInputOptionsValue } from '@client/src/global/services/shipment-list/data/date-input';
import { IComponentController } from 'angular';
import template from './date-input-group.html?raw';
import style from './date-input-group.module.scss';

type DateNull = Date | null;
type NumberNull = number | null;
type StringNull = string | null;

enum DatePickerValue {
  From = 'from_date',
  To = 'to_date',
  On = 'on_date',
}

interface IInputState {
  from: boolean;
  on: boolean;
  showDaysAgo: boolean;
  showDaysAhead: boolean;
}

class DateInputGroup implements IComponentController {
  style = style;
  esModelSelect: DateInputOptionsValue | null = null;
  today = new Date();
  datePickerValue = DatePickerValue;
  esFromDate: DateNull = null;
  esToDate: DateNull = null;
  esOnDate: DateNull = null;
  esDaysAgo: NumberNull = null;
  esDaysAhead: NumberNull = null;
  esAllowFutureDates = false;
  isShowFromDate = true;
  isShowOnDate = false;
  isShowDaysAgoInput = false;
  isShowDaysAheadInput = false;
  esTitle: StringNull = null;
  options: {
    value: DateInputOptionsValue;
    display: string;
  }[] = [];

  readonly numberValidation = {
    allowNegative: false,
  };

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  $onInit(): void {
    const $t = (key: string) => {
      return this.$translate.instant(`shipments.filter.date-options.${key}`);
    };

    this.options = [
      {
        value: DateInputOptionsValue.EqualTo,
        display: $t('equal-to'),
      },
      {
        value: DateInputOptionsValue.Before,
        display: $t('before'),
      },
      {
        value: DateInputOptionsValue.After,
        display: $t('after'),
      },
      {
        value: DateInputOptionsValue.Between,
        display: $t('between'),
      },
      {
        value: DateInputOptionsValue.DaysAgo,
        display: $t('days-ago'),
      },
      // TODO Disable until we support it in the future
      // {
      //   value: DateInputOptionsValue.Known,
      //   display: 'known',
      // },
      // {
      //   value: DateInputOptionsValue.Unknown,
      //   display: 'unknown',
      // },
    ];

    if (!this.esModelSelect) {
      this.esModelSelect = DateInputOptionsValue.Between;
      this.onValuesChange();
    }

    if (this.esTitle === 'Estimated Delivery Date') {
      this.options.push({
        value: DateInputOptionsValue.DaysAhead,
        display: 'in the coming days',
      });
    }

    this.assignCurrentValue();
  }

  $onChanges(): void {
    this.assignCurrentValue();
  }

  onSelectChange(value: DateInputOptionsValue): void {
    this.esModelSelect = value;
    this.switchDisabledInputState(this.esModelSelect);
    this.onValuesChange();
  }

  onDatePickerChange(value: Date, key: DatePickerValue): void {
    switch (key) {
      case DatePickerValue.From:
        this.esFromDate = value;
        break;
      case DatePickerValue.To:
        this.esToDate = value;
        break;
      case DatePickerValue.On:
        this.esOnDate = value;
        break;
      default:
        break;
    }

    this.onValuesChange();
  }

  onDaysAgoChange(value: number): void {
    this.esDaysAgo = value;
    this.onValuesChange();
  }

  onDaysAheadChange(value: number): void {
    this.esDaysAhead = value;
    this.onValuesChange();
  }

  private onValuesChange(): void {
    this.esOnChange({
      value: {
        select: this.esModelSelect,
        fromDate: this.esFromDate,
        toDate: this.esToDate,
        onDate: this.esOnDate,
        daysAgo: this.esDaysAgo,
        daysAhead: this.esDaysAhead,
      },
    });
  }
  private assignCurrentValue(): void {
    if (this.esModelSelect) {
      this.switchDisabledInputState(this.esModelSelect);
    }
  }

  private switchDisabledInputState(value: string): void {
    const equalState: IInputState = {
      from: false,
      on: true,
      showDaysAgo: false,
      showDaysAhead: false,
    };
    const betweenState: IInputState = {
      from: true,
      on: false,
      showDaysAgo: false,
      showDaysAhead: false,
    };
    const daysAgoState: IInputState = {
      from: false,
      on: false,
      showDaysAgo: true,
      showDaysAhead: false,
    };
    const daysAheadState: IInputState = {
      from: false,
      on: false,
      showDaysAgo: false,
      showDaysAhead: true,
    };

    // TODO Disable until we support it in the future
    // const knownState: IInputState = {
    //   from: false,
    //   on: false,
    //   showDaysAgo: false,
    // };

    switch (value) {
      case DateInputOptionsValue.EqualTo:
      case DateInputOptionsValue.Before:
      case DateInputOptionsValue.After:
        this.setInputDisplayState(equalState);
        this.resetInputValue(equalState);
        break;
      case DateInputOptionsValue.Between:
        this.setInputDisplayState(betweenState);
        this.resetInputValue(betweenState);
        break;
      // TODO Disable until we support it in the future
      // case DateInputOptionsValue.Known:
      // case DateInputOptionsValue.Unknown:
      //   this.setInputDisplayState(knownState);
      //   this.resetInputValue(knownState);
      //   break;
      case DateInputOptionsValue.DaysAgo:
        this.setInputDisplayState(daysAgoState);
        this.resetInputValue(daysAgoState);
        break;
      case DateInputOptionsValue.DaysAhead:
        this.setInputDisplayState(daysAheadState);
        this.resetInputValue(daysAheadState);
        break;
      default:
        break;
    }
  }

  private setInputDisplayState(inputState: IInputState): void {
    this.isShowFromDate = inputState.from;
    this.isShowOnDate = inputState.on;
    this.isShowDaysAgoInput = inputState.showDaysAgo;
    this.isShowDaysAheadInput = inputState.showDaysAhead;
  }

  private resetInputValue(inputState: IInputState): void {
    if (!inputState.from) {
      this.esFromDate = null;
      this.esToDate = null;
    }

    if (!inputState.on) {
      this.esOnDate = null;
    }

    if (!inputState.showDaysAgo) {
      this.esDaysAgo = null;
    }

    if (!inputState.showDaysAhead) {
      this.esDaysAhead = null;
    }
  }
}

const DateInputGroupComponent: ng.IComponentOptions = {
  controller: DateInputGroup,
  template,
  bindings: {
    esTitle: '@',
    esContent: '@',
    esLabel: '@',
    esAllowFutureDates: '<',
    esModelSelect: '<',
    esFromDate: '<',
    esToDate: '<',
    esOnDate: '<',
    esDaysAgo: '<',
    esDaysAhead: '<',
    esOnChange: '&',
  },
};

export { DateInputGroupComponent };
