export const ErrorSrcDirective = (): ng.IDirective => {
  return {
    link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes): void {
      element.bind('error', () => {
        if (attrs.src !== attrs.errorSrc) {
          attrs.$set('src', attrs.errorSrc);
        }
      });
    },
  };
};
