'use strict';
import template from './connect.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.connect', {
      url: '^/connect?platform&code&code_verifier&scope&instanceId&state&selling_partner_id&spapi_oauth_code&host&shop&timestamp&failed',
      template,
      controller: 'ConnectCtrl',
      controllerAs: 'vm',
      data: {
        requiredUserRight: 'menu.connect.new',
      },
    });
  },
]);
