import { IComponentController } from 'angular';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './cancel-modal.html?raw';
import style from './cancel-modal.module.scss';
import { PickupsPageService } from '../pickups-page.service';

type Busy = {
  cancellingPickup: boolean;
};

class CancelModal implements IComponentController {
  style = style;
  esPickupId: string | null = null;
  busy: Busy = {
    cancellingPickup: false,
  };

  static $inject = ['$translate', 'PickupsPageService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private PickupsPageService: PickupsPageService
  ) {}

  esClose(): void {
    // esClose expression bindings, need to add this in order for typescript to successfully compile
  }

  cancel(): void {
    if (!this.esPickupId) return;

    this.busy.cancellingPickup = true;

    this.PickupsPageService.cancel(this.esPickupId)
      .then(({ actions, display_request_state }) => {
        const { pickups } = this.PickupsPageService;

        const pickup = pickups.find((p) => p.pickup_id === this.esPickupId);

        if (pickup) {
          pickup.display_request_state = display_request_state;
          pickup.actions = actions;
        }

        this.PickupsPageService.updateTotals();

        toastSuccess(this.$translate.instant('pickups.cancel-success'));

        this.esClose();
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.cancellingPickup = false;
      });
  }
}

const CancelModalComponent: ng.IComponentOptions = {
  controller: CancelModal,
  template,
  bindings: {
    esPickupId: '<',
    esClose: '&',
  },
};

export { CancelModalComponent };
