import { IComponentController, IOnChangesObject } from 'angular';

import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';

import { IInputGroupRadioModel } from 'typings/form';
import { IGroupedArray, IHelperService } from 'typings/helper';
import { IAdvancedSearchAddressObject } from 'typings/advanced-search';
import { IAddressService } from 'typings/address';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

import template from './address-multi-select-group.html?raw';
import style from './address-multi-select-group.module.scss';

class AddressMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchAddressObject>
  implements IComponentController
{
  style = style;
  busy = {
    gettingAddress: false,
  };
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esTitle = '';
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchAddressObject>[] = [];

  static $inject = ['$scope', '$translate', 'AddressService', 'HelperService'];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private AddressService: IAddressService,
    private HelperService: IHelperService
  ) {
    super($scope);
  }

  $onInit(): void {
    const noun = this.esTitle;
    this.radioModel = [
      {
        title: this.$translate.instant(
          'global.relation.prefixed.is_any_of',
          { noun },
          undefined,
          undefined,
          'escape'
        ),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: this.$translate.instant(
          'global.relation.prefixed.is_none_of',
          { noun },
          undefined,
          undefined,
          'escape'
        ),
        value: MultiselectComponentModelValue.NoneOf,
      },
    ];

    this.assignDefaultRadioModel();
  }

  $onChanges(changes: IOnChangesObject): void {
    this.AddressService.getAddresses().then(() => {
      this.onChangesAssignAndTrigger(changes, 'allFields', this.setOriginAddresses);
    });
  }

  private setOriginAddresses = (): void => {
    this.busy.gettingAddress = true;

    const originAddresses = this.AddressService.getReusableShippingAddresses().map((address) => ({
      ...address,
      group: this.$translate.instant('global.all'),
    }));

    this.esModelOptions = this.HelperService.groupArrayByKey(originAddresses, 'group', {
      isDisplay: true,
    });
    this.watchAndTrigger('allFields');
    this.busy.gettingAddress = false;
  };
}

const AddressMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: AddressMultiSelectGroup,
  template,
  bindings: {
    esTitle: '<',
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { AddressMultiSelectGroupComponent };
