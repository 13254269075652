import angular from 'angular';
import { AngularLearnRequestFeature } from '@client/core/components/wrappers/components/AngularLearnRequestFeature';
import advancedSearch from './advanced-search';
import categoryHsCode from './category-hs-code/angular';
import { countrySelectorModuleName } from './country-selector';
import dashboardHeader from './dashboard-header';
import dashboardSidebar from './dashboard-sidebar';
import disconnectedStoreAlertModuleName from './disconnected-store-alert';
import esUpsDapAgreement from './ups-dap-agreement';
import esUpsTrademarkMessage from './ups-trademark-message';
import notificationCentre from './notification-centre';
import printingOptions from './printing-options';
import requestPickup from './request-pickup';
import shipFromAddressSelector from './ship-from-address-selector';
import shipUlwWarningModal from './ship-ulw-warning-modal';
import shipmentViews from './shipment-views';
import { shipmentActionDropdownModuleName } from './shipment-action/shipment-action-dropdown';
import { shipmentActionPanelModuleName } from './shipment-action/shipment-action-panel';
import taxId from './tax-id';
import toastify from './toastify';

const MODULE_NAME = 'app.global.components';

angular
  .module(MODULE_NAME, [
    advancedSearch,
    categoryHsCode,
    countrySelectorModuleName,
    dashboardHeader,
    dashboardSidebar,
    disconnectedStoreAlertModuleName,
    esUpsDapAgreement,
    esUpsTrademarkMessage,
    notificationCentre,
    printingOptions,
    requestPickup,
    shipFromAddressSelector,
    shipUlwWarningModal,
    shipmentViews,
    shipmentActionDropdownModuleName,
    shipmentActionPanelModuleName,
    taxId,
    toastify,
  ])
  .component('esLearnRequestFeature', AngularLearnRequestFeature);

export default MODULE_NAME;
