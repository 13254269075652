import { toastError } from '@client/core/components/react/Toastify';
import easyshipEnterpriseImageUrl from '@assets/images/dashboard/orientation-modals/easyship-enterprise.svg';
import linkCourierImageUrl from '@assets/images/dashboard/contact-sales-modal/link-courier.svg';
import phoneSavingsImageUrl from '@assets/images/dashboard/orientation-modals/phone-savings.svg';
import mobileBackgroundImageUrl from '@assets/images/dashboard/signup-modal/mobile-background.svg';
import style from './contact-sales.module.scss';
import template from './contact-sales.html?raw';

class ContactSales {
  easyshipEnterpriseImageUrl = easyshipEnterpriseImageUrl;

  linkCourierImageUrl = linkCourierImageUrl;

  phoneSavingsImageUrl = phoneSavingsImageUrl;

  mobileBackgroundImageUrl = mobileBackgroundImageUrl;

  static $inject = [
    '$translate',
    'UserSession',
    'User',
    'HubspotFormsService',
    'MixpanelService',
    'OnboardingChecklistService',
  ];

  constructor(
    $translate,
    UserSession,
    User,
    HubspotFormsService,
    MixpanelService,
    OnboardingChecklistService
  ) {
    this.style = style;
    this.$translate = $translate;
    this.translations = {};
    this.UserSession = UserSession;
    this.User = User;
    this.HubspotFormsService = HubspotFormsService;
    this.MixpanelService = MixpanelService;
    this.OnboardingChecklistService = OnboardingChecklistService;

    // Courier names from lYOC
    // src/couriers/courier-create/courier-create-main
    this.couriers = {
      dhl: 'DHL',
      ups: 'UPS',
      fedex: 'FedEx',
      'australia-post': 'Australia Post',
      'star-track': 'StarTrack',
      'australia-post-on-demand': 'Australia Post On Demand',
      'dhl-ecommerce': 'DHL eCommerce',
      aramex: 'Aramex',
      'couriers-please': 'CouriersPlease',
      dpex: 'DPEX',
      fastway: 'Fastway',
      lasership: 'Lasership',
      'on-trac': 'OnTrac',
      seko: 'Seko',
      tnt: 'Tnt',
    };

    this.schedulableTimes = [
      {
        value: 'asap',
        key: 'As soon as possible',
      },
      {
        value: 'morning',
        key: 'Morning (9:00 - 12:00 AM)',
      },
      {
        value: 'afternoon',
        key: 'Afternoon (12:00 - 6:00 PM)',
      },
      {
        value: 'evening',
        key: 'Evening (6:00 - 9:00 PM)',
      },
    ];

    this.modalLocation = {
      enterprise: {
        open: 'enterprise',
        title: 'Easyship Enterprise',
        description:
          'Chat with one of our experts to to learn more about additional benefits you can receive.',
      },
    };
  }

  $onInit() {
    // Get text from translation table
    this.$translate(['contact-sales', 'global']).then((translations) => {
      this.translations = translations;
    });

    const { user } = this.UserSession;

    this.formData = {
      contact_email: user.email,
      contact_number: user.mobile_phone,
      contact_time: this.schedulableTimes[0].value,
    };

    if (this.location === 'Link courier') {
      this.content = {
        title: 'Tell us what you need',
        description: `Your courier integration is missing? Chat with one of our experts to better understand your needs.`,
      };
    }

    if (this.location === 'New signup') {
      this.content = {
        title: 'Schedule a call',
      };
    }

    if (this.location === 'ACH') {
      this.content = {
        title: 'ACH Payments',
        description: 'Chat with one of our experts to set up ACH payments.',
      };
    }

    if (this.location === 'NetSuite') {
      this.content = {
        title: 'Connect NetSuite',
        description: 'Talk to our experts to help connect your NetSuite account.',
      };
    }

    const { enterprise } = this.modalLocation;
    if (this.location === enterprise.open) {
      this.content = {
        title: enterprise.title,
        description: enterprise.description,
      };
    }
  }

  onInputChange(value, key) {
    this.formData[key] = value;
  }

  onSubmit() {
    if (this.contactForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading = true;

    if (this.location === 'Link courier') {
      this.formData = {
        ...this.formData,
        reason: `Requesting new LYOC. Message from the user: ${this.formData.message}`,
      };
    }

    if (this.location === 'New signup') {
      this.formData = { ...this.formData, reason: 'New signup' };
    }

    if (this.location === 'ACH') {
      this.formData = { ...this.formData, reason: 'Setup ACH' };
    }

    if (this.location === 'NetSuite') {
      this.formData = { ...this.formData, reason: 'Integrate with NetSuite' };
    }

    if (this.location === this.modalLocation.enterprise.open) {
      this.formData = { ...this.formData, reason: 'Request to upgrade to Enterprise' };
    }

    this.HubspotFormsService.scheduleCall({ id: this.UserSession.user.id }, this.formData)
      .then(() => {
        if (this.location === 'New signup') {
          this.MixpanelService.track('Schedule Call', {
            phone: this.formData.contact_number,
            time: this.formData.contact_time,
          });
        }

        this.nextPage();

        if (this.location === 'Link courier') {
          this.OnboardingChecklistService.updateOnboarding(
            this.OnboardingChecklistService.checklistData.Lyoc
          );
        } else {
          this.OnboardingChecklistService.updateOnboarding(
            this.OnboardingChecklistService.checklistData.BookMeeting
          );
        }
      })
      .catch((error) => {
        const defaultMessage = 'Sorry something went wrong. Please try again.';

        this.error.form = (error.data && error.data.status) || defaultMessage;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

const ContactSalesComponent = {
  template,
  controller: ContactSales,
  bindings: {
    courier: '<',
    location: '<',
    nextPage: '&',
    onClose: '&',
  },
};

export { ContactSalesComponent };
