import { useGateways } from '@client/src/global/context/GatewayProvider';
import { useQuery } from '@tanstack/react-query';

export function useItemCategoriesQuery() {
  const { itemGateway } = useGateways();

  return useQuery({
    queryKey: ['itemCategories'],
    queryFn: () => itemGateway.getCategories(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
