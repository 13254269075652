import { IComponentController } from 'angular';
import template from './call-scheduled-card.html?raw';
import style from './call-scheduled-card.module.scss';
import image from './call-scheduled-card.svg?svgo';

class CallScheduledCard implements IComponentController {
  style = style;
  image = image;
}

const CallScheduledCardComponent: ng.IComponentOptions = {
  controller: CallScheduledCard,
  template,
  bindings: {
    esButtonAction: '&',
    esCloseTitleAction: '&',
    esShowCloseButton: '<',
  },
};

export { CallScheduledCardComponent };
