import angular from 'angular';
import inputModule from '../input';
import selectModule from '../select';

import { InputSelectComponent } from './input-select.component';

const MODULE_NAME = 'core.components.input-select';

angular
  .module(MODULE_NAME, [selectModule, inputModule])
  .component('esInputSelect', InputSelectComponent);

export default MODULE_NAME;
