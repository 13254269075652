(function () {
  'use strict';

  CourierService.$inject = ['$q', 'Courier'];

  function CourierService($q, Courier) {
    var service = this;

    service.couriers = [];

    service.hasCouriers = function () {
      return service.couriers && service.couriers.length;
    };

    service.getCouriers = function (options) {
      options = options || {};

      return $q(function (resolve) {
        if (service.couriers.length > 0 && options && !options.refetch) {
          return resolve(service.couriers);
        }

        Courier.get({ scope: options.scope }).$promise.then(function (response) {
          resolve(response.couriers);
          if (options.scope) return;

          service.couriers = angular.copy(response.couriers);
          service.activeCouriers = _.filter(angular.copy(response.couriers), {
            is_active: true,
          });
        });
      });
    };
  }

  angular
    .module('app.service.CourierService', ['app.factory.Courier'])
    .service('CourierService', CourierService);
})();
