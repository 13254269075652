/* eslint-disable max-classes-per-file */
import template from './box-modal.html?raw';

class BoxModalController {
  static $inject = ['BoxModalService', 'step', 'box'];

  constructor(BoxModalService, step, box) {
    this.BoxModalService = BoxModalService;
    this.step = step;
    this.box = box;
  }

  close(result) {
    this.BoxModalService.close(result);
  }

  dismiss() {
    this.BoxModalService.dismiss();
  }
}

class BoxModalService {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(step, box) {
    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: BoxModalController,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        step: () => step,
        box: () => box,
      },
    });

    this.modalReference.result.catch((res) => {
      if (res === 'cancel' || res === 'escape key press') return;
      throw res;
    });

    return this.modalReference.result;
  }

  close(result) {
    this.modalReference.close(result);
  }

  dismiss() {
    this.modalReference.dismiss('cancel');
  }
}

export { BoxModalService };
