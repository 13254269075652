/* eslint-disable max-classes-per-file */
import template from './flat-rates-modal.html?raw';

class FlatRatesModalController {
  static $inject = ['FlatRatesModal', 'params'];

  constructor(FlatRatesModal, params) {
    this.FlatRatesModal = FlatRatesModal;
    this.params = params;
  }

  close() {
    this.FlatRatesModal.close();
  }
}

class FlatRatesModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(params) {
    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: FlatRatesModalController,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        params: () => params,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { FlatRatesModal };
