import angular from 'angular';

import registrationService from '../registration';
import { WeeblyAuthService } from './weebly-auth.service';

const MODULE_NAME = 'auth.services.weebly-auth';

angular.module(MODULE_NAME, [registrationService]).service('WeeblyAuthService', WeeblyAuthService);

export default MODULE_NAME;
