import angular from 'angular';
import ngResource from 'angular-resource';

import '@client/core/config';
import '@client/app/factories/UserSession/UserSession.service';
import '@client/app/services/EndpointService/EndpointService.service';

import { BoxService } from './box.service';
import { BoxResource } from './box.resource';
import { FlatRateBoxService } from './flat-rate-box.service';
import { FlatRateBoxResource } from './flat-rate-box.resource';

angular
  .module('app.global.box', [
    ngResource,
    'core.config',
    'app.service.EndpointService',
    'app.factory.UserSession',
  ])
  .service('BoxService', BoxService)
  .service('BoxResource', BoxResource)
  .service('FlatRateBoxService', FlatRateBoxService)
  .factory('FlatRateBoxResource', FlatRateBoxResource);
