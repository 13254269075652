/* eslint-disable @typescript-eslint/naming-convention */
import { IApiConfig } from 'typings/core/config/api';
import { IStoreService, ISyncOrdersModalService } from 'typings/store';
import { IUserSession } from 'typings/user-session';
import { IShipmentAction } from 'typings/shipment';
import { IReportService } from 'typings/report';
import { FeatureKey } from '@client/data/subscription';

import { ICommaListFilterParams } from '@client/core/filters/to-comma-list.filter';
import { IComponentController, IScope } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import iconOrderSyncUrl from '@assets/images/dashboard/connect/icon-order-sync.svg';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './sync-order-card.module.scss';
import template from './sync-order-card.html?raw';

enum SyncWindowValue {
  sixHours = 'six-hours',
  oneDay = 'one-day',
  oneWeek = 'one-week',
  oneMonth = 'one-month',
  sixMonth = 'six-month',
}

type SyncWindowOption = {
  value: SyncWindowValue;
  label: string;
};

interface IOrderSyncCardForm {
  syncOrdersFromSelection?: SyncWindowValue | null;
  autoSyncOrders?: boolean;
  auto_sync_frequency?: number | null;
  fulfil_delay_method?: string;
  fulfil_delay_hours?: number | null;
  fulfil_time_of_day?: Date | null;
  deactivatePlatformTrackingEmail?: boolean;
}

type OrderSyncCardFormKey =
  | 'syncOrdersFromSelection'
  | 'autoSyncOrders'
  | 'auto_sync_frequency'
  | 'fulfil_delay_method'
  | 'fulfil_delay_hours'
  | 'fulfil_time_of_day'
  | 'use_platform_tracking_email';
const DEFAULT_AUTO_SYNC_FREQUENCY_VALUE = 24;

class StoreSettingsOrderSyncCardController implements IComponentController {
  style = style;
  iconOrderSyncUrl = iconOrderSyncUrl;
  expanded = false;
  showingTagList = false;
  subscriptionModalState = {};
  busy = false;
  isFallbackSettingsDialogOpen = false;
  fallbackSettingsScenarioName = 'Auto Sync Enable';
  fallbackSettingsDialogTrigger: 'autoSyncOrders' | 'manualSyncOrders' | '' = '';
  form: IOrderSyncCardForm = {};
  timezone = '';
  syncWindowOptions: SyncWindowOption[] = [];
  frequencyOptions = [1, 2, 3, 6, 12, 24]; // interval between syncs, in hours
  listOptions: ICommaListFilterParams;
  syncWindowOptionsTranslationKeys: Record<number, SyncWindowValue> = {
    6: SyncWindowValue.sixHours,
    24: SyncWindowValue.oneDay,
    168: SyncWindowValue.oneWeek,
    768: SyncWindowValue.oneMonth,
    4464: SyncWindowValue.sixMonth,
  };
  dateFormat = { dateStyle: 'medium', timeStyle: 'short', timeZone: undefined };
  isJustSynced = false;
  isAddressModalOpen = false;
  isSyncOrderTrue = false;

  static $inject = [
    '$scope',
    '$translate',
    'API',
    'StoreService',
    'SyncOrdersModalService',
    'SubscriptionService',
    'UserSession',
    'ShipmentAction',
    'MixpanelService',
    'AppCuesService',
    'ReportService',
    'UserRightsService',
  ];
  constructor(
    public $scope: IScope,
    private $translate: angular.translate.ITranslateService,
    private API: IApiConfig,
    private StoreService: IStoreService,
    private SyncOrdersModalService: ISyncOrdersModalService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession,
    private ShipmentAction: IShipmentAction,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService,
    private ReportService: IReportService,
    private UserRightsService: UserRightsService
  ) {
    this.updateFallbackSettingsSuccess = this.updateFallbackSettingsSuccess.bind(this);
    this.closeFallbackSettingsDialog = this.closeFallbackSettingsDialog.bind(this);
    this.closeAddressModal = this.closeAddressModal.bind(this);
    this.saveAddressSuccess = this.saveAddressSuccess.bind(this);
    this.listOptions = {
      conjunction: $translate.instant('global.or'),
      className: 'text-blue-700 strong',
    };
  }

  $onInit(): void {
    if (!this.StoreService.activeStore) return;

    this.syncWindowOptions = Object.values(SyncWindowValue).map((option) => ({
      value: option,
      label: this.$translate.instant(
        `store.order-sync.window-options.${option}`
      ) as SyncWindowValue,
    }));

    const {
      sync_orders_from_hours_ago,
      auto_sync_frequency,
      skip_fulfillment,
      fulfil_delay_method,
      fulfil_delay_hours,
      fulfil_time_of_day,
      use_platform_tracking_email,
    } = this.StoreService.activeStore;
    let formDelayMethod = fulfil_delay_method as string;

    if (skip_fulfillment) formDelayMethod = 'never';
    else if (fulfil_delay_method === 'with_delay' && fulfil_delay_hours === 0) {
      formDelayMethod = 'immediate';
    }

    this.form = {
      syncOrdersFromSelection: sync_orders_from_hours_ago
        ? this.syncWindowOptionsTranslationKeys[sync_orders_from_hours_ago]
        : null,
      autoSyncOrders: auto_sync_frequency !== null,
      auto_sync_frequency,
      fulfil_delay_method: formDelayMethod,
      fulfil_delay_hours,
      fulfil_time_of_day: new Date(fulfil_time_of_day || 0),
      deactivatePlatformTrackingEmail: !use_platform_tracking_email,
    };

    const minutesFromUtc = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(minutesFromUtc / 60)).toLocaleString('en', {
      minimumIntegerDigits: 2,
    });
    const minutes = Math.abs(minutesFromUtc % 60).toLocaleString('en', { minimumIntegerDigits: 2 });
    this.timezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/_/g, ' ') ||
      `UTC${minutesFromUtc < 0 ? '-' : '+'}${hours}:${minutes}`;
  }

  openModal(modalName: string): void {
    this.SyncOrdersModalService.open(modalName);
  }

  toggleTagList(): void {
    this.showingTagList = !this.showingTagList;
  }

  save(key: OrderSyncCardFormKey, value: number | string | boolean): void {
    if (!this.StoreService.activeStore) return;

    this.MixpanelService.track(`Store - Update Sync Settings - Start`);

    let changes: { [key: string]: number | string | boolean | null } = {};

    switch (key) {
      case 'syncOrdersFromSelection': {
        this.form.syncOrdersFromSelection = value as SyncWindowValue;

        const match = Object.entries(this.syncWindowOptionsTranslationKeys).find(
          ([k, v]) => value === v
        );
        changes = { sync_orders_from_hours_ago: match ? Number(match[0]) : null };

        this.MixpanelService.track(`Store - Update Sync Settings`, {
          setting: 'Sync window',
          value: changes.sync_orders_from_hours_ago,
        });
        break;
      }

      case 'autoSyncOrders':
        this.form.autoSyncOrders = value as boolean;
        this.form.auto_sync_frequency = value ? DEFAULT_AUTO_SYNC_FREQUENCY_VALUE : null;
        if (this.SubscriptionService.isFreePlan) {
          this.subscriptionModalState = { upgrade: true };
          this.MixpanelService.track(`Store - Update Sync Settings - Subscription Block`);

          return;
        }
        if (
          value &&
          this.StoreService.activeStore.missing_required_fallback &&
          this.isStoreAuthorized
        ) {
          this.isFallbackSettingsDialogOpen = true;
          this.fallbackSettingsDialogTrigger = 'autoSyncOrders';
          return;
        }
        changes = { auto_sync_frequency: this.form.auto_sync_frequency };

        this.MixpanelService.track(`Store - Update Sync Settings`, { setting: 'Auto sync', value });
        break;

      case 'auto_sync_frequency':
        this.form[key] = value as number;
        changes = { [key]: value };

        this.MixpanelService.track(`Store - Update Sync Settings`, {
          setting: 'Auto sync frequency',
        });
        break;

      case 'fulfil_delay_method':
        this.form[key] = value as string;
        if (value === 'never') {
          changes = { fulfil_delay_method: null, skip_fulfillment: true };
        } else if (value === 'immediate') {
          changes = {
            fulfil_delay_method: 'with_delay',
            skip_fulfillment: false,
            fulfil_delay_hours: 0,
          };
        } else {
          changes = {
            fulfil_delay_method: value,
            skip_fulfillment: false,
            fulfil_time_of_day: (this.form.fulfil_time_of_day || new Date()).toISOString(),
            fulfil_delay_hours: (this.form.fulfil_delay_hours as number) || 0,
          };
        }

        this.MixpanelService.track(`Store - Update Sync Settings`, {
          setting: 'Fulfil Delay Method',
          value,
        });
        break;

      case 'fulfil_delay_hours':
        // value is undefined in <easyship-input> callback, so this.form is used
        if (this.form.fulfil_delay_hours === undefined) this.form.fulfil_delay_hours = 0;
        changes = { fulfil_delay_hours: (this.form.fulfil_delay_hours as number) || 0 };

        this.MixpanelService.track(`Store - Update Sync Settings`, {
          setting: 'Fulfil Delay in Hours',
          value: changes.fulfil_delay_hours,
        });
        break;

      case 'fulfil_time_of_day':
        // value is undefined in <easyship-input> callback, so this.form is used
        changes = {
          fulfil_time_of_day: (this.form.fulfil_time_of_day || new Date()).toISOString(),
        };

        this.MixpanelService.track(`Store - Update Sync Settings`, {
          setting: 'Fulfil Delay Time of day',
          value: changes.fulfil_time_of_day,
        });
        break;

      case 'use_platform_tracking_email':
        changes = { use_platform_tracking_email: value };

        this.MixpanelService.track(`Store - Update Sync Settings`, {
          setting: 'Use platform tracking email',
          value: changes.use_platform_tracking_email,
        });
        break;

      default:
        break;
    }

    this.busy = true;
    this.StoreService.updateStore({ ...this.StoreService.activeStore, ...changes })
      .then((data) => {
        this.StoreService.activeStore = { ...this.StoreService.activeStore, ...data.store };
        toastSuccess(this.$translate.instant('store.notifications.order-sync-update'));
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy = false;
      });
  }

  toggleCardSize(): void {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.AppCuesService.track('Store | Expand Sync Settings', null, true);
    }
  }

  syncOrders(): void {
    if (!this.StoreService.activeStore) return;

    if (!this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
      return;
    }

    const store = this.StoreService.activeStore;

    const successCallback = () => {
      this.isJustSynced = true;
    };

    if (store.missing_required_fallback && this.isStoreAuthorized) {
      this.isFallbackSettingsDialogOpen = true;
      this.fallbackSettingsDialogTrigger = 'manualSyncOrders';
      return;
    }

    this.ShipmentAction.syncOrders(
      store,
      null,
      'Store Settings - Sync Orders',
      successCallback,
      successCallback
    ).catch(() => {
      toastError(this.$translate.instant('store.notifications.order-sync-failed'));
    });
  }

  closeFallbackSettingsDialog(): void {
    this.isFallbackSettingsDialogOpen = false;
    this.fallbackSettingsDialogTrigger = '';
    this.form.autoSyncOrders = false;
    this.$scope.$apply();
  }

  updateFallbackSettingsSuccess(): void {
    this.isFallbackSettingsDialogOpen = false;

    if (this.fallbackSettingsDialogTrigger === 'manualSyncOrders') {
      this.syncOrders();
    } else if (this.fallbackSettingsDialogTrigger === 'autoSyncOrders') {
      this.save('autoSyncOrders', true);
    }

    this.fallbackSettingsDialogTrigger = '';
  }

  closeAddressModal(): void {
    this.isAddressModalOpen = false;
  }

  saveAddressSuccess(): void {
    this.isAddressModalOpen = false;
    this.syncOrders();
  }

  // to reset the toggle state
  onUpgradeModalClosed(): void {
    this.form.autoSyncOrders = false;
  }

  get isCompanyEfulfilment(): boolean {
    return this.UserSession.isCompanyEfulfilment();
  }

  get platformAllowsOrderSync(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.get_orders;
  }

  get platformAllowsSyncByStatus(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.customize_fetch_statuses;
    // return !!this.StoreService.activeStore.available_fetch_statuses;
  }

  get platformAllowsSyncByTags(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.order_tags;
  }

  get platformName(): string | undefined {
    return this.StoreService.activeStore?.platform?.display_name;
  }

  get platformIsShopify(): boolean {
    return this.StoreService.activeStore?.platform?.name === 'Shopify';
  }

  get isStoreAuthorized(): boolean {
    return (
      this.StoreService.activeStore?.auth_state === 'verified' &&
      !this.StoreService.activeStore?.requires_reauthorize_permission
    );
  }

  get lastUpdated(): string | undefined {
    return this.StoreService.activeStore?.last_shipments_synced_at;
  }

  get statusesToFetch(): { fulfillment: string[]; payment: string[] } {
    if (!this.StoreService.activeStore) return { fulfillment: [], payment: [] };

    const store = this.StoreService.activeStore;

    return {
      payment: (store.payment_statuses_to_fetch || []).map(
        (status) => store.available_fetch_statuses?.payment_statuses.options[status]
      ) as string[],
      fulfillment: (store.fulfillment_statuses_to_fetch || []).map(
        (status) => store.available_fetch_statuses?.fulfillment_statuses.options[status]
      ) as string[],
    };
  }

  get excludedCountryCount(): number {
    return this.StoreService.activeStore?.excluded_destination_country_alpha2s?.length || 0;
  }

  get tagSettingOperator(): string | undefined {
    return this.StoreService.activeStore?.tag_setting_operator;
  }

  get tagSettingList(): string[] {
    return this.StoreService.activeStore?.tag_setting_list || [];
  }

  get supportUrl(): string {
    return `${this.API.help}/hc/en-us/articles/360022195692`;
  }

  get isBackgroundFetching(): boolean {
    return this.ReportService.busy;
  }

  get isEditSyncFrequencyDisabled(): boolean {
    return !this.isStoreAuthorized || !this.UserRightsService.canEditSyncFrequency;
  }

  get isSyncOrderDisabled(): boolean {
    return !this.isStoreAuthorized || !this.UserRightsService.canCreateShipmentBySync;
  }

  isPlanBadgeVisible(featureKey: FeatureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }
}

const StoreSettingsOrderSyncCardComponent: ng.IComponentOptions = {
  controller: StoreSettingsOrderSyncCardController,
  template,
  bindings: {},
};

export { StoreSettingsOrderSyncCardComponent };
