import { IApiConfig } from 'typings/core/config/api';
import {
  IShipmentListCustomViewsParam,
  IShipmentListCustomViewsResponse,
  IShipmentListOneCustomViewsParam,
  IShipmentListCustomViewsItem,
  IShipmentListParams,
  IShipmentListCustomViewsPayload,
} from 'typings/shipment';

class ShipmentViewsResource {
  resource: any;
  private readonly customViewsPath = 'companies/:company_id/custom_views/:view_id';
  private readonly v1 = 'v1';

  static $inject = ['$resource', 'API'];
  constructor($resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.baseEndpoint}/${this.v1}/${this.customViewsPath}`,
      {},
      {
        update: {
          method: 'PATCH',
        },
      }
    );
  }

  getCustomViews(
    params: IShipmentListCustomViewsParam
  ): ng.IPromise<IShipmentListCustomViewsResponse> {
    return this.resource.get(params).$promise;
  }

  getOneCustomViews(
    params: IShipmentListOneCustomViewsParam
  ): ng.IPromise<IShipmentListCustomViewsItem> {
    return this.resource.get(params).$promise;
  }

  createCustomViews(
    params: IShipmentListParams,
    payload: IShipmentListCustomViewsPayload
  ): ng.IPromise<IShipmentListCustomViewsItem> {
    return this.resource.save(params, payload).$promise;
  }

  updateCustomViews(
    params: IShipmentListOneCustomViewsParam,
    payload: IShipmentListCustomViewsPayload
  ): ng.IPromise<IShipmentListCustomViewsItem> {
    return this.resource.update(params, payload).$promise;
  }

  deleteCustomViews(params: IShipmentListOneCustomViewsParam): ng.IPromise<void> {
    return this.resource.delete(params, {}).$promise;
  }
}

export { ShipmentViewsResource };
