import Input from '@client/core/components/react/Input';
import { toastError } from '@client/core/components/react/Toastify';
import useResetPasswordMutation from '@client/src/auth/hooks/useResetPasswordMutation';
import { Email } from '@client/src/utils/email';
import { Button } from 'easyship-components';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResetPasswordPayload } from '../ForgetPassword.types';

export interface ForgetPasswordFormData {
  email: string;
}

interface ForgetPasswordFormProps {
  defaultData?: Partial<ForgetPasswordFormData>;
  recaptchaToken: string;
  onResetPasswordError?: () => void;
  onResetPasswordSuccess?: (email: string) => void;
}

type FormField = keyof ForgetPasswordFormData;
type FieldErrors = Partial<Record<FormField, string>>;

export function ForgetPasswordForm({
  onResetPasswordError,
  onResetPasswordSuccess,
  recaptchaToken,
  defaultData,
}: ForgetPasswordFormProps) {
  const { formatMessage } = useIntl();
  const [errors, setErrors] = useState<FieldErrors>({});
  const ref = useRef<HTMLFormElement>(null);

  const { mutateAsync: resetPasswordMutateAsync, isLoading } = useResetPasswordMutation();

  function validateEmail(email: string): string | null {
    if (!email) return 'Invalid email format';

    const { isValid } = new Email(email);
    if (!isValid) return 'Invalid email format';
    return null;
  }

  function isFormValid(formData: FormData): boolean {
    const emailError = validateEmail(formData.get('email')?.toString() ?? '');
    const newErrors: FieldErrors = {
      email: emailError ?? undefined,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value === undefined);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!ref.current) return;
    const formData = new FormData(ref.current);
    if (isFormValid(formData)) {
      const payload: ResetPasswordPayload = {
        email: formData.get('email')?.toString().trim() ?? '',
        token: recaptchaToken,
      };
      resetPasswordMutateAsync({
        payload,
        token: recaptchaToken ?? '',
      })
        .then(() => {
          onResetPasswordSuccess?.(payload.email);
        })
        .catch((reason: unknown) => {
          toastError(
            typeof reason === 'string' ? reason : formatMessage({ id: 'toast.default-error' })
          );
          onResetPasswordError?.();
        });
    } else {
      toastError('Please fill in the required fields');
    }
  }

  return (
    <form className="w-full grid gap-y-5 grid-cols-1" ref={ref} onSubmit={handleSubmit}>
      <div>
        <Input
          name="email"
          error={Boolean(errors.email)}
          helperText={errors.email}
          label={formatMessage({ id: 'global.email' })}
          defaultValue={defaultData?.email ?? ''}
        />
      </div>
      <div>
        <Button
          type="submit"
          color="primary"
          className="w-full text-lg font-semibold justify-center"
          loading={isLoading}
        >
          <FormattedMessage id="login.reset-password.button" />
        </Button>
      </div>
    </form>
  );
}
