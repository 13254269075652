import { react2angular } from 'react2angular';

import Uploader, { WrappedUploader } from './Uploader';

export default Uploader;
export const AngularUploader = react2angular(
  WrappedUploader,
  ['endpoint', 'headers', 'photos', 'onSelection', 'onRemove', 'onResponse', 'onError'],
  ['$injector', '$scope']
);
