(function () {
  'use strict';

  LazadaService.$inject = ['$q', '$window', 'PlatformApi', 'UserSession', 'StoreService', 'Auth'];
  function LazadaService($q, $window, PlatformApi, UserSession, StoreService, Auth) {
    var service = this;

    service.getLazadaUrl = function (store) {
      return PlatformApi.lazada
        .get({ company_id: UserSession.company.id })
        .$promise.then(function (response) {
          StoreService.saveStoreValueToLocal(store);
          return response.url;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.saveLazadaToken = function (lazadaGranted) {
      return $q(function (resolve, reject) {
        var store = angular.merge(
          {
            code: lazadaGranted.code,
          },
          StoreService.getStorePayloadFromLocalStorage()
        );

        //Ping the session to be sure that UserSession exists
        Auth.retrieveSession().then(function () {
          PlatformApi.lazada
            .save({ company_id: UserSession.company.id }, { lazada: store })
            .$promise.then(function (response) {
              StoreService.removeStoreValueFromLocal();
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      });
    };
  }

  angular.module('app.service.LazadaService', []).service('LazadaService', LazadaService);
})();
