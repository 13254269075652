import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

(function () {
  function deleteStoreModalCtrl(
    $scope,
    $state,
    $translate,
    StoreService,
    params,
    AppCuesService,
    MixpanelService
  ) {
    const vm = this;

    vm.store = {};
    vm.busy = {};

    (function init() {
      vm.store = params.store;
    })();

    vm.deactivateStore = function () {
      vm.busy.button = true;

      StoreService.deactivateStore(vm.store)
        .then(function () {
          toastSuccess($translate.instant('store.notifications.deleted', { store: vm.store.name }));
          MixpanelService.track('Store Settings - Delete Store', {
            source: 'Store Settings',
            platform: vm.store.platform.name,
            store_id: vm.store.id,
            store_name: vm.store.name,
          });
          AppCuesService.track('Store | Disconnected', {
            name: vm.store.name,
            id: vm.store.id,
          });
          $state.go('app.connect');
          $scope.$close();
        })
        .catch(function (err) {
          toastError(err.data.message || err.error || err);
          vm.busy.button = false;
          $scope.$close();
        });
    };
  }

  deleteStoreModalCtrl.$inject = [
    '$scope',
    '$state',
    '$translate',
    'StoreService',
    'params',
    'AppCuesService',
    'MixpanelService',
  ];

  angular
    .module('app.service.deleteStoreModal')
    .controller('deleteStoreModalCtrl', deleteStoreModalCtrl);
})();
