import angular from 'angular';

import { ReturnResource } from './Return.resource';
import { ReturnService } from './Return.service';

angular
  .module('app.global.return', [
    'core.config',
    'app.global.user-status',
    'core.components',
    'app.global.shipment',
    'app.service.AddressService',
    'ngResource',
  ])
  .factory('ReturnResource', ReturnResource)
  .service('ReturnService', ReturnService);
