(function () {
  'use strict';

  Country.$inject = ['$resource', 'API'];
  function Country($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/countries',
      {
        // id: '@_id'
      },
      {
        query: {
          method: 'GET',
          params: {
            // id: 'me'
          },
        },
      }
    );
  }

  USStates.$inject = ['$resource', 'API'];
  function USStates($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/us_states',
      {
        // id: '@_id'
      },
      {
        query: {
          method: 'GET',
          params: {
            // id: 'me'
          },
        },
      }
    );
  }

  angular
    .module('app.factory.Country', ['ngResource'])
    .factory('Country', Country)
    .factory('USStates', USStates);
})();
