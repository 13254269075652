import { IComponentController } from 'angular';
import template from './shipment-list-delivery-cell.html?raw';
import style from './shipment-list-delivery-cell.module.scss';

class ShipmentListDeliveryCell implements IComponentController {
  style = style;
  esMinDelivery: number | null = null;
  esMaxDelivery: number | null = null;

  $onInit() {
    this.esMinDelivery = Number(this.esMinDelivery);
    this.esMaxDelivery = Number(this.esMaxDelivery);
  }

  get isHighRating(): boolean {
    return this.esMaxDelivery ? this.esMaxDelivery <= 10 : false;
  }

  get isMedRating(): boolean {
    return this.esMaxDelivery ? this.esMaxDelivery > 10 && this.esMaxDelivery <= 20 : false;
  }

  get isLowRating(): boolean {
    return this.esMaxDelivery ? this.esMaxDelivery > 20 : false;
  }

  get isExcessDelivery(): boolean {
    return this.esMinDelivery ? this.esMinDelivery > 30 : false;
  }
}

const ShipmentListDeliveryCellComponent: ng.IComponentOptions = {
  controller: ShipmentListDeliveryCell,
  template,
  bindings: {
    esMinDelivery: '<',
    esMaxDelivery: '<',
  },
};

export { ShipmentListDeliveryCellComponent };
