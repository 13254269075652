import angular from 'angular';
import IItemGateway from '@client/core/corelogic/ports/item.interface';
import { ItemCategory } from '@client/core/corelogic/models/ItemCategory';
import { IItemCategoryService } from 'typings/item-category';
import { ICountryService } from 'typings/auth/services/country';
import {
  ItemSearchCriteria,
  ItemSearchField,
  ItemSearchResult,
} from '@client/core/corelogic/models/ItemSearch';
import { ProductListingService } from '@client/src/product-listing/product-listing.service';
import { ProductListingSuggestField } from 'typings/dashboard/services/product-listing';

export default class NgItemGateway implements IItemGateway {
  private itemCategoryService: IItemCategoryService;
  private productListingService: ProductListingService;
  private countryService: ICountryService;

  constructor() {
    this.itemCategoryService = angular
      .element(document.body)
      .injector()
      .get<IItemCategoryService>('ItemCategoryService');
    this.productListingService = angular
      .element(document.body)
      .injector()
      .get<ProductListingService>('ProductListingService');
    this.countryService = angular
      .element(document.body)
      .injector()
      .get<ICountryService>('CountryService');
  }

  getCategories(): Promise<ItemCategory[]> {
    return new Promise((resolve, reject) => {
      this.itemCategoryService
        .getItemCategories()
        .then((response) =>
          resolve(
            response.map((category) => ({
              fallbackName: category.name,
              hasBattery: category.includes_battery,
              containsLiquids: category.contains_liquids,
              containsBatteryPi966: category.contains_battery_pi966,
              containsBatteryPi967: category.contains_battery_pi967,
              ...category,
            }))
          )
        )
        .catch((reason) => reject(reason));
    });
  }

  async searchItem(search: ItemSearchCriteria): Promise<ItemSearchResult[]> {
    const fieldMap: Record<ItemSearchField, ProductListingSuggestField> = {
      sku: 'identifier',
      description: 'name',
    };
    const itemCategories = await this.itemCategoryService.getItemCategories();

    function getItemCategory(categoryId: number | null): ItemCategory | undefined {
      if (!categoryId) return undefined;

      const category = itemCategories.find((itemCategory) => itemCategory.id === categoryId);

      if (!category) return undefined;

      return {
        id: category.id,
        slug: category.slug,
        fallbackName: category.name,
        hasBattery: category.includes_battery,
        containsLiquids: category.contains_liquids,
        containsBatteryPi966: category.contains_battery_pi966,
        containsBatteryPi967: category.contains_battery_pi967,
      };
    }

    return new Promise((resolve, reject) => {
      this.productListingService
        .suggest(search.value, fieldMap[search.field])
        .then((products) =>
          resolve(
            products.map((product) => ({
              platformSlug: product.css_class || '',
              item: {
                productId: product.id,
                sku: product.identifier ?? '',
                description: product.name,
                pickLocation: product.pick_location ?? '',
                category: getItemCategory(product.item_category_id),
                hsCode: {
                  number: product.hs_code ?? '',
                  description: product.hs_code_description ?? '',
                },
                weight: product.weight || undefined,
                dimensions: {
                  length: product.length || undefined,
                  width: product.width || undefined,
                  height: product.height || undefined,
                },
                originCountry: product.origin_country_id
                  ? this.countryService.findCountry(product.origin_country_id)
                  : null,
                containsLiquids: !!product.contains_liquids,
                hasBattery: !!(product.contains_battery_pi966 || product.contains_battery_pi967),
                containsBatteryPi966: !!product.contains_battery_pi966,
                containsBatteryPi967: !!product.contains_battery_pi967,
              },
            }))
          )
        )
        .catch((reason) => reject(reason));
    });
  }
}
