import React, { createContext, useContext, useMemo } from 'react';
import { VerificationPort } from '../ports/verification';
import { VerificationGateway } from '../gateways/api/VerificationGateway';
import { FakeVerificationGateway } from '../gateways/fake/FakeVerificationGateway';

export interface VerificationGatewayContextData {
  verificationGateway: VerificationPort;
}

const VerificationGatewayContext = createContext<VerificationGatewayContextData | undefined>(
  undefined
);

interface VerificationGatewayProviderProps {
  children: React.ReactNode;
}

function VerificationGatewayProvider({ children }: VerificationGatewayProviderProps) {
  const data: VerificationGatewayContextData = useMemo(
    () => ({
      verificationGateway: new VerificationGateway(),
    }),
    []
  );

  return (
    <VerificationGatewayContext.Provider value={data}>
      {children}
    </VerificationGatewayContext.Provider>
  );
}

interface FakeVerificationGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<VerificationGatewayContextData>;
}

function FakeVerificationGatewayProvider({
  children,
  value,
}: FakeVerificationGatewayProviderProps) {
  const data: VerificationGatewayContextData = useMemo(
    () => ({
      verificationGateway: new FakeVerificationGateway(),
      ...value,
    }),
    [value]
  );

  return (
    <VerificationGatewayContext.Provider value={data}>
      {children}
    </VerificationGatewayContext.Provider>
  );
}

function useVerificationGateway() {
  const verificationGatewayContext = useContext(VerificationGatewayContext);

  if (verificationGatewayContext === undefined) {
    throw new Error('useVerificationGateway must be used within a VerificationGatewayProvider');
  }
  return verificationGatewayContext;
}

export { VerificationGatewayProvider, FakeVerificationGatewayProvider, useVerificationGateway };
