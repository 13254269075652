import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

import { HsCode, formatHsCodeNumber } from '@client/core/corelogic/models/HsCode';
import BoldMatch from '@client/core/components/react/BoldMatch';
import Input from '@client/core/components/react/Input';
import Button from '@client/core/components/react/Button';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import useDebounce from '@client/src/global/hooks/useDebounce';
import { DESCRIPTION_MINIMUM_LENGTH, useHsCodeSuggestionsQuery } from './useHsCodeSuggestionsQuery';

interface SearchHsCodeProps {
  onHsCodeSelect: (hsCodeNumber: string, hsCodeDescription: string) => void;
  backToCategory: () => void;
}

const useStyles = makeStyles((theme) => ({
  textButtonRoot: {
    padding: '11px 12px',
    justifyContent: 'left',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  textButtonIcon: {
    fontSize: '13px !important',
    fontWeight: 'bold',
  },
}));

function BackToCategoryButton(props: ButtonProps) {
  const classes = useStyles();

  return (
    <Button
      fullWidth
      classes={{ root: classes.textButtonRoot }}
      startIcon={<span className={`${classes.textButtonIcon} icon-chevron-left`} />}
      {...props}
    >
      <FormattedMessage id="shipments.category-hs-codes.back-to-category" />
    </Button>
  );
}

export default function SearchHsCode({ onHsCodeSelect, backToCategory }: SearchHsCodeProps) {
  const [keyword, setKeyword] = useState('');
  const keywordDebounced = useDebounce(keyword, 300);
  const { data: options = [], isFetching } = useHsCodeSuggestionsQuery({
    searchValue: keywordDebounced,
  });
  const showNoResultsMessage =
    keywordDebounced.length >= DESCRIPTION_MINIMUM_LENGTH && options.length === 0;

  // Make sure the dot(.) is consider when bolding the match number
  const hsCodePattern = keywordDebounced
    .split('')
    .map((char) => char.replace('.', '\\.'))
    .join('\\.?');

  // Bold each word that matched the search term
  const hsCodeDescriptionPattern = keywordDebounced.split(' ').join('|');

  return (
    <>
      <Input
        style={{ border: 0, borderRadius: 0 }}
        value={keyword}
        onChange={(event) => setKeyword(event.target.value)}
        loading={isFetching}
        autoFocus
        startAdornment={
          <InputAdornment position="start">
            <span className="icon-search text-xl text-ink-100" />
          </InputAdornment>
        }
        endAdornment={
          keyword && (
            <InputAdornment position="end">
              <span
                className="icon-circle-cross text-xl text-ink-100 cursor-pointer"
                onClick={backToCategory}
              />
            </InputAdornment>
          )
        }
      />
      <Divider />
      {options.length > 0 ? (
        <MenuList variant="menu">
          {options.map(({ name, description }, i) => (
            <MenuItem key={i} disableGutters onClick={() => onHsCodeSelect(name, description)}>
              <div>
                <BoldMatch text={formatHsCodeNumber(name)} pattern={hsCodePattern} unescaped />
                {' - '}
                <BoldMatch text={description} pattern={hsCodeDescriptionPattern} unescaped />
              </div>
            </MenuItem>
          ))}
        </MenuList>
      ) : (
        <>
          <div className="p-4 text-ink-100 text-base">
            <FormattedMessage
              id={`shipments.category-hs-codes.${showNoResultsMessage ? 'no-results' : 'tips'}`}
            />
          </div>
          <Divider />
          <BackToCategoryButton onClick={backToCategory} />
        </>
      )}
    </>
  );
}
