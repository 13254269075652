import angular from 'angular';
import uibModal from 'angular-ui-bootstrap/src/modal';

import { PaymentService } from './payment.service';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { AngularPaymentError} from './payment-error';

const MODULE_NAME = 'app.global.payment';

angular
  .module(MODULE_NAME, [
    uibModal,
    'ngResource',
    'app.global.user-status',
    'core.config',
    'core.components',
    'app.factory.UserSession',
    'app.factory.PaymentSource',
    'app.global.payment-source',
    'app.global.dashboard-component-library',
  ])
  .component('paymentCreditCardForm', CreditCardFormComponent)
  .component('esPaymentError', AngularPaymentError)
  .service('PaymentService', PaymentService);

export default MODULE_NAME;
