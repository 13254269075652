import Chip from '@client/core/components/react/Chip';
import Box from '@material-ui/core/Box';
import React from 'react';

interface FeatureChipProps {
  visible: boolean;
  planLabel: string;
}

export default function FeatureChip({ visible, planLabel }: FeatureChipProps) {
  if (!visible) {
    return null;
  }

  return (
    <Box clone ml={1}>
      <Chip label={planLabel} color="teal" variant="rounded" />
    </Box>
  );
}
