import template from './password-reset.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('home.auth.password-reset', {
        url: 'password-reset',
        template,
        controller: 'PasswordResetCtrl',
        authenticate: false,
        controllerAs: 'vm',
        params: {
          userEmail: null,
        },
      });
    },
  ]);
})();
