import template from '../../multiple-shipments/request-pickup/request-pickup.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('your-info', {
        url: '/your-info',
        parent: 'app.single',
        template,
        controller: 'RequestPickupCtrl as vm',
        params: {
          fullShipment: null,
        },
      });
    },
  ]);
})();
