import { IResidentialAddressPayload, IResidentialAddressResponse } from 'typings/check-residential';
import { IApiConfig } from 'typings/core/config/api';

class CheckResidentialResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor($resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/check_residential_address`,
      {},
      {
        checkResidentialAddress: { method: 'POST' },
      }
    );
  }

  checkResidentialAddress(
    params: Record<string, never>,
    payload: IResidentialAddressPayload
  ): ng.IPromise<IResidentialAddressResponse> {
    return this.resource.checkResidentialAddress(params, payload).$promise;
  }
}

export { CheckResidentialResource };
