import template from './address-validation-step-multi.html?raw';

(function () {
  'use strict';

  angular
    .module('easyshipDashboardApp')
    .directive('addressValidationStepMulti', addressValidationStepMulti);

  function addressValidationStepMulti() {
    var directive = {
      template,
      restrict: 'EA',
      scope: {
        address: '=',
        addresses: '=',
        goToStep: '=',
        proceedWithCorrectedAddress: '=',
        busy: '=',
      },
      controller: controller,
      controllerAs: 'addressValidationStepMulti',
    };

    return directive;
  }

  controller.$inject = ['$scope', 'AddressService'];

  function controller($scope, AddressService) {
    var addressValidationStepMulti = this;

    addressValidationStepMulti.addressList = _.map($scope.addresses, function (address) {
      address.displayAddress = AddressService.formatAddress(address);
      return address;
    });

    addressValidationStepMulti.displayAddress = AddressService.formatAddress($scope.address);
  }
})();
