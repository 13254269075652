import template from './table-data.html?raw';
import style from './table-data.module.scss';

class TableData {
  static $inject = ['$element'];

  constructor($element) {
    this.style = style;
    this.$element = $element;
  }

  $onInit() {
    const componentStyle = this.$element[0].style;

    const width = this.esFixedWidth ? `${this.esFixedWidth}px` : null;
    const percentWidth = this.esPercentWidth ? `${this.esPercentWidth}%` : null;

    componentStyle.minWidth = width;
    componentStyle.width = width || percentWidth;
  }
}

const TableDataComponent = {
  template,
  controller: TableData,
  transclude: true,
  bindings: {
    esFixedWidth: '@',
    esPercentWidth: '@',
    esAlign: '@',
  },
};

export { TableDataComponent };
