import { IUserService } from 'typings/user-service';

import { IComponentController } from 'angular';
import template from './items-per-page.html?raw';
import style from './items-per-page.module.scss';

const DEFAULT_ITEMS_PER_PAGE = 10;
const MAX_ITEMS_PER_PAGE = 99;

class ItemsPerPage implements IComponentController {
  style = style;
  esPage: undefined | string = undefined;
  esItemsPerPage = DEFAULT_ITEMS_PER_PAGE;
  maxItemPerPage = MAX_ITEMS_PER_PAGE;
  esOnSelectionChange?: (changes: { value: number }) => void;
  availableItemsPerPage = [10, 25, 50, 100];
  showDropdown = false;
  customItemsPerPage: number | null = null;
  showCustomItemNumberInput = false;

  static $inject = ['$element', '$timeout', 'UserService'];
  constructor(
    private $element: ng.IRootElementService,
    private $timeout: ng.ITimeoutService,
    private UserService: IUserService
  ) {}

  $onInit() {
    this.esItemsPerPage = this.esItemsPerPage || DEFAULT_ITEMS_PER_PAGE;

    if (!this.esPage) {
      throw new Error(`es-items-per-page: An es-page binding must be provided.`);
    }
  }

  onItemsPerPageClick(value: number) {
    if (!this.esPage) return;

    this.setItemsPerPageValue(this.esPage, value);
  }

  onCustomClick($event: KeyboardEvent) {
    $event.stopPropagation();

    this.showCustomItemNumberInput = true;

    this.$timeout(() => {
      this.$element.find('input').focus();
    });
  }

  onDropdownToggle(isOpen: boolean) {
    if (!this.esPage) return;

    if (!isOpen) {
      if (
        this.customItemsPerPage &&
        this.customItemsPerPage > 0 &&
        this.customItemsPerPage <= this.maxItemPerPage
      ) {
        this.setItemsPerPageValue(this.esPage, this.customItemsPerPage);
      } else {
        this.customItemsPerPage = null;
        this.showCustomItemNumberInput = false;
      }
    }

    this.showDropdown = isOpen;
  }

  onCustomInputChange(value: number) {
    this.customItemsPerPage = Number(value);
  }

  onCustomInputKeypress($event: KeyboardEvent) {
    if (!this.esPage) return;

    if (
      $event.key === 'Enter' &&
      this.customItemsPerPage &&
      this.customItemsPerPage > 0 &&
      this.customItemsPerPage <= this.maxItemPerPage
    ) {
      this.setItemsPerPageValue(this.esPage, this.customItemsPerPage);
    }
  }

  private setItemsPerPageValue(page: string, value: number) {
    const payload = {
      dashboard_settings: this.UserService.updateAndGetUserDashboardSettings(
        page,
        'items_per_page',
        value
      ),
    };
    this.UserService.update(payload);

    this.esOnSelectionChange && this.esOnSelectionChange({ value });

    this.esItemsPerPage = value;

    this.showDropdown = false;
    this.customItemsPerPage = null;
    this.showCustomItemNumberInput = false;
  }
}

const ItemsPerPageComponent = {
  controller: ItemsPerPage,
  template,
  transclude: true,
  bindings: {
    esPage: '@',
    esItemsPerPage: '<',
    esOnSelectionChange: '&',
  },
};

export { ItemsPerPageComponent };
