import { toastError } from '@client/core/components/react/Toastify';
import easyshipLogoUrl from '../../../../../../../assets/images/logos/easyship-logo/easyship.svg';

fileUploadCtrl.$inject = [
  '$scope',
  '$state',
  'API',
  'UserSession',
  'ReportService',
  'OnboardingChecklistService',
  'HelperService',
  'MixpanelService',
  'UserRightsService',
];
function fileUploadCtrl(
  $scope,
  $state,
  API,
  UserSession,
  ReportService,
  OnboardingChecklistService,
  HelperService,
  MixpanelService,
  UserRightsService
) {
  $scope.UserSession = UserSession;
  $scope.UserRightsService = UserRightsService;
  $scope.API = API;
  $scope.easyshipLogoUrl = easyshipLogoUrl;

  $scope.loading = false;
  $scope.numberOfShipments = 0;
  $scope.uploadSuccessful = false;
  $scope.errors = false;
  $scope.provider = {
    Easyship: { busy: false },
    Lazada: { busy: false },
    Groupon: { busy: false },
    Zalora: { busy: false },
    Linio: { busy: false },
    Jumia: { busy: false },
  };
  $scope.ReportService = ReportService;
  $scope.selectedForm = '';
  $scope.uploadEndpoint = `${API.endpoint}/cloud/companies/${UserSession.company.id}/shipments/csv_upload`;

  (function init() {
    MixpanelService.track('CSV Upload Modal - Open', {});
  })();

  $scope.showForm = function (name) {
    $scope.selectedForm = name;
  };

  $scope.onStartUpload = function () {
    $scope.ReportService.busy = true;
    $scope.loading = true;

    MixpanelService.track('CSV Upload Modal - Start Upload', {});
  };

  $scope.onFinishUpload = function (error, response) {
    if (error) {
      $scope.ReportService.busy = false;
      toastError(
        this.$translate('orders.import.parse-error', {
          details: error.data && error.data.status,
        })
      );
      $scope.loading = false;
      return;
    }

    OnboardingChecklistService.updateOnboarding('upload_orders');
    ReportService.getReport({
      id: response.data.report_id,
      listParams: { origin: $state.current.name },
    });
    $scope.loading = false;
  };

  $scope.downloadGuidelines = function (language) {
    const supportHsCodeTemplatePlatform = ['Easyship', 'Backerkit', 'Crowdox'];
    const platformsWithoutWeightUnit = ['Groupon'];
    const weightUnit =
      $scope.selectedForm.indexOf(platformsWithoutWeightUnit) === -1 &&
      UserSession.company.weight_unit === 'lb'
        ? '_US'
        : '';

    const isSupportInHsCodeTemplate = supportHsCodeTemplatePlatform.includes($scope.selectedForm);

    const hscode = isSupportInHsCodeTemplate ? '_hscode' : '';

    const baseUrl = `${API.easyship_storage}/csv-templates/`;

    let url = `${baseUrl + $scope.selectedForm}_Sample_and_Guidelines${weightUnit}${hscode}.xlsx`;

    if (language === 'chinese') {
      url = `${baseUrl + $scope.selectedForm}_Sample_and_Guidelines_CN.xlsx`;
    }

    HelperService.openNewTab(url);

    MixpanelService.track('CSV Upload Modal - Download template', {
      template: $scope.selectedForm,
    });
    OnboardingChecklistService.updateOnboarding('download_csv');
  };

  $scope.close = function () {
    $scope.ReportService.resetReportService();
    $scope.$close();
  };

  $scope.goToProductListingsPage = function () {
    // Using window.location instead of a state change because in client/app/routes/create-shipments/multiple-shipments/create-modals/create-modal.js the state is always being superseded by the modal result method. Changing it would reauire a bigger refactoring and a lot of QA.
    // TODO: Refactor state transition in shipment creation modal success
    window.location.href = `${API.dashboard}/product-listing`;
  };

  $scope.getPlatformSvgLogoUrl = function (filename) {
    return new URL(
      `../../../../../../../assets/images/logos/platform-logo-square/${filename}.svg`,
      import.meta.url
    ).href;
  };

  $scope.getPlatformPngLogoUrl = function (filename) {
    return new URL(
      `../../../../../../../assets/images/logos/platform-logo-square/${filename}.png`,
      import.meta.url
    ).href;
  };

  $scope.canCreateShipmentByUpload = UserRightsService.canCreateShipmentByUpload;

  $scope.wrappers = {
    a(chunk) {
      return `<a href="{{API.help}}/hc/en-us/articles/13390278761369" target="_blank" rel="noopener">${chunk}</a>`;
    },
    product(chunk) {
      if (
        UserSession.getCompanyDashboardSettings().beta_feature_team_rights_and_permissions &&
        !UserSession.hasUserRole('product_listing')
      ) {
        return `<span>${chunk}</span>`;
      }
      return `<a href="{{API.dashboard}}/product-listing">${chunk}</a>`;
    },
  };
}

angular.module('easyshipDashboardApp').controller('fileUploadCtrl', fileUploadCtrl);
