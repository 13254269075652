import { IComponentController } from 'angular';
import { IUserSession } from 'typings/user-session';
import { IWalkthroughService } from 'typings/walkthrough';
import template from './lyoc-banner.html?raw';
import style from './lyoc-banner.module.scss';
import lyocBannerImageUrl from '@assets/images/dashboard/lyoc-banner/lyoc-banner.png';

class LyocBannerController implements IComponentController {
  style = style;
  showBanner = false;
  lyocBannerImageUrl = lyocBannerImageUrl;

  static $inject = ['WalkthroughService', 'UserSession', '$state'];
  constructor(
    private WalkthroughService: IWalkthroughService,
    private UserSession: IUserSession,
    private $state: ng.ui.IStateService
  ) {}

  $onInit() {
    this.showBanner =
      this.UserSession.hasNotSeenWalkthrough('lyoc-banner') &&
      this.UserSession.hasUserRole('account_and_settings');
  }

  redirectToCourierPage(): void {
    this.$state.go('app.couriers');
  }

  close(): void {
    this.WalkthroughService.updateUserFlowRecords('walkthrough', 'lyoc-banner');
    this.showBanner = false;
  }
}

const LyocBannerComponent = {
  controller: LyocBannerController,
  template,
};

export { LyocBannerComponent };
