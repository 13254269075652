import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import COLORS from '@client/src/colors';
import Button from '@client/core/components/react/Button';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { ProductFormData } from '../reset-confirmation/ProductFormData';

interface ProductDetailsDrawerToolbarProps {
  index: number;
  lastIndex: number;
  totalCount: number;
  offset: number;
  isUpdating: boolean;
  isDeleting: boolean;
  onNavigation: (indexOffset: number) => void;
  onSave: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles({
  navButton: {
    width: 35,
    height: 35,
    minWidth: 0,
    padding: 0,
    borderRadius: 4,
    boxShadow: '0px 2px 4px #284E6633',
    backgroundImage: `linear-gradient(180deg, ${COLORS.white} 0%, ${COLORS.skyNormal} 100%)`,
    color: COLORS.inkLight,

    '& .MuiButton-label span': {
      display: 'flex',
      alignItems: 'center',
    },

    '&:active': {
      backgroundColor: `${COLORS.skyNormal} !important`,
    },

    '&.Mui-disabled > span.MuiButton-label > span > i': {
      // client/app/styles/typography.scss hard-codes the color of icon-chevron-down with !important
      color: `${COLORS.white} !important`,
    },
  },
});

export default function ProductDetailsDrawerToolbar({
  index,
  lastIndex,
  totalCount,
  offset,
  isUpdating,
  isDeleting,
  onNavigation,
  onSave,
  onCancel,
}: ProductDetailsDrawerToolbarProps): ReactElement<ProductDetailsDrawerToolbarProps> {
  const classes = useStyles();
  const { formState } = useFormContext<ProductFormData>();
  const userSession = useUserSession();
  const hasEditProductUserRight = !!userSession.user.userRights['products.edit'];

  return (
    <Box py={2} px={3} clone>
      <Grid container justifyContent="space-between">
        <Grid container item xs alignItems="center" spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              onClick={() => onNavigation(-1)}
              disabled={index === 0 || formState.isDirty}
              className={classes.navButton}
            >
              <i className="icon-chevron-up text-ink-100" />
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              <FormattedNumber value={offset + index + 1} /> -{' '}
              <FormattedNumber value={offset + lastIndex + 1} /> of{' '}
              <FormattedNumber value={totalCount} />
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              onClick={() => onNavigation(1)}
              disabled={index === lastIndex || formState.isDirty}
              className={classes.navButton}
            >
              <i className="icon-chevron-down" style={{ color: COLORS.inkLight }} />
            </Button>
          </Grid>
        </Grid>

        {formState.isDirty && (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="default"
                  onClick={onCancel}
                  style={{ width: 130 }}
                  disabled={isUpdating || isDeleting}
                >
                  <FormattedMessage id="forms.discard-edits" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                  style={{ width: 130 }}
                  loading={isUpdating}
                  disabled={isDeleting || !hasEditProductUserRight}
                >
                  <FormattedMessage id="global.save" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
