(function () {
  'use strict';

  PlatformService.$inject = ['$q', 'Platform'];
  function PlatformService($q, Platform) {
    var DIRECT_SALES = 'Direct Sales';
    var service = this;

    service.platforms = [];
    service.platformNames = [];
    service.platformGroups = [];

    // START WITH PLATFORMS THAT USE CSV & NO BACKEND
    service.platformGroups = [
      // Added by Frontend
      {
        group: 'Shopify',
        display: 'Shopify Plus',
        css_class: 'shopify-plus',
      },

      // Other channels
      {
        group: 'Direct Sales',
        display: 'Direct Sales',
        css_class: 'directsales',
      },
      {
        group: 'B2B',
        display: 'B2B',
        css_class: 'b2b',
      },

      // Various
      {
        group: '11th street',
        display: '11th street',
        css_class: '11thstreet',
      },
      {
        group: 'Cratejoy',
        display: 'Cratejoy',
        css_class: 'cratejoy',
      },
      {
        group: 'Fancy',
        display: 'Fancy',
        css_class: 'fancy',
      },
      {
        group: 'HKTDC',
        display: 'HKTDC',
        css_class: 'hktdc',
      },
      {
        group: 'JD',
        display: 'JD',
        css_class: 'jd',
      },
      {
        group: 'Jumper.ai',
        display: 'Jumper.ai',
        css_class: 'jumperai',
      },
      {
        group: 'Lazada',
        display: 'Lazada',
        css_class: 'lazada',
      },
      {
        group: 'Lelong',
        display: 'Lelong',
        css_class: 'lelong',
      },
      {
        group: 'M18',
        display: 'M18',
        css_class: 'm18',
      },
      {
        group: 'Newegg',
        display: 'Newegg',
        css_class: 'newegg',
      },
      {
        group: 'Qoo10',
        display: 'Qoo10',
        css_class: 'qoo10',
      },
      {
        group: 'Rakuten',
        display: 'Rakuten',
        css_class: 'rakuten',
      },
      {
        group: 'Shopcada',
        display: 'Shopcada',
        css_class: 'shopcada',
      },
      {
        group: 'Shopee',
        display: 'Shopee',
        css_class: 'shopee',
      },
      {
        group: 'Shopline',
        display: 'Shopline',
        css_class: 'shopline',
      },
      {
        group: 'Taobao',
        display: 'Taobao',
        css_class: 'taobao',
      },
      {
        group: 'Tmall',
        display: 'Tmall',
        css_class: 'tmall',
      },
      {
        group: 'Walmart',
        display: 'Walmart',
        css_class: 'walmart',
      },
      {
        group: 'Wish',
        display: 'Wish',
        css_class: 'wish',
      },
    ];

    service.platformNames = service.platformGroups.map(function (platformGroup) {
      return platformGroup.display;
    });

    service.getPlatformGroups = function () {
      return service.platformGroups;
    };

    service.getPlatformNames = function () {
      return service.platformNames;
    };

    service.getDefaultPlatform = function () {
      return DIRECT_SALES;
    };

    function sortPlatformGroups(a, b) {
      if (!a.display || !b.display) return 0;
      return a.display.toLowerCase().localeCompare(b.display.toLowerCase());
    }

    service.getAllPlatforms = function (type) {
      return $q(function (resolve, reject) {
        if (service.platforms.length === 0) {
          var platformResource;

          if (type === 'private') {
            platformResource = Platform.query().$promise;
          } else {
            platformResource = Platform.public().$promise;
          }

          platformResource
            .then(function (res) {
              service.platforms = res.platforms;
              service.platforms.forEach(function (platform) {
                if (service.platformNames.indexOf(platform.display_name) < 0) {
                  service.platformNames.push(platform.display_name);
                  service.platformGroups.push({
                    group: platform.group,
                    display: platform.display_name,
                    css_class: platform.css_class,
                  });
                }
              });

              service.platformGroups.sort(sortPlatformGroups);
              service.platformNames.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              });
              resolve(res);
            })
            .catch(function (err) {
              reject(err);
            });
        } else {
          resolve(service.platforms);
        }
      });
    };

    service.getAllActivePlatforms = function (type) {
      type = type || 'private';

      return $q(function (resolve, reject) {
        service
          .getAllPlatforms(type)
          .then(function () {
            var filteredPlatforms = filterPlatforms(service.platforms);

            if (type === 'public') {
              resolve({
                platforms: sortPlatforms(addManualPlatforms(filteredPlatforms)),
              });
            } else {
              var activePlatforms = getActivePlatforms(filteredPlatforms);
              var openApiPlatforms = getOpenApiPlatforms(filteredPlatforms);
              var sortedPlatforms = sortPlatforms(addManualPlatforms(activePlatforms));
              var publicPlatforms = sortPlatforms(getPublicPlatforms(filteredPlatforms));

              service.sortedPlatforms = sortedPlatforms;
              service.openAPIs = openApiPlatforms;

              resolve({
                platforms: sortedPlatforms,
                openAPIs: openApiPlatforms,
                publicPlatforms: publicPlatforms,
              });
            }
          })
          .catch(function (err) {
            reject(err);
          });
      });
    };

    service.getPlatformNameByCssClass = function (platformCssClass) {
      var match = _.find(service.sortedPlatforms, { css_class: platformCssClass }, {});
      return match && match.scope;
    };

    function addManualPlatforms(platformsArray) {
      platformsArray = addShopifyPlus(platformsArray);

      return platformsArray;
    }

    function addShopifyPlus(platformsArray) {
      var originalShopify = _.find(platformsArray, { id: 301 });

      var shopifyPlus = angular.copy(originalShopify);

      shopifyPlus.css_class = 'shopify-plus';
      shopifyPlus.display_name = 'Shopify Plus';
      shopifyPlus.name = 'Shopify Plus';
      shopifyPlus.scope = 'Shopify';

      platformsArray.push(shopifyPlus);

      return platformsArray;
    }

    function getPublicPlatforms(platforms) {
      return platforms.filter(function (platform) {
        return platform.visibility_in_website;
      });
    }

    function getActivePlatforms(platforms) {
      return platforms.filter(function (platform) {
        return platform.visibility_in_connect_page;
      });
    }

    function getOpenApiPlatforms(platforms) {
      return platforms.filter(function (platform) {
        // id 901 and 999 both mean other platform
        return platform.visibility_in_open_api_list && [901, 999].indexOf(platform.id) === -1;
      });
    }

    function filterPlatforms(platforms) {
      return _.uniqBy(platforms, 'display_name');
    }

    function sortPlatforms(platforms) {
      return _.sortBy(platforms, 'css_class');
    }
  }

  angular
    .module('app.service.PlatformService', ['app.factory.Platform'])
    .service('PlatformService', PlatformService);
})();
