import angular, { IComponentController } from 'angular';

import { IStoreService } from 'typings/store';
import { IItemCategoryService, IItemCategory } from 'typings/item-category';
import {
  IProductListingFilters,
  IProductListingFiltersStore,
} from 'typings/dashboard/services/product-listing';

import { ProductListingMissingFieldValue } from '@client/src/data/product-listing';
import { ICountry, ICountryService } from 'typings/auth/services/country';
import { IUserSession } from 'typings/user-session';
import style from './advanced-search.module.scss';
import template from './advanced-search.html?raw';
import { ProductListingService } from '../product-listing.service';

enum InputFields {
  MissingFields = 'missing_fields',
  FromDate = 'last_updated_from',
  ToDate = 'last_updated_to',
  Stores = 'store_ids',
  Category = 'category_ids',
  OriginCountry = 'origin_country_ids',
  MinWeight = 'min_weight',
  MaxWeight = 'max_weight',
}

enum StoresValue {
  None = 'none',
}

enum ButtonState {
  Search = 'search',
  Reset = 'reset',
}

interface IMissingFields {
  label?: string;
  translationKey: string;
  field: string;
  removeSelected?: boolean;
}

class AdvancedSearch implements IComponentController {
  style = style;
  today = new Date();
  inputFields = InputFields;
  buttonState = ButtonState;
  currentButtonState = ButtonState.Search;
  selectedMissingFields: string[] = [];
  selectedStores: string[] = [];
  selectedCategories: number[] = [];
  optionsMissingFields: IMissingFields[] = [
    {
      translationKey: 'global.any',
      field: ProductListingMissingFieldValue.All,
      removeSelected: true,
    },
    {
      translationKey: 'product-listing.table.name',
      field: ProductListingMissingFieldValue.ProductName,
    },
    {
      translationKey: 'global.category',
      field: ProductListingMissingFieldValue.Category,
    },
    {
      translationKey: 'global.dimensions',
      field: ProductListingMissingFieldValue.Dimensions,
    },
    {
      translationKey: 'global.weight',
      field: ProductListingMissingFieldValue.Weight,
    },
  ];
  optionsStores: IProductListingFiltersStore[] = [];
  optionsCategories: IItemCategory[] = [];
  optionsCountries: ICountry[] = [];
  close = () => {};

  static $inject = [
    '$translate',
    'UserSession',
    'CountryService',
    'StoreService',
    'ItemCategoryService',
    'ProductListingService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private CountryService: ICountryService,
    private StoreService: IStoreService,
    private ItemCategoryService: IItemCategoryService,
    private ProductListingService: ProductListingService
  ) {}

  $onInit(): void {
    const translationKeys = this.optionsMissingFields.map(({ translationKey }) => translationKey);
    this.$translate(translationKeys).then((translations: angular.translate.ITranslationTable) => {
      this.optionsMissingFields = this.optionsMissingFields.map((opt) => ({
        ...opt,
        label: translations[opt.translationKey] as string,
      }));
    });

    this.StoreService.getStores().then(({ stores }) => {
      const filterStore = stores.reduce(
        (accumulator: IProductListingFiltersStore[], { id, name = '', platform }) => {
          if (platform) {
            accumulator.push({
              id,
              name,
              css_class: platform.css_class,
            });
          }

          return accumulator;
        },
        []
      );

      this.optionsStores = [
        {
          id: StoresValue.None,
          name: this.$translate.instant('global.none'),
          removeSelected: true,
        },
        ...filterStore,
      ];
    });

    this.ItemCategoryService.getItemCategories().then((categories) => {
      this.optionsCategories = categories;
    });

    this.CountryService.getCountries().then(({ countries }) => {
      this.optionsCountries = countries;
    });
  }

  get companyWeightUnit(): string {
    return this.UserSession.getCompanyWeightUnit();
  }

  get currentFilters(): IProductListingFilters {
    return this.ProductListingService.currentFilters;
  }

  get newFilters(): IProductListingFilters {
    return this.ProductListingService.newFilters;
  }

  onMultiSelectChange(value: string[], key: string): void {
    switch (key) {
      case this.inputFields.MissingFields:
        if (value.includes(ProductListingMissingFieldValue.All)) {
          this.ProductListingService.newFilters[this.inputFields.MissingFields] = [
            ProductListingMissingFieldValue.All,
          ];
        } else {
          this.ProductListingService.newFilters[this.inputFields.MissingFields] = value;
        }
        break;
      case this.inputFields.Stores:
        if (value.includes(StoresValue.None)) {
          this.ProductListingService.newFilters[this.inputFields.Stores] = [StoresValue.None];
        } else {
          this.ProductListingService.newFilters[this.inputFields.Stores] = value;
        }
        break;
      case this.inputFields.Category:
        this.ProductListingService.newFilters[this.inputFields.Category] = value.map((text) =>
          parseInt(text, 10)
        );
        break;
      case this.inputFields.OriginCountry:
        this.ProductListingService.newFilters[this.inputFields.OriginCountry] = value.map((text) =>
          parseInt(text, 10)
        );
        break;
    }

    this.currentButtonState = ButtonState.Search;
  }

  onInputChange(value: string, key: string): void {
    switch (key) {
      case this.inputFields.MinWeight:
        if (value) {
          this.ProductListingService.newFilters[this.inputFields.MinWeight] = Number(value);
        } else {
          delete this.ProductListingService.newFilters[this.inputFields.MinWeight];
        }
        break;
      case this.inputFields.MaxWeight:
        if (value) {
          this.ProductListingService.newFilters[this.inputFields.MaxWeight] = Number(value);
        } else {
          delete this.ProductListingService.newFilters[this.inputFields.MaxWeight];
        }
        break;
      case this.inputFields.FromDate:
        this.ProductListingService.newFilters[this.inputFields.FromDate] = value;
        break;
      case this.inputFields.ToDate:
        this.ProductListingService.newFilters[this.inputFields.ToDate] = value;
        break;
    }

    this.currentButtonState = ButtonState.Search;
  }

  search(): void {
    this.currentButtonState = ButtonState.Reset;
    // Create a new copy of the filters to help es-search-bar determine the active state of the button
    this.ProductListingService.currentFilters = { ...this.newFilters };
    this.ProductListingService.isFiltered = true;
    this.ProductListingService.getProductList();
    this.ProductListingService.resetCheckState();
    this.close();
  }

  reset(): void {
    this.currentButtonState = ButtonState.Search;
    this.ProductListingService.resetSearch();
    this.ProductListingService.getProductList().then(() => {
      this.ProductListingService.isFiltered = false;
    });
    this.ProductListingService.resetCheckState();
    this.close();
  }
}

const AdvancedSearchComponent = {
  template,
  controller: AdvancedSearch,
  bindings: {
    open: '<',
    section: '<',
    onSearch: '&',
    onReset: '&',
    close: '&',
  },
};

export { AdvancedSearchComponent };
