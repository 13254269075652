(function () {
  'use strict';

  Session.$inject = ['$resource', 'API'];
  function Session($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/sessions/:id',
      {
        id: '@_id',
      },
      {
        create: {
          method: 'POST',
          params: {},
        },
        delete: {
          method: 'DELETE',
          params: {},
        },
      }
    );
  }

  angular.module('app.factory.Session', []).factory('Session', Session);
})();
