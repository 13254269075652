import { IInputGroupRadioModel, IInputGroupValue } from 'typings/form';

import { OrderStatusRadioModelValue } from '@client/src/global/services/shipment-list/data/radio-group';
import { IComponentController } from 'angular';
import template from './order-status-radio-group.html?raw';
import style from './order-status-radio-group.module.scss';

class OrderStatusRadioGroup implements IComponentController {
  style = style;
  esStatus: OrderStatusRadioModelValue | null = null;
  radioModel: IInputGroupRadioModel<OrderStatusRadioModelValue>[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    const $t = (key: string) => {
      return this.$translate.instant(`shipments.filter.order-status.radio-options.${key}`);
    };

    this.radioModel = [
      {
        title: $t('all.title'),
        value: OrderStatusRadioModelValue.AllShipments,
      },
      {
        title: $t('ready-to-shipped.title'),
        value: OrderStatusRadioModelValue.ReadyToShipped,
        content: $t('ready-to-shipped.description'),
      },
      {
        title: $t('not-ready-to-shipped.title'),
        value: OrderStatusRadioModelValue.NotReadyToShipped,
        content: $t('not-ready-to-shipped.description'),
      },
    ];

    if (!this.esStatus) {
      this.esStatus = OrderStatusRadioModelValue.AllShipments;
      this.onValuesChange();
    }
  }

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  onRadioChange(value: IInputGroupValue<OrderStatusRadioModelValue>): void {
    this.esStatus = value.radio;
    this.onValuesChange();
  }

  private onValuesChange(): void {
    this.esOnChange({
      value: this.esStatus,
    });
  }
}

const OrderStatusRadioGroupComponent: ng.IComponentOptions = {
  controller: OrderStatusRadioGroup,
  template,
  bindings: {
    esStatus: '<',
    esOnChange: '&',
  },
};

export { OrderStatusRadioGroupComponent };
