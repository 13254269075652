import angular from 'angular';
import ngResource from 'angular-resource';

import { PaymentSourceService } from './payment-source.service';

angular
  .module('app.global.payment-source', [
    ngResource,
    'core.config',
    'app.factory.UserSession',
    'app.global.payment-intent',
    'app.global.transaction-record',
  ])
  .service('PaymentSourceService', PaymentSourceService);
