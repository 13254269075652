import React from 'react';

export class CallbackListener {
  subscribers: Function[] = [];

  trigger(...args: any[]) {
    this.subscribers.forEach((callback) => callback(...args));
  }

  register(callback: Function) {
    this.subscribers.push(callback);
  }

  unregister(callback: Function) {
    const subscriberIndexFound = this.subscribers.findIndex(
      (subCallback) => subCallback === callback
    );
    if (subscriberIndexFound >= 0) {
      this.subscribers.splice(subscriberIndexFound, 1);
    }
  }
}

export const listeners: Record<string, CallbackListener> = {
  featureUnavailable: new CallbackListener(),
  routeToCouriers: new CallbackListener(),
  storeCreated: new CallbackListener(),
};

export default React.createContext<Record<string, CallbackListener>>(listeners);
