import { ICourierAccountsService, IActiveCourierAccounts } from 'typings/courier';
import { IHelperService } from 'typings/helper';
import { ISubscriptionObject, ISubscriptionFeature } from 'typings/subscription';
import { IUser, IUserSession } from 'typings/user-session';

import { FEATURE_KEY } from '@client/data/subscription';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { toastError } from '@client/core/components/react/Toastify';
import template from './info-card.html?raw';
import style from './info-card.module.scss';

class SubscriptionInfoCard implements IComponentController {
  style = style;
  translations: angular.translate.ITranslationTable = {};
  _couriersAccountsObject: IActiveCourierAccounts | null = null;
  showUpgradeBillingModal = false;

  static $inject = [
    '$state',
    '$translate',
    '$filter',
    'HelperService',
    'UserSession',
    'SubscriptionService',
    'CourierAccounts',
    '$scope',
    'MixpanelService',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $translate: angular.translate.ITranslateService,
    private $filter: ng.IFilterFunction,
    private HelperService: IHelperService,
    private UserSession: IUserSession,
    private SubscriptionService: SubscriptionService,
    private CourierAccounts: ICourierAccountsService,
    private $scope: ng.IScope,
    private MixpanelService: MixpanelService
  ) {}

  $onInit(): void {
    // Get text from translation table
    this.$translate(['subscription', 'global']).then(
      (translations: angular.translate.ITranslationTable) => {
        this.translations = translations;
      }
    );

    this.toggleShowUpgradeBillingModal();
    this.$scope.$on('routeUpdate', this.toggleShowUpgradeBillingModal);

    this._fetchCouriersAccount();

    const companyId = this.UserSession.getCompanyId();
    if (!this.SubscriptionService.currentSubscription && companyId) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: companyId,
      });
    }
  }

  async _fetchCouriersAccount(): Promise<void> {
    try {
      this._couriersAccountsObject = await this.CourierAccounts.getActiveCourierAccounts();
      this.$scope.$apply();
    } catch (error) {
      if (error instanceof Error) {
        toastError(error.message);
      }
      throw error;
    }
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get remainingDaysBeforeTrialEnd() {
    return this.SubscriptionService.remainingDaysBeforeTrialEnd;
  }

  get user(): IUser {
    return this.UserSession.user;
  }

  get avatarLetter(): string {
    if (this.UserSession && this.UserSession.user) {
      return this.HelperService.getFirstLetter(this.UserSession.user.first_name);
    }

    return '';
  }

  get companyName(): string {
    if (!this.UserSession.company) {
      return '';
    }
    return this.UserSession.company.name;
  }

  get progressPercentage(): number {
    if (this.currentSubscription?.all_features) {
      const { shipments_per_month: shipmentsPerMonth } = this.currentSubscription.all_features;
      if (shipmentsPerMonth?.current_count && shipmentsPerMonth.limit) {
        return this.HelperService.calculatePercentage(
          shipmentsPerMonth.current_count,
          shipmentsPerMonth.limit
        );
      }
    }

    return 0;
  }

  get currentPlanFormat(): string {
    if (this.currentSubscription?.plan) {
      const { amount, interval } = this.currentSubscription.plan;
      const name = this.SubscriptionService.currentPlanName;
      if (amount === 0) {
        return name;
      }

      const discounted = this.SubscriptionService.isCurrentLegacyPlan(
        this.currentSubscription,
        false
      )
        ? amount * this.SubscriptionService.legacyDiscountRate
        : amount;
      const formattedCost = this.$filter('intlCurrency')(
        discounted,
        this.UserSession.getCompanyCurrency()
      );

      return `${name} - ${formattedCost}/${interval}`;
    }

    return '-';
  }

  get isShowMemberTooltip(): boolean {
    return this.isShowTooltip(FEATURE_KEY.TeamMembers);
  }

  get isShowCouriersTooltip(): boolean {
    return this.isShowTooltip(FEATURE_KEY.Lyoc);
  }

  teamMemberLinkAction(): void {
    this.MixpanelService.track('Account - Subscription - Users');
  }

  couriersLinkAction(): void {
    this.MixpanelService.track('Account - Subscription - Courier Accounts');
  }

  isShowTooltip(featureName: string): boolean {
    if (this.currentSubscription && this.currentSubscription.all_features) {
      let feature: ISubscriptionFeature | undefined;
      switch (featureName) {
        case FEATURE_KEY.TeamMembers:
          feature = this.currentSubscription.all_features.team_members;
          break;
        case FEATURE_KEY.Lyoc:
          feature = this.currentSubscription.all_features.lyoc;
          break;
        default:
          break;
      }

      if (feature) {
        const { current_count: currentCount, limit } = feature;
        if (currentCount && limit) {
          return currentCount >= limit;
        }
      }
    }

    return false;
  }

  get teamMembers(): string {
    if (this.currentSubscription && this.currentSubscription.all_features) {
      const { current_count: currentCount, limit } =
        this.currentSubscription.all_features.team_members ?? {};
      const noun = this.$translate.instant(
        'global.pluralize.member',
        { COUNT: currentCount },
        'messageformat'
      );
      return limit && limit >= 0 ? `${currentCount}/${limit} ${noun}` : `${currentCount} ${noun}`;
    }

    return '-';
  }

  get couriers(): string {
    if (this.currentSubscription && this.currentSubscription.all_features) {
      const { current_count: currentCount, limit } =
        this.currentSubscription.all_features.lyoc ?? {};
      const noun = this.$translate.instant(
        'global.pluralize.account',
        { COUNT: currentCount },
        'messageformat'
      );
      return limit && limit >= 0 ? `${currentCount}/${limit} ${noun}` : `${currentCount} ${noun}`;
    }

    return '-';
  }

  get couriersCount(): number | string {
    if (this._couriersAccountsObject) {
      return this.SubscriptionService.returnEasyShipCouriersCount(
        this._couriersAccountsObject.easyship_courier_accounts
      );
    }

    return 0;
  }

  get isFreeTrialLeft(): boolean {
    return this.SubscriptionService.currentSubscriptionPeriod === 'TRIAL';
  }

  get isFreePlan(): boolean {
    return this.SubscriptionService.isFreePlan;
  }

  get isUnlimitedShipment(): boolean {
    if (this.currentSubscription?.all_features?.shipments_per_month?.limit === -1) {
      return true;
    }

    return false;
  }

  get canConnectCourier(): boolean {
    return this.UserSession.hasUserRole('account_and_settings');
  }

  mediumDateFormat(date: string): string {
    const locale = this.UserSession.user.dashboard_settings?.language?.code || 'en';
    if (date && date !== '') {
      return this.HelperService.mediumDateFormat(date, locale);
    }

    return '-';
  }

  toggleShowUpgradeBillingModal(): void {
    this.showUpgradeBillingModal =
      this.$state.params.open && this.$state.params.open.toLowerCase() === 'billing';
  }

  showUpdateBillingInfo(): void {
    this.$state.go(this.$state.current, { open: 'billing' });
    this.MixpanelService.track('Account - Subscription - Update Billing');
  }

  closeUpgradeBillingModal(): void {
    this.$state.go(this.$state.current, { open: null });
    this.MixpanelService.track('Subscription - Update Billing - Close');
  }
}

const SubscriptionInfoCardComponent: ng.IComponentOptions = {
  controller: SubscriptionInfoCard,
  template,
};

export { SubscriptionInfoCardComponent };
