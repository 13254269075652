import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { ColumnVisibilityMap } from '@typings/dashboard/shipment_table/settings';
import { useShipmentsTableSettingsGateway } from '../../providers/ShipmentsTableSettingsGatewayProvider';

export default function useShipmentsTableSettingsMutation(context: string) {
  const { company } = useUserSession();
  const { shipmentsTableSettingsGateway } = useShipmentsTableSettingsGateway();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (settings: ColumnVisibilityMap) =>
      shipmentsTableSettingsGateway.updateSettings(company.id, settings, context),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['new_shipments_table_settings'] }),
  });
}
