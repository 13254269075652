import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import ProgressIndicator from '@client/core/components/react/ProgressIndicator';
import Tooltip from '@client/core/components/react/Tooltip';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface ReturnLabelMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onCreateReturnLabel: () => Promise<void>;
}

export default function ReturnLabelMenuItem({
  divider = false,
  shipmentListItem,
  onCreateReturnLabel,
}: ReturnLabelMenuItemProps) {
  const [isReturnLabelLoading, setIsReturnLabelLoading] = useState(false);
  const { returnable: isReturnable } = shipmentListItem;

  async function handleReturnLabel() {
    setIsReturnLabelLoading(true);
    await onCreateReturnLabel();
    setIsReturnLabelLoading(false);
  }

  return (
    <ProgressIndicator show={isReturnLabelLoading}>
      <Tooltip
        placement="left"
        interactive
        active={!isReturnable}
        title={
          <p className="text-base m-0">
            <FormattedMessage id="shipments.menu-action-tooltip.returnable-disabled" />
            <br />
            <a
              href="https://support.easyship.com/hc/en-us/articles/360020926852-How-to-Create-Prepaid-Return-Labels"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="global.learn-more" />
            </a>
          </p>
        }
      >
        <div>
          <MenuItem onClick={handleReturnLabel} disabled={!isReturnable} divider={divider}>
            <div style={baseStyleIconBox}>
              <i className="icon-return text-xl" style={{ color: baseStyleIcon.colorGrey }} />
            </div>
            <span className="text-base">
              <FormattedMessage id="shipments.speed-dial.create-return-label" />
            </span>
          </MenuItem>
        </div>
      </Tooltip>
    </ProgressIndicator>
  );
}
