import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { ShipmentGatewayProvider } from '@client/src/manage-shipments/providers/ShipmentGatewayProvider';
import { LearnRequestFeatureProps } from '@/components/LearnRequestFeature/types';

const Component = lazy(() => import('@/components/LearnRequestFeature'));

function WrappedComponent(props: LearnRequestFeatureProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentGatewayProvider>
          <Suspense fallback={null}>
            <Component {...props} />
          </Suspense>
        </ShipmentGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularLearnRequestFeature = react2angular(WrappedComponent, [
  'requestFeaturePage',
  'learnMoreLink',
  'learnMoreLinkText',
]);
