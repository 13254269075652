import angular from 'angular';

import { PaymentMethodIconComponent } from './payment-method-icon.component';

const MODULE_NAME = 'app.global.dashboard-component-library.payment-method-icon';

angular
  .module(MODULE_NAME, ['core.filters'])
  .component('esPaymentMethodIcon', PaymentMethodIconComponent);

export default MODULE_NAME;
