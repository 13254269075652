PaymentIntentResource.$inject = ['$resource', 'API'];
function PaymentIntentResource($resource, API) {
  const endpointUrl = API.endpoint;

  return $resource(
    `${endpointUrl}/companies/:company_id/payment_intents/:id/:controller`,
    {
      company_id: '@_company_id',
      id: '@_id',
    },
    {
      confirm: {
        method: 'POST',
        params: { controller: 'confirm' },
      },
    }
  );
}

export { PaymentIntentResource };
