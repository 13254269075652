import { IComponentController } from 'angular';
import template from './add-payment-card.html?raw';
import style from './add-payment-card.module.scss';

class AddPaymentCard implements IComponentController {
  style = style;
}

const AddPaymentCardComponent: ng.IComponentOptions = {
  controller: AddPaymentCard,
  template,
  bindings: {
    esOnClick: '&',
  },
};

export { AddPaymentCardComponent };
