import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';

import { IComponentController } from 'angular';
import template from './product-sku-input-group.html?raw';
import style from './product-sku-input-group.module.scss';

interface ISkuModel {
  select: string;
  tags: string[];
  remainder?: string;
}

class ProductSkuInputGroup implements IComponentController {
  esModelSelect: MultiselectComponentModelValue | null = null;
  esModelTags: string[] = [];
  esOnChange: (changes?: { value: ISkuModel }) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
  style = style;
  options: string[] = [MultiselectComponentModelValue.AnyOf, MultiselectComponentModelValue.NoneOf];
  skuModel: ISkuModel = {
    select: MultiselectComponentModelValue.AnyOf,
    tags: [],
    remainder: '',
  };

  $onInit(): void {
    this.skuModel.select = this.esModelSelect || MultiselectComponentModelValue.AnyOf;
    this.skuModel.tags = this.esModelTags || [];
    this.handleChange();
  }

  handleChange(): void {
    const value = {
      select: this.skuModel.select,
      tags: this.skuModel.remainder
        ? [...this.skuModel.tags, this.skuModel.remainder]
        : this.skuModel.tags,
    };
    this.esOnChange && this.esOnChange({ value });
  }
}

const ProductSkuInputGroupComponent: ng.IComponentOptions = {
  controller: ProductSkuInputGroup,
  template,
  bindings: {
    esModelSelect: '<',
    esModelTags: '<',
    esOnChange: '&',
  },
};

export { ProductSkuInputGroupComponent };
