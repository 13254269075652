import angular from 'angular';

import { ReportIssueModalComponent } from './report-issue-modal.component';

const MODULE_NAME = 'app.pickups.report-issue-modal';

angular
  .module(MODULE_NAME, ['core.service.mixpanel', 'app.pickups'])
  .component('esReportIssueModal', ReportIssueModalComponent);

export default MODULE_NAME;
