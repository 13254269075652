import React, { useMemo } from 'react';
import { Button, Checkbox, Chip, Modal } from 'easyship-components';
import { useIntl } from 'react-intl';
import LockedSvg from '@assets/images/locked.svg?svgo';
import { ShipmentsTableColumnKey } from 'typings/user-session';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import useShipmentsTableSettingsMutation from '@client/src/manage-shipments/hooks/mutations/useShipmentTableSettingsUpdate';
import HotjarService, { TriggerSurveyEvent } from '@client/core/services/hotjar/hotjar.service';
import { FEATURE_KEY, FeatureKey } from '@client/data/subscription';
import { getMapOfAllColumns, SHIPMENTS_TABLE_ALL_COLUMNS, ShipmentsTableColumn } from './utils';
import { useVisibleColumns } from './useVisibleColumns';

interface ColumnSettingsModalProps {
  open: boolean;
  onClose: () => void;
  isTaxCollectEnabled: boolean;
  openUpgradeModal: (featureKey: FeatureKey) => void;
  planName: string | null;
  isFeatureAccessible: boolean;
}

export function ColumnSettingsModal({
  open,
  onClose,
  isTaxCollectEnabled,
  openUpgradeModal,
  planName,
  isFeatureAccessible,
}: ColumnSettingsModalProps) {
  const { visibleColumns, visibleColumnsMap, setVisibleColumnsMap } =
    useVisibleColumns(isTaxCollectEnabled);
  const { CustomizeShipmentTable } = FEATURE_KEY;

  const columns = useMemo(
    () =>
      SHIPMENTS_TABLE_ALL_COLUMNS.filter(
        (column) => column !== ShipmentsTableColumn.COLLECT_STATUS || isTaxCollectEnabled
      ),
    [isTaxCollectEnabled]
  );

  const { formatMessage } = useIntl();
  const translate = (id: string) => formatMessage({ id });

  const { mutateAsync: updateSettings, isLoading: isSaving } =
    useShipmentsTableSettingsMutation('Create Shipments');

  const handleColumnVisibilityChange = (key: ShipmentsTableColumnKey) => {
    if (!isFeatureAccessible) {
      openUpgradeModal(CustomizeShipmentTable);
      return;
    }
    setVisibleColumnsMap({
      ...visibleColumnsMap,
      [key]: !visibleColumnsMap[key],
    });
  };

  const handleSave = async () => {
    if (!isFeatureAccessible) {
      openUpgradeModal(CustomizeShipmentTable);
      return;
    }
    try {
      await updateSettings({
        ...getMapOfAllColumns(),
        ...visibleColumnsMap,
      });

      toastSuccess(translate('shipments.table.columns-update-success'));
      HotjarService.triggerSurveyEvent(TriggerSurveyEvent.ShipmentsTableAfterCustomized);
      onClose();
    } catch (e) {
      toastError(translate('shipments.table.columns-update-error'));
      setVisibleColumnsMap(visibleColumns); // reset the map
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header
        closeButtonAriaLabel="Close"
        title={
          <div className="flex items-center gap-3">
            <span>{translate('shipments.table.column-settings.title')}</span>
            {!!planName && (
              <Chip color="teal" size="normal" rounded className="bg-teal-500">
                {planName}
              </Chip>
            )}
          </div>
        }
        className="items-center"
      />
      <Modal.Content>
        <h2 className="text-lg font-bold mb-1">
          {translate('shipments.table.column-settings.heading')}
        </h2>
        <p className="text-base">{translate('shipments.table.column-settings.sub-heading')}</p>
        <ul>
          {columns.map((key) => (
            <li key={key}>
              {key === ShipmentsTableColumn.ORDER ? (
                <div className="flex items-center">
                  <div
                    className="pt-[6px] px-[6px]"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: LockedSvg }}
                  />
                  <div className="mt-5">
                    <strong>{translate(`shipments.table.columns.${key}.name`)}</strong>
                    <p className="text-ink-500 m-0">
                      {translate(`shipments.table.columns.${key}.description`)}
                    </p>
                  </div>
                </div>
              ) : (
                <Checkbox
                  className="!m-0 !outline-none cursor-pointer"
                  label={
                    <div className="mt-5">
                      <strong className="text-ink-900">
                        {translate(`shipments.table.columns.${key}.name`)}
                      </strong>
                      <p className="text-ink-500 m-0">
                        {translate(`shipments.table.columns.${key}.description`)}
                      </p>
                    </div>
                  }
                  checked={Boolean(visibleColumnsMap[key])}
                  onChange={() => handleColumnVisibilityChange(key)}
                />
              )}
            </li>
          ))}
        </ul>
      </Modal.Content>
      <Modal.Footer>
        <Button className="w-[175px]" color="primary" onClick={handleSave} loading={isSaving}>
          {translate('shipments.table.column-settings.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
