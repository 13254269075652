import angular from 'angular';
import { RedirectionComponent } from './redirection.component';

const MODULE_NAME = 'app.redirection';

angular
  .module(MODULE_NAME, [
    'ui.router',
    'app.service.ShipmentAction',
    'app.service.ChanneladvisorService',
    'core.service.mixpanel',
  ])
  .component('redirection', RedirectionComponent);

export default MODULE_NAME;
