import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { API } from '@client/core/config/api.constant';
import COLORS from '@client/src/colors';
import { PersonaOptions } from './types';
import { useOnboarding } from '../../hooks/OnboardingProvider';

interface PersonaButtonProps {
  value: PersonaOptions;
}

const useStyles = makeStyles((theme) => ({
  personaBottom: {
    width: 280,
    margin: 8,
    backgroundColor: '#FFF',
    border: `1px solid ${COLORS.skyDark}`,
    borderRadius: 16,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0!important',
      margin: '0 0 12px 0',
    },
    '&:hover': {
      boxShadow: '0px 8px 20px #284E661A',
    },
  },
  personaName: {
    marginBottom: 20,
    color: COLORS.inkDarkest,
    fontSize: 20,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
      fontSize: 16,
    },
  },
}));

function PersonaButton({ value }: PersonaButtonProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { onPersonaSelect } = useOnboarding();

  return (
    <Grid
      item
      container
      direction={isScreenSm ? 'row' : 'column'}
      alignItems="center"
      spacing={isScreenSm ? 3 : 4}
      className={classes.personaBottom}
      component="button"
      onClick={() => onPersonaSelect(value)}
    >
      <Grid item>
        <img
          src={`${API.easyship_storage}/onboarding/persona-${value.toLowerCase()}.svg`}
          alt={value}
          style={isScreenSm ? { width: 80, height: 80 } : { width: 160, height: 160 }}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          align={isScreenSm ? 'left' : 'center'}
          className={classes.personaName}
        >
          <FormattedMessage id={`onboarding.personas.${value}.label`} />
        </Typography>
        <Box>
          <Typography variant="body2" align={isScreenSm ? 'left' : 'center'}>
            <FormattedMessage id={`onboarding.personas.${value}.description`} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default PersonaButton;
