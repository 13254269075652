/* eslint-disable max-classes-per-file */
import template from './webhook-modal.html?raw';

class WebhookModalCtrl {
  static $inject = ['step', 'webhook', 'WebhookModal', 'MixpanelService'];

  constructor(step, webhook, WebhookModal, MixpanelService) {
    this.MixpanelService = MixpanelService;
    this.step = step;
    this.webhook = webhook;
    this.WebhookModal = WebhookModal;
  }

  close() {
    this.MixpanelService.track('Webhooks - Modal - Close');
    this.WebhookModal.close();
  }
}

class WebhookModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(step, webhook) {
    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: WebhookModalCtrl,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        step: () => step,
        webhook: () => webhook,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { WebhookModal };
