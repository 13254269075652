import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import COLORS from '@client/src/colors';
import sunsetImageUrl from '@assets/images/dashboard/churn-reasons/sunset.svg';

export const useStyles = makeStyles({
  sunset: {
    width: '100%',
    height: 215,
  },
  list: {
    paddingLeft: 17,
    marginBottom: 0,
    color: COLORS.inkNormal,

    '& li': {
      lineHeight: '20px',
      marginBottom: 10,
    },

    '& li:last-child': {
      marginBottom: 0,
    },
  },
});

interface PrefaceProps {
  plan: string | undefined;
}

export default function Preface({ plan }: PrefaceProps) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <img className={classes.sunset} src={sunsetImageUrl} alt="" />
      <Typography variant="h4" style={{ marginBottom: '20px', color: COLORS.inkDarkest }}>
        <FormattedMessage id="churn-reasons-flow.close-account-preface.sub-header" />
      </Typography>
      <ul className={classes.list}>
        <Typography variant="body2" component="li">
          <FormattedMessage id="churn-reasons-flow.close-account-preface.closure-permanent" />
        </Typography>
        {plan !== 'free' && (
          <Typography variant="body2" component="li">
            <FormattedMessage
              id="churn-reasons-flow.close-account-preface.subscription-cancelled"
              values={{
                plan: intl.formatMessage({
                  id: `freemium-pricing.category.${plan}`,
                }),
              }}
            />
          </Typography>
        )}
        <Typography variant="body2" component="li">
          <FormattedMessage id="churn-reasons-flow.close-account-preface.potential-review" />
        </Typography>
        <Typography variant="body2" component="li">
          <FormattedMessage id="churn-reasons-flow.close-account-preface.data-anonymized" />
        </Typography>
      </ul>
    </>
  );
}
