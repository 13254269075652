import { react2angular } from 'react2angular';

import TaxAndDutyExplainerDialog, {
  WrappedTaxAndDutyExplainerDialog,
} from './TaxAndDutyExplainerDialog';

export default TaxAndDutyExplainerDialog;
export const AngularTaxAndDutyExplainerDialog = react2angular(WrappedTaxAndDutyExplainerDialog, [
  'open',
  'onClose',
  'onLinkClick',
]);
