import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';

import { IComponentController } from 'angular';
import template from './order-number-input-group.html?raw';
import style from './order-number-input-group.module.scss';

interface RelationOption {
  value: MultiselectComponentModelValue;
  label: string;
}

interface IOrderNumberModel {
  select: MultiselectComponentModelValue;
  tags: string[];
}

class OrderNumberInputGroup implements IComponentController {
  style = style;
  esModelSelect: MultiselectComponentModelValue = MultiselectComponentModelValue.AnyOf;
  esModelTags: string[] = [];
  esOnChange: (changes?: { value: IOrderNumberModel }) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
  options: RelationOption[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    this.options = [
      MultiselectComponentModelValue.AnyOf,
      MultiselectComponentModelValue.NoneOf,
    ].map((option) => ({
      value: option,
      label: this.$translate.instant(`global.relation.${option}`),
    }));
    this.esModelSelect = this.esModelSelect || MultiselectComponentModelValue.AnyOf;
    this.esModelTags = this.esModelTags || [];
  }

  onSelectChange(value: MultiselectComponentModelValue): void {
    this.esModelSelect = value;

    this.esOnChange({ value: { select: value, tags: this.esModelTags } });
  }

  onTagsAreaChange(value: string[], remainder: string): void {
    this.esModelTags = value;

    this.esOnChange({
      value: {
        select: this.esModelSelect,
        tags: remainder ? [...value, remainder] : value,
      },
    });
  }
}

const OrderNumberInputGroupComponent: ng.IComponentOptions = {
  controller: OrderNumberInputGroup,
  template,
  bindings: {
    esModelSelect: '<',
    esModelTags: '<',
    esOnChange: '&',
  },
};

export { OrderNumberInputGroupComponent };
