import angular from 'angular';

import { AppLogoComponent } from './app-logo/app-logo.component';
import onboardingChecklist from './onboarding-checklist';
import { SidebarProfileComponent } from './sidebar-profile/sidebar-profile.component';

const MODULE_NAME = 'app.global.components.dashboard-sidebar';

angular
  .module(MODULE_NAME, [onboardingChecklist])
  .component('esSidebarAppLogo', AppLogoComponent)
  .component('esSidebarProfile', SidebarProfileComponent);

export default MODULE_NAME;
