import React, { createContext, useContext, useMemo } from 'react';
import { UpsOauthPort } from '../ports/UpsOauthPort';
import { UpsOauthGateway } from '../gateways/api/UpsOauthGateway';
import { FakeUpsOauthGateway } from '../gateways/fake/FakeUpsOauthGateway';

export interface UpsOauthGatewayContextData {
  upsOauthGateway: UpsOauthPort;
}

const UpsOauthGatewayContext = createContext<UpsOauthGatewayContextData | undefined>(undefined);

interface UpsOauthGatewayProviderProps {
  children: React.ReactNode;
}

function UpsOauthGatewayProvider({ children }: UpsOauthGatewayProviderProps) {
  const data: UpsOauthGatewayContextData = useMemo(
    () => ({
      upsOauthGateway: new UpsOauthGateway(),
    }),
    []
  );

  return <UpsOauthGatewayContext.Provider value={data}>{children}</UpsOauthGatewayContext.Provider>;
}

interface FakeUpsOauthGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<UpsOauthGatewayContextData>;
}

function FakeUpsOauthGatewayProvider({ children, value }: FakeUpsOauthGatewayProviderProps) {
  const data: UpsOauthGatewayContextData = useMemo(
    () => ({
      upsOauthGateway: new FakeUpsOauthGateway(),
      ...value,
    }),
    [value]
  );

  return <UpsOauthGatewayContext.Provider value={data}>{children}</UpsOauthGatewayContext.Provider>;
}

function useUpsOauthGateway() {
  const upsOauthGatewayContext = useContext(UpsOauthGatewayContext);

  if (upsOauthGatewayContext === undefined) {
    throw new Error('useUpsOauthGateway must be used within a UpsOauthGatewayProvider');
  }
  return upsOauthGatewayContext;
}

export { UpsOauthGatewayProvider, FakeUpsOauthGatewayProvider, useUpsOauthGateway };
