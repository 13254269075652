interface IModalControl {
  openModal: () => void;
  closeModal: () => void;
}

enum ModalScope {
  Size = 'lg',
  OpenedClass = 'easyship-modal-no-padding',
}

ModalInstanceDirective.$inject = ['$uibModal', '$timeout'];
function ModalInstanceDirective(
  $uibModal: ng.ui.bootstrap.IModalService,
  $timeout: ng.ITimeoutService
): ng.IDirective {
  return {
    restrict: 'AE',
    scope: {
      esControl: '=',
      esSize: '@',
      esKeyboard: '<',
      esOpenedClass: '<',
      esAnimation: '<',
      esOnCloseModalSuccess: '&',
    },
    transclude: true,
    link: (
      scope: any,
      element: ng.IAugmentedJQuery,
      attrs: ng.IAttributes,
      ctrl: any,
      transclude: any
    ): void => {
      const modalScope = scope;

      if (scope.esControl) {
        const $body = $('body');
        const elementId = `es-modal-instance-${Date.now() + Math.random()}`.replace('.', '');

        modalScope.esControl.openModal = () => {
          modalScope.instance = $uibModal.open({
            animation: scope.esAnimation || true,
            scope,
            template: `<div id="${elementId}"></div>`,
            size: scope.esSize || ModalScope.Size,
            keyboard: scope.esKeyboard || false,
            openedClass: scope.esOpenedClass || ModalScope.OpenedClass,
            appendTo: $body,
          });

          if (scope.esOnCloseModalSuccess && modalScope.instance) {
            scope.esOnCloseModalSuccess();

            // TODO: Testing if injecting to body will fix some of the z-index and overflow issue, if yes, we can clean this up
            // modalScope.instance.closed.then(() => {
            //   document.getElementsByTagName('body')[0].style.overflow = '';
            //   if (scope.esOnCloseModalSuccess) {
            //     scope.esOnCloseModalSuccess();
            //   }
            // });

            // modalScope.instance.opened.then(() => {
            //   document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            // });

            // modalScope.instance.result.catch(() => {
            //   document.getElementsByTagName('body')[0].style.overflow = '';
            // });
          }

          $timeout(() => {
            transclude(scope.$parent, (clonedContent: any) => {
              $body.find(`#${elementId}`).append(clonedContent);
            });
          });
        };

        modalScope.esControl.closeModal = () => {
          if (scope.instance) {
            scope.instance.close();
          }
        };
      }
    },
  };
}

export { ModalInstanceDirective, IModalControl };
