import React, { createContext, useContext, useMemo } from 'react';
import { Platform } from '@client/data/platform';
import { ConnectStorePort } from '../ports/connect-store';
import NgConnectStoreGateway from '../gateways/api/NgConnectStoreGateway';
import FakeConnectStoreGateway from '../gateways/fake/FakeConnectStoreGateway';

export interface ConnectStoreGatewayContextData {
  connectStoreGateway: ConnectStorePort;
}

const ConnectStoreGatewayContext = createContext<ConnectStoreGatewayContextData | undefined>(
  undefined
);

interface ConnectStoreGatewayProviderProps {
  children: React.ReactNode;
  platformName: Platform;
}

function ConnectStoreGatewayProvider({ children, platformName }: ConnectStoreGatewayProviderProps) {
  const data: ConnectStoreGatewayContextData = useMemo(
    () => ({
      connectStoreGateway: new NgConnectStoreGateway(platformName),
    }),
    [platformName]
  );

  return (
    <ConnectStoreGatewayContext.Provider value={data}>
      {children}
    </ConnectStoreGatewayContext.Provider>
  );
}

interface FakeConnectStoreGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<ConnectStoreGatewayContextData>;
}

function FakeConnectStoreGatewayProvider({
  children,
  value,
}: FakeConnectStoreGatewayProviderProps) {
  const data: ConnectStoreGatewayContextData = useMemo(
    () => ({
      connectStoreGateway: new FakeConnectStoreGateway(),
      ...value,
    }),
    [value]
  );

  return (
    <ConnectStoreGatewayContext.Provider value={data}>
      {children}
    </ConnectStoreGatewayContext.Provider>
  );
}

function useConnectStoreGateway() {
  const connectStoreGatewayContext = useContext(ConnectStoreGatewayContext);

  if (connectStoreGatewayContext === undefined) {
    throw new Error('useConnectStoreGateway must be used within a PlatformGatewayProvider');
  }
  return connectStoreGatewayContext;
}

export { ConnectStoreGatewayProvider, FakeConnectStoreGatewayProvider, useConnectStoreGateway };
