import { B13A_FILING_OPTIONS } from '@client/src/create-shipments/basic/b13a-filing/constant';
import { PriceCalculationErrorLog, Incoterms } from 'typings/shipment';
import { CourierLogo } from '@client/data/courier';
import { CourierServiceRateData } from '../CourierServiceRate';
import {
  AuPostMyPostOptionalService,
  FedexOptionalService,
  OrderData,
  OrderEditData,
  UPSOptionalService,
} from '../Order';
import { ReceiverAddressData, ReceiverAddressEditData } from '../receiverAddress';
import { SenderAddressData, SenderAddressEditData } from '../senderAddress';

export const fedexHomeDeliveryPremiumOptions = [
  'fedex_home_delivery_premium_evening',
  'fedex_home_delivery_premium_appointment',
  'fedex_home_delivery_premium_date_certain',
] as const;
export type FedexHomeDeliveryPremiumOption = (typeof fedexHomeDeliveryPremiumOptions)[number];

export const fedexDeliverySignatureOptions = [
  'fedex_delivery_confirmation_adult_signature',
  'fedex_delivery_confirmation_direct_signature',
  'fedex_delivery_confirmation_indirect_signature',
  'fedex_delivery_confirmation_no_signature_required',
  'fedex_delivery_confirmation_default_signature',
] as const;
export type FedexDeliverySignatureOption = (typeof fedexDeliverySignatureOptions)[number];

export const upsDeliveryConfirmationOptions = [
  'ups_delivery_confirmation_no_signature',
  'ups_delivery_confirmation_signature_required',
  'ups_delivery_confirmation_adult_signature_required',
] as const;
export type UPSDeliveryConfirmationOption = (typeof upsDeliveryConfirmationOptions)[number];

export const fedexBatteryOptions = [
  'fedex_no_battery',
  'fedex_battery_pi966',
  'fedex_battery_pi967',
  'fedex_battery_pi969',
  'fedex_battery_pi970',
] as const;
export type FedexBatteryOption = (typeof fedexBatteryOptions)[number];

export const fedexAdditionalServicesOptions = [
  'fedex_priority_alert',
  'fedex_saturday_delivery',
  'fedex_saturday_pickup',
  'fedex_third_party_consignee',
] as const;
export type FedexAdditionalServicesOption = (typeof fedexAdditionalServicesOptions)[number];

export const upsAddedServiceOptions = [
  'ups_saturday_delivery',
  'ups_shipper_release',
  'ups_large_package',
  'ups_additional_handling',
] as const;
export type UPSAddedServiceOption = (typeof upsAddedServiceOptions)[number];

export const fedexOptions = [
  ...fedexHomeDeliveryPremiumOptions,
  ...fedexAdditionalServicesOptions,
  ...fedexDeliverySignatureOptions,
  ...fedexBatteryOptions,
];
export type FedexOption = (typeof fedexOptions)[number];

export const auPostMyPostBusinessOptions = [
  'australia_post_signature_on_delivery',
  'australia_post_extra_cover_checked',
  'australia_post_extra_cover',
] as const;
export type AuPostMyPostBusinessOptions = (typeof auPostMyPostBusinessOptions)[number];

export const upsOptions = [...upsDeliveryConfirmationOptions, ...upsAddedServiceOptions];
export type UPSOption = UPSDeliveryConfirmationOption | UPSAddedServiceOption;

export const additionalServicesOptions = [
  ...fedexOptions,
  ...upsOptions,
  ...auPostMyPostBusinessOptions,
];
export type AdditionalServicesOption = (typeof additionalServicesOptions)[number];

export const payorTypes = ['SENDER', 'RECIPIENT', 'THIRD_PARTY'] as const;
export type PayorType = (typeof payorTypes)[number];

export const residentialIdentification = ['unknown', 'positive', 'negative'] as const;
export type ResidentialIdentification = (typeof residentialIdentification)[number];

export type B13aFilingOption = (typeof B13A_FILING_OPTIONS)[number];

export type CourierSupportedIncoterms = 'DDU' | 'DDP' | 'DDU,DDP';

export interface BuyerSelectionData {
  courier: {
    name: string;
  };
  senderCountryPrice: {
    shippingCost: number;
    importTaxCharge: number;
    importDutyCharge: number;
    currencyCode: string;
  };
  receiverCountryPrice: {
    shippingCost: number;
    importTaxCharge: number;
    importDutyCharge: number;
    currencyCode: string;
  };
}

interface Courier {
  name: string;
  umbrellaName: string;
}

export interface ShipmentData {
  id: string;
  easyshipId: string;
  createdAt: string;
  orderCreatedAt: string;
  totalCharge: number;
  currencyCode: string;
  order: OrderData;
  senderAddress: SenderAddressData;
  receiverAddress: ReceiverAddressData;
  buyerSelection?: BuyerSelectionData;
  courierServiceSelectedId: string;
  rates: CourierServiceRateData[];
  tax: ShipmentTax;
  deliveryTime: ShipmentDeliveryTime;
  courierAlerts: string[];
  additionalService?: UpsServiceData | FedexServiceData | AuPostMyPostServiceData;
  hasMissingDimensions: boolean;
  effectiveIncoterms: Incoterms;
  isAboveThreshold: boolean;
  courierSupportedIncoterms?: CourierSupportedIncoterms;
  apiAddressesErrors: PriceCalculationErrorLog;
  apiOrderErrors: PriceCalculationErrorLog;
  isValidToShip: boolean;
  collectState: string;
  courier: Partial<Courier>;
}

export interface ShipmentDeliveryTime {
  min: number;
  max: number;
}

export interface ShipmentTax {
  sales: number;
  salesTaxName: string;
  provincialSales: number;
  provincialSalesTaxName: string;
}

export interface OrderFormData {
  order: OrderEditData;
}

export interface AddressesFormData {
  senderAddress: SenderAddressEditData;
  receiverAddress: ReceiverAddressEditData;
}

export type ShipmentFormData = Partial<OrderFormData & AddressesFormData>;

export interface UpsServiceData {
  deliveryConfirmation: UPSDeliveryConfirmationOption;
  hasSaturdayDelivery: boolean;
  hasShipperRelease: boolean;
  hasLargePackage: boolean;
  hasAdditionalHandling: boolean;
}

type FedexHomeDeliveryPremiumOptionDetails =
  | {
      value: 'fedex_home_delivery_premium_evening' | 'fedex_home_delivery_premium_appointment';
    }
  | {
      value: 'fedex_home_delivery_premium_date_certain';
      date: string;
    };

type PriorityAlertDetails =
  | {
      value: false;
    }
  | {
      value: true;
      notes: string;
      hasPriorityAlertPlus: boolean;
    };

export interface AuPostMyPostServiceData {
  hasSignatureOnDelivery: boolean;
  hasExtraCoverChecked: boolean;
  hasExtraCover: string;
}

export interface FedexServiceData {
  shippingCost: {
    payor: PayorType;
    accountNumber: string;
  };
  taxDuties: {
    payor: PayorType;
    accountNumber: string;
  };
  deliveryConfirmation: FedexDeliverySignatureOption;
  hasHomeDeliveryPremium: boolean;
  homeDeliveryPremium?: FedexHomeDeliveryPremiumOptionDetails;
  battery: FedexBatteryOption;
  hasSaturdayDelivery: boolean;
  priorityAlert: PriorityAlertDetails;
  hasSaturdayPickup: boolean;
  hasThirdPartyConsignee: boolean;
  brokerId: string;
  b13a?: {
    filingOption: B13aFilingOption;
    permitNumber: string;
    exportComplianceStatement: string;
  };
}

export const upsOptionsDefault: UPSOptionalService = {
  deliveryConfirmation: 'ups_delivery_confirmation_no_signature',
  hasSaturdayDelivery: false,
  hasShipperRelease: false,
  hasLargePackage: false,
  hasAdditionalHandling: false,
  value: CourierLogo.Ups,
};

export const fedexOptionsDefault: FedexOptionalService = {
  shippingCost: {
    payor: 'SENDER',
    accountNumber: '',
  },
  taxDuties: {
    payor: 'SENDER',
    accountNumber: '',
  },
  deliveryConfirmation: 'fedex_delivery_confirmation_default_signature',
  battery: 'fedex_no_battery',
  hasSaturdayDelivery: false,
  hasSaturdayPickup: false,
  hasThirdPartyConsignee: false,
  hasHomeDeliveryPremium: false,
  priorityAlert: {
    value: false,
  },
  brokerId: '',
  b13a: {
    filingOption: 'NOT_REQUIRED',
    exportComplianceStatement: '',
    permitNumber: '',
  },
  value: CourierLogo.Fedex,
};

export const auPostMyPostBusinessOptionsDefault: AuPostMyPostOptionalService = {
  hasSignatureOnDelivery: false,
  hasExtraCoverChecked: false,
  hasExtraCover: '',
  value: CourierLogo.MyPost,
};
