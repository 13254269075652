import angular from 'angular';

import { HubspotResource } from './hubspot.resource';
import { HubspotService } from './hubspot.service';
import { HubspotFormsService } from './hubspot-forms.service';

angular
  .module('app.global.hubspot', ['ngResource'])
  .service('HubspotResource', HubspotResource)
  .service('HubspotFormsService', HubspotFormsService)
  .service('HubspotService', HubspotService);
