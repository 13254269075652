import { IUserSession } from 'typings/user-session';
import {
  IShipmentBulkEditFieldsSuccessResponse,
  IShipmentBulkEditConfirmPayload,
  IShipmentBulkEditConfirmSuccessResponse,
  IShipmentBulkEditConfirmSelectAllPayload,
} from 'typings/shipment';
import { ShipmentListResource } from '../shipment-list/shipment-list.resource';

class BulkEditService {
  static $inject = ['UserSession', 'ShipmentListResource'];
  constructor(
    private UserSession: IUserSession,
    private ShipmentListResource: ShipmentListResource
  ) {}

  getFields(): ng.IPromise<IShipmentBulkEditFieldsSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();
    const params = {
      company_type: 'cloud',
      company_id: companyId,
    };

    return this.ShipmentListResource.bulkEditFields(params);
  }

  updateShipment(
    payload: IShipmentBulkEditConfirmPayload | IShipmentBulkEditConfirmSelectAllPayload
  ): ng.IPromise<IShipmentBulkEditConfirmSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    const params = {
      company_type: 'cloud',
      company_id: companyId,
    };

    return this.ShipmentListResource.bulkEditConfirm(params, payload);
  }
}

export { BulkEditService };
