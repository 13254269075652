import template from './upload-error-modal.html?raw';
import { UploadModalErrorController as controller } from './upload-error-modal.controller';

class UploadErrorModalService {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(report) {
    this.modalReference = this.$uibModal.open({
      template,
      controller,
      controllerAs: '$ctrl',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        report: () => report,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { UploadErrorModalService };
