import { IComponentController } from 'angular';
import { NumberOfItemsInputOptionsValue } from '@client/src/global/services/shipment-list/data/number-input';
import template from './number-of-items-input-group.html?raw';
import style from './number-of-items-input-group.module.scss';

enum InputTypeValue {
  From = 'from_number',
  To = 'to_number',
  Equal = 'equal_number',
}

interface IInputState {
  from: boolean;
  to: boolean;
  equal: boolean;
}

type Option = {
  value: NumberOfItemsInputOptionsValue;
  display: string;
};

class NumberOfItemsInputGroup implements IComponentController {
  style = style;
  esModelSelect: NumberOfItemsInputOptionsValue | null = null;
  isBetweenOption = false;
  isShowInput = false;
  esFromNumber: number | null = null;
  esToNumber: number | null = null;
  esEqualNumber: number | null = null;
  inputTypeValue = InputTypeValue;
  readonly numberValidation = {
    allowNegative: false,
    allowFloat: false,
  };
  options: Option[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  $onInit(): void {
    if (!this.esModelSelect) {
      this.esModelSelect = NumberOfItemsInputOptionsValue.GreaterThanEqualTo;
      this.onValuesChange();
    }

    this.options = [
      {
        value: NumberOfItemsInputOptionsValue.EqualTo,
        display: this.$translate.instant('global.relation.is_equal_to'),
      },
      {
        value: NumberOfItemsInputOptionsValue.NotEqualTo,
        display: this.$translate.instant('global.relation.is_not_equal_to'),
      },
      {
        value: NumberOfItemsInputOptionsValue.GreaterThan,
        display: this.$translate.instant('global.relation.is_greater_than'),
      },
      {
        value: NumberOfItemsInputOptionsValue.GreaterThanEqualTo,
        display: this.$translate.instant('global.relation.is_greater_than_or_equal_to'),
      },
      {
        value: NumberOfItemsInputOptionsValue.LessThan,
        display: this.$translate.instant('global.relation.is_less_than'),
      },
      {
        value: NumberOfItemsInputOptionsValue.LessThanEqualTo,
        display: this.$translate.instant('global.relation.is_less_than_or_equal_to'),
      },
      {
        value: NumberOfItemsInputOptionsValue.Between,
        display: this.$translate.instant('global.relation.is_between'),
      },
      // TODO Disable until we support it in the future
      // {
      //   value: NumberOfItemsInputOptionsValue.Known,
      //   display: 'known',
      // },
      // {
      //   value: NumberOfItemsInputOptionsValue.Unknown,
      //   display: 'Unknown',
      // },
    ];

    this.assignCurrentValue();
  }

  $onChanges(): void {
    this.assignCurrentValue();
  }

  onSelectChange(value: NumberOfItemsInputOptionsValue): void {
    switch (value) {
      case NumberOfItemsInputOptionsValue.Between:
        this.resetInputValue({
          from: true,
          to: true,
          equal: false,
        });
        break;
      // TODO Disable until we support it in the future
      // case NumberOfItemsInputOptionsValue.Known:
      // case NumberOfItemsInputOptionsValue.Unknown:
      //   this.resetInputValue({
      //     from: false,
      //     to: false,
      //     equal: false,
      //   });
      //   break;
      default:
        this.resetInputValue({
          from: false,
          to: false,
          equal: true,
        });
        break;
    }
    this.esModelSelect = value;
    this.setBetweenOptionState();
    this.onValuesChange();
  }

  onInputChange(value: string, key: InputTypeValue): void {
    switch (key) {
      case InputTypeValue.From:
        this.esFromNumber = parseFloat(value);
        break;
      case InputTypeValue.To:
        this.esToNumber = parseFloat(value);
        break;
      case InputTypeValue.Equal:
        this.esEqualNumber = parseFloat(value);
        break;
      default:
        break;
    }

    this.onValuesChange();
  }

  private setBetweenOptionState(): void {
    switch (this.esModelSelect) {
      // TODO Disable until we support it in the future
      // case NumberOfItemsInputOptionsValue.Known:
      // case NumberOfItemsInputOptionsValue.Unknown:
      //   this.isShowInput = false;
      //   break;
      case NumberOfItemsInputOptionsValue.Between:
        this.isShowInput = true;
        this.isBetweenOption = true;
        break;
      default:
        this.isShowInput = true;
        this.isBetweenOption = false;
        break;
    }
  }

  private onValuesChange(): void {
    this.esOnChange({
      value: {
        select: this.esModelSelect,
        from: this.esFromNumber,
        to: this.esToNumber,
        equal: this.esEqualNumber,
      },
    });
  }

  private assignCurrentValue(): void {
    if (this.esModelSelect) {
      this.setBetweenOptionState();
    }
  }

  private resetInputValue(inputState: IInputState): void {
    if (!inputState.from) {
      this.esFromNumber = null;
    }

    if (!inputState.to) {
      this.esToNumber = null;
    }

    if (!inputState.equal) {
      this.esEqualNumber = null;
    }
  }
}

const NumberOfItemsInputGroupComponent: ng.IComponentOptions = {
  controller: NumberOfItemsInputGroup,
  template,
  bindings: {
    esModelSelect: '<',
    esEqualNumber: '<',
    esFromNumber: '<',
    esToNumber: '<',
    esOnChange: '&',
  },
};

export { NumberOfItemsInputGroupComponent };
