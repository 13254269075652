import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { HelpContactSupportFeature } from '../types';
import { COUNTRIES } from './constants';
import CallDescription from './CallDescription';
import ContactNumbers from './ContactNumbers';

interface FeatureDescriptionProps {
  feature: HelpContactSupportFeature;
  hasCallSupport: boolean;
}

export function FeatureDescription({
  feature,
  hasCallSupport,
}: FeatureDescriptionProps): ReactElement<FeatureDescriptionProps> {
  if (feature === 'call') {
    return hasCallSupport ? (
      <ContactNumbers countries={COUNTRIES} />
    ) : (
      <CallDescription feature={feature} countries={COUNTRIES} />
    );
  }
  return <FormattedMessage id={`help-guide.contact-support.${feature}.description`} />;
}
