(function () {
  'use strict';

  GoogleAnalytics.$inject = ['$window'];
  function GoogleAnalytics($window) {
    var googleAnalyticsService = {};

    googleAnalyticsService.createGAClickButton = function (label) {
      if (!$window.ga) return;

      $window.ga('send', {
        hitType: 'event',
        eventCategory: 'Button',
        eventAction: 'Click',
        eventLabel: label,
      });
    };

    /**
     * Example usage:
     *
     * GoogleAnalytics.createEvent({
     *   event: 'gaEvent',
     *   eventCategory: 'Seeking Help',
     *   eventAction: 'Click help button',
     *   eventLabel: UserSession.company.easyship_company_id
     * });
     */
    googleAnalyticsService.createEvent = function (action) {
      if (!$window.dataLayer) return;

      $window.dataLayer.push(action);
    };

    googleAnalyticsService.startShipping = function () {
      googleAnalyticsService.createGAClickButton('Start Shipping');
    };

    googleAnalyticsService.messageSent = function (label) {
      if (!$window.ga) return;

      $window.ga('send', {
        hitType: 'event',
        eventCategory: 'Message',
        eventAction: 'Sent',
        eventLabel: label,
      });
    };

    return googleAnalyticsService;
  }

  angular.module('app.factory.GoogleAnalytics', []).factory('GoogleAnalytics', GoogleAnalytics);
})();
