import template from './tracking-rating.html?raw';
import style from './tracking-rating.module.scss';

class TrackingRating {
  constructor() {
    this.style = style;
  }
}

const TrackingRatingComponent = {
  controller: TrackingRating,
  template,
  bindings: {
    esRating: '<',
  },
};

export { TrackingRatingComponent };
