import ssoTemplate from './sso.html?raw';
import sessionTemplate from './session/session.html?raw';
import luxotticaTemplate from './luxottica/luxottica.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider
        .state('home.auth.sso', {
          url: 'sso/:client?return_to&token',
          template: ssoTemplate,
          controller: 'SsoCtrl as vm',
          metadata: {
            title: 'Login to Easyship Support',
            description:
              'Login to your Easyship account to manage, ship and track your international eCommerce orders. Start shipping today with the the cheapest, fastest and best value for money shipping deals!',
          },
        })
        .state('home.auth.sso-session', {
          url: 'sso/session?token&error_message&sso_url',
          template: sessionTemplate,
          controller: 'SsoSessionCtrl as vm',
          metadata: {
            title: 'Login to Easyship Support',
            description:
              'Login to your Easyship account to manage, ship and track your international eCommerce orders. Start shipping today with the the cheapest, fastest and best value for money shipping deals!',
          },
        })
        .state('home.auth.luxottica', {
          url: 'sso/netiq/luxottica?token&error_message',
          template: luxotticaTemplate,
          controller: 'SsoLuxotticaCtrl as vm',
          metadata: {
            title: 'Login to Easyship Support',
            description:
              'Login to your Easyship account to manage, ship and track your international eCommerce orders. Start shipping today with the the cheapest, fastest and best value for money shipping deals!',
          },
        });
    },
  ]);
})();
