import angular from 'angular';

import { TeamComponent } from './team.component';
import { AngularManageTeamListComponent } from './components/ManageTeamList';

const MODULE_NAME = 'app.account.team';

angular
  .module(MODULE_NAME, [])
  .component('team', TeamComponent)
  .component('esAccountManageTeamList', AngularManageTeamListComponent);

export default MODULE_NAME;
