(function () {
  'use strict';

  AutomationAction.$inject = ['$resource', 'API'];
  function AutomationAction($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/automation_actions/:id',
      {
        id: '@_id',
      },
      {
        query: {
          method: 'GET',
          params: {},
        },
        fetch: {
          method: 'GET',
          params: {},
        },
      }
    );
  }

  angular.module('app.factory.AutomationAction', []).factory('AutomationAction', AutomationAction);
})();
