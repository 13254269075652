import template from './thermal-invoice-modal-content.html?raw';
import style from './thermal-invoice-modal-content.module.scss';
import rejectedPackageUrl from '@assets/images/dashboard/tools/rejected-package.svg';

class ThermalInvoiceModalContent {
  rejectedPackageUrl = rejectedPackageUrl;

  constructor() {
    this.style = style;
  }
}

const ThermalInvoiceModalContentComponent = {
  controller: ThermalInvoiceModalContent,
  template,
  bindings: {
    close: '&',
  },
};

export { ThermalInvoiceModalContentComponent };
