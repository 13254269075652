import logo from '@assets/images/logos/easyship-logo/logo-dark.svg?svgo';
import { IApiConfig } from 'typings/core/config/api';
import template from './index.html?raw';
import style from './index.module.scss';

class IndexLayoutController {
  style = style;
  logo: string;

  static $inject = ['$sce', 'API'];
  constructor(private $sce: ng.ISCEService, public API: IApiConfig) {
    this.logo = this.$sce.trustAsHtml(logo);
  }
}

const IndexLayoutComponent = {
  controller: IndexLayoutController,
  template,
};

export { IndexLayoutComponent };
