import { IAdvanceSearchTagItem } from 'typings/tags';
import { IGroupedArray } from 'typings/helper';
import { IInputGroupRadioModel } from 'typings/form';
import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';

import { IComponentController } from 'angular';
import { TagsService } from '@client/src/global/services/tags/tags.service';
import { toastError } from '@client/core/components/react/Toastify';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';
import template from './tags-input-group.html?raw';
import style from './tags-input-group.module.scss';

class TagsInputGroup
  extends MultiSelectInputGroup<IAdvanceSearchTagItem>
  implements IComponentController
{
  style = style;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvanceSearchTagItem>[] = [];
  preSelectedTags: string[] = [];
  loading = false;

  static $inject = ['$translate', 'TagsService', '$scope'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private TagsService: TagsService,
    $scope: ng.IScope
  ) {
    super($scope);

    const noun = this.$translate.instant('global.tag');
    const $t = (key: string) =>
      this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];
  }

  $onInit(): void {
    this.assignDefaultRadioModel();
    this.watchAndRequest(this.searchTags);
  }

  searchTags = (append = false): ng.IPromise<void> => {
    this.loading = true;

    if (!append) {
      const selectedOptions = this.esModelOptions?.length
        ? this.esModelOptions[0]?.options?.filter((tagItem) => tagItem.selected) || []
        : [];

      this.esModelOptions = [
        {
          display: 'order',
          options: selectedOptions,
        },
      ];
      this.preSelectedTags = this.esModelOptions[0].options?.map(({ value }) => value) || [];
      this.optionsOffset = 0;
    }

    return this.TagsService.query(this.esModelInput as string, this.optionsOffset)
      .then((tags) => this.assignTagsList(tags || []))
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
        this.esModelOptions = [];
      });
  };

  onSelectTag(option: IAdvanceSearchTagItem): void {
    // eslint-disable-next-line no-param-reassign
    option.selected = !option.selected;
  }

  loadMore = (last: boolean, inview: boolean) => {
    if (!last || !inview || this.loading || !this.esModelOptions?.length) return;

    this.searchTags(true);
  };

  assignTagsList = (tags: IAdvanceSearchTagItem[]): void => {
    const filteredResponse = tags.filter(({ value }) => !this.preSelectedTags.includes(value));
    this.esModelOptions[0].options = (this.esModelOptions[0].options || []).concat(
      filteredResponse
    );

    this.optionsOffset += tags.length;
    this.loading = false;
  };
}

const TagsInputGroupComponent: ng.IComponentOptions = {
  controller: TagsInputGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { TagsInputGroupComponent };
