import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import template from './delete-member-card.html?raw';
import style from './delete-member-card.module.scss';

class DeleteMemberCard implements IComponentController {
  style = style;
  esEmail?: string;

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  get disableDelete(): boolean {
    return !this.UserSession.isAccountOwner() || this.UserSession.user.email === this.esEmail;
  }
}

const DeleteMemberCardComponent: ng.IComponentOptions = {
  controller: DeleteMemberCard,
  template,
  bindings: {
    esRightText: '<',
    esRightAction: '&',
    esLeftText: '<',
    esLeftAction: '&',
    esCloseAction: '&',
    esShowCloseButton: '<',
    esMemberName: '<',
    esMemberInactive: '<',
    esAlertMessage: '<',
    esEmail: '<',
    esPhoneNumber: '<',
    esSubscriptionAvailable: '<',
  },
};

export { DeleteMemberCardComponent };
