(function () {
  'use strict';

  RateCalculator.$inject = ['$resource', 'API'];
  function RateCalculator($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/get_all_rates',
      {},
      {
        save: {
          method: 'POST',
          params: {},
        },
      }
    );
  }

  angular.module('app.factory.RateCalculator', []).factory('RateCalculator', RateCalculator);
})();
