import { IShipmentCustomView, IShipmentListScope } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { IHelperService } from 'typings/helper';
import template from './views-dropdown.html?raw';
import style from './views-dropdown.module.scss';

class ShipmentsViewsDropdown implements IComponentController {
  style = style;
  esIsOpen = false;
  esSelectedView = {} as IShipmentCustomView;
  esDefaultView = '';
  esCustomViews: IShipmentCustomView[] = [];

  isOpen = false;
  showReturnsView = false;
  isReturnPage = false;
  allShipmentsTitle = '';

  static $inject = ['$state', '$translate', '$stateParams', 'HelperService', 'UserSession'];
  constructor(
    private $state: ng.ui.IStateParamsService,
    private $translate: angular.translate.ITranslateService,
    private $stateParams: ng.ui.IStateParamsService,
    private HelperService: IHelperService,
    private UserSession: IUserSession
  ) {}

  get searchScope(): IShipmentListScope {
    return (
      this.$stateParams.sectionName
        ? this.HelperService.kebabToSnakeCase(this.$stateParams.sectionName)
        : 'shipments_all'
    ) as IShipmentListScope;
  }

  $onInit() {
    this.showReturnsView = !!this.UserSession.hasReturnFeatureSupport();
    this.isReturnPage = this.$state.current.name === 'app.returns';

    if (this.isReturnPage) {
      this.allShipmentsTitle = this.$translate.instant('shipment-views.default-views.all-returns');
    } else if (this.searchScope === 'shipments_all') {
      this.allShipmentsTitle = this.$translate.instant('shipment-views.default-views.all');
    } else if (this.searchScope === 'to_download') {
      this.allShipmentsTitle = this.$translate.instant('shipments.scopes.to_print');
    } else {
      this.allShipmentsTitle = this.$translate.instant(`shipments.scopes.${this.searchScope}`);
    }
  }

  // eslint-disable-next-line
  esOnDropdownToggle(data: { isOpen: boolean }): void {
    // esOnDropdownToggle expression bindings, need to add this in order for typescript to successfully compile
  }

  esOnSelectView(): void {
    // esOnSelectView expression bindings, need to add this in order for typescript to successfully compile
  }

  esOnResetDefaultView(): void {
    // esOnResetDefaultView expression bindings, need to add this in order for typescript to successfully compile
  }

  get viewId(): string | null {
    return this.$stateParams.view_id || this.esSelectedView?.id;
  }

  $onChanges(): void {
    if (this.esIsOpen || this.esIsOpen === false) {
      this.isOpen = this.esIsOpen;
    }
  }

  onToggle(isOpen: boolean): void {
    this.isOpen = isOpen;
    this.esOnDropdownToggle({
      isOpen,
    });
  }

  get defaultViewName(): string {
    const defaultView = this.esCustomViews.find((view: IShipmentCustomView) => {
      return view.id === this.esDefaultView;
    });

    return defaultView ? defaultView.name : this.allShipmentsTitle;
  }

  resetDefaultView($event: KeyboardEvent): void {
    $event.stopPropagation();

    this.esOnResetDefaultView();
    this.esSelectedView = {
      company_id: '',
      created_at: '',
      filters: '',
      id: '',
      items_per_page: 0,
      keyword: '',
      name: '',
      page_context: '',
      sort_criterias: '',
      updated_at: '',
    };
  }
}

const ShipmentsViewsDropdownComponent: ng.IComponentOptions = {
  controller: ShipmentsViewsDropdown,
  template,
  bindings: {
    esDefaultViews: '<',
    esCustomViews: '<',
    esSelectedView: '<',
    esIsCustomViewDifferent: '<',
    esCanSaveLayout: '<',
    esIsOpen: '<',
    esDefaultView: '<',
    esOnSaveViewSelect: '&',
    esOnDeleteViewSelect: '&',
    esOnSelectView: '&',
    esOnUpdateView: '&',
    esOnModifyView: '&',
    esOnDropdownToggle: '&',
    esOnResetDefaultView: '&',
  },
};

export { ShipmentsViewsDropdownComponent };
