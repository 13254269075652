class WebhooksResource {
  static $inject = ['$resource', 'API'];

  constructor($resource, API) {
    this.endpoint = `${API.endpoint}/companies/:company_id/webhooks/:id/:controller`;
    this.resource = $resource(
      this.endpoint,
      {},
      {
        update: { method: 'PATCH' },
        test: {
          method: 'POST',
          params: {
            controller: 'test',
          },
        },
      }
    );
  }

  getListing(params) {
    return this.resource.get(params).$promise;
  }

  getDetails(params) {
    return this.resource.get(params).$promise;
  }

  create(params, payload) {
    return this.resource.save(params, payload).$promise;
  }

  update(params, payload) {
    return this.resource.update(params, payload).$promise;
  }

  delete(params) {
    return this.resource.delete(params).$promise;
  }

  test(params, payload) {
    return this.resource.test(params, payload).$promise;
  }
}

export { WebhooksResource };
