import moment from 'moment';
import { DATE_FORMAT } from '../../../global/manifest/manifest.service';

class ManifestTrackingService {
  static $inject = ['MixpanelService'];

  constructor(MixpanelService) {
    this.MixpanelService = MixpanelService;
  }

  trackOpen({ manifests, manifestDates = [] }) {
    const datesWithManifests = manifestDates.filter((date) => !date.disabled);
    const allManifests = Object.keys(manifests).reduce((manifestsCollector, date) => {
      return [
        ...manifestsCollector,
        ...Object.keys(manifests[date]).reduce((manifestsForDate, location) => {
          return [...manifestsForDate, ...manifests[date][location]];
        }, []),
      ];
    }, []);

    this.MixpanelService.track('Manage Shipment - Open Manifest', {
      number_of_dates: datesWithManifests.length,
      number_of_manifests: allManifests.length,
    });
  }

  trackGenerateSubmit({ manifest, selectedDate }) {
    const {
      total_shipments_count = 0,
      pending_count = 0,
      rejected_count = 0,
      handover_info = {},
    } = manifest;

    this.MixpanelService.track('Manifest - Generate Manifest - Submit', {
      current_date: moment().startOf('day').toISOString(),
      selected_date: moment(selectedDate, DATE_FORMAT).toISOString(),
      number_of_shipments: total_shipments_count - pending_count - rejected_count,
      expired: handover_info.expired,
    });
  }

  trackGenerateComplete({ manifest }) {
    const { total_shipments_count = 0, pending_count = 0, rejected_count = 0, id = '' } = manifest;

    this.MixpanelService.track('Manifest - Generate Manifest - Complete', {
      number_of_shipments: total_shipments_count - pending_count - rejected_count,
      number_missing: pending_count + rejected_count,
      manifest_id: id,
    });
  }

  trackDownload({ manifest, selectedDate }) {
    const { id = '', handover_info = {} } = manifest;

    this.MixpanelService.track('Manifest - Re-Download Manifest', {
      current_date: moment().startOf('day').toISOString(),
      selected_date: moment(selectedDate, DATE_FORMAT).toISOString(),
      manifest_id: id,
      expired: handover_info.expired,
    });
  }
}

export { ManifestTrackingService };
