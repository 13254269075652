import COLORS from '@client/src/colors';

export const baseStyleIconBox = {
  width: '20px',
  height: '20px',
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const actionPanel = {
  border: `1px solid ${COLORS.skyDark}`,
  margin: '5px',
  padding: '10px',
};

export const baseStyleIcon = {
  colorGrey: '#919eab',
  colorRed: COLORS.redDark,
  marginRight: '8px',
  fontSizeMd: '15px',
  fontSizeLg: '20px',
};
