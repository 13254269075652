import { createPopper } from '@popperjs/core';

import template from './multiselect.html?raw';
import style from './multiselect.module.scss';

const KEYBOARD_ESCAPE_KEYCODE = 27;
const KEYBOARD_SPACE_KEYCODE = 32;
const KEYBOARD_ARROW_UP_KEYCODE = 38;
const KEYBOARD_ARROW_DOWN_KEYCODE = 40;

class Multiselect {
  static $inject = ['$element'];

  constructor($element) {
    this.style = style;
    this.$element = $element;
  }

  $onInit() {
    this.removeSelected = false;
    this.selectedOptions = this.ngModel.reduce((accumulator, option) => {
      accumulator[option] = this.ngModel.includes(option);

      return accumulator;
    }, {});

    this._setValidation();
  }

  _toggleOptions() {
    if (this.showOptions) {
      this._hideOptions();
    } else {
      this._showOptions();
    }
  }

  _showOptions() {
    this.showOptions = true;

    const reference = this.$element.find(`.${this.style.select}`)[0];
    const popper = this.$element.find(`.${this.style.options}`)[0];

    popper.style.width = `${reference.offsetWidth}px`;

    createPopper(reference, popper, {
      placement: 'bottom-start',
    });
  }

  _hideOptions() {
    this.showOptions = false;
  }

  onClick() {
    this._toggleOptions();

    if (this.showOptions) {
      this._showOptions();
    }
  }

  onInputKeydown($event) {
    if ($event.keyCode === KEYBOARD_ESCAPE_KEYCODE) {
      this._hideOptions();
    } else if ($event.keyCode === KEYBOARD_SPACE_KEYCODE) {
      this._stopAndPreventEventsMethod($event);
      this._toggleOptions();
    } else if ([KEYBOARD_ARROW_UP_KEYCODE, KEYBOARD_ARROW_DOWN_KEYCODE].includes($event.keyCode)) {
      this._stopAndPreventEventsMethod($event);
      this._showOptions();
    }
  }

  onDropdownKeydown($event) {
    if ($event.keyCode === KEYBOARD_ESCAPE_KEYCODE) {
      this._hideOptions();
    }
  }

  onClickOutside() {
    this._hideOptions();
  }

  updateModel(value, option) {
    if (option.removeSelected) {
      this.selectedOptions = {};
      this.removeSelected = true;
    } else if (this.removeSelected) {
      this.selectedOptions = {};
      this.removeSelected = false;
    }

    this.selectedOptions[option[this.esValueKey] || option] = value;

    this.ngModel = Object.keys(this.selectedOptions).reduce((accumulator, option) => {
      if (this.selectedOptions[option]) {
        accumulator.push(option);
      }

      return accumulator;
    }, []);

    this.ngChange({ value: this.ngModel });
    this._setValidation();
  }

  getPlaceholder() {
    if (!this.ngModel.length) return this.esPlaceholder;

    if (!this.esValueKey) return this.ngModel.join(', ');

    return Object.keys(this.esOptions)
      .reduce((accumulator, option) => {
        if (this.ngModel.includes(this.esOptions[option][this.esValueKey])) {
          accumulator.push(this.esOptions[option][this.esDisplayKey]);
        }

        return accumulator;
      }, [])
      .join(', ');
  }

  hasLabel() {
    return !!angular.element('ng-transclude', this.$element).html();
  }

  _setValidation() {
    this.$ngModelController.$setValidity('ngRequired', !(!this.ngModel.length && this.ngRequired));
  }

  _stopAndPreventEventsMethod($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}

const MultiselectComponent = {
  controller: Multiselect,
  require: {
    $ngModelController: 'ngModel',
  },
  template,
  transclude: true,
  bindings: {
    ngModel: '<',
    ngChange: '&',
    ngRequired: '<',
    esOptions: '<',
    esPlaceholder: '@',
    esHelp: '@',
    esValueKey: '@',
    esDisplayKey: '@',
    esPlatformKey: '@',
    esCourierKey: '@',
  },
};

export { MultiselectComponent };
