import template from './return-modal.html?raw';
import { ReturnModalCtrl } from './return-modal.controller';

class ReturnModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(shipment) {
    this.modalReference = this.$uibModal.open({
      template,
      controller: ReturnModalCtrl,
      controllerAs: '$ctrl',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        shipment() {
          return shipment;
        },
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { ReturnModal };
