import {
  IVerifyManifestParams,
  IVerifyManifestRequest,
  IVerifyManifestResponse,
  IGetManifestParams,
  IGetManifestResponse,
  IGenerateManifestParams,
  IGenerateManifestResponse,
  IGenerateManifestPayload,
  IRetrieveManifestParams,
  IRetrieveManifestPayload,
  IRetrieveManifestResponse,
} from 'typings/manifest';
import { IApiConfig } from 'typings/core/config/api';

class ManifestResource {
  verifyManifestEndpoint = '';
  manifestEndpoint = '';

  static $inject = ['$resource', 'API'];
  constructor(private $resource: any, private API: IApiConfig) {
    this.manifestEndpoint = `${API.endpoint}/cloud/companies/:company_id/manifests/:umbrella_name/:controller`;
    this.verifyManifestEndpoint = `${this.API.endpoint}/cloud/companies/:company_id/manifests/:umbrella_name/verify_manifestable`;
  }

  verify(
    params: IVerifyManifestParams,
    payload: IVerifyManifestRequest
  ): ng.IPromise<IVerifyManifestResponse> {
    return this.$resource(this.verifyManifestEndpoint).save(params, payload).$promise;
  }

  get(params: IGetManifestParams): ng.IPromise<IGetManifestResponse> {
    return this.$resource(this.manifestEndpoint).get(params).$promise;
  }

  retrieveManifests(
    params: IRetrieveManifestParams,
    payload: IRetrieveManifestPayload
  ): ng.IPromise<IRetrieveManifestResponse> {
    return this.$resource(this.manifestEndpoint).save(
      { controller: 'retrieve_manifests_by_courier_account', ...params },
      payload
    ).$promise;
  }

  generate(
    params: IGenerateManifestParams,
    payload: IGenerateManifestPayload
  ): ng.IPromise<IGenerateManifestResponse> {
    return this.$resource(this.manifestEndpoint).save(params, payload).$promise;
  }
}

export { ManifestResource };
