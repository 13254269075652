import template from './product-dimensions.html?raw';
import style from './product-dimensions.module.scss';

class ProductDimensionsController {
  static $inject = ['UserSession'];

  constructor(UserSession) {
    this.style = style;
    this.UserSession = UserSession;
  }

  $onChanges(changesObj) {
    if (!changesObj.item) return;

    const { length, width, height } = changesObj.item.currentValue;

    this.dimensions = {
      length,
      width,
      height,
    };
  }

  onDimensionsInputChange(value) {
    this.item = {
      ...this.item,
      ...value,
    };

    this.onUpdate({ index: this.index, value: this.item });
  }
}

const ProductDimensionsComponent = {
  template,
  controller: ProductDimensionsController,
  bindings: {
    item: '<',
    onUpdate: '&',
  },
};

export { ProductDimensionsComponent };
