import { routingFailsafeManageShipments } from './state-change-start/routing-failsafe-manage-shipments';

stateChangeStart.$inject = ['$transitions', 'HelperService'];
function stateChangeStart($transitions, HelperService) {
  $transitions.onStart({}, (transition) => {
    const fromState = transition.from();
    const toState = transition.to();
    const paramsTo = transition.params('to');

    // Add failsafe if going from app.shipments to app.shipments
    if (fromState.name === 'app.shipments' && toState.name === 'app.shipments') {
      return routingFailsafeManageShipments(transition, paramsTo, HelperService);
    }
  });
}

export { stateChangeStart };
