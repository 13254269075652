import template from './show-shipment.html?raw';

(function () {
  'use strict';

  showShipmentModal.$inject = ['$uibModal'];
  function showShipmentModal($uibModal) {
    var service = this;

    service.open = function (shipmentId, index, fromState) {
      service.modalInstance = $uibModal.open({
        template,
        controller: 'showShipmentCtrl',
        controllerAs: 'vm',
        openedClass: 'easyship-modal-auto-width',
        resolve: {
          shipmentData: function () {
            return {
              shipmentId: shipmentId,
              index: index,
              fromState: fromState,
            };
          },
        },
      });
    };

    service.close = function () {
      service.modalInstance.close();
    };
  }

  angular.module('app.service.show-shipment').service('showShipmentModal', showShipmentModal);
})();
