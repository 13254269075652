import { IComponentController } from 'angular';
import template from './edit-products-modal-actions.html?raw';
import style from './edit-products-modal-actions.module.scss';
import { ProductListingService } from '../../product-listing.service';

class EditProductsModalActions implements IComponentController {
  style = style;
  esMissingInformation = false;
  requestLimit = 100;
  totals: { missing_info_products_count: number; total_products_count: number };

  static $inject = ['ProductListingService'];
  constructor(private ProductListingService: ProductListingService) {
    this.totals = ProductListingService.totals;
  }

  get selectedProductsCount(): number {
    return this.ProductListingService.isSelectedAllProducts
      ? this.ProductListingService.totals.total_products_count -
          Object.keys(this.ProductListingService.excludedProducts).length
      : Object.keys(this.ProductListingService.selectedProducts).length;
  }

  get formattedNumbers(): { count: string; limit: string } {
    return {
      count: new Intl.NumberFormat().format(this.selectedProductsCount),
      limit: new Intl.NumberFormat().format(this.requestLimit),
    };
  }
}

const EditProductsModalActionsComponent: ng.IComponentOptions = {
  controller: EditProductsModalActions,
  template,
  bindings: {
    esOnBulkEditSelect: '&',
    esOnSpreadsheetSelect: '&',
    esMissingInformation: '<',
  },
};

export { EditProductsModalActionsComponent };
