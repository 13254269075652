import angular from 'angular';

import { WeightInputGroupComponent } from './weight-input-group.component';

const MODULE_NAME = 'app.global.components.advanced-search.weight-input-group';

angular
  .module(MODULE_NAME, [])
  .component('esAdvancedSearchWeightInputGroup', WeightInputGroupComponent);

export default MODULE_NAME;
