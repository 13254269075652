import { IStatusMessageService } from 'typings/status';
import { IGroupedArray } from 'typings/helper';
import { IInputGroupRadioModel } from 'typings/form';
import { IAdvancedSearchStatusMessageObject } from 'typings/advanced-search';

import {
  MultiselectComponentModelValue,
  MultiselectAttributes,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import style from './shipment-status-multi-select-group.module.scss';
import template from './shipment-status-multi-select-group.html?raw';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class ShipmentStatusMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchStatusMessageObject>
  implements IComponentController
{
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchStatusMessageObject>[] = [];

  style = style;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  title = '';
  busy = false;
  esIsReturnPage = false;
  hideFromCloudAccounts = [
    'processing at warehouse',
    'awaiting confirmation by warehouse',
    'cancelled (no stock)',
  ];
  hideFromEfulAccounts = [
    'pickup scheduled',
    'to be dropped off',
    'awaiting pickup confirmation by courier',
  ];

  static $inject = ['$scope', '$translate', 'StatusMessageService', 'UserSession'];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private StatusMessageService: IStatusMessageService,
    private UserSession: IUserSession
  ) {
    super($scope);
    this.fetchStatusMessage = this.fetchStatusMessage.bind(this);
  }

  $onInit(): void {
    this.title = this.$translate.instant(
      `shipments.filter.${this.esIsReturnPage ? 'return-shipment-status' : 'shipment-status'}.title`
    );
    const $t = (key: string) => {
      return this.$translate.instant(`global.relation.prefixed.${key}`, { noun: this.title });
    };
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];

    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    this.onChangesAssignAndTrigger(changes, MultiselectAttributes.Name, this.fetchStatusMessage);
  }

  private fetchStatusMessage(): void {
    this.busy = true;
    this.StatusMessageService.getNewStatusMessages()
      .then((res) => {
        if (res && res.status_messages) {
          let statusMessages = res.status_messages;

          if (this.UserSession.company.service === 'cloud') {
            statusMessages = statusMessages.filter(
              (message) =>
                message.filter_group !== 'Processing at Warehouse' &&
                message.name &&
                !this.hideFromCloudAccounts.includes(message.name.toLowerCase())
            );
          }

          if (this.UserSession.isCompanyEfulfilment()) {
            statusMessages = statusMessages.filter(
              (message) =>
                message.name && !this.hideFromEfulAccounts.includes(message.name.toLowerCase())
            );
          }

          const groupMessages = this.StatusMessageService.generateNewFilterStatusMessageObject(
            statusMessages,
            'name'
          );
          this.esModelOptions = groupMessages;
          this.watchAndTrigger(MultiselectAttributes.Name);
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
        this.esModelOptions = [];
      })
      .finally(() => {
        this.busy = false;
      });
  }
}

const ShipmentStatusMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: ShipmentStatusMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
    esIsReturnPage: '<',
  },
};

export { ShipmentStatusMultiSelectGroupComponent };
