import { IComponentController } from 'angular';
import template from './ups-trademark-message.html?raw';
import style from './ups-trademark-message.module.scss';

class UpsTrademarkMessageController implements IComponentController {
  style = style;
}

const UpsTrademarkMessageComponent = {
  controller: UpsTrademarkMessageController,
  template,
};

export { UpsTrademarkMessageComponent };
