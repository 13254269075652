import angular from 'angular';
import { react2angular } from 'react2angular';

import CountryFlag from '@client/core/components/react/CountryFlag';

const MODULE_NAME = 'core.components.react.country-flag';

angular.module(MODULE_NAME, []).component('esCountryFlag', react2angular(CountryFlag, ['alpha2']));

export default MODULE_NAME;
