import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

/* eslint-disable no-param-reassign */
(function () {
  angular.module('easyshipDashboardApp').controller('addressesCtrl', addressesCtrl);

  addressesCtrl.$inject = [
    '$scope',
    '$translate',
    'UserSession',
    'AddressService',
    'createNewAddressModal',
    'PostalCodeService',
    'AddressyService',
    'CourierService',
    'CourierAccounts',
    'MixpanelService',
    '$timeout',
    'CompanyService',
  ];

  function addressesCtrl(
    $scope,
    $translate,
    UserSession,
    AddressService,
    createNewAddressModal,
    PostalCodeService,
    AddressyService,
    CourierService,
    CourierAccounts,
    MixpanelService,
    $timeout,
    CompanyService
  ) {
    const vm = this;

    vm.UserSession = UserSession;
    vm.UserCompany = UserSession.company;
    vm.AddressService = AddressService;
    vm.AddressyService = AddressyService;
    vm.createNewAddressModal = createNewAddressModal;
    vm.addressInfo = {};
    vm.errors = {};

    vm.accordionState = {};
    vm.busy = { page: true };
    vm.prefillSuggestions =
      UserSession.company.dashboard_settings.show_receiver_address_suggestions;
    vm.fieldValidators = {};
    vm.form = {};

    $scope.addressyFunctions = {};

    const US_ID = 234;

    const translations = {
      address: $translate
        .instant('global.pluralize.address', { COUNT: 1 }, 'messageformat')
        .toLowerCase(),
      addresses: $translate
        .instant('global.pluralize.address', { COUNT: 2 }, 'messageformat')
        .toLowerCase(),
      settings: $translate.instant('global.setting').toLowerCase(),
    };

    (function init() {
      vm.address = {};

      vm.AddressService.getAddresses().then(
        function () {
          vm.busy.page = false;
          vm.errors.page = false;
        },
        function () {
          vm.errors.page = true;
          vm.busy.page = false;
          toastError($translate.instant('toast.fetch-error', { noun: translations.addresses }));
        }
      );

      $scope.$watch('vm.addressCopy.country_id', function (countryId) {
        vm.AddressService.getFieldValidators(countryId).then(function (fieldValidators) {
          vm.fieldValidators = fieldValidators;
        });
      });
    })();

    /**
     * [editAddress] Create a deep copy of the address that we want to update
     *               Show the edit form
     *
     * @param  {Object} address: address to be edited
     */
    vm.editAddress = function (address) {
      vm.addressNotFoundMessage = null;
      _closeAllAddresses();
      vm.addressCopy = angular.copy(address);
      vm.addressReference = angular.copy(address);
      vm.accordionState.isDisabled = false;
    };

    function _closeAllAddresses() {
      AddressService.getShippingAddresses().forEach(function (address) {
        address.isOpen = false;
      });

      AddressService.getBillingAddresses().forEach(function (address) {
        address.isOpen = false;
      });
    }

    /**
     * [cancelEdit] Close editMode and clear the addressCopy object
     *
     * @param  {Object} address: address to be closed
     */
    vm.cancelEdit = function (address) {
      address.isOpen = false;
      address.noMatchingState = false;

      // Add delay to not clear the form until the animation of collapsing the form is over
      $timeout(function () {
        vm.addressCopy = null;
      }, 300);
    };

    /**
     * [updateAddress] Update content of an address
     *
     * @param  {Object} addressCopy : new content of the address
     * @param  {Object} address     : old address
     */
    vm.updateAddress = function (addressCopy, address) {
      const hasNoInvalidFields = vm.AddressService.runFieldValidators(
        vm.fieldValidators,
        addressCopy
      );

      vm.AddressService.touchAllForms(vm.form);

      if (!hasNoInvalidFields) {
        toastError($translate.instant('toast.incomplete-form'));
        return;
      }

      if (addressCopy.country_id === US_ID && !vm.showUsBackupForm) {
        // Assign the new addressy ID to the addressCopy for comparison
        addressCopy.addressy_id = vm.addressInfo.Id;

        updatedAddressWithAddressy(addressCopy, address);
      } else {
        updateAddressManually(addressCopy, address);
      }
    };

    /**
     * [changeAddressyForm] Show address-form-us-addressy/address-form-us-backup
     *
     */
    vm.toggleAddressyForm = function () {
      vm.showUsBackupForm = !vm.showUsBackupForm;
    };

    function updatedAddressWithAddressy(addressCopy, address) {
      if (!addressCopy.addressy_id) return;

      // Need to check if the address_id has changed and if the address is a pickup address
      // in order to show the confirmation modal (recalculating)
      if (
        address.default_values.pickup &&
        UserSession.hasFixedOriginForShipping() &&
        addressCopy.addressy_id !== address.addressy_id
      ) {
        addressCopy.old_addressy_id = angular.copy(address.addressy_id);

        vm.createNewAddressModal.open(null, {
          address: addressCopy,
          defaultValues: getDefaultValues(address.default_values),
          openingStep: 'sender',
        });
      } else {
        address.updating = true;

        const payload = _.pick(addressCopy, [
          'id',
          'is_active',
          'addressy_id',
          'company_name',
          'contact_email',
          'contact_name',
          'contact_phone',
        ]);

        vm.AddressService.updateAddress(payload)
          .then(function (res) {
            if (res.address.usps_pickup_validation_state === 'invalid') {
              launchInvalidPickupAddressModal(res);
            } else {
              _refreshCourierCaches();
              toastSuccess(
                $translate.instant(
                  'toast.update-success',
                  { noun: translations.address },
                  'messageformat'
                )
              );
              address.updating = false;
              vm.showUsBackupForm = false;
            }
          })
          .catch(function (err) {
            address.updating = false;
            if (err.data.status) {
              toastError('toast.update-error', {
                noun: translations.address,
                details: err.data.status,
              });
            } else {
              handleAddressCreationErrors(err.data, address);
            }
          });
      }
    }

    function updateAddressManually(addressCopy, address) {
      vm.addressNotFoundMessage = null;
      // Prevents the function from running when user is in the middle of finding a state with postal code
      // - this situation happens when user enters a valid postal code, then changes it to an invalid one and
      // immediately clicking on add address
      //
      // Note that vm.addressCopy.busy is $scope.address.busy in address-form-usa.directive
      if (vm.addressCopy.busy) return;

      // Need to manually check
      //  - if there is a property called state
      //  - the state input is filled
      //
      // If both is true then return and do nothing - to prevent null being submited
      // (the ng-disable on the input field is preventing ng-pattern working)
      if (vm.addressCopy.country_id === US_ID && !vm.addressCopy.state && vm.showUsBackupForm)
        return;

      // Need to check that the postal code has entered is a real postal code if it is a USA company
      if (UserSession.hasFixedOriginForShipping() && !PostalCodeService.validator.addressUsa)
        return;

      // For the US we need to check if the zip code has changed and if the address is a pickup address
      // in order to show the confirmation modal (recalculating)
      if (
        address.default_values.pickup &&
        UserSession.hasFixedOriginForShipping() &&
        zipCodeHasChanged(addressCopy, address)
      ) {
        if (vm.showUsBackupForm) delete addressCopy.addressy_id;

        vm.createNewAddressModal.open(null, {
          address: addressCopy,
          defaultValues: getDefaultValues(address.default_values),
          openingStep: 'sender',
        });
      } else {
        address.updating = true;

        if (vm.showUsBackupForm) addressCopy.addressy_id = null;

        vm.AddressService.updateAddress(addressCopy)
          .then(function () {
            _refreshCourierCaches();

            MixpanelService.track('Account - Edit Address', {});
            toastSuccess(
              $translate.instant(
                'toast.update-success',
                { noun: translations.address },
                'messageformat'
              )
            );

            address.updating = false;
            vm.closeAccordion = false;
            vm.showUsBackupForm = false;
            _closeAllAddresses();
          })
          .catch(function (err) {
            address.updating = false;
            if (err.data.status) {
              toastError(
                $translate.instant('toast.update-error', {
                  noun: translations.address,
                  details: err.data.status,
                })
              );
            } else {
              handleAddressCreationErrors(err.data, addressCopy);
            }
          });
      }
    }

    function launchInvalidPickupAddressModal(res) {
      if (res.address.old_addressy_id) {
        res.address.old_addressy_id = res.address.addressy_id;
      }

      vm.createNewAddressModal.open(null, { address: res.address, openingStep: 'usps' });
    }

    vm.openSkipStep = function (addressCopy) {
      vm.createNewAddressModal.open(null, { address: addressCopy, openingStep: 'skip' });
    };

    vm.onTogglePrefillSuggestions = function (value) {
      CompanyService.updateDashboardSettings({ show_receiver_address_suggestions: value })
        .then(function () {
          vm.prefillSuggestions = value;
          UserSession.company.dashboard_settings.show_receiver_address_suggestions = value;
          toastSuccess(
            $translate.instant(
              'toast.update-success',
              { noun: translations.settings },
              'messageformat'
            )
          );
        })
        .catch(function () {
          toastError($translate.instant('toast.update-error', { noun: translations.settings }));
        });
    };

    /**
     * [zipCodeHasChanged] compare postal codes of two addresses
     *
     * @param  {Object} addressCopy : address to be compared
     * @param  {Object} address     : address to be compared
     * @return {Boolean}
     */
    // eslint-disable-next-line consistent-return
    function zipCodeHasChanged(addressCopy, address) {
      if (addressCopy && address) {
        return addressCopy.postal_code !== address.postal_code;
      }
    }

    /**
     * [getDefaultValues] Get the name of all the default values that are true
     *
     * @param  {Object}  default_values of an address
     * @return {String}  Single string of comma separated values. Ex: 'pickup' or 'billing, pickup'
     //  */
    function getDefaultValues(default_values) {
      const values = angular.copy(default_values);

      Object.keys(values).forEach(function (key) {
        if (!values[key]) delete values[key];
      });

      return Object.keys(values).join();
    }

    /**
     * [handleAddressCreationErrors] Determine the behavior of the modal according the error message sent by the validation
     * @param  {Object} errorResponse: data of the error sent by the backend
     */
    function handleAddressCreationErrors(errorResponse, address) {
      switch (errorResponse.response_code) {
        case '31':
        case '32':
        case '22':
          if (errorResponse.response.suggested_addresses.length > 0) {
            const correctedAddresses = AddressService.formatCorrectedAddresses(
              errorResponse.response.suggested_addresses
            );

            vm.createNewAddressModal.open(null, {
              address,
              openingStep: 'multi',
              correctedAddresses,
            });
          } else if (Object.keys(errorResponse.response.updated_fields).length > 0) {
            const correctedAddress = AddressService.formatCorrectedAddress(
              errorResponse.response.output_address
            );

            vm.createNewAddressModal.open(null, {
              address,
              openingStep: 'autocorrect',
              correctedAddress,
            });
          }
          break;
        default:
          vm.addressNotFoundMessage = errorResponse.response.message;
          break;
      } // switch
    }

    function _refreshCourierCaches() {
      CourierService.getCouriers();
      CourierAccounts.getActiveCourierAccounts();
    }
  } // ./companyCtrl
})();
