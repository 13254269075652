(function () {
  'use strict';

  angular
    .module('easyshipDashboardApp')
    .controller('SingleShipmentChooseCourierCtrl', SingleShipmentChooseCourierCtrl);

  SingleShipmentChooseCourierCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'UserSession',
    'ShipmentAction',
    'CheckoutService',
    'AddressService',
    'MixpanelService',
  ];

  function SingleShipmentChooseCourierCtrl(
    $scope,
    $state,
    $stateParams,
    UserSession,
    ShipmentAction,
    CheckoutService,
    AddressService,
    MixpanelService
  ) {
    $scope.upsDapCountries = [];
    $scope.originAddressId = '';

    (function init() {
      $scope.prepareData = null;

      if (!$stateParams.shipment) {
        $state.go('receiver-info');
        return;
      }

      if ($stateParams.shipment.price_comparison.rates.length === 0) {
        MixpanelService.track('Single - Destination (Step 3) No Options', {});
      }

      var shipment = $stateParams.shipment;
      $scope.shipment = shipment;

      $scope.isDomestic = false;
      if (shipment && shipment.origin_country_id === shipment.destination_country_id)
        $scope.isDomestic = true;

      $scope.activeModal = null;

      AddressService.getAddresses();
    })();

    $scope.prev = function () {
      $state.go('add-shipment', { shipment: $scope.shipment });
    };

    $scope.next = function () {
      if ($scope.shipment.price_comparison.rates.length === 0) return;

      $scope.busyNext = true;

      if ($scope.shipment.is_valid_to_ship) {
        var processApiParams = {
          company_id: UserSession.company.id,
          company_type: UserSession.company.type,
          single_shipment: true,
        };

        var processPayload = {
          shipment_ids: [$scope.shipment.id],
        };

        CheckoutService.prepareAndSave(processApiParams, processPayload)
          .then(function (data) {
            $scope.prepareData = data;

            $scope.busyNext = false;

            if (data.request_ups_dap_agreement && data.request_ups_dap_agreement.length > 0) {
              $scope.upsDapCountries = data.request_ups_dap_agreement;
              $scope.originAddressId = data.origins[0].sender_address_id;
              $scope.activeModal = 'ups-dap-agreement';
              return;
            }

            if (data.is_using_usps_lightweight && !UserSession.user.hide_usps_lightweight_message) {
              $scope.activeModal = 'ship-ulw-warning-modal';

              return;
            }

            // if (data.courier_handover_options) {
            //   MixpanelService.track('Single - Destination (Step 3) Success', {
            //     courier: data.courier_handover_options[0].courier_name
            //   });
            // }

            $scope.proceedToCheckout();
          })
          .catch(function (err) {
            $scope.busyNext = false;

            MixpanelService.track('Single - Destination (Step 3) Failure', {
              error_status: err.status,
              error_data: err.data,
            });
          });
      } else {
        $scope.busyNext = false;
      }
    };

    $scope.showNextUpsDapAgreeModal = function () {
      $scope.activeModal = null;
      $scope.upsDapCountries.shift();

      if ($scope.upsDapCountries.length > 0) {
        // Let modal slide out and then in on UI
        setTimeout(function () {
          $scope.activeModal = 'ups-dap-agreement';
        }, 0);
      }
    };

    $scope.proceedToCheckout = function () {
      CheckoutService.initCheckout($scope.prepareData, { isSingleShipment: true });
      CheckoutService.addonsSkipped = CheckoutService.skipAddOns();
      var route = CheckoutService.addonsSkipped ? 'order-summary' : 'your-info';

      $state.go(route, {
        fullShipment: $scope.shipment,
      });
    };
  }
})();
