import { IComponentController } from 'angular';
import template from './modals.html?raw';
import { ModalService } from './modals.service';

class BulkCancelModal implements IComponentController {
  static $inject = ['ModalService'];
  constructor(private ModalService: ModalService) {}

  get activeModal(): string | null {
    return this.ModalService.activeModal;
  }
}

const ModalsComponent: ng.IComponentOptions = {
  controller: BulkCancelModal,
  template,
  bindings: {},
};

export { ModalsComponent };
