import { IStoreService } from 'typings/store';
import { IUserSession } from 'typings/user-session';
import { compile } from 'handlebars';
import rawTemplate from '../../../../../../mandrill/email-to-consignee.hbs?raw';

class TrackingEmailPreview {
  esIsUpdateEmail = false;
  esFormData: any;
  mergeVariables = {
    SUBJECT: 'Your Order Will Ship Soon',
    CONSIGNEE_NAME: 'Jane Doe',
    INTRO_TEXT: null,
    ORDER_NUMBER: '4042704763395973',
    ITEM_LIST: '2x Bowl, with chaise, Finnsta dark gray',
    PLURALIZE_ITEM: true,
    ITEMS: [{ DESCRIPTION: 'Bowl, with chaise, Finnsta dark gray', QUANTITY: 2 }],
    COURIER: 'DHL eCommerce',
    TRACKING_NUMBER: '1ZA801990402238744',
    DELIVERY_ADDRESS_1: 'No. 171-177 Hollywood Road',
    DESTINATION_CITY: 'Sheung Wan',
    DESTINATION_COUNTRY: 'Hong Kong',
    TRACKING_URL: 'https://www.trackmyshipment.co/shipment-tracking/ESHK0040989',
    TRACKING_STATUS: 'Out for Delivery',
    TRACKING_MESSAGE:
      'The courier is en route to the delivery address. Delivery may be expected today.',
    TRACKING_STATUS_COLOR: '#4CCACE',
  };

  static $inject = ['$sce', '$element', 'UserSession', 'StoreService'];
  constructor(
    private $sce: ng.ISCEService,
    private $element: ng.IAugmentedJQuery,
    private UserSession: IUserSession,
    private StoreService: IStoreService
  ) {}

  $onChanges() {
    Object.assign(this.mergeVariables, {
      COMPANY_NAME:
        this.esFormData.tracking_email_show_header && this.UserSession.company.display_name,
      COMPANY_LOGO_URL:
        this.esFormData.tracking_email_show_header &&
        this.$sce.getTrustedUrl(this.UserSession.company.logo.asset_url),
      COMPANY_FOOTNOTE: this.esFormData.tracking_email_footnote,
      COMPANY_CS_WEBSITE:
        this.esFormData.tracking_email_show_contact &&
        this.$sce.getTrustedUrl(this.UserSession.company.cs_url),
      COMPANY_FACEBOOK_URL:
        this.esFormData.tracking_email_show_contact &&
        this.$sce.getTrustedUrl(this.UserSession.company.facebook_url),
      COMPANY_TWITTER_URL:
        this.esFormData.tracking_email_show_contact &&
        this.$sce.getTrustedUrl(this.UserSession.company.twitter_url),
      COMPANY_INSTAGRAM_URL:
        this.esFormData.tracking_email_show_contact &&
        this.$sce.getTrustedUrl(this.UserSession.company.instagram_url),
      COMPANY_YOUTUBE_URL:
        this.esFormData.tracking_email_show_contact &&
        this.$sce.getTrustedUrl(this.UserSession.company.youtube_url),
      BTN_BACKGROUND_COLOR: this.esFormData.tracking_email_btn_background_color,
      BTN_TEXT_COLOR: this.esFormData.tracking_email_btn_text_color,
      DEFAULT_BTN_BACKGROUND_COLOR:
        this.esFormData.tracking_email_btn_background_color === '#50D4A4',
      TRACKING_EMAIL_AD_IMAGE_URL: this.$sce.getTrustedUrl(
        this.UserSession.company.tracking_email_ad.asset_url
      ),
      TRACKING_EMAIL_AD_LINK_URL: this.$sce.getTrustedUrl(
        this.esFormData.tracking_email_advertising?.link_url
      ), // not included in UserSession.company
      TRACKING_EMAIL_AD_CALL_TO_ACTION: this.esFormData.tracking_email_advertising?.call_to_action, // not included in UserSession.company

      ESTIMATED_DDU_PAYMENT: this.esFormData.tracking_email_show_import_tax_and_duty && '$15.56',
      INSURANCE_COVERAGE: this.StoreService.insuranceAtCheckoutEnabled && 'HKD50.81',
      CLAIM_URL:
        this.StoreService.insuranceAtCheckoutEnabled &&
        'https://claims.easyship.com?track=1ZA801990402238744&email=consignee@gmail.com',
      IS_TRACKING_UPDATE: this.esIsUpdateEmail,
      IS_TRACKING_EMAIL: !this.esIsUpdateEmail,
    });

    const template = compile(rawTemplate);
    this.$element.html(template(this.mergeVariables));
  }
}

const TrackingEmailPreviewComponent = {
  controller: TrackingEmailPreview,
  bindings: {
    esFormData: '<',
    esIsUpdateEmail: '<',
  },
};

export { TrackingEmailPreviewComponent };
