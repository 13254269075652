import React, { useState, useCallback, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import Dropzone, { IUploadParams, IFileWithMeta, IInputProps } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import ImageList from '@material-ui/core/ImageList';
import Typography from '@material-ui/core/Typography';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import { IClaimPhoto } from 'typings/insurance';
import { IUserSession } from 'typings/user-session';

import useStyles from './useStyles';
import { getFilesFromEvent, handleChangeStatus, handleSubmit } from './eventHandlers';
import UploadedPhoto from './UploadedPhoto';
import ReactRootProviders from '../../context/ReactRootProviders';

export interface UploaderProps {
  endpoint?: string;
  headers?: any;
  photos?: IClaimPhoto[];
  onSelection?: (files: File[]) => void;
  onRemove?: (previewIndex: number) => void;
  onResponse?: (photo: IClaimPhoto) => void;
  onError?: (error: string) => void;
  $injector: angular.auto.IInjectorService;
}

export default function Uploader({
  endpoint,
  photos,
  headers,
  onSelection,
  onRemove,
  onResponse,
  onError,
  $injector,
}: UploaderProps) {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [previews, setPreviews] = useState<string[]>(photos?.map(({ url }) => url) || []);
  const UserSession = $injector.get('UserSession') as IUserSession;

  const getUploadParams = ({ meta }: IFileWithMeta) => {
    return {
      url: endpoint,
      headers: { ...headers, Authorization: `Bearer ${UserSession.session_token}` },
      type: meta.type,
    } as IUploadParams;
  };

  const handleRemove = (previewIndex: number) => {
    const cloned = previews.slice();

    cloned.splice(previewIndex, 1);
    setPreviews(cloned);
    if (onRemove) onRemove(previewIndex);
  };

  const ulRef = useCallback((node) => {
    if (node !== null) node.scrollLeft = node.scrollWidth;
  }, []);

  function FileInputField({ accept, onFiles, files, getFilesFromEvent }: IInputProps) {
    const paddingClass = !previews?.length
      ? ''
      : previews.length > 1
      ? classes.withPhotos
      : classes.withOnePhoto;

    return (
      <>
        <label className={`${classes.label} ${paddingClass}`}>
          <AddAPhotoIcon className={classes.icon} />
          <Typography variant="button" display="block">
            {uploading ? (
              <FormattedMessage id="uploader.busy" />
            ) : files.length > 0 ? (
              <FormattedMessage id="uploader.more" />
            ) : (
              <FormattedMessage id="uploader.upload" />
            )}
          </Typography>
          <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            multiple
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              getFilesFromEvent(e).then((chosenFiles: File[]) => {
                onFiles(chosenFiles);
                if (onSelection) onSelection(chosenFiles);
                return chosenFiles;
              });
            }}
            disabled={uploading}
          />
        </label>
        <div className={classes.carousel}>
          <ImageList className={classes.imageList} cols={2.5} ref={ulRef}>
            {previews.map((url: string, index) => (
              <UploadedPhoto
                url={url}
                onRemove={() => handleRemove(index)}
                disabled={uploading}
                key={url}
              />
            ))}
          </ImageList>
        </div>
      </>
    );
  }

  return (
    <Dropzone
      classNames={classes}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus(
        previews,
        setPreviews,
        setUploading,
        handleRemove,
        onResponse,
        onError
      )}
      onSubmit={handleSubmit}
      InputComponent={FileInputField}
      getFilesFromEvent={getFilesFromEvent}
    />
  );
}

export function WrappedUploader(props: UploaderProps) {
  return (
    <ReactRootProviders>
      <Uploader {...props} />
    </ReactRootProviders>
  );
}
