import angular from 'angular';

import { BulkCancelService } from './bulk-cancel.service';

const MODULE_NAME = 'app.global.services.bulk-cancel';

angular
  .module(MODULE_NAME, [])
  .service('BulkCancelService', BulkCancelService);

export default MODULE_NAME;
