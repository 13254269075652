/**
 * @see https://account.loqate.com/account
 * @see https://www.loqate.com/resources/support/apis/capture/
 * @see https://www.loqate.com/resources/support/apis/Capture/Interactive/Find/1.1/
 *
 * Capture Interactive Find (v1.1)
 * Uses a text search to find addresses and places. Note this does not return formatted addresses,
 * and repeated calls to this service may be required to drill-down through results until an addressID is returned.
 * The address ID should then be passed into the Retrieve service to obtain a fully formatted address.
 *
 * The Easyship BE makes a request to Loqate's Retrieve service, using the ID provided by the FE
 * Generally, API keys are active on production, but disabled on other environments until the service needs testing.
 */

(function () {
  'use strict';

  AddressyService.$inject = ['$q', '$http', 'ApiKeys', 'MessagingService', 'UserSession'];
  function AddressyService($q, $http, ApiKeys, MessagingService, UserSession) {
    var service = this;

    service.getAddressyData = function (addressField, id) {
      var sanitizedId = id ? id : '';
      var url =
        'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3.ws?Key=' +
        ApiKeys.addressy_key +
        '&Text=' +
        addressField +
        '&Container=' +
        sanitizedId +
        '&Origin=US&Countries=US&Limit=10&Language=en-us';

      return $q(function (resolve, reject) {
        $http.get(url).then(
          function (res) {
            var newAddresses = sanitizeAddresses(res.data.Items);
            resolve(newAddresses);
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    /**
     * [sanitizeAddresses] Creates a new array of addresses with inacurate duplicates
     *                     removed and each address formatted so that it's usable for
     *                     display
     * @param  {Array} arr [The raw list of addresses]
     * @return {Array}     [The sanitized list of addresses]
     */
    function sanitizeAddresses(arr) {
      var counts = {};
      var sanitizedAddresses = [];

      // If user out of credit, Addressy does not return an error - we need to manually check the success response to see if its this error
      if (arr.length === 1 && arr[0].Error === '3') {
        constructMessangeAndSend(arr[0]);
      } else if (arr.length > 0) {
        arr.forEach(function (elem) {
          var updatedElem = addNewPropertiesToElement(elem);

          if (!counts[updatedElem.Comparison]) {
            counts[updatedElem.Comparison] = 1;
            sanitizedAddresses.push(updatedElem);
          } else {
            counts[updatedElem.Comparison] += 1;

            var index = sanitizedAddresses.findIndex(function (addressElement) {
              return addressElement.Comparison === updatedElem.Comparison;
            });

            if (updatedElem.Type === 'BuildingNumber') {
              sanitizedAddresses[index] = updatedElem;

              // Logic of if statement below is to ensure that the duplicated addresses
              // are both type Address and if so then add the copy to the array
            } else if (sanitizedAddresses[index].Type === 'Address') {
              sanitizedAddresses.push(updatedElem);
            }
          }
        });

        return sanitizedAddresses;
      } else {
        return arr;
      }
    }

    /**
     * [addNewPropertiesToElement] Add's new properties to each object passed through,
     *                             an BuiltAddress property for display and a comparison
     *                             property for comparing and removing duplicated addresses
     * @param {Object} elem
     */
    function addNewPropertiesToElement(elem) {
      if (elem.Error === '1005') {
        return elem;
      } else {
        var updatedElem = buildComparisonProperty(elem);

        if (updatedElem.Type === 'BuildingNumber') {
          var descriptionArray = updatedElem.Description.split(' - ');

          updatedElem.Description = descriptionArray[0];
          updatedElem.SumOfAddresses = descriptionArray[1];
        }

        updatedElem.BuiltAddress = _.filter([updatedElem.Text, updatedElem.Description]).join(' ');
        // updatedElem.BuiltAddress = highlightString(_.filter([updatedElem.Text, updatedElem.Description]).join(' '), updatedElem.Highlight);

        return updatedElem;
      }
    }

    /**
     * [buildComparisonProperty] Creates a new property called Comparison which is the Text
     *                           property with abbreviation (Suite, Avenue, Flat etc.) removed
     * @param  {Objext} elem
     * @return {Object}
     */
    function buildComparisonProperty(elem) {
      var comp = angular.copy(elem.Text);

      elem.Comparison = comp.replace(/ Apt| Ste| Ave| Fl| Av/g, '');
      return elem;
    }

    function highlightString(string, mark) {
      var regex = /\d+/g;
      var boldIndex = mark.match(regex);
      var stringArr = string.split('');

      boldIndex.forEach(function (elem, index) {
        var position = Number(elem) === 0 ? Number(elem) : Number(elem) + 1;

        if (index % 2) {
          // console.log('odd', elem);
          stringArr.splice(position, 0, '</b>');
        } else {
          // console.log('even', elem);
          stringArr.splice(position, 0, '<b>');
        }
      });

      return stringArr.join('');
    }

    function constructMessangeAndSend(elem) {
      var message = {
        company_id: UserSession.company ? UserSession.company.id : null,
        name: 'Addressy Service',
        message: elem.Description + ' - ' + elem.Cause,
      };

      sendEmail(message);
    }

    function sendEmail(message) {
      MessagingService.sendSlackAlert(message);
    }
  }

  angular.module('app.service.AddressyService', []).service('AddressyService', AddressyService);
})();
