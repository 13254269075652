export interface IPlan {
  id: number;
  ranking: number;
  tier: number;
  name: string;
  version: string;
  rate: number;
  taxName: string;
  isTaxed: boolean;
  totalAmount: number;
  isCustom: boolean;
  isCurrent: boolean;
  isUpgrade: boolean;
  action: ButtonTranslationKey;
}

export enum ButtonTranslationKey {
  Downgrade = 'subscription.buttons.downgrade',
  Current = 'subscription.buttons.current',
  Upgrade = 'subscription.buttons.upgrade',
  Change = 'subscription.buttons.change',
  LetsTalk = 'subscription.buttons.lets-talk',
  Continue = 'subscription.buttons.continue',
  TryForFree = 'subscription.buttons.try-for-free',
  EndTrial = 'subscription.buttons.end-trial',
}

export type ICategorizedPlan = Record<'standardPlans' | 'scalePlans', IPlan[]>;
