import { IApiConfig } from 'typings/core/config/api';
import { IShipmentListItem } from 'typings/shipment';
import { IInsuredState, IClaimFeedback } from 'typings/insurance';
import { NotInsured, NotFiled, ClaimStateKey } from '@client/data/insurance';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './claim-cta.html?raw';
import identifyInsuredState from './identifyInsuredState';
import identifyClaimFeedback from './identifyClaimFeedback';

class ShipmentInfoClaimCta implements IComponentController {
  baseUrl: string;
  esShipment: IShipmentListItem | undefined;
  insuredState: IInsuredState = NotInsured;
  claimFeedback: IClaimFeedback = NotFiled;
  showClaimForm = false;
  showClaimStatus = false;

  static $inject = ['API', 'MixpanelService', '$window'];
  constructor(
    API: IApiConfig,
    private MixpanelService: MixpanelService,
    private $window: ng.IWindowService
  ) {
    this.baseUrl = API.help;
  }

  $onInit(): void {
    if (!this.esShipment) return;

    this.insuredState = identifyInsuredState(this.esShipment, this.baseUrl);
    this.claimFeedback = identifyClaimFeedback(this.esShipment);

    this.showClaimForm =
      this.insuredState.insured &&
      !this.claimFeedback.type &&
      this.claimFeedback.state !== ClaimStateKey.NotShipped;
    this.showClaimStatus = this.insuredState.insured && !!this.claimFeedback.type;
  }

  goToInsuranceClaim(): void {
    if (!this.esShipment) return;

    this.MixpanelService.track('Show Shipments - Details - Click Claim Insurance', {
      easyship_shipment_id: this.esShipment.easyship_shipment_id,
    });

    this.$window.open(this.insuredState.helpUrl, '_blank');
  }
}

const ShipmentInfoClaimCtaComponent: ng.IComponentOptions = {
  controller: ShipmentInfoClaimCta,
  template,
  bindings: { esShipment: '<' },
};

export { ShipmentInfoClaimCtaComponent };
