/**
 * Manage shipments has only one state but different 'sections' that act as filters
 * This ensures that while navigating within the 'app.shipments' state, the sectionName (given by the URL) and the scope query are always matching
 * -> It can happen that they do not match if the user press Back or Forward on their browser and the target state is still 'app.shipments'
 *
 * TODO: refactor the 'app.shipments' pages to have individual nested state for easier management
 *
 * @param {transition} transition: instance from ui router
 * @param {paramsTo} paramsTo: params passed to the destination state
 * @param {HelperService} HelperService app/services/HelperService
 */
function routingFailsafeManageShipments(transition, paramsTo, HelperService) {
  const targetScope = HelperService.kebabToSnakeCase(paramsTo.sectionName);

  // If the query exists and targeted scope does not match it, override the transition and replace the scope query by the targetScope (from the sectionName in the URL)
  if (paramsTo.query && paramsTo.query.scope && targetScope !== paramsTo.query.scope) {
    return transition.router.stateService.target(
      'app.shipments',
      { sectionName: paramsTo.sectionName, query: { scope: targetScope }, page: '1', reload: true },
      { reload: true }
    );
  }
}

export { routingFailsafeManageShipments };
