// @see https://tc39.es/proposal-intl-datetime-style/

import angular from 'angular';
import { IUserSession } from 'typings/user-session';

const MODULE_NAME = 'app.filter.intlDate';
export type IntlDateFilter = (
  isoDateString: string | null,
  formatOptions: Intl.DateTimeFormatOptions
) => string;

intlDate.$inject = ['UserSession'];
function intlDate(UserSession: IUserSession): IntlDateFilter {
  return (isoDateString, formatOptions = { dateStyle: 'medium' }): string => {
    if (!isoDateString) return '-';

    const date = new Date(isoDateString);

    if (Intl && 'DateTimeFormat' in Intl) {
      const formatter = new Intl.DateTimeFormat(
        UserSession.user.dashboard_settings?.language?.code || 'en',
        { timeZone: 'UTC', ...formatOptions }
      );

      return formatter.format(date);
    }

    // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_format
    return date.toISOString();
  };
}

angular.module(MODULE_NAME, ['app.factory.UserSession']).filter('intlDate', intlDate);

export default MODULE_NAME;
