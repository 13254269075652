import React from 'react';
import { react2angular } from 'react2angular';
import { FormattedMessage } from 'react-intl';
import { Button } from 'easyship-components';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import shippingRulesAutomationsUrl from '@assets/images/dashboard/shipping-rules/shipping-rules-automations.svg';

interface IShippingRulesBenefitsProps {
  areShippingRulesAccessible: boolean;
  openUpgradeModalIfFeatureAccessible: () => void;
}

function ShippingRulesBenefits({
  areShippingRulesAccessible,
  openUpgradeModalIfFeatureAccessible,
}: IShippingRulesBenefitsProps) {
  return (
    <div className="flex flex-col justify-center items-center w-[488px] min-h-[516px] box-border text-ink-900">
      <img className="mb-5" alt="Shipping rules automation" src={shippingRulesAutomationsUrl} />

      <div className="text-xl font-bold mb-5 ">
        <FormattedMessage id="automations.no-rules.shipping-rules-benefits-header" />
      </div>

      <div className="text-center">
        <FormattedMessage id="automations.no-rules.shipping-rules-benefits-description" />{' '}
        {!areShippingRulesAccessible && (
          <FormattedMessage id="automations.no-rules.shipping-rules-benefits-description-no-access" />
        )}
      </div>

      {!areShippingRulesAccessible && (
        <Button color="primary" className="mt-5" onClick={openUpgradeModalIfFeatureAccessible}>
          <FormattedMessage id="automations.no-rules.shipping-rules-benefits-upgrade" />
        </Button>
      )}
    </div>
  );
}

function WrappedShippingRulesBenefits(props: IShippingRulesBenefitsProps) {
  return (
    <ReactRootProviders>
      <ShippingRulesBenefits {...props} />
    </ReactRootProviders>
  );
}

export const AngularShippingRulesBenefits = react2angular(WrappedShippingRulesBenefits, [
  'areShippingRulesAccessible',
  'openUpgradeModalIfFeatureAccessible',
]);
