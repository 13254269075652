import angular from 'angular';
import { react2angular } from 'react2angular';

import EsSearchBar from './EsSearchBar';

const EsSearchBarR2AComponent = react2angular(
  EsSearchBar,
  [
    'esId',
    'esKeyword',
    'esPlaceholder',
    'esFilter',
    'esFilterQuery',
    'esIsFiltered',
    'onFilterClick',
    'onChange',
    'onSubmit',

    'ngFilterClick',
    'esChange',
    'esSubmit',
  ],
  ['$injector', '$scope']
);

const MODULE_NAME = 'app.global.dashboard-component-library.search-bar';
angular.module(MODULE_NAME, []).component('esSearchBar', EsSearchBarR2AComponent);

export default MODULE_NAME;
export { EsSearchBar, EsSearchBarR2AComponent };
