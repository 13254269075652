import '@client/app/styles/toastify.scss';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import parse from 'html-react-parser';
import {
  ToastContainer,
  ToastContainerProps,
  cssTransition,
  toast,
  ToastOptions,
} from 'react-toastify';
import { Check as CheckIcon, Exclaim as ExclaimIcon } from 'easyship-components/icons';
import { createPortal } from 'react-dom';

const animation = cssTransition({
  enter: 'easyship-animation-in',
  exit: 'easyship-animation-out',
});

const toasterConfig: ToastContainerProps = {
  position: 'bottom-center',
  autoClose: 0,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: false,
  theme: 'light',
  transition: animation,
  closeButton: false,
  style: { zIndex: 999999 },
};

export function Toastify() {
  const hasToastContainer = !!document.querySelector('.Toastify');

  if (hasToastContainer) {
    return null;
  }

  return createPortal(<ToastContainer {...toasterConfig} />, document.body);
}

/**
 * Toast without Actions
 * Automatically dismissed after a 3,000 ms time delay. (autoClose: 3000)
 * Also manually dismissible if the user clicks on it. (closeOnClick: true)
 */
const DEFAULT_TOAST_WITHOUT_ACTION_CONFIG = {
  closeButton: false,
  closeOnClick: true,
  autoClose: 3000,
};

/** Displays a success toast notification with a pre-configured success icon and default configurations */
export function toastSuccess(message: string | undefined, params?: ToastOptions) {
  if (!message) {
    return '';
  }

  return toast(message, {
    icon: () => <CheckIcon fontSize="small" />,
    ...DEFAULT_TOAST_WITHOUT_ACTION_CONFIG,
    ...params,
  });
}

/** Displays an error toast notification with a pre-configured warning icon and default configurations. */
export function toastError(message: string | undefined, params?: ToastOptions) {
  if (!message) {
    return '';
  }

  return toast(<>{parse(message)}</>, {
    icon: () => <ExclaimIcon fontSize="small" />,
    ...DEFAULT_TOAST_WITHOUT_ACTION_CONFIG,
    ...params,
  });
}

/** Displays a toast notification without any icon using the default configurations. */
export function toastMessage(message: string | undefined, params?: ToastOptions) {
  if (!message) {
    return '';
  }

  return toast(message, {
    ...DEFAULT_TOAST_WITHOUT_ACTION_CONFIG,
    ...params,
  });
}
