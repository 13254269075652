import angular from 'angular';

/**
 * This id should match the script tag used to load Stripe which currently lives in client/index.tpl.html
 */
const STRIPE_SCRIPT_ID = 'script__stripejs-v3';

class StripeService {
  static $inject = ['$window', '$q'];

  constructor($window, $q) {
    this.$window = $window;
    this.$q = $q;
    this.reset();
  }

  getInstance() {
    return this._stripeInstance;
  }

  instantiate(stripePublishableKey) {
    if (this._stripeInstantiated) {
      return;
    }

    if (this.$window.Stripe) {
      this._instantiateStripe(stripePublishableKey);
    } else {
      angular.element(`#${STRIPE_SCRIPT_ID}`).on('load', () => {
        this._instantiateStripe(stripePublishableKey);
      });
    }
  }

  reset() {
    this._stripeInstantiated = false;
    this._stripeInstance = this.$q((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  _instantiateStripe(stripePublishableKey) {
    this._stripeInstantiated = true;
    return this._resolve(this.$window.Stripe(stripePublishableKey));
  }
}

export { StripeService };
