import { IHelperService } from 'typings/helper';
import { IPaymentService } from 'typings/payment-service';
import { ICreditCardData } from 'typings/payment-source';
import { IFeature, IPlan, IPlansDetail, ISubscriptionObject } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import { IApiConfig } from 'typings/core/config/api';
import { PaymentFormId } from '@client/data/payment-form-id';

import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import { BillingFrequency, BillingInterval, SubscriptionPeriod } from '@client/data/subscription';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { SubscriptionPaymentService } from '@client/src/global/services/subscription/subscription-payment.service';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { toastError } from '@client/core/components/react/Toastify';
import template from './upgrade-plan-card.html?raw';
import style from './upgrade-plan-card.module.scss';

const DEFAULT_TOGGLE_STATE = false;

enum DefaultData {
  Title = 'subscription.upgrade-modal.header',
  TitleForTrial = 'subscription.upgrade-modal.header-try-for-free',
  ContinueTo = 'subscription.upgrade-modal.header-continue',
  Label = 'subscription.compare.annual-pitch',
  LearnMore = 'global.learn-more',
  ToggleOn = 'subscription.compare.toggle-monthly',
  ToogleOff = 'subscription.compare.toggle-annually',
  LeftButton = 'subscription.buttons.more-plans',
}

class UpgradePlanCard implements IComponentController {
  style = style;
  esSelectedPlanId = 1;
  esLeftButtonText = '';
  esRightButtonText = '';
  esTitle = '';
  esLabel = '';
  esLearnMore = '';
  esToggleOn = '';
  esToogleOff = '';
  esToggleState = false;
  esBillingPlan = '';
  esLearnMoreUrl = '#';
  esTriggerSource?: string;
  selectedPlan: IPlan | null = null;
  selectedFeatures: IFeature[] = [];
  plansDetail: IPlansDetail | null = null;
  paymentState = true;
  defaultCard: ICreditCardData | null = null;
  busyPaying = false;
  creditCardErrorCode = '';
  paymentFormId = PaymentFormId.UpgradePlan;

  static $inject = [
    '$window',
    '$location',
    '$translate',
    '$timeout',
    'SubscriptionService',
    'PaymentSourceService',
    'UserSession',
    'HelperService',
    'PaymentService',
    'SubscriptionPaymentService',
    'API',
    'MixpanelService',
  ];
  constructor(
    private $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private $translate: angular.translate.ITranslateService,
    private $timeout: ng.ITimeoutService,
    public SubscriptionService: SubscriptionService,
    private PaymentSourceService: PaymentSourceService,
    private UserSession: IUserSession,
    private HelperService: IHelperService,
    private PaymentService: IPaymentService,
    private SubscriptionPaymentService: SubscriptionPaymentService,
    private API: IApiConfig,
    private MixpanelService: MixpanelService
  ) {}

  esToggleAction(_state: { value: boolean }): void {
    // esToggleAction expression bindings, need to add this in order for typescript to successfully compile
  }

  esRightButtonAction(): void {
    // esRightAction expression bindings, need to add this in order for typescript to successfully compile
  }

  $onInit(): void {
    if (this.esSelectedPlanId === undefined) {
      throw new Error(
        'Easyship Subscription Upgrade Plan Card: An es-selected-plan-id binding must be provided.'
      );
    }

    if (!this.esLeftButtonText) {
      this.esLeftButtonText = this.$translate.instant(DefaultData.LeftButton);
    }

    if (this.esToggleState === undefined) {
      this.esToggleState = DEFAULT_TOGGLE_STATE;
    }

    if (!this.esLabel) {
      this.esLabel = this.$translate.instant(DefaultData.Label);
    }

    if (!this.esLearnMore) {
      this.esLearnMore = this.$translate.instant(DefaultData.LearnMore);
    }

    if (!this.esToggleOn) {
      this.esToggleOn = this.$translate.instant(DefaultData.ToggleOn);
    }

    if (!this.esToogleOff) {
      this.esToogleOff = this.$translate.instant(DefaultData.ToogleOff);
    }

    if (this.esBillingPlan === undefined) {
      this.esBillingPlan = BillingInterval.Month;
    }

    if (!this.esLearnMoreUrl) {
      this.esLearnMoreUrl = `${this.API.help}/hc/en-us/articles/360035582872-Easyship-Pricing-Plans-Breakdown`;
    }

    if (this.UserSession) {
      const { company } = this.UserSession;
      const { plansDetail } = this.SubscriptionService;

      if (!plansDetail) {
        this._fetchPlansDetail(company.country_id);
      } else {
        this.plansDetail = plansDetail;
        if (this.plansDetail.plans) {
          this._assignSelectedPlan(this.plansDetail.plans);
        }

        this._assignSelectedFeatures(this.plansDetail.features);
      }

      if (!this.SubscriptionService.currentSubscription) {
        this.SubscriptionService.fetchCurrentSubscription({ company_id: company.id }).then(() => {
          this._fetchCards();
        });
      } else {
        this._fetchCards();
      }

      this.PaymentService.unregisterPaymentForm(PaymentFormId.UpgradePlan);
    }
    if (this.SubscriptionService.triggerSource) {
      this.esTriggerSource = this.SubscriptionService.triggerSource;
    }

    if (!this.esTitle) {
      let title;

      switch (this.SubscriptionService.currentSubscriptionPeriod) {
        case SubscriptionPeriod.TRIAL:
          title = DefaultData.TitleForTrial;
          break;
        case SubscriptionPeriod.END_OF_TRIAL:
          title = DefaultData.ContinueTo;
          break;
        default:
          title = DefaultData.Title;
      }

      this.esTitle = this.$translate.instant(title, {
        plan: this.selectedPlanName,
      });
    }

    this.MixpanelService.track('Subscription - Payment - Open', {
      trigger_source: this.esTriggerSource,
    });
  }

  get togglePlan(): string {
    return this.esToggleState ? BillingInterval.Year : BillingInterval.Month;
  }

  get unitPlan(): BillingFrequency {
    return this.esToggleState ? BillingFrequency.Year : BillingFrequency.Month;
  }

  get rightButtonText(): string {
    if (this.esRightButtonText) {
      return this.esRightButtonText;
    }

    if (
      this.selectedPlan &&
      this.SubscriptionService.currentSubscription &&
      this.SubscriptionService.freeTrialAmount &&
      this.SubscriptionService.freeTrialAmount > 0
    ) {
      return this.$translate.instant('subscription.buttons.try-free', {
        plan: this.selectedPlanName,
        period: this.SubscriptionService.currentSubscription.trial_period_display_text,
      });
    }

    if (this.selectedPlan) {
      const action = this.$translate.instant(
        this.isCurrentSelectedPlan ? DefaultData.ContinueTo : DefaultData.Title
      );
      return `${action} ${this.selectedPlanName}`;
    }

    return this.$translate.instant('subscription.buttons.upgrade');
  }

  get isMaxShipments(): boolean {
    return this.SubscriptionService.isMaxShipments;
  }

  get isFreePlan(): boolean {
    return this.SubscriptionService.isFreePlan;
  }

  get freeTrialAmount(): number {
    return this.SubscriptionService.freeTrialAmount;
  }

  get isFreeTrialAvailable(): boolean {
    return this.freeTrialAmount > 0;
  }

  get remainingDaysBeforeTrialEnd(): string {
    return this.SubscriptionService.remainingDaysBeforeTrialEnd;
  }

  get defaultCardBrand(): string {
    if (this.defaultCard && this.defaultCard.brand) {
      return this.defaultCard.brand.toUpperCase();
    }

    return '';
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get isCurrentSelectedPlan(): boolean {
    if (this.currentSubscription && this.currentSubscription.plan) {
      return this.esSelectedPlanId === this.currentSubscription.plan.id;
    }

    return false;
  }

  get selectedPlanName(): string {
    return this.SubscriptionService.getPlanNameByPlanSlug(this.selectedPlan?.slug);
  }

  _assignSelectedPlan(plans: IPlan[]): void {
    this.selectedPlan = this.HelperService.returnObjectByColumn<IPlan>(
      plans,
      'id',
      this.esSelectedPlanId
    );
  }

  _assignSelectedFeatures(features: IFeature[]): void {
    this.selectedFeatures = this.SubscriptionService.returnAvailableFeatures(
      this.esSelectedPlanId,
      features
    );
  }

  clearCardError() {
    this.creditCardErrorCode = '';
  }

  toggleAction(value: boolean): void {
    this.esToggleState = value;
    this.esToggleAction({ value });

    this.MixpanelService.track('Subscription - Payment - Period Toggle');
  }

  _fetchPlansDetail(id: number): void {
    this.SubscriptionService.fetchPlansDetail({
      country_id: id,
    })
      .then((data: IPlansDetail) => {
        if (data) {
          this.plansDetail = data;
          if (this.plansDetail.plans) {
            this._assignSelectedPlan(this.plansDetail.plans);
          }

          this._assignSelectedFeatures(this.plansDetail.features);
        }
      })
      .catch((error) => {
        if (error && error.message) {
          toastError(error.message);
        }
        throw error;
      });
  }

  _handlePaymentErrors(err: Error): void {
    this.busyPaying = false;
    const [errorCode] = transformToErrorCodes(err);
    this.creditCardErrorCode = errorCode || 'unknown';
    toastError(this.$translate.instant('account.payment.notifications.stripe-error'));
  }

  async onCardFormSubmit(): Promise<void> {
    if (this.busyPaying) {
      return;
    }

    this.busyPaying = true;
    this.creditCardErrorCode = '';

    this.PaymentService.submitPaymentForm(PaymentFormId.UpgradePlan)
      .then((paymentFormResult) => {
        const companyId = this.UserSession.getCompanyId();
        if (!companyId) throw new Error('Missing company ID');
        if (!paymentFormResult) throw new Error('Missing payment result');

        this.SubscriptionPaymentService.paymentFormResultAction(
          paymentFormResult,
          companyId,
          this.esSelectedPlanId,
          this.unitPlan
        )
          .then(() => {
            this.busyPaying = false;
            this.esRightButtonAction();
            this.refreshPage();
          })
          .catch((err) => this._handlePaymentErrors(err));
      })
      .catch((err) => this._handlePaymentErrors(err));
  }

  _fetchCards(): void {
    this.PaymentSourceService.getCreditCards()
      .then(() => {
        if (this.PaymentSourceService.creditCards.length <= 0) {
          this.paymentState = false;
        } else {
          if (this.SubscriptionService.currentSubscription) {
            this.defaultCard = this.SubscriptionService.findDefaultSubscriptionCard(
              this.PaymentSourceService.creditCards
            );
          } else {
            this.defaultCard = this.SubscriptionService.findDefaultCard(
              this.PaymentSourceService.creditCards
            );
          }

          if (!this.defaultCard) {
            this.paymentState = false;
          } else {
            this.PaymentSourceService.selectCard(this.defaultCard);
          }
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  get canUseTeamRightsAndPermission(): boolean {
    return !!this.UserSession.getCompanyDashboardSettings()
      .beta_feature_team_rights_and_permissions;
  }

  async upgradeAction(card?: ICreditCardData): Promise<void> {
    const { selectedCard = card } = this.PaymentSourceService;
    const isUsingNewCard = !selectedCard;
    const hasAvailableCard = Boolean(this.paymentState || selectedCard);

    if (
      this.canUseTeamRightsAndPermission &&
      !this.UserSession.hasUserRole('subscription_and_billing')
    ) {
      toastError(this.$translate.instant('global.permission-disabled'));
      this.busyPaying = false;
      return;
    }

    if (!this.isFreeTrialAvailable && (isUsingNewCard || !hasAvailableCard)) {
      this.onCardFormSubmit();
      this.MixpanelService.track('Subscription - Payment - Submit');
      return;
    }

    this.busyPaying = true;
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      toastError(this.$translate.instant('toast.default-error'));
      this.busyPaying = false;
      return;
    }

    try {
      const cardId = !this.isFreeTrialAvailable && selectedCard ? selectedCard.id : null;

      await this.SubscriptionService.actionChangePlan(
        cardId,
        companyId,
        this.esSelectedPlanId,
        this.unitPlan
      );
      this.esRightButtonAction();
      this.refreshPage();
    } catch (error) {
      this._handlePaymentErrors(error);
    } finally {
      this.$timeout(() => {
        this.busyPaying = false;
      });
    }

    this.MixpanelService.track('Subscription - Payment - Submit');
  }

  // Refresh page to ensure that dashboard features match the new plan
  private refreshPage() {
    if (this.selectedPlan) {
      this.$window.location.href = `${this.$location.path()}?upgrade_plan=${this.selectedPlanName}`;
    }
  }
}

const UpgradePlanCardComponent: ng.IComponentOptions = {
  controller: UpgradePlanCard,
  template,
  bindings: {
    esSelectedPlanId: '<',
    esCloseTitleAction: '&',
    esToggleState: '=?',
    esLeftButtonAction: '&',
    esRightButtonAction: '&',
    esTriggerSource: '<',
  },
};

export { UpgradePlanCardComponent };
