import { toastError } from '@client/core/components/react/Toastify';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import template from './pay-return.html?raw';
import style from './pay-return.module.scss';

class ReturnModalPayReturn {
  static $inject = [
    '$filter',
    'UserSession',
    'ReturnService',
    'CompanyService',
    'MixpanelService',
    'PaymentService',
    'UserStatusService',
    'PaymentSourceService',
    '$rootScope',
    'UserRightsService',
  ];

  constructor(
    $filter,
    UserSession,
    ReturnService,
    CompanyService,
    MixpanelService,
    PaymentService,
    UserStatusService,
    PaymentSourceService,
    $rootScope,
    UserRightsService
  ) {
    this.style = style;
    this.$filter = $filter;
    this.UserSession = UserSession;
    this.ReturnService = ReturnService;
    this.CompanyService = CompanyService;
    this.MixpanelService = MixpanelService;
    this.PaymentService = PaymentService;
    this.UserStatusService = UserStatusService;
    this.PaymentSourceService = PaymentSourceService;
    this.$rootScope = $rootScope;
    this.UserRightsService = UserRightsService;

    this.busy = {};
    this.error = '';
    this.creditCardErrorCode = '';

    this.data = {};
    this.payload = {};
    this.courier = {};
    this.totalCharge = 0;
    this.sufficientFunds = false;
    this.charge = '';
    this.paymentFormId = 'fix-monkey-payment-form';
  }

  $onInit() {
    this.currency = this.UserSession.getCompanyCurrency();
    this.data = this.ReturnService.paymentData;
    this.payload = this.ReturnService.payload;

    this.courier = this.data.courier;
    this.totalCharge = this.data.courier.totals.total_charge;
    this.totalToTopUp = this.data.totals.total_to_top_up;
    this.sufficientFunds =
      this.data.totals.total_to_top_up === 0 || this.UserSession.company.is_authorized_overdraft;
    this.paymentRecipient = this._getPaymentRecipient();

    this.$rootScope.$on('payment-failed', () => {
      this.busy.pay = false;
    });
  }

  // eslint-disable-next-line consistent-return
  _getPaymentRecipient() {
    const { total_pay_at_easyship, total_pay_at_easyship_on_scan, total_pay_at_courier } =
      this.data.totals;

    if (total_pay_at_easyship) {
      return 'Easyship';
    }

    if (total_pay_at_easyship_on_scan) {
      return 'EasyshipPayOnScan';
    }

    if (total_pay_at_courier) {
      return 'Courier';
    }
  }

  /**
   * [back] Returns to edit shipment step. Disabled when form is loading.
   * @return {N/A}
   */
  back() {
    if (this.busy.pay) return;
    this.MixpanelService.track('Returns - Pay Return Label - Back', {
      easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
    });

    this.ReturnService.goToEditShipmentStep();
  }

  /**
   * [next] Main call-to-action. Scenarios:
   *  1) Company has sufficient credit and pays with credit or receives a refund
   *  2) Company has insufficient credit and must pay with credit card
   *    a) Company has selected a pre-existing credit card
   *    b) Company has opted to add a new credit card
   * @return {N/A}
   */
  // eslint-disable-next-line consistent-return
  next() {
    this.creditCardErrorCode = '';

    this.busy.pay = true;
    // 1) Company has sufficient credit
    if (this.sufficientFunds) return this._processPayment();

    // 2) Payment through credit card
    // send payment event
    this._sendPaymentSubmitMixpanelEvent(this.data, this.payload);

    this.PaymentSourceService.paymentAmount =
      this.data.totals && this.data.totals.total_pay_at_easyship;

    this.PaymentService.submitPaymentForm(this.paymentFormId)
      .then((results) => {
        const valid = results && results.valid;

        // for old cards
        const paymentSourceId = results && results.payment && results.payment.token;

        if (!valid) {
          this.busy.pay = false;
          return;
        }

        this._processPayment({
          paymentSourceId,
        });
      })
      .catch((error) => {
        const [errorCode] = transformToErrorCodes(error);
        this.creditCardErrorCode = errorCode || 'unknown';
        this.busy.pay = false;
      });
  }

  _sendPaymentSubmitMixpanelEvent(data, payload) {
    if (!data || !payload) return;
    if (!data.totals) return;

    const eventData = {
      payment_method: this._getPaymentMethodForAnalytics(data.totals),
      number_of_orders: 1,
      total_pay_at_easyship: data.totals.total_pay_at_easyship,
      grand_total: data.totals.total_pay_at_easyship + data.totals.total_pay_at_courier,
    };

    this.MixpanelService.track('Returns - Pay Return Label - Confirm', eventData);
  }

  _getPaymentMethodForAnalytics(totals) {
    if (!totals) return null;

    if (totals.total_to_top_up && totals.total_to_pay_from_credit) {
      return 'hybrid';
    }
    if (totals.total_to_top_up) {
      return 'credit_card';
    }
    return 'easyship_bucks';
  }

  /**
   * Calls the fix_monkey#confirm endpoint and redirects to success / fail step
   * @param  {object} paymentOptions Contains the paymentSourceId and other meta data about the payment
   */
  _processPayment(paymentOptions = {}) {
    const token = paymentOptions.paymentSourceId;

    this.ReturnService.confirm(this.shipment.id, this.payload, token)
      .then((data) => {
        if (data.label_state === 'generated') {
          this.ReturnService.goToSuccessStep(data);
        } else {
          this.ReturnService.goToFailedStep(data);
        }
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.pay = false;
      });
  }

  get isConfirmRefundButtonDisabled() {
    return this.globalBusy || this.busy.pay || !this.UserRightsService.canCreateReturnLabel;
  }
}

const ReturnModalPayReturnComponent = {
  controller: ReturnModalPayReturn,
  template,
  bindings: {
    shipment: '<',
  },
};

export { ReturnModalPayReturnComponent };
