import { IComponentController } from 'angular';
import template from './shipment-list-row-select-all.html?raw';
import style from './shipment-list-row-select-all.module.scss';

class ShipmentListRowSelectAll implements IComponentController {
  style = style;
}

const ShipmentListRowSelectAllComponent: ng.IComponentOptions = {
  controller: ShipmentListRowSelectAll,
  template,
  bindings: {
    esSelectedCount: '<',
    esTotalCount: '<',
    esIsSelectedAll: '<',
    esOnSelectAll: '&',
    esOnDeselectAll: '&',
  },
};

export { ShipmentListRowSelectAllComponent };
