(function () {
  StorePlatformApi.$inject = ['$resource', 'API'];
  function StorePlatformApi($resource, API) {
    const endpointUrl = API.endpoint;

    return $resource(
      `${endpointUrl}/companies/:company_id/stores/:store_id/:platform/:controller`,
      { store_id: '@_store_id', company_id: '@_company_id', platform: '@_platform' },
      {
        create: { method: 'POST' },
      }
    );
  }
  angular.module('app.factory.StorePlatformApi', []).factory('StorePlatformApi', StorePlatformApi);
})();
