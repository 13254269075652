import angular from 'angular';

import registrationService from '../registration';
import { ShopifyAuthService } from './shopify-auth.service';
import { ShopifyResource } from './shopify-auth.resource';

const MODULE_NAME = 'auth.services.shopify-auth';

angular
  .module(MODULE_NAME, [registrationService])
  .service('ShopifyAuthService', ShopifyAuthService)
  .service('ShopifyResource', ShopifyResource);

export default MODULE_NAME;
