(function () {
  'use strict';

  function addressFormUsAddressyCrtl() {
    var ctrl = this;

    ctrl.fieldLengths = {
      contact_name: {
        pattern: [3, 140],
        valid: true
      },
      contact_email: {
        pattern: [0, 140],
        valid: true
      },
      contact_phone: {
        pattern: [1, 20],
        valid: true
      },
      company_name: {
        pattern: [3, 140],
        valid: true
      }
    };

    ctrl.$onInit = function () {

    };

    ctrl.showInputErrorMessage = function (field) {
      ctrl.fieldLengths[field].valid =
        !ctrl.address[field] || ctrl.address[field].length <= ctrl.fieldLengths[field].pattern[1];
    };

  }

  addressFormUsAddressyCrtl.$inject = [];

  angular
    .module('app.component.address-form-us-addressy')
    .controller('addressFormUsAddressyCrtl', addressFormUsAddressyCrtl);

})();
