import angular from 'angular';
import uibModal from 'angular-ui-bootstrap/src/modal';
import 'angular-resource';

import helperService from '@client/core/services/helper';
import mixpanelService from '@client/core/services/mixpanel';
import appcuesService from '@client/core/services/app-cues';
import uploadErrorModalService from '@client/src/global/services/upload-error-modal';

import '@client/core/config';
import '@client/src/global/shipment';
import '@client/app/services/ShipmentCache/ShipmentCache.service';
import '@client/app/services/ShipmentList/ShipmentList.service';
import '@client/app/services/EndpointService/EndpointService.service';

import { ReportService } from './Report.service';

angular
  .module('app.global.report', [
    uibModal,
    'ngResource',
    'core.config',
    helperService,
    mixpanelService,
    appcuesService,
    uploadErrorModalService,
    'app.global.shipment',
    'app.service.ShipmentCache',
    'app.service.ShipmentList',
    'app.service.EndpointService',
    'app.factory.UserSession',
  ])
  .service('ReportService', ReportService);
