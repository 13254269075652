import React from 'react';
import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import COLORS from '@client/src/colors';
import FormLabel, { FormLabelProps } from './FormLabel';
import ProgressIndicator, { ProgressIndicatorProps } from './ProgressIndicator';

export interface SelectProps extends MuiSelectProps, Pick<FormLabelProps, 'flag' | 'helperIcon'> {
  label?: React.ReactNode;
  loading?: boolean;
  progressIndicatorProps?: ProgressIndicatorProps;
}

function Select(
  {
    label,
    style,
    flag,
    helperIcon,
    loading,
    disabled,
    progressIndicatorProps,
    ...props
  }: SelectProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <FormLabel label={label} flag={flag} helperIcon={helperIcon}>
      <ProgressIndicator show={loading} {...progressIndicatorProps}>
        <MuiSelect
          ref={ref}
          disabled={disabled || loading}
          style={{ width: '100%', ...style }}
          {...props}
          IconComponent={() => (
            <Box
              width={24}
              height={24}
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={`${COLORS.inkNormal} !important`}
              clone
            >
              <i className="icon-chevron-down MuiSelect-icon MuiSelect-iconOutlined" />
            </Box>
          )}
        />
      </ProgressIndicator>
    </FormLabel>
  );
}

export default React.forwardRef(Select);
