import { IUserSession } from 'typings/user-session';
import { IShipmentListItem } from 'typings/shipment';

import { IComponentController } from 'angular';
import template from './shipment-list-edit-courier-card.html?raw';
import style from './shipment-list-edit-courier-card.module.scss';

class ShipmentListEditCourierCard implements IComponentController {
  style = style;
  buyerSelection?: {
    name: string;
    shippingCost: number;
    currency?: string;
    originShippingCost?: number;
    originCurrency?: string;
    importTax: number;
  };
  esShipment?: IShipmentListItem;
  flatRateBoxId: string | null = null;

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  $onInit() {
    const {
      name,
      shipping_cost = 0,
      import_tax_charge = 0,
      import_duty_charge = 0,
      currency,
      origin_shipping_cost = 0,
      origin_import_tax_charge = 0,
      origin_import_duty_charge = 0,
      origin_currency,
    } = this.esShipment?.buyer_selected_courier_data || {};
    const shippingCostTyped =
      shipping_cost && Number.isFinite(shipping_cost)
        ? (shipping_cost as number)
        : parseFloat(shipping_cost as string);

    this.buyerSelection = {
      name: this.esShipment?.buyer_selected_courier?.name || name || '',
      shippingCost: shippingCostTyped + import_tax_charge + import_duty_charge,
      currency,
      originShippingCost:
        origin_shipping_cost + origin_import_tax_charge + origin_import_duty_charge,
      originCurrency: origin_currency,
      importTax: import_tax_charge + import_duty_charge,
    };

    this.flatRateBoxId =
      this.esShipment?.parcels_attributes.reduce<string | null>(
        (agg, parcel) => (parcel.packageable_type === 'FlatRateBox' ? parcel.packageable_id : agg),
        null
      ) || null;
  }

  get isParcelCast(): boolean {
    return this.UserSession.isParcelCast();
  }
}

const ShipmentListEditCourierCardComponent: ng.IComponentOptions = {
  controller: ShipmentListEditCourierCard,
  template,
  bindings: {
    esShipment: '<',
    esCloseAction: '&',
    esOnCourierSelect: '&',
    esQuotePage: '<',
  },
};

export { ShipmentListEditCourierCardComponent };
