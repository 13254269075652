import { IComponentController } from 'angular';
import template from './modal-instance.html?raw';

import { IModalControl } from './modal-instance.directive';

class ModalInstance implements IComponentController {
  esShow: boolean;
  modalDirectiveCtrl: IModalControl;
  scope: ng.IScope;

  static $inject = ['$scope'];
  constructor($scope: ng.IScope) {
    this.esShow = false;
    this.scope = $scope;
    this.modalDirectiveCtrl = {
      openModal: () => {},
      closeModal: () => {},
    };
  }

  $onInit() {
    this.scope.$watch('$ctrl.esShow', (isShow: boolean) => {
      if (isShow) {
        this.showModal();
      } else {
        this.closeModal();
      }
    });
  }

  showModal(): void {
    if (this.modalDirectiveCtrl) {
      this.esShow = true;
      this.modalDirectiveCtrl.openModal();
    }
  }

  closeModal(): void {
    if (this.modalDirectiveCtrl) {
      this.esShow = false;
      this.modalDirectiveCtrl.closeModal();
    }
  }
}

const ModalInstanceComponent: ng.IComponentOptions = {
  controller: ModalInstance,
  template,
  transclude: true,
  bindings: {
    esShow: '=?',
    esKeyboard: '<',
    esOpenedClass: '@',
    esAnimation: '<',
    esOnCloseModalSuccess: '&',
  },
};

export { ModalInstanceComponent };
