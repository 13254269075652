import angular from 'angular';

import { ShipmentStatusCellComponent } from './shipment-status-cell.component';

const MODULE_NAME = 'app.global.shipment-cells.status';

angular
  .module(MODULE_NAME, [])
  .component('esShipmentStatusCell', ShipmentStatusCellComponent);

export default MODULE_NAME;
