import angular from 'angular';

import { ReturnLabelComponent } from './return-label.component';

const MODULE_NAME = 'app.manage-shipments.shipment-information.return-label';

angular
  .module(MODULE_NAME, [])
  .component('esShipmentReturnLabel', ReturnLabelComponent);

export default MODULE_NAME ;
