import { States } from 'typings/states';
import { StatesResource } from './states.resource';

class StatesService {
  states: States.IState[] = [];

  static $inject = ['$q', 'StatesResource'];
  constructor(private $q: ng.IQService, private StatesResource: StatesResource) {}

  getStates(): ng.IPromise<States.IApiGetSuccessResponse> {
    return this.$q((resolve) => {
      this.StatesResource.get().then(({ states }) => {
        this.states = states;

        resolve({ states });
      });
    });
  }

  findState(key: keyof States.IState, value: string) {
    return this.states.find((state) => {
      return state[key] === value;
    });
  }
}

export { StatesService };
