import { IApiConfig } from 'typings/core/config/api';
import {
  IStoreService,
  InsuranceFeatureStatus,
  InsuranceFeatureInstallStatus,
} from 'typings/store';

import { IComponentController } from 'angular';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './insurance-card.module.scss';
import template from './insurance-card.html?raw';

type FormKey = 'insurance_at_checkout_dark_mode' | 'insurance_at_checkout_on_by_default';
class StoreSettingsInsuranceCardController implements IComponentController {
  style = style;
  expanded = false;
  showingActivationModal = false;
  showingDeactivationModal = false;
  showingExampleModal = false;
  busy = false;
  form: { [key in FormKey]: boolean } = {
    insurance_at_checkout_dark_mode: false,
    insurance_at_checkout_on_by_default: false,
  };
  wrappers = { a: (chunk: string): string => chunk };

  static $inject = ['$translate', 'API', 'StoreService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private API: IApiConfig,
    private StoreService: IStoreService
  ) {}

  $onInit(): void {
    const store = this.StoreService.activeStore;
    const templateEditorUrl = !store
      ? '#'
      : `${store?.store_url}/admin/themes/${store?.insurance_at_checkout_theme_id}?key=sections/cart-template.liquid&line=256`;
    this.wrappers = {
      a: (chunk: string) =>
        `<a href='${templateEditorUrl}' target='_blank' rel='noopener noreferrer'>${chunk}</a>`,
    };

    this.form.insurance_at_checkout_dark_mode = !!store?.insurance_at_checkout_dark_mode;
    this.form.insurance_at_checkout_on_by_default = !!store?.insurance_at_checkout_on_by_default;
  }

  toggleCardSize(): void {
    this.expanded = !this.expanded;
  }

  toggleActivationModal(): void {
    this.showingActivationModal = !this.showingActivationModal;
  }

  toggleDeactivationModal(): void {
    this.showingDeactivationModal = !this.showingDeactivationModal;
    this.expanded = false;
  }

  toggleExampleModal(): void {
    this.showingExampleModal = !this.showingExampleModal;
  }

  save(key: FormKey | 'pickupFeeOption', value: boolean): void {
    if (!this.StoreService.activeStore) return;

    this.busy = true;
    this.StoreService.updateStore({ ...this.StoreService.activeStore, [key]: value })
      .then((data) => {
        this.StoreService.activeStore = { ...this.StoreService.activeStore, ...data.store };
        toastSuccess(
          this.$translate.instant('store.notifications.settings-update', {
            store:
              this.StoreService.activeStore.name ||
              this.$translate.instant('global.store').toLowerCase(),
          })
        );
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy = false;
      });
  }

  get insuranceIconUrl(): string {
    return `${this.API.easyship_storage}/insurance/shield-teal.svg`;
  }

  get platformAllowsInsurance(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.insurance_at_checkout;
  }

  get status(): InsuranceFeatureStatus {
    if (!this.platformAllowsInsurance) return 'unavailable';
    return this.StoreService.activeStore?.insurance_at_checkout_state || 'inactive';
  }

  get platformName(): string | null {
    return this.StoreService.activeStore?.platform?.display_name || 'this platform';
  }

  get installStatus(): InsuranceFeatureInstallStatus {
    const store = this.StoreService.activeStore;
    return {
      product: !!store?.insurance_at_checkout_product_installed,
      snippet: !!store?.insurance_at_checkout_snippet_installed,
    };
  }

  get templateEditorUrl(): string {
    const store = this.StoreService.activeStore;
    return !store
      ? '#'
      : `${store?.store_url}/admin/themes/${store?.insurance_at_checkout_theme_id}?key=sections/cart-template.liquid&line=256`;
  }

  get supportUrl(): string {
    return `${this.API.help}/hc/en-us/articles/4402695345293-Insurance-at-Checkout-BETA`;
  }
}

const StoreSettingsInsuranceCardComponent: ng.IComponentOptions = {
  controller: StoreSettingsInsuranceCardController,
  template,
  bindings: {},
};

export { StoreSettingsInsuranceCardComponent };
