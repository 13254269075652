import angular from 'angular';
import uibModal from 'angular-ui-bootstrap/src/modal';

import '@client/app/factories/UserSession/UserSession.service';
import '@client/src/global/services/box';
import mixpanelService from '@client/core/services/mixpanel';

import { BoxesComponent } from './boxes.component';
import { BoxCardComponent } from './box-card/box-card.component';
import { BoxAddComponent } from './box-add/box-add.component';
import { BoxUpdateComponent } from './box-update/box-update.component';
import { BoxDeleteComponent } from './box-delete/box-delete.component';
import { FlatRateBoxUpdateComponent } from './flat-rate-box-update/flat-rate-box-update.component';
import { BoxModalMainComponent } from './box-modal-main/box-modal-main-component';
import { BoxModalService } from './box-modal/box-modal.service';
import { AngularAutoBoxExplainer } from './AutoBoxExplainer';

angular
  .module('app.tools.boxes', [
    uibModal,
    'app.factory.UserSession',
    mixpanelService,
    'app.global.box',
  ])
  .component('boxes', BoxesComponent)
  .component('boxCard', BoxCardComponent)
  .component('boxAdd', BoxAddComponent)
  .component('boxUpdate', BoxUpdateComponent)
  .component('boxDelete', BoxDeleteComponent)
  .component('flatRateBoxUpdate', FlatRateBoxUpdateComponent)
  .component('boxModalMain', BoxModalMainComponent)
  .component('esAutoBoxExplainer', AngularAutoBoxExplainer)
  .service('BoxModalService', BoxModalService);
