import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

class PickupService {
  static $inject = ['$filter', '$translate', 'PickupResource', 'UserSession', 'HelperService'];

  constructor($filter, $translate, PickupResource, UserSession, HelperService) {
    this.$filter = $filter;
    this.$translate = $translate;
    this.PickupResource = PickupResource;
    this.UserSession = UserSession;
    this.HelperService = HelperService;
  }

  getCompanyPickups(params = {}, payload = {}) {
    const queryParams = { ...params, company_id: this.UserSession.company.id };

    return this.PickupResource.getFilterValues(queryParams, payload)
      .then((res) =>
        res.pickups.map((pickup) => {
          // eslint-disable-next-line no-param-reassign
          pickup.displayableDescription = this.$translate.instant(
            'shipments.filter.pickup.option',
            {
              date: this.$filter('intlDate')(pickup.selected_date, {
                dateStyle: 'medium',
                timeZone: undefined,
              }),
              from: pickup.selected_from_time,
              to: pickup.selected_to_time,
              handler: pickup.handler_name,
              count: pickup.number_of_shipments,
            },
            'messageformat'
          );
          return pickup;
        })
      )
      .catch((err) => {
        toastError(err);
      });
  }

  initFilter(pickups) {
    const withDate = (pickups || []).map((pickup) => ({
      ...pickup,
      selected_date: this.$filter('intlDate')(pickup.selected_date, {
        dateStyle: 'medium',
        timeZone: undefined,
      }),
    }));

    const groupedOptions = this.HelperService.groupArrayByKey(withDate, 'selected_date', {
      isDisplay: true,
    });

    if (pickups.length > 0) {
      groupedOptions.unshift({
        id: 'all_pickups',
        display: 'All pickups',
        value: pickups.map(({ pickup_id }) => pickup_id),
      });
    }

    return groupedOptions;
  }

  getSupportInfo(id) {
    return this.PickupResource.getSupportInfo(
      { company_id: this.UserSession.company.id, pickup_id: id },
      {}
    );
  }

  cancelPickup(id) {
    return this.PickupResource.cancel(
      { company_id: this.UserSession.company.id, pickup_id: id },
      {}
    )
      .then((res) => {
        toastSuccess(res.status);
      })
      .catch((err) => {
        toastError(err.status);
      });
  }
}

export { PickupService };
