import { useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface IPlanNameProps {
  planName: string;
  isActive: boolean;
}
export default function PlanName({ planName, isActive }: IPlanNameProps) {
  const theme = useTheme();

  return (
    <Typography
      variant="h4"
      className="m-b-5 m-t-10"
      style={{
        fontSize: theme.utils.fz.smallest,
        color: isActive ? theme.palette.ink.dark : theme.palette.ink.light,
        lineHeight: 1,
      }}
    >
      {planName}
    </Typography>
  );
}
