import { IComponentController } from 'angular';
import { IUserSession } from 'typings/user-session';
import template from './no-rates-banner.html?raw';
import style from './no-rates-banner.module.scss';
import noRatesBannerImageUrl from '@assets/images/dashboard/no-rates-banner/no-rates-banner-image.svg';

class NoRatesBannerController implements IComponentController {
  style = style;
  noRatesBannerImageUrl = noRatesBannerImageUrl;
  showBanner = false;
  companyOriginCountry = '';
  noCard = false;

  static $inject = ['UserSession', '$state'];
  constructor(private UserSession: IUserSession, private $state: ng.ui.IStateService) {}

  $onInit() {
    this.companyOriginCountry = this.UserSession.getCompanyCountryName();
  }

  get canConnectCourier() {
    return this.UserSession.hasUserRole('account_and_settings');
  }

  redirectToCourierPage(): void {
    this.$state.go('app.couriers');
  }
}

const NoRatesBannerComponent = {
  controller: NoRatesBannerController,
  template,
  bindings: {
    noCard: '<',
  },
};

export { NoRatesBannerComponent };
