import { IComponentController } from 'angular';
import template from './shipment-list-collection-cell.html?raw';
import style from './shipment-list-collection-cell.module.scss';

class ShipmentListCollectionCell implements IComponentController {
  style = style;
  indicatorColorClass = 'none';
  esCollectState: 'none' | 'collected' | 'to_be_collected' = 'none';

  $onInit() {
    this.indicatorColorClass = this.calculateIndicatorColorClass();
  }

  private calculateIndicatorColorClass(): string {
    if (this.esCollectState === 'to_be_collected') {
      return this.style.toBeCollected;
    }
    if (this.esCollectState === 'collected') {
      return this.style.collected;
    }
    return this.style.none;
  }
}

const ShipmentListCollectionCellComponent: ng.IComponentOptions = {
  controller: ShipmentListCollectionCell,
  template,
  bindings: {
    esCollectState: '<',
  },
};

export { ShipmentListCollectionCellComponent };
