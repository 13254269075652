import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

/* eslint-disable no-param-reassign */
(function () {
  angular.module('easyshipDashboardApp').controller('VerifyCtrl', VerifyCtrl);

  VerifyCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'Auth',
    'AUTH_EVENTS',
    'ApiKeys',
    'vcRecaptchaService',
    'UserService',
    'UserSession',
  ];

  function VerifyCtrl(
    $scope,
    $state,
    $stateParams,
    $translate,
    Auth,
    AUTH_EVENTS,
    ApiKeys,
    vcRecaptchaService,
    UserService,
    UserSession
  ) {
    const vm = this;
    vm.ApiKeys = ApiKeys;

    vm.user = {};
    vm.busy = {
      page: true,
      login: false,
    };

    // Check if user is logged in, redirect user back to client
    // Otherwise, load sign-in form
    (function init() {
      const sessionToken = Auth.getCredentials();

      if (sessionToken) {
        // Must ping the server to set headers
        Auth.retrieveSession();
      } else {
        vm.busy.page = false;
      }
    })();

    vm.onInputChange = function (value, key) {
      vm.user[key] = value;
    };

    /**
     * [login] Triggers to login function allowing user to enter the dashboard
     * @return {N/A}
     */
    vm.login = function () {
      vm.busy.login = true;

      if (vm.loginForm.$error.required) {
        vm.busy.login = false;
        toastError($translate.instant('toast.incomplete-form'));
      }

      if (vm.loginForm.$error.recaptcha) {
        vm.busy.login = false;
        vcRecaptchaService.execute();
      }
    };
    // Set the recaptcha widget id once loaded, this can be use to reload the widget
    vm.setRecaptchaWidgetId = function (widgetId) {
      vm.widgetId = widgetId;
    };

    vm.authenticate = function (recaptchaResponse) {
      vm.busy.login = true;

      Auth.login(vm.user, null, recaptchaResponse)
        .catch(function () {
          toastError($translate.instant('toast.default-error'));
        })
        .finally(function () {
          vm.busy.login = false;
        });
    };

    function loginFailFallback(err) {
      vm.busy.login = false;
      vm.loginFail = true;
      vm.errors = err.response.data;
      vcRecaptchaService.reload(vm.widgetId);
    }

    function loginSuccessCallBack() {
      // confirm the token
      const { token } = $stateParams;

      // Confirm email if it was not
      if (token) {
        UserService.confirmEmail(UserSession.getUserId(), token)
          .then(function (response) {
            UserSession.user = response.user;
            toastSuccess($translate.instant('login.verify.success'));
            redirectToApp();
          })
          .catch(function (err) {
            vm.confirmError = err && err.data && err.data.status;
          })
          .finally(function () {
            vm.busy.page = false;
          });
      } else {
        redirectToApp();
      }
    }

    function redirectToApp() {
      return $state.go('app.home');
    }

    // /**
    //  * [login] Triggers to login function allowing user to enter the dashboard
    //  * @return {N/A}
    //  */
    // vm.login = function logUserIn() {
    //   vm.busy.login = true;
    //   Auth.login(vm.user);
    // };

    /**
     * [goToResetPage] Redirects user to reset password page and pass email if applicable
     * @return {N/A}
     */
    vm.goToResetPage = function directToResetPageAndPassEmail() {
      $state.go('home.auth.password-reset', { userEmail: vm.user.email });
    };

    // Intercept the failed event and shows the errors.
    // @param {Object} e
    // @param {Object} response
    $scope.$on(AUTH_EVENTS.loginFailed, function (e, err) {
      loginFailFallback(err);
    });

    // Redirect back to client after login
    $scope.$on(AUTH_EVENTS.loginSuccess, function () {
      loginSuccessCallBack();
    });

    $scope.$on(AUTH_EVENTS.fullLoginSuccess, function () {
      loginSuccessCallBack();
    });
  }
})();
