import angular from 'angular';
import ngResource from 'angular-resource';

import '@client/core/config';
import '@client/app/factories/UserSession/UserSession.service';
import '@client/app/services/EndpointService/EndpointService.service';

import { ShippingDiscountsService } from './shipping-discounts.service';
import { ShippingDiscountsResource } from './shipping-discounts.resource';

angular
  .module('app.global.shipping-discounts', [
    ngResource,
    'core.config',
    'app.service.EndpointService',
    'app.factory.UserSession',
  ])
  .service('ShippingDiscountsService', ShippingDiscountsService)
  .factory('ShippingDiscountsResource', ShippingDiscountsResource);
