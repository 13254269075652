import { Persona } from '@client/data/persona';
import { EcommercePlatform, NoPlatform, OtherPlatform } from '@client/data/platform';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';

enum PersonaPlatform {
  EcommerceMerchantMajorPlatform = 'EcommerceMerchantMajorPlatform',
  EcommerceMerchantMinorPlatform = 'EcommerceMerchantMinorPlatform',
  EcommerceMerchantNoPlatformIntegration = 'EcommerceMerchantNoPlatformIntegration',
  Crowdfunding = 'Crowdfunding',
  Personal = 'Personal',
  Fulfillment = 'Fulfillment',
}

export interface IOnboardingChecklist {
  [key: string]: boolean;
}

interface IOrderByPersona {
  [key: string]: OnboardingChecklist[];
}

const ORDER_BY_PERSONA: IOrderByPersona = {
  [PersonaPlatform.EcommerceMerchantMajorPlatform]: [
    OnboardingChecklist.ConnectStore,
    OnboardingChecklist.SyncOrders,
    OnboardingChecklist.GenerateLabel,
    OnboardingChecklist.SetupBranding,
    OnboardingChecklist.InputDimensions,
    OnboardingChecklist.AddBoxes,
    OnboardingChecklist.CreateShippingRule,
    OnboardingChecklist.InstallCheckout,
  ],
  [PersonaPlatform.EcommerceMerchantMinorPlatform]: [
    OnboardingChecklist.ConnectStore,
    OnboardingChecklist.SyncOrders,
    OnboardingChecklist.GenerateLabel,
    OnboardingChecklist.SetupBranding,
    OnboardingChecklist.InputDimensions,
    OnboardingChecklist.AddBoxes,
    OnboardingChecklist.CreateShippingRule,
  ],
  [PersonaPlatform.EcommerceMerchantNoPlatformIntegration]: [
    OnboardingChecklist.GetQuote,
    OnboardingChecklist.CreateShipment,
    OnboardingChecklist.GenerateLabel,
    OnboardingChecklist.SetupBranding,
    OnboardingChecklist.CreateShippingRule,
  ],
  [PersonaPlatform.Crowdfunding]: [
    OnboardingChecklist.GetQuote,
    OnboardingChecklist.BookMeeting,
    OnboardingChecklist.CreateShippingRule,
    OnboardingChecklist.DownloadCsv,
    OnboardingChecklist.UploadOrders,
    OnboardingChecklist.GenerateLabel,
    OnboardingChecklist.SetupBranding,
  ],
  [PersonaPlatform.Personal]: [
    OnboardingChecklist.GetQuote,
    OnboardingChecklist.CreateShipment,
    OnboardingChecklist.GenerateLabel,
    OnboardingChecklist.TrackShipment,
  ],
  [PersonaPlatform.Fulfillment]: [
    OnboardingChecklist.WarehousePartners,
    OnboardingChecklist.Lyoc,
    OnboardingChecklist.ConnectStore,
    OnboardingChecklist.SyncOrders,
    OnboardingChecklist.InputDimensions,
  ],
};

class OnboardingChecklistService {
  checklist: IOnboardingChecklist | undefined;
  checklistData = OnboardingChecklist;
  previousProgressPercentage = 0;

  static $inject = ['User', 'UserSession', 'StoreService'];
  constructor(private User: any, private UserSession: any, private StoreService: any) {
    if (!this.UserSession.getUserPersona()) {
      return;
    }

    this.getChecklist();
    this.getConnectStoreStatus();
    this.previousProgressPercentage = this.currentProgressPercentage;
  }

  get currentProgressPercentage(): number {
    if (!this.checklist) {
      return 0;
    }

    const checklistKeys = Object.keys(this.checklist);
    const doneChecklist = checklistKeys.reduce((acc, checklist) => {
      if (this.checklist && this.checklist[checklist]) {
        acc += 1;
      }

      return acc;
    }, 0);

    return Math.ceil((doneChecklist / checklistKeys.length) * 100);
  }

  get isComplete(): boolean {
    return this.currentProgressPercentage === 100;
  }

  set showOnboarding(showOnboarding: boolean) {
    this.UserSession.user.show_onboarding = showOnboarding;
  }

  get showOnboarding(): boolean {
    return this.UserSession.user.show_onboarding;
  }

  get hasProgressPercentageChanged(): boolean {
    return this.currentProgressPercentage !== this.previousProgressPercentage;
  }

  updateOnboarding(item: OnboardingChecklist): void {
    if (!this.checklist || !this.checklist.hasOwnProperty(item) || this.checklist[item]) {
      return;
    }

    this.User.updateFlowRecords(
      { id: this.UserSession.user.id },
      {
        flow_record: {
          flow_type: 'onboarding',
          flow_name: item,
        },
      }
    ).$promise.then(() => {
      if (this.checklist) {
        this.previousProgressPercentage = this.currentProgressPercentage;

        this.checklist[item] = true;
      }
    });
  }

  turnOffOnboarding(): void {
    this.User.update(
      {
        company_id: this.UserSession.company.id,
        id: this.UserSession.user.id,
      },
      {
        user: {
          show_onboarding: false,
        },
      }
    );

    this.showOnboarding = false;
  }

  private getChecklist(): void {
    let checklist;

    if (this.isMerchantWithNoPlatform) {
      checklist = ORDER_BY_PERSONA[PersonaPlatform.EcommerceMerchantNoPlatformIntegration];
    } else if (this.isMerchantWithMajorEcommercePlatform) {
      checklist = ORDER_BY_PERSONA[PersonaPlatform.EcommerceMerchantMajorPlatform];
    } else if (this.isMerchantWithMinorEcommercePlatform) {
      checklist = ORDER_BY_PERSONA[PersonaPlatform.EcommerceMerchantMinorPlatform];
    } else if (
      this.UserSession.getUserPersona() === Persona.Crowdfunding ||
      this.UserSession.isKickstarter()
    ) {
      checklist = ORDER_BY_PERSONA[PersonaPlatform.Crowdfunding];
    } else if (this.UserSession.getUserPersona() === Persona.Personal) {
      checklist = ORDER_BY_PERSONA[PersonaPlatform.Personal];
    } else if (this.UserSession.getUserPersona() === Persona.Fulfillment) {
      checklist = ORDER_BY_PERSONA[PersonaPlatform.Fulfillment];
    }

    if (checklist) {
      this.checklist = checklist.reduce(
        (acc: IOnboardingChecklist, item) => {
          acc[item] = !!(
            this.UserSession.user.flow_records.onboarding &&
            this.UserSession.user.flow_records.onboarding[item]
          );

          return acc;
        },
        {
          [OnboardingChecklist.ShippingAddress]: true,
        }
      );
    }
  }

  private getConnectStoreStatus(): void {
    if (
      this.checklist &&
      this.checklist.hasOwnProperty(OnboardingChecklist.ConnectStore) &&
      !this.checklist[OnboardingChecklist.ConnectStore]
    ) {
      this.StoreService.getStores().then(() => {
        if (this.checklist) {
          if (this.StoreService.stores && this.StoreService.stores.length) {
            const storePlatform = this.StoreService.stores.find((store: any) => {
              return store.platform;
            });

            if (storePlatform) {
              this.updateOnboarding(OnboardingChecklist.ConnectStore);
            }
          }
        }
      });
    }
  }

  private get isMerchantWithMajorEcommercePlatform(): boolean {
    return (
      this.UserSession.getUserPersona() === Persona.Merchant &&
      this.UserSession.getCompanySelectedPlatforms().some((platform: EcommercePlatform) => {
        return [
          EcommercePlatform.Shopify,
          EcommercePlatform.BigCommerce,
          EcommercePlatform.Magento,
        ].includes(platform);
      })
    );
  }

  private get isMerchantWithMinorEcommercePlatform(): boolean {
    return (
      this.UserSession.getUserPersona() === Persona.Merchant &&
      this.UserSession.getCompanySelectedPlatforms().some((platform: EcommercePlatform) => {
        return ![
          EcommercePlatform.Shopify,
          EcommercePlatform.BigCommerce,
          EcommercePlatform.Magento,
        ].includes(platform);
      })
    );
  }

  private get isMerchantWithNoPlatform(): boolean {
    return (
      this.UserSession.getUserPersona() === Persona.Merchant &&
      this.UserSession.getCompanySelectedPlatforms().some((platform: EcommercePlatform) => {
        return [NoPlatform, OtherPlatform].includes(platform);
      })
    );
  }
}

export { OnboardingChecklistService };
