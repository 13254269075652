(function () {
  'use strict';

  Product.$inject = ['$resource', 'API'];
  function Product($resource, API) {
    var endpointUrl = API.endpoint;

    return {
      export: $resource(
        endpointUrl + '/companies/:company_id/stores/:store_id/products/export',
        {
          store_id: '@_store_id',
          company_id: '@_company_id',
        },
        {
          export: {
            method: 'GET',
          },
        }
      ),

      upload: $resource(
        endpointUrl + '/companies/:company_id/stores/:store_id/products/csv_upload',
        {
          store_id: '@_store_id',
          company_id: '@_company_id',
        },
        {
          upload: {
            method: 'POST',
          },
        }
      ),

      index: $resource(
        endpointUrl + '/companies/:company_id/stores/:store_id/products',
        {
          company_id: '@_company_id',
          store_id: '@_store_id',
        },
        {
          query: {
            method: 'GET',
          },
        }
      ),

      delete: $resource(
        endpointUrl + '/companies/:company_id/stores/:store_id/products/:id',
        {
          store_id: '@_store_id',
          company_id: '@_company_id',
          id: '@_id',
        },
        {
          query: {
            method: 'DELETE',
          },
        }
      ),
    };
  }

  angular.module('app.factory.Product', []).factory('Product', Product);
})();
