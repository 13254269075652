import { IComponentController, IQService } from 'angular';
import { IUserSession } from 'typings/user-session';
import { IAddressService, IDraftAddress } from 'typings/address';

import {
  ICountryHkDistrict,
  ICountrySupportOptions,
  ICountryService,
  ICountry,
} from 'typings/auth/services/country';
import { IPostalCodeService } from 'typings/postal-code';

import template from './sender-form.html?raw';
import style from './sender-form.module.scss';

class SenderFormController implements IComponentController {
  NZ_ID = 172;

  esAddress?: IDraftAddress;
  esOnChange?: (changes: { form: IDraftAddress; originCountryId: number }) => void;
  esDomesticShipment?: boolean;
  esDisableValidation?: boolean;

  style = style;
  error: any = {};
  busy: any = { postalCodeLookup: false };
  shippingCountryRequirements: ICountrySupportOptions = {
    has_postal_code: false,
    requires_state: false,
    postal_code_examples: null,
    postal_code_mandatory_from_origin: null,
    postal_code_regex: null,
    consignee_tax_id_required: false,
    consignee_tax_id_required_umbrella_names: [],
    consignee_tax_id_suggested_umbrella_names: [],
  };
  countries: ICountry[] = [];
  hkDistricts: ICountryHkDistrict[] = [];
  cityOptions: string[] = [];
  // To avoid Exceptions::ManagerError: CloudShipmentManagerError
  maxLength = {
    contact_name: 22, // even though reciever address can be 50
    company_name: 27, // even though reciever address can be 50
    contact_phone: 20, // even though reciever address can be 50
    contact_email: 50, // even though reciever address can be 100
    line_1: 35, // even though reciever address can be 100
    line_2: 35, // even though reciever address can be 100
    city: 40,
    postal_code: 20,
    state: 30,
  };
  alertState = {
    showEmptyPostalCode: false,
    showRegexMismatch: false,
    showStateMismatch: false,
    showInvalidConsigneeIdFormat: false,
  };
  form: IDraftAddress;
  validatedOnInit = false;
  allowForeignAddress = false;

  static $inject = ['$q', 'CountryService', 'PostalCodeService', 'AddressService', 'UserSession'];
  constructor(
    private $q: IQService,
    private CountryService: ICountryService,
    private PostalCodeService: IPostalCodeService,
    private AddressService: IAddressService,
    private UserSession: IUserSession
  ) {
    this.form = {
      nickname: '',
      line_1: '',
      line_2: '',
      contact_name: '',
      company_name: '',
      contact_phone: '',
      contact_email: '',
      country_id: 0,
      postal_code: '',
      city: '',
      state: '',
      hk_district_id: 0,
      use_for_later: true,
      pickup_instruction_slug: 'none',
      pickup_instruction_users_input: null,
    };
  }

  $onInit(): void {
    this.allowForeignAddress =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_global_account;

    this.$q
      .all([
        this.CountryService.getShippingSupportedCountries(),
        this.AddressService.getAddresses(),
      ])
      .then(([{ countries }]) => {
        this.countries = countries;
        if (!this.form.country_id) {
          const defaultAddress = this.AddressService.getDefaultPickupAddress();
          this.form.country_id = defaultAddress
            ? defaultAddress.country_id
            : this.UserSession.company.country_id;
        }
        if (this.form.country_id) this.getCountryRequirements();
      });
  }

  $onChanges(): void {
    if (!this.esAddress) return;

    this.form = this.esAddress;

    if (this.form.postal_code && !this.validatedOnInit) {
      this.getCountryRequirements();
      this.validatePostalCode(this.form.postal_code);
      this.validatedOnInit = true;
    }
  }

  get selectedCountry(): ICountry {
    return this.CountryService.findCountry(this.form.country_id);
  }

  get isOriginUnitedStates(): boolean {
    return this.form.country_id === 234;
  }

  get isOriginHongKong(): boolean {
    return this.form.country_id === 96;
  }

  get isOriginAustralia(): boolean {
    return this.form.country_id === 14;
  }

  get isOriginNewZealand(): boolean {
    return this.form.country_id === 172;
  }

  onInputChange(value: any, key: keyof IDraftAddress): void {
    if (value === undefined) return;

    switch (key) {
      case 'contact_phone':
        this.cleanPhoneNumber(value);
        break;

      case 'country_id':
        this.form[key] = value;
        this.form.state = '';
        this.form.city = '';
        delete this.form.postal_code;
        delete this.form.hk_district_id;

        this.getCountryRequirements();
        break;

      case 'hk_district_id':
        this.form[key] = value as number;
        break;

      case 'use_for_later':
        this.form[key] = value as boolean;
        break;

      case 'postal_code':
        this.form[key] = value;
        this.validatePostalCode(value);
        break;

      default:
        this.form[key] = value;
        break;
    }

    if (this.esOnChange) {
      this.esOnChange({ form: this.form, originCountryId: this.form.country_id });
    }
  }

  private getCountryRequirements() {
    const { requirements } = this.CountryService.findCountry(this.form.country_id);
    this.shippingCountryRequirements = requirements;

    if (this.isOriginHongKong) {
      this.CountryService.getHkDistricts().then((districts) => {
        this.hkDistricts = districts.map((district) => ({
          ...district,
          display: `${district.district} - ${district.area}`,
        }));
      });
    }
  }

  private validatePostalCode(value: string): void {
    const validPostalCode = new RegExp(
      this.shippingCountryRequirements.postal_code_regex as string
    ).test(value);

    if (validPostalCode) {
      this.error.postalCodeRegex = false;
      if (this.destinationNeedsPostalCode) this.fillCityState(value);
    } else {
      this.error.postalCodeRegex = true;
      this.form.city = '';
      if (this.destinationNeedsPostalCode) this.form.state = '';
    }
  }

  private cleanPhoneNumber(value: string): void {
    this.form.contact_phone = value.replace(/[.\-+\s]+/g, '');
  }

  private fillCityState(postal_code: string) {
    if (!this.esAddress) return;

    this.busy.postalCodeLookup = true;

    const params = {
      postal_code,
      country_id: this.form.country_id,
    };

    this.PostalCodeService.getState(params)
      .then((res) => {
        if (!res) return;
        this.error.postalCode = false;

        if (this.isOriginAustralia) {
          this.form.state = (res.states?.length && res.states[0].name) || this.form.state;
          this.form.city = (res.suburbs?.length && res.suburbs[0]) || this.form.city;
          this.cityOptions = res.suburbs || [this.form.city];
        } else {
          this.form.state = res.state?.name || '';

          this.form.city = res?.city || '';

          if (res.cities && res.cities.length > 0) {
            const [city] = res.cities;
            this.form.city = city;
            this.cityOptions = res.cities;
          }
        }

        if (this.esOnChange) {
          this.esOnChange({ form: this.form, originCountryId: this.form.country_id });
        }
      })
      .catch(() => {
        this.form.state = '';
        this.form.city = '';
        this.error.postalCode = true;
      })
      .finally(() => {
        this.busy.postalCodeLookup = false;
      });
  }

  get destinationNeedsPostalCode(): boolean {
    return !!this.shippingCountryRequirements?.postal_code_mandatory_from_origin?.includes(
      this.form.country_id
    );
  }
}

const SenderFormComponent = {
  template,
  controller: SenderFormController,
  bindings: {
    esAddress: '<',
    esOnChange: '&', // use es-on-change="$ctrl.myMethod(form)"
    esDomesticShipment: '<',
    esDisableValidation: '<',
  },
};

export { SenderFormComponent };
