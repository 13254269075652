import { IComponentController } from 'angular';
import { IShowDeletePaymentModal } from 'typings/payment-method';
import template from './delete-payment-flow.html?raw';
import style from './delete-payment-flow.module.scss';

class DeletePaymentFlow implements IComponentController {
  style = style;
  esShowModal: IShowDeletePaymentModal = {
    deleteCard: false,
    confirmDeleteCard: false,
  };

  $onInit() {
    if (!this.esShowModal) {
      throw new Error('Delete payment flow: An es Show Modal binding must be provided.');
    }
  }

  esClosePaymentModal() {}

  closePaymentModal(): void {
    this.esShowModal.deleteCard = false;
    this.esClosePaymentModal();
  }

  confirmDeletePayment(): void {
    this.esShowModal.deleteCard = false;
    this.esShowModal.confirmDeleteCard = true;
  }

  closeConfirmPaymentModal(): void {
    this.esShowModal.confirmDeleteCard = false;
    this.esClosePaymentModal();
  }
}

const DeletePaymentFlowComponent: ng.IComponentOptions = {
  controller: DeletePaymentFlow,
  template,
  bindings: {
    esShowModal: '=?',
    esDeleteCardAction: '&',
    esClosePaymentModal: '&',
    esSelectedCardId: '<',
  },
};

export { DeletePaymentFlowComponent };
