'use strict';

angular.module('easyshipDashboardApp').directive('dashboardHeader', dashboardHeader);

dashboardHeader.$inject = [
  '$state',
  '$parse',
  'ShipmentListAdvancedService',
  'ShipmentAction',
  'ShipmentCache',
  'ShipmentList',
  'ReportService',
  'SubscriptionService',
];
function dashboardHeader(
  $state,
  $parse,
  ShipmentListAdvancedService,
  ShipmentAction,
  ShipmentCache,
  ShipmentList,
  ReportService,
  SubscriptionService
) {
  return {
    templateUrl: function () {
      var stateName;

      if ($state.is('manualInput') || $state.is('fileUpload')) {
        stateName = 'multiple';
      } else {
        stateName = $state.current.parent
          ? $state.current.parent.split('.')[1]
          : $state.current.name.split('.')[1];

        if (stateName === 'returns') {
          stateName = 'shipments';
        }
      }

      return new URL(
        `./dashboard-header-${stateName}/dashboard-header-${stateName}.html`,
        import.meta.url
      ).href;
    },
    restrict: 'EA',
    link: function (scope, element, attrs) {
      scope.ShipmentAction = ShipmentAction;
      scope.ReportService = ReportService;
      scope.ShipmentList = ShipmentList;
      scope.ShipmentCache = ShipmentCache;
      scope.ShipmentListAdvancedService = ShipmentListAdvancedService;
      scope.SubscriptionService = SubscriptionService;
      scope.onPrepareMultipleShipments = $parse(attrs.onPrepareMultipleShipments)(scope);
      scope.openDashboardHeaderModal = $parse(attrs.openDashboardHeaderModal)(scope);
    },
  };
}
