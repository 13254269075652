import angular from 'angular';
import { CommercialInvoiceComponent } from './commercial-invoice.component';

const MODULE_NAME = 'app.tools.printing-options.commercial-invoice';

angular
  .module(MODULE_NAME, [])
  .component('esCommercialInvoice', CommercialInvoiceComponent);

export default MODULE_NAME;
