import { IShipmentListItem } from 'typings/shipment';
import { IClaimFeedback } from 'typings/insurance';
import { ClaimStateKey, ClaimFeedbacks, ClaimTypeKey } from '@client/data/insurance';
import { UNSHIPPED_STATUS_IDS } from '@client/data/shipment';

export function identifyClaimState(shipment: IShipmentListItem): IClaimFeedback {
  const { last_status_message, insurance_claim_state } = shipment;

  if (
    last_status_message &&
    last_status_message.id &&
    UNSHIPPED_STATUS_IDS.includes(last_status_message.id)
  )
    return { state: ClaimStateKey.NotShipped };

  switch (insurance_claim_state) {
    case 'not_filed':
    default:
      return { state: ClaimStateKey.NotFiled };
    case 'filed_by_client':
      return { state: ClaimStateKey.FiledByClient };
    case 'filed_to_courier':
      return { state: ClaimStateKey.FiledToCourier };
    case 'filed_to_courier_damaged':
      return { state: ClaimStateKey.FiledToCourier, type: ClaimTypeKey.DamagedOrMissing };
    case 'filed_to_courier_lost':
      return { state: ClaimStateKey.FiledToCourier, type: ClaimTypeKey.Lost };
    case 'accepted_damaged':
      return { state: ClaimStateKey.Accepted, type: ClaimTypeKey.DamagedOrMissing };
    case 'accepted_lost':
      return { state: ClaimStateKey.Accepted, type: ClaimTypeKey.Lost };
    case 'rejected_damaged':
      return { state: ClaimStateKey.Rejected, type: ClaimTypeKey.DamagedOrMissing };
    case 'rejected_lost':
      return { state: ClaimStateKey.Rejected, type: ClaimTypeKey.Lost };
  }
}

export default function identifyIClaimFeedback(shipment: IShipmentListItem): IClaimFeedback {
  const claimState = identifyClaimState(shipment);

  return (
    ClaimFeedbacks.find(
      ({ state, type }) => state === claimState.state && type === claimState.type
    ) || claimState
  );
}
