import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './receipts.html?raw';
import style from './receipts.module.scss';

class Receipts {
  static $inject = ['$translate', 'UserSession', 'BillingService'];

  constructor($translate, UserSession, BillingService) {
    this.style = style;
    this.$translate = $translate;
    this.UserSession = UserSession;
    this.BillingService = BillingService;
    this.financialDocuments = BillingService.financialDocuments;
  }

  handleKeywordSearch = () => {
    this.onSearch();
  };

  openFilter = () => {
    this.BillingService.toggleAdvancedSearch();
  };

  onSearchChange = (value) => {
    this.BillingService.filter.keyword = value;
  };

  downloadPdf() {
    const documentIds = this.BillingService.buildFinancialDocumentsPayload(this.financialDocuments);

    if (documentIds.length < 1) {
      toastError(
        this.$translate.instant('toast.select-error', {
          noun: this.$translate
            .instant('global.pluralize.receipt', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );
      return;
    }

    this.BillingService.downloadFinancialDocuments(documentIds)
      .then(() => {
        toastSuccess(
          this.$translate.instant('toast.email-file', {
            email: this.UserSession.user.email,
          })
        );
      })
      .catch((err) => {
        toastError(err.message);
      });
  }

  onCheckboxChange(value, id, page) {
    if (!value && this.financialDocuments.selectedPages[page]) {
      this.financialDocuments.selectedPages[page] = value;
    }

    this.financialDocuments.selectedDocuments[id] = value;
  }

  onPageCheckboxChange(value, page) {
    this.financialDocuments.selectedPages[page] = value;

    this._checkPage(value);
  }

  _checkPage(value) {
    this.receipts.forEach(({ id }) => {
      this.financialDocuments.selectedDocuments[id] = value;
    });
  }
}

const ReceiptsComponent = {
  template,
  controller: Receipts,
  bindings: {
    onSearch: '&',
    receipts: '<',
    loading: '<',
    error: '<',
  },
};

export { ReceiptsComponent };
