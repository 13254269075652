import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { react2angular } from 'react2angular';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Button from '@client/core/components/react/Button';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import EditorVersionDialog from './EditorVersionDialog';
import EditorVersionPopper from './EditorVersionPopper';

interface EditorVersionSelectorProps {
  isRetainingClassicView: boolean;
  onToggle: () => void;
  onSubmit: (feedback: string) => void;
}

export default function EditorVersionSelector({
  isRetainingClassicView,
  onToggle,
  onSubmit,
}: EditorVersionSelectorProps): JSX.Element {
  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [isShowingDialog, setIsShowingDialog] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    if (isRetainingClassicView) {
      onToggle();
    } else {
      setIsShowingMenu(!isShowingMenu);
    }
  };

  const handleFeedbackSelection = () => {
    setIsShowingDialog(true);
    setIsShowingMenu(false);
  };

  const handleSwitchBack = () => {
    onToggle();
    handleFeedbackSelection();
  };

  const handleSubmit = (feedback: string) => {
    onSubmit(feedback);
    setIsShowingDialog(false);
  };

  // return focus to the button when we transitioned from !isShowingMenu -> isShowingMenu
  const prevOpen = React.useRef(isShowingMenu);
  useEffect(() => {
    if (prevOpen.current === true && isShowingMenu === false) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = isShowingMenu;
  }, [isShowingMenu]);

  return (
    <>
      <Grid container>
        {isRetainingClassicView && (
          <Box padding="9px 12px" borderRight="1px solid #DFE3E8">
            <Typography color="textSecondary">
              <FormattedMessage id="dashboard-header.shipments.view-version.classic" />
            </Typography>
          </Box>
        )}
        <Button
          ref={anchorRef}
          onClick={handleToggle}
          aria-expanded={isShowingMenu}
          style={{ padding: '10px', textTransform: 'none', lineHeight: '17px' }}
          endIcon={
            !isRetainingClassicView && (
              <KeyboardArrowDownIcon
                style={{
                  color: '#A1ADB8',
                  transform: isShowingMenu ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.5s ease-in-out',
                }}
              />
            )
          }
        >
          <Typography style={{ display: 'flex' }}>
            <FormattedMessage
              id={`dashboard-header.shipments.view-version.panel${
                isRetainingClassicView ? '.try' : ''
              }`}
            />
          </Typography>
        </Button>
      </Grid>

      <EditorVersionPopper
        open={isShowingMenu}
        anchorEl={anchorRef.current}
        onClickAway={() => setIsShowingMenu(false)}
        onFeedbackSelection={handleFeedbackSelection}
        onSwitchBack={handleSwitchBack}
      />

      <EditorVersionDialog
        open={isShowingDialog}
        onClose={() => setIsShowingDialog(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
}

function WrappedEditorVersionSelector(props: EditorVersionSelectorProps) {
  return (
    <ReactRootProviders>
      <EditorVersionSelector {...props} />
    </ReactRootProviders>
  );
}

export const AngularEditorVersionSelector = react2angular(WrappedEditorVersionSelector, [
  'isRetainingClassicView',
  'onToggle',
  'onSubmit',
]);
