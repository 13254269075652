import { toastError } from '@client/core/components/react/Toastify';

(function () {
  angular.module('easyshipDashboardApp').controller('SsoSessionCtrl', SsoSessionCtrl);

  SsoSessionCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    '$window',
    'AUTH_EVENTS',
    'Auth',
  ];

  function SsoSessionCtrl($scope, $state, $stateParams, $translate, $window, AUTH_EVENTS, Auth) {
    const vm = this;

    (function init() {
      if ($stateParams.error_message) {
        handleLoginFail($stateParams.error_message);
      } else if ($stateParams.token) {
        handleLoginSuccess($stateParams.token);
      }

      if ($stateParams.sso_url) {
        $window.localStorage.setItem('ssoUrl', $stateParams.sso_url);
      }

      $scope.$on(AUTH_EVENTS.fullLoginSuccess, function () {
        $state.go('app.home');
      });
    })();

    vm.login = function () {
      const url = $window.localStorage.getItem('ssoUrl');

      if (url) {
        $window.localStorage.removeItem('ssoUrl');
        window.location.href = url;
      } else {
        vm.errorMsg =
          "Oops! Login with SSO isn't possible from this page right now. Please use your SSO-provider portal to log into Easyship instead.";
      }
    };

    function handleLoginFail(errorMessage) {
      vm.errorMsg = errorMessage;
    }

    function handleLoginSuccess(token) {
      Auth.validateUserSsoCredentials(token).catch(function () {
        toastError($translate.instant('toast.default-error'));
      });
    }
  }
})();
