'use strict';
import template from './file-upload.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('fileUpload', {
      url: '/file-upload',
      parent: 'createModal',
      views: {
        'modal@': {
          template,
          controller: 'fileUploadCtrl',
        },
      },
    });
  },
]);
