import { IOnboardingParams, IOnboardingResponse } from 'typings/onboarding';
import { IApiConfig } from 'typings/core/config/api';

class OnboardingResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor($resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/onboarding`,
      {},
      {
        update: { method: 'PATCH' },
      }
    );
  }

  update(payload: IOnboardingParams): Promise<IOnboardingResponse> {
    return this.resource.update({}, payload).$promise;
  }
}

export { OnboardingResource };
