import angular from 'angular';
import IShipmentGateway, {
  ShipmentMetadata,
  ShipmentRestrictionsPayload,
} from '@client/core/corelogic/ports/shipment.interface';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';
import { BoxData } from '@client/core/corelogic/models/Box';
import { BoxCollectionData } from '@client/core/corelogic/models/BoxCollectionData';
import {
  CreateShippingDocumentsParams,
  CreateShippingDocumentsResponse,
  IShipmentAddress,
  IShipmentListItem,
  IShipmentResource,
  MarkShipmentAsPrintedParams,
  MarkShipmentAsPrintedPayload,
  ShippingDocuments,
} from 'typings/shipment';
import { IBoxService, IFlatRateBoxService } from 'typings/boxes';
import { IAddressService } from 'typings/address';
import { RestrictionsService } from '@client/src/global/services/restrictions/restrictions.service';
import {
  ShipmentRestrictions,
  TaxIdRequirement,
} from '@client/core/corelogic/models/ShipmentRestrictions';
import { TaxIdRequire } from 'typings/dashboard/services/restrictions';
import { ExcludedCourierData } from '@client/core/corelogic/models/CourierServiceData';
import { ShipmentData, ShipmentFormData } from '@client/core/corelogic/models/Shipment';
import { pick } from 'lodash';
import ShipmentMapper from '../mappers/shipmentMapper';

const SCOPE_ORDERS_ALL = 'orders_all';

export default class NgShipmentGateway implements IShipmentGateway {
  private ShipmentService: IShipmentListAdvancedService;
  private FlatRateBoxService: IFlatRateBoxService;
  private BoxService: IBoxService;
  private AddressService: IAddressService;
  private shipmentMapper: ShipmentMapper;
  private restrictionsService: RestrictionsService;
  private shipmentApi: IShipmentResource;

  constructor() {
    this.ShipmentService = angular
      .element(document.body)
      .injector()
      .get<IShipmentListAdvancedService>('ShipmentListAdvancedService');
    this.FlatRateBoxService = angular
      .element(document.body)
      .injector()
      .get<IFlatRateBoxService>('FlatRateBoxService');
    this.BoxService = angular.element(document.body).injector().get<IBoxService>('BoxService');
    this.AddressService = angular
      .element(document.body)
      .injector()
      .get<IAddressService>('AddressService');
    this.restrictionsService = angular
      .element(document.body)
      .injector()
      .get<RestrictionsService>('RestrictionsService');
    this.shipmentApi = angular.element(document.body).injector().get<IShipmentResource>('Shipment');
    this.shipmentMapper = new ShipmentMapper();
  }

  getShipmentById(id: string): Promise<ShipmentData> {
    return new Promise((resolve, reject) => {
      this.ShipmentService.getEditShipment(id)
        .then(({ shipment }) => resolve(this.shipmentMapper.fromAPI(shipment)))
        .catch((reason) => reject(reason));
    });
  }

  getUnavailableRates(id: string): Promise<ExcludedCourierData[]> {
    return new Promise((resolve, reject) => {
      this.ShipmentService.getShipmentUnavailableRates(id)
        .then(({ excluded_couriers }) => {
          resolve(
            excluded_couriers.map<ExcludedCourierData>((courier) => ({
              id: courier.id,
              displayName: courier.display_name,
              reason: courier.message,
              courierSlug: courier.logo_url,
            }))
          );
        })
        .catch((reason) => reject(reason));
    });
  }

  updateShipment(id: string, shipment: ShipmentFormData, metadata: ShipmentMetadata) {
    const shipmentPayload = this.shipmentMapper.toAPI(shipment, metadata);
    const validateAddresses = !!(shipment.senderAddress || shipment.receiverAddress);

    return new Promise<ShipmentData>((resolve, reject) =>
      this.ShipmentService.updateShipmentForm(id, {
        shipment: shipmentPayload,
        option: {
          calculate_rates: metadata.courierServices.shouldRecalculate,
          preserve_courier: true,
          validate_address: validateAddresses,
          except_incoterms_and_insurance: true,
        },
      })
        .then(async ({ shipment: updatedShipment }) => {
          if (shipment.senderAddress?.useForLater) {
            await this.updateReusableAddressesCache(updatedShipment);
          }

          // TODO: remove when shipments list is replace in React.
          // Which would allow to read the Shipment model
          // and not the server data as needed here.

          const parcelsPackageNames = (updatedShipment.parcels_attributes || []).map(
            (parcel) => parcel.name
          );
          // eslint-disable-next-line no-param-reassign
          updatedShipment.parcels_package_names =
            parcelsPackageNames as IShipmentListItem['parcels_package_names'];
          // eslint-disable-next-line no-param-reassign
          const getFirstShipmentItem = (
            shipmentItems?: IShipmentListItem['shipment_items_attributes']
          ): IShipmentListItem['first_shipment_item'] =>
            (pick(shipmentItems?.[0], 'company_sku', 'description', 'quantity') || {}) as IShipmentListItem['first_shipment_item'];

          // eslint-disable-next-line no-param-reassign
          updatedShipment.first_shipment_item = getFirstShipmentItem(
            updatedShipment.shipment_items_attributes
          );
          this.ShipmentService.updateShipmentInList(updatedShipment);
          resolve(this.shipmentMapper.fromAPI(updatedShipment));
        })
        .catch((reason) => reject(reason))
    );
  }

  private async updateReusableAddressesCache(shipment: IShipmentListItem) {
    const originAddress = shipment.origin_address as IShipmentAddress;
    const senderAddressAlreadyExist = this.AddressService.addresses.shippingAddresses.some(
      (address) => address.id === originAddress.id
    );

    if (!senderAddressAlreadyExist) {
      const newAddress = await this.AddressService.queryById(originAddress.id);
      this.AddressService.addresses.reusableShippingAddresses.push(newAddress);
    }
  }

  getCourierBoxes(): Promise<BoxCollectionData[]> {
    return new Promise((resolve, reject) =>
      this.FlatRateBoxService.getBoxes()
        .then((response) => {
          resolve(
            response.map<BoxCollectionData>((courier) => ({
              name: courier.umbrella_name,
              boxes: courier.boxes.map<BoxData>((box) => ({
                id: box.id,
                name: box.name,
                weight: box.weight,
                height: box.outer_height,
                width: box.outer_width,
                length: box.outer_length,
                isFlatRate: true,
              })),
            }))
          );
        })
        .catch((reason) => reject(reason))
    );
  }

  getSavedBoxes(): Promise<BoxData[]> {
    return new Promise((resolve, reject) =>
      this.BoxService.getBoxes()
        .then(({ boxes }) => {
          resolve(
            boxes.map((box) => ({
              id: box.id,
              name: box.name,
              weight: box.weight,
              height: box.outer_height,
              width: box.outer_width,
              length: box.outer_length,
              isFlatRate: false,
            }))
          );
        })
        .catch((reason) => reject(reason))
    );
  }

  updateCourier(shipmentId: string, courierServiceId: string): Promise<ShipmentData> {
    return new Promise((resolve, reject) =>
      this.ShipmentService.overwriteCourier(shipmentId, courierServiceId)
        .then(({ shipment }) => {
          // TODO: remove when shipments list is replace in React.
          // Which would allow to read the Shipment model
          // and not the server data as needed here.
          this.ShipmentService.updateShipmentInList(shipment);

          resolve(this.shipmentMapper.fromAPI(shipment));
        })
        .catch((reason) => reject(reason))
    );
  }

  getRestrictions({
    senderCountryId,
    receiverCountryId,
    courierServiceId,
  }: ShipmentRestrictionsPayload): Promise<ShipmentRestrictions> {
    const requirementMapper: Record<TaxIdRequire, TaxIdRequirement> = {
      mandatory: 'required',
      suggested: 'suggested',
      no_need: 'optional',
    };

    return new Promise((resolve, reject) =>
      this.restrictionsService
        .getShipmentRestrictions({
          destination_country_id: receiverCountryId,
          origin_country_id: senderCountryId,
          courier_id: courierServiceId,
          type: 'shipment',
        })
        .then(({ shipment }) => {
          const responseTaxIdRequire = shipment.destination_address.consignee_tax_id.require;
          const taxIdRequirement = requirementMapper[responseTaxIdRequire];
          const taxIdPattern = new RegExp(
            shipment.destination_address.consignee_tax_id.regexes.join('|')
          );

          resolve({
            taxId: { requirement: taxIdRequirement, pattern: taxIdPattern },
            multiBox: {
              isVisible: shipment.features.multi_box.is_visible,
              isUsable: shipment.features.multi_box.is_usable,
            },
            includeReturnLabel: {
              isVisible: shipment.features.automated_return.is_visible,
              isUsable: shipment.features.automated_return.is_usable,
            },
          });
        })
        .catch((reason) => reject(reason))
    );
  }

  createShippingDocuments(params: CreateShippingDocumentsParams): Promise<ShippingDocuments[]> {
    return new Promise((resolve, reject) => {
      this.shipmentApi
        .createShippingDocuments(params, null)
        .$promise.then((res: CreateShippingDocumentsResponse) => {
          resolve(res.shipping_documents);
        })
        .catch(reject);
    });
  }

  markShipmentAsPrinted(
    params: MarkShipmentAsPrintedParams,
    payload: MarkShipmentAsPrintedPayload
  ): Promise<IShipmentListItem> {
    return new Promise((resolve, reject) => {
      this.shipmentApi
        .markShipmentAsPrinted(params, payload)
        .$promise.then((res: { shipment: IShipmentListItem }) => {
          resolve(res.shipment);
        })
        .catch(reject);
    });
  }

  reloadShipments(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.ShipmentService.fetchShipmentEntitiesWithFilter(SCOPE_ORDERS_ALL)
        .then(resolve)
        .catch(reject);
    });
  }
}
