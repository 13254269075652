import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface ViewDetailsMenuItemProps {
  divider?: boolean;
  onViewDetails: () => void;
}

export default function ViewDetailsMenuItem({
  divider = false,
  onViewDetails,
}: ViewDetailsMenuItemProps) {
  return (
    <MenuItem onClick={onViewDetails} divider={divider}>
      <div style={baseStyleIconBox}>
        <i className="icon-view-details text-xl" style={{ color: baseStyleIcon.colorGrey }} />
      </div>
      <span className="text-base">
        <FormattedMessage id="shipments.speed-dial.view-details" />
      </span>
    </MenuItem>
  );
}
