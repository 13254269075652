(function () {
  'use strict';

  PostalCode.$inject = ['$resource', 'API'];
  function PostalCode($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/countries/:country_id/postal_codes/:postal_code',
      {
        country_id: '@_country_id',
        postal_code: '@_postal_code',
      },
      {
        query: {
          method: 'GET',
          params: {},
        },
      }
    );
  }

  angular.module('app.factory.PostalCode', []).factory('PostalCode', PostalCode);
})();
