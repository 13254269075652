/* eslint-disable max-classes-per-file */
import template from './optional-dimensions-modal-main.component.html?raw';

class OptionalDimesionsModalController {
  static $inject = [
    'OptionalDimensionsModalService',
    'ShipmentListAdvancedService',
    'MixpanelService',
    'params',
  ];

  constructor(
    OptionalDimensionsModalService,
    ShipmentListAdvancedService,
    MixpanelService,
    params
  ) {
    this.OptionalDimensionsModalService = OptionalDimensionsModalService;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.MixpanelService = MixpanelService;
    this.params = params;
  }

  close() {
    this.MixpanelService.track('Create Shipments - Missing Box Dimensions Modal', {
      action: 'close_modal',
    });

    this.OptionalDimensionsModalService.close();
    this.ShipmentListAdvancedService.clearSelectedShipment();
  }

  changeStep(value) {
    this.params.modal = value.step;
  }
}

class OptionalDimensionsModalService {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(params) {
    this.opened = true;

    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: OptionalDimesionsModalController,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        params: () => {
          return params;
        },
      },
    });
  }

  close() {
    this.opened = false;
    this.modalReference.close();
  }
}

export { OptionalDimensionsModalService };
