import { BoxBaseService } from './box.base.service';

class FlatRateBoxService extends BoxBaseService {
  static $inject = ['$q', '$translate', 'UserSession', 'FlatRateBoxResource', 'EndpointService'];

  constructor($q, $translate, UserSession, FlatRateBoxResource, EndpointService) {
    super();

    this.$q = $q;
    this.$translate = $translate;
    this.UserSession = UserSession;
    this.FlatRateBoxResource = FlatRateBoxResource;
    this.EndpointService = EndpointService;

    this.boxes = null;
  }

  getBoxes(params = {}) {
    return this.FlatRateBoxResource.query(
      {
        company_id: this.UserSession.company.id,
        ...params,
      },
      {}
    ).$promise.then((response) => {
      this.boxes = response.flat_rate_boxes.map((courier) => {
        courier.boxes = courier.boxes.sort(this._sortBoxesByName);

        return courier;
      });

      return this.boxes;
    });
  }

  isFlatRateBox(idToFind) {
    return this.boxes && this.boxes.some(({ id }) => id === idToFind);
  }

  getBox(idToFind) {
    return (
      this.boxes &&
      this.boxes.reduce((found, { boxes, logo_url }) => {
        if (found) return found;
        const box = boxes.find(({ id }) => id === idToFind);
        return box ? { ...box, logo_url } : found;
      }, null)
    );
  }

  _sortBoxesByName(box1, box2) {
    const box1LowerCase = box1.name.toLowerCase();
    const box2LowerCase = box2.name.toLowerCase();

    if (box1LowerCase < box2LowerCase) {
      return -1;
    }
    if (box1LowerCase > box2LowerCase) {
      return 1;
    }

    return 0;
  }

  getFlatRateBoxesSelectOptions() {
    const reduceBoxes = (boxes) => {
      return boxes.reduce(this._groupFlatRateBoxesSelectOptionsByType.bind(this), []);
    };

    if (this.boxes) {
      return this.$q((resolve) => {
        resolve(reduceBoxes(this.boxes));
      });
    }

    return this.getBoxes().then((response) => {
      return reduceBoxes(response);
    });
  }

  _groupFlatRateBoxesSelectOptionsByType(accumulator, courierBoxes) {
    return [
      ...accumulator,
      ...courierBoxes.boxes.map((courierBox) => {
        const { id, outer_height, outer_length, outer_width, name, display_dimensions } =
          courierBox;

        return {
          flat_rate_box_id: id,
          name: `${name} (${display_dimensions})`,
          height: outer_height,
          length: outer_length,
          width: outer_width,
          type: this.$translate.instant(
            'shipments.filter.box.groups.courier',
            { courier: courierBoxes.umbrella_name },
            'messageformat'
          ),
        };
      }),
    ];
  }

  getFilterFlatRateBoxesSelectOptions() {
    return this.getBoxes({ scope: this.EndpointService.params.scope }).then((response) => {
      return response.reduce(this._groupFilterFlatRateBoxesSelectOptionsByType.bind(this), []);
    });
  }

  _groupFilterFlatRateBoxesSelectOptionsByType(accumulator, courierBoxes) {
    return [
      ...accumulator,
      ...courierBoxes.boxes.map((courierBox) => {
        const { id, name, display_dimensions, shipments_count } = courierBox;

        return {
          flat_rate_box_id: id,
          name: this.$translate.instant(
            'shipments.filter.box.option',
            {
              name,
              dimensions: display_dimensions,
              count: shipments_count,
            },
            'messageformat',
            false,
            'escape' // allows ®, etc.
          ),
          type: this.$translate.instant(
            'shipments.filter.box.groups.courier',
            { courier: courierBoxes.umbrella_name },
            'messageformat'
          ),
        };
      }),
    ];
  }

  updateBox(payload, logoURL) {
    return this.FlatRateBoxResource.update(
      {
        company_id: this.UserSession.company.id,
      },
      {
        flat_rate_boxes: payload,
      }
    ).$promise.then((response) => {
      this.boxes = this.boxes.map((courier) => {
        if (courier.logo_url === logoURL) {
          courier.boxes = payload;
        }

        return courier;
      });

      return response;
    });
  }
}

export { FlatRateBoxService };
