import template from './forceUserReload.html?raw';

('use strict');

angular.module('easyshipDashboardApp').directive('forceUserReload', [
  '$window',
  'UserStatusService',
  ($window, UserStatusService) => {
    return {
      template,
      restrict: 'EA',
      link: function (scope, element, attrs) {
        scope.UserStatusService = UserStatusService;

        scope.reloadPage = function () {
          $window.location.reload(true);
        };
      },
    };
  },
]);
