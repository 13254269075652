import { fetchLocalize, localizeStore } from '@/providers/I18nProvider/localizeStore';
import { useEffect, useSyncExternalStore } from 'react';
import { EasyshipLocale } from '@/types/locale';
import { useDashboardSettings } from '@/hooks/queries/useDashboardSettings';

export const useGetLocalizedMessages = (selectedLocale?: EasyshipLocale) => {
  const { data: dashboardSettings } = useDashboardSettings();
  const localeCode = selectedLocale || dashboardSettings?.localeCode || 'en';

  const messages = useSyncExternalStore(localizeStore.subscribe, () => localizeStore.getData());

  useEffect(() => {
    fetchLocalize(localeCode).then();
  }, [localeCode]);

  return {
    messages,
    locale: localeCode,
  };
};
