(function () {
  'use strict';
  angular
    .module('easyshipDashboardApp')
    .controller('DashboardHeaderShipmentsCtrl', DashboardHeaderShipmentsCtrl);

  DashboardHeaderShipmentsCtrl.$inject = ['$scope', 'UserSession'];
  function DashboardHeaderShipmentsCtrl($scope, UserSession) {
    this.$onInit = function () {
      this.scopeQuery = $scope.query;
      this.scopeAuthToken = UserSession.session_token;
    };
  }
})();
