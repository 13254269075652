import style from './upload-error-modal.module.scss';

class UploadModalErrorController {
  static $inject = ['report', 'HelperService'];

  constructor(report, HelperService) {
    this.style = style;
    this.report = report;
    this.HelperService = HelperService;
  }

  formatKey(string) {
    return this.HelperService.snakeCaseToTitleCase(string);
  }
}

export { UploadModalErrorController };
