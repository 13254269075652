/**
 * credits https://github.com/robertmassaioli/ts-is-present
 */

/**
 * Put the type guard in an array filter function
 * const definedResults: Array<TestData> = results.filter(isPresent);
 * */
export function isPresent<T>(value: T | undefined | null | void): value is T {
  return value !== undefined && value !== null;
}

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function assertIsPresent<T>(value: T | undefined | null | void): asserts value is T {
  if (!isPresent(value)) {
    throw new Error('[assertIsPresent] The value should not be undefined or null');
  }
}
