(function () {
  'use strict';

  angular
    .module('easyshipDashboardApp')
    .controller('DashboardHeaderSingleCtrl', DashboardHeaderSingleCtrl);

  DashboardHeaderSingleCtrl.$inject = ['$scope', '$state', 'UserRightsService'];

  function DashboardHeaderSingleCtrl($scope, $state, UserRightsService) {
    $scope.state = $state;

    var isAtConfirmStage = $scope.state.current.name == 'order-summary';
    var canPurchaseLabel = UserRightsService.canPurchaseLabel;
    $scope.isConfirmPurchaseLabelDisabled = isAtConfirmStage && !canPurchaseLabel;
  }
})();
