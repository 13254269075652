import template from './verify.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('home.auth.verify', {
        url: 'verify?token',
        template,
        controller: 'VerifyCtrl as vm',
        metadata: {
          title: 'Verify your Easyship Account',
          description:
            'Login to your Easyship account to manage, ship and track your international eCommerce orders. Start shipping today with the the cheapest, fastest and best value for money shipping deals!',
        },
      });
    },
  ]);
})();
