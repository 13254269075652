import { StringRelation } from 'typings/automation';
import { IComponentController } from 'angular';
import { MultiselectComponentModelValue } from '../../services/shipment-list/data/multi-select';

import template from './sku-name-select-group.html?raw';
import style from './sku-name-select-group.module.scss';

interface RelationOption {
  value: MultiselectComponentModelValue | StringRelation;
  label: string;
}

interface IModel {
  select: MultiselectComponentModelValue | StringRelation;
  tags: string[];
  remainder: string;
}

class SkuNameSelectGroup implements IComponentController {
  style = style;
  esModel: IModel = {
    select: MultiselectComponentModelValue.AnyOf,
    tags: [],
    remainder: '',
  };
  esOptions: MultiselectComponentModelValue[] | StringRelation[] = [
    MultiselectComponentModelValue.AnyOf,
    MultiselectComponentModelValue.NoneOf,
  ];
  options: RelationOption[] = [];
  esOnChange: (changes?: { value: IModel }) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    this.options = this.esOptions.map((option) => ({
      value: option,
      label: this.$translate.instant(`global.relation.${option}`),
    }));
    this.esModel.tags = this.esModel.tags || [];
    this.handleChange();
  }

  onSelectChange(value: MultiselectComponentModelValue | StringRelation): void {
    this.esModel.select = value;
    this.handleChange();
  }

  onTagsAreaChange(skus: string[], remainder: string): void {
    this.esModel.tags = skus;
    this.esModel.remainder = remainder;
    this.handleChange();
  }

  private handleChange() {
    this.esOnChange && this.esOnChange({ value: this.esModel });
  }
}

const SkuNameSelectGroupComponent: ng.IComponentOptions = {
  controller: SkuNameSelectGroup,
  template,
  transclude: true,
  bindings: {
    esModel: '<',
    esOptions: '<',
    esOnChange: '&',
  },
};

export { SkuNameSelectGroupComponent };
