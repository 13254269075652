import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Link from '@client/core/components/react/Link';
import Alert from '@client/core/components/react/Alert';

import { API } from '@client/core/config/api.constant';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

interface PaymentErrorProps {
  creditCardErrorCode?: string;
}

export default function PaymentError({ creditCardErrorCode }: PaymentErrorProps) {
  const { formatMessage } = useIntl();
  if (!creditCardErrorCode) return null;

  return (
    <Grid item xs={12}>
      <Alert severity="error">
        <FormattedMessage
          id={`account.payment.errors.${creditCardErrorCode}`}
          defaultMessage={formatMessage({ id: 'account.payment.errors.unknown' })}
        />
        <Grid item>
          <Link href={`${API.help}/hc/en-us/articles/360007659411`} target="_blank" color="initial">
            <FormattedMessage id="account.payment.method.learn-errors" />
          </Link>
        </Grid>
      </Alert>
    </Grid>
  );
}

export function WrappedPaymentError(props: PaymentErrorProps) {
  return (
    <ReactRootProviders>
      <PaymentError {...props} />
    </ReactRootProviders>
  );
}
