import template from './button.html?raw';
import style from './button.module.scss';

class Button {
  constructor() {
    this.style = style;
    this.esCustomIconSize = 13;
  }

  getButtonType() {
    return this.esSubmit ? 'submit' : 'button';
  }
}

const ButtonComponent = {
  controller: Button,
  template,
  transclude: true,
  bindings: {
    esCustomIcon: '@',
    esCustomIconRight: '@',
    esCustomIconSize: '@',
    esType: '@',
    esLoading: '<',
    esSize: '@',
    esSubmit: '<',
    ngDisabled: '<',
    ngClick: '&',
  },
};

export { ButtonComponent };
