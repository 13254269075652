import angular from 'angular';
import { BillingComponent } from './billing.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { CreditNotesComponent } from './credit-notes/credit-notes.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { SubscriptionInvoicesComponent } from './subscription-invoices/subscription-invoices.component';
import { BillingService } from './billing.service';

angular
  .module('app.account.billing', [
    'app.global.user',
    'app.global.user-status',
    'app.global.transaction-record',
    'app.factory.UserSession',
    'core.components',
  ])
  .component('billing', BillingComponent)
  .component('transactions', TransactionsComponent)
  .component('receipts', ReceiptsComponent)
  .component('invoices', InvoicesComponent)
  .component('creditNotes', CreditNotesComponent)
  .component('esSubscriptionInvoices', SubscriptionInvoicesComponent)
  .component('advancedSearch', AdvancedSearchComponent)
  .service('BillingService', BillingService);
