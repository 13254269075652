class ManifestModalCtrl {
  static $inject = ['ManifestService', 'UserSession'];

  constructor(ManifestService, UserSession) {
    this.ManifestService = ManifestService;
    this.UserSession = UserSession;
  }
}

export { ManifestModalCtrl };
