(function () {
  'use strict';

  Courier.$inject = ['$resource', 'API'];
  function Courier($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(endpointUrl + '/couriers');
  }

  angular.module('app.factory.Courier', []).factory('Courier', Courier);
})();
