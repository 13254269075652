import angular from 'angular';

import { RestrictionsService } from './restrictions.service';
import { RestrictionsResource } from './restrictions.resource';

const MODULE_NAME = 'app.global.services.shipments';

angular
  .module(MODULE_NAME, ['ngResource'])
  .service('RestrictionsService', RestrictionsService)
  .service('RestrictionsResource', RestrictionsResource);

export default MODULE_NAME;
