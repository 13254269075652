import { ICreditCardData } from 'typings/payment-source';
import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { toastSuccess } from '@client/core/components/react/Toastify';
import style from './manage-payment-method-modal.module.scss';
import template from './manage-payment-method-modal.html?raw';

type CardUsage = 'shipments' | 'subscription';

class ManagePaymentMethodModal implements IComponentController {
  style = style;
  selections = { subscription: false, shipments: false };
  busySaving = false;
  esSelectedCardId: string | null = null;
  creditCard: ICreditCardData | null = null;
  esCloseTitleAction = () => {
    //
  };

  static $inject = ['$translate', 'PaymentSourceService', 'SubscriptionService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private PaymentSourceService: PaymentSourceService,
    private SubscriptionService: SubscriptionService
  ) {}

  $onInit(): void {
    if (this.esSelectedCardId) {
      this.PaymentSourceService.getCreditCard(this.esSelectedCardId)
        .then((res) => {
          if (res && res.payment_source) {
            this.resetCreditCardState(res.payment_source);
          } else {
            this.esCloseTitleAction();
          }
        })
        .catch(this.esCloseTitleAction);
    }
  }

  get isDefaultCard(): boolean {
    return (
      !!this.creditCard &&
      (this.creditCard.default_for_shipments || this.creditCard.default_for_subscription)
    );
  }

  get isSubscriptionAvailable(): boolean {
    return this.SubscriptionService.isSubscriptionAvailable;
  }

  paymentToggleChange(value: boolean, usage: CardUsage): void {
    this.selections[usage] = value;
  }

  saveAction(): void {
    if (this.creditCard) {
      this.busySaving = true;
      this.PaymentSourceService.updateCard(this.creditCard.source_id, {
        payment_source: {
          default_for_shipments: this.selections.shipments,
          default_for_subscription: this.selections.subscription,
        },
      })
        .then((res) => {
          if (res && res.payment_source) {
            this.resetCreditCardState(res.payment_source);
            this.PaymentSourceService.getCreditCards();
            this.esCloseTitleAction();
            toastSuccess(
              this.$translate.instant('account.payment.notifications.default-card-saved')
            );
          }
        })
        .finally(() => {
          this.busySaving = false;
        });
    }
  }

  logoUrl(className: string): string | null {
    return new URL(
      `../../../../../../assets/images/logos/credit-card-logo/${className}-colour.svg`,
      import.meta.url
    ).href;
  }

  private resetCreditCardState(card: ICreditCardData): void {
    this.creditCard = card;
    this.selections = {
      shipments: this.creditCard.default_for_shipments,
      subscription: this.creditCard.default_for_subscription,
    };
  }
}

const ManagePaymentMethodModalComponent: ng.IComponentOptions = {
  controller: ManagePaymentMethodModal,
  template,
  bindings: {
    esCloseTitleAction: '&',
    esDeleteCardAction: '&',
    esSelectedCardId: '<',
  },
};

export { ManagePaymentMethodModalComponent };
