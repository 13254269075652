import { toastError } from '@client/core/components/react/Toastify';
import template from './add-shipments.html?raw';
import style from './add-shipments.module.scss';

class AddShipmentsController {
  static $inject = [
    '$state',
    '$stateParams',
    '$filter',
    '$translate',
    'UserSession',
    'ReportService',
    'MixpanelService',
    'StoreService',
    'Shipment',
    'CreateShipmentModal',
    'OnboardingChecklistService',
    'AppCuesService',
    'AddressService',
    'UserRightsService',
  ];

  constructor(
    $state,
    $stateParams,
    $filter,
    $translate,
    UserSession,
    ReportService,
    MixpanelService,
    StoreService,
    Shipment,
    CreateShipmentModal,
    OnboardingChecklistService,
    AppCuesService,
    AddressService,
    UserRightsService
  ) {
    this.style = style;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$filter = $filter;
    this.$translate = $translate;
    this.UserSession = UserSession;
    this.ReportService = ReportService;
    this.MixpanelService = MixpanelService;
    this.StoreService = StoreService;
    this.Shipment = Shipment;
    this.CreateShipmentModal = CreateShipmentModal;
    this.OnboardingChecklistService = OnboardingChecklistService;
    this.AppCuesService = AppCuesService;
    this.AddressService = AddressService;
    this.UserRightsService = UserRightsService;

    this.addShipmentsActions = [];
    this.footer = '';
    this.isFallbackSettingsDialogOpen = false;
    this.fallbackSettingsStore = null;
    this.fallbackSettingsScenarioName = 'Sync Orders Attempt';
    this.isAddressModalOpen = false;
    this.saveAddressSuccess = () => {};

    this.updateFallbackSettingsSuccess = this.updateFallbackSettingsSuccess.bind(this);
    this.closeFallbackSettingsDialog = this.closeFallbackSettingsDialog.bind(this);
    this.closeAddressModal = this.closeAddressModal.bind(this);

    this.addShipmentsActions = [
      {
        icon: 'icon-plus',
        displayText: this.$translate.instant('welcome.actions.CreateShipment'),
        action: () => this.$state.go('manual-input'),
      },
      {
        icon: 'icon-file',
        displayText: this.$translate.instant('product-listing.buttons.import-file'),
        action: () => {
          this.MixpanelService.track('Open File Upload', {});

          if (!this.UserSession.hasPickupAddress()) {
            this.isAddressModalOpen = true;
            this.saveAddressSuccess = () => {
              this.isAddressModalOpen = false;
              this.$state.go('fileUpload');
            };
            return;
          }

          this.$state.go('fileUpload');
        },
      },
      {
        icon: 'icon-connect',
        displayText: this.$translate.instant('product-listing.buttons.connect-store'),
        action: () => {
          this.MixpanelService.track('Advanced - Connect a Store', {});
          this.$state.go('app.connect');
        },
        requiredUserRole: 'integrations',
      },
    ];
  }

  $onInit() {
    this.addShipmentsActions = this.addShipmentsActions.filter((action) => {
      if (!action.requiredUserRole) return true;
      return this.UserSession.hasUserRole(action.requiredUserRole);
    });

    this.StoreService.getStores().then(() => {
      const { stores } = this.StoreService;

      if (stores.length === 0) return;
      const storeMenuItems = stores
        .filter(({ platform }) => platform.name !== 'API' && platform.actions.get_orders)
        .map((store) => {
          const storeNameText = this.$translate.instant('create.menu.sync-from', {
            store: store.name,
          });
          const lastSyncedText = !store.last_shipments_synced_at
            ? ''
            : this.$translate.instant('create.menu.last-synced', {
                timestamp: this.$filter('intlDate')(store.last_shipments_synced_at, {
                  dateStyle: 'short',
                  timeStyle: 'short',
                  timeZone: undefined,
                }),
              });

          return {
            platform: store.platform.css_class,
            action: () => this._syncStore(store, store.platform.css_class),
            html: `<p class="m-b-0">${storeNameText}</p><p class="m-b-0 text-xs text-ink-100">${lastSyncedText}</p>`,
            disabled: !this.UserRightsService.canCreateShipmentBySync,
          };
        });

      this.addShipmentsActions = this.addShipmentsActions.concat(storeMenuItems);
      this.footer = this.$translate
        .instant('create.menu.footer')
        .replace('<a>', '<a ui-sref="app.connect">');

      if (this.$stateParams.sync_first_store) {
        // Remove the sync_first_store query param to avoid syncing store when the user refresh the page
        this.$state.go('app.multiple', { sync_first_store: null });
        this._syncStore(stores[0], stores[0].platform.css_class);
      }
    });
  }

  _syncStore(store, platform) {
    if (!this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
      this.saveAddressSuccess = () => {
        this.isAddressModalOpen = false;
        this._syncStore(store, platform);
      };
      return;
    }

    if (
      store.missing_required_fallback &&
      store.auth_state === 'verified' &&
      !store.requires_reauthorize_permission
    ) {
      this.fallbackSettingsStore = store;
      this.isFallbackSettingsDialogOpen = true;
      return;
    }
    this.fallbackSettingsStore = null;

    const payload = {
      created_before: new Date(),
      created_after: new Date(),
      store_id: store.id,
    };

    payload.created_after.setDate(payload.created_before.getDate() + 1);
    payload.created_after.setMonth(payload.created_before.getMonth() - 1);
    payload.job_description = 'Manual Store Sync Advanced Page';

    this.ReportService.resetReportService();
    this.ReportService.busy = true;

    this.AppCuesService.track("Advanced | Selected 'Sync' Shipments", null, true);

    this.Shipment.apiUpload(
      { company_type: 'cloud', company_id: this.UserSession.company.id },
      payload
    )
      .$promise.then(({ report_id }) => {
        const successCallback = () => {
          this.OnboardingChecklistService.updateOnboarding('sync_orders');
        };

        this.ReportService.getReport({
          id: report_id,
          listParams: { origin: this.$state.current.name },
          platform,
          store,
          successCallback,
        });
      })
      .catch(() => {
        this.ReportService.busy = false;
        toastError(
          this.$translate.instant('toast.fetch-error', {
            noun: this.$translate
              .instant('global.pluralize.order', { COUNT: 2 }, 'messageformat')
              .toLowerCase(),
          })
        );
      });
  }

  updateFallbackSettingsSuccess() {
    this.fallbackSettingsStore.missing_required_fallback = false;
    this.isFallbackSettingsDialogOpen = false;
    this._syncStore(this.fallbackSettingsStore, this.fallbackSettingsStore.platform.css_class);
  }

  closeFallbackSettingsDialog() {
    this.isFallbackSettingsDialogOpen = false;
    this.fallbackSettingsStore = null;
  }

  closeAddressModal() {
    this.isAddressModalOpen = false;
    this.saveAddressSuccess = () => {
      //
    };
  }
}

const AddShipmentsComponent = {
  template,
  controller: AddShipmentsController,
};

export { AddShipmentsComponent };
