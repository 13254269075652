import { ITagsParams, ITagsPayload, ITagsResponse, IAdvanceSearchTagItem } from 'typings/tags';
import { IUserSession } from 'typings/user-session';
import { TagsResource } from './tags.resource';

class TagsService {
  savedTags: string[] = [];
  tagsList: IAdvanceSearchTagItem[] = [];
  tagListIsUpdated = false;
  keyword?: string;
  allDownloaded = false; // Future enhancement: Use FE filters if all tags were downloaded on the first query

  static $inject = ['TagsResource', 'UserSession', '$q'];
  constructor(
    private TagsResource: TagsResource,
    private UserSession: IUserSession,
    private $q: ng.IQService
  ) {}

  getTags(params: ITagsParams): ng.IPromise<ITagsResponse> {
    return this.TagsResource.getTags(params).then((response) => {
      this.tagListIsUpdated = true;
      return response;
    });
  }

  query(keyword?: string, offset = 0): ng.IPromise<IAdvanceSearchTagItem[]> {
    if (keyword !== this.keyword) {
      this.keyword = keyword;
      this.allDownloaded = false;
      this.tagsList = [];
    }

    if (this.allDownloaded && offset > 0) return this.$q((resolve) => resolve([]));

    return this.TagsResource.getTags({
      company_id: this.UserSession.getCompanyId(),
      controller: 'search',
      keyword,
      limit: 100,
      offset,
    }).then(({ tags, next_page }: ITagsResponse) => {
      const tagsList = tags.sort().map(
        (tagValue: string) =>
          ({
            value: tagValue,
            display: tagValue,
            group: 'order',
            isDisplay: true,
            selected: this.savedTags.includes(tagValue),
          } as IAdvanceSearchTagItem)
      );

      this.allDownloaded = !next_page;
      if (keyword === undefined || keyword === '') {
        this.tagListIsUpdated = true;
        this.tagsList = this.tagsList.concat(tagsList);
      }

      return tagsList;
    });
  }

  addTag(params: ITagsParams, payload: ITagsPayload): ng.IPromise<void> {
    this.tagListIsUpdated = false;
    return this.TagsResource.addTag(params, payload);
  }

  newTag(tagName: string): ng.IPromise<void> {
    this.tagListIsUpdated = false;
    return this.TagsResource.addTag(
      { company_id: this.UserSession.getCompanyId() },
      {
        tag: { name: tagName.toLowerCase() },
      }
    );
  }

  toggleTagSelection(tag: IAdvanceSearchTagItem): void {
    const index = this.savedTags.indexOf(tag.value);
    const selection = this.tagsList.find(({ value }) => value === tag.value);

    if (index === -1) {
      this.savedTags.push(tag.value);
    } else {
      this.savedTags.splice(index, 1);
    }
    if (selection) selection.selected = !selection.selected;
  }

  resetSelections(): void {
    this.savedTags = [];
    this.tagsList.forEach((tagItem) => {
      tagItem.selected = false;
    });
  }
}

export { TagsService };
