export enum Persona {
  Merchant = 'merchant',
  Developer = 'developer',
  Crowdfunding = 'crowdfunding',
  Personal = 'personal',
  Fulfillment = 'fulfillment',
  Skypostal = 'skypostal',
  Parcelcast = 'parcelcast',
  Postageforce = 'postageforce',
  Luxottica = 'luxottica',
  Packifi = 'packifi',
  Kickstarter = 'kickstarter',
  Indiegogo = 'indiegogo',
  Duda = 'duda_partner',
}

export interface IPersona {
  value: Persona;
}

export const Personas: IPersona[] = [
  { value: Persona.Merchant },
  { value: Persona.Developer },
  { value: Persona.Crowdfunding },
  { value: Persona.Personal },
];
