import { IComponentController } from 'angular';
import template from './two-action-card.html?raw';
import style from './two-action-card.module.scss';

const CONTENT_PADDING = 50;

class TwoActionCard implements IComponentController {
  style = style;
  esRightButtonType = 'primary';
  esLeftButtonType = 'link';
  esContentPadding = CONTENT_PADDING;
  esCardWidth = 500;
  esRightButtonShow = true;
  esLeftButtonShow = true;
  esShowCloseButton = true;

  $onInit() {
    if (!this.esRightButtonType) {
      this.esRightButtonType = 'primary';
    }

    if (!this.esLeftButtonType) {
      this.esLeftButtonType = 'link';
    }

    if (!this.esContentPadding) {
      this.esContentPadding = CONTENT_PADDING;
    }

    if (!this.esCardWidth) {
      this.esCardWidth = 500;
    }

    if (this.esRightButtonShow === undefined) {
      this.esRightButtonShow = true;
    }

    if (this.esLeftButtonShow === undefined) {
      this.esLeftButtonShow = true;
    }

    if (this.esShowCloseButton === undefined) {
      this.esShowCloseButton = true;
    }
  }
}

const TwoActionCardComponent: ng.IComponentOptions = {
  controller: TwoActionCard,
  template,
  transclude: {
    title: '?actionCardTitle',
    content: '?actionCardContent',
    leftButtonTransclude: '?actionCardLeftButtonTransclude',
    rightButtonTransclude: '?actionCardRightButtonTransclude',
  },
  bindings: {
    esRightButtonType: '<',
    esRightButtonText: '<',
    esRightButtonDisabled: '<',
    esRightButtonShow: '<',
    esRightButtonAction: '&',
    esRightButtonClass: '<',
    esRightButtonLoading: '<',
    esLeftButtonType: '<',
    esLeftButtonText: '<',
    esLeftButtonDisabled: '<',
    esLeftButtonShow: '<',
    esLeftButtonAction: '&',
    esLeftButtonClass: '<',
    esLeftButtonLoading: '<',
    esContentPadding: '<',
    esCardWidth: '<',
    esShowCloseButton: '<',
  },
};

export { TwoActionCardComponent };
