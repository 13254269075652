import { IAuthService } from 'typings/core/services/auth';
import { IDraftStore, IStore, IStoreService } from 'typings/store';
import { IUserSession } from 'typings/user-session';

interface EtsyAuthRedirection {
  redirect_url: string;
  state: string;
  code_verifier: string;
}

interface EtsyAuthResult {
  code: string;
  state: string;
  code_verifier?: string;
}
export class EtsyService {
  static $inject = ['$window', 'Auth', 'UserSession', 'PlatformApi', 'StoreService'];
  constructor(
    private $window: ng.IWindowService,
    private Auth: IAuthService,
    private UserSession: IUserSession,
    private PlatformApi: any,
    private StoreService: IStoreService
  ) {}

  authenticate(): ng.IPromise<void> {
    // Ping the session to be sure that UserSession exists
    return this.Auth.retrieveSession()
      .then(
        () =>
          this.PlatformApi.etsy.authenticate({ company_id: this.UserSession.company.id }, {})
            .$promise
      )
      .then((res: EtsyAuthRedirection) => {
        this.$window.localStorage.setItem('etsyCodeVerifier', res.code_verifier);
        this.$window.localStorage.setItem('etsyState', res.state);

        this.$window.location.href = res.redirect_url;
      });
  }

  retrieveVerifier(result: EtsyAuthResult): string | null {
    return this.$window.localStorage.getItem('etsyState') === result.state
      ? this.$window.localStorage.getItem('etsyCodeVerifier')
      : null;
  }

  connectStore(newStore: IDraftStore): ng.IPromise<{ store: IStore }> {
    // Ping the session to be sure that UserSession exists
    return this.Auth.retrieveSession()
      .then(
        () =>
          this.PlatformApi.etsy.save(
            { company_id: this.UserSession.company.id },
            { store: newStore }
          ).$promise
      )
      .then((response: { store: IStore }) => {
        this.StoreService.saveStoreValueToLocal(response.store);
        return response;
      });
  }
}
