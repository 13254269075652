import React, { createContext, useContext, useMemo } from 'react';
import { AbTestPort } from '@client/core/corelogic/ports/abTest';
import NgAbTestGateway from '@client/core/adapters/abTest/ngAbTestGateway';
import FakeAbTestGateway from '@client/core/adapters/abTest/fakeAbTestGateway';

export interface AbTestGatewayContextData {
  abTestGateway: AbTestPort;
}

const AbTestGatewayContext = createContext<AbTestGatewayContextData | undefined>(undefined);

interface AbTestGatewayProviderProps {
  children: React.ReactNode;
}

function AbTestGatewayProvider({ children }: AbTestGatewayProviderProps) {
  const data: AbTestGatewayContextData = useMemo(
    () => ({
      abTestGateway: new NgAbTestGateway(),
    }),
    []
  );

  return <AbTestGatewayContext.Provider value={data}>{children}</AbTestGatewayContext.Provider>;
}

interface FakeAbTestGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<AbTestGatewayContextData>;
}

function FakeAbTestGatewayProvider({ children, value }: FakeAbTestGatewayProviderProps) {
  const data: AbTestGatewayContextData = useMemo(
    () => ({
      abTestGateway: new FakeAbTestGateway(),
      ...value,
    }),
    [value]
  );

  return <AbTestGatewayContext.Provider value={data}>{children}</AbTestGatewayContext.Provider>;
}

function useAbTestGateway() {
  const abTestGatewayContext = useContext(AbTestGatewayContext);

  if (abTestGatewayContext === undefined) {
    throw new Error('useAbTestGateway must be used within a AbTestGatewayProvider');
  }
  return abTestGatewayContext;
}

export { AbTestGatewayProvider, FakeAbTestGatewayProvider, useAbTestGateway };
