// Usage:   <p translate="store.api.about" translate-tags="vm.wrappers"></p>

TranslateTagsDirective.$inject = ['$timeout', '$compile'];
function TranslateTagsDirective(
  $timeout: ng.ITimeoutService,
  $compile: ng.ICompileService
): ng.IDirective {
  return {
    restrict: 'A',
    link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes): void {
      // Re-run directive when the translation ID changes
      attrs.$observe('translate', () => {
        const replaceAll = () => {
          const RE_XML = /<([^>]+)>([^<]+)<\/([^>]+)>/gm;
          const wrappers: { [key: string]: (chunk: string) => string } = scope.$eval(
            attrs.translateTags
          );
          const { innerHTML } = element[0];

          // find instances of XML tags in the translationText
          const matches = Array.from(innerHTML.matchAll(RE_XML));

          const reducer = (text: string, [full, open, chunk, close]: string[]) => {
            // skip replacement if tags don't match or if wrapper is malformed
            if (open !== close || typeof wrappers[open] !== 'function') return text;

            // replace text using provided function
            return text.replace(full, wrappers[open](chunk));
          };
          element.html(matches.reduce(reducer, innerHTML));
          // @ts-ignore
          $compile(element.contents())(scope);
        };

        // Make sure that the translate directive compiled first before modifying the DOM
        $timeout(replaceAll);
      });
    },
  };
}

export { TranslateTagsDirective };
