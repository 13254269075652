import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './team.html?raw';
import style from './team.module.scss';

class Team implements IComponentController {
  style = style;
  showChangePlanModal = {
    enterpriseCall: false,
    upgrade: false,
  };

  static $inject = ['SubscriptionService', 'GoogleAnalytics', 'MixpanelService', '$scope'];
  constructor(
    private SubscriptionService: SubscriptionService,
    public GoogleAnalytics: GoogleAnalytics,
    private MixpanelService: MixpanelService,
    private $scope: ng.IScope
  ) {
    this.showUpgradeModal = this.showUpgradeModal.bind(this);
    this.onCloseUpgrade = this.onCloseUpgrade.bind(this);
  }

  get currentSubscription() {
    return this.SubscriptionService.currentSubscription;
  }

  get suggestedPlanId() {
    return this.SubscriptionService.getSuggestedPlanIdByFeatureKey('team_members');
  }

  showUpgradeModal() {
    if (this.suggestedPlanId === this.SubscriptionService.planIds.Enterprise) {
      this.showChangePlanModal.enterpriseCall = true;
      this.MixpanelService.track('Subscription - Enterprise - Open');
    } else {
      this.showChangePlanModal.upgrade = true;
    }

    this.$scope.$apply();
  }

  onCloseUpgrade() {
    this.showChangePlanModal.upgrade = false;
  }
}

const TeamComponent: ng.IComponentOptions = {
  controller: Team,
  template,
  bindings: {},
};

export { TeamComponent };
