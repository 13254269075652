/* eslint-disable max-classes-per-file */
import template from './advertising-modal.html?raw';

class AdvertisingModalCtrl {
  static $inject = ['type', 'formData', '$uibModalInstance'];

  constructor(type, formData, $uibModalInstance) {
    this.type = type;
    this.formData = formData;
    this.$close = $uibModalInstance.close;
  }
}

class AdvertisingModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(type, formData) {
    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: AdvertisingModalCtrl,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        type: () => type,
        formData: () => formData,
      },
    });

    return this.modalReference.result;
  }

  close(response) {
    this.modalReference.close(response);
  }
}

export { AdvertisingModal };
