import { IApiConfig } from 'typings/core/config/api';

class InsurancePhotoResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor($resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/companies/:company_id/policies/:policy_id/claim_photos/:photo_id`
    );
  }

  deletePhoto(params: {
    policy_id: string;
    photo_id: string;
    company_id: string;
  }): ng.IPromise<void> {
    return this.resource.delete(params).$promise;
  }
}

export { InsurancePhotoResource };
