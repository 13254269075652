import template from './sync-orders-modal-main.html?raw';

class SyncOrdersModalMainController {
  static $inject = ['SyncOrdersModalService'];

  constructor(SyncOrdersModalService) {
    this.SyncOrdersModalService = SyncOrdersModalService;

    this.INCLUDE_STATUS_STEP = 'include-status';
    this.EXCLUDE_COUNTRIES_STEP = 'exclude-countries';
    this.EXCLUDE_TAGS_STEP = 'exclude-tags';
  }

  close() {
    this.SyncOrdersModalService.close();
  }
}

const SyncOrdersModalMainComponent = {
  template,
  controller: SyncOrdersModalMainController,
  bindings: {
    step: '<',
  },
};

export { SyncOrdersModalMainComponent };
