/* eslint-disable max-classes-per-file */
import template from './courier-details.html?raw';
import style from './courier-details.module.scss';

class CourierDetailsCtrl {
  static $inject = ['courier', 'CourierDetails', 'MixpanelService'];

  constructor(courier, CourierDetails, MixpanelService) {
    this.style = style;
    this.courier = courier;
    this.CourierDetails = CourierDetails;
    this.MixpanelService = MixpanelService;
  }

  close() {
    // Track closing of the modal only when user manually leaves the modal
    this.MixpanelService.track('Courier - Service - Close');
    this.CourierDetails.close();
  }
}

class CourierDetails {
  static $inject = ['$uibModal', 'MixpanelService'];

  constructor($uibModal, MixpanelService) {
    this.$uibModal = $uibModal;
    this.MixpanelService = MixpanelService;
    this.fedexBrokerFormShow = null;
  }

  open(courier) {
    this.MixpanelService.track('Courier - Click Service', {
      courier_name: courier.umbrella_name,
      courier_type: courier.type,
    });

    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: CourierDetailsCtrl,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        courier: () => courier,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { CourierDetails };
