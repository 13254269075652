import angular from 'angular';

import { ShipmentViewsResource } from './shipment-views.resource';
import { ShipmentViewsService } from './shipment-views.service';

const MODULE_NAME = 'app.global.services.shipment-views';

angular
  .module(MODULE_NAME, ['ngResource', 'core.config', 'app.factory.UserSession'])
  .service('ShipmentViewsResource', ShipmentViewsResource)
  .service('ShipmentViewsService', ShipmentViewsService);

export default MODULE_NAME;
