import template from './dashboard-homepage.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('app.home', {
        url: '^/dashboard?welcome&new_connect',
        template,
        controller: 'DashboardHomepageCtrl as vm',
        params: {
          onboarding: false,
        },
        resolve: {},
        data: {
          requiredUserRight: 'menu.analytics',
        },
      });
    },
  ]);
})();
