import template from './pill.html?raw';
import style from './pill.module.scss';

class Pill {
  static $inject = ['SubscriptionService'];

  constructor(SubscriptionService) {
    this.SubscriptionService = SubscriptionService;
    this.style = style;
  }

  $onInit() {
    if (!this.esHeight) {
      this.esHeight = '16';
    }

    if (!this.esBorderRadius) {
      this.esBorderRadius = '8';
    }

    if (!this.esPadding) {
      this.esPadding = '8';
    }
  }

  showUpgradeModal() {
    if (!this.esUpgradeModal) return;

    this.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
      this.esUpgradeModal,
      this.esTriggerSource
    );
  }
}

const PillComponent = {
  controller: Pill,
  template,
  transclude: true,
  bindings: {
    esColor: '@',
    esUpgradeModal: '<',
    esHeight: '<',
    esBorderRadius: '<',
    esPadding: '<',
    esTriggerSource: '<?',
    esMarginLeft: '@',
  },
};

export { PillComponent };
