import angular from 'angular';

import moveItem from './move-item';
import { ItemListComponent } from './item-list.component';

import '@client/app/services/ItemCategoryService/ItemCategoryService.service.js';
import '@client/app/factories/UserSession/UserSession.service';

angular
  .module('app.create-shipments.basic.item-list', [moveItem, 'app.service.ItemCategoryService'])
  .component('esItemList', ItemListComponent);
