import React, { memo } from 'react';
import iconLiquidUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-liquid.svg';
import iconBatteryUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-battery.svg';
import { API } from '@client/core/config/api.constant';
import { useIntl } from 'react-intl';
import { ArrowTooltip } from 'easyship-components';
import CellLayout from './CellLayout';
import { CellProps } from './d';
import BuyersNote from './buyers-note.svg';

export const RemarkCell = memo(({ row }: CellProps) => {
  const { formatMessage } = useIntl();

  const shipment = row.original;
  const hasInsured = shipment.is_insured;

  const insuredFormattedMessage = hasInsured
    ? 'global.shipment-insured'
    : 'global.shipment-not-insured';

  const note = shipment.buyer_notes;
  const hasLiquids = shipment.contains_liquids;
  const hasBattery = shipment.contains_battery_pi966 || shipment.contains_battery_pi967;
  const batteryType =
    (shipment.contains_battery_pi966 &&
      (shipment.contains_battery_pi967
        ? 'contain-battery-pi966-pi967'
        : 'contain-battery-pi966')) ||
    'contain-battery-pi967';

  return (
    <CellLayout>
      <div className="flex min-w-[120px] gap-2 px-2 items-center">
        <ArrowTooltip label={formatMessage({ id: insuredFormattedMessage })}>
          <img
            className={!hasInsured ? 'px-[3px]' : ''}
            src={`${API.easyship_storage}/insurance/shield-${hasInsured ? 'green' : 'grey'}.svg`}
            alt="insured-icon"
          />
        </ArrowTooltip>
        {note && (
          <ArrowTooltip label={note}>
            <img src={BuyersNote} alt="buyer-note-icon" />
          </ArrowTooltip>
        )}
        {hasLiquids && (
          <ArrowTooltip label={formatMessage({ id: 'shipments.category-hs-codes.contain-liquid' })}>
            <img src={iconLiquidUrl} alt="liquid-icon" />
          </ArrowTooltip>
        )}
        {hasBattery && (
          <ArrowTooltip label={formatMessage({ id: `shipments.category-hs-codes.${batteryType}` })}>
            <img src={iconBatteryUrl} alt="battery-icon" />
          </ArrowTooltip>
        )}
      </div>
    </CellLayout>
  );
});
