import { IModalInstanceService } from 'angular-ui-bootstrap';
import template from './contact-sales-modal.html?raw';

import { ContactSalesModalCtrl } from './contact-sales-modal.ctrl';

class ContactSalesModal {
  modalReference: IModalInstanceService | null = null;

  static $inject = ['$uibModal'];
  constructor(private $uibModal: ng.ui.bootstrap.IModalService) {}

  open(location: string, courier?: string): void {
    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: ContactSalesModalCtrl,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        location: () => location,
        courier: () => courier,
      },
    });
  }

  close(): void {
    if (this.modalReference) {
      this.modalReference.close();
    }
  }
}

export { ContactSalesModal };
