/**
 * A map of discrepencies between Moment's date format
 * and UI Bootstrap's
 *
 * Moment:
 * https://momentjs.com/docs/#/parsing/
 *
 * UI Bootstrap:
 * http://angular-ui.github.io/bootstrap/versioned-docs/1.3.3/#/dateparser
 */
const MOMENT_UIBDATE_MAP = {
  Y: 'y', // Digit year
  M: 'M', // Month number
  D: 'd', // Day of month
  d: 'E', // Day name in locale
};

function convertToMoment(uibFormat) {
  return uibFormat
    .split('')
    .map(uibInput => {
      for (const momentKey in MOMENT_UIBDATE_MAP) {
        if (uibInput === MOMENT_UIBDATE_MAP[momentKey]) return momentKey;
      }

      return uibInput;
    })
    .join('');
}

function convertToUIB(momentFormat) {
  return momentFormat
    .split('')
    .map(momentInput => {
      for (const momentKey in MOMENT_UIBDATE_MAP) {
        if (momentInput === momentKey) return MOMENT_UIBDATE_MAP[momentKey];
      }

      return momentInput;
    })
    .join('');
}

export { convertToMoment, convertToUIB };
