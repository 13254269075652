import angular from 'angular';

export interface ICommaListFilterParams {
  capitalize?: boolean;
  className?: string;
  limit?: number;
  commaReplacement?: string;
  conjunction?: string;
}

toCommaList.$inject = ['$translate'];
function toCommaList($translate: angular.translate.ITranslateService) {
  return (data: string[] | string, params: ICommaListFilterParams = {}): string => {
    if (!data) return '';

    const {
      capitalize,
      className,
      limit,
      commaReplacement = ',',
      conjunction = $translate.instant('global.and'),
    } = params;

    // Make an Array out of a comma separated String if needed
    const words = Array.isArray(data) ? data : data.split(',');

    // Capitalize every word
    const capitalized = capitalize
      ? words.map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      : words;

    // Format every word
    const stylized = className
      ? capitalized.map((word) => `<span class="${className}">${word}</span>`)
      : capitalized;

    // Build the sentence with the requested keyword (if applicable)
    const sentence = stylized.reduce((agg, word, index, arr) => {
      // first word
      if (index === 0) return word;

      // reached limit
      if (limit) {
        if (index === limit) {
          const ending = $translate.instant(
            'global.count-others',
            { count: words.length - limit },
            'messageformat'
          );
          const formattedEnding = className
            ? `<span class="${className}">${ending}</span>`
            : ending;
          return `${agg}${commaReplacement} ${conjunction} ${formattedEnding}`;
        }
        if (index > limit - 1) return agg;
      }

      // middle words
      if (index !== arr.length - 1) return `${agg}${commaReplacement} ${word}`;

      // last word
      return `${agg}${arr.length > 2 ? commaReplacement : ''} ${conjunction} ${word}`;
    }, '');

    return sentence;
  };
}

const MODULE_NAME = 'app.filter.to-comma-list';
angular.module(MODULE_NAME, ['pascalprecht.translate']).filter('toCommaList', toCommaList);

export default MODULE_NAME;
