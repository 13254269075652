import angular from 'angular';
import { AngularSchedulePickupLaterButton } from '@client/core/components/wrappers/pickups/AngularSchedulePickupLaterButton';
import { ButtonComponent } from './button.component';

const MODULE_NAME = 'core.components.button';

angular
  .module(MODULE_NAME, [])
  .component('esSheduleLaterPickupButton', AngularSchedulePickupLaterButton)
  .component('easyshipButton', ButtonComponent);

export default MODULE_NAME;
