import angular from 'angular';

import { Manifest } from './manifest.service.js';
import { ManifestResource } from './manifest.resource';

const MODULE_NAME = 'app.manifest';

angular
  .module(MODULE_NAME, [
    'core.config',
    'app.global.user-status',
    'app.factory.UserSession',
    'app.service.ShipmentList',
    'ngResource',
  ])
  .service('Manifest', Manifest)
  .service('ManifestResource', ManifestResource);

export default MODULE_NAME;
