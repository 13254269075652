import { IComponentController, IOnChangesObject } from 'angular';
import {
  MultiselectAttributes,
  MultiselectComponentModelValue,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { NormalizeFilterKey } from '@client/src/global/services/shipment-list/data/normalize';
import { IGroupedArray, IHelperService } from 'typings/helper';
import { IInputGroupRadioModel } from 'typings/form';
import { IAdvancedSearchGroupableObject } from 'typings/advanced-search';

import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';
import template from './missing-field-multi-select-group.html?raw';

const options: IAdvancedSearchGroupableObject[] = [
  {
    value: NormalizeFilterKey.MissingDimensions, // Box Dimensions
    group: 'order-information',
  },
  {
    value: NormalizeFilterKey.MissingParcelWeight, // labeled as Shipment Weight
    group: 'order-information',
  },
  {
    value: NormalizeFilterKey.MissingItemDescription,
    group: 'order-information',
  },
  {
    value: NormalizeFilterKey.MissingItemCategoryId,
    group: 'order-information',
  },
  {
    value: NormalizeFilterKey.MissingItemDeclaredCustomsValue, // Unit Selling Price
    group: 'order-information',
  },
  {
    value: NormalizeFilterKey.MissingShipmentItemsCount,
    group: 'order-information',
  },
  {
    value: NormalizeFilterKey.MissingDestinationName,
    group: 'receiver-information',
  },
  {
    value: NormalizeFilterKey.MissingPhoneNumber,
    group: 'receiver-information',
  },
  {
    value: NormalizeFilterKey.MissingEmail,
    group: 'receiver-information',
  },
  {
    value: NormalizeFilterKey.MissingPostalCode,
    group: 'receiver-information',
  },
  {
    value: NormalizeFilterKey.MissingCity,
    group: 'receiver-information',
  },
  {
    value: NormalizeFilterKey.MissingState,
    group: 'receiver-information',
  },
  {
    value: NormalizeFilterKey.MissingAddress,
    group: 'receiver-information',
  },
  {
    value: NormalizeFilterKey.MissingReceiverTaxId,
    group: 'receiver-information',
  },
];

const translationIds = [
  'shipments.filter.missing-field.order-information-subheader',
  'shipments.filter.missing-field.receiver-information-subheader',
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingDimensions}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingItemDescription}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingParcelWeight}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingItemCategoryId}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingItemDeclaredCustomsValue}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingShipmentItemsCount}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingDestinationName}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingPhoneNumber}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingEmail}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingPostalCode}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingCity}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingState}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingAddress}`,
  `shipments.filter.missing-field.options.${NormalizeFilterKey.MissingReceiverTaxId}`,
];

class MissingFieldMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchGroupableObject>
  implements IComponentController
{
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchGroupableObject>[] = [];

  static $inject = ['$scope', '$translate', 'HelperService', 'MixpanelService'];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private HelperService: IHelperService,
    private MixpanelService: MixpanelService
  ) {
    super($scope);
  }

  $onInit(): void {
    this.MixpanelService.track('Create Shipments - Filters - missing_fields');

    this.esModelRadio = MultiselectComponentModelValue.AnyOf;

    const selectedKeys: NormalizeFilterKey[] =
      this.esModelOptions?.reduce((groupAgg: NormalizeFilterKey[], groupCur) => {
        return groupAgg.concat(
          groupCur.options?.reduce((fieldAgg: NormalizeFilterKey[], fieldCur) => {
            if (fieldCur.selected && fieldCur.value) {
              fieldAgg.push(fieldCur.value as NormalizeFilterKey);
            }
            return fieldAgg;
          }, []) || []
        );
      }, []) || [];

    this.$translate(translationIds).then((translations: Record<string, string>) => {
      const selectAlls = this.esModelOptions?.map((group) => !!group?.selected) || [];
      this.esModelOptions = this.HelperService.groupArrayByKey(
        options.map(
          ({ value, group }: IAdvancedSearchGroupableObject) =>
            ({
              value,
              display: translations[`shipments.filter.missing-field.options.${value}`],
              group: translations[`shipments.filter.missing-field.${group}-subheader`],
              isDisplay: true,
              selected: selectedKeys.includes(value as NormalizeFilterKey),
            } as IAdvancedSearchGroupableObject)
        ) as IAdvancedSearchGroupableObject[],
        'group'
      );
      [this.esModelOptions[0].selected, this.esModelOptions[1].selected] = selectAlls;

      this.watchAndTrigger(MultiselectAttributes.Value);
    });
  }

  $onChanges(changes: IOnChangesObject): void {
    this.triggerChangeIfKnowOrUnknowModel(changes);
    this.assignDefaultSelectValue(this.esModelOptions);
  }
}

const MissingFieldMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: MissingFieldMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { MissingFieldMultiSelectGroupComponent };
