import angular from 'angular';
import ngResource from 'angular-resource';

import { PaymentIntentService } from './payment-intent.service';
import { PaymentIntentResource } from './payment-intent.resource';

angular
  .module('app.global.payment-intent', [ngResource, 'core.config', 'app.factory.UserSession'])
  .service('PaymentIntentService', PaymentIntentService)
  .service('PaymentIntentResource', PaymentIntentResource);
