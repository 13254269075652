import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './trial-end-card.html?raw';
import style from './trial-end-card.module.scss';
import image from './trial-end-card.svg?svgo';

class TrialEndCard implements IComponentController {
  style = style;
  image = image;
  isOwner = true;

  static $inject = ['$translate', 'SubscriptionService', 'UserSession'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession
  ) {}

  $onInit(): void {
    const companyId = this.UserSession.getCompanyId();
    if (!this.SubscriptionService.currentSubscription && companyId) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: companyId,
      });
    }

    if (this.UserSession.hasTeamRightsAndPermissionsBetaKey()) {
      this.isOwner =
        this.UserSession.hasUserRole('subscription_and_billing') &&
        this.UserSession.isAccountOwner();
    }
  }

  get rightButtonText(): string {
    if (this.isOwner) {
      return this.$translate.instant('subscription.buttons.confirm-trial-subscription', {
        plan: this.SubscriptionService.currentPlanName,
      });
    }

    return this.$translate.instant('global.got-it');
  }
}

const TrialEndCardComponent: ng.IComponentOptions = {
  controller: TrialEndCard,
  template,
  bindings: {
    esRightAction: '&',
    esLeftAction: '&',
    esCloseAction: '&',
  },
};

export { TrialEndCardComponent };
