FixMonkey.$inject = ['$resource', 'API'];
function FixMonkey($resource, API) {
  const endpointUrl = API.endpoint;

  return {
    checkAndConfirm: $resource(
      `${endpointUrl}/cloud/companies/:company_id/shipments/:id/fix_and_generate_label`,
      {
        company_id: '@_company_id',
        id: '@_id',
      },
      {
        generate: {
          method: 'POST',
        },
      }
    ),
    cancelAndRecreate: $resource(
      `${endpointUrl}/cloud/companies/:company_id/shipments/:id/cancel_and_recreate`,
      {
        company_id: '@_company_id',
        id: '@_id',
      },
      {
        update: {
          method: 'PATCH',
        },
      }
    ),
    report: $resource(
      `${endpointUrl}/cloud/companies/:company_id/shipments/:id/report`,
      {
        company_id: '@_company_id',
        id: '@_id',
      },
      {
        update: {
          method: 'PATCH',
        },
      }
    ),
  };
}

export { FixMonkey };
