import angular from 'angular';

import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import { CustomerEmailsComponent } from './customer-emails.component';
import { TrackingEmailComponent } from './tracking-email/tracking-email.component';
import { RatingEmailComponent } from './rating-email/rating-email.component';
import { TrackingEmailPreviewComponent } from './tracking-email/tracking-email-preview/tracking-email-preview.component';
import trackingEmailPreviewModal from './tracking-email/tracking-email-preview-modal';
import { RatingEmailPreviewComponent } from './rating-email/rating-email-preview/rating-email-preview.component';
import { RatingEmailPreviewModal } from './rating-email/rating-email-preview/rating-email-preview-modal.service';

const MODULE_NAME = 'app.tools.customer-emails';

angular
  .module(MODULE_NAME, [
    'core.service.mixpanel',
    'app.global.user',
    'app.factory.UserSession',
    'app.service.CompanyService',
    'core.components',
    onboardingChecklistService,
    trackingEmailPreviewModal,
  ])
  .component('customerEmails', CustomerEmailsComponent)
  .component('trackingEmail', TrackingEmailComponent)
  .component('ratingEmail', RatingEmailComponent)
  .component('trackingEmailPreview', TrackingEmailPreviewComponent)
  .component('ratingEmailPreview', RatingEmailPreviewComponent)
  .service('RatingEmailPreviewModal', RatingEmailPreviewModal);

export default MODULE_NAME;
