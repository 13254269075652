import { IHelperService, IGroupedArray } from 'typings/helper';
import { INotification } from 'typings/notification-centre';

import { IComponentController } from 'angular';
import { NotificationCentreService } from '@client/src/global/services/notification-centre/notification-centre.service';
import { IUserSession } from 'typings/user-session';
import style from './notification-centre-panel.module.scss';
import template from './notification-centre-panel.html?raw';
import emptyBellIcon from './empty-bell.svg?svgo';

const CREATED_AT_DATE = 'created_at_date';
const PICKUP_SCHEDULED_DATE = 'pickup_scheduled_date';

class NotificationCentrePanel implements IComponentController {
  style = style;
  emptyBellIcon = emptyBellIcon;
  loading = false;
  groupedNotifications: IGroupedArray<INotification>[] = [];
  expandIds: string[] = [];

  static $inject = [
    '$filter',
    '$translate',
    'HelperService',
    'NotificationCentreService',
    'UserSession',
    '$scope',
    '$location',
    '$state',
  ];
  constructor(
    private $filter: ng.IFilterFunction,
    private $translate: angular.translate.ITranslateService,
    private HelperService: IHelperService,
    private NotificationCentreService: NotificationCentreService,
    private UserSession: IUserSession,
    private $scope: ng.IScope,
    private $location: ng.ILocationService,
    private $state: ng.ui.IStateService
  ) {}

  $onInit(): void {
    this.fetchNotifications();
    this.$scope.$watchCollection(
      () => this.notifications,
      (data: INotification[]) => {
        if (data) {
          this.assignGroupedNotifications(data);
        }
      }
    );
  }

  esOnCtaClick(): void {
    // esOnCtaClick expression bindings, need to add this in order for typescript to successfully compile
  }

  /**
   *
   * @param utcDate an ISO-8601-formatted timestamp in UTC timezone (e.g '2020-12-16T08:23:18.896Z')
   */
  convertCreatedText(utcDate: string): string {
    const d = new Date(utcDate);

    if (Object.prototype.toString.call(d) !== '[object Date]' || isNaN(d.getTime())) return '';

    if (this.HelperService.isToday(new Date(utcDate))) {
      const diffSeconds = (Date.now() - d.valueOf()) / 1000;
      return this.convertSecondsToString(diffSeconds);
    }

    return this.$filter('intlDate')(d, { hour: 'numeric', minute: 'numeric' });
  }

  convertLabelText(utcDate: string): string {
    const d = new Date(utcDate);

    if (Object.prototype.toString.call(d) !== '[object Date]' || isNaN(d.getTime())) return '';

    if (this.HelperService.isToday(d)) {
      return this.$translate.instant('global.today');
    }
    if (this.HelperService.isYesterday(d)) {
      return this.$translate.instant('global.yesterday');
    }

    return this.$filter('intlDate')(d, {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  markAll(): void {
    this.NotificationCentreService.readNotification().then(() => {
      this.NotificationCentreService.markAllRead();
      this.assignGroupedNotifications(this.notifications);
    });
  }

  onCtaClick(ctaLink: string, notificationId: string, readAt: string, ctaText: string): void {
    if (!readAt) {
      this.NotificationCentreService.readNotification({
        notification_id: notificationId,
      }).then(() => {
        this.NotificationCentreService.markOneAsRead(notificationId);
        this.assignGroupedNotifications(this.notifications);
      });
    }

    const paths = ctaLink.split('?');
    if (paths.length > 1) {
      const queryParams = this.HelperService.convertQueryParamsToJson(paths[1]);
      if (this.$location.path() === paths[0]) {
        this.$state.go(
          this.$state.current.name!,
          { ...queryParams, from_request_pickup: undefined, from_handover: undefined },
          { reload: true }
        );
      } else {
        this.$location.path(paths[0]).search(queryParams);
      }
    } else {
      this.$location.url(ctaLink);
    }

    this.esOnCtaClick();

    this.NotificationCentreService.onMixpanelCta(ctaText);
  }

  onDelete(notificationId: string): void {
    this.NotificationCentreService.deleteNotifications({
      notification_id: notificationId,
    }).then(() => {
      this.NotificationCentreService.removeNotification(notificationId);
    });
  }

  onExpand(notificationId: string, readAt: string): void {
    if (!readAt && !this.expandIds.includes(notificationId)) {
      this.expandIds.push(notificationId);
    }
  }

  onOffRowClick(notificationId: string): void {
    if (this.expandIds.includes(notificationId)) {
      this.NotificationCentreService.readNotification({
        notification_id: notificationId,
      }).then(() => {
        this.NotificationCentreService.markOneAsRead(notificationId);
        this.assignGroupedNotifications(this.notifications);
        this.expandIds.splice(this.expandIds.indexOf(notificationId), 1);
      });
    }
  }

  formatPickupMessage(message: string, scheduledDate: string): string {
    if (!scheduledDate) {
      return message;
    }

    return message.replace(
      PICKUP_SCHEDULED_DATE,
      this.$filter('intlDate')(scheduledDate, { dateStyle: 'long' })
    );
  }

  get notifications(): INotification[] {
    return this.NotificationCentreService.notifications;
  }

  get isEmpty(): boolean {
    return this.NotificationCentreService.unreadCount <= 0;
  }

  get isGroupedNotificationsEmpty(): boolean {
    return this.groupedNotifications && this.groupedNotifications.length <= 0;
  }

  get canChangeSettings(): boolean {
    return this.UserSession.hasUserRole('account_and_settings');
  }

  private fetchNotifications(): void {
    this.loading = true;
    this.NotificationCentreService.fetchNotifications().finally(() => {
      this.loading = false;
    });
  }

  private convertSecondsToString(seconds: number): string {
    if (seconds < 60) {
      return this.$translate.instant('global.now');
    }
    if (seconds < 3600) {
      return this.$translate.instant(
        'global.pluralize.with-count.minute-abbr',
        { count: Math.floor(seconds / 60) },
        'messageformat'
      );
    }

    return this.$translate.instant(
      'global.pluralize.with-count.hour-abbr',
      { count: Math.floor(seconds / 60 / 60) },
      'messageformat'
    );
  }

  private groupSortingNotifications(
    notifications: INotification[]
  ): IGroupedArray<INotification>[] {
    return this.HelperService.groupArrayByKey<INotification, INotification>(
      notifications,
      CREATED_AT_DATE
    ).sort((a, b) => new Date(b.display).getTime() - new Date(a.display).getTime());
  }

  private assignGroupedNotifications(data: INotification[]): void {
    this.groupedNotifications = this.groupSortingNotifications(data);
  }
}

const NotificationCentrePanelComponent: ng.IComponentOptions = {
  controller: NotificationCentrePanel,
  template,
  bindings: {
    esOnCtaClick: '&',
  },
};

export { NotificationCentrePanelComponent };
