import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { ShipmentData, ShipmentFormData } from '@client/core/corelogic/models/Shipment';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import {
  getBoxWeightUnitCount,
  mapParcelModifiedDataToMixpanel,
} from './mapParcelModifiedDataToMixpanel';
import {
  getShipmentItemCount,
  mapShipmentItemModifiedDataToMixpanel,
} from './mapShipmentItemModifiedDataToMixpanel';
import { SectionName } from './types';
import {
  mapDirtyFieldsToMixpanelData,
  mapSenderAddressModifiedFieldsToMixpanelData,
} from './mapDirtyFieldsToMixpanelData';

interface MixpanelShipmentModifiedData {
  easyship_shipment_id: string;
  easyship_company_id: string;
  platform_order_number: string;
  platform_name: string[];
  origin_country: string;
  destination_country: string;
  courier_umbrella: string;
  courier_service: string;
  total_item_count: number;
  unique_item_count: number;
  total_parcel_count: number;
  interface: 'Web';
  page_name: string;
  sections_modified: SectionName[];
  fields_modified: string[];
  box_weight_oz_count: number | undefined;
  box_weight_lb_count: number | undefined;
  box_weight_kg_count: number | undefined;
  box_weight_g_count: number | undefined;
  shipment_count: number;
  is_bulk: boolean;
  context?: string;
}

interface MapShipmentModifiedFieldsToMixpanelData {
  dirtyFields: FieldNamesMarkedBoolean<ShipmentFormData>;
  previousShipmentData: ShipmentData;
  newShipmentData: ShipmentData;
  companyId: string;
}
export function mapShipmentModifiedFieldsToMixpanelData({
  dirtyFields,
  previousShipmentData,
  newShipmentData,
  companyId,
}: MapShipmentModifiedFieldsToMixpanelData): MixpanelShipmentModifiedData {
  const sectionsModified = new Set<SectionName>();
  const modifiedOrderFields = mapDirtyFieldsToMixpanelData({
    dirtyFields,
    section: 'shipment',
  });

  const modifiedSenderDetailFields = mapSenderAddressModifiedFieldsToMixpanelData({
    dirtyFields,
  });

  const modifiedReceiverDetailFields = mapDirtyFieldsToMixpanelData({
    dirtyFields,
    section: 'receiver',
  });
  const {
    modifiedParcelFields,
    totalParcelCount,
    boxWeightOzCount,
    boxWeightLbCount,
    boxWeightKgCount,
    boxWeightGCount,
  } = mapParcelModifiedDataToMixpanel({
    previousShipmentData,
    newShipmentData,
  });

  const { modifiedShipmentItemFields, totalItemCount, uniqueItemCount } =
    mapShipmentItemModifiedDataToMixpanel({
      previousShipmentData,
      newShipmentData,
    });

  if (modifiedOrderFields.length > 0) {
    sectionsModified.add('Shipment details');
  }

  if (modifiedParcelFields.length > 0) {
    sectionsModified.add('Parcel details');
  }

  if (modifiedShipmentItemFields.length > 0) {
    sectionsModified.add('Item details');
  }

  if (modifiedSenderDetailFields.length > 0) {
    sectionsModified.add('Sender details');
  }

  if (modifiedReceiverDetailFields.length > 0) {
    sectionsModified.add('Receiver details');
  }

  return {
    easyship_shipment_id: newShipmentData.easyshipId,
    easyship_company_id: companyId,
    platform_order_number: newShipmentData.order.platform.orderNumber,
    platform_name: [newShipmentData.order.platform.name],
    origin_country: newShipmentData.senderAddress.country.name,
    destination_country: newShipmentData.receiverAddress.country.name,
    courier_umbrella: newShipmentData.courier.umbrellaName ?? '',
    courier_service: newShipmentData.courier.name ?? '',
    total_item_count: totalItemCount,
    unique_item_count: uniqueItemCount,
    total_parcel_count: totalParcelCount,
    box_weight_oz_count: boxWeightOzCount,
    box_weight_lb_count: boxWeightLbCount,
    box_weight_kg_count: boxWeightKgCount,
    box_weight_g_count: boxWeightGCount,
    interface: 'Web',
    page_name: window.location.pathname,
    is_bulk: false,
    shipment_count: 1,
    sections_modified: [...sectionsModified],
    fields_modified: [
      ...modifiedOrderFields,
      ...modifiedParcelFields,
      ...modifiedShipmentItemFields,
      ...modifiedSenderDetailFields,
      ...modifiedReceiverDetailFields,
    ],
    context: 'shipment drawer',
  };
}

const MAP_MODIFIED_FIELDS_AND_SECTION = {
  'Courier / Selected': 'Courier',
  'Shipment details / Tax and Duty': 'Shipment details',
  'Shipment details / Return label': 'Shipment details',
} as const;

interface SendSpecificModifiedFieldToMixpanel {
  newShipmentData: ShipmentData;
  companyId: string;
  fieldModified: keyof typeof MAP_MODIFIED_FIELDS_AND_SECTION;
}
export function sendSpecificModifiedFieldToMixpanel({
  newShipmentData,
  companyId,
  fieldModified,
}: SendSpecificModifiedFieldToMixpanel): void {
  const { totalItemCount, uniqueItemCount } = getShipmentItemCount(newShipmentData);
  const boxWeightUnitCount = getBoxWeightUnitCount(newShipmentData.order.parcels);

  const mixpanelShipmentModifiedData: MixpanelShipmentModifiedData = {
    easyship_shipment_id: newShipmentData.easyshipId,
    easyship_company_id: companyId,
    platform_order_number: newShipmentData.order.platform.orderNumber,
    platform_name: [newShipmentData.order.platform.name],
    origin_country: newShipmentData.senderAddress.country.name,
    destination_country: newShipmentData.receiverAddress.country.name,
    courier_umbrella: newShipmentData.courier.umbrellaName ?? '',
    courier_service: newShipmentData.courier.name ?? '',
    total_item_count: totalItemCount,
    unique_item_count: uniqueItemCount,
    total_parcel_count: newShipmentData.order.parcels.length,
    interface: 'Web',
    page_name: window.location.pathname,
    sections_modified: [MAP_MODIFIED_FIELDS_AND_SECTION[fieldModified]],
    fields_modified: [fieldModified],
    box_weight_oz_count: boxWeightUnitCount.oz || undefined,
    box_weight_lb_count: boxWeightUnitCount.lb || undefined,
    box_weight_kg_count: boxWeightUnitCount.kg || undefined,
    box_weight_g_count: boxWeightUnitCount.g || undefined,
    is_bulk: false,
    shipment_count: 1,
    context: 'shipment drawer',
  };

  MixpanelService.track('Shipment Modified', mixpanelShipmentModifiedData);
}
