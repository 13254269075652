import angular from 'angular';
import { AngularEmailUnverifiedBanner } from './EmailUnverifiedBanner';
import { AngularOtpFormModal } from './OtpFormModal';

const MODULE_NAME = 'app.verification.verification-components';

angular
  .module(MODULE_NAME, [])
  .component('esEmailUnverifiedBanner', AngularEmailUnverifiedBanner)
  .component('esOtpFormModal', AngularOtpFormModal);

export default MODULE_NAME;
