import { Brokers } from 'typings/brokers';
import { IComponentController } from 'angular';
import template from './broker-card.html?raw';
import style from './broker-card.module.scss';

class BrokerCardController implements IComponentController {
  style = style;
  esBrokerDetails: Brokers.IBroker | null = null;

  static $inject = ['CourierDetails'];
  constructor(private CourierDetails: any) {}

  $onInit() {}

  editBroker() {
    this.CourierDetails.fedexBrokerFormShow = 'edit-fedex-broker';
    this.CourierDetails.selectedBroker = this.esBrokerDetails;
  }

  deleteBroker() {
    this.CourierDetails.fedexBrokerFormShow = 'delete-fedex-broker';
    this.CourierDetails.selectedBroker = this.esBrokerDetails;
  }
}

const BrokerCardComponent = {
  controller: BrokerCardController,
  template,
  bindings: {
    esBrokerDetails: '<',
  },
};

export { BrokerCardComponent };
