(function () {
  'use strict';

  angular.module('easyshipDashboardApp').controller('OnboardingCtrl', OnboardingCtrl);

  OnboardingCtrl.$inject = ['$state', '$rootScope', 'UserSession', 'WelcomeService'];

  function OnboardingCtrl($state, $rootScope, UserSession, WelcomeService) {
    var vm = this;

    vm.redirectToPage = function (res) {
      UserSession.updateOnboarding(res);

      $state.go('app.home', { onboarding: true });
      $rootScope.$broadcast('onboarding-updated');
      WelcomeService.runHomepageWalkthrough();
    };
  }
})();
