import { useMutation } from '@tanstack/react-query';
import { IOnboardingParams } from 'typings/onboarding';
import { useOnboardingGateway } from '../gateways/api/OnboardingGatewayProvider';

export function useOnboardingMutation() {
  const { onboardingGateway } = useOnboardingGateway();

  return useMutation({
    mutationFn: (payload: IOnboardingParams) => onboardingGateway.update(payload),
  });
}
