'use strict';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.multiple', {
      url: '^/advanced?page&shipment_id&valid_to_ship&from_request_pickup&tour&brandingtour&sidebaropen&createtour&appcue&view_id&sync_first_store',
      component: 'createShipmentsAdvanced',
      params: {
        reload: false,
        query: {},
        runWalkthrough: '',
      },
      reloadOnSearch: false,
      resolve: {
        previousState: [
          'uibRoutingOptions',
          (uibRoutingOptions) => {
            return uibRoutingOptions.previousState();
          },
        ],
      },
      data: {
        requiredUserRight: 'menu.createShipments.multiple',
      },
    });
  },
]);
