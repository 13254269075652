(function () {
  'use strict';

  WalkthroughService.$inject = [
    '$timeout',
    '$q',
    'UserSession',
    'WalkthroughData',
    'WalkthroughTooltipService',
    'User',
    'walkthroughModal',
    'MixpanelService',
    '$window',
  ];
  /**
   * [WalkthroughService]
   * The entire app WalkThrough is separated in "mini-walktroughs" called "flows"
   *
   * Inside each "flow", there are "steps" that can be of three types:
   * - "sequence flows" (type: "sequence") are flows with multiple steps that need to be entirely done in order to disapear. They will explain how to perform relatively complex actions (i.e. fixing a shipment).
   * - "informative flows" (type: "info") are just tooltip explaining a simple concept with a "Got it" button.
   * - "modal flows" (type: "modal") are tutorial modals that behave like the "informative flows".
   *
   * The Walkthrough logic is separated in three blocks:
   * - runPageWalkthrough method that determines which walkthrough "flow" to show to the user
   * - runModalWalkthrough method that open a modal flow
   * - goToNextStep will go to the next available "step" in a specific "flow" or update user.flow_records.walkthrough to set the flow as done and run runPageWalkthrough again to find the next "flow" and so on...
   *
   * Flows available to the user to see are the one that are NOT present in UserSession.user.flow_records.walkthrough
   */
  function WalkthroughService(
    $timeout,
    $q,
    UserSession,
    WalkthroughData,
    WalkthroughTooltipService,
    User,
    walkthroughModal,
    MixpanelService,
    $window
  ) {
    var service = this;
    service.isHomepage = $window.location.pathname === 'dashboard';
    service.walkthroughFlow = {
      courierComparisonTable: 'courier-comparison-table',
      pickups: 'pickups',
    };

    /**
     * [initData] Get all flows data that are stored in WalkthroughData
     */
    service.initData = function () {
      service.allFlows = WalkthroughData.getAllFlows();
      service.walkthroughPageData = WalkthroughData.getAllWalkthroughPagesData();
    };

    /**
     * [runWalkthrough] Determines which walktrough to show to the user
     *
     * Check if the user can see a walkthrough at all: UserSession.user.show_walkthrough
     *
     * For each flow:
     *  1. Has the user seen this flow, has it been "snooze" or are they any other flow that need to be done before showing this one
     *  2. Does the shipment/element matches the condition to show this flow?
     *  3a. If yes, show the flow by setting its "active" value to true and run goToNextStep
     *  3b. If no, run runPageWalkthrough again skipping this flow.
     *
     * @param  {String} pageName: the page where you want to show a walkthrough
     * @param  {Object} options
     */
    service.runPageWalkthrough = function (pageName, options) {
      var options = options || {};

      options.pageName = pageName;

      // If the flow data is not loaded, load it. TODO: add promise to make sure the data is there when it will be moved to a yaml in the backend
      if (!service.allFlows || !service.walkthroughPageData) service.initData();

      // If the user is not allowed to see a walkthrough, return
      if (!UserSession.user.show_walkthrough) return;

      service.activeWalkthroughPage = pageName;

      // Make sure no tooltips are present
      WalkthroughTooltipService.destroyAllVisibleTooltips();

      $timeout(function () {
        if (isPageConditionMatched(pageName)) return findAndExecuteNextFlow(options);
      });
    };

    /**
     * Check to see if a particular flow should be shown to the user
     * @param {String} flowName
     *
     * @return {Boolean}
     */
    service.shouldFlowBeShown = function (flowName) {
      const hasWalkthrough =
        UserSession.user &&
        UserSession.user.flow_records &&
        UserSession.user.flow_records.walkthrough &&
        !UserSession.user.flow_records.walkthrough[flowName];
      const isNotSnoozed =
        service.allFlows && service.allFlows[flowName] && !service.allFlows[flowName].snooze;

      return hasWalkthrough && isNotSnoozed && flowNeedsExplicitMention(flowName);
    };

    /**
     * Check if the page walktrough has some conditions to be ran defined in the WalkthrougData factory
     * @param {String} pageName
     *
     * @return {Boolean}
     */
    function pageHasNoWalktroughConditionsDefined(pageName) {
      return (
        !service.walkthroughPageData[pageName] ||
        !service.walkthroughPageData[pageName].hasOwnProperty('matchesPageCondition')
      );
    }

    /**
     * Check if the conditions are matched for this page and this flow
     *
     * @param {String} pageName
     *
     * @return {Boolean}
     */
    function isPageConditionMatched(pageName) {
      // If the page does not have any specific conditions return true
      if (pageHasNoWalktroughConditionsDefined(pageName)) {
        return true;
      } else {
        return service.walkthroughPageData[pageName].matchesPageCondition();
      }
    }

    /**
     * Gets a list of all flows for this page
     *
     * @param {String} pageName
     *
     * @return {Array} Array of flow names as strings
     */
    function getFlowListForPage(pageName) {
      var pageFlows = {};

      for (var flowName in service.allFlows) {
        if (service.allFlows[flowName].pages.indexOf(pageName) > -1) {
          pageFlows[flowName] = service.allFlows[flowName];
        }
      }

      return Object.keys(pageFlows);
    }

    /**
     * Find the first flow eligible for the active page and run it
     *
     * @param {Object} options
     */
    function findAndExecuteNextFlow(options) {
      var flows = getFlowListForPage(service.activeWalkthroughPage);

      for (var i = 0; i < flows.length; i++) {
        // 1. Has the user seen this flow, has it been "snooze" or are they any other flow that need to be done before showing this one?
        if (flows[i] && flowValidToRun(flows[i])) {
          executeFlow(flows[i], options);
          break;
        }
      }
    }

    /**
     * Run a flow:
     * - Make sure the consitions to run it are passed:
     *   - run goToNextStep to activate it if passed
     *   - re run findAndExecuteNextFlow to find the next flow
     *
     * @param {String} flowName
     * @param {Object} options
     */
    function executeFlow(flowName, options) {
      if (!service.allFlows[flowName]) return;
      var conditionMatched;

      if (service.allFlows[flowName].hasOwnProperty('matchesFlowCondition')) {
        conditionMatched = service.allFlows[flowName].matchesFlowCondition(options.pageName);
      } else {
        conditionMatched = true;
      }

      // 2. Does the shipment/element matches the condition to show this flow?
      if (conditionMatched) {
        // 3a. If yes, show the flow by seting its "active" value to true and run goToNextStep
        service.allFlows[flowName].active = true;
        service.goToNextStep(flowName, null, options);
      } else {
        // 3b. If no, run runPageWalkthrough again skipping this flow using "snooze"
        if (!service.allFlows[flowName].active) {
          service.allFlows[flowName].snooze = true;
          findAndExecuteNextFlow(options);
        }
      }
    }

    /**
     * [runModalWalkthrough]
     * If the conditions are matched, will open a modal containing a tutorial
     *
     * @param  {String} flow
     */
    service.runModalWalkthrough = function (flow, pageName, options) {
      var options = options || {};

      // If the flow data is not loaded, load it.
      if (!service.allFlows) service.initData();

      // If the user is not allowed to see a walkthrough, return
      if (!UserSession.user.show_walkthrough) return;

      service.activeWalkthroughPage = pageName;

      if (flowValidToRun(flow)) {
        service.allFlows[flow].active = true;
        service.goToNextStep(flow, null, options);
      }
    };

    /**
     * [closeBannerInfo]
     * If the conditions are matched, will open a modal containing a tutorial
     *
     * @param  {String} flow
     */
    service.closeBannerInfo = function (flow) {
      // If the flow data is not loaded, load it.
      if (!service.allFlows) service.initData();

      // If the user is not allowed to see a walkthrough, return
      if (!UserSession.user.show_walkthrough) return;

      if (flow) {
        service.updateUserFlowRecords('walkthrough', flow);
        $('body').removeClass('show-announcement');
      }
    };

    /**
     * [flowValidToRun]
     * Should this flow be ran?
     * - user did not see this walkthrough yet (not in UserSession.user.flow_records.walkthrough)
     * - this flow has not been snoozed
     * - all dependent flows are done
     *
     * @param  {String} flow: flow name
     *
     * @return {Boolean}
     */
    function flowValidToRun(flow) {
      return (
        !UserSession.user.flow_records.walkthrough[flow] &&
        !service.allFlows[flow].snooze &&
        dependentFlowsAreDone(flow) &&
        flowNeedsExplicitMention(flow)
      );
    }

    function flowNeedsExplicitMention(flow) {
      if (!service.allFlows) service.allFlows = WalkthroughData.getAllFlows();

      if (service.allFlows[flow].triggerOnExplicitMention)
        return typeof UserSession.user.flow_records.walkthrough[flow] === 'boolean';
      return true;
    }

    /**
     * [dependentFlowsAreDone]
     * Go through the dependencies of each flow and check if the user has seen the dependent flows
     *
     * @param  {String} flow name
     *
     * @return {Boolean}
     */
    function dependentFlowsAreDone(flow) {
      // If this flow does not have dependencies, return true
      if (!service.allFlows[flow].dependencies) return true;

      // Gather a list of flows left to do
      var flowsLeft = _.reduce(
        service.allFlows[flow].dependencies,
        function (arr, value, key) {
          if (!UserSession.user.flow_records.walkthrough[key]) arr.push(key);
          return arr;
        },
        []
      );

      // If there are no flows left to do, return true, else return false;
      return flowsLeft.length === 0;
    }

    /**
     * [goToNextStep] in a specific "flow", will check what is the next step to display
     * Will be triggered either in a specific page walkthrough init or by events (click, form change...) according to the user actions
     * 1a. If there is no stepId, set the first step as active and return
     *
     * 1b. If there is a stepId, will set this step (stepId) as done and inactive
     * 2. if the passed step is not the last one, will set the next step as active
     * 3. if the passed step is the last one:
     *     i.  will make an API call to update the user flow_records.walkthrough array
     *     ii. run runPageWalkthrough again to find the next flow (first flow that is not in the flow_records.walkthrough array)
     *
     * @param  {String} flow: flow name
     * @param  {String} stepName: current step
     */
    service.goToNextStep = function (flow, stepName, options) {
      if (!flow) return;

      var options = options || {};
      var nextStepName;

      if (stepName) {
        // Set stepName (current step) as inactive and done
        service.allFlows[flow].steps[stepName].active = false;
        service.allFlows[flow].steps[stepName].done = true;
      }

      nextStepName = findNextStepName(flow, stepName);

      // If there is still a next step, set it as active
      if (nextStepName) {
        if (nextStepName === 'connect-store-incentive-modal' && service.isHomepage) {
          service.allFlows['connect-store-incentive'].steps[
            'connect-store-incentive-modal'
          ].active = false;
          service.allFlows['connect-store-incentive'].steps[
            'connect-store-incentive-modal'
          ].done = true;
        } else {
          service.allFlows[flow].steps[nextStepName].active = true;
        }

        // If the step has a type 'modal', open the defult wakthrough modal
        if (isModalStep(flow, nextStepName)) {
          if (nextStepName === 'connect-store-incentive-modal' && service.isHomepage) {
            finishFlow(flow, options, service.isHomepage);
          } else {
            _openModal(flow, nextStepName, options);
          }
        }
        // If the flow allows the next tooltip to open, call showStepTooltip
        else if (!options.preventTooltipOpening) {
          showStepTooltip(flow, nextStepName, service.allFlows[flow].steps[nextStepName], options);
        }
      }
      // else it means that this flow is done
      else {
        finishFlow(flow, options, service.isHomepage);
      }
    };

    function _openModal(flow, nextStepName, options) {
      if (options.callback) {
        options.callback();
      } else {
        walkthroughModal.open({ flow: flow, nextStepName: nextStepName, options: options });
      }
    }

    /**
     * [unsnoozeAll] Allow to reset the snooze status of all flows if needed
     * Sometimes the flows are automatically snoozed because no conditions are matched nut the user still needs to see it later
     */
    service.unsnoozeAll = function () {
      for (var flow in service.allFlows) {
        service.allFlows[flow].snooze = false;
      }
    };

    /**
     * [unsnooze] Set snooze to false for a specific flow
     * @param {String} flow: flow to unsnooze
     */
    service.unsnooze = function (flow) {
      service.allFlows[flow].snooze = false;
    };

    /**
     * Within a given flow, find the next step available after the one mentionned (stepName)
     * if no stepName is specified, take the first step of the flow
     *
     *
     * @param {String} flow
     * @param {String} stepName
     */
    function findNextStepName(flow, stepName) {
      var flowStepNameList = Object.keys(service.allFlows[flow].steps);
      var nextStepName;
      var nextStepIndex;

      // No stepName is passed -> next step is the first in the list
      if (!stepName) {
        MixpanelService.track('Walkthrough - flow start', {
          flow_name: flow,
          page: service.activeWalkthroughPage,
        });

        nextStepIndex = 0;
      }
      // Find the step in the list
      else {
        // Find next step index
        nextStepIndex = _.indexOf(flowStepNameList, stepName) + 1;
      }

      // Get next step name with the index
      nextStepName = flowStepNameList[nextStepIndex];

      return nextStepName;
    }

    /**
     * [showStepTooltip] call showStepTooltip from the WalkthroughTooltipService to build the tippy instance and show it manually
     * then bindClickEventToTooltipButton
     *
     * @param  {String} flow
     * @param  {String} stepName
     * @param  {Object} stepObject
     */
    function showStepTooltip(flow, stepName, stepObject, options) {
      WalkthroughTooltipService.showStepTooltip(flow, stepName, stepObject).then(function () {
        if (stepObject.concurrentAction) stepObject.concurrentAction();

        $timeout(function () {
          bindClickEventToTooltipButton(flow, stepName, stepObject, options);
        });
      });
    }

    /**
     * [bindClickEventToTooltipButton]
     * According to the flow, stepName and tooltip type, bind a click function to the actionnable part of the tooltip
     *
     * @param  {String} flow
     * @param  {String} stepName
     * @param  {Object} stepObject
     */
    function bindClickEventToTooltipButton(flow, stepName, stepObject, options) {
      var targetId;

      // If it is a 'sequence' tooltip, the action will be snooze
      if (stepObject.type === 'sequence') {
        // Structure of the targeted id is defined in WalkthroughTooltipService sequenceTooltipTemplate()
        $('body').on('click', '#snooze-' + flow, function () {
          service.snoozeFlow(flow);
        });
      }

      // If it is an 'info' tooltip, the action will be on a 'Got it' button and will go to the next step
      else if (stepObject.type === 'info') {
        // Structure of the targeted id is defined in WalkthroughTooltipService infoTooltipTemplate()
        var data = { flow: flow, stepName: stepName, stepObject: stepObject, options: options };

        $('#gotit-' + flow + '-' + stepName).on('click', data, function (event) {
          var options = event.data.options || {};

          if (
            service.allFlows[event.data.flow].steps[event.data.stepName].preventNextTooltipOpening
          ) {
            event.data.options = Object.assign(event.data.options, {
              preventTooltipOpening: true,
            });
          }

          service.goToNextStep(event.data.flow, event.data.stepName, event.data.options);
        });
      }

      // Temporary fallback
      else {
        return;
      }
    }

    /**
     * [finishFlow]
     * - Close every tooltips
     * - set the flow as inactive
     * - Update the user flow_records.walkthrough
     * - Re-run runPageWalkthrough to find the next flow
     *
     * @param  {String} flow: name of the flow to finsih
     */
    function finishFlow(flow, options) {
      // Close every tooltips
      WalkthroughTooltipService.destroyAllVisibleTooltips();

      // set the flow as inactive
      service.allFlows[flow].active = false;

      // Update flow records
      service
        .updateUserFlowRecords('walkthrough', flow)
        .then(function (data) {
          UserSession.updateFlowRecords(data.user.flow_records);

          // Send mixpanel event
          MixpanelService.track('Walkthrough - flow completed', {
            flow_name: flow,
            page: service.activeWalkthroughPage,
          });

          if (!isHomepage) {
            // Re-run runPageWalkthrough to find the next flow
            service.runPageWalkthrough(service.activeWalkthroughPage, options);
          }
        })
        .catch(function (err) {
          // Maybe snooze?
        });
    }

    /**
     * [snoozeFlow] Allow to skip a flow without marking it as done
     * - Set flow as inactive and set snooze as true
     * - Set all steps as inactive for this flowxw
     * - Re-run runPageWalkthrough to find the next flow
     *
     * @param  {String} flow: name of flow to skip
     */
    service.snoozeFlow = function (flow) {
      // Set flow as inactive and set snooze as true
      service.allFlows[flow].active = false;
      service.allFlows[flow].snooze = true;

      // Send mixpanel event
      MixpanelService.track('Walkthrough - flow snoozed', buildMixpanelSnoozeData(flow));

      // Set all steps as inactive for this flow
      for (var step in service.allFlows[flow].steps) {
        if (service.allFlows[flow].steps.hasOwnProperty(step)) {
          service.allFlows[flow].steps[step].active = false;
        }
      }

      // Re-run runPageWalkthrough to find the next flow
      service.runPageWalkthrough(service.activeWalkthroughPage);
    };

    /**
     * [buildMixpanelSnoozeData]
     * Find active step in flow and return mixpanel data:
     * {
        flow_name
        step_name
        step_number
        tooltip_type
        page
      }
     *
     * @param  {String} flow: flow name
     *
     * @return {Object} mixpnale data
     */
    function buildMixpanelSnoozeData(flow) {
      if (!flow) return;
      if (!service.allFlows[flow] || !service.allFlows[flow].steps) return;

      var activeStepName;
      var activeStepObject;

      // Find active step within this flow
      for (var step in service.allFlows[flow].steps) {
        if (service.allFlows[flow].steps[step].active) {
          activeStepName = step;
          activeStepObject = service.allFlows[flow].steps[step];
          break;
        }
      }

      if (!activeStepName || !activeStepObject) return;

      return {
        flow_name: flow,
        step_name: activeStepName,
        step_number:
          activeStepObject.stepNumber + '/' + Object.keys(service.allFlows[flow].steps).length,
        tooltip_type: activeStepObject.type,
        page: service.activeWalkthroughPage,
      };
    }

    /**
     * [resetFlow] Rest a flow to a specific step
     *
     * @param  {String} flow: name of flow to reset
     * @param  {String} step: name of the step to reset to
     */
    service.resetFlowToStep = function (flow, resetStep) {
      if (!flow) return;

      // Set all steps as inactive for this flow
      for (var step in service.allFlows[flow].steps) {
        if (service.allFlows[flow].steps.hasOwnProperty(step)) {
          service.allFlows[flow].steps[step].active = false;
        }
      }

      // Make sure all tooltips are hidden
      WalkthroughTooltipService.destroyAllVisibleTooltips();

      // Active a step of the flow
      var stepToGoTo;
      //
      // if resetStep is present
      if (resetStep) {
        stepToGoTo = resetStep;
      }
      // else pickup the first step of the flow
      else {
        var flowStepNameList = Object.keys(service.allFlows[flow].steps);
        stepToGoTo = flowStepNameList[0];
      }

      // Activate step
      service.allFlows[flow].steps[stepToGoTo].active = true;

      // Show tooltip accordingly
      $timeout(function () {
        showStepTooltip(flow, stepToGoTo, service.allFlows[flow].steps[stepToGoTo]);
      });
    };

    /**
     * [updateUserFlowRecords]
     * Call updateFlowRecords endpoint to update the user
     *
     * @param {String} flowType: 'walkthrough' or 'page_view'
     * @param {String} flowName: flow to be updated
     *
     * @return {Promise}
     */
    service.updateUserFlowRecords = function (flowType, flowName) {
      return $q(function (resolve, reject) {
        // TEMP to remove after the full refactoring of the shipment pages (avoiding code conflicts)
        if (flowType === 'page_view') {
          resolve();
          return;
        }

        UserSession.user.flow_records.walkthrough[flowName] = true;

        User.updateFlowRecords(
          { id: UserSession.user.id },
          { flow_record: { flow_type: flowType, flow_name: flowName } },
          resolve,
          reject
        );
      });
    };

    service.walkthroughResetAfterCloseAllField = function () {};

    service.runOptionalDimensionsWalkthrough = function (
      runWalkthrough,
      totals,
      isDimensionsModalServiceOpened,
      callback
    ) {
      // Add timeout delay to have the transition smoother
      $timeout(function () {
        if (
          runWalkthrough === 'order-sync-with-missing-dimensions' &&
          totals &&
          totals.missing_dimensions_count &&
          !isDimensionsModalServiceOpened
        ) {
          service.runModalWalkthrough('order-sync-with-missing-dimensions', 'multiple', {
            callback: function () {
              if (callback) {
                return callback();
              }
            },
          });
        }
      });
    };

    service.actionAfterUpdateOrderForm = function (isValidToShip) {};

    service.closeFieldActionWalkthrough = function () {};

    service.toggleShipmentWalkthrough = function (index) {};

    /**
     * [isModalStep]
     * is this step using a modal (type: 'modal')
     *
     * @param  {String}  flow
     * @param  {String}  stepName
     *
     * @return {Boolean}
     */
    function isModalStep(flow, stepName) {
      return service.allFlows[flow].steps[stepName].type === 'modal';
    }
  }

  angular
    .module('app.service.WalkthroughService', [
      'app.factory.WalkthroughData',
      'app.factory.UserSession',
      'app.service.WalkthroughTooltipService',
      'app.service.walkthrough-modal',
      'app.global.user',
      'core.service.mixpanel',
    ])
    .service('WalkthroughService', WalkthroughService);
})();
