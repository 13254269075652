import angular from 'angular';
import { ICompanyService } from 'typings/company';
import ICompanyGateway from '@client/core/corelogic/ports/company.interface';
import { IUser } from 'typings/user-session';

export default class NgCompanyGateway implements ICompanyGateway {
  private companyService: ICompanyService;

  constructor() {
    this.companyService = angular
      .element(document.body)
      .injector()
      .get<ICompanyService>('CompanyService');
  }

  getCompanyUsers(): Promise<IUser[]> {
    return new Promise((resolve, reject) => {
      this.companyService
        .getCompanyUsers()
        .then((data: { users: IUser[] }) => resolve(data.users), reject);
    });
  }
}
