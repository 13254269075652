import template from './courier-details-main.html?raw';
import style from './courier-details-main.module.scss';

class CourierDetailsMain {
  static $inject = ['CourierDetails', 'CourierAccounts'];

  constructor(CourierDetails, CourierAccounts) {
    this.STEP_UPDATE = 'update';
    this.STEP_DELETE = 'delete';

    this.style = style;
    this.step = this.STEP_UPDATE;
    this.CourierDetails = CourierDetails;
    this.CourierAccounts = CourierAccounts;
  }

  $onInit() {
    this.showFedexEula =
      this.courier.logo_url === 'fedex' && !this.courier.fedex_compatible_eula_signed;
  }

  goToDeleteStep() {
    this.step = this.STEP_DELETE;
  }

  goToEditStep() {
    this.step = this.STEP_UPDATE;
    this.CourierDetails.fedexBrokerFormShow = null;
  }

  agreeFedexEula() {
    this.step = this.STEP_UPDATE;
  }
}

const CourierDetailsMainComponent = {
  controller: CourierDetailsMain,
  template,
  bindings: {
    courier: '<',
  },
};

export { CourierDetailsMainComponent };
