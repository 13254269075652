import template from './manifest-modal.html?raw';
import { ManifestModalCtrl } from './manifest-modal.controller';

class ManifestModal {
  static $inject = ['$uibModal', 'UserSession', 'ManifestService'];

  constructor($uibModal, UserSession, ManifestService) {
    this.$uibModal = $uibModal;
    this.UserSession = UserSession;
    this.ManifestService = ManifestService;
  }

  open() {
    this.modalReference = this.$uibModal.open({
      template,
      controller: ManifestModalCtrl,
      controllerAs: 'vm',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { ManifestModal };
