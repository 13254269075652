'use strict';
import template from './add-shipment.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('add-shipment', {
      url: '/add-shipment',
      parent: 'app.single',
      template,
      controller: 'SingleShipmentAddShipmentCtrl',
      params: {
        shipment: null,
      },
    });
  },
]);
