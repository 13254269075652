import { IInputGroupRadioModel } from 'typings/form';
import { IGroupedArray } from 'typings/helper';
import { IAdvancedSearchMultiselectObject } from 'typings/advanced-search';

import { CollectStateScopeValue } from '@client/src/global/services/shipment-list/data/normalize';
import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import template from './collect-state-multi-select-group.html?raw';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class CollectStateMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchMultiselectObject>
  implements IComponentController
{
  defaultFilters: IGroupedArray<IAdvancedSearchMultiselectObject>[] = [];
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions = [];

  static $inject = ['$scope', '$translate'];
  constructor($scope: ng.IScope, private $translate: angular.translate.ITranslateService) {
    super($scope);
  }

  $onInit(): void {
    const noun = this.$translate.instant('shipments.filter.collection-status.title');
    const $t = (key: string) => {
      return this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    };
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];

    this.assignDefaultRadioModel();
  }

  $onChanges(changes: any): void {
    if (this.defaultFilters.length === 0) {
      const $t = (key: string) => {
        return this.$translate.instant(key);
      };

      this.defaultFilters = [
        {
          display: $t('global.all'),
          value: '',
          options: [
            {
              display: $t('global.none'),
              value: CollectStateScopeValue.None,
              isDisplay: true,
            },
            {
              display: $t('shipments.collection.to_be_collected'),
              value: CollectStateScopeValue.ToCollect,
              isDisplay: true,
            },
            {
              display: $t('shipments.collection.collected'),
              value: CollectStateScopeValue.Collected,
              isDisplay: true,
            },
          ],
        },
      ];
    }

    this.triggerChangeIfKnowOrUnknowModel(changes);
    this.assignDefaultSelectValue(this.esModelOptions || this.defaultFilters);
  }
}

const CollectStateMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: CollectStateMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { CollectStateMultiSelectGroupComponent };
