import template from './item-row-header.html?raw';
import style from './item-row-header.module.scss';

class ItemRowHeader {
  constructor() {
    this.style = style;
  }
}

const ItemRowHeaderComponent = {
  template,
  transclude: true,
  controller: ItemRowHeader,
};

export { ItemRowHeaderComponent };
