import angular from 'angular';

import subscriptionService from '@client/src/global/services/subscription';

import { LegacyTrialEndModalComponent } from './modal/legacy-trial-end-modal.component';
import { LegacyTrialEndCardComponent } from './legacy-trial-end-card/legacy-trial-end-card.component';

const MODULE_NAME = 'app.global.components.subscription-legacy-trial-end';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', subscriptionService])
  .component('esSubscriptionLegacyTrialEndModal', LegacyTrialEndModalComponent)
  .component('esSubscriptionLegacyTrialEndCard', LegacyTrialEndCardComponent);

export default MODULE_NAME;
