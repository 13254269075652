import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { CreateShippingDocumentsParams } from 'typings/shipment';
import { useShipmentGateway } from '../../providers/ShipmentGatewayProvider';

export default function useCreateShippingDocumentsMutation() {
  const { shipmentGateway } = useShipmentGateway();
  const { company } = useUserSession();

  return useMutation({
    mutationFn: ({ id, context }: Pick<CreateShippingDocumentsParams, 'id' | 'context'>) =>
      shipmentGateway.createShippingDocuments({
        company_id: company.id,
        company_type: 'cloud',
        id,
        context,
      }),
  });
}
