(function () {
  'use strict';

  LuxotticaService.$inject = ['$q', 'LuxotticaManagedShops', 'LuxotticaCreateSession'];

  function LuxotticaService($q, LuxotticaManagedShops, LuxotticaCreateSession) {
    var service = this;

    service.luxotticaManagedShopsList = [];
    service.selectedSearchAddress = '';

    service.queryLuxotticaManagedShops = function () {
      return $q(function (resolve, reject) {
        LuxotticaManagedShops.query(
          {},
          {},
          function (response) {
            service.luxotticaManagedShopsList = response.companies;
            resolve(response);
          },
          function (errors) {
            reject(errors);
          }
        );
      });
    };

    service.postLuxotticaSession = function (shopId) {
      var params = {
        company_id: shopId || null,
      };

      return $q(function (resolve, reject) {
        LuxotticaCreateSession.query(
          params,
          {},
          function (response) {
            resolve(response);
          },
          function (errors) {
            reject(errors);
          }
        );
      });
    };
  }

  angular
    .module('app.service.LuxotticaService', ['app.factory.Luxottica'])
    .service('LuxotticaService', LuxotticaService);
})();
