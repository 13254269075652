import template from './table.html?raw';
import style from './table.module.scss';

class Table {
  constructor() {
    this.style = style;
  }
}

const TableComponent = {
  template,
  controller: Table,
  transclude: {
    head: '?tableHead',
    body: '?tableBody',
  },
  bindings: {
    esLoading: '<',
    esError: '<',
  },
};

export { TableComponent };
