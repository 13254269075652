(function () {
  angular.module('easyshipDashboardApp').controller('InviteConfirmCtrl', InviteConfirmCtrl);

  InviteConfirmCtrl.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    'Auth',
    'Invitee',
    'CountryService',
    'MixpanelService',
    'WelcomeService',
  ];

  function InviteConfirmCtrl(
    $scope,
    $stateParams,
    $state,
    Auth,
    Invitee,
    CountryService,
    MixpanelService,
    WelcomeService
  ) {
    $scope.user = {};
    $scope.tokenVerified = false;
    $scope.success = false;
    $scope.fail = false;

    Invitee.checkInvitationToken(
      {},
      { email_confirmation_token: $stateParams.emailConfirmationToken },
      function () {
        $scope.tokenVerified = true;
      },
      function (err) {
        $scope.tokenFailed = true;
        $scope.errorMessage = err.data.status;
      }
    );

    /*
     * Check to see if the passwords match
     */
    $scope.$watch('user.password_confirmation', function (password_confirmation) {
      $scope.notMatched = password_confirmation !== $scope.user.password;
    });

    $scope.confirmInvite = function (event) {
      event.preventDefault();
      $scope.notMatched =
        $scope.confirmInviteForm.$$controls[3].$modelValue !==
        $scope.confirmInviteForm.$$controls[4].$modelValue;

      // NOTE: The *required attributes disable form submission, but ng-minlength is not...
      if ($scope.confirmInviteForm.$error.minlength || $scope.notMatched) return;

      $scope.busy = true;
      Auth.confirmInvitee(
        $stateParams.emailConfirmationToken,
        $scope.user,
        function (response) {
          if (response.user && response.company) {
            const { user, company } = response;

            MixpanelService.identify(user.id);
            MixpanelService.setPeople({
              $easyship_company_id: company.id,
              $user_permissions: (user.dashboard_permissions?.user_roles || [])
                .map(({ name }) => name)
                .join(),
              $email_domain: user.email.split('@')[1],
            });

            MixpanelService.track('Account Created', {
              id: user.id,
              email: user.email,
              first_name: user.first_name,
              last_name: user.last_name,
              company_id: company.id,
              company_name: company.name,
              company_country: CountryService.findCountry(company.country_id),
              es_company_id: company.easyship_company_id,
              created_at: company.created_at,
            });
          }

          $scope.busy = false;
          $scope.success = true;
          // Set the inviteeOnboarding state to true to trigger invitee orientation modal
          WelcomeService.inviteeOnboarding = true;
          $state.go('app.home');
        },
        function (response) {
          $scope.busy = false;
          $scope.fail = true;
          $scope.errors = response.data;
        }
      );
    };

    $scope.returnConfirmation = function () {
      $scope.fail = false;
      $scope.success = false;
    };
  }
})();
