import {
  Automation,
  AutomationAction,
  AutomationCondition,
  IAutomationService,
} from 'typings/automation';
import { ICourierServiceOptionSummary } from 'typings/courier';

import { IComponentController } from 'angular';
import template from './automation-summary.html?raw';
import style from './automation-summary.module.scss';

class AutomationSummaryController implements IComponentController {
  style = style;
  esIsPreview = false;
  esAutomation: Automation | null = null;
  conditions: AutomationCondition[] = [];
  actions: AutomationAction[] = [];
  wrappers = {
    blue: (chunk: string) => `<span class="strong ${this.tagClass}">${chunk}</span>`,
  };

  static $inject = ['AutomationService'];
  constructor(private AutomationService: IAutomationService) {}

  get isDisabled() {
    return !this.esIsPreview && !this.esAutomation?.is_accessible;
  }

  get tagClass() {
    return this.isDisabled ? 'text-sky-700' : 'text-blue-700';
  }

  private replaceBolds(html: string) {
    if (html.includes('<span class="strong">')) {
      return html.replace(/<span class="strong">/g, `<span class="strong ${this.tagClass}">`);
    }
    if (html.includes('<b>')) {
      return html
        .replace(/<b>/g, `<span class="strong ${this.tagClass}">`)
        .replace(/<\/b>/g, '</span>');
    }
    return html;
  }

  private mergeCouriers(courierServices: ICourierServiceOptionSummary[]) {
    const hash: Record<string, ICourierServiceOptionSummary> = {};
    for (let i = 0; i < courierServices.length; i++) {
      const key = `${courierServices[i].name}-${courierServices[i].origin_country_alpha2}`;
      if (!hash[key]) {
        hash[key] = { ...courierServices[i] };
      } else {
        hash[key].id += `,${courierServices[i].id}`;
      }
    }

    return Object.values(hash);
  }

  get conditionCount(): number {
    const activeConditions =
      this.esAutomation?.rules_attributes.filter(({ _destroy }) => !_destroy) || [];

    return activeConditions[0]?.name === 'Automation::Rules::MatchAll'
      ? 0
      : activeConditions.length || 0;
  }

  summarizeCondition(condition: AutomationCondition): string {
    return this.replaceBolds(this.AutomationService.summarizeCondition(condition));
  }

  summarizeAction(action: AutomationAction, asSentence: boolean): string {
    let options;

    switch (action.name) {
      case 'Automation::Actions::AddPreferredCourier':
        options = action.options as {
          preferred_couriers?: ICourierServiceOptionSummary[];
          couriers?: ICourierServiceOptionSummary[];
        };
        if (options.preferred_couriers) {
          options.preferred_couriers = this.mergeCouriers(options.preferred_couriers);
        }
        if (options.couriers) {
          options.couriers = this.mergeCouriers(options.couriers);
        }
        break;

      case 'Automation::Actions::AddNeverCourier':
        options = action.options as { never_couriers: ICourierServiceOptionSummary[] };
        break;
      default:
    }

    return this.replaceBolds(this.AutomationService.summarizeAction(action, asSentence));
  }
}

const AutomationSummaryComponent: ng.IComponentOptions = {
  controller: AutomationSummaryController,
  template,
  bindings: {
    esAutomation: '<',
    esIndex: '<',
    esIsPreview: '<',
  },
};

export { AutomationSummaryComponent };
