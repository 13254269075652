import template from './pagination.html?raw';
import style from './pagination.module.scss';

class Pagination {
  constructor() {
    this.style = style;
  }

  $onChanges() {
    this._setPageRanges();
  }

  onBackButtonClick() {
    this.onChangePage({ value: this.esPage - 1 });
  }

  onNextButtonClick() {
    this.onChangePage({ value: this.esPage + 1 });
  }

  _setPageRanges() {
    this.startRange = (this.esPage - 1) * this.esRowsPerPage + 1;
    this.isLastPage = this.esPage > Math.ceil(this.esCount / this.esRowsPerPage) - 1;

    this.endRange =
      this.isLastPage && this.esCount % this.esRowsPerPage !== 0
        ? this.startRange + (this.esCount % this.esRowsPerPage) - 1
        : this.startRange + this.esRowsPerPage - 1;
  }
}

const PaginationComponent = {
  template,
  controller: Pagination,
  bindings: {
    esCount: '<',
    esRowsPerPage: '<',
    esPage: '<',
    esDisabled: '<',
    onChangePage: '&',
  },
};

export { PaginationComponent };
