import angular from 'angular';

import stripeService from '@client/core/services/stripe';

import { SubscriptionService } from './subscription.service';
import { SubscriptionResource } from './subscription.resource';
import { SubscriptionPaymentService } from './subscription-payment.service';
import { SubscriptionPaymentResource } from './subscription-payment.resource';

const MODULE_NAME = 'app.global.services.subscription';

angular
  .module(MODULE_NAME, [
    'app.factory.UserSession',
    'app.global.helper',
    'app.service.checkoutService',
    'app.global.user-status',
    'app.global.payment-source',
    stripeService,
  ])
  .service('SubscriptionService', SubscriptionService)
  .service('SubscriptionResource', SubscriptionResource)
  .service('SubscriptionPaymentService', SubscriptionPaymentService)
  .service('SubscriptionPaymentResource', SubscriptionPaymentResource);

export default MODULE_NAME;
