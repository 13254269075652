import { formatDate } from '@client/src/utils/date';
import template from './request-pickup-courier-service.html?raw';
import style from './request-pickup-courier-service.module.scss';

class RequestPickupCourierServiceCtrl {
  static $inject = ['UserSession'];

  constructor(UserSession) {
    this.style = style;
    this.UserSession = UserSession;

    this.companyCurrency = this.UserSession.company.currency;
  }

  $onInit() {
    if (!this.esCourier) return;

    this.showCourierTrackingWarning = this._doesCourierHaveBadTracking();
    this.courierName = this._buildCourierName();
  }

  $onChanges(changes) {
    if (changes && changes.esSelectedAddress && changes.esSelectedAddress.currentValue) {
      this.showCourierAUPickupWarning = this._isAUCourierWithPickup();
    }
  }

  showAdditionalHybridMessage() {
    return (
      this.esCourier.courier_is_hybrid &&
      this.esSelectedAddress &&
      this.esSelectedAddress.country.alpha2 === 'SG'
    );
  }

  _buildCourierName() {
    return this.esCourier.courier_nickname
      ? `${this.esCourier.courier_nickname} - ${this.esCourier.courier_name}`
      : this.esCourier.courier_name;
  }

  _doesCourierHaveBadTracking() {
    const badTrackingQualities = ['-1', '0'];

    return badTrackingQualities.indexOf(this.esCourier.courier_tracking_rating) > -1;
  }

  _isAUCourierWithPickup() {
    return (
      this.esSelectedAddress.country.alpha2 === 'AU' &&
      this._courierHasPickup(this.esCourier.available_handover_options)
    );
  }

  _courierHasPickup(availableHandoverOptions) {
    const pickupValues = ['free_pickup', 'paid_pickup'];
    const availableHandoverOptionsArray = availableHandoverOptions.split(',');
    let hasPickup = false;

    for (let i = 0; i < availableHandoverOptionsArray.length; i += 1) {
      if (pickupValues.indexOf(availableHandoverOptionsArray[i]) > -1) {
        hasPickup = true;
        break;
      }
    }

    return hasPickup;
  }

  get isRequestPickupTimeFailedAlertVisible() {
    return this.esCourier.pickup_window_flag_required;
  }

  get pickupTimeSlot() {
    if (!this.esCourier.pickup_to_time) {
      return '';
    }

    const pickUpToTime = formatDate(this.esCourier.pickup_to_time, 'HH:mm');
    return `${this.esCourier.pickup_from_time} - ${pickUpToTime}`;
  }
}

const RequestPickupCourierServiceComponent = {
  template,
  controller: RequestPickupCourierServiceCtrl,
  bindings: {
    esCourier: '<',
    esCourierIndex: '<',
    esOriginIndex: '<',
    esSelectedAddress: '<',
  },
};

export { RequestPickupCourierServiceComponent };
