import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './merge-selected-different-destination.html?raw';
import style from './merge-selected-different-destination.module.scss';

class MergeSelectedDifferentDestination {
  static $inject = [
    'API',
    '$state',
    'MergeSelectedModal',
    'ShipmentList',
    'EndpointService',
    'MixpanelService',
    'ShipmentListAdvancedService',
    '$timeout',
    '$translate',
  ];

  constructor(
    API,
    $state,
    MergeSelectedModal,
    ShipmentList,
    EndpointService,
    MixpanelService,
    ShipmentListAdvancedService,
    $timeout,
    $translate
  ) {
    this.style = style;
    this.API = API;
    this.$state = $state;
    this.MergeSelectedModal = MergeSelectedModal;
    this.ShipmentList = ShipmentList;
    this.EndpointService = EndpointService;
    this.MixpanelService = MixpanelService;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.$timeout = $timeout;
    this.$translate = $translate;

    this.selectedShipment = null;
  }

  $onInit() {
    this.shipmentIds = this._prepareShipmentIds(this.shipments);
  }

  onSelect(id) {
    this.selectedShipment = id;
  }

  onSubmit() {
    if (!this.selectedShipment) {
      toastError(this.$translate.instant('merge-selected.error-destination'));
      return;
    }

    this.MixpanelService.track('Multiple - Select Merge - Confirm', { multiple_address: true });
    this.busy = true;

    this.ShipmentListAdvancedService.mergeSelectedConfirm(this.shipmentIds, this.selectedShipment)
      .then((response) => {
        // Need to add timeout for shipment reindex (Elastic search)
        this.$timeout(() => {
          this.busy = false;
          this.MergeSelectedModal.close();

          toastSuccess(
            this.$translate.instant('merge-selected.success', {
              initial_shipments_count: this.shipments.length,
              merged_shipments_count: 1,
            })
          );

          this.ShipmentListAdvancedService.resetShipmentActionsConfirm();
          this.ShipmentListAdvancedService.setUpdatedShipments(response.newly_merged_shipment_id);
          this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter('orders_all');
        }, 1000);
      })
      .catch((error) => {
        this.busy = false;
        this.error =
          (error.data && error.data.status) || 'Sorry, something went wrong, please try again.';
      });
  }

  onCancelClick() {
    this.MixpanelService.track('Multiple - Select Merge - Cancel');
    this.MergeSelectedModal.close();
  }

  _prepareShipmentIds(shipments) {
    return shipments.map((shipment) => shipment.id);
  }
}

const MergeSelectedDifferentDestinationComponent = {
  template,
  controller: MergeSelectedDifferentDestination,
  bindings: {
    shipments: '<',
  },
};

export { MergeSelectedDifferentDestinationComponent };
