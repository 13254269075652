(function () {
  'use strict';

  WoocommerceService.$inject = ['$q', 'PlatformApi', 'UserSession', 'StoreService'];
  function WoocommerceService($q, PlatformApi, UserSession, StoreService) {
    var service = this;

    service.formatWooCommerceKey = function (store, platforms) {
      return $q(function (resolve, reject) {
        store.platform_id = platforms[0].id;
        // store.name = store.url.replace(/.*?:\/\//g, "").replace(/\/$/g, "").replace(/^www./g, "");
        resolve(store);
      });
    };

    service.getWooCommerceUrl = function (store) {
      return $q(function (resolve, reject) {
        PlatformApi.woocommerce.save(
          {
            company_id: UserSession.company.id,
          },
          {
            woocommerce_store_url: store.url,
            version: store.version,
          },
          function (response) {
            StoreService.saveStoreValueToLocal(store);
            resolve(response.url);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };
  }

  angular
    .module('app.service.WoocommerceService', [])
    .service('WoocommerceService', WoocommerceService);
})();
