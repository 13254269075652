import angular from 'angular';

import subscriptionService from '@client/src/global/services/subscription';

import subscriptionPaymentFailed from '@client/src/global/components/subscription-payment-failed';

import { SubscriptionComponent } from './subscription.component';

const MODULE_NAME = 'app.account.subscription';

angular
  .module(MODULE_NAME, [
    subscriptionService,
    'app.factory.UserSession',
    'app.global.contact-sales',
    'app.global.helper',
    'core.components',
    'app.global.courier-accounts',
    subscriptionPaymentFailed,
  ])
  .component('subscription', SubscriptionComponent);

export default MODULE_NAME;
