import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { react2angular } from 'react2angular';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import COLORS from '@client/src/colors';

import { ReviewState, PaymentState } from 'typings/insurance';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

interface IClaimStatusProps {
  state: ReviewState | PaymentState;
}

export default function ClaimStatus({ state }: IClaimStatusProps) {
  const statusColors: Record<ReviewState | PaymentState, string> = {
    not_filed: COLORS.inkNormal,
    submitted: COLORS.yellowDark,
    documents_requested: COLORS.yellowDark,
    pending_review: COLORS.yellowDark,
    denied: COLORS.redDark,
    approved: COLORS.greenNormal,
    payment_info_requested: COLORS.yellowDark,
    processing_payment: COLORS.yellowDark,
    paid: COLORS.greenNormal,
    closed: COLORS.redDark,
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <svg height="12" width="16">
        <circle cx="6" cy="6" r="6" stroke="none" fill={statusColors[state]} />
      </svg>
      <Typography variant="body1">
        <FormattedMessage id={`insurance-claim.claim-states.${state}`} />
      </Typography>
    </Grid>
  );
}

export function WrappedClaimStatus(props: IClaimStatusProps): ReactElement<IClaimStatusProps> {
  return (
    <ReactRootProviders>
      <ClaimStatus {...props} />
    </ReactRootProviders>
  );
}

export const AngularClaimStatus = react2angular(WrappedClaimStatus, ['state'], []);
