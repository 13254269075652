import { IComponentController } from 'angular';
import template from './transition-drawer.html?raw';
import style from './transition-drawer.module.scss';

enum Slide {
  Menu = 'menu',
  Content = 'content',
}

class TransitionDrawer implements IComponentController {
  style = style;
  slide = Slide;
  esStatus = Slide.Menu;

  $onInit() {
    if (!this.esStatus) {
      this.esStatus = Slide.Menu;
    }
  }
}

const TransitionDrawerComponent: ng.IComponentOptions = {
  controller: TransitionDrawer,
  template,
  transclude: {
    title: '?drawerTitle',
    menu: '?drawerMenu',
    content: '?drawerContent',
  },
  bindings: {
    esStatus: '<',
    esIsOpen: '<',
    esIsFullHeight: '<',
    esOnClose: '&',
  },
};

export { TransitionDrawerComponent };
