import angular from 'angular';
import { CollectService } from './collect.service';
import { CollectResource } from './collect.resource';

const MODULE_NAME = 'collect.services';

angular
  .module(MODULE_NAME, [])
  .service('CollectService', CollectService)
  .service('CollectResource', CollectResource);

export default MODULE_NAME;
