import template from './password-new.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('home.auth.password-new', {
        url: 'password-new/:resetPasswordToken',
        template,
        controller: 'PasswordNewCtrl',
        controllerAs: 'vm',
      });
    },
  ]);
})();
