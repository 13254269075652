import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { react2angular } from 'react2angular';

import HeaderBanner from '@client/core/components/react/HeaderBanner';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

interface AccountUnderReviewBannerProps {
  show: boolean;
}

export default function AccountUnderReviewBanner({ show }: AccountUnderReviewBannerProps) {
  return (
    <HeaderBanner show={show}>
      <FormattedMessage id="banners.under-review" />
    </HeaderBanner>
  );
}

export function WrappedAccountUnderReviewBanner(
  props: AccountUnderReviewBannerProps
): ReactElement<AccountUnderReviewBannerProps> {
  return (
    <ReactRootProviders>
      <AccountUnderReviewBanner {...props} />
    </ReactRootProviders>
  );
}

export const AngularAccountUnderReviewBanner = react2angular(WrappedAccountUnderReviewBanner, [
  'show',
]);
