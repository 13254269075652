import angular from 'angular';
import { AddressCreatorModalComponent } from './address-creator-modal/address-creator-modal.component';
import { AddressCorrectionModalComponent } from './address-correction-modal/address-correction-modal.component';
import { AddressEditorCardComponent } from './address-editor-card/address-editor-card.component';
import { AddressDefaultForComponent } from './address-editor-card/address-default-for.component';
import { AddressRecalculationWarningComponent } from './address-recalculation-warning/address-recalculation-warning.component';

angular
  .module('app.account.addresses', [])
  .component('esAddressCreatorModal', AddressCreatorModalComponent)
  .component('esAddressCorrectionModal', AddressCorrectionModalComponent)
  .component('esAddressEditorCard', AddressEditorCardComponent)
  .component('esAddressDefaultFor', AddressDefaultForComponent)
  .component('esAddressRecalculationWarning', AddressRecalculationWarningComponent);
