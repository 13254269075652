import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { react2angular } from 'react2angular';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { IShipmentListItem } from 'typings/shipment';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import ClaimDetails from './ClaimDetails';
import ClaimHistory from './ClaimHistory';
import ClaimResponse from './ClaimResponse';

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: 'hidden',
  },
}));

export interface InsuranceClaimDialogProps {
  $injector: angular.auto.IInjectorService;
  shipment?: IShipmentListItem;
  onClose: () => void;
}

export default function InsuranceClaimDialog({
  $injector,
  shipment,
  onClose,
}: InsuranceClaimDialogProps) {
  const classes = useStyles();
  const historyMessages = shipment?.claim?.claim_messages.filter(
    ({ user_role }) => user_role !== 'system'
  );

  return (
    <Dialog
      open={!!shipment}
      maxWidth={
        shipment?.claim?.available_events && shipment?.claim?.available_events?.length > 0
          ? 'md'
          : 'sm'
      }
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <FormattedMessage
            id="insurance-claim.select.header"
            values={{ shipmentId: shipment?.easyship_shipment_id }}
          />
          <IconButton aria-label="close modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!!shipment?.claim && (
          <Grid container wrap="nowrap" spacing={3}>
            <Grid
              item
              style={{ width: shipment?.claim?.available_events.length > 0 ? '50%' : '100%' }}
            >
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <ClaimDetails claim={shipment.claim} />
                </Grid>
                <Grid item xs={12}>
                  {historyMessages && <ClaimHistory messages={shipment.claim.claim_messages} />}
                </Grid>
              </Grid>
            </Grid>
            {shipment?.claim?.available_events.length > 0 && (
              <Grid item xs={6}>
                <ClaimResponse
                  policyId={shipment.policy.id}
                  claimId={shipment.claim.id}
                  latestMessage={
                    Array.isArray(historyMessages) && historyMessages?.length > 0
                      ? historyMessages[0]
                      : undefined
                  }
                  nextAction={shipment.claim.available_events[0]}
                  onClose={onClose}
                  $injector={$injector}
                />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export function WrappedInsuranceClaimDialog(
  props: InsuranceClaimDialogProps
): ReactElement<InsuranceClaimDialogProps> {
  return (
    <ReactRootProviders>
      <InsuranceClaimDialog {...props} />
    </ReactRootProviders>
  );
}

export const AngularInsuranceClaimDialog = react2angular(
  WrappedInsuranceClaimDialog,
  ['shipment', 'onClose'],
  ['$injector']
);
