import template from './residential-coverage-card.html?raw';
import style from './residential-coverage-card.module.scss';
import iconResiUrl from '@assets/images/dashboard/handover-addons/icon-resi.svg';

class ResidentialCoverageCardController {
  iconResiUrl = iconResiUrl;
  static $inject = ['UserSession', 'CheckoutService', 'API', '$translate'];

  constructor(UserSession, CheckoutService, API, $translate) {
    this.style = style;
    this.UserSession = UserSession;
    this.CheckoutService = CheckoutService;
    this.API = API;
    this.$translate = $translate;
  }

  $onInit() {
    this.courierUmbrellaNames = this.data.origins.reduce((agg, origin) => {
      origin.couriers.forEach((courier) => {
        if (this._isCourierWithResidentialSurcharge(courier))
          agg.push(courier.courier_umbrella_name);
      });

      return agg;
    }, []);
  }

  _isCourierWithResidentialSurcharge(courier) {
    return (
      !courier.isInsurance &&
      courier.courier_umbrella_name &&
      courier.totals &&
      courier.totals.residential_discounted_fee_not_applied
    );
  }

  get applicableShipmentCount() {
    return this.data.totals?.total_residential_discounted_fee_not_applied_shipments_count;
  }
}

const ResidentialCoverageCardComponent = {
  template,
  controller: ResidentialCoverageCardController,
  bindings: {
    data: '<',
    payload: '<',
    onRadioSelect: '&',
    errors: '<',
  },
};

export { ResidentialCoverageCardComponent };
