import { array } from 'prop-types';
import template from './luxottica-custom-form.html?raw';
import style from './luxottica-custom-form.module.scss';

class LuxotticaCustomFormCtrl {
  static $inject = ['$q', '$sce', 'DestinationAddressesService', 'CountryService', 'HelperService'];

  constructor($q, $sce, DestinationAddressesService, CountryService, HelperService) {
    this.style = style;
    this.$q = $q;
    this.$sce = $sce;
    this.DestinationAddressesService = DestinationAddressesService;
    this.CountryService = CountryService;
    this.HelperService = HelperService;
  }

  selectedSearchAddress = {};

  searchTerm = '';

  luxotticaAddressList = [];

  countries = [];

  isAddressListActive = false;

  busy = false;

  queryLuxotticaAddressList = function(searchTerm, countryAlpha2) {
    this.busy = true;
    this.DestinationAddressesService.queryDestinationAddresses(searchTerm, countryAlpha2, 'store')
      .then((data) => {
        if (data && data.destination_addresses) {
          this.luxotticaAddressList = data.destination_addresses;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.busy = false;
      });
  };

  resetSearchTerm = function() {
    this.searchTerm = '';
  };

  resetAddressList = function() {
    this.luxotticaAddressList = [];
  };

  searchableAddressFormatTitle = function(name, companyName) {
    return `${name} - ${companyName}`;
  };

  transformToSearchableAddress = function(addressObject) {
    return `${this.searchableAddressFormatTitle(
      addressObject.name,
      addressObject.company_name
    )} · ${[
      addressObject.line_1,
      addressObject.line_2,
      addressObject.city,
      addressObject.state,
      addressObject.postal_code,
      addressObject.country_alpha2,
    ]
      .filter(Boolean)
      .join(', ')}`;
  };

  transformToDisplayAddress = function(addressObject) {
    return [
      addressObject.line_1,
      addressObject.city,
      addressObject.state,
      addressObject.postal_code,
      addressObject.country_alpha2,
    ]
      .filter(Boolean)
      .join(', ');
  };

  showAddressList = function() {
    if (this.searchTerm.length > 2) {
      this.queryLuxotticaAddressList(this.searchTerm, this.shipment.destination_country.alpha2);
      this.isAddressListActive = true;
    } else {
      this.isAddressListActive = false;
      this.resetAddressList();
    }
  };

  checkHasNoResult = function() {
    return this.luxotticaAddressList.length === 0 && this.searchTerm.length > 2;
  };

  setDestinationCountryInfoToShipment = function(countryAlpha2) {
    this.shipment.destination_country = this.CountryService.findCountryByAlpha2(countryAlpha2);
    this.shipment.destination_country_id = this.shipment.destination_country.id;
  };

  setSelectedSearchAddress = function(address) {
    this.DestinationAddressesService.selectedSearchAddress = address;
    this.isAddressListActive = false;
    this.resetSearchTerm();
    this.DestinationAddressesService.setLuxotticaShipmentData(
      this.shipment,
      this.DestinationAddressesService.selectedSearchAddress
    );
  };

  setSelectedReturnAddress = function(index) {
    this.DestinationAddressesService.setLuxotticaShipmentData(
      this.shipment,
      this.DestinationAddressesService.setSelectedLuxotticaReturnAddress(index)
    );
  };

  setSelectedDamageAddress = function(index) {
    this.DestinationAddressesService.setLuxotticaShipmentData(
      this.shipment,
      this.DestinationAddressesService.setSelectedLuxotticaDamageAddress(index)
    );
  };

  setSelectedFtcAddress = function(index) {
    this.DestinationAddressesService.setLuxotticaShipmentData(
      this.shipment,
      this.DestinationAddressesService.setSelectedLuxotticaFtcAddress(index)
    );
  };

  highlightLuxStoreTitle = function(textWhereToSearch, id, companyName) {
    const highlightedTitleText = this.HelperService.highlightInputTerm(
      this.transformToSearchableAddress(textWhereToSearch),
      this.searchableAddressFormatTitle(id, companyName),
      'span'
    );

    return highlightedTitleText;
  };

  highlightSearchTerm = function(itemToHighlight) {
    const searchableAddress = this.transformToSearchableAddress(itemToHighlight);
    const highlightedText = this.HelperService.highlightInputTerm(
      searchableAddress,
      this.searchTerm,
      'strong'
    );
    return this.searchTerm
      ? this.$sce.trustAsHtml(highlightedText)
      : this.$sce.trustAsHtml(itemToHighlight);
  };
}

const LuxotticaCustomFormComponent = {
  template,
  controller: LuxotticaCustomFormCtrl,
  bindings: {
    shipment: '=',
  },
};

export { LuxotticaCustomFormComponent };
