PickupsRoutes.$inject = ['$stateProvider'];
function PickupsRoutes($stateProvider) {
  $stateProvider.state('app.pickups', {
    url: '^/pickups?page&group&returnBackFromReschedulePickupPage&appcue',
    component: 'pickups',
    data: {
      requiredUserRight: 'menu.pickups',
    },
  });
}

export { PickupsRoutes };
