import template from './shipment-list-search-bar.html?raw';
import style from './shipment-list-search-bar.module.scss';

class ShipmentListSearchBar {
  style = style;

  esOnInputSearch(): void {
    // esOnInputSearch expression bindings, need to add this in order for typescript to successfully
  }

  onKeyDown($event: KeyboardEvent): void {
    const { key } = $event;
    switch (key) {
      case 'Enter':
        this.esOnInputSearch();
        break;
      default:
        break;
    }
  }
}

const ShipmentListSearchBarComponent: ng.IComponentOptions = {
  controller: ShipmentListSearchBar,
  template,
  bindings: {
    esModelInput: '<',
    esPlaceholder: '<',
    esActionText: '<',
    esActionButtonType: '<',
    esOnInputChange: '&',
    esOnInputSearch: '&',
    esOnActionClick: '&',
  },
};

export { ShipmentListSearchBarComponent };
