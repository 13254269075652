import { ButtonBase } from '@material-ui/core';
import parse from 'html-react-parser';

import React from 'react';
import { toast, CloseButtonProps as ToastifyCloseButtonProps, ToastOptions } from 'react-toastify';
import {
  Check as CheckIcon,
  Exclaim as ExclaimIcon,
  Cross as CrossIcon,
  Snooze as SnoozeIcon,
} from 'easyship-components/icons';

/**
 * Toast with Actions
 * Should not automatically dismiss after a time delay. (autoClose: false)
 * Not dismissible if a user clicks anywhere else on the toast. (closeOnClick: false)
 * Should only be dismissible when users interact with any actions or hyperlinks. (invoke "closeToast" callback)
 */
const DEFAULT_TOAST_WITH_ACTION_CONFIG = {
  closeOnClick: false,
  autoClose: false,
} as const;

export interface ShowToastWithActionOptions
  extends Omit<ToastOptions, 'icon' | 'closeButton' | 'closeOnClick' | 'autoClose'> {
  /** Displays a success or error icon on the left side of the message. By default, no icon is displayed. */
  type?: 'success' | 'error';

  /** The label text to be displayed on the right side of the message. */
  actionLabel?: string;
  onActionLabelClick?: () => void;

  /** The icon to be displayed on the right side of the message. */
  actionIcon?: ActionIconProps['actionIcon'];
  onActionIconClick?: () => void;
}
export function showToastWithAction(message: string, options?: ShowToastWithActionOptions) {
  const { type, actionLabel, actionIcon, onActionLabelClick, onActionIconClick, ...params } =
    options ?? {};

  return toast(<>{parse(message)}</>, {
    icon: () => {
      if (type === 'success') {
        return <CheckIcon fontSize="small" />;
      }

      if (type === 'error') {
        return <ExclaimIcon fontSize="small" />;
      }

      return false;
    },
    closeButton: ({ closeToast }) => (
      <>
        {actionLabel && (
          <ActionLabel
            onActionTextClick={onActionLabelClick}
            actionLabel={actionLabel}
            closeToast={closeToast}
          />
        )}
        {actionIcon && (
          <ActionIcon
            onActionIconClick={onActionIconClick}
            actionIcon={actionIcon}
            closeToast={closeToast}
          />
        )}
      </>
    ),
    style: {
      cursor: 'default',
    },
    ...DEFAULT_TOAST_WITH_ACTION_CONFIG,
    ...params,
  });
}

/**
 * Action Labels
 */
export interface ActionLabelProps {
  actionLabel: string;
  onActionTextClick?: () => void;
  closeToast: ToastifyCloseButtonProps['closeToast'];
}

export function ActionLabel({ closeToast, actionLabel, onActionTextClick }: ActionLabelProps) {
  return (
    <ButtonBase
      aria-label={actionLabel}
      className="action-button"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        closeToast(e);
        onActionTextClick?.();
      }}
    >
      {actionLabel}
    </ButtonBase>
  );
}

/**
 * Action Buttons
 */
interface ActionIconProps {
  actionIcon: 'close' | 'snooze';
  onActionIconClick?: () => void;
  closeToast: ToastifyCloseButtonProps['closeToast'];
}

function ActionIcon({ closeToast, actionIcon, onActionIconClick }: ActionIconProps) {
  return (
    <ButtonBase
      aria-label={actionIcon}
      className="action-button"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        closeToast(e);
        onActionIconClick?.();
      }}
    >
      {actionIcon === 'close' && <CrossIcon className="text-white" />}
      {actionIcon === 'snooze' && <SnoozeIcon className="text-white" />}
    </ButtonBase>
  );
}
