import template from './select-courier-card-content.html?raw';
import style from './select-courier-card-content.module.scss';

class ReturnModalSelectCourierCardContent {
  static $inject = ['API', 'UserSession'];

  constructor(API, UserSession) {
    this.style = style;
    this.API = API;
    this.UserSession = UserSession;
  }
}

const ReturnModalSelectCourierCardContentComponent = {
  controller: ReturnModalSelectCourierCardContent,
  template,
  bindings: {
    courier: '<',
  },
};

export { ReturnModalSelectCourierCardContentComponent };
