import { AbTestPort } from '@client/core/corelogic/ports/abTest';
import angular from 'angular';
import {
  AbTestAssignmentPayload,
  AbTestVariationData,
  AbTestVariationParams,
  IAbTestsService,
} from 'typings/ab-tests';

export default class NgAbTestGateway implements AbTestPort {
  private abTestService: IAbTestsService;

  constructor() {
    this.abTestService = angular.element(document.body).injector().get('AbTestsService');
  }

  getAbTestVariation(params: AbTestVariationParams): Promise<AbTestVariationData> {
    return new Promise((resolve, reject) => {
      this.abTestService.getAbTestVariation(params).then(resolve).catch(reject);
    });
  }

  assignAbTest(payload: AbTestAssignmentPayload): Promise<void> {
    return new Promise((resolve, reject) => {
      this.abTestService.saveAbTestAssignment(payload).then(resolve).catch(reject);
    });
  }
}
