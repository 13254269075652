import { IComponentController } from 'angular';
import template from './notification-centre-bell.html?raw';
import style from './notification-centre-bell.module.scss';

import bellIcon from './bell.svg?svgo';

class NotificationCentreBell implements IComponentController {
  style = style;
  bellIcon = bellIcon;
}

const NotificationCentreBellComponent: ng.IComponentOptions = {
  controller: NotificationCentreBell,
  template,
  bindings: {
    esNumber: '<',
  },
};

export { NotificationCentreBellComponent };
