import { toastError } from '@client/core/components/react/Toastify';
import template from './shipment-tracking-cell.html?raw';
import style from './shipment-tracking-cell.module.scss';

const NO_TRACKING_SERVICE_ID = 3;
const translationNamespace = 'shipments.list.tracking.store-sync';
class ShipmentTrackingCell {
  static $inject = [
    '$state',
    '$filter',
    '$timeout',
    'API',
    'OnboardingChecklistService',
    'HelperService',
    '$translate',
    'MixpanelService',
    'ShipmentListManageService',
  ];

  constructor(
    $state,
    $filter,
    $timeout,
    API,
    OnboardingChecklistService,
    HelperService,
    $translate,
    MixpanelService,
    ShipmentListManageService
  ) {
    this.style = style;
    this.$state = $state;
    this.$filter = $filter;
    this.$timeout = $timeout;
    this.$translate = $translate;
    this.API = API;
    this.OnboardingChecklistService = OnboardingChecklistService;
    this.HelperService = HelperService;
    this.MixpanelService = MixpanelService;
    this.ShipmentListManageService = ShipmentListManageService;
  }

  $onInit() {
    this.isReturnPage = this.$state.current.name === 'app.returns';
    this._updateFulfillmentTooltipParams();
  }

  get fulfillmentStoreStatus() {
    return this.HelperService.toCamelCase(this.fulfillmentStatusSyncState);
  }

  $onChanges(changesObj) {
    const { fulfilmentDelayedAt } = changesObj;

    if (
      fulfilmentDelayedAt.previousValue &&
      fulfilmentDelayedAt.previousValue !== fulfilmentDelayedAt.currentValue
    ) {
      this._updateFulfillmentTooltipParams();
    }
  }

  _updateFulfillmentTooltipParams() {
    this.fulfillmentTooltipParams = {
      platform: this.platformName,
      timestamp: this.$filter('intlDate')(this.fulfilmentDelayedAt, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZone: undefined,
      }),
    };

    switch (this.fulfillmentStatusSyncState) {
      case 'failed':
        this.fulfillmentTooltip = `${translationNamespace}.failed-tooltip`;
        return;

      case 'waiting_for_batch':
        this.fulfillmentTooltip = this.fulfilmentDelayedAt
          ? `${translationNamespace}.delayed-tooltip`
          : `${translationNamespace}.waiting_for_batch-tooltip`;
        break;

      case 'delayed':
        this.fulfillmentTooltip = `${translationNamespace}.delayed-tooltip`;

      // eslint-disable-next-line no-fallthrough
      case 'pending':
        if (this.platformName === 'Amazon') {
          this.fulfillmentTooltip = `${translationNamespace}.waiting_for_batch-tooltip`;
        }
        if (this.fulfilmentDelayedAt) {
          this.fulfillmentTooltip = `${translationNamespace}.delayed-tooltip`;
        }
        break;

      default:
        this.fulfillmentTooltip = '';
        break;
    }
  }

  get isPending() {
    return !this.trackingNumber && this.serviceTypeId !== NO_TRACKING_SERVICE_ID;
  }

  get isDisabled() {
    return !this.trackingNumber && this.serviceTypeId === NO_TRACKING_SERVICE_ID;
  }

  get trackingNumberLabel() {
    return this.trackingNumber.length >= 25
      ? `${this.trackingNumber.substring(0, 10)}...${this.trackingNumber.substring(
          this.trackingNumber.length - 10,
          this.trackingNumber.length
        )}`
      : this.trackingNumber;
  }

  get showRetry() {
    return this.platformName !== 'direct_sales' && this.fulfillmentStatusSyncState === 'failed';
  }

  handleTrackingNumberClick() {
    this.OnboardingChecklistService.updateOnboarding('track_shipment');
  }

  copyTrackingNumber() {
    this.HelperService.copyToClipboard(this.trackingNumber);
    this.showCopied = true;

    this.$timeout(() => {
      this.showCopied = false;
    }, 1000);
  }

  handleRetryFulfillment() {
    this.ShipmentListManageService.retryFulfillment(this.shipmentId).catch((err) => {
      toastError(err);
    });
  }
}

const ShipmentTrackingCellComponent = {
  controller: ShipmentTrackingCell,
  template,
  bindings: {
    trackingNumber: '=',
    serviceTypeId: '=',
    fulfillmentStatusSyncState: '=',
    easyshipShipmentId: '=',
    shipmentId: '=',
    fulfilmentDelayedAt: '<',
    platformName: '<',
    isReturn: '<',
  },
};

export { ShipmentTrackingCellComponent };
