import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { useUserRoleGateway } from '../../providers/UserRoleGatewayProvider';

export default function useUserRolesQuery(userId: string) {
  const { userRoleGateway } = useUserRoleGateway();
  const { company } = useUserSession();

  return useQuery({
    queryKey: ['userRoles'],
    queryFn: () => userRoleGateway.getUserRoles({ userId, companyId: company.id }),
    enabled: !!userId,
  });
}
