import template from './combo-button.html?raw';
import menuTemplate from './combo-button-menu.html?raw';
import style from './combo-button.module.scss';

class ComboButton {
  static $inject = ['$compile', '$rootScope', '$element', '$scope'];

  constructor($compile, $rootScope, $element, $scope) {
    this.style = style;
    this.$compile = $compile;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$element = $element;
    this.menuVisibility = false;
    this.id = Math.floor(Math.random() * 10000);
    this.clickEventName = `click.${this.id}`;
    this.tabEventName = `keydown.${this.id}`;
  }

  $onInit() {
    this._validateActionConfig();
  }

  $onChanges(changesObj) {
    if (this.menuScope && changesObj.esMenuActions && changesObj.esMenuActions.currentValue) {
      this.menuScope.$ctrl.actions = { ...changesObj.esMenuActions.currentValue };
    }
  }

  /**
   * Compiles the menu template and attaches it to the body element to ensure
   * that it sits on the stack above other elements on the page. A click
   * listener is also bound on the body for closing the menu.
   */
  $postLink() {
    this.menuScope = this._prepareMenuScope(this.esMenuActions, this.$element);
    this.$menu = this.$compile(menuTemplate)(this.menuScope);

    angular
      .element('body')
      .append(this.$menu)
      .on(this.clickEventName, ($event) => {
        const $target = angular.element($event.target);
        const $menuButton = this.$element.find(`.${style.menuButton}`);
        const targetIsNotMenuItem = $target.is(this.$menu.find('li'));
        const targetIsButton = !!$target.closest($menuButton).length;

        if (!this.menuVisibility || targetIsButton || targetIsNotMenuItem) return;

        this.$scope.$apply(() => {
          this.onMenuButtonClick();
        });
      });
  }

  /**
   * Manually clean up the click event and element that we've compiled.
   */
  $onDestroy() {
    angular.element('body').off(this.clickEventName);
    this.menuScope.$destroy();
    this.$menu.remove();
  }

  onMenuButtonClick() {
    this.menuVisibility = !this.menuVisibility;
    this.menuScope.$ctrl.menuVisibility = this.menuVisibility;

    if (this.menuVisibility) {
      this.$menu.focus();
      this._bindMenuTabEvent();
    } else {
      this._unbindMenuTabEvent();
    }
  }

  /**
   * Preps a new scope object containing the menu's controller
   *
   * @param {MenuAction[]} esMenuActions User defined menu actions
   * @param {JQuery} $element Parent element for the menu to anchor to
   * @returns {Scope} AngularJS Scope obj containing the menu's data
   */
  _prepareMenuScope(esMenuActions, $element) {
    const offset = $element.offset();

    return Object.assign(this.$rootScope.$new(true), {
      $ctrl: {
        style,
        $parent: $element,
        actions: { ...esMenuActions },
        menuVisibility: this.menuVisibility,
      },
    });
  }

  _validateActionConfig() {
    if (!this.esMenuActions) {
      throw new Error('EasyshipComboButton: An es-menu-actions binding must be provided.');
    }

    this.esMenuActions.forEach((config) => {
      const hasHref = typeof config.href === 'string';
      const hasAction = typeof config.action === 'function';

      if (hasHref && hasAction) {
        throw new Error('EasyshipComboButton: A menu item cannot have both a href and an action.');
      } else if (!hasHref && !hasAction) {
        throw new Error('EasyshipComboButton: A menu item must have either a href or an action.');
      }
    });
  }

  /**
   * Set up keyboard navigation for the menu. When users tab away from the first
   * or last item in the menu, the menu is closed and the focus returns to the
   * button element.
   */
  _bindMenuTabEvent() {
    this.$menu.on(`keydown.${this.id}`, (event) => {
      const { which, target } = event;
      const tabKey = 9;
      const isItem = target.tagName === 'BUTTON' || target.tagName === 'A';

      if (which !== tabKey || !isItem) return;

      const $target = angular.element(target);
      const $parent = $target.parent();
      const $listItems = $target.closest('ul').children();
      const isFirst = $parent.is($listItems.first());
      const isLast = $parent.is($listItems.last());

      if (isLast || (isFirst && event.shiftKey)) {
        event.preventDefault();

        this.$scope.$apply(() => {
          this.onMenuButtonClick();
          this.$element.find(`button`).last().focus();
        });
      }
    });
  }

  _unbindMenuTabEvent() {
    this.$menu.off(`keydown.${this.id}`);
  }
}

const ComboButtonComponent = {
  controller: ComboButton,
  template,
  transclude: {
    easyshipButton: 'easyshipButton',
  },
  bindings: {
    esType: '@',
    esMenuActions: '<',
    ngDisabled: '<',
    esLoading: '<',
  },
};

export { ComboButtonComponent };
