import template from './shipment-taxes-cell.html?raw';
import style from './shipment-taxes-cell.module.scss';

class ShipmentTaxesCell {
  constructor() {
    this.style = style;
    this.isDDP = false;
    this.totalImportTaxes = 0;
  }

  $onInit() {
    if (!this.rate) return;

    this.isDDP = this.rate.effective_incoterms === 'DDP';
    this.totalImportTaxes =
      this.rate.effective_incoterms === 'DDP'
        ? this.rate.import_tax_charge + this.rate.import_duty_charge + this.rate.ddp_handling_fee
        : this.rate.estimated_import_tax + this.rate.estimated_import_duty;
    this.isDomestic = this.rate.is_domestic;
  }
}

const ShipmentTaxesCellComponent = {
  controller: ShipmentTaxesCell,
  template,
  bindings: {
    rate: '<',
    currency: '<',
  },
};

export { ShipmentTaxesCellComponent };
