import angular from 'angular';

import { ShipmentCostsComponent } from './shipment-costs.component';

const MODULE_NAME = 'app.manage-shipments.shipment-information.shipment-costs';

angular
  .module(MODULE_NAME, [])
  .component('esShipmentInfoCosts', ShipmentCostsComponent);

export default MODULE_NAME;
