import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import React, { ReactElement, useEffect, useLayoutEffect } from 'react';
import { react2angular } from 'react2angular';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { API } from '@client/core/config/api.constant';
import QueryClientProvider from '@client/src/global/context/QueryClientProvider';
import { GatewayProvider } from '@client/src/global/context/GatewayProvider';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import { AbTestGatewayProvider } from '@client/src/global/context/AbTestProvider';
import PersonaSection from '../components/Personas';
import { PersonaOptions } from '../components/Personas/types';
import PlatformSection from '../components/Platforms';
import { OnboardingProvider, useOnboarding } from '../hooks/OnboardingProvider';
import { OnboardingGatewayProvider } from '../gateways/api/OnboardingGatewayProvider';

interface OnboardingPageProps {
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  outlet: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 100,
    overflow: 'scroll',
    padding: '36px 20px',
    backgroundColor: '#FFF',
    backgroundImage: `url(${API.easyship_storage}/onboarding/background.png)`,
    backgroundPosition: 'center',
    backgroundSize: '200%',
  },
}));

function OnboardingPage() {
  const classes = useStyles();
  const { currentSection, navToPlatform } = useOnboarding();
  const { user, features } = useUserSession();

  useEffect(() => {
    if (features.isPlatformRequired) {
      navToPlatform(user.persona as PersonaOptions);
    }
  }, [user.persona, features.isPlatformRequired, navToPlatform]);

  useLayoutEffect(() => {
    const metaViewport = document.querySelector('meta[name="viewport"]');

    metaViewport?.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
    );

    return () => {
      metaViewport?.setAttribute('content', 'width=1440');
    };
  }, []);

  return (
    <Grid className={classes.outlet} container>
      {currentSection === 'platform' ? <PlatformSection /> : <PersonaSection />}
    </Grid>
  );
}

export function WrappedOnboardingPage(props: OnboardingPageProps): ReactElement {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <AbTestGatewayProvider>
          <OnboardingGatewayProvider>
            <OnboardingProvider {...props}>
              <OnboardingPage />
            </OnboardingProvider>
          </OnboardingGatewayProvider>
        </AbTestGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularOnboardingPage = react2angular(WrappedOnboardingPage, ['onClose']);
