import { toastError } from '@client/core/components/react/Toastify';

(function () {
  addressFormHkCtrl.$inject = ['CountryService', '$translate'];
  function addressFormHkCtrl(CountryService, $translate) {
    const ctrl = this;

    ctrl.hkDistricts = [];

    ctrl.$onInit = function () {
      CountryService.getHkDistricts()
        .then(function (hkDistricts) {
          ctrl.hkDistricts = hkDistricts;
        })
        .catch(function () {
          toastError($translate.instant('toast.default-error'));
        });
    };
  }

  angular
    .module('app.component.address-form-hk')
    .controller('addressFormHkCtrl', addressFormHkCtrl);
})();
