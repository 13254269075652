import React from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DialogActions, makeStyles } from '@material-ui/core';
import Button from '@client/core/components/react/Button';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

interface MarkAsDeliveredDialogProps extends Omit<DialogProps, 'open'> {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  dialogContent: {
    padding: '36px 48px',
  },
}));

export default function MarkAsDeliveredDialog({
  onClose,
  onConfirm,
  isOpen,
  ...props
}: MarkAsDeliveredDialogProps) {
  const classes = useStyles();

  function handleCancel(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClose();
  }

  function handleConfirm(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onConfirm();
  }

  return (
    <Dialog {...props} className={classes.root} maxWidth="sm" fullWidth open={isOpen}>
      <DialogTitle>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h2" component="span">
                <FormattedMessage id="mark-as-delivered.header" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton aria-label="close modal" onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h4" component="span">
          <FormattedMessage id="mark-as-delivered.message" />
        </Typography>{' '}
        <a
          href="https://support.easyship.com/hc/en-us/articles/115003434411-Understanding-Shipment-Tracking-Statuses"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="global.learn-more" />
        </a>
      </DialogContent>
      <DialogActions>
        <Grid container item justifyContent="space-between">
          <Grid>
            <Button onClick={handleCancel}>
              <FormattedMessage id="global.abort" />
            </Button>
          </Grid>
          <Grid>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              <FormattedMessage id="global.mark-as-delivered" />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export function WrappedMarkAsDeliveredDialog(props: MarkAsDeliveredDialogProps) {
  return (
    <ReactRootProviders>
      <MarkAsDeliveredDialog {...props} />
    </ReactRootProviders>
  );
}
