import template from './rating-email-preview.html?raw';
import style from './rating-email-preview.module.scss';

class RatingEmailPreview {
  static $inject = ['$sce', 'UserSession'];

  constructor($sce, UserSession) {
    this.$sce = $sce;
    this.style = style;
    this.UserSession = UserSession;
    this.company = { ...UserSession.company };

    this.COURIER_NAME = 'DHL eCommerce';
    this.COURIER_LOGO_URL =
      'https://s3.amazonaws.com/easyship-storage/courier-logos/dashboard/dhl-ecommerce-mini.svg';

    this.COMPANY_NAME = this.company.display_name;
    this.COMPANY_LOGO_URL = this.company.logo && this.company.logo.asset_url;
  }
}

const RatingEmailPreviewComponent = {
  controller: RatingEmailPreview,
  template,
};

export { RatingEmailPreviewComponent };
