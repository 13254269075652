(function () {
  'use strict';

  function walkthroughModalCtrl(
    $scope,
    params,
    WalkthroughService,
    MixpanelService,
    UserSession,
    PlatformService
  ) {
    var vm = this;

    vm.UserSession = UserSession;

    (function init() {
      if (!params.flow) $scope.$close();

      vm.flow = params.flow;
      vm.template = setModalContent(params.flow);
      vm.header = setModalHeader(params.flow, params.options);

      $scope.options = params.options;
      $scope.PlatformService = PlatformService;

      if (params.flow === 'connect-store-incentive') {
        MixpanelService.track('Store Connect Incentive - See Modal', {
          platform_name: vm.UserSession.getActionableSelectedPlatform(),
          incentive_amount:
            vm.UserSession.getCompanyDisplayCurrency() +
            ' ' +
            vm.UserSession.company.connect_store_incentive_amount,
        });
      }
    })();

    function setModalContent(flow) {
      return new URL(`./walkthrough-modal__${flow}.html`, import.meta.url).href;
    }

    function setModalHeader(flow, options) {
      var options = options || {};

      vm.platformName = PlatformService.getPlatformNameByCssClass(options.platform);
      vm.storeIncentiveGraphic = _getStoreIncentiveGraphicName(options.platform);

      switch (flow) {
        case 'connect-store-incentive':
          return (
            'Connect for ' +
            vm.UserSession.getCompanyDisplayCurrency() +
            options.incentiveAmount +
            ' in Shipping Credit'
          );
        default:
          return '';
      }
    }

    function _getStoreIncentiveGraphicName(platformName) {
      var platformAvailableAsAssets = [
        'amazon',
        'bigcommerce',
        'etsy',
        'magento',
        'shopify',
        'squarespace',
        'wix',
        'woocommerce',
        'zoho',
      ];

      return platformAvailableAsAssets.indexOf(platformName) > -1 ? platformName : 'generic';
    }

    vm.close = function () {
      WalkthroughService.goToNextStep(params.flow, params.nextStepName);
      $scope.$close();

      if ($scope.options.ctaAction) {
        $scope.options.ctaAction();
      }
    };

    vm.dismiss = function () {
      MixpanelService.track('Walkthrough - Modal - Dismiss', {
        flow_name: params.flow,
      });
      WalkthroughService.goToNextStep(params.flow, params.nextStepName);

      $scope.$close();
    };

    vm.getStoreIncentiveImageUrl = function (platformName) {
      return new URL(
        `../../../../assets/images/dashboard/store-incentive/store-incentive-${platformName}.svg`,
        import.meta.url
      ).href;
    };
  }

  walkthroughModalCtrl.$inject = [
    '$scope',
    'params',
    'WalkthroughService',
    'MixpanelService',
    'UserSession',
    'PlatformService',
  ];

  angular
    .module('app.service.walkthrough-modal')
    .controller('walkthroughModalCtrl', walkthroughModalCtrl);
})();
