import { IAddress } from 'typings/address';
import { ICountryService } from 'typings/auth/services/country';
import { IHelperService } from 'typings/helper';

import { IComponentController } from 'angular';
import { getDateDifferenceByDays } from '@client/src/utils/date';
import template from './origin-breakdown.html?raw';

const THIRTY_DAYS = 30;

class ShipmentInfoOriginBreakdownController implements IComponentController {
  esAddress?: IAddress;

  country: string | null = null;

  static $inject = ['$state', 'CountryService', 'HelperService'];
  constructor(
    private $state: ng.ui.IStateService,
    private CountryService: ICountryService,
    private HelperService: IHelperService
  ) {}

  $onInit(): void {
    this.CountryService.getCountries().then(() => {
      if (this.esAddress?.country_id) {
        this.country = this.CountryService.findCountry(this.esAddress.country_id).simplified_name;
      }
    });
  }

  canFilterByBatch(createdAt: string) {
    return getDateDifferenceByDays(new Date(), new Date(createdAt)) <= THIRTY_DAYS;
  }

  filterByBatch(id: string) {
    this.$state.go(
      'app.shipments',
      {
        page: 1,
        batch_id: id,
        shipment_id: null,
      },
      {
        reload: true,
      }
    );
  }
}

const ShipmentInfoOriginBreakdownComponent: ng.IComponentOptions = {
  controller: ShipmentInfoOriginBreakdownController,
  template,
  bindings: {
    esAddress: '<',
    esBatches: '<',
  },
};

export { ShipmentInfoOriginBreakdownComponent };
