import { useMutation } from '@tanstack/react-query';
import { useAuthGateway } from '../providers/AuthGatewayProvider';
import { ResetPasswordPayload } from '../platforms/uber-freight/ForgetPassword.types';

interface ResetPasswordParams {
  payload: ResetPasswordPayload;
  token: string;
}

export default function useResetPasswordMutation() {
  const { authGateway } = useAuthGateway();

  return useMutation({
    mutationFn: ({ payload, token }: ResetPasswordParams) =>
      authGateway.resetPassword(payload, token),
  });
}
