import { IUserSession } from 'typings/user-session';
import { BillingFrequency, DefinedSubscriptionPlanId } from '@client/data/subscription';
import { IComponentController } from 'angular';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './modal.module.scss';
import template from './modal.html?raw';

class PaymentFailedModal implements IComponentController {
  style = style;
  showUpgradeBillingModal = false;
  isEndOfTrial = false;
  busy = false;

  static $inject = [
    '$window',
    '$location',
    '$translate',
    'SubscriptionService',
    'UserSession',
    'MixpanelService',
  ];
  constructor(
    private $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService
  ) {}

  get hasBetaFeatureTeamPermission(): boolean {
    return !!this.UserSession.getCompanyDashboardSettings()
      .beta_feature_team_rights_and_permissions;
  }

  get showPaymentFailedModal(): boolean {
    return this.SubscriptionService.showPaymentFailedModal;
  }

  get showModal() {
    return this.SubscriptionService.showChangePlanIntegrationModals;
  }

  get showTrialEndModal(): boolean {
    return this.SubscriptionService.showTrialEndModal;
  }

  get suggestedPlanId() {
    return this.SubscriptionService.suggestedPlanId;
  }

  closePaymentFailModal(): void {
    this.SubscriptionService.showPaymentFailedModal = false;
  }

  closeTrialEndModal(): void {
    this.SubscriptionService.showTrialEndModal = false;
    this.MixpanelService.track('Subscription - Trial Expired - Close');
  }

  closeUpgradeBillingModal(): void {
    this.showUpgradeBillingModal = false;

    this.MixpanelService.track('Subscription - Update Billing - Close');
  }

  showComparePlanTable(): void {
    this.SubscriptionService.showChangePlanIntegrationModals = {
      compare: true,
      upgrade: false,
    };
    this.MixpanelService.track('Subscription - Trial Expired - See More Plans');
  }

  closeModal(): void {
    this.SubscriptionService.showChangePlanIntegrationModals = {
      compare: false,
      upgrade: false,
    };
  }

  updatePaymentInfo(origin?: string): void {
    this.closePaymentFailModal();
    this.closeTrialEndModal();
    this.showUpgradeBillingModal = true;

    switch (origin) {
      case 'payment_fail':
        this.MixpanelService.track('Subscription - Payment Failed - Billing');
        break;
      case 'trial_end':
        this.isEndOfTrial = true;
        break;
      default:
        break;
    }
  }

  onCardFormSubmitSuccess(): void {
    this.showUpgradeBillingModal = false;
  }

  moveToFreePlan(origin?: string): void {
    this.busy = true;
    if (this.UserSession && this.UserSession.company) {
      const { company } = this.UserSession;
      this.SubscriptionService.postChangePlan(
        {
          company_id: company.id,
        },
        {
          plan_id: DefinedSubscriptionPlanId.Free,
          billing_frequency: BillingFrequency.Month,
          payment_source_id: null,
        }
      )
        .then(() => {
          this.SubscriptionService.fetchCurrentSubscription({ company_id: company.id });
          this.SubscriptionService.showPaymentFailedModal = false;
          this.SubscriptionService.showTrialEndModal = false;

          this.$window.location.href = `${this.$location.path()}?downgrade_plan=Free`;
        })
        .catch((error) => {
          if (error instanceof Error) {
            toastError(error.message);
          }

          if (transformToErrorCodes(error).includes('access_denied')) {
            toastError(this.$translate.instant('subscription.update-billing.errors.access_denied'));
          }

          throw error;
        })
        .finally(() => {
          this.busy = false;
          switch (origin) {
            case 'payment_fail':
              this.MixpanelService.track('Subscription - Payment Failed - Move To Free');
              break;
            case 'trial_end':
              this.MixpanelService.track('Subscription - Trial Expired - Move To Free');
              break;
            default:
              break;
          }
        });
    }
  }
}

const PaymentFailedModalComponent: ng.IComponentOptions = {
  controller: PaymentFailedModal,
  template,
  bindings: {},
};

export { PaymentFailedModalComponent };
