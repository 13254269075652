import React from 'react';
import { FormattedMessage } from 'react-intl';

interface ContactSupportModalDescriptionProps {
  platformName: string;
}

export function ContactSupportModalDescription({
  platformName,
}: ContactSupportModalDescriptionProps) {
  return (
    <p className="text-lg leading-6">
      <FormattedMessage
        id="connect.form.contact-support"
        values={{
          platform: platformName,
        }}
      />
    </p>
  );
}
