import angular from 'angular';

import { AddProductsModalActionsComponent } from './add-products-modal-actions.component';

const MODULE_NAME = 'app.product-listing.add-products-modal.actions';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingAddProductsModalActions', AddProductsModalActionsComponent);

export default MODULE_NAME;
