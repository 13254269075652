import { IComponentController } from 'angular';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { IUserSession } from 'typings/user-session';
import template from './empty-queue.html?raw';
import style from './empty-queue.module.scss';
import emptyQueueImage from './empty-queue.svg?svgo';

class EmptyQueue implements IComponentController {
  style = style;
  emptyQueueImage = emptyQueueImage;

  static $inject = ['$state', 'ShipmentListManageService', 'UserSession'];
  constructor(
    private $state: ng.ui.IStateService,
    private ShipmentListManageService: ShipmentListManageService,
    private UserSession: IUserSession
  ) {}

  get showCreateShipmentButton(): boolean {
    const hasUserRight = this.UserSession.hasUserRole('create_shipments');
    const hasNoShipment = !this.ShipmentListManageService.currentShipments?.length;
    return hasNoShipment && hasUserRight;
  }

  goToShipmentsPage(): void {
    this.$state.go('app.multiple');
  }
}

const EmptyQueueComponent: ng.IComponentOptions = {
  controller: EmptyQueue,
  template,
  bindings: {},
};

export { EmptyQueueComponent };
