import { IAddressService, IDraftAddress, IAddress } from 'typings/address';

import { IComponentController } from 'angular';
import template from './ship-from-address-selector.html?raw';
import style from './ship-from-address-selector.module.scss';

class ShipFromAddressSelectorController implements IComponentController {
  style = style;
  useExistingAddress = true;
  dropdownOpen = false;
  accountAddresses: IAddress[] = [];
  esOriginAddress?: IAddress;
  esOriginAddressId?: string;
  esShouldHideToggle?: boolean;
  esOnChange?: (changes: any) => void;
  selectedAddress?: IAddress;
  selectedAddressId?: string;
  form: IDraftAddress = {
    line_1: '',
    line_2: '',
    contact_name: '',
    company_name: '',
    contact_phone: '',
    contact_email: '',
    country_id: 0,
    postal_code: '',
    city: '',
    state: '',
    use_for_later: false,
    pickup_instruction_slug: 'none',
    pickup_instruction_users_input: null,
  };

  static $inject = ['AddressService'];
  constructor(private AddressService: IAddressService) {}

  $onInit(): void {
    this.AddressService.getAddresses().then(() => {
      // Get saved options for account
      this.accountAddresses = this.AddressService.getReusableShippingAddresses();
      if (!this.esOriginAddressId && !this.esOriginAddress && this.accountAddresses.length) {
        this.selectedAddress =
          this.accountAddresses.find((address) => address.default_values.pickup) ||
          this.accountAddresses[0];
        this.selectedAddressId = (this.selectedAddress as IAddress).id;
      }

      if (this.accountAddresses.length === 0) {
        this.useExistingAddress = false;
        this.esShouldHideToggle = true;
      }
    });
  }

  $onChanges(): void {
    if (this.esOriginAddress) {
      if (this.esOriginAddress?.address_state === 'active' && this.esOriginAddress?.use_for_later) {
        this.selectedAddress = this.esOriginAddress;
        this.selectedAddressId = this.esOriginAddress?.id;
        this.useExistingAddress = true;
      } else {
        this.useExistingAddress = false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, address_state: addressState, ...rest } = this.esOriginAddress;
        this.form = rest as IDraftAddress;
      }
      return;
    }

    if (this.esOriginAddressId) {
      this.selectedAddress = this.esOriginAddress;
      this.selectedAddressId = this.esOriginAddressId;
      this.useExistingAddress = true;
    }
  }

  toggleForm(): void {
    this.useExistingAddress = !this.useExistingAddress;

    if (this.useExistingAddress) {
      this.esOnChange &&
        this.esOnChange({
          changes: {
            originAddress: null,
            originAddressId: this.selectedAddressId,
            originCountryId: (this.selectedAddress as IAddress).country_id,
          },
        });
    } else {
      this.esOnChange &&
        this.esOnChange({
          changes: {
            originAddress: this.form,
            originAddressId: null,
            originCountryId: this.form.country_id,
          },
        });
    }
  }

  toggleSaveForFuture(): void {
    this.form.use_for_later = !this.form.use_for_later;

    if (this.esOnChange) {
      this.esOnChange({
        changes: { originAddress: this.form, originCountryId: this.form.country_id },
      });
    }
  }

  onAddressSelected(id: string, address: IAddress): void {
    this.selectedAddressId = id;
    this.selectedAddress = address;
    this.dropdownOpen = false;

    if (this.esOnChange) {
      this.esOnChange({
        changes: {
          originAddressId: this.selectedAddressId,
          originCountryId: this.selectedAddress.country_id,
        },
      });
    }
  }

  updateAddress(form: IDraftAddress, originCountryId: number): void {
    this.form = form;

    if (this.esOnChange) {
      this.esOnChange({ changes: { originAddress: this.form, originCountryId } });
    }
  }
}

const ShipFromAddressSelectorComponent = {
  controller: ShipFromAddressSelectorController,
  template,
  bindings: {
    esOriginAddress: '<',
    esOriginAddressId: '<',
    esOnChange: '&', // use es-on-change="$ctrl.myMethod(changes)"
    esDomesticShipment: '<',
    esShouldHideToggle: '<',
  },
};

export { ShipFromAddressSelectorComponent };
