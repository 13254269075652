import angular from 'angular';
import { CompanyAccountComponent } from './companyAccount.component';

angular
  .module('app.account.company', [
    'core.service.mixpanel',
    'app.global.user',
    'app.factory.UserSession',
    'app.service.CompanyService',
    'core.components',
  ])
  .component('companyAccount', CompanyAccountComponent);
