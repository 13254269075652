import dayjs from '@client/core/plugins/dayjs';

/**
 * Try to migrate some helper function from helperService to here
 * client/core/services/helper/helper.service.js
 */

/**
 * BE used to respond with the special format "YYYY-MM-DD HH:mm:ss UTC",
 * which is not a standard format. We are trying to migrate to the
 * standard ISO format.
 */
export function formatDate(
  date: string,
  format = 'D MMM YYYY',
  parseFormat = 'YYYY-MM-DD HH:mm:ss UTC'
): string {
  const d = date.includes('UTC') ? dayjs.utc(date, parseFormat, true).local() : dayjs(date);

  if (!d.isValid()) return '';

  return d.format(format);
}

/**
 * BE used to respond with the special format "YYYY-MM-DD HH:mm:ss UTC",
 * which is not a standard format.
 */
export function parseUTC(date: string) {
  const dayjsDate = date.includes('UTC')
    ? dayjs.utc(date, 'YYYY-MM-DD HH:mm:ss UTC', true).local()
    : dayjs(date);

  return dayjsDate.toDate();
}

export function getDateDifferenceByDays(
  firstDate: Parameters<typeof dayjs>[0],
  secondDate: Parameters<typeof dayjs>[0]
) {
  const a = dayjs(firstDate);
  const b = dayjs(secondDate);
  return Math.ceil(a.diff(b, 'days', true));
}
