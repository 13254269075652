import angular from 'angular';

import { ReturnModal } from './return-modal.service';
import { ReturnModalMainComponent } from './return-modal-main/return-modal-main.component';
import { ReturnModalSelectCourierComponent } from './return-modal-main/select-courier/select-courier.component';
import { ReturnModalPayReturnComponent } from './return-modal-main/pay-return/pay-return.component';
import { ReturnModalSuccessReturnComponent } from './return-modal-main/success/success.component';
import { ReturnModalFailedReturnComponent } from './return-modal-main/failed/failed.component';
import { ReturnModalSelectCourierCardContentComponent } from './return-modal-main/select-courier/select-courier-card-content/select-courier-card-content.component';

angular
  .module('app.returns-modal', [
    'core.service.mixpanel',
    'app.components',
    'core.components',
    'app.global.return',
  ])
  .component('returnModalMain', ReturnModalMainComponent)
  .component('returnModalSelectCourier', ReturnModalSelectCourierComponent)
  .component('returnModalPayReturn', ReturnModalPayReturnComponent)
  .component('returnModalSuccessReturn', ReturnModalSuccessReturnComponent)
  .component('returnModalFailedReturn', ReturnModalFailedReturnComponent)
  .component('selectCourierCardContent', ReturnModalSelectCourierCardContentComponent)
  .service('ReturnModal', ReturnModal);
