import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
(function () {
  angular.module('easyshipDashboardApp').controller('automationsCtrl', automationsCtrl);

  automationsCtrl.$inject = [
    '$scope',
    '$translate',
    'UserSession',
    'ShippingRules',
    'Action',
    'Rule',
    'StoreService',
    'AutomationService',
    'CourierService',
    'CountryService',
    'ItemCategoryService',
    'OnboardingChecklistService',
    'TagsService',
    'AppCuesService',
    'MixpanelService',
    'SubscriptionService',
    'API',
    'UserRightsService',
  ];

  function automationsCtrl(
    $scope,
    $translate,
    UserSession,
    ShippingRules,
    Action,
    Rule,
    StoreService,
    AutomationService,
    CourierService,
    CountryService,
    ItemCategoryService,
    OnboardingChecklistService,
    TagsService,
    AppCuesService,
    MixpanelService,
    SubscriptionService,
    API,
    UserRightsService
  ) {
    $scope.UserSession = UserSession;
    $scope.busy = { list: true };
    $scope.show = {};
    $scope.automation = { rules_attributes: [], actions_attributes: [] };
    $scope.automation_copy = {};
    $scope.recalculateShipments = false;
    $scope.Action = Action;
    $scope.StoreService = StoreService;
    $scope.CountryService = CountryService;
    $scope.ItemCategoryService = ItemCategoryService;
    $scope.mixPanelTriggerSource = 'Shipping Rules';
    $scope.UserRightsService = UserRightsService;

    $scope.canDeleteAutomation = UserRightsService.canDeleteAutomation;

    const vm = this;

    const translations = {
      shippingRule: $translate
        .instant('global.pluralize.shipping-rule', { COUNT: 1 }, 'messageformat')
        .toLowerCase(),
      shippingRules: $translate
        .instant('global.pluralize.shipping-rule', { COUNT: 2 }, 'messageformat')
        .toLowerCase(),
    };

    vm.Rule = Rule;

    vm.wrappers = {
      a(text) {
        return `<a ng-href="${API.dashboard}/account/subscription?open=compare">${text}</a>`;
      },
    };

    $scope.filterAutomationActions = function (actions) {
      if (actions) {
        return actions;
      }
      if (Action.automation_actions) {
        const skuRuleFound = $scope.automation.rules_attributes.find(
          (rule) => rule.automation_rule_id === 13 && !rule._destroy
        );
        const apiAutomationActions = [...Action.automation_actions];
        if (!skuRuleFound) {
          const splitByParcelIndex = apiAutomationActions.findIndex(
            (action) => action.automation_action_id === 14
          );

          if (splitByParcelIndex > -1) {
            apiAutomationActions.splice(splitByParcelIndex, 1);
          }
        }
        return apiAutomationActions;
      }
      return [];
    };

    $scope.isCreateButtonDisabled = function () {
      return (
        !Rule.show.hasRules ||
        $scope.busy.button ||
        Action.show.addAction ||
        Rule.show.addRule ||
        !UserRightsService.canCreateShippingRule
      );
    };

    $scope.service_types = [
      { id: 1, name: 'Express' },
      { id: 2, name: 'Postal With Tracking ID' },
      { id: 3, name: 'Postal Without Tracking ID' },
    ];

    StoreService.getStores().then(function () {
      Rule.buildStoresAndPlatformsArray();
    });

    CourierService.getCouriers({ refetch: true }).then(function () {
      Action.couriers = Action.addAdditionalAttributesToCouriers(CourierService.activeCouriers);
    });

    TagsService.getTags({
      company_id: UserSession.getCompanyId(),
    }).then(function (response) {
      Rule.orderTags = response.tags.sort();
    });

    const sessionWatcher = $scope.$watch(
      'UserSession',
      function (session) {
        if (session.user) {
          getAutomations(session);
          sessionWatcher();
        }
      },
      true
    );

    MixpanelService.track('Shipping Rules - Visit page', {});

    /**
     * [prepareDataForAutomationContent]
     * Build sentences for automation cards before loading the shipping rules section in order to be able to use Isotope to arrange the cards
     */
    function prepareDataForAutomationContent(actionAddressIds) {
      return AutomationService.getData(actionAddressIds);
    }

    /**
     * - GET Automations for the current User
     * - Sort the Automations by the User's preferred priority
     * - Use formatRule and formatAction to format the Rules and Actions for each Automation
     *   - These functions take the Ruby model of each action/rule from action.name and rule.name
     *   - Depending on the Ruby model, it will set the options for each action/rule
     */
    function getAutomations(session) {
      ShippingRules.query(
        { company_id: session.company.id },
        {},
        function (data) {
          const actionAddressIds = [];
          data.automations.forEach(function (automation) {
            automation.actions_attributes.forEach(function (action) {
              if (action.automation_action_id === 11)
                actionAddressIds.push(action.options.origin_address_id);
            });
          });

          prepareDataForAutomationContent(actionAddressIds).then(function () {
            $scope.automations = _.sortBy(data.automations, 'priority');

            $scope.automations.forEach(function (automation) {
              automation.rules_attributes.forEach(function (rule) {
                vm.Rule.formatRule(rule);
              });

              automation.actions_attributes.forEach(function (action) {
                $scope.Action.formatAction(action);
              });
            });

            $scope.busy.list = false;
          });
        },
        function () {
          toastError($translate.instant('toast.fetch-error', { noun: translations.shippingRules }));
        }
      );
    }

    /**
     * Show the create form for a new Automation
     * Set to false the relevant display variables to have a pristine form
     */
    $scope.showForm = function () {
      $scope.Action.couriers_copy = angular.copy($scope.Action.couriers); // Create a clean copy of couriers

      $scope.show = {
        form: true,
        cancel: true,
      };

      $scope.Action.show = {
        chooseAction: true,
      };

      vm.Rule.show = {
        chooseRule: true,
      };

      vm.Rule.filterRules();

      AppCuesService.track("Shipping Rule | Selected 'Add Rule'", null, true);
    };

    /**
     * When a rule is selected form the 'Choose a condition' select:
     * - Clear the relevant elements of the form, in case there is unsaved rule or action open
     * - Call the options available according to the selected rule:
     *    - According to the Ruby name of the rule selected, it will call the proper function from the Rule service
     *    - Each function from the Rule service will display the proper list of option and define the proper array
     */
    $scope.displayRuleOptions = function () {
      vm.Rule.clearAllRulesSelected();
      $scope.Action.clearAllActionsSelected();
      $scope.Action.newActionName = null;
      vm.Rule.show.addRule = false;
      $scope.Action.show.addAction = false;
      vm.Rule.options = {};

      // eslint-disable-next-line default-case
      switch (vm.Rule.newRuleName.automation_rule_id) {
        case 1:
          vm.Rule.matchCategory();
          break;
        case 2:
          vm.Rule.matchCountry();
          break;
        case 3:
          vm.Rule.matchOperation();
          break;
        case 4:
          vm.Rule.matchPlatformName();
          break;
        case 5:
          vm.Rule.serviceType();
          break;
        case 6:
          vm.Rule.matchAll();
          break;
        case 8:
          vm.Rule.matchItems();
          break;
        case 9:
          vm.Rule.matchWeight();
          break;
        case 10:
          vm.Rule.matchOrderTags();
          break;
        case 11:
          vm.Rule.matchBuyerSelectedSolution();
          break;
        case 12:
          vm.Rule.matchState();
          break;
        case 13:
          vm.Rule.matchSku();
          break;
        case 14:
          vm.Rule.matchZipCode();
          break;
        default:
          break;
      }
    };

    /**
     * When an action is selected from the 'Choose an action' select:
     * Clear every element of the form, in case there is unsaved rule or action open
     * - Call the options available according to the selected action:
     *    - According to the Ruby name of the action selected, it will call the proper function from the Action service
     *    - Each function from the Action service will display the proper list of option and define the proper array
     */
    $scope.displayActionOptions = function () {
      vm.Rule.clearAllRulesSelected();
      vm.Rule.newRuleName = null;
      vm.Rule.show.addRule = false;
      $scope.Action.show.addAction = false;
      $scope.Action.options = {};
      Action.show.disableRecalculation = false;

      switch ($scope.Action.newActionName.automation_action_id) {
        case $scope.Action.ACTION_IDS.forceIncoterms:
          $scope.Action.forceIncoterms();
          break;

        case $scope.Action.ACTION_IDS.forceSortBy:
          $scope.Action.forceSortBy();
          break;

        case $scope.Action.ACTION_IDS.forceInsurance:
          $scope.Action.forceInsurance();
          break;

        case $scope.Action.ACTION_IDS.forceTracking:
          $scope.Action.forceTracking();
          break;

        case $scope.Action.ACTION_IDS.forceDeliveryTime:
          $scope.Action.forceDeliveryTime();
          break;

        case $scope.Action.ACTION_IDS.forceBox:
          $scope.Action.forceBox();
          break;

        case $scope.Action.ACTION_IDS.addNeverBox:
          $scope.Action.neverBox();
          break;

        case $scope.Action.ACTION_IDS.forceResidential:
          $scope.Action.forceResidential();
          break;

        case $scope.Action.ACTION_IDS.forceShipFrom:
          $scope.Action.forceShipFrom();
          break;

        case $scope.Action.ACTION_IDS.forceReturnLabel:
          $scope.Action.forceReturnLabel();
          break;

        case $scope.Action.ACTION_IDS.splitParcelsBySku:
          $scope.Action.splitParcelsBySku();
          Action.show.disableRecalculation = true;
          break;

        case $scope.Action.ACTION_IDS.addNeverCourier:
        case $scope.Action.ACTION_IDS.addPreferredCourier:
        default:
          Action.courierAction($scope.Action.newActionName);
      }
    };

    $scope.handleSplitParcelChanged = function (e) {
      $scope.Action.splitParcelsBySkuValue = e;
      if ($scope.isSplitParcelsBySkuAvailable()) {
        $scope.Action.addSplitParcelToOptions();
      }
    };

    /**
     * When click on 'Save condition'
     * Call addRule in the Rule service and finish rule
     *  - addRule will create a rule object, format it properly and push it to the automation object
     *  - finish will clean up the proper display variables after.
     *
     * @param {array} rules_attributes
     */
    $scope.addRule = function (rulesAttributes) {
      vm.Rule.addRule(rulesAttributes);
      const skuRuleFound = $scope.automation.rules_attributes.find(
        (rule) => rule.automation_rule_id === 13
      );
      if (skuRuleFound) {
        $scope.filterAutomationActions(Action.automation_actions);
        vm.Rule.show.addAnotherRule = false;
      } else {
        vm.Rule.show.addAnotherRule = true;
      }
      $scope.finish('rule');
    };

    /**
     * When click on 'Save action'
     * Call addAction in the Action service and finish action
     *   - addAction will create am action object, format it properly and push it to the automation object
     *   - finish will clean up the proper display variables after.
     *
     * @param {array} actions_attributes
     */
    $scope.addAction = function (actionsAttributes) {
      $scope.Action.addAction(actionsAttributes);
      $scope.finish('action');
      $scope.toggleRecalculateShipments(false);
    };

    /**
     * Call $scope.finish for a rule or an action. It will clean up the proper display in order to have a clean .
     * Call editRule or editAction
     * @param  {string} 'rule' or 'action'
     * @param  {object} rule or action
     */
    $scope.editOption = function (string, object) {
      // eslint-disable-next-line default-case
      switch (string) {
        case 'rule':
          $scope.finish('action');
          vm.Rule.editRule(object);
          break;

        case 'action':
          $scope.finish('rule');
          $scope.Action.editAction(object);
          break;

        default:
          break;
      }
    };

    // Automation

    $scope.deleteCondition = function (condition) {
      // set destroy key (for Ruby nested attributes)
      condition._destroy = true;

      vm.Rule.hasRules($scope.automation.rules_attributes);
      $scope.filterAutomationActions();
      vm.Rule.filterRules();
    };

    $scope.deleteAction = function (action) {
      // set destroy key (for Ruby nested attributes)
      action._destroy = true;
      Action.show.disableRecalculation = false;

      // preferred_couriers && never_couriers do not preserve the type attribute,
      // so, until the editor is closed, the options will be returned to the top of the
      // select element's list instead of being sorted as "My Couriers" or "Easyship Couriers"
      // eslint-disable-next-line default-case
      switch (action.name) {
        case 'Automation::Actions::AddPreferredCourier':
          $scope.Action.couriers_copy = $scope.Action.couriers_copy.concat(
            action.options.preferred_couriers
          );
          break;
        case 'Automation::Actions::AddNeverCourier':
          $scope.Action.couriers_copy = $scope.Action.couriers_copy.concat(
            action.options.never_couriers
          );
          break;
        default:
          break;
      }

      $scope.Action.hasActions($scope.automation.actions_attributes);
    };

    $scope.buildPreferCourierDisplay = function (action, courier) {
      function _isLast() {
        return (
          courier.id ===
          action.options.preferred_couriers[action.options.preferred_couriers.length - 1].id
        );
      }

      function _getAdverb() {
        return action.options.do_not_authorize_fallback ? ', and ' : ', then ';
      }

      return courier.display_name + (_isLast(action, courier) ? '' : _getAdverb(action));
    };
    /**
     * Format the object options to match what the back-end expects
     *  - In the front end: an array of string or objects: ie: ['id1','id2','id3','id4']
     *  - In the backend/database: a string: ie: ['id1,id2,id3,id4']
     *  (For the courier we need to map the ids first)
     * @param  {array} rules_attributes or actions_attributes
     */
    function formatToSave(rulesOrActions) {
      rulesOrActions.forEach(function (object) {
        let ids;

        // eslint-disable-next-line default-case
        switch (object.name) {
          case 'Automation::Rules::MatchCategory':
            object.options.category_ids = object.options.category_ids.toString();
            break;

          case 'Automation::Rules::MatchCountry':
            object.options.country_ids = object.options.country_ids.toString();
            break;

          case 'Automation::Rules::IncludeOrderTagName':
            object.options.order_tag_list = object.options.order_tag_list.toString();
            break;

          case 'Automation::Rules::MatchServiceType':
            object.options.service_type_ids = object.options.service_type_ids.toString();
            break;

          case 'Automation::Rules::MatchPlatformNameOrStore':
            object.options = formatStoresAndPlatformsArrayToSave(object.options.platformsAndStores);
            break;

          case 'Automation::Actions::AddNeverCourier':
            ids = _.map(object.options.never_couriers, 'id').toString();
            object.options = { never_courier_ids: ids };
            break;

          case 'Automation::Actions::AddPreferredCourier':
            ids = _.map(object.options.preferred_couriers, 'id').toString();
            delete object.options.preferred_couriers;
            object.options.preferred_courier_ids = ids;
            break;

          case 'Automation::Actions::ForcePackage':
            object.options.force_box_id = object.options.box.id;
            object.options.force_flat_rate_box_id = object.options.box.flat_rate_box_id;
            break;

          case 'Automation::Actions::RejectPackages':
            object.options.never_package_ids = _getBoxesIds(object.options.never_boxes).toString();
            break;

          case 'Automation::Actions::ForceShipFrom':
            object.options.origin_address_id = object.options.shipFrom.id;
            break;

          case 'Automation::Rules::MatchState':
            object.options.states = object.options.states.toString();
            break;

          default:
            break;
        }
      });
    }

    function _getBoxesIds(boxes) {
      return boxes.map(function (box) {
        return box.flat_rate_box_id || box.id;
      });
    }

    /**
     * [formatStoresAndPlatformsArrayToSave] Format data to be:
     * {
     *   platform_names: "A,B,C",
     *   store_ids: "d,e,f"
     * }
     * @return {Object}
     */
    function formatStoresAndPlatformsArrayToSave(storesAndPlatformsArray) {
      const arrayOfStoreIds = [];
      const arrayOfPlatforms = [];

      _.forEach(storesAndPlatformsArray, function (element) {
        if (element.id) {
          arrayOfStoreIds.push(element.id);
        } else {
          arrayOfPlatforms.push(element.name);
        }
      });

      return {
        platform_names: arrayOfPlatforms.join(','),
        store_ids: arrayOfStoreIds.join(','),
      };
    }

    /**
     * Clear all the relevant display variables and empty the options object
     * finish('rule') will set the proper display variables in the condition section after a condition is saved
     * finish('action') will set the proper display variables in the action section after a action is saved
     *
     * @param  {string} 'rule' or 'action'
     */
    $scope.finish = function (string) {
      vm.Rule.options = {};
      $scope.Action.options = {};

      // eslint-disable-next-line default-case
      switch (string) {
        case 'rule':
          vm.Rule.clearAllRulesSelected();
          vm.Rule.newRuleName = null;
          vm.Rule.show.addRule = false;
          vm.Rule.show.editRule = false;
          vm.Rule.show.chooseRule = false;
          displayAddAnotherRule();
          break;

        case 'action':
          $scope.Action.clearAllActionsSelected();
          $scope.Action.newActionName = null;
          $scope.Action.show.addAction = false;
          $scope.Action.show.editAction = false;
          $scope.Action.show.chooseAction = false;
          $scope.Action.show.addAnotherAction = true;
          break;

        default:
          break;
      }
    };

    /**
     * This function checks if the condition 'All Shipments' (Automation::Rules::MatchAll) has been selected by the user
     * to know if we can allow him to 'Add another condition' or not (if you want to match ALL shipments you cannot add other conditons)
     * Because of the fact that the backend is using nested attributes, if a rule is deleted but the automation is not yet saved,
     * it will still be present in the array of rules_attributes but with an extra key:value => _destroy:true.
     * We then need to check if all the 'Automation::Rules::MatchAll' conditions have been destroyed
     * to allow 'Add another condition' to be displayed again.
     */
    function displayAddAnotherRule() {
      // group all the rules 'Automation::Rules::MatchAll' into one seperate array to be able to use _.every
      const arrayOfMatchAllRules = _.filter($scope.automation.rules_attributes, {
        name: 'Automation::Rules::MatchAll',
      });
      // check if every 'Automation::Rules::MatchAll' present have been destroyed
      const everyMatchAllAreDestroyed = _.every(arrayOfMatchAllRules, {
        name: 'Automation::Rules::MatchAll',
        _destroy: true,
      });

      if (everyMatchAllAreDestroyed) {
        vm.Rule.show.addAnotherRule = true;
      } else if (arrayOfMatchAllRules.length > 0) vm.Rule.show.addAnotherRule = false;
    }

    /**
     * Update existing automation
     * formatRule and formatAction need to be called on success to recreate an array out the strings provided by the back end
     *
     */
    function updateAutomation() {
      $scope.automation.description = AutomationService.summarize($scope.automation, true);

      // Avoid multiple shipping rules to have the same priority
      delete $scope.automation.priority;

      ShippingRules.update(
        {
          company_id: UserSession.company.id,
          id: $scope.automation.id,
          recalculate_shipments: $scope.recalculateShipments,
        },
        {
          automation: $scope.automation,
        },
        function (response) {
          $scope.busy.button = false;
          $scope.show.form = false;
          $scope.clearForm();
          const updatedAutomation = response.automation;
          updatedAutomation.rules_attributes.forEach(function (rule) {
            Rule.formatRule(rule);
          });
          updatedAutomation.actions_attributes.forEach(function (action) {
            $scope.Action.formatAction(action);
          });

          // avoid doubles
          $scope.automations.splice(updatedAutomation.priority, 1, updatedAutomation);
          toastSuccess(
            $translate.instant(
              'toast.update-success',
              { noun: translations.shippingRule },
              'messageformat'
            )
          );
        },
        function (err) {
          toastError(err.data.status, { autoClose: 5000, pauseOnHover: true });
          $scope.busy.button = false;
        }
      );
    }

    /**
     * Create a new automation
     * formatRule and formatAction need to be called on success to recreate an array out the strings provided by the back end
     */
    function saveAutomation() {
      if (!$scope.automation.name) {
        $scope.automation.name = `Shipping rule (${($scope.automations.length + 1).toString()})`;
      }

      MixpanelService.track('Shipping Rules - Create Rule', {});

      $scope.automation.description = AutomationService.summarize($scope.automation, true);
      ShippingRules.create(
        {
          company_id: UserSession.company.id,
          recalculate_shipments: $scope.recalculateShipments,
        },
        {
          automation: $scope.automation,
        },
        function (response) {
          $scope.busy.button = false;
          $scope.show.form = false;
          $scope.clearForm();
          const newAutomation = response.automation;
          newAutomation.rules_attributes.forEach(function (rule) {
            Rule.formatRule(rule);
          });
          newAutomation.actions_attributes.forEach(function (action) {
            $scope.Action.formatAction(action);
          });

          // avoid doubles
          $scope.automations.unshift(newAutomation);

          toastSuccess(
            $translate.instant('toast.create-success', { noun: translations.shippingRule })
          );

          OnboardingChecklistService.updateOnboarding('create_shipping_rule');
          AppCuesService.track('Shipping Rule | Shipping Rule Created');
        },
        function (err) {
          toastError(err.data.status, { autoClose: 5000, pauseOnHover: true });
          $scope.busy.button = false;
        }
      );
    }

    /**
     * Call formatToSave to format the array into strings to be saved in the db
     * and update or save the automation
     */
    $scope.saveAutomation = function () {
      $scope.busy.button = true;
      formatToSave($scope.automation.rules_attributes);
      formatToSave($scope.automation.actions_attributes);
      if ($scope.automation.id) {
        updateAutomation();
      } else {
        saveAutomation();
      }
    };

    /**
     * Transpose the automation object into the creation panel for editing
     * @param  {object} automation
     */
    $scope.editAutomation = function (automation) {
      // Set up a clean form
      $scope.clearForm();
      vm.Rule.clearAllRulesSelected();
      $scope.Action.clearAllActionsSelected();

      // Show the relevant elements
      $scope.show.form = true;
      $scope.show.update = true;
      vm.Rule.show.hasRules = true;
      vm.Rule.show.chooseRule = false;
      vm.Rule.show.addAnotherRule = true;
      $scope.Action.show.hasActions = true;
      $scope.Action.show.addAnotherAction = true;
      $scope.Action.show.chooseAction = false;

      // Need to create a deep copy to be able to cancel
      angular.copy(automation, $scope.automation_copy);
      $scope.automation = $scope.automation_copy;

      // create a clean copy with all the couriers
      $scope.Action.couriers_copy = angular.copy($scope.Action.couriers);

      // Clear the courier list from existing couriers in the actions
      $scope.automation.actions_attributes.forEach(function (action) {
        // eslint-disable-next-line default-case
        switch (action.name) {
          case 'Automation::Actions::AddNeverCourier':
            $scope.Action.rejectCourierFromList(action.options.never_couriers);
            break;

          case 'Automation::Actions::AddPreferredCourier':
            $scope.Action.rejectCourierFromList(action.options.preferred_couriers);
            break;

          default:
            break;
        }
      });

      AppCuesService.track("Shipping Rule | Selected 'Add Rule'", null, true);
    };

    /**
     * Delete an automation from the database
     * @param  {object} automation
     */
    $scope.deleteAutomation = function (automation) {
      automation.deactivateBusy = true;
      ShippingRules.delete(
        { company_id: UserSession.company.id, id: automation.id },
        {},
        function () {
          // _.pull will remove the automation from the array (and any double)
          _.pull($scope.automations, automation);
          automation.deactivateBusy = false;
          toastSuccess(
            $translate.instant('toast.delete-success', { noun: translations.shippingRule })
          );
        },
        function () {
          automation.deactivateBusy = false;
          toastError($translate.instant('toast.delete-error', { noun: automation.name }));
        }
      );
    };

    /**
     * Clean up all scope variables in the creation panel
     */
    $scope.clearForm = function () {
      vm.Rule.newRuleName = null;
      $scope.Action.newActionName = null;
      $scope.automationName = null;
      $scope.automationDescription = null;
      $scope.show = {};
      $scope.automation = { rules_attributes: [], actions_attributes: [] };
      $scope.Action.options = {};
      vm.Rule.options = {};
      $scope.automation_copy = {};
      vm.Rule.show = { chooseRule: true };
      $scope.Action.show = { chooseAction: true };
      $scope.recalculateShipments = false;
    };

    /**
     * Drag and drop: update automation priority when the user drop an automation
     * @param  {string} automation id
     */
    $scope.move = function (id) {
      // Only the NEW index (where you want the object to be dropped) is necessary to update the priority
      // angular drag and drop will then maintain by itself the 'physical' position displayed
      const index = _.findIndex($scope.automations, { id });
      ShippingRules.update(
        { company_id: UserSession.company.id, id },
        {
          index,
        },
        function () {
          toastSuccess(
            $translate.instant(
              'toast.update-success',
              { noun: translations.shippingRule },
              'messageformat'
            )
          );
        },
        function () {
          toastError($translate.instant('toast.update-error', { noun: translations.shippingRule }));
        }
      );
    };

    /**
     * Toggle deactivate mode
     * @param  {object} automation
     */
    $scope.toggleMode = function (automation) {
      automation.deactivateMode = !automation.deactivateMode;
    };

    $scope.toggleRecalculateShipments = function (value) {
      $scope.recalculateShipments = value;
    };

    function replaceBolds(html) {
      if (html.includes('<span class="strong">')) {
        return html.replace(/<span class="strong">/g, '<span class="strong text-blue-700">');
      }
      if (html.includes('<b>')) {
        return html
          .replace(/<b>/g, '<span class="strong text-blue-700">')
          .replace(/<\/b>/g, '</span>');
      }
      return html;
    }

    $scope.summarizeCondition = function (condition, asSentence) {
      return replaceBolds(AutomationService.summarizeCondition(condition, asSentence));
    };

    $scope.summarizeAction = function (action, asSentence) {
      return replaceBolds(AutomationService.summarizeAction(action, asSentence));
    };

    $scope.isPlanBadgeVisible = function (featureKey) {
      return SubscriptionService.isPlanBadgeVisible(featureKey);
    };

    $scope.getPlanNameByFeatureKey = function (featureKey) {
      return SubscriptionService.getPlanNameByFeatureKey(featureKey);
    };

    $scope.isFeatureAccessible = function () {
      return SubscriptionService.isFeatureAccessible('shipping_rules');
    };

    $scope.isSplitParcelsBySkuAvailable = function () {
      return !SubscriptionService.isFreePlan && !SubscriptionService.isPlusPlan;
    };

    $scope.openUpgradeModalIfFeatureNotAccessible = function (featureName) {
      SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
        featureName ?? 'shipping_rules',
        $scope.mixPanelTriggerSource
      );
      $scope.$evalAsync();
    };
  }
})();
