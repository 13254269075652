import template from './resume-draft-modal.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').service('resumeDraftModal', resumeDraftModal);

  resumeDraftModal.$inject = ['$uibModal'];
  function resumeDraftModal($uibModal) {
    var service = this;

    service.open = function (draftShipment) {
      $uibModal.open({
        template,
        controller: 'resumeDraftModalCtrl',
        openedClass: 'easyship-modal-default',
        resolve: {
          draftShipment: function () {
            return draftShipment;
          },
        },
      });
    };
  }
})();
