import { IShipmentListItem, IShipmentListScope } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';

import { IHelperService } from 'typings/helper';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { MixpanelEventContext } from '@client/core/services/mixpanel/constant';
import { MODAL, openModalByName } from '@/utils/angular-modal';
import template from './shipment-list-row-table-manage.html?raw';
import style from './shipment-list-row-table-manage.module.scss';

class ShipmentListRowTableManage implements IComponentController {
  style = style;
  esShipment: IShipmentListItem | null = null;
  esIndex = 0;
  markAsDeliveredModalIsOpen = false;
  isShipmentBusy = false;

  static $inject = [
    '$scope',
    '$state',
    'UserSession',
    'showShipmentModal',
    'HelperService',
    '$stateParams',
    'ShipmentListManageService',
  ];

  constructor(
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private UserSession: IUserSession,
    private showShipmentModal: any,
    private HelperService: IHelperService,
    private $stateParams: ng.ui.IStateParamsService,
    private ShipmentListManageService: ShipmentListManageService
  ) {
    this.style = style;

    this.$state = $state;
    this.UserSession = UserSession;
    this.showShipmentModal = showShipmentModal;
    this.HelperService = HelperService;
    this.ShipmentListManageService = ShipmentListManageService;

    this.showShipmentDetails = this.showShipmentDetails.bind(this);
    this.cancelShipment = this.cancelShipment.bind(this);
    this.downloadAllShipmentDocs = this.downloadAllShipmentDocs.bind(this);
    this.printCommercialInvoice = this.printCommercialInvoice.bind(this);
    this.duplicateShipment = this.duplicateShipment.bind(this);
    this.createReturnLabel = this.createReturnLabel.bind(this);
    this.editShipment = this.editShipment.bind(this);
    this.rescheduleHandover = this.rescheduleHandover.bind(this);
    this.goToPickups = this.goToPickups.bind(this);
    this.openMarkAsDeliveredDialog = this.openMarkAsDeliveredDialog.bind(this);
    this.closeMarkAsDeliveredDialog = this.closeMarkAsDeliveredDialog.bind(this);
    this.markAsDeliveredModalOnConfirm = this.markAsDeliveredModalOnConfirm.bind(this);
    this.startInsuranceClaimFlow = this.startInsuranceClaimFlow.bind(this);
  }

  showShipmentDetails() {
    if (!this.esShipment || !this.$state.current.name) {
      return;
    }
    this.$state.go(this.$state.current.name, { shipment_id: this.esShipment.easyship_shipment_id });

    this.showShipmentModal.open(this.esShipment.easyship_shipment_id, this.esIndex);
  }

  cancelShipment() {
    if (this.esShipment) {
      const requestSectionName = this.HelperService.kebabToSnakeCase(this.$stateParams.sectionName);

      this.ShipmentListManageService.cancelShipment(
        this.esShipment,
        requestSectionName as IShipmentListScope
      );
    }
  }

  downloadAllShipmentDocs() {
    if (this.esShipment) {
      this.ShipmentListManageService.getLabel(
        this.esShipment.id,
        MixpanelEventContext.MANAGE_SHIPMENT_ROW
      );
    }
  }

  printCommercialInvoice() {
    if (this.esShipment) {
      this.ShipmentListManageService.downloadCommercialInvoice(
        this.esShipment,
        MixpanelEventContext.MANAGE_SHIPMENT
      );
    }
  }

  async duplicateShipment() {
    if (this.esShipment) {
      await this.ShipmentListManageService.duplicateShipment(this.esShipment.easyship_shipment_id);
    }
  }

  async editShipment() {
    if (this.isShipmentBusy || !this.esShipment) return;
    this.isShipmentBusy = true;

    await this.ShipmentListManageService.editShipment(this.esShipment.easyship_shipment_id);

    this.isShipmentBusy = false;
  }

  async rescheduleHandover() {
    if (this.esShipment) {
      await this.ShipmentListManageService.rescheduleHandover(this.esShipment.id);
    }
  }

  goToPickups() {
    if (this.esShipment?.id) {
      openModalByName(MODAL.SCHEDULE_PICK_UP, { shipmentIds: [this.esShipment.id] });
    }
  }

  openMarkAsDeliveredDialog() {
    this.markAsDeliveredModalIsOpen = true;
  }

  closeMarkAsDeliveredDialog() {
    this.markAsDeliveredModalIsOpen = false;
    this.$scope.$apply();
  }

  markAsDeliveredModalOnConfirm() {
    if (this.esShipment) {
      const requestSectionName = this.HelperService.kebabToSnakeCase(this.$stateParams.sectionName);

      this.ShipmentListManageService.markedAsDelivered(
        this.esShipment.id,
        requestSectionName as IShipmentListScope
      );

      this.markAsDeliveredModalIsOpen = false;

      this.$scope.$apply();
    }
  }

  async createReturnLabel() {
    if (this.esShipment) {
      await this.ShipmentListManageService.openReturnModal(this.esShipment.easyship_shipment_id);
    }
  }

  async startInsuranceClaimFlow() {
    if (this.esShipment) {
      await this.ShipmentListManageService.startInsuranceClaimFlow(
        this.esShipment.easyship_shipment_id
      );

      this.$scope.$apply();
    }
  }

  get consigneeName(): string {
    if (!this.esShipment) return '';

    if (
      this.UserSession.company.easyship_company_id === 'CNL360360' &&
      this.esShipment.destination_company_name
    ) {
      return this.esShipment.destination_company_name;
    }
    return this.esShipment.destination_name;
  }

  get serviceTypeId(): number | undefined {
    return this.esShipment?.courier?.service_type_id;
  }
}

const ShipmentListRowTableManageComponent: ng.IComponentOptions = {
  controller: ShipmentListRowTableManage,
  template,
  bindings: {
    esShipment: '<',
    esIndex: '<',
    esOnCheckboxChange: '&',
    esIsEfulfilment: '<',
  },
};

export { ShipmentListRowTableManageComponent };
