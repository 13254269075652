import { showToastWithAction } from '@client/core/components/react/ToastWithAction';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

const PULL_INTERVAL = 3000;

class ReportService {
  static $inject = [
    '$uibModalStack',
    '$state',
    '$translate',
    'ShipmentList',
    'EndpointService',
    'Shipment',
    'UserSession',
    'AppCuesService',
    'MixpanelService',
    'HelperService',
    'ReportsResource',
    'UploadErrorModalService',
  ];

  constructor(
    $uibModalStack,
    $state,
    $translate,
    ShipmentList,
    EndpointService,
    Shipment,
    UserSession,
    AppCuesService,
    MixpanelService,
    HelperService,
    ReportsResource,
    UploadErrorModalService
  ) {
    this.$uibModalStack = $uibModalStack;
    this.$state = $state;
    this.$translate = $translate;
    this.ShipmentList = ShipmentList;
    this.EndpointService = EndpointService;
    this.Shipment = Shipment;
    this.UserSession = UserSession;
    this.AppCuesService = AppCuesService;
    this.MixpanelService = MixpanelService;
    this.HelperService = HelperService;
    this.ReportsResource = ReportsResource;
    this.UploadErrorModalService = UploadErrorModalService;

    this.busy = false;
    this.showProgressBar = false;
    this.errorMessage = '';
    this.uploadTotal = 0;
    this.uploadTotalRows = 0;
    this.uploadProgress = 0;
    this.msgCount = 0;
    this.lastResponse = null;
    this.msgRepeatCount = 0;
    this.downloadProgress = 0;
    this.downloadTotal = 0;

    this.$translate(['reports.start-shipping', 'global.got-it', 'global.fix-now']).then(
      (translations) => {
        this.translations = translations;
      }
    );
  }

  resetReportService() {
    this.busy = false;
    this.showProgressBar = false;
    this.errorMessage = '';
    this.uploadTotal = 0;
    this.uploadTotalRows = 0;
    this.uploadProgress = 0;
    this.msgCount = 0;
    this.lastResponse = null;
    this.msgRepeatCount = 0;
    this.downloadProgress = 0;
    this.downloadTotal = 0;
    this.ShipmentList.isBusy = false;
    this.isInitializing = false;
    this.isFetching = false;
    this.isCreating = false;
    this.isCalculating = false;
    this.currentReport = {};
  }

  getReport({ id, listParams, platform, store, successCallback, noOrdersCallback, source }) {
    this.ShipmentList.isBusy = true;

    const fetchReport = () => {
      if (this.UserSession.user === null) return;

      this.ReportsResource.get({
        report_id: id,
        company_id: this.UserSession.getCompanyId(),
        job_type: 'api_upload',
      })
        .then((response) => {
          // Success (Upload is now in progress)
          // To keep as it is still used for the other reports
          this.msgCount += 1;

          // 0. Ensure the report exists
          this.currentReport = response && response.report;
          if (!this.currentReport) {
            this.ShipmentList.isBusy = false;
            return;
          }

          // 1. Ensure the progress bar is showing
          this.showProgressBar = true;

          // 2. Store and update current progress
          this.uploadProgress = this.currentReport.success + this.currentReport.failure;
          this.uploadTotal = this.currentReport.total;
          this.uploadTotalRows = this.currentReport.estimated_rows;

          // 3. Show a message to initialize and redirect to advanced page if needed
          if (!this.isInitializing) {
            this.isInitializing = true;

            this.$uibModalStack.dismissAll();

            switch (this.$state.current.name) {
              case 'app.home':
                this.$state.go('app.multiple');
                break;

              case 'app.platform-redirect':
              case 'app.connect':
              case 'redirection':
                if (store.id) {
                  this.$state.go('app.single-store', { id: store.id });
                } else {
                  this.$state.go('app.multiple');
                }
                break;
              default:
            }
          }

          // 4. React to current state
          switch (this.currentReport.state) {
            case 'complete': {
              this._processReport({
                listParams,
                platform,
                store,
                successCallback,
                noOrdersCallback,
                source,
              });
              return;
            }
            case 'failed': {
              const errorBody = this.currentReport.message && this.currentReport.message.body;

              if (/connection\serror/.test(errorBody)) {
                showToastWithAction(this.$translate.instant('reports.connection-error'), {
                  actionLabel: this.translations['global.fix-now'],
                  onActionLabelClick: () => {
                    this.MixpanelService.track('Disconnected Store - See Details - Toast', {
                      source,
                      store_id: store.id,
                      store_name: store.name,
                      platform,
                    });
                    this.$state.go('app.single-store', { id: store.id });
                  },
                });
              } else {
                toastError(errorBody);
              }

              this.resetReportService();
              return;
            }

            case 'fetching':
              if (!this.isFetching) {
                this.isFetching = true;
                toastSuccess(this.$translate.instant('reports.orders-fetching'));
              }
              break;

            case 'creating':
              if (!this.isCreating) {
                this.isCreating = true;
                toastSuccess(this.$translate.instant('reports.orders-creating'));
              }
              break;

            case 'calculating':
              if (!this.isCalculating) {
                this.isCalculating = true;
                toastSuccess(this.$translate.instant('reports.orders-calculating'));
              }
              break;

            case 'pending':
              // Don't reset, continue to rerun fetchReport()
              break;

            default:
              return this.resetReportService();
          }

          setTimeout(fetchReport, PULL_INTERVAL);
        })
        // Error (Import Report has no content)
        .catch((err) => {
          if (this._isSpamMsg(err.data)) {
            this.$uibModalStack.dismissAll();

            if (this.$state.current.name === 'app.connect' && store.id) {
              this.$state.go('app.single-store', { id: store.id });
            } else {
              toastError(this.$translate.instant('reports.upload-again'));
            }

            this.resetReportService();
            return;
          }

          setTimeout(fetchReport, 2000);
        });
    };

    fetchReport();
  }

  async _processReport({ listParams, platform, store, successCallback, noOrdersCallback, source }) {
    // Check if there were any orders to download
    if (
      (this.currentReport.total === 0 && this.currentReport.estimated_rows === null) ||
      this.currentReport.estimated_rows === 0
    ) {
      this._noOrdersFoundCallback({ platform, store, noOrdersCallback, source });
      return;
    }

    // If there are no errors, show the report
    if (this.currentReport.errors === null) {
      await this._refreshState(listParams.origin);

      if (this.currentReport.failure > 0) {
        toastError(
          this.$translate.instant(
            'reports.orders-failure',
            { count: this.currentReport.failure },
            'messageformat'
          )
        );
        this.UploadErrorModalService.open(this.currentReport);
      }

      if (this.currentReport.success > 0) {
        switch (this.$state.current.name) {
          case 'app.multiple':
          case 'app.home': {
            toastSuccess(
              this.$translate.instant(
                'reports.orders-success',
                {
                  count: this.currentReport.success,
                },
                'messageformat'
              )
            );

            break;
          }
          case 'app.single-store':
            showToastWithAction(
              this.$translate.instant(
                'reports.orders-success',
                {
                  count: this.currentReport.success,
                },
                'messageformat'
              ),
              {
                actionLabel: this.translations['reports.start-shipping'],
                onActionLabelClick: () => {
                  this.$state.go('app.multiple', { page: 1, reload: true }, { reload: true });
                },
              }
            );
            break;

          default:
            showToastWithAction(
              this.$translate.instant(
                'reports.orders-success',
                {
                  count: this.currentReport.success,
                },
                'messageformat'
              ),
              {
                actionLabel: this.translations['reports.start-shipping'],
                onActionLabelClick: () => {
                  this.$state.go('app.multiple');
                },
              }
            );
        }

        this.AppCuesService.track('Advanced | Created Shipment', null, true);

        if (listParams.origin === 'app.multiple.createModal.fileUpload') {
          this.MixpanelService.track('File Upload - Success', {});
        }

        if (typeof successCallback === 'function') successCallback();
      }
    }

    // Else show the errors
    else {
      if (this.currentReport && listParams.origin === 'app.multiple.createModal.fileUpload') {
        this.MixpanelService.track('File Upload - Failed', {
          error: this.currentReport.errors,
        });
      }

      switch (this.currentReport.errors) {
        case 'Incorrect Format':
          toastError(this.$translate.instant('reports.incorrect-format'));
          break;

        case 'No Shipments':
          // TBD: How is this triggered? Should we switch the response to _noOrdersFoundCallback()?
          toastError(this.$translate.instant('reports.no-shipments'));
          break;
        default:
      }
    }

    // Reset and insert new shipments
    this.resetReportService();
    if (this.$state.current.name !== 'app.single-store') {
      this.ShipmentList.insertMany(listParams);
    }
  }

  _isSpamMsg(response) {
    if (this.lastResponse === null) {
      this.lastResponse = response;
      this.msgRepeatCount = 0;
      return false;
    }

    if (angular.equals(this.lastResponse, response)) {
      this.msgRepeatCount++;
      return this.msgRepeatCount >= 30;
    }

    this.msgRepeatCount = 0;
    return false;
  }

  _noOrdersFoundCallback({ platform, store, noOrdersCallback, source }) {
    this.resetReportService();
    this.errorMessage = this.currentReport.message && this.currentReport.message.body;

    const shouldNotify =
      [
        'app.home',
        'app.platform-redirect',
        'app.connect',
        'app.single-store',
        'redirection',
        'app.multiple',
      ].indexOf(this.$state.current.name) > -1;
    const shouldRedirect =
      ['app.platform-redirect', 'app.connect', 'redirection'].indexOf(this.$state.current.name) >
      -1;

    if (shouldNotify) {
      // NOTE: This component is rendered in a way that the ui-sref directive doesn't render properly

      const errorMessage =
        this.errorMessage ||
        (this.$state.current.name === 'app.single-store'
          ? this.$translate.instant('reports.no-paid-orders-no-link')
          : this.$translate
              .instant('reports.no-paid-orders')
              .replace('<a>', `<a href="/store/${store.id}">`));

      showToastWithAction(errorMessage, {
        actionIcon: 'close',
        onActionIconClick: () => undefined,
      });
    }

    if (shouldRedirect) {
      this.$state.go('app.single-store', { id: store.id });
    } else {
      this.$uibModalStack.dismissAll();
    }

    if (typeof noOrdersCallback === 'function') noOrdersCallback();
  }

  _refreshState(origin) {
    const stateName = this.$state.current.name;

    // Prevent modal walkthrough from showing if it is a CSV upload
    const runWalkthroughValue =
      origin === 'app.multiple.createModal.fileUpload'
        ? null
        : 'order-sync-with-missing-dimensions';

    if (stateName === 'app.multiple') {
      // Need two reload attributes: one for the state itself and one to trigger data fetch
      return this.$state.go(
        'app.multiple',
        { page: 1, reload: true, runWalkthrough: runWalkthroughValue },
        { reload: true }
      );
    }
    if (stateName === 'app.shipments') {
      // Need two reload attributes: one for the state itself and one to trigger data fetch
      return this.$state.go('app.shipments', { page: 1, reload: true }, { reload: true });
    }

    return Promise.resolve();
  }

  getReportStatus(reportId) {
    return this.ReportsResource.get({
      report_id: reportId,
      company_id: this.UserSession.getCompanyId(),
      job_type: 'api_upload',
    });
  }

  watchReportStatus(reportId, messageKey, callback) {
    this.getReportStatus(reportId).then((res) => {
      if (!res || !res.report) return;

      const { success, total, estimated_rows, state, failure } = res.report;

      this.msgCount += 1;
      this.showProgressBar = true;

      if ((total === 0 && estimated_rows === null) || estimated_rows === 0) {
        if (state === 'complete') callback();

        this.resetReportService();
        return;
      }

      if (this.msgCount === 1) {
        toastSuccess(this.$translate.instant('product-listing.sync.started'));
      }

      this.uploadProgress = success + failure;
      this.uploadTotal = total;
      this.uploadTotalRows = estimated_rows;

      if (state === 'complete') {
        return this._handleReportStatus(res.report, messageKey, callback);
      }
      if (state === 'failed') {
        callback(true);
        toastError(this.$translate.instant('toast.default-error'));
        this.resetReportService();
        return;
      }

      // Wait another interval
      setTimeout(() => {
        if (this.UserSession.user !== null) this.watchReportStatus(reportId, messageKey, callback);
      }, PULL_INTERVAL);
    });
  }

  _handleReportStatus(report, messageKey, callback) {
    this.resetReportService();
    const { success, failure, errors } = report;

    if (errors) this._displayReportErrors(errors);

    if (success) {
      toastSuccess(
        this.$translate.instant(
          messageKey,
          {
            count: success,
            succeeded: true,
          },
          'messageformat'
        )
      );
      if (callback) callback();
    }

    if (failure) {
      toastError(
        this.$translate.instant(
          messageKey,
          {
            count: success,
            succeeded: false,
          },
          'messageformat'
        )
      );
    }
  }

  _displayReportErrors(errors) {
    let errorMessageKey = 'toast.default-error';

    switch (errors) {
      case 'Incorrect Format':
        errorMessageKey = 'product-listing.sync.format-error';
        break;
      case 'No Shipments':
        errorMessageKey = 'product-listing.sync.empty-error';
        break;
      default:
    }

    toastError(this.$translate.instant(errorMessageKey));
  }
}

export { ReportService };
