import { IComponentController } from 'angular';
import template from './delete-invite-member-card.html?raw';
import style from './delete-invite-member-card.module.scss';

class DeleteInviteMemberCard implements IComponentController {
  style = style;
}

const DeleteInviteMemberCardComponent: ng.IComponentOptions = {
  controller: DeleteInviteMemberCard,
  template,
  bindings: {
    email: '<',
    onDelete: '&',
    onClose: '&',
  },
};

export { DeleteInviteMemberCardComponent };
