import angular from 'angular';
import IUserGateway from '@client/core/corelogic/ports/user.interface';
import { IWalkthroughService } from 'typings/walkthrough';
import { IPreferences } from '@client/src/global/context/UserSessionProvider';
import { FeatureFlags } from '@client/core/corelogic/models/FeatureFlag';
import { ISessionData, IUser, IUserSession } from 'typings/user-session';
import { DashboardSettings } from '@client/core/corelogic/models/DashboardSettings';
import { IUserService } from 'typings/user-service';
import { PartialDeep } from 'type-fest';

export default class NgUserGateway implements IUserGateway {
  private walkthroughService: IWalkthroughService;
  private userSession: IUserSession;
  private userService: IUserService;

  constructor() {
    this.walkthroughService = angular
      .element(document.body)
      .injector()
      .get<IWalkthroughService>('WalkthroughService');
    this.userSession = angular.element(document.body).injector().get<IUserSession>('UserSession');
    this.userService = angular.element(document.body).injector().get<IUserService>('UserService');
  }

  updatePreferences(preferences: IPreferences): Promise<void> {
    return new Promise((resolve) => {
      if (preferences.showLyocBanner === false) {
        this.walkthroughService
          .updateUserFlowRecords('walkthrough', 'lyoc-banner')
          .then(() => resolve());
      } else if (preferences.showBarcodeBanner === false) {
        this.walkthroughService
          .updateUserFlowRecords('walkthrough', 'barcode-banner')
          .then(() => resolve());
      } else {
        resolve();
      }
    });
  }

  getFeatureFlags(): Promise<FeatureFlags> {
    return Promise.resolve({
      isCourierComparisonEnabled:
        this.userSession.company.dashboard_settings.beta_feature_courier_comparison ?? false,
      isNewFedexLyocEnabled:
        this.userSession.company.dashboard_settings.beta_feature_new_fedex_lyoc ?? false,
    });
  }

  getDashboardSettings(): Promise<DashboardSettings> {
    return Promise.resolve({
      localeCode: this.userSession.user.dashboard_settings?.language?.code ?? 'en',
    });
  }

  updateUser(payload: PartialDeep<IUser>) {
    return new Promise<Omit<ISessionData, 'company'>>((resolve, reject) => {
      this.userService.update(payload, payload.id).then(resolve).catch(reject);
    });
  }
}
