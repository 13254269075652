import {
  AddressFieldValidatorsMap,
  IAddress,
  IAddressService,
  PickupInstructionField,
} from 'typings/address';
import {
  ICheckoutCourier,
  ICheckoutOrigin,
  ICheckoutPayloadOrigin,
  ICheckoutService,
} from 'typings/checkout';

import { ICourierPickup } from 'typings/courier';
import { IComponentController } from 'angular';
import template from './request-pickup-origin-group-card.html?raw';
import style from './request-pickup-origin-group-card.module.scss';
import iconOriginUrl from '@assets/images/dashboard/handover-addons/icon-origin.svg';
import iconDestinationUrl from '@assets/images/dashboard/handover-addons/icon-destination.svg';

class RequestPickupOriginGroupCardController implements IComponentController {
  style = style;
  iconOriginUrl = iconOriginUrl;
  iconDestinationUrl = iconDestinationUrl;
  esOrigin: ICheckoutOrigin = {
    rates_based_on_origin_address: false,
    pickup_address_id: '',
    sender_address_id: '',
    couriers: [],
  };
  esOriginIndex = 0;
  esOnChange?: (payload: { origin: any; index: number }) => void;
  esHideLabelAddress = false;
  isPickupInstructionEditable = false;
  isPickupInstructionAvailable = false;
  payloadOrigin: ICheckoutPayloadOrigin = {
    pickup_address_id: '',
    sender_address_id: '',
    pickups: [],
  };
  addressOptions: IAddress[] = [];
  filteredCouriers: (ICheckoutCourier | ICourierPickup)[] = [];
  eligibleShipmentCount = 0;
  fieldValidators: AddressFieldValidatorsMap = {};
  unsupportedCouriersForPickupInstruction: (ICheckoutCourier | ICourierPickup)[] = [];

  static $inject = ['$scope', '$translate', 'AddressService', 'CheckoutService'];
  constructor(
    private $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private AddressService: IAddressService,
    private CheckoutService: ICheckoutService
  ) {}

  $onInit(): void {
    this.filteredCouriers = this.esOrigin.couriers.filter(
      ({ courier_handover_options }: ICheckoutCourier) => courier_handover_options.length > 0
    );

    this.eligibleShipmentCount = this.esOrigin.couriers.reduce(
      (count: number, courier: ICheckoutCourier) =>
        courier.courier_handover_options.length > 0 ? count + courier.shipments_count : count,
      0
    );

    this.AddressService.getAddresses().then(async () => {
      const pickupAddressId = this.esOrigin.pickup_address_id;
      const reusableShippingAddresses = this.AddressService.getReusableShippingAddresses();
      const currentPickupAddress = reusableShippingAddresses.find(
        ({ id }) => id === pickupAddressId
      );

      const pickupAddress =
        currentPickupAddress || (await this.AddressService.queryById(pickupAddressId));

      const addressOptions = currentPickupAddress
        ? reusableShippingAddresses
        : [...reusableShippingAddresses, pickupAddress];

      this.addressOptions = this.filterByCountryId(addressOptions, pickupAddress.country_id);

      this.esOrigin.sender_address_id =
        this.addressOptions.find(({ default_values }) => default_values.sender)?.id ??
        this.esOrigin.pickup_address_id;

      this.payloadOrigin = this.CheckoutService.payload.origins[this.esOriginIndex];

      const senderAddress = await this.AddressService.queryById(
        this.payloadOrigin.sender_address_id
      );

      this.isPickupInstructionAvailable =
        this.AddressService.isPickupInstructionBetaFeatureEnabled() &&
        pickupAddress.country.alpha2 === 'AU';

      if (this.isPickupInstructionAvailable) {
        this.initializePickupInstruction(senderAddress);
        this.unsupportedCouriersForPickupInstruction = this.filteredCouriers.filter(
          (courier) => !courier.support_pickup_instruction
        );
      }
    });

    this.$scope.$watch(
      () => this.payloadOrigin.sender_address_id,
      async (senderAddressId) => {
        if (senderAddressId) {
          const senderAddress = await this.AddressService.queryById(senderAddressId);
          this.fieldValidators = await this.AddressService.getFieldValidators(
            senderAddress.country_id
          );
        }
      }
    );
  }

  initializePickupInstruction(senderAddress: IAddress): void {
    if (
      this.payloadOrigin.pickup_instruction_slug !== undefined &&
      this.payloadOrigin.pickup_instruction_users_input !== undefined
    ) {
      return;
    }

    this.payloadOrigin.pickup_instruction_slug = senderAddress.pickup_instruction_slug || 'none';
    this.payloadOrigin.pickup_instruction_users_input =
      senderAddress.pickup_instruction_users_input || null;
  }

  generatePickupInstructionWarningMessage(): string {
    const unsupportedCouriers = this.unsupportedCouriersForPickupInstruction.map(
      (courier) => courier.courier_display_name
    );

    let message = '';

    if (unsupportedCouriers.length === 1) {
      const [unsupportedCourier] = unsupportedCouriers;
      // Pickup instruction is currently not available for A
      message = `${this.$translate.instant(
        'checkout.request-pickup.warning'
      )} ${unsupportedCourier}.`;
    } else if (unsupportedCouriers.length > 1) {
      // Pickup instruction is currently not available for A, B and C
      const lastUnsupportedCourier = unsupportedCouriers.pop();
      message = `${this.$translate.instant(
        'checkout.request-pickup.warning'
      )} ${unsupportedCouriers.join(', ')} ${this.$translate.instant(
        'global.and'
      )} ${lastUnsupportedCourier}.`;
    }

    return message;
  }

  onPickupInstructionSlugChange(slug: string | null): void {
    const defaultInput = slug === 'others' ? '' : null;
    this.payloadOrigin.pickup_instruction_users_input = defaultInput;

    this.updateErrorStateInPayloadOrigin('pickup_instruction_slug');
  }

  async updateErrorStateInPayloadOrigin(field: PickupInstructionField): Promise<void> {
    const pickupInstructionError =
      await this.AddressService.checkForPickupInstructionErrorInPayloadOrigin(this.payloadOrigin);

    const hasPickupInstructionError = pickupInstructionError[field];

    if (hasPickupInstructionError) this.payloadOrigin.errors = pickupInstructionError;
    else delete this.payloadOrigin.errors;
  }

  onAddressSelected(id: string): void {
    if (this.esHideLabelAddress) return;
    this.esOrigin.sender_address_id = id;
    this.payloadOrigin.sender_address_id = id;
  }

  onPickupAddressSelected(id: string): void {
    if (!this.allowPickupAddressChange) return;
    this.esOrigin.pickup_address_id = id;
    this.payloadOrigin.pickup_address_id = id;
  }

  get selectedPickupAddress(): IAddress {
    return this.addressOptions.find(({ id }) => id === this.esOrigin.pickup_address_id) as IAddress;
  }

  get allowPickupAddressChange(): boolean {
    // esHideLabelAddress indicates a "reschedule pickup" flow, where address changes are disallowed
    return !this.esHideLabelAddress && !this.esOrigin.rates_based_on_origin_address;
  }

  get pickupInstructionTooltipMessage(): string {
    return `${this.$translate.instant('account.addresses.pickup-instruction')}${
      this.isPickupInstructionEditable
        ? `<br><br>${this.$translate.instant('account.addresses.pickup-instruction-extended')}`
        : ''
    }`;
  }

  private filterByCountryId(addressOptions: IAddress[], countryId: number) {
    return addressOptions.filter((address) => {
      return address.country_id === countryId;
    });
  }
}

const RequestPickupOriginGroupCardComponent = {
  controller: RequestPickupOriginGroupCardController,
  template,
  bindings: {
    esOrigin: '<',
    esOriginIndex: '<',
    esHideLabelAddress: '<',
    isPickupInstructionEditable: '<',
  },
};

export { RequestPickupOriginGroupCardComponent };
