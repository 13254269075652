import React from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

export default function TaxesOptionPopover({ className }: { className?: string }) {
  return (
    <div className={twMerge('flex flex-col gap-4 text-base', className)}>
      <div>
        <div className="font-bold">
          <FormattedMessage id="shipments.courier-service.shipping-options.ddp-popover.title" />
        </div>
        <div>
          <FormattedMessage id="shipments.courier-service.shipping-options.ddp-popover.content" />
        </div>
      </div>
      <div>
        <div className="font-bold">
          <FormattedMessage id="shipments.courier-service.shipping-options.ddu-popover.title" />
        </div>
        <div>
          <FormattedMessage id="shipments.courier-service.shipping-options.ddu-popover.content" />
        </div>
      </div>
    </div>
  );
}
