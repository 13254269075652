import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { useInviteeGateway } from '../../providers/InviteeGatewayProvider';

export default function useCompanyInviteesQuery() {
  const { inviteeGateway } = useInviteeGateway();
  const { company } = useUserSession();

  return useQuery({
    queryKey: ['companyInvitees'],
    queryFn: () => inviteeGateway.getCompanyInvitees(company.id),
  });
}
