(function () {
  StoreService.$inject = [
    '$q',
    '$rootScope',
    'UserSession',
    'Store',
    'Product',
    '$window',
    'HelperService',
    'AppCuesService',
    'MixpanelService',
  ];
  function StoreService(
    $q,
    $rootScope,
    UserSession,
    Store,
    Product,
    $window,
    HelperService,
    AppCuesService,
    MixpanelService
  ) {
    const service = this;
    const storeKeys = {
      name: 'storeName',
      category: 'storeCategory',
      boxLength: 'storeBoxLength',
      boxWidth: 'storeBoxWidth',
      boxHeight: 'storeBoxHeight',
      itemweight: 'storeItemweight',
      packageId: 'packageId',
    };

    service.defaultLimit = 50;
    service.currentStoreId = '';
    service.insuranceAtCheckoutEnabled = null;

    service.stores = [];
    service.tabStores = [];
    service.listStores = [];

    service.pagination = { page: 1, start: 0, offset: 0, limit: service.defaultLimit };

    service.selectedStore = {};

    /**
     * [getStores] Retrieve all stores associated with the company
     * @return {N/A}
     */
    service.getStores = function () {
      return $q(function (resolve, reject) {
        if (!UserSession.company) reject();

        Store.query(
          {
            company_id: UserSession.company.id,
            is_active: true,
            include: 'insurance_at_checkout_state,requires_reauthorize_permission',
          },
          function (data) {
            service.stores = data.stores
              .map(function (store) {
                store.name = store.name || '[unnamed store]';
                return store;
              })
              .sort(function (a, b) {
                return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
              });
            service.insuranceAtCheckoutEnabled = _.some(data.stores, {
              insurance_at_checkout_state: 'active',
            });
            resolve({ stores: data.stores });
          },
          reject
        );
      });
    };

    /**
     * [getStore] Retrieve a store
     * @return {N/A}
     */
    service.getStore = function (storeId, companyId) {
      companyId = companyId || UserSession.company.id;

      return $q(function (resolve, reject) {
        Store.query(
          {
            company_id: companyId,
            store_id: storeId,
          },
          function (data) {
            resolve(data.store);
          },
          reject
        );
      });
    };

    /**
     * [createStore] Create a new store
     * @param  {Object} newStore
     */
    service.createStore = function (newStore) {
      newStore.is_active = true;

      if (newStore.dimensions) {
        const normalizeStore = service.normalizeStoreObject(newStore);
        newStore.default_item_length = normalizeStore.default_item_length;
        newStore.default_item_width = normalizeStore.default_item_width;
        newStore.default_item_height = normalizeStore.default_item_height;
      }

      return $q(function (resolve, reject) {
        Store.create({ company_id: UserSession.company.id }, { store: newStore }, resolve, reject);
      });
    };

    /**
     * [fetchWarehouses] Fetches a list of warehouses
     * @param {Object} credentials
     * @param {String} platform
     */
    service.fetchWarehouses = function (credentials, platform) {
      const payload = {};
      payload[platform] = credentials;

      return Store.fetchWarehouses({ company_id: UserSession.company.id, platform }, payload)
        .$promise;
    };

    // Only use by Sellbrite
    service.createStores = function (newStores) {
      const payload = {};
      const { platform } = newStores;
      const { credentials } = newStores;

      payload[platform] = angular.merge(newStores, {
        key: credentials.key,
        username: credentials.username,
      });

      return Store.create({ company_id: UserSession.company.id, platform }, payload).$promise;
    };

    /**
     * [deactivateStore] Update store to is_active false
     * @param  {Object} store
     * @param  {Integer} index
     */
    service.deactivateStore = function (store) {
      return $q(function (resolve, reject) {
        Store.deactivate(
          { company_id: UserSession.company.id, store_id: store.id },
          {},
          function () {
            const index = _.findIndex(service.stores, { id: store.id });
            service.stores.splice(index, 1);
            resolve();
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    service.getProducts = function (params, query) {
      const searchQuery = query || '';

      return $q(function (resolve, reject) {
        Product.index.query(
          {
            company_id: UserSession.company.id,
            store_id: params.store_id,
            offset: params.offset,
            limit: params.limit,
            sort_by: params.sort_by,
            order: params.order,
            query: searchQuery,
          },
          resolve,
          reject
        );
      });
    };

    service.deleteProduct = function (params) {
      return $q(function (resolve, reject) {
        Product.delete.query(
          {
            company_id: UserSession.company.id,
            store_id: params.store_id,
            id: params.id,
          },
          resolve,
          reject
        );
      });
    };

    service.updateStore = function (store) {
      return $q(function (resolve, reject) {
        Store.update(
          {
            company_id: UserSession.company.id,
            store_id: store.id,
          },
          { store },
          function (res) {
            const index = _.findIndex(service.stores, { id: res.store.id });
            service.stores[index] = res.store;
            if (service.activeStore && service.activeStore.id === res.store.id) {
              service.activeStore = res.store;
            }
            resolve(res);
          },
          reject
        );
      });
    };

    service.createOauthApplications = function (storeId) {
      return $q(function (resolve, reject) {
        Store.createOauthApplications(
          {
            company_id: UserSession.company.id,
            store_id: storeId,
          },
          {},
          resolve,
          reject
        );
      });
    };

    service.updateOauthApplications = function (storeId, oauthApplications) {
      return $q(function (resolve, reject) {
        Store.updateOauthApplications(
          {
            company_id: UserSession.company.id,
            store_id: storeId,
          },
          { oauth_applications: oauthApplications },
          resolve,
          reject
        );
      });
    };

    service.getCheckoutDiagnosticData = function (storeId) {
      return Store.getCheckoutDiagnosticData(
        { company_id: UserSession.company.id, store_id: storeId },
        {}
      ).$promise;
    };

    service.resetPagination = function () {
      service.pagination = { page: 1, start: 0, offset: 0, limit: service.defaultLimit };
    };

    service.assignMaxCount = function includeMaxProductToSelectedStore(store, res) {
      if (store.id !== service.currentStoreId) {
        store.total_products_count = res.total_record_count;
        service.currentStoreId = store.id;
      }
    };

    service.setPaginationTotal = function (total) {
      service.pagination.total = total;
    };

    service.setPaginationOffset = function (offset) {
      service.pagination.offset = offset;
    };

    service.setStore = function (store) {
      service.selectedStore = store;
    };

    service.emptyEverything = function () {
      service.selectedStore = {};
      service.tabStores = [];
      service.listStores = [];
    };

    service.activeStoreCredentialsAreInvalid = function () {
      return (
        service.activeStore &&
        service.activeStore.auth_state &&
        service.activeStore.auth_state !== 'verified'
      );
    };

    service.activeStoreIsAPI = function () {
      return _.get(service.activeStore, 'platform.group') === 'API';
    };

    service.activeStoreCanGetOrders = function () {
      return (
        service.activeStore &&
        service.activeStore.platform &&
        service.activeStore.platform.actions &&
        service.activeStore.platform.actions.get_orders
      );
    };

    service.checkConnection = function (storeId) {
      return Store.checkConnection({ company_id: UserSession.company.id, store_id: storeId })
        .$promise;
    };

    service.saveStoreValueToLocal = function (store) {
      if (store) {
        if (store.name) {
          $window.localStorage.setItem(storeKeys.name, store.name);
        }

        if (store.default_category_id) {
          $window.localStorage.setItem(storeKeys.category, store.default_category_id);
        }

        if (store.default_item_weight) {
          $window.localStorage.setItem(storeKeys.itemWeight, store.default_item_weight);
        }

        if (store.dimensions) {
          $window.localStorage.setItem(storeKeys.boxLength, store.dimensions.length);
          $window.localStorage.setItem(storeKeys.boxWidth, store.dimensions.width);
          $window.localStorage.setItem(storeKeys.boxHeight, store.dimensions.height);
        }

        if (store.default_package_id) {
          $window.localStorage.setItem(storeKeys.packageId, store.default_package_id);
        }
      }
    };

    service.removeStoreValueFromLocal = function () {
      Object.keys(storeKeys).forEach(function (storeKey) {
        $window.localStorage.removeItem(storeKeys[storeKey]);
      });
    };

    service.getStorePayloadFromLocalStorage = function () {
      const storeName = $window.localStorage.getItem('storeName');
      const storeCategory = HelperService.sanitizeStringToInteger(
        $window.localStorage.getItem('storeCategory')
      );
      const length = $window.localStorage.getItem(storeKeys.boxLength);
      const width = $window.localStorage.getItem(storeKeys.boxWidth);
      const height = $window.localStorage.getItem(storeKeys.boxHeight);
      const itemWeight = $window.localStorage.getItem(storeKeys.itemWeight);
      const packageId = $window.localStorage.getItem(storeKeys.packageId);
      let storePayload = {
        name: storeName,
      };

      const skipDefaults = !!$window.localStorage.getItem('skipDefaults');
      $window.localStorage.removeItem('skipDefaults');

      if (!skipDefaults) {
        storePayload.default_category_id = storeCategory;
        storePayload.default_item_weight = itemWeight;
        storePayload.fallback_type = 'no_fallback';
      }

      if (length) {
        storePayload = angular.merge(storePayload, {
          fallback_type: 'box',
          default_box_length: length,
          default_box_width: width,
          default_box_height: height,
        });
      }

      if (packageId) {
        storePayload = angular.merge(storePayload, {
          fallback_type: 'box',
          default_package_id: packageId,
          default_package_type: 'Box',
        });
      }

      return storePayload;
    };

    service.normalizeStoreObject = function (store) {
      const normalizeStore = angular.copy(store);

      if (normalizeStore.dimensions) {
        if (normalizeStore.dimensions.length) {
          normalizeStore.default_item_length = normalizeStore.dimensions.length;
        }

        if (normalizeStore.dimensions.width) {
          normalizeStore.default_item_width = normalizeStore.dimensions.width;
        }

        if (normalizeStore.dimensions.height) {
          normalizeStore.default_item_height = normalizeStore.dimensions.height;
        }
      }

      return normalizeStore;
    };

    service.activeStoreNeedsReauthorization = function () {
      return service.activeStore && service.activeStore.requires_reauthorize_permission;
    };

    service.sendStoreConnectedEvents = function (store) {
      var store = store || {};

      AppCuesService.track(
        'Store | Connected',
        {
          name: store.name,
          id: store.id,
          platform: store.platform && store.platform.css_class,
        },
        true,
        {
          api_saved: store.platform && store.platform.group,
        }
      );
    };

    $rootScope.$on('auth-logout-success', function () {
      service.currentStoreId = '';

      service.stores = [];
      service.tabStores = [];
      service.listStores = [];
    });
  }

  angular
    .module('app.service.StoreService', [
      'app.global.report',
      'ngFileUpload',
      'app.factory.Store',
      'app.factory.Product',
    ])
    .service('StoreService', StoreService);
})();
