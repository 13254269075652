import { IAdjustmentsParams, IAdjustmentsData } from 'typings/adjustments';
import { IApiConfig } from 'typings/core/config/api';

class AdjustmentsResource {
  adjustmentsEndpoint = '';

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.adjustmentsEndpoint = `${this.API.endpoint}/companies/:company_id/adjustment_summary`;
  }

  adjustmentsData(params: IAdjustmentsParams): ng.IPromise<IAdjustmentsData> {
    return this.$resource(this.adjustmentsEndpoint).get(params).$promise;
  }
}

export { AdjustmentsResource };
