import { toastError } from '@client/core/components/react/Toastify';
import template from './webhook-delete.html?raw';
import style from './webhook-delete.module.scss';

class WebhookDelete {
  static $inject = ['Webhooks', 'WebhookModal', 'MixpanelService', '$translate'];

  constructor(Webhooks, WebhookModal, MixpanelService, $translate) {
    this.style = style;
    this.Webhooks = Webhooks;
    this.WebhookModal = WebhookModal;
    this.MixpanelService = MixpanelService;
    this.$translate = $translate;
  }

  onDelete() {
    this.loading = true;
    this.Webhooks.deleteWebhook(this.webhook)
      .then(() => {
        this.WebhookModal.close();
        this.MixpanelService.track('Webhooks - Edit - Delete Button');
      })
      .catch(() => {
        this.loading = false;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  onCancelClick() {
    this.onBack();
    this.MixpanelService.track('Webhooks - Delete - Cancel');
  }
}

const WebhookDeleteComponent = {
  template,
  controller: WebhookDelete,
  bindings: {
    webhook: '<',
    onBack: '&',
  },
};

export { WebhookDeleteComponent };
