import { IComponentController } from 'angular';

import { IUserSession } from 'typings/user-session';

import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import chatImage from '../images/chat.svg?svgo';
import { CallToActionVariant } from '../types';
import template from './call-to-action.html?raw';
import style from './call-to-action.module.scss';

class CallToAction implements IComponentController {
  translations = {};
  style = style;
  esVariant: CallToActionVariant = 'tour';
  textKey = 'welcome.tour.text';
  buttonKey = 'welcome.tour.button';

  static $inject = ['$translate', '$state', 'MixpanelService', 'UserSession'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private $state: ng.ui.IStateService,
    private MixpanelService: MixpanelService,
    private UserSession: IUserSession
  ) {}

  $onInit(): void {
    this.$translate(['welcome']).then((translations: angular.translate.ITranslationTable) => {
      this.translations = translations;
    });

    this.textKey = `welcome.${this.esVariant}.text`;
    this.buttonKey = `welcome.${this.esVariant}.button`;
  }

  get svg(): string | null {
    switch (this.esVariant) {
      case 'chat-crowdfunding':
      case 'chat':
        return chatImage;

      default:
        return null;
    }
  }

  onClick(): void {
    switch (this.esVariant) {
      case 'chat-crowdfunding':
        this.MixpanelService.track('Welcome Actions - Click Meeting', {
          type: 'crowdfunding',
        });
        this.UserSession.openCrowdfundingMeetingLink();
        break;

      case 'chat':
        this.MixpanelService.track('Welcome Actions - Click Meeting', {
          type: 'sales',
        });
        this.UserSession.openSalesMeetingLink();
        break;

      default:
        this.$state.go('app.multiple');
    }
  }
}

const CallToActionComponent: ng.IComponentOptions = {
  controller: CallToAction,
  template,
  bindings: { esVariant: '<' },
};

export { CallToActionComponent };
