import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import style from './enjoy-free-trial-card.module.scss';
import template from './enjoy-free-trial-card.html?raw';

class EnjoyFreeTrialCard implements IComponentController {
  style = style;
  translations: angular.translate.ITranslationTable = {};

  static $inject = ['$translate', 'SubscriptionService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService
  ) {}

  get leftButtonText() {
    return this.$translate
      .instant('subscription.buttons.try-for-more-days', {
        days: this.SubscriptionService.freeTrialAmount,
      })
      .toUpperCase();
  }
}

const EnjoyFreeTrialCardComponent: ng.IComponentOptions = {
  controller: EnjoyFreeTrialCard,
  template,
  bindings: {
    esLeftButtonAction: '&',
    esCloseTitleAction: '&',
    esRightButtonAction: '&',
  },
};

export { EnjoyFreeTrialCardComponent };
