createShipmentRoutes.$inject = ['$stateProvider'];
function createShipmentRoutes($stateProvider) {
  $stateProvider.state('manual-input', {
    parent: 'app.multiple',
    url: '/manual-input',
    params: {
      baseShipment: null,
      mixpanelEvent: null,
    },
    onEnter: [
      'CreateShipmentModal',
      '$stateParams',
      (CreateShipmentModal, $stateParams) => {
        CreateShipmentModal.open({
          baseShipment: $stateParams.baseShipment,
          mixpanelEvent: $stateParams.mixpanelEvent,
        });
      },
    ],
  });
}

export { createShipmentRoutes };
