import { IUserSession } from 'typings/user-session';
import {
  IProductListingProducts,
  IProductListingTotals,
  IProductListingFilters,
  IProductListingSavePayload,
  IProductListingUpdatePayload,
  IProductListingGetSuccessResponse,
  IProductListingStoreSyncResponse,
  IProductListingStoreSyncPayload,
  IProductListingSelectedProducts,
  ProductListingExportType,
  ProductListingSuggestField,
  ProductListingSuggestResponse,
  IProductListingLoading,
} from 'typings/dashboard/services/product-listing';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';

import {
  ProductListingModal,
  ProductListingMissingFieldValue,
} from '@client/src/data/product-listing';
import { toastError } from '@client/core/components/react/Toastify';
import { ProductListingResource } from './product-listing.resource';
import { OnboardingChecklistService } from '../global/services/onboarding-checklist/onboarding-checklist.service';

const MAX_ITEMS_TO_EDIT_COUNT = 100;
const DEFAULT_ITEMS_PER_PAGE = 50;

class ProductListingService {
  activeModal: ProductListingModal | null = null;
  loading: IProductListingLoading = {
    fetchingProducts: true,
    exportingProducts: false,
    syncingStore: false,
  };
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
  currentPage = 1;
  currentFilters: IProductListingFilters = this.defaultFilter;
  newFilters: IProductListingFilters = this.defaultFilter;
  isFiltered = false;
  isSelectedAllProducts = false;
  selectedProducts: IProductListingSelectedProducts = {};
  excludedProducts: IProductListingSelectedProducts = {};
  products: IProductListingProducts[] = [];
  selectedProductsCount = 0;
  totals: IProductListingTotals = {
    missing_info_products_count: 0,
    total_products_count: 0,
  };
  isOpenBulkEditor = false;

  static $inject = [
    '$q',
    '$translate',
    'UserSession',
    'ProductListingResource',
    'OnboardingChecklistService',
  ];
  constructor(
    private $q: ng.IQService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private ProductListingResource: ProductListingResource,
    private OnboardingChecklistService: OnboardingChecklistService
  ) {
    this.itemsPerPage = this.UserSession.getItemsPerPageValue('products') || DEFAULT_ITEMS_PER_PAGE;
  }

  showModal(modal: ProductListingModal): void {
    this.activeModal = modal;
  }

  hideModal(): void {
    this.activeModal = null;
  }

  getProductList(): ng.IPromise<void> {
    this.loading.fetchingProducts = true;

    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      toastError(this.$translate.instant('toast.default-error'));
      return this.$q.reject();
    }

    return this.ProductListingResource.get(
      {
        company_id: companyId,
        include: 'totals',
      },
      {
        limit: this.itemsPerPage,
        offset: (this.currentPage - 1) * this.itemsPerPage,
        ...this.normalizeFilters,
      }
    )
      .then(({ products, totals }) => {
        this.products = products;
        this.totals = totals;
        this.loading.fetchingProducts = false;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  getProduct(productId: string): ng.IPromise<IProductListingProducts> {
    return this.ProductListingResource.show(productId);
  }

  getProductsToUpdate(): ng.IPromise<IProductListingGetSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      return this.$q.reject();
    }

    return this.ProductListingResource.get(
      {
        company_id: companyId,
      },
      {
        limit: MAX_ITEMS_TO_EDIT_COUNT,
        included_product_ids: Object.keys(this.selectedProducts),
        excluded_products_ids: Object.keys(this.excludedProducts),
        ...this.normalizeFilters,
      }
    );
  }

  getMissingProducts(): ng.IPromise<IProductListingGetSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      return this.$q.reject();
    }

    return this.ProductListingResource.get(
      {
        company_id: companyId,
      },
      {
        limit: MAX_ITEMS_TO_EDIT_COUNT,
        missing_fields: [ProductListingMissingFieldValue.All],
      }
    );
  }

  save(payload: IProductListingSavePayload): ng.IPromise<IProductListingProducts> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      return this.$q.reject();
    }

    this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.InputDimensions);

    return this.ProductListingResource.save(
      {
        company_id: companyId,
      },
      payload
    );
  }

  saveStoreProduct(
    storeId: string,
    payload: IProductListingSavePayload
  ): ng.IPromise<IProductListingProducts> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      return this.$q.reject();
    }

    this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.InputDimensions);

    return this.ProductListingResource.saveStoreProduct(
      {
        store_id: storeId,
        company_id: companyId,
      },
      payload
    );
  }

  syncStore(
    payload: IProductListingStoreSyncPayload
  ): ng.IPromise<IProductListingStoreSyncResponse> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      return this.$q.reject();
    }

    this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.InputDimensions);

    return this.ProductListingResource.syncStore(
      {
        company_id: companyId,
      },
      payload
    );
  }

  update(payload: IProductListingUpdatePayload): ng.IPromise<null> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      return this.$q.reject();
    }

    return this.ProductListingResource.update(
      {
        company_id: companyId,
      },
      payload
    );
  }

  delete(productId: string): ng.IPromise<void> {
    const companyId = this.UserSession.getCompanyId();
    if (!companyId) return this.$q.reject();

    return this.ProductListingResource.delete(
      { company_id: companyId },
      { included_product_ids: [productId], excluded_products_ids: [] }
    );
  }

  deleteSelected(): ng.IPromise<void> {
    const companyId = this.UserSession.getCompanyId();
    if (!companyId) return this.$q.reject();

    const payload = {
      included_product_ids: Object.keys(this.selectedProducts),
      excluded_products_ids: Object.keys(this.excludedProducts),
      ...this.normalizeFilters,
    };

    return this.ProductListingResource.delete({ company_id: companyId }, payload);
  }

  export(type: ProductListingExportType = 'filter'): ng.IPromise<void> {
    this.loading.exportingProducts = true;
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      return this.$q.reject();
    }

    let payload;

    switch (type) {
      case 'template':
        payload = {};
        break;
      case 'missing-products':
        payload = {
          missing_fields: [ProductListingMissingFieldValue.All],
        };
        break;
      default:
        payload = {
          included_product_ids: Object.keys(this.selectedProducts),
          excluded_products_ids: Object.keys(this.excludedProducts),
          ...this.normalizeFilters,
        };
    }

    return this.ProductListingResource.export(
      {
        company_id: companyId,
      },
      payload
    ).then(({ data }) => {
      // Convert CSV response to CSV File then download
      const csvFile = new Blob([data], { type: 'text/csv' });
      const downloadLink = document.createElement('a');

      downloadLink.download = `product-listing-${Date.now()}.csv`;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();

      this.loading.exportingProducts = false;
    });
  }

  resetSearch(): void {
    this.currentFilters = this.defaultFilter;
    this.newFilters = this.defaultFilter;
  }

  resetState(): void {
    this.products = [];
    this.totals = {
      missing_info_products_count: 0,
      total_products_count: 0,
    };
    this.currentFilters = this.defaultFilter;
    this.newFilters = this.defaultFilter;
    this.isFiltered = false;
    this.isSelectedAllProducts = false;
    this.selectedProducts = {};
    this.excludedProducts = {};
  }

  resetCheckState(): void {
    this.isSelectedAllProducts = false;
    this.selectedProducts = {};
    this.excludedProducts = {};
  }

  suggest(
    query: string,
    field: ProductListingSuggestField
  ): ng.IPromise<ProductListingSuggestResponse[]> {
    const companyId = this.UserSession.getCompanyId();

    return this.ProductListingResource.suggest(
      { company_id: companyId },
      {
        keyword: query,
        keyword_search_field: field,
        offset: 0,
        limit: 10,
      }
    ).then((res: { products: ProductListingSuggestResponse[] }) => res.products);
  }

  private get normalizeFilters(): IProductListingFilters {
    const filters = { ...this.currentFilters };

    // Add 1 day into `Last updated date to`
    if (filters.last_updated_to) {
      const lastUpdateTo = new Date(filters.last_updated_to);
      lastUpdateTo.setDate(lastUpdateTo.getDate() + 1);

      filters.last_updated_to = lastUpdateTo.toISOString();
    }

    return filters;
  }

  private get defaultFilter(): IProductListingFilters {
    return {
      keyword: '',
      missing_fields: [],
      store_ids: [],
      category_ids: [],
      origin_country_ids: [],
      min_weight: null,
      max_weight: null,
      last_updated_from: null,
      last_updated_to: null,
    };
  }
}

export { ProductListingService };
