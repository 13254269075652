(function () {
  'use strict';

  angular.module('easyshipDashboardApp').controller('PasswordNewCtrl', PasswordNewCtrl);

  PasswordNewCtrl.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    'User',
    'Auth',
    'API',
    'MixpanelService',
    '$location',
  ];
  function PasswordNewCtrl(
    $scope,
    $stateParams,
    $state,
    User,
    Auth,
    API,
    MixpanelService,
    $location
  ) {
    var vm = this;
    vm.API = API;

    (function init() {
      checkResetToken();
      MixpanelService.track('New Password after Reset Request - Arrive');
      vm.isUberFreight = $location.path().split('/')[1] === 'uber-freight';
    })();

    vm.wrappers = {
      retry: function (text) {
        if (vm.isUberFreight) {
          return '<a href="/uber-freight/forget-password">' + text + '</a>';
        } else {
          return '<a ui-sref="home.auth.password-reset">' + text + '</a>';
        }
      },
      continue: function (text) {
        return '<a ui-sref="app.home">' + text + '</a>';
      },
      contact: function (text) {
        return '<a ng-href="' + API.homepage + '/contact">' + text + '</a>';
      },
    };

    /**
     * [setPassword] Saves the new password that the user has entered
     * @return {N/A}
     */
    vm.setPassword = function savesNewPasswordToDatabase() {
      MixpanelService.track('New Password after Reset Request - Confirm');
      if (vm.newPasswordForm.$valid && vm.passwordsMatch) {
        Auth.setNewPassword(vm.user, $stateParams.resetPasswordToken)
          .then(function (res) {
            vm.success = true;
            $state.go('app.home');
          })
          .catch(function (err) {
            vm.fail = true;
            vm.errors = err.data;
          });
      }
    };

    /**
     * [checkPasswordsMatch] Checks to see if newPassword has been changed and whether newPassword and confirmPassword are equal
     * @return {N/A}
     */
    vm.checkPasswordsMatch = function saveWhetherPasswordMatchToVar() {
      vm.passwordsMatch =
        vm.newPasswordForm.confirmPassword.$dirty &&
        vm.newPasswordForm.newPassword.$modelValue ===
          vm.newPasswordForm.confirmPassword.$modelValue;
    };

    /**
     * [checkResetToken] checks to see if user has a resetToken when user navigates to the page
     * @return {N/A}
     */
    function checkResetToken() {
      User.checkResetToken(
        {},
        { reset_password_token: $stateParams.resetPasswordToken },
        function (res) {
          vm.tokenVerified = true;
        },
        function (err) {
          vm.tokenFailed = true;
        }
      );
    }
  }
})();
