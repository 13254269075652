import template from './radio.html?raw';
import style from './radio.module.scss';

class Radio {
  constructor() {
    this.style = style;
  }
}

const RadioComponent = {
  controller: Radio,
  template,
  bindings: {
    esValue: '<',
    esError: '<',
    ngChange: '&',
    ngModel: '<',
    ngModelOptions: '<',
    ngDisabled: '<',
  },
  transclude: true,
};

export { RadioComponent };
