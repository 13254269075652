import { toastError } from '@client/core/components/react/Toastify';

(function () {
  addressFormDefaultCtrl.$inject = ['$scope', '$translate', 'AddressService', 'PostalCodeService'];

  function addressFormDefaultCtrl($scope, $translate, AddressService, PostalCodeService) {
    const ctrl = this;

    ctrl.postalCodeBusy = false;
    ctrl.isPostalCodeOptional = false;
    ctrl.hasCityOptions = false;
    ctrl.cityOptions = [];

    ctrl.$onInit = function () {
      ctrl.address.city = ctrl.address.city || null;

      if (ctrl.address.city && AddressService.shouldFetchCityState(ctrl.address.country_id)) {
        _getCityOptions();
      }

      $scope.$watch('ctrl.address.country_id', function (countryId) {
        ctrl.isPostalCodeOptional = AddressService.isPostalCodeOptional(countryId);
        ctrl.hasCityOptions = AddressService.hasCityOptions(countryId);
      });
    };

    ctrl.retrieveState = function () {
      if (!AddressService.shouldFetchCityState(ctrl.address.country_id)) return;

      const isPostalCodeFormatValid = ctrl.fieldValidators.postal_code.validate(
        ctrl.address.postal_code
      );
      if (!isPostalCodeFormatValid) {
        _setCityAndState();
        return;
      }

      _getCityOptions();
    };

    function _setCityAndState(resData) {
      // eslint-disable-next-line no-param-reassign
      resData = resData || {};

      const { state, city, cities } = resData;
      const hasMultipleCityOptions = cities && cities.length > 0;

      ctrl.address.state = state ? state.name : null;
      ctrl.address.city = hasMultipleCityOptions ? cities[0] : city || null;
      ctrl.cityOptions = hasMultipleCityOptions ? cities : [];
    }

    function _getCityOptions() {
      ctrl.postalCodeBusy = true;

      const params = {
        postal_code: ctrl.address.postal_code,
        country_id: ctrl.address.country_id,
      };

      PostalCodeService.getState(params)
        .then(_setCityAndState)
        .catch(function () {
          _setCityAndState();

          toastError($translate.instant('account.addresses.invalid-postal-code'));
        })
        .finally(function () {
          ctrl.postalCodeBusy = false;
        });
    }
  }

  angular
    .module('app.component.address-form-default')
    .controller('addressFormDefaultCtrl', addressFormDefaultCtrl);
})();
