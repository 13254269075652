import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';
import { IStore } from 'typings/store';
import { IComponentController } from 'angular';
import style from './add-products-modal-import.module.scss';
import template from './add-products-modal-import.html?raw';
import { ProductListingService } from '../../product-listing.service';

class AddProductsModalImport implements IComponentController {
  style = style;
  esStore: IStore | null = null;
  loading = false;
  uploadEndpoint = '';
  exportEndpoint = '';

  static $inject = ['API', 'UserSession', 'ProductListingService', '$window'];
  constructor(
    private API: IApiConfig,
    private UserSession: IUserSession,
    private ProductListingService: ProductListingService,
    private $window: ng.IWindowService
  ) {}

  $onInit(): void {
    const baseUrl = `${this.API.endpoint}/companies/${this.UserSession.getCompanyId()}/products`;
    this.exportEndpoint = `${baseUrl}/export.csv?authorization_token=${this.UserSession.session_token}`;

    if (this.esStore) {
      this.uploadEndpoint = `${
        this.API.endpoint
      }/companies/${this.UserSession.getCompanyId()}/stores/${this.esStore.id}/products/csv_upload`;
    } else {
      this.uploadEndpoint = `${baseUrl}/csv_upload`;
    }
  }

  downloadTemplate(): void {
    const systemOfUnits = this.UserSession.getCompanyWeightUnit() === 'kg' ? 'metric' : 'imperial';
    const url = `https://assets.easyship.com/files/catalog_template_${systemOfUnits}_country_origin.xlsx`;

    this.$window.location.assign(url);
  }

  exportTemplate(): void {
    this.ProductListingService.export('template');
  }
}

const AddProductsModalImportComponent: ng.IComponentOptions = {
  controller: AddProductsModalImport,
  template,
  bindings: {
    esStore: '<',
    esOnBack: '&',
  },
};

export { AddProductsModalImportComponent };
