import { ExcludedCourierData } from '@client/core/corelogic/models/CourierServiceData';
import IShipmentGateway from '@client/core/corelogic/ports/shipment.interface';
import { useGateways } from '@client/src/global/context/GatewayProvider';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function fetchUnavailableRates(shipmentGateway: IShipmentGateway, shipmentId: string | undefined) {
  return typeof shipmentId === 'undefined'
    ? Promise.reject(new Error('Invalid shipmentId'))
    : shipmentGateway.getUnavailableRates(shipmentId);
}
interface UseUnavailableRatesQueryParams extends UseQueryOptions<ExcludedCourierData[]> {
  shipmentId: string | undefined;
}

export function useUnavailableRateQuery({ shipmentId, ...params }: UseUnavailableRatesQueryParams) {
  const { shipmentGateway } = useGateways();

  return useQuery({
    queryKey: ['unavailableRates', shipmentId],
    queryFn: () => fetchUnavailableRates(shipmentGateway, shipmentId),
    ...params,
  });
}
