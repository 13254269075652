import angular, { IComponentController } from 'angular';

import { IPickupsFilters } from 'typings/pickup';

import template from './advanced-search.html?raw';
import style from './advanced-search.module.scss';
import { PickupsPageService } from '../pickups-page.service';

type DrawerStatus = 'menu' | 'content';
type DrawerMenuKey = 'couriers' | 'date' | 'address';

class AdvancedSearch implements IComponentController {
  readonly drawerMenuList: DrawerMenuKey[] = ['couriers', 'date', 'address'];

  style = style;
  currentStatus: DrawerStatus = 'menu';
  currentContent: DrawerMenuKey | null = null;
  tempFilters = {} as IPickupsFilters;

  static $inject = ['PickupsPageService'];
  constructor(private PickupsPageService: PickupsPageService) {}

  get filters(): IPickupsFilters {
    return this.PickupsPageService.filters;
  }

  set filters(value) {
    this.PickupsPageService.filters = value;
  }

  get filtersNumber(): number {
    return Object.keys(this.filters).length;
  }

  backToMenu(): void {
    this.currentStatus = 'menu';
    this.tempFilters = {} as IPickupsFilters;
  }

  onMenuClick(menu: DrawerMenuKey): void {
    this.currentStatus = 'content';
    this.currentContent = menu;
  }

  onFilterChange(value: any, key: DrawerMenuKey): void {
    this.tempFilters[key] = value;
  }

  resetField(): void {
    this.currentStatus = 'menu';
    if (this.currentContent) {
      delete this.filters[this.currentContent];
    }
    this.tempFilters = {} as IPickupsFilters;
    this.getPickups();
  }

  applyFilter(): void {
    this.currentStatus = 'menu';
    this.filters = {
      ...this.filters,
      ...angular.copy(this.tempFilters),
    };
    this.tempFilters = {} as IPickupsFilters;
    this.PickupsPageService.isFiltered = true;

    this.getPickups();
  }

  resetFilters(): void {
    this.filters = {} as IPickupsFilters;

    this.getPickups();
  }

  private getPickups(): void {
    this.PickupsPageService.getPickups();
    this.PickupsPageService.updateTotals();
  }
}

const AdvancedSearchComponent: ng.IComponentOptions = {
  controller: AdvancedSearch,
  template,
  bindings: {
    esIsOpen: '<',
    esOnClose: '&',
  },
};

export { AdvancedSearchComponent };
