import { IComponentController } from 'angular';
import template from './address-recalculation-warning.html?raw';

class AddressRecalculationWarningController implements IComponentController {}

const AddressRecalculationWarningComponent: ng.IComponentOptions = {
  controller: AddressRecalculationWarningController,
  template,
  bindings: {
    esShow: '<',
    esConfirm: '&',
    esCancel: '&',
  },
};

export { AddressRecalculationWarningComponent };
