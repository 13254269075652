import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './resend-emails-modal-main.html?raw';
import style from './resend-emails-modal-main.module.scss';

class ResendEmailsModalMain {
  static $inject = ['$translate', 'CollectBulkService', 'ResendEmailsModal', 'MixpanelService'];

  constructor($translate, CollectBulkService, ResendEmailsModal, MixpanelService) {
    this.style = style;
    this.busy = false;
    this.error = null;
    this.$translate = $translate;
    this.CollectBulkService = CollectBulkService;
    this.ResendEmailsModal = ResendEmailsModal;
    this.MixpanelService = MixpanelService;
  }

  onSubmit() {
    this.busy = true;
    this.MixpanelService.track('Collect - Resend Multiple - Confirm', {
      first_count: this.emailsCount.survey,
      reminder_count: this.emailsCount.reminder,
      confirm_count: this.emailsCount.confirmation,
    });
    this.CollectBulkService.resendEmailsConfirm(this.shipments)
      .then((res) => {
        const totalEmailsSent = res.first_count + res.reminder_count + res.confirm_count;
        toastSuccess(
          this.$translate.instant('shipments.collect-service.bulk-confirmation.success', {
            count: totalEmailsSent,
          })
        );
        this.ResendEmailsModal.close();
      })
      .catch((error) => {
        this.error =
          (error.data && error.data.message) ||
          toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy = false;
      });
  }
}

const ResendEmailsModalMainComponent = {
  template,
  controller: ResendEmailsModalMain,
  bindings: {
    shipments: '<',
    emailsCount: '<',
  },
};

export { ResendEmailsModalMainComponent };
