import { IRegistrationSuccessResponse } from 'typings/auth/services/registration';
import { RegistrationResource } from '../registration/registration.resource';

const PLATFORM = 'trade_gecko';

class TradeGeckoAuthService {
  static $inject = ['$location', 'RegistrationResource'];
  constructor(
    public $location: ng.ILocationService,
    public RegistrationResource: RegistrationResource
  ) {}

  createRegistration(): ng.IPromise<IRegistrationSuccessResponse> {
    const { code } = this.$location.search();

    return this.RegistrationResource.platform(
      { platform: PLATFORM },
      {
        code,
      }
    );
  }
}

export { TradeGeckoAuthService };
