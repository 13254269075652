import angular from 'angular';
import ngResource from 'angular-resource';

import { CheckResidentialResource } from './check-residential.resource';
import { CheckResidentialService } from './check-residential.service';

angular
  .module('app.global.check-residential', [ngResource, 'core.config'])
  .service('CheckResidentialService', CheckResidentialService)
  .service('CheckResidentialResource', CheckResidentialResource);
