import { toastError } from '@client/core/components/react/Toastify';
import { RETURN_REASONS } from '@client/src/data/return-reasons';

import template from './select-courier.html?raw';
import style from './select-courier.module.scss';

class ReturnModalSelectCourier {
  static $inject = [
    '$scope',
    'API',
    'UserSession',
    'AddressService',
    'ReturnModal',
    'ReturnService',
    'MixpanelService',
    'CountryService',
    'BrokersService',
    'CourierAccounts',
    '$translate',
  ];

  constructor(
    $scope,
    API,
    UserSession,
    AddressService,
    ReturnModal,
    ReturnService,
    MixpanelService,
    CountryService,
    BrokersService,
    CourierAccounts,
    $translate
  ) {
    this.$scope = $scope;
    this.style = style;
    this.API = API;
    this.UserSession = UserSession;
    this.AddressService = AddressService;
    this.ReturnModal = ReturnModal;
    this.ReturnService = ReturnService;
    this.MixpanelService = MixpanelService;
    this.CountryService = CountryService;
    this.BrokersService = BrokersService;
    this.CourierAccounts = CourierAccounts;
    this.$translate = $translate;

    this.busy = {};
    this.addresses = [];
    this.addressForm = {};
    this.brokers = [];
    this.showAddressForm = false;
    this.enableShipmentInformationFields = false;
    this.payload = {};
    this.ratesErrors = [];
    this.rates = [];
    this.hasCompanyFedex = false;
    this.isFedExSelected = false;
    this.returnReasons = RETURN_REASONS.map((returnReason) => ({
      value: returnReason,
      label: this.$translate.instant(`shipments.return-label-modal.return-reason.${returnReason}`),
    }));
    this.fieldValidators = {};
    this.onWeightChange = this.onWeightChange.bind(this);
  }

  $onInit() {
    this.customerInfo = this._buildCustomerInfo(this.shipment);
    this.addresses = [...this.AddressService.getShippingAddresses()];
    this.addresses.sort((a, b) =>
      a.fullAddress.localeCompare(b.fullAddress, undefined, { sensitivity: 'base', numeric: true })
    );
    this.payload = this.ReturnService.payload;
    this.ratesErrors = _.get(this.ReturnService, 'ratesErrorMessages');
    this.rates = _.get(this.ReturnService, 'data.price_comparison.rates');
    this.hasCompanyFedex = this.CourierAccounts.hasCompanyFedex();
    this.isFedExSelected = this._isFedExSelected();

    this.displayWeightUnit = this.payload.display_weight_unit;
    this.displayWeight = this.payload.display_weight;
    this.weightInputError = 'default';

    if (this.hasCompanyFedex) {
      this._getBrokers();
    }

    this.$scope.$watch(
      () => this.addressForm.country_id,
      (countryId) => {
        this.AddressService.getFieldValidators(countryId).then((fieldValidators) => {
          this.fieldValidators = fieldValidators;
        });
      }
    );
  }

  editCustomerInformation() {
    this.showAddressForm = true;

    this.addressForm = {
      company_name: this.shipment.destination_company_name,
      contact_name: this.shipment.destination_name,
      contact_email: this.shipment.email_address,
      contact_phone: this.shipment.phone_number,
      country_id: this.shipment.destination_country_id,
      line_1: this.shipment.address_line_1,
      line_2: this.shipment.address_line_2,
      city: this.shipment.city,
      postal_code: this.shipment.postal_code,
      state: this.shipment.state,
    };
  }

  discardChanges() {
    this.showAddressForm = false;
  }

  saveCustomerInformation() {
    const {
      contact_name,
      company_name,
      contact_email,
      contact_phone,
      country_id,
      line_1,
      line_2,
      city,
      postal_code,
      state,
    } = this.addressForm;

    this.payload.origin_address = {
      contact_name,
      company_name,
      contact_email,
      contact_phone,
      country_id,
      line_1,
      line_2,
      city,
      postal_code,
      state,
    };

    this.MixpanelService.track('Returns - Edit Customer Information - Save', {
      easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
    });

    this._recalculate().then(() => {
      this.shipment.destination_company_name = this.addressForm.company_name;
      this.shipment.destination_name = this.addressForm.contact_name;
      this.shipment.email_address = this.addressForm.contact_email;
      this.shipment.phone_number = this.addressForm.contact_phone;
      this.shipment.destination_country_id = this.addressForm.country_id;
      this.shipment.destination_country = this.CountryService.findCountry(
        this.addressForm.country_id
      );
      this.shipment.address_line_1 = this.addressForm.line_1;
      this.shipment.address_line_2 = this.addressForm.line_2;
      this.shipment.city = this.addressForm.city;
      this.shipment.postal_code = this.addressForm.postal_code;
      this.shipment.state = this.addressForm.state;

      this.customerInfo = this._buildCustomerInfo(this.shipment);

      this.showAddressForm = false;
    });
  }

  saveShipmentInformation() {
    if (this.shipmentForm.$invalid) return;
    if (this.weightInputError === 'error') return;

    this.MixpanelService.track('Returns - Select Return Method - Save', {
      easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
    });

    this._recalculate().then(() => {
      this.enableShipmentInformationFields = false;
    });
  }

  editShipmentInformation() {
    this.enableShipmentInformationFields = true;

    this.MixpanelService.track('Returns - Select Return Method - Edit', {
      easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
    });
  }

  onInputChange(value, key) {
    if (['return_type', 'return_description'].includes(key)) {
      if (!this.payload.order_data) {
        this.payload.order_data = {};
      }

      this.payload.order_data[key] = value;

      return;
    }

    if (key === 'broker_id' && value === 'none') {
      this.payload[key] = null;
    } else {
      this.payload[key] = value;
    }

    if (key === 'incoterms') {
      this._recalculate();
    }
  }

  onWeightChange(unit, value) {
    this.displayWeightUnit = unit;
    this.displayWeight = value;

    let actualWeightWithSystemUnit = '';

    if (parseFloat(this.displayWeight) > 0) {
      this.weightInputError = 'default';
      if (unit === 'oz') {
        // convert oz to lb
        actualWeightWithSystemUnit = parseFloat(this.displayWeight) / 16;
      } else if (unit === 'g') {
        // convert g to kg
        actualWeightWithSystemUnit = parseFloat(this.displayWeight) / 1000;
      } else {
        // keep it the same as system unit
        actualWeightWithSystemUnit = parseFloat(this.displayWeight);
      }
    } else {
      this.weightInputError = 'error';
    }

    this.onInputChange(actualWeightWithSystemUnit, 'actual_weight');
    this.onInputChange(value, 'display_weight');
    this.onInputChange(unit, 'display_weight_unit');
    this.$scope.$apply();
  }

  showIncoterms() {
    const returnAddress =
      this.payload.origin_address ||
      this.addresses.find((address) => {
        return address.id === this.payload.address_id;
      });

    return returnAddress.country_id !== this.shipment.destination_country_id;
  }

  onSelectCourier(courier) {
    this.payload.courier_id = courier.courier_id;
    this.isFedExSelected = this._isFedExSelected();
  }

  onCloseClick() {
    this.MixpanelService.track('Returns - Select Return Method - Cancel', {
      easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
    });

    this.ReturnModal.close();
  }

  _assignErrors(data) {
    return (data && data.ratesErrorMessages) || [];
  }

  onPrepare() {
    if (!this.payload.courier_id) return;

    if (this.showAddressForm || this.enableShipmentInformationFields) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    if (this.isFedExSelected && !this.payload.order_data.return_type) {
      toastError(this.$translate.instant('return-modal.notifications.reason-error'));
      return;
    }

    this.busy.prepare = true;

    this.ReturnService.prepare(this.shipment.id, this._getPayload())
      .then(() => {
        this.MixpanelService.track('Returns - Select Return Method - Pay', {
          easyship_shipment_id: this.shipment && this.shipment.easyship_shipment_id,
          courier_name: this._getSelectedCourierName(),
        });

        this.ReturnService.goToPayReturnStep(this.payload);
      })
      .catch((error) => {
        toastError(this.$translate.instant(error.data.error));
      })
      .finally(() => {
        this.busy.prepare = false;
      });
  }

  _buildCustomerInfo(shipment) {
    const info = {};
    const destinationCountry = shipment.destination_country && shipment.destination_country.name;

    info.name = shipment.destination_name;
    info.line1 = _.filter([shipment.phone_number, shipment.email_address]).join(', ');
    info.line2 = _.filter([
      shipment.address_line_1,
      shipment.address_line_2,
      shipment.city,
      shipment.postal_code,
      shipment.state,
      destinationCountry,
    ]).join(', ');

    return info;
  }

  _getSelectedCourier() {
    return this.rates.find((rate) => {
      return rate.courier_id === this.payload.courier_id;
    });
  }

  _getSelectedCourierName() {
    const courierService = this._getSelectedCourier();
    return courierService && courierService.courier_display_name;
  }

  _isFedExSelected() {
    const courierService = this._getSelectedCourier();
    return courierService && courierService.courier_logo === 'fedex';
  }

  _getBrokers() {
    const companyFedexAccount = this.CourierAccounts.getFedExActiveAccount();

    this.BrokersService.getBrokers({
      courier_account_id: companyFedexAccount.id,
    }).then((data) => {
      this.brokers = data.brokers.map((broker) => {
        return {
          ...broker,
          display_text: `${broker.contact_name}, ${broker.company_name}, ${broker.address_line_1}, ${broker.city}, ${broker.state}`,
        };
      });

      this.brokers = [
        {
          id: 'none',
          display_text: 'None',
        },
        ...this.brokers,
      ];
    });
  }

  _recalculate() {
    this.busy.calculate = true;

    return this.ReturnService.calculateRates(this.shipment.id, this._getPayload())
      .then((data) => {
        this.rates = data.rates;
        this.ratesErrors = this._assignErrors(data);
        this.payload = this.ReturnService.payload;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.calculate = false;
      });
  }

  _getPayload() {
    const payload = angular.copy(this.payload);

    return {
      ...payload,
      incoterms: this.payload.incoterms ? 'DDU' : 'DDP',
    };
  }
}

const ReturnModalSelectCourierComponent = {
  controller: ReturnModalSelectCourier,
  template,
  bindings: {
    shipment: '<',
  },
};

export { ReturnModalSelectCourierComponent };
