import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { ProductListingService } from '../product-listing.service';
import template from './delete-product-modal.html?raw';
import style from './delete-product-modal.module.scss';

class DeleteProductModal implements IComponentController {
  style = style;
  loading = false;

  static $inject = ['$translate', 'ProductListingService', 'MixpanelService', 'UserRightsService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private ProductListingService: ProductListingService,
    private MixpanelService: MixpanelService,
    private UserRightsService: UserRightsService
  ) {}

  onSubmit(): void {
    this.loading = true;
    this.ProductListingService.deleteSelected()
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.delete-count',
            {
              count: this.selectedProductsCount.toString(),
              noun: this.$translate
                .instant(
                  'global.pluralize.product',
                  { count: this.selectedProductsCount },
                  'messageformat'
                )
                .toLowerCase(),
            },
            'messageformat'
          )
        );
        this.ProductListingService.hideModal();
        this.ProductListingService.getProductList();
        this.ProductListingService.resetCheckState();

        this.MixpanelService.track('Products - Delete');
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get selectedProductsCount(): number {
    return this.ProductListingService.selectedProductsCount;
  }

  get isDeleteButtonDisabled(): boolean {
    return this.loading || !this.UserRightsService.canDeleteProduct;
  }

  back(): void {
    this.ProductListingService.hideModal();
  }
}

const DeleteProductModalComponent: ng.IComponentOptions = {
  controller: DeleteProductModal,
  template,
  bindings: {},
};

export { DeleteProductModalComponent };
