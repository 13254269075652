import { toastError } from '@client/core/components/react/Toastify';

class BillingService {
  isFiltered = false;

  hideClosingBalance = false;

  static $inject = [
    'UserSession',
    'TransactionRecord',
    'HelperService',
    '$timeout',
    '$q',
    '$translate',
  ];

  constructor(UserSession, TransactionRecord, HelperService, $timeout, $q, $translate) {
    this.UserSession = UserSession;
    this.TransactionRecord = TransactionRecord;
    this.HelperService = HelperService;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$translate = $translate;

    this.isAdvancedSearchOpen = false;
    this.resetState();
  }

  toggleAdvancedSearch() {
    this.isAdvancedSearchOpen = !this.isAdvancedSearchOpen;
  }

  setPagination(page) {
    this.pagination = { ...this.pagination, ...{ page, offset: (page - 1) * this.itemsPerPage } };
  }

  resetFilter() {
    this.filter = this._setDefaultFilter();
    this.isFiltered = false;
    this.hideClosingBalance = false;
  }

  resetState() {
    this.itemsPerPage = this.UserSession.getItemsPerPageValue('billing');
    this.pagination = this._setDefaultPagination();
    this.financialDocuments = this._setDefaultFinancialDocuments();
    this.filter = this._setDefaultFilter();
    this.isFiltered = false;
    this.hideClosingBalance = false;
  }

  /**
   * @param {"invoice"|"credit_note"|"receipt"|"subscription"|""} category
   */
  fetchListing(category) {
    this.isFiltered = this._setFilterState();
    return category ? this._fetchFinanceDocuments(category) : this._fetchTransactions();
  }

  _fetchTransactions() {
    const params = this._buildPayload();

    return this.TransactionRecord.getTransactions(params)
      .then((data) => {
        const itemCount = data.totals.transactions_count;
        const nextBillingDate = data.next_billing_date;

        return { listing: data.transaction_records, itemCount, nextBillingDate };
      })
      .catch((err) => {
        throw err;
      });
  }

  exportTransactions(params, payload) {
    return this.TransactionRecord.exportTransactions(params, payload).catch((err) => {
      const errorCode = err.data?.error_code;
      if (errorCode) {
        toastError(
          this.$translate.instant(`header.${errorCode}`, { email: this.UserSession.user.email })
        );
      } else {
        toastError(this.$translate.instant('header.export-error'), {
          email: this.UserSession.user.email,
        });
      }
    });
  }

  _fetchFinanceDocuments(section) {
    const params = this._buildPayload(section);

    return this.TransactionRecord.getFinanceDocuments(params)
      .then((data) => {
        const listing = data.finance_documents;
        const itemCount = data.totals.count;

        return { listing, itemCount };
      })
      .catch((err) => {
        throw err;
      });
  }

  downloadFinancialDocuments(ids) {
    return this.TransactionRecord.downloadFinancialDocuments({ finance_documents_ids: ids })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  buildFinancialDocumentsPayload(financialDocuments) {
    return Object.keys(financialDocuments.selectedDocuments).filter(
      (id) => financialDocuments.selectedDocuments[id]
    );
  }

  /**
   * @param {"invoice"|"credit_note"|"receipt"|"subscription"=} category
   */
  _buildPayload(category = '') {
    if (this.filter && this.filter.to_date) {
      this.filter.to_date = this._includeFullDay(this.filter.to_date);
    }

    let mappedTransactionableTypes = '';
    if (this.filter.transactionable_types.length > 0) {
      mappedTransactionableTypes = this.filter.transactionable_types.split(',');
    }

    const params = {
      limit: this.pagination.limit,
      offset: this.pagination.offset,
      keyword: this.filter.keyword,
      from_date: this.filter.from_date,
      to_date: this.filter.to_date,
      transactionable_types: mappedTransactionableTypes,
      balance_type: this.filter.balance_type,
      category,
      finance_document_id: this.filter.finance_document_id,
      settlement_state: this.filter.settlement_state,
    };

    return params;
  }

  _setFilterState() {
    const { keyword, ...filterQuery } = this.filter;
    const { to_date, from_date, ...otherFilters } = filterQuery;

    this.hideClosingBalance = !this.HelperService.areObjKeyLengthsNil(otherFilters);
    return !this.HelperService.areObjKeyLengthsNil(filterQuery);
  }

  _setDefaultFilter() {
    return {
      balance_type: '',
      transactionable_types: '',
      finance_document_id: '',
      settlement_state: '',
    };
  }

  _setDefaultPagination() {
    return { limit: this.itemsPerPage, offset: 0, page: 1 };
  }

  _setDefaultFinancialDocuments() {
    return {
      selectedPages: {},
      selectedDocuments: {},
    };
  }

  _includeFullDay(date) {
    // ensure we include the full day
    return moment(date).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').toDate();
  }
}

export { BillingService };
