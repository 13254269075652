import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './box-update.html?raw';
import style from './box-update.module.scss';

class BoxUpdateController {
  static $inject = [
    'BoxService',
    'MixpanelService',
    'UserSession',
    '$translate',
    'UserRightsService',
  ];

  constructor(BoxService, MixpanelService, UserSession, $translate, UserRightsService) {
    this.BoxService = BoxService;
    this.MixpanelService = MixpanelService;
    this.UserSession = UserSession;
    this.$translate = $translate;

    this.style = style;
    this.loading = false;
    this.UserRightsService = UserRightsService;
  }

  $onInit() {
    this.autoSelection = this.box.auto_selected;
    this.MixpanelService.track('Boxes - Edit - Start');
    this.weightUnit = this.UserSession.getCompanyWeightUnit();
  }

  onAutoSelectionChange(value) {
    this.autoSelection = value;
  }

  onSubmit() {
    this.loading = true;

    const payload = {
      box: {
        auto_selected: this.autoSelection,
      },
    };

    this.BoxService.updateBox(this.box.id, payload)
      .then(() => {
        this.close();
        toastSuccess(
          this.$translate.instant('toast.update-success', { noun: this.box.name }, 'messageformat')
        );
        this.MixpanelService.track('Boxes - Edit - Submit');
      })
      .catch(() => {
        this.loading = false;
        this.error = true;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  onDelete() {
    this.onDelete();
    this.MixpanelService.track('Boxes - Edit - Delete');
  }

  onCancel() {
    this.close();
  }

  get isSaveButtonDisabled() {
    return !this.UserRightsService.canEditBox || this.loading;
  }
}

const BoxUpdateComponent = {
  template,
  controller: BoxUpdateController,
  bindings: {
    box: '<',
    close: '&',
    onDelete: '&',
  },
};

export { BoxUpdateComponent };
