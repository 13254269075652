import { Platform, EcommercePlatform, OtherChannel, fallbackKey } from '@client/data/platform';

import template from './platform-logo.html?raw';
import style from './platform-logo.module.scss';

interface ImportResponse {
  default: string;
}

const imports = import.meta.glob<boolean, 'svgo', ImportResponse>(
  '@assets/images/logos/platform-logo-icon/*.svg',
  {
    as: 'svgo',
  }
);

const UNSUPPORTED_PLATFORM_KEYS = [
  '11thstreet',
  'alloy-automation',
  'anchanto',
  'builderall',
  'celigo',
  'duda',
  'fancy',
  'jd',
  'jumperai',
  'lelong',
  'm18',
  'make',
  'newegg',
  'odoo',
  'omnyfy',
  'other-api-platform',
  'qoo10',
  'rakuten',
  'shopcada',
  'taobao',
  'tmall',
  'uber-freight',
  'wish',
];

class PlatformLogo {
  style = style;
  detailedImgSrc?: string;
  sidebarInclude?: string;
  logoStyle?: { 'background-image': string; 'background-size': string };

  esKey: Platform | 'zoho_inventory' = OtherChannel.DirectSales;
  esLogoSize = 'mini';
  iconLogo = '';

  async $onChanges(): Promise<void> {
    if (this.esKey === 'zoho_inventory') this.esKey = EcommercePlatform.ZohoInventory;
    this.esKey = this.esKey || OtherChannel.DirectSales;
    this.esLogoSize = this.esLogoSize || 'mini';
    this.iconLogo = await this.getIconLogo();
  }

  get useMiniLogo(): boolean {
    return ['mini', 'large'].includes(this.esLogoSize);
  }

  get miniLogoUrl() {
    const unsupportedKeyFound = UNSUPPORTED_PLATFORM_KEYS.includes(this.esKey);

    let imagePath = new URL(`./assets/mini/${this.esKey}.svg`, import.meta.url).href;

    if (unsupportedKeyFound) {
      imagePath = new URL(`./assets/mini/${fallbackKey}.svg`, import.meta.url).href;
    }
    return imagePath;
  }

  get cardLogoUrl() {
    let imagePath = new URL(
      `../../../../../assets/images/logos/platform-logo-color/${this.esKey}-color.svg`,
      import.meta.url
    ).href;

    if (!imagePath) {
      imagePath = new URL(
        `../../../../../assets/images/logos/platform-logo-color/api-color.svg`,
        import.meta.url
      ).href;
    }
    return imagePath;
  }

  get detailedLogoUrl() {
    const unsupportedKeyFound = UNSUPPORTED_PLATFORM_KEYS.includes(this.esKey);

    let imagePath = new URL(
      `../../../../../assets/images/logos/platform-logo-detailed/${this.esKey}-detailed.svg`,
      import.meta.url
    ).href;

    if (unsupportedKeyFound) {
      imagePath = new URL(
        `../../../../../assets/images/logos/platform-logo-icon/${fallbackKey}-icon.svg`,
        import.meta.url
      ).href;
    }
    return imagePath;
  }

  async getIconLogo(): Promise<string> {
    let importCallback = imports[`/assets/images/logos/platform-logo-icon/${this.esKey}-icon.svg`];

    if (!importCallback) {
      importCallback = imports[`/assets/images/logos/platform-logo-icon/${fallbackKey}-icon.svg`];
    }

    const res = await importCallback();

    return res.default;
  }
}

const PlatformLogoComponent = {
  controller: PlatformLogo,
  template,
  bindings: {
    esKey: '<',
    esLogoSize: '@',
    ngDisabled: '<',
  },
};

export { PlatformLogoComponent };
