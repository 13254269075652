import angular, { IComponentController } from 'angular';
import { ICourierAccountsService } from 'typings/courier';
import { IShipmentOrderData } from 'typings/shipment';
import { PayorType, payorTypes } from '@client/core/corelogic/models/Shipment';

import template from './billing-options.html?raw';

interface PayorTypeOption {
  value: PayorType;
  label: string;
}

class BillingOptions implements IComponentController {
  esModel?: IShipmentOrderData = {};
  esOnChange?(value: any): void;
  esDefaultAccountNumber?: string;

  paymentType: PayorTypeOption[] = [];
  hasMoreThanOneCompanyFedexAccount = false;

  static $inject = ['CourierAccounts', '$translate'];
  constructor(
    private CourierAccounts: ICourierAccountsService,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit(): void {
    this.paymentType = payorTypes.map((option) => ({
      value: option,
      label: this.$translate.instant(`shipments.billing-options.payment-type.${option}`),
    }));
    if (this.esModel) {
      if (!this.esModel.fedex_shipping_charges_payment_type) {
        this.esModel.fedex_shipping_charges_payment_type = 'SENDER';
      }

      if (!this.esModel.fedex_duties_payment_type) {
        this.esModel.fedex_duties_payment_type = 'SENDER';
      }
    }

    this.hasMoreThanOneCompanyFedexAccount =
      this.CourierAccounts.getAccountsFromSpecificCourier('fedex').length > 1;
  }

  $doCheck(): void {
    if (this.esModel) {
      if (this.esModel.fedex_duties_payment_type === 'SENDER') {
        this.esModel.fedex_duties_payment_account_number = '';
      }

      if (
        this.esModel.fedex_shipping_charges_payment_type === 'SENDER' &&
        !this.esModel.fedex_shipping_charges_account_number &&
        this.esDefaultAccountNumber
      ) {
        this.esModel.fedex_shipping_charges_account_number = this.esDefaultAccountNumber;
      }
    }
  }

  get isTaxesPayorAccountNumberRequired(): boolean {
    return this.esModel?.fedex_duties_payment_type === 'THIRD_PARTY';
  }

  get taxesPayorAccountNumberPlaceholder(): string {
    let label = this.$translate.instant('shipments.billing-options.fedex-account-no.label');

    if (!this.isTaxesPayorAccountNumberRequired) {
      label += ` (${this.$translate.instant('global.optional')})`;
    }

    return label;
  }

  onInputChange(value: PayorType, field: keyof IShipmentOrderData): void {
    if (this.esModel) {
      this.esModel[field] = value as any;

      if (field === 'fedex_shipping_charges_payment_type') {
        if (value === 'SENDER') {
          this.esModel.fedex_shipping_charges_account_number = this.esDefaultAccountNumber;
        } else if (
          this.esModel.fedex_shipping_charges_account_number === this.esDefaultAccountNumber
        ) {
          this.esModel.fedex_shipping_charges_account_number = '';
        }
      }
    }

    this.esOnChange && this.esOnChange({ value: this.esModel });
  }
}

const BillingOptionsComponent: ng.IComponentOptions = {
  controller: BillingOptions,
  template,
  bindings: {
    esModel: '<',
    esOnChange: '&',
    esDefaultAccountNumber: '<',
    esIsDomesticShipment: '<',
  },
};

export { BillingOptionsComponent };
