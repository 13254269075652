class ReturnModalCtrl {
  static $inject = ['$scope', '$state', 'shipment', 'UserSession', 'ReturnService'];

  constructor($scope, $state, shipment, UserSession, ReturnService) {
    this.$scope = $scope;
    this.$state = $state;
    this.shipment = shipment;
    this.UserSession = UserSession;
    this.ReturnService = ReturnService;
  }

  close() {
    this.$scope.$close();

    if (this.ReturnService.step === 'success') {
      if (
        this.UserSession.getCompanyDashboardSettings().beta_feature_returns_page &&
        this.UserSession.hasReturnFeatureSupport()
      ) {
        this.$state.go('app.returns');

        return;
      }

      const viewId = this.UserSession.hasReturnFeatureSupport() ? 'default_return' : null;

      this.$state.go('app.shipments', { view_id: viewId }, { reload: true });
    }
  }
}

export { ReturnModalCtrl };
