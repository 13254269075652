import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import style from './edit-products-modal-spreadsheet.module.scss';
import template from './edit-products-modal-spreadsheet.html?raw';
import { ProductListingService } from '../../product-listing.service';

class EditProductsModalSpreadsheet implements IComponentController {
  style = style;
  esMissingInformation = false;
  loading = false;
  uploadEndpoint = '';

  static $inject = ['API', 'UserSession', 'ProductListingService'];
  constructor(
    private API: IApiConfig,
    private UserSession: IUserSession,
    private ProductListingService: ProductListingService
  ) {}

  $onInit(): void {
    this.uploadEndpoint = `${
      this.API.endpoint
    }/companies/${this.UserSession.getCompanyId()}/products/csv_upload`;
  }

  exportSelectedProducts(): void {
    if (this.esMissingInformation) {
      this.ProductListingService.export('missing-products');
    } else {
      this.ProductListingService.export();
    }
  }
}

const EditProductsModalSpreadsheetComponent: ng.IComponentOptions = {
  controller: EditProductsModalSpreadsheet,
  template,
  bindings: {
    esMissingInformation: '<',
    esOnBack: '&',
  },
};

export { EditProductsModalSpreadsheetComponent };
