import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import ProgressIndicator from '@client/core/components/react/ProgressIndicator';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface ScheduleHandoverMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onScheduleHandover: () => void;
}

export default function SchedulePickupMenuItem({
  divider = false,
  shipmentListItem,
  onScheduleHandover,
}: ScheduleHandoverMenuItemProps) {
  const { is_valid_to_schedule_pickup: isValidToSchedulePickup } = shipmentListItem;

  if (!isValidToSchedulePickup) {
    return null;
  }

  return (
    <ProgressIndicator>
      <MenuItem onClick={() => onScheduleHandover()} divider={divider}>
        <div style={baseStyleIconBox}>
          <i
            className="icon-delivery"
            style={{ color: baseStyleIcon.colorGrey, transform: 'scaleX(-1)', fontSize: '24px' }}
          />
        </div>
        <span className="text-base">
          <FormattedMessage id="shipments.speed-dial.schedule-a-pickup" />
        </span>
      </MenuItem>
    </ProgressIndicator>
  );
}
