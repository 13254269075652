import axios, { AxiosResponse } from 'axios';
import { VerificationPort } from '../../ports/verification';
import {
  CheckEmailVerificationPayload,
  SendOtpEmailPayload,
} from '../../models/payload/verification-payload';
import { OtpData } from '../../models/data/verification-data';

export class VerificationGateway implements VerificationPort {
  checkEmailVerification(payload: CheckEmailVerificationPayload): Promise<OtpData> {
    return axios
      .post<OtpData>(`${import.meta.env.VITE_APP_ENDPOINT}/users/verify_email`, payload)
      .then((res: AxiosResponse) => {
        return res.data;
      });
  }

  sendOtpEmail(payload: SendOtpEmailPayload): Promise<OtpData> {
    return axios
      .post<OtpData>(`${import.meta.env.VITE_APP_ENDPOINT}/mfa/email_otp_codes`, payload)
      .then((res: AxiosResponse) => {
        return res.data;
      });
  }
}
