import template from './checkout-modal-main.html?raw';

class CheckoutModalMainController {
  static $inject = ['CheckoutModalService'];

  constructor(CheckoutModalService) {
    this.CheckoutModalService = CheckoutModalService;

    this.DISCOUNT_RATES_STEP = 'discount-rates';
    this.EXCLUDE_COUNTRIES_STEP = 'exclude-countries';
  }

  $onInit() {
    this.form = this.form || {};
  }

  onAdd() {
    this.step = this.EXCLUDE_COUNTRIES_STEP;
  }

  onCancel() {
    this.step = this.DISCOUNT_RATES_STEP;
  }

  close() {
    this.CheckoutModalService.close();
  }

  updateForm(field, value) {
    this.form[field] = value;
  }
}

const CheckoutModalMainComponent = {
  template,
  controller: CheckoutModalMainController,
  bindings: {
    step: '<',
    form: '<',
    edit: '<',
  },
};

export { CheckoutModalMainComponent };
