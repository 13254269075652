import { toastSuccess } from '@client/core/components/react/Toastify';
import template from './cancel-shipment.html?raw';

(function () {
  const fixShipmentModalBodyCancelShipment = {
    template,
    bindings: {
      globalBusy: '<',
      shipmentData: '<',
      afterRetry: '<',
    },
    controller: 'fixShipmentModalBodyCancelShipmentCtrl as ctrl',
  };

  function fixShipmentModalBodyCancelShipmentCtrl(
    $state,
    $translate,
    FixMonkeyService,
    ShipmentList,
    fixShipmentModal
  ) {
    const ctrl = this;

    ctrl.error = false;

    /**
     * [cancelShipment] Removes the current shipment from "failed" status and moves it to under "Create Shipments" and updates the list afterwards.
     * @return {N/A}
     */
    ctrl.cancelShipment = function () {
      FixMonkeyService.cancelShipment()
        .then(function () {
          fixShipmentModal.close();
          toastSuccess($translate.instant('shipments.fix-monkey.cancel.success'), {
            autoClose: 8000,
          });
          ShipmentList.reset();

          if (FixMonkeyService.preventRedirectToRejected) {
            return;
          }

          $state.go(
            'app.shipments',
            { sectionName: 'rejected', page: 1, query: { scope: 'rejected' } },
            { reload: true }
          );
        })
        .catch(function () {
          ctrl.error = true;
        });
    };

    /**
     * [back] Returns to edit shipment step. Disabled when form is loading.
     * @return {N/A}
     */
    ctrl.back = function () {
      if (ctrl.globalBusy) return;
      FixMonkeyService.goToEditShipmentStep();
    };
  }

  fixShipmentModalBodyCancelShipmentCtrl.$inject = [
    '$state',
    '$translate',
    'FixMonkeyService',
    'ShipmentList',
    'fixShipmentModal',
  ];

  angular
    .module('app.component.fix-shipment-modal-body-cancel-shipment')
    .component('fixShipmentModalBodyCancelShipment', fixShipmentModalBodyCancelShipment)
    .controller('fixShipmentModalBodyCancelShipmentCtrl', fixShipmentModalBodyCancelShipmentCtrl);
})();
