import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface PrintCommercialInvoiceMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onPrintCommercialInvoice: () => void;
}

export default function PrintCommercialInvoiceMenuItem({
  divider = false,
  shipmentListItem,
  onPrintCommercialInvoice,
}: PrintCommercialInvoiceMenuItemProps) {
  const {
    shipment_state: shipmentState,
    is_valid_to_print: isValidToPrint,
    is_return: isReturn,
  } = shipmentListItem;

  if (!isValidToPrint || shipmentState === 'cancelled' || isReturn) {
    return null;
  }

  return (
    <MenuItem onClick={onPrintCommercialInvoice} divider={divider}>
      <div style={baseStyleIconBox}>
        <i className="icon-print u-text-md" style={{ color: baseStyleIcon.colorGrey }} />
      </div>
      <span className="text-base">
        <FormattedMessage id="shipments.speed-dial.print-commercial-invoice" />
      </span>
    </MenuItem>
  );
}
