import angular from 'angular';

import { EditProductsModalActionsComponent } from './edit-products-modal-actions.component';

const MODULE_NAME = 'app.product-listing.edit-products-modal.actions';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingEditProductsModalActions', EditProductsModalActionsComponent);

export default MODULE_NAME;
