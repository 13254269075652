import angular from 'angular';

import { DashboardHeaderShipmentsComponent } from './dashboard-header-shipments/dashboard-header-shipments.component';

const MODULE_NAME = 'app.global.components.dashboard-header';

angular
  .module(MODULE_NAME, ['app.manage-shipments', 'app.global.courier-accounts'])
  .component('esDashboardHeaderShipments', DashboardHeaderShipmentsComponent);

export default MODULE_NAME;
