import {
  IAddress,
  IDraftAddress,
  IAddressService,
  IAddressUpdateError,
  AddressFieldValidatorsMap,
} from 'typings/address';
import { ICourierAccountsService, ICourierService } from 'typings/courier';
import { IUserSession } from 'typings/user-session';
import { IUserService } from 'typings/user-service';
import { IGoogleAnalyticsService } from 'typings/core/services/google-analytics';
import { ADDRESS_LENGTH_LIMIT } from '@client/data/address';

import { COUNTRY_ID } from '@client/data/country';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './address-creator-modal.module.scss';
import template from './address-creator-modal.html?raw';

interface AddressyInfo {
  BuiltAddress: string;
  Comparison: string;
  Description: string;
  Highlight: string;
  Id: string;
  Text: string;
  Type: string;
}

const addressDraftData: IDraftAddress = {
  company_name: '',
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  line_1: '',
  line_2: '',
  line_3: '',
  country_id: 0,
  postal_code: '',
  city: '',
  state: '',
  use_for_later: true,
  pickup_instruction_slug: 'none',
  pickup_instruction_users_input: null,
};

const initialBusyState = {
  checkingPostalCode: false,
  confirmation: false,
  saving: false,
};
const initialErrorState = { postalCode: false };

class AddressCreatorModalController implements IComponentController {
  style = style;
  open = false;
  busy = { ...initialBusyState };
  error = { ...initialErrorState };
  uspsPickupValidity?: string;
  addressNotFoundMessage = '';
  usingAddressy = true; // limited by this.addressyAvailable
  addressyInfo?: AddressyInfo;
  address: IDraftAddress = { ...addressDraftData };
  correctedAddress?: IAddress;
  correctedAddresses?: IAddress[];
  addressToConfirm?: IAddress;
  allowForeignAddress = false;
  fieldValidators: AddressFieldValidatorsMap = {};
  esDefaultOpen = false;
  esOnClose?: () => void;
  esOnSaveSuccess?: () => void;
  form: Partial<ng.IFormController> = {};

  static $inject = [
    '$scope',
    '$translate',
    'UserSession',
    'AddressService',
    'UserService',
    'CourierService',
    'CourierAccounts',
    'MixpanelService',
    'GoogleAnalytics',
  ];
  constructor(
    private $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private AddressService: IAddressService,
    private UserService: IUserService,
    private CourierService: ICourierService,
    private CourierAccounts: ICourierAccountsService,
    private MixpanelService: MixpanelService,
    private GoogleAnalytics: IGoogleAnalyticsService
  ) {}

  $onInit(): void {
    this.allowForeignAddress =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_global_account;

    this.esDefaultOpen && this.openModal();

    this.$scope.$watch(
      () => this.address.country_id,
      (countryId) => {
        this.AddressService.getFieldValidators(countryId).then((fieldValidators) => {
          this.fieldValidators = fieldValidators;
        });
      }
    );
  }

  private initFormData() {
    this.address = { ...addressDraftData };
    const firstName = this.UserSession.user.first_name;
    const lastName = this.UserSession.user.last_name;
    const mobilePhone = this.UserSession.user.mobile_phone;

    this.address.company_name = this.UserSession.company.name;
    this.address.contact_name = `${firstName} ${lastName}`;
    this.address.contact_email = this.UserSession.user.email;
    this.address.contact_phone = mobilePhone;
    this.address.country_id = this.UserSession.getCompanyCountryId();
    this.error = { ...initialErrorState };
    this.addressNotFoundMessage = '';
    this.addressyInfo = undefined;
  }

  toggleAddressyForm(): void {
    this.usingAddressy = !this.usingAddressy;
  }

  toggleModal(): void {
    if (this.open) {
      this.closeModal();
    } else {
      this.openModal();
    }
  }

  openModal(): void {
    this.open = true;
    this.initFormData();
    this.MixpanelService.track('Add New Address - Modal Displayed', {
      existing_address: !this.noAddresses,
    });
  }

  closeModal(): void {
    this.open = false;
    this.esOnClose?.();
    this.MixpanelService.track('Add New Address - Modal Exited', {
      existing_address: !this.noAddresses,
    });
  }

  async save(): Promise<void> {
    this.MixpanelService.track('Add New Address - Save Attempted', {
      existing_address: !this.noAddresses,
    });

    const isUsingSearchForm = Boolean(this.addressyAvailable && this.usingAddressy);

    if (isUsingSearchForm) {
      this.address.addressy_id = this.addressyInfo?.Id;
    } else {
      delete this.address.addressy_id;
      const isFormValid = this.AddressService.runFieldValidators(
        this.fieldValidators,
        this.address
      );

      this.AddressService.touchAllForms(this.form);

      if (!isFormValid) {
        this.MixpanelService.track('Add New Address - Save Failed - Validation', {
          existing_address: !this.noAddresses,
        });
        toastError(this.$translate.instant('toast.incomplete-form'));
        return;
      }
    }

    if (this.AddressService.getShippingAddresses().length === 0) {
      this.address.default_address = 'pickup, return, sender';
    }

    this.busy.saving = true;

    const confirmedAddress = await this.AddressService.createAddress(this.address).catch((err) => {
      this.addressNotFoundMessage = err.data.status;
      this.handleAddressCreationErrors(err.data);
      this.MixpanelService.track('Add New Address - Save Failed - Error Response', {
        existing_address: !this.noAddresses,
      });
    });

    this.busy.saving = false;

    if (!confirmedAddress) return;

    this.refreshCourierCaches();
    this.MixpanelService.track('Add New Address - Save Success', {
      existing_address: !this.noAddresses,
    });

    this.uspsPickupValidity = confirmedAddress.usps_pickup_validation_state;

    if (this.uspsPickupValidity === 'invalid') {
      this.addressToConfirm = confirmedAddress;
    }

    this.open = false;

    this.esOnSaveSuccess?.();

    toastSuccess(
      this.$translate.instant('toast.create-success', {
        noun: this.$translate
          .instant('global.pluralize.address', { COUNT: 1 }, 'messageformat')
          .toLowerCase(),
      })
    );

    const eventData = this.AddressService.isPickupInstructionBetaFeatureEnabled()
      ? {
          pickup_instruction: this.AddressService.getMixpanelPickupInstructionValue(
            this.address.pickup_instruction_slug,
            this.address.pickup_instruction_users_input
          ),
        }
      : {};

    this.MixpanelService.track('Account - Add Address', eventData);
    this.GoogleAnalytics.createGAClickButton('Create Address');

    this.UserService.refreshShippingCountries();
  }

  handleAddressCreationErrors(errorResponse: IAddressUpdateError) {
    if (!errorResponse?.response) return;

    switch (errorResponse.response_code) {
      case '31':
      case '32':
      case '22':
        if (errorResponse.response.suggested_addresses.length > 0) {
          this.correctedAddresses = this.AddressService.formatCorrectedAddresses(
            errorResponse.response.suggested_addresses
          );
        } else if (Object.keys(errorResponse.response.updated_fields).length > 0) {
          this.correctedAddresses = this.AddressService.formatCorrectedAddresses([
            errorResponse.response.output_address,
          ]);
        }
        break;
      default:
        this.addressNotFoundMessage = errorResponse.response.message;
        break;
    }
  }

  applyCorrection(_address: IAddress): void {
    // NOTE: I've tried to recreate the logic related to
    // client/app/directives/address-validation-steps/address-validation-step-skip
    // but I haven't been able to replicate the API error response that triggers it
  }

  private refreshCourierCaches(): void {
    this.CourierService.getCouriers({ refetch: true });
    this.CourierAccounts.getActiveCourierAccounts();
  }

  get busyAtAll(): boolean {
    return Object.values(this.busy).includes(true);
  }

  get tooLong(): boolean {
    if (!this.address) return false;

    const line1 = this.address.line_1;
    const line2 = this.address.line_2;
    const line3 = this.address.line_3;

    return line1.length + (line2?.length || 0) + (line3?.length || 0) > ADDRESS_LENGTH_LIMIT;
  }

  get noAddresses(): boolean {
    return this.AddressService.getShippingAddresses().length === 0;
  }

  get addressyAvailable(): boolean {
    const availableCountries: number[] = [COUNTRY_ID.UnitedStates];
    const countryId = this.address.country_id;

    return availableCountries.includes(countryId);
  }
}

const AddressCreatorModalComponent: ng.IComponentOptions = {
  controller: AddressCreatorModalController,
  template,
  bindings: {
    esDisabled: '<',
    esDefaultOpen: '<',
    esOnClose: '<',
    esOnSaveSuccess: '<',
  },
};

export { AddressCreatorModalComponent };
