import { hubspotLoader } from './auth-full-login-success/hubspot-loader/hubspot-loader';
import {
  initFingerprint,
  getVisitorResult,
} from './auth-full-login-success/fingerprint-loader/fingerprint-loader';

authFullLoginSuccess.$inject = [
  '$rootScope',
  '$state',
  '$window',
  'UserSession',
  'AUTH_EVENTS',
  'UserService',
];
function authFullLoginSuccess($rootScope, $state, $window, UserSession, AUTH_EVENTS, UserService) {
  $rootScope.$on(AUTH_EVENTS.fullLoginSuccess, () => {
    if ($state.is('home.auth.sso') || $state.is('home.auth.verify')) return;

    hubspotLoader(UserService, UserSession);
    initFingerprint();
    getVisitorResult(UserSession.getUserId(), 'login');
  });
}

export { authFullLoginSuccess };
