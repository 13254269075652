(function () {
  'use strict';

  Store.$inject = ['$resource', 'API'];
  function Store($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/companies/:company_id/stores/:store_id/:platform/:controller',
      { store_id: '@_store_id', company_id: '@_company_id', platform: '@_platform' },
      {
        query: { method: 'GET' },
        create: { method: 'POST' },
        update: { method: 'PATCH' },
        createOauthApplications: { method: 'POST', params: { controller: 'oauth_applications' } },
        updateOauthApplications: { method: 'PATCH', params: { controller: 'oauth_applications' } },
        createSettingsAutomations: {
          method: 'POST',
          params: { controller: 'settings_automations' },
        },
        fetchWarehouses: { method: 'POST', params: { controller: 'warehouses' } },
        checkConnection: { method: 'GET', params: { controller: 'check_connection' } },
        deactivate: { method: 'PATCH', params: { platform: 'deactivate' } },
        getCheckoutDiagnosticData: { method: 'GET', params: { controller: 'checkout_diagnostic' } },
      }
    );
  }

  angular.module('app.factory.Store', []).factory('Store', Store);
})();
