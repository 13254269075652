import angular from 'angular';

import './buttons/button';
import './buttons/combo-button';
import './buttons/dropdown-button';
import './buttons/pop-button';
import './buttons/row-button';

import './forms/checkbox';
import './forms/copy-clipboard-input';
import './forms/datepicker';
import './forms/input';
// TODO: Dependancies on app/services to migrate when app is migrated to src
import './forms/radio';
import './forms/select';
import './forms/textarea';
import './forms/toggle';
import './forms/multiselect';
import inputSelect from './forms/input-select';
import multiSelectCheckboxTree from './forms/multi-select-checkbox-tree';
import tagsArea from './forms/tags-area';
import typeahead from './forms/typeahead';

import './layout/card';
import './layout/clickable-card';
import './layout/drawer';
import './layout/expandable-section';
import './layout/table';
import oneActionCard from './layout/one-action-card';
import twoActionCard from './layout/two-action-card';
import modalCardTitle from './modal/modal-card-title';
import drawerMenuBarItem from './layout/drawer-menu-bar-item';
import transitionDrawer from './layout/transition-drawer';
import dropdown from './layout/dropdown';

import utilities from './utilities';

import modalInstanceModule from './modal/modal-instance';

angular.module('core.components', [
  'core.components.button',
  'core.components.combo-button',
  'core.components.dropdown-button',
  'core.components.pop-button',
  'core.components.row-button',
  'core.components.checkbox',
  'core.components.copy-clipboard-input',
  'core.components.datepicker',
  'core.components.input',
  'core.components.radio',
  'core.components.select',
  'core.components.textarea',
  'core.components.toggle',
  'core.components.card',
  'core.components.clickable-card',
  oneActionCard,
  twoActionCard,
  modalCardTitle,
  modalInstanceModule,
  tagsArea,
  typeahead,
  drawerMenuBarItem,
  transitionDrawer,
  multiSelectCheckboxTree,
  'core.components.drawer',
  'core.components.expandable-section',
  'core.components.table',
  'core.components.multiselect',
  inputSelect,
  dropdown,
  utilities,
]);
