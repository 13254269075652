import { Channel } from 'pusher-js';

import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { Hubspot } from 'typings/hubspot';
import { INotification } from 'typings/notification-centre';
import { showToastWithAction } from '@client/core/components/react/ToastWithAction';
import { IUserStatusService } from 'typings/user-status';

import { IComponentController } from 'angular';
import { PusherService } from '@client/src/global/services/pusher/pusher.service';
import { NotificationCentreService } from '@client/src/global/services/notification-centre/notification-centre.service';
import style from './notification-centre-group.module.scss';
import template from './notification-centre-group.html?raw';

interface IStoreIncentiveClaim {
  amount: string;
  claim_date: string;
}

class NotificationCentreGroup implements IComponentController {
  style = style;
  isShowPanel = false;
  channel: Channel | null = null;
  readonly channelName = 'comments-channel';
  readonly eventName = 'create';

  static $inject = [
    '$rootScope',
    '$translate',
    'UserStatusService',
    'AppCuesService',
    'HubspotService',
    'NotificationCentreService',
    'PusherService',
  ];
  constructor(
    private $rootScope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private UserStatusService: IUserStatusService,
    private AppCuesService: AppCuesService,
    private HubspotService: Hubspot.IAnalyticsService,
    private NotificationCentreService: NotificationCentreService,
    private PusherService: PusherService
  ) {}

  $onInit(): void {
    this.NotificationCentreService.fetchUnreadCount();
    this.subscribeChannel();
    this.listenToRouteChangeOnChannelUnavailabilty();
  }

  $onDestroy(): void {
    this.PusherService.unsubscribeWithUser(this.channelName);
  }

  get unreadCount(): number {
    return this.NotificationCentreService.unreadCount;
  }

  togglePanel(): void {
    this.isShowPanel = !this.isShowPanel;
  }

  closePanel(): void {
    this.isShowPanel = false;
  }

  private subscribeChannel(): void {
    this.channel = this.PusherService.subscribeWithUser(this.channelName);
    if (this.channel) {
      this.PusherService.bind(
        this.channel,
        this.eventName,
        (data: { notification: INotification }) => {
          if (data && data.notification) {
            const { notification } = data;
            if (!notification.read_at) {
              this.NotificationCentreService.increaseUnreadCount();
            }

            if (this.isShowPanel) {
              this.NotificationCentreService.unshiftNotification(notification);
            }
          }
        }
      );

      // Subscribe to the store incentives channel
      this.PusherService.bind(this.channel, 'credit-added', (res: IStoreIncentiveClaim) => {
        if (!res?.amount) return;

        showToastWithAction(
          this.$translate.instant('connect.notifications.credit-applied', { amount: res.amount }),
          {
            actionLabel: this.$translate.instant('global.got-it'),
          }
        );

        const payload = {
          connect_qualified_incentive_amount: res.amount,
          connect_qualified_incentive_claimed_date:
            res.claim_date || new Date().toISOString().slice(0, 10),
        };

        this.AppCuesService.track('Store | Incentive Applied', payload);
        this.HubspotService.identify(payload);

        const splitAmount = res.amount.split(' ');
        const numericalAmount = splitAmount.length === 2 && parseFloat(splitAmount[0]);

        if (
          numericalAmount &&
          !Number.isNaN(numericalAmount) &&
          this.UserStatusService.availableBalance
        ) {
          this.UserStatusService.updateAvailableBalance(
            this.UserStatusService.availableBalance + numericalAmount
          );
        }
      });
    }
  }

  private listenToRouteChangeOnChannelUnavailabilty(): void {
    this.$rootScope.$on('$stateChangeSuccess', () => {
      if (this.PusherService.connectionState === 'unavailable') {
        this.NotificationCentreService.fetchUnreadCount();
      }
    });
  }
}

const NotificationCentreGroupComponent: ng.IComponentOptions = {
  controller: NotificationCentreGroup,
  template,
  bindings: {},
};

export { NotificationCentreGroupComponent };
