import template from './address-form-us-backup.html?raw';

(function () {
  'use strict';

  var addressFormUsBackup = {
    template,
    bindings: {
      address: '=',
      postalCodeBusy: '=?',
      fieldValidators: '<',
    },
    controller: 'addressFormUsBackupCtrl as ctrl',
  };

  angular
    .module('app.component.address-form-us-backup')
    .component('addressFormUsBackup', addressFormUsBackup);
})();
