import { loadLocaleMessages } from '@/utils/locale';
import { EasyshipLocale } from '@/types/locale';

const StoreData = {
  locale: null,
  messages: null,
  fetchingLocale: null,
} as {
  locale: EasyshipLocale | null;
  messages: Record<string, string> | null;
  fetchingLocale: EasyshipLocale | null;
};

type Listener = () => void;

const createLocalizeStore = () => {
  const listeners = new Set<Listener>();

  const setMessages = (locale: EasyshipLocale, messages: Record<string, string> | null) => {
    StoreData.messages = messages;
    StoreData.locale = locale;
    listeners.forEach((notify) => notify());
  };
  return {
    getData: () => StoreData.messages,
    fetchData: async (localeCode: EasyshipLocale) => {
      if (
        (localeCode === StoreData.locale && StoreData.messages) ||
        StoreData.fetchingLocale === localeCode
      )
        return;
      StoreData.fetchingLocale = localeCode;
      loadLocaleMessages(localeCode)
        .then((loadedMessages) => {
          setMessages(localeCode, loadedMessages);
        })
        .finally(() => {
          StoreData.fetchingLocale = null;
        });
    },
    subscribe: (listener: Listener) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
};

const localizeStore = createLocalizeStore();

export { localizeStore };

export const fetchLocalize = async (local: EasyshipLocale) => localizeStore.fetchData(local);
