import template from './dimensions.html?raw';
import style from './dimensions.module.scss';

class DimensionsController {
  static $inject = ['$transclude', '$element', '$timeout'];

  constructor($transclude, $element, $timeout) {
    this.style = style;
    this.$transclude = $transclude;
    this.$element = $element;
    this.$timeout = $timeout;
    this.debounce = 250;
  }

  _getDefaultUnitOptions() {
    return {
      min: '0.01',
      decimals: '2',
    };
  }

  $onInit() {
    this.ngModel = this.ngModel || {};
    this.esOptions = {
      length: this._getDefaultUnitOptions(),
      width: this._getDefaultUnitOptions(),
      height: this._getDefaultUnitOptions(),
      ...this.esOptions,
    };

    const { esInitValidation, esError } = this;

    this._validateInputs();

    // manually set input field as invalid when the ngModel is programmatically retrieved and validation fails from backend
    if (esInitValidation && esError) {
      this.$ngModelController.$setValidity('ngBackend', false);
      this.$ngModelController.$setDirty();
    }
  }

  $onChanges() {
    this._validateInputs(true);
  }

  noLabelNorTooltip() {
    return (
      !angular.element('dimensions-label', this.$element).html() &&
      !angular.element('dimensions-tooltip', this.$element).html()
    );
  }

  getMin(unit) {
    return this.isRequired(unit)
      ? this.esOptions && this.esOptions[unit] && this.esOptions[unit].min
      : '';
  }

  getDecimals(unit) {
    return this.isRequired(unit)
      ? this.esOptions && this.esOptions[unit] && this.esOptions[unit].decimals
      : '';
  }

  _validateInputs(dirtyCheck = false) {
    const element = 'easyship-input';

    this.$timeout(() => {
      if (dirtyCheck) {
        const hasInvalidDirtyInputs = !!this.$element.find(`${element}.ng-invalid.ng-dirty`).length;

        if (hasInvalidDirtyInputs) {
          this.$ngModelController.$setDirty();
        }
      }

      const hasAllEmptyInputs =
        !this.ngRequired && this.$element.find(`${element}.ng-empty`).length === 3;

      const hasInvalidInputs = !!this.$element.find(`${element}.ng-invalid`).length;

      this.$ngModelController.$setValidity('ngDimensions', hasAllEmptyInputs || !hasInvalidInputs);
    }, this.debounce);
  }

  onDimensionsInputChange(value, model, firstChange) {
    this.ngModel[model] = value;

    this._validateInputs(true);

    this.onChange({ value: this.ngModel });

    // If an error is passed through the backend on initial ngModel retrieval, remove the error when user begins typing
    if (this.esInitValidation && this.esError && !firstChange) {
      this.$ngModelController.$setValidity('ngBackend', true);
    }
  }

  isRequired(field) {
    if (!this.ngRequired) {
      return this.ngModel && !!(this.ngModel.length || this.ngModel.width || this.ngModel.height);
    }

    if (typeof this.ngRequired === 'object') {
      return this.ngRequired.indexOf(field) !== -1 || this.ngModel[field];
    }

    return this.ngRequired;
  }

  hasTranscludeUnit() {
    return this.$transclude.isSlotFilled('unit');
  }
}

const DimensionsComponent = {
  template,
  controller: DimensionsController,
  require: {
    $ngModelController: 'ngModel',
  },
  transclude: {
    label: '?dimensionsLabel',
    unit: '?dimensionsUnit',
    tooltip: '?dimensionsTooltip',
  },
  bindings: {
    ngModel: '<',
    esOptions: '<',
    esId: '@',
    esError: '<',
    esInitValidation: '<',
    esHelp: '@',
    esReadOnly: '<',
    ngRequired: '<',
    ngDisabled: '<',
    onChange: '&',
    esRecommended: '<',
  },
};

export { DimensionsComponent };
