import 'angular-translate';

import { IAddress, IAddressService } from 'typings/address';

import { IComponentController } from 'angular';
import template from './nicknamed-address.html?raw';
import style from './nicknamed-address.module.scss';

class NicknamedAddressController implements IComponentController {
  style = style;
  translations: angular.translate.ITranslationTable = {};
  esTooltip = false;
  esNicknameOnly = false;
  esAddressId?: string;
  esAddress?: IAddress;

  static $inject = ['AddressService'];
  constructor(private AddressService: IAddressService) {}

  $onChanges(): void {
    if (!this.esAddress) {
      if (!this.esAddressId) return;

      const foundAddress = this.AddressService.getShippingAddresses().find(
        ({ id }) => id === this.esAddressId
      );

      if (foundAddress) {
        this.esAddress = foundAddress;
        return;
      }

      this.AddressService.queryById(this.esAddressId).then((address) => {
        this.esAddress = address;
      });
    }
  }

  get concatenatedAddress(): string {
    if (!this.esAddress) return '';

    const {
      line_1,
      line_2,
      city,
      postal_code,
      state,
      contact_name,
      company_name,
      contact_phone,
      contact_email,
    } = this.esAddress;
    const addressFields = `${line_1 || ''} ${line_2 || ''} ${city || ''} ${postal_code || ''} ${
      state || ''
    }`;
    const contactFields = `${contact_name || ''}, ${company_name || ''}, ${contact_phone || ''}, ${
      contact_email || ''
    }`;

    return ` ${addressFields} - ${contactFields}`.replace(/\s*,?(?:\s+|$)/g, ' ');
  }
}

const NicknamedAddressComponent: ng.IComponentOptions = {
  controller: NicknamedAddressController,
  template,
  transclude: true,
  bindings: {
    esTooltip: '<',
    esAddressId: '<',
    esAddress: '<',
    esNicknameOnly: '<',
  },
};

export { NicknamedAddressComponent };
