import { IComponentController } from 'angular';
import template from './select-claim-type.html?raw';
import style from '../insurance-claim.module.scss';
import damagedBoxImageUrl from '@assets/images/dashboard/insurance-claim/damaged-box.svg';
import lostBoxImageUrl from '@assets/images/dashboard/insurance-claim/lost-box.svg';
class SelectClaimType implements IComponentController {
  style = style;
  damagedBoxImageUrl = damagedBoxImageUrl;
  lostBoxImageUrl = lostBoxImageUrl;
}

const SelectClaimTypeComponent: ng.IComponentOptions = {
  controller: SelectClaimType,
  template,
  bindings: {
    esClose: '&',
    esTriggerDamagedStep: '&',
    esTriggerLostStep: '&',
    esEasyshipShipmentId: '<',
    esBusy: '<',
  },
};

export { SelectClaimTypeComponent };
