import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import { getVisitorResult } from '@client/src/initializers/auth-full-login-success/fingerprint-loader/fingerprint-loader';
import poweredByStripeImageUrl from '@assets/images/dashboard/add-credit/powered-by-stripe.svg';
import template from './credit-card.html?raw';
import style from './credit-card.module.scss';

const PAYMENT_FORM_ID = 'add-credit-payment-form';

class CreditCard {
  poweredByStripeImageUrl = poweredByStripeImageUrl;

  static $inject = [
    'PaymentSourceService',
    'UserSession',
    'PaymentFormService',
    'PaymentResource',
    'MixpanelService',
    'CompanyService',
    '$scope',
    'API',
    '$rootScope',
    '$translate',
    '$timeout',
  ];

  constructor(
    PaymentSourceService,
    UserSession,
    PaymentFormService,
    PaymentResource,
    MixpanelService,
    CompanyService,
    $scope,
    API,
    $rootScope,
    $translate,
    $timeout
  ) {
    this.style = style;
    this.PaymentSourceService = PaymentSourceService;
    this.UserSession = UserSession;
    this.PaymentFormService = PaymentFormService;
    this.PaymentResource = PaymentResource;
    this.MixpanelService = MixpanelService;
    this.CompanyService = CompanyService;
    this.$scope = $scope;
    this.API = API;
    this.$rootScope = $rootScope;
    this.$translate = $translate;
    this.$timeout = $timeout;

    this.paymentFormId = PAYMENT_FORM_ID;
    this.paymentAmount = null;
    this.paymentCurrency = this.UserSession.company.currency;
    this.PaymentSourceService.saveCreditCard = false;
    this.showPaymentForm = false;
    this.loadingCards = false;
    this.submittingPayment = false;
    this.creditCardErrorCode = '';
    this.showAddress = this.UserSession.hasFixedOriginForShipping();

    this.amountValidationOption = {
      maxDigits: 8,
      maxDecimals: 2,
      allowNegative: false,
      showThousandSeparator: true,
    };
  }

  $onInit() {
    this._fetchCards();

    this.$rootScope.$on('payment-failed', () => {
      this.submittingPayment = false;
    });

    this.$scope.$on('add-credits-by-credit-card', () => {
      this.onAddCreditClick();
    });

    if (this.esShowAddCreditButton === undefined) {
      this.esShowAddCreditButton = true;
    }

    // Default values for bindings
    this.esDisableDeletingCards = this.esDisableDeletingCards || false;
    this.esDisableAddingCards = this.esDisableAddingCards || false;
  }

  onSelectCard(card) {
    this.PaymentSourceService.selectCard(card);
    this.showPaymentForm = false;
  }

  onSelectNewCard() {
    this.PaymentSourceService.selectedCard = null;
    this.showPaymentForm = true;
  }

  onPaymentAmountChange(value) {
    this.paymentAmount = value;
    this.PaymentSourceService.paymentAmount = value;
  }

  onSaveCreditCardChange(value) {
    this.PaymentSourceService.saveCreditCard = value;
  }

  /**
   * Performs multiple async actions in the following sequence:
   * 1 - Validate the credit card form and the child payment form component
   * 2 - Submits the payment data
   * 3 - Refetch saved credit cards and selecte the newly saved card
   * 4 - Fetch the updated credit balance
   */
  async onAddCreditClick() {
    if (!this.paymentAmount || this.paymentAmount < 0) {
      toastError(this.$translate.instant('account.payment.notifications.amount-error'));
      return;
    }

    getVisitorResult(this.UserSession.getUserId(), 'add_credit');

    if (this.submittingPayment) return;
    this.submittingPayment = true;
    this.creditCardErrorCode = '';

    /**
     * Validate the form, including the payment form component which
     * validates through Stripe.js
     */
    const { valid } = await this._validateForm();

    if (!valid) {
      this.$scope.$apply(() => {
        toastError(this.$translate.instant('global.form-fields-missing'));
        this.submittingPayment = false;
      });

      return;
    }

    this.$scope.$emit('start-adding-via-credit-card');
    try {
      /**
       * Submit the payment data to /payments and save the new credit card if
       * the user has indicated so
       */

      // Get the card selected
      const { selectedCard } = this.PaymentSourceService;

      // Set payment amount in service
      if (!this.PaymentSourceService.paymentAmount)
        this.PaymentSourceService.paymentAmount = this.paymentAmount;

      // If no card is selected -> proceed to the one time payment flow
      const usingCreditCardForm = selectedCard === null;

      let payment;

      if (usingCreditCardForm) {
        // submitPaymentForm will also proceed with the payment
        payment = await this.PaymentFormService.submitPaymentForm(PAYMENT_FORM_ID);
      } else {
        payment = await this._submitPayment(selectedCard);
      }

      if (!(payment && payment.valid)) throw payment.error;

      this.MixpanelService.track('Add Credit', { amount: payment.total, type: 'Stripe' });
      toastSuccess(this.$translate.instant('account.payment.notifications.credit-added'));

      // Get updated list of card and preselect the one chosen
      await this._fetchCards();

      // Update the credit balance
      await this.CompanyService.updateStatus();

      this._selectCard(selectedCard);

      this.$timeout(() => {
        this.submittingPayment = false;
        this.$scope.$emit('finished-adding-via-credit-card', { closeModal: true });
      });
    } catch (error) {
      const [errorCode] = transformToErrorCodes(error);
      this.creditCardErrorCode = errorCode || 'unknown';
      toastError(this.$translate.instant('account.payment.notifications.stripe-error'));

      this.CompanyService.getCurrentStatus().then((res) => {
        if (res.company.require_admin_contact) {
          this.CompanyService.showContactAdminModal = true;
          this.$scope.$emit('finished-adding-via-credit-card', { closeModal: true });
        } else {
          this.$scope.$emit('finished-adding-via-credit-card', { closeModal: false });
        }
      });

      this.$timeout(() => {
        this.submittingPayment = false;
      });
    }
  }

  canSaveCreditCard() {
    const paymentSettings = this.UserSession.getCompanyPaymentSettings();
    return paymentSettings && paymentSettings.save_credit_card;
  }

  _selectCard(card = {}) {
    // find card that has been used to pay in the list of card and assign it to selectedCard
    const cardSourceId = card && card.source_id;
    const selectedCard =
      this.PaymentSourceService.findCardBy('source_id', cardSourceId) ||
      this.PaymentSourceService.getDefaultCard();

    // Select card and reset the form
    this.$scope.$apply(() => {
      this.PaymentSourceService.selectCard(selectedCard);
      this._resetForm();
    });
  }

  _fetchCards() {
    this.loadingCards = true;

    return this.PaymentSourceService.getCreditCards()
      .then(() => {
        if (this.PaymentSourceService.creditCards.length === 0) {
          this.showPaymentForm = true;
        }
      })
      .catch(() => toastError(this.$translate.instant('toast.default-error')))
      .finally(() => {
        this.loadingCards = false;
      });
  }

  async _validateForm() {
    const usingCreditCardForm = this.PaymentSourceService.selectedCard === null;

    this.formController.$$controls.forEach((ngModelController) => {
      ngModelController.$commitViewValue();
      ngModelController.$setDirty();
    });

    const valid = usingCreditCardForm ? this.formController.$valid : true;

    return { valid };
  }

  async _submitPayment(card = {}) {
    // Have switch here to go to old flow or new flow according to boolean payment_intent_flow
    if (card.payment_intent_flow) {
      // new flow
      return this.PaymentSourceService.pay(card);
    }

    const payment = {
      currency: this.paymentCurrency,
      description: `${this.paymentCurrency} ${this.paymentAmount}`,
      platform: 'Stripe',
      token: card.source_id,
      total: this.paymentAmount,
    };

    const params = {
      payment,
    };

    return this.PaymentResource.savePayment({ company_id: this.UserSession.company.id }, params);
  }

  _resetForm() {
    if (!this.formController) return;

    this.creditCardErrorCode = '';
    this.PaymentSourceService.saveCreditCard = false;
    this.paymentAmount = null;

    this.formController.$$controls.forEach((ngModelController) => {
      ngModelController.$setPristine();
      ngModelController.$setUntouched();
    });

    this.formController.$submitted = false;
    this.formController.$setPristine();
    this.formController.$setUntouched();
  }
}

const CreditCardComponent = {
  controller: CreditCard,
  template,
  bindings: {
    esShowAddCreditButton: '<',
    esDisableDeletingCards: '<',
    esDisableAddingCards: '<',
  },
};

export { CreditCardComponent };
