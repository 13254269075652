type Modals = 'bulk-cancel' | 'insurance-claim';

export class ModalService {
  private _activeModal: Modals | null = null;
  private _params: any = null;

  get activeModal(): Modals | null {
    return this._activeModal;
  }

  get params(): any {
    return this._params;
  }

  open(modal: Modals, params?: any): ModalService {
    this._activeModal = modal;
    this._params = params;

    return this;
  }

  close(): ModalService {
    this._activeModal = null;
    this._params = null;

    return this;
  }
}
