import React, { useCallback, useState, useEffect, ReactElement, KeyboardEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import {
  FormProvider,
  useForm,
  useFormState,
  SubmitHandler,
  FieldError,
  FieldErrors,
  useWatch,
} from 'react-hook-form';
import { ClickAwayListenerProps } from '@material-ui/core/ClickAwayListener';
import { DrawerProps } from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import COLORS from '@client/src/colors';
import Button from '@client/core/components/react/Button';
import Drawer, { DrawerToolbar } from '@client/core/components/react/Drawer';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';

import { IItemCategoryService } from 'typings/item-category';
import { IProductListingProducts } from 'typings/dashboard/services/product-listing';
import { DimensionsUnitAbbreviation, WeightUnitAbbreviation } from 'typings/units';

import ElevationScroll from '@client/core/components/react/ElevationScroll';
import Box from '@material-ui/core/Box';
import {
  CategorySelection,
  HsCodeSelection,
} from '@client/src/global/components/category-hs-code/types';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import { useStyles } from './styles';
import ProductDetailsDrawerToolbar from './product-details-drawer-toolbar';
import ProductSummary from './product-summary';
import ProductInfoCard from './product-info-card';
import ProductImportCard from './product-import-card';
import ProductFulfillmentCard from './product-fulfillment-card';
import ProductDetailsResetConfirmation from './reset-confirmation';
import ProductItemDeclarationCard from './product-item-declaration-card';
import { ProductFormData } from './reset-confirmation/ProductFormData';
import { ProductListingService } from '../product-listing.service';

interface ProductDetailsDrawerProps extends DrawerProps {
  ClickAwayListenerProps?: Partial<ClickAwayListenerProps>;
  product?: IProductListingProducts;
  isOpen: boolean;
  index: number;
  lastIndex: number;
  offset: number;
  totalCount: number;
  $injector: angular.auto.IInjectorService;
  onClose: (event?: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onClickAway: React.MouseEventHandler<HTMLDivElement | Document>;
  onNavigation: (indexOffset: number) => void;
}

export default function ProductDetailsDrawer({
  product,
  isOpen,
  index,
  lastIndex,
  offset,
  totalCount,
  $injector,
  onClose,
  onClickAway,
  onNavigation,
}: ProductDetailsDrawerProps): ReactElement<ProductDetailsDrawerProps> {
  const $state = $injector.get('$state') as ng.ui.IStateService;
  const ProductListingService = $injector.get<ProductListingService>('ProductListingService');
  const ItemCategoryService = $injector.get<IItemCategoryService>('ItemCategoryService');
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isResetConfirmVisible, setIsResetConfirmVisible] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [contentEl, setContentEl] = useState<HTMLElement>();
  const contentRefCb = useCallback((node) => node && setContentEl(node), []);
  const userSession = useUserSession();
  const canDeleteProduct = !!userSession.user.userRights['box.delete'];

  const initForm = (product?: IProductListingProducts) => ({
    id: product?.id,
    name: product?.name || product?.description || '',
    length: String(product?.length) || '',
    width: String(product?.width) || '',
    height: String(product?.height) || '',
    weight: String(product?.weight) || '',
    identifier: product?.identifier || '',
    categoryHsCode:
      product?.hs_code && product?.hs_code_description
        ? { hsCodeNumber: product?.hs_code, hsCodeDescription: product?.hs_code_description }
        : product?.category.id
        ? { categoryId: product?.category.id }
        : null,
    origin_country_id: product?.origin_country_id || '',
    description: product?.description || product?.name || '',
    pick_location: product?.pick_location || '',
    contains_liquids: !!product?.contains_liquids,
    batteryType: product?.contains_battery_pi966
      ? 'packed_battery'
      : product?.contains_battery_pi967
      ? 'contained_battery'
      : '',
  });

  const methods = useForm<ProductFormData>({
    mode: 'onTouched',
    defaultValues: initForm(product),
  });

  const categoryHsCode = useWatch({ control: methods.control, name: 'categoryHsCode' });
  const isHsCode = !!(categoryHsCode as HsCodeSelection)?.hsCodeNumber;

  const { isDirty } = useFormState({ control: methods.control });
  const placeholders = {
    length: (product?.fallback_length || '').toString(),
    width: (product?.fallback_width || '').toString(),
    height: (product?.fallback_height || '').toString(),
    weight: (product?.fallback_weight || '').toString(),
  };

  useEffect(() => {
    methods.reset(initForm(product));
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit: SubmitHandler<ProductFormData> = async (values) => {
    setIsResetConfirmVisible(false);
    setIsDeleteConfirmVisible(false);
    setIsUpdating(true);
    const { categoryHsCode, batteryType, ...productPartialPayload } = values;

    const payload = {
      products: [
        {
          ...productPartialPayload,
          category_id: (categoryHsCode as CategorySelection).categoryId,
          hs_code: (categoryHsCode as HsCodeSelection).hsCodeNumber,
          origin_country_id: productPartialPayload.origin_country_id
            ? Number(productPartialPayload.origin_country_id)
            : null,
          length: parseFloat(productPartialPayload.length),
          width: parseFloat(productPartialPayload.width),
          height: parseFloat(productPartialPayload.height),
          weight: parseFloat(productPartialPayload.weight),
          contains_liquids: productPartialPayload.contains_liquids,
          contains_battery_pi966: batteryType === 'packed_battery',
          contains_battery_pi967: batteryType === 'contained_battery',
        },
      ],
    };

    ProductListingService.update(payload)
      .then(() => {
        methods.reset(values);

        const elementWithFocus = document.activeElement as HTMLElement;
        if (elementWithFocus) elementWithFocus.blur();

        toastSuccess(formatMessage({ id: 'product-listing.warnings.update-success' }));

        const productIndex = ProductListingService.products.findIndex(({ id }) => id === values.id);
        const selectedCategoryId = (categoryHsCode as CategorySelection).categoryId;
        const category = ItemCategoryService.itemCategories.find(
          ({ id }) => id === selectedCategoryId
        );

        const updatedProduct = {
          ...ProductListingService.products[productIndex],
          ...values,
          length: parseFloat(values.length) || null,
          width: parseFloat(values.width) || null,
          height: parseFloat(values.height) || null,
          weight: parseFloat(values.weight) || null,
          category: category ? { id: category.id, name: category.name, slug: category.slug } : {},
          hs_code: (categoryHsCode as HsCodeSelection).hsCodeNumber,
          hs_code_description: (categoryHsCode as HsCodeSelection).hsCodeDescription,
          updated_at: new Date().toISOString(),
          contains_liquids: productPartialPayload.contains_liquids,
          contains_battery_pi966: batteryType === 'packed_battery',
          contains_battery_pi967: batteryType === 'contained_battery',
        } as IProductListingProducts;
        ProductListingService.products.splice(productIndex, 1, updatedProduct);
      })
      .finally(() => setIsUpdating(false));
  };

  const handleClickAway = (event: React.MouseEvent<Document>) => {
    const isPopperVisible = !!document.querySelector('div[role="tooltip"]');

    if (isPopperVisible) return;

    if (methods.formState.isDirty) {
      event.stopPropagation();
      event.preventDefault();
      setIsResetConfirmVisible(true);
    } else {
      onClickAway(event);
    }
  };

  const confirmDiscard = () => {
    setIsResetConfirmVisible(false);
    setIsDeleteConfirmVisible(false);
    methods.reset(initForm(product));
  };

  const handleDelete = () => {
    if (!product) return;

    setIsDeleting(true);

    ProductListingService.delete(product.id)
      .then(() => {
        toastSuccess(formatMessage({ id: 'product-listing.warnings.delete-success' }));
        ProductListingService.products.splice(index, 1);
        methods.reset(initForm(product));
        setIsDeleteConfirmVisible(false);
        onClose();
      })
      .finally(() => setIsDeleting(false));
  };

  const handleValidationError = (errors: FieldErrors<ProductFormData>) => {
    toastError(
      (Object.values(errors)[0] as FieldError).message ||
        formatMessage({ id: 'global.invalid-form' })
    );
  };

  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLElement;

    switch (event.code) {
      case 'Enter':
        isDirty && methods.handleSubmit(submit, handleValidationError)();
        break;

      case 'Escape':
        !methods.formState.isDirty && onClose();
        break;

      case 'ArrowUp':
        index > 0 && !methods.formState.isDirty && target.nodeName !== 'INPUT' && onNavigation(-1);
        break;

      case 'ArrowDown':
        index < lastIndex &&
          !methods.formState.isDirty &&
          target.nodeName !== 'INPUT' &&
          onNavigation(1);
        break;

      default:
        return;
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const goToStoreSettings = () => {
    if (!product?.store) return;
    $state.go('app.single-store', { id: product.store.id });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit, handleValidationError)}>
        <Drawer
          open={isOpen}
          width={580}
          onClickAway={handleClickAway}
          PaperProps={{ elevation: 3 }}
          onKeyDown={handleKeyPress}
          tabIndex={-1}
          // Fix issue where element inside Popper is not focusable
          disableEnforceFocus
        >
          <ProductDetailsResetConfirmation
            visible={isResetConfirmVisible}
            isUpdating={isUpdating}
            onBack={() => setIsResetConfirmVisible(false)}
            onDiscard={() => {
              confirmDiscard();
              onClose();
            }}
            onSave={methods.handleSubmit(submit, handleValidationError)}
          >
            <>
              <ElevationScroll target={contentEl}>
                <DrawerToolbar style={{ height: 56 }}>
                  <ProductDetailsDrawerToolbar
                    index={index}
                    lastIndex={lastIndex}
                    offset={offset}
                    totalCount={totalCount}
                    isUpdating={isUpdating}
                    isDeleting={isDeleting}
                    onNavigation={onNavigation}
                    onSave={methods.handleSubmit(submit, handleValidationError)}
                    onCancel={confirmDiscard}
                  />
                </DrawerToolbar>
              </ElevationScroll>
              <Box
                {...({ ref: contentRefCb } as any)}
                position="relative"
                overflow="auto"
                bgcolor={COLORS.skyNormal}
                p={3}
              >
                {product && (
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                      <ProductSummary
                        product={product}
                        classes={classes}
                        onStoreSettingsClick={goToStoreSettings}
                      />
                    </Grid>
                    <Grid item>
                      <ProductInfoCard
                        CardHeaderProps={{
                          title: formatMessage({ id: 'product-listing.details.basic-info' }),
                          className: classes.cardHeader,
                        }}
                        CardContentProps={{ className: classes.cardContent }}
                        dimensionsUnit={product.dimensions_unit as DimensionsUnitAbbreviation}
                        weightUnit={product.weight_unit as WeightUnitAbbreviation}
                        placeholders={placeholders}
                      />
                    </Grid>
                    <Grid item>
                      <ProductImportCard
                        CardHeaderProps={{
                          title: formatMessage({
                            id: 'product-listing.details.shipping-customs',
                          }),
                          className: classes.cardHeader,
                        }}
                        CardContentProps={{ className: classes.cardContent }}
                        $injector={$injector}
                      />
                    </Grid>
                    {isHsCode && (
                      <Grid item xs={12}>
                        <ProductItemDeclarationCard
                          CardHeaderProps={{
                            title: formatMessage({
                              id: 'product-listing.details.item-declaration',
                            }),
                            className: classes.cardHeader,
                          }}
                          CardContentProps={{ className: classes.cardContent }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <ProductFulfillmentCard
                        CardHeaderProps={{
                          title: formatMessage({ id: 'product-listing.details.fulfillment' }),
                          className: classes.cardHeader,
                        }}
                        CardContentProps={{ className: classes.cardContent }}
                      />
                    </Grid>
                    {isDeleteConfirmVisible ? (
                      <Grid item container alignItems="center">
                        <Grid xs={6} item>
                          <Typography variant="body1" style={{ color: COLORS.inkDarkest }}>
                            <FormattedMessage id="forms.are-you-sure" />
                          </Typography>
                        </Grid>
                        <Grid xs={6} item container spacing={2} justifyContent="flex-end">
                          <Grid item>
                            <Button variant="text" onClick={() => setIsDeleteConfirmVisible(false)}>
                              <FormattedMessage id="global.cancel" />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={handleDelete}
                              variant="contained"
                              color="error"
                              loading={isDeleting}
                            >
                              <FormattedMessage id="global.delete" />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12} container justifyContent="center">
                        <Button
                          onClick={() => setIsDeleteConfirmVisible(true)}
                          variant="contained"
                          color="error"
                          disabled={isUpdating || !canDeleteProduct}
                        >
                          <FormattedMessage id="product-listing.details.delete" />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Box>
            </>
          </ProductDetailsResetConfirmation>
        </Drawer>
      </form>
    </FormProvider>
  );
}

export function WrappedProductDetailsDrawer(
  props: ProductDetailsDrawerProps
): ReactElement<ProductDetailsDrawerProps> {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ProductDetailsDrawer {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularProductDetailsDrawer = react2angular(
  WrappedProductDetailsDrawer,
  [
    'product',
    'isOpen',
    'index',
    'lastIndex',
    'totalCount',
    'offset',
    'onClose',
    'onClickAway',
    'onNavigation',
  ],
  ['$injector']
);
