(function () {
  'use strict';

  function editShipmentCourierCtrl($scope, params, MixpanelService, UserSession) {
    $scope.params = params;
    $scope.UserSession = UserSession;

    (function init() {
      MixpanelService.track('Multiple - Edit Courier - Open', {
        dimensions_trigger: $scope.params && $scope.params.hasMissingDimensions,
      });
    })();

    $scope.close = function () {
      $scope.$close();
    };

    $scope.onOptionalDimensionsBannerClick = function () {
      $scope.$close();
    };
  }

  editShipmentCourierCtrl.$inject = ['$scope', 'params', 'MixpanelService', 'UserSession'];

  angular
    .module('app.service.edit-courier')
    .controller('editShipmentCourierCtrl', editShipmentCourierCtrl);
})();
