import { IPickupsFilters } from 'typings/pickup';

import { IComponentController } from 'angular';
import template from './search-not-match.html?raw';
import style from './search-not-match.module.scss';
import boxQuestion from './box-question.svg?svgo';
import { PickupsPageService } from '../pickups-page.service';

class SearchNotMatch implements IComponentController {
  style = style;
  boxQuestion = boxQuestion;

  static $inject = ['PickupsPageService'];
  constructor(private PickupsPageService: PickupsPageService) {}

  resetSearch(): void {
    this.PickupsPageService.filters = {} as IPickupsFilters;
    this.PickupsPageService.getPickups();
    this.PickupsPageService.updateTotals();
  }
}

const SearchNotMatchComponent: ng.IComponentOptions = {
  controller: SearchNotMatch,
  template,
  bindings: {},
};

export { SearchNotMatchComponent };
