import template from './address-form-au.html?raw';

(function () {
  'use strict';

  var addressFormAu = {
    template,
    bindings: {
      address: '=',
      fieldValidators: '<',
      isPickupInstructionAvailable: '<',
    },
    controller: 'addressFormAuCtrl as ctrl',
  };

  angular.module('app.component.address-form-au').component('addressFormAu', addressFormAu);
})();
