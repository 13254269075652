import angular from 'angular';

import { PrintPreviewModalComponent } from './print-preview-modal.component';

const MODULE_NAME = 'app.printing-options.print-preview-modal';

angular
  .module(MODULE_NAME, ['app.global.services.printing-options'])
  .component('esPrintingOptionsPrintPreviewModal', PrintPreviewModalComponent);

export default MODULE_NAME;
