import angular from 'angular';

import { FixMonkey } from './FixMonkey.resource';
import { FixMonkeyService } from './FixMonkey.service';

const MODULE_NAME = 'app.global.fix-monkey';

angular
  .module(MODULE_NAME, [
    'core.config',
    'app.global.user-status',
    'core.components',
    'app.global.shipment',
    'ngResource',
  ])
  .factory('FixMonkey', FixMonkey)
  .service('FixMonkeyService', FixMonkeyService);

export default MODULE_NAME;
