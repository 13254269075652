export function convertToSnake(data: any): any {
  if (data instanceof FormData) {
    const arr: any[] = Array.from((<any>data).entries());

    return arr.reduce((acc, [key, val]) => {
      const newKey = key.replace(/([A-Z])/g, (m: string) => `_${m.toLowerCase()}`);
      if (val) {
        if (val instanceof File) {
          acc.append(newKey, val, val.name);
        } else {
          acc.append(newKey, val);
        }
      }

      return acc;
    }, new FormData());
  }
  if (data instanceof Date || data instanceof Boolean || data instanceof String) {
    return data;
  }
  if (data instanceof Array) {
    return data.map((item: any) => convertToSnake(item));
  }

  if (data !== null && typeof data === 'object') {
    const transformed: any = {};
    Object.keys(data).forEach((key: any) => {
      let transformedKey = key;
      let transformedItem = data[key];

      if (typeof transformedKey === 'string') {
        transformedKey = transformedKey.replace(/([A-Z])/g, (m: string) => `_${m.toLowerCase()}`);
      }

      if (typeof transformedItem === 'object') {
        transformedItem = convertToSnake(transformedItem);
      }

      transformed[transformedKey] = transformedItem;
    });

    return transformed;
  }

  return data;
}
