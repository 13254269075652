import { IWelcomeAction } from 'typings/dashboard/components/welcome-action';
import GetQuoteSvg from '@client/src/analytics/welcome/get-started-actions/images/action-quote.svg?svgo';
import CreateShipmentSvg from '@client/src/analytics/welcome/get-started-actions/images/action-create-shipment.svg?svgo';
import ManageShipmentSvg from '@client/src/analytics/welcome/get-started-actions/images/action-manage-shipment.svg?svgo';
import ImportProductSvg from '@client/src/analytics/welcome/get-started-actions/images/action-import-products.svg?svgo';
import ConnectStoreSvg from '@client/src/analytics/welcome/get-started-actions/images/action-store.svg?svgo';
import ConnectCourierSvg from '@client/src/analytics/welcome/get-started-actions/images/action-courier.svg?svgo';
import CheckStatementsSvg from '@client/src/analytics/welcome/get-started-actions/images/action-statement.svg?svgo';
import CreateApiSvg from '@client/src/analytics/welcome/get-started-actions/images/action-api.svg?svgo';
import CreateWebhookSvg from '@client/src/analytics/welcome/get-started-actions/images/action-webhook.svg?svgo';
import SyncOrdersSvg from '@client/src/analytics/welcome/get-started-actions/images/action-sync-orders.svg?svgo';
import BookMeetingSvg from '@client/src/analytics/welcome/get-started-actions/images/book-a-meeting-gradient.svg?svgo';
import PaymentMethodSvg from '@client/src/analytics/welcome/get-started-actions/images/payment-method.svg?svgo';
import ApiAccessTokenSvg from '@client/src/analytics/welcome/get-started-actions/images/api-access-token.svg?svgo';

export enum WelcomeAction {
  GetQuote = 'GetQuote',
  CreateShipment = 'CreateShipment',
  ManageShipment = 'ManageShipment',
  ConnectStore = 'ConnectStore',
  ConnectCourier = 'ConnectCourier',
  CheckStatements = 'CheckStatements',
  CreateApi = 'CreateApi',
  CreateWebhook = 'CreateWebhook',
  SyncOrders = 'SyncOrders',
  ImportOrders = 'ImportOrders',
  BookMeeting = 'BookMeeting',
  ImportProducts = 'ImportProducts',
  AddPaymentMethod = 'AddPaymentMethod',
  GetApiAccessToken = 'GetApiAccessToken',
  AddABox = 'AddABox',
}

export const WelcomeActions: IWelcomeAction[] = [
  {
    key: WelcomeAction.GetQuote,
    translationKey: `welcome.actions.${WelcomeAction.GetQuote}`,
    image: new URL('../../assets/images/dashboard/get-started/action-quote.svg', import.meta.url).href,
    svg: GetQuoteSvg,
    sref: 'app.quote',
    requiredUserRole: 'create_shipments',
  },
  {
    key: WelcomeAction.CreateShipment,
    translationKey: `welcome.actions.${WelcomeAction.CreateShipment}`,
    image: new URL('../../assets/images/dashboard/get-started/action-create-shipment.svg', import.meta.url).href,
    svg: CreateShipmentSvg,
    sref: 'receiver-info',
    requiredUserRole: 'create_shipments',
  },
  {
    key: WelcomeAction.ManageShipment,
    translationKey: `welcome.actions.${WelcomeAction.ManageShipment}`,
    image: new URL('../../assets/images/dashboard/get-started/action-manage-shipment.svg', import.meta.url).href,
    svg: ManageShipmentSvg,
    sref: 'app.shipments',
    requiredUserRole: 'manage_shipments',
  },
  {
    key: WelcomeAction.ConnectStore,
    translationKey: `welcome.actions.${WelcomeAction.ConnectStore}`,
    image: new URL('../../assets/images/dashboard/get-started/action-store.svg', import.meta.url).href,
    svg: ConnectStoreSvg,
    sref: 'app.connect({platform:"shopify"})',
    requiredUserRole: 'integrations',
  },
  {
    key: WelcomeAction.ConnectCourier,
    translationKey: `welcome.actions.${WelcomeAction.ConnectCourier}`,
    image: new URL('../../assets/images/dashboard/get-started/action-courier.svg', import.meta.url).href,
    svg: ConnectCourierSvg,
    sref: 'app.couriers({new:true})',
    requiredUserRole: 'account_and_settings',
  },
  {
    key: WelcomeAction.CheckStatements,
    translationKey: `welcome.actions.${WelcomeAction.CheckStatements}`,
    image: new URL('../../assets/images/dashboard/get-started/action-statement.svg', import.meta.url).href,
    svg: CheckStatementsSvg,
    sref: 'app.account.billing',
    requiredUserRole: 'subscription_and_billing',
  },
  {
    key: WelcomeAction.CreateApi,
    translationKey: `welcome.actions.${WelcomeAction.CreateApi}`,
    image: new URL('../../assets/images/dashboard/get-started/action-api.svg', import.meta.url).href,
    svg: CreateApiSvg,
    sref: 'app.connect({platform:"api"})',
    requiredUserRole: 'integrations',
  },
  {
    key: WelcomeAction.CreateWebhook,
    translationKey: `welcome.actions.${WelcomeAction.CreateWebhook}`,
    image: new URL('../../assets/images/dashboard/get-started/action-webhook.svg', import.meta.url).href,
    svg: CreateWebhookSvg,
    sref: 'app.webhooks({new:true})',
    requiredUserRole: 'integrations',
  },
  {
    key: WelcomeAction.SyncOrders,
    translationKey: `welcome.actions.${WelcomeAction.SyncOrders}`,
    image: new URL('../../assets/images/dashboard/get-started/action-sync-orders.svg', import.meta.url).href,
    svg: SyncOrdersSvg,
    sref: 'app.multiple({sync_first_store:true})',
    requiredUserRole: 'create_shipments',
  },
  {
    key: WelcomeAction.ImportOrders,
    translationKey: `welcome.actions.${WelcomeAction.ImportOrders}`,
    image: new URL('../../assets/images/dashboard/get-started/action-create-shipment.svg', import.meta.url).href,
    svg: CreateShipmentSvg,
    sref: 'fileUpload',
    requiredUserRole: 'create_shipments',
  },
  {
    key: WelcomeAction.BookMeeting,
    translationKey: `welcome.actions.${WelcomeAction.BookMeeting}`,
    image: new URL('../../assets/images/dashboard/get-started/book-a-meeting-gradient.svg', import.meta.url).href,
    svg: BookMeetingSvg,
    sref: '',
  },
  {
    key: WelcomeAction.ImportProducts,
    translationKey: `welcome.actions.${WelcomeAction.ImportProducts}`,
    image: new URL('../../assets/images/dashboard/get-started/action-import-products.svg', import.meta.url).href,
    svg: ImportProductSvg,
    sref: 'app.product-listing',
    requiredUserRole: 'product_listing',
  },
  {
    key: WelcomeAction.AddPaymentMethod,
    translationKey: `welcome.actions.${WelcomeAction.AddPaymentMethod}`,
    image: new URL('../../assets/images/dashboard/get-started/payment-method.svg', import.meta.url).href,
    svg: PaymentMethodSvg,
    sref: 'app.account.payment',
    requiredUserRole: 'subscription_and_billing',
  },
  {
    key: WelcomeAction.GetApiAccessToken,
    translationKey: `welcome.actions.${WelcomeAction.GetApiAccessToken}`,
    image: new URL('../../assets/images/dashboard/get-started/api-access-token.svg', import.meta.url).href,
    svg: ApiAccessTokenSvg,
    sref: '',
  },
  // Requested that the Add a box action will have same graphic as Create Shipment
  {
    key: WelcomeAction.AddABox,
    translationKey: `welcome.actions.${WelcomeAction.AddABox}`,
    image: new URL('../../assets/images/dashboard/get-started/action-create-shipment.svg', import.meta.url).href,
    svg: CreateShipmentSvg,
    sref: 'boxes',
    requiredUserRole: 'account_and_settings',
  },
];
