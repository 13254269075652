import React, { useState, useEffect } from 'react';
import Image1 from './images/1.png';
import Image2 from './images/2.png';
import Image3 from './images/3.png';
import Image4 from './images/4.png';

const images = [Image1, Image2, Image3, Image4];

function PrinterAnimation() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex justify-center items-center mb-8">
      <img
        src={images[currentIndex]}
        alt={`printer ${currentIndex + 1}`}
        className="w-[320px] h-[200px] object-contain"
      />
    </div>
  );
}

export default PrinterAnimation;
