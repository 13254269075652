import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

/* eslint-disable no-param-reassign */
(function () {
  angular.module('easyshipDashboardApp').controller('platformRedirectCtrl', platformRedirectCtrl);

  platformRedirectCtrl.$inject = [
    '$location',
    '$state',
    '$translate',
    'StoreService',
    'EbayService',
  ];

  function platformRedirectCtrl($location, $state, $translate, StoreService, EbayService) {
    const vm = this;

    setTimeout(function () {
      const spinnerElement = document.getElementById('app__initial-load');
      if (spinnerElement) spinnerElement.parentNode.removeChild(spinnerElement);
    });

    function getEbayToken(ebayGranted) {
      EbayService.getEbayToken(ebayGranted)
        .then(function (response) {
          keyCreatedCallback(response);
        })
        .catch(function (error) {
          keyCreationFailedCallback(error);
        });
    }

    function processEbay() {
      const ebayGranted = {
        username: $location.search().username,
        session_id: $location.search().vars ? $location.search().vars.split('|')[0] : null,
        id: $location.search().vars ? $location.search().vars.split('|')[1] : null,
      };

      if (ebayGranted.username) {
        // Check if the User is coming from an eBay callback
        getEbayToken(ebayGranted);
      }
    }

    function processWooCommerce() {
      const wooCommerceGranted = {
        success: $location.search().success,
        user_id: $location.search().user_id,
      };

      if (wooCommerceGranted.success) {
        // Woocommerce callback to add!
        toastSuccess($translate.instant('connect.notifications.added'));
      } else if (wooCommerceGranted.success === 0) {
        toastSuccess($translate.instant('connect.notifications.save-failed'));
      }
    }

    // -------- KEY CREATION ----------------------
    function keyCreatedCallback(response) {
      const index = _.findIndex(StoreService.stores, { id: response.store.id });

      if (index > -1) {
        StoreService.stores[index] = response.store;
        toastSuccess($translate.instant('connect.notifications.updated'));
      } else {
        StoreService.stores.unshift(response.store);
        toastSuccess($translate.instant('connect.notifications.added'));
      }

      if (response.store) {
        StoreService.sendStoreConnectedEvents(response.store);
      }

      $state.go('app.single-store', { id: response.store.id, store: response.store });
    }

    function keyCreationFailedCallback(error) {
      if (!error) {
        toastError($translate.instant('connect.notifications.add-failed'));
        $state.go('app.connect');
      }

      if (_.get(error, 'data.username')) {
        vm.errors = `${error.data.username[0]}.`;
      } else if (_.get(error, 'data.validation_error')) {
        vm.errors = `${error.data.validation_error[0]}.`;
      } else if (_.get(error, 'data.status')) {
        vm.errors = error.data.status;
      }

      toastError(
        `${$translate.instant('connect.notifications.add-failed')} ${_.capitalize(vm.errors)}`
      );
      $state.go('app.connect');
    }
    // -------- ./KEY CREATION ----------------------

    function addStore() {
      const urlParams = $location.search();

      if (urlParams.success && urlParams.user_id) {
        processWooCommerce();
      } else if (urlParams.username) {
        processEbay();
      }
    }

    (function init() {
      addStore();
    })();
  }
})();
