import { IComponentController } from 'angular';
import template from './shipments-done-card.html?raw';
import style from './shipments-done-card.module.scss';
import image from './shipments-done-card.svg?svgo';

class ShipmentsDoneCard implements IComponentController {
  style = style;
  image = image;
}

const ShipmentsDoneCardComponent: ng.IComponentOptions = {
  controller: ShipmentsDoneCard,
  template,
  bindings: {
    esRightAction: '&',
    esLeftAction: '&',
    esCloseAction: '&',
    esShipments: '<',
  },
};

export { ShipmentsDoneCardComponent };
