/* eslint-disable no-param-reassign */
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './address-validation-step-sender.html?raw';

(function () {
  angular
    .module('easyshipDashboardApp')
    .directive('addressValidationStepSender', addressValidationStepSender);

  function addressValidationStepSender() {
    const directive = {
      template,
      restrict: 'EA',
      scope: {
        address: '=',
        defaultValues: '=',
        deactivate: '=',
        proceed: '=',
        index: '=',
        busy: '=',
        closeModal: '=',
        settingAsDefault: '=',
        skipAddressInfo: '=',
        goToStep: '=',
      },
      controller,
      controllerAs: 'addressValidationStepSender',
    };

    return directive;
  }

  controller.$inject = [
    '$scope',
    '$translate',
    'AddressService',
    'CourierService',
    'CourierAccounts',
  ];

  function controller($scope, $translate, AddressService, CourierService, CourierAccounts) {
    const ctrl = this;

    const translations = {
      address: $translate
        .instant('global.pluralize.address', { COUNT: 1 }, 'messageformat')
        .toLowerCase(),
    };

    (function init() {
      // Set all variables according to params passed in open()
      ctrl.address = $scope.address;
      ctrl.defaultValues = $scope.defaultValues;
      ctrl.deactivate = $scope.deactivate;
      ctrl.index = $scope.index;

      // Show or not "We may need to recalclulate..."
      ctrl.updatingSender = isUpdatingSenderAddress(ctrl.defaultValues);

      // If the address is set to be deactivated the user needs to choose an address to be the new pickup address
      if (ctrl.deactivate) chooseAddress();
    })();

    /**
     * [confirm] Redirect to an update of the content or just the default value of the address according to the params
     */
    ctrl.confirm = function () {
      if (ctrl.defaultValues && !ctrl.chosenAddress && $scope.settingAsDefault) {
        setDefaultValue(ctrl.address, ctrl.defaultValues);
      } else if (ctrl.chosenAddress && ctrl.defaultValues) {
        updateAndDelete(ctrl.chosenAddress, ctrl.defaultValues);
      } else {
        $scope.proceed(ctrl.address);
      }
    };

    /**
     * [updateAndDelete] If a pickup address is to be deactivated (deleted)
     * Two updates are necessary in order for the backend to know if it has to recalculate the orders or not
     * - Update the new pickup address chosen to be the default pickup address
     * - Update the old address to active -> false
     *
     * @param  {Object} chosenAddress : address to be the new pickup address
     */
    function updateAndDelete(chosenAddress, default_values) {
      ctrl.updating = true;

      // 1. Set the chosen address to default pickup
      AddressService.setAddressAsDefault(chosenAddress, default_values).then(
        function () {
          // 2. When it's done, deactivate the old one
          AddressService.deactivateAddress(
            AddressService.getShippingAddresses()[ctrl.index],
            ctrl.index
          ).then(
            function () {
              ctrl.updating = false;

              // Success and close
              _refreshCourierCaches();
              toastSuccess(
                $translate.instant('toast.delete-success', { noun: translations.address })
              );
              $scope.closeModal();
            },
            function () {
              updateAndDeleteFailedCallback();
            }
          );
        },
        function () {
          updateAndDeleteFailedCallback();
        }
      );
    }

    function updateAndDeleteFailedCallback() {
      ctrl.updating = false;
      AddressService.getShippingAddresses()[ctrl.index].deactivateFailed = true;
      toastError($translate.instant('toast.delete-error', { noun: translations.address }));
    }

    /**
     * [setDefaultValue] Update default value of a given address
     *
     * @param {Object} address      : Address to be updated
     * @param {String} defaultValue : default value to be set to true
     */
    function setDefaultValue(address, defaultValues) {
      ctrl.updating = true;

      AddressService.setAddressAsDefault(address, defaultValues).then(
        function () {
          // Success and close
          ctrl.updating = false;
          address.settingAddressAsDefault = false;
          toastSuccess(
            $translate.instant(
              'toast.update-success',
              { noun: translations.address },
              'messageformat'
            )
          );

          $scope.closeModal();
        },
        function () {
          ctrl.updating = false;
          address.settingAddressAsDefault = false;
          toastError($translate.instant('toast.update-error', { noun: translations.address }));
        }
      );
    }

    /**
     * [chooseAddress] If a Pickup address is set to be deactivated, the user can choose which address is going to be the new pickup address to prevent having to click and recalculating two times
     *
     */
    function chooseAddress() {
      // Format addresses to be in a single string to be displayed in the dropdown
      AddressService.formatAllAddresses().then(function (addresses) {
        // Address dropdown -> Reject the address that is going to be deleted
        ctrl.addresses = _.reject(addresses, {
          id: addresses[ctrl.index].id,
        });

        // Filter addresses that are in another country
        ctrl.addresses = _.filter(ctrl.addresses, {
          country_id: addresses[ctrl.index].country_id,
        });

        // Preselect the first address so chosenAddress always has a value
        // eslint-disable-next-line prefer-destructuring
        ctrl.chosenAddress = ctrl.addresses[0];

        // Show the dropdown if there will be more than two addresses after deletion
        if (ctrl.addresses.length > 2) ctrl.chooseAddress = true;
      });
    }

    /**
     * [isUpdatingSenderAddress] Is one of the default values 'pickup' ?
     * @param  {String}  default_values to be updated
     * @return {Boolean}
     */
    function isUpdatingSenderAddress(default_values) {
      const match = default_values.match(/sender/g);
      return !!match;
    }

    function _refreshCourierCaches() {
      CourierService.getCouriers();
      CourierAccounts.getActiveCourierAccounts();
    }
  } // ./updateAddressConfirmationCtrl
})();
