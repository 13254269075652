import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useShipmentsTableSettingsGateway } from '@client/src/manage-shipments/providers/ShipmentsTableSettingsGatewayProvider';
import { ColumnVisibilityMap } from '@typings/dashboard/shipment_table/settings';

export default function useShipmentsTableSettingsQuery(): UseQueryResult<ColumnVisibilityMap> {
  const { shipmentsTableSettingsGateway } = useShipmentsTableSettingsGateway();

  return useQuery({
    queryKey: ['new_shipments_table_settings'],
    queryFn: (): Promise<ColumnVisibilityMap> => shipmentsTableSettingsGateway.getSettings(),
    cacheTime: Infinity,
    staleTime: 60 * 1000,
  });
}
