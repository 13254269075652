/* eslint-disable no-param-reassign */
import { showToastWithAction } from '@client/core/components/react/ToastWithAction';

(function () {
  AddressService.$inject = [
    '$q',
    '$state',
    '$timeout',
    '$translate',
    'Address',
    'AddressSearch',
    'UserSession',
    'GoogleAnalytics',
    'CountryService',
  ];

  function AddressService(
    $q,
    $state,
    $timeout,
    $translate,
    Address,
    AddressSearch,
    UserSession,
    GoogleAnalytics,
    CountryService
  ) {
    const service = this;
    const VALID_DEFAULT_TYPES = ['pickup', 'sender', 'return', 'billing'];
    const promises = {
      queryById: {},
    };
    let translations = {};

    const UAE_ID = 2;
    const US_ID = 234;
    const NZ_ID = 172;
    const CA_ID = 39;
    const MX_ID = 158;
    const AU_ID = 14;
    const PR_ID = 183;

    const COUNTRIES_REQUIRE_STATE = [AU_ID, CA_ID, US_ID];
    const COUNTRIES_TO_FETCH_CITY_STATE = [AU_ID, CA_ID, MX_ID, NZ_ID, PR_ID, US_ID];
    const COUNTRIES_WITH_CITY_OPTIONS = [AU_ID, NZ_ID];

    const POSTAL_CODE_FIELD_LENGTHS = {
      0: [0, 20],
      14: [4, 4],
      39: [6, 7],
      78: [5, 7],
      158: [5, 5],
      167: [6, 6],
      172: [4, 4],
      199: [6, 6],
      234: [5, 5],
    };

    const defaultPickupInstructionOption = {
      name: 'None',
      value: 'none',
    };

    const pickupInstructionOptions = [
      defaultPickupInstructionOption,
      {
        name: 'Front Door',
        value: 'front_door',
      },
      {
        name: 'Back Door',
        value: 'back_door',
      },
      {
        name: 'Side Door',
        value: 'side_door',
      },
      {
        name: 'Mail Room',
        value: 'mail_room',
      },
      {
        name: 'Office',
        value: 'office',
      },
      {
        name: 'Reception',
        value: 'reception',
      },
      {
        name: 'In/At Mailbox',
        value: 'in_mailbox',
      },
      {
        name: 'Others',
        value: 'others',
      },
    ];

    const COUNTRIES_AVAILABLE_FOR_PICKUP_INSTRUCTION = [AU_ID];

    service.addresses = {
      shippingAddresses: [],
      billingAddresses: [],
      reusableShippingAddresses: [],
    };

    service.isPickupInstructionBetaFeatureEnabled = function () {
      return !!(
        UserSession.company &&
        UserSession.company.dashboard_settings.beta_feature_pickup_instruction
      );
    };

    service.isPickupInstructionAvailable = function (countryId) {
      return (
        service.isPickupInstructionBetaFeatureEnabled() &&
        COUNTRIES_AVAILABLE_FOR_PICKUP_INSTRUCTION.includes(countryId)
      );
    };

    $translate(['account.addresses.add-new']).then(function (result) {
      translations = result;
    });

    const matchesCountryCode = function (countryAlpha2) {
      return function (address) {
        return address.country.alpha2 === countryAlpha2;
      };
    };

    /**
     * [getAddresses] Get all the active addresses
     * @return {Promise}
     */
    service.getAddresses = function () {
      return $q(function (resolve, reject) {
        Address.query(
          { company_id: UserSession.company.id, use_for_later: true },
          function (data) {
            service.addresses = buildAddressLineForArray(data.addresses);
            service.addresses = _seperateAddressesArrays(service.addresses);
            resolve(service.addresses);
          },
          function (err) {
            service.addresses = [];
            reject(err);
          }
        );
      });
    };

    /**
     * [suggest] Get receiver address suggestion
     * @return {Promise}
     */
    service.suggest = function (keyword, keywordSearchField) {
      const companyId = UserSession.getCompanyId();

      return $q(function (resolve, reject) {
        AddressSearch.save(
          { company_id: companyId },
          {
            keyword,
            keyword_search_field: keywordSearchField,
            offset: 0,
            limit: 5,
            scope: 'shipments_all',
          },
          function (response) {
            resolve(response.suggested_addresses);
          },
          function (reason) {
            reject(reason);
          }
        );
      });
    };

    /**
     * [queryById] Get a single address from the API
     * @desc   Used if the address is not among those returned with the `use_for_later: true` query
     * @return {Promise}
     */
    service.queryById = function (addressId) {
      if (!promises.queryById[addressId]) {
        promises.queryById[addressId] = $q(function (resolve, reject) {
          // Use timeout to reduce request
          const clearPromise = function () {
            $timeout(function () {
              promises.queryById[addressId] = null;
            }, 1000);
          };

          const foundAddress = service.addresses.shippingAddresses.find(function (address) {
            return address.id === addressId;
          });

          if (foundAddress) {
            clearPromise();
            resolve(foundAddress);
          } else {
            Address.fetch({ company_id: UserSession.company.id, id: addressId })
              .$promise.then(function (data) {
                const withAddressLine = buildAddressLineForArray([data.address]);
                service.addresses.shippingAddresses.push(withAddressLine[0]);

                clearPromise();
                resolve(withAddressLine[0]);
              })
              .catch(function (err) {
                promises.queryById[addressId] = null;
                reject(err);
              });
          }
        });
      }

      return promises.queryById[addressId];
    };

    function _concatFields(address) {
      return (
        (address.name || '') +
        (address.nickname || '') +
        (address.company_name || '') +
        (address.contact_name || '') +
        (address.contact_email || '') +
        (address.contact_phone || '') +
        (address.line_1 || '') +
        (address.line_2 || '') +
        (address.line_3 || '') +
        (address.city || '') +
        (address.state || '') +
        (address.postal_code || '') +
        // (address.country && (address.country.name || '') + (address.country.alpha2 || '') || '' ) +
        ((address.hk_district &&
          (address.hk_district.area || '') +
            (address.hk_district.district || '') +
            (address.hk_district.zone || '')) ||
          '')
      );
    }

    function _seperateAddressesArrays(addresses) {
      const shippingAddresses = [];
      const billingAddresses = [];
      const reusableShippingAddresses = [];

      addresses.forEach(function (address) {
        address.allFields = _concatFields(address);

        if (address.default_values.billing) {
          billingAddresses.push(address);
          return;
        }

        shippingAddresses.push(address);

        if (address.use_for_later) reusableShippingAddresses.push(address);
      });

      return {
        shippingAddresses,
        billingAddresses,
        reusableShippingAddresses,
      };
    }

    /**
     * [getAddresses] Get all the active addresses
     * @return {Promise}
     */
    service.getAddressesAndFormat = function () {
      return $q(function (resolve, reject) {
        service
          .getAddresses()
          .then(function () {
            service
              .formatAllAddresses(service.addresses.shippingAddresses, true)
              .then(function (addresses) {
                resolve(addresses);
              })
              .catch(function () {
                reject();
              });
          })
          .catch(function () {
            reject();
          });
      });
    };

    /**
     * [createAddress] Create new address
     *
     * @param  {Object} address
     * @return {Promise}
     */
    service.createAddress = function (address, params) {
      params = params || {};
      address = _formatAddressToSave(address);

      return $q(function (resolve, reject) {
        Address.create(
          {
            company_id: UserSession.company.id,
            skip_address_validation: params.skipValidation,
            force_validation: params.forceValidation,
          },
          { address },
          function (res) {
            if (service.addresses.shippingAddresses.length === 0) {
              // Manually update has_pickup_address in case no current_user call are made
              UserSession.company.has_pickup_address = true;
            }
            res.address.fullAddress = constructAddressSentence(res.address);

            res.address.formattedAddress = service.formatAddress(res.address, true);

            service.addresses.shippingAddresses.push(res.address);

            resolve(res.address);
          },
          function (err) {
            reject(err);
          }
        );
      }); // $q
    }; // createAddress

    /**
     * [updateAddress] Update a given address
     *
     * @param  {Object} addressCopy: new content of address
     * @param  {Object} address    : old address
     * @return {Promise}
     */
    service.updateAddress = function (addressCopy, params) {
      params = params || {};
      addressCopy = _formatAddressToSave(addressCopy);

      return $q(function (resolve, reject) {
        Address.update(
          {
            company_id: UserSession.company.id,
            id: addressCopy.id,
            skip_address_validation: params.skipValidation,
            force_validation: params.forceValidation,
          },
          addressCopy,
          function (response) {
            response.address.fullAddress = constructAddressSentence(response.address);

            if (_isBillingAddress(response.address)) {
              service.addresses.billingAddresses[0] = response.address;
            } else {
              const addressIndex = _.findIndex(service.addresses.shippingAddresses, {
                id: addressCopy.id,
              });

              service.addresses.shippingAddresses[addressIndex] = response.address;
            }

            resolve(response);
          },
          function (err) {
            reject(err);
          }
        );
      }); // $q
    }; // updateAddress

    /**
     * [deactivateAddress] Deactivate Address. From a user perspective it is the same as deleting the address
     *
     * @param  {Object} address  : address to deactivate
     * @param  {Integer} index   : index of the address to deactivate in the array
     * @return {Promise}
     */
    service.deactivateAddress = function (address) {
      const index = service.addresses.shippingAddresses
        .map(function (addr) {
          return addr.id;
        })
        .indexOf(address.id);

      return $q(function (resolve, reject) {
        address.deactivateFailed = false;
        address.deactivateMode = true;
        address.is_active = false;

        address.default_address = '';

        Address.deactivate(
          { company_id: UserSession.company.id, id: address.id },
          address,
          function () {
            if (index >= 0) service.addresses.shippingAddresses.splice(index, 1);
            GoogleAnalytics.createGAClickButton('Delete Address');
            resolve();
          },
          reject
        );
      });
    };

    /**
     * [setAddressAsDefault] Sets the requested default value for the address ('pickup', 'sender', 'billing') to true
     *
     * @param  {Object}  address       : address to update
     * @param  {String]} default_value : string of default value. Can hold multiple values. ex: 'billing, sender'
     * @return {Promise}
     */
    service.setAddressAsDefault = function (address, default_values) {
      return $q(function (resolve, reject) {
        Address.setAsDefault(
          { company_id: UserSession.company.id, id: address.id, set_address_as_default: true },
          { address: _.extend(address, { default_address: default_values }) },
          function (res) {
            default_values.split(',').forEach(function (default_value) {
              if (default_value === 'pickup') {
                UserSession.company.origin_postal_code = res.address.postal_code;
              }

              resetAllDefault(default_value);
            });

            const addressIndex = _.findIndex(service.addresses.shippingAddresses, {
              id: address.id,
            });
            service.addresses.shippingAddresses[addressIndex] = res.address;

            res.address.fullAddress = constructAddressSentence(res.address);

            resolve(res.address);
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    service.createUsAddress = function (address) {
      address = _formatAddressToSave(address);

      return $q(function (resolve, reject) {
        Address.createUsAddress(
          { company_id: UserSession.company.id },
          { address },
          function (res) {
            if (
              service.addresses.shippingAddresses.length === 0 &&
              res.address.usps_pickup_validation_state === 'validated'
            ) {
              // Manually update has_pickup_address in case no current_user call are made
              UserSession.company.has_pickup_address = true;
            }

            res.address.fullAddress = constructAddressSentence(res.address);

            res.address.formattedAddress = service.formatAddress(res.address, true);

            resolve(res);
          },
          reject
        );
      });
    };

    service.updateUsAddress = function (address) {
      address = _formatAddressToSave(address);

      return $q(function (resolve, reject) {
        Address.updateUsAddress(
          { company_id: UserSession.company.id, id: address.id },
          { address },
          function (res) {
            const addressIndex = _.findIndex(service.addresses.shippingAddresses, {
              id: address.id,
            });

            res.address.fullAddress = constructAddressSentence(res.address);

            service.addresses.shippingAddresses[addressIndex] = res.address;

            resolve(res);
          },
          reject
        );
      });
    };

    service.validateAddress = function (address) {
      address = _formatAddressToSave(address);

      return $q(function (resolve, reject) {
        Address.validateAddress(
          { company_id: UserSession.company.id, id: address.id },
          { address },
          function (res) {
            if (
              service.addresses.shippingAddresses.length === 0 &&
              res.address.usps_pickup_validation_state === 'invalid_confirmed_by_client'
            ) {
              UserSession.company.has_pickup_address = true;
            }

            resolve(res);
          },
          reject
        );
      });
    };

    service.deleteAddress = function (address) {
      return $q(function (resolve, reject) {
        Address.delete({ company_id: UserSession.company.id, id: address.id }, {}, resolve, reject);
      });
    };

    /**
     * [resetAllDefault] Set all the addresses default value specified as a param to false
     * /!\ Can only pass ONE value
     *
     * @param  {String} default_value : 'billing', 'sender' or 'pickup'
     */
    function resetAllDefault(default_value) {
      service.addresses.shippingAddresses.forEach(function (address) {
        address.default_values[default_value] = false;
      });
    }

    /**
     * [formatAllAddresses] For each Address, add a new property to the Address to show the formatted Address as one sentence.
     *
     * @param {Array} addresses: Array of Addresses
     */
    service.formatAllAddresses = function (addresses, includeContact) {
      const addressesToFormat = addresses || service.addresses.shippingAddresses;
      return $q(function (resolve) {
        addressesToFormat.forEach(function (address) {
          address.formattedAddress = service.formatAddress(address, includeContact);
        });

        resolve(addressesToFormat);
      });
    };

    /**
     * Format an Address to concatenate each part of the Address into one sentence separated by commas.
     *
     * @param  {Object}  address: Address to format
     * @param  {Boolean} includeContact: include contact infos in the formatted address
     * @return {String}  Formatted Address as one sentence.
     */
    service.formatAddress = function (address, includeContact) {
      if (!address) return '';

      let formattedAddress = address.line_1;

      if (includeContact) {
        if (address.company_name) formattedAddress = `${address.company_name}, ${formattedAddress}`;
        if (address.contact_name) formattedAddress = `${address.contact_name}, ${formattedAddress}`;
      }

      if (address.line_2) formattedAddress += `, ${address.line_2}`;
      if (address.line_3) formattedAddress += `, ${address.line_3}`;
      if (address.postal_code && address.postal_code !== '0') {
        formattedAddress += `, ${address.postal_code}`;
      }
      if (address.state) formattedAddress += `, ${address.state}`;
      if (address.city) formattedAddress += ` ${address.city}`;

      if (includeContact) {
        if (address.hk_district) formattedAddress += `, ${address.hk_district.area}`;
        if (address.contact_phone) formattedAddress += `, ${address.contact_phone}`;
        if (address.contact_email) formattedAddress += `, ${address.contact_email}`;
      }

      return formattedAddress;
    };

    /**
     * [needPickupAddress] Checks if the company has a pickup address
     *   - if this is not the case: opens the crate address modal and return true
     *   - else do nothing
     */
    service.needPickupAddress = function () {
      if (!UserSession.company.has_pickup_address) {
        showToastWithAction(this.$translate.instant('account.addresses.pickup-unspecified'), {
          actionLabel: translations['account.addresses.add-new'],
          onActionLabelClick() {
            $state.go('app.account.addresses');
          },
        });
      }
      return !UserSession.company.has_pickup_address;
    };

    /**
     * [getPickupAddress] Returns the requested default address of the company
     *
     * @param  {String} defaultType: 'sender', 'pickup', 'billing' or 'return'
     * @return {Object} Address
     */
    service.getDefaultAddress = function (_defaultType) {
      return $q(function (resolve, reject) {
        if (!_defaultType) reject();

        const isStringType = typeof _defaultType === 'string';
        const isObjectType = typeof _defaultType === 'object';
        const defaultType = isStringType ? _defaultType : _defaultType?.defaultType;

        // Fetch the addresses (the page where it's used will not necessary already have it)
        service.getAddresses().then(function (addresses) {
          const shippingAddresses = addresses.shippingAddresses || [];
          // no addresses -> do nothing
          if (shippingAddresses.length === 0) {
            reject();
          } else {
            const matchedAddress = (() => {
              if (isStringType && VALID_DEFAULT_TYPES.indexOf(defaultType) > -1) {
                // Find and resolve the default address
                const address = _.find(shippingAddresses, function (addr) {
                  return addr.default_values[defaultType];
                });
                if (address) return address;
              }
              if (isObjectType && _defaultType?.id) {
                // Find and resolve the default address by id
                const address = _.find(shippingAddresses, function (addr) {
                  return addr.id === _defaultType.id;
                });
                if (address) return address;
              }
              return defaultType === 'return' ? shippingAddresses[0] : null;
            })();

            return matchedAddress ? resolve(matchedAddress) : reject();
          }
        });
      });
    };

    /**
     * [formatCorrectedAddresses] Format address to be easily used in the next validation step
     * - formatCorrectedAddress
     * - Add index in order to identify the address in the list in the next step (ng-class)
     * @param  {Array} arrayOfcorrectedAddress: array of addresses sent back by USPS
     * @return {Array} formatted addresses
     */
    service.formatCorrectedAddresses = function (arrayOfcorrectedAddress) {
      let i = 0;
      return _.map(arrayOfcorrectedAddress, function (address) {
        address = service.formatCorrectedAddress(address);
        address.index = i++;
        return address;
      });
    };

    service.findAddressById = function (addressId) {
      if (service.addresses.shippingAddresses.length > 0) {
        const address = _.find(service.addresses.shippingAddresses, { id: addressId });

        return address || {};
      }
      return {};
    };

    /**
     * [formatCorrectedAddress] format a single address for validation steps
     * Note: only removing rdi for the moment as Easyhsip does not use it
     * @param  {Object} correctedAddress
     */
    service.formatCorrectedAddress = function (correctedAddress) {
      delete correctedAddress.rdi;
      return correctedAddress;
    };

    service.addAddressToAddressArr = function (address) {
      service.addresses.shippingAddresses.push(address);
    };

    service.updatedAddressInAddressArr = function (address) {
      const addressIndex = _.findIndex(service.addresses.shippingAddresses, { id: address.id });

      if (addressIndex) service.addresses.shippingAddresses[addressIndex] = address;
    };

    service.getShippingAddresses = function () {
      return service.addresses.shippingAddresses || [];
    };

    service.getReusableShippingAddresses = function () {
      return service.addresses.reusableShippingAddresses || [];
    };

    service.fetchReusableShippingAddresses = function () {
      return $q((resolve, reject) => {
        service
          .getAddresses()
          .then(() => {
            resolve(service.getReusableShippingAddresses());
          })
          .catch((err) => reject(err));
      });
    };

    service.getBillingAddresses = function () {
      return service.addresses.billingAddresses || [];
    };

    service.getDefaultPickupAddress = function () {
      return (
        service.addresses.shippingAddresses.find(function (address) {
          return address.default_values.pickup;
        }) || service.addresses.shippingAddresses[0]
      );
    };

    service.hasSenderAddressInCountry = function (countryAlpha2, verifyNoOtherCountry) {
      return $q(function (resolve) {
        const { shippingAddresses } = service.addresses;
        const matchesCountryCodeFn = function (address) {
          return address.country.alpha2 === countryAlpha2;
        };

        if (shippingAddresses.length) {
          resolve(
            verifyNoOtherCountry
              ? shippingAddresses.every(matchesCountryCodeFn)
              : shippingAddresses.some(matchesCountryCodeFn)
          );
        } else {
          service.getAddresses().then(function () {
            const { shippingAddresses } = service.addresses;
            resolve(
              verifyNoOtherCountry
                ? shippingAddresses.every(matchesCountryCodeFn)
                : shippingAddresses.some(matchesCountryCodeFn)
            );
          });
        }
      });
    };

    service.hasAllSenderAddressesInSingleCountry = function (countryAlpha2) {
      return service.getAddresses().then(function () {
        const { shippingAddresses } = service.addresses;
        return shippingAddresses.every(matchesCountryCode(countryAlpha2));
      });
    };

    service.isAddressInvalidForPickup = function (addressId) {
      if (!addressId) return false;
      const address = _.find(service.addresses.shippingAddresses, { id: addressId });

      return address && address.usps_pickup_validation_state !== 'validated';
    };

    function _doesAddressRequirePickupInstruction(addressId) {
      return service
        .queryById(addressId || '')
        .then(function (address) {
          return !address || service.isPickupInstructionAvailable(address.country_id);
        })
        .catch(function (reason) {
          console.error(reason);
          return false;
        });
    }

    service.convertFieldLengthToRegexPattern = function (fieldLength, charType) {
      return new RegExp(`^${charType || '.'}{${fieldLength[0]},${fieldLength[1]}}$`);
    };

    service.fieldValidators = {
      line_1: {
        key: 'line_1',
        models: ['line_1'],
        fieldLength: [1, 35],
        pattern: /^.{1,35}$/,
        validate(value) {
          return this.pattern.test(value);
        },
      },
      line_2: {
        key: 'line_2',
        models: ['line_2'],
        fieldLength: [0, 35],
        pattern: /^.{0,35}$/,
        validate(value) {
          return value === undefined || this.pattern.test(value);
        },
      },
      state_required: {
        key: 'state',
        models: ['state'],
        fieldLength: [1, 300],
        pattern: /^.{1,300}$/,
        validate(value) {
          return this.pattern.test(value);
        },
      },
      state_optional: {
        key: 'state',
        models: ['state'],
        fieldLength: [0, 200],
        pattern: /^.{0,200}$/,
        validate(value) {
          return this.pattern.test(value);
        },
      },
      city: {
        key: 'city',
        models: ['city'],
        fieldLength: [3, 300],
        pattern: /^.{3,300}$/,
        validate(value) {
          return this.pattern.test(value);
        },
      },
      pickup_instruction_slug: {
        key: 'pickup_instruction_slug',
        models: ['pickup_instruction_slug'],
        options: pickupInstructionOptions,
        validate(value) {
          return (
            value === null ||
            this.options.some(function (option) {
              return option.value === value;
            })
          );
        },
      },
      pickup_instruction_users_input: {
        key: 'pickup_instruction_users_input',
        models: ['pickup_instruction_users_input'],
        fieldLength: [1, 15],
        pattern: /^.{1,15}$/,
        validate(value) {
          return value === null || this.pattern.test(value);
        },
      },
      pickup_instruction_consistency: {
        key: 'pickup_instruction_consistency',
        models: ['pickup_instruction_slug', 'pickup_instruction_users_input'],
        validate(slug, usersInput) {
          if (slug === null || slug !== 'others') return usersInput === null;

          return typeof usersInput === 'string' && !!usersInput;
        },
      },
    };

    function _getPostalCodeFieldLength(countryId) {
      const fieldLengthKey = countryId in POSTAL_CODE_FIELD_LENGTHS ? countryId : 0;
      return POSTAL_CODE_FIELD_LENGTHS[fieldLengthKey];
    }

    function _getPostalCodeValidator(countryId, postalCodeRegex) {
      const fieldLength = _getPostalCodeFieldLength(countryId);
      const validator = {
        key: 'postal_code',
        models: ['postal_code'],
        fieldLength,
      };

      if (postalCodeRegex) {
        const pattern = new RegExp(postalCodeRegex);
        validator.pattern = pattern;
        validator.validate = function (value) {
          return pattern.test(value);
        };
      } else {
        validator.validate = function (value) {
          return value.length >= fieldLength[0] && value.length <= fieldLength[1];
        };
      }

      return validator;
    }

    function _findCountryPromise(countryId) {
      return $q(function (resolve) {
        resolve(CountryService.findCountry(countryId));
      });
    }

    service.getFieldValidators = function (countryId) {
      const fieldValidators = {
        line_1: service.fieldValidators.line_1,
        line_2: service.fieldValidators.line_2,
        city: service.fieldValidators.city,
      };

      return _findCountryPromise(countryId)
        .then(function (country) {
          if (!country) {
            return fieldValidators;
          }

          const { requirements } = country;
          if (requirements.has_postal_code) {
            fieldValidators.postal_code = _getPostalCodeValidator(
              countryId,
              requirements.postal_code_regex
            );
          }

          if (COUNTRIES_REQUIRE_STATE.indexOf(countryId) > -1) {
            fieldValidators.state = service.fieldValidators.state_required;
          } else {
            fieldValidators.state = service.fieldValidators.state_optional;
          }

          if (service.isPickupInstructionAvailable(countryId)) {
            fieldValidators.pickup_instruction_slug =
              service.fieldValidators.pickup_instruction_slug;
            fieldValidators.pickup_instruction_users_input =
              service.fieldValidators.pickup_instruction_users_input;
            fieldValidators.pickup_instruction_consistency =
              service.fieldValidators.pickup_instruction_consistency;
          }

          return fieldValidators;
        })
        .catch(function (reason) {
          console.error(reason);
        });
    };

    service.runFieldValidators = function (fieldValidators, address) {
      return !Object.keys(fieldValidators).some(function (fieldValidatorKey) {
        const fieldValidator = fieldValidators[fieldValidatorKey];
        const values = fieldValidator.models.map(function (model) {
          return address[model];
        });

        // eslint-disable-next-line prefer-spread
        return !fieldValidator.validate.apply(fieldValidator, values);
      });
    };

    service.checkForPickupInstructionErrorInPayloadOrigin = function (payloadOrigin) {
      return _doesAddressRequirePickupInstruction(payloadOrigin.pickup_address_id).then(function (
        isPickupInstructionAvailable
      ) {
        if (!isPickupInstructionAvailable) {
          return {
            pickup_instruction_slug: false,
            pickup_instruction_users_input: false,
          };
        }

        const hasPickupInstructionSlugError =
          !service.fieldValidators.pickup_instruction_slug.validate(
            payloadOrigin.pickup_instruction_slug
          );

        const hasPickupInstructionUsersInputError = !(
          service.fieldValidators.pickup_instruction_users_input.validate(
            payloadOrigin.pickup_instruction_users_input
          ) &&
          service.fieldValidators.pickup_instruction_consistency.validate(
            payloadOrigin.pickup_instruction_slug,
            payloadOrigin.pickup_instruction_users_input
          )
        );

        return {
          pickup_instruction_slug: hasPickupInstructionSlugError,
          pickup_instruction_users_input: hasPickupInstructionUsersInputError,
        };
      });
    };

    service.getDisplayedPickupInstructionName = function (
      pickupInstructionSlug,
      pickupInstructionUsersInput
    ) {
      const pickupInstructionOption =
        pickupInstructionOptions.find(function (option) {
          return option.value === pickupInstructionSlug;
        }) || defaultPickupInstructionOption;

      return pickupInstructionOption.value === 'others'
        ? pickupInstructionUsersInput
        : pickupInstructionOption.name;
    };

    service.getMixpanelPickupInstructionValue = function (
      pickupInstructionSlug,
      pickupInstructionUsersInput
    ) {
      return pickupInstructionSlug === 'others'
        ? `${pickupInstructionSlug}: ${pickupInstructionUsersInput}`
        : pickupInstructionSlug;
    };

    service.isPostalCodeOptional = function (countryId) {
      return [UAE_ID].indexOf(countryId) > -1;
    };

    service.shouldFetchCityState = function (countryId) {
      return COUNTRIES_TO_FETCH_CITY_STATE.indexOf(countryId) > -1;
    };

    service.hasCityOptions = function (countryId) {
      return COUNTRIES_WITH_CITY_OPTIONS.indexOf(countryId) > -1;
    };

    service.touchFormFields = function (form) {
      Object.keys(form).forEach(function (fieldName) {
        if (fieldName.startsWith('$') || !form[fieldName].$setTouched) return;

        form[fieldName].$setTouched();
      });
    };

    service.touchAllForms = function (forms) {
      Object.keys(forms).forEach(function (formKey) {
        if (formKey.startsWith('$')) return;

        service.touchFormFields(forms[formKey]);
      });
    };

    function _isBillingAddress(address) {
      return address.default_values && address.default_values.billing;
    }

    function buildAddressLineForArray(arr) {
      arr.forEach(function (elem) {
        elem.fullAddress = constructAddressSentence(elem);
      });

      return arr;
    }

    function _formatAddressToSave(address) {
      address.contact_phone = _formatContactPhone(address.contact_phone);

      // not included in addressy updates
      if (address.postal_code) {
        address.postal_code = _formatPostalCode(address.postal_code, address.country_id);
      }

      return address;
    }

    function _formatContactPhone(phone) {
      phone = phone || '';

      // Remove space . and -
      return phone.replace(/[.\-\s]+/g, '');
    }

    function _formatPostalCode(postalCode, countryId) {
      const postalCodeDefault = service.isPostalCodeOptional(countryId) ? '' : '0';

      return postalCode || postalCodeDefault;
    }

    function constructAddressSentence(address) {
      return _.filter([
        address.line_1,
        address.line_2,
        address.city,
        address.postal_code,
        address.state,
        address.country.name,
      ]).join(', ');
    }
  }

  angular
    .module('app.service.AddressService', [
      'app.factory.Address',
      'app.factory.UserSession',
      'app.factory.GoogleAnalytics',
      'app.service.createNewAddressModal',
      'app.factory.HkDistrict',
    ])
    .service('AddressService', AddressService);
})();
