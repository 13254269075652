import angular from 'angular';

import { StatesResource } from './states.resource';
import { StatesService } from './states.service';

const MODULE_NAME = 'app.global.services.states';

angular
  .module(MODULE_NAME, ['ngResource'])
  .service('StatesResource', StatesResource)
  .service('StatesService', StatesService);

export default MODULE_NAME;
