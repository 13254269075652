import angular from 'angular';
import '../../buttons/button';
import '../input';

import { DatepickerComponent } from './datepicker.component';
import { DatepickerRun } from './datepicker.run';

angular
  .module('core.components.datepicker', [
    'ui.bootstrap.module.datepicker',
    'ui.bootstrap.module.datepickerPopup',
  ])
  .component('easyshipDatepicker', DatepickerComponent)
  .run(DatepickerRun);
