import logoDarkUrl from '@assets/images/logos/easyship-logo/logo-dark.svg';
import uberFreightUrl from '@assets/images/logos/platform-logo-full/uber-freight.svg';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').controller('AuthCtrl', AuthCtrl);

  AuthCtrl.$inject = ['$scope', 'API', '$location'];
  function AuthCtrl($scope, API, $location) {
    $scope.API = API;
    $scope.logoDarkUrl = logoDarkUrl;
    $scope.uberFreightUrl = uberFreightUrl;

    (function init() {
      $scope.isUberFreight = $location.path().split('/')[1] === 'uber-freight';
    })();

    setTimeout(function () {
      var el = document.getElementById('app__initial-load');
      if (el) el.parentNode.removeChild(el);
    });

    $scope.$on('$locationChangeSuccess', function () {
      $scope.isUberFreight = $location.path().split('/')[1] === 'uber-freight';
    });
  }
})();
