class DiscountRateService {
  static $inject = ['StoreService'];

  constructor(StoreService) {
    this.StoreService = StoreService;
  }

  /**
   * Set null for falsy value except 0
   * @param {*} value any
   */
  getPriceValue(value) {
    return value || value === 0 ? value : null;
  }

  updateShippingDiscount(id, updatedShippingDiscount) {
    this.StoreService.activeStore.shipping_discounts =
      this.StoreService.activeStore.shipping_discounts.map((shippingDiscount) => {
        if (shippingDiscount.id === id) {
          shippingDiscount = updatedShippingDiscount;
        }

        return shippingDiscount;
      });
  }

  deleteShippingDiscount(id) {
    this.StoreService.activeStore.shipping_discounts =
      this.StoreService.activeStore.shipping_discounts.filter((shippingDiscount) => {
        return shippingDiscount.id !== id;
      });
  }
}

export { DiscountRateService };
