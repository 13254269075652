import { IComponentController, IOnChangesObject } from 'angular';

import { IInputGroupRadioModel } from 'typings/form';
import { IGroupedArray } from 'typings/helper';
import { IAdvancedSearchMultiselectObject } from 'typings/advanced-search';
import { IncotermsScopeValue } from '@client/src/global/services/shipment-list/data/normalize';
import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';

import template from './incoterms-multi-select-group.html?raw';
import style from './incoterms-multi-select-group.module.scss';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class IncotermsMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchMultiselectObject>
  implements IComponentController
{
  style = style;
  defaultFilters: IGroupedArray<IAdvancedSearchMultiselectObject>[] = [];
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions = [];

  static $inject = ['$scope', '$translate'];
  constructor($scope: ng.IScope, private $translate: angular.translate.ITranslateService) {
    super($scope);
  }

  $onInit(): void {
    this.assignDefaultRadioModel();

    const noun = this.$translate.instant('global.incoterms');
    this.radioModel = [
      {
        title: this.$translate.instant('global.relation.prefixed.are_any_of', { noun }),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: this.$translate.instant('global.relation.prefixed.are_none_of', { noun }),
        value: MultiselectComponentModelValue.NoneOf,
      },
    ];
  }

  $onChanges(changes: IOnChangesObject): void {
    this.triggerChangeIfKnowOrUnknowModel(changes);

    if (this.defaultFilters.length === 0) {
      const $t = (key: string) => {
        return this.$translate.instant(`global.${key}`);
      };

      this.defaultFilters = [
        {
          display: $t('all'),
          value: '',
          options: [
            {
              display: $t('pre-paid-sender'),
              value: IncotermsScopeValue.PrePaid,
              isDisplay: true,
            },
            {
              display: $t('post-paid-receiver'),
              value: IncotermsScopeValue.PostPaid,
              isDisplay: true,
            },
            {
              display: $t('does-not-apply'),
              value: IncotermsScopeValue.DoesNotApply,
              isDisplay: true,
            },
          ],
        },
      ];
    }
    this.assignDefaultSelectValue(this.esModelOptions || this.defaultFilters);
  }
}

const IncotermsMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: IncotermsMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { IncotermsMultiSelectGroupComponent };
