import angular from 'angular';
import angularInview from 'angular-inview';

import './basic';
import './flat-rates-modal';
import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import shipmentListComponents from '@client/src/global/components/shipment-list';
import bulkEditModal from './bulk-edit-modal';

import { createShipmentRoutes } from './index.routes';

import { MergeAllModalMainComponent } from './merge-all-modal/merge-all-modal-main/merge-all-modal-main.component';
import { MergeSelectedModalMainComponent } from './merge-selected-modal/merge-selected-modal-main/merge-selected-modal-main.component';
import { MergeSelectedDifferentDestinationComponent } from './merge-selected-modal/merge-selected-different-destination/merge-selected-different-destination.component';
import { MergeSelectedShipmentComponent } from './merge-selected-modal/merge-selected-shipment/merge-selected-shipment.component';
import { MergeSelectedSameDestinationComponent } from './merge-selected-modal/merge-selected-same-destination/merge-selected-same-destination.component';
import { SplitModalMainComponent } from './split-modal/split-modal-main/split-modal-main.component';
import { SplitModalItemComponent } from './split-modal/split-modal-item/split-modal-item.component';
import { AddShipmentsComponent } from './add-shipments/add-shipments.component';
import { ResendEmailsModalMainComponent } from './resend-emails-modal/resend-emails-modal-main/resend-emails-modal-main.component';
import { MergeAllModal } from './merge-all-modal/merge-all-modal.service';
import { MergeSelectedModal } from './merge-selected-modal/merge-selected-modal.service';
import { SplitModal } from './split-modal/split-modal.service';
import { CreateShipmentModal } from './create-shipment-modal/create-shipment-modal.service';
import { CreateShipmentModalMainComponent } from './create-shipment-modal/create-shipment-modal-main/create-shipment-modal-main.component';
import { ReceiverInformationComponent } from './create-shipment-modal/create-shipment-modal-main/receiver-information/receiver-information.component';
import { PackageInformationComponent } from './create-shipment-modal/create-shipment-modal-main/package-information/package-information.component';
import { ResidentialCoverageCardComponent } from './add-on/residential-coverage-card/residential-coverage-card.component';
import { InsuranceCardComponent } from './add-on/insurance-card/insurance-card.component';
import { CourierInformationComponent } from './create-shipment-modal/create-shipment-modal-main/courier-information/courier-information.component';
import { TagsShipmentDropdownComponent } from './tags-shipment-dropdown/tags-shipment-dropdown.component';
import { CreateShipmentsAdvancedComponent } from './advanced/create-shipments-advanced.component';
import { AngularShipmentDetailsDrawer } from './shipment-details-drawer';
import { AngularEditorVersionSelector } from './editor-version-selector';
import { ResendEmailsModal } from './resend-emails-modal/resend-emails-modal.service';

const MODULE_NAME = 'app.create-shipments';

angular
  .module(MODULE_NAME, [
    angularInview,
    'core.components',
    'core.config',
    'app.global.dashboard-component-library',
    'core.service.mixpanel',
    'app.create-shipments.basic',
    'app.create-shipments.flat-rates-modal',
    'app.service.ShipmentAction',
    'app.service.ShipmentCache',
    'app.service.ShipmentList',
    'app.service.EndpointService',
    'ui.router',
    'app.filter.to-comma-list',
    onboardingChecklistService,
    shipmentListComponents,
    bulkEditModal,
  ])
  .component('mergeAllModalMain', MergeAllModalMainComponent)
  .component('mergeSelectedModalMain', MergeSelectedModalMainComponent)
  .component('mergeSelectedDifferentDestination', MergeSelectedDifferentDestinationComponent)
  .component('mergeSelectedShipment', MergeSelectedShipmentComponent)
  .component('mergeSelectedSameDestination', MergeSelectedSameDestinationComponent)
  .component('splitModalMain', SplitModalMainComponent)
  .component('splitModalItem', SplitModalItemComponent)
  .component('addShipments', AddShipmentsComponent)
  .component('createShipmentModalMain', CreateShipmentModalMainComponent)
  .component('receiverInformation', ReceiverInformationComponent)
  .component('packageInformation', PackageInformationComponent)
  .component('esCourierInformation', CourierInformationComponent)
  .component('residentialCoverageCard', ResidentialCoverageCardComponent)
  .component('esInsuranceCard', InsuranceCardComponent)
  .component('esTagsShipmentDropdown', TagsShipmentDropdownComponent)
  .component('createShipmentsAdvanced', CreateShipmentsAdvancedComponent)
  .component('esShipmentDetailsDrawer', AngularShipmentDetailsDrawer)
  .component('esEditorVersionSelector', AngularEditorVersionSelector)
  .component('resendEmailsModalMain', ResendEmailsModalMainComponent)
  .service('MergeAllModal', MergeAllModal)
  .service('MergeSelectedModal', MergeSelectedModal)
  .service('SplitModal', SplitModal)
  .service('CreateShipmentModal', CreateShipmentModal)
  .service('ResendEmailsModal', ResendEmailsModal)
  .config(createShipmentRoutes);

export default MODULE_NAME;
