FlatRateBoxResource.$inject = ['$resource', 'API'];
function FlatRateBoxResource($resource, API) {
  const endpointUrl = API.endpoint;

  return $resource(
    `${endpointUrl}/companies/:company_id/flat_rate_boxes`,
    {
      company_id: '@_company_id',
    },
    {
      query: { method: 'GET' },
      update: { method: 'PATCH' },
    }
  );
}

export { FlatRateBoxResource };
