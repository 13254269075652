import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import COLORS from '@client/src/colors';
import { TrackingQuality as TrackingQualityValue } from '@client/core/corelogic/models/TrackingQuality';

const TRACKING_QUALITY_COLOR_MAP = {
  [TrackingQualityValue.Excellent]: COLORS.greenNormal,
  [TrackingQualityValue.Regular]: COLORS.blueNormal,
  [TrackingQualityValue.Basic]: COLORS.yellowNormal,
  [TrackingQualityValue.Limited]: COLORS.redNormal,
  [TrackingQualityValue.None]: COLORS.inkNormal,
};

export interface TrackingQualityProps {
  value: TrackingQualityValue;
  orientation?: 'horizontal' | 'vertical';
  size?: 'small' | 'regular' | 'thin';
  title?: 'short' | 'long';
  className?: string;
}

export function TrackingQuality({
  value,
  orientation = 'vertical',
  size = 'regular',
  title = 'short',
  className,
}: TrackingQualityProps) {
  function getIndexColor(index: number) {
    return index > value ? COLORS.skyNormal : TRACKING_QUALITY_COLOR_MAP[value];
  }

  const indicatorSize = getSize(size);
  const isHorizontal = orientation === 'horizontal';
  return (
    <Grid
      container
      justifyContent={isHorizontal ? undefined : 'center'}
      alignItems={isHorizontal ? 'center' : undefined}
      wrap={isHorizontal ? 'nowrap' : 'wrap'}
      className={className}
      direction={isHorizontal ? 'row' : 'column'}
      spacing={1}
    >
      <Grid
        item
        container
        justifyContent={isHorizontal ? undefined : 'center'}
        style={{ width: 'initial' }}
        wrap="nowrap"
      >
        {[...Array(4)].map((_, index) => (
          <Grid
            item
            key={index}
            style={{ padding: 3, paddingLeft: index === 0 && isHorizontal ? 0 : undefined }}
          >
            <Box
              width={indicatorSize.width}
              height={indicatorSize.height}
              borderRadius={8}
              bgcolor={getIndexColor(index)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container justifyContent={isHorizontal ? 'flex-start' : 'center'}>
        <FormattedMessage
          id={`shipments.list.tracking.${getTrackingQualityKey(value)}.label${
            title === 'long' ? '-long' : ''
          }`}
        />
      </Grid>
    </Grid>
  );
}

function getSize(size: TrackingQualityProps['size']) {
  switch (size) {
    case 'regular':
      return { width: 9, height: 16 };
    case 'small':
      return { width: 6, height: 12 };
    case 'thin':
      return { width: 4, height: 14 };
    default:
      throw new Error('Unknown size argument');
  }
}

const TRACKING_QUALITY_TO_KEY = {
  [TrackingQualityValue.Excellent]: 'excellent',
  [TrackingQualityValue.Regular]: 'regular',
  [TrackingQualityValue.Basic]: 'basic',
  [TrackingQualityValue.Limited]: 'limited',
  [TrackingQualityValue.None]: 'none',
};

export function getTrackingQualityKey(trackingQuality: TrackingQualityValue) {
  return TRACKING_QUALITY_TO_KEY[trackingQuality];
}
