import { IUserSession } from 'typings/user-session';
import { IShipmentListItem, IShipmentTransactionRecord } from 'typings/shipment';
import { IComponentController } from 'angular';
import template from './shipment-costs.html?raw';
import style from './shipment-costs.module.scss';

interface IFees {
  label?: string;
  translationKey?: string;
  value: number | undefined;
}

class ShipmentCosts implements IComponentController {
  esShipment: IShipmentListItem | null = null;
  validTransactions: IShipmentTransactionRecord[] = [];

  style = style;
  fees: IFees[] = [];
  taxes: IFees[] = [];
  chargeWithoutTax = 0;
  totalSalesTax = 0;
  companyCurrency = '';
  showCustomerSelection = false;

  static $inject = ['UserSession', '$filter', '$translate'];
  constructor(
    private UserSession: IUserSession,
    private $filter: ng.IFilterFunction,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit() {
    this.fees = this.getFees();
    this.taxes = this.getTaxes();
    this.chargeWithoutTax = this.fees.reduce((agg, cur) => agg + (cur.value || 0), 0);
    this.totalSalesTax = this.taxes.reduce((agg, cur) => agg + (cur.value || 0), 0);
    this.companyCurrency = this.UserSession.getCompanyCurrency();
    this.validTransactions =
      this.esShipment?.transaction_records.filter((shipment) => shipment.adjustment_type) || [];
    this.showCustomerSelection =
      Object.keys(this.esShipment?.buyer_selected_courier_data || {}).length > 0;
  }

  getCustomerAmountAtCheckoutText(): string {
    const formatCurrency = (currency: string) =>
      new Intl.NumberFormat(this.UserSession.user.dashboard_settings?.language?.code || 'en', {
        style: 'currency',
        currency,
      }).format;
    const currency = this.esShipment?.buyer_selected_courier_data?.currency || this.companyCurrency;
    const shippingCost = Number(this.esShipment?.buyer_selected_courier_data?.shipping_cost);
    const label = this.$translate.instant(
      'shipment-info.shipment-costs.fees.customer-paid-checkout'
    );

    if (currency !== this.companyCurrency) {
      const amount = ['AUD', 'CAD', 'USD'].includes(currency)
        ? formatCurrency(currency)(shippingCost)
        : this.$filter('intlCurrency')(shippingCost, currency);
      return `${label} (${amount})`;
    }

    return label;
  }

  getTotalTransactions(type: string) {
    if (!this.esShipment?.transaction_records) return 0;

    return this.esShipment.transaction_records
      .filter((transaction) => {
        return transaction.payment_recipient === type;
      })
      .reduce((accumulator, transaction) => {
        accumulator -= parseFloat(transaction.transaction_amount);

        return accumulator;
      }, 0);
  }

  private getFees() {
    if (!this.esShipment) return [];
    const returnShipmentCostKey =
      this.esShipment.payment_recipient === 'EasyshipPayOnScan'
        ? 'estimated-return-shipment-cost'
        : 'return-shipment-cost';
    const shipmentChargeLabel = this.esShipment.is_return ? returnShipmentCostKey : 'shipment-cost';
    const otherSurcharges: { label: string; value: number }[] = (
      this.esShipment.other_surcharges?.details || []
    ).map(({ name, fee }) => ({ label: name, value: fee }));

    return [
      {
        translationKey: shipmentChargeLabel,
        value: this.esShipment.shipment_charge,
      },
      {
        translationKey: 'fuel-surcharge',
        value: this.esShipment.fuel_surcharge,
      },
      {
        translationKey: 'remote-area-surcharge',
        value: this.esShipment.remote_area_surcharge,
      },
      {
        translationKey: 'import-tax',
        value: this.esShipment.import_tax_charge,
      },
      {
        translationKey: 'import-duty',
        value: this.esShipment.import_duty_charge,
      },
      {
        translationKey: 'ddp-handling-fee',
        value: this.esShipment.ddp_handling_fee,
      },
      {
        translationKey: 'warehouse-handling-fee',
        value: this.esShipment.warehouse_handling_fee,
      },
      {
        translationKey: 'additional-surcharge',
        value: this.esShipment.additional_services_surcharge,
      },
      {
        translationKey: 'residential-surcharge',
        value: this.esShipment.residential_fee_applied,
      },
      {
        translationKey: 'oversized-surcharge',
        value: this.esShipment.oversized_surcharge,
      },
      {
        translationKey: 'insurance-fee',
        value: this.esShipment.insurance_fee,
      },
      ...otherSurcharges,
    ];
  }

  private getTaxes() {
    const taxes: IFees[] = [];

    if (!this.esShipment) return taxes;

    const {
      sales_tax: salesTax,
      provincial_sales_tax: provincialSalesTax,
      sales_tax_name: salesTaxName,
      provincial_sales_tax_name: provincialSalesTaxName,
    } = this.esShipment;

    if (salesTaxName) {
      taxes.push({ label: salesTaxName, value: salesTax || 0 });
    }

    if (provincialSalesTaxName) {
      taxes.push({ label: provincialSalesTaxName, value: provincialSalesTax || 0 });
    }

    return taxes;
  }
}

const ShipmentCostsComponent: ng.IComponentOptions = {
  controller: ShipmentCosts,
  template,
  bindings: {
    esShipment: '<',
  },
};

export { ShipmentCostsComponent };
