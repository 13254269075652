(function () {
  'use strict';

  var COMPANY_NAME_DEFAULT_MIN_LENGTH = 3;
  var COMPANY_NAME_DEFAULT_MAX_LENGTH = 27;

  function addressFormContactInfoCrtl() {
    var ctrl = this;

    ctrl.fieldLengths = {
      contact_name: {
        pattern: [0, 22],
        valid: true,
      },
      contact_email: {
        pattern: [0, 50],
        valid: true,
      },
      contact_phone: {
        pattern: [0, 20],
        valid: true,
      },
      company_name: {
        pattern: [COMPANY_NAME_DEFAULT_MIN_LENGTH, COMPANY_NAME_DEFAULT_MAX_LENGTH],
        valid: true,
      },
    };

    ctrl.companyNamePattern =
      '.{' + COMPANY_NAME_DEFAULT_MIN_LENGTH + ',' + COMPANY_NAME_DEFAULT_MAX_LENGTH + '}';

    ctrl.$onInit = function () {
      // Contact form is never empty (prefilled if new address)
      if (ctrl.address) runAllFormLengthValidations();

      if (ctrl.companyNameMaxLength) {
        ctrl.fieldLengths.company_name.pattern = [
          COMPANY_NAME_DEFAULT_MIN_LENGTH,
          ctrl.companyNameMaxLength,
        ];
        ctrl.companyNamePattern =
          '.{' + COMPANY_NAME_DEFAULT_MIN_LENGTH + ',' + ctrl.companyNameMaxLength + '}';
      }
    };

    ctrl.showInputErrorMessage = function (field) {
      ctrl.fieldLengths[field].valid =
        !ctrl.address[field] || ctrl.address[field].length <= ctrl.fieldLengths[field].pattern[1];
    };

    function runAllFormLengthValidations() {
      var fieldLengthsKeys = Object.keys(ctrl.fieldLengths);

      _.each(fieldLengthsKeys, function (fieldName) {
        ctrl.showInputErrorMessage(fieldName);
      });
    }
  }

  addressFormContactInfoCrtl.$inject = [];

  angular
    .module('app.component.address-form-contact-info')
    .controller('addressFormContactInfoCrtl', addressFormContactInfoCrtl);
})();
