class ReturnService {
  static $inject = ['ReturnResource', 'AddressService', 'UserSession'];

  constructor(ReturnResource, AddressService, UserSession) {
    this.ReturnResource = ReturnResource;
    this.AddressService = AddressService;
    this.UserSession = UserSession;

    this.step = ''; // 'select' | 'pay' | 'success' | 'failed'
    this.payload = {};
    this.data = {};
  }

  initReturnFlow(outboundShipment) {
    const companyId = this.UserSession.company?.easyship_company_id;
    /** Amex hardcoded + testing id */
    const props = ['CTW110065', 'CUS589129', 'CUS574932'].includes(companyId)
      ? {
          defaultType: 'return',
          id: outboundShipment?.origin_address?.id,
        }
      : 'return';
    return this.AddressService.getDefaultAddress(props).then((returnAddress) => {
      const preparePayload = this._buildPayload(returnAddress, outboundShipment);
      return this.calculateRates(outboundShipment.id, preparePayload).then((rateCalcResponse) => {
        this.payload = this._buildPayload(returnAddress, outboundShipment, rateCalcResponse.rates);
        this.goToEditShipmentStep();
      });
    });
  }

  calculateRates(shipmentId, payload) {
    return this.ReturnResource.calculate(
      {
        company_id: this.UserSession.company.id,
        id: shipmentId,
      },
      payload
    )
      .$promise.then((data) => {
        // Store data state in service
        this.data = data;

        // Check if there are some error messages from the rates
        this.ratesErrorMessages = this._priceComparisonHasMessages(data)
          ? data.price_comparison.messages
          : [];

        // Preselect courier
        const rates = data.price_comparison && data.price_comparison.rates;
        this.payload.courier_id = this._getFirstCourierId(rates);

        return { rates, ratesErrorMessages: this.ratesErrorMessages };
      })
      .catch((err) => {
        throw err;
      });
  }

  prepare(shipmentId, payload) {
    return this.ReturnResource.prepare(
      { company_id: this.UserSession.company.id, id: shipmentId },
      payload
    )
      .$promise.then((data) => {
        this.paymentData = data;
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  confirm(shipmentId, payload, token) {
    return this.ReturnResource.confirm(
      { company_id: this.UserSession.company.id, id: shipmentId },
      this._buildConfirmPayload(payload, token)
    )
      .$promise.then((data) => {
        this.successData = data;

        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  sendEmail(shipmentId, payload) {
    return this.ReturnResource.sendEmail(
      { company_id: this.UserSession.company.id, id: shipmentId },
      payload
    )
      .$promise.then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  goToEditShipmentStep(payload) {
    if (payload) this.payload = payload;
    this.step = 'select';
  }

  goToPayReturnStep(payload) {
    if (payload) this.payload = payload;
    this.step = 'pay';
  }

  goToSuccessStep(data) {
    if (data) this.successData = data;
    this.step = 'success';
  }

  goToFailedStep(data) {
    if (data) this.failedData = data;
    this.step = 'failed';
  }

  _priceComparisonHasMessages(data) {
    return (
      data.price_comparison &&
      data.price_comparison.messages &&
      data.price_comparison.messages.length > 0
    );
  }

  _buildPayload(address = {}, shipment = { box: {} }, rates) {
    const payload = {
      address_id: address.id,
      actual_weight: shipment.total_actual_weight,
      display_weight: shipment.total_actual_weight,
      display_weight_unit: this.UserSession.company.weight_unit === 'kg' ? 'kg' : 'lb',
      length: shipment.parcels_attributes[0].package_data
        ? shipment.parcels_attributes[0].package_data.length
        : null,
      width: shipment.parcels_attributes[0].package_data
        ? shipment.parcels_attributes[0].package_data.width
        : null,
      height: shipment.parcels_attributes[0].package_data
        ? shipment.parcels_attributes[0].package_data.height
        : null,
      courier_id: this._getFirstCourierId(rates),
      origin_address: {
        contact_name: shipment.destination_name,
        company_name: shipment.destination_company_name,
        contact_email: shipment.email_address,
        contact_phone: shipment.phone_number,
        country_id: shipment.destination_country_id,
        line_1: shipment.address_line_1,
        line_2: shipment.address_line_2,
        city: shipment.city,
        postal_code: shipment.postal_code,
        state: shipment.state,
      },
    };

    return payload;
  }

  _buildConfirmPayload(payload, token) {
    let confirmPayload;
    const total = _.get(this.paymentData, 'totals.total_to_top_up');

    if (!total) return payload;

    if (token) {
      confirmPayload = {
        ...payload,
        payment: this._setPaymentParams(token, total),
      };
    } else {
      confirmPayload = payload;
    }

    return confirmPayload;
  }

  _getFirstCourierId(rates) {
    return rates && rates.length > 0 && rates[0].courier_id;
  }

  /**
   * _setPaymentParams: build payment object with necessary Stripe infos
   * @param {string} token The payment source ID
   * @return payment object
   */
  _setPaymentParams(token, totalToPay) {
    return {
      token,
      total: totalToPay,
      currency: this.UserSession.company.currency,
      description: `${this.UserSession.company.currency} ${totalToPay}`,
      platform: 'Stripe',
    };
  }
}

export { ReturnService };
