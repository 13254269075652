import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';

import OptionButton from '@client/core/components/react/OptionButton';

import { ChurnReason, DialogType } from './typings';

interface ReasonsListProps {
  reasons: ChurnReason[];
  subheader: string;
  origin: DialogType;
  onSelect: (selection: { reason: ChurnReason; index: number }) => void;
}

export default function ReasonsList({ reasons, subheader, origin, onSelect }: ReasonsListProps) {
  const reasonsImages: Record<DialogType, string> = {
    'close-account': '',
    'downgrade-plan': new URL(
      '../../../../assets/images/dashboard/churn-reasons/downgrade-plan.svg',
      import.meta.url
    ).href,
    'inactive-account': new URL(
      '../../../../assets/images/dashboard/churn-reasons/inactive-account.svg',
      import.meta.url
    ).href,
  };

  return (
    <>
      {reasonsImages[origin] && (
        <img style={{ display: 'flex', margin: '0 auto 25px' }} src={reasonsImages[origin]} />
      )}

      {subheader && (
        <Typography variant="body2" style={{ marginBottom: '25px' }}>
          {subheader}
        </Typography>
      )}

      {origin === 'downgrade-plan' && (
        <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '20px' }}>
          <FormattedMessage id="churn-reasons-flow.downgrade-plan.im-downgrading-because" />
        </Typography>
      )}

      {reasons.map((reason, zeroBasedIndex) => (
        <OptionButton
          key={reason}
          onClick={() => {
            onSelect({ reason, index: zeroBasedIndex + 1 });
          }}
        >
          <FormattedMessage id={`churn-reasons-flow.${origin}.reasons.${reason}`} />
        </OptionButton>
      ))}
    </>
  );
}
