import throttle from 'lodash.throttle';

class DeviceService {
  static $inject = ['$window', '$timeout'];

  constructor($window, $timeout) {
    this.$window = $window;
    this.$timeout = $timeout;
  }

  deviceResizeListener() {
    return throttle(this.updateDeviceType.bind(this))();
  }

  updateDeviceType() {
    this.$timeout(() => {
      if (window.matchMedia('only screen and (min-width: 1025px)').matches) {
        this.device = 'desktop';
      } else if (window.matchMedia('only screen and (min-width: 768px)').matches) {
        this.device = 'tablet';
      } else {
        this.device = 'mobile';
      }
    });
  }

  isDesktop() {
    return this.device === 'desktop';
  }

  isTablet() {
    return this.device === 'tablet';
  }

  isMobile() {
    return this.device === 'mobile';
  }

  isMacintosh() {
    return this.$window.navigator.userAgent.includes('Macintosh');
  }

  isMobileOs() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      this.$window.navigator.userAgent
    );
  }

  mount() {
    this.updateDeviceType();
    this.$window.addEventListener('resize', this.deviceResizeListener.bind(this));
  }

  destroy() {
    this.$window.removeEventListener('resize', this.deviceResizeListener);
  }
}

export { DeviceService };
