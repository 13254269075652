import template from './courier-card.html?raw';
import style from './courier-card.module.scss';

class CourierCard {
  static $inject = ['UserSession', 'SubscriptionService'];

  constructor(UserSession, SubscriptionService) {
    this.style = style;
    this.SubscriptionService = SubscriptionService;

    this.countryId = UserSession.company.country_id;
  }

  async $onInit() {
    if (this.name) return;
  }

  isPlanBadgeVisible(featureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }
}

const CourierCardComponent = {
  controller: CourierCard,
  template,
  bindings: {
    logoUrl: '<',
    name: '<',
    nickname: '<',
    flagAlpha2: '<',
    services: '<',
    pillColor: '@',
    pillText: '@',
    ngDisabled: '<',
    onClick: '&',
    isMaxCourier: '<',
  },
};

export { CourierCardComponent };
