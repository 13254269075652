import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { Button } from 'easyship-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { react2angular } from 'react2angular';
import { UpsOauthGatewayProvider } from '../../providers/UpsOauthGatewayProvider';
import {
  CourierConnectResultContent,
  CourierConnectResultContentProps,
} from '../CourierConnectResultContent';

interface ConnectSuccessComponentProps
  extends Omit<CourierConnectResultContentProps, 'isSuccess' | 'errorMessage'> {
  umbrellaName: string;
  onClose: () => void;
}

// This is to be used in angular and to be inserted into CourierCreateMain
// Since CourierCreate has already provided modal window functionality no need to wrap it into Modal component
function ConnectSuccessComponent({
  nickname,
  umbrellaName,
  onClose,
}: ConnectSuccessComponentProps) {
  return (
    <div>
      <header className="flex items-start justify-between gap-3 p-5 pl-12 border-b border-b-sky-300">
        <div className="flex items-center w-full gap-3">
          <div className="text-xl break-words text-ink-900">
            <FormattedMessage
              id="courier.connect.success.connected"
              values={{ courier: umbrellaName }}
            />
          </div>
        </div>
      </header>
      <CourierConnectResultContent nickname={nickname} isSuccess />
      <footer className="flex justify-center py-5 border-t border-t-sky-300">
        <Button color="default" className="px-10" type="button" onClick={onClose}>
          <FormattedMessage id="global.got-it" />
        </Button>
      </footer>
    </div>
  );
}

export function WrappedConnectSuccessComponent(props: ConnectSuccessComponentProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <UpsOauthGatewayProvider>
          <ConnectSuccessComponent {...props} />
        </UpsOauthGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularConnectSuccessComponent = react2angular(WrappedConnectSuccessComponent, [
  'nickname',
  'umbrellaName',
  'onClose',
]);
