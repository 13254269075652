import template from './dashboard-single-flow-steps.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('singleFlowSteps', singleFlowSteps);

  singleFlowSteps.$inject = ['$state', 'UserSession'];
  function singleFlowSteps($state, UserSession) {
    return {
      template,
      restrict: 'E',
      link: function (scope, element, attrs) {
        scope.state = $state;
        scope.UserSession = UserSession;

        var states = [
          'receiver-info',
          'add-shipment',
          'choose-courier',
          'your-info',
          'order-summary',
          'payment-success',
        ];

        scope.isStatePassed = function (stateName) {
          if (states.indexOf(stateName) < states.indexOf($state.current.name)) {
            return true;
          } else {
            return false;
          }
        };

        scope.isStatePassedOrEqual = function (stateName) {
          if (states.indexOf(stateName) <= states.indexOf($state.current.name)) {
            return true;
          } else {
            return false;
          }
        };
      },
    };
  }
})();
