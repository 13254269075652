import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

(function () {
  angular.module('easyshipDashboardApp').controller('deletePromptModalCtrl', deletePromptModalCtrl);

  deletePromptModalCtrl.$inject = [
    '$scope',
    '$timeout',
    '$translate',
    'ShipmentListAdvancedService',
    'MixpanelService',
  ];

  function deletePromptModalCtrl(
    $scope,
    $timeout,
    $translate,
    ShipmentListAdvancedService,
    MixpanelService
  ) {
    $scope.busy = false;

    $scope.confirmDeleteShipments = function () {
      $scope.busy = true;
      ShipmentListAdvancedService.deleteConfirm()
        .then(function (response) {
          // Need to add timeout for shipment reindex (Elastic search)
          $timeout(function () {
            $scope.busy = false;
            $scope.$close();
            toastSuccess(
              $translate.instant(
                'toast.delete-count',
                {
                  count: response.shipment_ids.length,
                  noun: $translate
                    .instant(
                      'global.pluralize.shipment',
                      { COUNT: response.shipment_ids.length },
                      'messageformat'
                    )
                    .toLowerCase(),
                },
                'messageformat'
              )
            );

            ShipmentListAdvancedService.resetShipmentActionsConfirm();
            ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter('orders_all');
          }, 1000);
        })
        .catch(function () {
          $scope.busy = false;
          toastError(
            $translate.instant('toast.delete-error', {
              noun: $translate
                .instant(
                  'global.pluralize.shipment',
                  { COUNT: ShipmentListAdvancedService.totals.orders_count },
                  'messageformat'
                )
                .toLowerCase(),
            })
          );
        });
    };

    $scope.totals = function () {
      if (ShipmentListAdvancedService.totals) {
        return ShipmentListAdvancedService.totals.orders_count;
      }

      return 0;
    };

    $scope.isSelectedAll = function () {
      return ShipmentListAdvancedService.isSelectedAll;
    };

    $scope.excludedIds = function () {
      return ShipmentListAdvancedService.excludedIds;
    };

    $scope.selectedIds = function () {
      return ShipmentListAdvancedService.selectedIds;
    };
  }
})();
