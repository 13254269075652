import angular from 'angular';

import esConnectCourierForms from './forms';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier';

angular
  .module(MODULE_NAME, [esConnectCourierForms]);

export default MODULE_NAME;
