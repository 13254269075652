class BoxBaseService {
  static $inject = ['$translate'];

  constructor($translate) {
    this.$translate = $translate;
  }

  showInnerDimensions(box) {
    return (
      box.outer_length !== box.inner_length ||
      box.outer_width !== box.inner_width ||
      box.outer_height !== box.inner_height
    );
  }

  getUnitsDisplayText(box) {
    if (!this.showInnerDimensions(box)) {
      return this._getDimensionDisplayText(box.outer_length, box.outer_width, box.outer_height);
    }
    const outerDims = this._getDimensionDisplayText(
      box.outer_length,
      box.outer_width,
      box.outer_height
    );
    const innerDims = this._getDimensionDisplayText(
      box.inner_length,
      box.inner_width,
      box.inner_height
    );

    return this.$translate.instant(
      'shipments.filter.box.inner-outer',
      {
        outer: outerDims,
        inner: innerDims,
      },
      'messageformat'
    );
  }

  _getDimensionDisplayText(length, width, height) {
    const unit = this.UserSession.isCompanyUsingImperialDimensions() ? 'inch' : 'centimeter';
    const locale =
      (this.UserSession.user.dashboard_settings &&
        this.UserSession.user.dashboard_settings.language &&
        this.UserSession.user.dashboard_settings.language.code) ||
      'en';
    const heightWithDimension = new Intl.NumberFormat(locale, { style: 'unit', unit }).format(
      height
    );

    return `${length} x ${width} x ${heightWithDimension}`;
  }
}

export { BoxBaseService };
