import { IComponentController } from 'angular';
import { ISubscriptionObject } from 'typings/subscription';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './modal.html?raw';
import style from './modal.module.scss';

class WelcomeEnterprisePlanModal implements IComponentController {
  style = style;

  static $inject = ['SubscriptionService'];
  constructor(private SubscriptionService: SubscriptionService) {
    this.closeEnterpriseModal = this.closeEnterpriseModal.bind(this);
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get showWelcomeEnterpriseModal(): boolean {
    return this.SubscriptionService.showWelcomeEnterpriseModal;
  }

  closeEnterpriseModal(): void {
    this.SubscriptionService.showWelcomeEnterpriseModal = false;
  }
}

const WelcomeEnterprisePlanModalComponent: ng.IComponentOptions = {
  controller: WelcomeEnterprisePlanModal,
  template,
  bindings: {},
};

export { WelcomeEnterprisePlanModalComponent };
