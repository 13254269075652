import angular from 'angular';

import { BillingOptionsComponent } from './billing-options.component';

const MODULE_NAME = 'app.create-shipments.basic.billing-options';

angular
  .module(MODULE_NAME, [])
  .component('esBillingOptions', BillingOptionsComponent);

export default MODULE_NAME;
