import { IApiConfig } from 'typings/core/config/api';

interface IWebsiteGeolocationResponse {
  continentCode: string;
  country: string;
  countryCode: string;
  status: string;
}

class WebsiteApiService {
  static $inject = ['$http', 'API'];
  constructor(private $http: ng.IHttpService, private API: IApiConfig) {}

  getIpGeolocation(): ng.IHttpPromise<IWebsiteGeolocationResponse> {
    return this.$http.get(`${this.API.website_api}/geolocation`, { cache: true });
  }
}

export { WebsiteApiService };
