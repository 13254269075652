import React, { ReactElement } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
// import { useFormContext, Controller } from 'react-hook-form';
import Box from '@material-ui/core/Box';
// import Grid from '@material-ui/core/Grid';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from '@client/core/components/react/Alert';
// import Input from '@client/core/components/react/Input';
import COLORS from '@client/src/colors';
import { IProductListingProducts } from 'typings/dashboard/services/product-listing';

// import { ProductFormData } from '../ProductDetailsDrawer';

interface ProductSummaryProps {
  product: IProductListingProducts;
  classes: {
    platformLogo: string;
  };
  onStoreSettingsClick: () => void;
}

export default function ProductSummary({
  product,
  classes,
  onStoreSettingsClick,
}: ProductSummaryProps): ReactElement {
  // const { control } = useFormContext<ProductFormData>();
  const { formatMessage, formatList } = useIntl();
  const plaformSlug = product.store?.css_class || 'directsales';
  const imgSrc = `https://assets.easyship.com/app/platform-logos/${plaformSlug}-mini.svg`;
  const missingProperties = [];

  if (!(product.category.id || product.hs_code) && product.fallback_category?.id) {
    missingProperties.push(formatMessage({ id: 'global.category' }));
  }
  if (!product.length || !product.width || (!product.height && product.fallback_length)) {
    missingProperties.push(formatMessage({ id: 'global.dimensions' }));
  }
  if (!product.weight && product.fallback_weight) {
    missingProperties.push(formatMessage({ id: 'global.weight' }));
  }

  return (
    <>
      {/* <Controller
        render={({ field, fieldState }) => (
          <Input
            label={
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" component="span">
                    <FormattedMessage id="product-listing.details.name" />
                  </Typography>
                  <Tooltip title={formatMessage({ id: 'product-listing.details.name-tooltip' })}>
                    <i
                      style={{ color: COLORS.inkLight, lineHeight: 'inherit', marginLeft: 3 }}
                      className="icon-info"
                    />
                  </Tooltip>
                </Grid>

                <Grid item>
                  {product.updated_at && (
                    <Typography variant="subtitle2" color="textSecondary">
                      <FormattedMessage id="global.last-updated" values={{ timestamp: formatDate(product.updated_at, {year: 'numeric', month: 'long', day: '2-digit'}) }} />
                    </Typography>
                  )}
                </Grid>
              </Grid>
            }
            {...field}
            error={!!fieldState.error}
            maxLength={50}
          />
        )}
        control={control}
        rules={{ maxLength: 50 }}
        name="name"
      /> */}
      <Box mt={1}>
        <img src={imgSrc} className={classes.platformLogo} />
        <Typography component="span" variant="body2" color="textSecondary">
          {product.store?.name}
          {product.platform_product_id ? ` - ${product.platform_product_id}` : null}
        </Typography>
      </Box>
      {missingProperties.length > 0 && (
        <Box mt={1}>
          <Alert>
            <FormattedMessage
              id="product-listing.details.fallback-alert"
              values={{
                properties: formatList(missingProperties, { type: 'conjunction' }).toLowerCase(),
                a: (chunks: string) => (
                  <span
                    onClick={onStoreSettingsClick}
                    style={{ color: COLORS.blueNormal, cursor: 'pointer' }}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
          </Alert>
        </Box>
      )}
    </>
  );
}
