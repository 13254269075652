import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './add-remove-countries.html?raw';
import style from './add-remove-countries.module.scss';

class AddRemoveCountriesController {
  static $inject = ['$translate', 'StoreService', 'MixpanelService'];

  constructor($translate, StoreService, MixpanelService) {
    this.$translate = $translate;
    this.StoreService = StoreService;
    this.MixpanelService = MixpanelService;

    this.style = style;
    this.loading = false;
  }

  $onInit() {
    this.countries = [...this.StoreService.activeStore.excluded_destination_country_alpha2s];
  }

  updateCountries(value) {
    this.countries = value;
  }

  onBack() {
    this.close();
  }

  onSubmit() {
    const activeStore = {
      ...this.StoreService.activeStore,
      excluded_destination_country_alpha2s: this.countries,
    };

    this.loading = true;

    this.MixpanelService.track(`Store - Update Sync Settings`, { setting: 'Countries' });

    this.StoreService.updateStore(activeStore)
      .then(() => {
        this.StoreService.activeStore.excluded_destination_country_alpha2s = this.countries;
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.$translate.instant('global.setting').toLowerCase() },
            'messageformat'
          )
        );
        this.close();
      })
      .catch(() => {
        this.loading = false;
        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.$translate.instant('global.setting').toLowerCase(),
          })
        );
      });
  }
}

const AddRemoveCountriesComponent = {
  template,
  controller: AddRemoveCountriesController,
  bindings: {
    close: '&',
  },
};

export { AddRemoveCountriesComponent };
