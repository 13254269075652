(function () {
  'use strict';

  angular.module('easyshipDashboardApp').controller('UberFreightLoginCtrl', UberFreightLoginCtrl);

  UberFreightLoginCtrl.$inject = [
    '$scope',
    '$state',
    '$rootScope',
    '$window',
    'AUTH_EVENTS',
    'MixpanelService',
  ];

  function UberFreightLoginCtrl($scope, $state, $rootScope, $window, AUTH_EVENTS, MixpanelService) {
    var vm = this;
    var ignoredRoutes = ['home.auth.password-new'];

    (function init() {
      var spinnerElement = document.getElementById('app__initial-load');
      if (spinnerElement) spinnerElement.style.display = 'none';
      resetState();
    })();

    function resetState() {
      if (ignoredRoutes.indexOf($rootScope.returnToState) !== -1) {
        $rootScope.returnToState = null;
        $rootScope.returnToParams = null;
      }
    }

    $scope.$on(AUTH_EVENTS.loginSuccess, function () {
      $window.localStorage.setItem('isUberFreight', 'true');
      MixpanelService.track('Login Page - Uber Freight - Logged in');
      if ($rootScope.returnToState) {
        $state.go($rootScope.returnToState, $rootScope.returnToParams);
        $rootScope.returnToState = null;
        $rootScope.returnToParams = null;
      } else {
        $state.go('app.home');
      }
    });

    $scope.$on(AUTH_EVENTS.fullLoginSuccess, function () {
      $window.localStorage.setItem('isUberFreight', 'true');
      MixpanelService.track('Login Page - Uber Freight - Logged in');
      if ($rootScope.returnToState) {
        $state.go($rootScope.returnToState, $rootScope.returnToParams);

        $rootScope.returnToState = null;
        $rootScope.returnToParams = null;
      } else {
        $state.go('app.home');
      }
    });
  }
})();
