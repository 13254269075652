import { IComponentController } from 'angular';
import template from './input-select.html?raw';
import style from './input-select.module.scss';

const DEFAULT_INPUT_WIDTH = 100;
const DEFAULT_SELECT_WIDTH = 80;

class InputSelect implements IComponentController {
  style = style;
  esType = '';
  esMin = '';
  esSelectValueKey = '';
  esInputWidth = DEFAULT_INPUT_WIDTH;
  esSelectWidth = DEFAULT_SELECT_WIDTH;

  $onInit() {
    if (this.esInputWidth === undefined) this.esInputWidth = DEFAULT_INPUT_WIDTH;
    if (this.esSelectWidth === undefined) this.esSelectWidth = DEFAULT_SELECT_WIDTH;
    if (this.esType === undefined) this.esType = 'text';
    if (this.esMin === undefined) this.esMin = '';
    if (this.esSelectValueKey === undefined) this.esSelectValueKey = '';
  }
}

const InputSelectComponent: ng.IComponentOptions = {
  controller: InputSelect,
  template,
  bindings: {
    esId: '<',
    esModelInput: '<',
    esModelInputOptions: '<',
    esChangeInput: '&',
    esType: '@',
    esError: '<',
    esDisabled: '<',
    esRequired: '<',
    esPrepend: '<',
    esModelSelect: '<',
    esChangeSelect: '&',
    esOptions: '<',
    esSelectValueKey: '@',
    esInputWidth: '<',
    esSelectWidth: '<',
    esNumberValidation: '<',
    esMin: '@',
    esSelectLabel: '<',
    esInputLabel: '<',
    esPlaceholder: '@',
  },
};

export { InputSelectComponent };
