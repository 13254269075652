import { IAddress, IDraftAddress, IAddressService } from 'typings/address';
import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import template from './address-correction-modal.html?raw';

class AddressCorrectionModalController implements IComponentController {
  confirmed = false;
  ignoringValidation = false;
  selectedIndex = 0;
  esInputAddress?: IDraftAddress;
  esCorrectedAddresses?: IAddress[];
  esAddressToConfirm?: IAddress;
  esUspsPickupValidity?: string;
  esConfirmAddress?: (changes: { address: IAddress | IDraftAddress }) => void;

  static $inject = ['AddressService', '$translate'];
  constructor(
    private AddressService: IAddressService,
    private $translate: angular.translate.ITranslateService
  ) {}

  selectCorrection(index: number): void {
    this.selectedIndex = index;
  }

  closeModal(): void {
    // confirms address as-is
    this.confirmed = true;
  }

  rejectCorrection(): void {
    if (!this.esConfirmAddress || !this.esInputAddress) return;

    this.esConfirmAddress({ address: this.esInputAddress });
    this.confirmed = true;
  }

  confirm(): void {
    if (
      this.esConfirmAddress &&
      this.esCorrectedAddresses?.length &&
      this.selectedIndex !== undefined
    ) {
      this.esConfirmAddress({ address: this.esCorrectedAddresses[this.selectedIndex] });
    } else if (this.isUspsPickupInvalid && this.esAddressToConfirm) {
      this.AddressService.validateAddress(this.esAddressToConfirm).catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
    }

    this.confirmed = true;
  }

  get open(): boolean {
    return !!((this.esCorrectedAddresses?.length || this.isUspsPickupInvalid) && !this.confirmed);
  }

  get concatenatedAddresses(): string[] {
    if (!this.esCorrectedAddresses) return [];

    return this.esCorrectedAddresses.map((address) => {
      const { line_1: line1, line_2: line2, city, postal_code: postalCode, state } = address;
      const addressFields = `${line1 || ''} ${line2 || ''} ${city || ''} ${postalCode || ''} ${
        state || ''
      }`;

      return ` ${addressFields}`.replace(/\s{2,}/g, ' ');
    });
  }

  get isUspsPickupInvalid() {
    return this.esUspsPickupValidity === 'invalid';
  }

  // NOTE: Disabled the USPS pickup validation until further notice, see https://easyship.slack.com/archives/GAQDCBD6G/p1645690867997669
  // get undeterminedUspsPickupValidity(): boolean {
  //   return (
  //     !!this.esUspsPickupValidity &&
  //     this.esUspsPickupValidity !== 'invalid' &&
  //     this.esUspsPickupValidity !== 'valid'
  //   );
  // }
}

const AddressCorrectionModalComponent: ng.IComponentOptions = {
  controller: AddressCorrectionModalController,
  template,
  bindings: {
    esMessage: '<',
    esInputAddress: '<',
    esCorrectedAddresses: '<',
    esUspsPickupValidity: '<',
    esPostalCodeChanged: '<',
    esConfirmAddress: '&',
    esAddressToConfirm: '<',
  },
};

export { AddressCorrectionModalComponent };
