import { toastError } from '@client/core/components/react/Toastify';

class AnalyticsHelpers {
  static $inject = ['$state', 'CourierAccounts', 'PlatformService', 'UserSession', '$translate'];

  constructor($state, CourierAccounts, PlatformService, UserSession, $translate) {
    this.$state = $state;
    this.CourierAccounts = CourierAccounts;
    this.PlatformService = PlatformService;
    this.selectedDate = {};
    this.UserSession = UserSession;
    this.$translate = $translate;
  }

  goToFilter(field, value, selectedDate) {
    let query = {};

    switch (field) {
      case 'country':
        query = { destination_country_id: value.country_id };
        break;
      case 'courier':
        query = { courier_id: value.courier_id };
        break;
      case 'salesChannel':
        query = value.store_id ? { store_id: value.store_id } : { platform_name: value.name };
        break;
      default:
        break;
    }

    this.goToShipmentsPage(query, selectedDate);
  }

  goToShipmentsPage(query, selectedDate) {
    const date = this.sanitizeDateParams(selectedDate);

    const queryWithDate = Object.assign(query, {
      label_paid_at_from: date && date.from_date,
      label_paid_at_to: date && date.to_date,
    });

    if (!this.UserSession.hasUserRole('manage_shipments')) {
      toastError(this.$translate.instant('global.page-access-disabled'));
      return;
    }
    this.$state.go('app.shipments', { page: 1, query: queryWithDate }, { reload: true });
  }

  /**
   * [sanitizeDateParams] Manipulate date params before sending to backend
   * @param  {Object} dateParams : contains startDate and endDate
   * @return {Object} dateParams ready to be sent (same format)
   */
  sanitizeDateParams(dateParams) {
    // Avoid mutation that is causing UI and API value discrepancy
    const cloneDateParams = angular.copy(dateParams);

    this.dateRangeIsToday = false;
    this.dateRangeIsSameDay = false;
    let endDate;

    // To fix one of the library's flaw, need to manually add 1 day if the user wants to say 'today'
    // (library will not set a date in the future because next dates are disabled)
    if (cloneDateParams.endDate.isSame(moment(), 'day')) {
      endDate = cloneDateParams.endDate.set({ hours: 24, minutes: 0, seconds: 0 });
      this.endDateIsToday = true;
    } else {
      endDate = cloneDateParams.endDate;
      this.endDateIsToday = false;
    }

    this.startDateIsToday = cloneDateParams.startDate.isSame(moment(), 'day');

    if (this.endDateIsToday && this.startDateIsToday) {
      this.dateRangeIsSameDay = true;
      this.dateRangeIsToday = true;
    } else if (
      moment(cloneDateParams.startDate).isSame(cloneDateParams.endDate, 'day') &&
      moment(cloneDateParams.startDate).isSame(cloneDateParams.endDate, 'month') &&
      moment(cloneDateParams.startDate).isSame(cloneDateParams.endDate, 'year')
    ) {
      this.dateRangeIsSameDay = true;
    }

    return {
      from_date: dateParams.startDate,
      to_date: endDate.subtract(1, 'days').milliseconds(0),
    };
  }

  getLogoName(name, isCourier) {
    if (name) {
      const formattedName = name.split(' ')[0].toLowerCase();

      if (
        isCourier &&
        this.CourierAccounts.easyshipCouriers &&
        this.CourierAccounts.easyshipCouriers.length > 0
      ) {
        const courierName = this.CourierAccounts.easyshipCouriers.some((courier) => {
          return courier.logo_url === formattedName;
        });

        return courierName ? formattedName : null;
      }

      return formattedName;
    }

    return null;
  }
}

export { AnalyticsHelpers };
