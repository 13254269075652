import angular from 'angular';

import { ShipmentListResource } from './shipment-list.resource';
import { ShipmentListAdvancedService } from './shipment-list-advanced.service';
import { ShipmentListManageService } from './shipment-list-manage.service';

const MODULE_NAME = 'app.global.services.shipment-list';

angular
  .module(MODULE_NAME, [
    'ngResource',
    'core.config',
    'app.factory.UserSession',
    'app.service.BatchService',
    'app.service.CountryService',
    'app.service.CourierService',
    'app.service.PlatformService',
    'app.service.ShipmentAction',
    'app.service.StoreService',
    'app.global.export',
    'app.global.helper',
    'app.global.services.printing-options',
    'app.global.services.shipment-views',
    'app.global.services.advanced-search-component-api-normalize',
    'app.global.status-message',
    'app.global.reschedule-pickup',
  ])
  .service('ShipmentListResource', ShipmentListResource)
  .service('ShipmentListAdvancedService', ShipmentListAdvancedService)
  .service('ShipmentListManageService', ShipmentListManageService);

export default MODULE_NAME;
