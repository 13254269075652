import { IStore } from 'typings/store';
import { IReportService } from 'typings/report';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import template from './add-products-modal-actions.html?raw';
import style from './add-products-modal-actions.module.scss';
import { ProductListingService } from '../../product-listing.service';

class AddProductsModalActions implements IComponentController {
  style = style;
  esStore?: IStore;

  static $inject = [
    'ProductListingService',
    'ReportService',
    'MixpanelService',
    'AppCuesService',
    'UserRightsService',
  ];
  constructor(
    private ProductListingService: ProductListingService,
    private ReportService: IReportService,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService,
    private UserRightsService: UserRightsService
  ) {}

  syncAuthorized(store: IStore): boolean {
    return store.auth_state !== 'auth_failed';
  }

  syncFromStore(): void {
    if (!this.esStore?.platform?.actions?.products_sync) return;

    const payload = {
      store_id: this.esStore.id,
    };

    this.ProductListingService.loading.syncingStore = true;
    this.ProductListingService.hideModal();

    const callback = (failed?: boolean) => {
      if (failed) {
        this.ProductListingService.loading.syncingStore = false;
        return;
      }

      this.ProductListingService.getProductList().finally(() => {
        this.ProductListingService.loading.syncingStore = false;

        this.MixpanelService.track('Products - Create', { method: 'Sync' });

        if (this.ProductListingService.products.length > 0) {
          this.AppCuesService.track('Products | Product created', null, true);
        }
      });
    };

    this.ProductListingService.syncStore(payload)
      .then(({ report_id }) => {
        this.ReportService.watchReportStatus(report_id, 'product-listing.sync.status', callback);
      })
      .catch(() => {
        this.ProductListingService.loading.syncingStore = false;
      });
  }

  trackReconnectDetails(): void {
    this.MixpanelService.track('Disconnected Store - See Details - Banner', {
      source: 'Add Products',
      store_id: this.esStore?.id,
      name: this.esStore?.name,
      platform: this.esStore?.platform?.name,
    });
  }

  get isSyncDisabled(): boolean {
    return (
      !this.UserRightsService.canCreateProduct ||
      !this.esStore?.platform?.actions?.products_sync ||
      !this.syncAuthorized(this.esStore)
    );
  }
}

const AddProductsModalActionsComponent: ng.IComponentOptions = {
  controller: AddProductsModalActions,
  template,
  bindings: {
    esStore: '<',
    esOnManualInputSelect: '&',
    esOnImportSelect: '&',
    esOnBack: '&',
  },
};

export { AddProductsModalActionsComponent };
