import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import ProgressIndicator from '@client/core/components/react/ProgressIndicator';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface EditShipmentMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onEditShipment: () => Promise<void>;
}

export default function EditShipmentMenuItem({
  divider = false,
  shipmentListItem,
  onEditShipment,
}: EditShipmentMenuItemProps) {
  const [isEditShipmentLoading, setIsEditShipmentLoading] = useState(false);
  const { last_status_message: lastStatusMessage } = shipmentListItem;

  async function handleEditShipment() {
    setIsEditShipmentLoading(true);
    await onEditShipment();
    setIsEditShipmentLoading(false);
  }

  if (lastStatusMessage?.id !== 120) {
    return null;
  }

  return (
    <ProgressIndicator show={isEditShipmentLoading}>
      <MenuItem divider={divider} onClick={handleEditShipment} disabled={isEditShipmentLoading}>
        <div style={baseStyleIconBox}>
          <i className="icon-pencil text-lg" style={{ color: baseStyleIcon.colorGrey }} />
        </div>
        <span className="text-base">
          <FormattedMessage id="shipments.speed-dial.edit-shipment" />
        </span>
      </MenuItem>
    </ProgressIndicator>
  );
}
