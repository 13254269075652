import React from 'react';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import COLORS from '@client/src/colors';
import { FormattedMessage } from 'react-intl';

export type LabelFlagOptions = 'optional' | 'suggested' | 'required';

const translationIdMapper = {
  optional: 'no_need',
  suggested: 'suggested',
  required: 'mandatory',
};

export interface FormLabelProps extends Omit<FormControlLabelProps, 'control'> {
  flag?: LabelFlagOptions;
  helperIcon?: React.ReactElement;
  children: JSX.Element;
}

export default function FormLabel({
  label,
  flag,
  helperIcon,
  children,
  ...props
}: FormLabelProps): JSX.Element {
  props.labelPlacement = props.labelPlacement ?? 'top';

  return label ? (
    <FormControlLabel
      {...props}
      label={
        <Grid container alignItems="center">
          <span>
            {label}{' '}
            {flag && (
              <span
                style={{
                  color: COLORS.inkLight,
                  whiteSpace: 'pre',
                  fontSize: '12px',
                }}
              >
                (<FormattedMessage id={`tax-id-input.require.${translationIdMapper[flag]}`} />)
              </span>
            )}
          </span>
          {helperIcon && (
            <Box
              ml={1}
              height={16}
              display="inline-flex"
              style={{ cursor: 'default' }}
              alignItems="center"
              component="span"
            >
              {helperIcon}
            </Box>
          )}
        </Grid>
      }
      control={children}
    />
  ) : (
    children
  );
}
