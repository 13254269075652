import checkoutSuccessImageUrl from '@assets/images/dashboard/single-store/checkout-success.svg';

(function () {
  'use strict';

  function incartSuccessModalCtrl($scope, $state, $stateParams, $window, MixpanelService) {
    var vm = this;
    vm.checkoutSuccessImageUrl = checkoutSuccessImageUrl;

    vm.platformName = '';

    vm.openLink = function () {
      var link = '';

      switch (vm.platformName) {
        case 'Shopline':
          link =
            'https://support.easyship.com/hc/en-us/articles/115003453151-Shipping-Rates-at-Checkout-Feature';
          break;
        case 'Shopify':
          link =
            'https://support.easyship.com/hc/en-us/articles/360019789212-Shipping-Zones-Checkout-Rates-Shopify';
          break;
      }
      $window.open(link, '_blank');
    };

    vm.close = function () {
      $window.localStorage.setItem('scroll-to-shipping-at-checkout', 'true');

      $state.go(
        'app.single-store',
        { id: $stateParams.id, platform: null },
        { location: 'replace' }
      );
      $scope.$close();
    };

    (function init() {
      vm.platformName =
        $stateParams.platform.charAt(0).toUpperCase() + $stateParams.platform.slice(1);
      MixpanelService.track('Checkout Success modal opened');
    })();
  }

  incartSuccessModalCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$window',
    'MixpanelService',
  ];

  angular
    .module('app.service.incart-installation-modal')
    .controller('incartSuccessModalCtrl', incartSuccessModalCtrl);
})();
