import angular from 'angular';

import { SelectAddressComponent } from './select-address.component';

const MODULE_NAME = 'core.components.select-address';

angular
  .module(MODULE_NAME, ['pascalprecht.translate', 'app.service.AddressService'])
  .component('esSelectAddress', SelectAddressComponent);

export default MODULE_NAME;
