import angular from 'angular';

const MODULE_NAME = 'app.filter.trust-as-html';

trustAsHtml.$inject = ['$sce'];
function trustAsHtml($sce: ng.ISCEService) {
  return (value: string): HTMLElement => {
    return $sce.trustAsHtml(value);
  };
}

angular.module(MODULE_NAME, []).filter('trustAsHtml', trustAsHtml);

export default MODULE_NAME;
