import { IUserSession } from 'typings/user-session';
import { IUserService } from 'typings/user-service';
import {
  IShipmentListScope,
  IShipmentListCustomViewsItem,
  IShipmentListCustomViewsPayload,
  SortDirection,
  SortByAttribute,
} from 'typings/shipment';
import { IShipmentListFiltersData } from '@client/src/global/services/shipment-list/shipment-list-normalize.service';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';

import { IComponentController } from 'angular';
import { ShipmentViewsService } from '@client/src/global/services/shipment-views/shipment-views.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './view-name.module.scss';
import template from './view-name.html?raw';

enum ShipmentViewModal {
  CustomView = 'custom-view',
  DeleteView = 'delete-view',
}

class ViewName implements IComponentController {
  style = style;
  activeModal: ShipmentViewModal | null = null;
  esPageContext: IShipmentListScope | null = null;
  deleteViewId: string | null = null;
  modifyView: IShipmentListCustomViewsItem | null = null;
  isDropdownOpen = false;
  esSelectedView: IShipmentListCustomViewsItem | null = null;
  esIsDifferent = false;
  esFilter: IShipmentListFiltersData | null = null;
  esKeyword: string | null = null;
  esLimit: number | null = null;
  esSortBy: SortByAttribute | null = null;
  esSortDirection: SortDirection | null = null;
  esCustomViews: IShipmentListCustomViewsItem[] | null = null;

  static $inject = [
    '$translate',
    'ShipmentViewsService',
    'ShipmentListAdvancedService',
    'UserService',
    'UserSession',
    'UserRightsService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private ShipmentViewsService: ShipmentViewsService,
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private UserService: IUserService,
    private UserSession: IUserSession,
    private UserRightsService: UserRightsService
  ) {}

  esOnSelectView(_data: { viewId?: string }): void {
    // esOnSelectView expression bindings, need to add this in order for typescript to successfully compile
  }

  esOnSaveSuccess(_data?: { view: IShipmentListCustomViewsItem }): void {
    // esOnSaveSuccess expression bindings, need to add this in order for typescript to successfully compile
  }

  esOnDeleteView(_data: { id: string }): void {
    // esOnDeleteView expression bindings, need to add this in order for typescript to successfully compile
  }

  esOnUpdateSuccess(_data?: { view: IShipmentListCustomViewsItem }): void {
    // esOnUpdateSuccess expression bindings, need to add this in order for typescript to successfully compile
  }

  onSaveViewSelect(): void {
    this.modifyView = null;
    this.showSaveViewModal();
  }

  onModifyView(id: string): void {
    if (this.esCustomViews) {
      const customView = this.esCustomViews.find((view) => view.id === id);
      if (customView) {
        this.modifyView = customView;
        this.showSaveViewModal();
      }
    }
  }

  onDeleteViewSelect(id: string): void {
    if (id) {
      this.deleteViewId = id;
      this.activeModal = ShipmentViewModal.DeleteView;
    }
  }

  onSelectView(viewId?: string): void {
    this.isDropdownOpen = false;
    this.esOnSelectView({
      viewId,
    });
  }

  onSaveSuccess(view?: IShipmentListCustomViewsItem): void {
    this.hideModal();
    if (view) {
      this.esOnSaveSuccess({ view });
    } else {
      this.esOnSaveSuccess();
    }
  }

  onDeleteView(id: string): void {
    this.hideModal();
    this.esOnDeleteView({ id });
  }

  hideModal(): void {
    this.activeModal = null;
  }

  onUpdateSelectedView(): void {
    if (!this.esIsDifferent || this.isUpdateFilterViewDisabled) return;

    if (this.esSelectedView && this.esPageContext) {
      const payload: IShipmentListCustomViewsPayload = {
        name: this.esSelectedView.name,
        page_context: this.esPageContext,
        items_per_page: this.esLimit || 10,
      };

      if (this.esFilter) {
        payload.filters = this.esFilter;
      } else {
        payload.filters = {};
      }

      if (this.esSortBy) {
        let direction: SortDirection = 'desc';
        if (this.ShipmentListAdvancedService.isReverseSortOrder(this.esSortBy)) {
          direction = 'asc';
        }
        payload.sort_criterias = {
          [this.esSortBy]: this.esSortDirection ? this.esSortDirection : direction,
        };
      } else {
        payload.sort_criterias = {};
      }

      payload.keyword = this.esKeyword;

      this.ShipmentViewsService.updateCustomViews(this.esSelectedView.id, payload).then((res) => {
        if (res) {
          this.isDropdownOpen = false;
          this.esOnUpdateSuccess({
            view: res,
          });
          return;
        }

        if (this.esSelectedView) {
          this.isDropdownOpen = false;
          this.esOnUpdateSuccess({
            view: {
              ...this.esSelectedView,
              ...payload,
            },
          });
        }
      });
    }
  }

  onDropdownToggle(isOpen: boolean): void {
    this.isDropdownOpen = isOpen;
  }

  onResetDefaultView(): void {
    const userDefaultViews = this.UserSession.getDefaultViews();

    this.UserService.update({
      dashboard_settings: {
        default_views: {
          ...userDefaultViews,
          [this.esPageContext as IShipmentListScope]: null,
        },
      },
    })
      .then((res) => {
        this.isDropdownOpen = false;
        this.UserSession.update('user', res.user);
        this.onSelectView();
        toastSuccess(this.$translate.instant('shipment-views.notifications.reset'));
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  get isCustomViewModalActive(): boolean {
    return this.activeModal === ShipmentViewModal.CustomView;
  }

  get isDeleViewModalActive(): boolean {
    return this.activeModal === ShipmentViewModal.DeleteView;
  }

  get canAddLayout(): boolean {
    return this.esIsDifferent;
  }

  get canSaveLayout(): boolean {
    return !this.esSelectedView?.id.includes('default_');
  }

  get defaultView(): string | null | undefined {
    const userDefaultViews = this.UserSession.getDefaultViews();

    return userDefaultViews[this.esPageContext as IShipmentListScope];
  }

  get isUpdateFilterViewDisabled(): boolean {
    return !this.UserRightsService.canSaveFilterView;
  }

  private showSaveViewModal(): void {
    this.activeModal = ShipmentViewModal.CustomView;
  }
}

const ViewNameComponent: ng.IComponentOptions = {
  controller: ViewName,
  template,
  bindings: {
    esPageContext: '<',
    esCustomViews: '<',
    esDefaultViews: '<',
    esSelectedView: '<',
    esIsDifferent: '<',
    esFilter: '<',
    esKeyword: '<',
    esLimit: '<',
    esSortBy: '<',
    esSortDirection: '<',
    esOnSelectView: '&',
    esOnSaveSuccess: '&',
    esOnDeleteView: '&',
    esOnUpdateSuccess: '&',
  },
};

export { ViewNameComponent };
