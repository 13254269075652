import angular from 'angular';

import { TableComponent } from './table.component';
import { TableBodyComponent } from './table-body/table-body.component';
import { TableDataComponent } from './table-data/table-data.component';
import { TableHeadComponent } from './table-head/table-head.component';
import { TableRowComponent } from './table-row/table-row.component';

angular
  .module('core.components.table', [])
  .component('easyshipTable', TableComponent)
  .component('easyshipTableRow', TableRowComponent)
  .component('easyshipTableData', TableDataComponent)
  .component('easyshipTableBody', TableBodyComponent)
  .component('easyshipTableHead', TableHeadComponent);
