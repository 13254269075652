import { IPickupService } from 'typings/pickup';
import { IGroupedArray } from 'typings/helper';
import { IInputGroupRadioModel } from 'typings/form';
import { IAdvancedSearchPickupObject } from 'typings/advanced-search';

import {
  MultiselectComponentModelValue,
  MultiselectAttributes,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';
import style from './pickups-multi-select-group.module.scss';
import template from './pickups-multi-select-group.html?raw';

class PickupsMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchPickupObject>
  implements IComponentController
{
  style = style;
  busy = false;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchPickupObject>[] = [];

  static $inject = ['$scope', '$translate', 'PickupService'];
  constructor(
    $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private PickupService: IPickupService
  ) {
    super($scope);
    this.fetchPickups = this.fetchPickups.bind(this);
  }

  $onInit(): void {
    this.assignDefaultRadioModel();

    const noun = this.$translate.instant('global.pickup');
    const $t = (key: string) => {
      return this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    };
    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];
  }

  $onChanges(changes: any): void {
    this.onChangesAssignAndTrigger(
      changes,
      MultiselectAttributes.DisplayableDescription,
      this.fetchPickups
    );
  }

  private fetchPickups(): void {
    this.busy = true;
    this.PickupService.getCompanyPickups({ scope: 'pickup_only' }, { status: 'in_progress' })
      .then((pickups) => {
        this.esModelOptions = this.PickupService.initFilter(pickups);
        this.watchAndTrigger(MultiselectAttributes.DisplayableDescription);
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
        this.esModelOptions = [];
      })
      .finally(() => {
        this.busy = false;
      });
  }
}

const PickupsMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: PickupsMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { PickupsMultiSelectGroupComponent };
