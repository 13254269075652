import { ShipmentTax } from '@client/core/corelogic/models/Shipment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTax from './useTax';

interface TaxProps {
  tax: ShipmentTax;
  fontSize?: string;
}

export default function Tax({ tax, fontSize = 'xs' }: TaxProps) {
  const { taxName } = useTax({ tax });

  if (!taxName) {
    return null;
  }

  return (
    <div className={`text-${fontSize} leading-5`}>
      <FormattedMessage id="shipments.panel-header.include-tax" values={{ name: taxName }} />
    </div>
  );
}
