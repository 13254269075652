import { IClaim, IUpdateClaimPayload, ISubmitClaimPayload } from 'typings/insurance';
import { ClaimStep } from '@client/data/insurance';
import { IShipmentListItem } from 'typings/shipment';
import { IComponentController, IFilterFunction } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError } from '@client/core/components/react/Toastify';
import { ModalService } from '../modals.service';
import style from './insurance-claim.module.scss';
import template from './insurance-claim.html?raw';
import { InsuranceResource } from '../../services/insurance/insurance.resource';

class InsuranceClaim implements IComponentController {
  claimFlowStep = ClaimStep.Select;
  shipment: IShipmentListItem;
  style = style;
  busy = false;
  claimSteps = ClaimStep;

  static $inject = [
    'InsuranceResource',
    'MixpanelService',
    'ModalService',
    'showShipmentModal',
    '$filter',
    '$translate',
  ];
  constructor(
    private InsuranceResource: InsuranceResource,
    private MixpanelService: MixpanelService,
    private ModalService: ModalService,
    private showShipmentModal: any,
    private $filter: IFilterFunction,
    private $translate: angular.translate.ITranslateService
  ) {
    this.submitClaim = this.submitClaim.bind(this);
    this.shipment = this.ModalService.params;
  }

  private successActions(response: { claim: IClaim }, step: ClaimStep) {
    this.shipment.claim = response.claim;
    this.claimFlowStep = step;
    this.busy = false;
  }

  get isBeforeFilingWindowOpens(): boolean {
    const filingWindowOpensDate = new Date(this.shipment.claim_filing_window_opens || '');

    return filingWindowOpensDate.valueOf() > Date.now();
  }

  triggerStep(step: ClaimStep) {
    if (step === ClaimStep.Lost || step === ClaimStep.Damaged) {
      if (step === ClaimStep.Lost && this.isBeforeFilingWindowOpens) {
        const oneDayInMiliseconds = 24 * 60 * 60 * 1000;
        const daysUntilFilingWindowOpens = Math.floor(
          Math.abs(new Date(this.shipment.claim_filing_window_opens || '').valueOf() - Date.now()) /
            oneDayInMiliseconds
        ).toFixed(0);
        const date = this.$filter('intlDate')(this.shipment.claim_filing_window_opens);
        toastError(
          this.$translate.instant(
            'insurance-claim.before-filing-window-note',
            { daysUntilFilingWindowOpens, date },
            'messageformat'
          )
        );
      } else {
        this.busy = true;

        if (this.shipment?.claim?.id) {
          this.updateClaim({ claim_type: step, id: this.shipment.claim.id });
        } else {
          this.createClaim(step);
        }
      }
    } else {
      this.claimFlowStep = step;
    }

    this.MixpanelService.track(`Insurance Claim Flow - Trigger ${step} step`);
  }

  private createClaim(claim_type: ClaimStep) {
    this.InsuranceResource.createClaim({ policy_id: this.shipment.policy.id }, { claim_type })
      .then((response) => {
        this.successActions(response, claim_type);
      })
      .catch((error) => {
        toastError(error.data.error);
      })
      .finally(() => {
        this.busy = false;
      });
  }

  private updateClaim(payload: IUpdateClaimPayload) {
    this.InsuranceResource.updateClaim({ policy_id: this.shipment.policy.id }, payload)
      .then((response) => {
        this.successActions(response, payload.claim_type);
      })
      .catch((error) => {
        toastError(error.data.error);
      })
      .finally(() => {
        this.busy = false;
      });
  }

  submitClaim(payload: ISubmitClaimPayload) {
    this.busy = true;

    // eslint-disable-next-line no-param-reassign
    payload.selected_shipment_items = payload.selected_shipment_items?.filter(
      (item) => item.quantity > 0
    );

    this.InsuranceResource.submitClaim({ policy_id: this.shipment.policy.id }, payload)
      .then((response) => {
        this.successActions(response, ClaimStep.Success);
      })
      .catch((error) => {
        toastError(error.data.error);
      })
      .finally(() => {
        this.busy = false;
      });
  }

  close(): void {
    this.ModalService.close();
    this.MixpanelService.track('Insurance Claim - Close Modal');
  }

  goToShipmentDetails() {
    this.close();
    this.showShipmentModal.open(this.shipment.id, null);
  }
}

const InsuranceClaimComponent: ng.IComponentOptions = {
  controller: InsuranceClaim,
  template,
  bindings: {
    esCloseModal: '&',
  },
};

export { InsuranceClaimComponent };
