import template from './uber-freight-change-password.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('home.auth.uber-freight-change-password', {
        url: 'uber-freight/change-password/:resetPasswordToken',
        template,
        controller: 'PasswordNewCtrl',
        controllerAs: 'vm',
      });
    },
  ]);
})();
