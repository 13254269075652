import {
  FedexBatteryOption,
  FedexOption,
  fedexBatteryOptions,
} from '@client/core/corelogic/models/Shipment/ShipmentData';
import { IComponentController } from 'angular';
import template from './battery-services.html?raw';
import style from './battery-services.module.scss';

interface IFedexBatteryChange {
  value: FedexOption;
  label: string;
}

class BatteryServices implements IComponentController {
  style = style;
  fedexBatteryOptions: IFedexBatteryChange[] = [];

  esModel: FedexOption[] = [];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  esOnChange: (arg: { value: FedexOption[] }) => void = () => {};
  static $inject = ['$translate'];
  batteryOptionSelected!: IFedexBatteryChange;
  constructor(private $translate: angular.translate.ITranslateService) {}
  $onInit() {
    this.fedexBatteryOptions = fedexBatteryOptions.map((option) => ({
      value: option,
      label: this.$translate.instant(`shipments.batteries.labels.${option}`),
    }));
    [this.batteryOptionSelected] = this.fedexBatteryOptions;
  }

  onInputChange(option: IFedexBatteryChange) {
    this.batteryOptionSelected = option;
    this.esModel = this.esModel.filter(
      (i) => !fedexBatteryOptions.includes(i as FedexBatteryOption)
    );
    const value = option.value !== 'fedex_no_battery' ? option.value : '';
    if (value) {
      this.esModel.push(value);
    }

    this.esOnChange({ value: this.esModel });
  }
}

const BatteryServicesComponent: ng.IComponentOptions = {
  controller: BatteryServices,
  template,
  bindings: {
    esModel: '<',
    esOnChange: '&',
  },
};

export { BatteryServicesComponent };
