import { IShipmentListItem } from 'typings/shipment';
import { PillColor } from 'typings/pill';
import { IUserSession } from 'typings/user-session';
import { InsuranceState, IClaim } from 'typings/insurance';
import { ClaimStatus } from '@client/data/insurance';

import { IComponentController } from 'angular';
import { InsuranceService } from '@client/src/global/services/insurance/insurance.service';
import template from './claim-summary.html?raw';
import style from './claim-summary.module.scss';

class ClaimSummary implements IComponentController {
  style = style;
  esShipment?: IShipmentListItem;
  state: InsuranceState = 'uninsured';
  claim?: IClaim;
  esDetailsOnly = false;
  esOpenClaimDialog?: () => void;

  static $inject = ['InsuranceService', 'UserSession'];
  constructor(private InsuranceService: InsuranceService, private UserSession: IUserSession) {}

  $onInit(): void {
    this.state = this.InsuranceService.determineInsuredState(this.esShipment);
    this.claim = this.esShipment?.claim;
  }

  get pillColor(): PillColor {
    return this.InsuranceService.getPolicyColor(this.state);
  }

  get statusChipColor(): string {
    return this.InsuranceService.getClaimColor(this.claim?.provider_claim_status as ClaimStatus);
  }

  get isClaimModalEnabled(): boolean {
    return !!(
      this.UserSession.getCompanyDashboardSettings().beta_feature_easyship_claims_processing &&
      this.claim?.processor === 'easyship' &&
      !this.esDetailsOnly &&
      !this.esShipment?.insurance_requested_by_buyer
    );
  }

  get claimState(): string {
    if (!this.claim) return '';

    return this.claim.review_state === 'approved'
      ? this.claim.payment_state
      : this.claim.review_state;
  }

  get claimId(): string {
    if (this.claim) {
      return this.claim.processor === 'easyship'
        ? this.claim.easyship_claim_id
        : this.claim.provider_claim_id;
    }
    return '';
  }
}

const ClaimSummaryComponent: ng.IComponentOptions = {
  controller: ClaimSummary,
  template,
  bindings: {
    esShipment: '<',
    esDetailsOnly: '<',
    esOpenClaimDialog: '&',
  },
};

export { ClaimSummaryComponent };
