import angular from 'angular';

import editProductsModalActions from './edit-products-modal-actions';
import editProductsModalBulkEdit from './edit-products-modal-bulk-edit';
import editProductsModalSpreadsheet from './edit-products-modal-spreadsheet';
import { EditProductsModalComponent } from './edit-products-modal.component';

const MODULE_NAME = 'app.product-listing.edit-products-modal';

angular
  .module(MODULE_NAME, [
    editProductsModalActions,
    editProductsModalBulkEdit,
    editProductsModalSpreadsheet,
  ])
  .component('esProductListingEditProductsModal', EditProductsModalComponent);

export default MODULE_NAME;
