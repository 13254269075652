(function () {
  'use strict';

  uibRoutingOptions.$inject = ['$q', '$state', '$timeout', '$location', 'Auth', 'UserSession'];
  function uibRoutingOptions($q, $state, $timeout, $location, Auth, UserSession) {
    var service = this;

    /**
     * To use this service's functions when going to state, implement it using resolve like so:
     *
     * resolve: {
     *  previousState: function(uibRoutingOptions) {
     *      return uibRoutingOptions.previousState();
     *    }
     *  }
     *
     * The resolve function will run before the state has actually been loaded, so it is perfect
     * for things such as authenticatio and checking the previous state.
     *
     * To use the above example in this state's controller, do it like this:
     *
     * .controller('SomeCtrl', function (previousState) {
     *   console.log(previousState); // This will return to you the Object from this service
     * });
     */

    service.previousState = function () {
      var currentStateData = {
        name: $state.current.name,
        params: $state.params,
        url: $state.href($state.current.name, $state.params),
      };

      return currentStateData;
    };
  }

  angular
    .module('app.service.uibRoutingOptions', [])
    .service('uibRoutingOptions', uibRoutingOptions);
})();
