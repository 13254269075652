import { ClaimStatus } from '@client/data/insurance';
import { IApiConfig } from 'typings/core/config/api';
import { InsuranceState } from 'typings/insurance';
import { PillColor } from 'typings/pill';
import { IShipmentListItem } from 'typings/shipment';

class InsuranceService {
  supportUrlBase: string;

  static $inject = ['API'];
  constructor(private API: IApiConfig) {
    this.supportUrlBase = `${this.API.help}/hc/en-us/articles`;
  }

  determineInsuredState(shipment?: IShipmentListItem): InsuranceState {
    const courierOffersLiability =
      shipment?.courier?.insurance_liability &&
      !/no\s?liability/i.test(shipment?.courier?.insurance_liability);
    const insuranceFee =
      typeof shipment?.insurance_fee === 'string'
        ? parseFloat(shipment?.insurance_fee)
        : shipment?.insurance_fee;

    // If applied through InsureShip
    if (shipment?.is_insured) {
      if (shipment.insurance_requested_by_buyer) return 'buyer-insured';
      if (insuranceFee) return 'self-insured';
      return courierOffersLiability ? 'fallback-courier' : 'fallback-uninsured';
    }

    if (courierOffersLiability) return 'courier-insured';

    return shipment?.courier && !shipment?.courier.is_easyship_courier
      ? 'lyoc-uninsured'
      : 'uninsured';
  }

  isCovered(shipment?: IShipmentListItem): boolean {
    return ['buyer-insured', 'self-insured', 'courier-insured', 'fallback-courier'].includes(
      this.determineInsuredState(shipment)
    );
  }

  getPolicyColor(state?: InsuranceState): PillColor {
    switch (state) {
      case 'self-insured':
        return 'valid';

      case 'buyer-insured':
        return '';

      case 'courier-insured':
      case 'fallback-courier':
        return 'warning';

      case 'fallback-uninsured':
      case 'uninsured':
        return 'danger';

      case 'lyoc-uninsured':
      default:
        return 'secondary';
    }
  }

  getSupportUrl(state?: InsuranceState): string | null {
    switch (state) {
      case 'self-insured':
        return `${this.supportUrlBase}/360012274532-Insurance-Claim`;

      case 'buyer-insured':
        return `${this.supportUrlBase}/4402695345293-Insurance-at-Checkout-BETA`;

      case 'courier-insured':
      case 'fallback-courier':
      case 'fallback-uninsured':
      case 'uninsured':
        return `${this.supportUrlBase}/360036401891-Uninsured-Shipments-How-to-File-a-Claim`;

      case 'lyoc-uninsured':
      default:
        return null;
    }
  }

  getClaimColor(claimStatus?: ClaimStatus): string {
    switch (claimStatus) {
      case ClaimStatus.Success:
      case ClaimStatus.Submitted:
      case ClaimStatus.Approved:
      case ClaimStatus.ClaimReceived:
      case ClaimStatus.Completed:
      case ClaimStatus.Paid:
        return '#50D4A4';

      case ClaimStatus.PaymentInfoRequested:
      case ClaimStatus.PendingDenial:
      case ClaimStatus.UnderReview:
      case ClaimStatus.WaitingOnDocuments:
        return '#F7B500';

      case ClaimStatus.Denied:
        return '#FA6400';

      case ClaimStatus.Closed:
      case ClaimStatus.ClosedDenied:
      case ClaimStatus.ClosedPaid:
      default:
        return '#454F5B';
    }
  }
}

export { InsuranceService };
