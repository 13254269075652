import angular from 'angular';

import { ShipFromAddressSelectorComponent } from './ship-from-address-selector.component';

const MODULE_NAME = 'app.global.components.ship-from-address-selector';

angular
  .module(MODULE_NAME, ['pascalprecht.translate', 'app.service.AddressService'])
  .component('esShipFromAddressSelector', ShipFromAddressSelectorComponent);

export default MODULE_NAME;
