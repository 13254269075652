import { IComponentController } from 'angular';
import template from './multi-select-checkbox-tree.html?raw';
import style from './multi-select-checkbox-tree.module.scss';

interface IMultiSelectCheckboxTreeData {
  name: string;
  display?: string;
  selected: boolean;
  isDisplay: boolean;
  options?: IMultiSelectCheckboxTreeData[];
}

class MultiSelectCheckboxTree implements IComponentController {
  esOnChange?(changes: { value: IMultiSelectCheckboxTreeData }) {}
  esOnToggle?(value: { isOpen: boolean }) {}
  style = style;
  esModel: IMultiSelectCheckboxTreeData | null = null;
  esOptions: IMultiSelectCheckboxTreeData[] | null = null;
  esDisplayKey: string | null = null;
  esIsOpen = false;
  isDisplayLabel = true;

  static $inject = ['$scope'];
  constructor(private $scope: ng.IScope) {}

  $onInit(): void {
    this.$scope.$watch(
      '$ctrl.esOptions',
      () => {
        this.isDisplayLabel = this.checkDisplayLabelStatus();
      },
      true
    );
  }

  onTitleChange(value: boolean): void {
    if (this.esModel) {
      this.esModel.selected = value;
    }

    if (this.esOptions) {
      this.esOptions.forEach((option) => (option.selected = value));

      if (this.esOnChange && this.esModel) {
        this.esOnChange({ value: this.esModel });
      }
    }
  }

  onOptionChange(value: boolean, index: number): void {
    if (this.esOptions) {
      this.esOptions[index].selected = value;

      if (this.esModel) {
        this.esModel.selected = this.esOptions.some((option) => option.selected);

        this.esOnChange && this.esOnChange({ value: this.esModel });
      }
    }
  }

  get hasPartialOptionSelected(): boolean {
    if (!this.esModel || !this.esOptions || this.esOptions.length <= 0) {
      return false;
    }

    const checkedOptions = this.esOptions.filter((option) => {
      return option.selected;
    });

    if (!checkedOptions.length || checkedOptions.length === this.esOptions.length) {
      return false;
    }

    return true;
  }

  checkDisplayLabelStatus(): boolean {
    const isDisplay = true;
    if (this.esOptions) {
      if (this.esOptions.length <= 0) {
        return isDisplay;
      }
      return this.esOptions.some((option) => option.isDisplay);
    }

    return isDisplay;
  }

  optionName(option: any): string {
    if (this.esDisplayKey) {
      return option[this.esDisplayKey];
    }

    return option.name;
  }

  onToggle(): void {
    this.esIsOpen = !this.esIsOpen;
    this.esOnToggle && this.esOnToggle({ isOpen: this.esIsOpen });
  }
}

const MultiSelectCheckboxTreeComponent: ng.IComponentOptions = {
  controller: MultiSelectCheckboxTree,
  template,
  transclude: {
    leftLabel: '?actionLeftLabel',
    rightLabel: '?actionRightLabel',
  },
  bindings: {
    esModel: '<',
    esOptions: '<',
    esOptionsLength: '<',
    esIsOpen: '<',
    esDisplayKey: '<',
    esShowPlatformLogo: '<',
    esShowCourierLogo: '<',
    esFormatAddresses: '<',
    esFilterInput: '<',
    esOnToggle: '&',
    esOnChange: '&',
  },
};

export { MultiSelectCheckboxTreeComponent };
