(function () {
  'use strict';

  function addressFormGbCtrl() {
    var ctrl = this;

    ctrl.removeWhiteSpace = function (input) {
      return (input || '').replace(/\s/g, '');
    };
  }

  angular
    .module('app.component.address-form-gb')
    .controller('addressFormGbCtrl', addressFormGbCtrl);
})();
