import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import COLORS from '@client/src/colors';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import PersonaButton from './PersonaButton';

const PRE_NEGOTIATED_COUNTRIES: string[] = [
  'United States',
  'Canada',
  'United Kingdom',
  'France',
  'Hong Kong',
  'Singapore',
  'Australia',
  'New Zealand',
];

function PersonaSection() {
  const {
    user: { firstName, lastName },
  } = useUserSession();
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'));

  const hasMixpanelSent = useRef(false);

  // TODO: uncoment when this wants to be activated
  /*
    const { selectedPersona } = useOnboarding();

    const { abTestVariation, isGetAbTestVariationSuccess } = useAbTest('direct_to_quote', {
      enabled:
        selectedPersona === 'personal' && PRE_NEGOTIATED_COUNTRIES.includes(shippingCountry.name),
      });

    const { mutateAsync: abTestMutateAsync } = useAbTestMutation();

    const handleAssignAbTest = useCallback((variation: string) => {
      const abTestAssignPayload: AbTestAssignmentPayload = {
        ab_test_assignment: {
          agent_id: companyId,
          variation,
        },
        abTestKey: 'direct_to_quote',
      };
      abTestMutateAsync({ payload: abTestAssignPayload }).catch((error) => {
        console.error(error);
      });
    }, []);

    useEffect(() => {
      if (abTestVariation) {
        handleAssignAbTest(abTestVariation?.variation);
      }
    }, [abTestVariation, handleAssignAbTest, isGetAbTestVariationSuccess]);
  */

  const sendMixpanelArriveEvent = () => {
    if (!hasMixpanelSent.current) {
      MixpanelService.track(`Onboarding - Declared Persona - Arrive`);
      hasMixpanelSent.current = true;
    }
  };

  useEffect(() => {
    sendMixpanelArriveEvent();
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent={isScreenSm ? 'flex-start' : 'center'}
      alignItems="center"
    >
      <Grid item>
        <Box marginBottom={isScreenSm ? 2 : 4}>
          <Typography
            variant="h2"
            style={{ fontSize: isScreenSm ? 20 : 28, color: COLORS.inkDarkest }}
          >
            <FormattedMessage id="auth.mobile-tips.header" />, {firstName} {lastName}!
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box marginBottom={isScreenSm ? 4 : 8}>
          <Typography variant="h4" align={isScreenSm ? 'center' : 'left'}>
            <FormattedMessage id="onboarding.personas.description" />
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        container
        direction={isScreenSm ? 'column' : 'row'}
        justifyContent="center"
        spacing={isScreenSm ? 0 : 4}
        style={{ width: '100%' }}
      >
        <PersonaButton value="personal" />
        <PersonaButton value="merchant" />
        <PersonaButton value="crowdfunding" />
        <PersonaButton value="developer" />
      </Grid>
    </Grid>
  );
}

export default PersonaSection;
