import angular from 'angular';

import { AdvancedSearchComponent } from './advanced-search.component';

const MODULE_NAME = 'app.product-listing.advanced-search';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingAdvancedSearch', AdvancedSearchComponent);

export default MODULE_NAME;
