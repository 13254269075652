import { react2angular } from 'react2angular';

import RateExplainerDialog, { WrappedRateExplainerDialog } from './RateExplainerDialog';

export default RateExplainerDialog;
export const AngularRateExplainerDialog = react2angular(WrappedRateExplainerDialog, [
  'open',
  'onClose',
  'onLinkClick',
]);
