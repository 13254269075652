import { IUserSession } from 'typings/user-session';
import { IUserService } from 'typings/user-service';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import { toastError } from '@client/core/components/react/Toastify';
import template from './enterprise-call-card.html?raw';
import style from './enterprise-call-card.module.scss';
import enterpriseCallSvg from './enterprise-call-card.svg?svgo';

interface ScheduleTimeOption {
  value: string;
  label: string;
}

class EnterpriseCallCard implements IComponentController {
  style = style;
  esRightButtonAction?: () => void;
  esCloseTitleAction?: () => void;
  image = enterpriseCallSvg;
  contactForm: any;
  loading = false;
  formData: any = {
    contact_time: 'asap',
    contact_number: '',
    contact_email: '',
    reason: 'contact-sales.upgrade-enterprise',
  };
  schedulableTimes: ScheduleTimeOption[] = [];

  static $inject = [
    '$translate',
    'MixpanelService',
    'UserSession',
    'UserService',
    'OnboardingChecklistService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private MixpanelService: MixpanelService,
    private UserSession: IUserSession,
    private UserService: IUserService,
    private OnboardingChecklistService: OnboardingChecklistService
  ) {
    this.schedulableTimes = ['asap', 'morning', 'afternoon', 'evening'].map((time) => ({
      value: time,
      label: this.$translate.instant(`contact-sales.schedule.${time}`),
    }));
  }

  $onInit() {
    const { user } = this.UserSession;
    this.formData.contact_email = user.email;
    this.formData.contact_number = user.mobile_phone;
  }

  scheduleCall(): void {
    if (this.contactForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading = true;
    this.UserService.scheduleCall(this.UserSession.user.id, this.formData)
      .then(() => {
        this.esRightButtonAction?.();
        this.OnboardingChecklistService.updateOnboarding(
          this.OnboardingChecklistService.checklistData.BookMeeting
        );
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading = false;
      });

    this.MixpanelService.track('Subscription - Enterprise - Schedule Call');
  }

  closeModal(): void {
    this.MixpanelService.track('Schedule Call', { close: true });
    this.esCloseTitleAction?.();
  }

  onInputChange(value: string, key: string): void {
    if (this.formData) {
      this.formData[key] = value;
    }
  }
}

const EnterpriseCallCardComponent: ng.IComponentOptions = {
  controller: EnterpriseCallCard,
  template,
  bindings: {
    esCloseTitleAction: '&',
    esLeftButtonAction: '&',
    esRightButtonAction: '&',
  },
};

export { EnterpriseCallCardComponent };
