(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('metaproperty', metaproperty);

  metaproperty.$inject = ['MetadataService'];
  function metaproperty(MetadataService) {
    var directive = {
      restrict: 'A',
      scope: {
        metaproperty: '@',
      },
      link: link,
    };

    return directive;

    function link(scope, element, attrs) {
      scope.default = element.attr('content');
      scope.metadata = MetadataService.meta;

      // Watch for metadata changes and set content
      scope.$watch(
        'metadata',
        function (newVal, oldVal) {
          setContent(newVal);
        },
        true
      );

      // Set the content attribute with new MetadataService value or back to the default
      function setContent(metadata) {
        var content = metadata[scope.metaproperty] || scope.default;
        element.attr('content', content);
      }

      setContent(scope.metadata);
    }
  }
})();
