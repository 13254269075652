import React from 'react';
import { FormattedMessage, FormattedDisplayName } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

import CountryFlag from '@client/core/components/react/CountryFlag';
import Select from '@client/core/components/react/Select';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

const OpaqueSelect = withStyles({
  root: {
    backgroundColor: 'white',
    width: '200px !important',
    paddingLeft: '12px',
    paddingRight: '12px !important',
    boxSizing: 'border-box',
  },
})(Select);

interface CountrySelectorProps {
  alpha2: string;
  options: string[];
  onChange: (alpha2: string) => void;
}

export default function CountrySelector({ alpha2, options, onChange }: CountrySelectorProps) {
  if (options.length === 0) return null;

  return (
    <OpaqueSelect displayEmpty value={alpha2} onChange={(e) => onChange(e.target.value as string)}>
      <MenuItem value="">
        <FormattedMessage id="global.all-countries" />
      </MenuItem>
      {options.map((code) => (
        <MenuItem value={code} key={code} selected={code === alpha2}>
          <Box component="span" mr={1}>
            <CountryFlag alpha2={code} />
          </Box>
          <FormattedDisplayName type="region" value={code} />
        </MenuItem>
      ))}
    </OpaqueSelect>
  );
}

export function WrappedCountrySelector(props: CountrySelectorProps) {
  return (
    <ReactRootProviders>
      <CountrySelector {...props} />
    </ReactRootProviders>
  );
}
