import template from './address-validation-step-skip.html?raw';

(function () {
  'use strict';

  angular
    .module('easyshipDashboardApp')
    .directive('addressValidationStepSkip', addressValidationStepSkip);

  function addressValidationStepSkip() {
    var directive = {
      template,
      restrict: 'EA',
      scope: {
        address: '=',
        goToStep: '=',
        proceed: '=',
        busy: '=',
      },
      controller: controller,
      controllerAs: 'addressValidationStepSkip',
    };

    return directive;
  }

  controller.$inject = ['$scope', 'AddressService'];

  function controller($scope, AddressService) {
    var addressValidationStepSkip = this;

    addressValidationStepSkip.displayAddress = AddressService.formatAddress($scope.address);
  }
})();
