(function () {
  'use strict';

  Platform.$inject = ['$resource', 'API'];
  function Platform($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/platforms/:controller',
      {},
      {
        query: {
          method: 'GET',
          params: {},
        },
        public: {
          method: 'GET',
          params: {
            controller: 'public',
          },
        },
      }
    );
  }

  angular.module('app.factory.Platform', []).factory('Platform', Platform);
})();
