import { IUserSession, ICompanyType } from 'typings/user-session';
import { ITagsResponse } from 'typings/tags';
import { IShipmentAction, IShipmentListItem, IShipmentUpdateParams } from 'typings/shipment';

import { IComponentController } from 'angular';
import { TagsService } from '@client/src/global/services/tags/tags.service';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './shipment-info-edit-tags-dropdown.module.scss';
import template from './shipment-info-edit-tags-dropdown.html?raw';

class ShipmentInfoEditTagsDropdown implements IComponentController {
  style = style;
  tags: string[] = [];
  showUnassignedTags = false;
  tagName = '';
  addingTag = false;
  gettingTags = false;
  updatingShipment = false;
  esShipment: IShipmentListItem | null = null;
  showTagsEditer = false;
  esShipmentIndex = 0;

  static $inject = [
    '$translate',
    'UserSession',
    'TagsService',
    'ShipmentAction',
    'ShipmentListManageService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private TagsService: TagsService,
    private ShipmentAction: IShipmentAction,
    private ShipmentListManageService: ShipmentListManageService
  ) {
    this.style = style;
  }

  $onInit() {
    this.getTags();

    if (this.esShipment && !this.esShipment.order_tag_list) {
      this.esShipment.order_tag_list = [];
    }
  }

  updateShipmentTags(): void {
    if (this.esShipment) {
      const params: IShipmentUpdateParams = {
        company_type: this.UserSession.getCompanyType() as ICompanyType,
        company_id: this.UserSession.getCompanyId(),
        id: this.esShipment.id,
      };

      const payload = {
        shipment: {
          order_tag_list: this.esShipment.order_tag_list,
        },
      };

      this.updatingShipment = true;

      this.ShipmentAction.singleShipmentUpdate(params, payload)
        .then((res: any) => {
          if (typeof this.esShipmentIndex === 'number') {
            this.ShipmentListManageService.modifyShipmentTagList(
              this.esShipmentIndex,
              res.shipment.order_tag_list
            );
          }
        })
        .catch(() => {
          toastError(
            this.$translate.instant('toast.update-error', {
              noun: this.$translate.instant('global.tags').toLowerCase(),
            })
          );
        })
        .finally(() => {
          this.updatingShipment = false;
          this.setTagEditerHide();
        });
    }
  }

  isSelected(tag: string): boolean {
    if (this.esShipment) {
      return this.esShipment.order_tag_list.includes(tag);
    }

    return false;
  }

  onTagInputChange(name: string): void {
    this.tagName = name;
  }

  toggleUnassignedTags($event: KeyboardEvent): void {
    $event.stopPropagation();
    this.showUnassignedTags = !this.showUnassignedTags;
  }

  addTag($event: KeyboardEvent): void {
    if ($event.code !== 'Enter') return;

    $event.preventDefault();
    const tagNameLowerCased = this.tagName.toLowerCase().trim();
    const tagExists = this.tags.includes(tagNameLowerCased);

    if (tagExists) {
      this.selectTag($event, tagNameLowerCased);
      this.tagName = '';
      return;
    }
    if (tagNameLowerCased) {
      this.addingTag = true;
      const companyId = this.UserSession.getCompanyId();

      if (!companyId) return;

      this.TagsService.addTag(
        {
          company_id: companyId,
        },
        {
          tag: {
            name: tagNameLowerCased,
          },
        }
      )
        .then(() => {
          this.tags.unshift(tagNameLowerCased);
          this.selectTag($event, tagNameLowerCased);
          this.tagName = '';
        })
        .catch(() => {
          toastError(this.$translate.instant('toast.default-error'));
        })
        .finally(() => {
          this.addingTag = false;
        });
    }
  }

  selectTag($event: KeyboardEvent, tag: string): void {
    $event.stopPropagation();
    if (this.esShipment) {
      const index = this.esShipment.order_tag_list.indexOf(tag);

      if (index > -1) {
        this.esShipment.order_tag_list.splice(index, 1);
      } else {
        this.esShipment.order_tag_list.push(tag);
      }
    }
  }

  isUnassigned(tag: string): boolean {
    if (this.esShipment) {
      return !this.esShipment.order_tag_list.includes(tag);
    }

    return true;
  }

  onClickOutside(): void {
    this.setTagEditerHide();
  }

  setTagEditerShow(): void {
    this.showTagsEditer = true;
  }

  setTagEditerHide(): void {
    this.showTagsEditer = false;
  }

  private getTags(): void {
    this.gettingTags = true;
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) return;

    this.TagsService.getTags({
      company_id: companyId,
    })
      .then((response: ITagsResponse) => {
        this.tags = response.tags.sort();
      })
      .finally(() => {
        this.gettingTags = false;
      });
  }
}

const ShipmentInfoEditTagsDropdownComponent: ng.IComponentOptions = {
  controller: ShipmentInfoEditTagsDropdown,
  template,
  bindings: {
    esShipment: '<',
    esShipmentIndex: '<',
  },
};

export { ShipmentInfoEditTagsDropdownComponent };
