(function () {
  'use strict';

  MatahariMallService.$inject = ['$q'];
  function MatahariMallService($q) {
    var service = this;

    service.formatMatahariMallKey = function (store, platforms) {
      return $q(function (resolve, reject) {
        store.url = platforms[0].url;
        store.platform_id = platforms[0].id;
        resolve(store);
      });
    };
  }

  angular
    .module('app.service.MatahariMallService', [])
    .service('MatahariMallService', MatahariMallService);
})();
