import React from 'react';
import clsx from 'clsx';
import { fallbackKey } from '@client/data/platform';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  md: {
    width: 25,
    height: 19,
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: -2,
  },
  sm: {
    width: 20,
    height: 15,
    padding: '1px',
    borderRadius: '2px',
    overflow: 'hidden',
  },
  lg: {
    borderRadius: '8px',
    overflow: 'hidden',
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    margin: 12,
  },
}));

interface PlatformLogoProps extends React.ComponentPropsWithoutRef<'img'> {
  platformNameSlug: string;
  size?: 'md' | 'sm' | 'lg';
}

export default function PlatformLogo({
  platformNameSlug,
  size = 'md',
  className,
}: PlatformLogoProps) {
  const classes = useStyles();
  const logoUrl = new URL(
    `../../../src/global/dashboard-component-library/platform-logo/assets/mini/${platformNameSlug}.svg`,
    import.meta.url
  ).href;
  const fallbackUrl = new URL(
    `../../../src/global/dashboard-component-library/platform-logo/assets/mini/${fallbackKey}.svg`,
    import.meta.url
  ).href;

  return (
    <img
      src={logoUrl}
      className={clsx(
        `${classes[size]} platform-colors inline-flex  ${platformNameSlug || fallbackKey}`,
        className
      )}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = fallbackUrl;
      }}
      alt={platformNameSlug}
    />
  );
}
