import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';
import { Criteria } from '@client/data/help-guide';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import style from './help-guide-topic.module.scss';
import template from './help-guide-topic.html?raw';
import walkthroughIcon from '@assets/images/dashboard/help-guide/walkthrough.svg?svgo';
import tutorialIcon from '@assets/images/dashboard/help-guide/tutorial.svg?svgo';

class HelpGuideTopicController implements IComponentController {
  style = style;
  esKey?: string;
  esWalkthrough?: {
    state: string;
    params: {
      [key: string]: string;
    };
  };
  esTutorial?: string;
  esIcon?: string;
  esCriteria?: string;
  walkthroughIcon: string;
  tutorialIcon: string;

  static $inject = ['API', '$sce', '$document', '$state', 'UserSession', 'MixpanelService'];
  constructor(
    private API: IApiConfig,
    private $sce: ng.ISCEService,
    private $document: ng.IDocumentService,
    private $state: ng.ui.IStateService,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService
  ) {
    this.walkthroughIcon = $sce.trustAsHtml(walkthroughIcon);
    this.tutorialIcon = $sce.trustAsHtml(tutorialIcon);
  }

  startWalkthrough() {
    const navSection = this.esWalkthrough?.params?.sidebaropen;
    if (navSection) {
      if (/connect/i.test(navSection)) this.$document.find('#toggle-connect').prop('checked', true);
      if (/settings/i.test(navSection)) this.$document.find('#toggle-tools').prop('checked', true);
      if (/account/i.test(navSection)) this.$document.find('#toggle-account').prop('checked', true);
    }

    if (this.esWalkthrough?.state) {
      this.$state.go(this.esWalkthrough?.state, this.esWalkthrough?.params);
    }

    this.MixpanelService.track('Help Guide - Tutorial', {
      tour_name: this.esKey,
      persona: this.UserSession.getUserPersona(),
    });
  }

  get topicIcon(): string {
    return this.$sce.trustAsHtml(this.esIcon);
  }

  get title(): string {
    return !this.esKey ? '' : `help-guide.${this.esKey}.title`;
  }

  get subtitle(): string {
    return !this.esKey ? '' : `help-guide.${this.esKey}.subtitle`;
  }

  get tutorialUrl(): string | undefined {
    return this.esTutorial && `${this.API.help}${this.esTutorial}`;
  }

  get isNotRelevant(): boolean {
    switch (this.esCriteria) {
      case Criteria.eFulfillmentOnly:
        return this.UserSession.isCompanyEfulfilment();
      default:
        return false;
    }
  }
}

const HelpGuideTopicComponent = {
  controller: HelpGuideTopicController,
  template,
  bindings: {
    esKey: '<',
    esWalkthrough: '<',
    esTutorial: '<',
    esIcon: '<',
    esCriteria: '<',
  },
};

export { HelpGuideTopicComponent };
