/* eslint-disable import/extensions */

import angular from 'angular';

import { ShipmentListRowHeaderComponent } from './row-header/shipment-list-row-header.component';
import { ShipmentListRowTableComponent } from './row-table/shipment-list-row-table.component';
import { ShipmentListOrderCellComponent } from './order-cell/shipment-list-order-cell.component';
import { ShipmentListReceiverCellComponent } from './receiver-cell/shipment-list-receiver-cell.component';
import { ShipmentListCourierCellComponent } from './courier-cell/shipment-list-courier-cell.component';
import { ShipmentListDeliveryCellComponent } from './delivery-cell/shipment-list-delivery-cell.component';
import { ShipmentListTrackingQualityCellComponent } from './tracking-quality-cell/shipment-list-tracking-quality-cell.component';
import { ShipmentListHandoverCellComponent } from './handover-cell/shipment-list-handover-cell.component';
import { ShipmentListIncotermsHeaderTooltipComponent } from './incoterms-header-tooltip/incoterms-header-tooltip.component';
import { ShipmentListTaxesCellComponent } from './taxes-cell/shipment-list-taxes-cell.component';
import { ShipmentListCollectionCellComponent } from './collection-cell/shipment-list-collection-cell.component';
import { ShipmentListCostCellComponent } from './cost-cell/shipment-list-cost-cell.component';
import { ShipmentListOrderFormComponent } from './order-form/shipment-list-order-form.component';
import { ShipmentListReceiverFormComponent } from './receiver-form/shipment-list-receiver-form.component';
import { ShipmentListTrackingHeaderTooltipComponent } from './tracking-header-tooltip/tracking-header-tooltip.component';
import { ShipmentListEditCourierCardComponent } from './edit-courier-card/shipment-list-edit-courier-card.component';
import { ShipmentListRemarksCellComponent } from './remarks-cell/shipment-list-remarks-cell.component';
import { ShipmentListSearchBarComponent } from './search-bar/shipment-list-search-bar.component';
import { ShipmentListEmptyStateComponent } from './empty-state/shipment-list-empty-state.component';
import { ShipmentListRowSelectAllComponent } from './row-select-all/shipment-list-row-select-all.component';
import { ShipmentListRowTableManageComponent } from './row-table-manage/shipment-list-row-table-manage.component';

const MODULE_NAME = 'app.global.components.shipments-list';
const prefix = 'esShipmentList';

angular
  .module(MODULE_NAME, [])
  .component(`${prefix}RowHeader`, ShipmentListRowHeaderComponent)
  .component(`${prefix}RowTable`, ShipmentListRowTableComponent)
  .component(`${prefix}OrderCell`, ShipmentListOrderCellComponent)
  .component(`${prefix}ReceiverCell`, ShipmentListReceiverCellComponent)
  .component(`${prefix}CourierCell`, ShipmentListCourierCellComponent)
  .component(`${prefix}DeliveryCell`, ShipmentListDeliveryCellComponent)
  .component(`${prefix}TrackingQualityCell`, ShipmentListTrackingQualityCellComponent)
  .component(`${prefix}HandoverCell`, ShipmentListHandoverCellComponent)
  .component(`${prefix}IncotermsHeaderTooltip`, ShipmentListIncotermsHeaderTooltipComponent)
  .component(`${prefix}TaxesCell`, ShipmentListTaxesCellComponent)
  .component(`${prefix}CollectionCell`, ShipmentListCollectionCellComponent)
  .component(`${prefix}CostCell`, ShipmentListCostCellComponent)
  .component(`${prefix}OrderForm`, ShipmentListOrderFormComponent)
  .component(`${prefix}ReceiverForm`, ShipmentListReceiverFormComponent)
  .component(`${prefix}TrackingHeaderTooltip`, ShipmentListTrackingHeaderTooltipComponent)
  .component(`${prefix}EditCourierCard`, ShipmentListEditCourierCardComponent)
  .component(`${prefix}RemarksCell`, ShipmentListRemarksCellComponent)
  .component(`${prefix}SearchBar`, ShipmentListSearchBarComponent)
  .component(`${prefix}EmptyState`, ShipmentListEmptyStateComponent)
  .component(`${prefix}RowSelectAll`, ShipmentListRowSelectAllComponent)
  .component(`${prefix}RowTableManage`, ShipmentListRowTableManageComponent);

export default MODULE_NAME;
