import { IRegistrationSuccessResponse } from 'typings/auth/services/registration';
import { RegistrationResource } from '../registration/registration.resource';

const PLATFORM = 'weebly';

class WeeblyAuthService {
  static $inject = ['$location', 'RegistrationResource'];
  constructor(
    public $location: ng.ILocationService,
    public RegistrationResource: RegistrationResource
  ) {}

  createRegistration(): ng.IPromise<IRegistrationSuccessResponse> {
    const { authorization_code, site_id, user_id } = this.$location.search();

    return this.RegistrationResource.platform(
      { platform: PLATFORM },
      {
        authorization_code,
        site_id,
        user_id,
      }
    );
  }
}

export { WeeblyAuthService };
