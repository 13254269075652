import angular from 'angular';

import { PaymentComponent } from './payment.component';

const MODULE_NAME = 'app.account.payment';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', 'core.components'])
  .component('payment', PaymentComponent);

export default MODULE_NAME;
