import { toastError } from '@client/core/components/react/Toastify';
import template from './customer-emails.html?raw';
import style from './customer-emails.module.scss';

class CustomerEmails {
  static $inject = ['CompanyService', '$translate'];

  constructor(CompanyService, $translate) {
    this.style = style;
    this.CompanyService = CompanyService;
    this.$translate = $translate;
    this.loading = {};
  }

  $onInit() {
    this.loading.settings = true;

    this.CompanyService.getTrackingEmailBrandingData()
      .then((data) => {
        this.formData = data;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading.settings = false;
      });
  }
}

const CustomerEmailsComponent = {
  controller: CustomerEmails,
  template,
};

export { CustomerEmailsComponent };
