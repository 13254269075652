import { IComponentController } from 'angular';
import template from './insurance-deactivation-modal.html?raw';

class StoreSettingsInsuranceDeactivationModalController implements IComponentController {
  esClose = () => {};
}

const StoreSettingsInsuranceDeactivationModalComponent: ng.IComponentOptions = {
  controller: StoreSettingsInsuranceDeactivationModalController,
  template,
  bindings: {
    esShow: '<',
    esClose: '&',
  },
};

export { StoreSettingsInsuranceDeactivationModalComponent };
