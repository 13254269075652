import { toastError } from '@client/core/components/react/Toastify';
import template from './handover.html?raw';
import headerTemplate from './handover-header.html?raw';
import style from './handover.module.scss';

class ReschedulePickupHandover {
  static $inject = [
    '$stateParams',
    '$state',
    '$translate',
    'UserSession',
    'CheckoutService',
    'MixpanelService',
    'HelperService',
    'API',
    'ShipmentListManageService',
  ];

  constructor(
    $stateParams,
    $state,
    $translate,
    UserSession,
    CheckoutService,
    MixpanelService,
    HelperService,
    API,
    ShipmentListManageService
  ) {
    this.style = style;
    this.headerTemplate = headerTemplate;

    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$translate = $translate;

    this.CheckoutService = CheckoutService;
    this.UserSession = UserSession;
    this.MixpanelService = MixpanelService;
    this.HelperService = HelperService;
    this.ShipmentListManageService = ShipmentListManageService;
    this.API = API;

    this.busy = {};
    this.error = {};
    this.wrappers = {
      a: (chunk) =>
        `<a href="${this.API.help}/hc/en-us/articles/360004311451-Reschedule-Pickup-Schedule-a-different-pickup" target="_blank" rel="noopener">${chunk}</a>`,
    };

    this.originState = this.$state.current.parent;
  }

  $postLink() {
    this.HelperService.compileAndAppendTemplateToTarget(
      this.headerTemplate,
      '#handover-header',
      this
    );
  }

  $onInit() {
    // Redirects back to Manage Shipments if no params
    if (!this._hasCheckoutData()) {
      this.prev();
    }
  }

  next() {
    if (!this._canGoToNextStep()) return;

    this.CheckoutService.recalculateTotalsToPay();
    this._sendMixpanelEvent();

    this._proceed();
  }

  prev() {
    if (this.$stateParams.comingFromPickupsPage) {
      return this.$state.go('app.pickups', { returnBackFromReschedulePickupPage: true });
    }

    return this.$state.go('app.shipments', {
      page: this.ShipmentListManageService.pageNum,
      from_handover: true,
    });
  }

  _hasCheckoutData() {
    return Object.keys(this.CheckoutService.data).length > 0;
  }

  _canGoToNextStep() {
    if (this.busy.page) {
      return false;
    }

    if (this.CheckoutService.hasEveryInputsNeeded(this.CheckoutService.payload)) {
      return true;
    }

    toastError(this.$translate.instant('toast.incomplete-form'));
    return false;
  }

  _proceed() {
    return this.$state.go('app.reschedule-pickup.summary', {
      comingFromPickupsPage: this.$stateParams.comingFromPickupsPage,
    });
  }

  _sendMixpanelEvent() {
    const eventData = {};

    this.CheckoutService.payload.origins.forEach((origin) =>
      origin.pickups.forEach((pickup) => {
        eventData[`${pickup.courierAdminName}_handover_available`] =
          pickup.availableHandoverOptions;
        eventData[`${pickup.courierAdminName}_handover_chosen`] = this._buildDetailedCategoryName(
          pickup.handoverOptionCategory,
          pickup.handoverPickupFee
        );
        eventData[`${pickup.courierAdminName}_pickup_fee`] = pickup.handoverPickupFee;
        eventData.number_of_shipments = pickup.shipment_ids && pickup.shipment_ids.length;
        eventData.postal_code = pickup.pickupAddressZip;
      })
    );

    this.MixpanelService.track('Reschedule Pickup - Choose Handover (Step 1)', eventData);
  }

  _buildDetailedCategoryName(handoverOptionCategory, pickupFee) {
    if (handoverOptionCategory === 'pickup') {
      return pickupFee ? 'paid_pickup' : 'free_pickup';
    }

    return 'dropoff';
  }
}

const ReschedulePickupHandoverComponent = {
  controller: ReschedulePickupHandover,
  template,
};

export { ReschedulePickupHandoverComponent };
