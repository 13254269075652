import angular from 'angular';
import '../../buttons/button';
import '../input';
import helperService from '@client/core/services/helper';

import { CopyClipboardInputComponent } from './copy-clipboard-input.component';

angular
  .module('core.components.copy-clipboard-input', [
    'core.components.button',
    'core.components.input',
    helperService,
  ])
  .component('easyshipCopyClipboardInput', CopyClipboardInputComponent);
