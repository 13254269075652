import { IInputGroupRadioModel, IInputGroupValue } from 'typings/form';
import { TbcRadioModelValue } from '@client/src/global/services/shipment-list/data/radio-group';
import { IComponentController } from 'angular';
import template from './tbc-radio-group.html?raw';
import style from './tbc-radio-group.module.scss';

class TbcRadioGroup implements IComponentController {
  style = style;
  esStatus: TbcRadioModelValue | null = null;
  radioModel: IInputGroupRadioModel<TbcRadioModelValue>[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit() {
    this.radioModel = [
      {
        title: this.$translate.instant('global.all'),
        value: TbcRadioModelValue.All,
      },
      {
        title: this.$translate.instant('global.return'),
        value: TbcRadioModelValue.Return,
      },
      {
        title: this.$translate.instant('global.shipments'),
        value: TbcRadioModelValue.Shipment,
      },
    ];
    if (!this.esStatus) {
      this.esStatus = TbcRadioModelValue.All;
    }
  }

  esOnChange(value: any): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  onRadioChange(value: IInputGroupValue<TbcRadioModelValue>): void {
    this.esStatus = value.radio;
    this.esOnChange({
      value: this.esStatus,
    });
  }
}

const TbcRadioGroupComponent: ng.IComponentOptions = {
  controller: TbcRadioGroup,
  template,
  bindings: {
    esStatus: '<',
    esOnChange: '&',
  },
};

export { TbcRadioGroupComponent };
