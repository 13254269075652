import React from 'react';
import { useStyles } from './styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface OptionButtonProps {
  children: JSX.Element;
  onClick: () => void;
}

export default function OptionButton(props: OptionButtonProps) {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={props.onClick}>
      <Typography variant="body2">{props.children}</Typography>
      <div className={classes.arrowIcon}>
        <ArrowForwardIosIcon fontSize="small" />
      </div>
    </div>
  );
}
