import template from './checkbox.html?raw';
import style from './checkbox.module.scss';
import checkmarkIcon from './assets/checkmark.svg?svgo';
import indeterminateIcon from './assets/indeterminate.svg?svgo';

class Checkbox {
  static $inject = ['$sce', '$element'];

  constructor($sce, $element) {
    this.style = style;
    this.$sce = $sce;
    this.$element = $element;
  }

  $onInit() {
    this.esIndeterminate = this.esIndeterminate === true || false;
    this.icons = {
      checkmarkIcon: this.$sce.trustAsHtml(checkmarkIcon),
      indeterminateIcon: this.$sce.trustAsHtml(indeterminateIcon),
    };
  }

  onChange() {
    if (this.esIndeterminate === true) {
      this.esIndeterminate = false;
    }

    this.ngChange({ value: this.ngModel });
  }

  onKeyPress($event) {
    const { keyCode } = $event;
    const spaceBar = 32;

    if (keyCode === spaceBar) {
      $event.preventDefault(); // prevents default behaviour of scroll down on spacebar
      if (!this.ngDisabled) {
        this.ngModel = !this.ngModel;
        this.onChange();
      }
    }
  }

  hasLabel() {
    return !!angular.element('ng-transclude', this.$element).html();
  }
}

const CheckboxComponent = {
  controller: Checkbox,
  template,
  bindings: {
    esError: '<',
    esIndeterminate: '<',
    esLabelPosition: '@',
    esAlignItems: '@',
    ngModel: '<',
    ngModelOptions: '<',
    ngDisabled: '<',
    ngChange: '&',
  },
  transclude: true,
};

export { CheckboxComponent };
