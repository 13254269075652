import template from './item-row-column.html?raw';
import style from './item-row-column.module.scss';

class ItemRowColumn {
  static $inject = ['$element'];

  constructor($element) {
    this.style = style;
    this.$element = $element;
  }

  $onInit() {
    const componentStyle = this.$element[0].style;

    const width = this.esFixedWidth ? `${this.esFixedWidth}px` : null;
    const percentWidth = this.esPercentWidth ? `${this.esPercentWidth}%` : null;

    componentStyle.minWidth = width;
    componentStyle.width = width || percentWidth;
  }
}

const ItemRowColumnComponent = {
  template,
  transclude: true,
  controller: ItemRowColumn,
  bindings: {
    esFixedWidth: '@',
    esPercentWidth: '@',
  },
};

export { ItemRowColumnComponent };
