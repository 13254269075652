import angular from 'angular';

const MODULE_NAME = 'app.filter.titleize';

const titleize = () => {
  return (input: string): string => {
    return input
      ? input
          .split(' ')
          .map((wrd) => wrd.charAt(0).toUpperCase() + wrd.substr(1).toLowerCase())
          .join(' ')
      : '';
  };
};

angular.module(MODULE_NAME, []).filter('titleize', titleize);

export default MODULE_NAME;
