import { IApiConfig } from 'typings/core/config/api';
import { ICheckoutPayload, ICheckoutErrors, ICheckoutTotals } from 'typings/checkout';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import insureYesImageUrl from '@assets/images/dashboard/handover-addons/insure-yes.svg';
import insureNoImageUrl from '@assets/images/dashboard/handover-addons/insure-no.svg';
import style from './insurance-card.module.scss';
import template from './insurance-card.html?raw';

class InsuranceCardController implements IComponentController {
  esErrors: ICheckoutErrors | null = null;
  esTotalData: ICheckoutTotals | null = null;
  esPayload: ICheckoutPayload | null = null;
  esOnRadioSelect(value: { value: boolean }) {}

  style = style;
  insureYesImageUrl = insureYesImageUrl;
  insureNoImageUrl = insureNoImageUrl;
  insuranceIconUrl = '';
  allInsured?: boolean;
  insuranceFee = '';
  shipmentValue = '';
  wrappers = {
    a: (chunk: string) => `<a href="#" target="_blank" rel="noopener">${chunk}</a>`,
    b: (chunk: string) => `<span class="strong">${chunk}</span>`,
    i: (chunk: string) => `<a href="#" target="_blank" rel="noopener">${chunk}</a>`,
    green: (chunk: string) => `<span class="text-green-700 strong">${chunk}</span>`,
    redstrong: (chunk: string) => `<span class="text-red-900 strong">${chunk}</span>`,
    red: (chunk: string) => `<span class="text-red-900">${chunk}</span>`,
    dark: (chunk: string) => `<span class="text-ink-700 strong">${chunk}</span>`,
  };

  static $inject = ['$filter', 'API', 'UserSession', 'MixpanelService'];
  constructor(
    private $filter: ng.IFilterFunction,
    private API: IApiConfig,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService
  ) {}

  $onInit() {
    this.insuranceIconUrl = `${this.API.easyship_storage}/insurance/shield-teal.svg`;
    this.allInsured = this.esPayload?.set_all_as_insured;

    this.wrappers.a = (chunk: string) =>
      `<a href="${this.API.help}/hc/en-us/articles/115003451011-Add-Shipping-Insurance" target="_blank" rel="noopener">${chunk}</a>`;
    this.wrappers.i = (chunk: string) =>
      `<a href="${this.API.homepage}/legal/insurance-terms-and-conditions" target="_blank" rel="noopener">${chunk}</a>`;
    this.insuranceFee = this.$filter('intlCurrency')(
      this.esTotalData?.total_insurance_fee_not_applied,
      this.UserSession.getCompanyCurrency()
    );
    this.shipmentValue = this.$filter('intlCurrency')(
      this.esTotalData?.total_value_not_insured,
      this.UserSession.getCompanyCurrency()
    );
  }

  onSelect(value: boolean): void {
    this.allInsured = value;
    this.esOnRadioSelect({ value });
  }
}

const InsuranceCardComponent = {
  controller: InsuranceCardController,
  template,
  bindings: {
    esTotalData: '<',
    esPayload: '<',
    esOnRadioSelect: '&',
    esErrors: '<',
  },
};

export { InsuranceCardComponent };
