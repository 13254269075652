export enum OnboardingChecklist {
  ShippingAddress = 'shipping_address',
  ConnectStore = 'connect_store',
  SyncOrders = 'sync_orders',
  InstallCheckout = 'install_checkout',
  InputDimensions = 'input_dimensions',
  CreateShippingRule = 'create_shipping_rule',
  SetupBranding = 'setup_branding',
  GenerateLabel = 'generate_label',
  GetQuote = 'get_quote',
  CreateShipment = 'create_shipment',
  BookMeeting = 'book_meeting',
  DownloadCsv = 'download_csv',
  UploadOrders = 'upload_orders',
  TrackShipment = 'track_shipment',
  Lyoc = 'lyoc',
  WarehousePartners = 'warehouse_partners',
  AddBoxes = 'add_boxes',
}
