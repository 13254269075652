import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import template from './impersonation-banner.html?raw';
import style from './impersonation-banner.module.scss';
import maskImageUrl from '@assets/images/dashboard/auth/mask.svg';

class ImpersonationBannerController implements IComponentController {
  style = style;
  maskImageUrl = maskImageUrl;
  show = false;
  userFirstName?: string;

  static $inject = ['$window', 'UserSession'];
  constructor(private $window: ng.IWindowService, private UserSession: IUserSession) {}

  $onInit(): void {
    this.userFirstName = this.UserSession.user.first_name;
    this.show = !!this.UserSession.admin_email;
  }
}

const ImpersonationBannerComponent = {
  controller: ImpersonationBannerController,
  template,
};

export { ImpersonationBannerComponent };
