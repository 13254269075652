export enum OrderStatusRadioModelValue {
  AllShipments = 0,
  ReadyToShipped = 'ready_to_shipped',
  NotReadyToShipped = 'not_ready_to_shipped',
}

export enum TbcRadioModelValue {
  All = 0,
  Return = 'return',
  Shipment = 'shipment',
}
