/**
 * Expose new form api that allow us to change fields state to dirty programatically
 * Usage: $scope.myForm.$submit();
 */
function FormDirective() {
  return {
    require: 'form',
    restrict: 'E',
    link: {
      pre: (scope, element, attrs, form) => {
        form.$submit = () => {
          if (form && form.$invalid && form.$$controls) {
            form.$$controls.forEach(ngModelController => {
              ngModelController.$invalid ? ngModelController.$setDirty() : null;
            });
          }
        };
      },
    },
  };
}

export { FormDirective };
