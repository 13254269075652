import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { MarkShipmentAsPrintedParams, MarkShipmentAsPrintedPayload } from 'typings/shipment';
import { useShipmentGateway } from '../../providers/ShipmentGatewayProvider';

interface MarkAsPrintMutationParam extends MarkShipmentAsPrintedPayload {
  id: string;
}

export default function useMarkShipmentAsPrintedMutation() {
  const { shipmentGateway } = useShipmentGateway();
  const { company } = useUserSession();

  return useMutation({
    mutationFn: ({ id, context, print_method, shipping_document }: MarkAsPrintMutationParam) => {
      const params: MarkShipmentAsPrintedParams = {
        company_id: company.id,
        company_type: 'cloud',
        id,
      };
      const payload: MarkShipmentAsPrintedPayload = {
        context,
        print_method,
        shipping_document,
      };
      return shipmentGateway.markShipmentAsPrinted(params, payload);
    },
  });
}
