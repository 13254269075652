import webhookIconUrl from '@assets/images/sidebar/icons/webhook.svg';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './webhook-update.html?raw';
import style from './webhook-update.module.scss';

class WebhookUpdate {
  webhookIconUrl = webhookIconUrl;

  static $inject = ['Webhooks', 'WebhookModal', 'HelperService', 'MixpanelService', '$translate'];

  constructor(Webhooks, WebhookModal, HelperService, MixpanelService, $translate) {
    this.style = style;
    this.Webhooks = Webhooks;
    this.WebhookModal = WebhookModal;
    this.HelperService = HelperService;
    this.MixpanelService = MixpanelService;
    this.$translate = $translate;

    this.availableEvents = [...this.Webhooks.availableEvents];
    this.eventTested = null;
    this.testing = false;
    this.endpointChanged = false;
  }

  $onInit() {
    this._getWebhookDetails();
  }

  _getWebhookDetails() {
    this.Webhooks.getWebhookDetails(this.webhook.id)
      .then((response) => {
        this.webhook = { ...response };
      })
      .catch(() => {
        toastError(this.$translate.instant('webhook.notifications.update-error'));
      });
  }

  testWebhook(eventName) {
    this.eventTested = eventName;
    this.testing = true;

    this.Webhooks.testWebhookEvent({ id: this.webhook.id, event_type: eventName })
      .then((response) => {
        toastSuccess(response.status);
      })
      .catch((err) => {
        toastError(err.data && err.data.status);
      })
      .finally(() => {
        this.eventTested = null;
        this.testing = false;
      });
  }

  onToggleStatus(value) {
    if (this.webhook.auto_deactivated) {
      this.webhook.auto_deactivated = false;
    }
    this.webhook.is_active = value;
  }

  onEndpointChange(value) {
    this.webhook.endpoint = value;
    this.endpointChanged = true;
  }

  onEventChange(value, key) {
    this.webhook.event_types[key] = value;
  }

  onCancelClick() {
    this.WebhookModal.close();
    this.MixpanelService.track('Webhooks - Edit - Cancel');
  }

  onDeleteClick() {
    this.onDelete({ webhook: this.webhook });
    this.MixpanelService.track('Webhooks - Edit - Delete Button');
  }

  onSubmitForm() {
    if (this.updateWebhookForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    if (!this._hasOneEventTypeBeenSelected()) {
      toastError(this.$translate.instant('webhook.notifications.none-selected'));
      return;
    }

    this.loading = true;

    this.Webhooks.updateWebhookDetails(this.webhook)
      .then(() => {
        this.WebhookModal.close();
        this.MixpanelService.track('Webhooks - Edit - Save', {
          event_types: this.webhook.event_types,
          is_active: this.webhook.is_active,
        });
      })
      .catch((err) => {
        toastError(err.data && err.data.error);
        this.loading = false;
      });
  }

  // Checks the form for input with an id that starts with 'event'. Returns true on the first checkbox that has been checked.
  _hasOneEventTypeBeenSelected() {
    const formControllers = this.updateWebhookForm.$$controls;
    for (let i = 0; i < formControllers.length; i++) {
      if (
        formControllers[i].$$attr.esId &&
        formControllers[i].$$attr.esId.startsWith('event') &&
        formControllers[i].$modelValue === true
      )
        return true;
    }

    return false;
  }
}

const WebhookUpdateComponent = {
  template,
  controller: WebhookUpdate,
  bindings: {
    webhook: '<',
    onDelete: '&',
  },
};

export { WebhookUpdateComponent };
