import { InsuranceState, IClaimFeedback } from 'typings/insurance';
import { IShipmentAction, IShipmentListItem } from 'typings/shipment';
import { PillColor } from 'typings/pill';
import { ClaimStateKey, ClaimTypeKey } from '@client/data/insurance';
import { UNSHIPPED_STATUS_IDS } from '@client/data/shipment';

import { IComponentController } from 'angular';
import { InsuranceService } from '@client/src/global/services/insurance/insurance.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './insurance-breakdown.html?raw';
import style from './insurance-breakdown.module.scss';

class ShipmentInfoInsuranceBreakdownController implements IComponentController {
  style = style;
  esShipment?: IShipmentListItem;
  state: InsuranceState = 'uninsured';
  busy = false;
  form = { email_address: '', bcc_sender: false };
  wrappers = {
    claim: (chunk: string) => chunk,
    support: (chunk: string) => chunk,
  };

  static $inject = ['ShipmentAction', 'InsuranceService', '$translate'];
  constructor(
    private ShipmentAction: IShipmentAction,
    private InsuranceService: InsuranceService,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit(): void {
    this.state = this.InsuranceService.determineInsuredState(this.esShipment);
    this.form.email_address = this.esShipment?.email_address || '';
    this.wrappers = {
      claim: (chunk: string) =>
        `<a href='https://form.typeform.com/to/knRSQC' target='_blank' rel='noopener noreferrer'>${chunk}</a>`,
      support: (chunk: string) =>
        `<a href='${this.InsuranceService.getSupportUrl(
          this.state
        )}' target='_blank' rel='noopener noreferrer'>${chunk}</a>`,
    };
  }

  updateForm(value: string | boolean, key: 'email_address' | 'bcc_sender'): void {
    switch (key) {
      case 'email_address':
        this.form[key] = value as string;
        break;

      case 'bcc_sender':
      default:
        this.form[key] = value as boolean;
        break;
    }
  }

  sendEmailToBuyer(): ng.IPromise<void> {
    if (!this.esShipment) return Promise.reject();

    this.busy = true;
    return this.ShipmentAction.sendConsigneeEmail(this.esShipment.id, this.form)
      .then((data) => {
        if (this.esShipment) this.esShipment.consignee_notified_at = data.consignee_notified_at;
        toastSuccess(this.$translate.instant('shipments.modal.insurance.email-sent'));
      })
      .catch((reason?: { error: string }) => {
        toastError(
          this.$translate.instant(reason?.error || 'shipments.modal.insurance.email-failed')
        );
      })
      .finally(() => {
        this.busy = false;
      });
  }

  get alertMessage(): string | null {
    if (this.pastFilingWindow) {
      return 'shipments.modal.insurance.claim-note.too-late';
    }

    if (this.esShipment?.last_status_message?.status_group === 'cancelled') {
      return 'shipments.modal.insurance.claim-note.cancelled';
    }

    if (this.esShipment?.last_status_message?.status_group === 'To ship') {
      return 'shipments.modal.insurance.claim-note.not-shipped';
    }

    return '';
  }

  get isNotClaimed(): boolean {
    return (
      !!this.esShipment?.insurance_claim_state &&
      ['not_shipped', 'not_filed'].includes(this.esShipment?.insurance_claim_state)
    );
  }

  get pastFilingWindow(): boolean {
    if (!this.esShipment?.claim_filing_window_closes) return false;

    const filingWindowCloseDate = new Date(this.esShipment.claim_filing_window_closes);

    return filingWindowCloseDate.valueOf() < Date.now();
  }

  get showClaimByDate(): boolean {
    return this.esShipment?.claimable || this.pastFilingWindow;
  }

  get hasGeneratedLabel(): boolean {
    return !!this.esShipment?.label_state && this.esShipment.label_state !== 'technical_failed';
  }

  get pillColor(): PillColor {
    return this.InsuranceService.getPolicyColor(this.state);
  }

  get claimPortalUrl(): string | null {
    return !this.esShipment?.tracking_number
      ? null
      : `https://claims.easyship.com/claim?track=${this.esShipment?.tracking_number}&email=${this.esShipment?.email_address}`;
  }

  get claimStatus(): IClaimFeedback {
    if (!this.esShipment) return { state: ClaimStateKey.NotFiled };
    const { last_status_message: lastStatusMessage, insurance_claim_state: insuranceClaimState } =
      this.esShipment;

    if (
      lastStatusMessage &&
      lastStatusMessage.id &&
      UNSHIPPED_STATUS_IDS.includes(lastStatusMessage.id)
    )
      return { state: ClaimStateKey.NotShipped };

    switch (insuranceClaimState) {
      case 'filed_by_client':
        return { state: ClaimStateKey.FiledByClient };
      case 'filed_to_courier':
        return { state: ClaimStateKey.FiledToCourier };
      case 'filed_to_courier_damaged':
        return { state: ClaimStateKey.FiledToCourier, type: ClaimTypeKey.DamagedOrMissing };
      case 'filed_to_courier_lost':
        return { state: ClaimStateKey.FiledToCourier, type: ClaimTypeKey.Lost };
      case 'accepted_damaged':
        return { state: ClaimStateKey.Accepted, type: ClaimTypeKey.DamagedOrMissing };
      case 'accepted_lost':
        return { state: ClaimStateKey.Accepted, type: ClaimTypeKey.Lost };
      case 'rejected_damaged':
        return { state: ClaimStateKey.Rejected, type: ClaimTypeKey.DamagedOrMissing };
      case 'rejected_lost':
        return { state: ClaimStateKey.Rejected, type: ClaimTypeKey.Lost };
      case 'not_filed':
      default:
        return { state: ClaimStateKey.NotFiled };
    }
  }

  get showClaimStatus(): boolean {
    return (
      !['not_shipped', 'not_filed'].includes(this.claimStatus.state) &&
      this.state !== 'buyer-insured'
    );
  }

  get isClaimable(): boolean {
    return !!this.esShipment?.claimable && !this.pastFilingWindow;
  }

  get insureshipError(): boolean {
    // This is when insureship sends back an error of their side but our BE still created the claim.
    return !!this.esShipment?.claim?.check_certify && !this.esShipment?.claim?.provider_claim_id;
  }
}

const ShipmentInfoInsuranceBreakdownComponent: ng.IComponentOptions = {
  controller: ShipmentInfoInsuranceBreakdownController,
  template,
  bindings: {
    esShipment: '<',
    esStartClaimFlow: '&',
  },
};

export { ShipmentInfoInsuranceBreakdownComponent };
