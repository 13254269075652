import angular from 'angular';
import '@iamadamjowett/angular-click-outside';

import easyshipCheckbox from '@client/core/components/forms/checkbox';
import esPlatformLogo from '@client/src/global/dashboard-component-library/platform-logo';
import esCourierLogoAndName from '@client/src/global/dashboard-component-library/CourierLogoAndNameComponent';

import { MultiselectComponent } from './multiselect.component';

const MODULE_NAME = 'core.components.multiselect';

angular
  .module(MODULE_NAME, [
    'angular-click-outside',
    easyshipCheckbox,
    esPlatformLogo,
    esCourierLogoAndName,
  ])
  .component('easyshipMultiselect', MultiselectComponent);

export default MODULE_NAME;
