import angular, { IComponentController } from 'angular';

import { ICountryService } from 'typings/auth/services/country';
import { IUserSession } from 'typings/user-session';

import { DrawerMenuKey, DrawerStatus } from '@client/src/global/services/shipment-list/data/drawer';
import { IShipmentListFiltersData } from '@client/src/global/services/shipment-list/shipment-list-normalize.service';

import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { TagsService } from '@client/src/global/services/tags/tags.service';
import style from './shipments-advanced-search-sidebar.module.scss';
import template from './shipments-advanced-search-sidebar.html?raw';

const DEFAULT_FILTERS_DATA: IShipmentListFiltersData = {
  [DrawerMenuKey.OrderStatus]: null,
  [DrawerMenuKey.MissingField]: null,
  [DrawerMenuKey.ShipmentCreationDate]: null,
  [DrawerMenuKey.OrderPlacedDate]: null,
  [DrawerMenuKey.LabelPaymentDate]: null,
  [DrawerMenuKey.NumberOfItems]: null,
  [DrawerMenuKey.SkuCount]: null,
  [DrawerMenuKey.Weight]: null,
  [DrawerMenuKey.TBC]: null,
  [DrawerMenuKey.Insurance]: null,
  [DrawerMenuKey.Incoterms]: null,
  [DrawerMenuKey.WarehouseStatus]: null,
  [DrawerMenuKey.Pickups]: null,
  [DrawerMenuKey.Batches]: null,
  [DrawerMenuKey.Manifests]: null,
  [DrawerMenuKey.Boxes]: null,
  [DrawerMenuKey.DestinationCountry]: null,
  [DrawerMenuKey.DestinationState]: null,
  [DrawerMenuKey.Couriers]: null,
  [DrawerMenuKey.Origins]: null,
  [DrawerMenuKey.ShipmentStatus]: null,
  [DrawerMenuKey.FulfillmentStatus]: null,
  [DrawerMenuKey.LabelStatus]: null,
  [DrawerMenuKey.StoresOrPlatform]: null,
  [DrawerMenuKey.ProductSKU]: null,
  [DrawerMenuKey.Tags]: null,
  [DrawerMenuKey.EstimatedDeliveryDate]: null,
  [DrawerMenuKey.ItemDescription]: null,
  [DrawerMenuKey.CollectState]: null,
  [DrawerMenuKey.OrderNumber]: null,
  [DrawerMenuKey.ShipmentId]: null,
  [DrawerMenuKey.PickLocation]: null,
};

interface IMenuList {
  title: string;
  key: DrawerMenuKey;
  type: DrawerStatus.Advanced | DrawerStatus.Manage | DrawerStatus.All;
  display?: boolean;
}

class ShipmentsAdvancedSearchSidebar implements IComponentController {
  esPageName = '';
  esOnClose(): void {} // eslint-disable-line @typescript-eslint/no-empty-function
  esOnApply: (data?: { data: IShipmentListFiltersData }) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  readonly drawerStatus = DrawerStatus;
  readonly drawerMenuKey = DrawerMenuKey;

  style = style;
  isReturnPage = false;
  hasReturnsPageBetaAccess =
    this.UserSession.getCompanyDashboardSettings().beta_feature_returns_page;
  currentStatus = DrawerStatus.Menu;
  currentContent: DrawerMenuKey | null = null;
  drawerMenuList: IMenuList[] = [];
  isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  esType = DrawerStatus.Advanced;
  esQuery: IShipmentListFiltersData | null = null;
  filtersData: IShipmentListFiltersData = angular.copy(DEFAULT_FILTERS_DATA);
  tempData: IShipmentListFiltersData = angular.copy(DEFAULT_FILTERS_DATA);

  static $inject = [
    '$state',
    '$stateParams',
    '$translate',
    'UserSession',
    'TagsService',
    'CountryService',
    'MixpanelService',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private TagsService: TagsService,
    private CountryService: ICountryService,
    private MixpanelService: MixpanelService
  ) {}

  $onInit(): void {
    this.isReturnPage = this.esPageName === 'app.returns';

    this.CountryService.initCountries();

    // Translate using global keys
    const $tg = (key: string) => {
      return this.$translate.instant(`global.${key}`);
    };

    // Translate using filter keys
    const $tf = (key: string) => {
      return this.$translate.instant(`shipments.filter.${key}.title`);
    };

    this.drawerMenuList = [
      {
        title: $tf('order-status'),
        key: DrawerMenuKey.OrderStatus,
        type: DrawerStatus.Advanced,
      },
      {
        title: $tf('missing-field'),
        key: DrawerMenuKey.MissingField,
        type: DrawerStatus.Advanced,
      },
      {
        title: $tf(this.isReturnPage ? 'return-creation-date' : 'creation-date'),
        key: DrawerMenuKey.ShipmentCreationDate,
        type: DrawerStatus.All,
      },
      {
        title: $tf('order-placed-date'),
        key: DrawerMenuKey.OrderPlacedDate,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('label-payment-date'),
        key: DrawerMenuKey.LabelPaymentDate,
        type: DrawerStatus.Manage,
        display: !this.isReturnPage,
      },
      {
        title: $tf('estimated-delivery-date'),
        key: DrawerMenuKey.EstimatedDeliveryDate,
        type: DrawerStatus.Manage,
        display: !this.isReturnPage,
      },
      {
        title: $tf('item-number'),
        key: DrawerMenuKey.NumberOfItems,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('item-description'),
        key: DrawerMenuKey.ItemDescription,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('sku-count'),
        key: DrawerMenuKey.SkuCount,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('pick-location'),
        key: DrawerMenuKey.PickLocation,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('weight'),
        key: DrawerMenuKey.Weight,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('shipment-type'),
        key: DrawerMenuKey.TBC,
        type: DrawerStatus.Manage,
        display: !this.isReturnPage,
      },
      {
        title: $tf('insurance'),
        key: DrawerMenuKey.Insurance,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tg('taxes-and-duties'),
        key: DrawerMenuKey.Incoterms,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      // {
      //   title: $tf('warehouse-status'),
      //   key: DrawerMenuKey.WarehouseStatus,
      //   type: DrawerStatus.Manage,
      // },
      {
        title: $tg('pickups'),
        key: DrawerMenuKey.Pickups,
        type: DrawerStatus.Manage,
        display: !this.UserSession.isCompanyEfulfilment() && !this.isReturnPage,
      },
      {
        title: $tg('batches'),
        key: DrawerMenuKey.Batches,
        type: DrawerStatus.Manage,
        display: !this.isReturnPage,
      },
      {
        title: $tg('manifests'),
        key: DrawerMenuKey.Manifests,
        type: DrawerStatus.Manage,
        display: !this.isReturnPage,
      },
      {
        title: $tf('box'),
        key: DrawerMenuKey.Boxes,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('destination-country'),
        key: DrawerMenuKey.DestinationCountry,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('destination-state'),
        key: DrawerMenuKey.DestinationState,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tf('courier'),
        key: DrawerMenuKey.Couriers,
        type: DrawerStatus.All,
      },
      {
        title: $tf('origin-country'),
        key: DrawerMenuKey.Origins,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: this.isReturnPage ? $tf('return-shipment-status') : $tf('shipment-status'),
        key: DrawerMenuKey.ShipmentStatus,
        type: DrawerStatus.Manage,
      },
      {
        title: $tf('store_state'),
        key: DrawerMenuKey.FulfillmentStatus,
        type: DrawerStatus.Manage,
        display: !this.isReturnPage,
      },
      // TODO Implement later when data is confirmed
      // {
      //   title: $tf('label-status')
      //   key: DrawerMenuKey.LabelStatus,
      //   type: DrawerStatus.Manage,
      // },
      {
        title: $tf('store-platform'),
        key: DrawerMenuKey.StoresOrPlatform,
        type: DrawerStatus.All,
      },
      {
        title: $tf('product-sku'),
        key: DrawerMenuKey.ProductSKU,
        type: DrawerStatus.All,
        display: !this.isReturnPage,
      },
      {
        title: $tg('tags'),
        key: DrawerMenuKey.Tags,
        type: DrawerStatus.All,
      },
      {
        title: $tf('collection-status'),
        key: DrawerMenuKey.CollectState,
        type: DrawerStatus.Advanced,
        display: !!this.UserSession.company.dashboard_settings.show_tax_collect_filter,
      },
      {
        title: $tg('order-number'),
        key: DrawerMenuKey.OrderNumber,
        type: DrawerStatus.All,
      },
      {
        title: $tg('shipment-id'),
        key: DrawerMenuKey.ShipmentId,
        type: DrawerStatus.All,
      },
    ];
  }

  $onChanges(): void {
    if (this.esQuery) {
      this.filtersData = this.esQuery;
    } else if (this.esQuery === null) {
      this.filtersData = angular.copy(DEFAULT_FILTERS_DATA);
    }
  }

  onMenuClick(menu: DrawerMenuKey): void {
    const pageType = {
      'app.multiple': 'Create Shipments',
      'app.shipments': 'Manage Shipments',
      'app.returns': 'Returns',
    }[this.esPageName];

    this.MixpanelService.track(`${pageType} - Filters - ${menu}`);

    this.currentStatus = DrawerStatus.Content;
    this.currentContent = menu;
  }

  onBackToMenu(): void {
    this.backToMenu();
    this.resetTempData();
  }

  onDrawerClose(): void {
    this.backToMenu();
    this.esOnClose();
  }

  isShowSidebarItem(status: DrawerStatus, menuKey: DrawerMenuKey): boolean {
    switch (status) {
      case DrawerStatus.All:
        return true;
      default:
        if (menuKey === DrawerMenuKey.TBC) {
          return (
            this.esType === status &&
            this.UserSession.hasDomesticReturns() &&
            !this.hasReturnsPageBetaAccess
          );
        }

        if (menuKey === DrawerMenuKey.WarehouseStatus || menuKey === DrawerMenuKey.LabelStatus) {
          return this.esType === status && this.UserSession.isCompanyEfulfilment();
        }

        if (menuKey === DrawerMenuKey.Manifests) {
          return this.esType === status && this.UserSession.hasManifest();
        }

        if (menuKey === DrawerMenuKey.ShipmentStatus) {
          return (
            this.esType === status && this.$stateParams.sectionName === DrawerStatus.ShipmentsAll
          );
        }

        return this.esType === status;
    }
  }

  applyFilter(): void {
    Object.keys(this.tempData).forEach((key) => {
      const data = (this.tempData as any)[key];

      if (data || data === 0) {
        (this.filtersData as any)[key] = angular.copy(data);
      }
    });

    this.fetchShipments();
    this.onBackToMenu();
  }

  isFilterAvailable(menuKey: DrawerMenuKey): boolean {
    return !!this.filtersData[menuKey];
  }

  resetFilters(): void {
    this.resetTempData();
    this.filtersData = {
      ...DEFAULT_FILTERS_DATA,
    };
    this.resetShipments();
  }

  resetComponentFilter(): void {
    if (this.currentContent) {
      this.filtersData[this.currentContent] = null;
      this.tempData[this.currentContent] = null;

      if (this.currentContent === 'tags') {
        this.TagsService.resetSelections();
      }

      if (this.currentContent === 'batches') {
        this.$state.go('app.shipments', { batch_id: null });
      }
    }
    this.fetchShipments();
    this.backToMenu();
  }

  // AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<any>?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFilterChange(value: any, menuKey: DrawerMenuKey): void {
    this.tempData[menuKey] = value;
  }

  onEnterKey($event: KeyboardEvent): void {
    if ($event.code === 'Enter' && this.currentStatus === DrawerStatus.Content) {
      $event.preventDefault();
      this.applyFilter();
    }
  }

  get isFiltersEmpty(): boolean {
    return !(Object.keys(this.filtersData) as Array<keyof IShipmentListFiltersData>).some((key) => {
      if (this.hasReturnsPageBetaAccess || this.isReturnPage) {
        return this.filtersData && key !== DrawerMenuKey.TBC && this.filtersData[key];
      }

      return this.filtersData && this.filtersData[key];
    });
  }

  get filtersNumber(): number {
    return (Object.keys(this.filtersData) as Array<keyof IShipmentListFiltersData>).filter(
      (key) => {
        if (this.hasReturnsPageBetaAccess || this.isReturnPage) {
          return this.filtersData && key !== DrawerMenuKey.TBC && this.filtersData[key];
        }

        return this.filtersData && this.filtersData[key];
      }
    ).length;
  }

  private backToMenu(): void {
    this.currentStatus = DrawerStatus.Menu;
    this.currentContent = null;
  }

  private resetTempData(): void {
    this.tempData = { ...DEFAULT_FILTERS_DATA };
  }

  private fetchShipments(): void {
    this.esOnApply({
      data: this.filtersData,
    });
  }

  private resetShipments(): void {
    this.esOnApply();
  }
}

const ShipmentsAdvancedSearchSidebarComponent: ng.IComponentOptions = {
  controller: ShipmentsAdvancedSearchSidebar,
  template,
  bindings: {
    esIsOpen: '<',
    esOnClose: '&',
    esOnApply: '&',
    esType: '<',
    esQuery: '<',
    esPageName: '<',
  },
};

export { ShipmentsAdvancedSearchSidebarComponent };
