import { IApiConfig } from 'typings/core/config/api';
import {
  IPickupsParams,
  IPickupsPayload,
  IPickupGetSuccessResponse,
  IPickupTotalsSuccessResponse,
  IPickupsGetSupportInfoParams,
  IPickupsSupportInfoSuccessResponse,
  IPickupsReportPayload,
  IPickupsReportSuccessResponse,
  IPickupsMarkAsResolvedSuccessResponse,
  IPickupsCancelSuccessResponse,
  IPickupsEditPickupInfoGetSuccessResponse,
  IPickupsEditPickupInfoPayload,
  IPickupsEditPickupInfoPatchSuccessResponse,
} from 'typings/pickup';

const DEFAULT_PARAMS = { v: 'v1', company_type: 'cloud' };
const V2_BASE_PARAMS = { v: 'v2', company_type: null };

class PickupResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = this.$resource(
      `${this.API.baseEndpoint}/:v/:company_type/companies/:company_id/pickups/:pickup_id/:controller`,
      DEFAULT_PARAMS,
      {
        update: { method: 'PATCH' },
      }
    );
  }

  get(params: IPickupsParams, payload: IPickupsPayload): ng.IPromise<IPickupGetSuccessResponse> {
    const mergeParams = {
      ...V2_BASE_PARAMS,
      ...params,
    };

    return this.resource.save(mergeParams, payload).$promise;
  }

  /**
   * TODO: Should be deprecated and just use API v2 - PickupResource.get()
   * https://easyship.slack.com/archives/C0141AY367L/p1606187906009500
   */
  getFilterValues(params: IPickupsParams): ng.IPromise<IPickupGetSuccessResponse> {
    const mergeParams = {
      ...DEFAULT_PARAMS,
      ...params,
    };

    return this.resource.get(mergeParams).$promise;
  }

  totals(
    params: IPickupsParams,
    payload: IPickupsPayload
  ): ng.IPromise<IPickupTotalsSuccessResponse> {
    const mergeParams = {
      ...V2_BASE_PARAMS,
      ...params,
      controller: 'totals',
    };

    return this.resource.save(mergeParams, payload).$promise;
  }

  getSupportInfo(
    params: IPickupsGetSupportInfoParams
  ): ng.IPromise<IPickupsSupportInfoSuccessResponse> {
    const mergeParams = {
      ...params,
      controller: 'get_support_info',
    };

    return this.resource.get(mergeParams).$promise;
  }

  report(
    params: IPickupsGetSupportInfoParams,
    payload: IPickupsReportPayload
  ): ng.IPromise<IPickupsReportSuccessResponse> {
    const mergeParams = {
      ...params,
      controller: 'report',
    };

    return this.resource.save(mergeParams, payload).$promise;
  }

  markAsResolved(
    params: IPickupsGetSupportInfoParams
  ): ng.IPromise<IPickupsMarkAsResolvedSuccessResponse> {
    const mergeParams = {
      ...params,
      controller: 'mark_as_resolved',
    };

    return this.resource.save(mergeParams, {}).$promise;
  }

  cancel(params: IPickupsGetSupportInfoParams): ng.IPromise<IPickupsCancelSuccessResponse> {
    const mergeParams = {
      ...params,
      controller: 'cancel',
    };

    return this.resource.update(mergeParams, {}).$promise;
  }

  editPickupInfo(
    params: IPickupsGetSupportInfoParams
  ): ng.IPromise<IPickupsEditPickupInfoGetSuccessResponse> {
    const mergeParams = {
      ...params,
      controller: 'edit_pickup_info',
    };

    return this.resource.get(mergeParams, {}).$promise;
  }

  updatePickupInfo(
    params: IPickupsGetSupportInfoParams,
    payload: IPickupsEditPickupInfoPayload
  ): ng.IPromise<IPickupsEditPickupInfoPatchSuccessResponse> {
    const mergeParams = {
      ...params,
      controller: 'edit_pickup_info',
    };

    return this.resource.update(mergeParams, payload).$promise;
  }
}

export { PickupResource };
