import angular from 'angular';

import documentQueue from './document-queue';
import emptyQueue from './empty-queue';
import queueList from './queue-list';
import printPreviewModal from './print-preview-modal';

import { PrintingOptionsComponent } from './printing-options.component';

const MODULE_NAME = 'app.printing-options';

angular
  .module(MODULE_NAME, [
    'app.factory.UserSession',
    'app.global.helper',
    'app.global.services.printing-options',
    'app.global.services.pusher',
    'app.global.services.shipment-list',
    documentQueue,
    emptyQueue,
    queueList,
    printPreviewModal,
  ])
  .component(`esPrintingOptions`, PrintingOptionsComponent);

export default MODULE_NAME;
