import template from './contact-sales-success.html?raw';
import style from './contact-sales-success.module.scss';
import logoDarkUrl from '@assets/images/logos/easyship-logo/logo-dark.svg';
import linkCourierSuccessImageUrl from '@assets/images/dashboard/contact-sales-modal/link-courier-success.svg';
import mobileBackgroundImageUrl from '@assets/images/dashboard/signup-modal/mobile-background.svg';

class ContactSalesSuccess {
  logoDarkUrl = logoDarkUrl;
  linkCourierSuccessImageUrl = linkCourierSuccessImageUrl;
  mobileBackgroundImageUrl = mobileBackgroundImageUrl;
  static $inject = ['DeviceService', 'ContactSalesModal', '$compile', '$element', '$scope'];

  constructor(DeviceService, ContactSalesModal, $compile, $element, $scope) {
    this.style = style;
    this.DeviceService = DeviceService;
    this.ContactSalesModal = ContactSalesModal;
    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
  }

  closeModal() {
    this.ContactSalesModal.close();
  }

  $onInit() {
    this.isMobile = this.DeviceService.device === 'mobile';
    this.isNewSignup = this.location === 'New signup';
    this.isLinkCourier = this.location === 'Link courier';
    this.isACH = this.location === 'ACH';

    this.wrappers = {
      a: (text) => `<a ui-sref="app.quote">${text}</a>`,
    };
  }
}

const ContactSalesSuccessComponent = {
  template,
  controller: ContactSalesSuccess,
  bindings: {
    courier: '<',
    location: '<',
  },
};

export { ContactSalesSuccessComponent };
