import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import ProgressIndicator from '@client/core/components/react/ProgressIndicator';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface DuplicateShipmentMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onDuplicateShipment: () => Promise<void>;
}

export default function DuplicateShipmentMenuItem({
  divider = false,
  shipmentListItem,
  onDuplicateShipment,
}: DuplicateShipmentMenuItemProps) {
  const [isDuplicateShipmentLoading, setIsDuplicateShipmentLoading] = useState(false);
  const { last_status_message: lastStatusMessage } = shipmentListItem;

  async function handleDuplicateShipment() {
    setIsDuplicateShipmentLoading(true);
    await onDuplicateShipment();
    setIsDuplicateShipmentLoading(false);
  }

  if (lastStatusMessage?.id === 120) {
    return null;
  }

  return (
    <ProgressIndicator show={isDuplicateShipmentLoading}>
      <MenuItem
        onClick={handleDuplicateShipment}
        disabled={isDuplicateShipmentLoading}
        divider={divider}
      >
        <div style={baseStyleIconBox}>
          <i className="icon-duplicate-full text-xl" style={{ color: baseStyleIcon.colorGrey }} />
        </div>
        <span className="text-base">
          <FormattedMessage id="shipments.speed-dial.duplicate-shipment" />
        </span>
      </MenuItem>
    </ProgressIndicator>
  );
}
