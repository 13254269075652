import { IAppCuesIdentifyData, IAppCuesUserData } from 'typings/core/services/app-cues';
import { IUser, ICompany, IUserSession, FeatureFlags } from 'typings/user-session';
import { ICompanyActivity } from 'typings/company';
import { IStore } from 'typings/store';

class AppCuesService {
  private readonly appcuesUrl = '//fast.appcues.com/71999.js';
  private loadScriptPromise: Promise<unknown> | null = null;

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  /*
   * This function should be called on page load with a userId and some set
   * of user properties that you want to target by. The properties that are
   * sent to Appcues are what is used to determine if a user qualifies for
   * some Appcues content.
   */
  identify(identifyData: IAppCuesIdentifyData | IAppCuesUserData): void {
    // Disable for Easyship staff logged-in as another user
    if (this.UserSession.admin_email) return;

    this.checkIfScriptIsLoaded(() => {
      this.appcues.identify(
        this.UserSession.getUserId(), // unique, required
        identifyData
      );
    });
  }

  updateProperties(identifyData: Partial<IAppCuesIdentifyData>): void {
    // Disable for Easyship staff logged-in as another user
    if (this.UserSession.admin_email) return;

    this.checkIfScriptIsLoaded(() => {
      this.appcues.identify(
        this.UserSession.getUserId(), // unique, required
        identifyData
      );
    });
  }

  /*
   * This function should be called when the user has already been identified
   * once since the current page load, but you want Appcues to attempt to show
   * new content. We'll consult the list of content and the current user
   * properties and show content if the user qualifies.
   */
  page(): void {
    if (this.UserSession.admin_email) return;

    this.checkIfScriptIsLoaded(() => {
      this.appcues.page();
    });
  }

  track(
    eventName: string,
    attr?: Record<string, string | number | boolean | null> | null,
    triggerPage?: boolean,
    identifyWithProperties?: Partial<IAppCuesIdentifyData>
  ): void {
    if (this.UserSession.admin_email) return;

    this.checkIfScriptIsLoaded(() => {
      this.appcues.track(eventName, attr);

      if (identifyWithProperties) {
        this.updateProperties(identifyWithProperties);
      }

      if (triggerPage && !identifyWithProperties) this.appcues.page();
    });
  }

  reset(): void {
    this.checkIfScriptIsLoaded(() => {
      this.appcues.reset();
    });
  }

  identifyAndBuildAppCuesData(
    user: IUser,
    company: ICompany,
    activity?: ICompanyActivity,
    storeData?: { stores: IStore[] }
  ): void {
    this.identify(this.buildAppCuesIdentifyData(user, company, activity, storeData?.stores));
  }

  // eslint-disable-next-line
  private get appcues(): any {
    // eslint-disable-next-line
    return (window as any).Appcues;
  }

  private buildAppCuesIdentifyData(
    user: IUser,
    company: ICompany,
    activity?: ICompanyActivity,
    stores: IStore[] = []
  ): IAppCuesIdentifyData | IAppCuesUserData {
    const storeGroups = stores
      .map((store: IStore) => store.platform?.group)
      .filter((item, index, array) => array.indexOf(item) === index)
      .join(',');

    return Object.assign(activity || {}, {
      user_signup_date: user.created_at, // Unix timestamp of user signup date
      role: user.role && user.role.name, // Current user’s role or permissions
      user_id: user.id, // Current user's account ID
      first_name: user.first_name, // current user's first name
      // additional suggestions
      company_name: company.name, // Current user’s company name
      company_signup_drivers: company.signup_drivers?.join(',') || '', // Signup drivers
      company_feature_flags: !company.feature_flags
        ? []
        : (Object.keys(company.feature_flags).filter(
            (key) => !!company?.feature_flags[key as keyof FeatureFlags]
          ) as (keyof FeatureFlags)[]),
      email: user.email, // Current user's email
      company_signup_date: company.created_at,
      company_type: company.service, // BE is using a different key name
      easyship_company_id: company.easyship_company_id,
      persona: user.persona,
      locale: user.dashboard_settings?.language?.code,
      origin_country: company.country_name,
      origin_country_id: company.country_id,
      api_saved: storeGroups,
      declared_platforms: company.marketing_settings?.platforms?.join(',') || '',
      expected_shipment_volume: company.expected_shipment_volume || '',
      company_special_type_id: company.company_special_type_id,
      point_of_contact_team: company.hubspot_properties?.point_of_contact_team,
      reached_habit_date: company.hubspot_properties?.reached_habit_date,
    });
  }

  private checkIfScriptIsLoaded(callback: () => void): void {
    if (this.appcues !== undefined && this.appcues !== null) {
      callback();
    } else {
      // Avoid multiple script injection which can happen on first load
      if (!this.loadScriptPromise) {
        // Load the script for Appcues
        this.loadScriptPromise = this.loadScript(this.appcuesUrl);
      }

      // Once loaded, call all the stack callback - Appcues methods
      this.loadScriptPromise
        .then(() => {
          callback();
        })
        .catch(() => {
          return null;
        });
    }
  }

  private loadScript(src: string): Promise<void> {
    // create a promise for the newScript
    return new Promise((resolve, reject): void => {
      // create an html script element
      const script = document.createElement('script');
      // set the source of the script element
      script.src = src;
      // set a listener when the script element finishes loading the script
      script.addEventListener('load', () => {
        // resolve if the script element loads
        resolve();
      });
      // set a listener when the script element faces any errors while loading
      script.addEventListener('error', (e) => {
        // reject if the script element has an error while loading the script
        reject(e);
      });
      // append the script element to the body
      document.body.appendChild(script);
    });
  }
}

export { AppCuesService };
