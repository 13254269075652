(function () {
  'use strict';

  GenerateLabel.$inject = ['$resource', 'API'];
  function GenerateLabel($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(endpointUrl + '/:company_type/companies/:company_id/shipments/pend_label', {
      company_id: '@_company_id',
      company_type: '@_company_type',
    });
  }

  angular.module('app.factory.GenerateLabel', []).factory('GenerateLabel', GenerateLabel);
})();
