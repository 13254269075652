import angular from 'angular';

import esConnectCourier from './connect-courier';
import { CourierCreate } from './courier-create.service';
import { CourierCreateMainComponent } from './courier-create-main/courier-create-main.component';
import { ConnectCourierComponent } from './connect-courier/connect-courier.component';
// eslint-disable-next-line import/extensions
import { AngularChooseCourierComponent } from '../components/ChooseCourierComponent/index.tsx';

const MODULE_NAME = 'app.couriers.courier-create';

angular
  .module(MODULE_NAME, [esConnectCourier])
  .service('CourierCreate', CourierCreate)
  .component('chooseCourier', AngularChooseCourierComponent)
  .component('courierCreateMain', CourierCreateMainComponent)
  .component('connectCourier', ConnectCourierComponent);

export default MODULE_NAME;
