import analyticsIcon from '@assets/images/sidebar/icons/analytics.svg?raw';
import getQuoteIcon from '@assets/images/sidebar/icons/get-quote.svg?raw';
import createShipmentsIcon from '@assets/images/sidebar/icons/create-shipments.svg?raw';
import manageShipmentsIcon from '@assets/images/sidebar/icons/manage-shipments.svg?raw';
import returnsIcon from '@assets/images/sidebar/icons/returns.svg?raw';
import pickupsIcon from '@assets/images/sidebar/icons/pickups.svg?raw';
import connectIcon from '@assets/images/sidebar/icons/connect.svg?raw';
import webhookIcon from '@assets/images/sidebar/icons/webhook.svg?raw';
import productListingIcon from '@assets/images/sidebar/icons/product-listing.svg?raw';
import automationsIcon from '@assets/images/sidebar/icons/automations.svg?raw';
import couriersIcon from '@assets/images/sidebar/icons/couriers.svg?raw';
import settingsIcon from '@assets/images/sidebar/icons/settings.svg?raw';
import accountIcon from '@assets/images/sidebar/icons/account.svg?raw';
import supportIcon from '@assets/images/sidebar/icons/support.svg?raw';
import template from './dashboard-sidebar.html?raw';

(function () {
  angular.module('easyshipDashboardApp').directive('dashboardSidebar', dashboardSidebar);

  dashboardSidebar.$inject = [
    '$state',
    '$sce',
    '$timeout',
    '$window',
    '$location',
    'UserSession',
    'EndpointService',
    'StoreService',
    'API',
    'MixpanelService',
    'HelperService',
    'DeviceService',
    'SubscriptionService',
    'NotificationCentreService',
    'ShipmentListAdvancedService',
    'ShipmentListManageService',
    'AppCuesService',
  ];

  function dashboardSidebar(
    $state,
    $sce,
    $timeout,
    $window,
    $location,
    UserSession,
    EndpointService,
    StoreService,
    API,
    MixpanelService,
    HelperService,
    DeviceService,
    SubscriptionService,
    NotificationCentreService,
    ShipmentListAdvancedService,
    ShipmentListManageService,
    AppCuesService
  ) {
    return {
      template,
      restrict: 'EA',
      link(scope, element) {
        scope.UserSession = UserSession;
        scope.state = $state;
        scope.EndpointService = EndpointService;
        scope.StoreService = StoreService;
        scope.API = API;
        scope.DeviceService = DeviceService;
        scope.SubscriptionService = SubscriptionService;
        scope.ShipmentListAdvancedService = ShipmentListAdvancedService;

        scope.showReturns =
          UserSession.getCompanyDashboardSettings().beta_feature_returns_page &&
          !!UserSession.hasReturnFeatureSupport();

        const toggleLinks = Array.prototype.slice.call(element[0].querySelectorAll('.toggle'));

        toggleLinks.forEach(function (toggleLink) {
          toggleLink.addEventListener('change', function () {
            _.map(toggleLinks, function (link) {
              if (toggleLink.checked && toggleLink.id !== link.id) link.checked = false;
            });
          });
        });
      },
      controller: [
        '$scope',
        'UserSession',
        '$stateParams',
        '$element',
        ($scope, UserSession, $stateParams, $element) => {
          if ($location.$$search.sidebaropen) {
            if (/connect/i.test($location.$$search.sidebaropen)) {
              $element.find('#toggle-connect').prop('checked', true);
            }
            if (/settings/i.test($location.$$search.sidebaropen)) {
              $element.find('#toggle-tools').prop('checked', true);
            }
            if (/account/i.test($location.$$search.sidebaropen)) {
              $element.find('#toggle-account').prop('checked', true);
            }
          }

          $scope.user = UserSession.user;
          $scope.isLuxUser = UserSession.isLuxUser();
          $scope.analyticsIcon = $sce.trustAsHtml(analyticsIcon);
          $scope.getQuoteIcon = $sce.trustAsHtml(getQuoteIcon);
          $scope.createShipmentsIcon = $sce.trustAsHtml(createShipmentsIcon);
          $scope.manageShipmentsIcon = $sce.trustAsHtml(manageShipmentsIcon);
          $scope.returnsIcon = $sce.trustAsHtml(returnsIcon);
          $scope.pickupsIcon = $sce.trustAsHtml(pickupsIcon);
          $scope.connectIcon = $sce.trustAsHtml(connectIcon);
          $scope.webhookIcon = $sce.trustAsHtml(webhookIcon);
          $scope.productListingIcon = $sce.trustAsHtml(productListingIcon);
          $scope.automationsIcon = $sce.trustAsHtml(automationsIcon);
          $scope.couriersIcon = $sce.trustAsHtml(couriersIcon);
          $scope.settingsIcon = $sce.trustAsHtml(settingsIcon);
          $scope.accountIcon = $sce.trustAsHtml(accountIcon);
          $scope.supportIcon = $sce.trustAsHtml(supportIcon);

          $scope.logout = function () {
            const isSsoSession = $window.localStorage.getItem('ssoUrl');
            AppCuesService.reset();

            $timeout(function () {
              if ($scope.isLuxUser) {
                window.location = '/logout?sso=luxottica';
              } else if (isSsoSession) {
                window.location = '/logout?sso=session';
              } else {
                window.location = '/logout';
              }
            }, 1000);
          };

          $scope.currentSection = function () {
            return $stateParams.sectionName;
          };

          $scope.goToShipments = function (shipmentsScope) {
            ShipmentListManageService.resetAll();
            $state.go(
              'app.shipments',
              {
                sectionName:
                  shipmentsScope === 'to_download'
                    ? shipmentsScope
                    : HelperService.snakeToKebabCase(shipmentsScope),
                page: 1,
                query: { scope: shipmentsScope },
                view_id: null,
              },
              { reload: true }
            );
          };

          $scope.convertToThousands = function (number) {
            if (number >= 10000) {
              const thousands = `${(number / 1000).toFixed(1)}k`;
              return thousands;
            }
            return number;
          };

          $scope.isNotificationAvailable = function () {
            return NotificationCentreService.isAvailable;
          };

          $scope.getPlanName = function () {
            return SubscriptionService.getPlanNameByFeatureKey('pick_and_pack');
          };

          $scope.isPlanBadgeVisible = function () {
            return SubscriptionService.isPlanBadgeVisible('pick_and_pack');
          };

          $scope.showPickups = function () {
            return !UserSession.isCompanyEfulfilment();
          };

          $scope.isSettingsLinkActive = function (state) {
            return (
              state.is('settings') ||
              state.is('printing-options') ||
              state.is('customer-emails') ||
              state.is('tracking-page') ||
              state.is('packing') ||
              state.is('notifications') ||
              state.is('insurance') ||
              state.is('boxes')
            );
          };
        },
      ],
    };
  }
})();
