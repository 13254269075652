import angular from 'angular';

// Services
import helperService from '@client/core/services/helper';
import subscriptionService from '@client/src/global/services/subscription';
import adjustmentsService from '@client/src/global/services/adjustments';
import autoRechargeService from '@client/src/global/services/auto-recharge';
import collectService from '@client/src/global/services/collect';
import pusherService from '@client/src/global/services/pusher';
import notificationCentreService from '@client/src/global/services/notification-centre';
import shipmentListService from '@client/src/global/services/shipment-list';
import pickupService from '@client/src/global/services/pickup';
import requestFeatureService from '@client/src/global/services/request-feature';
import shipmentViewsService from '@client/src/global/services/shipment-views';
import BrokersService from '@client/src/global/services/brokers';
import InsuranceService from '@client/src/global/services/insurance';
import InsurancePhotoResource from '@client/src/global/services/insurance-photo';
import OnboardingService from '@client/src/global/services/onboarding';

// Components
import subscriptionComponents from '@client/src/global/components/subscription';
import subscriptionPaymentFailedComponents from '@client/src/global/components/subscription-payment-failed';
import subscriptionLegacyTrialEndComponents from '@client/src/global/components/subscription-legacy-trial-end';
import subscriptionModalController from '@client/src/global/components/subscription-modal-controller';

import welcomeEnterprisePlanComponents from '@client/src/global/components/welcome-enterprise-plan';
import welcomeLegacyPlanComponents from '@client/src/global/components/welcome-legacy-plan';
import paymentComponents from '@client/src/global/components/payment';
import adjustmentsModal from '@client/src/global/adjustments-modal';
import courierComponents from '@client/src/global/components/courier';
import addressyInputForm from '@client/src/global/addressy-input-form';
import noRatesBanner from '@client/src/global/no-rates-banner';
import impersonationBanner from '@client/src/global/impersonation-banner';
import requestFeature from '@client/src/global/request-feature';
import bannerModalContainer from '@client/src/global/banner-modal-container';
import globalModals from '@client/src/global/modals';

import '@client/src/auth/pages/index';
import '@client/core/components';
import '@client/core/plugins';
import '@client/core/services';
import '@client/core/config';
import '@client/core/directives';
import './dashboard-component-library';
import './services';
import './StatusMessage';
import './Report';
import './payment';
import './PrintLabel';
import './FixMonkey';
import './ReschedulePickup';
import './user-status';
import './manifest';
import './courier-accounts';
import './contact-sales-modal';
import './order-summary';
import './User';
import './webhooks';
import './shipment';
import './Return';
import './export';
import './hubspot';
import './transaction-record';
import './growsumo';
import './optional-dimensions';
import './services/payment-intent';
import './services/payment-source';
import './lyoc-banner';
import { AngularAlert } from '@client/core/components/react/Alert';
import { AngularClaimStatus } from '@client/core/components/react/ClaimStatus';
import { AngularShippingRulesBenefits } from '@client/core/components/react/ShippingRulesBenefits';
import { AngularWeightInputDropdown } from '@client/core/components/react/WeightInputDropdown';
import { AngularVerificationRequiredBanner } from '@app/wrappers/AngularVerificationRequiredBanner';
import automationSummary from './automation-summary';
import globalComponents from './components';
import { AngularAccountUnderReviewBanner } from './banners/account-under-review-banner';
import { AngularSafariBugBanner } from './banners/safari-bug-banner';
import { AngularTrialExpiryBanner } from './banners/TrialExpiryBanner';
import { AngularBarcodeScanningBanner } from './banners/BarcodeScanningBanner';

angular
  .module('app.global', [
    'app.auth.pages.auth-pages',
    'core.config',
    'core.components',
    'core.services',
    'core.directives',
    'app.global.dashboard-component-library',
    globalComponents,
    globalModals,
    'app.global.services',
    'app.global.status-message',
    'app.global.report',
    'app.global.payment',
    'app.global.print-label',
    'app.global.fix-monkey',
    'app.global.reschedule-pickup',
    'app.global.user-status',
    'app.manifest',
    'app.global.courier-accounts',
    'app.global.contact-sales',
    'app.global.order-summary',
    helperService,
    'app.global.user',
    'app.global.webhooks',
    'app.global.shipment',
    'app.global.return',
    'app.global.export',
    'app.global.hubspot',
    'app.global.transaction-record',
    'app.global.growsumo',
    'app.global.optional-dimensions',
    'app.global.payment-intent',
    'app.global.payment-source',
    subscriptionService,
    subscriptionComponents,
    subscriptionPaymentFailedComponents,
    subscriptionLegacyTrialEndComponents,
    subscriptionModalController,
    welcomeEnterprisePlanComponents,
    welcomeLegacyPlanComponents,
    paymentComponents,
    courierComponents,
    'app.global.lyoc-banner',
    noRatesBanner,
    adjustmentsModal,
    adjustmentsService,
    autoRechargeService,
    collectService,
    addressyInputForm,
    impersonationBanner,
    automationSummary,
    pusherService,
    notificationCentreService,
    shipmentListService,
    pickupService,
    requestFeature,
    requestFeatureService,
    shipmentViewsService,
    BrokersService,
    InsuranceService,
    bannerModalContainer,
    InsurancePhotoResource,
    OnboardingService,
  ])
  .component('esAccountUnderReviewBanner', AngularAccountUnderReviewBanner)
  .component('esSafariBugBanner', AngularSafariBugBanner)
  .component('esAlert', AngularAlert)
  .component('esTrialExpiryBanner', AngularTrialExpiryBanner)
  .component('esBarcodeScanningBanner', AngularBarcodeScanningBanner)
  .component('esVerificationRequiredBanner', AngularVerificationRequiredBanner)
  .component('esClaimStatus', AngularClaimStatus)
  .component('esShippingRulesBenefits', AngularShippingRulesBenefits)
  .component('esWeightInputDropdown', AngularWeightInputDropdown);
