ReturnResource.$inject = ['$resource', 'API'];
function ReturnResource($resource, API) {
  this.endpoint = `${API.endpoint}/cloud/companies/:company_id/shipments/:id/return/:controller`;

  return $resource(
    this.endpoint,
    { company_id: '@_company_id', id: '@_id' },
    {
      calculate: { method: 'POST', params: { controller: 'calculate' } },
      prepare: { method: 'POST', params: { controller: 'prepare' } },
      confirm: { method: 'POST', params: { controller: 'confirm' } },
      sendEmail: { method: 'POST', params: { controller: 'send_email' } },
    }
  );
}

export { ReturnResource };
