import React from 'react';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { react2angular } from 'react2angular';
import { useIntl } from 'react-intl';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import { WeightUnits } from 'typings/shipment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import COLORS from '@client/src/colors';
import Select from './Select';
import Input, { InputProps } from './Input';

interface IWeightInputDropdownProps extends Omit<InputProps, 'onChange'> {
  onChange: (selectedUnit: WeightUnits, value: string, parcelIndex?: number) => void;
  value: number;
  unit: WeightUnits;
  errorStatus: IFieldErrors;
  disabled?: boolean;
  parcelIndex?: number;
}

type IFieldErrors = 'error' | 'default';
const IMPERIAL_OPTIONS = [
  { label: 'lb', value: 'lb' },
  { label: 'oz', value: 'oz' },
];

const METRIC_OPTIONS = [
  { label: 'kg', value: 'kg' },
  { label: 'g', value: 'g' },
];

const useStyles = makeStyles(() => ({
  input: {
    borderRight: 'none',
    '&.Mui-focused': {
      border: '0.625px solid rgba(145, 158, 171, 1)',
    },
    '&.Mui-error': {
      border: '0.625px solid rgba(192, 15, 25, 1)',
    },
  },
  select: {
    borderLeft: 'none',
    '&.Mui-focused': {
      borderColor: COLORS.skyDark,
    },
  },
}));

export const WeightInputDropdown = React.forwardRef(function WeightInputDropdown(
  {
    onChange,
    value,
    unit,
    errorStatus,
    disabled,
    parcelIndex,
    ...props
  }: IWeightInputDropdownProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const UserSession = useUserSession();
  const optionsList = UserSession.company.weightUnit === 'kg' ? METRIC_OPTIONS : IMPERIAL_OPTIONS;
  const currentWeight = value?.toString() ?? '';
  const selectedUnit = {
    label: unit ?? optionsList[0].label,
    value: unit ?? (optionsList[0].value as WeightUnits),
  };

  return (
    <FormGroup row className="w-[150px] h-[65px] items-end">
      <Input
        {...props}
        ref={ref}
        label={formatMessage({ id: 'global.weight' })}
        placeholder="0"
        onChange={(e) => onChange(selectedUnit.value, e.target.value, parcelIndex)}
        value={currentWeight}
        className={clsx(classes.input, '!w-[80px] !rounded-r-none h-[40px]')}
        type="number"
        inputProps={{ min: 0 }}
        error={errorStatus === 'error'}
        disabled={disabled}
      />
      <Select
        className={clsx(classes.select, '!rounded-l-none h-[40px]')}
        value={selectedUnit.value}
        onChange={(e) => onChange(e.target.value as WeightUnits, currentWeight, parcelIndex)}
        disabled={disabled}
      >
        {optionsList.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormGroup>
  );
});

function WrappedWeightInputDropdown(props: IWeightInputDropdownProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <WeightInputDropdown {...props} ref={null} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularWeightInputDropdown = react2angular(WrappedWeightInputDropdown, [
  'onChange',
  'value',
  'unit',
  'errorStatus',
  'disabled',
  'parcelIndex',
]);
