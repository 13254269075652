import { IComponentController } from 'angular';
import template from './member-list-card.html?raw';
import style from './member-list-card.module.scss';

class MemberListCard implements IComponentController {
  style = style;
}

const MemberListCardComponent: ng.IComponentOptions = {
  controller: MemberListCard,
  template,
  bindings: {
    esTitle: '<',
    esStateText: '<',
    esStateType: '<',
    esAvatarColor: '<',
    esAction: '&',
  },
};

export { MemberListCardComponent };
