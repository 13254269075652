import template from './manifest-confirm.html?raw';
import style from './manifest-confirm.module.scss';

class ManifestConfirm {
  static $inject = ['ManifestModal'];

  constructor(ManifestModal) {
    this.style = style;
    this.ManifestModal = ManifestModal;
  }

  onConfirm() {
    this.generatingManifest = true;

    this.onConfirmGenerateManifest({ manifest: this.manifest })
      .then(this.ManifestModal.close)
      .finally(() => {
        this.generatingManifest = false;
      });
  }

  hasManifestLimit() {
    const courierWithManifestLimit = ['USPS'];
    const courierName = this.courier && this.courier.name;
    return courierWithManifestLimit.includes(courierName);
  }
}

const ManifestConfirmComponent = {
  template,
  controller: ManifestConfirm,
  bindings: {
    shipments: '<',
    error: '<',
    onCancel: '&',
    onConfirmGenerateManifest: '&',
    manifest: '<',
    courier: '<',
  },
};

export { ManifestConfirmComponent };
