import { useQuery } from '@tanstack/react-query';
import { usePlatformGateway } from '@client/src/platform/providers/PlatformGatewayProvider';
import { IAllActivePlatforms, IPublicPlatform } from 'typings/platform';
import { ActivePlatformData, PlatformData } from '../models/platform';

export function useActivePlatformsQuery() {
  const { platformGateway } = usePlatformGateway();

  return useQuery<IAllActivePlatforms, unknown, ActivePlatformData>({
    queryKey: ['platforms'],
    queryFn: () => platformGateway.getActivePlatforms(),
    select: (data) => {
      return {
        publicPlatforms: data.publicPlatforms?.map(mapPlatformDataFromApi),
        openAPIs: data.openAPIs?.map(mapPlatformDataFromApi),
        platforms: data.platforms.map(mapPlatformDataFromApi),
      };
    },
  });
}

function mapPlatformDataFromApi(platform: IPublicPlatform): PlatformData {
  return {
    id: platform.id,
    crowdfunding: platform.crowdfunding,
    featured: platform.featured,
    displayName: platform.display_name,
    cssClass: platform.css_class,
    name: platform.name,
  };
}
