import { toastError } from '@client/core/components/react/Toastify';
import template from './country-selector.html?raw';
import style from './country-selector.module.scss';

class CountrySelectorController {
  static $inject = ['CountryService'];

  constructor(CountryService) {
    this.CountryService = CountryService;
    this.style = style;
  }

  $onInit() {
    this.ngModel = this.ngModel || [];
    this.selectedContinent = {};
    this.selectedCountries = {};
    this.toggle = {};
    this.countrySearchQuery = '';
    this.loading = true;

    this.accessorKey = this.esCountryKey || 'alpha2';

    this.CountryService.getCountriesByContinent()
      .then((response) => {
        this.loading = false;
        this.countriesByContinent = response.countriesByContinent;
        this.allCountries = response.countries;

        this._mapModelToSelected();

        if (this.esCollapseOnLoad) {
          this._collapseAll();
        }
      })
      .catch(() => {
        this.loading = false;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  _collapseAll() {
    Object.keys(this.countriesByContinent).forEach((continent) => {
      this.toggle[continent] = true;
    });
  }

  $onChanges() {
    this._mapModelToSelected();
  }

  _mapModelToSelected() {
    if (!this.allCountries) return;

    this.selectedCountries = this.ngModel.reduce((accumulator, identifier) => {
      const typedIdentifier = this.accessorKey === 'id' ? Number(identifier) : identifier;
      const country = this.allCountries.find((c) => c[this.accessorKey] === typedIdentifier);

      if (country) {
        accumulator[country.continent] = accumulator[country.continent] || {};
        accumulator[country.continent][typedIdentifier] = true;
      }

      return accumulator;
    }, {});
    this.selectedContinent = Object.keys(this.countriesByContinent).reduce(
      (accumulator, continent) => {
        accumulator[continent] = this._areAllCountriesSelected(continent);

        return accumulator;
      },
      {}
    );
  }

  _areAllCountriesSelected(continent) {
    if (!this.selectedCountries[continent]) return false;

    return (
      this.countriesByContinent[continent].length ===
      Object.keys(this.selectedCountries[continent]).filter((country) => {
        return this.selectedCountries[continent][country];
      }).length
    );
  }

  _getCountriesSelected() {
    return Object.keys(this.selectedCountries).reduce((accumulator, continent) => {
      return [...accumulator, ...this._getCountriesSelectedPerContinent(continent)];
    }, []);
  }

  _getCountriesSelectedPerContinent(continent) {
    return Object.keys(this.selectedCountries[continent]).filter((country) => {
      return this.selectedCountries[continent][country];
    });
  }

  onCountrySearchQueryChange = (value) => {
    this.countrySearchQuery = value;
  };

  hasPartialCountrySelected(continent) {
    if (!this.selectedCountries[continent]) return false;

    return !(
      !this._getCountriesSelectedPerContinent(continent).length ||
      this._areAllCountriesSelected(continent)
    );
  }

  onContinentChange(value, continent) {
    this.selectedCountries[continent] = this.selectedCountries[continent] || {};
    this.countriesByContinent[continent].forEach((country) => {
      this.selectedCountries[continent][country[this.accessorKey]] = value;
    });
    this.ngModel = this._getCountriesSelected();
    this.ngChange({ value: this.ngModel });
  }

  onCountryChange(value, country) {
    const { continent } = country;
    const accessorKeyValue = country[this.accessorKey];

    this.selectedCountries[continent] = this.selectedCountries[continent] || {};
    this.selectedCountries[continent][accessorKeyValue] = value;
    this.selectedContinent[continent] = this._areAllCountriesSelected(continent);
    this.ngModel = this._getCountriesSelected();
    this.ngChange({ value: this.ngModel });
  }
}

const CountrySelectorComponent = {
  template,
  controller: CountrySelectorController,
  bindings: {
    ngModel: '<',
    ngChange: '&',
    esCountryKey: '<',
    esCollapseOnLoad: '<',
    esAutoHeight: '<',
  },
};

export { CountrySelectorComponent };
