import { IApiConfig } from 'typings/core/config/api';
import { PickupHandlers } from 'typings/pickup-handlers';

class PickupHandlersResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = this.$resource(
      `${this.API.endpoint}/pickup_handlers`,
      {},
      {
        getHandlers: {
          method: 'GET',
          cache: true,
        },
      }
    );
  }

  get(params: PickupHandlers.IApiGetParams): ng.IPromise<PickupHandlers.IApiGetSuccessResponse> {
    return this.resource.getHandlers(params).$promise;
  }
}

export { PickupHandlersResource };
