import { IComponentController } from 'angular';
import { PrintingOptionsService } from '@client/src/global/services/printing-options/printing-options.service';
import { Reports } from 'typings/reports';
import template from './print-preview-modal.html?raw';
import style from './print-preview-modal.module.scss';

class PrintPreviewModal implements IComponentController {
  style = style;
  tabOptions: string[] = [];
  selectedTab: string | null = null;
  busy = {
    gettingGeoLocation: true,
  };
  country: string | null = null;
  unregisterWatcher: (() => void) | null = null;

  static $inject = ['PrintingOptionsService', '$scope'];
  constructor(private PrintingOptionsService: PrintingOptionsService, private $scope: ng.IScope) {}

  $onInit() {
    this.unregisterWatcher = this.$scope.$watch(
      () => this.PrintingOptionsService.previewDocuments,
      (previewDocuments: Reports.IPreviewDocuments[] | null | undefined) => {
        this.tabOptions =
          previewDocuments?.map((document) => `${document.name} (${document.format})`) ?? [];

        this.selectedTab = this.tabOptions[0] ?? null;
      }
    );
  }

  get pdfUrl(): string {
    return (
      this.PrintingOptionsService.previewDocuments?.find((document) => {
        return (
          this.selectedTab?.includes(document.name) && this.selectedTab?.includes(document.format)
        );
      })?.url || ''
    );
  }

  hideModal(): void {
    this.PrintingOptionsService.showPrintPreview = false;
    this.PrintingOptionsService.previewDocuments = null;
    this.unregisterWatcher?.();
  }

  onChangeTab(value: string): void {
    this.selectedTab = value;
  }
}

const PrintPreviewModalComponent: ng.IComponentOptions = {
  controller: PrintPreviewModal,
  template,
  bindings: {},
};

export { PrintPreviewModalComponent };
