import { IUserSession } from 'typings/user-session';
import {
  IShipmentGetPackingSlipsPayload,
  IShipmentGetPackingSlipsSuccessResponse,
} from 'typings/shipment';
import { ShipmentListResource } from '../shipment-list/shipment-list.resource';

class PackingSlipService {
  static $inject = ['UserSession', 'ShipmentListResource'];
  constructor(
    private UserSession: IUserSession,
    private ShipmentListResource: ShipmentListResource
  ) {}

  getPackingSlips(
    payload: IShipmentGetPackingSlipsPayload
  ): ng.IPromise<IShipmentGetPackingSlipsSuccessResponse> {
    const companyId = this.UserSession.getCompanyId() ?? '';
    const params = {
      company_id: companyId,
      company_type: 'cloud',
    };

    return this.ShipmentListResource.getPackingSlips(params, payload);
  }
}

export { PackingSlipService };
