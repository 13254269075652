import template from './webhook-card.html?raw';
import style from './webhook-card.module.scss';
import webhookIcon from '@assets/images/sidebar/icons/webhook.svg?svgo';

class WebhookCard {
  static $inject = ['$sce'];
  constructor($sce) {
    this.webhookIcon = $sce.trustAsHtml(webhookIcon);
    this.style = style;
  }
}

const WebhookCardComponent = {
  controller: WebhookCard,
  template,
  bindings: {
    endpointTitle: '<',
    endpointSubtitle: '<',
    isActive: '<',
    onClick: '&',
  },
};

export { WebhookCardComponent };
