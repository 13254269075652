import template from './address-form-hk.html?raw';

(function () {
  'use strict';

  var addressFormHk = {
    template,
    bindings: {
      address: '=',
      fieldValidators: '<',
    },
    controller: 'addressFormHkCtrl as ctrl',
  };

  angular.module('app.component.address-form-hk').component('addressFormHk', addressFormHk);
})();
