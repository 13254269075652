import { IComponentController } from 'angular';
import template from './drawer-menu-bar-item.html?raw';
import style from './drawer-menu-bar-item.module.scss';

class DrawerMenuBarItem implements IComponentController {
  style = style;
}

const DrawerMenuBarItemComponent: ng.IComponentOptions = {
  controller: DrawerMenuBarItem,
  template,
  transclude: true,
  bindings: {
    esOnClick: '&',
    esShowLeftBorder: '<',
  },
};

export { DrawerMenuBarItemComponent };
