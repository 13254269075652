import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { useInviteeGateway } from '../../providers/InviteeGatewayProvider';
import { SendCompanyInviteParams } from '../../gateways/ports/invitee';

export default function useSendInviteMutation() {
  const queryClient = useQueryClient();
  const { inviteeGateway } = useInviteeGateway();
  const { company } = useUserSession();

  return useMutation({
    mutationFn: ({ email, userRoles }: Pick<SendCompanyInviteParams, 'email' | 'userRoles'>) =>
      inviteeGateway.sendCompanyInvite({ email, userRoles, companyId: company.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['currentSubscription'],
      });
      queryClient.invalidateQueries({
        queryKey: ['companyInvitees'],
      });
    },
  });
}
