import template from './help-section.html?raw';

('use strict');

angular.module('easyshipDashboardApp').directive('helpSection', [
  'API',
  (API) => {
    return {
      template,
      restrict: 'EA',
      link: function (scope, element, attrs) {
        scope.API = API;
      },
    };
  },
]);
