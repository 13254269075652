import angular from 'angular';
import { AngularCourierLogoAndNameComponent } from './CourierLogoAndNameComponent';

const MODULE_NAME = 'app.global.dashboard-component-library.courier-logo-and-name';

angular
  .module(MODULE_NAME, [])
  .component('esCourierLogoAndName', AngularCourierLogoAndNameComponent);

export default MODULE_NAME;
