import angular from 'angular';
// eslint-disable-next-line import/extensions
import { AngularTemplateBlankComponent } from './index.tsx';

const MODULE_NAME = 'app.template-blank-component';

angular
  .module(MODULE_NAME, [])
  .component('esTemplateBlankComponent', AngularTemplateBlankComponent);

export default MODULE_NAME;
