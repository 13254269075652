/* eslint-disable max-classes-per-file */
import template from './email-preview.html?raw';

class EmailPreviewCtrl {
  static $inject = ['emailName', '$uibModalInstance'];

  constructor(emailName, $uibModalInstance) {
    this.emailName = emailName;
    this.$close = $uibModalInstance.close;
  }
}

class EmailPreview {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(name) {
    this.$uibModal.open({
      template,
      size: 'lg',
      controller: EmailPreviewCtrl,
      controllerAs: '$ctrl',
      bindToController: true,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        emailName: () => name,
      },
    });
  }
}

export { EmailPreview };
