import {
  IRequestFeatureParams,
  IRequestFeaturePayload,
  IRequestFeatureResponse,
} from 'typings/request-feature';
import { IApiConfig } from 'typings/core/config/api';

class RequestFeatureResource {
  endpoint = '';

  static $inject = ['$resource', 'API'];
  constructor(private $resource: any, private API: IApiConfig) {
    this.endpoint = `${this.API.endpoint}/users/:userId/feedback`;
  }

  submitRequest(
    params: IRequestFeatureParams,
    payload: IRequestFeaturePayload
  ): ng.IPromise<IRequestFeatureResponse> {
    return this.$resource(this.endpoint, params, {
      submit: {
        method: 'POST',
      },
    }).submit(payload).$promise;
  }
}

export { RequestFeatureResource };
