import { IComponentController } from 'angular';
import template from './modal-card-title.html?raw';
import style from './modal-card-title.module.scss';

const DEFAULT_OFFSET = 30;

class ModalCardTitle implements IComponentController {
  style = style;
  esShowCloseButton = true;
  esCloseButtonOffset = DEFAULT_OFFSET;

  $onInit() {
    if (this.esShowCloseButton === undefined) {
      this.esShowCloseButton = true;
    }

    if (this.esCloseButtonOffset === undefined) {
      this.esCloseButtonOffset = DEFAULT_OFFSET;
    }
  }
}

const ModalCardTitleComponent: ng.IComponentOptions = {
  controller: ModalCardTitle,
  template,
  transclude: true,
  bindings: {
    esShowCloseButton: '<',
    esCloseAction: '&',
    esCloseButtonOffset: '<',
  },
};

export { ModalCardTitleComponent };
