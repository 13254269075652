import { useQuery } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';

export function usePlatformsQuery() {
  const { platformGateway } = useGateways();

  return useQuery({
    queryKey: ['platforms'],
    queryFn: () => platformGateway.getActivePlatforms(),
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (data) => {
      return data.publicPlatforms;
    },
  });
}
