import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './platform-title.html?raw';

(function () {
  angular.module('easyshipDashboardApp').directive('platformTitle', platformTitle);

  function platformTitle() {
    const directive = {
      template,
      restrict: 'EA',
      controller,
      scope: {
        store: '=',
        customMessage: '@',
        platform: '@',
        platformImageSize: '@',
        editable: '@',
      },
    };

    return directive;
  }

  controller.$inject = ['$scope', '$translate', 'StoreService'];

  function controller($scope, $translate, StoreService) {
    $scope.busy = {};

    $scope.toggleEditMode = function () {
      copyStore();
      $scope.showEditNameForm = !$scope.showEditNameForm;
      emptyEditStoreNameForm();
    };

    $scope.ifStoreNameChangedUpdate = function () {
      if (!angular.equals($scope.store, $scope.copiedStore)) {
        updateStoreAndChangeName();
      } else {
        $scope.showEditNameForm = false;
      }
    };

    $scope.isBetaConnect = function (platformCssClass) {
      const betaPlatformCssClasses = [];

      return betaPlatformCssClasses.indexOf(platformCssClass) > -1;
    };

    function copyStore() {
      $scope.copiedStore = angular.copy($scope.store);
    }

    function emptyEditStoreNameForm() {
      $scope.updateStore = {};
    }

    function updateStoreAndChangeName() {
      $scope.busy.button = true;

      StoreService.updateStore($scope.copiedStore)
        .then(function (res) {
          $scope.store.name = res.store.name;
          $scope.showEditNameForm = false;
          $scope.busy.button = false;
          toastSuccess(
            $translate.instant('store.notifications.renamed', { store: res.store.name })
          );
        })
        .catch(function (err) {
          $scope.busy.button = false;

          if (err.data) {
            $scope.updateErrors = '';

            _.forEach(err.data, function (value, key) {
              if (!key || !value) return;
              const errorMessage = `${key} ${value}.`;
              $scope.updateErrors += errorMessage;
            });

            toastError(
              $translate.instant('store.notifications.error-list', { errors: $scope.updateErrors })
            );
          } else {
            toastError($translate.instant('toast.default-error'));
          }
        });
    }
  }
})();
