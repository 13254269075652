import { IUserSession } from 'typings/user-session';
import { IPrintingOptions } from 'typings/printing-options';
import { Reports } from 'typings/reports';
import { MixpanelEventContext } from '@client/core/services/mixpanel/constant';
import { toastError } from '@client/core/components/react/Toastify';
import { PrintPreviewModalProps } from '@client/src/manage-shipments/PrintPreviewModal/PrintPreviewModal';
import { ReportsResource } from '../reports/reports.resource';
import { PrintingOptionsResource } from './printing-options.resource';

const DEFAULT_LIMIT = 20;

export interface MarkAsPrintedParams {
  reportId: string;
  printMethod: Reports.IApiPrintedParams['print_method'];
}

class PrintingOptionsService {
  readonly limit = DEFAULT_LIMIT;
  showDocumentQueue = false;
  showPrintPreview = false;
  printPreviewModalProps: PrintPreviewModalProps | null = null;
  reports: Reports.IReport[] = [];
  previewDocuments: Reports.IPreviewDocuments[] | null = null;

  static $inject = [
    '$q',
    '$translate',
    'UserSession',
    'ReportsResource',
    'PrintingOptionsResource',
  ];
  constructor(
    private $q: ng.IQService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private ReportsResource: ReportsResource,
    private PrintingOptionsResource: PrintingOptionsResource
  ) {}

  getSizes(): ng.IPromise<IPrintingOptions.IGetSuccessResponse> {
    return this.PrintingOptionsResource.getSizes();
  }

  getReports(offset: number): ng.IPromise<Reports.IReport[]> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      toastError(this.$translate.instant('toast.default-error'));
      return this.$q.reject();
    }

    return this.ReportsResource.get({
      company_id: companyId,
      job_type: 'print_label',
      limit: this.limit,
      offset,
    }).then(({ reports }) => {
      if (offset === 0) {
        this.reports = reports;
      } else {
        this.reports = [...this.reports, ...reports];
      }

      return reports;
    });
  }

  markAsPrinted({
    reportId,
    printMethod,
  }: MarkAsPrintedParams): ng.IPromise<Reports.IApiPrintedSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    if (!companyId) {
      toastError(this.$translate.instant('toast.default-error'));
      return this.$q.reject();
    }

    return this.ReportsResource.printed({
      company_id: companyId,
      report_id: reportId,
      context: MixpanelEventContext.MANAGE_SHIPMENT,
      print_method: printMethod,
    });
  }
}

export { PrintingOptionsService };
