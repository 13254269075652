import { IHelperService } from 'typings/helper';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { IAuthService } from 'typings/core/services/auth';
import template from './luxottica-custom-search.html?raw';
import style from './luxottica-custom-search.module.scss';

class LuxotticaCustomSearchController implements IComponentController {
  style = style;
  isShowLuxotticaModal = false;
  busy = false;
  searchTerm = '';
  filteredAddressesList: { name: string; id: string }[] = [];

  static $inject = [
    'UserSession',
    'HelperService',
    'LuxotticaService',
    'Auth',
    '$window',
    '$translate',
  ];
  constructor(
    private UserSession: IUserSession,
    private HelperService: IHelperService,
    private LuxotticaService: any,
    private Auth: IAuthService,
    private $window: ng.IWindowService,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit(): void {
    this.LuxotticaService.queryLuxotticaManagedShops();
    this.updateFilteredAndHighlightedList();
    this.showToasterOnReload();
  }

  get isLuxUser(): boolean {
    return this.UserSession.isLuxUser();
  }

  toggleLuxotticaModal(): void {
    this.isShowLuxotticaModal = !this.isShowLuxotticaModal;
    this.resetSelectedSearchAddress();
    this.resetSearchTerm();
    this.updateFilteredAndHighlightedList();
  }

  queryLuxotticaManagedShops = () => {
    this.busy = true;
    this.LuxotticaService.queryLuxotticaManagedShops()
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy = false;
      });
  };

  createLuxotticaSession = () => {
    if (this.LuxotticaService.selectedSearchAddress) {
      this.busy = true;
      this.LuxotticaService.postLuxotticaSession(this.LuxotticaService.selectedSearchAddress)
        .then((response: any) => {
          const {
            session: { token: sessionToken },
          } = response;
          if (sessionToken) {
            this.Auth.setCredentials(sessionToken);
            this.reloadScreenWithNewSession();
          }
        })
        .catch(() => {
          toastError(this.$translate.instant('store.notifications.fallback-error'));
        })
        .finally(() => {
          this.busy = false;
        });
    } else {
      toastError(this.$translate.instant('shipment-info.custom.luxottica.select-account-to-login'));
    }
  };

  reloadScreenWithNewSession() {
    this.$window.localStorage.setItem('reloading', 'true');
    this.$window.location.reload();
  }

  showToasterOnReload() {
    const isReloading = this.$window.localStorage.getItem('reloading');
    if (isReloading) {
      this.$window.localStorage.removeItem('reloading');
      toastSuccess(this.$translate.instant('shipment-info.custom.luxottica.account-switched'));
    }
  }

  transformToSearchableAddress(addressObject: any) {
    return [addressObject.name].filter(Boolean).join(', ');
  }

  filterAddressesList() {
    this.filteredAddressesList =
      this.searchTerm.length > 0
        ? this.luxotticaShopsList.filter(
            (item: string) =>
              this.transformToSearchableAddress(item)
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) > -1
          )
        : this.luxotticaShopsList;
  }

  updateHighlightedList() {
    this.filteredAddressesList =
      (this.filteredAddressesList &&
        this.filteredAddressesList.map((item) => ({
          ...item,
          display_value: this.highlightSearchTerm(item),
        }))) ||
      [];
  }

  updateFilteredAndHighlightedList() {
    this.filterAddressesList();
    this.updateHighlightedList();
  }

  highlightSearchTerm = (itemToHighlight: { name: string; id: string }) => {
    const transformedItemToHighlight = this.transformToSearchableAddress(itemToHighlight);
    const highlightedText = this.HelperService.highlightInputTerm(
      transformedItemToHighlight,
      this.searchTerm,
      'strong'
    );
    return this.searchTerm ? highlightedText : transformedItemToHighlight;
  };

  setSelectedSearchAddress(shopId: string) {
    this.LuxotticaService.selectedSearchAddress = shopId;
  }

  isThisAddressSelected(shopId: boolean) {
    return this.LuxotticaService.selectedSearchAddress === shopId;
  }

  resetSelectedSearchAddress(): void {
    this.LuxotticaService.selectedSearchAddress = '';
  }

  resetSearchTerm(): void {
    this.searchTerm = '';
  }

  get luxotticaShopsList() {
    return this.LuxotticaService.luxotticaManagedShopsList;
  }
}

const LuxotticaCustomSearchComponent: ng.IComponentOptions = {
  controller: LuxotticaCustomSearchController,
  template,
  bindings: {},
};

export { LuxotticaCustomSearchComponent };
