import { createPopper, VariationPlacement } from '@popperjs/core';

import { IComponentController } from 'angular';
import template from './dropdown.html?raw';
import style from './dropdown.module.scss';

enum Animation {
  Drop = 'drop',
  Fade = 'fade',
}

class Dropdown implements IComponentController {
  style: any = style;
  showDropdown = false;
  esWidth = null;
  esMaxWidth = null;
  esIsOpen = false;
  esAnimation = Animation.Drop;
  esPlacement: VariationPlacement = 'bottom-start';
  esOnToggle?: (changes: { isOpen: boolean }) => void;

  static $inject = ['$element'];
  constructor(private $element: ng.IAugmentedJQuery) {
    this.style = style;
  }

  $onInit(): void {
    this.esAnimation = this.esAnimation || Animation.Drop;
    this.esPlacement = this.esPlacement || 'bottom-start';
  }

  $onChanges(): void {
    if (this.esIsOpen || this.esIsOpen === false) {
      this.showDropdown = this.esIsOpen;
      if (this.esOnToggle) this.esOnToggle({ isOpen: this.esIsOpen });

      if (this.esIsOpen) {
        this.showAndCalculateDropdownPosition();
      }
    }
  }

  get isInitializerAnInput(): boolean {
    return !!this.$element.find('dropdown-initializer input').length;
  }

  toggleDropdown(): void {
    if (this.isInitializerAnInput) return;

    if (this.showDropdown) {
      this.hideDropdown();
    } else {
      this.showAndCalculateDropdownPosition();
    }

    if (this.esOnToggle) this.esOnToggle({ isOpen: this.showDropdown });
  }

  onClickOutside(): void {
    this.hideDropdown();

    if (this.esOnToggle) this.esOnToggle({ isOpen: false });
  }

  onInputKeydown($event: KeyboardEvent): void {
    if (this.isInitializerAnInput) return;

    $event.preventDefault();

    switch ($event.code) {
      case 'Space':
        this.toggleDropdown();
        break;
      case 'Escape':
        this.hideDropdown();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        this.showAndCalculateDropdownPosition();
        break;
    }
  }

  private showAndCalculateDropdownPosition(): void {
    this.showDropdown = true;

    const initializer = this.$element.find(`.${this.style.initializer}`)[0];
    const popper = this.$element.find(`.${this.style.popper}`)[0];

    if (this.esMaxWidth) {
      popper.style.minWidth = this.esWidth ? `${this.esWidth}px` : `${initializer.offsetWidth}px`;
      popper.style.maxWidth = `${this.esMaxWidth}px`;
    } else {
      popper.style.width = this.esWidth ? `${this.esWidth}px` : `${initializer.offsetWidth}px`;
    }

    popper.style.height = 'auto';

    createPopper(initializer, popper, {
      placement: this.esPlacement,
    });
  }

  private hideDropdown(): void {
    this.showDropdown = false;
  }
}

const DropdownComponent: ng.IComponentOptions = {
  controller: Dropdown,
  template,
  transclude: {
    initializer: 'dropdownInitializer',
    content: 'dropdownContent',
  },
  bindings: {
    esWidth: '<',
    esMaxWidth: '<',
    esIsOpen: '<',
    esAnimation: '@',
    esPlacement: '@',
    esOnToggle: '&',
  },
};

export { DropdownComponent };
