import { toastSuccess } from '@client/core/components/react/Toastify';
import template from './merge-selected-same-destination.html?raw';
import style from './merge-selected-same-destination.module.scss';

class MergeSelectedSameDestination {
  static $inject = [
    'API',
    '$state',
    'MergeSelectedModal',
    'ShipmentListAdvancedService',
    'ShipmentList',
    'EndpointService',
    'MixpanelService',
    '$timeout',
    '$translate',
  ];

  constructor(
    API,
    $state,
    MergeSelectedModal,
    ShipmentListAdvancedService,
    ShipmentList,
    EndpointService,
    MixpanelService,
    $timeout,
    $translate
  ) {
    this.style = style;
    this.API = API;
    this.$state = $state;
    this.MergeSelectedModal = MergeSelectedModal;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.ShipmentList = ShipmentList;
    this.EndpointService = EndpointService;
    this.MixpanelService = MixpanelService;
    this.$timeout = $timeout;
    this.$translate = $translate;
  }

  $onInit() {
    this.shipmentIds = this._prepareShipmentIds(this.shipments);
  }

  onSubmit() {
    this.MixpanelService.track('Multiple - Select Merge - Confirm', { single_address: true });
    this.busy = true;

    this.ShipmentListAdvancedService.mergeSelectedConfirm(this.shipmentIds, this.shipments[0].id)
      .then((response) => {
        // Need to add timeout for shipment reindex (Elastic search)
        this.$timeout(() => {
          this.busy = false;
          this.MergeSelectedModal.close();

          toastSuccess(
            this.$translate.instant('merge-selected.success', {
              initial_shipments_count: this.shipments.length,
              merged_shipments_count: 1,
            })
          );

          this.ShipmentListAdvancedService.resetShipmentActionsConfirm();
          this.ShipmentListAdvancedService.setUpdatedShipments(response.newly_merged_shipment_id);
          this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter('orders_all');
        }, 1000);
      })
      .catch((error) => {
        this.busy = false;
        this.error =
          (error.data && error.data.status) || 'Sorry, something went wrong, please try again.';
      });
  }

  _prepareShipmentIds(shipments) {
    return shipments.map((shipment) => shipment.id);
  }

  onCancelClick() {
    this.MixpanelService.track('Multiple - Select Merge - Cancel');
    this.MergeSelectedModal.close();
  }
}

const MergeSelectedSameDestinationComponent = {
  template,
  controller: MergeSelectedSameDestination,
  bindings: {
    shipments: '<',
  },
};

export { MergeSelectedSameDestinationComponent };
