import React from 'react';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';

import { Box, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { HelpContactSupportFeature } from '../types';

const MIXPANEL_SOURCE_NAMES = {
  email: 'Email',
  chat: 'Live Chat',
  call: 'Call Support',
};

interface IUpgradeButtonProps {
  feature: HelpContactSupportFeature;
  onUpgradeClick: () => void;
}

export default function UpgradeButton({ feature, onUpgradeClick }: IUpgradeButtonProps) {
  function handleUpgrade() {
    onUpgradeClick();
    MixpanelService.track('Subscription - Plans - Open', {
      trigger_source: MIXPANEL_SOURCE_NAMES[feature],
    });
  }
  return (
    <Box clone width={150}>
      <Button variant="contained" onClick={handleUpgrade}>
        <FormattedMessage id="global.upgrade" />
      </Button>
    </Box>
  );
}
