(function () {
  'use strict';

  ShopifyService.$inject = [
    '$q',
    '$window',
    'PlatformApi',
    'UserSession',
    'StoreService',
    'Auth',
    'MixpanelService',
  ];
  function ShopifyService(
    $q,
    $window,
    PlatformApi,
    UserSession,
    StoreService,
    Auth,
    MixpanelService
  ) {
    var service = this;

    service.getShopifyKey = function (store) {
      return $q(function (resolve, reject) {
        PlatformApi.shopify.getAPIKey(
          { company_id: UserSession.company.id },
          {
            id: store.id,
            shopify: { shop: store.url || store.store_url },
          },
          function (response) {
            $window.localStorage.setItem('nonce', response.nonce);
            StoreService.saveStoreValueToLocal(store);
            resolve(response.url);
          },
          function (error) {
            reject(error.data);
          }
        );
      });
    };

    service.checkValidShopifyResponse = function (locationObject) {
      return $q(function (resolve, reject) {
        //Ping the session to be sure that UserSession exists
        Auth.retrieveSession()
          .then(function () {
            PlatformApi.shopify.createAccessToken(
              {
                company_id: UserSession.company.id,
              },
              {
                shopify: locationObject,
                store: {},
              },
              function (response) {
                StoreService.removeStoreValueFromLocal();
                resolve(response);
              },
              function (error) {
                reject(error);
              }
            );
          })
          .catch(function () {
            reject({ message: 'Shopify authorization failed' });
          });
      });
    };

    service.createCarrierServiceOrUpgradeShopify = function (store) {
      return $q(function (resolve, reject) {
        // Check if our version of the Shopify key is > 1 otherwise need to request additional rights
        if (store && store.platform && store.platform.name === 'Shopify' && !(store.version > 1)) {
          $window.localStorage.setItem('enable_easyship_rates', true);
          service.getShopifyKey(store.username);
        } else {
          createCarrierService(store)
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        }
      });
    };

    service.checkCreateCarrierService = function (store) {
      return $q(function (resolve, reject) {
        // After a Shopify store is added or updated, check if it is needed to install the Carier Service
        if (
          store.platform.name === 'Shopify' &&
          $window.localStorage.getItem('enable_easyship_rates') === 'true'
        ) {
          $window.localStorage.removeItem('enable_easyship_rates');
          createCarrierService(store)
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        } else {
          reject();
        }
      });
    };

    service.deleteCarrierService = function (store) {
      return $q(function (resolve, reject) {
        PlatformApi.shopify.deleteCarrierService(
          {
            company_id: UserSession.company.id,
            id: store.id,
          },
          {},
          function (response) {
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    function createCarrierService(store) {
      return PlatformApi.shopify
        .createCarrierService(
          {
            company_id: UserSession.company.id,
          },
          {
            id: store.id,
            is_rates_enabled: true,
            store: store,
          }
        )
        .$promise.then(function (response) {
          if (_.get(response.data, 'store.is_rates_enabled')) {
            MixpanelService.track('Dynamic Rate - Success', {
              platform: _.get(response.data, 'store.platform.css_class'),
            });
          } else {
            MixpanelService.track('Dynamic Rate - Unavailable', {
              platform: _.get(response.data, 'store.platform.css_class'),
            });
          }

          return response;
        })
        .catch(function (error) {
          MixpanelService.track('Dynamic Rate - Failed', {
            platform: _.get(error.data, 'store.platform.css_class'),
          });

          throw error;
        });
    }
  }

  angular.module('app.service.ShopifyService', []).service('ShopifyService', ShopifyService);
})();
