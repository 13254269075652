import { IHelperService } from 'typings/helper';
import { ISubscriptionObject, IShowChangePlanObject } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import { FlexibleSubscriptionPlanId } from '@client/data/subscription';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './subscription.module.scss';
import template from './subscription.html?raw';

class Subscription implements IComponentController {
  style = style;
  translations: angular.translate.ITranslationTable = {};
  showModal: Partial<IShowChangePlanObject> = {
    compare: false,
    upgrade: false,
    downgrade: false,
    endTrial: false,
    enjoyFreeTrial: false,
    enterpriseCall: false,
    callScheduledCard: false,
    cancel: false,
  };
  wrappers = {
    a: (chunk: string) =>
      `<a href="https://support.easyship.com/hc/en-us/articles/7796000345241-Supscription-Plan-Changes" target="_blank" rel="noopener">${chunk}</a>`,
  };

  static $inject = [
    '$scope',
    '$state',
    '$translate',
    'SubscriptionService',
    'UserSession',
    'HelperService',
    'MixpanelService',
  ];
  constructor(
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession,
    private HelperService: IHelperService,
    private MixpanelService: MixpanelService
  ) {}

  $onInit() {
    // Get text from translation table
    this.$translate(['subscription']).then((translations: angular.translate.ITranslationTable) => {
      this.translations = translations;
    });

    const companyId = this.UserSession.getCompanyId();
    if (companyId) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: companyId,
      });
    } else {
      toastError(this.$translate.instant('toast.default-error'));
    }

    this.updateCompareModal();
    this.$scope.$on('routeUpdate', this.updateCompareModal);
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get isTrial(): boolean {
    return this.SubscriptionService.currentSubscriptionPeriod === 'TRIAL';
  }

  get isCurrentPlanChangeable(): boolean {
    return this.SubscriptionService.isCurrentPlanChangeable;
  }

  get isMaxShipments(): boolean {
    return this.SubscriptionService.isMaxShipments;
  }

  get isNextEnterprisePlan() {
    const nextPlanId = this.SubscriptionService.suggestedPlanForMoreShipments();
    return nextPlanId === FlexibleSubscriptionPlanId.Enterprise;
  }

  get nextBillingDate(): string | null {
    if (
      this.currentSubscription &&
      this.currentSubscription.next_invoice_at &&
      this.currentSubscription.next_invoice_at !== ''
    ) {
      const locale = this.UserSession.user.dashboard_settings?.language?.code || 'en';
      return this.HelperService.mediumDateFormat(this.currentSubscription.next_invoice_at, locale);
    }

    return null;
  }

  get isFreePlan(): boolean {
    return this.SubscriptionService.isFreePlan;
  }

  changePlan(): void {
    this.$state.go(this.$state.current, { open: 'compare' });
    this.MixpanelService.track('Subscription - Plans - Open', {
      trigger_source: 'Subscription Page',
    });
  }

  upgradeCustomPlan(): void {
    this.UserSession.openSalesMeetingLink();
  }

  cancelSubscription(): void {
    this.showModal.cancel = true;
    this.MixpanelService.track('Account - Subscription - Cancel');
  }

  endTrial(): void {
    this.showModal.enjoyFreeTrial = true;
    this.MixpanelService.track('Account - Subscription - Enjoy Free Trial');
  }

  updateCompareModal(): void {
    this.showModal.compare =
      this.$state.params.open && this.$state.params.open.toLowerCase() === 'compare';
  }

  get isPlanVersionOutdated(): boolean {
    return this.SubscriptionService.isPlanVersionOutdated;
  }

  get formattedMigrateDate(): string {
    return this.SubscriptionService.formattedMigrateDate;
  }
}

const SubscriptionComponent: ng.IComponentOptions = {
  controller: Subscription,
  template,
  bindings: {},
};

export { SubscriptionComponent };
