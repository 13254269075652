import { IApiConfig } from 'typings/core/config/api';

interface IApiGetPaymentsParams {
  company_id: string;
}

interface IApiSavePaymentParams {
  company_id: string;
}

interface IApiSavePaymentPayload {
  payment: {
    callback_url: string;
    total: number | null;
    description: string;
    currency: string;
    platform: string;
  };
}

class PaymentResource {
  endpoint: string;
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(public $resource: ng.resource.IResourceService, public API: IApiConfig) {
    this.endpoint = `${API.endpoint}/companies/:company_id/payments/:id`;
    this.resource = $resource(this.endpoint);
  }

  getPayments(params: IApiGetPaymentsParams) {
    return this.resource.get(params).$promise;
  }

  savePayment(params: IApiSavePaymentParams, payload: IApiSavePaymentPayload) {
    return this.resource.save(params, payload).$promise;
  }
}

export { PaymentResource };
