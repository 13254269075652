import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface CellLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const CellLayout = forwardRef<HTMLDivElement, CellLayoutProps>(({ children, className }, ref) => {
  return (
    <div
      ref={ref}
      className={twMerge(
        'flex flex-grow h-full hover:bg-teal-200 cursor-pointer w-fit min-w-full',
        className
      )}
    >
      {children}
    </div>
  );
});

CellLayout.displayName = 'CellLayout'; // Helpful for debugging

export default CellLayout;
