import template from './manifest-list-row.html?raw';
import style from './manifest-list-row.module.scss';

class ManifestListRow {
  constructor() {
    this.style = style;
    this.downloadError = false;
    this.generatingManifest = false;
    this.timeFormat = { timeStyle: 'short', hour12: true };
  }

  getShipmentCounts(manifest) {
    const { total_shipments_count, rejected_count = 0, pending_count = 0 } = manifest;
    const validShipmentsCount = total_shipments_count - rejected_count - pending_count;

    return validShipmentsCount === total_shipments_count
      ? `${total_shipments_count}`
      : `${validShipmentsCount}/${total_shipments_count}`;
  }

  getMissingLabelCount(manifest) {
    return manifest.rejected_count + manifest.pending_count;
  }

  isManifestEmpty(manifest) {
    const { total_shipments_count, rejected_count, pending_count } = manifest;

    return total_shipments_count - rejected_count - pending_count <= 0;
  }

  handleGenerateClick() {
    this.generatingManifest = true;

    this.onGenerateClick().finally(() => {
      this.generatingManifest = false;
    });
  }
}

const ManifestListRowComponent = {
  controller: ManifestListRow,
  template,
  bindings: {
    manifest: '<',
    shipments: '<',
    courier: '<',
    onDownloadClick: '&',
    onGenerateClick: '&',
    showError: '<',
    selectedDate: '<',
  },
};

export { ManifestListRowComponent };
