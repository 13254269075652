import { IComponentController } from 'angular';
import template from './label-field.html?raw';
import style from './label-field.module.scss';

class LabelField implements IComponentController {
  style = style;
}

const LabelFieldComponent: ng.IComponentOptions = {
  controller: LabelField,
  template,
  transclude: true,
  bindings: {
    esTitle: '@',
    esShowTooltip: '<',
    esTooltipMessage: '<',
  },
};

export { LabelFieldComponent };
