import { IApiConfig } from 'typings/core/config/api';
import { IPrintingOptions } from 'typings/printing-options';

class PrintingOptionsResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = this.$resource(
      `${this.API.endpoint}/printing_options`,
      {},
      {
        get: {
          method: 'GET',
          cache: true,
        },
      }
    );
  }

  getSizes(): ng.IPromise<IPrintingOptions.IGetSuccessResponse> {
    return this.resource.get().$promise;
  }
}

export { PrintingOptionsResource };
