import { IUserSession } from 'typings/user-session';
import { IBoxService, IBoxObject } from 'typings/boxes';

import { IComponentController } from 'angular';
import template from './default-dimensions-input.html?raw';
import style from './default-dimensions-input.module.scss';

class DefaultDimensionsInput implements IComponentController {
  style = style;
  boxes: IBoxObject[] = [];

  static $inject = ['UserSession', 'BoxService'];
  constructor(private UserSession: IUserSession, private BoxService: IBoxService) {}

  $onInit(): void {
    this.BoxService.getCustomBoxesSelectOptions().then((boxes: IBoxObject[]) => {
      this.boxes = boxes.filter((box) => {
        return box.id;
      });
    });
  }

  get dimensionUnit(): string {
    return this.UserSession.company.dimensions_unit;
  }
}

const DefaultDimensionsInputComponent: ng.IComponentOptions = {
  controller: DefaultDimensionsInput,
  template,
  bindings: {
    esDimensionsModel: '<',
    esBoxModel: '<',
    esOnDimensionsUpdate: '&',
    esOnBoxUpdate: '&',
  },
};

export { DefaultDimensionsInputComponent };
