(function () {
  'use strict';

  angular.module('easyshipDashboardApp').controller('resumeDraftModalCtrl', resumeDraftModalCtrl);

  resumeDraftModalCtrl.$inject = ['$scope', '$state', 'UserSession', 'draftShipment', 'Shipment'];

  function resumeDraftModalCtrl($scope, $state, UserSession, draftShipment, Shipment) {
    $scope.draftShipment = draftShipment;
    $scope.UserSession = UserSession;
    $scope.timestampFormat = { dateStyle: 'short', timeStyle: 'short', timeZone: undefined };

    $scope.resumeDraft = function () {
      $scope.$close();
      $state.go('receiver-info', { shipment: $scope.draftShipment }, { reload: true });
    };

    $scope.discardDrafts = function () {
      Shipment.discardDrafts(
        {
          company_id: $scope.UserSession.company.id,
          company_type: $scope.UserSession.company.type,
        },
        {},
        function (data) {},
        function (err) {}
      );

      $scope.$close();
    };
  }
})();
