import { IUserStatusService } from 'typings/user-status';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import template from './confirm-refund-modal-card.html?raw';
import style from './confirm-refund-modal-card.module.scss';
import confirmRefundImageUrl from '@assets/images/dashboard/refund-credits/confirm-refund.svg';

class ConfirmRefundModalCard implements IComponentController {
  style = style;
  confirmRefundImageUrl = confirmRefundImageUrl;

  static $inject = ['UserSession', 'UserStatusService'];
  constructor(private UserSession: IUserSession, private UserStatusService: IUserStatusService) {}

  get currency(): string {
    return this.UserSession.getCompanyCurrency();
  }

  get availableBalance(): number {
    return this.UserStatusService.availableBalance || 0;
  }
}

const ConfirmRefundModalCardComponent: ng.IComponentOptions = {
  controller: ConfirmRefundModalCard,
  template,
  bindings: {
    esCloseModal: '&',
    esConfirmRefundAction: '&',
    esRightButtonDisabled: '<',
    esRightButtonLoading: '<',
  },
};

export { ConfirmRefundModalCardComponent };
