import angular from 'angular';

import { ShipmentInfoClaimCtaComponent } from './claim-cta.component';

const MODULE_NAME = 'app.manage-shipments.shipment-information.claim-cta';

angular
  .module(MODULE_NAME, [])
  .component('esShipmentInfoClaimCta', ShipmentInfoClaimCtaComponent);

export default MODULE_NAME;
