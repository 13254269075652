import angular from 'angular';

import { RegistrationService } from './registration.service';
import { RegistrationResource } from './registration.resource';
import stepsNavigationService from '../steps-navigation';

const MODULE_NAME = 'auth.services.registration';

angular
  .module(MODULE_NAME, [stepsNavigationService])
  .service('RegistrationService', RegistrationService)
  .service('RegistrationResource', RegistrationResource);

export default MODULE_NAME;
