import { ICompanyService } from 'typings/company';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './contact-admin-modal.html?raw';
import style from './contact-admin-modal.module.scss';
import image from './contact-admin.svg?svgo';

class ContactAdminModal implements IComponentController {
  style = style;
  image = image;

  static $inject = ['$scope', '$window', 'MixpanelService', 'CompanyService'];
  constructor(
    private $scope: ng.IScope,
    private $window: ng.IWindowService,
    private MixpanelService: MixpanelService,
    private CompanyService: ICompanyService
  ) {}

  $onInit(): void {
    this.$scope.$watch(
      '$ctrl.CompanyService.showContactAdminModal',
      (showContactAdminModal: boolean) => {
        showContactAdminModal && this.MixpanelService.track('Require Admin Contact Modal - Open');
      }
    );
  }

  close(): void {
    this.CompanyService.showContactAdminModal = false;
  }

  openContactSupport(): void {
    if (!this.$window.zE) return;

    this.$window.zE('messenger', 'open');
    this.MixpanelService.track('Require Admin Contact Modal - Click Get Support');
  }
}

const ContactAdminModalComponent: ng.IComponentOptions = {
  controller: ContactAdminModal,
  template,
};

export { ContactAdminModalComponent };
