import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const DAYS_BEHIND = 3;
const DAYS_BEHIND_SELECT = 7;
const DAYS_AHEAD = 7;
const DAYS_AHEAD_SELECT = 1;

class Manifest {
  static $inject = ['$q', 'UserSession', 'ManifestResource', 'CourierAccounts'];

  constructor($q, UserSession, ManifestResource, CourierAccounts) {
    this.$q = $q;
    this.UserSession = UserSession;
    this.ManifestResource = ManifestResource;
    this.CourierAccounts = CourierAccounts;
  }

  verifyManifest(umbrellaName, courierAccountId, shipmentIds) {
    const company_id = this.UserSession.company.id;

    return this.ManifestResource.verify(
      { company_id, umbrella_name: umbrellaName },
      { courier_account_id: courierAccountId, shipment_ids: shipmentIds }
    ).catch((e) => {
      throw e;
    });
  }

  getManifestsFilterData() {
    const company_id = this.UserSession.company.id;

    return this.ManifestResource.get({
      company_id,
    }).catch((e) => {
      throw e;
    });
  }

  /**
   * Fetches USPS Manifests and groups them by date and postal code
   * '2018-07-09': { 20521: manifest[] }
   *
   * @returns {Promise<IManifestsByDateAndPostalCode>}
   */
  getManifests(
    { umbrellaName, method },
    { courierAccount, shipmentIds, validCount, invalidCount } = {}
  ) {
    const company_id = this.UserSession.company.id;

    return this.ManifestResource.retrieveManifests(
      {
        company_id,
        umbrella_name: umbrellaName,
      },
      { courier_account_id: courierAccount, shipment_ids: shipmentIds }
    ) // : IRetrieveManifestResponse
      .then((data) => {
        const manifestsByDate = data.manifests;
        const today = moment().startOf('day');
        const oldestManifestDate = this._getOldestManifestDate(manifestsByDate);
        const dates = this._prepareDates(method, oldestManifestDate);

        const manifestsByDateAndPostalCode = dates.reduce((accumulator, date) => {
          return {
            ...accumulator,
            [date]: manifestsByDate[date]
              ? manifestsByDate[date].reduce(this._groupByPostalCode, {})
              : {},
          };
        }, {});

        if (method === 'select' && (validCount || invalidCount)) {
          manifestsByDateAndPostalCode[today.format(DATE_FORMAT)].selected_shipments = [
            {
              shipment_ids: shipmentIds,
              total_shipments_count: validCount + invalidCount,
              handover_info: {
                category: 'handover',
              },
              rejected_count: invalidCount,
              pending_count: 0,
              manifestable_ids_key: 'shipment_ids',
            },
          ];
        }

        return manifestsByDateAndPostalCode;
      });
  }

  _getOldestManifestDate(manifests) {
    return Object.keys(manifests).sort((a, b) => {
      return moment.utc(a.timeStamp).diff(moment.utc(b.timeStamp));
    })[0];
  }

  generateManifest(manifest, { umbrellaName, courierAccountId }) {
    const company_id = this.UserSession.company && this.UserSession.company.id;
    const { manifestable_ids_key } = manifest;
    const manifestableIds = manifest[manifestable_ids_key];

    if (!company_id || !manifestableIds) {
      return this.$q.reject();
    }

    return this.ManifestResource.generate(
      { company_id, umbrella_name: umbrellaName },
      { [manifestable_ids_key]: manifestableIds, courier_account_id: courierAccountId }
    );
  }

  // @returns { [postalCode: string]: IManifestObject[] }
  _groupByPostalCode(postalAccumulator, manifest) {
    const { pickup_address } = manifest;
    const postalCode = (pickup_address && pickup_address.postal_code) || '_';
    const postalObject = postalAccumulator[postalCode] || [];

    return {
      ...postalAccumulator,
      [postalCode]: [...postalObject, manifest],
    };
  }

  _prepareDates(method, oldestManifestDate) {
    let daysBefore = DAYS_BEHIND;
    let daysAhead = DAYS_AHEAD;

    if (method === 'select') {
      const date1 = moment();
      const date2 = moment(oldestManifestDate);
      const diffInDays = date1.diff(date2, 'days');

      daysBefore = DAYS_BEHIND_SELECT > diffInDays ? DAYS_BEHIND_SELECT : diffInDays;
      daysAhead = DAYS_AHEAD_SELECT;
    }

    const firstDay = moment().startOf('day').subtract(daysBefore, 'days');

    return [...Array(daysBefore + daysAhead)].map((_date, index) => {
      return moment(firstDay).add(index, 'days').format(DATE_FORMAT);
    });
  }

  buildManifestOptions(data = {}, courierAccounts) {
    const { manifests = {} } = data;

    const manifestOptions = manifests.reduce((accumulator, groupByCourierAccountManifests) => {
      const manifestOption = groupByCourierAccountManifests.courier_account_manifests.map(
        (courierAccountManifest) => {
          let type = '';
          const totalShipmentsLabel =
            courierAccountManifest.total_shipments_count === 1 ? 'shipment' : 'shipments';

          if (courierAccounts.length && courierAccountManifest.courier_account_id) {
            type = this.CourierAccounts.getCourierAccountName(
              courierAccounts,
              courierAccountManifest.courier_account_id
            );
          }

          return {
            id: courierAccountManifest.id,
            displayText: `${courierAccountManifest.ref_number} (${courierAccountManifest.total_shipments_count} ${totalShipmentsLabel} - ${courierAccountManifest.date})`,
            type,
          };
        }
      );

      return [...accumulator, ...manifestOption];
    }, []);

    return [...manifestOptions];
  }
}

export { Manifest, DATE_FORMAT };
