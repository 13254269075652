import template from './select.html?raw';
import style from './select.module.scss';

class Select {
  static $inject = ['$element', '$timeout'];

  constructor($element, $timeout) {
    this.style = style;
    this.$element = $element;
    this.$timeout = $timeout;
    this.esId = '';
    this.selectOptions = [];
  }

  $onInit() {
    this.esId = this.esId || `easyship-select-${Math.random()}`;

    const { ngModel, esInitValidation, esError } = this;
    this._validateBindings();

    // validate ngModel when it is set programmatically
    // digest cycle is triggered so that the form directive is updated with validated on init. Currently can only used for string values.
    if (esInitValidation && ngModel && typeof ngModel !== 'object') {
      this.$timeout(() => {
        this.onSelectChange();
      });
    }

    // manually set input field as invalid when the ngModel is programmatically retrieved and validation fails from backend
    if (esInitValidation && esError) {
      this.$ngModelController.$setValidity('ngBackend', false);
      this.$ngModelController.$setDirty();
    }
  }

  $onChanges(changesObj) {
    const { ngModel, esOptions } = changesObj;

    /**
     * Sets display value for 2 cases:
     * 1) On manual select change in which a value is a primitive; and
     * 2) On setting a value programmatically even when operation is async
     */
    this._setSelectDisplay();

    // If an error is passed through the backend on initial ngModel retrieval, remove the error when user begins typing
    if (this.esInitValidation && this.esError && ngModel && !ngModel.isFirstChange()) {
      this.$ngModelController.$setValidity('ngBackend', true);
    }

    if (esOptions) {
      // Map valid data for value & display
      this.selectOptions = this.esOptions.map((option) => {
        const esSelectValue =
          option[this.esValueKey] || option[this.esValueKey] === 0 || option[this.esValueKey] === ''
            ? option[this.esValueKey]
            : option;

        const esSelectDisplay = option[this.esDisplayKey] || option[this.esValueKey] || option;

        return {
          ...(typeof option === 'string' ? {} : option),
          esSelectValue,
          esSelectDisplay,
        };
      });
    }
  }

  _setSelectDisplay() {
    const { esDisplaySelectedKey, esDisplayKey, esValueKey, esTrackKey, esOptions, ngModel } = this;

    if (this.hasNoValue()) {
      this.selectedDisplayValue = null;
      return;
    }

    /**
     * Fallbacks for select options object values. This is because we sometimes build our options from
     * the database and add on additional options from the frontend (which would not
     * have all the keys)
     */
    const matchedValue = angular.isObject(ngModel)
      ? ngModel[esValueKey] || ngModel[esTrackKey] || ngModel[esDisplayKey]
      : ngModel;

    // esValueKey takes precedence over esTrackKey and esDisplayKey
    const selectedOption = esOptions.find((option) =>
      [option[esValueKey], option[esTrackKey], option[esDisplayKey]].includes(matchedValue)
    );

    if (selectedOption) {
      // esDisplayKey takes precedence over esValueKey
      this.selectedDisplayValue =
        selectedOption[esDisplaySelectedKey] ||
        selectedOption[esDisplayKey] ||
        selectedOption[esValueKey];
    } else {
      this.selectedDisplayValue = esOptions.length ? ngModel : '...';
    }
  }

  onSelectChange() {
    this.ngChange({ value: this.ngModel });
  }

  hasLabel() {
    return !!angular.element('ng-transclude', this.$element).text().trim();
  }

  hasNoValue() {
    const isValidEmptyStringValue = this.esAllowEmptyString ? false : this.ngModel === '';

    return this.ngModel === null || typeof this.ngModel === 'undefined' || isValidEmptyStringValue;
  }

  _validateBindings() {
    if (!Array.isArray(this.esOptions)) {
      throw new Error('EasyshipSelect: An es-options array must be provided.');
    }
  }
}

const SelectComponent = {
  controller: Select,
  template,
  require: {
    $ngModelController: 'ngModel',
  },
  transclude: true,
  bindings: {
    ngModel: '<',
    ngChange: '&',
    ngDisabled: '<',
    ngRequired: '<',
    esOptions: '<',
    esValueKey: '@',
    esDisplayKey: '@',
    esDisplaySelectedKey: '@',
    esTrackKey: '@',
    esGroupByKey: '@',
    esDisabledKey: '@',
    esId: '@',
    esPlaceholder: '@',
    esHelp: '@',
    esReadOnly: '<',
    esError: '<',
    esInitValidation: '<',
    esTooltipOptions: '<',
    esAllowEmptyString: '<',
  },
};

export { SelectComponent };
