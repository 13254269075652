import { IComponentController } from 'angular';
import { toast } from 'react-toastify';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './bulk-cancel-modal.html?raw';
import style from './bulk-cancel-modal.module.scss';
import { BulkCancelService } from '../../services/bulk-cancel/bulk-cancel.service';
import { ModalService } from '../modals.service';

class BulkCancelModal implements IComponentController {
  style = style;
  busy = {
    cancellingShipments: false,
  };
  immediateRefundShipmentsCount = 0;
  pendingRefundShipmentsCount = 0;
  cancellationRequestShipmentsCount = 0;
  nonCancellationRequestShipmentsCount = 0;
  totalCancellableShipmentsCount = 0;
  nonRefundableShipmentsCount = 0;
  uncancellableShipmentsCount = 0;
  easyshipShipmentId = null;
  disableCancelButton = false;
  translations: { [key: string]: string } = {};

  static $inject = ['$translate', 'ModalService', 'BulkCancelService', 'MixpanelService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private ModalService: ModalService,
    private BulkCancelService: BulkCancelService,
    private MixpanelService: MixpanelService
  ) {}

  $onInit(): void {
    const { totals, selectedShipmentCount } = this.ModalService.params;
    const {
      immediate_refund_shipments_count,
      pending_refund_shipments_count,
      non_refundable_shipments_count,
      cancellation_request_shipments_count,
    } = totals;

    this.immediateRefundShipmentsCount = immediate_refund_shipments_count || 0;
    this.pendingRefundShipmentsCount = pending_refund_shipments_count || 0;
    this.nonRefundableShipmentsCount = non_refundable_shipments_count || 0;
    this.cancellationRequestShipmentsCount = cancellation_request_shipments_count || 0;
    this.nonCancellationRequestShipmentsCount =
      this.immediateRefundShipmentsCount +
      this.pendingRefundShipmentsCount +
      this.nonRefundableShipmentsCount;
    this.totalCancellableShipmentsCount =
      this.nonCancellationRequestShipmentsCount + this.cancellationRequestShipmentsCount;
    this.uncancellableShipmentsCount = selectedShipmentCount - this.totalCancellableShipmentsCount;
    this.easyshipShipmentId = this.ModalService.params.easyshipShipmentId;
    this.disableCancelButton = !this.easyshipShipmentId && !this.totalCancellableShipmentsCount;
    this.translations = {
      cancelButton: this.$translate.instant(
        'shipments.bulk-cancel-modal.cancel-button',
        { count: this.easyshipShipmentId ? 1 : this.totalCancellableShipmentsCount },
        'messageformat'
      ),
      uncancellableShipmentsMessage: this.$translate.instant(
        'shipments.bulk-cancel-modal.uncancellable-shipments-message',
        { count: this.easyshipShipmentId ? 1 : this.uncancellableShipmentsCount },
        'messageformat'
      ),
    };
  }

  close(): void {
    this.ModalService.close();
  }

  cancelShipments(): void {
    this.busy.cancellingShipments = true;

    let cancelPromise;

    if (this.easyshipShipmentId) {
      cancelPromise = this.BulkCancelService.cancelShipment(
        this.ModalService.params.shipmentId
      ).then(() => {
        let translationKey = 'shipments.bulk-cancel-modal.';
        let translationValues = {};

        if (this.cancellationRequestShipmentsCount > 0) {
          translationKey += 'cancellation-request-message';
          translationValues = {
            totalCancellableShipmentsCount: 1,
            cancellationRequestShipmentsCount: 1,
          };
        } else {
          translationKey += 'cancellation-success-message';
          translationValues = {
            count: 1,
          };
        }
        toastSuccess(this.$translate.instant(translationKey, translationValues, 'messageformat'));

        this.close();
      });
    } else {
      cancelPromise = this.BulkCancelService.cancelShipments(this.ModalService.params.filters).then(
        () => {
          toastSuccess(
            this.$translate.instant(
              'shipments.bulk-cancel-modal.cancellation-request-message',
              {
                totalCancellableShipmentsCount: this.totalCancellableShipmentsCount.toFixed(0),
                cancellationRequestShipmentsCount:
                  this.cancellationRequestShipmentsCount.toFixed(0),
              },
              'messageformat'
            )
          );

          this.close();
        }
      );
    }

    cancelPromise
      .catch((error) => {
        const [message] = error.data.shipment || [];
        toastError(message);
      })
      .finally(() => {
        this.busy.cancellingShipments = false;
      });
  }
}

const BulkCancelModalComponent: ng.IComponentOptions = {
  controller: BulkCancelModal,
  template,
  bindings: {},
};

export { BulkCancelModalComponent };
