import angular from 'angular';

import './form';
import './ng-submit';
import errorSrc from './error-src';
import translateTags from './translate-tags';

angular.module('core.directives', [
  'core.directives.form',
  'core.directives.ng-submit',
  errorSrc,
  translateTags,
]);
