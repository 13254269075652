// TODO: Move to core
(function () {
  CountryService.$inject = [
    '$http',
    '$q',
    '$timeout',
    '$translate',
    'API',
    'USStates',
    'HkDistrict',
  ];

  function CountryService($http, $q, $timeout, $translate, API, USStates, HkDistrict) {
    const service = this;
    const url = `${API.endpoint}/countries`;
    const promises = {
      getCountries: null,
      getShippingPublicCountries: null,
    };

    service.countries = [];
    service.shippingPublicCountries = [];
    service.currencies = [];
    service.USStates = [];
    service.hkDistricts = [];

    const US_ID = 234;
    const NZ_ID = 172;

    // List of countries that can use PostalCodeService.getState to also retrieve it's
    // corresponding city
    service.countriesWithPostalCodeCity = [US_ID, NZ_ID];

    service.availableCountries = [];
    service.otherCountries = [];

    service.initCountries = function () {
      return $http.get(url, {}).then(function (response) {
        service.countries = _.sortBy(response.data.countries, 'simplified_name');
        buildCurrenciesArray(service.countries);
      });
    };

    service.getCountries = function () {
      if (!promises.getCountries) {
        promises.getCountries = $q(function (resolve, reject) {
          if (service.countries.length === 0) {
            $http
              .get(url, {})
              .then(function (response) {
                service.countries = _.sortBy(response.data.countries, 'simplified_name');
                buildCurrenciesArray(service.countries);

                // Use timeout to reduce request
                $timeout(function () {
                  promises.getCountries = null;
                }, 1000);

                resolve({ countries: service.countries, currencies: service.currencies });
              })
              .catch(function (err) {
                promises.getCountries = null;
                reject(err);
              });
          } else {
            resolve({ countries: service.countries, currencies: service.currencies });
          }
        });
      }

      return promises.getCountries;
    };

    service.doesDestinationCountrySupportAutoFillState = function (countryAlpha2) {
      return this.countriesCanFetchState?.includes(countryAlpha2);
    };

    service.getShippingPublicCountries = function () {
      if (!promises.getShippingPublicCountries) {
        promises.getShippingPublicCountries = $q(function (resolve, reject) {
          if (service.shippingPublicCountries.length === 0) {
            $http
              .get(`${url}/shipping_countries`, {})
              .then(function (response) {
                if (response && response.data) {
                  service.shippingPublicCountries = _.sortBy(response.data.countries, 'name');

                  // Use timeout to reduce request
                  $timeout(function () {
                    promises.getShippingPublicCountries = null;
                  }, 1000);

                  resolve({
                    countries: service.shippingPublicCountries,
                  });
                } else {
                  reject();
                }
              })
              .catch(function (err) {
                promises.getShippingPublicCountries = null;
                reject(err);
              });
          } else {
            resolve({
              countries: service.shippingPublicCountries,
            });
          }
        });
      }

      return promises.getShippingPublicCountries;
    };

    service.getShippingSupportedCountries = function () {
      return $q(function (resolve) {
        $q.all([service.getCountries(), service.getShippingPublicCountries()]).then(function (
          data
        ) {
          const supportedCountries = data[1].countries.reduce(function (accumulator, country) {
            if (country.allow_dashboard_signup) accumulator[country.id] = country;

            return accumulator;
          }, {});

          const supportedCountriesWithCourierAvailability = data[0].countries.reduce(function (
            accumulator,
            country
          ) {
            if (Object.keys(supportedCountries).indexOf(country.id.toString()) > -1) {
              const slug = supportedCountries[country.id].pre_negotiated_courier_rates_available
                ? 'pre-negotiated-rates'
                : 'ship-with-own-courier';

              accumulator.push({
                ...country,
                courierAvailability: $translate.instant(`account.addresses.${slug}`),
                hasPreNegotiatedCourierRates:
                  supportedCountries[country.id].pre_negotiated_courier_rates_available,
              });
            }

            return accumulator;
          },
          []);

          return resolve({ countries: supportedCountriesWithCourierAvailability });
        });
      });
    };

    service.getValidReceiverCountries = function () {
      return $q(function (resolve) {
        $q.all([service.getCountries(), service.getShippingPublicCountries()]).then(function (
          data
        ) {
          const { countries } = data[0];
          const { currencies } = data[0];
          const shippingCountries = data[1];

          const validReceiverCountries = countries.filter(function (country) {
            const shippingCountry = shippingCountries.countries.find(function (shippingCountry) {
              return shippingCountry.id === country.id;
            });
            return shippingCountry && shippingCountry.valid_shipment_destination;
          });

          return resolve({
            countries: validReceiverCountries,
            currencies,
          });
        });
      });
    };

    service.getCountriesByContinent = function () {
      return $q(function (resolve, reject) {
        if (
          service.countries &&
          service.countriesByContinent &&
          service.countriesByContinentSorted
        ) {
          resolve({
            countries: service.countries,
            countriesByContinent: service.countriesByContinentSorted,
          });
        }

        service
          .getCountries()
          .then(function () {
            const continentsSorted = setOthersAtTheEnd(
              getAndSortListOfContinents(service.countries)
            );
            service.countriesByContinent = _.groupBy(service.countries, 'continent');
            service.countriesByContinentSorted = {};

            for (let i = 0; i < continentsSorted.length; i++) {
              service.countriesByContinentSorted[continentsSorted[i]] =
                service.countriesByContinent[continentsSorted[i]];
            }

            resolve({
              countries: service.countries,
              countriesByContinent: service.countriesByContinentSorted,
            });
          })
          .catch(function () {
            reject();
          });
      });
    };

    service.getCountryNameById = function (id) {
      const match = _.find(service.countries, { id: parseInt(id) });
      if (match) return match.simplified_name;
    };

    service.getCountryCodeById = function (id) {
      const match = _.find(service.countries, { id: parseInt(id) });
      if (match) return match.alpha2;
    };

    service.isCountryWithPostalCodeCity = function (id) {
      return this.countriesWithPostalCodeCity.indexOf(id) >= 0;
    };

    function getAndSortListOfContinents(countries) {
      return _.uniq(_.map(countries, 'continent')).sort();
    }

    function setOthersAtTheEnd(continents) {
      const index = continents.indexOf('Others');
      continents.splice(index, 1);
      continents.push('Others');
      return continents;
    }

    service.getUSStates = function () {
      return $q(function (resolve, reject) {
        if (service.USStates.length === 0) {
          USStates.query(
            {},
            {},
            function (data) {
              service.USStates = _.sortBy(data.us_states, 'name');
              resolve(service.USStates);
            },
            function (error) {
              reject(error);
            }
          );
        } else {
          resolve(service.USStates);
        }
      });
    };

    service.getHkDistricts = function () {
      return $q(function (resolve, reject) {
        // TODO: Check if we can remove this logic as it's already cache by resource
        if (service.hkDistricts.length === 0) {
          HkDistrict.query(
            {},
            {},
            function (data) {
              service.hkDistricts = data.hk_districts;
              resolve(service.hkDistricts);
            },
            function (error) {
              reject(error);
            }
          );
        } else {
          resolve(service.hkDistricts);
        }
      });
    };

    /**
     * [findHkDistrictByName] Find HK district object with an id
     * @param  {String} countryId: id of the country to find
     * @return {Object} country
     */
    service.findHkDistrictByName = function (hkDistrictName) {
      if (!hkDistrictName) return;

      return _.find(service.hkDistricts, function (districtObject) {
        return districtObject.district.toLowerCase() === hkDistrictName.toLowerCase();
      });
    };

    /**
     * [findCountry] Find country object with an id
     * @param  {String} countryId: id of the country to find
     * @return {Object} country
     */
    service.findCountry = function (countryId) {
      // TO DO: Improve this method so that it returns a consistant type (currently, ICountry | Promise<ICountry> | undefined)
      if (!countryId) return;

      let country;

      if (service.countries.length === 0) {
        return service.getCountries().then(function () {
          return service.findCountry(countryId);
        });
      }
      country = _.find(service.countries, { id: countryId });

      return country;
    };

    /**
     * [findCountryName] Find country name with an id
     * @param  {String} countryId: id of the country to find
     * @return {String} country name
     */
    service.findCountryName = function (countryId) {
      if (!countryId) return;
      return service.findCountry(countryId).name;
    };

    /**
     * [findCountryName] Find country name with an id
     * @param  {String} countryId: id of the country to find
     * @return {String} country name
     */
    service.findCountryByAlpha2 = function (alpha2) {
      if (!alpha2) return;

      const country = _.find(service.countries, { alpha2 });

      if (country) return country;
    };

    service.switchCountryIdType = function (countryId) {
      if (!countryId) return;
      if (angular.isString(countryId)) return parseInt(countryId);
      return countryId.toString();
    };

    function buildCurrenciesArray(countryData) {
      countryData = _.sortBy(countryData, 'currency_code');

      countryData.forEach(function (country) {
        const currency = {
          currency_code: country.currency_code,
          currency_symbol: country.currency_symbol,
        };

        const match = _.find(service.currencies, { currency_code: currency.currency_code });
        if (!match && currency.currency_code.length) service.currencies.push(currency);
      });
    }
  }

  angular
    .module('app.service.CountryService', [
      'core.config',
      'app.factory.Country',
      'app.factory.HkDistrict',
    ])
    .service('CountryService', CountryService);
})();
