import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import Box from '@material-ui/core/Box';
import COLORS from '@client/src/colors';
import Typography from '@material-ui/core/Typography';
import Button from '@client/core/components/react/Button';
import { useCurrentSubscriptionQuery } from '../../hooks/useCurrentSubscription';
import { UserSessionProvider, useUserSession } from '../../context/UserSessionProvider';

export default function TrialExpiryBanner() {
  const { formatMessage } = useIntl();
  const { data: currentSubscription } = useCurrentSubscriptionQuery();
  const remainingDaysBeforeTrialEnd = currentSubscription?.freeTrialDaysLeft;
  const userSession = useUserSession();
  const { user, features } = userSession;
  const currentSubscriptionPeriod = currentSubscription?.period;
  const planVersion = currentSubscription?.plan.slug;
  const formattedPlanVersion = planVersion
    ? formatMessage({ id: `freemium-pricing.category.${planVersion}` })
    : '';
  const goldenPlanSlug = 'golden';

  const showTrialExpiryBanner = features.canUseTeamRightsAndPermission
    ? user.hasUserRole('subscription_and_billing') &&
      currentSubscriptionPeriod === 'TRIAL' &&
      planVersion?.toLowerCase() !== goldenPlanSlug
    : currentSubscriptionPeriod === 'TRIAL' && planVersion?.toLowerCase() !== goldenPlanSlug;

  useEffect(() => {
    if (showTrialExpiryBanner) {
      document.body.classList.add('show-trial-expiry');
    }
  }, [showTrialExpiryBanner]);

  if (!showTrialExpiryBanner) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '8px 0 8px 210px',
        zIndex: 26,
        bgcolor: COLORS.tealNewLight,
      }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        style={{ gap: '16px' }}
      >
        <Typography variant="body2" component="p" color="textPrimary">
          <strong>
            <FormattedMessage
              id="subscription.trial-countdown-banner.days-left"
              values={{ day: remainingDaysBeforeTrialEnd, version: formattedPlanVersion }}
            />
          </strong>
          &nbsp;
          <FormattedMessage id="subscription.trial-countdown-banner.trial-end" />
        </Typography>
        <Button variant="contained" href="/account/subscription?open=compare">
          <FormattedMessage id="subscription.trial-countdown-banner.button" />
        </Button>
      </Box>
    </Box>
  );
}

export function WrappedTrialExpiryBanner() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <TrialExpiryBanner />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularTrialExpiryBanner = react2angular(WrappedTrialExpiryBanner, [], []);
