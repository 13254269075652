import { IUserSession, IUserRoleActions } from 'typings/user-session';
import { IUserStatusService } from 'typings/user-status';

import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './sidebar-profile.html?raw';
import style from './sidebar-profile.module.scss';

class SidebarProfileController implements IComponentController {
  style = style;

  static $inject = ['$state', 'UserSession', 'UserStatusService', 'SubscriptionService'];
  constructor(
    private $state: ng.ui.IStateService,
    private UserSession: IUserSession,
    private UserStatusService: IUserStatusService,
    private SubscriptionService: SubscriptionService
  ) {}

  get givenName(): string {
    return this.UserSession.user.first_name;
  }

  get companyName(): string {
    return this.UserSession.company.name;
  }

  get currency(): string {
    return this.UserSession.company.currency;
  }

  get balance(): number {
    return this.UserStatusService.availableBalance || 0;
  }

  get trialPeriod(): string | null | undefined {
    const { isFreePlan, isSubscriptionAvailable } = this.SubscriptionService;

    return isFreePlan || !isSubscriptionAvailable
      ? null
      : this.SubscriptionService.currentSubscription?.trial_period_display_text;
  }

  get userRoleActions(): IUserRoleActions {
    return this.UserSession?.user?.role?.actions;
  }

  get removeFalseRoleActionsAndTransformToArray(): any {
    return Object.keys(this.userRoleActions).filter(
      (key) => this.userRoleActions[key as keyof IUserRoleActions] !== false
    );
  }

  get isLuxUser(): boolean {
    return this.UserSession.isLuxUser();
  }

  checkMenuList(item: string): boolean {
    const arraySubItems = this.removeFalseRoleActionsAndTransformToArray;
    const relatedSubItems = arraySubItems.filter((subItem: string) =>
      subItem.startsWith(`menu.${item}`)
    );
    return relatedSubItems.length > 0;
  }

  redirectToPage(page: string): void {
    this.$state.go(page);
  }
}

const SidebarProfileComponent: ng.IComponentOptions = {
  controller: SidebarProfileController,
  template,
  bindings: {},
};

export { SidebarProfileComponent };
