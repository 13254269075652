import { IStoreService } from 'typings/store';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './add-remove-tags.html?raw';
import style from './add-remove-tags.module.scss';

class AddRemoveTags implements IComponentController {
  style = style;
  esClose?: () => void;
  tagName = '';
  storeExcludedTags: string[] = [];
  isExclude = false;
  savingSettings = false;

  static $inject = ['$translate', 'StoreService', 'MixpanelService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private StoreService: IStoreService,
    private MixpanelService: MixpanelService
  ) {
    this.selectTags = this.selectTags.bind(this);
  }

  $onInit(): void {
    this.storeExcludedTags = this.StoreService.activeStore?.tag_setting_list?.slice().sort() || [];
    this.isExclude = this.StoreService.activeStore?.tag_setting_operator === 'exclude';
  }

  onTagInputChange(name: string): void {
    this.tagName = name;
  }

  onIncludeExcludeToggleChange(value: boolean): void {
    this.isExclude = value;
  }

  selectTags(selections: string[]): void {
    this.storeExcludedTags = selections;
  }

  saveChanges(): void {
    if (!this.StoreService.activeStore) return;
    this.savingSettings = true;
    this.MixpanelService.track(`Store - Update Sync Settings`, { setting: 'Tags' });

    const store = {
      ...this.StoreService.activeStore,
      tag_setting_list: this.storeExcludedTags,
      tag_setting_operator: this.isExclude ? 'exclude' : 'include',
    };

    this.StoreService.updateStore(store)
      .then(() => {
        toastSuccess(this.$translate.instant('store.notifications.tags-success'));
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.savingSettings = false;
        if (this.esClose) this.esClose();
      });
  }

  get includeOrExclude(): string {
    return this.isExclude ? 'exclude' : 'include';
  }
}

const AddRemoveTagsComponent = {
  template,
  controller: AddRemoveTags,
  bindings: {
    esClose: '&',
  },
};

export { AddRemoveTagsComponent };
