import { Automation, IAutomationService } from 'typings/automation';
import { IHelperService } from 'typings/helper';
import { IShopifyService } from 'typings/platform';
import { IStore, IStoreService, OauthCredentials } from 'typings/store';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './store-page.module.scss';
import template from './store-page.html?raw';

class StorePageController implements IComponentController {
  style = style;
  errors = { page: false };
  busy = { page: false, checking: false, reconnecting: false };
  show = { features: false, shippingRules: false, api: false };
  automations?: Automation[];
  isRateExplainerDialogOpen = false;
  isTaxDutyDialogOpen = false;
  isFallbackSettingsDialogOpen = false;
  fallbackSettingsScenarioName = 'Store Settings Arrive';
  isAddressModalOpen = false;

  static $inject = [
    '$scope',
    '$timeout',
    '$window',
    '$state',
    '$stateParams',
    '$translate',
    'UserSession',
    'StoreService',
    'AutomationService',
    'HelperService',
    'MixpanelService',
    'ShopifyService',
    'SubscriptionService',
    'AmazonService',
    'incartSuccessModal',
    'deleteStoreModal',
  ];
  constructor(
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private StoreService: IStoreService,
    private AutomationService: IAutomationService,
    private HelperService: IHelperService,
    private MixpanelService: MixpanelService,
    private ShopifyService: IShopifyService,
    private SubscriptionService: SubscriptionService,
    private AmazonService: any,
    private incartSuccessModal: any,
    private deleteStoreModal: any
  ) {
    this.closeRateExplainerDialog = this.closeRateExplainerDialog.bind(this);
    this.closeTaxDutyDialog = this.closeTaxDutyDialog.bind(this);
    this.openRateExampleDialog = this.openRateExampleDialog.bind(this);
    this.openTaxExampleDialog = this.openTaxExampleDialog.bind(this);
    this.openRateDialog = this.openRateDialog.bind(this);
    this.openTaxDutyDialog = this.openTaxDutyDialog.bind(this);
    this.closeFallbackSettingsDialog = this.closeFallbackSettingsDialog.bind(this);
    this.updateFallbackSettingsSuccess = this.updateFallbackSettingsSuccess.bind(this);
  }

  $onInit() {
    this.busy.page = true;

    if (this.$stateParams.refetch) {
      this.refetchStores();
    }

    if (this.$stateParams.store) {
      this.StoreService.activeStore = this.$stateParams.store;
      this.storeLoadedCallback();
    } else {
      this.getStore(this.$stateParams.id)
        .then(() => this.AutomationService.getData())
        .then(() => this.storeLoadedCallback())
        .catch(() => {
          toastError(this.$translate.instant('store.notifications.loading-error'));
          this.errors.page = true;
          this.busy.page = false;
        });
    }

    if (this.$stateParams.platform) {
      this.incartSuccessModal.open(this.$stateParams.platform);
    }

    if (this.$stateParams.new === 'true') {
      toastSuccess(this.$translate.instant('connect.notifications.added'));
      this.$state.go('app.single-store', { id: this.$stateParams.id, new: null });
    }
  }

  private getStore(storeId: string): ng.IPromise<void> {
    return this.StoreService.getStore(storeId)
      .then((response: IStore) => {
        this.StoreService.activeStore = response;
        this.StoreService.activeStore.is_enabled = true;
      })
      .catch(() => {
        this.errors.page = true;
        this.busy.page = false;
        this.$state.go('app.connect');
      });
  }

  private refetchStores(): ng.IPromise<void> {
    return this.StoreService.getStores()
      .then(() => {
        //
      })
      .catch(() => {
        this.errors.page = true;
        this.busy.page = false;
        this.$state.go('app.connect');
      });
  }

  private storeLoadedCallback() {
    if (!this.StoreService.activeStore) return;

    this.automations = this.StoreService.activeStore.shipping_rules?.sort(
      (a, b) => a.priority - b.priority
    );

    // using ng-if in the shipping rules section
    // -> only load the section and isotope when the descriptions are ready to be passed in
    // Add delay to make sure Isotope is not glitching too much when loading
    this.setShowVariables(this.StoreService.activeStore);
    if (
      this.isMissingRequiredFallback &&
      !this.isInvalidCredentials &&
      !this.needsReauthorization
    ) {
      this.isFallbackSettingsDialogOpen = true;
    }

    if (this.isAPI && !this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
    }

    this.$timeout(() => {
      this.busy.page = false;
    }, 700);
  }

  private setShowVariables(store: IStore) {
    const isApi = store.platform?.group === 'API';
    const isEnabled = store.platform?.easyship_token ? !this.isAwaitingAuth : !!store.is_enabled;

    this.show = {
      features: isEnabled && !isApi,
      shippingRules: isEnabled && !isApi,
      api: isApi,
    };

    if (store.oauth_applications?.production) {
      // eslint-disable-next-line no-param-reassign
      store.oauth_applications.production.key = this.HelperService.preventNewLineInString(
        store.oauth_applications.production.key
      );
      // eslint-disable-next-line no-param-reassign
      store.oauth_applications.production.secret = this.HelperService.preventNewLineInString(
        store.oauth_applications.production.secret
      );
    }
  }

  private reconnectShopify() {
    if (!this.StoreService.activeStore?.store_url) return;

    const payload = {
      url: this.StoreService.activeStore.store_url,
    };

    this.$window.localStorage.setItem('skipDefaults', 'true');

    this.ShopifyService.getShopifyKey(payload)
      .then((redirectUrl) => {
        this.$window.location.href = redirectUrl;
      })
      .catch((err) => this.showErrorMessage(err));
  }

  private reconnectAmazon(activeStore: IStore) {
    this.AmazonService.authenticate(activeStore.platform?.id, activeStore.name)
      .then(({ redirect_url }: { redirect_url: string }) => {
        this.$window.location.href = redirect_url;
      })
      .catch(() => {
        toastError(this.$translate.instant('connect.notifications.auth-failed'));
      });
  }

  private showErrorMessage(err: { data: { error: string; auth_state: string } }) {
    this.busy.checking = false;
    toastError(err?.data?.error);

    if (this.StoreService.activeStore) {
      this.StoreService.activeStore.auth_state = err.data && err.data.auth_state;
    }
  }

  openRateExampleDialog(): void {
    this.isRateExplainerDialogOpen = true;
  }

  openTaxExampleDialog(): void {
    this.isTaxDutyDialogOpen = true;
  }

  openTaxDutyDialog(): void {
    this.isTaxDutyDialogOpen = true;
    this.isRateExplainerDialogOpen = false;
    this.$scope.$apply();
  }

  openRateDialog(): void {
    this.isRateExplainerDialogOpen = true;
    this.isTaxDutyDialogOpen = false;
    this.$scope.$apply();
  }

  closeRateExplainerDialog(): void {
    this.isRateExplainerDialogOpen = false;
    this.$scope.$apply();
  }

  closeTaxDutyDialog(): void {
    this.isTaxDutyDialogOpen = false;
    this.$scope.$apply();
  }

  openDeleteStoreModal(): void {
    this.deleteStoreModal.open({ store: this.StoreService.activeStore });
  }

  closeFallbackSettingsDialog(): void {
    this.isFallbackSettingsDialogOpen = false;
    this.$scope.$apply();
  }

  updateFallbackSettingsSuccess(): void {
    this.isFallbackSettingsDialogOpen = false;
  }

  closeAddressModal(): void {
    this.isAddressModalOpen = false;
  }

  reconnectStore() {
    // Currently, only Amazon and shopify needs reconnect
    if (!this.StoreService.activeStore) return;
    this.busy.reconnecting = true;
    this.MixpanelService.track('Disconnected Store - Reconnect', {
      source: 'Store Settings',
      store_id: this.StoreService.activeStore.id,
      store_name: this.StoreService.activeStore.name,
      platform: this.StoreService.activeStore.platform?.name,
    });

    const { activeStore } = this.StoreService;
    if (activeStore?.platform?.name === 'Shopify') {
      this.reconnectShopify();
    } else if (activeStore?.platform?.group === 'Amazon') {
      this.reconnectAmazon(activeStore);
    }
  }

  checkConnection() {
    if (!this.StoreService.activeStore) return;
    this.busy.checking = true;
    this.MixpanelService.track('Disconnected Store - Retry', {
      source: 'Store Settings',
      store_id: this.StoreService.activeStore.id,
      store_name: this.StoreService.activeStore.name,
      platform: this.StoreService.activeStore.platform?.name,
    });

    this.StoreService.checkConnection(this.StoreService.activeStore?.id)
      .then((response: { auth_state: string }) => {
        this.busy.checking = false;
        if (!this.StoreService.activeStore) return;

        this.StoreService.activeStore.auth_state = response.auth_state;
        toastSuccess(this.$translate.instant('connect.notifications.reconnected'));
      })
      .catch((err) => {
        const { activeStore } = this.StoreService;

        // Hotfix Shopify issue where user fail to sync their store
        if (activeStore?.platform?.name === 'Shopify') {
          this.reconnectShopify();
        } else if (activeStore?.platform?.group === 'Amazon') {
          this.reconnectAmazon(activeStore);
        } else {
          this.showErrorMessage(err);
        }
      });
  }

  get activeStore(): IStore | undefined {
    return this.StoreService.activeStore;
  }

  get isWooCommerce(): boolean {
    return this.StoreService.activeStore?.platform?.name === 'WooCommerce';
  }

  get isAPI(): boolean {
    return this.StoreService.activeStore?.platform?.group === 'API';
  }

  get isMissingRequiredFallback(): boolean {
    return !!this.StoreService.activeStore?.missing_required_fallback;
  }

  get isInvalidCredentials(): boolean {
    return (
      this.StoreService.activeStore?.auth_state !== 'verified' &&
      this.StoreService.activeStore?.platform?.group !== 'Amazon' &&
      this.StoreService.activeStore?.platform?.group !== 'API' &&
      !!this.StoreService.activeStore?.platform?.actions?.get_orders
    );
  }

  get needsReauthorization(): boolean {
    return (
      (!!this.StoreService.activeStore?.requires_reauthorize_permission &&
        !this.isInvalidCredentials) ||
      (this.StoreService.activeStore?.auth_state !== 'verified' &&
        this.StoreService.activeStore?.platform?.group === 'Amazon')
    );
  }

  get isUsingToken(): boolean {
    return (
      !!this.StoreService.activeStore?.oauth_applications?.production?.token &&
      this.StoreService.activeStore?.platform?.group === 'WooCommerceGroup'
    );
  }

  get isAwaitingAuth(): boolean {
    return this.StoreService.activeStore?.auth_state === 'unverified';
  }

  get productionAuth(): OauthCredentials | undefined {
    return this.StoreService.activeStore?.oauth_applications?.production;
  }

  get subscriptionPlanAllowsRatesAtCheckout(): boolean {
    return !!this.SubscriptionService.currentSubscription?.all_features?.rates_at_checkout
      ?.is_accessible;
  }

  get isFree(): boolean {
    return this.SubscriptionService.currentPlanName === 'Free';
  }

  get isNotShopifyAndNotFreePlan(): boolean {
    return (
      this.StoreService?.activeStore?.name !== 'Shopify' &&
      this.subscriptionPlanAllowsRatesAtCheckout &&
      !this.isFree
    );
  }

  get isInsuranceAtCheckoutActive(): boolean {
    return this.StoreService.activeStore?.insurance_at_checkout_state === 'active';
  }
}

const StorePageComponent: ng.IComponentOptions = {
  controller: StorePageController,
  template,
  bindings: {
    esExplainerDialog: '&',
    onClose: '&',
  },
};

export { StorePageComponent };
