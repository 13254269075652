import { useGateways } from '@app/contexts/providers/GatewayProvider';
import { AddCourierParams } from '@app/types/courierAccount';
import { useMutation } from '@tanstack/react-query';

export default function useCreateCourierAccountMutation() {
  const { courierGateway } = useGateways();

  return useMutation({
    mutationFn: (payload: AddCourierParams) => courierGateway.addCourierAccount(payload),
  });
}
