import deviceService from './device';
import mixpanelService from './mixpanel';
import hotjarService from './hotjar';
import stripeService from './stripe';
import websiteApiService from './website-api';
import appCuesService from './app-cues';
import SurveySparrowService from './survey-sparrow';
import SentryService from './sentry';
import UserRightsService from './user-rights';
import ScanService from './scan';

const MODULE_NAME = 'core.services';

angular.module(MODULE_NAME, [
  deviceService,
  mixpanelService,
  hotjarService,
  stripeService,
  websiteApiService,
  appCuesService,
  SurveySparrowService,
  SentryService,
  UserRightsService,
  ScanService,
]);

export default MODULE_NAME;
