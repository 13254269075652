import { IComponentController } from 'angular';
import template from './tracking-header-tooltip.html?raw';

class ShipmentListTrackingHeaderTooltip implements IComponentController {}

const ShipmentListTrackingHeaderTooltipComponent: ng.IComponentOptions = {
  controller: ShipmentListTrackingHeaderTooltip,
  template,
};

export { ShipmentListTrackingHeaderTooltipComponent };
