import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { HsCode } from '@client/core/corelogic/models/HsCode';
import IHsCodeGateway, {
  IHsCodeSuggestionSearchField,
} from '@client/core/corelogic/ports/hsCode.interface';
import { useGateways } from '../../context/GatewayProvider';

const HS_CODE_MINIMUM_LENGTH = 2;
export const DESCRIPTION_MINIMUM_LENGTH = 3;

function isValidSearchValue(searchValue: string): boolean {
  const regexpMatch = new RegExp(
    `\\d{${HS_CODE_MINIMUM_LENGTH}}|[a-zA-Z]{${DESCRIPTION_MINIMUM_LENGTH}}`
  );

  return regexpMatch.test(searchValue);
}

function sortHsCodes(hsCodes: HsCode[]): HsCode[] {
  return hsCodes.sort(({ name: a }, { name: b }) => +a - +b);
}

function fetchHsCodeSuggestions(
  hsCodeGateway: IHsCodeGateway,
  searchValue: string
): Promise<HsCode[]> {
  const searchValueSanitized = searchValue.replaceAll('.', '');

  if (!isValidSearchValue(searchValueSanitized)) return Promise.resolve([]);

  const field: IHsCodeSuggestionSearchField = /\d/.test(searchValueSanitized)
    ? 'name'
    : 'description';

  return hsCodeGateway.getHsCodeSuggestions(searchValueSanitized, field).then(sortHsCodes);
}

interface HsCodeSuggestionsParams extends UseQueryOptions<HsCode[]> {
  searchValue: string;
}

export function useHsCodeSuggestionsQuery({ searchValue, ...params }: HsCodeSuggestionsParams) {
  const { hsCodeGateway } = useGateways();

  return useQuery({
    queryKey: ['hsCodeSuggestions', searchValue],
    queryFn: () => fetchHsCodeSuggestions(hsCodeGateway, searchValue),
    cacheTime: Infinity,
    staleTime: Infinity,
    ...params,
  });
}
