import React, { createContext, useContext, useMemo } from 'react';
import { UserGateway } from '../gateways/api/UserGateway';
import { FakeUserGateway } from '../gateways/fake/FakeUserGateway';
import { UserPort } from '../ports/user';

export interface UserGatewayContextData {
  userGateway: UserPort;
}

const UserGatewayContext = createContext<UserGatewayContextData | undefined>(undefined);

interface UserGatewayProviderProps {
  children: React.ReactNode;
}

function UserGatewayProvider({ children }: UserGatewayProviderProps) {
  const data: UserGatewayContextData = useMemo(
    () => ({
      userGateway: new UserGateway(),
    }),
    []
  );

  return <UserGatewayContext.Provider value={data}>{children}</UserGatewayContext.Provider>;
}

interface FakeUserGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<UserGatewayContextData>;
}

function FakeUserGatewayProvider({ children, value }: FakeUserGatewayProviderProps) {
  const data: UserGatewayContextData = useMemo(
    () => ({
      userGateway: new FakeUserGateway(),
      ...value,
    }),
    [value]
  );

  return <UserGatewayContext.Provider value={data}>{children}</UserGatewayContext.Provider>;
}

function useUserGateway() {
  const userGatewayContext = useContext(UserGatewayContext);

  if (userGatewayContext === undefined) {
    throw new Error('useUserGateway must be used within a UserGatewayProvider');
  }
  return userGatewayContext;
}

export { UserGatewayProvider, FakeUserGatewayProvider, useUserGateway };
