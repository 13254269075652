import angular from 'angular';
import { CustomViewModalComponent } from './custom-view-modal.component';

const MODULE_NAME = 'app.global.shipments-views-custom-view-modal';

angular
  .module(MODULE_NAME, ['app.global.services.shipment-views', 'app.global.services.shipment-list'])
  .component('esShipmentsViewsCustomViewModal', CustomViewModalComponent);

export default MODULE_NAME;
