import { ShipmentData } from '@client/core/corelogic/models/Shipment';
import { difference, get, intersection } from 'lodash';
import { SectionName } from './types';

const SHIPMENT_ITEM_MODIFIED_FIELDS = [
  { fieldName: 'Declared value', path: 'unitSellingPrice' },
  { fieldName: 'Description', path: 'description' },
  { fieldName: 'Declared currency', path: 'currencyCode' },
  { fieldName: 'Quantity', path: 'quantity' },
  { fieldName: 'SKU', path: 'sku' },
  { fieldName: 'HS Code', path: 'categoryHsCode.hsCodeNumber' },
  { fieldName: 'Category', path: 'categoryHsCode.categoryId' },
  { fieldName: 'Contains battery', path: 'hasBattery' },
  { fieldName: 'Contains liquid', path: 'containsLiquids' },
] as const;

interface MapShipmentItemModifiedDataToMixpanel {
  previousShipmentData: ShipmentData;
  newShipmentData: ShipmentData;
}
export function mapShipmentItemModifiedDataToMixpanel({
  previousShipmentData,
  newShipmentData,
}: MapShipmentItemModifiedDataToMixpanel) {
  const SECTION_NAME: SectionName = 'Item details';
  const shipmentItemFieldsModified: string[] = [];

  const previousShipmentItems = previousShipmentData.order.parcels.flatMap(
    (parcel) => parcel.items
  );
  const {
    totalItemCount,
    uniqueItemCount,
    shipmentItems: newShipmentItems,
  } = getShipmentItemCount(newShipmentData);

  const previousShipmentItemIds = previousShipmentItems.map((item) => item.id);
  const newShipmentItemIds = newShipmentItems.map((item) => item.id);

  const addedShipmentItemIds = difference(newShipmentItemIds, previousShipmentItemIds);
  const deletedShipmentItemIds = difference(previousShipmentItemIds, newShipmentItemIds);
  const updatedShipmentItemIds = intersection(previousShipmentItemIds, newShipmentItemIds);

  if (addedShipmentItemIds.length > 0) {
    shipmentItemFieldsModified.push('Shipment item added');
  }

  if (deletedShipmentItemIds.length > 0) {
    shipmentItemFieldsModified.push('Shipment item deleted');
  }

  updatedShipmentItemIds.forEach((itemId) => {
    const previousShipmentItem = previousShipmentItems.find((item) => item.id === itemId);
    const newShipmentItem = newShipmentItems.find((item) => item.id === itemId);

    SHIPMENT_ITEM_MODIFIED_FIELDS.forEach(({ path, fieldName }) => {
      if (get(previousShipmentItem, path) !== get(newShipmentItem, path)) {
        shipmentItemFieldsModified.push(fieldName);
      }
    });
  });

  return {
    modifiedShipmentItemFields: shipmentItemFieldsModified.map(
      (field) => `${SECTION_NAME} / ${field}`
    ),
    totalItemCount,
    uniqueItemCount,
  };
}

export function getShipmentItemCount(shipmentData: ShipmentData): {
  shipmentItems: ShipmentData['order']['parcels'][0]['items'];
  totalItemCount: number;
  uniqueItemCount: number;
} {
  const shipmentItems = shipmentData.order.parcels.flatMap((parcel) => parcel.items);
  const totalItemCount = shipmentItems.reduce((acc, item) => acc + item.quantity, 0);
  const uniqueItemCount = new Set(shipmentItems.map((item) => `${item.sku}-${item.description}`))
    .size;

  return {
    shipmentItems,
    totalItemCount,
    uniqueItemCount,
  };
}
