import { CollectBulkParams, CollectBulkPayload, CollectBulkResponse } from 'typings/collect';
import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';

class CollectBulkResource {
  resource: any;

  static $inject = ['$resource', 'API', 'UserSession'];

  constructor(
    $resource: ng.resource.IResourceService,
    private API: IApiConfig,
    private UserSession: IUserSession
  ) {
    this.UserSession = UserSession;
    const endpointUrl = this.API.endpoint.replace('/api/', '/collect_api/');
    const HEADERS = { Authorization: `Bearer ${this.UserSession.session_token}` };

    this.resource = $resource(
      `${endpointUrl}/companies/:company_id/bulk_emails/:controller`,
      {},
      {
        resendPrepare: {
          method: 'POST',
          headers: HEADERS,
          params: {
            controller: 'preview',
          },
        },
        resendConfirm: {
          method: 'POST',
          headers: HEADERS,
        },
      }
    );
  }

  resendEmailsPrepare(
    params: CollectBulkParams,
    payload: CollectBulkPayload
  ): Promise<CollectBulkResponse> {
    return this.resource.resendPrepare(params, payload).$promise;
  }

  resendEmailsConfirm(
    params: CollectBulkParams,
    payload: CollectBulkPayload
  ): Promise<CollectBulkResponse> {
    return this.resource.resendConfirm(params, payload).$promise;
  }
}

export { CollectBulkResource };
