import { IUserSession } from 'typings/user-session';
import { ICompanyService } from 'typings/company';

import { IComponentController } from 'angular';
import template from './banner-modal-container.html?raw';

class BannerModalContainerController implements IComponentController {
  show = false;

  static $inject = ['UserSession', 'CompanyService'];
  constructor(private UserSession: IUserSession, private CompanyService: ICompanyService) {
    this.closeReasonsForInactivityFlow = this.closeReasonsForInactivityFlow.bind(this);
  }

  get showReasonsForInactivityFlow(): boolean {
    return !!this.CompanyService.isInactive && !this.UserSession.isLuxUser();
  }

  get showAccountUnderReviewBanner(): boolean {
    return this.UserSession.isUnderAccountClosingReview();
  }

  closeReasonsForInactivityFlow(): void {
    this.CompanyService.isInactive = false;
  }
}

const BannerModalContainerComponent = {
  controller: BannerModalContainerController,
  template,
};

export { BannerModalContainerComponent };
