import React, { useMemo } from 'react';
import { FormattedNumber } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getAmountClass } from './utils';

interface AdjustmentSummaryProps {
  title: string;
  amount: number;
  currency: string;
  showSign?: boolean;
}

export function AdjustmentSummary({
  title,
  amount,
  currency,
  showSign = false,
}: AdjustmentSummaryProps) {
  const amountSign = useMemo(() => {
    if (!showSign) {
      return '';
    }

    if (amount > 0) {
      return '+';
    }

    if (amount < 0) {
      return '-';
    }

    return '';
  }, [amount, showSign]);

  const amountClass = getAmountClass(amount);

  return (
    <Box mb={1} display="flex" justifyContent="space-between">
      <Typography className="text-ink-700">{title}</Typography>

      <Typography variant="body1" align="right" className={amountClass}>
        {amountSign}
        <FormattedNumber
          value={Math.abs(amount)}
          style="currency"
          currency={currency}
          minimumFractionDigits={2}
        />
      </Typography>
    </Box>
  );
}
