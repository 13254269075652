(function () {
  'use strict';

  Lead.$inject = ['$resource', 'API'];
  function Lead($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/leads/:id',
      {},
      {
        create: {
          method: 'POST',
          params: {},
        },
        delete: {
          method: 'DELETE',
          params: {
            id: '@_id',
          },
        },
      }
    );
  }

  angular.module('app.factory.Lead', []).factory('Lead', Lead);
})();
