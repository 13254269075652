(function () {
  ShippingRules.$inject = ['$resource', 'API'];
  function ShippingRules($resource, API) {
    const endpointUrl = API.endpoint;
    return $resource(
      `${endpointUrl}/companies/:company_id/automations/:id`,
      {
        company_id: '@_company_id',
        id: '@_id',
      },
      {
        query: {
          method: 'GET',
        },
        fetch: {
          method: 'GET',
        },
        create: {
          method: 'POST',
        },
        update: {
          method: 'PATCH',
        },
        delete: {
          method: 'DELETE',
        },
      }
    );
  }

  AutomationService.$inject = [
    '$q',
    '$filter',
    '$translate',
    'CountryService',
    'StatesService',
    'StoreService',
    'ItemCategoryService',
    'CourierService',
    'Rule',
    'Action',
    'BoxService',
    'FlatRateBoxService',
    'AddressService',
    'UserSession',
  ];

  function AutomationService(
    $q,
    $filter,
    $translate,
    CountryService,
    StatesService,
    StoreService,
    ItemCategoryService,
    CourierService,
    Rule,
    Action,
    BoxService,
    FlatRateBoxService,
    AddressService,
    UserSession
  ) {
    const service = this;

    service.defaultCssClass = 'strong';

    $translate([
      'global.unknown.category',
      'global.unknown.country',
      'global.unknown.store',
      'global.unknown.box',
      'global.unknown.address',
    ]).then(function (translations) {
      service.translations = translations;
    });

    service.getData = function (addressIds) {
      addressIds = addressIds || [];
      return $q(function (resolve, reject) {
        $q.all([
          _getCountries(),
          _getItemCategories(),
          _getBoxes(),
          _getShippingAddresses(),
          StatesService.getStates(),
        ])
          .then(function () {
            const addressPromises = [];
            const knownIds = Action.origins.map(function (origin) {
              return origin.id;
            });
            addressIds.forEach(function (addressId) {
              if (knownIds.indexOf(addressId) < 0)
                addressPromises.push(AddressService.queryById(addressId));
            });
            return $q.all(addressPromises);
          })
          .then(function (moreAddresses) {
            Action.origins = Action.origins.concat(
              moreAddresses.map(function (address) {
                const joinedAddress = `${[
                  address.line_1,
                  address.line_2,
                  address.line_3,
                  address.city,
                  address.state,
                  address.postalCode,
                ]
                  .join(' ')
                  .trim()
                  .replace(/\s{2,}/g, ' ')}, ${address.country.name}`;
                address.display = address.nickname || joinedAddress;
                return address;
              })
            );
            resolve();
          })
          .catch(reject);
      });
    };

    service.summarizeCondition = function (condition, asSentence) {
      const conditionName = Rule.abbr(condition.name);
      let selections;

      switch (conditionName) {
        case 'MatchAll':
          return '';

        case 'MatchCategory':
          // NOTE: Until we can refactor formatToSave() in automations.controller.js,
          // some attributes of `options` might be either string, string[], or number[]
          selections = _getArray(condition.options.category_ids).map(_pickCategory);
          return $translate.instant(
            'automations.condition-phrases.MatchCategory',
            {
              asSentence,
              selections: $filter('toCommaList')(selections, {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
            },
            'messageformat'
          );

        case 'MatchCountry':
          selections = _getArray(condition.options.country_ids).map(_pickCountry).sort();
          return $translate.instant(
            'automations.condition-phrases.MatchCountry',
            {
              asSentence,
              selections: $filter('toCommaList')(selections, {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
            },
            'messageformat'
          );

        case 'MatchState':
          // On save, condition.options.states is not an array?
          var stateCodes =
            typeof condition.options.states === 'string'
              ? condition.options.states.split(',')
              : condition.options.states;
          var countryDict = stateCodes.reduce(_groupStates, {});

          selections = Object.entries(countryDict).map(_displayStates);
          return $translate.instant(
            'automations.condition-phrases.MatchState',
            {
              asSentence,
              selections: $filter('toCommaList')(selections, {
                commaReplacement: ';',
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
            },
            'messageformat'
          );

        case 'MatchPlatformNameOrStore':
          var platforms = condition.options.platform_names
            ? condition.options.platform_names.split(',')
            : [];
          var stores = condition.options.store_ids
            ? condition.options.store_ids.split(',').map(_pickStore)
            : [];
          var edited = condition.options.platformsAndStores
            ? condition.options.platformsAndStores.map(function (selection) {
                return selection.name;
              })
            : [];
          selections = edited.length ? edited : platforms.concat(stores);
          return $translate.instant(
            'automations.condition-phrases.MatchPlatformNameOrStore',
            {
              asSentence,
              selections: $filter('toCommaList')(selections, {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
            },
            'messageformat'
          );

        case 'IncludeOrderTagName':
          selections = condition.options.order_tag_list.split(',');
          return $translate.instant(
            'automations.condition-phrases.IncludeOrderTagName',
            {
              asSentence,
              selections: $filter('toCommaList')(_.escape(selections), {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
              count: $filter('intlNumber')(selections.length),
            },
            'messageformat'
          );

        case 'MatchSku':
          selections = condition.options.shipment_items_sku;
          return $translate.instant(
            'automations.condition-phrases.MatchSku',
            {
              asSentence,
              selections: $filter('toCommaList')(_.escape(selections), {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
              operator: condition.options.sign.replace(/\s/g, '_'),
              count: $filter('intlNumber')(selections.length),
            },
            'messageformat'
          );

        case 'MatchBuyerSelectedCourierName':
          return $translate.instant(
            'automations.condition-phrases.MatchBuyerSelectedCourierName',
            {
              asSentence,
              operator: condition.options.sign,
              value: condition.options.buyer_selected_courier_name,
            },
            'messageformat'
          );

        case 'MatchZipcode':
          selections = condition.options.zipcodes.split(',');
          return $translate.instant(
            'automations.condition-phrases.MatchZipcode',
            {
              asSentence,
              selections: $filter('toCommaList')(_.escape(selections), {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
              operator: condition.options.operator,
              exact: condition.options.operator !== 'starts_with',
            },
            'messageformat'
          );

        case 'MatchOperation':
          return $translate.instant(
            'automations.condition-phrases.MatchOperation',
            {
              asSentence,
              relation: condition.options.sign,
              value: $filter('intlCurrency')(condition.options.value, condition.options.currency),
            },
            'messageformat'
          );

        case 'MatchItemsCount':
          return $translate.instant(
            'automations.condition-phrases.MatchItemsCount',
            {
              asSentence,
              relation: condition.options.sign,
              value: $filter('intlNumber')(condition.options.shipment_items_count),
            },
            'messageformat'
          );

        case 'MatchWeight':
          return $translate.instant(
            'automations.condition-phrases.MatchWeight',
            {
              asSentence,
              relation: condition.options.sign,
              value: $filter('intlNumber')(condition.options.total_actual_weight),
              weightunit: UserSession.getCompanyWeightUnit(),
            },
            'messageformat'
          );

        default:
          break;
      }
    };

    service.summarizeAction = function (action, asSentence) {
      const actionName = Action.abbr(action.name);
      let selections;

      switch (actionName) {
        case 'AddNeverCourier':
          if (action.options.never_courier_ids) {
            selections = action.options.never_courier_ids.split(',').map(_pickCourier);
          } else {
            selections = (
              Array.isArray(action.options) ? action.options : action.options.never_couriers
            ).map(function (courier) {
              return courier
                ? `${courier.display_name} [${courier.origin_country_alpha2}]`
                : _handleMissingRecords('courier');
            });
          }
          return $translate.instant(
            'automations.action-phrases.AddNeverCourier',
            {
              asSentence,
              selections: $filter('toCommaList')(_.escape(selections), {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
            },
            'messageformat'
          );

        case 'AddPreferredCourier':
          selections = action.options.preferred_courier_ids
            ? action.options.preferred_courier_ids.split(',').map(_pickCourier)
            : (action.options.couriers || action.options.preferred_couriers).map(function (
                courier
              ) {
                return courier
                  ? `${courier.display_name} [${courier.origin_country_alpha2}]`
                  : _handleMissingRecords('courier');
              });
          return $translate.instant(
            'automations.action-phrases.AddPreferredCourier',
            {
              asSentence,
              selections: $filter('toCommaList')(_.escape(selections), {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
              do_not_authorize_fallback: action.options.do_not_authorize_fallback,
            },
            'messageformat'
          );

        case 'ForceIncoterms':
          return $translate.instant(
            'automations.action-phrases.ForceIncoterms',
            {
              asSentence,
              incoterms: action.options.incoterms.value || action.options.incoterms,
            },
            'messageformat'
          );

        case 'ForceSortBy':
          return $translate.instant(
            'automations.action-phrases.ForceSortBy',
            {
              asSentence,
              sort_by: action.options.sort_by.category || action.options.sort_by,
            },
            'messageformat'
          );

        case 'ForceInsurance':
          return $translate.instant(
            'automations.action-phrases.ForceInsurance',
            {
              asSentence,
              is_insured: action.options.is_insured || action.options.insurance.value,
            },
            'messageformat'
          );

        case 'ForceTrackingRating':
          return $translate.instant(
            'automations.action-phrases.ForceTrackingRating',
            {
              asSentence,
              force_tracking_rating: (
                action.options.force_tracking_rating || action.options.tracking.value
              ).replace(/,/g, ''),
            },
            'messageformat'
          );

        case 'ForcePackage':
          selections = action.options.force_box_id
            ? _pickBox(action.options.force_box_id)
            : _.escape(action.options.box && action.options.box.name);
          return $translate.instant(
            'automations.action-phrases.ForcePackage',
            {
              asSentence,
              selections: $filter('toCommaList')(selections, {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
            },
            'messageformat'
          );

        case 'RejectPackages':
          selections = (
            action.options.never_boxes ||
            (action.options.never_package_ids ? action.options.never_package_ids.split(',') : [])
          ).map(_pickBox);
          return $translate.instant(
            'automations.action-phrases.RejectPackages',
            {
              asSentence,
              selections: $filter('toCommaList')(selections, {
                conjunction: $translate.instant('global.or'),
                limit: 8,
                className: service.defaultCssClass,
              }),
            },
            'messageformat'
          );

        case 'ForceResidentialSurcharge':
          return $translate.instant(
            'automations.action-phrases.ForceResidentialSurcharge',
            {
              asSentence,
              set_as_residential: action.options.set_as_residential || action.options.residential,
            },
            'messageformat'
          );

        case 'ForceShipFrom':
          return $translate.instant(
            'automations.action-phrases.ForceShipFrom',
            {
              asSentence,
              origin_address: action.options.origin_address_id
                ? _pickAddress(action.options.origin_address_id)
                : _.escape(action.options.shipFrom.display),
            },
            'messageformat'
          );

        case 'ForceAutomatedReturnRequested':
          return $translate.instant(
            'automations.action-phrases.ForceAutomatedReturnRequested',
            {
              asSentence,
              force_automated_return_requested:
                action.options.force_automated_return_requested === 'true',
            },
            'messageformat'
          );

        case 'AddForcedDeliveryTime':
          return $translate.instant(
            'automations.action-phrases.AddForcedDeliveryTime',
            {
              asSentence,
              operator: $translate.instant(`global.relation.duration.${action.options.operator}`),
              value: $filter('intlNumber')(action.options.value),
            },
            'messageformat'
          );

        case 'SplitParcelsBySku':
          return $translate.instant(
            'automations.action-phrases.SplitParcelsBySku',
            { asSentence, split_orders: action.options.group_by_sku },
            'messageformat'
          );

        default:
          break;
      }
    };

    service.summarize = function (automation, stripHtml) {
      const conditionCount =
        automation.rules_attributes &&
        automation.rules_attributes.length &&
        automation.rules_attributes[0].name === 'Automation::Rules::MatchAll'
          ? 0
          : automation.rules_attributes.length;

      const summary = $translate
        .instant(
          'automations.summary',
          {
            count: conditionCount,
            conditions: $filter('toCommaList')(
              automation.rules_attributes.filter(_ignoreDeleted).map(service.summarizeCondition),
              { conjuntion: 'global.and', commaReplacement: ';' }
            ),
            actions: $filter('toCommaList')(
              automation.actions_attributes.filter(_ignoreDeleted).map(service.summarizeAction),
              { conjuntion: 'global.and', commaReplacement: ';' }
            ),
          },
          'messageformat'
        )
        .replace(/<\/?b>/g, '')
        .replaceAll(/\s{2,}/g, ' ');

      return stripHtml ? summary.replace(/<\/?[^>]+(>|$)/g, '') : summary;
    };

    function _ignoreDeleted(actionOrCondition) {
      return !actionOrCondition._destroy;
    }

    function _getArray(input) {
      return Array.isArray(input) ? input : input.split(/,\s?/);
    }

    function _pickCategory(id) {
      const idString = typeof id === 'string' ? id : id.toString();
      const category = service.itemCategories.find(function (category) {
        return category.id.toString() === idString;
      });

      if (!category) return _handleMissingRecords('category');
      const slug = `global.product-categories.${category.slug}`;
      const translation = $translate.instant(slug);
      const failedTranslation = translation === slug;
      return `<b>${failedTranslation ? category.name : translation}</b>`;
    }

    function _pickCourier(id) {
      const courier = CourierService.activeCouriers.find(function (courier) {
        return courier.id === id;
      });
      return courier
        ? courier.display_name +
            (courier.origin_country_alpha2 ? ` [${courier.origin_country_alpha2}]` : '')
        : _handleMissingRecords('courier');
    }

    // NOTE: Until we can refactor formatToSave() in automations.controller.js,
    // this argument might be either a number  or string
    function _pickCountry(id) {
      const country = service.countries.find(function (country) {
        return country.id === id || country.id.toString() === id;
      });
      return country ? country.simplified_name : _handleMissingRecords('country');
    }

    function _groupStates(aggregator, stateCode) {
      const parts = stateCode.split('-');
      if (parts.length !== 2) return _handleMissingRecords('state');

      const state = StatesService.states.find(function (state) {
        return state.country_id.toString() === parts[0] && state.abbr === parts[1];
      });

      if (!aggregator[state.country_id]) aggregator[state.country_id] = [];
      aggregator[state.country_id].push(state.name);

      return aggregator;
    }

    function _displayStates(entry) {
      const country = service.countries.find(function (country) {
        return country.id.toString() === entry[0];
      });
      return `${$filter('toCommaList')(entry[1], {
        conjunction: $translate.instant('global.or'),
        limit: 6,
      })} (${country.simplified_name})`;
    }

    function _pickStore(idString) {
      const store = StoreService.stores.find(function (store) {
        return store.id.toString() === idString;
      });
      return store ? store.name : _handleMissingRecords('store');
    }

    function _pickBox(idOrBox) {
      const box =
        typeof idOrBox === 'string'
          ? Action.boxes.find(function (box) {
              return box.id === idOrBox;
            })
          : idOrBox;
      return box ? _.escape(box.name) : _handleMissingRecords('box');
    }

    function _pickAddress(id) {
      const address = Action.origins.find(function (address) {
        return address.id === id;
      });
      return address ? _.escape(address.display) : _handleMissingRecords('address');
    }

    function _getCountries() {
      return $q(function (resolve, reject) {
        CountryService.getCountries()
          .then(function (data) {
            service.countries = data.countries;
            resolve();
          })
          .catch(reject);
      });
    }

    function _getItemCategories() {
      return $q(function (resolve, reject) {
        ItemCategoryService.getItemCategories()
          .then(function (data) {
            service.itemCategories = data;
            resolve();
          })
          .catch(reject);
      });
    }

    function _getBoxes() {
      return $q
        .all([
          BoxService.getCustomBoxesSelectOptions(),
          FlatRateBoxService.getFlatRateBoxesSelectOptions(),
        ])
        .then(function (response) {
          const customBoxes = response[0];
          const flatRateBoxes = response[1];

          const allBoxes = customBoxes.concat(flatRateBoxes);

          Action.boxes = _removeBoxesWithNoId(allBoxes);
        });
    }

    function _getShippingAddresses() {
      return AddressService.getAddresses().then(function (response) {
        Action.origins = response.shippingAddresses.map(function (address) {
          const joinedAddress = `${[
            address.line_1,
            address.line_2,
            address.line_3,
            address.city,
            address.state,
            address.postalCode,
          ]
            .join(' ')
            .trim()
            .replace(/\s{2,}/g, ' ')}, ${address.country.name}`;
          address.display = address.nickname || joinedAddress;
          return address;
        });
      });
    }

    function _removeBoxesWithNoId(boxes) {
      return boxes.filter(function (box) {
        return box.id || box.flat_rate_box_id;
      });
    }

    function _handleMissingRecords(type) {
      return `<i>${service.translations[`global.unknown.${type}`]}</i>`;
    }
  }

  angular
    .module('app.factory.ShippingRules', [])
    .factory('ShippingRules', ShippingRules)
    .service('AutomationService', AutomationService);
})();
