import ISubscriptionGateway, {
  CurrentSubscription,
} from '@client/core/corelogic/ports/subscription.interface';
import angular from 'angular';
import { IUserSession } from 'typings/user-session';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { IPlansDetail } from '@typings/subscription';
import CurrentSubscriptionMapper from '../mappers/currentSubscriptionMapper';

export default class NgSubscriptionGateway implements ISubscriptionGateway {
  private SubscriptionService: SubscriptionService;
  private UserSession: IUserSession;
  private mapper: CurrentSubscriptionMapper;

  constructor() {
    this.SubscriptionService = angular.element(document.body).injector().get('SubscriptionService');
    this.UserSession = angular.element(document.body).injector().get('UserSession');
    this.mapper = new CurrentSubscriptionMapper();
  }

  getCurrent(): Promise<CurrentSubscription> {
    return new Promise((resolve, reject) => {
      this.SubscriptionService.destroyCurrentSubscription();
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: this.UserSession.getCompanyId(),
      })
        .then((response) => {
          return resolve(this.mapper.fromAPI(response));
        })
        .catch(reject);
    });
  }

  getPlans(): Promise<IPlansDetail> {
    return new Promise((resolve, reject) => {
      this.SubscriptionService.fetchPlansDetail({
        country_id: this.UserSession.getCompanyCountryId(),
      })
        .then((response) => {
          return resolve(response);
        })
        .catch(reject);
    });
  }
}
