import angular from 'angular';

import registrationService from '../registration';
import { BigCommerceAuthService } from './bigcommerce-auth.service';
import { BigCommerceResource } from './bigcommerce-auth.resource';

const MODULE_NAME = 'auth.services.bigcommerce-auth';

angular
  .module(MODULE_NAME, [registrationService])
  .service('BigCommerceAuthService', BigCommerceAuthService)
  .service('BigCommerceResource', BigCommerceResource);

export default MODULE_NAME;
