BoxResource.$inject = ['$resource', 'API'];
function BoxResource($resource, API) {
  const endpointUrl = API.endpoint;

  return $resource(
    `${endpointUrl}/companies/:company_id/boxes/:id`,
    {
      company_id: '@_company_id',
      id: '@_id',
    },
    {
      query: { method: 'GET' },
      create: { method: 'POST' },
      update: { method: 'PATCH' },
      delete: { method: 'DELETE' },
    }
  );
}

export { BoxResource };
