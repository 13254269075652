import template from './item-row-label.html?raw';
import style from './item-row-label.module.scss';

class ItemRowLabel {
  static $inject = ['$element'];

  constructor($element) {
    this.style = style;
    this.$element = $element;
  }

  $onInit() {
    const componentStyle = this.$element[0].style;

    const width = this.esFixedWidth ? `${this.esFixedWidth}px` : null;
    const percentWidth = this.esPercentWidth ? `${this.esPercentWidth}%` : null;

    componentStyle.minWidth = width;
    componentStyle.width = width || percentWidth;
  }
}

const ItemRowLabelComponent = {
  template,
  transclude: true,
  controller: ItemRowLabel,
  bindings: {
    label: '@',
    esFixedWidth: '@',
    esPercentWidth: '@',
  },
};

export { ItemRowLabelComponent };
