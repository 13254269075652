import template from './uber-freight-login.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('home.uber-freight-login', {
        url: 'uber-freight/login',
        template,
        controller: 'UberFreightLoginCtrl',
        controllerAs: 'vm',
      });
    },
  ]);
})();
