import template from './shipment-upload-progress.html?raw';

('use strict');

angular.module('easyshipDashboardApp').directive('shipmentUploadProgress', function () {
  return {
    template,
    restrict: 'EA',
    link: function (scope, element, attrs) {},
    controller: [
      '$scope',
      'ReportService',
      'UserSession',
      'ShipmentList',
      ($scope, ReportService, UserSession, ShipmentList) => {
        $scope.ReportService = ReportService;
        $scope.UserSession = UserSession;
        $scope.ShipmentList = ShipmentList;
      },
    ],
  };
});
