import { useMutation } from '@tanstack/react-query';
import { useVerificationGateway } from '../providers/VerificationGatewayProvider';
import { SendOtpEmailPayload } from '../models/payload/verification-payload';

interface SendOtpEmailMutationParams {
  payload: SendOtpEmailPayload;
}

export default function useSendOtpEmailMutation() {
  const { verificationGateway } = useVerificationGateway();

  return useMutation({
    mutationFn: ({ payload }: SendOtpEmailMutationParams) =>
      verificationGateway.sendOtpEmail(payload),
  });
}
