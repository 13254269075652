import React from 'react';
import { FormattedMessage } from 'react-intl';
import { react2angular } from 'react2angular';

import HeaderBanner from '@client/core/components/react/HeaderBanner';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

function AppleLink(text: string) {
  return (
    <a href="https://support.apple.com/en-vn/HT213058" target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
}

export default function SafariBugBanner() {
  const matchSafari15 = /version\/(15\.?\d?).*safari/i.exec(navigator.appVersion);
  const show = !!matchSafari15 && parseFloat(matchSafari15[1]) < 15.3;

  return (
    <HeaderBanner show={show}>
      <FormattedMessage id="banners.safari-15" values={{ a: AppleLink }} />
    </HeaderBanner>
  );
}

export function WrappedSafariBugBanner() {
  return (
    <ReactRootProviders>
      <SafariBugBanner />
    </ReactRootProviders>
  );
}

export const AngularSafariBugBanner = react2angular(WrappedSafariBugBanner);
