import angular from 'angular';

import { EditModalComponent } from './edit-modal.component';

const MODULE_NAME = 'app.pickups.edit-modal';

angular
  .module(MODULE_NAME, ['app.service.CountryService', 'app.pickups'])
  .component('esPickupsEditModal', EditModalComponent);

export default MODULE_NAME;
