/* eslint-disable @typescript-eslint/naming-convention */
import { IBoxModalService, IBoxObject, IBoxDetail, IBoxService } from 'typings/boxes';
import { IItemCategory, IItemCategoryService } from 'typings/item-category';
import { IStoreService, DimensionFallback } from 'typings/store';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import iconProductsUrl from '@assets/images/dashboard/connect/icon-products.svg';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './products-card.module.scss';
import template from './products-card.html?raw';

type DimensionKey = 'l' | 'w' | 'h' | 'all' | 'unit';
type Dimensions = {
  length: number | undefined;
  width: number | undefined;
  height: number | undefined;
};
interface IDimensionsForm {
  length?: number | string | null;
  width?: number | string | null;
  height?: number | string | null;
}
interface IProductsCardForm {
  sync_products?: boolean;
  default_category_id?: number;
  default_item_weight: string | null; // should parse as a number
  default_dimensions: IDimensionsForm;
  fallback_type?: DimensionFallback;
  default_package_id?: string;
  default_package_type?: string | null;
}
type ProductsCardFormKey =
  | 'sync_products'
  | 'default_category_id'
  | 'default_item_weight'
  | 'default_dimensions'
  | 'fallback_type'
  | 'default_package_id';

class StoreSettingsProductsCardController implements IComponentController {
  style = style;
  iconProductsUrl = iconProductsUrl;
  expanded = false;
  showingFallbacksEditor = false;
  busy = false;
  form: IProductsCardForm = {
    sync_products: false,
    default_category_id: undefined,
    default_item_weight: '',
    default_dimensions: {
      length: undefined,
      width: undefined,
      height: undefined,
    },
    fallback_type: 'no_fallback',
    default_package_id: undefined,
  };
  productCount?: number;
  boxOptions?: (IBoxObject | IBoxDetail)[];
  defaultBox?: IBoxObject | IBoxDetail;
  wrappers = {
    b: (chunk: string) => `<span class="strong text-ink-900">${chunk}</span>`,
  };
  fallbackForm: any;

  static $inject = [
    '$rootScope',
    '$translate',
    'StoreService',
    'ItemCategoryService',
    'BoxService',
    'UserSession',
    'AppCuesService',
    'BoxModalService',
  ];
  constructor(
    private $rootScope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private StoreService: IStoreService,
    private ItemCategoryService: IItemCategoryService,
    private BoxService: IBoxService,
    private UserSession: IUserSession,
    private AppCuesService: AppCuesService,
    private BoxModalService: IBoxModalService
  ) {}

  $onInit() {
    if (!this.StoreService.activeStore) return;

    const {
      sync_products,
      default_category_id,
      default_item_weight,
      default_item_length,
      default_item_width,
      default_item_height,
      fallback_type,
      default_package_id,
      default_package_type,
    } = this.StoreService.activeStore;
    this.form = {
      sync_products: !!sync_products,
      default_category_id,
      default_item_weight: default_item_weight?.toString() || '',
      default_dimensions: {
        length: default_item_length,
        width: default_item_width,
        height: default_item_height,
      },
      fallback_type,
      default_package_id,
      default_package_type,
    };

    const productRequestParams = {
      store_id: this.StoreService.activeStore.id,
      limit: this.StoreService.defaultLimit,
      offset: 0,
    };
    this.StoreService.getProducts(productRequestParams).then(({ total_record_count }) => {
      this.productCount = total_record_count;
    });

    const ADD_BOX_OPTION: IBoxObject = {
      id: 'add-box',
      name: this.$translate.instant('store.features.boxes-add'),
      type: 'placeholder',
    };

    this.BoxService.getBoxes().then(({ boxes }) => {
      const formattedBoxes = boxes
        .filter(({ is_active }) => is_active)
        .map((box) => ({
          ...box,
          name: this.$translate.instant(
            'shipments.filter.box.option-no-count',
            {
              name: box.name,
              dimensions: this.BoxService.getUnitsDisplayText(box),
            },
            'messageformat'
          ),
        }));

      this.boxOptions = [...formattedBoxes, ADD_BOX_OPTION];
      this.defaultBox = boxes.find(
        ({ id }) => id === this.StoreService.activeStore?.default_package_id
      );
    });

    this.$rootScope.$on('editing-fallbacks', () => {
      this.expanded = true;
      this.showingFallbacksEditor = true;
    });
  }

  saveToggle(key: ProductsCardFormKey, value: string | boolean) {
    if (!this.StoreService.activeStore) return;

    const changes = { [key]: value };
    const { id } = this.StoreService.activeStore;

    this.busy = true;
    this.StoreService.updateStore({ id, ...changes })
      .then((data) => {
        this.StoreService.activeStore = { ...this.StoreService.activeStore, ...data.store };
        toastSuccess(this.$translate.instant('store.notifications.product-sync-update'));
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy = false;
      });
  }

  handleChange = (
    value: number | string | IDimensionsForm | DimensionFallback,
    key: ProductsCardFormKey
    // eslint-disable-next-line consistent-return
  ) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'default_item_weight':
        this.form[key] = value as string;
        break;

      case 'default_category_id':
        this.form[key] = value as number;
        break;

      case 'default_package_id': {
        if (value === 'add-box') {
          return this.BoxModalService.open('add').then((newBox?: IBoxDetail) => {
            if (!newBox) return;

            // eslint-disable-next-line no-param-reassign
            newBox.name = this.$translate.instant(
              'shipments.filter.box.option-no-count',
              {
                name: newBox.name,
                dimensions: this.BoxService.getUnitsDisplayText(newBox),
              },
              'messageformat'
            );

            if (!Number.isNaN(this.boxOptions?.length)) {
              this.boxOptions?.splice(this.boxOptions.length - 1, 0, newBox);
            }
            this.defaultBox = newBox;
            this.handleChange(newBox.id, 'default_package_id');
            // NOTE: box has been created, but not yet saved as dimensions default
          });
        }

        const box = this.boxOptions?.find(({ id }) => id === value);
        // eslint-disable-next-line consistent-return
        if (!box) return;

        this.form.default_package_id = value as string;
        this.form.default_package_type = 'Box'; // from box.type?
        break;
      }

      case 'default_dimensions': {
        const { length, width, height } = value as IDimensionsForm;
        this.form.default_dimensions = {
          length: parseFloat(length as string),
          width: parseFloat(width as string),
          height: parseFloat(height as string),
        };
        break;
      }

      case 'fallback_type':
        this.form.fallback_type = value as DimensionFallback;
        break;
    }
  };

  saveDefaults() {
    if (!this.StoreService.activeStore) return;

    const { default_dimensions, default_item_weight, ...rest } = this.form;
    const { length, width, height } = default_dimensions as Dimensions;
    const changes = {
      ...rest,
      default_item_weight: Number(default_item_weight),
      default_package_type: this.form.fallback_type === 'box' ? 'Box' : null,
      default_item_length: length,
      default_item_width: width,
      default_item_height: height,
    };
    const { id } = this.StoreService.activeStore;

    this.busy = true;
    this.StoreService.updateStore({ id, ...changes })
      .then((data) => {
        this.StoreService.activeStore = { ...this.StoreService.activeStore, ...data.store };
        this.defaultBox = this.boxOptions?.find(
          ({ id }) => id === this.StoreService.activeStore?.default_package_id
        );
        toastSuccess(this.$translate.instant('store.notifications.fallback-settings-update'));

        this.showingFallbacksEditor = false;
      })
      .catch(() => {
        toastError(this.$translate.instant('store.notifications.fallback-error'));
      })
      .finally(() => {
        this.busy = false;
      });
  }

  toggleCardSize() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.AppCuesService.track('Store | Expand Product Settings', null, true);
    }
  }

  toggleFallbacksEditor() {
    this.showingFallbacksEditor = !this.showingFallbacksEditor;
  }

  get platformAllowsProductSync(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.products;
  }

  get platformAllowsDefaultWeight(): boolean {
    return !!this.StoreService.activeStore?.platform?.actions?.default_weight;
  }

  get itemCategories(): IItemCategory[] {
    return this.ItemCategoryService.itemCategories;
  }

  get defaultCategoryName(): string | undefined {
    return this.ItemCategoryService.itemCategories.find(
      (category) => category.id === this.StoreService.activeStore?.default_category_id
    )?.name;
  }

  get defaultItemWeight(): number | undefined | null {
    return this.StoreService.activeStore?.default_item_weight;
  }

  get dimensionFallback(): string | undefined {
    return this.StoreService.activeStore?.fallback_type;
  }

  get defaultBoxName(): string | undefined {
    return this.defaultBox?.name;
  }

  get defaultItemDimensions(): {
    [key in DimensionKey]?: number | string | boolean | undefined | null;
  } {
    return {
      unit: this.UserSession.company.dimensions_unit,
      l: this.StoreService.activeStore?.default_item_length,
      w: this.StoreService.activeStore?.default_item_width,
      h: this.StoreService.activeStore?.default_item_height,
    };
  }

  get weightUnit(): string | null {
    return this.UserSession.company.weight_unit;
  }

  get dimensionsUnit(): string | null {
    return this.UserSession.company.dimensions_unit;
  }

  get canSave(): boolean {
    if (
      !this.form.default_category_id ||
      (!!this.form.default_item_weight && Number(this.form.default_item_weight) <= 0) ||
      this.fallbackForm.$invalid
    )
      return false;
    if (this.form.fallback_type === 'box') return !!this.form.default_package_id;
    if (this.form.fallback_type === 'product') {
      return !!(
        this.form.default_dimensions.length &&
        this.form.default_dimensions.width &&
        this.form.default_dimensions.height
      );
    }

    return true;
  }
}

const StoreSettingsProductsCardComponent: ng.IComponentOptions = {
  controller: StoreSettingsProductsCardController,
  template,
  bindings: {},
};

export { StoreSettingsProductsCardComponent };
