/* eslint-disable max-classes-per-file */
import template from './split-modal.html?raw';

class SplitModalCtrl {
  static $inject = ['shipment', 'SplitModal', 'MixpanelService'];

  constructor(shipment, SplitModal, MixpanelService) {
    this.shipment = shipment;
    this.SplitModal = SplitModal;
    this.MixpanelService = MixpanelService;
  }

  close() {
    this.MixpanelService.track('Multiple - Select Split - Cancel');
    this.SplitModal.close();
  }
}

class SplitModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open({ shipment }) {
    this.modalReference = this.$uibModal.open({
      template,
      controller: SplitModalCtrl,
      controllerAs: '$ctrl',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        shipment: () => shipment,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { SplitModal };
