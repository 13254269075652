import template from './expandable-section.html?raw';
import style from './expandable-section.module.scss';

class ExpandableSection {
  constructor() {
    this.style = style;
  }

  $onInit() {
    this._validateBindings();
  }

  _validateBindings() {
    if (typeof this.esId !== 'string' || !this.esId.length) {
      throw new Error('EasyshipExpandableSection: An es-id binding must be provided.');
    }
  }
}

const ExpandableSectionComponent = {
  controller: ExpandableSection,
  transclude: {
    title: '?sectionTitle',
    content: '?sectionContent',
  },
  template,
  bindings: {
    esId: '@',
  },
};

export { ExpandableSectionComponent };
