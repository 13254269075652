import { IUserSession } from 'typings/user-session';
import { ICompanyService } from 'typings/company';

import { IComponentController } from 'angular';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './commercial-invoice.html?raw';
import style from './commercial-invoice.module.scss';

class CommercialInvoice implements IComponentController {
  remarksForm!: ng.IFormController;
  savingRemarks = false;
  style = style;
  wrappers = {
    a(chunk: string) {
      return `<a href="https://support.easyship.com/hc/en-us/articles/20558487762585-How-to-Add-a-Remark-to-Your-Commercial-Invoice" target="_blank" rel="noopener">${chunk}</a>`;
    },
  };

  static $inject = ['$translate', 'UserSession', 'CompanyService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private CompanyService: ICompanyService
  ) {}

  $onInit() {
    setTimeout(() => {
      this.remarksTextareaOnChange(this.UserSession.company.commercial_invoice_remark ?? '');
    });
  }

  canSaveRemarks() {
    return !(this.savingRemarks || this.remarksForm.$invalid || this.remarksForm.$pristine);
  }

  remarksTextareaOnChange(value: string) {
    this.remarksForm.remarks = value;
  }

  onSubmit() {
    if (!this.canSaveRemarks()) {
      return;
    }

    this.savingRemarks = true;

    this.CompanyService.updateCommercialInvoiceRemarks(this.remarksForm.remarks)
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            {
              noun: this.$translate.instant('global.setting').toLowerCase(),
            },
            'messageformat'
          )
        );
      })
      .catch((err) => {
        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.$translate.instant('global.setting').toLowerCase(),
            details: err.data.status,
          })
        );
      })
      .finally(() => {
        this.savingRemarks = false;
      });
  }
}

const CommercialInvoiceComponent: ng.IComponentOptions = {
  controller: CommercialInvoice,
  template,
};

export { CommercialInvoiceComponent };
