import React from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';

import { SubscriptionServiceProvider } from '@client/src/global/context/SubscriptionProvider';
import {
  CourierPerCountryComponent,
  CourierPerCountryComponentProps,
} from './CourierPerCountryComponent';

export function WrappedCourierPerCountryComponent(props: CourierPerCountryComponentProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <SubscriptionServiceProvider>
          <CourierPerCountryComponent {...props} />
        </SubscriptionServiceProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularCourierPerCountryComponent = react2angular(WrappedCourierPerCountryComponent, [
  'esOnCourierSelect',
  'esCloseCourierCreateModal',
  'esShowUpgradeModal',
]);
