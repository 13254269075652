import angular from 'angular';

import registrationService from '../registration';
import { TradeGeckoAuthService } from './tradegecko-auth.service';

const MODULE_NAME = 'auth.services.tradegecko-auth';

angular
  .module(MODULE_NAME, [registrationService])
  .service('TradeGeckoAuthService', TradeGeckoAuthService);

export default MODULE_NAME;
