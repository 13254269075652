import angular from 'angular';
import { RequestPickupOriginGroupCardComponent } from './request-pickup-origin-group-card/request-pickup-origin-group-card.component';
import { RequestPickupCourierAdditionalOptionsComponent } from './request-pickup-courier-additional-options/request-pickup-courier-additional-options.component';
import { RequestPickupCourierHandoverOptionsComponent } from './request-pickup-courier-handover-options/request-pickup-courier-handover-options.component';
import { RequestPickupCourierServiceComponent } from './request-pickup-courier-service/request-pickup-courier-service.component';

const MODULE_NAME = 'app.global.request-pickup';

angular
  .module(MODULE_NAME, ['pascalprecht.translate', 'app.service.AddressService'])
  .component('esRequestPickupOriginGroupCard', RequestPickupOriginGroupCardComponent)
  .component(
    'esRequestPickupCourierAdditionalOptions',
    RequestPickupCourierAdditionalOptionsComponent
  )
  .component('requestPickupCourierHandoverOptions', RequestPickupCourierHandoverOptionsComponent)
  .component('esRequestPickupCourierService', RequestPickupCourierServiceComponent);

export default MODULE_NAME;
