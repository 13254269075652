import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import MarkAsDeliveredDialog from '@client/src/manage-shipments/MarkAsDeliveredDialog';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface MarkAsDeliveredMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onMarkAsDelivered: () => void;
  markAsDeliveredModalIsOpen: boolean;
  markAsDeliveredModalOnClose: () => void;
  markAsDeliveredModalOnConfirm: () => void;
}

export default function MarkAsDeliveredMenuItem({
  divider = false,
  shipmentListItem,
  onMarkAsDelivered,
  markAsDeliveredModalIsOpen,
  markAsDeliveredModalOnClose,
  markAsDeliveredModalOnConfirm,
}: MarkAsDeliveredMenuItemProps) {
  const { allow_mark_as_delivered_by_user: allowMarkAsDeliveredByUser } = shipmentListItem;

  if (!allowMarkAsDeliveredByUser) {
    return null;
  }

  return (
    <MenuItem onClick={onMarkAsDelivered} divider={divider}>
      <div style={baseStyleIconBox}>
        <i className="icon-circle-tick text-xl" style={{ color: baseStyleIcon.colorGrey }} />
      </div>
      <span className="text-base">
        <FormattedMessage id="shipments.speed-dial.mark-as-delivered" />
      </span>
      <MarkAsDeliveredDialog
        isOpen={markAsDeliveredModalIsOpen}
        onClose={markAsDeliveredModalOnClose}
        onConfirm={markAsDeliveredModalOnConfirm}
      />
    </MenuItem>
  );
}
