import angular, { IComponentController } from 'angular';
import { IShipmentListScope } from 'typings/shipment';

import { IUserSession } from 'typings/user-session';
import template from './shipment-list-empty-state.html?raw';
import style from './shipment-list-empty-state.module.scss';

class ShipmentListEmptyState implements IComponentController {
  style = style;
  esType?: 'advanced' | 'manage';
  esFilterApplied?: boolean;
  esScope?: IShipmentListScope;
  wrappers = {
    a: (chunk: string) => `<a ui-sref="app.multiple">${chunk}</a>`,
  };

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  openAddShipmentsDropdown(): void {
    const element = window.document.querySelector('#add-shipments-dropdown > easyship-button');
    if (element) angular.element(element).triggerHandler('click');
  }

  get isManagePage() {
    return this.esType === 'manage';
  }

  get isShowingAll() {
    return !this.esFilterApplied && (!this.esScope || this.esScope === 'shipments_all');
  }

  get isUnscoped() {
    return !this.esScope || this.esScope === 'shipments_all';
  }

  get showCreateShipment(): boolean {
    return this.UserSession.hasUserRole('create_shipments');
  }
}

const ShipmentListEmptyStateComponent: ng.IComponentOptions = {
  controller: ShipmentListEmptyState,
  template,
  bindings: {
    esType: '<',
    esIsEmpty: '<',
    esIsView: '<',
    esBusy: '<',
    esFilterApplied: '<',
    esScope: '<',
    esOnReset: '&',
    esOnClearView: '&',
  },
};

export { ShipmentListEmptyStateComponent };
