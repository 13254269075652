StatusMessage.$inject = ['$resource', 'API'];
function StatusMessage($resource, API) {
  const endpointUrl = API.endpoint;

  return $resource(
    `${endpointUrl}/cloud/status_messages/:controller/:country_id`,
    { country_id: '@_country_id' },
    {
      query: { method: 'GET', cache: true },
      getAnalyticsMapping: { method: 'GET', params: { controller: 'analytics_mapping' } },
    }
  );
}

export { StatusMessage };
