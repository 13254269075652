import template from './merge-selected-modal-main.html?raw';

class MergeSelectedModalMain {
  constructor() {}
}

const MergeSelectedModalMainComponent = {
  template,
  controller: MergeSelectedModalMain,
  bindings: {
    isMatch: '<',
    shipments: '<',
  },
};

export { MergeSelectedModalMainComponent };
