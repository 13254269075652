import template from './order-summary-courier-row.html?raw';
import style from './order-summary-courier-row.module.scss';
import iconReturn from '@assets/images/dashboard/create-shipments/icon-return.svg';

class OrderSummaryCourierRowCtrl {
  static $inject = ['UserSession', '$translate', '$sce'];

  constructor(UserSession, $translate, $sce) {
    this.UserSession = UserSession;
    this.$translate = $translate;
    this.$sce = $sce;
    this.style = style;
  }

  $onInit() {
    if (typeof this.totals !== 'undefined') {
      this.fees = {
        total_charge: this._setTotalCharge(this.totals, this.totalFor),
        total_vat: this.totals.total_vat,
      };
      this.shipmentsCount = this._setShipmentsCount(this.totals, this.totalFor);
    } else {
      this.shipmentsCount = this.courier && this.courier.shipments_count;
    }

    if (this.courier) {
      this.showPayAtCounterPill = this._courierIsHKPostToPayAtCounter(this.courier);
      this.courierName = this._buildCourierName(this.courier);
      this.tooltip = this.$translate.instant(
        'checkout.summary.cannot-insure',
        { count: this.courier.shipments_count_insured_not_charged },
        'messageformat'
      );
    }
  }

  get iconReturn() {
    return this.$sce.trustAsHtml(iconReturn);
  }

  get courierAlert() {
    return this.courier.courier_alerts?.filter(Boolean).join(' ');
  }

  get isUserRoleSettingPricesActive() {
    return this.UserSession.isUserRoleSettingPricesActive();
  }

  showPickupFeesTooltip() {
    return !this.courier?.is_easyship_courier && this.fees?.total_charge;
  }

  _buildCourierName({ courier_name, courier_nickname, courier_display_name }) {
    const displayName = courier_display_name || courier_name;

    return courier_nickname ? `${courier_nickname} - ${displayName}` : displayName;
  }

  _setTotalCharge(totals, totalFor) {
    let totalCharge;

    if (totalFor === 'Easyship') {
      totalCharge = totals.total_pay_at_easyship;
    } else if (totalFor === 'Courier') {
      totalCharge = totals.total_pay_at_courier;
    } else if (totalFor === 'Reschedule Pickup') {
      totalCharge = totals.total_pay;
    }

    return totalCharge;
  }

  _setShipmentsCount(totals, totalFor) {
    let shipmentsCount;

    switch (totalFor) {
      case 'Easyship':
        return totals.shipments_count_pay_at_easyship;
      case 'Courier':
        return totals.shipments_count_pay_at_courier;
      case 'EasyshipPayOnScan':
        return totals.shipments_count_pay_on_scan_returns;
      case 'Reschedule Pickup':
        return totals.shipments_count;
      default:
    }

    return shipmentsCount;
  }

  _courierIsHKPostToPayAtCounter(courier) {
    if (!courier || !courier.courier_admin_name) return false;
    return (
      courier.courier_admin_name.split('_')[0] === 'HKPost' &&
      courier.courier_payment_recipient === 'Courier'
    );
  }
}

const OrderSummaryCourierRowComponent = {
  template,
  controller: OrderSummaryCourierRowCtrl,
  bindings: {
    courier: '<',
    companyCurrency: '<',
    totals: '<',
    totalFor: '<',
  },
};

export { OrderSummaryCourierRowComponent };
