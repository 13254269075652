import template from './credit-card-input.html?raw';
import style from './credit-card-input.module.scss';

// TODO: This can be imported from colors.scss with sass-variable-loader
const colors = {
  '$color-text-disabled': '#C4CDD5',
  '$color-text-secondary': '#454F5B',
  '$color-text-primary': '#001222',
  '$color-red-dark': '#C00F19',
};

// https://stripe.com/docs/stripe-js/reference#elements-create
const stripeStyles = {
  classes: {
    base: style.easyshipCreditCardInput,
    focus: style.easyshipCreditCardInputFocus,
    invalid: style.easyshipCreditCardInputInvalid,
  },
  style: {
    empty: {
      iconColor: colors['$color-text-disabled'],
      '::placeholder': {
        color: colors['$color-text-disabled'],
      },
      ':focus': {
        iconColor: colors['$color-text-secondary'],
      },
      ':disabled': {
        color: colors['$color-text-disabled'],
        '::placeholder': {
          color: colors['$color-text-disabled'],
        },
      },
    },
    base: {
      iconColor: colors['$color-text-secondary'],
      color: colors['$color-text-primary'],
      fontFamily: 'Karla',
      fontSize: '13px',
      ':disabled': {
        color: colors['$color-text-disabled'],
        '::placeholder': {
          color: colors['$color-text-disabled'],
        },
      },
    },
    invalid: {
      color: colors['$color-text-primary'],
      iconColor: colors['$color-red-dark'],
    },
  },
  iconStyle: 'solid',
};

class CreditCardInput {
  static $inject = ['$window', 'UserSession', 'StripeService'];

  constructor($window, UserSession, StripeService) {
    this.style = style;
    this.$window = $window;
    this.UserSession = UserSession;
    this.StripeService = StripeService;
    this.cardInputInstance = null;
  }

  $onChanges(changesObj) {
    if (!this.cardInputInstance) return;

    const { ngDisabled } = changesObj;

    if (ngDisabled && typeof ngDisabled.currentValue === 'boolean') {
      this.cardInputInstance.update({
        disabled: ngDisabled.currentValue,
      });
    }
  }

  $onInit() {
    this._validateBindings();
  }

  async $postLink() {
    const stripe = await this.StripeService.getInstance();
    const elements = stripe.elements({
      fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Karla' }],
      locale: this.UserSession.user.dashboard_settings.language.code,
    });

    this.cardInputInstance = elements.create('card', {
      disabled: this.ngDisabled,
      ...stripeStyles,
    });

    if (document.getElementById(this.esId)) {
      this.cardInputInstance.mount(`#${this.esId}`);
    }

    if (typeof this.paymentForm !== 'undefined' && this.paymentForm !== null) {
      this.paymentForm.registerCardComponent(this.cardInputInstance);
    }
  }

  $onDestroy() {
    if (this.cardInputInstance) {
      this.cardInputInstance.destroy();
    }
  }

  hasLabel() {
    return !!angular.element('ng-transclude', this.$element).html();
  }

  _validateBindings() {
    if (typeof this.esId !== 'string' || !this.esId.length) {
      throw new Error('EasyshipCreditCardInput: An es-id binding must be provided.');
    }
  }
}

const CreditCardInputComponent = {
  controller: CreditCardInput,
  template,
  transclude: true,
  require: {
    paymentForm: '?^^easyshipPaymentForm',
  },
  bindings: {
    esId: '@',
    ngDisabled: '<',
  },
};

export { CreditCardInputComponent };
