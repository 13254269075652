import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { Platform } from '@client/data/platform';
import { IPublicPlatform } from 'typings/platform';
import { IOnboardingParams, IOnboardingResponse } from 'typings/onboarding';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { PersonaOptions } from '../components/Personas/types';
import { usePlatformsQuery } from './usePlatformsQuery';
import { useOnboardingMutation } from './useOnboardingMutation';

type SectionKeys = 'persona' | 'platform';

interface OnboardingContextData {
  currentSection: SectionKeys;
  selectedPersona: PersonaOptions | null;
  selectedPlatforms: Platform[];
  publicPlatforms: IPublicPlatform[];
  isCrowdfunding: boolean;
  navToPlatform: (persona: PersonaOptions) => void;
  onPersonaSelect: (persona: PersonaOptions) => void;
  onPlatformClick: (platform: Platform) => void;
  sendUpdateRequest: (params: IOnboardingParams) => void;
}

const OnboardingContext = createContext<OnboardingContextData | undefined>(undefined);

interface OnboardingContextProps {
  onClose: (response: IOnboardingResponse) => void;
  children: React.ReactNode;
}

function OnboardingProvider({ onClose, children }: OnboardingContextProps) {
  const [currentSection, setCurrentSection] = useState<SectionKeys>('persona');
  const [selectedPersona, setSelectedPersona] = useState<PersonaOptions | null>(null);
  const [selectedPlatforms, setSelectedPlatforms] = useState<Platform[]>([]);

  const { data: publicPlatforms = [] } = usePlatformsQuery();
  const { mutateAsync: onboardingMutateAsync } = useOnboardingMutation();

  const isCrowdfunding = selectedPersona === 'crowdfunding';

  const sendUpdateRequest = useCallback(
    (params: IOnboardingParams) => {
      onboardingMutateAsync(params)
        .then((res: IOnboardingResponse) => {
          MixpanelService.track(`Onboarding - Complete`);

          onClose(res);
        })
        .catch((reason: unknown) => {
          console.error(reason);
        });
    },
    [onboardingMutateAsync, onClose]
  );

  const navToPlatform = useCallback((persona: PersonaOptions) => {
    setSelectedPersona(persona);
    setCurrentSection('platform');
  }, []);

  const handlePersonaSelect = useCallback(
    (persona: PersonaOptions) => {
      const params = {
        persona,
      };

      MixpanelService.setPeople(params);
      MixpanelService.track(`Onboarding - Declared Persona - Selected`, params);
      setSelectedPersona(persona);

      if (['personal', 'developer'].includes(persona)) {
        sendUpdateRequest(params);
      } else {
        onboardingMutateAsync(params)
          .then(() => {
            navToPlatform(persona);
          })
          .catch((reason: unknown) => {
            console.error(reason);
          });
      }
    },
    [onboardingMutateAsync, sendUpdateRequest, navToPlatform]
  );

  const handlePlatformClick = useCallback(
    (platform: Platform) => {
      if (selectedPlatforms.includes(platform)) {
        setSelectedPlatforms(selectedPlatforms.filter((value) => value !== platform));
      } else {
        setSelectedPlatforms([...selectedPlatforms, platform]);
      }
    },
    [selectedPlatforms]
  );

  const values = useMemo(() => {
    return {
      currentSection,
      selectedPersona,
      selectedPlatforms,
      isCrowdfunding,
      publicPlatforms,
      navToPlatform,
      onPersonaSelect: handlePersonaSelect,
      onPlatformClick: handlePlatformClick,
      sendUpdateRequest,
    };
  }, [
    currentSection,
    selectedPersona,
    selectedPlatforms,
    isCrowdfunding,
    publicPlatforms,
    navToPlatform,
    handlePersonaSelect,
    handlePlatformClick,
    sendUpdateRequest,
  ]);

  return <OnboardingContext.Provider value={values}>{children}</OnboardingContext.Provider>;
}

function useOnboarding() {
  const context = useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error('useOnboarding must be used within a OnboardingProvider');
  }
  return context;
}

export { OnboardingProvider, useOnboarding };
