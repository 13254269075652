(function () {
  Action.$inject = [
    '$translate',
    'AutomationAction',
    'HelperService',
    'SubscriptionService',
    'MixpanelService',
  ];

  function Action(
    $translate,
    AutomationAction,
    HelperService,
    SubscriptionService,
    MixpanelService
  ) {
    const Action = this;
    Action.show = {};
    Action.options = {};
    Action.show.chooseAction = true;
    let tempID = 0;
    const groupNames = {
      addresses: $translate.instant('sidebar.addresses'),
      boxes: $translate.instant('sidebar.boxes'),
      couriers: $translate.instant('sidebar.couriers'),
      others: $translate.instant('global.others'),
      easyshipCouriers: $translate.instant('shipments.filter.courier.easyship-courier'),
      yourCouriers: $translate.instant('shipments.filter.courier.own-courier'),
    };
    const groups = {
      'Automation::Actions::ForceResidentialSurcharge': groupNames.addresses,
      'Automation::Actions::ForceShipFrom': groupNames.addresses,
      'Automation::Actions::SplitParcelsBySku': groupNames.boxes,
      'Automation::Actions::RejectPackages': groupNames.boxes,
      'Automation::Actions::ForcePackage': groupNames.boxes,
      'Automation::Actions::AddNeverCourier': groupNames.couriers,
      'Automation::Actions::AddForcedDeliveryTime': groupNames.couriers,
      'Automation::Actions::ForceTrackingRating': groupNames.couriers,
      'Automation::Actions::AddPreferredCourier': groupNames.couriers,
      'Automation::Actions::ForceSortBy': groupNames.couriers,
      'Automation::Actions::ForceAutomatedReturnRequested': groupNames.others,
      'Automation::Actions::ForceIncoterms': groupNames.others,
      'Automation::Actions::ForceInsurance': groupNames.others,
    };
    const forcedSort = Object.keys(groups);

    Action.ACTION_IDS = {
      addNeverCourier: 1,
      addPreferredCourier: 2,
      // forceServiceType: 3,
      forceIncoterms: 4,
      forceSortBy: 5,
      forceInsurance: 6,
      forceTracking: 7,
      forceBox: 8,
      addNeverBox: 9,
      forceResidential: 10,
      forceShipFrom: 11,
      forceReturnLabel: 12,
      forceDeliveryTime: 13,
      splitParcelsBySku: 14,
    };

    AutomationAction.query(
      {},
      {},
      function (data) {
        Action.automation_actions = data.automation_actions
          .filter(function (action) {
            return (
              !Action.actionIsForceReturnLabel(action) ||
              SubscriptionService.canUsePayOnScanFeatures
            );
          })
          .map(function (action) {
            action.group = groups[action.automation_action_name] || 'Others';
            action.translation = $translate.instant(
              `automations.action-labels.${Action.abbr(action.automation_action_name)}`
            );
            return action;
          })
          .sort(function (a, b) {
            return (
              forcedSort.indexOf(a.automation_action_name) -
              forcedSort.indexOf(b.automation_action_name)
            );
          });
      },
      function () {}
    );

    Action.abbr = function (actionName) {
      return actionName.replace('Automation::Actions::', '');
    };

    /**
     * Remove from the array couriers_copy the couriers passed as param
     * @param  {array} array of couriers
     */
    Action.rejectCourierFromList = function (couriers) {
      couriers.forEach(function (courier) {
        Action.couriers_copy = _.reject(Action.couriers_copy, { id: courier.id });
      });
    };

    /**
     * Push back the elements in the array
     * Exemple: Action.rejectCourierFromList will remove the couriers available if they are selected as an option by the user
     * when the user deletes the option, it needs to be available again in the list.
     *
     * @param  {array,object} elementToAddBack
     * @param  {array} array to be populated
     */
    Action.pushBack = function (elementToAddBack, array) {
      if (angular.isArray(elementToAddBack)) {
        elementToAddBack.forEach(function (e) {
          array.push(e);
        });
      } else {
        array.push(elementToAddBack);
      }
    };

    /**
     * - Delete option from an action using splice
     * - If elementToAddBack abd arrayToPushBack are present, repopulate the array with the deleted option(s)
     * - Call showAddActionButton to display or not the 'Save action' button
     *
     * @param  {integer} index
     * @param  {array}   array of options
     * @param  {object}  element to be added back in the list available (elementToAddBack)
     * @param  {array}   array to be populated
     */
    Action.deleteOptionAction = function (index, array, elementToAddBack, arrayToPushBack) {
      array.splice(index, 1);
      Action.pushBack(elementToAddBack, arrayToPushBack);
      showAddActionButton(array);
    };

    /**
     * Display force incoterms options (select)
     */
    Action.forceIncoterms = function () {
      Action.forcedIncoterms = null;
      Action.options.forceIncoterms = true;
    };

    /**
     * Display force insurance options (select)
     */
    Action.forceInsurance = function () {
      Action.forcedInsurance = null;
      Action.options.forceInsurance = true;
    };

    /**
     * Display force tracking options (select)
     */
    Action.forceTracking = function () {
      Action.forcedTracking = null;
      Action.options.forceTracking = true;
    };

    /**
     * Display force tracking options (select)
     */
    Action.forceDeliveryTime = function () {
      Action.forcedDeliveryTime = {
        operator: 'less_than',
        value: null,
      };
      Action.options.forceDeliveryTime = true;
    };

    /**
     * Display force sort_by options (select)
     */
    Action.forceSortBy = function () {
      Action.forcedSortBy = null;
      Action.options.forceSortBy = true;
    };

    /**
     * Display force box options (select)
     */
    Action.forceBox = function () {
      Action.forcedBox = null;
      Action.options.forceBox = true;
    };

    /**
     * Display force residential options (select)
     */
    Action.forceResidential = function () {
      Action.forcedResidential = null;
      Action.options.forceResidential = true;
    };

    /**
     * Display force ship from options (select)
     */
    Action.forceShipFrom = function () {
      Action.forcedShipFrom = null;
      Action.options.forceShipFrom = true;
    };

    /**
     * Display force return label from options (select)
     */
    Action.forceReturnLabel = function () {
      Action.forcedReturnLabel = null;
      Action.options.forceReturnLabel = true;
    };

    /**
     * Actions regarding couriers
     * According the Ruby model action's name, display the proper select and define the proper array
     * Use the same function for preferred courier and never courier
     *
     * @param  {string} action name
     */
    Action.courierAction = function (action) {
      if (Action.actionIsAddNeverCourier(action)) {
        Action.options.neverCourierOptions = true;
        Action.neverCourierSelected = [];
      } else if (Action.actionIsAddPreferredCourier(action)) {
        Action.options.preferredCourierOptions = true;
        Action.preferredCourierSelected = [];
        Action.preferCourierDoNotAuthorizeFallback = false;
      }
    };

    Action.neverBox = function () {
      Action.options.neverBoxOptions = true;
      Action.neverBoxSelected = [];
    };

    /**
     * Display split order by boxes (radio)
     */
    Action.splitParcelsBySku = function () {
      Action.splitParcelsBySkuValue = null;
      Action.options.splitParcelsBySku = true;
    };

    /**
     * Empty every arrays of selected options
     * Called for example if the user select another action without adding(saving) it
     */
    Action.clearAllActionsSelected = function () {
      Action.options = {};
      Action.neverCourierSelected = [];
      Action.preferredCourierSelected = [];
      Action.preferCourierDoNotAuthorizeFallback = false;
      Action.serviceTypeActionSelected = [];
    };

    /**
     * Populates the arrays with the selected couriers
     * Remove the selected couriers from couriers_copy, the list of available couriers to select
     * Clear ng-model newPreferredCourierToAdd
     * Display the 'Save action' button
     */
    Action.addPreferredCourierToOptions = function () {
      Action.preferredCourierSelected.push(Action.newPreferredCourierToAdd);

      // remove the courier from couriers_copy so it will be hidden in the select component
      Action.rejectCourierFromList(Action.preferredCourierSelected);
      Action.newPreferredCourierToAdd = null;
      showAddActionButton(Action.preferredCourierSelected);
    };

    Action.togglePreferCourierDoNotAuthorizeFallback = function (value) {
      Action.preferCourierDoNotAuthorizeFallback = value;
    };

    Action.addNeverCourierToOptions = function () {
      Action.neverCourierSelected.push(Action.newNeverCourierToAdd);

      // remove the courier from couriers_copy so it will be hidden in the select component
      Action.rejectCourierFromList(Action.neverCourierSelected);
      Action.newNeverCourierToAdd = null;
      showAddActionButton(Action.neverCourierSelected);
    };

    Action.addNeverBoxToOptions = function () {
      // prevent user from selecting a second time
      if (
        !HelperService.hasItemWithId(
          Action.neverBoxSelected,
          Action.newNeverBoxToAdd.id || Action.newNeverBoxToAdd.flat_rate_box_id
        )
      ) {
        Action.neverBoxSelected.push(Action.newNeverBoxToAdd);
      }
      Action.newNeverBoxToAdd = null;
      showAddActionButton(Action.neverBoxSelected);
    };

    /**
     * Only call showAddActionButton because no options are needed.
     * The value is taken directly from ng-model
     *
     * @param {array}
     * @param {object}
     */
    Action.addForceToOptions = function (array, object) {
      showAddActionButton(array, object);
    };

    Action.addSplitParcelToOptions = function () {
      showAddActionButton(null, Action.splitParcelsBySku);
    };

    /**
     * Shows the Add Action button if at least one option is added
     * @param {array}
     * @param {object}
     */
    function showAddActionButton(array, object) {
      if (array) {
        if (array.length === 0) {
          Action.show.addAction = false;
        } else {
          Action.show.addAction = true;
        }
      } else if (object) {
        Action.show.addAction = true;
      } else {
        Action.show.addAction = false;
      }
    }

    /**
     * Display 'add another action' and hide the action select input
     */
    Action.addAnotherAction = function () {
      Action.show.chooseAction = true;
      Action.show.addAnotherAction = false;
    };

    /**
     * Builds an action object with the selected options and the type of action
     * and pushes it to automation.actions_attributes
     *
     * @param {array} array of actions attributes
     */
    Action.addAction = function (arrayOfAttributes) {
      // Increment the tempID to be always 'unique'
      tempID++;
      const action = Action.show.editAction
        ? Action.newActionName
        : {
            automation_action_id: Action.newActionName.automation_action_id,
            name: Action.newActionName.automation_action_name,
            origin_country_alpha2: Action.newActionName.origin_country_alpha2,
            slug: Action.newActionName.automation_action_slug,
            // Attribute a temporary ID to be able to identify the action when editing if it has not been saved in the db yet
            tempID,
          };

      action.options = {};

      if (Action.options.neverCourierOptions) {
        action.options.never_couriers = Action.neverCourierSelected;
      }

      if (Action.options.preferredCourierOptions) {
        action.options.preferred_couriers = Action.preferredCourierSelected;
        action.options.do_not_authorize_fallback = Action.preferCourierDoNotAuthorizeFallback;
      }

      if (Action.options.updateServiceTypeOptions) {
        action.options.service_type_id = Action.serviceTypeUpdate.toString();
      }

      if (Action.options.forceIncoterms) {
        action.options.incoterms = Action.forcedIncoterms;
      }

      if (Action.options.forceSortBy) {
        action.options.sort_by = Action.forcedSortBy;
      }

      if (Action.options.forceInsurance) {
        action.options.is_insured = Action.forcedInsurance;
      }

      if (Action.options.forceTracking) {
        action.options.force_tracking_rating = Action.forcedTracking;
      }

      if (Action.options.forceDeliveryTime) {
        action.options = Action.forcedDeliveryTime;
      }

      if (Action.options.forceBox) {
        action.options.box = Action.forcedBox;
      }

      if (Action.options.neverBoxOptions) {
        action.options.never_boxes = Action.neverBoxSelected;
      }

      if (Action.options.forceShipFrom) {
        action.options.shipFrom = Action.forcedShipFrom;
      }

      if (Action.options.forceResidential) {
        action.options.set_as_residential = Action.forcedResidential;
      }

      if (Action.options.forceReturnLabel) {
        action.options.force_automated_return_requested = Action.forcedReturnLabel;
      }

      if (Action.options.splitParcelsBySku) {
        action.options = { group_by_sku: Action.splitParcelsBySkuValue };
      }

      if (!Action.show.editAction) {
        arrayOfAttributes.push(action);
      }
      Action.hasActions(arrayOfAttributes);

      MixpanelService.track('Shipping Rules - Add Action', { name: action.name });
    };

    /**
     * Format the action object's content for editing
     * @param {object} action
     */
    Action.editAction = function (action) {
      // Use the actual id to identify the action to edit if it has been saved in the db already
      // otherwise use the temporary ID
      if (action.id) {
        Action.actionToEdit = action.id;
      } else {
        Action.actionToEdit = action.tempID;
      }
      Action.newActionName = action;
      Action.show.editAction = true;
      Action.show.chooseAction = false;
      Action.show.addAnotherAction = false;
      Action.options = {};

      switch (action.automation_action_id) {
        case Action.ACTION_IDS.addNeverCourier:
          Action.courierAction(action);
          Action.neverCourierSelected = action.options.never_couriers;
          break;

        case Action.ACTION_IDS.addPreferredCourier:
          Action.courierAction(action);
          Action.preferredCourierSelected = action.options.preferred_couriers;
          Action.preferCourierDoNotAuthorizeFallback = action.options.do_not_authorize_fallback;
          break;

        case Action.ACTION_IDS.forceIncoterms:
          Action.forceIncoterms();
          Action.forcedIncoterms = action.options.incoterms;
          break;

        case Action.ACTION_IDS.forceSortBy:
          Action.forceSortBy();
          Action.forcedSortBy = action.options.sort_by;
          break;

        case Action.ACTION_IDS.forceInsurance:
          Action.forceInsurance();
          Action.forcedInsurance = action.options.is_insured;
          break;

        case Action.ACTION_IDS.forceTracking:
          Action.forceTracking();
          Action.forcedTracking = action.options.force_tracking_rating;
          break;

        case Action.ACTION_IDS.forceDeliveryTime:
          Action.forceDeliveryTime();
          Action.forcedDeliveryTime = action.options;
          break;

        case Action.ACTION_IDS.forceBox:
          Action.forceBox();
          Action.forcedBox = action.options.box;
          break;

        case Action.ACTION_IDS.addNeverBox:
          Action.neverBox(action);
          Action.neverBoxSelected = action.options.never_boxes;
          break;

        case Action.ACTION_IDS.forceResidential:
          Action.forceResidential();
          Action.forcedResidential = action.options.set_as_residential;
          break;

        case Action.ACTION_IDS.forceShipFrom:
          Action.forceShipFrom();
          Action.forcedShipFrom = action.options.shipFrom;
          break;

        case Action.ACTION_IDS.forceReturnLabel:
          Action.forceReturnLabel();
          Action.forcedReturnLabel = action.options.force_automated_return_requested;
          break;

        case Action.ACTION_IDS.splitParcelsBySku:
          Action.splitParcelsBySku();
          Action.splitParcelsBySkuValue = action.options.group_by_sku;
          break;

        default:
          break;
      }
    };

    /**
     * Check if the automation has actions.
     * If so, display the right elements
     *
     * @param  {array} actionsAttributes
     */
    Action.hasActions = function (actionsAttributes) {
      const allToDestroy = _.every(actionsAttributes, '_destroy', true);
      if (actionsAttributes.length === 0) {
        Action.show.hasActions = false;
        Action.show.chooseAction = true;
        Action.show.addAnotherAction = false;
      } else if (allToDestroy) {
        Action.show.hasActions = false;
        Action.show.chooseAction = true;
        Action.show.addAnotherAction = false;
      } else {
        Action.show.hasActions = true;
      }
    };

    /**
     * Format the action from the database to use it as arrays
     *
     * @param  {object} action
     */
    Action.formatAction = function (action) {
      switch (action.automation_action_id) {
        case Action.ACTION_IDS.addNeverCourier:
          if (Array.isArray(action.options)) {
            const never_couriers = action.options;
            action.options = {};
            action.options.never_couriers = never_couriers;
          }
          break;

        case Action.ACTION_IDS.addPreferredCourier:
          action.options.preferred_couriers = action.options.couriers;

          // Format received is "true" or "false" as String
          action.options.do_not_authorize_fallback =
            action.options.do_not_authorize_fallback === 'true';
          delete action.options.couriers;
          break;

        case Action.ACTION_IDS.forceBox:
          action.options.box = action.options.force_box_id
            ? _.find(Action.boxes, { id: action.options.force_box_id })
            : _.find(Action.boxes, { flat_rate_box_id: action.options.force_flat_rate_box_id });
          break;

        case Action.ACTION_IDS.addNeverBox:
          action.options.never_boxes = _getBoxesById(action.options.never_package_ids);
          break;

        case Action.ACTION_IDS.forceShipFrom:
          action.options.shipFrom = _.find(Action.origins, {
            id: action.options.origin_address_id,
          });
          break;

        default:
          break;
      }

      return action;
    };

    function _getBoxesById(boxes) {
      if (!boxes) return;

      const arrayOfBoxesId = boxes.split(',');

      // eslint-disable-next-line consistent-return
      return arrayOfBoxesId.map(function (boxId) {
        const matchInBoxes = _.find(Action.boxes, { id: boxId });
        const matchInFRBoxes = _.find(Action.boxes, { flat_rate_box_id: boxId });

        return matchInBoxes || matchInFRBoxes;
      });
    }

    Action.actionIsAddNeverCourier = function (action) {
      return action.automation_action_id === 1;
    };

    Action.actionIsAddPreferredCourier = function (action) {
      return action.automation_action_id === 2;
    };

    Action.actionIsForceIncoterms = function (action) {
      return action.automation_action_id === 4;
    };

    Action.actionIsForceSortBy = function (action) {
      return action.automation_action_id === 5;
    };

    Action.actionIsForceInsurance = function (action) {
      return action.automation_action_id === 6;
    };

    Action.actionIsForceTracking = function (action) {
      return action.automation_action_id === 7;
    };

    Action.actionIsForceBox = function (action) {
      return action.automation_action_id === 8;
    };

    Action.actionIsAddNeverBox = function (action) {
      return action.automation_action_id === 9;
    };

    Action.actionIsForceResidential = function (action) {
      return action.automation_action_id === 10;
    };

    Action.actionIsForceShipFrom = function (action) {
      return action.automation_action_id === 11;
    };

    Action.actionIsForceReturnLabel = function (action) {
      return action.automation_action_id === 12;
    };

    Action.addAdditionalAttributesToCouriers = function (courierServices) {
      const hash = {};

      for (let i = 0; i < courierServices.length; i++) {
        const key = courierServices[i].id;
        hash[key] = courierServices[i];
        hash[key].dropOffLabel = courierServices[i].does_pickup ? '' : '(Drop Off Only)';
        hash[key].accountOwner = courierServices[i].is_easyship_courier
          ? groupNames.easyshipCouriers
          : groupNames.yourCouriers;
      }

      return Object.values(hash);
    };

    Action.getBoxSentence = function (boxes) {
      let sentence = '';

      boxes.forEach(function (box, index) {
        const boxName = (box && box.name) || 'Unknown Box';
        sentence += index ? `and ${boxName}` : `${boxName} `;
      });

      return sentence;
    };
  }

  angular.module('app.service.Action', []).service('Action', Action);
})();
