import { IComponentController } from 'angular';
import template from './status-chip.html?raw';
import style from './status-chip.module.scss';

class StatusChip implements IComponentController {
  style = style;
  esColor = '';
}

const StatusChipComponent: ng.IComponentOptions = {
  controller: StatusChip,
  template,
  transclude: true,
  bindings: {
    esColor: '@',
  },
};

export { StatusChipComponent };
