import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import ProgressIndicator from '@client/core/components/react/ProgressIndicator';
import { ClaimInsurance } from 'easyship-components/icons';
import { baseStyleIconBox } from '../styles';

interface InsuranceClaimMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onStartInsuranceClaim: () => Promise<void>;
}

export default function InsuranceClaimMenuItem({
  divider = false,
  shipmentListItem,
  onStartInsuranceClaim,
}: InsuranceClaimMenuItemProps) {
  const [isInsuranceClaimLoading, setIsInsuranceClaimLoading] = useState(false);
  const { claimable: isClaimable } = shipmentListItem;

  async function handleInsuranceClaim() {
    setIsInsuranceClaimLoading(true);
    await onStartInsuranceClaim();
    setIsInsuranceClaimLoading(false);
  }

  if (!isClaimable) {
    return null;
  }

  return (
    <ProgressIndicator show={isInsuranceClaimLoading}>
      <MenuItem onClick={handleInsuranceClaim} disabled={isInsuranceClaimLoading} divider={divider}>
        <div style={baseStyleIconBox}>
          <ClaimInsurance className="text-ink-100" />
        </div>
        <span className="text-base">
          <FormattedMessage id="shipments.speed-dial.claim-insurance" />
        </span>
      </MenuItem>
    </ProgressIndicator>
  );
}
