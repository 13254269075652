import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

interface MaskOverlayProps extends BoxProps {
  children: JSX.Element;
  innerContent: JSX.Element;
  visible: boolean;
  maskProps?: BoxProps;
}

export default function MaskOverlay({
  children,
  innerContent,
  visible,
  maskProps,
  ...props
}: MaskOverlayProps) {
  return (
    <Box {...props} position="relative">
      {children}
      {visible && (
        <Box
          zIndex={2}
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          p={4}
          bgcolor="rgba(255,255,255,.9)"
          {...maskProps}
        >
          {innerContent}
        </Box>
      )}
    </Box>
  );
}
