/**
 * http://apiref.riskified.com/php/#beacon-spa
 * @param {$window} $window AngularJS $window service
 * @param {$location} $location AngularJS $location service
 */
function riskifiedPageView($window, $location) {
  if (!$window.RISKX) return;

  $window.RISKX.go($location.path());
}

export { riskifiedPageView }
