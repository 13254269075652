import React from 'react';

import Box from '@material-ui/core/Box';

import { BillingInterval } from '@client/data/subscription';
import { useSubscriptionService } from '@client/src/global/context/SubscriptionProvider';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { IPlan } from '../types';
import PlanName from './PlanName';
import PlanPrice from './PlanPrice';
import PlanTax from './PlanTax';
import ActionButton from './ActionButton';

interface PlanItemProps {
  plan: IPlan;
  billingInterval: BillingInterval;
  onPlanSelect: (planId: number) => void;
  isPlanVersionOutDated: boolean;
  hasTaxedPlan: boolean;
  children?: React.ReactNode;
}

export default function PlanItem({
  plan,
  billingInterval,
  onPlanSelect,
  isPlanVersionOutDated,
  hasTaxedPlan,
  children,
}: PlanItemProps) {
  const { currentSubscription, currentSubscriptionPeriod, isCurrentPlanChangeable } =
    useSubscriptionService();

  const {
    company: { currency },
  } = useUserSession();

  const currentSubscriptionPlanId = currentSubscription?.plan?.id;

  return (
    <Box textAlign="center" minWidth="130px" margin="4px">
      <PlanName planName={plan.name} isActive={plan.id === currentSubscriptionPlanId} />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        marginBottom="12px"
        minHeight={hasTaxedPlan ? 42 : 26}
      >
        <PlanPrice plan={plan} currency={currency} billingInterval={billingInterval} />
        <PlanTax plan={plan} currency={currency} />
      </Box>

      <Box minHeight={20} marginBottom="18px" lineHeight={0}>
        {children}
      </Box>

      <ActionButton
        plan={plan}
        onPlanSelect={onPlanSelect}
        currentSubscriptionPeriod={currentSubscriptionPeriod}
        isPlanVersionOutDated={isPlanVersionOutDated}
        isCurrentPlanChangeable={isCurrentPlanChangeable}
      />
    </Box>
  );
}
