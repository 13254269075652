(function () {
  'use strict';

  angular.module('easyshipDashboardApp').controller('PasswordResetCtrl', PasswordResetCtrl);

  PasswordResetCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'API',
    'Auth',
    'User',
    'MixpanelService',
    'UrlPath',
    'ApiKeys',
    'vcRecaptchaService',
  ];
  function PasswordResetCtrl(
    $scope,
    $state,
    $stateParams,
    API,
    Auth,
    User,
    MixpanelService,
    UrlPath,
    ApiKeys,
    vcRecaptchaService
  ) {
    var vm = this;

    vm.API = API;
    vm.UrlPath = UrlPath;
    vm.busy = false;
    vm.valid = false;
    vm.wrappers = {
      a: function (text) {
        return (
          '<a href="' + API.homepage + '/contact" target="_blank" rel="noopener">' + text + '</a>'
        );
      },
    };

    (function init() {
      if (Auth.isLoggedIn() === true) $state.go('app.home');

      if ($stateParams.userEmail) vm.email = $stateParams.userEmail;
      vm.recaptchaKey = ApiKeys.recaptcha;
    })();

    // Set the recaptcha widget id once loaded, this can be use to reload the widget if the API rejects the request
    vm.setRecaptchaWidgetId = function (widgetId) {
      vm.widgetId = widgetId;
    };

    vm.handleChange = function (value) {
      vm.email = value;
    };

    vm.handleValidityChange = function (value) {
      vm.valid = value;
    };

    vm.submit = function () {
      if (!vm.valid) return;
      vm.busy = true;

      if ($scope.resetPasswordForm.$error.recaptcha) {
        vcRecaptchaService.execute();
      }
    };

    vm.requestReset = function (recaptchaResponse) {
      User.resetPassword(
        { token: recaptchaResponse },
        { email: vm.email, token: recaptchaResponse }
      )
        .$promise.then(function () {
          MixpanelService.track('Resets Password', {});
          vm.success = true;
        })
        .catch(function (err) {
          vm.fail = true;
          vm.error = err.data.status;
          vcRecaptchaService.reload(vm.widgetId);
        })
        .finally(function () {
          vm.busy = false;
        });
    };

    vm.tryAgain = function () {
      vm.fail = false;
    };
  }
})();
