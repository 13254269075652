import template from './tab.html?raw';
import style from './tab.module.scss';

class Tab {
  constructor() {
    this.style = style;
  }
}

const TabComponent = {
  controller: Tab,
  template,
  bindings: {
    esSelected: '<',
    esLabel: '<',
  },
};

export { TabComponent };
