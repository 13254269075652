function NgSubmitDirective() {
  return {
    require: '?form',
    link: {
      pre: (scope, element, attrs, form) => {
        element.on('submit', () => {
          if (form && form.$invalid && form.$$controls) {
            form.$$controls.forEach(ngModelController => {
              ngModelController.$invalid ? ngModelController.$setDirty() : null;
            });
          }
        });
      },
    },
  };
}

export { NgSubmitDirective };
