import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './rating-email.html?raw';
import style from './rating-email.module.scss';

class RatingEmail {
  static $inject = ['$translate', 'RatingEmailPreviewModal', 'CompanyService', 'MixpanelService'];

  constructor($translate, RatingEmailPreviewModal, CompanyService, MixpanelService) {
    this.style = style;
    this.$translate = $translate;
    this.RatingEmailPreviewModal = RatingEmailPreviewModal;
    this.CompanyService = CompanyService;
    this.MixpanelService = MixpanelService;
  }

  viewPreview() {
    this.RatingEmailPreviewModal.open();
    this.MixpanelService.track('Settings - Customer Emails - Preview Rating Email');
  }

  onToggleChange(value, model) {
    const data = { [model]: value };

    this.esFormData[model] = value;
    this._updateRatingEmail(data);
  }

  _updateRatingEmail(data) {
    this.CompanyService.updateCompany(data)
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            {
              noun: this.$translate.instant('global.setting').toLowerCase(),
            },
            'messageformat'
          )
        );
      })
      .catch((error) => {
        toastError(
          this.$translate.instant((error.data && error.data.status) || 'toast.update-error', {
            noun: this.$translate.instant('global.setting').toLowerCase(),
          })
        );
      });
  }
}

const RatingEmailComponent = {
  controller: RatingEmail,
  template,
  bindings: {
    esFormData: '<',
  },
};

export { RatingEmailComponent };
