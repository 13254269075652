import angular from 'angular';
import tippy from 'tippy.js';

import template from './tooltip.html?raw';
import style from './tooltip.module.scss';
import infoIcon from './assets/info.svg?svgo';

class Tooltip {
  static $inject = ['$scope', '$element', '$sce', '$timeout'];

  constructor($scope, $element, $sce, $timeout) {
    this.style = style;
    this.$scope = $scope;
    this.$element = $element;
    this.$sce = $sce;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.infoIconMarkup = this.$sce.trustAsHtml(infoIcon);
  }

  $postLink() {
    // Let the digest cycle finish to ensure that we have the compiled
    // transcluded element
    this.$timeout(() => {
      this._instantiateTooltip();
    });

    if (!this.hasTranscludedLabelElement()) {
      this.$element.addClass('easyship-tooltip-no-transclude');
    }
  }

  $onDestroy() {
    this._destroyTooltip();
  }

  hasTranscludedLabelElement() {
    return (
      !!angular.element('ng-transclude', this.$element).html() ||
      !!angular.element('tooltip-label', this.$element).html()
    );
  }

  isError() {
    return this.esType === 'error';
  }

  isWarning() {
    return this.esType === 'warning';
  }

  _instantiateTooltip() {
    if (
      this.esDisabled ||
      !(this.esMessage || angular.element('tooltip-message', this.$element).html())
    )
      return;

    const element = this.$element.children()[0];
    const contentElement = this.$element.find(`.${this.style.tooltipContent}`)[0];

    if (this.esPlacement) {
      this.esOptions = { ...this.esOptions, placement: this.esPlacement };
    }

    this.tooltipInstance = tippy(element, {
      ...{
        appendTo: () => document.body,
        animation: 'shift-toward',
        duration: [600, 300],
        hideOnClick: 'persistent',
        content: contentElement,
        allowHTML: true,
        interactive: true,
        inertia: true,
        placement: 'top',
        theme: `es-tippy-theme ${this.isError() ? 'es-tippy-error-theme' : ''}`,
      },
      ...this.esOptions,
    });
  }

  _destroyTooltip() {
    if (this.tooltipInstance) this.tooltipInstance.destroy();
  }
}

const TooltipComponent = {
  controller: Tooltip,
  template,
  transclude: true,
  transclude: {
    label: '?tooltipLabel',
    message: '?tooltipMessage', // Preferred
  },
  bindings: {
    esMessage: '@', // Deprecated
    esType: '@',
    esOptions: '<',
    esWidth: '@',
    esHeight: '@',
    esPlacement: '@',
    esDisabled: '<',
  },
};

export { TooltipComponent };
