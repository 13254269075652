(function () {
  'use strict';

  function addressFormSgCtrl() {
    var ctrl = this; // eslint-disable-line no-unused-vars
  }

  addressFormSgCtrl.$inject = [];

  angular
    .module('app.component.address-form-sg')
    .controller('addressFormSgCtrl', addressFormSgCtrl);
})();
