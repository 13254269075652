import { useMutation } from '@tanstack/react-query';
import { IUserRole } from 'typings/user-session';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { useUserRoleGateway } from '../../providers/UserRoleGatewayProvider';

export default function useUserRolesMutation() {
  const { userRoleGateway } = useUserRoleGateway();
  const { company } = useUserSession();

  return useMutation({
    mutationFn: ({ userId, userRoles }: { userId: string; userRoles: Partial<IUserRole>[] }) =>
      userRoleGateway.updateUserRoles({
        userId,
        userRoles,
        companyId: company.id,
      }),
  });
}
