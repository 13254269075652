import { IComponentController } from 'angular';
import template from './tag-pill.html?raw';
import style from './tag-pill.module.scss';

class TagPill implements IComponentController {
  style = style;
  esAllowDeselect = false;
  esLockDefaultColor = false;

  constructor() {
    this.style = style;
  }

  $onInit() {
    if (this.esAllowDeselect === undefined) {
      this.esAllowDeselect = false;
    }
  }
}

const TagPillComponent: ng.IComponentOptions = {
  controller: TagPill,
  template,
  transclude: true,
  bindings: {
    esSelected: '<',
    esAllowDeselect: '<',
    esLockDefaultColor: '<',
  },
};

export { TagPillComponent };
