/**
 * This service exists to enable payment forms to be triggered by a component
 * outside of their component tree. eg. The dashboard-header-* set of directives.
 *
 * Each payment form component should register itself in their $onInit lifecycle
 * hook and unregister in their $onDestroy hooks.
 *
 * If multiple payment form in the page, use the paymentFormId param to avoid triggering the wrong form
 * eg: It can happen on the summary page if the user has a subscription payment failed modal where we are asking the user to update their billing info
 *     https://github.com/easyship/easyship-dashboard/pull/6616/files#r686536808
 */

class PaymentService {
  constructor() {
    this._paymentForm = {};
  }

  registerPaymentForm(paymentFormId, callback) {
    if (this._isPaymentFormAvailable(paymentFormId)) {
      throw new Error('PaymentService: Another payment form has already been registered.');
    }

    this._paymentForm[paymentFormId] = callback;
  }

  unregisterPaymentForm(paymentFormId) {
    this._paymentForm[paymentFormId] = null;
  }

  submitPaymentForm(paymentFormId) {
    if (this._isPaymentFormAvailable(paymentFormId)) {
      return Promise.resolve(this._paymentForm[paymentFormId]());
    }

    return Promise.reject(new Error('PaymentService: There are no payment forms registered.'));
  }

  _isPaymentFormAvailable(paymentFormId) {
    return typeof this._paymentForm[paymentFormId] === 'function';
  }
}

export { PaymentService };
