import angular from 'angular';

import { StateSelectorComponent } from './state-selector.component';

const MODULE_NAME = 'app.global.dashboard-component-library.state-selector';

angular
  .module(MODULE_NAME, [])
  .component('esStateSelector', StateSelectorComponent);

export default MODULE_NAME;
