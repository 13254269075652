(function () {
  angular.module('easyshipDashboardApp').controller('SignupConfirmCtrl', SignupConfirmCtrl);

  SignupConfirmCtrl.$inject = ['$scope', '$stateParams', '$state', 'Auth', 'UrlPath'];

  function SignupConfirmCtrl($scope, $stateParams, $state, Auth, UrlPath) {
    const vm = this;

    vm.UrlPath = UrlPath;

    Auth.confirmUser(
      $stateParams.emailConfirmationToken,
      function () {
        vm.success = true;
        $state.go('app.home');
      },
      function (response) {
        vm.failMessage = response.data.status;
      }
    );
  }
})();
