(function () {
  'use strict';

  GrouponService.$inject = ['$q'];
  function GrouponService($q) {
    var service = this;

    service.formatGrouponKey = function (store, platforms) {
      return $q(function (resolve, reject) {
        store.name = 'Groupon ' + store.country;
        store.url = 'https://scm.commerceinterface.com/api/v3/';
        store.platform_id = platforms[0].id;
        resolve(store);
      });
    };
  }

  angular.module('app.service.GrouponService', []).service('GrouponService', GrouponService);
})();
