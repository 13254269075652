import { IPaymentService } from 'typings/payment-service';
import { PaymentFormId } from '@client/data/payment-form-id';
import { IApiConfig } from 'typings/core/config/api';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';

import { IComponentController } from 'angular';
import { SubscriptionPaymentService } from '@client/src/global/services/subscription/subscription-payment.service';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './add-payment-modal-card.html?raw';
import style from './add-payment-modal-card.module.scss';

class AddPaymentModalCard implements IComponentController {
  style = style;
  paymentFormId = PaymentFormId.AddCreditCard;
  busySaving = false;
  creditCardErrorCode = '';
  static $inject = [
    '$translate',
    'PaymentService',
    'PaymentSourceService',
    'SubscriptionPaymentService',
    'API',
    'UserRightsService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private PaymentService: IPaymentService,
    private PaymentSourceService: PaymentSourceService,
    private SubscriptionPaymentService: SubscriptionPaymentService,
    public API: IApiConfig,
    private UserRightsService: UserRightsService
  ) {}

  esCloseTitleAction(): void {
    // esCloseTitleAction expression bindings, need to add this in order for typescript to successfully compile
  }

  _handlePaymentErrors(err: Error): void {
    this.busySaving = false;
    const [errorCode] = transformToErrorCodes(err);
    this.creditCardErrorCode = errorCode || 'other';
    toastError(this.$translate.instant('account.payment.notifications.stripe-error'));
  }

  onCardFormSubmit(): void {
    if (this.busySaving) {
      return;
    }

    this.busySaving = true;
    this.creditCardErrorCode = '';

    this.PaymentSourceService.selectCard(null);

    this.PaymentService.submitPaymentForm(PaymentFormId.AddCreditCard)
      .then((paymentFormResult) => {
        if (!paymentFormResult) {
          return;
        }
        if (!paymentFormResult.requires_action) {
          this.refreshCardList();
          return;
        }

        this.SubscriptionPaymentService.handleCardSetupIntentsConfirm(paymentFormResult)
          .then(() => {
            this.busySaving = false;
            this.refreshCardList();
          })
          .catch((err) => this._handlePaymentErrors(err));
      })
      .catch((err) => this._handlePaymentErrors(err));
  }

  addCreditCardAction(): void {
    this.onCardFormSubmit();
  }

  private refreshCardList(): void {
    this.PaymentSourceService.getCreditCards();
    this.esCloseTitleAction();
    toastSuccess(this.$translate.instant('account.payment.notifications.card-added'));
  }

  get canAddPaymentMethod(): boolean {
    return this.UserRightsService.canAddPaymentMethod;
  }
}

const AddPaymentModalCardComponent: ng.IComponentOptions = {
  controller: AddPaymentModalCard,
  template,
  bindings: {
    esLeftButtonAction: '&',
    esCloseTitleAction: '&',
    esIsBeforeShipmentFlow: '<',
  },
};

export { AddPaymentModalCardComponent };
