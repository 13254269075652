import { IUserSession } from 'typings/user-session';

import angular, { IComponentController } from 'angular';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { toastError } from '@client/core/components/react/Toastify';
import template from './add-credits-paypal-modal-card.html?raw';
import style from './add-credits-paypal-modal-card.module.scss';

class AddCreditsPaypalModalCard implements IComponentController {
  style = style;
  paymentCurrency = '';
  paymentAmount: number | null = null;
  processingPaypalPaymentRequest = false;

  static $inject = ['$translate', 'UserSession', 'PaymentSourceService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private PaymentSourceService: PaymentSourceService
  ) {}

  $onInit() {
    this.paymentCurrency = this.UserSession.company.currency;
  }

  onPaymentAmountChange(amount: number): void {
    this.paymentAmount = amount;
  }

  addCredits(): void {
    if (!this.paymentAmount || this.paymentAmount < 0) {
      toastError(this.$translate.instant('account.payment.notifications.amount-error'));
      return;
    }

    this.processingPaypalPaymentRequest = true;
    const MIXPANEL_EVENT_NAME = 'Add Credits/Payment';

    this.PaymentSourceService.addCredits(
      this.paymentAmount,
      this.PaymentSourceService.platform.PayPal,
      MIXPANEL_EVENT_NAME
    ).finally(() => {
      this.processingPaypalPaymentRequest = false;
    });
  }
}

const AddCreditsPaypalModalCardComponent: ng.IComponentOptions = {
  controller: AddCreditsPaypalModalCard,
  template,
  bindings: {
    esCloseAction: '&',
  },
};

export { AddCreditsPaypalModalCardComponent };
