import { toastError } from '@client/core/components/react/Toastify';

(function () {
  addressFormAuCtrl.$inject = ['PostalCodeService', '$translate'];
  function addressFormAuCtrl(PostalCodeService, $translate) {
    const ctrl = this;

    ctrl.postalCodeBusy = false;
    ctrl.states = [];
    ctrl.suburbs = [];
    ctrl.suburbListByAbbr = {};
    ctrl.hasCityValue = false;

    ctrl.$onInit = function () {
      if (ctrl.address.city) {
        ctrl.hasCityValue = true;
        ctrl.retrieveState();
      }
    };

    ctrl.retrieveState = function () {
      const isPostalCodeFormatValid = ctrl.fieldValidators.postal_code.validate(
        ctrl.address.postal_code
      );
      if (!isPostalCodeFormatValid) {
        ctrl.suburbs = [];
        return;
      }

      ctrl.postalCodeBusy = true;

      const params = {
        postal_code: ctrl.address.postal_code,
        country_id: ctrl.address.country_id,
      };

      PostalCodeService.getState(params)
        .then(_handleGetState)
        .catch(function () {
          _resetCityAndState();
        })
        .finally(function () {
          ctrl.postalCodeBusy = false;
        });
    };

    ctrl.onPickupInstructionSlugChange = function (slug) {
      const defaultUsersInput = slug === 'others' ? '' : null;
      ctrl.address.pickup_instruction_users_input = defaultUsersInput;
    };

    function _resetCityAndState() {
      ctrl.address.city = null;
      ctrl.address.state = null;
      ctrl.states = [];
      ctrl.suburbs = [];

      return toastError($translate.instant('account.addresses.invalid-postal-code'));
    }

    // eslint-disable-next-line consistent-return
    function _handleGetState(resData) {
      ctrl.states = (resData || {}).states;

      if (!resData.states || !resData.states.length) {
        return _resetCityAndState();
      }

      ctrl.states = resData.states;

      ctrl.suburbListByAbbr = {};

      ctrl.states.forEach(function (stateData) {
        const { suburbs } = stateData;
        const stateAbbr = stateData.abbr;

        ctrl.suburbListByAbbr[stateAbbr] = suburbs;
      });

      const hasOnlyOneState = ctrl.states.length === 1;
      ctrl.address.state = hasOnlyOneState ? ctrl.states[0].abbr : null;

      if (hasOnlyOneState) {
        ctrl.setSuburbsList(ctrl.address.state);
      }
    }

    ctrl.setSuburbsList = function (stateAbbr) {
      ctrl.suburbs = ctrl.suburbListByAbbr[stateAbbr];

      if (ctrl.hasCityValue) {
        ctrl.hasCityValue = false;
        return;
      }

      ctrl.address.city = ctrl.suburbs.length === 1 ? ctrl.suburbs[0] : null;
    };
  }

  angular
    .module('app.component.address-form-au')
    .controller('addressFormAuCtrl', addressFormAuCtrl);
})();
