import template from './fix-shipment-modal-body.html?raw';

(function () {
  'use strict';

  var fixShipmentModalBody = {
    template,
    bindings: {
      step: '<',
      globalBusy: '<',
      shipmentData: '<',
      priceComparison: '<',
    },
    controller: 'fixShipmentModalBodyCtrl as ctrl',
  };

  function fixShipmentModalBodyCtrl() {}

  fixShipmentModalBodyCtrl.$inject = [];

  angular
    .module('app.component.fix-shipment-modal-body')
    .component('fixShipmentModalBody', fixShipmentModalBody)
    .controller('fixShipmentModalBodyCtrl', fixShipmentModalBodyCtrl);
})();
