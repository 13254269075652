import angular, { IComponentController } from 'angular';

import { ICourierService } from 'typings/courier';
import { IInputGroupRadioModel, IInputGroupValue } from 'typings/form';
import { IHelperService, IGroupedArray } from 'typings/helper';
import { IAdvancedSearchCourierObject } from 'typings/advanced-search';
import {
  MultiselectComponentModelValue,
  MultiselectAttributes,
} from '@client/src/global/services/shipment-list/data/multi-select';

import { toastError } from '@client/core/components/react/Toastify';
import {
  ShipmentListNormalizeService,
  ICouriersGroupedArray,
} from '../../../services/shipment-list/shipment-list-normalize.service';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';
import style from './couriers-input-group.module.scss';
import template from './couriers-input-group.html?raw';

const NAME = 'name';

class CouriersInputGroup
  extends MultiSelectInputGroup<IAdvancedSearchCourierObject>
  implements IComponentController
{
  style = style;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions = [];
  esModelEasyshipOptions: IGroupedArray<IAdvancedSearchCourierObject>[] = [];
  esModelCompanyOptions: IGroupedArray<IAdvancedSearchCourierObject>[] = [];

  static $inject = ['CourierService', 'HelperService', '$translate', '$scope'];
  constructor(
    private CourierService: ICourierService,
    private HelperService: IHelperService,
    private $translate: angular.translate.ITranslateService,
    $scope: ng.IScope
  ) {
    super($scope);
  }

  $onInit(): void {
    this.assignDefaultRadioModel();
    this.radioModel = [
      {
        title: this.$translate.instant('pickups.filters.menu.couriers.any'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: this.$translate.instant('pickups.filters.menu.couriers.none'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: this.$translate.instant('pickups.filters.menu.couriers.known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: this.$translate.instant('pickups.filters.menu.couriers.unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];
  }

  $onChanges(changes: any): void {
    if (this.isKnownOrUnknowModel(changes)) {
      this.triggerCouriersChange();
    }

    if (!this.esModelEasyshipOptions || !this.esModelCompanyOptions) {
      this.fetchAllCouriers();
      return;
    }

    this.esModelEasyshipOptions = angular.copy(this.esModelEasyshipOptions);
    this.esModelCompanyOptions = angular.copy(this.esModelCompanyOptions);
    this.watchAndUpdateEasyshipOptions();
    this.watchAndUpdateCompanyOptions();
    this.triggerCouriersChange();
  }

  onRadioCourierChange(value: IInputGroupValue<MultiselectComponentModelValue>): void {
    this.assignModelRadioInputValue(value);
    this.triggerCouriersChange();
  }

  private fetchAllCouriers(): void {
    this.CourierService.getCouriers()
      .then((courierServices) => {
        const easyshipHash: Record<string, IAdvancedSearchCourierObject> = {};
        const companyHash: Record<string, IAdvancedSearchCourierObject> = {};

        for (let i = 0; i < courierServices.length; i++) {
          const key = `${courierServices[i].logo_url}-${courierServices[i].name}`;
          if (courierServices[i].is_easyship_courier) {
            if (!easyshipHash[key]) {
              easyshipHash[key] = { ...courierServices[i] };
            } else {
              easyshipHash[key].id += `,${courierServices[i].id}`;
            }
          } else if (!companyHash[key]) {
            companyHash[key] = { ...courierServices[i] };
          } else {
            companyHash[key].id += `,${courierServices[i].id}`;
          }
        }

        const byName = (a: IAdvancedSearchCourierObject, b: IAdvancedSearchCourierObject) =>
          a.name > b.name ? 1 : -1;
        const byDisplay = (
          a: IGroupedArray<IAdvancedSearchCourierObject>,
          b: IGroupedArray<IAdvancedSearchCourierObject>
        ) => (a.display > b.display ? 1 : -1);

        this.esModelEasyshipOptions = ShipmentListNormalizeService.injectLogoUrlToCourierObject(
          this.groupCouriersObject(Object.values(easyshipHash).sort(byName))
        ).sort(byDisplay);
        this.esModelCompanyOptions = ShipmentListNormalizeService.injectLogoUrlToCourierObject(
          this.groupCouriersObject(Object.values(companyHash).sort(byName))
        ).sort(byDisplay);

        this.watchAndUpdateEasyshipOptions();
        this.watchAndUpdateCompanyOptions();
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.triggerCouriersChange();
      });
  }

  private watchAndUpdateEasyshipOptions(): void {
    this.watchAndUpdateFilter(this.esModelEasyshipOptions as any, NAME);
  }

  private watchAndUpdateCompanyOptions(): void {
    this.watchAndUpdateFilter(this.esModelCompanyOptions as any, NAME, 2);
  }

  private groupCouriersObject(couriers: IAdvancedSearchCourierObject[]): ICouriersGroupedArray[] {
    return this.HelperService.groupArrayByKey(couriers, MultiselectAttributes.UmbrellaName, {
      isDisplay: true,
      isOpen: true,
    });
  }

  private triggerCouriersChange(): void {
    this.onValuesChange({
      easyshipCouriers: this.esModelEasyshipOptions,
      companyCouriers: this.esModelCompanyOptions,
    });
  }
}

const CouriersInputGroupComponent: ng.IComponentOptions = {
  controller: CouriersInputGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelEasyshipOptions: '<',
    esModelCompanyOptions: '<',
    esOnChange: '&',
  },
};

export { CouriersInputGroupComponent };
