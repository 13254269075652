import { IUserSession } from 'typings/user-session';
import { IApiConfig } from 'typings/core/config/api';
import { toastSuccess } from '@client/core/components/react/Toastify';
import { FinanceDocumentsResource } from './finance-documents.resource';
import {
  IApiExportParams,
  IApiExportPayload,
  TransactionRecordResource,
} from './transaction-record.resource';

interface IGetTransactionsParams {
  company_id: string;
  invoiceId: string;
  is_settled: boolean;
  offset: number;
  limit: number;
  from_date: string;
  to_date: string;
  payment_recipient: string;
  keyword: string;
  transactionable_types: string[];
  balance_type: string;
  finance_document_id: string;
  settlement_state: string;
}

interface IGetFinanceDocumentsParams {
  keyword: string;
  to_date: string;
  from_date: string;
  category: string;
  limit: number;
  offset: number;
}

class TransactionRecord {
  static $inject = [
    'TransactionRecordResource',
    'FinanceDocumentsResource',
    'UserSession',
    'API',
    '$translate',
  ];
  constructor(
    private TransactionRecordResource: TransactionRecordResource,
    private FinanceDocumentsResource: FinanceDocumentsResource,
    public UserSession: IUserSession,
    public API: IApiConfig,
    private $translate: angular.translate.ITranslateService
  ) {
    this.TransactionRecordResource = TransactionRecordResource;
    this.FinanceDocumentsResource = FinanceDocumentsResource;
    this.UserSession = UserSession;
    this.API = API;
  }

  getTransactions(params: IGetTransactionsParams) {
    return this.TransactionRecordResource.getTransactions({
      company_id: this.UserSession.company.id,
      invoice_id: params.invoiceId,
      is_settled: params.is_settled,
      offset: params.offset,
      limit: params.limit,
      created_at_from: params.from_date,
      created_at_to: params.to_date,
      payment_recipient: params.payment_recipient,
      keyword: params.keyword,
      transactionable_types:
        params.transactionable_types.length > 0 ? params.transactionable_types : undefined,
      balance_type: params.balance_type,
      finance_document_id: params.finance_document_id,
      settlement_state: params.settlement_state,
    }).catch((err: unknown) => {
      throw err;
    });
  }

  async exportTransactions(params: IApiExportParams, payload: IApiExportPayload) {
    const response = await this.TransactionRecordResource.export(params, payload);

    if (response.method === 'email') {
      toastSuccess(
        this.$translate.instant('header.export-email', { email: this.UserSession.user.email })
      );
      return;
    }

    const { data } = await this.TransactionRecordResource.download({
      company_id: this.UserSession.company.id,
      id: response.id,
    });

    const csvFile = new Blob([data], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = `transaction-records-${Date.now()}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  getFinanceDocuments(params: IGetFinanceDocumentsParams) {
    return this.FinanceDocumentsResource.getDocuments({
      company_id: this.UserSession.company.id,
      offset: params.offset,
      limit: params.limit,
      billed_at_from: params.from_date,
      billed_at_to: params.to_date,
      category: params.category,
      keyword: params.keyword,
    }).catch((err: unknown) => {
      throw err;
    });
  }

  getFinanceDocumentsFilter(params: IGetFinanceDocumentsParams) {
    return this.FinanceDocumentsResource.getDocuments({
      company_id: this.UserSession.company.id,
      ...params,
    }).catch((err: unknown) => {
      throw err;
    });
  }

  downloadFinancialDocuments({ finance_documents_ids }: { finance_documents_ids: string[] }) {
    return this.FinanceDocumentsResource.downloadDocuments(
      {
        company_id: this.UserSession.company.id,
      },
      {
        finance_documents_ids,
      }
    ).catch((err: unknown) => {
      throw err;
    });
  }
}

export { TransactionRecord };
