import { cloneDeep, isEmpty } from 'lodash';
import angular from 'angular';

import {
  IAdvancedSearchBatchObject,
  IAdvancedSearchCourierObject,
  IAdvancedSearchStatusMessageObject,
  IAdvancedSearchStorePlatformOption,
} from 'typings/advanced-search';
import { DateInputOptionsValue } from '@client/src/global/services/shipment-list/data/date-input';
import { AdvancedSearchComponentApiNormalize } from 'typings/advanced-search-component-api-normalize';
import { IBatchObject, IBatchService } from 'typings/batch';
import { ICountryService } from 'typings/auth/services/country';
import { ICourierService } from 'typings/courier';
import { IHelperService } from 'typings/helper';
import { IPlatformService } from 'typings/platform';
import {
  IShipmentListCustomViewsItem,
  IShipmentListCustomViewsSortCriterias,
  IShipmentListDefaultViewsItem,
  IShipmentListEntities,
  IShipmentListEntity,
  IShipmentListItem,
  IShipmentListPayload,
  IShipmentListScope,
  IShipmentResource,
  IShipmentSortByOptions,
  IShipmentValidKey,
  IUpdatedShipmentListItems,
  SortByAttribute,
  SortDirection,
} from 'typings/shipment';
import { INewStatusMessage, IStatusMessageService } from 'typings/status';
import { IStoreService } from 'typings/store';
import { IUserSession } from 'typings/user-session';

import { IPickupService } from 'typings/pickup';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import { toastError } from '@client/core/components/react/Toastify';
import {
  ICountriesGroupObject,
  ICouriersGroupedArray,
  IShipmentListFiltersData,
  IShipmentListPayloadFilter,
  ShipmentListNormalizeService,
} from './shipment-list-normalize.service';
import { DrawerMenuKey } from './data/drawer';
import { MultiselectAttributes, MultiselectComponentModelValue } from './data/multi-select';
import { OrderStatusRadioModelValue, TbcRadioModelValue } from './data/radio-group';
import { ShipmentViewsService } from '../shipment-views/shipment-views.service';
import { ShipmentListResource } from './shipment-list.resource';
import { AdvancedSearchComponentApiNormalizeService } from '../advanced-search-component-api-normalize/advanced-search-component-api-normalize.service';

enum DefaultValue {
  Limit = 10,
  Page = 1,
}

enum ShipmentType {
  OrdersAll = 'orders_all',
  Multiple = 'multiple',
}

export enum ShipmentFields {
  Height = 'height',
  Length = 'length',
  Width = 'width',
  ActualWeight = 'actual_weight',
}

export class ShipmentListAbstractService extends ShipmentListNormalizeService {
  limit: number = DefaultValue.Limit;
  pageNum: number = DefaultValue.Page;
  readonly shipmentType = {
    ordersAll: ShipmentType.OrdersAll,
    multiple: ShipmentType.Multiple,
  };
  readonly shipmentItemFields = [
    ShipmentFields.Height,
    ShipmentFields.Length,
    ShipmentFields.Width,
    ShipmentFields.ActualWeight,
  ];
  private _selectedIds: string[] = [];
  private _excludedIds: string[] = [];
  private _isSelectedAll = false;
  private _shipmentBusy = false;
  private _totalBusy = false;
  private _entities: IShipmentListEntities | null = null;
  private _keyword: string | null = null;
  private _filter: IShipmentListFiltersData | null = null;
  private _sortBy: SortByAttribute | null = null;
  private _sortDirection: SortDirection | null = null;
  private _updatedShipments: IUpdatedShipmentListItems | null = null;
  private _customViews: IShipmentListCustomViewsItem[] | null = null;
  private _selectedView: IShipmentListDefaultViewsItem | IShipmentListCustomViewsItem | null = null;
  readonly defaultViews: IShipmentListDefaultViewsItem[] = [
    {
      id: 'default_shipment',
      translationKey: 'outbound',
    },
    {
      id: 'default_return',
      translationKey: 'return',
    },
  ];
  readonly commonSortingOptions: IShipmentSortByOptions[] = [
    { key: 'created_at' },
    { key: 'order_created_at' },
    { key: 'platform_order_number' },
    // Not available
    // { key: 'courier.display_name' },
    // { key: 'destination_country.name' },
    { key: 'destination_name' },
    { key: 'shipment_items_count' },
    { key: 'unique_sku_count' },
    // Not available
    // { key: 'store.name' },
  ];

  static $inject = [
    'ShipmentListResource',
    'Shipment',
    'UserSession',
    'BatchService',
    'StatusMessageService',
    'HelperService',
    'CourierService',
    '$q',
    '$translate',
    'StoreService',
    'PlatformService',
    'PickupService',
    'ShipmentViewsService',
    'CountryService',
    'AdvancedSearchComponentApiNormalizeService',
  ];

  constructor(
    public ShipmentListResource: ShipmentListResource,
    public Shipment: IShipmentResource,
    public UserSession: IUserSession,
    public BatchService: IBatchService,
    public StatusMessageService: IStatusMessageService,
    public HelperService: IHelperService,
    private CourierService: ICourierService,
    private $q: ng.IQService,
    public $translate: angular.translate.ITranslateService,
    private StoreService: IStoreService,
    private PlatformService: IPlatformService,
    private PickupService: IPickupService,
    public ShipmentViewsService: ShipmentViewsService,
    CountryService: ICountryService,
    AdvancedSearchComponentApiNormalizeService: AdvancedSearchComponentApiNormalizeService
  ) {
    super(CountryService, AdvancedSearchComponentApiNormalizeService);
  }

  resetShipmentsLimit(limitNumber: number, scope: IShipmentListScope, include?: string): void {
    this.limit = limitNumber;
    this.pageNum = DefaultValue.Page;
    this.resetEntities();
    this.resetSelectedAll();
    this.clearSelectedIds();
    this.clearExcludedIds();
    this.fetchShipmentEntitiesWithFilter(scope, include);
  }

  resetEntities(): void {
    this._entities = null;
  }

  fetchShipmentEntitiesWithFilter(scope: IShipmentListScope, include?: string): ng.IPromise<void> {
    if (!isEmpty(this.filterPayload)) {
      return this.fetchShipmentEntities(scope, this.filterPayload, include);
    }
    return this.fetchShipmentEntities(scope, undefined, include);
  }

  fetchShipmentEntities(
    scope: IShipmentListScope,
    filterData?: IShipmentListPayloadFilter,
    include?: string
  ): ng.IPromise<void> {
    let payload: IShipmentListPayload = {
      offset: this.offset,
      limit: this.limit,
      scope,
      by_search: true,
      include: 'chargeable_weight,missing_dimensions_warning,missing_tags_warning,timestamps',
    };

    // This is a short-term solution
    if (filterData?.store_state?.values?.includes('fulfilled')) {
      filterData.store_state.values.push('already_fulfilled', 'fulfillable_quantity_mismatch');
    }

    if (filterData) {
      payload = {
        ...payload,
        ...filterData,
      };
    }

    if (include) {
      payload = {
        ...payload,
        include,
      };
    }

    this._shipmentBusy = true;
    return this.ShipmentListResource.getShipmentList(
      {
        company_id: this.UserSession.getCompanyId() || '',
      },
      payload
    )
      .then((data) => {
        if (data && data.shipments) {
          if (this._entities) {
            if (this._entities[this.pageNum]) {
              this._entities[this.pageNum].shipments = data.shipments;
            } else {
              this._entities[this.pageNum] = {
                selected: this.isSelectedAll,
                shipments: data.shipments,
              };
            }
            this.setShipmentSelectedStatus();
          } else {
            this._entities = {
              [this.pageNum]: {
                selected: false,
                shipments: data.shipments,
              },
            };
          }
        }
      })
      .catch((error) => {
        if (transformToErrorCodes(error).includes('keyword_too_long')) {
          toastError(this.$translate.instant('shipments.filter.errors.keyword_too_long'));
        } else if (error instanceof Error) {
          toastError(error.message);
        } else {
          console.error('[fetchShipments] unhandled error', error);
        }
      })
      .finally(() => {
        this._shipmentBusy = false;
      });
  }

  fetchShipment(id: string, include?: string): ng.IPromise<{ shipment: IShipmentListItem }> {
    return this.Shipment.query({
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id,
      include,
    }).$promise;
  }

  mutateVisibleShipment(shipmentId: string, shipment: IShipmentListItem) {
    const shipmentsOnPage = this._entities?.[this.pageNum]?.shipments;
    if (!shipmentsOnPage) return;

    const shipmentIndexToMutate = shipmentsOnPage.findIndex(
      (shipment) => shipment.id === shipmentId
    );
    if (shipmentIndexToMutate === -1) return;

    this._entities?.[this.pageNum]?.shipments?.splice(
      shipmentIndexToMutate,
      1,
      cloneDeep(shipment)
    );
  }

  rowHeaderCheck(isCheck: boolean): void {
    if (this._entities && this._entities[this.pageNum] && this._entities[this.pageNum].shipments) {
      this._entities[this.pageNum].selected = isCheck;
      const currentShipments = this._entities[this.pageNum].shipments;
      if (currentShipments) {
        this._entities[this.pageNum].shipments = currentShipments.map((shipment) => {
          const selectedShipment = shipment;
          selectedShipment.selected = isCheck;
          this.updateShipmentIdsAndExcludeShipmentIdsAfterSelect(isCheck, selectedShipment);

          return selectedShipment;
        });
      }
    }
  }

  rowListCheck(isCheck: boolean, index: number): void {
    if (this._entities && this._entities[this.pageNum] && this._entities[this.pageNum].shipments) {
      const { shipments: pageShipments } = this._entities[this.pageNum];
      if (pageShipments) {
        const selectedShipment = pageShipments[index];
        selectedShipment.selected = isCheck;
        this._entities[this.pageNum].selected = pageShipments.every(
          (shipment) => shipment.selected
        );
        this.updateShipmentIdsAndExcludeShipmentIdsAfterSelect(isCheck, selectedShipment);
      }
    }
  }

  selectAllShipments(): void {
    this.selectAllShipmentsAction(true);
    this._isSelectedAll = true;
    this.resetSelectedIds();
  }

  deselectAllShipments(): void {
    this.selectAllShipmentsAction(false);
    this.resetSelectedAll();
    this.resetSelectedIds();
  }

  setKeyword(keyword: string | null): void {
    this._keyword = keyword;
  }

  updateFilter(data: IShipmentListFiltersData | null): void {
    this._filter = angular.copy(data);
  }

  clearExcludedIds(): void {
    this._excludedIds = [];
  }

  clearSelectedIds(): void {
    this._selectedIds = [];
  }

  clearUpdatedShipments(): void {
    this._updatedShipments = null;
  }

  clearFilter(): void {
    this._filter = null;
  }

  resetPageNum(): void {
    this.pageNum = DefaultValue.Page;
  }

  resetKeyword(): void {
    this._keyword = null;
  }

  resetSelectedAll(): void {
    this._isSelectedAll = false;
  }

  resetCustomViews(): void {
    this._customViews = null;
  }

  resetSortBy(): void {
    this._sortBy = null;
    this._sortDirection = null;
  }

  resetAll(isKeepSelectedView?: boolean, isKeepFilterResult?: boolean): void {
    this.resetEntities();
    this.clearUpdatedShipments();
    this.resetSelectedAll();
    this.resetBusy();
    this.clearSelectedIds();
    this.clearExcludedIds();

    if (!isKeepSelectedView) {
      this.resetSelectedView();
    }

    if (!isKeepFilterResult) {
      this.resetPageNum();
      this.resetKeyword();
      this.resetSortBy();
      this.clearFilter();
    }
  }

  resetApplyFilter(isKeepSortBy = true): void {
    this.resetEntities();
    this.resetPageNum();
    this.clearSelectedIds();
    this.clearExcludedIds();
    this.clearUpdatedShipments();
    this.resetSelectedAll();
    if (!isKeepSortBy) {
      this.resetSortBy();
    }
  }

  resetShipmentActionsConfirm(): void {
    this.resetEntities();
    this.clearSelectedIds();
    this.clearExcludedIds();
    this.clearUpdatedShipments();
    this.resetSelectedAll();
    this.resetBusy();
    this.resetSortBy();
  }

  setUpdatedShipments(shipmentId: string): void {
    if (this._updatedShipments) {
      this._updatedShipments[shipmentId] = {
        shown: false,
      };
    } else {
      this._updatedShipments = {
        [shipmentId]: {
          shown: false,
        },
      };
    }
  }

  setShipmentBusy(): void {
    this._shipmentBusy = true;
  }

  resetShipmentBusy(): void {
    this._shipmentBusy = false;
  }

  async applyFilterFromUrl(stateParams: ng.ui.IStateParamsService): Promise<void> {
    if (stateParams) {
      const {
        valid_to_ship: validToShip,
        batch_id: batchId,
        pickup_id: pickupId,
        last_status_message_id: lastStatusMessageId,
        query,
      } = stateParams;
      if (validToShip === false || validToShip === 'false') {
        if (this._filter) {
          this._filter[DrawerMenuKey.OrderStatus] = OrderStatusRadioModelValue.NotReadyToShipped;
        } else {
          this._filter = {
            [DrawerMenuKey.OrderStatus]: OrderStatusRadioModelValue.NotReadyToShipped,
          };
        }
      }

      if (validToShip === true || validToShip === 'true') {
        if (this._filter) {
          this._filter[DrawerMenuKey.OrderStatus] = OrderStatusRadioModelValue.ReadyToShipped;
        } else {
          this._filter = {
            [DrawerMenuKey.OrderStatus]: OrderStatusRadioModelValue.ReadyToShipped,
          };
        }
      }

      try {
        if (batchId) {
          const batchIds = batchId.split(',');
          await this.generateBatchFilterData(batchIds);
        }

        if (pickupId) {
          await this.generatePickupFilterData(pickupId);
        }

        if (lastStatusMessageId) {
          await this.generateShipmentStatusFilterData(lastStatusMessageId);
        }

        if (query) {
          if (query.destination_country_id && typeof query.destination_country_id === 'number') {
            await this.CountryService.getCountries();
            this.generateDestinationCountryFilterData(query.destination_country_id);
          }

          if (query.courier_id && typeof query.courier_id === 'string') {
            await this.generateCourierFilterData(query.courier_id);
          }

          if (query.platform_name && typeof query.platform_name === 'string') {
            await this.generatePlatformNameObject(query.platform_name);
          }

          if (query.store_id && typeof query.store_id === 'string') {
            await this.generatePlatformNameObject(query.store_id);
          }

          if (query.last_status_message_id && typeof query.last_status_message_id === 'string') {
            await this.generateShipmentStatusFilterData(query.last_status_message_id);
          }
          if (query.label_paid_at_from && query.label_paid_at_to) {
            this.generateLabelPaidObject(query.label_paid_at_from, query.label_paid_at_to);
          }
        }
      } catch (e) {
        toastError(this.$translate.instant('toast.default-error'));
      }
    }
  }

  fetchCustomViews(pageContext: IShipmentListScope): ng.IPromise<void> {
    return this.ShipmentViewsService.getCustomViews(pageContext).then((res) => {
      if (res) {
        this._customViews = res.custom_views.sort((a, b) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
        );
      }
    });
  }

  applyDefaultView(viewId: string): void {
    const view = this.defaultViews.find((defaultView) => defaultView.id === viewId);

    this.updateSelectedView(view as IShipmentListDefaultViewsItem);

    switch (viewId) {
      case 'default_shipment':
      case 'default_return':
        this.updateFilter({
          tbc: viewId.replace('default_', '') as TbcRadioModelValue,
        });
        break;

      default:
        // Default view that coming from the BE
        break;
    }
  }

  applyCustomView(viewId: string): void {
    if (this.customViews) {
      const view = this.customViews.find((customView) => customView.id === viewId);
      if (view) {
        this.updateSelectedView(view);
        if (this._selectedView) {
          const {
            filters,
            items_per_page: itemsPerPage,
            keyword,
            sort_criterias: sortCriterias,
          } = this._selectedView as IShipmentListCustomViewsItem;

          if (filters !== undefined) {
            this.updateFilter(filters);
          }

          if (itemsPerPage) {
            this.limit = itemsPerPage;
          }

          if (keyword || keyword === null) {
            this.setKeyword(keyword);
          }

          if (sortCriterias && !this.HelperService.isObjectEmpty(sortCriterias)) {
            Object.keys(sortCriterias).forEach((key) => {
              this._sortBy = key as SortByAttribute;
              this._sortDirection = sortCriterias[key];
            });
          } else {
            this.resetSortBy();
          }
        }
      }
    }
  }

  applySortBy(sortBy: SortByAttribute, sortDirection: SortDirection): void {
    this._sortBy = sortBy;
    this._sortDirection = sortDirection;
  }

  updateSelectedView(view: IShipmentListCustomViewsItem | IShipmentListDefaultViewsItem): void {
    this._selectedView = angular.copy(view);
  }

  resetSelectedView(): void {
    this._selectedView = null;
  }

  setShipmentAndTotalBusy(): void {
    this.shipmentBusy = true;
    this.totalBusy = true;
  }

  updateViewAndShipmentOnInitPage(
    defaultViews: IShipmentListDefaultViewsItem[] | null,
    customViews: IShipmentListCustomViewsItem[] | null,
    pageContext: IShipmentListScope,
    updateViewCallback: (viewId: string) => void,
    fetchShipmentsCallback: () => void,
    viewBusyCallback: () => void,
    selectedViewId?: string
  ): void {
    const userDefaultViews = this.UserSession.getDefaultViews();

    if (defaultViews || customViews) {
      let viewId: string | null | undefined;

      if (selectedViewId) {
        viewId = selectedViewId;
      } else if (userDefaultViews) {
        viewId = userDefaultViews[pageContext];
      }

      let selectedView: IShipmentListDefaultViewsItem | IShipmentListCustomViewsItem | undefined;

      if (defaultViews) {
        selectedView = defaultViews.find((view) => view.id === viewId);
      }

      if (customViews && !selectedView) {
        selectedView = customViews.find((view) => view.id === viewId);
      }

      if (selectedView) {
        const viewId = selectedView.id;

        if (selectedView.filters && selectedView.filters?.countries) {
          this.CountryService.getCountries().then(() => {
            updateViewCallback(viewId);
          });
        } else {
          updateViewCallback(viewId);
        }
      } else {
        fetchShipmentsCallback();
        viewBusyCallback();
      }
    } else {
      viewBusyCallback();
    }
  }

  isReverseSortOrder(sortKey: SortByAttribute): boolean {
    return sortKey === 'destination_name';
  }

  get isSelectedAll(): boolean {
    return this._isSelectedAll;
  }

  get offset(): number {
    return this.limit * (this.pageNum - 1);
  }

  get entities(): IShipmentListEntities | null {
    return this._entities;
  }

  get shipmentBusy(): boolean {
    return this._shipmentBusy;
  }

  set shipmentBusy(isBusy: boolean) {
    this._shipmentBusy = isBusy;
  }

  get totalBusy(): boolean {
    return this._totalBusy;
  }

  set totalBusy(isBusy: boolean) {
    this._totalBusy = isBusy;
  }

  get currentShipments(): IShipmentListItem[] | null {
    if (this.entities && this.entities[this.pageNum]) {
      return this.entities[this.pageNum].shipments || null;
    }

    return null;
  }

  updateShipment(shipment: IShipmentListItem): void {
    if (!shipment?.id) return;
    const array = this.entities?.[this.pageNum]?.shipments || [];
    const index = array.findIndex((i) => i.id === shipment.id);
    if (index !== -1) {
      array.splice(index, 1, shipment);
      this.cloneCurrentShipments();
    }
  }

  cloneCurrentShipments() {
    if (this.entities?.[this.pageNum]?.shipments?.length) {
      this.entities[this.pageNum].shipments = [
        ...(this.entities[this.pageNum].shipments as IShipmentListItem[]),
      ];
    }
  }

  get currentEntity(): IShipmentListEntity | null {
    if (this.entities && this.entities[this.pageNum]) {
      return this.entities[this.pageNum];
    }

    return null;
  }

  get selectedCount(): number {
    let counter = 0;

    if (this.entities) {
      (Object.keys(this.entities) as unknown as Array<keyof IShipmentListEntities>).forEach(
        (key) => {
          if (this.entities && this.entities[key]) {
            const currentEntity = this.entities[key];
            if (currentEntity.shipments) {
              currentEntity.shipments.forEach((shipment) => {
                if (shipment.selected) {
                  counter += 1;
                }
              });
            }
          }
        }
      );
    }

    return counter;
  }

  get excludedIds(): string[] {
    return this._excludedIds;
  }

  get selectedIds(): string[] {
    return this._selectedIds;
  }

  get keyword(): string | null {
    return this._keyword;
  }

  get filter(): IShipmentListFiltersData | null {
    return this._filter;
  }

  get updatedShipments(): IUpdatedShipmentListItems | null {
    return this._updatedShipments;
  }

  get validToShipSelectedCount(): number {
    return this.getValidSelectedCount('is_valid_to_ship');
  }

  get validToPrintSelectedCount(): number {
    return this.getValidSelectedCount('is_valid_to_print', this.isSelectedAll);
  }

  get validToRescheduleCount(): number {
    return this.getValidSelectedCount('is_valid_to_reschedule_pickup', this.isSelectedAll);
  }

  get validToScheduleCount(): number {
    return this.getValidSelectedCount('is_valid_to_schedule_pickup', this.isSelectedAll);
  }

  get validToShipSelectedIds(): string[] {
    return this.getValidSelectedIds('is_valid_to_ship');
  }

  get validToPrintSelectedIds(): string[] {
    return this.getValidSelectedIds('is_valid_to_print');
  }

  get validToRescheduleSelectedIds(): string[] {
    return this.getValidSelectedIds('is_valid_to_reschedule_pickup');
  }

  get validToScheduleSelectedIds(): string[] {
    return this.getValidSelectedIds('is_valid_to_schedule_pickup');
  }

  get filterPayload(): IShipmentListPayloadFilter {
    let payload: IShipmentListPayloadFilter = {};
    if (this.keyword) {
      payload.keyword = this.keyword;
    }

    if (this.filter) {
      payload = {
        ...payload,
        ...this.normalizeFilterData(this.filter),
      };
    }

    if (this.sortBy) {
      payload = {
        ...payload,
        ...this.sortCriteriasData(this.sortBy),
      };
    }

    return payload;
  }

  get customViews(): IShipmentListCustomViewsItem[] | null {
    return this._customViews;
  }

  private getValidSelectedIds(key: IShipmentValidKey): string[] {
    const validIds: string[] = [];
    this.calculateValidShipment(key, (shipmentId) => {
      validIds.push(shipmentId);
    });

    return validIds;
  }

  private getValidSelectedCount(key: IShipmentValidKey, isSelectedAll = false): number {
    let count = 0;
    this.calculateValidShipment(
      key,
      () => {
        count += 1;
      },
      isSelectedAll
    );

    return count;
  }

  get selectedView(): IShipmentListDefaultViewsItem | IShipmentListCustomViewsItem | null {
    return this._selectedView;
  }

  isCurrentViewFilterInitialStateChanged(isReturnPage = false): boolean {
    // Custom view
    if (this.selectedView) {
      const modifyView = this.selectedView as IShipmentListCustomViewsItem;
      const clearFilterData = this.deleteUndefinedFilterAttribute(this.filter);

      if (modifyView.filters && clearFilterData) {
        if (
          !angular.equals(this.deleteUndefinedFilterAttribute(modifyView.filters), clearFilterData)
        ) {
          return true;
        }
      } else if (!angular.equals(modifyView.filters, clearFilterData)) {
        return true;
      }

      if (modifyView.items_per_page !== this.limit) {
        return true;
      }

      if ((modifyView.keyword || this.keyword) && modifyView.keyword !== this.keyword) {
        return true;
      }

      // if modifyView.sort_criterias not exist and this.sortBy not exist
      // if modifyView.sort_criterias not exist but this.sortBy exist
      if (
        (!modifyView.sort_criterias ||
          this.HelperService.isObjectEmpty(modifyView.sort_criterias)) &&
        this.sortBy
      ) {
        return true;
      }

      // if modifyView.sort_criterias exist but this.sortBy not exist
      if (
        modifyView.sort_criterias &&
        !this.HelperService.isObjectEmpty(modifyView.sort_criterias) &&
        !this.sortBy
      ) {
        return true;
      }

      // if modifyView.sort_criterias exist and this.sortBy exist
      if (
        modifyView.sort_criterias &&
        !this.HelperService.isObjectEmpty(modifyView.sort_criterias) &&
        this.sortBy
      ) {
        // if modifyView.sort_criterias[this.sortBy] not exist
        if (!modifyView.sort_criterias[this.sortBy]) {
          return true;
        }

        // if modifyView.sort_criterias[this.sortBy] exist but different sort direction
        if (modifyView.sort_criterias[this.sortBy] !== this.sortDirection) {
          return true;
        }
      }

      return false;
    }

    // Default view
    const hasFilter = (
      Object.keys(this.filter || {}) as Array<keyof IShipmentListFiltersData>
    ).some((key) => {
      if (
        this.UserSession.getCompanyDashboardSettings().beta_feature_returns_page ||
        isReturnPage
      ) {
        return this.filter && key !== DrawerMenuKey.TBC && this.filter[key];
      }

      return this.filter && this.filter[key];
    });

    return !!(hasFilter || this.sortBy || this.keyword);
  }

  get sortBy(): SortByAttribute | null {
    return this._sortBy;
  }

  get sortDirection(): SortDirection | null {
    return this._sortDirection;
  }

  private getBatchFilterData(
    batches: IBatchObject[],
    batchId: string[]
  ): AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchBatchObject> {
    return {
      radio: MultiselectComponentModelValue.AnyOf,
      input: null,
      options: this.BatchService.generateFilterBatchObject(batches, batchId),
    };
  }

  private getStatusMessageFilterData(
    statusMessage: INewStatusMessage[],
    statusId: string[]
  ): AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStatusMessageObject> {
    return {
      radio: MultiselectComponentModelValue.AnyOf,
      input: null,
      options: this.StatusMessageService.generateNewFilterStatusMessageObject(
        statusMessage,
        'name',
        this.HelperService.convertArrayStringToArrayNumber(statusId)
      ),
    };
  }

  private calculateValidShipment(
    validKey: IShipmentValidKey,
    callback: (shipmentId: string) => void,
    isSelectedAll = false
  ): void {
    if (this.entities) {
      (Object.keys(this.entities) as unknown as Array<keyof IShipmentListEntities>).forEach(
        (key) => {
          if (this.entities && this.entities[key]) {
            const { shipments } = this.entities[key];
            if (shipments) {
              const ids = isSelectedAll ? this.excludedIds : this.selectedIds;
              shipments.forEach((shipment) => {
                if (shipment[validKey] && ids.includes(shipment.id)) {
                  callback(shipment.id);
                }
              });
            }
          }
        }
      );
    }
  }

  private selectAllShipmentsAction(isCheck: boolean): void {
    if (this.entities) {
      (Object.keys(this.entities) as unknown as Array<keyof IShipmentListEntities>).forEach(
        (key) => {
          if (this.entities && this.entities[key]) {
            const currentEntity = this.entities[key];
            currentEntity.selected = isCheck;
            if (currentEntity.shipments) {
              currentEntity.shipments = currentEntity.shipments.map((shipment) => {
                const currentShipment = shipment;
                currentShipment.selected = isCheck;
                return currentShipment;
              });
            }
          }
        }
      );
    }
  }

  public updateShipmentsDetail(shipments: IShipmentListItem[]): void {
    const array = this.entities?.[this.pageNum]?.shipments || [];

    shipments.forEach((shipment) => {
      const index = array.findIndex((i) => i.id === shipment.id);
      if (index !== -1) {
        array.splice(index, 1, shipment);
      } else array.unshift(shipment);
    });

    const existing = this.entities?.[this.pageNum]?.shipments;
    if (existing) this.cloneCurrentShipments();
    else {
      this._entities = {
        [this.pageNum]: {
          selected: false,
          shipments: array,
        },
      };
    }
    this.shipmentBusy = false;
  }

  private resetSelectedIds(): void {
    this._selectedIds = [];
    this._excludedIds = [];
  }

  private setShipmentSelectedStatus(): void {
    if (this._entities) {
      const currentEntity = this._entities[this.pageNum];

      if (this.isSelectedAll) {
        currentEntity.selected = true;
      }

      if (currentEntity && currentEntity.shipments) {
        this._entities[this.pageNum].shipments = currentEntity.shipments.map((shipment) => {
          const modifyShipment = shipment;
          modifyShipment.selected = currentEntity.selected;
          if (this._selectedIds.includes(modifyShipment.id)) {
            modifyShipment.selected = true;
          }

          if (this._excludedIds.includes(modifyShipment.id)) {
            modifyShipment.selected = false;
          }

          return modifyShipment;
        });

        if (
          this._entities[this.pageNum].shipments &&
          this._entities[this.pageNum].shipments?.some((shipment) => !shipment.selected)
        ) {
          this._entities[this.pageNum].selected = false;
        }
      }
    }
  }

  private addIdsIfExist(shipment: IShipmentListItem, shipmentIds: string[]): string[] {
    const ids = angular.copy(shipmentIds);
    if (!ids.includes(shipment.id)) {
      ids.push(shipment.id);
    }

    return ids;
  }

  private deleteIdsIfExist(shipment: IShipmentListItem, shipmentIds: string[]): string[] {
    let ids = angular.copy(shipmentIds);
    if (ids.includes(shipment.id)) {
      ids = ids.filter((id) => id !== shipment.id);
    }

    return ids;
  }

  private updateShipmentIdsAndExcludeShipmentIdsAfterSelect(
    isCheck: boolean,
    selectedShipment: IShipmentListItem
  ): void {
    if (isCheck) {
      if (this.isSelectedAll) {
        this._excludedIds = this.deleteIdsIfExist(selectedShipment, this._excludedIds);
      } else {
        this._selectedIds = this.addIdsIfExist(selectedShipment, this._selectedIds);
      }
    } else if (this.isSelectedAll) {
      this._excludedIds = this.addIdsIfExist(selectedShipment, this._excludedIds);
    } else {
      this._selectedIds = this.deleteIdsIfExist(selectedShipment, this._selectedIds);
    }
  }

  private async generateBatchFilterData(batchId: string[]): Promise<void> {
    const data = await this.BatchService.getBatches();
    if (data && data.batches) {
      if (this._filter) {
        this._filter[DrawerMenuKey.Batches] = this.getBatchFilterData(data.batches, batchId);
      } else {
        this._filter = {
          [DrawerMenuKey.Batches]: this.getBatchFilterData(data.batches, batchId),
        };
      }
    }
  }

  private async generatePickupFilterData(pickupId: string): Promise<void> {
    // query recent pickups
    const pickups = await this.PickupService.getCompanyPickups(
      { scope: 'pickup_only' },
      { status: 'in_progress' }
    );
    const includesSelectedId = pickups.some(({ pickup_id }) => pickup_id === pickupId);

    // query this specific pickup
    if (!includesSelectedId) {
      const { pickup } = await this.PickupService.getSupportInfo(pickupId);
      pickups.push({
        ...pickup,
        pickup_id: pickup.id,
      });
    }

    // merge them into a filter object
    const pickupOptions = this.PickupService.initFilter(pickups);
    const withSelection = pickupOptions.map((pickupGroup) => ({
      ...pickupGroup,
      selected: pickupGroup.options?.some(({ pickup_id }) => pickup_id === pickupId),
      options: pickupGroup.options?.map((pickup) =>
        pickup.pickup_id === pickupId ? { selected: true, ...pickup } : pickup
      ),
    }));
    this._filter = this._filter || {};
    this._filter[DrawerMenuKey.Pickups] = {
      radio: MultiselectComponentModelValue.AnyOf,
      input: null,
      options: withSelection, // [{ display: 'Selected pickup', selected: true, value: [pickupId] }],
    };
  }

  private async generateShipmentStatusFilterData(statusId: string): Promise<void> {
    const res = await this.StatusMessageService.getNewStatusMessages();
    if (res) {
      if (this._filter) {
        this._filter[DrawerMenuKey.ShipmentStatus] = this.getStatusMessageFilterData(
          res.status_messages,
          statusId.split(',')
        );
      } else {
        this._filter = {
          [DrawerMenuKey.ShipmentStatus]: this.getStatusMessageFilterData(
            res.status_messages,
            statusId.split(',')
          ),
        };
      }
    }
  }

  private generateDestinationCountryFilterData(countryId: number): void {
    const country = this.CountryService.findCountry(countryId);
    if (country) {
      if (this._filter) {
        this._filter[DrawerMenuKey.DestinationCountry] = this.getDestinationCountryFilterData(
          country.alpha2
        );
      } else {
        this._filter = {
          [DrawerMenuKey.DestinationCountry]: this.getDestinationCountryFilterData(country.alpha2),
        };
      }
    }
  }

  private async generateCourierFilterData(courierId: string): Promise<void> {
    await this.CourierService.getCouriers();
    const companyCouriers: IAdvancedSearchCourierObject[] = [];
    const easyshipCouriers: IAdvancedSearchCourierObject[] = [];
    this.CourierService.couriers.forEach((courier) => {
      const courierObject: IAdvancedSearchCourierObject = angular.copy(courier);
      if (courierId === courierObject.id) {
        courierObject.selected = true;
      }
      if (courierObject.is_easyship_courier) {
        easyshipCouriers.push(courierObject);
      } else {
        companyCouriers.push(courierObject);
      }
    });

    const courierFilterData: AdvancedSearchComponentApiNormalize.ICourierGroupObject = {
      easyshipCouriers: null,
      companyCouriers: null,
      radio: MultiselectComponentModelValue.AnyOf,
      options: null,
      input: null,
    };

    if (easyshipCouriers.length > 0) {
      courierFilterData.easyshipCouriers =
        ShipmentListNormalizeService.injectLogoUrlToCourierObject(
          this.groupCouriersObject(easyshipCouriers)
        );
    }

    if (companyCouriers.length > 0) {
      courierFilterData.companyCouriers = ShipmentListNormalizeService.injectLogoUrlToCourierObject(
        this.groupCouriersObject(companyCouriers)
      );
    }

    if (this._filter) {
      this._filter[DrawerMenuKey.Couriers] = courierFilterData;
    } else {
      this._filter = {
        [DrawerMenuKey.Couriers]: courierFilterData,
      };
    }
  }

  private async generatePlatformNameObject(platformName: string): Promise<void> {
    const res = await this.$q.all([
      this.StoreService.getStores(),
      this.PlatformService.getPlatformGroups(),
    ]);

    const concatStore = this.prepareStorePlatFormData(res, platformName);
    const filterData: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStorePlatformOption> =
      {
        input: null,
        radio: MultiselectComponentModelValue.AnyOf,
        options: this.HelperService.groupArrayByKey(concatStore, MultiselectAttributes.Group, {
          isDisplay: true,
        }),
      };
    if (this._filter) {
      this._filter[DrawerMenuKey.StoresOrPlatform] = filterData;
    } else {
      this._filter = {
        [DrawerMenuKey.StoresOrPlatform]: filterData,
      };
    }
  }

  private getDestinationCountryFilterData(countryName: string): ICountriesGroupObject {
    return {
      radio: MultiselectComponentModelValue.AnyOf,
      options: [countryName],
    };
  }

  private groupCouriersObject(couriers: IAdvancedSearchCourierObject[]): ICouriersGroupedArray[] {
    return this.HelperService.groupArrayByKey(couriers, MultiselectAttributes.UmbrellaName, {
      isDisplay: true,
      isOpen: true,
    });
  }

  private deleteUndefinedFilterAttribute(
    filter: IShipmentListFiltersData | null
  ): IShipmentListFiltersData | null {
    const clearData = angular.copy(filter);
    if (clearData) {
      (Object.keys(clearData) as unknown as Array<keyof IShipmentListFiltersData>).forEach(
        (fKey) => {
          if (clearData) {
            const currentData = clearData[fKey];
            if (currentData) {
              Object.keys(currentData).forEach((sKey) => {
                // eslint-disable-next-line
                if ((currentData as any)[sKey] === undefined) {
                  // eslint-disable-next-line
                  delete (currentData as any)[sKey];
                }
              });
            }
          }
        }
      );
    }

    return clearData;
  }

  private resetBusy(): void {
    this._shipmentBusy = false;
    this._totalBusy = false;
  }

  private sortCriteriasData(key: SortByAttribute): IShipmentListCustomViewsSortCriterias {
    let direction: SortDirection = 'desc';
    if (this.isReverseSortOrder(key)) {
      direction = 'asc';
    }

    return {
      sort_criterias: {
        [key]: this.sortDirection ? this.sortDirection : direction,
      },
    };
  }

  private generateLabelPaidObject(labelPaidFrom: string, labelPaidTo: string) {
    if (this._filter) {
      this._filter[DrawerMenuKey.LabelPaymentDate] = {
        daysAgo: null,
        daysAhead: null,
        onDate: null,
        select: DateInputOptionsValue.Between,
        fromDate: labelPaidFrom,
        toDate: labelPaidTo,
      };
    } else {
      this._filter = {
        [DrawerMenuKey.LabelPaymentDate]: {
          daysAgo: null,
          daysAhead: null,
          onDate: null,
          select: DateInputOptionsValue.Between,
          fromDate: labelPaidFrom,
          toDate: labelPaidTo,
        },
      };
    }
  }
}
