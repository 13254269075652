(function () {
  'use strict';

  AutomationRule.$inject = ['$resource', 'API'];
  function AutomationRule($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/automation_rules/:id',
      {
        id: '@_id',
      },
      {
        query: {
          method: 'GET',
          params: {},
        },
        fetch: {
          method: 'GET',
          params: {},
        },
      }
    );
  }

  angular.module('app.factory.AutomationRule', []).factory('AutomationRule', AutomationRule);
})();
