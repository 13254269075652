(function () {
  'use strict';

  HkDistrict.$inject = ['$resource', 'API'];
  function HkDistrict($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/hk_districts',
      {
        // id: '@_id'
      },
      {
        query: {
          method: 'GET',
          cache: true,
          params: {
            // id: 'me'
          },
        },
      }
    );
  }

  angular.module('app.factory.HkDistrict', []).factory('HkDistrict', HkDistrict);
})();
