import { IComponentController } from 'angular';
import template from './tags-area.html?raw';
import style from './tags-area.module.scss';

class TagsArea implements IComponentController {
  esOnChange?(changes: { value: string[]; remainder?: string }) {}
  style = style;
  esModel: string[] = [];
  textarea = '';
  previousTextarea = '';
  isFocused = false;

  static $inject = ['$element', '$timeout'];
  constructor(private $element: ng.IAugmentedJQuery, private $timeout: ng.ITimeoutService) {}

  $onInit(): void {
    this.$timeout(() => {
      const textarea = this.$element.find('textarea')[0];
      const placeholder = this.$element.find('.tags-area-placeholder-span')[0];
      const textareaPadding = 10;
      if (textarea && placeholder) {
        textarea.style.minWidth = `${placeholder.offsetWidth + textareaPadding}px`;
      }
    });

    if (!this.esModel) {
      this.esModel = [];
    }
  }

  removeTag(index: number): void {
    this.esModel.splice(index, 1);

    this.esOnChange && this.esOnChange({ value: this.esModel, remainder: this.textarea });
  }

  onTextareaChange($event: KeyboardEvent): void {
    let remainder;

    switch ($event.code) {
      case 'Enter':
      case 'Space':
      case 'Comma':
        $event.preventDefault();
        if (this.textarea !== '') {
          this.esModel.push(this.textarea);
          this.textarea = '';
        }
        break;

      case 'Backspace':
        if (this.esModel.length > 0 && !this.textarea && this.textarea === this.previousTextarea) {
          this.esModel.pop();
        }
        break;

      case 'Meta':
      case 'MetaLeft':
        break;

      default:
        remainder = `${this.textarea}${$event.key}`;
        break;
    }

    this.previousTextarea = this.textarea;
    this.esOnChange && this.esOnChange({ value: this.esModel, remainder });
  }

  onPasteValue(): void {
    this.$timeout(() => {
      this.generateTagsValue(this.textarea).forEach((value) => {
        this.esModel.push(value);
        this.esOnChange && this.esOnChange({ value: this.esModel, remainder: '' });
      });
      this.textarea = '';
    });
  }

  onHostClick(): void {
    this.$element.find('textarea')[0].focus();
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;
  }

  private generateTagsValue(value: string): string[] {
    return value.replace(/\n/g, ',').split(/\s*,+\s*/);
  }
}

const TagsAreaComponent: ng.IComponentOptions = {
  controller: TagsArea,
  template,
  bindings: {
    esModel: '<',
    esPlaceholder: '@',
    esOnChange: '&',
  },
};

export { TagsAreaComponent };
