/* eslint-disable max-classes-per-file */
import template from './sync-orders-modal.html?raw';

class SyncOrdersModalController {
  static $inject = ['SyncOrdersModalService', 'step'];

  constructor(SyncOrdersModalService, step) {
    this.SyncOrdersModalService = SyncOrdersModalService;

    this.step = step;
  }

  close() {
    this.SyncOrdersModalService.close();
  }
}

class SyncOrdersModalService {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open(step) {
    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: SyncOrdersModalController,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        step: () => step,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { SyncOrdersModalService };
