import angular from 'angular';

import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import { PackingSlipComponent } from './packing-slip.component';

const MODULE_NAME = 'app.tools.packing-slip';

angular
  .module(MODULE_NAME, [
    'core.service.mixpanel',
    'app.global.user',
    'app.factory.UserSession',
    'app.service.CompanyService',
    'app.service.AddressService',
    'core.components',
    onboardingChecklistService,
  ])
  .component('packingSlip', PackingSlipComponent);

export default MODULE_NAME;
