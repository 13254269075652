import { Hubspot } from 'typings/hubspot';
import { IUser } from 'typings/user-session';
import { HubspotResource } from './hubspot.resource';

class HubspotFormsService {
  // Seperate from HubspotService to avoid circular dependency
  // $http <- $resource <- HubspotResource <- HubspotService <- UserSession <- httpInterceptor <- $http <- $uiRouter

  static $inject = ['$q', '$location', '$document', 'HubspotResource'];
  constructor(
    private $q: ng.IQService,
    private $location: ng.ILocationService,
    private $document: any,
    private HubspotResource: HubspotResource
  ) {}

  scheduleCall(user: IUser, formData: Hubspot.IContactSalesForm) {
    const fields = [
      //   { name: 'user_id', value: user.id },
      { name: 'email', value: formData.contact_email },
      { name: 'phone', value: formData.contact_number },
      { name: 'preferred_meeting_time', value: formData.contact_time },
      //   { name: 'reason', value: formData.reason },
    ];

    const context = { pageUri: this.$location.absUrl(), pageName: this.$document[0].title };
    const payload = {
      submittedAt: Date.now(),
      fields,
      context,
      skipValidation: false,
    };

    if (import.meta.env.VITE_APP_ENVIRONMENT !== 'production') {
      console.log('HubSpot forms integration is disabled outside production environment.', payload);
      return this.$q.resolve({ inlineMessage: 'disabled outside production' });
    }

    return this.HubspotResource.scheduleCall(payload);
  }
}

export { HubspotFormsService };
