import { IDeferred } from 'angular';
import { Hubspot } from 'typings/hubspot';

class HubspotResource {
  deferred: IDeferred<any>;
  endpoint: string;
  resource: any;

  static $inject = ['$q', '$resource'];

  constructor(private $q: ng.IQService, private $resource: ng.resource.IResourceService) {
    this.deferred = this.$q.defer();
    this.endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/2304206/:formId`;
    this.resource = this.$resource(
      this.endpoint,
      {},
      {
        scheduleCall: {
          method: 'POST',
          params: { formId: '6e2f7a4e-11bd-451a-b26b-00ad41a75240' },
        },
      }
    );
  }

  scheduleCall(payload: Hubspot.IPayload): Promise<Hubspot.IConfirmation> {
    return this.resource.scheduleCall({}, payload).$promise.then(
      (data: Hubspot.IConfirmation) => data,
      (error: any) => ({ inlineMessage: 'Booking failed', ...error })
    );
  }
}

export { HubspotResource };
