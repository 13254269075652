import template from './automations.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('automations', {
      url: '/automations?sidebaropen&appcue',
      parent: 'app.tools',
      template,
      controller: 'automationsCtrl as vm',
      data: {
        requiredUserRight: 'menu.shippingRules',
      },
    });
  },
]);
