import iconLiquidUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-liquid.svg';
import iconBatteryUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-battery.svg';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { openModalByName } from '../../../../../app/utils/angular-modal';

(function () {
  function showShipmentCtrl(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $translate,
    API,
    AddressService,
    HelperService,
    shipmentData,
    UserSession,
    MixpanelService,
    ReportService,
    CheckoutService,
    Shipment,
    ShipmentAction,
    ReturnModal,
    $window,
    $filter,
    PickupService,
    ShipmentListManageService,
    FixMonkeyService,
    fixShipmentModal,
    ModalService
  ) {
    const vm = this;
    vm.iconLiquidUrl = iconLiquidUrl;
    vm.iconBatteryUrl = iconBatteryUrl;

    vm.busy = {
      gettingShipmentInfo: true,
    };
    vm.API = API;
    vm.ReportService = ReportService;
    vm.FixMonkeyService = FixMonkeyService;
    vm.ReturnModal = ReturnModal;
    vm.fixShipmentModal = fixShipmentModal;
    vm.ModalService = ModalService;

    vm.shipmentId = shipmentData.shipmentId;
    vm.index = shipmentData.index;
    vm.fromState = shipmentData.fromState;
    vm.copied = false;
    vm.taxesCost = 0;
    vm.userRoleActions = UserSession.user.role.actions;
    vm.timestampFormat = { dateStyle: 'medium', timeStyle: 'short', timeZone: undefined };
    vm.timeFormat = { hour: 'numeric', minute: 'numeric', hour12: false, timeZone: undefined };
    vm.isMarkAsDeliveredDialogOpen = false;

    vm.htmlReplacements = {
      blue(chunk) {
        return `<span class="text-blue-700">${chunk}</span>`;
      },
    };

    vm.closeModal = function () {
      $scope.$close();
      $state.go((vm.fromState && vm.fromState.name) || $state.current.name, {
        shipment_id: null,
      });
    };

    (function init() {
      Shipment.query(
        {
          company_id: UserSession.company.id,
          company_type: UserSession.company.type,
          id: vm.shipmentId, // This API endpoint accepts either the `id` or `easyship_shipment_id`
          include:
            'shipment_items,list_of_couriers,status_records,checkpoints,returns,transaction_records,batches,chargeable_weight',
        },
        function (data) {
          vm.shipment = data.shipment;

          vm.editRequired = vm.shipment.last_status_message.id === 120;
          vm.showRequestPickupBtn = _checkIfRequestPickupRequired();
          vm.showSchedulePickupsBtn = !!vm.shipment.is_valid_to_schedule_pickup;
          vm.isReturn = vm.shipment.is_return;
          vm.isReturnCreatedInApp = vm.isReturn && vm.shipment.return_for_shipment;
          vm.eligibleToReschedule = vm.shipment.is_valid_to_reschedule_pickup;
          vm.showReturnLabelSection = vm.isReturn || vm.shipment.return_shipments.length;
          vm.showResendNotificationSection = _isConsigneeNotifiable(vm.shipment);
          _prependProtocolToDropoffURL();

          vm.consigneeEmailPayload = {
            email_address: vm.shipment && vm.shipment.email_address,
            bcc_sender: false,
          };

          vm.statusHistory = _prepareStatusHistory();
          vm.shipmentCurrency = vm.shipment.currency;
          vm.weightUnit = UserSession.getCompanyWeightUnit();
          vm.dimensionsUnit = UserSession.getCompanyDimensionsUnit();
          vm.cancelCtaTranslationSlug = _getCancelCtaTranslationSlug();
          vm.calculationUnitSlug = `shipments.list.cost.${vm.shipment.chargeable_weight_calculation_method}`;

          vm.busy.gettingShipmentInfo = false;
          const isTaxOwedCustoms =
            vm.shipment.import_duty_charge +
              vm.shipment.import_tax_charge +
              vm.shipment.estimated_import_tax +
              vm.shipment.estimated_import_duty >
            0;
          const isDomestic =
            vm.shipment.origin_country.alpha2 === vm.shipment.destination_country.alpha2;
          // eslint-disable-next-line no-nested-ternary
          vm.incotermsSlug = isTaxOwedCustoms
            ? vm.shipment.effective_incoterms === 'DDP'
              ? 'global.pre-paid-sender'
              : 'global.post-paid-receiver'
            : isDomestic
            ? 'global.not-applicable'
            : 'global.not-required';

          const hasUserRight = UserSession.hasUserRole('create_shipments');
          vm.showDuplicateShipmentButton =
            !vm.editRequired &&
            Object.keys(vm.userRoleActions).includes('menu.createShipments.multiple') &&
            hasUserRight;

          vm.isReturnsUserRightAllowed = Object.keys(vm.userRoleActions).includes('menu.returns');

          vm.showPickupInstruction =
            AddressService.isPickupInstructionBetaFeatureEnabled() &&
            vm.shipment.origin_country.alpha2 === 'AU' &&
            vm.shipment.pickup.handover_option.category !== 'dropoff';
        },
        function () {
          toastError($translate.instant('toast.default-error'));
        }
      );
    })();

    function _prepareStatusHistory() {
      const statusHistory = [];

      vm.shipment.status_records.forEach(function (statusRecord) {
        statusHistory.push({
          status: statusRecord.name,
          time: statusRecord.created_at,
          displayTime: $filter('intlDate')(statusRecord.created_at), // , {dateStyle: 'medium', timeStyle: 'medium'}), // MMM dd, yyyy h:mm a Mar 18, 2022 2:28 PM
          handler:
            statusRecord.scope === 'Easyship' ? statusRecord.scope : vm.shipment.courier.name,
        });
      });

      vm.shipment.checkpoints.forEach(function (checkpoint) {
        statusHistory.push({
          status: checkpoint.message,
          time: checkpoint.checkpoint_time,
          displayTime: checkpoint.display_checkpoint_time || 'Not provided',
          handler: checkpoint.handler,
        });
      });

      return statusHistory;
    }

    vm.startInsuranceClaimFlowForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Claim insurance', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      vm.startInsuranceClaimFlow();
    };

    vm.startInsuranceClaimFlow = function () {
      $scope.$close();
      vm.ModalService.open('insurance-claim', vm.shipment);
      MixpanelService.track('Shipment Details - Insurance - Show Insurance Claim Flow');
    };

    vm.closeInsuranceClaimFlow = function () {
      vm.showInsuranceClaimFlow = false;
    };

    vm.goToInsuranceClaim = function (link) {
      // Track
      MixpanelService.track('Show Shipments - Details - Click Claim Insurance', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      $window.open(link, '_blank');
    };

    vm.duplicateShipmentForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Duplicate shipment', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      return vm.duplicateShipment();
    };

    vm.duplicateShipment = function () {
      return ShipmentListManageService.duplicateShipment(vm.shipment.easyship_shipment_id);
    };

    vm.goToPickups = function () {
      vm.closeModal();
      return openModalByName('SCHEDULE_PICK_UP', { shipmentIds: [vm.shipment.id] });
    };

    vm.editShipmentForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Edit Shipment', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      vm.editShipment();
    };

    vm.editShipment = function () {
      if (vm.shipment.busy) return;
      vm.shipment.busy = true;

      // eslint-disable-next-line consistent-return
      return ShipmentListManageService.editShipment(
        vm.shipment.easyship_shipment_id,
        vm.closeModal
      ).then(function () {
        vm.shipment.busy = false;
      });
    };

    vm.openMarkAsDeliveredDialogForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Mark As Delivered', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      vm.openMarkAsDeliveredDialog();
    };

    vm.openMarkAsDeliveredDialog = function () {
      vm.isMarkAsDeliveredDialogOpen = true;
    };

    vm.closeMarkAsDeliveredDialog = function () {
      vm.isMarkAsDeliveredDialogOpen = false;
      $scope.$apply();
    };

    vm.markAsDeliveredModalOnConfirm = function () {
      const requestSectionName = HelperService.kebabToSnakeCase($stateParams.sectionName);

      ShipmentListManageService.markedAsDelivered(
        vm.shipment.easyship_shipment_id,
        requestSectionName,
        vm.closeModal
      ).then(function () {
        vm.isMarkAsDeliveredDialogOpen = false;
      });
    };

    vm.copy = function () {
      vm.copied = true;
      $timeout(function () {
        vm.copied = false;
      }, 1000);

      MixpanelService.track('Show Shipments - Details - Copy tracking link', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });
    };

    vm.cancelShipmentForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Cancel Shipment', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      vm.cancelShipment();
    };

    vm.cancelShipment = function () {
      if (vm.shipment) {
        const requestSectionName = HelperService.kebabToSnakeCase($stateParams.sectionName);

        ShipmentListManageService.cancelShipment(vm.shipment, requestSectionName);

        vm.closeModal();
      }
    };

    vm.rescheduleHandoverForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Reschedule handover', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      return vm.rescheduleHandover();
    };

    vm.rescheduleHandover = function () {
      vm.preparingRescheduleHandover = true;

      return ShipmentListManageService.rescheduleHandover(vm.shipment.id).finally(function () {
        vm.preparingRescheduleHandover = false;
      });
    };

    vm.cancelPickup = function () {
      vm.cancellingPickup = true;

      PickupService.cancelPickup(vm.shipment.pickup.id).finally(function () {
        vm.cancellingPickup = false;
      });
      a;
    };

    vm.downloadShippingDocsForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Print Shipping document', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      vm.downloadShippingDocs();
    };

    vm.downloadShippingDocs = function () {
      ShipmentListManageService.getLabel(vm.shipment.id, 'Manage Shipments Modal', vm.closeModal);
      vm.closeModal();
    };

    vm.createReturnLabelForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Create Return label', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      vm.createReturnLabel();
    };

    vm.createReturnLabel = function () {
      if (!vm.shipment) throw new Error();
      vm.busyReturn = true;

      return ShipmentListManageService.openReturnModal(
        vm.shipment.easyship_shipment_id,
        vm.closeModal
      ).then(function () {
        vm.busyReturn = false;
      });
    };

    vm.onConsigneeEmailInputChange = function (value, key) {
      vm.consigneeEmailPayload[key] = value;
    };

    vm.sendConsigneeEmail = function () {
      vm.busySendingEmail = true;

      MixpanelService.track('Show Shipments - Details - Send notification to consignee', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      ShipmentAction.sendConsigneeEmail(vm.shipment.id, vm.consigneeEmailPayload)
        .then(function (data) {
          vm.shipment.consignee_notified_at = data.consignee_notified_at;
          toastSuccess($translate.instant('shipments.modal.notification.send-success'));
        })
        .catch(function (error) {
          if (error.data && error.data.status) {
            toastError(error.data.status);
          }
        })
        .finally(function () {
          vm.busySendingEmail = false;
        });
    };

    vm.downloadCommercialInvoiceForOldButton = function () {
      MixpanelService.track('View Details - Old buttons - Print Commercial invoice', {
        easyship_shipment_id: vm.shipment && vm.shipment.easyship_shipment_id,
      });

      vm.downloadCommercialInvoice();
    };

    vm.downloadCommercialInvoice = function () {
      ShipmentListManageService.downloadCommercialInvoice(vm.shipment, 'Manage Shipments Modal');
    };

    vm.calculateShipmentTotalItemCount = function () {
      return vm.shipment.parcels_attributes.reduce(function (accumulator, parcel) {
        return accumulator + vm.calculateParcelItemCount(parcel.shipment_items_attributes);
      }, 0);
    };

    vm.calculateParcelItemCount = function (items) {
      return items.reduce(function (accumulator, item) {
        return accumulator + item.quantity;
      }, 0);
    };

    vm.formatHsCodeNumber = function (hsCodeNumber) {
      return HelperService.formatHsCodeNumber(hsCodeNumber);
    };

    vm.openInsuranceClaimDialog = function () {
      $scope.$close();
      $state.go('app.shipments', {
        shipment_id: null,
        claim: vm.shipment.id,
      });
    };

    vm.getDeclaredCustomsValueText = function (shipment_items_attribute) {
      function formatCurrency(currency) {
        return new Intl.NumberFormat(UserSession.user.dashboard_settings.language.code || 'en', {
          style: 'currency',
          currency,
        }).format;
      }

      if (
        shipment_items_attribute.declared_currency !== shipment_items_attribute.origin_currency &&
        ['AUD', 'CAD', 'USD'].includes(shipment_items_attribute.declared_currency)
      ) {
        return formatCurrency(shipment_items_attribute.declared_currency)(
          shipment_items_attribute.declared_customs_value
        );
      }

      return $filter('intlCurrency')(
        shipment_items_attribute.declared_customs_value,
        shipment_items_attribute.declared_currency
      );
    };

    function _checkIfRequestPickupRequired() {
      return !UserSession.isCompanyEfulfilment() && vm.shipment.is_valid_to_reschedule_pickup;
    }

    function _prependProtocolToDropoffURL() {
      const dropoffUrl = _.get(vm.shipment, 'pickup.handover_option.dropoff_url');

      if (dropoffUrl && dropoffUrl.indexOf('http') < 0) {
        vm.shipment.pickup.handover_option.dropoff_url = `http://${dropoffUrl}`;
      }
    }

    function _isConsigneeNotifiable(shipment) {
      // not a return
      if (vm.isReturn) return false;

      if (!_hasPreparationLabelState()) return false;

      // shipment cannot be cancelled
      const unnotifiableShipmentStates = ['cancelled'];

      if (unnotifiableShipmentStates.indexOf(shipment.shipment_state) > -1) return false;

      return true;
    }

    function _hasPreparationLabelState() {
      const preparationStates = ['generated', 'printed'];

      return preparationStates.indexOf(vm.shipment.label_state) > -1;
    }

    function _getCancelCtaTranslationSlug() {
      let type = 'cancel-shipment';

      if (_efulfilmentShipmentWithCancellationRequest()) {
        type = 'request-cancel';
      }

      return type;
    }

    function _efulfilmentShipmentWithCancellationRequest() {
      return UserSession.isCompanyEfulfilment() && _hasPreparationLabelState();
    }
  }

  showShipmentCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$translate',
    'API',
    'AddressService',
    'HelperService',
    'shipmentData',
    'UserSession',
    'MixpanelService',
    'ReportService',
    'CheckoutService',
    'Shipment',
    'ShipmentAction',
    'ReturnModal',
    '$window',
    '$filter',
    'PickupService',
    'ShipmentListManageService',
    'FixMonkeyService',
    'fixShipmentModal',
    'ModalService',
  ];

  angular.module('app.service.show-shipment').controller('showShipmentCtrl', showShipmentCtrl);
})();
