import { toastSuccess } from '@client/core/components/react/Toastify';
import template from './merge-all-modal-main.html?raw';
import style from './merge-all-modal-main.module.scss';

class MergeAllModalMain {
  static $inject = [
    '$state',
    'MergeAllModal',
    'ShipmentAction',
    'ShipmentList',
    'EndpointService',
    'MixpanelService',
    'ShipmentListAdvancedService',
    '$translate',
  ];

  constructor(
    $state,
    MergeAllModal,
    ShipmentAction,
    ShipmentList,
    EndpointService,
    MixpanelService,
    ShipmentListAdvancedService,
    $translate
  ) {
    this.style = style;
    this.$state = $state;
    this.MergeAllModal = MergeAllModal;
    this.ShipmentAction = ShipmentAction;
    this.ShipmentList = ShipmentList;
    this.EndpointService = EndpointService;
    this.MixpanelService = MixpanelService;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.$translate = $translate;

    this.busy = false;
    this.error = null;
  }

  onSubmit() {
    this.busy = true;

    this.MixpanelService.track('Multiple - Merge All - Confirm', {
      initial_shipments_count: this.initialShipmentsCount,
      merged_shipments_count: this.mergedShipmentsCount,
    });

    this.ShipmentListAdvancedService.mergeAllConfirm()
      .then((res) => {
        const { initial_shipments_count, merged_shipments_count } = res;

        toastSuccess(
          this.$translate.instant('merge-selected.success', {
            initial_shipments_count,
            merged_shipments_count,
          })
        );

        this.MergeAllModal.close();
        this.ShipmentListAdvancedService.resetAll();
        this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotal();
      })
      .catch((error) => {
        this.error =
          (error.data && error.data.message) || 'Sorry, something went wrong, please try again.';
      })
      .finally(() => {
        this.busy = false;
      });
  }
}

const MergeAllModalMainComponent = {
  template,
  controller: MergeAllModalMain,
  bindings: {
    initialShipmentsCount: '<',
    mergedShipmentsCount: '<',
  },
};

export { MergeAllModalMainComponent };
