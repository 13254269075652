import { IApiConfig } from 'typings/core/config/api';
import {
  IShopifyKeyResponse,
  IShopifyResourceAuthorizeParams,
  IShopifyResourceSuccessResponse,
  IShopifyParams,
} from 'typings/auth/services/shopify-auth';

class ShopifyResource {
  static $inject = ['$resource', 'API'];
  constructor(public $resource: ng.resource.IResourceService, public API: IApiConfig) {}

  authorize(params: IShopifyResourceAuthorizeParams): ng.IPromise<IShopifyResourceSuccessResponse> {
    const resource = this.$resource(`${this.API.endpoint}/shopify/authorize`, {});

    return resource.get(params).$promise;
  }

  getShopifyKey(
    locationObject: IShopifyParams,
    companyId: string
  ): ng.IPromise<IShopifyKeyResponse> {
    const { hmac, host, shop, timestamp } = locationObject;
    const resource = this.$resource(
      `${this.API.endpoint}/companies/:company_id/stores/shopify/get_shopify_key`,
      {}
    );
    const params = { company_id: companyId };
    const payload = { shopify: { hmac, host, shop, timestamp } };

    return resource.save(params, payload).$promise;
  }
}

export { ShopifyResource };
