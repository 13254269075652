import { IFeature, PlanRanking } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import {
  BillingInterval,
  DefinedSubscriptionPlanId,
  FlexibleSubscriptionPlanId,
} from '@client/data/subscription';

import { assertIsPresent } from '@client/src/utils/type-guards';
import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { toastError } from '@client/core/components/react/Toastify';
import template from './legacy-trial-end-card.html?raw';
import style from './legacy-trial-end-card.module.scss';
import image from './legacy-trial-end-card.svg?svgo';

class LegacyTrialEndCard implements IComponentController {
  style = style;
  image = image;
  currency = '';
  legacyDiscount: number;
  currentInterval = BillingInterval.Month;
  planId: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId = DefinedSubscriptionPlanId.Free;
  planRanking?: PlanRanking;
  planName = '';
  price = {};
  currentFeatures: IFeature[] = [];

  static $inject = ['$translate', 'SubscriptionService', 'UserSession'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession
  ) {
    this.currency = this.UserSession.getCompanyCurrency();
    this.legacyDiscount = this.SubscriptionService.legacyDiscountRate * 100;
  }

  $onInit(): void {
    const countryId = this.UserSession.getCompanyCountryId();
    if (!countryId) return;

    this.SubscriptionService.fetchPlansDetail({ country_id: countryId })
      .then(({ features }) => {
        if (!features || !Object.values(DefinedSubscriptionPlanId).includes(this.planId as any)) {
          return;
        }

        const currentPlan = this.SubscriptionService.currentSubscription?.plan;

        assertIsPresent(currentPlan);

        this.currentInterval = currentPlan.interval;
        this.planId = currentPlan.id;
        this.planName = this.SubscriptionService.currentPlanName;
        this.planRanking = currentPlan.ranking;
        this.price = this.SubscriptionService.getPrice();
        this.currentFeatures = this.SubscriptionService.returnAvailableFeatures(
          this.planId as DefinedSubscriptionPlanId,
          features
        );
      })
      .catch((error) => {
        toastError(error?.message);
      });
  }

  formatCount(planAllowance: number | string): string {
    if (typeof planAllowance === 'number') return planAllowance.toLocaleString();

    switch (planAllowance) {
      case 'freemium-pricing.table.unlimited':
        return this.$translate.instant('global.unlimited');

      case 'freemium-pricing.table.contact_us':
      default:
        return '';
    }
  }
}

const LegacyTrialEndCardComponent: ng.IComponentOptions = {
  controller: LegacyTrialEndCard,
  template,
  bindings: {
    onAccept: '&',
    onDecline: '&',
    onClose: '&',
  },
};

export { LegacyTrialEndCardComponent };
