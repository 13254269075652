import { IShipmentListScope } from 'typings/shipment';
import { IUserService } from 'typings/user-service';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import { ShipmentViewsService } from '@client/src/global/services/shipment-views/shipment-views.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './delete-view-modal.module.scss';
import template from './delete-view-modal.html?raw';

class IDeleteViewModal implements IComponentController {
  style = style;
  busy = false;
  esSelectedId: string | null = null;
  esPageContext: IShipmentListScope | null = null;

  static $inject = ['$translate', 'ShipmentViewsService', 'UserSession', 'UserService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private ShipmentViewsService: ShipmentViewsService,
    private UserSession: IUserSession,
    private UserService: IUserService
  ) {}

  esOnDeleteSuccess(_data: { id: string }): void {
    // esOnDelete expression bindings, need to add this in order for typescript to successfully compile
  }

  delete(): void {
    if (this.esSelectedId) {
      this.busy = true;
      this.ShipmentViewsService.deleteCustomViews(this.esSelectedId)
        .then(() => {
          toastSuccess(
            this.$translate.instant('toast.delete-success', {
              noun: this.$translate.instant('global.custom-view').toLowerCase(),
            })
          );

          // If the deleted view is the current default view, then we need to reset the User data as well to prevent 'Reset Default' from showing.
          if (
            this.esPageContext &&
            this.UserSession.getDefaultViews()[this.esPageContext] === this.esSelectedId
          ) {
            this.UserService.update({
              dashboard_settings: { default_views: { [this.esPageContext]: null } },
            })
              .then((res) => {
                this.UserSession.update('user', res.user);
              })
              .catch(() => {
                toastError(this.$translate.instant('toast.default-error'));
              });
          }

          this.esOnDeleteSuccess({
            id: this.esSelectedId as string,
          });
        })
        .finally(() => {
          this.busy = false;
        });
    }
  }
}

const DeleteViewModalComponent: ng.IComponentOptions = {
  controller: IDeleteViewModal,
  template,
  bindings: {
    esOnCancel: '&',
    esOnDeleteSuccess: '&',
    esSelectedId: '<',
    esPageContext: '<',
  },
};

export { DeleteViewModalComponent };
