import { IComponentController } from 'angular';
import template from './progress.html?raw';
import style from './progress.module.scss';

enum ProgressType {
  Horizontal = 'horizontal',
  Round = 'round',
}

enum ProgressColorName {
  Teal = 'teal',
  Sky = 'sky',
  White = 'white',
  Transparent = 'transparent',
}

enum ProgressColorCode {
  Teal = '#4ccaca',
  Sky = '#f4f6f8',
  White = '#fff',
  Transparent = '',
}

const DEFAULT_BACKGROUND = ProgressColorName.Sky;
const DEFAULT_HORIZONTAL_HEIGHT = 5;
const DEFAULT_ROUND_HEIGHT = 30;
const DEFAULT_ROUND_BAR_THICKNESS = 3;

class ProgressController implements IComponentController {
  style = style;
  duration = 0;
  esType: ProgressType | null = null;
  esCurrentValue = 0;
  esInitialValue = 0;
  esHeight = 0;
  esRoundBarThickness = 0;
  esColor: string | null = null;
  esBackground: string | null = null;

  static $inject = ['$timeout'];
  constructor(private $timeout: ng.ITimeoutService) {}

  $onInit() {
    if (!this.esType) {
      this.esType = ProgressType.Horizontal;
    }

    if (!this.esHeight) {
      if (this.esType === ProgressType.Horizontal) {
        this.esHeight = DEFAULT_HORIZONTAL_HEIGHT;
      } else {
        this.esHeight = DEFAULT_ROUND_HEIGHT;
      }
    }

    if (!this.esBackground) {
      this.esBackground = DEFAULT_BACKGROUND;
    }

    if (!this.esRoundBarThickness) {
      this.esRoundBarThickness = DEFAULT_ROUND_BAR_THICKNESS;
    }

    this.setInitialValueAndAnimation();
  }

  getColorCode(color: ProgressColorName): ProgressColorCode {
    switch (color) {
      case ProgressColorName.Sky:
        return ProgressColorCode.Sky;
      case ProgressColorName.White:
        return ProgressColorCode.White;
      case ProgressColorName.Transparent:
        return ProgressColorCode.Transparent;
      default:
        // teal
        return ProgressColorCode.Teal;
    }
  }

  // Set initial value without animation then set the current with animation after propagation
  private setInitialValueAndAnimation() {
    const currentValue = this.esCurrentValue;

    if (this.esInitialValue) {
      if (this.esCurrentValue !== this.esInitialValue) {
        this.esCurrentValue = this.esInitialValue;
      }

      this.$timeout(() => {
        this.esCurrentValue = currentValue;

        this.duration = 600;
      });
    } else {
      this.duration = 600;
    }
  }
}

const ProgressComponent: ng.IComponentOptions = {
  controller: ProgressController,
  template,
  bindings: {
    esType: '@?',
    esCurrentValue: '<',
    esInitialValue: '<',
    esHeight: '<?',
    esColor: '@?',
    esBackground: '@?',
    esRoundBarThickness: '@?',
  },
  transclude: true,
};

export { ProgressComponent };
