import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip';
import { PopperProps } from '@material-ui/core/Popper';
import { InfoIcon } from '@client/src/components/icons/InfoIcon';

const InfoIconWithRef = React.forwardRef<HTMLDivElement>(function MyComponent(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <span
      style={{
        display: 'inline-flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <InfoIcon fontSize="small" />
    </span>
  );
});

const useSimpleTooltipStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    '&&': {
      padding: '4px 8px',
      backgroundColor: theme.palette.sky.main,
      boxShadow: '0 5px 15px 0 rgba(40, 78, 102, 0.15)',
      margin: '4px',
    },
  },
}));

interface Props {
  width?: string;
}

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    width: (props: Props) => props.width,
  },
}));

export interface TooltipProps extends Omit<MuiTooltipProps, 'children'> {
  children?: React.ReactElement;
  active?: boolean;
  tooltipWidth?: string;
}

function TooltipInner({
  interactive,
  children,
  tooltipWidth,
  ...props
}: Omit<TooltipProps, 'active'>): JSX.Element {
  const content = children ?? <InfoIconWithRef />;
  const simpleTooltipClasses = useSimpleTooltipStyles();
  const TooltipWidthClasses = useTooltipStyles({ width: tooltipWidth });
  const defaultPopperProps: Partial<PopperProps> = { style: { maxWidth: 300 } };
  const popperProps: Partial<PopperProps> = Object.assign(defaultPopperProps, props.PopperProps);

  return (
    <MuiTooltip
      arrow={interactive}
      interactive={interactive}
      PopperProps={popperProps}
      classes={!interactive ? simpleTooltipClasses : TooltipWidthClasses}
      {...props}
    >
      {content}
    </MuiTooltip>
  );
}

export default function Tooltip({ active = true, ...props }: TooltipProps): JSX.Element {
  const content = props?.children ?? <InfoIconWithRef />;
  return !active ? content : <TooltipInner {...props} />;
}
