import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box, { BoxProps } from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  pulse: {
    backgroundColor: theme.palette.ink.main,
    borderRadius: '100%',
    animation: '$scaleout 1.0s infinite ease-in-out',
  },
  '@keyframes scaleout': {
    '0%': {
      transform: 'scale(0.0)',
    },
    '100%': {
      transform: 'scale(1.0)',
      opacity: 0,
    },
  },
}));

type PulseSize = 'sm' | 'md' | 'lg';

export interface ProgressIndicatorProps extends BoxProps {
  size?: PulseSize;
  show?: boolean;
}

export default function ProgressIndicator({
  size = 'md',
  show = false,
  children,
  ...props
}: ProgressIndicatorProps): JSX.Element {
  const classes = useStyles();
  const pxSize: Record<PulseSize, number> = { sm: 20, md: 40, lg: 80 };

  return (
    <Box position="relative" {...props}>
      {children}
      {show && (
        <Box
          position="absolute"
          top={0}
          bottom={0}
          right={0}
          left={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          role="progressbar"
        >
          <Box className={classes.pulse} width={pxSize[size]} height={pxSize[size]} />
        </Box>
      )}
    </Box>
  );
}
