import angular from 'angular';

import { CourierRedirectComponent } from './courier-redirect.component';

const MODULE_NAME = 'app.couriers.courier-redirect';

angular
  .module(MODULE_NAME, [])
  .component('esCourierRedirect', CourierRedirectComponent);

export default MODULE_NAME;
