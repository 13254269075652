import { react2angular } from 'react2angular';

import LearnMore, { WrappedLearnMore } from './LearnMore';

export default LearnMore;
export const AngularLearnMore = react2angular(
  WrappedLearnMore,
  ['esSectionName', 'esSupportLink', 'esDescription', 'esClickLink', 'esAddBorder'],
  ['$injector']
);
