import React from 'react';
import { Button, Modal } from 'easyship-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { CourierConnectResultContent } from '../CourierConnectResultContent';

interface ConnectUpsErrorProps {
  response: {
    data: {
      status: string;
    };
  };
}
interface CourierConnectResultModalProps {
  nickname: string;
  onClose: () => void;
}
// This is to be used in react and to provide modal window functionality
export default function CourierConnectResultModal({
  nickname,
  onClose,
}: CourierConnectResultModalProps) {
  const { formatMessage } = useIntl();

  const queryClient = useQueryClient();
  const mutation = queryClient
    .getMutationCache()
    .getAll()
    .find(
      (mutation) =>
        mutation.options.mutationKey && mutation.options.mutationKey[0] === 'tokenMutation'
    );
  const isSuccess = mutation?.state?.status === 'success';
  const errorMessage = mutation?.state?.error as ConnectUpsErrorProps;

  const translatedHeader = isSuccess
    ? formatMessage({ id: 'courier.connect.success.connected' }, { courier: nickname })
    : formatMessage({ id: 'courier.connect.success.connection-failed' }, { nickname });
  const error = errorMessage ? errorMessage?.response?.data?.status : '';

  return (
    <Modal open={!!nickname} onClose={onClose}>
      <Modal.Header closeButtonAriaLabel="Close" title={translatedHeader} />
      <Modal.Content className="max-w-[520px] p-0">
        <CourierConnectResultContent
          nickname={nickname}
          isSuccess={isSuccess}
          errorMessage={error}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button color="default" className="px-10" type="button" onClick={onClose}>
          <FormattedMessage id="global.got-it" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
