import { StringOptionsValue } from '@client/src/global/services/shipment-list/data/string-input';
import { IComponentController } from 'angular';
import template from './pick-location-input-group.html?raw';
import style from './pick-location-input-group.module.scss';

interface PickUpOption {
  value: StringOptionsValue;
  label: string;
}

class PickLocationInputGroup implements IComponentController {
  style = style;
  esModelSelect: StringOptionsValue | null = null;
  esModelInput: string | null = null;
  esOnChange(value: any): void {}
  options: PickUpOption[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    this.options = [
      StringOptionsValue.Contains,
      StringOptionsValue.NotContain,
      StringOptionsValue.OnlyContains,
    ].map((option) => ({
      value: option,
      label: this.$translate.instant(
        `shipments.filter.pick-location.select-options.value.${option}`
      ),
    }));
    if (!this.esModelInput) {
      this.esModelInput = '';
    }

    if (!this.esModelSelect) {
      this.esModelSelect = StringOptionsValue.Contains;
    }
  }

  onSelectChange(value: StringOptionsValue): void {
    this.esModelSelect = value;
    this.esOnChange({
      value: {
        select: this.esModelSelect,
        input: this.esModelInput,
      },
    });
  }

  onInputChange(value: string): void {
    this.esModelInput = value;
    this.esOnChange({
      value: {
        select: this.esModelSelect,
        input: this.esModelInput,
      },
    });
  }
}

const PickLocationInputGroupComponent: ng.IComponentOptions = {
  controller: PickLocationInputGroup,
  template,
  bindings: {
    esModelSelect: '<',
    esModelInput: '<',
    esOnChange: '&',
  },
};

export { PickLocationInputGroupComponent };
