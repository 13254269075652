export const B13A_FILING_SERVICE_NAME = 'fedex_b13a_filing';

export const B13A_FILING_NOT_REQUIRED = 'NOT_REQUIRED';

export const B13A_FILING_OPTIONS = [
  B13A_FILING_NOT_REQUIRED,
  'FEDEX_TO_STAMP',
  'FILED_ELECTRONICALLY',
  'MANUALLY_ATTACHED',
  'SUMMARY_REPORTING',
] as const;

export type B13aFilingOption = (typeof B13A_FILING_OPTIONS)[number];
