(function () {
  'use strict';

  function addressFormsContainerCtrl(CountryService, AddressService) {
    var ctrl = this;

    ctrl.countries = [];

    ctrl.HK_ID = 96;
    ctrl.SG_ID = 199;
    ctrl.US_ID = 234;
    ctrl.AU_ID = 14;
    ctrl.CA_ID = 39;
    ctrl.NL_ID = 167;
    ctrl.GB_ID = 78;

    var countriesWithAddressForm = [
      ctrl.HK_ID,
      ctrl.SG_ID,
      ctrl.US_ID,
      ctrl.AU_ID,
      ctrl.CA_ID,
      ctrl.NL_ID,
      ctrl.GB_ID,
    ];

    ctrl.$onInit = function () {
      if (ctrl.hideCountrySelector) return;

      if (ctrl.formType !== 'shipping') {
        CountryService.getCountries().then(function (response) {
          ctrl.countries = response.countries;
        });

        return;
      }

      CountryService.getShippingSupportedCountries().then(function (response) {
        ctrl.countries = response.countries;
      });

      if (
        ctrl.address.pickup_instruction_slug === undefined ||
        ctrl.address.pickup_instruction_users_input === undefined
      ) {
        ctrl.address.pickup_instruction_slug = ctrl.isPickupInstructionAvailable() ? 'none' : null;
        ctrl.address.pickup_instruction_users_input = null;
      }
    };

    ctrl.isPickupInstructionAvailable = function () {
      return (
        AddressService.isPickupInstructionAvailable(ctrl.address.country_id) &&
        ctrl.formType === 'shipping'
      );
    };

    ctrl.onCountryChange = function (countryId) {
      ctrl.address.country_id = countryId;
      ctrl.address.postal_code = '';
      ctrl.address.city = '';
      ctrl.address.state = '';
      ctrl.address.pickup_instruction_slug = ctrl.isPickupInstructionAvailable() ? 'none' : null;
      ctrl.address.pickup_instruction_users_input = null;
    };

    ctrl.showDefaultAddressForm = function (countryId) {
      return countriesWithAddressForm.indexOf(countryId) === -1;
    };
  }

  addressFormsContainerCtrl.$inject = ['CountryService', 'AddressService'];

  angular
    .module('app.component.address-forms-container')
    .controller('addressFormsContainerCtrl', addressFormsContainerCtrl);
})();
