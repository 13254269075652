import template from './delete-prompt-modal.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').service('deletePromptModal', deletePromptModal);

  deletePromptModal.$inject = ['$uibModal'];
  function deletePromptModal($uibModal) {
    var service = this;

    service.open = function () {
      $uibModal.open({
        template,
        controller: 'deletePromptModalCtrl',
        openedClass: 'easyship-modal-default',
      });
    };
  }
})();
