import {
  NumberInputTypeValue,
  NumberOfItemsInputOptionsValue,
} from '@client/src/global/services/shipment-list/data/number-input';

import { IComponentController } from 'angular';
import template from './weight-input-group.html?raw';
import style from './weight-input-group.module.scss';

interface RelationOption {
  value: NumberOfItemsInputOptionsValue;
  label: string;
}

class WeightInputGroup implements IComponentController {
  style = style;
  esOnChange: (value: any) => void = () => {};
  esModelSelect: NumberOfItemsInputOptionsValue | null = null;
  isBetweenOption = false;
  esFromNumber: number | null = null;
  esToNumber: number | null = null;
  esEqualNumber: number | null = null;
  inputTypeValue = NumberInputTypeValue;
  readonly numberValidation = {
    allowNegative: false,
  };
  options: RelationOption[] = [];

  static $inject = ['$translate'];
  constructor(private $translate: angular.translate.ITranslateService) {}

  $onInit(): void {
    this.options = [
      NumberOfItemsInputOptionsValue.GreaterThan,
      NumberOfItemsInputOptionsValue.LessThan,
      NumberOfItemsInputOptionsValue.Between,
    ].map((option) => ({
      value: option,
      label: this.$translate.instant(`global.relation.${option}`),
    }));
    if (!this.esModelSelect) {
      this.esModelSelect = NumberOfItemsInputOptionsValue.GreaterThan;
      this.onValuesChange();
    }
  }

  $onChanges(): void {
    this.assignCurrentValue();
  }

  onSelectChange(value: NumberOfItemsInputOptionsValue): void {
    this.esFromNumber = null;
    this.esToNumber = null;
    this.esEqualNumber = null;

    this.esModelSelect = value;
    this.setBetweenOptionState();
    this.onValuesChange();
  }

  onInputChange(value: string, key: NumberInputTypeValue): void {
    switch (key) {
      case NumberInputTypeValue.From:
        this.esFromNumber = parseFloat(value);
        break;
      case NumberInputTypeValue.To:
        this.esToNumber = parseFloat(value);
        break;
      case NumberInputTypeValue.Equal:
        this.esEqualNumber = parseFloat(value);
        break;
      default:
        break;
    }

    this.onValuesChange();
  }

  private setBetweenOptionState(): void {
    switch (this.esModelSelect) {
      case NumberOfItemsInputOptionsValue.Between:
        this.isBetweenOption = true;
        break;
      default:
        this.isBetweenOption = false;
        break;
    }
  }

  private onValuesChange(): void {
    this.esOnChange({
      value: {
        select: this.esModelSelect,
        from: this.esFromNumber,
        to: this.esToNumber,
        equal: this.esEqualNumber,
      },
    });
  }

  private assignCurrentValue(): void {
    if (this.esModelSelect) {
      this.setBetweenOptionState();
    }
  }
}

const WeightInputGroupComponent: ng.IComponentOptions = {
  controller: WeightInputGroup,
  template,
  bindings: {
    esModelSelect: '<',
    esEqualNumber: '<',
    esFromNumber: '<',
    esToNumber: '<',
    esOnChange: '&',
  },
};

export { WeightInputGroupComponent };
