import React, { ReactElement, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Button from '@client/core/components/react/Button';

import { ICompanyService, ICloseAccountResponse } from 'typings/company';
import { IUserSession } from 'typings/user-session';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { transformToErrorCodes } from '@client/src/utils/handleApiErrors';
import {
  DialogType,
  ChurnReason,
  ChurnIssue,
  ChurnReasonsDialogProps,
  ChurnReasonsDialogSteps,
} from './typings';

import { useStyles } from './styles';

import Preface from './Preface';
import ReasonsList from './ReasonsList';
import IssuesList from './IssuesList';
import TellUsMore from './TellUsMore';
import Confirmation from './Confirmation';
import UnderReview from './UnderReview';

export default function ChurnReasonsDialog({
  open,
  onClose,
  logOut,
  dialogOrigin,
  $injector,
}: ChurnReasonsDialogProps) {
  const CompanyService = $injector.get('CompanyService') as ICompanyService;
  const SubscriptionService = $injector.get<SubscriptionService>('SubscriptionService');
  const UserSession = $injector.get('UserSession') as IUserSession;
  const $window = $injector.get('$window') as any;
  const $location = $injector.get('$location') as any;
  const { formatMessage } = useIntl();

  const plan = SubscriptionService.currentSubscription?.plan?.name.toLowerCase() || 'free';

  const classes = useStyles();

  const setFirstStep = (): ChurnReasonsDialogSteps => {
    switch (dialogOrigin) {
      case 'close-account':
        return ChurnReasonsDialogSteps.preface;
      case 'downgrade-plan':
        return ChurnReasonsDialogSteps.reasons;
      case 'inactive-account':
        return ChurnReasonsDialogSteps.reasons;
      default:
        throw new Error('Unhandled dialog origin');
    }
  };

  const [step, setStep] = useState<ChurnReasonsDialogSteps>(setFirstStep());
  const [selection, setSelection] = useState<{ reason: ChurnIssue | ChurnReason; index: number }>();
  const [feedback, setFeedback] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

  const randomize = () => Math.random() - 0.5;

  const issueSlugs = [
    'hard_setup_usage',
    'courier_no_show',
    'delivered_late',
    'lost_damaged',
    'technical_issue',
    'bad_experience_support',
  ].sort(randomize) as ChurnIssue[];

  const closeSlugs = [
    'not_many_orders',
    'rates_not_competitive',
    'encountered_issue',
    'platform_not_as_expected',
    'no_value',
  ].sort(randomize) as ChurnReason[];

  const downgradeSlugs = [
    'not_many_orders',
    'rates_not_competitive',
    'no_value',
    'encountered_issue',
    'too_expensive',
  ].sort(randomize) as ChurnReason[];

  const inactiveSlugs = [
    'not_many_orders',
    'rates_not_competitive',
    'encountered_issue',
    'platform_not_as_expected',
    'no_value',
  ].sort(randomize) as ChurnReason[];

  const issues = [...issueSlugs, 'other_issue'] as ChurnIssue[];
  const reasons: Record<DialogType, ChurnReason[]> = {
    'close-account': [...closeSlugs, 'other'],
    'downgrade-plan': [...downgradeSlugs, 'other'],
    'inactive-account': [...inactiveSlugs, 'other'],
  };

  useEffect(() => {
    if (!dialogOrigin) {
      throw new Error('A dialog origin must be provided.');
    }

    if (!open) return;

    switch (dialogOrigin) {
      case 'close-account':
        setStep(ChurnReasonsDialogSteps.preface);
        MixpanelService.track('Reasons for Leaving - Show');
        break;
      case 'downgrade-plan':
        setStep(ChurnReasonsDialogSteps.reasons);
        MixpanelService.track('Reasons for Downgrading - Show');
        break;
      case 'inactive-account':
        setStep(ChurnReasonsDialogSteps.reasons);
        MixpanelService.track('Reasons for Inactivity - Show');
        break;
      default:
    }
  }, [open]);

  const handleSelectOption = (newSelection: {
    reason: ChurnReason | ChurnIssue;
    index: number;
  }) => {
    if (newSelection.reason === 'encountered_issue') {
      setStep(ChurnReasonsDialogSteps.issues);
    } else {
      setSelection(newSelection);
      setStep(ChurnReasonsDialogSteps.tellUsMore);
    }
  };

  const handleTellUsMoreBack = () => {
    setStep(
      issues.includes(selection?.reason as ChurnIssue)
        ? ChurnReasonsDialogSteps.issues
        : ChurnReasonsDialogSteps.reasons
    );
  };

  const submitDowngradeToFreePlanReasons = (): void => {
    if (!selection) {
      MixpanelService.track('Reasons for Leaving - Cancel', { closed_at: step });
    }

    MixpanelService.track('Reasons for Downgrading - Submit', { closed_at: step });
    const subscriptionId = SubscriptionService?.currentSubscription?.id || '';

    CompanyService.submitChurnReasons(
      'Subscription',
      subscriptionId,
      selection?.reason,
      selection?.index,
      feedback
    ).then(() => {
      $window.location.href = `${$location.path()}?downgrade_plan=Free`;
    });
  };

  const submitInactivityReasons = (): void => {
    const companyId = UserSession.company.id;
    CompanyService.submitChurnReasons(
      'Company',
      companyId,
      selection?.reason,
      selection?.index,
      feedback
    ).then(() => {
      MixpanelService.track('Reasons for Inactivity - Submit');
      toastSuccess(formatMessage({ id: 'churn-reasons-flow.feedback-thanks' }));
      onClose();
    });
  };

  const handleTellUsMoreSubmit = () => {
    switch (dialogOrigin) {
      case 'close-account':
        setStep(ChurnReasonsDialogSteps.confirmation);
        break;

      case 'downgrade-plan':
        submitDowngradeToFreePlanReasons();
        break;

      case 'inactive-account':
        submitInactivityReasons();
        break;
      default:
    }
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
    setIsPasswordIncorrect(false);
  };

  const closeAccount = () => {
    MixpanelService.track('Reasons for Leaving - Submit');
    CompanyService.closeAccount(selection?.reason, selection?.index, feedback, password)
      .then((response: ICloseAccountResponse) => {
        if (response.state === 'under_review') {
          setStep(ChurnReasonsDialogSteps.underReview);
          UserSession.company.account_closure = { state: 'under_review' };
        } else {
          logOut();
        }
      })
      .catch((reason: unknown) => {
        if (transformToErrorCodes(reason).includes('password_incorrect')) {
          setIsPasswordIncorrect(true);
          setPassword('');
          toastError(
            formatMessage({
              id: 'churn-reasons-flow.close-account-confirmation.password-incorrect',
            })
          );
        }
      });
  };

  const closeFlow = () => {
    onClose();
    setStep(ChurnReasonsDialogSteps.preface);
    if (dialogOrigin === 'downgrade-plan') {
      submitDowngradeToFreePlanReasons();
    }

    if (dialogOrigin === 'inactive-account') {
      MixpanelService.track('Reasons for Inactivity - Cancel', { closed_at: step });
      submitInactivityReasons();
    }
  };

  const showBackToCloseAccountPrefaceButton = () => {
    return dialogOrigin === 'close-account' && step === ChurnReasonsDialogSteps.reasons;
  };

  const reasonsListSubheader = (): string => {
    const { currentSubscription } = SubscriptionService;

    switch (dialogOrigin) {
      case 'downgrade-plan':
        return formatMessage({
          id: currentSubscription?.next_invoice_at
            ? 'churn-reasons-flow.downgrade-plan.reasons-subheader'
            : 'churn-reasons-flow.downgrade-plan.reasons-subheader-nopay',
        });
      case 'inactive-account':
        return formatMessage({ id: 'churn-reasons-flow.inactive-account.reasons-subheader' });
      default:
        return formatMessage({ id: 'churn-reasons-flow.reasons.sub-header' });
    }
  };

  return dialogOrigin ? (
    <Dialog open={open} className={classes.root} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Typography variant="h2">
            <FormattedMessage id={`churn-reasons-flow.${step}.header`} />
          </Typography>
          <IconButton aria-label="close modal" onClick={closeFlow}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {step === ChurnReasonsDialogSteps.preface && <Preface plan={plan} />}
        {step === ChurnReasonsDialogSteps.reasons && (
          <ReasonsList
            reasons={reasons[dialogOrigin]}
            onSelect={handleSelectOption}
            subheader={reasonsListSubheader()}
            origin={dialogOrigin}
          />
        )}
        {step === ChurnReasonsDialogSteps.issues && (
          <IssuesList issues={issues} onSelect={handleSelectOption} />
        )}
        {step === ChurnReasonsDialogSteps.tellUsMore && <TellUsMore onChange={setFeedback} />}
        {step === ChurnReasonsDialogSteps.confirmation && (
          <Confirmation onChange={handlePasswordChange} hasError={isPasswordIncorrect} />
        )}
        {step === ChurnReasonsDialogSteps.underReview && <UnderReview />}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent={
            step === ChurnReasonsDialogSteps.underReview ? 'space-around' : 'space-between'
          }
        >
          {step === ChurnReasonsDialogSteps.preface && (
            <>
              <Button onClick={closeFlow}>
                <FormattedMessage id="global.cancel" />
              </Button>
              <Button
                onClick={() => setStep(ChurnReasonsDialogSteps.reasons)}
                color="error"
                variant="contained"
              >
                <FormattedMessage
                  id={`churn-reasons-flow.${ChurnReasonsDialogSteps.preface}.proceed`}
                />
              </Button>
            </>
          )}

          {showBackToCloseAccountPrefaceButton() && (
            <Button onClick={() => setStep(ChurnReasonsDialogSteps.preface)}>
              <FormattedMessage id="global.back" />
            </Button>
          )}

          {step === ChurnReasonsDialogSteps.issues && (
            <Button onClick={() => setStep(ChurnReasonsDialogSteps.reasons)}>
              <FormattedMessage id="global.back" />
            </Button>
          )}

          {step === ChurnReasonsDialogSteps.tellUsMore && (
            <>
              <Button onClick={handleTellUsMoreBack}>
                <FormattedMessage id="global.back" />
              </Button>
              <Button
                onClick={handleTellUsMoreSubmit}
                color="error"
                variant="contained"
                style={{ padding: '8px 25px' }}
              >
                <FormattedMessage id={`churn-reasons-flow.${dialogOrigin}.tell-us-more-next`} />
              </Button>
            </>
          )}

          {step === ChurnReasonsDialogSteps.confirmation && (
            <>
              <Button onClick={closeFlow}>
                <FormattedMessage
                  id={`churn-reasons-flow.${ChurnReasonsDialogSteps.confirmation}.left-button-text`}
                />
              </Button>
              <Button
                onClick={closeAccount}
                color="error"
                variant="contained"
                style={{ padding: '8px 15px' }}
                disabled={!password}
              >
                <FormattedMessage
                  id={`churn-reasons-flow.${ChurnReasonsDialogSteps.confirmation}.right-button-text`}
                />
              </Button>
            </>
          )}

          {step === ChurnReasonsDialogSteps.underReview && (
            <Button onClick={closeFlow} variant="contained" className={classes.gotItButton}>
              <FormattedMessage
                id={`churn-reasons-flow.${ChurnReasonsDialogSteps.underReview}.action-button`}
              />
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  ) : null;
}

export function WrappedChurnReasonsDialog(
  props: ChurnReasonsDialogProps
): ReactElement<ChurnReasonsDialogProps> {
  return (
    <ReactRootProviders>
      <ChurnReasonsDialog {...props} />
    </ReactRootProviders>
  );
}

export const AngularChurnReasonsDialog = react2angular(
  WrappedChurnReasonsDialog,
  ['open', 'onClose', 'dialogOrigin', 'logOut'],
  ['$injector']
);
