'use strict';
import template from './auth.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('home.auth', {
      abstract: true,
      template,
      controller: 'AuthCtrl',
    });
  },
]);
