import template from './address-form-sg.html?raw';

(function () {
  'use strict';

  var addressFormSg = {
    template,
    bindings: {
      address: '=',
      fieldValidators: '<',
    },
    controller: 'addressFormSgCtrl as ctrl',
  };

  angular.module('app.component.address-form-sg').component('addressFormSg', addressFormSg);
})();
