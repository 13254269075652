import angular from 'angular';

import { AdvancedSearchComponentApiNormalizeService } from './advanced-search-component-api-normalize.service';

const MODULE_NAME = 'app.global.services.advanced-search-component-api-normalize';

angular
  .module(MODULE_NAME, [])
  .service('AdvancedSearchComponentApiNormalizeService', AdvancedSearchComponentApiNormalizeService);

export default MODULE_NAME;
