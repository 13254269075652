(function () {
  'use strict';

  angular.module('easyshipDashboardApp').config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('home.auth.logout', {
        url: 'logout?return_to&sso',
        template: '<div></div>',
        controller: 'LogoutCtrl as vm',
      });
    },
  ]);
})();
