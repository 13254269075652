import { IUserSession } from 'typings/user-session';
import { IRequestFeatureResponse } from 'typings/request-feature';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import requestFeatureImageUrl from '@assets/images/dashboard/request-feature/request-feature.svg';
import style from './request-feature.module.scss';
import template from './request-feature.html?raw';
import { RequestFeatureService } from '../services/request-feature/request-feature.service';

class RequestFeatureController implements IComponentController {
  style = style;
  requestFeatureImageUrl = requestFeatureImageUrl;
  comment = '';
  showCommentBox = false;
  busy = false;
  esBorderLeft = false;
  esSectionName = '';
  wrappers = {
    a: (chunk: string) =>
      `<a href="https://support.easyship.com/hc/en-us" target="_blank" rel="noopener">${chunk}</a>`,
  };

  static $inject = ['UserSession', 'RequestFeatureService', 'MixpanelService', '$translate'];
  constructor(
    private UserSession: IUserSession,
    private RequestFeatureService: RequestFeatureService,
    private MixpanelService: MixpanelService,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit() {
    //
  }

  openCommentBox(): void {
    this.showCommentBox = true;
  }

  onCommentChange(value: string): void {
    this.comment = value;
  }

  close(): void {
    this.showCommentBox = false;
    this.MixpanelService.track('Request Feature Closed', { page: this.esSectionName });
  }

  handleOffClick(): void {
    this.showCommentBox && this.close();
  }

  submitRequest(): void {
    this.busy = true;

    this.RequestFeatureService.submitRequest(
      {
        userId: this.UserSession.user.id,
      },
      {
        page: this.esSectionName || '',
        comment: this.comment,
      }
    )
      .then(() => {
        toastSuccess(this.$translate.instant('request-feature.thanks'));
        this.MixpanelService.track('Request Feature', { page: this.esSectionName });
      })
      .catch((error: IRequestFeatureResponse) => {
        if (error.data.error) {
          toastError(error.data.error);
        }
      })
      .finally(() => {
        this.busy = false;
        this.comment = '';
        this.close();
      });
  }
}

const RequestFeatureComponent = {
  controller: RequestFeatureController,
  template,
  bindings: {
    esBorderLeft: '<',
    esSectionName: '<',
    esHideIcon: '<',
    esPlacement: '@',
  },
};

export { RequestFeatureComponent };
