import { Checkbox } from 'easyship-components';
import React, { forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { withScrollingTableElement } from '../providers/ScrollingTableProvider';
import ShipmentsTableRowCell from './ShipmentsTableRowCell';
import RoundedCorner from './RoundedCorner';

export interface ShipmentsTableRowProps {
  children: React.ReactNode;
  className?: string;
  isFirst: boolean;
  isLast: boolean;
  isOpened: boolean;
  index: number;
  isCheckboxChecked: boolean;
  onCheckboxChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isInScrollView?: boolean;
}

const ShipmentsTableRow = forwardRef(
  (
    {
      children,
      className,
      isFirst,
      isLast,
      isOpened,
      isCheckboxChecked,
      onCheckboxChanged,
      isInScrollView,
      index,
    }: ShipmentsTableRowProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const isShowInScroll = isInScrollView || index < 25;

    return (
      <div
        ref={ref}
        className={twMerge(
          'flex w-full bg-white h-[84px] pr-[10px] hover:bg-teal-100 group',
          className,
          isFirst && 'rounded-t',
          isLast && 'rounded-b',
          !isShowInScroll && 'animate-pulse'
        )}
      >
        {isShowInScroll && (
          <>
            <ShipmentsTableRowCell
              className={twMerge(
                'flex-grow-0 min-w-[42px] w-[42px]  cursor-pointer sticky left-0 z-[5] pl-[10px]  pr-8',
                'bg-white group-hover:bg-teal-100'
              )}
            >
              {isFirst && <RoundedCorner classNameInner="group-hover:bg-teal-100" />}
              {isLast && (
                <RoundedCorner position="bottom-left" classNameInner="group-hover:bg-teal-100" />
              )}
              {isOpened && <div className="bg-teal-500 absolute left-0 w-[8px] h-full" />}
              <Checkbox
                className={twJoin(
                  '!outline-none cursor-pointer hover:bg-transparent checked:before:bg-[#36a3ac] checked:before:border-none after:bg-white !m-0',
                  'h-[84px] before:top-[2.1rem] after:top-[2.1rem] hover:!bg-[#d2f1f2] rounded-none'
                )}
                checked={isCheckboxChecked}
                onChange={onCheckboxChanged}
              />
            </ShipmentsTableRowCell>
            {children}
          </>
        )}
      </div>
    );
  }
);

export default withScrollingTableElement(ShipmentsTableRow);
