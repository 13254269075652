import { IDeviceService } from 'typings/core/services/device';
import {
  EasyshipLeadStateName,
  FulfillmentLeadStateName,
  PlatformLeadStateName,
  PlatformAuthStateName,
  RegistrationFlowStateName,
  RegistrationFlowStep,
  StateParamsPlatform,
  InvalidStep,
} from '@client/auth/data/state';
import { Persona } from '@client/data/persona';

import easyshipLogoDark from '@assets/images/logos/easyship-logo/logo-dark.svg?svgo';
import easyshipLogoWhite from '@assets/images/logos/easyship-logo/logo-white.svg?svgo';
import { IComponentController } from 'angular';
import template from './signup.html?raw';
import style from './signup.module.scss';
import { RegistrationService } from '../../services/registration/registration.service';
import { StepsNavigationService } from '../../services/steps-navigation/steps-navigation.service';

// TODO: Get from env
const VIDEO_URL = 'https://assets.easyship.com/app/signup';

enum VideoFileName {
  Lead = 'Card 1. Sign up Flow - Lead',
  LeadKs = 'Card 1. Sign up Flow - Lead - Kickstarter',
  LeadIg = 'Card 1. Sign up Flow - Lead - Indiegogo',
  LeadDuda = 'Card 1. Sign up Flow - Lead - DUDA',
  LeadUberFreight = 'Card 1. Sign up Flow - Lead - Uber-Freight',
  YourInfo = 'Card 2. Sign up Flow - Your Info',
  Merchant = 'Card 3. Sign up Flow - Merchant',
  MerchantKs = 'Card 3. Sign up Flow - Merchant - Kickstarter',
  MerchantIg = 'Card 3. Sign up Flow - Merchant - Indiegogo',
  Crowdfunding = 'Card 4. Sign up Flow - Crowdfunding',
  CrowdfundingKs = 'Card 4. Sign up Flow - Crowdfunding - Kickstarter',
  CrowdfundingIg = 'Card 4. Sign up Flow - Crowdfunding - Indiegogo',
  Developer = 'Card 5. Sign up Flow - Developer',
  Address = 'Card 6. Sign up Flow - Address',
  Settings = 'Card 7. Sign up Flow - Settings',
}

class SignupLayout implements IComponentController {
  easyshipLogoDark: string;
  easyshipLogoWhite: string;
  style = style;
  stateParamsPlatform = StateParamsPlatform;
  EasyshipLeadStateName = EasyshipLeadStateName;
  PlatformLeadStateName = PlatformLeadStateName;

  static $inject = [
    '$sce',
    '$state',
    'DeviceService',
    'RegistrationService',
    'StepsNavigationService',
  ];
  constructor(
    $sce: ng.ISCEService,
    private $state: ng.ui.IStateService,
    private DeviceService: IDeviceService,
    private RegistrationService: RegistrationService,
    private StepsNavigationService: StepsNavigationService
  ) {
    this.easyshipLogoDark = $sce.trustAsHtml(easyshipLogoDark);
    this.easyshipLogoWhite = $sce.trustAsHtml(easyshipLogoWhite);
    if ($state.params.id) this.hideSpinner();
  }

  get platform(): StateParamsPlatform {
    return this.$state.params.platform;
  }

  get isKickstarter(): boolean {
    return this.platform === StateParamsPlatform.Kickstarter;
  }

  get isIndiegogo(): boolean {
    return this.platform === StateParamsPlatform.Indiegogo;
  }

  get isDuda(): boolean {
    return this.platform === StateParamsPlatform.Duda;
  }

  get isUberFreight(): boolean {
    return this.platform === StateParamsPlatform.UberFreight;
  }

  get isShopline(): boolean {
    return this.platform === StateParamsPlatform.Shopline;
  }

  get isLabelgo(): boolean {
    return this.platform === StateParamsPlatform.Labelgo;
  }

  get progressBarValue(): number {
    if (this.$state.current.name === `${RegistrationFlowStateName}.${InvalidStep.Country}`) {
      return 66;
    }

    const { currentStepNumber } = this.StepsNavigationService;
    const totalSteps = this.StepsNavigationService.accessibleSteps.length;

    return (currentStepNumber / totalSteps) * 100;
  }

  get videoUrl(): string {
    const currentStateName = this.$state.current.name;
    const { platform } = this;
    const { persona } = this.RegistrationService.data.user;
    let fileName = VideoFileName.Merchant;

    if (currentStateName) {
      if (currentStateName === `${RegistrationFlowStateName}.${RegistrationFlowStep.User}`) {
        switch (this.platform) {
          case StateParamsPlatform.Kickstarter:
            fileName = VideoFileName.MerchantKs;
            break;
          case StateParamsPlatform.Indiegogo:
            fileName = VideoFileName.MerchantIg;
            break;
          default:
            fileName = VideoFileName.YourInfo;
        }
      } else if (
        [EasyshipLeadStateName, PlatformLeadStateName, FulfillmentLeadStateName].includes(
          currentStateName
        ) ||
        currentStateName === `${PlatformAuthStateName}-${StateParamsPlatform.Website}`
      ) {
        switch (this.platform) {
          case StateParamsPlatform.Kickstarter:
            fileName = VideoFileName.LeadKs;
            break;
          case StateParamsPlatform.Indiegogo:
            fileName = VideoFileName.LeadIg;
            break;
          case StateParamsPlatform.Duda:
            fileName = VideoFileName.LeadDuda;
            break;
          case StateParamsPlatform.UberFreight:
            fileName = VideoFileName.LeadUberFreight;
            break;
          default:
            fileName = VideoFileName.Lead;
        }
      } else if (
        currentStateName === `${RegistrationFlowStateName}.${RegistrationFlowStep.Address}`
      ) {
        fileName = VideoFileName.Address;
      } else if (
        currentStateName === `${RegistrationFlowStateName}.${RegistrationFlowStep.Settings}`
      ) {
        fileName = VideoFileName.Settings;
      } else if (persona === Persona.Merchant || currentStateName === PlatformAuthStateName) {
        fileName = VideoFileName.Merchant;
      } else if (persona === Persona.Crowdfunding || platform === StateParamsPlatform.Fulfillment) {
        switch (this.platform) {
          case StateParamsPlatform.Kickstarter:
            fileName = VideoFileName.CrowdfundingKs;
            break;
          case StateParamsPlatform.Indiegogo:
            fileName = VideoFileName.CrowdfundingIg;
            break;
          default:
            fileName = VideoFileName.Crowdfunding;
        }
      } else if (persona === Persona.Developer) {
        fileName = VideoFileName.Developer;
      } else if (
        currentStateName === `${RegistrationFlowStateName}.${RegistrationFlowStep.Company}` &&
        this.isKickstarter
      ) {
        fileName = VideoFileName.CrowdfundingKs;
      } else if (
        currentStateName === `${RegistrationFlowStateName}.${RegistrationFlowStep.Company}` &&
        this.isIndiegogo
      ) {
        fileName = VideoFileName.CrowdfundingIg;
      }
    }

    return `${VIDEO_URL}/${fileName}`;
  }

  get isLeadPage(): boolean {
    return (
      this.$state.current.name === EasyshipLeadStateName ||
      this.$state.current.name === PlatformLeadStateName
    );
  }

  get showPlatformLogo(): boolean {
    if (!this.$state.current.name) {
      return false;
    }

    if (
      [StateParamsPlatform.Website, StateParamsPlatform.Fulfillment].includes(this.platform) ||
      (this.DeviceService.isMobile() && this.platform === StateParamsPlatform.BigCommerce)
    ) {
      return false;
    }

    if (
      this.platform === StateParamsPlatform.EcwidPrivate ||
      this.platform === StateParamsPlatform.Duda
    )
      return false;

    return (
      this.isKickstarter ||
      this.isIndiegogo ||
      this.isUberFreight ||
      this.isShopline ||
      this.isLabelgo ||
      (![EasyshipLeadStateName, FulfillmentLeadStateName].includes(this.$state.current.name) &&
        this.platform !== StateParamsPlatform.Dashboard)
    );
  }

  get isCourierConnect(): boolean {
    return [
      StateParamsPlatform.SkyPostal,
      StateParamsPlatform.ParcelCast,
      StateParamsPlatform.PostageForce,
      StateParamsPlatform.Packifi,
    ].includes(this.platform);
  }

  get isInvalidCountryStep(): boolean {
    return this.$state.current.name === `${RegistrationFlowStateName}.${InvalidStep.Country}`;
  }

  get isMobile(): boolean {
    return this.DeviceService.isMobile();
  }

  goToPreviousStep(): void {
    if (this.isInvalidCountryStep) {
      this.StepsNavigationService.backToUserForm();
    } else {
      this.StepsNavigationService.back();
    }
  }

  hideSpinner() {
    const spinnerElement = document.getElementById('app__initial-load');
    if (spinnerElement) spinnerElement.style.display = 'none';
  }
}

const SignupLayoutComponent = {
  controller: SignupLayout,
  template,
};

export { SignupLayoutComponent };
