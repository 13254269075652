import React, { createContext, useContext, useMemo } from 'react';
import { ShippingCountryPort } from '@client/core/corelogic/ports/shippingCountry';
import { NgAuthGateway } from '../gateways/api/NgAuthGateway';
import { AuthPort } from '../ports/auth';
import { FakeAuthGateway } from '../gateways/fake/FakeAuthGateway';
import NgShippingCountryGateway from '../gateways/api/NgShippingCountryGateway';
import FakeShippingCountryGateway from '../gateways/fake/FakeShippingCountryGateway';

export interface AuthGatewayContextData {
  authGateway: AuthPort;
  shippingCountryGateway: ShippingCountryPort;
}

const AuthGatewayContext = createContext<AuthGatewayContextData | undefined>(undefined);

interface AuthGatewayProviderProps {
  children: React.ReactNode;
}

function AuthGatewayProvider({ children }: AuthGatewayProviderProps) {
  const data: AuthGatewayContextData = useMemo(
    () => ({
      authGateway: new NgAuthGateway(),
      shippingCountryGateway: new NgShippingCountryGateway(),
    }),
    []
  );

  return <AuthGatewayContext.Provider value={data}>{children}</AuthGatewayContext.Provider>;
}

interface FakeAuthGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<AuthGatewayContextData>;
}

function FakeAuthGatewayProvider({ children, value }: FakeAuthGatewayProviderProps) {
  const data: AuthGatewayContextData = useMemo(
    () => ({
      authGateway: new FakeAuthGateway(),
      shippingCountryGateway: new FakeShippingCountryGateway(),
      ...value,
    }),
    [value]
  );

  return <AuthGatewayContext.Provider value={data}>{children}</AuthGatewayContext.Provider>;
}

function useAuthGateway() {
  const authGatewayContext = useContext(AuthGatewayContext);

  if (authGatewayContext === undefined) {
    throw new Error('useAuthGateway must be used within a AuthGatewayProvider');
  }
  return authGatewayContext;
}

export { AuthGatewayProvider, FakeAuthGatewayProvider, useAuthGateway };
