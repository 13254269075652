import { ITagsParams, ITagsPayload, ITagsResponse } from 'typings/tags';
import { IApiConfig } from 'typings/core/config/api';

class TagsResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor($resource: any, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/companies/:company_id/tags/:controller`,
      {},
      {
        create: { method: 'POST' },
      }
    );
  }

  getTags(params: ITagsParams): ng.IPromise<ITagsResponse> {
    return this.resource.get(params).$promise;
  }

  addTag(params: ITagsParams, payload: ITagsPayload): ng.IPromise<void> {
    return this.resource.create(params, payload).$promise;
  }
}

export { TagsResource };
