import {
  IShipmentListLogsPriceCalculation,
  IShipmentListLogsPriceCalculationItem,
} from 'typings/shipment';

export function isIShipmentListLogsPriceCalculationItem(
  priceCalculationLogs: IShipmentListLogsPriceCalculation
): priceCalculationLogs is IShipmentListLogsPriceCalculationItem {
  return !Array.isArray(priceCalculationLogs);
}
