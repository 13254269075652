import angular from 'angular';

import { SkuNameSelectGroupComponent } from './sku-name-select-group.component';

const MODULE_NAME = 'app.global.dashboard-component-library.sku-name-select-group';

angular
  .module(MODULE_NAME, [])
  .component('esSkuNameSelectGroup', SkuNameSelectGroupComponent);

export default MODULE_NAME;
