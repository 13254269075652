import angular from 'angular';

import { EditProductsModalSpreadsheetComponent } from './edit-products-modal-spreadsheet.component';

const MODULE_NAME = 'app.product-listing.edit-products-modal.spreadsheet';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingEditProductsModalSpreadsheet', EditProductsModalSpreadsheetComponent);

export default MODULE_NAME;
