import { IShipmentListItem, IShipmentListLogsPriceCalculationItem } from '@typings/shipment';
import useCountriesQuery from '@client/src/global/hooks/useCountriesQuery';

export const useShipmentReceiverAlert = (shipment: IShipmentListItem) => {
  const { data: countries = {}, isLoading: isLoadingCountries } = useCountriesQuery();

  const isMissingSuggestedTaxId = (shipment: IShipmentListItem): boolean => {
    if (
      isLoadingCountries ||
      !shipment.destination_country_id ||
      !shipment.courier?.umbrella_name
    ) {
      return false;
    }
    const receiverCountry = countries[shipment.destination_country_id];
    if (!receiverCountry) return false;

    const isTaxIdSuggested =
      receiverCountry.requirements.consignee_tax_id_suggested_umbrella_names.includes(
        shipment.courier.umbrella_name
      );

    return isTaxIdSuggested && !shipment.consignee_tax_id;
  };

  const {
    identified_as_residential: identifiedAsResidential,
    set_as_residential: setAsResidential,
  } = shipment;

  const isResidentialAddressMismatch =
    typeof identifiedAsResidential !== 'boolean'
      ? false
      : setAsResidential !== identifiedAsResidential;

  const isRecommendedInputIssue = isMissingSuggestedTaxId(shipment) || isResidentialAddressMismatch;

  const errorLogs = shipment.logs.price_calculation;

  const isAddressInvalid =
    !shipment?.is_valid_address || !!(errorLogs as IShipmentListLogsPriceCalculationItem).address;

  return {
    hasError: isAddressInvalid,
    hasWarning: isRecommendedInputIssue,
  };
};
