import angular from 'angular';

import { DocumentQueueComponent } from './document-queue.component';

const MODULE_NAME = 'app.printing-options.document-queue';

angular
  .module(MODULE_NAME, ['app.global.services.printing-options'])
  .component('esPrintingOptionsDocumentQueue', DocumentQueueComponent);

export default MODULE_NAME;
