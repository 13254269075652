import {
  IShowAddPaymentModal,
  IShowDeletePaymentModal,
  IShowPaymentMethodModal,
} from 'typings/payment-method';
import { ICreditCardData } from 'typings/payment-source';
import { IUserSession } from 'typings/user-session';
import { IShowRefundCreditsModal } from 'typings/refund-credits';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { AutoRechargeService } from '@client/src/global/services/auto-recharge/auto-recharge.service';
import { ContactSalesModal } from '@client/src/global/contact-sales-modal/contact-sales-modal.service';
import style from './payment.module.scss';
import template from './payment.html?raw';

class Payment implements IComponentController {
  style = style;
  selectedCardId: string | null = null;
  busyFetching = false;
  showAddPaymentModal: IShowAddPaymentModal = {
    addCard: false,
  };
  showDeletePaymentModal: IShowDeletePaymentModal = {
    deleteCard: false,
    confirmDeleteCard: false,
  };
  showPaymentMethodModal: IShowPaymentMethodModal = {
    creditCard: false,
    choosePayment: false,
    paypal: false,
    bankTransfer: false,
  };
  showRefundCreditsModal: IShowRefundCreditsModal = {
    confirmRefund: false,
    refundStatus: false,
  };
  isAddressModalOpen = false;
  isAutoRechargeModalOpen = false;
  saveAddressSuccess = () => {};

  static $inject = [
    'PaymentSourceService',
    'MixpanelService',
    'UserSession',
    'ContactSalesModal',
    'AutoRechargeService',
  ];
  constructor(
    private PaymentSourceService: PaymentSourceService,
    private MixpanelService: MixpanelService,
    private UserSession: IUserSession,
    private ContactSalesModal: ContactSalesModal,
    private AutoRechargeService: AutoRechargeService
  ) {
    this.closeAddressModal = this.closeAddressModal.bind(this);
  }

  $onInit() {
    this.busyFetching = true;
    this.PaymentSourceService.getCreditCards()
      .then(({ isAchEnabled }) => {
        if (!isAchEnabled) {
          this.AutoRechargeService.retrieveAutoRechargeData();
        }
      })
      .finally(() => {
        this.busyFetching = false;
      });
  }

  get creditCards(): ICreditCardData[] {
    return this.PaymentSourceService.creditCards;
  }

  get hasMissingPaymentIntentFlow(): boolean {
    return (
      this.creditCards.filter((card) => {
        return !card.payment_intent_flow;
      }).length > 0
    );
  }

  get isUSCompany(): boolean {
    return this.UserSession.isUSCompany();
  }

  addNewPayment(): void {
    if (!this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
      this.saveAddressSuccess = () => {
        this.isAddressModalOpen = false;
        this.addNewPayment();
      };
      return;
    }

    this.showAddPaymentModal.addCard = true;
  }

  deletePayment(card: ICreditCardData): void {
    this.selectedCardId = card.source_id;
    this.showDeletePaymentModal.deleteCard = true;
  }

  addCredits(): void {
    if (!this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
      this.saveAddressSuccess = () => {
        this.isAddressModalOpen = false;
        this.addCredits();
      };
      return;
    }

    this.showPaymentMethodModal.choosePayment = true;
  }

  refundCredits(): void {
    this.showRefundCreditsModal.confirmRefund = true;

    this.MixpanelService.track('Refund - Payment Methods - Click Refund Credits');
  }

  closePaymentModal(): void {
    this.selectedCardId = null;
  }

  editAutoRecharge(): void {
    this.isAutoRechargeModalOpen = true;
  }

  closeAutoRecharge(): void {
    this.isAutoRechargeModalOpen = false;
  }

  saveAutoRecharge(): void {
    this.isAutoRechargeModalOpen = false;
  }

  closeAddressModal() {
    this.isAddressModalOpen = false;
    this.saveAddressSuccess = () => {};
  }

  openContactAchModal() {
    this.ContactSalesModal.open('ACH');
  }
}

const PaymentComponent: ng.IComponentOptions = {
  controller: Payment,
  template,
  bindings: {},
};

export { PaymentComponent };
