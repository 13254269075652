import { CurrentSubscription } from '@client/core/corelogic/ports/subscription.interface';
import { FeatureKey, FlexibleSubscriptionPlanId } from '@client/data/subscription';
import { IUser } from '@client/src/global/context/UserSessionProvider';

export function isFeatureMaxLimitReached(
  currentSubscription: CurrentSubscription | undefined,
  featureKey: FeatureKey
): boolean {
  const subscriptionFeature = currentSubscription?.allFeatures[featureKey];
  if (!subscriptionFeature) return false;

  const { current_count: currentCount, limit } = subscriptionFeature;

  if (limit === undefined || currentCount === undefined || limit === -1) return false;

  return currentCount >= limit;
}

export function isSubscriptionAvailable({
  currentUser,
  currentSubscription,
}: {
  currentUser: IUser | undefined;
  currentSubscription: CurrentSubscription | undefined;
}): boolean {
  return !!(
    currentUser?.shippingCountry?.features?.subscription?.enabled &&
    currentSubscription?.id &&
    currentSubscription?.plan?.id !== FlexibleSubscriptionPlanId.Gold
  );
}
