import { useMutation } from '@tanstack/react-query';
import { SendCourierAccountTokenPayload } from '../../models/payloads/ups-oauth-payload';
import { useUpsOauthGateway } from '../../providers/UpsOauthGatewayProvider';

interface SendCourierAccountTokenMutationParams {
  companyId: string;
  courierAccountId: string;
  payload: SendCourierAccountTokenPayload;
}

export default function useSendCourierAccountTokenMutation() {
  const { upsOauthGateway } = useUpsOauthGateway();

  return useMutation({
    mutationKey: ['tokenMutation'],
    mutationFn: ({ companyId, courierAccountId, payload }: SendCourierAccountTokenMutationParams) =>
      upsOauthGateway.sendCourierAccountToken(payload, companyId, courierAccountId),
  });
}
