import React, { memo } from 'react';
import { IShipmentListLogsPriceCalculationItem } from 'typings/shipment';
import AlertIcon from '@client/src/create-shipments/shipment-details-drawer/ShipmentDetailsDrawerToolbar/Tabs/components/TabLabelTitle/AlertIcon';
import { FormattedMessage } from 'react-intl';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import TruncateText from '@client/core/components/react/TruncateText/TruncateText';
import CellLayout from './CellLayout';
import { CellProps, ShipmentState } from './d';
import ShipmentCellSkeleton from './ShipmentCellSkeleton';

export const PackageCell = memo(({ row }: CellProps) => {
  const UserSession = useUserSession();

  const shipment = row.original;
  const counts = shipment.parcels_count;
  const boxNames = shipment.parcels_package_names?.join('') || 'Custom Box';
  const missingDimension = shipment.display_missing_dimensions_warning;
  const weight = `${shipment.total_actual_weight} ${UserSession.company.weightUnit}`;
  const error = shipment.logs.price_calculation as IShipmentListLogsPriceCalculationItem;
  const { parcels } = error || {};
  const hasWeightError =
    parcels && Object.values(parcels).some((parcel) => !!parcel.total_actual_weight);
  const isRecalculating = shipment.shipment_state === ShipmentState.Calculating;

  return (
    <CellLayout>
      <div className="flex flex-col min-w-[158px] items-start justify-center px-2 gap-1">
        {isRecalculating ? (
          <ShipmentCellSkeleton />
        ) : (
          <>
            {counts > 1 ? (
              <span className="text-ink-900">
                <FormattedMessage id="global.pluralize.with-count.box" values={{ count: counts }} />
              </span>
            ) : (
              boxNames &&
              !missingDimension && (
                <div className="flex items-center gap-1 max-w-full">
                  <TruncateText
                    label={boxNames}
                    className="whitespace-normal line-clamp-2 text-ink-900"
                  />
                </div>
              )
            )}
            {missingDimension && (
              <div className="flex items-center gap-1 leading-5">
                <AlertIcon severity="warning" />
                <span className="text-yellow-900 text-[12px]">
                  <FormattedMessage id="global.no-dimension" />
                </span>
              </div>
            )}
            {hasWeightError ? (
              <div className="flex items-center gap-1 leading-5 ">
                <AlertIcon severity="error" />
                <span className="text-red-700 text-[12px]">
                  <FormattedMessage id="global.invalid-weight" />
                </span>
              </div>
            ) : (
              <div className="text-ink-900 text-[13px] font-body font-normal">{weight}</div>
            )}
          </>
        )}
      </div>
    </CellLayout>
  );
});
