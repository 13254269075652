import { IShipmentParcelAttributes } from 'typings/shipment';

import { IComponentController } from 'angular';
import template from './move-item.html?raw';
import style from './move-item.module.scss';

interface Box {
  parcelIndex: number;
  label: string;
}

class MoveItem implements IComponentController {
  style = style;
  esParcels: IShipmentParcelAttributes[] | undefined = undefined;
  esSelectedParcelIndex: number | undefined = undefined;
  esSelectedItemIndex: number | undefined = undefined;
  esOnConfirm(value: any): void {}
  boxes: Box[] = [];
  selectedBoxes: number | null = null;
  quantity: number | null = null;
  maxQuantity: number | null = null;
  itemCount: number | null = null;

  static $inject = ['$element', '$timeout'];
  constructor(private $element: ng.IRootElementService, private $timeout: ng.ITimeoutService) {}

  $onChanges() {
    if (!this.esParcels) return;

    this.$timeout(() => {
      this.$element.find('input').trigger('focus');
    });

    const { boxes } = this.esParcels.reduce(
      (accumulator, parcel, index) => {
        if (parcel._destroy) return accumulator;

        accumulator.boxCount += 1;

        if (index !== this.esSelectedParcelIndex) {
          accumulator.boxes.push({
            label: `Box ${accumulator.boxCount}`,
            parcelIndex: index,
          });
        }

        return accumulator;
      },
      {
        boxCount: 0,
        boxes: [] as Box[],
      }
    );

    this.boxes = boxes;
    this.selectedBoxes = this.boxes[0].parcelIndex;

    const items = this.esParcels[this.esSelectedParcelIndex as number].shipment_items_attributes;

    this.quantity = 1;
    this.itemCount = items[this.esSelectedItemIndex as number].quantity;
    this.maxQuantity =
      items.length === 1
        ? items[this.esSelectedItemIndex as number].quantity - 1
        : items[this.esSelectedItemIndex as number].quantity;
  }

  onFieldChange(value: number, field: string) {
    switch (field) {
      case 'quantity':
        this.quantity = Number(value);
        break;

      case 'selectedBoxes':
        this.selectedBoxes = value;
        break;
    }
  }

  onSelectedBoxesChange(value: number) {
    this.selectedBoxes = value;
  }

  confirm(): void {
    if (
      !this.quantity ||
      !this.maxQuantity ||
      this.quantity <= 0 ||
      this.quantity > this.maxQuantity
    )
      return;

    this.esOnConfirm({
      quantity: this.quantity,
      parcelIndex: this.selectedBoxes,
    });
  }
}

const MoveItemComponent: ng.IComponentOptions = {
  controller: MoveItem,
  template,
  bindings: {
    esParcels: '<',
    esSelectedParcelIndex: '<',
    esSelectedItemIndex: '<',
    esOnConfirm: '&',
    esOnCancel: '&',
  },
};

export { MoveItemComponent };
