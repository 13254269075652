(function () {
  'use strict';

  function incartInstallationModalCtrl(ItemCategoryService, StoreService, params) {
    var vm = this;

    vm.ItemCategoryService = ItemCategoryService;
    vm.StoreService = StoreService;
    vm.busy = {};
    vm.params = params || {};
    vm.defaultDimensions = {};

    (function init() {
      vm.busy.page = false;
      vm.showInstallFailedMessage = true;
    })();
  }

  incartInstallationModalCtrl.$inject = ['ItemCategoryService', 'StoreService', 'params'];

  angular
    .module('app.service.incart-installation-modal')
    .controller('incartInstallationModalCtrl', incartInstallationModalCtrl);
})();
