import { Id, toast } from 'react-toastify';
import { IUserSession } from 'typings/user-session';
import { ICompanyService } from 'typings/company';
import { ICountryService, ICountryCurrencyList } from 'typings/auth/services/country';
import { IStoreService } from 'typings/store';
import { InsuranceClaimPaymentMethod, InsuranceValueCalculationMethod } from 'typings/insurance';

import { EMAIL_REGEX } from '@client/src/data/regex';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { showToastWithAction } from '@client/core/components/react/ToastWithAction';
import style from './insurance.module.scss';
import template from './insurance.html?raw';

enum InsuranceOptions {
  SellingPrice = 'total_customs_value',
  // CostPrice = 'shipment_cost_price',
  FixedValue = 'shipment_fixed_value',
  PercentageOfSellingPrice = 'total_customs_value_percentage',
}

enum InsuranceSettingsKeys {
  Option = 'insurance_current_option',
  Currency = 'insurance_currency',
  FixedValue = 'insurance_shipment_fixed_value',
  PricePercentage = 'insurance_total_customs_value_percentage',
  IncludeShippingInInsurance = 'insurance_include_shipping',
}

interface IInsuranceSettings {
  insurance_current_option: InsuranceValueCalculationMethod;
  insurance_shipment_fixed_value: number;
  insurance_currency: string; // ISO 4217
  insurance_total_customs_value_percentage: number;
  insurance_include_shipping: boolean;
  recalculate_shipments: boolean;
  payment_method: InsuranceClaimPaymentMethod;
  paypal_account_holder: string;
  paypal_email: string;
  bank_details: string;
}

class Insurance implements IComponentController {
  style = style;
  disableSave = false;
  busy = false;
  insuranceOptions = InsuranceOptions;
  insuranceSettingsKeys = InsuranceSettingsKeys;
  percentageOfSellingPrice = 0;
  percentageOfSellingPriceInputSettings = {
    maxDecimals: 0,
    allowNegative: false,
  };
  fixedPriceAmountInputSettings = {
    maxDecimals: 2,
    allowNegative: false,
  };
  warnings: { fullPrice?: boolean; aboveValue?: boolean } = {};
  currencies: ICountryCurrencyList[] = [];
  recalculateShipments = true;
  storesWithInsuranceAtCheckout: string[] = [];
  translations: Record<string, string> = {};
  form: IInsuranceSettings = {
    insurance_current_option: 'total_customs_value',
    insurance_shipment_fixed_value: 0,
    insurance_currency: 'USD',
    insurance_total_customs_value_percentage: 0,
    insurance_include_shipping: true,
    recalculate_shipments: true,
    payment_method: 'easyship_balance',
    paypal_account_holder: '',
    paypal_email: '',
    bank_details: '',
  };

  exceedMaxInsuredToastId: Id | undefined;

  static $inject = [
    '$translate',
    'UserSession',
    'CompanyService',
    'StoreService',
    'CountryService',
    'MixpanelService',
    '$state',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private CompanyService: ICompanyService,
    private StoreService: IStoreService,
    private CountryService: ICountryService,
    private MixpanelService: MixpanelService,
    private $state: ng.ui.IStateService
  ) {}

  $onInit(): void {
    const {
      claim_payment_method,
      currency,
      insurance_current_option,
      insurance_shipment_fixed_value,
      insurance_currency,
      insurance_total_customs_value_percentage,
      insurance_include_shipping,
    } = this.UserSession.company;
    this.form = {
      ...this.form,
      insurance_current_option,
      insurance_shipment_fixed_value: insurance_shipment_fixed_value || 0,
      insurance_currency: insurance_currency || currency || 'USD',
      insurance_total_customs_value_percentage: insurance_total_customs_value_percentage
        ? insurance_total_customs_value_percentage * 100
        : 0,
      insurance_include_shipping,
      payment_method: claim_payment_method.payment_method,
      paypal_account_holder:
        claim_payment_method.payment_method === 'paypal'
          ? claim_payment_method.payment_details.paypal_account_holder
          : '',
      paypal_email:
        claim_payment_method.payment_method === 'paypal'
          ? claim_payment_method.payment_details.paypal_email
          : '',
      bank_details:
        claim_payment_method.payment_method === 'bank_account'
          ? claim_payment_method.payment_details.bank_details
          : '',
    };

    this.initializeCurrencyList();

    this.StoreService.getStores().then(({ stores }) => {
      this.storesWithInsuranceAtCheckout = stores
        .filter(({ insurance_at_checkout_state, name }) => insurance_at_checkout_state === 'active')
        .map(({ name, platform }) => name || platform?.name || 'unknown');
    });

    this.$translate(['global.setting']).then((translations) => {
      this.translations = translations;
    });

    this.MixpanelService.track('Insurance - Open');
  }

  selectValueCalculationMethod(insurance_current_option: InsuranceValueCalculationMethod) {
    this.form.insurance_current_option = insurance_current_option;
    this.MixpanelService.track('Insurance - Method Change', {
      insuranceSetting: 'insurance_current_option',
      value: insurance_current_option,
    });
  }

  toggleShippingCostInclusion(value: boolean) {
    this.form.insurance_include_shipping = value;
    this.MixpanelService.track('Insurance - Change Shipping', { shippingSelected: value });
  }

  toggleRecalculateShipments(value: boolean) {
    this.form.recalculate_shipments = value;
    this.MixpanelService.track('Insurance - Select Recalculate', { selection: value });
  }

  selectClaimPaymentMethod(payment_method: InsuranceClaimPaymentMethod) {
    this.form.payment_method = payment_method;
    this.MixpanelService.track('Insurance - Claim Payment Method Change', { payment_method });
  }

  setInsuranceSettings(value: string, key: keyof IInsuranceSettings) {
    (this.form[key] as string) = value;
    this.disableSave = this.determineAdditionalDetailsNeeded();

    this.MixpanelService.track('Insurance - Setting Change', { [key]: value });
  }

  update() {
    switch (this.form.insurance_current_option) {
      case 'total_customs_value_percentage':
        if (!this.percentageOfSalesPriceIsValid()) return;
        break;

      case 'shipment_fixed_value':
        if (!this.fixedValueIsValid()) return;
        break;
    }

    if (this.missingClaimPaymentMethodDetails) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.busy = true;

    if (this.form.payment_method !== 'bank_account') {
      this.form.bank_details = '';
    }

    if (this.form.payment_method !== 'paypal') {
      this.form.paypal_account_holder = '';
      this.form.paypal_email = '';
    }

    const { payment_method, paypal_account_holder, paypal_email, bank_details, ...rest } =
      this.form;

    this.CompanyService.updateCompany(
      {
        claim_payment_method_attributes: {
          payment_method,
          payment_details: {
            paypal_account_holder,
            paypal_email,
            bank_details,
          },
        },
        ...rest,
      },
      this.form.recalculate_shipments
    )
      .then(() => {
        this.dismissExceedMaxInsuredToast();
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            {
              noun: this.translations['global.setting'].toLowerCase(),
            },
            'messageformat'
          )
        );

        this.MixpanelService.track('Insurance - Save', {
          recalculation: this.form.recalculate_shipments,
          shipping: this.form.insurance_include_shipping,
          calculationMethod: this.form.insurance_current_option,
        });
      })
      .catch((error) => {
        if (error.data?.status === 'exceed_max_insured') {
          this.exceedMaxInsuredToastId = showToastWithAction(
            this.$translate.instant('settings.insurance.error-exceed-max-insured'),
            {
              type: 'error',
              toastId: this.exceedMaxInsuredToastId,
              actionLabel: this.$translate.instant('global.learn-more').toUpperCase(),
              onActionLabelClick: () => {
                window.open(
                  'https://www.easyship.com/legal/insurance-terms-and-conditions',
                  '_blank',
                  'noopener'
                );
              },
            }
          );
        } else {
          toastError(
            this.$translate.instant('toast.update-error', {
              noun: this.translations['global.setting'].toLowerCase(),
              details: error.data?.status,
            })
          );
        }
      })
      .finally(() => {
        this.busy = false;
      });
  }

  shippingRulesClick(): void {
    this.$state.go('automations');
    this.MixpanelService.track('Insurance - Click Shipping Rules');
  }

  learnMoreInsuranceClick(): void {
    this.MixpanelService.track('Insurance - Learn More');
  }

  get incompleteCoverage(): boolean {
    return (
      [this.insuranceOptions.FixedValue, this.insuranceOptions.PercentageOfSellingPrice].includes(
        this.form.insurance_current_option as InsuranceOptions
      ) || this.form.insurance_include_shipping === false
    );
  }

  get hasShippingRulesUserRole(): boolean {
    return this.UserSession.hasUserRole('shipping_rules');
  }

  private determineAdditionalDetailsNeeded(): boolean {
    const {
      insurance_current_option: option,
      insurance_total_customs_value_percentage: pricePercentage,
      insurance_shipment_fixed_value: fixedPrice,
    } = this.form;

    return (
      (option === this.insuranceOptions.PercentageOfSellingPrice && !pricePercentage) ||
      (option === this.insuranceOptions.FixedValue && !fixedPrice)
    );
  }

  private fixedValueIsValid(): boolean {
    const fixedValue = this.form.insurance_shipment_fixed_value;

    if (fixedValue === null || fixedValue < 0.01) {
      return false;
    }
    if (typeof fixedValue === 'string') {
      this.form.insurance_shipment_fixed_value = parseFloat(fixedValue);
    }
    return true;
  }

  private convertPercentageToDecimal(value: number): number {
    return parseFloat((value / 100).toFixed(2));
  }

  private percentageOfSalesPriceIsValid(): boolean {
    this.warnings = {};
    const pricePercentage = Number(this.form.insurance_total_customs_value_percentage);

    if (pricePercentage === null) return false;
    if (pricePercentage < 1 && pricePercentage > 0) return true;

    if (pricePercentage > 0 && pricePercentage <= 99) {
      this.form.insurance_total_customs_value_percentage =
        this.convertPercentageToDecimal(pricePercentage);
      return true;
    }
    if (pricePercentage === 100) {
      this.warnings.fullPrice = true;
    } else if (pricePercentage > 100) {
      this.warnings.aboveValue = true;
    }

    return false;
  }

  get missingClaimPaymentMethodDetails(): boolean {
    const missingPaypalDetails =
      this.form.payment_method === 'paypal' &&
      (this.form.paypal_account_holder === '' ||
        this.form.paypal_email === '' ||
        !EMAIL_REGEX.test(this.form.paypal_email));
    const missingBankAccountDetails =
      this.form.payment_method === 'bank_account' && this.form.bank_details === '';
    return missingPaypalDetails || missingBankAccountDetails;
  }

  private async initializeCurrencyList(): Promise<void> {
    await this.CountryService.initCountries();
    this.currencies = this.CountryService.currencies;
  }

  private dismissExceedMaxInsuredToast() {
    if (this.exceedMaxInsuredToastId && toast.isActive(this.exceedMaxInsuredToastId)) {
      toast.dismiss(this.exceedMaxInsuredToastId);
      this.exceedMaxInsuredToastId = undefined;
    }
  }
}

const InsuranceComponent = {
  template,
  controller: Insurance,
};

export { InsuranceComponent };
