import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import helpGuideContactSupport from './help-contact-support';
import helpGuideTopic from './help-guide-topic';
import helpGuideSection from './help-guide-section';

import { HelpGuideRoutes } from './index.routes';
import { HelpGuideComponent } from './help-guide.component';

const MODULE_NAME = 'app.help-guide';

angular
  .module(MODULE_NAME, [uiRouter, helpGuideContactSupport, helpGuideTopic, helpGuideSection])
  .config(HelpGuideRoutes)
  .component('helpGuide', HelpGuideComponent);

export default MODULE_NAME;
