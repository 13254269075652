import { API } from '@client/core/config/api.constant';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { makeStyles } from '@material-ui/core/styles';

import CardActions from '@material-ui/core/CardActions';
import React, { ReactElement } from 'react';

import CtaButton from './CtaButton';

import { HelpContactSupportFeature } from '../types';
import UpgradeButton from './UpgradeButton';

const useStyles = makeStyles(() => ({
  cardActions: {
    padding: '0 39px 30px',
  },
  cardActionsNoButton: {
    padding: '2.5px 39px',
  },
}));

interface FooterProps {
  feature: HelpContactSupportFeature;
  isAccessible: boolean;
  onChatClick: () => void;
  onUpgradeClick: () => void;
}

export function Footer({
  feature,
  isAccessible,
  onChatClick,
  onUpgradeClick,
}: FooterProps): ReactElement<FooterProps> {
  const classes = useStyles();
  const { user, company } = useUserSession();

  const handleEmailClick = () => {
    window.open(buildFormCraftUrl(), '_blank', 'noopener');
    MixpanelService.track('Help Guide - Contact Support - Email');
  };

  function buildFormCraftUrl() {
    // field24 = email
    // field107 = easyshup id company
    return `${API.help}/hc/en-us/requests/new?field24=${encodeURIComponent(user.email)}&field107=${
      company.easyshipCompanyId
    }`;
  }

  return (
    <CardActions
      className={
        feature === 'call' && isAccessible ? classes.cardActionsNoButton : classes.cardActions
      }
    >
      {feature === 'email' && <CtaButton onClick={handleEmailClick} feature={feature} />}

      {feature === 'chat' &&
        (isAccessible ? (
          <CtaButton onClick={onChatClick} feature={feature} />
        ) : (
          <UpgradeButton onUpgradeClick={onUpgradeClick} feature={feature} />
        ))}

      {feature === 'call' && !isAccessible && (
        <UpgradeButton onUpgradeClick={onUpgradeClick} feature={feature} />
      )}
    </CardActions>
  );
}
