import { useQuery } from '@tanstack/react-query';
import { ICountry } from '@typings/auth/services/country';
import { useGateways } from '@client/src/global/context/GatewayProvider';

export interface UseCountryQueryResult {
  [key: string]: ICountry;
}

export default function useCountriesQuery() {
  const { countryGateway } = useGateways();

  return useQuery({
    queryKey: ['get_country_service_countries_by_id'],
    queryFn: async (): Promise<UseCountryQueryResult> => {
      const result = await countryGateway.getCountries();
      return result.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {} as UseCountryQueryResult);
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
