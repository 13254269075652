import { toastSuccess } from '@client/core/components/react/Toastify';
import template from './report-shipment.html?raw';

(function () {
  const fixShipmentModalBodyReportShipment = {
    template,
    bindings: {
      globalBusy: '<',
      shipmentData: '<',
    },
    controller: 'fixShipmentModalBodyReportShipmentCtrl as ctrl',
  };

  function fixShipmentModalBodyReportShipmentCtrl(
    $state,
    $translate,
    FixMonkeyService,
    ShipmentList,
    fixShipmentModal
  ) {
    const ctrl = this;

    ctrl.error = '';

    ctrl.$onInit = function () {
      ctrl.subject = `Shipment error: ${ctrl.shipmentData.easyship_shipment_id}`;
    };

    /**
     * [back] Returns to edit shipment step. Disabled when form is loading.
     * @return {N/A}
     */
    ctrl.back = function () {
      if (ctrl.globalBusy) return;
      FixMonkeyService.goToEditShipmentStep();
    };

    /**
     * [reportShipment] Builds a message object and passes it to backend.
     * @return {N/A}
     */
    ctrl.reportShipment = function () {
      if (!isFormValid()) return;

      const message = {
        user_shipment_report: {
          subject: ctrl.subject,
          message: ctrl.message,
        },
      };

      FixMonkeyService.reportShipment(message)
        .then(function () {
          fixShipmentModal.close();
          toastSuccess($translate.instant('shipments.fix-monkey.report'));
          ShipmentList.reset();

          if (FixMonkeyService.preventRedirectToRejected) {
            return;
          }

          $state.go(
            'app.shipments',
            { sectionName: 'rejected', page: 1, query: { scope: 'rejected' } },
            { reload: true }
          );
        })
        .catch(function (err) {
          ctrl.error = err.data ? err.data.message : 'toast.default-error';
        });
    };

    /**
     * isFormValid: manually perform form validation because the button is outside of the form itself
     * @return {Boolean} is valid or not
     */
    function isFormValid() {
      const fieldsToCheck = ['subject', 'message'];

      if (ctrl.reportShipmentForm.$invalid) {
        fieldsToCheck.forEach(function (field) {
          ctrl.reportShipmentForm[field] && ctrl.reportShipmentForm[field].$setDirty();
        });
        return false;
      }
      return true;
    }
  }

  fixShipmentModalBodyReportShipmentCtrl.$inject = [
    '$state',
    '$translate',
    'FixMonkeyService',
    'ShipmentList',
    'fixShipmentModal',
    'MixpanelService',
  ];

  angular
    .module('app.component.fix-shipment-modal-body-report-shipment')
    .component('fixShipmentModalBodyReportShipment', fixShipmentModalBodyReportShipment)
    .controller('fixShipmentModalBodyReportShipmentCtrl', fixShipmentModalBodyReportShipmentCtrl);
})();
