(function () {
  'use strict';

  WalkthroughTooltipService.$inject = ['$timeout', '$q'];
  /**
   * [WalkthroughTooltipService]
   * Used to genererate, hide or show walkthrough tooltips using Tippy.js (https://atomiks.github.io/tippyjs/)
   * for the Walkthrough
   */
  function WalkthroughTooltipService($timeout, $q) {
    var service = this;

    /**
     * [showStepTooltip] Build and manually show a step tooltip from a specified flow
     *
     * @param  {String} flow
     * @param  {String} step
     */
    service.showStepTooltip = function (flow, stepName, stepObject) {
      return $q(function (resolve, reject) {
        // Make sure no other tooltip is currently visible
        service.destroyAllVisibleTooltips();

        // Build tippy instance
        buildEasyshipWalkthroughTooltip(flow, stepObject, stepName);

        // get the DOM element associated to this step and flow
        $timeout(function () {
          var target = getTargetElement(flow, stepName);

          // Show the Tooltip
          // A delay is added for cosmetic purposes
          $timeout(function () {
            if (target && target._tippy) {
              target._tippy.show();
              resolve();
            }
          }, 500);
        });
      });
    };

    /**
     * [destroyAllVisibleTooltips] Hide any tippy tooltip
     *
     * - Get all tippy instances in the document
     * - If any are currently visible, hide them
     */
    service.destroyAllVisibleTooltips = function () {
      // Get all tippy instances in the document
      var allTippys = Array.prototype.slice
        .call(document.querySelectorAll('[data-tippy]'))
        .map(function (element) {
          return element._tippy;
        });

      // If any are currently visible, hide them
      _.each(allTippys, function (tippyInstance) {
        if (_.get(tippyInstance, 'state.visible')) {
          if (tippyInstance.state.visible) tippyInstance.destroy();
        }
      });
    };

    /**
     * [getTargetElement] Get the DOM element with id associated to this flow and step
     *
     * @param  {String} flow
     * @param  {String} step
     *
     * @return DOM element
     */
    function getTargetElement(flow, step) {
      var targetId = buildTargetString(flow, step);
      var el = document.querySelector(targetId);

      return el;
    }

    /**
     * [buildTargetString] Build the string corresponding to the id used in the DOM for the this flow and step
     *
     * @param  {String} flow
     * @param  {String} step
     *
     * @return {String} step id
     */
    function buildTargetString(flow, step) {
      return '#walkthrough__' + flow + '__' + step + '-0';
    }

    /**
     * [buildEasyshipWalkthroughTooltip] Build a tippy instance
     *
     * @param  {String} target: id of the DOM element where the tooltip is supposed to appear
     * @param  {Object} stepObject: entire step object
     * @param  {String} content: actual text of the tooltip (to be improved)
     * @param  {Obejct} options: allow to override any of the tippy attributes on a case by case basis
     *
     */
    function buildEasyshipWalkthroughTooltip(flow, stepObject, stepName) {
      // Defaults
      if (!flow || !stepObject || !stepName) return;
      var options = stepObject.tooltipOptions || {};

      // Build target id
      var target = buildTargetString(flow, stepName);

      // generate DOM element
      var tooltipBody = document.createElement('div');

      // Build template according to the tooltip type
      tooltipBody.innerHTML =
        stepObject.type === 'sequence'
          ? sequenceTooltipTemplate(stepObject.content, flow)
          : infoTooltipTemplate(stepObject.content, flow, stepName);

      var tooltipOptions = {
        html: tooltipOptionHandler(options.html, tooltipBody), //Actual html present in the tooltip
        theme: tooltipOptionHandler(options.theme, 'easyship-walkthrough'), //css theme defined in easyship-tippy.scss
        animation: tooltipOptionHandler(options.animation, 'shift-toward'), //animation when tooltip appears
        arrow: tooltipOptionHandler(options.arrow, true), //show/hide arrow
        hideOnClick: tooltipOptionHandler(options.hideOnClick, 'persistent'), //behaviour when DOM is clicked
        interactive: tooltipOptionHandler(options.interactive, true), //Make tooltip clickable
        trigger: tooltipOptionHandler(options.trigger, 'manual'), //how is the tooltip triggered
        arrowType: tooltipOptionHandler(options.arrowType, 'large'), //pre-set style of the arrow
        inertia: tooltipOptionHandler(options.inertia, true), //inertia animation effect
        duration: tooltipOptionHandler(options.duration, [600, 300]), // animation length
        arrowTransform: tooltipOptionHandler(options.arrowTransform, 'translateY(-1px)'), //Manually move the arrow
        placement: tooltipOptionHandler(options.placement, 'top-start'), //Default placement of the tooltip according to it's parent element
        flip: tooltipOptionHandler(options.flip, false), //Allow (or not) the tooltip to change position if it goes off screem
        zIndex: tooltipOptionHandler(options.zIndex, 24), //vertical positioning,
        offset: tooltipOptionHandler(options.offset, null), //Manually reposition the tooltip 'XAxis, Yaxis' in px
      };

      if (stepObject.overrideOverflow) {
        // Make the toolip position correctly even if the container has an 'overflow' css attribute
        // https://github.com/atomiks/tippyjs/issues/196
        tooltipOptions = Object.assign(tooltipOptions, {
          popperOptions: {
            modifiers: {
              preventOverflow: {
                escapeWithReference: true,
              },
            },
          },
        });
      }

      // Bind hide/show tooltip event according to the position of the targeted el on the Yaxis
      if (stepObject.hideOnScrollPosition)
        registerHideAndShowOnPositionEvent({
          targetYPosition: stepObject.hideOnScrollPosition,
          flow: flow,
          stepName: stepName,
        });

      // Call tippy to build tippy instance
      var tooltip = tippy(target, tooltipOptions);

      return tooltip;
    }

    function tooltipOptionHandler(customOption, defaultOption) {
      return angular.isDefined(customOption) ? customOption : defaultOption;
    }

    /**
     * [registerHideAndShowOnPositionEvent]
     * Bind hide/show tooltip event according to the position of the targeted el on the Yaxis
     *
     * @param  {[type]} params :{targetYPosition, flow, stepName}
     */
    function registerHideAndShowOnPositionEvent(params) {
      var params = params || {};
      var target = getTargetElement(params.flow, params.stepName);
      var throttleDelay = 100;

      if (!target) return;

      $('#sidebar-scrollable-content')
        // Unbind any scroll event first to make sure to update the target
        .unbind('scroll')
        // Use _.throttle to make sure to limit the number of event fired
        .bind(
          'scroll',
          { target: target },
          _.throttle(function (event) {
            var position = $('#' + params.stepName).offset();
            var tippyInstance = _.get(event.data, 'target._tippy');

            if (!tippyInstance || !position) return;

            if (position.top <= params.targetYPosition) {
              tippyInstance.hide();
            } else if (
              tippyInstance.state &&
              tippyInstance.state.enabled &&
              !tippyInstance.state.visible
            ) {
              tippyInstance.show();
            }
          }, throttleDelay)
        );
    }

    /**
     * [sequenceTooltipTemplate]
     * build the HTML content of a tooltip type 'sequence' (snooze button)
     *
     * @param  {String} content: HTML
     * @param  {String} flow
     * @return {String} HTML content
     */
    function sequenceTooltipTemplate(content, flow) {
      return (
        '<div class="sequence-tooltip"><div>' +
        content +
        '</div><button id="snooze-' +
        flow +
        '"></button></div>'
      );
    }

    /**
     * [infoTooltipTemplate]
     * build the HTML content of a tooltip type 'sequence' (Got it button)
     *
     * @param  {String} content: HTML
     * @param  {String} flow
     * @param  {String} stepName
     *
     * @return {String} HTML content
     */
    function infoTooltipTemplate(content, flow, stepName) {
      return (
        '<div class="info-tooltip"><div>' +
        content +
        '</div><div><button id="gotit-' +
        flow +
        '-' +
        stepName +
        '"><span>Got it</span></button></div></div>'
      );
    }
  }

  angular
    .module('app.service.WalkthroughTooltipService', [])
    .service('WalkthroughTooltipService', WalkthroughTooltipService);
})();
