/* eslint-disable max-classes-per-file */
import template from './merge-selected-modal.html?raw';

class MergeSelectedModalCtrl {
  static $inject = ['shipments', 'isMatch', 'MixpanelService', 'MergeSelectedModal'];

  constructor(shipments, isMatch, MixpanelService, MergeSelectedModal) {
    this.shipments = shipments;
    this.isMatch = isMatch;
    this.MixpanelService = MixpanelService;
    this.MergeSelectedModal = MergeSelectedModal;
  }

  close() {
    this.MixpanelService.track('Multiple - Select Merge - Cancel');
    this.MergeSelectedModal.close();
  }
}

class MergeSelectedModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open({ is_match, shipments }) {
    this.modalReference = this.$uibModal.open({
      template,
      controller: MergeSelectedModalCtrl,
      controllerAs: '$ctrl',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        shipments: () => shipments,
        isMatch: () => is_match,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { MergeSelectedModal };
