import { ICourierAccount } from '@client/src/couriers/models/data/lyoc-courier-account';
import axios, { AxiosResponse } from 'axios';
import { UpsOauthPort } from '../../ports/UpsOauthPort';
import {
  CreateUpsCourierAccountPayload,
  SendCourierAccountTokenPayload,
} from '../../models/payloads/ups-oauth-payload';
import { AuthorizeRedirectionData, CourierAccountData } from '../../models/data/ups-oauth-data';

export class UpsOauthGateway implements UpsOauthPort {
  createUpsCourierAccount(
    companyId: string,
    payload: CreateUpsCourierAccountPayload
  ): Promise<ICourierAccount> {
    return axios
      .post(
        `${
          import.meta.env.VITE_APP_ENDPOINT
        }/companies/${companyId}/courier_accounts/ups-ready-oauth`,
        payload
      )
      .then((res: AxiosResponse) => {
        return res.data;
      });
  }
  getUpsRedirectUrl(companyId: string, upsAccountId: string): Promise<AuthorizeRedirectionData> {
    return axios
      .get(
        // TODO: move call to service and do not hard code domain in url
        `${
          import.meta.env.VITE_APP_ENDPOINT
        }/companies/${companyId}/courier_accounts/${upsAccountId}/authorize`
      )
      .then((res: AxiosResponse) => {
        return res.data;
      });
  }

  sendCourierAccountToken(
    payload: SendCourierAccountTokenPayload,
    companyId: string,
    courierAccountId: string
  ): Promise<CourierAccountData> {
    return axios.post(
      `${
        import.meta.env.VITE_APP_ENDPOINT
      }/companies/${companyId}/courier_accounts/${courierAccountId}/token`,
      payload
    );
  }
}
