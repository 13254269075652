import angular, { IComponentController } from 'angular';
import moment, { Moment } from 'moment';

import {
  IManifestTrackingService,
  IManifestService,
  IGenerateManifestObject,
  IManifestDate,
  IManifestsByDateAndPostalCode,
  IManifestsByDate,
  IManifestModalService,
  IManifestObject,
  IManifestsByLocation,
} from 'typings/manifest';

import { IApiConfig } from 'typings/core/config/api';
import { IShipmentsObject } from 'typings/shipment';
import {
  ICourierManifestModal,
  ICourierAccountsService,
  ICourierAccountObject,
  IActiveCourierAccounts,
  ILabeledCourierAccount,
} from 'typings/courier';
import { IHelperService } from 'typings/helper';

import { IAddressService } from 'typings/address';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './manifest-modal-main.html?raw';
import style from './manifest-modal-main.module.scss';
import { DATE_FORMAT } from '../../../global/manifest/manifest.service';

enum Steps {
  Select = 'select',
  Confirm = 'confirm',
}

enum CourierManifestMethod {
  Select = 'select',
}

type GeneratedManifestCounter = {
  generated: number;
  ungenerated: number;
};

class ManifestModalMain implements IComponentController {
  style = style;
  supportSite = '';
  loadingManifests = false;
  loadError = false;
  hasUsAddress = false;
  hasAuAddress = false;
  hasGbAddress = false;
  isUsCourierAccount = false;
  isAuCourierAccount = false;
  isGbCourierAccount = false;
  manifests: IManifestsByLocation = {};
  manifestDates: IManifestDate[] = [];
  manifestToGenerate: IGenerateManifestObject | null = null;
  today: Moment;
  selectedDate: string | null = null;
  selectedCourier: any;
  manifestWithError: IGenerateManifestObject | null = null;
  step = Steps.Select;
  courier: ICourierManifestModal | null = null;
  error: string | null = null;
  shipments: IShipmentsObject | null = null;
  courierAccounts: ILabeledCourierAccount[] = [];
  linkWrapperUs: Record<string, (text: string) => string>;
  linkWrapperAu: Record<string, (text: string) => string>;

  static $inject = [
    '$q',
    '$translate',
    '$filter',
    'Manifest',
    'ManifestTrackingService',
    'API',
    'ShipmentListManageService',
    'CourierAccounts',
    'HelperService',
    'ManifestModal',
    'AddressService',
  ];
  constructor(
    private $q: ng.IQService,
    private $translate: angular.translate.ITranslateService,
    private $filter: ng.IFilterFunction,
    private Manifest: IManifestService,
    private ManifestTrackingService: IManifestTrackingService,
    private API: IApiConfig,
    private ShipmentListManageService: ShipmentListManageService,
    private CourierAccounts: ICourierAccountsService,
    private HelperService: IHelperService,
    private ManifestModal: IManifestModalService,
    private AddressService: IAddressService
  ) {
    this.today = moment().startOf('day');
    this.setupManifestByCourier();
    this.getSupportedCountries();

    this.linkWrapperUs = {
      a: (text) =>
        `<a href="${this.API.help}/hc/en-us/articles/360008515292" target="_blank">${text}</a>`,
    };
    this.linkWrapperAu = {
      a: (text) =>
        `<a href="${this.API.help}/hc/en-us/articles/360025926411" target="_blank">${text}</a>`,
    };
  }

  onDateChange(value: string): void {
    this.selectedDate = value;
  }

  onCourierAccountChange(value: ILabeledCourierAccount): void {
    this.selectedCourier = value;
    this.isUsCourierAccount = false;
    this.isAuCourierAccount = false;
    this.isGbCourierAccount = false;
    this.manifestDates = [];

    switch (this.selectedCourier.country_alpha2) {
      case 'US':
        this.isUsCourierAccount = true;
        break;

      case 'GB':
        this.isGbCourierAccount = true;
        break;

      case 'AU':
        this.isAuCourierAccount = true;
        this.selectedCourier.method = CourierManifestMethod.Select;
        this.validateManifest();
        return;
    }

    this.fetchManifests();
  }

  getManifestLocationsForSelectedDate(): IManifestsByDate | null {
    if (!this.manifests || !this.selectedDate) {
      return null;
    }

    return this.manifests[this.selectedDate];
  }

  onGenerateManifest(manifest: IGenerateManifestObject): ng.IPromise<any> | undefined {
    if (!this.selectedCourier) return;

    if (
      this.selectedCourier &&
      this.selectedCourier.method === CourierManifestMethod.Select &&
      this.step !== Steps.Confirm
    ) {
      this.step = Steps.Confirm;
      this.manifestToGenerate = manifest;
      return this.$q.resolve();
    }

    if (typeof this.selectedDate === 'string') {
      this.ManifestTrackingService.trackGenerateSubmit({
        manifest,
        selectedDate: this.selectedDate,
      });
    }

    // eslint-disable-next-line consistent-return
    return this.Manifest.generateManifest(manifest, {
      umbrellaName: this.selectedCourier.umbrella_name,
      courierAccountId: this.selectedCourier.id,
    })
      .then((response) => {
        const generatedManifest = response.manifest;
        const { document_url, total_shipments_count } = generatedManifest;

        toastSuccess(
          this.$translate.instant(
            'manifest-modal.notifications.generated',
            {
              count: total_shipments_count.toFixed(0),
            },
            'messageformat'
          )
        );

        this.ManifestTrackingService.trackGenerateComplete({ manifest: generatedManifest });

        this.fetchManifests()
          .then(() => {
            this.downloadManifest(document_url);
          })
          .catch(angular.noop);
      })
      .catch((error) => {
        this.manifestWithError = manifest;

        this.error =
          error?.error_messages || this.$translate.instant('manifest-modal.notifications.failed');

        if (this.selectedCourier && this.selectedCourier.method !== CourierManifestMethod.Select) {
          const errorMessage = error?.error_messages || 'manifest-modal.notifications.failed';
          toastError(this.$translate.instant(errorMessage));
        }
      });
  }

  onDownloadManifest(manifest: IGenerateManifestObject): void {
    const { document_url } = manifest;

    /**
     * Our UI should never have let this happen. If we are able to trigger a download
     * on a manifest without a link somehow, throw an error.
     */
    if (typeof document_url === 'undefined' || document_url === null) {
      this.manifestWithError = manifest;
      throw new Error('USPS Manifest: Attempted to download manifest without a valid document_url');
    }

    if (typeof this.selectedDate === 'string') {
      this.ManifestTrackingService.trackDownload({
        manifest,
        selectedDate: this.selectedDate,
      });
    }

    this.downloadManifest(document_url);
  }

  reloadManifests(): ng.IPromise<void> {
    return this.fetchManifests();
  }

  onCancel(): void {
    this.step = Steps.Select;
    this.error = null;
  }

  private get defaultDate(): string {
    return moment().startOf('day').format(DATE_FORMAT);
  }

  private getSupportedCountries(): void {
    this.$q
      .all([
        this.AddressService.hasSenderAddressInCountry('US'),
        this.AddressService.hasSenderAddressInCountry('AU'),
        this.AddressService.hasSenderAddressInCountry('GB'),
      ])
      .then(([hasUsAddress, hasAuAddress, hasGBAddress]) => {
        this.hasUsAddress = hasUsAddress;
        this.hasAuAddress = hasAuAddress;
        this.hasGbAddress = hasGBAddress;
      });
  }

  /**
   * method parameter sets whether manifests are generated by selecting shipments
   * manually (when method === 'select'), or selected automatically based on
   * the date
   */
  private setupManifestByCourier(): void {
    this.CourierAccounts.getActiveCourierAccounts()
      .then((res: IActiveCourierAccounts) => {
        this.courierAccounts = res.company_courier_accounts.reduce(
          (acc: ILabeledCourierAccount[], companyCourierAccount: ICourierAccountObject) => {
            if (companyCourierAccount.manifest_name && companyCourierAccount.manifest_method) {
              acc.push({
                label: `${companyCourierAccount.nickname} - ${companyCourierAccount.umbrella_name}`,
                ...companyCourierAccount,
              });
            }

            return acc;
          },
          []
        );

        this.courierAccounts = res.easyship_courier_accounts.reduce(
          (acc: ILabeledCourierAccount[], easyshipCourierAccount: ICourierAccountObject) => {
            if (easyshipCourierAccount.manifest_name && easyshipCourierAccount.manifest_method) {
              acc.push({
                label: easyshipCourierAccount.umbrella_name,
                ...easyshipCourierAccount,
              });
            }

            return acc;
          },
          this.courierAccounts
        );

        if (!this.courierAccounts.length) {
          this.ManifestModal.close();
          toastError(this.$translate.instant('manifest-modal.notifications.no-courier'));
          return;
        }

        this.onCourierAccountChange(this.courierAccounts[0]);
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private validateManifest(): ng.IPromise<void> {
    const shipmentIds = this.ShipmentListManageService.selectedIds;

    if (!this.selectedCourier) {
      toastError(this.$translate.instant('manifest-modal.notifications.validate-error'));
      return this.$q.reject();
    }

    return this.Manifest.verifyManifest(
      this.selectedCourier.umbrella_name,
      this.selectedCourier.id,
      shipmentIds
    )
      .then(({ valid_count, invalid_count, valid_to_create_manifest }) => {
        this.shipments = {
          validCount: valid_count,
          invalidCount: invalid_count,
          isManifestable: valid_to_create_manifest,
          shipmentIds,
        };

        this.fetchManifests();
      })
      .catch(() => {
        toastError(this.$translate.instant('manifest-modal.notifications.validate-error'));
      });
  }

  private fetchManifests(): ng.IPromise<void> {
    const shipmentIds = this.ShipmentListManageService.selectedIds;

    this.loadingManifests = true;
    this.loadError = false;

    if (this.selectedCourier) {
      let payload: IShipmentsObject = {
        courierAccount: this.selectedCourier.id,
        shipmentIds,
      };

      if (this.shipments) {
        payload = {
          ...payload,
          validCount: this.shipments.validCount,
          invalidCount: this.shipments.invalidCount,
        };
      }

      return this.Manifest.getManifests(
        {
          umbrellaName: this.selectedCourier.umbrella_name,
          method: this.selectedCourier.method,
        },
        payload
      )
        .then((manifestsByDateAndPostalCode) => {
          this.manifests = manifestsByDateAndPostalCode;
          this.manifestDates = this.prepareManifestDates(manifestsByDateAndPostalCode);
          this.selectedDate = this.defaultDate;
        })
        .catch((error: any) => {
          toastError(
            this.$translate.instant('toast.fetch-error', {
              noun: this.$translate.instant('global.manifests').toLowerCase(),
            })
          );

          this.loadError = true;

          throw error;
        })
        .finally(() => {
          this.loadingManifests = false;
        });
    }

    return this.$q.reject();
  }

  private downloadManifest(url: string): void {
    this.HelperService.openNewTab(url);
  }

  private prepareManifestDates(
    manifestsByDateAndPostalCode: IManifestsByDateAndPostalCode
  ): IManifestDate[] {
    return Object.keys(manifestsByDateAndPostalCode).map((date) => {
      const disabled = this.dateIsDisabled(manifestsByDateAndPostalCode, date);
      const manifestsCount = this.getManifestsCountByDateAndStatus(
        manifestsByDateAndPostalCode,
        date
      );
      const hasExistingManifests = manifestsCount.generated + manifestsCount.ungenerated > 0;
      const displayDate = !hasExistingManifests
        ? this.$filter('intlDate')(date)
        : this.$translate.instant(
            'manifest-modal.main.date-option',
            {
              date: this.$filter('intlDate')(date),
              ...manifestsCount,
            },
            'messageformat'
          );

      return {
        value: date,
        displayDate,
        disabled,
      };
    });
  }

  private getManifestsCountByDateAndStatus(
    manifests: IManifestsByDateAndPostalCode,
    date: string
  ): GeneratedManifestCounter {
    return Object.keys(manifests[date]).reduce<GeneratedManifestCounter>(
      (totalManifestsCountPerLocation, postalCode) => {
        // @ts-ignore: implicit any
        const totalManifestsAndShipmentsCount = manifests[date][postalCode].reduce(
          (totalShipmentsCount: GeneratedManifestCounter, manifest: IManifestObject) => {
            const { total_shipments_count, rejected_count, pending_count, document_url } = manifest;

            // Generated manifest
            if (manifest.id) {
              totalShipmentsCount.generated += 1;
            }

            // Ungenerated manifest
            if (!document_url && total_shipments_count - rejected_count - pending_count > 0) {
              totalShipmentsCount.ungenerated += 1;
            }

            return totalShipmentsCount;
          },
          {
            generated: 0,
            ungenerated: 0,
          }
        );

        totalManifestsCountPerLocation.generated += totalManifestsAndShipmentsCount.generated;
        totalManifestsCountPerLocation.ungenerated += totalManifestsAndShipmentsCount.ungenerated;

        return totalManifestsCountPerLocation;
      },
      {
        generated: 0,
        ungenerated: 0,
      }
    );
  }

  private dateIsDisabled(manifests: IManifestsByDateAndPostalCode, date: string): boolean {
    let disabled;

    if (this.selectedCourier && this.selectedCourier.method === CourierManifestMethod.Select) {
      disabled = Object.keys(manifests[date]).length === 0 && this.today.isAfter(date);
    } else {
      disabled = Object.keys(manifests[date]).length === 0;
    }

    return disabled;
  }
}

const ManifestModalMainComponent = {
  controller: ManifestModalMain,
  template,
};

export { ManifestModalMainComponent };
