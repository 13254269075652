import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface ScrollingTableProviderProps {
  observer: IntersectionObserver;
}

interface InScrollingViewProps {
  setInScrollView: (value: boolean) => void;
}

const ScrollingTableContext = createContext<ScrollingTableProviderProps>(
  {} as ScrollingTableProviderProps
);

export function ScrollingTableProvider({ children }: { children?: ReactNode }) {
  const value = useMemo(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            entry.target?.setInScrollView?.(entry.isIntersecting);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0,
        rootMargin: '4800px',
      }
    );
    return {
      observer,
    };
  }, []);

  return <ScrollingTableContext.Provider value={value}>{children}</ScrollingTableContext.Provider>;
}

export const withScrollingTableElement = <P extends { isInScrollView?: boolean }>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> =>
  // eslint-disable-next-line func-names
  function (props: P) {
    const { observer } = useContext(ScrollingTableContext);

    const [isInScrollView, setInScrollView] = useState(false);

    const ref = useRef<HTMLDivElement & InScrollingViewProps>(null);

    useEffect(() => {
      if (ref.current) {
        ref.current.setInScrollView = setInScrollView;
        observer.observe(ref.current);
      }
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (ref.current) observer.unobserve(ref.current);
      };
    }, [observer]);

    return <WrappedComponent {...(props as P)} isInScrollView={isInScrollView} ref={ref} />;
  };
