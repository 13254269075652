import { IHelperService } from 'typings/helper';
import { ISubscriptionObject } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import style from './modal.module.scss';
import template from './modal.html?raw';

class WelcomeLegacyPlanModal implements IComponentController {
  style = style;

  static $inject = [
    'SubscriptionService',
    'HelperService',
    'MixpanelService',
    'UserSession',
    '$state',
  ];
  constructor(
    private SubscriptionService: SubscriptionService,
    private HelperService: IHelperService,
    private MixpanelService: MixpanelService,
    private UserSession: IUserSession,
    private $state: ng.ui.IStateService
  ) {}

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get showWelcomeLegacyModal(): boolean {
    return this.SubscriptionService.showWelcomeLegacyModal;
  }

  closeLegacyModal(): void {
    this.SubscriptionService.showWelcomeLegacyModal = false;

    this.MixpanelService.track('Subscription - Legacy - Confirm');
  }

  goToSubscriptionPageWithPlanOpen(): void {
    this.SubscriptionService.showWelcomeLegacyModal = false;

    this.$state.go('app.account.subscription', { isOpenPlan: true });
  }

  mediumDateFormat(date: string): string {
    const locale = this.UserSession.user.dashboard_settings?.language?.code || 'en';
    if (date && date !== '') {
      return this.HelperService.mediumDateFormat(date, locale);
    }

    return '-';
  }
}

const WelcomeLegacyPlanModalComponent: ng.IComponentOptions = {
  controller: WelcomeLegacyPlanModal,
  template,
  bindings: {},
};

export { WelcomeLegacyPlanModalComponent };
