import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './report-issue-modal.html?raw';
import style from './report-issue-modal.module.scss';
import { PickupsPageService } from '../pickups-page.service';

class ReportIssueModal implements IComponentController {
  style = style;
  esPickupId?: string;
  esFlow?: 'contact-support' | 'edit';
  esClose: () => void = () => {};
  busy = {
    sendingReport: false,
  };
  categories = ['failed', 'no-show', 'refused', 'other'];
  formData = {
    category: '',
    message: '',
  };

  static $inject = ['$translate', 'MixpanelService', 'PickupsPageService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private MixpanelService: MixpanelService,
    private PickupsPageService: PickupsPageService
  ) {}

  $onInit(): void {
    this.formData.category = this.esFlow === 'contact-support' ? 'no-show' : 'failed';
  }

  onFieldChange(value: string, field: 'category' | 'message'): void {
    this.formData[field] = value;
  }

  onSubmit(form: ng.IFormController): void {
    if (!this.esPickupId) return;

    if (form.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.busy.sendingReport = true;

    this.PickupsPageService.report(this.esPickupId, this.formData)
      .then(({ display_request_state }) => {
        const { pickups } = this.PickupsPageService;

        const pickup = pickups.find((p) => p.pickup_id === this.esPickupId);

        if (pickup) {
          pickup.display_request_state = display_request_state;
        }

        this.PickupsPageService.updateTotals();
        toastSuccess(this.$translate.instant('pickups.report-modal.success'));
        const eventTitle =
          this.esFlow === 'contact-support' ? 'Ask for support' : 'Edit pickup info';

        this.MixpanelService.track(`Pickups - Request Help - ${eventTitle}`);

        this.esClose();
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.sendingReport = false;
      });
  }
}

const ReportIssueModalComponent: ng.IComponentOptions = {
  controller: ReportIssueModal,
  template,
  bindings: {
    esPickupId: '<',
    esFlow: '@',
    esBack: '&',
    esClose: '&',
  },
};

export { ReportIssueModalComponent };
