import angular from 'angular';
import {
  ColumnVisibilityMap,
  UpdateColumnSettingPayload,
} from '@typings/dashboard/shipment_table/settings';
import { IUserSession } from '@typings/user-session';
import axios from 'axios';
import { ShipmentsTableSettingsPort } from '../ports/shipmentsTableSettings';

export default class NgShipmentsTableSettingsGateway implements ShipmentsTableSettingsPort {
  async updateSettings(companyId: string, settings: UpdateColumnSettingPayload, context: string) {
    const result = await axios.patch(
      `${import.meta.env.VITE_APP_ENDPOINT}/companies/${companyId}/shipments_table_settings`,
      {
        context,
        shipments_table_settings: settings,
      }
    );
    if (result?.status === 200) {
      const resource = angular.element(document.body).injector().get('UserSession') as IUserSession;
      resource?.updateCompanyDashboardTableSettings(
        result.data?.shipments_table ? result.data.shipments_table : settings
      );
    }
  }

  getSettings(): Promise<ColumnVisibilityMap> {
    const resource = angular.element(document.body).injector().get('UserSession') as IUserSession;
    const settings = resource?.company?.dashboard_settings?.shipments_table || {};
    const isTaxCollectEnabled = !!resource?.company?.dashboard_settings?.show_tax_collect_filter;
    settings.collection_status_column = settings.collection_status_column && isTaxCollectEnabled;
    return Promise.resolve(settings);
  }
}
