import {
  IShipmentBulkEditField,
  IShipmentBulkEditFields,
  IShipmentBulkEditAttributes,
} from 'typings/shipment';
import { IAddress, IAddressService } from 'typings/address';
import { IUserSession } from 'typings/user-session';
import { IBoxObject, IFlatRateBox, IBoxService, IFlatRateBoxService } from 'typings/boxes';
import { IPlatformService } from 'typings/platform';
import { ICourierServiceOption, ICourierService } from 'typings/courier';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { BulkEditService } from '@client/src/global/services/bulk-edit/bulk-edit.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './bulk-edit-modal.html?raw';
import style from './bulk-edit-modal.module.scss';

interface Dimensions {
  height: string;
  length: string;
  width: string;
}

interface IFormData {
  [key: string]: string | boolean | Dimensions | IBoxObject | IFlatRateBox;
}

type Busy = {
  updatingShipments: boolean;
};

class BulkEditModal implements IComponentController {
  style = style;
  busy: Busy = {
    updatingShipments: false,
  };
  fields: IShipmentBulkEditFields[] = [];
  selectedField: IShipmentBulkEditField | null = null;
  allBoxes: (IBoxObject | IFlatRateBox)[] = [];
  platformNames: string[] = [];
  couriers: ICourierServiceOption[] = [];
  reusableShippingAddresses: IAddress[] = [];
  formData: IFormData = {
    incoterms: false,
    insurance: false,
    residential: false,
  };

  static $inject = [
    '$q',
    '$translate',
    'ShipmentListAdvancedService',
    'BulkEditService',
    'UserSession',
    'BoxService',
    'FlatRateBoxService',
    'PlatformService',
    'MixpanelService',
    'CourierService',
    'AddressService',
  ];
  constructor(
    private $q: ng.IQService,
    private $translate: angular.translate.ITranslateService,
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private BulkEditService: BulkEditService,
    private UserSession: IUserSession,
    private BoxService: IBoxService,
    private FlatRateBoxService: IFlatRateBoxService,
    private PlatformService: IPlatformService,
    private MixpanelService: MixpanelService,
    private CourierService: ICourierService,
    private AddressService: IAddressService
  ) {}

  $onInit(): void {
    this.getFields();
    this.getAndPrefillTheBoxOptions();
    this.getCouriers();
    this.getAddresses();
    this.platformNames = this.PlatformService.getPlatformNames();
  }

  esOnCancel(): void {
    // esOnCancel expression bindings, need to add this in order for typescript to successfully
  }

  get totalShipmentCount(): number {
    const { isSelectedAll, totals, excludedIds, selectedCount } = this.ShipmentListAdvancedService;

    if (!totals) return 0;

    if (isSelectedAll) {
      return totals.orders_count - excludedIds.length;
    }

    return selectedCount;
  }

  get dimensionsUnit(): string {
    return this.UserSession.company.dimensions_unit;
  }

  get weightUnit(): string | null {
    return this.UserSession.company.weight_unit;
  }

  onPropertyToEditChange(value: IShipmentBulkEditField): void {
    this.selectedField = value;
  }

  onFieldChange(value: string | boolean | Dimensions, field: IShipmentBulkEditField): void {
    this.formData[field] = value;

    // Dimensions
    if (typeof value !== 'string' && typeof value !== 'boolean') {
      const { height, length, width } = value;

      this.formData.height = height;
      this.formData.length = length;
      this.formData.width = width;

      this.formData.dimensions = {
        height,
        length,
        width,
      };
    }
  }

  onOriginSelected(id: string): void {
    this.formData.origin_address_id = id;
  }

  updateShipments(form: ng.IFormController): void {
    if (form.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.busy.updatingShipments = true;

    if (!this.selectedField) return;

    const payload = this.buildPayload(this.selectedField);

    this.BulkEditService.updateShipment(payload)
      .then((response) => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-count',
            {
              count: this.totalShipmentCount.toString(),
              noun: this.$translate
                .instant(
                  'global.pluralize.shipment',
                  { COUNT: this.totalShipmentCount.toString() },
                  'messageformat'
                )
                .toLowerCase(),
            },
            'messageformat'
          )
        );

        if (response.recalculating) {
          toastSuccess(
            this.$translate.instant(
              'shipments.bulk-edit-modal.recalculate-all',
              { count: this.totalShipmentCount.toString() },
              'messageformat'
            )
          );
        }

        this.esOnCancel();
        this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter();

        this.MixpanelService.track('Bulk update - Update property', {
          property_name: this.selectedField,
        });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.updatingShipments = false;
      });
  }

  private getFields() {
    this.BulkEditService.getFields()
      .then((response) => {
        this.fields = response.fields.reduce<IShipmentBulkEditFields[]>((fields, field) => {
          const { name } = field;

          // eslint-disable-next-line no-param-reassign
          field.label = this.$translate.instant(
            `shipments.bulk-edit-modal.form.fields.value.${name}`
          );

          fields.push(field);

          if (name === 'add_tags') {
            // eslint-disable-next-line no-param-reassign
            fields = fields.filter((field) => {
              return field.name !== 'tags';
            });
          }
          return fields;
        }, []);
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private getAndPrefillTheBoxOptions() {
    this.$q
      .all([
        this.BoxService.getCustomBoxesSelectOptions(),
        this.FlatRateBoxService.getFlatRateBoxesSelectOptions(),
      ])
      .then(([customBoxes, flatRateBoxes]) => {
        this.allBoxes = [...customBoxes, ...flatRateBoxes];

        // eslint-disable-next-line prefer-destructuring
        this.formData.box = this.allBoxes[0];
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private getCouriers() {
    return this.CourierService.getCouriers()
      .then((couriers) => {
        this.couriers = couriers
          .filter((courier) => !!courier.accepts_outbounds)
          .sort((a, b) => (a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1))
          .map((courierService) => ({
            ...courierService,
            display_name: `${courierService.display_name} [${courierService.origin_country_alpha2}]`,
          }));
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private getAddresses() {
    this.reusableShippingAddresses = this.AddressService.getReusableShippingAddresses();
  }

  private buildPayload(fieldName: IShipmentBulkEditField) {
    const { isSelectedAll, excludedIds, selectedIds } = this.ShipmentListAdvancedService;

    const attributes: IShipmentBulkEditAttributes = {};

    if (fieldName === 'box_and_dimensions') {
      if ((this.formData.box as IBoxObject).id) {
        attributes.package_type = 'Box';
        attributes.package_id = (this.formData.box as IBoxObject).id;
      }

      if ((this.formData.box as IFlatRateBox).flat_rate_box_id) {
        attributes.package_type = 'FlatRateBox';
        attributes.package_id = (this.formData.box as IFlatRateBox).flat_rate_box_id;
      }

      attributes.height = this.formData.height as string;
      attributes.length = this.formData.length as string;
      attributes.width = this.formData.width as string;
    }

    if (fieldName === 'insurance') {
      attributes.is_insured = this.formData.insurance as string;
    }

    if (fieldName === 'residential') {
      attributes.set_as_residential = this.formData.residential as string;
    }

    if (fieldName === 'sales_channel') {
      attributes.platform_name = this.formData.platformName as string;
    }

    if (fieldName === 'tax_and_duty') {
      attributes.incoterms = this.formData.incoterms ? 'DDU' : 'DDP';
    }

    if (fieldName === 'seller_notes') {
      attributes.seller_notes = this.formData.sellerNotes as string;
    }

    if (['tags', 'add_tags', 'remove_tags'].includes(fieldName)) {
      attributes.order_tag_list = this.formData.tags as string;
    }

    if (fieldName === 'weight') {
      attributes.total_actual_weight = this.formData.weight as string;
    }

    if (fieldName === 'courier') {
      attributes.courier_id = this.formData.courier as string;
    }

    if (fieldName === 'origin_address') {
      attributes.origin_address_id = this.formData.origin_address_id as string;
    }

    let payload;

    if (isSelectedAll) {
      payload = {
        by_search: true,
        exclude_shipment_ids: excludedIds,
        scope: 'orders_all',
        ...this.ShipmentListAdvancedService.filterPayload,
      };
    } else {
      payload = {
        shipment_ids: selectedIds,
      };
    }

    return {
      name: fieldName,
      attributes,
      limit: this.totalShipmentCount,
      ...payload,
    };
  }
}

const BulkEditModalComponent: ng.IComponentOptions = {
  controller: BulkEditModal,
  template,
  bindings: {
    esOnCancel: '&',
  },
};

export { BulkEditModalComponent };
