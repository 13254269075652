import { IComponentController } from 'angular';
import template from './typeahead.html?raw';
import style from './typeahead.module.scss';

const DEFAULT_MIN_CHARS = 3;

class Typeahead implements IComponentController {
  id = `es-typeahead-${Date.now() + Math.random()}`.replace('.', '');
  style = style;
  esModel = '';
  esMinChars = DEFAULT_MIN_CHARS;
  esTemplate = '';
  esOnChange = (changes: any) => {};
  esOnQuery = (changes: any) => {};
  esOnSelect = (changes: any) => {};
  showOptions = false;

  static $inject = ['$element'];
  constructor(private $element: ng.IAugmentedJQuery) {
    this.style = style;
  }

  $onInit() {
    this.esMinChars = this.esMinChars || DEFAULT_MIN_CHARS;
  }

  $onChanges(changesObj: any) {
    if (changesObj.esOptions && changesObj.esOptions.currentValue) {
      // Make the model special character regex expression safe eg. + -> /+
      const esModel = this.esModel.replace(/\W/g, '/$&');
      let $highlightables = this.$element.find('dropdown-content es-typeahead-highlight');

      if ($highlightables.length === 0) {
        $highlightables = this.$element.find('dropdown-content ul li');
      }

      $highlightables.each((i, list) => {
        list.innerHTML = list.innerHTML
          .replace('<span class="strong">', '')
          .replace('</span>', '')
          .replace(new RegExp(esModel, 'gi'), '<span class="strong">$&</span>');
      });

      this.showOptions = !!changesObj.esOptions.currentValue.length;
    }
  }

  onToggle(isOpen: boolean) {
    if (isOpen === false) {
      this.showOptions = false;
    }
  }

  onChange(value = '') {
    this.esModel = value;
    this.esOnChange({ value: this.esModel });

    if (value.length >= this.esMinChars) {
      this.esOnQuery({ value: this.esModel });
    } else {
      this.showOptions = false;
    }
  }

  onSelect(option: any[]): void {
    this.showOptions = false;

    this.esOnSelect({ value: option });
  }

  parseTemplate() {
    return (
      this.esTemplate
        // Wrap models that contains `|h` into `es-typeahead-highlight` element and remove it
        .replace(/{{%([a-zA-Z]*)\|h}}/g, '<es-typeahead-highlight>{{%$1}}</es-typeahead-highlight>')
        .replace(/%/g, 'option.')
    );
  }
}

const TypeaheadComponent: ng.IComponentOptions = {
  controller: Typeahead,
  template,
  transclude: true,
  bindings: {
    esModel: '<',
    esOptions: '<',
    esRequired: '<',
    esMinChars: '<',
    esDisabled: '<',
    esOnChange: '&',
    esOnQuery: '&',
    esOnSelect: '&',
    esTemplate: '@',
    esMaxLength: '@',
    esValidation: '@',
    esInitValidation: '<',
    esError: '<',
    esPlaceholder: '@',
    esPrependIcon: '@',
    esPrependIconImg: '@',
    esPrependTooltip: '@',
    esLearnMoreLink: '@',
    esTooltipWidth: '@',
  },
};

export { TypeaheadComponent };
