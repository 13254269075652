export enum NumberInputTypeValue {
  From = 'from_number',
  To = 'to_number',
  Equal = 'equal_number',
}

export enum NumberOfItemsInputOptionsValue {
  EqualTo = 'is_equal_to',
  NotEqualTo = 'is_not_equal_to',
  GreaterThan = 'is_greater_than',
  GreaterThanEqualTo = 'is_greater_than_or_equal_to',
  LessThan = 'is_less_than',
  LessThanEqualTo = 'is_less_than_or_equal_to',
  Between = 'is_between',
  // TODO Disable until we support it in the future
  // Known = 'is_known',
  // Unknown = 'is_unknown',
}
