(function () {
  'use strict';

  ItemCategory.$inject = ['$resource', 'API'];
  function ItemCategory($resource, API) {
    var endpointUrl = API.endpoint;
    return $resource(
      endpointUrl + '/item_categories',
      {},
      {
        query: {
          method: 'GET',
          params: {},
        },
      }
    );
  }

  angular.module('app.factory.ItemCategory', []).factory('ItemCategory', ItemCategory);
})();
