import moment from 'moment';
import {
  AdditionalServicesOption,
  FedexDeliverySignatureOption,
  FedexHomeDeliveryPremiumOption,
  UPSDeliveryConfirmationOption,
} from '@client/core/corelogic/models/Shipment';
import { IShipmentListItem } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';
import { IComponentController } from 'angular';
import template from './request-pickup-courier-additional-options.html?raw';
import style from './request-pickup-courier-additional-options.module.scss';
// TBD: Replace enums use with shipments types (from shipment.d.ts)
enum UpsValueAddedServices {
  SaturdayDelivery = 'ups_saturday_delivery',
  ShipperRelease = 'ups_shipper_release',
  LargePackage = 'ups_large_package',
  AdditionalHandling = 'ups_additional_handling',
}

enum UpsDeliveryConfirmationOptions {
  NoSignature = 'ups_delivery_confirmation_no_signature',
  SignatureRequired = 'ups_delivery_confirmation_signature_required',
  AdultSignatureRequired = 'ups_delivery_confirmation_adult_signature_required',
}

enum FedexDeliverySignatureOptions {
  AdultSignature = 'fedex_delivery_confirmation_adult_signature',
  DirectSignature = 'fedex_delivery_confirmation_direct_signature',
  IndirectSignature = 'fedex_delivery_confirmation_indirect_signature',
  NoSignatureRequired = 'fedex_delivery_confirmation_no_signature_required',
  DefaultRequired = 'fedex_delivery_confirmation_default_signature',
}

enum FedexAdditionalServicesOptions {
  PriorityAlert = 'fedex_priority_alert',
  SaturdayDelivery = 'fedex_saturday_delivery',
  SaturdayPickup = 'fedex_saturday_pickup',
  ThirdPartyConsignee = 'fedex_third_party_consignee',
}

interface IFedexHomeDeliveryPremiumOption {
  name: string;
  value: FedexHomeDeliveryPremiumOption;
}

class RequestPickupCourierAdditionalOptionsController implements IComponentController {
  style = style;
  servicesChosen: AdditionalServicesOption[] = [];
  chosenConfirmationService: FedexDeliverySignatureOption | UPSDeliveryConfirmationOption | null =
    null;
  upsValueAddedServices = UpsValueAddedServices;
  upsDeliveryConfirmationOptions = UpsDeliveryConfirmationOptions;
  fedexHomeDeliveryPremiumOptions: IFedexHomeDeliveryPremiumOption[];
  fedexDeliverySignatureOptions = FedexDeliverySignatureOptions;
  fedexAdditionalServicesOptions = FedexAdditionalServicesOptions;
  esShipment: IShipmentListItem | null = null;
  showHomeDeliverySelect = false;
  homeDeliveryOptionChosen: IFedexHomeDeliveryPremiumOption | null = null;
  fedexHomeDeliveryDateCertainDate: Date | null = null;
  withinTenDays = moment().add(10, 'd');
  esAdditionalServiceCourier = '';

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {
    this.fedexHomeDeliveryPremiumOptions = [
      {
        name: 'FedEx Evening Home Delivery®',
        value: 'fedex_home_delivery_premium_evening',
      },
      {
        name: 'FedEx Appointment Home Delivery®',
        value: 'fedex_home_delivery_premium_appointment',
      },
      {
        name: 'FedEx Date Certain Home Delivery®',
        value: 'fedex_home_delivery_premium_date_certain',
      },
    ];
  }

  $onInit(): void {
    if (this.esShipment && !this.esShipment.order_data) {
      this.esShipment.order_data = {};
    }

    // This is to remember the chosen delivery confirmation options.
    if (this.esShipment) {
      if (this.esShipment.additional_services) {
        this.servicesChosen = this.esShipment.additional_services;

        if (!this.servicesChosen) return;

        const confirmationServices =
          this.esAdditionalServiceCourier === 'fedex-services'
            ? this.fedexDeliverySignatureOptions
            : this.upsDeliveryConfirmationOptions;

        [this.chosenConfirmationService] = Object.values(confirmationServices).filter((option) => {
          return this.servicesChosen.includes(option);
        });

        // NOTE: This is a temporary fix for the UPS delivery confirmation options.
        // We don't send the "No Signature" option to the backend for now
        if (this.esAdditionalServiceCourier === 'ups-services' && !this.chosenConfirmationService) {
          this.chosenConfirmationService = UpsDeliveryConfirmationOptions.NoSignature;
        }

        this.showHomeDeliverySelect = this.servicesChosen.some((service) => {
          this.homeDeliveryOptionChosen =
            this.fedexHomeDeliveryPremiumOptions.find((fedexHomeDeliveryPremiumOption) => {
              return fedexHomeDeliveryPremiumOption.value === service;
            }) || null;

          return !!this.homeDeliveryOptionChosen;
        });
      }

      if (
        this.esShipment.order_data &&
        this.esShipment.order_data.fedex_home_delivery_certain_date
      ) {
        this.fedexHomeDeliveryDateCertainDate = moment(
          this.esShipment.order_data.fedex_home_delivery_certain_date,
          'YYYY-MM-DD'
        ).toDate();
      }
    }
  }

  onCheckboxChange(service: AdditionalServicesOption): void {
    if (this.servicesChosen.includes(service)) {
      this.servicesChosen = this.servicesChosen.filter((s) => s !== service);
    } else {
      this.servicesChosen.push(service);
    }
    this.updateCheckoutPayload();
  }

  onRadioChange(service: FedexDeliverySignatureOption | UPSDeliveryConfirmationOption): void {
    if (
      this.chosenConfirmationService &&
      this.servicesChosen.includes(this.chosenConfirmationService)
    ) {
      this.servicesChosen = this.servicesChosen.filter((s) => s !== this.chosenConfirmationService);
    }
    // NOTE: This is a temporary fix for the UPS delivery confirmation options.
    // We don't send the "No Signature" option to the backend for now
    if (service !== UpsDeliveryConfirmationOptions.NoSignature) {
      this.servicesChosen.push(service);
    }
    this.chosenConfirmationService = service;
    this.updateCheckoutPayload();
  }

  onHomeDeliveryCheckboxChange(): void {
    if (this.showHomeDeliverySelect) {
      this.homeDeliveryOptionChosen = null;
      this.fedexHomeDeliveryDateCertainDate = null;
      this.removeAllHomeDeliveryOptionsFromServicesChosen();
      this.updateCheckoutPayload();
    } else {
      const indexOfSaturdayDelivery = this.servicesChosen.indexOf(
        this.fedexAdditionalServicesOptions.SaturdayPickup
      );

      if (indexOfSaturdayDelivery > -1) {
        this.servicesChosen.splice(indexOfSaturdayDelivery, 1);
      }
    }

    this.showHomeDeliverySelect = !this.showHomeDeliverySelect;
  }

  onHomeDeliveryOptionsSelectChange(option: IFedexHomeDeliveryPremiumOption): void {
    this.removeAllHomeDeliveryOptionsFromServicesChosen();
    this.homeDeliveryOptionChosen = option;
    this.servicesChosen.push(this.homeDeliveryOptionChosen.value);
    this.updateCheckoutPayload();
  }

  removeAllHomeDeliveryOptionsFromServicesChosen(): void {
    this.servicesChosen = this.servicesChosen.reduce((accumulator, service) => {
      if (
        service !== 'fedex_home_delivery_premium_evening' &&
        service !== 'fedex_home_delivery_premium_appointment' &&
        service !== 'fedex_home_delivery_premium_date_certain'
      ) {
        return [...accumulator, service];
      }

      return accumulator;
    }, [] as AdditionalServicesOption[]);
  }

  onHomeDeliveryCertainDateChange(value: Date): void {
    this.fedexHomeDeliveryDateCertainDate = value;
    this.updateCheckoutPayload();
  }

  getCheckboxState(service: AdditionalServicesOption): boolean {
    if (this.esShipment) {
      const chosenAdditionalServices = this.esShipment.additional_services;

      if (chosenAdditionalServices) {
        return chosenAdditionalServices.includes(service);
      }
    }

    return false;
  }

  onPriorityAlertPlusInputChange(value: boolean | string, field: string): void {
    if (!this.esShipment?.order_data) return;

    if (field === 'fedex_priority_alert_enhancement_type') {
      this.esShipment.order_data.fedex_priority_alert_enhancement_type = value
        ? 'PRIORITY_ALERT_PLUS'
        : undefined;
    } else {
      this.esShipment.order_data.fedex_priority_alert_content = value as string;
    }
  }

  get fedexHomeDeliveryDateCertainSelected(): boolean {
    return !!(
      this.homeDeliveryOptionChosen &&
      this.homeDeliveryOptionChosen.value === 'fedex_home_delivery_premium_date_certain'
    );
  }

  private updateCheckoutPayload(): void {
    if (this.esShipment) {
      const uniqueServices = this.servicesChosen.filter(
        (service) => !this.esShipment?.additional_services.includes(service)
      );
      this.esShipment.additional_services.push(...uniqueServices);

      if (
        this.esShipment.order_data &&
        this.fedexHomeDeliveryDateCertainSelected &&
        this.fedexHomeDeliveryDateCertainDate
      ) {
        this.esShipment.order_data.fedex_home_delivery_certain_date = moment(
          this.fedexHomeDeliveryDateCertainDate
        ).format('YYYY-MM-DD');
      }
    }
  }
  get dimensionsUnit(): string {
    return this.UserSession.company.dimensions_unit;
  }
}

const RequestPickupCourierAdditionalOptionsComponent = {
  controller: RequestPickupCourierAdditionalOptionsController,
  template,
  bindings: {
    esAdditionalServiceCourier: '<',
    esShipment: '<',
    esType: '@',
  },
};

export { RequestPickupCourierAdditionalOptionsComponent };
