import { IShipmentListItem } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import template from './shipment-list-row-header.html?raw';
import style from './shipment-list-row-header.module.scss';

enum ShipmentListType {
  Advanced = 'advanced',
  Manage = 'manage',
}

class ShipmentListRowHeader implements IComponentController {
  translations: angular.translate.ITranslationTable = {};
  style = style;
  esShipments: IShipmentListItem[] | null = null;
  esType: ShipmentListType | null = null;
  isReturnPage = false;

  static $inject = ['$state', 'UserSession'];
  constructor(private $state: ng.ui.IStateService, private UserSession: IUserSession) {}

  $onInit() {
    this.isReturnPage = this.$state.current.name === 'app.returns';
  }

  onCheckboxChange(value: boolean): void {
    this.esOnChange({ value });
  }

  // eslint-disable-next-line
  esOnChange(value: { value: boolean }): void {
    // esOnToggle expression bindings, need to add this in order for typescript to successfully compile
  }

  get isCompanyEfulfilment(): boolean {
    return this.UserSession.isCompanyEfulfilment();
  }

  get isTaxCollectEnabled(): boolean | undefined {
    return this.UserSession.company.dashboard_settings.show_tax_collect_filter;
  }

  get hasShipments(): boolean {
    if (!this.esShipments) {
      return false;
    }
    return this.esShipments.length > 0;
  }

  get isAdvanced(): boolean {
    return this.esType === ShipmentListType.Advanced;
  }

  get isManage(): boolean {
    return this.esType === ShipmentListType.Manage;
  }
}

const ShipmentListRowHeaderComponent: ng.IComponentOptions = {
  controller: ShipmentListRowHeader,
  template,
  bindings: {
    esType: '<',
    esShipments: '<',
    esOnChange: '&',
    esModel: '<',
  },
};

export { ShipmentListRowHeaderComponent };
