import angular from 'angular';
import { CollectBulkService } from './collect-bulk.service';
import { CollectBulkResource } from './collect-bulk.resource';

const MODULE_NAME = 'app.global.services.collect-bulk';

angular
  .module(MODULE_NAME, [])
  .service('CollectBulkService', CollectBulkService)
  .service('CollectBulkResource', CollectBulkResource);

export default MODULE_NAME;
