import { Button } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPeriod } from 'typings/subscription';
import { IPlan } from '../types';

interface IGetButtonColor {
  isCurrentPlan: boolean;
  isUpgradePlan: boolean;
  isOnEndOfTrialMode: boolean;
  isPlanVersionOutDated: boolean;
}
function getButtonColor({
  isCurrentPlan,
  isUpgradePlan,
  isOnEndOfTrialMode,
  isPlanVersionOutDated,
}: IGetButtonColor): 'primary' | 'default' {
  return (isOnEndOfTrialMode && isCurrentPlan) ||
    (isPlanVersionOutDated && isCurrentPlan) ||
    isUpgradePlan
    ? 'primary'
    : 'default';
}

interface IActionButtonProps {
  plan: IPlan;
  onPlanSelect: (planId: number) => void;
  currentSubscriptionPeriod: SubscriptionPeriod | undefined;
  isPlanVersionOutDated: boolean;
  isCurrentPlanChangeable: boolean;
}

export default function ActionButton({
  plan,
  onPlanSelect,
  currentSubscriptionPeriod,
  isPlanVersionOutDated,
  isCurrentPlanChangeable,
}: IActionButtonProps) {
  const isCurrentPlanVersionOutdated = plan.isCurrent && isPlanVersionOutDated;
  const isDisabled =
    currentSubscriptionPeriod !== 'END_OF_TRIAL' &&
    (!isCurrentPlanChangeable || (plan.isCurrent && !isPlanVersionOutDated));
  const buttonColor = getButtonColor({
    isCurrentPlan: plan.isCurrent,
    isUpgradePlan: plan.isUpgrade,
    isOnEndOfTrialMode: currentSubscriptionPeriod === 'END_OF_TRIAL',
    isPlanVersionOutDated,
  });

  function handleClick() {
    onPlanSelect(plan.id);
  }

  return (
    <Button
      className="w-full"
      variant="contained"
      color={buttonColor}
      disabled={isDisabled}
      onClick={handleClick}
    >
      <FormattedMessage
        id={isCurrentPlanVersionOutdated ? 'global.switch-to-new-version' : plan.action}
      />
    </Button>
  );
}
