ReschedulePickupRoutes.$inject = ['$stateProvider'];
function ReschedulePickupRoutes($stateProvider) {
  $stateProvider
    .state('app.reschedule-pickup.handover', {
      url: '/handover?comingFromPickupsPage',
      component: 'reschedulePickupHandover',
    })
    .state('app.reschedule-pickup.summary', {
      url: '/summary?comingFromPickupsPage',
      component: 'reschedulePickupSummary',
    });
}

export { ReschedulePickupRoutes };
