import moment from 'moment';
import template from './manifest-list.html?raw';
import style from './manifest-list.module.scss';

class ManifestList {
  constructor() {
    this.style = style;
    this.today = moment().startOf('day');
  }

  hasManifests() {
    return !!(this.manifests && Object.keys(this.manifests).length);
  }

  getManifestLocation(manifests) {
    const manifest = manifests[0];
    if (!manifest || !manifest.pickup_address) return '';

    const { city, postal_code } = manifest.pickup_address;

    if (city && postal_code) {
      return `${city}, ${postal_code}`;
    }
    if (city && !postal_code) {
      return city;
    }
    return postal_code || '';
  }

  manifestHasError(manifest) {
    return this.manifestWithError === manifest;
  }

  /**
   * Used for ng-repeat render tracking. When a manifest is generated it is assigned an `id` field.
   * Detecting its addition will ensure the manifest row is re-rendered after it has been generated.
   * @param {*} manifest
   */
  getManifestID(manifest) {
    const { manifestable_ids_key, id } = manifest;
    const manifestableIds = manifest[manifestable_ids_key];

    return id || manifestableIds[0];
  }

  /**
   * Invalid description explains to the user that all selected shipments
   * are not from a manifestable courier
   *
   * This should be shown when:
   * 1) There are no valid shipments to manifest;
   * 2) There are no invalid shipments to manifest; and
   * 3) The selected date is same as the current date
   * 4) The shipment is manifestable
   */
  showInvalidSelectedShipments() {
    return (
      this.selectedCourier &&
      this.selectedCourier.method === 'select' &&
      moment(this.selectedDate).isSame(this.today) &&
      this.shipments &&
      !this.shipments.validCount &&
      !this.shipments.invalidCount &&
      this.shipments.isManifestable
    );
  }
}

const ManifestListComponent = {
  controller: ManifestList,
  template,
  bindings: {
    manifests: '<',
    selectedCourier: '<',
    shipments: '<',
    loading: '<',
    onGenerateManifest: '&',
    onDownloadManifest: '&',
    manifestWithError: '<',
    showError: '<',
    reloadManifests: '&',
    selectedDate: '<',
  },
};

export { ManifestListComponent };
