import { IUserSession } from 'typings/user-session';
import { IShipmentListItem } from 'typings/shipment';

import { IComponentController } from 'angular';
import template from './shipment-list-taxes-cell.html?raw';
import style from './shipment-list-taxes-cell.module.scss';

class ShipmentListTaxesCell implements IComponentController {
  style = style;
  currency = '';
  isDDP = false;
  totalImportTaxes = 0;
  esShipment: IShipmentListItem | null = null;
  isDomestic!: boolean;

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  $onInit(): void {
    if (!this.esShipment) return;

    this.currency = this.esShipment.currency || this.UserSession.getCompanyCurrency();
    this.isDDP = this.esShipment.effective_incoterms === 'DDP';
    this.totalImportTaxes = this.isDDP
      ? this.esShipment.import_tax_charge +
        this.esShipment.import_duty_charge +
        this.esShipment.ddp_handling_fee
      : this.esShipment.estimated_import_tax + this.esShipment.estimated_import_duty;
    this.isDomestic =
      this.esShipment.origin_country?.alpha2 === this.esShipment.destination_country?.alpha2;
  }
}

const ShipmentListTaxesCellComponent: ng.IComponentOptions = {
  controller: ShipmentListTaxesCell,
  template,
  bindings: {
    esShipment: '<',
  },
};

export { ShipmentListTaxesCellComponent };
