import template from './shipment-courier-cell.html?raw';

class shipmentCourierCell {
  normalizeCourierName() {
    return this.courierName && this.courierName.replace(/'/g, '&#39;');
  }
}

const ShipmentCourierCellComponent = {
  controller: shipmentCourierCell,
  template,
  bindings: {
    logoUrl: '<',
    logoSize: '<',
    courierName: '<',
    esCostRank: '<',
    esDeliveryTimeRank: '<',
    esValueRank: '<',
  },
};

export { ShipmentCourierCellComponent };
