import angular from 'angular';
import { ReschedulePickupRoutes } from './index.routes';
import { ReschedulePickupComponent } from './reschedule-pickup.component';
import './handover';
import './summary';

angular
  .module('app.reschedule-pickup', [
    'ui.router',
    'app.reschedule-pickup.handover',
    'app.reschedule-pickup.summary',
  ])
  .config(ReschedulePickupRoutes)
  .component('reschedulePickup', ReschedulePickupComponent);
