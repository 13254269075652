import { useMutation } from '@tanstack/react-query';
import { useVerificationGateway } from '../providers/VerificationGatewayProvider';
import { CheckEmailVerificationPayload } from '../models/payload/verification-payload';

interface CheckVerificationParams {
  payload: CheckEmailVerificationPayload;
}

export default function useCheckVerificationMutation() {
  const { verificationGateway } = useVerificationGateway();

  return useMutation({
    mutationFn: ({ payload }: CheckVerificationParams) =>
      verificationGateway.checkEmailVerification(payload),
  });
}
