import Pusher, { Channel } from 'pusher-js';

import { IApiKeysConfig } from 'typings/core/config/api-keys';
import { IUserSession } from 'typings/user-session';
import { IApiConfig } from 'typings/core/config/api';

type ConnectionState =
  | 'initialized'
  | 'connecting'
  | 'connected'
  | 'unavailable'
  | 'failed'
  | 'disconnected';

interface IPusherConnectionStates {
  previous: ConnectionState;
  current: ConnectionState;
}

interface IPusherBindOptions {
  unavailableCallbackInterval?: number;
}

const DEFAULT_INTERVAL_IN_SECONDS = 3;

class PusherService {
  private _client: Pusher | null = null;
  private _connectionState: ConnectionState | null = null;
  private appKey: string;
  private unavailableCallback: any = {};

  static $inject = ['ApiKeys', '$rootScope', '$interval', 'API', 'UserSession'];
  constructor(
    ApiKeys: IApiKeysConfig,
    private $rootScope: ng.IScope,
    private $interval: ng.IIntervalService,
    private API: IApiConfig,
    private UserSession: IUserSession
  ) {
    this.appKey = ApiKeys.pusher;
  }

  get client(): Pusher | null {
    return this._client;
  }

  get connectionState(): ConnectionState | null {
    return this._connectionState;
  }

  private get isDevelopment(): boolean {
    return this.API.environment === 'development';
  }

  private get isProduction(): boolean {
    return this.API.environment === 'production';
  }

  initPusher(): void {
    // Enable pusher logging - don't include this in production
    if (this.isDevelopment && localStorage.pusher) {
      Pusher.logToConsole = true;
    }

    this._client = new Pusher(this.appKey, {
      cluster: 'mt1',
      channelAuthorization: {
        transport: 'ajax',
        endpoint: `${this.API.endpoint}/companies/${this.UserSession.company.id}/pusher/auth`,
        headers: {
          Authorization: `Bearer ${this.UserSession.session_token}`,
        },
      },
      forceTLS: this.isProduction,
    });

    this._client.connection.bind('state_change', (states: IPusherConnectionStates) => {
      this._connectionState = states.current;

      if (states.current === 'connected') {
        this.cancelAllUnavailableCallbacks();
      }
    });
  }

  destroyPusher(): void {
    this._client = null;
  }

  subscribe(channelName: string): Channel | null {
    if (this.client) {
      return this.client.subscribe(channelName);
    }

    return null;
  }

  unsubscribe(channelName: string): void {
    if (this.client) {
      this.client.unsubscribe(channelName);
      this.cancelUnavailableCallbacks(channelName);
    }
  }

  subscribeWithUser(channelName: string): Channel | null {
    if (this.client) {
      return this.client.subscribe(`${channelName}-${this.UserSession.getUserId()}`);
    }

    return null;
  }

  unsubscribeWithUser(channelName: string): void {
    if (this.client) {
      channelName = `${channelName}-${this.UserSession.getUserId()}`;

      this.client.unsubscribe(channelName);
      this.cancelUnavailableCallbacks(channelName);
    }
  }

  subscribeWithCompany(channelName: string): Channel | null {
    if (this.client) {
      return this.client.subscribe(`${channelName}-${this.UserSession.getCompanyId()}`);
    }

    return null;
  }

  unsubscribeWithCompany(channelName: string): void {
    if (this.client) {
      channelName = `${channelName}-${this.UserSession.getCompanyId()}`;

      this.client.unsubscribe(channelName);
      this.cancelUnavailableCallbacks(channelName);
    }
  }

  bind<T>(
    channel: Channel,
    eventName: string,
    connectedCallback: (data: T) => void,
    unavailableCallback?: () => void,
    options: IPusherBindOptions = {}
  ): void {
    const unavailableCallbackInterval =
      options.unavailableCallbackInterval ?? DEFAULT_INTERVAL_IN_SECONDS;

    const decoratedConnectedCallback = (data: T) => {
      connectedCallback(data);

      this.$rootScope.$digest();
    };

    channel.bind(eventName, decoratedConnectedCallback);

    if (this._client && unavailableCallback) {
      const callbackName = `${channel.name}-${eventName}`;
      const intervalInMilliseconds = unavailableCallbackInterval * 1000;

      const decoratedUnavailableCallback = () => {
        if (this.unavailableCallback[callbackName]) return;

        this.unavailableCallback[callbackName] = this.$interval(() => {
          // Only do the callback if user is actually using the page
          if (!document.hidden) unavailableCallback();
        }, intervalInMilliseconds);
      };

      if (this._connectionState === 'unavailable') {
        decoratedUnavailableCallback();
      }

      this._client.connection.bind('state_change', (states: IPusherConnectionStates) => {
        if (states.current === 'unavailable') {
          decoratedUnavailableCallback();
        }
      });
    }
  }

  private cancelUnavailableCallback(callbackName: string) {
    this.$interval.cancel(this.unavailableCallback[callbackName]);
    this.unavailableCallback[callbackName] = null;
  }

  private cancelUnavailableCallbacks(channelName: string) {
    Object.keys(this.unavailableCallback).forEach((callbackName) => {
      if (callbackName.includes(channelName)) {
        this.cancelUnavailableCallback(callbackName);
      }
    });
  }

  private cancelAllUnavailableCallbacks() {
    Object.keys(this.unavailableCallback).forEach((callbackName) => {
      this.cancelUnavailableCallback(callbackName);
    });
  }
}

export { PusherService };
