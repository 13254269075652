(function () {
  'use strict';

  Address.$inject = ['$http', '$resource', 'API'];
  function Address($http, $resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/companies/:company_id/addresses/:id/:controller',
      {
        company_id: '@_company_id',
        id: '@_id',
      },
      {
        query: {
          method: 'GET',
        },
        fetch: {
          method: 'GET',
        },
        create: {
          method: 'POST',
        },
        update: {
          method: 'PATCH',
        },
        delete: {
          method: 'DELETE',
        },
        createUsAddress: {
          method: 'POST',
          params: {
            controller: 'create_us',
          },
        },
        updateUsAddress: {
          method: 'PATCH',
          params: {
            controller: 'update_us',
          },
        },
        validateAddress: {
          method: 'PATCH',
          params: {
            controller: 'confirm_us',
          },
        },
        setAsDefault: {
          method: 'PATCH',
          params: {
            controller: 'set_as_default',
          },
        },
        deactivate: {
          method: 'PATCH',
          params: {
            controller: 'deactivate',
          },
        },
      }
    );
  }

  angular.module('app.factory.Address', []).factory('Address', Address);
})();
