import template from './order-summary-summary-card.html?raw';
import style from './order-summary-summary-card.module.scss';

class OrderSummarySummaryCardCtrl {
  static $inject = ['UserSession'];

  constructor(UserSession) {
    this.UserSession = UserSession;
    this.style = style;
  }

  $onInit() {
    if (!this.data.origins) return;

    this.totals = this.getTotals(this.data, this.flow);
    this.couriers = this.getCouriers(this.data, this.flow);
    this.isEfulfillment = this.UserSession.isCompanyEfulfilment();
  }

  paymentRecipientIsCourier() {
    return this.courierPaymentRecipient === 'Courier';
  }

  showRowCondition(courier) {
    if (
      this.courierPaymentRecipient &&
      ![courier.courier_payment_recipient, 'EasyshipPayOnScan'].includes(
        this.courierPaymentRecipient
      )
    ) {
      return false;
    }

    // Now that the insurance object is always present in the array of couriers
    // we need to hide the row if all the counts are 0 at the end of the flow
    if (
      courier.isInsurance &&
      !courier.shipments_count_insured &&
      !courier.shipments_count_insured_not_charged
    ) {
      return false;
    }

    if (courier.isReturn && !courier.shipments_count) {
      return false;
    }

    return true;
  }

  getTotalFor() {
    return this.flowIsReschedulePickup(this.flow)
      ? 'Reschedule Pickup'
      : this.courierPaymentRecipient;
  }

  getTotals(data, flow) {
    if (!data) return;

    if (this.flowIsReschedulePickup(flow)) {
      const calculateTotalToPayAndShipmentCount = (couriers, totalKey) => {
        return couriers.reduce(
          (agg, courier) => ({
            total_pay: agg.total_pay + courier[totalKey],
            shipments_count: agg.shipments_count + courier.shipments_count,
          }),
          { total_pay: 0, shipments_count: 0 }
        );
      };

      if (data.couriers) {
        return calculateTotalToPayAndShipmentCount(data.couriers, 'total_pay');
      }

      return data.origins.reduce(
        (agg, origin) => {
          const totalToPayAndShipmentCount = calculateTotalToPayAndShipmentCount(
            origin.couriers,
            'totalCost'
          );

          return {
            total_pay: agg.total_pay + totalToPayAndShipmentCount.total_pay,
            shipments_count: agg.shipments_count + totalToPayAndShipmentCount.shipments_count,
          };
        },
        { total_pay: 0, shipments_count: 0 }
      );
    }

    return {
      ...data.totals,
      total_vat: data.origins.reduce(
        (outerAgg, origin) =>
          outerAgg + origin.couriers.reduce((innerAgg, courier) => innerAgg + courier.vatCost, 0),
        0
      ),
    };
  }

  getCouriers(data) {
    if (!data) return;

    if (this.courierPaymentRecipient !== 'EasyshipPayOnScan') {
      if (data.couriers) return data.couriers;

      const courierDict = data.origins.reduce((agg, origin) => {
        origin.couriers.forEach((courier) => {
          if (!agg[courier.courier_id]) {
            agg[courier.courier_id] = angular.copy(courier);
          } else {
            agg[courier.courier_id].totalCost += courier.totalCost;
          }
        });
        return agg;
      }, {});

      return Object.values(courierDict);
    }
    return data.return_couriers.filter((courier) => {
      return courier.payment_recipient === 'EasyshipPayOnScan';
    });
  }

  flowIsReschedulePickup(flow) {
    return flow === 'reschedule-pickup';
  }
}

const OrderSummarySummaryCardComponent = {
  template,
  controller: OrderSummarySummaryCardCtrl,
  transclude: {
    title: '?summaryTitle',
    subtitle: '?summarySubtitle',
  },
  bindings: {
    data: '<',
    companyCurrency: '<',
    courierPaymentRecipient: '<',
    flow: '<',
  },
};

export { OrderSummarySummaryCardComponent };
