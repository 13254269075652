import angular from 'angular';

import { StatesInputGroupComponent } from './states-input-group.component';

const MODULE_NAME = 'app.global.components.advanced-search.states-input-group';

angular
  .module(MODULE_NAME, [])
  .component('esAdvancedSearchStatesInputGroup', StatesInputGroupComponent);

export default MODULE_NAME;
