import template from './address-validation-step-autocorrect.html?raw';

(function () {
  'use strict';

  angular
    .module('easyshipDashboardApp')
    .directive('addressValidationStepAutocorrect', addressValidationStepAutocorrect);

  function addressValidationStepAutocorrect() {
    var directive = {
      template,
      restrict: 'EA',
      scope: {
        address: '=',
        goToStep: '=',
        proceedWithCorrectedAddress: '=',
        busy: '=',
        correctedAddress: '=',
      },
      controller: controller,
      controllerAs: 'addressValidationStepAutocorrect',
    };

    return directive;
  }

  controller.$inject = ['$scope', 'AddressService'];

  function controller($scope, AddressService) {
    var addressValidationStepAutocorrect = this;

    addressValidationStepAutocorrect.displayAddress = AddressService.formatAddress(
      $scope.correctedAddress
    );
  }
})();
