import angular from 'angular';

import { PicklistService } from './picklist.service';

const MODULE_NAME = 'app.global.services.picklist';

angular
  .module(MODULE_NAME, [])
  .service('PicklistService', PicklistService);

export default MODULE_NAME;
