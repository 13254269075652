import angular from 'angular';
import { ShipmentsViewsDropdownComponent } from './views-dropdown.component';

const MODULE_NAME = 'app.global.shipments-views-dropdown';

angular
  .module(MODULE_NAME, [])
  .component('esShipmentsViewsDropdown', ShipmentsViewsDropdownComponent);

export default MODULE_NAME;
