import { IComponentController } from 'angular';

export default class ToolsCtrl implements IComponentController {
  static $inject = ['$state', '$rootScope'];
  static observer?: MutationObserver;

  isPackingPage = false;
  isTrialExpiryBannerDisplayed = false;

  constructor(private $state: ng.ui.IStateService, private $rootScope: ng.IScope) {}

  $onInit() {
    this.isPackingPage = this.$state.is('packing');
    this.isTrialExpiryBannerDisplayed = document.body.classList.contains('show-trial-expiry');

    this.$rootScope.$on('$stateChangeSuccess', (_, toState) => {
      this.isPackingPage = toState.name === 'packing';
    });

    if (!this.isTrialExpiryBannerDisplayed) {
      this.observeTrialExpiryBanner();
    }
  }

  observeTrialExpiryBanner() {
    if (ToolsCtrl.observer) {
      return;
    }

    ToolsCtrl.observer = new MutationObserver((mutations, observer) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          this.isTrialExpiryBannerDisplayed = (
            mutation.target as HTMLBodyElement
          ).classList.contains('show-trial-expiry');

          if (this.isTrialExpiryBannerDisplayed) {
            observer.disconnect();
            ToolsCtrl.observer = undefined;
          }
        }
      });
    });

    ToolsCtrl.observer.observe(document.body, {
      attributes: true,
    });
  }
}
