import {
  INotificationParams,
  INotificationResponse,
  INotificationUnreadCount,
} from 'typings/notification-centre';
import { IApiConfig } from 'typings/core/config/api';

export class NotificationResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor($resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = $resource(
      `${this.API.endpoint}/users/:user_id/notifications/:controller/:notification_id`,
      {},
      {
        update: { method: 'PATCH' },
        unread: {
          method: 'GET',
          params: {
            controller: 'unread_count',
          },
        },
        read: {
          method: 'PATCH',
          params: {
            controller: 'read',
          },
        },
        delete: {
          method: 'DELETE',
        },
      }
    );
  }

  getNotifications(params: INotificationParams): ng.IPromise<INotificationResponse> {
    return this.resource.get(params).$promise;
  }

  getUnreadCount(params: INotificationParams): ng.IPromise<INotificationUnreadCount> {
    return this.resource.unread(params).$promise;
  }

  readNotification(params: INotificationParams): ng.IPromise<void> {
    return this.resource.read(params, {}).$promise;
  }

  deleteNotifications(params: INotificationParams): ng.IPromise<void> {
    return this.resource.delete(params, {}).$promise;
  }
}
