import React, { useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import { getAmountClass } from './utils';

interface AdjustmentItemProps {
  title: string;
  itemCount: number;
  amount: number;
  currency: string;
  isRefunded?: boolean;
}

export default function AdjustmentItem({
  title,
  itemCount,
  amount,
  currency,
  isRefunded = false,
}: AdjustmentItemProps) {
  const amountSign = useMemo(() => {
    if (amount > 0) {
      return '+';
    }

    if (amount < 0) {
      return '-';
    }

    // Take care of case when amount is 0 in charge/refund state
    return isRefunded ? '+' : '-';
  }, [amount, isRefunded]);

  const amountClass = getAmountClass(amount);

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography className="text-ink-700" variant="body2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography className="text-ink-700" variant="body2">
          <FormattedMessage
            id="adjustments-modal.n-shipments"
            values={{
              number: itemCount,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" align="right" className={amountClass}>
          {amountSign}
          <FormattedNumber
            value={Math.abs(amount)}
            style="currency"
            currency={currency}
            minimumFractionDigits={2}
          />
        </Typography>
      </Grid>
    </Grid>
  );
}
