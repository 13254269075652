import { IComponentController } from 'angular';
import { Reports } from 'typings/reports';

import { PrintingOptionsService } from '@client/src/global/services/printing-options/printing-options.service';
import template from './document-queue.html?raw';
import style from './document-queue.module.scss';

type Busy = {
  gettingReports: boolean;
};

class DocumentQueue implements IComponentController {
  style = style;
  busy: Busy = {
    gettingReports: true,
  };

  static $inject = ['PrintingOptionsService'];
  constructor(private PrintingOptionsService: PrintingOptionsService) {}

  $onChanges(changes: any): void {
    const { esOpen } = changes;

    if (esOpen) {
      if (esOpen.currentValue) {
        this.PrintingOptionsService.getReports(0).finally(() => {
          this.busy.gettingReports = false;
        });
      } else {
        this.busy.gettingReports = true;
      }
    }
  }

  get reports(): Reports.IReport[] {
    return this.PrintingOptionsService.reports;
  }
}

const DocumentQueueComponent: ng.IComponentOptions = {
  controller: DocumentQueue,
  template,
  bindings: {
    esOpen: '<',
    esOnClose: '&',
  },
};

export { DocumentQueueComponent };
