import { useMutation } from '@tanstack/react-query';
import { FlowRecordsPayload } from '../../models/payloads/user-payload';
import { useUserGateway } from '../../providers/UserGatewayProvider';

interface UpdateFlowRecordsParams {
  userId: string;
  payload: FlowRecordsPayload;
}

export default function useUpdateFlowRecordsMutation() {
  const { userGateway } = useUserGateway();

  return useMutation({
    mutationFn: ({ userId, payload }: UpdateFlowRecordsParams) =>
      userGateway.updateFlowRecords(userId, payload),
  });
}
