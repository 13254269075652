import { MixpanelEventContext } from '@client/core/services/mixpanel/constant';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './create-shipment-modal-main.html?raw';
import style from './create-shipment-modal-main.module.scss';

class CreateShipmentModalMain {
  static $inject = [
    '$state',
    '$translate',
    'UserSession',
    'EndpointService',
    'MixpanelService',
    'ShipmentList',
    'ShipmentAction',
    'CreateShipmentModal',
    'OnboardingChecklistService',
    'editShipmentCourier',
    'ShipmentCache',
    'ShipmentListAdvancedService',
    '$timeout',
    'AppCuesService',
  ];

  constructor(
    $state,
    $translate,
    UserSession,
    EndpointService,
    MixpanelService,
    ShipmentList,
    ShipmentAction,
    CreateShipmentModal,
    OnboardingChecklistService,
    editShipmentCourier,
    ShipmentCache,
    ShipmentListAdvancedService,
    $timeout,
    AppCuesService
  ) {
    this.$state = $state;
    this.$translate = $translate;
    this.style = style;
    this.UserSession = UserSession;
    this.EndpointService = EndpointService;
    this.MixpanelService = MixpanelService;
    this.ShipmentList = ShipmentList;
    this.ShipmentAction = ShipmentAction;
    this.CreateShipmentModal = CreateShipmentModal;
    this.OnboardingChecklistService = OnboardingChecklistService;
    this.editShipmentCourier = editShipmentCourier;
    this.ShipmentCache = ShipmentCache;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.$timeout = $timeout;
    this.AppCuesService = AppCuesService;

    this.STEP_1 = 'receiver-information';
    this.STEP_2 = 'package-information';
    this.STEP_3 = 'courier-information';

    this.step = this.STEP_1;

    this.shipment = {
      parcels_attributes: [
        {
          package_data: {},
          shipment_items_attributes: [{ declared_currency: this.UserSession.company.currency }],
        },
      ],
      is_insured: false,
      incoterms: 'DDU',
    };
  }

  $onInit() {
    this.EndpointService.set('multiple');

    if (this.baseShipment) {
      this.shipment = this.ShipmentAction.duplicateAndSanitizeShipment(this.baseShipment);
    }
  }

  onNext(shipment) {
    this.shipment = angular.copy(shipment);
    this.step = this.STEP_2;
  }

  onBack() {
    this.step = this.STEP_1;
  }

  onCancel() {
    this.CreateShipmentModal.close();
  }

  onSave(shipment) {
    this.shipment = angular.copy(shipment);

    this.shipment.destination_country_id = this.shipment.destination_country.id;

    const saveApiParams = {
      company_id: this.UserSession.getCompanyId(),
      company_type: this.UserSession.getCompanyType(),
    };

    const savePayload = {
      shipment: this.shipment,
      include: 'shipment_items,list_of_couriers,store',
      context: this.mixpanelEvent?.context ?? MixpanelEventContext.ADVANCED_FLOW,
    };

    return this.ShipmentAction.singleShipmentSave(saveApiParams, savePayload)
      .then((response) => {
        this.AppCuesService.track('Advanced | Created Shipment', null, true);

        // Wait for shipment Reindex
        this.$timeout(() => {
          toastSuccess(
            this.$translate.instant('toast.create-success', {
              noun: this.$translate.instant('global.order').toLowerCase(),
            })
          );
          this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter();

          this.OnboardingChecklistService.updateOnboarding('create_shipment');

          if (response) {
            const shipmentResponse = response.shipment;
            if (
              shipmentResponse &&
              shipmentResponse.price_comparison &&
              shipmentResponse.price_comparison.rates &&
              shipmentResponse.price_comparison.rates.length > 0
            ) {
              this.step = this.STEP_3;
              this.shipment = shipmentResponse;
            } else {
              this.AppCuesService.track('Advanced | Shipment No Rates', null, true);
              this.CreateShipmentModal.close();
            }
          } else {
            this.CreateShipmentModal.close();
          }
        }, 1000);
      })
      .catch((err) => {
        let errKey = null;

        if (!err) {
          toastError(this.$translate.instant('toast.default-error'));
        } else {
          // Loop through each form field
          for (let i = 0; i < Object.keys(err.data).length; i++) {
            errKey = Object.keys(err.data)[i];

            if (typeof err.data[errKey] === 'string') {
              toastError(`${errKey} ${err.data[errKey]}`);
            } else {
              // Loop through each error
              for (let j = 0; j < err.data[errKey].length; j++) {
                toastError(`${errKey} ${err.data[errKey][j]}`);
              }
            }
          }
        }

        this.MixpanelService.track('Manual Input -  Failed', {
          error: errKey,
        });
      });
  }

  closeCompareRates() {
    this.CreateShipmentModal.close();
  }
}

const CreateShipmentModalMainComponent = {
  template,
  controller: CreateShipmentModalMain,
  bindings: {
    baseShipment: '<',
    mixpanelEvent: '<',
  },
};

export { CreateShipmentModalMainComponent };
