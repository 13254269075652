export enum MultiselectComponentModelValue {
  AnyOf = 'is_any_of',
  NoneOf = 'is_none_of',
  Known = 'is_known',
  Unknown = 'is_unknown',
}

export enum EqualityModelValue {
  EqualTo = 'is_equal_to',
  NotEqualTo = 'is_not_equal_to',
}

export enum MultiselectAttributes {
  DisplayText = 'displayText',
  DisplayableDescription = 'displayableDescription',
  DisplayableName = 'displayableName',
  Group = 'group',
  Name = 'name',
  Type = 'type',
  All = 'All',
  SelectedDate = 'selected_date',
  UmbrellaName = 'umbrella_name',
  Value = 'value',
  FilterGroup = 'filter_group',
}

export enum MultiSelectGroupKey {
  YourStores = 'Stores',
  Platforms = 'Platforms',
}
