import { IPickupsTotals } from 'typings/pickup';

import { IComponentController } from 'angular';
import template from './footer.html?raw';
import style from './footer.module.scss';
import { PickupsPageService } from '../pickups-page.service';

class Footer implements IComponentController {
  style = style;

  static $inject = ['$state', 'PickupsPageService'];
  constructor(
    private $state: ng.ui.IStateService,
    private PickupsPageService: PickupsPageService
  ) {}

  get itemsPerPage(): number {
    return this.PickupsPageService.itemsPerPage;
  }

  get totals(): IPickupsTotals | null {
    return this.PickupsPageService.totals;
  }

  get currentPage(): number {
    return this.PickupsPageService.currentPage;
  }

  onChangeItemsPerPage(itemsPerPage: number): void {
    this.PickupsPageService.currentPage = 1;
    this.PickupsPageService.itemsPerPage = itemsPerPage;
    this.PickupsPageService.isFiltered = true;

    this.PickupsPageService.getPickups();
  }

  onChangePage(page: number): void {
    this.PickupsPageService.currentPage = page;
    this.PickupsPageService.isFiltered = true;

    this.$state.go(this.$state.current.name as ng.ui.IState, { page });
  }
}

const FooterComponent: ng.IComponentOptions = {
  controller: Footer,
  template,
  bindings: {},
};

export { FooterComponent };
