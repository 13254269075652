import template from './receiver-information.html?raw';
import style from './receiver-information.module.scss';

class ReceiverInformation {
  static $inject = ['UserSession'];

  constructor(UserSession) {
    this.style = style;
    this.UserSession = UserSession;

    this.busy = {};

    this.AUSTRALIA_ID = 14;
    this.UNITED_STATES_ID = 234;
  }

  $onInit() {
    this.shipment = angular.copy(this.shipment);
  }

  onInputChange(value, model) {
    this.shipment[model] = value;

    if (model === 'postal_code') {
      this._validatePostalCode(this.shipment);
    }
  }

  onSelectChange(value, model) {
    this.shipment[model] = value;

    if (model === 'destination_country') {
      this._onCountryChange(this.shipment);
    }
  }

  onOriginChange(changes) {
    this.shipment.origin_address_id = changes.originAddressId;
    this.shipment.origin_country_id = changes.originCountryId;
    this.shipment.origin_address = changes.originAddress;
  }

  onSubmit() {
    if (this.receiverInformationForm.$invalid) return;

    this.onNext({ shipment: this.shipment });
  }

  isDomesticShipment() {
    const { destination_country_id, destination_country } = this.shipment;
    const { company } = this.UserSession;

    return (
      destination_country &&
      (destination_country.id === company.country_id ||
        destination_country_id === company.country_id)
    );
  }

  isDestinationUnitedStates() {
    return (
      this.shipment.destination_country &&
      this.shipment.destination_country.id === this.UNITED_STATES_ID
    );
  }
}

const ReceiverInformationComponent = {
  template,
  controller: ReceiverInformation,
  bindings: {
    onNext: '&',
    onCancel: '&',
    shipment: '<',
  },
};

export { ReceiverInformationComponent };
