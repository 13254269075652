import React from 'react';
import { Button, Modal } from 'easyship-components';

interface ConfirmMemberRemovalProps {
  open: boolean;
  header: string;
  content: string | JSX.Element;
  confirmText: string;
  confirmDisabled?: boolean;
  confirmClassName?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ConfirmModal({
  open,
  header,
  content,
  confirmText,
  confirmDisabled = false,
  confirmClassName = '',
  onCancel,
  onConfirm,
}: ConfirmMemberRemovalProps) {
  return (
    <Modal id="confirm-dialog" open={open} onClose={onCancel}>
      <Modal.Header className="items-center" closeButtonAriaLabel="close" title={header} />
      <Modal.Content>{content}</Modal.Content>
      <Modal.Footer>
        <Button
          color="danger"
          onClick={onConfirm}
          disabled={confirmDisabled}
          className={confirmClassName}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
