import template from './service-rating.html?raw';
import style from './service-rating.module.scss';

class ServiceRating {
  constructor() {
    this.style = style;
  }
}

const ServiceRatingComponent = {
  controller: ServiceRating,
  template,
  bindings: {
    surveysCount: '<',
    surveyRating: '<',
    name: '<',
  },
};

export { ServiceRatingComponent };
