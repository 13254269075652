import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

(function () {
  function tokenInstallationModalCtrl(
    $scope,
    $q,
    $rootScope,
    $translate,
    StoreService,
    ItemCategoryService,
    HelperService,
    API,
    MixpanelService
  ) {
    const vm = this;

    vm.API = API;
    vm.StoreService = StoreService;
    vm.ItemCategoryService = ItemCategoryService;

    vm.busy = {};
    vm.wrappers = {
      plugin(text) {
        return `<a href="https://wordpress.org/plugins/easyship-woocommerce-shipping-rates/" target="_blank" rel="noopener noreferrer">${text}</a>`;
      },
      support(text) {
        return `<a ng-href="${vm.API.help}/hc/articles/360020937892-Shipping-Zones-Checkout-Rates-WooCommerce" target="_blank" rel="noopener noreferrer">${text}</a>`;
      },
    };

    (function () {
      vm.busy.page = true;

      ItemCategoryService.getItemCategories()
        .then(function () {
          if (
            HelperService.doesKeyValueExists(
              vm.StoreService.activeStore,
              'oauth_applications.production.token'
            )
          ) {
            vm.tokenGenerated = true;
          }

          vm.categoryId = HelperService.doesKeyValueExists(
            vm.StoreService.activeStore,
            'settings.in_cart_rate.category_id'
          );

          vm.busy.page = false;
        })
        .catch(function () {
          vm.busy.page = false;
          toastError($translate.instant('toast.default-error'));
          $scope.$close();
        });
    })();

    vm.proceed = function (categoryId) {
      vm.busy.button = true;

      // Always update the category
      updateSettings(categoryId)
        .then(function () {
          // If the token has been already generated, finish
          if (vm.tokenGenerated) {
            $rootScope.$broadcast('store-dynamic-rates-updated');
            toastSuccess(
              $translate.instant('store.woocommerce-checkout-modal.notifications.updated')
            );
            vm.busy.button = false;
            $scope.$close();
          }

          // If the token has not  yet been generated > generate it and leave the modal open
          else {
            generateToken()
              .then(function () {
                toastSuccess(
                  $translate.instant('store.woocommerce-checkout-modal.notifications.generated')
                );
                $rootScope.$broadcast('store-dynamic-rates-updated');
                vm.busy.button = false;
              })
              .catch(function () {
                toastError(
                  $translate.instant('store.woocommerce-checkout-modal.notifications.create-error')
                );
                vm.busy.button = false;
              });
          }
        })
        .catch(function () {
          toastError($translate.instant('toast.default-error'));
          vm.busy.button = false;
        });
    };

    function updateSettings(categoryId) {
      if (!vm.StoreService.activeStore.settings.in_cart_rate) {
        vm.StoreService.activeStore.settings.in_cart_rate = {};
      }
      vm.StoreService.activeStore.settings.in_cart_rate.category_id = categoryId;

      return $q(function (resolve, reject) {
        StoreService.updateStore(vm.StoreService.activeStore)
          .then(function () {
            resolve();
          })
          .catch(function () {
            reject();
          });
      });
    }

    function generateToken() {
      return $q(function (resolve, reject) {
        StoreService.createOauthApplications(vm.StoreService.activeStore.id)
          .then(function (response) {
            vm.StoreService.activeStore.oauth_applications = response.oauth_applications;
            vm.tokenGenerated = true;

            if (vm.StoreService.activeStore.platform) {
              MixpanelService.track('Dynamic Rate - Success', {
                platform: vm.StoreService.activeStore.platform.css_class,
              });
            }

            resolve();
          })
          .catch(function (error) {
            if (vm.StoreService.activeStore.platform) {
              MixpanelService.track('Dynamic Rate - Failed', {
                platform: vm.StoreService.activeStore.platform.css_class,
                error_status: error.status,
                error_data: error.data,
              });
            }

            reject();
          });
      });
    }
  }

  tokenInstallationModalCtrl.$inject = [
    '$scope',
    '$q',
    '$rootScope',
    '$translate',
    'StoreService',
    'ItemCategoryService',
    'HelperService',
    'API',
    'MixpanelService',
  ];

  angular
    .module('app.service.token-installation-modal')
    .controller('tokenInstallationModalCtrl', tokenInstallationModalCtrl);
})();
