import template from './single-store-api.html?raw';
import iconProductionUrl from '@assets/images/dashboard/connect/icon-production.svg';
import iconSandboxUrl from '@assets/images/dashboard/connect/icon-sandbox.svg';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('singleStoreApi', singleStoreApi);

  function singleStoreApi() {
    var directive = {
      template,
      restrict: 'E',
      controller: controller,
      controllerAs: 'vm',
    };

    return directive;
  }

  controller.$inject = ['StoreService'];

  function controller(StoreService) {
    var vm = this;

    vm.StoreService = StoreService;
    vm.iconProductionUrl = iconProductionUrl;
    vm.iconSandboxUrl = iconSandboxUrl;

    vm.busy = {};
    vm.expandedFeature = {};

    vm.wrappers = {
      a: function (chunk) {
        return (
          '<a href="https://developers.easyship.com" target="_blank" rel="noopener">' +
          chunk +
          '</a>'
        );
      },
    };

    vm.toggleFeatureRow = function (row) {
      if (!vm.expandedFeature[row]) vm.expandedFeature = {};
      vm.expandedFeature[row] = !vm.expandedFeature[row];
    };
  }
})();
