import template from './payment-form.html?raw';
import style from './payment-form.module.scss';

class PaymentForm {
  static $inject = [
    'StripeService',
    '$q',
    'PaymentFormService',
    'PaymentSourceService',
    'PaymentIntentService',
    'CheckoutService',
    'SubscriptionPaymentService',
    'UserSession',
    '$state',
    '$rootScope',
  ];

  constructor(
    StripeService,
    $q,
    PaymentFormService,
    PaymentSourceService,
    PaymentIntentService,
    CheckoutService,
    SubscriptionPaymentService,
    UserSession,
    $state,
    $rootScope
  ) {
    this.style = style;
    this.StripeService = StripeService;
    this.PaymentFormService = PaymentFormService;
    this.PaymentSourceService = PaymentSourceService;
    this.PaymentIntentService = PaymentIntentService;
    this.CheckoutService = CheckoutService;
    this.SubscriptionPaymentService = SubscriptionPaymentService;
    this.UserSession = UserSession;
    this.$q = $q;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.cardHolderName = '';
    this.cardHolderAddress = '';
    this._cardInputInstance = null;
  }

  $onInit() {
    this._validateBindings();
    this.creditCardId = `${this.esId}-card`;
    this.PaymentFormService.registerPaymentForm({
      id: this.esId,
      onSubmit: this.onSubmit.bind(this),
      onReset: this._onReset.bind(this),
    });
  }

  $onDestroy() {
    this.PaymentFormService.unregisterPaymentForm(this.esId);
  }

  registerCardComponent(card) {
    this._cardInputInstance = card;
  }

  onCardHolderNameChange(value) {
    this.cardHolderName = value;
  }

  onCardHolderAddressChange(value) {
    this.cardHolderAddress = value;
  }

  onSubmit() {
    return this.$q.when(this._submitForm());
  }

  _onReset() {
    if (this._cardInputInstance) {
      this._cardInputInstance.clear();
    }

    this.cardHolderName = '';
    this.cardHolderAddress = '';

    // TODO: Replace this with $getControls() when upgrading to angularjs 1.7
    this.formController.$$controls.forEach((ngModelController) => {
      ngModelController.$setPristine();
      ngModelController.$setUntouched();
    });

    this.formController.$submitted = false;
    this.formController.$setPristine();
    this.formController.$setUntouched();
  }

  async _submitForm() {
    const stripe = await this.StripeService.getInstance();

    try {
      return await this._handleOneTimePayment(stripe, this.esIsSubscriptionForm);
    } catch (err) {
      this.PaymentSourceService.paymentAmount = null;

      this.$rootScope.$broadcast('payment-failed');

      throw err;
    }
  }

  async _handleOneTimePayment(stripe, isSubscription = false) {
    // 1. create a payment method (register card) through Stripe.js
    const paymentMethod = await stripe.createPaymentMethod('card', this._cardInputInstance, {
      billing_details: { name: this.cardHolderName },
    });

    if (paymentMethod.error) {
      // eslint-disable-next-line no-throw-literal
      throw { data: { error_code: paymentMethod.error.type } };
    }

    if (this.formController.$invalid) {
      this.formController.$valid = false;
      this.formController.$submitted = false;

      throw new Error({});
    }

    this.formController.$submitted = true;
    if (isSubscription && this.UserSession.getCompanyId()) {
      if (paymentMethod.error) return null;

      return this.SubscriptionPaymentService.setupIntentsCreate(
        { company_id: this.UserSession.getCompanyId() },
        paymentMethod.paymentMethod,
        this._cardInputInstance
      );
    }

    return this.PaymentSourceService.pay(paymentMethod);
  }

  _validateBindings() {
    if (typeof this.esId !== 'string' || !this.esId.length) {
      throw new Error('EasyshipPaymentForm: An es-id binding must be provided.');
    }
  }
}

const PaymentFormComponent = {
  controller: PaymentForm,
  template,
  bindings: {
    esId: '@',
    esShowAddress: '<',
    esIsSubscriptionForm: '<',
    ngDisabled: '<',
  },
};

export { PaymentFormComponent };
