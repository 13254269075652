import { FilterQuery } from './types';

export default function sanitizeQuery(filterQuery: FilterQuery): FilterQuery {
  return Object.keys(filterQuery)
    .filter((queryKey) => {
      const qValue = filterQuery[queryKey];
      if (queryKey === 'sidebar') {
        return Object.values(qValue).some((value) => !!value);
      }

      if (Array.isArray(qValue)) return !!qValue.length;
      return !!qValue;
    })
    .reduce((agg, key) => ({ ...agg, [key]: filterQuery[key] }), {});
}
