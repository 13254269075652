'use strict';

/**
 * AngularJS is not able to know if an input file is filled with the ng-required attribute.
 * This directive fix this issue.
 */
angular.module('easyshipDashboardApp').directive('validInputFile', function () {
  return {
    require: 'ngModel',
    link: function (scope, el, attrs, ngModel) {
      el.bind('change', function () {
        scope.$apply(function () {
          ngModel.$setViewValue(el.val());
          ngModel.$render();
        });
      });
    },
  };
});
