import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

class ExportService {
  static $inject = ['$window', '$q', '$translate', 'Export', 'API', 'UserSession'];

  constructor($window, $q, $translate, Export, API, UserSession) {
    this.$window = $window;
    this.$q = $q;
    this.$translate = $translate;
    this.Export = Export;
    this.API = API;
    this.UserSession = UserSession;
  }

  // Makes a POST with payload as filter, and receives an id as a response. Then passes the id as a GET request to receive a CSV to download
  getCSV(filters) {
    return this.$q((resolve, reject) => {
      this.Export.create(filters)
        .$promise.then((response) => {
          this._handleGetCSVResponse(response, filters);
          resolve();
        })
        .catch((error) => {
          const errorCode = error.data?.error_code;
          if (errorCode) {
            toastError(
              this.$translate.instant(`header.${errorCode}`, { email: this.UserSession.user.email })
            );
          } else {
            toastError(
              this.$translate.instant('header.export-error', { email: this.UserSession.user.email })
            );
          }
          reject();
        });
    });
  }

  _handleGetCSVResponse(response) {
    if (response.method === 'email') {
      toastSuccess(
        this.$translate.instant('header.export-email', { email: this.UserSession.user.email })
      );
    } else {
      this.$window.location.assign(`${this.API.endpoint}/export/${response.id}`);
    }
  }
}

export { ExportService };
