(function () {
  OauthPlatformApi.$inject = ['$resource', 'API'];
  function OauthPlatformApi($resource, API) {
    const endpointUrl = API.endpoint;

    return $resource(
      `${endpointUrl}/companies/:company_id/:platform_name/stores/:controller`,
      { company_id: '@_company_id' },
      {
        authenticate: {
          method: 'POST',
          params: { controller: 'oauth_link' },
        },
        connect: {
          method: 'POST',
        },
      }
    );
  }
  angular.module('app.factory.OauthPlatformApi', []).factory('OauthPlatformApi', OauthPlatformApi);
})();
