import React from 'react';
import { IntlConfig, IntlProvider } from 'react-intl';
import { useGetLocalizedMessages } from '@/providers/I18nProvider/useGetLocalizedMessages';
import { EasyshipLocale } from '@/types/locale';

const defaultRichTextElements: IntlConfig['defaultRichTextElements'] = {
  b: (parts) => <strong>{parts}</strong>,
  // @ts-expect-error: This works, but 'react-intl' typing is wrong and expect a function
  br: <br />,
};

interface IntlProviderProps {
  children: React.ReactNode;
  selectedLocale?: EasyshipLocale;
}

export default function I18nProvider({ children, selectedLocale }: IntlProviderProps) {
  const { messages, locale } = useGetLocalizedMessages(selectedLocale);

  if (!messages) return null;
  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      defaultLocale="en"
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </IntlProvider>
  );
}
