import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import { PlatformGatewayProvider } from '@client/src/platform/providers/PlatformGatewayProvider';
import { useActivePlatformsQuery } from '@client/src/platform/hooks/useActivePlatformsQuery';
import Link from '@material-ui/core/Link';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { PlatformData } from '@client/src/platform/models/platform';
import { EcommercePlatform, Platform } from '@client/data/platform';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import EventListener, { CallbackListener } from '@client/src/global/context/EventListener';
import { IStore } from 'typings/store';
import { IconButton } from 'easyship-components';
import { CrossLarge } from 'easyship-components/icons';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { RequestPlatform } from '@client/src/connect/request-platform/RequestPlatform';
import clsx from 'clsx';
import { PlatformCard } from '../../components/PlatformCard';
import { PlatformModalFormLayout } from '../../components/PlatformModalFormLayout';
import { ConnectStoreGatewayProvider } from '../../providers/ConnectStoreProvider';

interface NewConnectPageProps {
  onPlatformCardClicked(platform: PlatformData): void;
  onStoreCreated(store: IStore): void;
  selectedPlatform?: PlatformData;
  onPlatformModalClosed(): void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function NewConnectPage({
  onPlatformCardClicked,
  onStoreCreated,
  onPlatformModalClosed,
  selectedPlatform,
}: NewConnectPageProps) {
  const { formatMessage } = useIntl();

  const { user } = useUserSession();

  const [storeCreated] = useState(new CallbackListener());

  useEffect(() => {
    storeCreated.register(onStoreCreated);
    return () => storeCreated.unregister(onStoreCreated);
  }, [onStoreCreated, storeCreated]);

  const platformApi: PlatformData = {
    displayName: formatMessage({ id: 'connect.api-integration' }),
    cssClass: 'api',
    id: 1,
    featured: false,
    crowdfunding: false,
    name: 'api',
  };

  useEffect(() => {
    MixpanelService.track('Connect Page - Arrived');
  }, []);

  const { data: activePlatform, isFetching } = useActivePlatformsQuery();

  function handlePlatformClicked(platform: PlatformData): void {
    onPlatformCardClicked?.(platform);
    MixpanelService.track('Connect Page - Platform Logo - Click', { platform_name: platform });
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <EventListener.Provider value={{ storeCreated }}>
      <div className="ml-auto mr-auto w-[900px]">
        {isFetching && <div className="spinner" />}
        {!isFetching && (
          <div>
            {!user.isDuda() && (
              <>
                <div className="pt-12 pl-12 pr-12">
                  <div className="mb-7">
                    <p className="mb-3 text-xl text-center text-ink-900">
                      <FormattedMessage id="connect.header" />
                    </p>
                    <p className="mb-3 text-lg text-center text-ink-500">
                      <FormattedMessage id="connect.subheader" />
                    </p>
                  </div>
                </div>
                <div className="pb-7 w-[630px] ml-auto mr-auto flex items-center justify-center gap-x-4 gap-y-7 flex-wrap rounded rounded-tl-lg rounded-bl-lg">
                  {activePlatform?.platforms.map((platform) => (
                    <div key={platform.displayName} className="w-[30%]">
                      <PlatformCard
                        onPlatformClicked={() => {
                          handlePlatformClicked(platform);
                        }}
                        platform={platform}
                      />
                    </div>
                  ))}
                </div>
                <div className="w-[630px] mx-auto mt-7 mb-12">
                  <RequestPlatform />
                </div>
              </>
            )}
            <div className={clsx('w-[630px] ml-auto mr-auto', user.isDuda() ? 'mt-16' : 'my-7')}>
              <p className="mb-3 text-xl text-center text-ink-900">
                <FormattedMessage id="connect.api-integration" />
              </p>
              <p className="text-lg text-center mb-7 text-ink-500">
                <FormattedMessage
                  id="connect.api-integration-notes"
                  values={{
                    // eslint-disable-next-line react/no-unstable-nested-components, jsx-a11y/anchor-is-valid
                    a: (chunks: string) => (
                      <Link href="https://developers.easyship.com" target="_blank" rel="noopener">
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              </p>
              <div className="w-[30%] ml-auto mr-auto">
                <PlatformCard
                  onPlatformClicked={() => {
                    handlePlatformClicked(platformApi);
                  }}
                  platform={platformApi}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {!!selectedPlatform && (
        <Dialog
          TransitionComponent={Transition}
          TransitionProps={{ timeout: 400 }}
          maxWidth="sm"
          fullWidth
          open={!!selectedPlatform}
        >
          <div className="bg-white rounded-md">
            <div className="flex justify-end w-full pt-4 pr-5">
              <IconButton
                flat
                tooltipProps={{
                  label: 'Close',
                  usePortal: true,
                  autoPlacement: ['top'],
                }}
                className=" p-0 top-[4%] right-[4%]"
                onClick={onPlatformModalClosed}
                aria-label="Close"
              >
                <CrossLarge />
              </IconButton>
            </div>
            <div className="px-12 pb-7">
              <PlatformModalFormLayout platform={selectedPlatform} />
            </div>
          </div>
        </Dialog>
      )}
    </EventListener.Provider>
  );
}

export function WrappedNewConnectPage({
  onPlatformCardClicked,
  onStoreCreated,
}: Omit<NewConnectPageProps, 'selectedPlatform' | 'onPlatformModalClosed'>): ReactElement {
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformData | undefined>(undefined);

  function handlePlatformClicked(platform: PlatformData): void {
    if (platform.cssClass === EcommercePlatform.NetSuite) {
      onPlatformCardClicked(platform);
    } else {
      setSelectedPlatform(platform);
    }
  }

  return (
    <ReactRootProviders>
      <PlatformGatewayProvider>
        <UserSessionProvider>
          <ConnectStoreGatewayProvider platformName={selectedPlatform?.cssClass as Platform}>
            <NewConnectPage
              onPlatformModalClosed={() => setSelectedPlatform(undefined)}
              selectedPlatform={selectedPlatform}
              onPlatformCardClicked={handlePlatformClicked}
              onStoreCreated={onStoreCreated}
            />
          </ConnectStoreGatewayProvider>
        </UserSessionProvider>
      </PlatformGatewayProvider>
    </ReactRootProviders>
  );
}

export const AngularNewConnectPage = react2angular(WrappedNewConnectPage, [
  'onPlatformCardClicked',
  'onStoreCreated',
]);
