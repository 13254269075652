/* eslint-disable import/extensions */

import angular from 'angular';

import weightInputGroup from './weight-input-group';
import itemDescriptionInputGroup from './item-description-input-group';
import statesInputGroup from './states-input-group';

import { ShipmentsAdvancedSearchSidebarComponent } from './shipments-advanced-search-sidebar/shipments-advanced-search-sidebar.component';
import { OrderStatusRadioGroupComponent } from './order-status-radio-group/order-status-radio-group.component';
import { ProductSkuInputGroupComponent } from './product-sku-input-group/product-sku-input-group.component';
import { CollectStateMultiSelectGroupComponent } from './collect-state-multi-select-group/collect-state-multi-select-group.component';
import { CouriersInputGroupComponent } from './couriers-input-group/couriers-input-group.component';
import { CountriesInputGroupComponent } from './countries-input-group/countries-input-group.component';
import { SidebarInputGroupComponent } from './sidebar-input-group/sidebar-input-group.component';
import { NumberOfItemsInputGroupComponent } from './number-of-items-input-group/number-of-items-input-group.component';
import { SkuCountInputGroupComponent } from './sku-count-input-group/sku-count-input-group.component';
import { TbcRadioGroupComponent } from './tbc-radio-group/tbc-radio-group.component';
import { DateInputGroupComponent } from './date-input-group/date-input-group.component';
import { InsuranceMultiSelectGroupComponent } from './insurance-multi-select-group/insurance-multi-select-group.component';
import { WarehouseStatusMultiSelectGroupComponent } from './warehouse-status-multi-select-group/warehouse-status-multi-select-group.component';
import { PickupsMultiSelectGroupComponent } from './pickups-multi-select-group/pickups-multi-select-group.component';
import { BatchesMultiSelectGroupComponent } from './batches-multi-select-group/batches-multi-select-group.component';
import { ManifestsMultiSelectGroupComponent } from './manifests-multi-select-group/manifests-multi-select-group.component';
import { BoxesMultiSelectGroupComponent } from './boxes-multi-select-group/boxes-multi-select-group.component';
import { IncotermsMultiSelectGroupComponent } from './incoterms-multi-select-group/incoterms-multi-select-group.component';
import { ShipmentStatusMultiSelectGroupComponent } from './shipment-status-multi-select-group/shipment-status-multi-select-group.component';
import { FulfillmentStatusMultiSelectGroupComponent } from './fulfillment-status-multi-select-group/fulfillment-status-multi-select-group.component';
import { LabelStatusMultiSelectGroupComponent } from './label-status-multi-select-group/label-status-multi-select-group.component';
import { StorePlatformMultiSelectGroupComponent } from './store-platform-multi-select-group/store-platform-multi-select-group.component';
import { TagsInputGroupComponent } from './tags-input-group/tags-input-group.component';
import { AddressMultiSelectGroupComponent } from './address-multi-select-group/address-multi-select-group.component';
import { HandlersMultiSelectGroupComponent } from './handlers-multi-select-group/handlers-multi-select-group.component';
import { MissingFieldMultiSelectGroupComponent } from './missing-field-multi-select-group/missing-field-multi-select-group.component';
import { OrderNumberInputGroupComponent } from './order-number-input-group/order-number-input-group.component';
import { ShipmentIdInputGroupComponent } from './shipment-id-input-group/shipment-id-input-group.component';
import { PickLocationInputGroupComponent } from './pick-location-input-group/pick-location-input-group.component';

const MODULE_NAME = 'app.global.components.advanced-search';

const prefix = 'AdvancedSearch';

angular
  .module(MODULE_NAME, [itemDescriptionInputGroup, statesInputGroup, weightInputGroup])
  .component(`esShipments${prefix}Sidebar`, ShipmentsAdvancedSearchSidebarComponent)
  .component(`es${prefix}NumberOfItemsInput`, NumberOfItemsInputGroupComponent)
  .component(`es${prefix}SkuCountInput`, SkuCountInputGroupComponent)
  .component(`es${prefix}OrderStatusRadioGroup`, OrderStatusRadioGroupComponent)
  .component(`es${prefix}ProductSkuInputGroup`, ProductSkuInputGroupComponent)
  .component(`es${prefix}CouriersInputGroup`, CouriersInputGroupComponent)
  .component(`es${prefix}CountriesInputGroup`, CountriesInputGroupComponent)
  .component(`es${prefix}SidebarInputGroup`, SidebarInputGroupComponent)
  .component(`es${prefix}TbcRadioGroup`, TbcRadioGroupComponent)
  .component(`es${prefix}CollectStateMultiSelectGroup`, CollectStateMultiSelectGroupComponent)
  .component(`es${prefix}DateInputGroup`, DateInputGroupComponent)
  .component(`es${prefix}InsuranceMultiSelectGroup`, InsuranceMultiSelectGroupComponent)
  .component(`es${prefix}IncotermsMultiSelectGroup`, IncotermsMultiSelectGroupComponent)
  .component(`es${prefix}WarehouseStatusMultiSelectGroup`, WarehouseStatusMultiSelectGroupComponent)
  .component(`es${prefix}PickupsMultiSelectGroup`, PickupsMultiSelectGroupComponent)
  .component(`es${prefix}BatchesMultiSelectGroup`, BatchesMultiSelectGroupComponent)
  .component(`es${prefix}ManifestsMultiSelectGroup`, ManifestsMultiSelectGroupComponent)
  .component(`es${prefix}BoxesMultiSelectGroup`, BoxesMultiSelectGroupComponent)
  .component(`es${prefix}ShipmentStatusMultiSelectGroup`, ShipmentStatusMultiSelectGroupComponent)
  .component(
    `es${prefix}FulfillmentStatusMultiSelectGroup`,
    FulfillmentStatusMultiSelectGroupComponent
  )
  .component(`es${prefix}LabelStatusMultiSelectGroup`, LabelStatusMultiSelectGroupComponent)
  .component(`es${prefix}StorePlatformMultiSelectGroup`, StorePlatformMultiSelectGroupComponent)
  .component(`es${prefix}TagsInputGroup`, TagsInputGroupComponent)
  .component(`es${prefix}AddressMultiSelectGroup`, AddressMultiSelectGroupComponent)
  .component(`es${prefix}HandlersMultiSelectGroup`, HandlersMultiSelectGroupComponent)
  .component(`es${prefix}MissingFieldMultiSelectGroup`, MissingFieldMultiSelectGroupComponent)
  .component(`es${prefix}OrderNumberInputGroup`, OrderNumberInputGroupComponent)
  .component(`es${prefix}ShipmentIdInputGroup`, ShipmentIdInputGroupComponent)
  .component(`es${prefix}PickLocationInputGroup`, PickLocationInputGroupComponent);

export default MODULE_NAME;
