(function () {
  'use strict';

  TikTokService.$inject = ['Auth', 'PlatformApi', 'UserSession', 'StoreService'];
  function TikTokService(Auth, PlatformApi, UserSession, StoreService) {
    var service = this;

    service.authenticate = function (platform_id) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.tiktok.authenticate(
          { company_id: UserSession.company.id },
          { platform_id: platform_id }
        ).$promise;
      });
    };

    service.connectStore = function (newStore) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.tiktok
          .connect({ company_id: UserSession.company.id }, { store: newStore })
          .$promise.then(function (response) {
            StoreService.saveStoreValueToLocal(response.store);
            return response;
          });
      });
    };
  }

  angular.module('app.service.TikTokService', []).service('TikTokService', TikTokService);
})();
