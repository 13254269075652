(function () {
  'use strict';

  SquareService.$inject = ['$window', 'PlatformApi', 'StoreService', 'UserSession', 'Auth'];
  function SquareService($window, PlatformApi, StoreService, UserSession, Auth) {
    var service = this;

    service.authenticate = function () {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.square
          .authenticate({ company_id: UserSession.company.id }, {})
          .$promise.then(function (response) {
            // Save state in local storage for validation
            var params = service.getParams(response.redirect_url);

            if (params.state) {
              $window.localStorage.setItem('squareState', params.state);
            }

            return response;
          });
      });
    };

    service.connectStore = function (newStore) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.square
          .connect({ company_id: UserSession.company.id }, { store: newStore })
          .$promise.then(function (response) {
            StoreService.saveStoreValueToLocal(response.store);
            return response;
          });
      });
    };

    service.getParams = function (url) {
      var parsedUrl = new URL(url);
      var state = parsedUrl.searchParams.get('state');
      var code = parsedUrl.searchParams.get('code');

      return {
        state: state,
        code: code,
      };
    };
  }

  angular.module('app.service.SquareService', []).service('SquareService', SquareService);
})();
