import { toastError, toastSuccess } from '@client/core/components/react/Toastify';

/* eslint-disable no-param-reassign */
(function () {
  function createNewAddressModalCtrl(
    $scope,
    $rootScope,
    $translate,
    UserSession,
    HkDistrict,
    stateParams,
    modalParams,
    AddressService,
    CountryService,
    PostalCodeService,
    AddressyService,
    CourierService,
    CourierAccounts,
    MixpanelService
  ) {
    $scope.UserSession = UserSession;
    $scope.AddressService = AddressService;
    $scope.AddressyService = AddressyService;
    $scope.modalParams = modalParams;
    $scope.CountryService = CountryService;

    $scope.maxLength = {
      contact_name: 22,
      company_name: 27,
      line_1: 35,
      line_2: 35,
    };

    $scope.hasAddresses = $scope.modalParams.hasAddresses;
    $scope.address = { city: $scope.cityName };
    $scope.stateParams = stateParams || null;
    $scope.busy = false;
    $scope.postalCodeBusy = false;
    $scope.addressyInfo = {};
    $scope.step = {};
    $scope.lengthErrorMessage = {};
    $scope.allowForeignAddress = false;
    $scope.fieldValidators = {};

    const translations = {
      address: $translate
        .instant('global.pluralize.address', { COUNT: 1 }, 'messageformat')
        .toLowerCase(),
    };

    (function init() {
      $scope.states = CountryService.getUSStates().$$state.value;

      if ($scope.modalParams.openingStep) {
        $scope.step[$scope.modalParams.openingStep] = true;
      } else {
        $scope.step.form = true;
      }

      if ($scope.modalParams.correctedAddress)
        $scope.correctedAddress = $scope.modalParams.correctedAddress;
      if ($scope.modalParams.correctedAddresses)
        $scope.correctedAddresses = $scope.modalParams.correctedAddresses;

      // Fetch HK districts for HK customers
      if ($scope.UserSession.company.country_id === 96) $scope.hk_districts = HkDistrict.query();

      $scope.address = {
        company_name: UserSession.company.name,
        contact_name: `${UserSession.user.first_name} ${UserSession.user.last_name}`,
        contact_email: UserSession.user.email,
        contact_phone: UserSession.user.mobile_phone,
        country: {
          name: null,
        },
        country_id: UserSession.company.country_id,
        city: null,
      };

      $scope.$watch('address.country_id', function (countryId) {
        $scope.AddressService.getFieldValidators(countryId).then(function (fieldValidators) {
          $scope.fieldValidators = fieldValidators;
        });
      });

      if ($scope.modalParams.address) {
        $scope.address = _.extend($scope.address, $scope.modalParams.address);
      }

      $scope.allowForeignAddress =
        !!UserSession.getCompanyDashboardSettings().beta_feature_global_account;
    })();

    $scope.closeModal = function () {
      $scope.$close();
    };

    $scope.proceed = function (address, params) {
      if (address.country_id === 234) {
        createOrUpdateUsAddress(address, $scope.addressyInfo);
      } else {
        createOrUpdateNonUsAddress(address, params);
      }
    };

    $scope.findStateFromPostalCode = function (postalCode) {
      $scope.busyFindingAddress = true;

      const params = {
        postal_code: postalCode,
        country_id: UserSession.company.country_id,
      };

      PostalCodeService.getState(params)
        .then(function (res) {
          $scope.address.state = res.state.name;
          $scope.showNoStateMatchError = false;
          $scope.busyFindingAddress = false;
        })
        .catch(function () {
          delete $scope.address.state;
          $scope.addressNotFoundMessage = '';
          $scope.showNoStateMatchError = true;
          $scope.busyFindingAddress = false;
        });
    };

    $scope.showInputErrorMessage = function (field) {
      if ($scope.address[field].length > $scope.maxLength[field]) {
        $scope.lengthErrorMessage[field] = true;
      } else {
        $scope.lengthErrorMessage[field] = false;
      }
    };

    $scope.toggleAddressyForm = function () {
      $scope.showUsBackupForm = !$scope.showUsBackupForm;

      // Set the $scope.addressyInfo.Id to null if user is going into manual input mode to ensure
      // the manual address is used instead
      if ($scope.showUsBackupForm) $scope.addressyInfo.Id = null;
      if ($scope.showUsBackupForm && $scope.address.fullAddress) delete $scope.address.fullAddress;
    };

    function createOrUpdateNonUsAddress(address, params) {
      // Prevents the function from running when user is in the middle of finding a state with postal code
      // - this situation happens when user enters a valid postal code, then changes it to an invalid one and
      // immediately clicking on add address
      if ($scope.busyFindingAddress) return;

      $scope.busy = true;
      $scope.addressNotFoundMessage = '';

      if ($scope.modalParams.newAddress) {
        AddressService.createAddress(address, params).then(
          function () {
            $scope.busy = false;

            MixpanelService.track('Account - Address Validation Check', {});
            toastSuccess(
              $translate.instant('toast.create-success', { noun: translations.address })
            );

            // If it is the first address
            if (AddressService.getShippingAddresses().length === 1) {
              $rootScope.$broadcast('first-address-created');
            }

            $scope.$close();
          },
          function (err) {
            $scope.busy = false;
            handleError(err);
          }
        );
      } else {
        address.id = $scope.modalParams.address.id;

        AddressService.updateAddress(address, params).then(
          function () {
            $scope.busy = false;
            _refreshCourierCaches();
            MixpanelService.track('Skipped shipper address validation', {});
            toastSuccess(
              $translate('toast.update-success', { noun: translations.address }, 'messageformat')
            );
            $scope.$close();
          },
          function (err) {
            $scope.busy = false;
            handleError(err);
          }
        );
      }
    }

    function createOrUpdateUsAddress(address, addressyInfo) {
      // Need to manually check
      //  - if there is a property called state
      //  - the state input is filled
      //
      // If both is true then return and do nothing - to prevent null being submited
      // (the ng-disable on the input field is preventing ng-pattern working)
      if (!address.state && $scope.showUsBackupForm) return;

      $scope.busy = true;

      if (address.deleteAddressyId) delete address.addressy_id;

      if ($scope.modalParams.newAddress) {
        address.addressy_id = addressyInfo.Id;

        AddressService.createAddress(address)
          .then(function (res) {
            if (res.usps_pickup_validation_state === 'invalid') {
              handleInvalidUspsAddress(res);
            } else {
              _refreshCourierCaches();

              // If it is the first address
              if (AddressService.getShippingAddresses().length === 1) {
                $rootScope.$broadcast('first-address-created');
              }

              toastSuccess(
                $translate.instant('toast.create-success', { noun: translations.address })
              );

              $scope.busy = false;
              $scope.$close();
            }
          })
          .catch(function (err) {
            handleError(err);
            $scope.busy = false;
          });
      } else {
        AddressService.updateAddress(address)
          .then(function (res) {
            if (res.address.usps_pickup_validation_state === 'invalid') {
              if (address.old_addressy_id) res.address.old_addressy_id = address.old_addressy_id;
              handleInvalidUspsAddress(res.address);
            } else {
              _refreshCourierCaches();

              toastSuccess(
                $translate.instant(
                  'toast.update-success',
                  { noun: translations.address },
                  'messageformat'
                )
              );

              $scope.busy = false;
              $scope.$close();
            }
          })
          .catch(function (err) {
            $scope.busy = false;
            handleError(err);
          });
      }
    }

    function handleError(err) {
      if (err.data.status) {
        formatAndShowErrorMessages(err.data.status);
      } else {
        handleAddressCreationErrors(err.data);
      }
    }

    /**
     * [goToStep] Go the next validation step
     * @param  {String} stepName
     */
    $scope.goToStep = function (stepName) {
      // If the user is editing an address
      if (stepName === 'form' && !$scope.modalParams.newAddress) {
        $scope.$close();
        return;
      }

      $scope.step = {};
      $scope.step[stepName] = true;
    };

    /**
     * [proceedWithCorrectedAddress] pass adress into formatUSPSAddress before proceeding
     * @param  {Object} address
     * @param  {Object} params
     */
    $scope.proceedWithCorrectedAddress = function (address, params) {
      const formattedAddress = formatUSPSAddress(address);
      $scope.proceed(formattedAddress, params);
    };

    /**
     * [formatUSPSAddress] format USPS address to Easyship format (before saving)
     * @param  {Object} USPSAddress
     * @return {Object}
     */
    function formatUSPSAddress(USPSAddress) {
      const addressCopy = angular.copy(USPSAddress);

      if (addressCopy.displayAddress) delete addressCopy.displayAddress;

      addressCopy.state = _.find(CountryService.USStates, { abbr: addressCopy.state }).name;

      const formattedAddress = _.extend(addressCopy, {
        contact_name: $scope.address.contact_name,
        contact_phone: $scope.address.contact_phone,
        contact_email: $scope.address.contact_email,
        company_name: $scope.address.company_name,
      });

      return formattedAddress;
    }

    /**
     * [handleAddressCreationErrors] Determine the behavior of the modal according the error message sent by the validation
     * @param  {Object} errorResponse: data of the error sent by the backend
     */
    function handleAddressCreationErrors(errorResponse) {
      switch (errorResponse.response_code) {
        case '31':
        case '32':
        case '22':
          if (errorResponse.response.suggested_addresses.length > 0) {
            $scope.correctedAddresses = AddressService.formatCorrectedAddresses(
              errorResponse.response.suggested_addresses
            );
            $scope.goToStep('multi');
          } else if (Object.keys(errorResponse.response.updated_fields).length > 0) {
            $scope.correctedAddress = AddressService.formatCorrectedAddress(
              errorResponse.response.output_address
            );
            $scope.goToStep('autocorrect');
          }
          break;
        default:
          if ($scope.step.sender) {
            $scope.skipAddressInfo = errorResponse.response.message;
          } else {
            $scope.addressNotFoundMessage = errorResponse.response.message;
          }
          break;
      } // switch
    }

    function formatAndShowErrorMessages(errObj) {
      if (typeof errObj === 'string') {
        toastError(
          $translate.instant('toast.create-error', {
            noun: translations.address,
            details: errObj,
          })
        );
        return;
      }

      const formattedErrors = [];

      Object.keys(errObj).forEach(function (key) {
        const error = `${key.replace(/_/g, ' ')} ${errObj[key].join(', ')}`;
        formattedErrors.push(error);
      });

      toastError(
        $translate.instant('toast.create-error', {
          noun: translations.address,
          details: formattedErrors.join(', '),
        })
      );
    }

    /**
     * [handleInvalidUspsAddress] prepare the address data so that it is ready to be deleted
     * @param  {[type]} res [description]
     * @return {[type]}     [description]
     */
    function handleInvalidUspsAddress(res) {
      $scope.step.usps = true;
      if ($scope.modalParams.newAddress) {
        $scope.step.form = false;
      } else {
        $scope.step.sender = false;
      }

      $scope.address = res;
      $scope.busy = false;
    }

    function _refreshCourierCaches() {
      CourierService.getCouriers();
      CourierAccounts.getActiveCourierAccounts();
    }
  }

  createNewAddressModalCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$translate',
    'UserSession',
    'HkDistrict',
    'stateParams',
    'modalParams',
    'AddressService',
    'CountryService',
    'PostalCodeService',
    'AddressyService',
    'CourierService',
    'CourierAccounts',
    'MixpanelService',
  ];

  angular
    .module('app.service.createNewAddressModal')
    .controller('createNewAddressModalCtrl', createNewAddressModalCtrl);
})();
