import { IComponentController, IPromise } from 'angular';
import { Channel } from 'pusher-js';
import { Id, toast } from 'react-toastify';
import { DebouncedFunc, debounce } from 'lodash';

import { FeatureKey, FEATURE_KEY } from '@client/data/subscription';

import { PusherService } from '@client/src/global/services/pusher/pusher.service';
import { toastError, toastMessage, toastSuccess } from '@client/core/components/react/Toastify';

import { BulkEdit } from 'typings/bulk-edit';

import {
  DashboardScopeType,
  IShipmentDashboardScopesUpdateData,
  IShipmentListScope,
  ShipmentId,
  ShipmentLastStatusMessageUpdateResponse,
} from 'typings/shipment';
import { IManifestModalService } from 'typings/manifest';
import { IUserSession } from 'typings/user-session';
import { ICompanyService } from 'typings/company';
import { IAddressService } from 'typings/address';
import { IReportService } from 'typings/report';
import { IHelperService } from 'typings/helper';
import { IExportService, ICSVFiltersParams } from 'typings/export';
import { ICheckoutService } from 'typings/checkout';
import { ICourierAccountsService } from 'typings/courier';

import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { BulkCancelService } from '@client/src/global/services/bulk-cancel/bulk-cancel.service';
import { PicklistService } from '@client/src/global/services/picklist/picklist.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { ShipmentListManageService } from '@client/src/global/services/shipment-list/shipment-list-manage.service';
import { ModalService } from '@client/src/global/modals/modals.service';
import { showToastWithAction } from '@client/core/components/react/ToastWithAction';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { ShipmentListSectionName } from '@client/src/manage-shipments/constants';
import { MODAL, openModalByName } from '@/utils/angular-modal';
import style from './dashboard-header-shipments.module.scss';
import template from './dashboard-header-shipments.html?raw';

// Allowance time for Elastic search to reindex the shipments
const REFRESH_SHIPMENTS_DELAY = 1000;
const BULK_CANCEL_NOTIFICATION_DELAY = 3000;
const UPDATE_SHIPMENT_STATUS_COUNT_DELAY = 5000;

enum DashboardScope {
  OrdersAll = 'orders_all',
  ShipmentsAll = 'shipments_all',
  Pending = 'pending',
  Rejected = 'rejected',
  ToDownload = 'to_download',
}

enum SectionPrint {
  ShipmentsAll = 'shipments-all',
  ShipmentsDownload = 'to_download',
}

enum SectionExport {
  Rejected = 'rejected',
  Pending = 'pending',
}

interface IReloadActionsObject {
  icon: string;
  displayText: string;
  action: () => void;
  hidden?: boolean;
  isPlanBadgeVisible?: boolean;
  planName?: string;
  disabled?: boolean;
}

class DashboardHeaderShipments implements IComponentController {
  private readonly LIVE_SHIPMENT_CHANNEL_NAME = 'private-live-shipment-information';
  private readonly CANCEL_REPORT_CHANNEL_NAME = 'reports-channel-cancel-report';
  private readonly CREATE_EVENT_NAME = 'create';
  private readonly SHIPMENT_SCOPE_UPDATE_EVENT_NAME = 'shipment-dashboard-scopes-bulk-update';
  private readonly SHIPMENT_LIVE_INFORMATION_UPDATE_EVENT_NAME = 'shipment-live-information-update';

  style = style;
  translations: angular.translate.ITranslationTable = {};
  isReturnPage = false;
  esScopeQuery: any;
  esAuthToken: string | null = null;
  sectionName: ShipmentListSectionName | '' = '';
  sectionsWithPrint = [SectionPrint.ShipmentsAll, SectionPrint.ShipmentsDownload];
  sectionsWithExportOnly = [SectionExport.Rejected, SectionExport.Pending];
  actions: IReloadActionsObject[] = [];
  busy = { shipmentActions: false };
  isManifestAvailable = false;
  hasOnlyAuAddress = false;

  private cancelShipmentChannel: Channel | null = null;
  private cancelledShipmentToastId: Id | undefined;
  private refreshToastId: Id | undefined;
  private liveShipmentChannel: Channel | null = null;
  private notificationIsSnoozed = false;
  private updateShipmentStatusCountWithDebounce: DebouncedFunc<() => IPromise<void>>;

  static $inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$translate',
    'ModalService',
    'ManifestModal',
    'ReportService',
    'UserSession',
    'CompanyService',
    'AddressService',
    'CourierAccounts',
    'HelperService',
    'ExportService',
    'CheckoutService',
    'MixpanelService',
    'ShipmentListManageService',
    'AppCuesService',
    'PicklistService',
    'BulkCancelService',
    'PusherService',
    'SubscriptionService',
    'UserRightsService',
  ];
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $scope: any,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $timeout: ng.ITimeoutService,
    private $translate: angular.translate.ITranslateService,
    private ModalService: ModalService,
    private ManifestModal: IManifestModalService,
    public ReportService: IReportService,
    private UserSession: IUserSession,
    private CompanyService: ICompanyService,
    private AddressService: IAddressService,
    private CourierAccounts: ICourierAccountsService,
    private HelperService: IHelperService,
    private ExportService: IExportService,
    private CheckoutService: ICheckoutService,
    private MixpanelService: MixpanelService,
    private ShipmentListManageService: ShipmentListManageService,
    private AppCuesService: AppCuesService,
    private PicklistService: PicklistService,
    private BulkCancelService: BulkCancelService,
    private PusherService: PusherService,
    private SubscriptionService: SubscriptionService,
    private UserRightsService: UserRightsService
  ) {
    this.updateShipmentStatusCountWithDebounce = debounce(
      this.CompanyService.updateStatus,
      UPDATE_SHIPMENT_STATUS_COUNT_DELAY,
      {
        leading: true,
        maxWait: UPDATE_SHIPMENT_STATUS_COUNT_DELAY,
      }
    );
  }

  $onInit(): void {
    this.sectionName = this.$stateParams.sectionName;

    this.$translate([
      'header.reschedule-pickup',
      'header.schedule-pickup',
      'header.order-summary',
      'header.pick-list',
      'header.generate-manifest',
      'header.cancel-shipments',
      'header.export',
      'header.export-proozy',
      'header.export-note-cards',
      'header.export-igg',
      'header.export-mirakl',
      'header.export-es-collect',
      'header.selected-none',
      'header.selected-multiple',
      'header.domestic-returns',
      'header.already-return',
      'header.label-before-return',
      'global.refresh',
      'global.got-it',
    ]).then((translations: angular.translate.ITranslationTable) => {
      this.translations = translations;
    });

    this.isReturnPage = this.$state.current.name === 'app.returns';

    if (this.esScopeQuery) {
      this.$scope = {
        ...this.$scope,
        query: this.esScopeQuery,
      };
    }

    if (this.esAuthToken) {
      this.$scope = {
        ...this.$scope,
        authorizationToken: this.esAuthToken,
      };
    }

    this._fetchManifestState();
    this.subscribeToCancelShipmentChannel();
    this.subscribeToLiveShipmentChannel();
    this._checkHasOnlyAuAddress();
  }

  $onDestroy(): void {
    this.PusherService.unsubscribeWithCompany(this.CANCEL_REPORT_CHANNEL_NAME);
    this.cleanUpLiveShipment();
  }

  _fetchManifestState(): void {
    this.CourierAccounts.getActiveCourierAccounts()
      .then((res) => {
        this.isManifestAvailable = res.company_courier_accounts
          .concat(res.easyship_courier_accounts)
          .some(({ manifest_name, manifest_method }) => manifest_name && manifest_method);
      })
      .catch(() => {
        toastError(this.$translate.instant('header.export-error'));
      });
  }

  _checkHasOnlyAuAddress(): void {
    this.AddressService.hasAllSenderAddressesInSingleCountry('AU').then((resp) => {
      this.hasOnlyAuAddress = resp;
    });
  }

  refreshShipments(): void {
    this.ShipmentListManageService.fetchShipmentEntitiesAndTotalWithFilter(this.requestSectionName);
  }

  private generatePicklist(): void {
    const isUpgradeModalVisible = this.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
      FEATURE_KEY.PickList,
      'Pick List'
    );
    if (isUpgradeModalVisible) return;

    if (
      !this.ShipmentListManageService.isSelectedAll &&
      this.ShipmentListManageService.selectedIds.length === 0
    ) {
      toastError(
        this.$translate.instant('toast.select-error', {
          noun: this.$translate
            .instant('global.pluralize.shipment', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );
      return;
    }

    this.busy.shipmentActions = true;
    toastMessage(this.$translate.instant('header.generating-pick-list'));

    let payload;

    if (this.ShipmentListManageService.isSelectedAll) {
      payload = {
        by_search: true,
        exclude_shipment_ids: this.ShipmentListManageService.excludedIds,
        scope: this.requestSectionName,
        ...this.ShipmentListManageService.filterPayload,
        context: 'Manage Shipment',
      };
    } else {
      payload = {
        shipment_ids: this.ShipmentListManageService.selectedIds,
        context: 'Manage Shipment',
      };
    }

    this.PicklistService.getPicklist({
      ...payload,
      ...this.$scope.query,
    })
      .then((response) => {
        this.HelperService.openNewTab(response.file);
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.shipmentActions = false;
      });

    this.MixpanelService.track('Pick List - Generate', {
      page: 'Manage',
    });
  }

  private cancelShipments(): void {
    const preferredTimeslots = <boolean[]>[];
    const { isSelectedAll, selectedIds, filterPayload, excludedIds, selectedShipmentCount } =
      this.ShipmentListManageService;

    if (!isSelectedAll && !selectedIds.length) {
      toastError(
        this.$translate.instant('toast.select-error', {
          noun: this.$translate
            .instant('global.pluralize.shipment', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );

      return;
    }

    this.busy.shipmentActions = true;

    const payload = {
      scope: this.requestSectionName,
      ...this.$scope.query,
      ...filterPayload,
      ...(isSelectedAll
        ? {
            exclude_shipment_ids: excludedIds,
          }
        : {
            shipment_ids: selectedIds,
          }),
    };

    selectedIds.forEach((selectedId) => {
      const shipment = this.ShipmentListManageService.getShipmentById(selectedId);
      preferredTimeslots.push(shipment?.preferred_timeslot as boolean);
    });
    this.BulkCancelService.getTotals(payload)
      .then((totals) => {
        return this.ModalService.open('bulk-cancel', {
          totals,
          filters: payload,
          selectedShipmentCount,
          preferredTimeslots,
          refreshShipments: () => {
            this.$timeout(() => {
              this.refreshShipments();
              this.ShipmentListManageService.deselectAllShipments();
            }, REFRESH_SHIPMENTS_DELAY);
          },
        });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.shipmentActions = false;
      });
  }

  export(exportType?: string): void {
    this.AppCuesService.track('Manage | Export');
    this.MixpanelService.track('Manage - Click Export');

    if (
      this.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
        FEATURE_KEY.ExportShipments,
        'Export Shipments'
      )
    ) {
      return;
    }

    this.busy.shipmentActions = true;
    const query = { ...this.$scope.query, ...this.ShipmentListManageService.filterPayload } || {};

    // Ensure we include the full day
    ['created_at_to', 'order_created_at_to', 'label_paid_at_to'].forEach((field) => {
      if (query[field]) {
        const date = new Date(query[field]);
        date.setDate(date.getDate() + 1);
        query[field] = date.toISOString();
      }
    });

    let basicQuery = {
      scope: this.requestSectionName,
      by_search: true,
      context: this.getContext(this.requestSectionName),
    };

    if (
      (this.ShipmentListManageService.selectedIds.length <= 0 ||
        this.ShipmentListManageService.isSelectedAll) &&
      this.ShipmentListManageService.totals
    ) {
      basicQuery = {
        ...basicQuery,
        ...query,
        limit: this.ShipmentListManageService.totals.in_scope_count,
        offset: 0,
      };
    }

    const params: ICSVFiltersParams = this.ShipmentListManageService.isSelectedAll
      ? {
          ...basicQuery,
          exclude_shipment_ids: this.ShipmentListManageService.excludedIds,
        }
      : {
          ...basicQuery,
          shipment_ids: this.ShipmentListManageService.selectedIds,
        };

    if (params.sidebar) {
      delete params.sidebar;
    }

    if (exportType) {
      params.export_type = exportType;
    }

    this.ExportService.getCSV(params)
      .then(() => {
        // Need to retrigger the page() event on appcues to update the event instantly on appcues
        this.AppCuesService.page();
      })
      .catch((err) => {
        toastError(err.data?.error || this.$translate.instant('header.export-error'));
      })
      .finally(() => {
        this.busy.shipmentActions = false;
      });
  }

  _openManifestModal(): void {
    return this.ManifestModal.open();
  }

  _prepareReschedulePickups(): ng.IPromise<void> | void {
    if (
      !this.ShipmentListManageService.isSelectedAll &&
      !this._hasSelectedReschedulableShipments()
    ) {
      toastError(
        this.$translate.instant('toast.select-error', {
          noun: this.$translate
            .instant('global.pluralize.shipment', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );

      return;
    }

    this.MixpanelService.track('Manage Shipments - Click Reschedule Pickup', {
      number_of_shipments: this.reschedulableShipmentCount,
    });

    // eslint-disable-next-line consistent-return
    return this.ShipmentListManageService.prepareReschedulePickups(this.requestSectionName)
      .then((data) => {
        this.CheckoutService.initCheckout(data, { isReschedulePickup: true });
        this._goToReschedulePickupStep();
      })
      .catch((err) => {
        toastError(err?.data?.message || this.$translate.instant('toast.default-error'));
      });
  }

  _buildReschedulePickupText(): string {
    const base = this.translations['header.reschedule-pickup'] as string;
    const count = ` (${this.reschedulableShipmentCount})`;

    return this.shouldShowReschedulableCount ? base + count : base;
  }

  _goToSchedulePickups() {
    if (!this.ShipmentListManageService.isSelectedAll && !this._hasSelectedSchedulableShipments()) {
      toastError(
        this.$translate.instant('toast.select-error', {
          noun: this.$translate
            .instant('global.pluralize.shipment', { COUNT: 1 }, 'messageformat')
            .toLowerCase(),
        })
      );
    } else {
      return openModalByName(MODAL.SCHEDULE_PICK_UP, { shipmentIds: this.getSchedulePickupIds() });
    }
  }

  buildSchedulePickupText(): string {
    const base = this.translations['header.schedule-pickup'] as string;
    const count = ` (${this.scheduleShipmentCount})`;

    return this.shouldScheduleCount ? base + count : base;
  }

  isPlanBadgeVisible(featureKey: FeatureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  reloadActions() {
    let manifestDescription = '';

    if (this.isManifestAvailable) {
      manifestDescription = this.translations[
        this.hasOnlyAuAddress ? 'header.order-summary' : 'header.generate-manifest'
      ] as string;
    }

    const actions: IReloadActionsObject[] = [
      {
        icon: 'icon-delivery',
        displayText: this._buildReschedulePickupText(),
        action: () => {
          return this._prepareReschedulePickups();
        },
        hidden: this.UserSession.isCompanyEfulfilment() || !this.hasPrint || this.isReturnPage,
      },
      {
        icon: 'icon-delivery',
        displayText: this.buildSchedulePickupText(),
        action: () => {
          return this._goToSchedulePickups();
        },
        hidden: this.UserSession.isCompanyEfulfilment() || !this.hasPrint || this.isReturnPage,
      },
      {
        icon: 'icon-picklist',
        displayText: this.translations['header.pick-list'] as string,
        action: () => {
          return this.generatePicklist();
        },
        hidden: !this.hasPrint || this.isReturnPage,
        isPlanBadgeVisible: this.isPlanBadgeVisible(FEATURE_KEY.PickList),
        planName: this.getPlanNameByFeatureKey(FEATURE_KEY.PickList),
        disabled: !this.UserRightsService.canGeneratePickList,
      },
      {
        icon: 'icon-manifest',
        displayText: manifestDescription,
        action: () => {
          return this._openManifestModal();
        },
        hidden: !manifestDescription || !this.hasPrint || this.isReturnPage,
      },
      {
        icon: 'icon-circle-cross',
        displayText: this.translations['header.cancel-shipments'] as string,
        action: () => {
          return this.cancelShipments();
        },
      },
      {
        icon: 'icon-export',
        displayText: this.translations['header.export'] as string,
        action: () => {
          return this.export();
        },
        isPlanBadgeVisible: this.isPlanBadgeVisible(FEATURE_KEY.ExportShipments),
        planName: this.getPlanNameByFeatureKey(FEATURE_KEY.ExportShipments),
      },
      {
        icon: 'icon-export',
        displayText: `${this.translations['header.export-proozy']}`,
        action: () => {
          return this.export('client_proozy');
        },
        // Proozy Companies
        hidden: !['CUS277455', 'CUS00003'].includes(this.UserSession.company.easyship_company_id),
      },
      {
        icon: 'icon-export',
        displayText: `${this.translations['header.export-note-cards']}`,
        action: () => {
          return this.export('client_send_a_friend');
        },
        // SendAFriend Companies
        hidden: !['CUS199515', 'CUS00003'].includes(this.UserSession.company.easyship_company_id),
      },
      {
        icon: 'icon-export',
        displayText: `${this.translations['header.export-igg']}`,
        action: () => {
          return this.export('client_indiegogo');
        },
        // Only if has the IGG export beta feature key
        hidden: !this.hasIGGExport(),
      },
      {
        icon: 'icon-export',
        displayText: `${this.translations['header.export-mirakl']}`,
        action: () => {
          return this.export('client_mirakl');
        },
        // Only if has the Mirakl export beta feature key
        hidden: !this.hasMiraklExport(),
      },
      {
        icon: 'icon-export',
        displayText: `${this.translations['header.export-es-collect']}`,
        action: () => {
          return this.export('client_tax_collect');
        },
        // Only if has the Tax Collect export beta feature key
        hidden: !this.hasTaxCollectExport(),
      },
    ];

    this.actions = actions.filter(({ hidden }) => {
      return !hidden;
    });
  }

  hasIGGExport(): boolean {
    return !!this.UserSession.getCompanyDashboardSettings()?.beta_feature_igg_export;
  }

  hasMiraklExport(): boolean {
    return !!this.UserSession.getCompanyDashboardSettings()?.beta_feature_mirakl_export;
  }

  hasTaxCollectExport(): boolean {
    return !!this.UserSession.getCompanyDashboardSettings()?.show_tax_collect_dashboard_export;
  }

  _goToReschedulePickupStep(): ng.IPromise<void> {
    return this.$state.go('app.reschedule-pickup.handover');
  }

  _hasSelectedReschedulableShipments(): boolean {
    return this.ShipmentListManageService.validToRescheduleCount > 0;
  }

  _hasSelectedSchedulableShipments(): boolean {
    return this.ShipmentListManageService.validToScheduleCount > 0;
  }

  printCourierLabels(): void {
    this.ShipmentListManageService.printCourierLabels(this.requestSectionName);
  }

  get actionsDisabled(): boolean {
    return (
      this.ReportService.busy ||
      this.ShipmentListManageService.shipmentBusy ||
      this.busy.shipmentActions
    );
  }

  get shouldShowShipmentCount(): boolean {
    return this.printableShipmentCount > 0;
  }

  get shouldShowReschedulableCount(): boolean {
    return this.reschedulableShipmentCount > 0;
  }

  get shouldScheduleCount(): boolean {
    return this.scheduleShipmentCount > 0;
  }

  get printableShipmentCount(): number {
    if (this.ShipmentListManageService.isSelectedAll) {
      return this.ShipmentListManageService.totals
        ? this.ShipmentListManageService.totals.printable_count -
            this.ShipmentListManageService.validToPrintSelectedCount
        : 0;
    }

    return this.ShipmentListManageService.validToPrintSelectedCount;
  }

  get reschedulableShipmentCount(): number {
    if (this.ShipmentListManageService.isSelectedAll) {
      return this.ShipmentListManageService.totals
        ? this.ShipmentListManageService.totals.valid_to_reschedule_pickup_count -
            this.ShipmentListManageService.validToRescheduleCount
        : 0;
    }

    return this.ShipmentListManageService.validToRescheduleCount;
  }

  getSchedulePickupIds(): string[] {
    return this.ShipmentListManageService.validToScheduleSelectedIds;
  }

  get scheduleShipmentCount(): number {
    if (this.ShipmentListManageService.isSelectedAll) {
      return this.ShipmentListManageService.totals
        ? this.ShipmentListManageService.totals.valid_to_schedule_pickup_count -
            this.ShipmentListManageService.validToScheduleCount
        : 0;
    }

    return this.ShipmentListManageService.validToScheduleCount;
  }

  get hasPrint(): boolean {
    return this.sectionsWithPrint.includes(this.sectionName as SectionPrint);
  }

  private get requestSectionName(): IShipmentListScope {
    return this.HelperService.kebabToSnakeCase(this.sectionName) as IShipmentListScope;
  }

  private subscribeToCancelShipmentChannel(): void {
    this.cancelShipmentChannel = this.PusherService.subscribeWithCompany(
      this.CANCEL_REPORT_CHANNEL_NAME
    );

    if (this.cancelShipmentChannel) {
      this.PusherService.bind(
        this.cancelShipmentChannel,
        this.CREATE_EVENT_NAME,
        this.onReportCanceled.bind(this)
      );
    }
  }

  private dismissCancelledShipmentToast() {
    if (this.cancelledShipmentToastId) {
      toast.dismiss(this.cancelledShipmentToastId);
      this.cancelledShipmentToastId = undefined;
    }
  }

  private subscribeToLiveShipmentChannel(): void {
    this.liveShipmentChannel = this.PusherService.subscribeWithCompany(
      this.LIVE_SHIPMENT_CHANNEL_NAME
    );

    if (this.liveShipmentChannel) {
      this.PusherService.bind(
        this.liveShipmentChannel,
        this.SHIPMENT_LIVE_INFORMATION_UPDATE_EVENT_NAME,
        this.onShipmentStatusUpdate.bind(this)
      );

      this.PusherService.bind(
        this.liveShipmentChannel,
        this.SHIPMENT_SCOPE_UPDATE_EVENT_NAME,
        this.onShipmentDashboardScopesUpdateReceived.bind(this)
      );
    }
  }

  private async onReportCanceled(data: { report: BulkEdit.IReport }) {
    if (data && data.report) {
      const { job_type: jobType, success, failure, total } = data.report;

      if (success + failure !== total) return;

      this.$timeout(() => {
        this.dismissCancelledShipmentToast();

        if (success && jobType === 'refund') {
          this.updateShipmentStatusCountWithDebounce();
          this.cancelledShipmentToastId = toastSuccess(
            this.$translate.instant(
              'shipments.bulk-cancel-modal.cancellation-success-message',
              {
                count: success.toFixed(0),
              },
              'messageformat'
            )
          );
        }

        if (failure) {
          let translationKey = 'shipments.bulk-cancel-modal.';

          translationKey +=
            jobType === 'refund'
              ? 'cancellation-error-message'
              : 'cancellation-request-error-message';

          this.cancelledShipmentToastId = toastError(
            this.$translate.instant(
              translationKey,
              {
                count: failure.toFixed(0),
              },
              'messageformat'
            )
          );
        }
      }, BULK_CANCEL_NOTIFICATION_DELAY);
    }
  }

  private async onShipmentStatusUpdate(data: ShipmentLastStatusMessageUpdateResponse) {
    const isShipmentVisibleOnThePage = this.ShipmentListManageService.currentShipments?.some(
      (shipment) => shipment.id === data.shipment?.id
    );
    if (!isShipmentVisibleOnThePage) return;

    const prevShipment = this.ShipmentListManageService.getShipmentById(data.shipment.id);
    if (!prevShipment) return;

    this.ShipmentListManageService.mutateVisibleShipment(data.shipment.id, {
      ...prevShipment,
      ...data.shipment,
    });

    this.$rootScope.$apply();
  }

  private onShipmentDashboardScopesUpdateReceived(data: IShipmentDashboardScopesUpdateData) {
    this.updateShipmentStatusCountWithDebounce();

    if (
      (this.refreshToastId && toast.isActive(this.refreshToastId)) ||
      this.notificationIsSnoozed
    ) {
      // refresh toast is either still active or already snoozed
      return;
    }

    const showToast = this.shouldShowToast(
      data.shipment_ids,
      data.dashboard_scopes,
      data.previous_attributes.dashboard_scopes
    );

    if (showToast) {
      this.showRefreshToast();
    }
  }

  /**
   * The logic of showing the Toast:
   * - When there are some updated shipments not shown on the page.
   * - And when a user is on a page that's listed in the `current_dashboard_scopes`.
   * - And when `previous_dashboard_scopes` contains "order_all" and
   * the `current_dashboard_scopes` contains "shipments_all".
   */
  private shouldShowToast(
    shipmentIds: ShipmentId[],
    currentDashboardScopes: DashboardScopeType[],
    previousDashboardScopes: DashboardScopeType[]
  ): boolean {
    const areAllShipmentsAlreadyVisibleOnThePage = shipmentIds.every((shipmentId) =>
      this.ShipmentListManageService.currentShipments?.some(
        (shipment) => shipment.id === shipmentId
      )
    );
    if (areAllShipmentsAlreadyVisibleOnThePage) return false;

    return this.sectionName === 'shipments-all'
      ? previousDashboardScopes.includes(DashboardScope.OrdersAll) &&
          currentDashboardScopes.includes(DashboardScope.ShipmentsAll)
      : currentDashboardScopes.includes(this.sectionName as DashboardScope);
  }

  private showRefreshToast() {
    this.dismissCancelledShipmentToast();
    this.refreshToastId = showToastWithAction(this.$translate.instant('toast.new-shipments'), {
      actionLabel: this.$translate.instant('global.refresh').toUpperCase(),
      onActionLabelClick: this.onRefreshClicked.bind(this),
      actionIcon: 'snooze',
      onActionIconClick: this.onSnoozeClicked.bind(this),
      toastId: this.refreshToastId,
    });
  }

  private onRefreshClicked() {
    this.dismissRefreshToast();
    this.refreshShipments();
    this.notificationIsSnoozed = false;
  }

  private onSnoozeClicked() {
    this.notificationIsSnoozed = true;
    this.dismissRefreshToast();
  }

  private dismissRefreshToast() {
    if (this.refreshToastId) {
      toast.dismiss(this.refreshToastId);
      this.refreshToastId = undefined;
    }
  }

  private cleanUpLiveShipment() {
    this.dismissRefreshToast();
    this.liveShipmentChannel?.unbind_all();
    this.PusherService.unsubscribe(this.LIVE_SHIPMENT_CHANNEL_NAME);
  }

  private getContext(scope: string) {
    const contextMap: { [key: string]: string } = {
      pending: 'Manage Shipments / Pending',
      rejected: 'Manage Shipments / Rejected',
      to_download: 'Manage Shipments / To Print',
    };

    return contextMap[scope] || 'Manage Shipments / All';
  }
}

const DashboardHeaderShipmentsComponent: ng.IComponentOptions = {
  controller: DashboardHeaderShipments,
  template,
  bindings: {
    esScopeQuery: '=?',
    esAuthToken: '<',
  },
};

export { DashboardHeaderShipmentsComponent };
