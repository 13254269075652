import React, { useState } from 'react';
import clsx from 'clsx';

import { IHelperService } from 'typings/helper';

import style from './EsSearchBar.r.module.scss';
import { IEsSearchBarProps } from './types';
import sanitizeQuery from './sanitizeQuery';
import DEFAULT_QUERY_KEYS from './defaultQueryKeys.json';

const EsSearchBarReactComponent: React.FC<IEsSearchBarProps> = (props: IEsSearchBarProps) => {
  const HelperService = props.$injector.get('HelperService') as IHelperService;
  const {
    onFilterClick = props.ngFilterClick,
    onChange = props.esChange,
    onSubmit = props.esSubmit,
  } = props;
  const [keyword, setKeyword] = useState(props.esKeyword || '');

  const queryHasKeys =
    !!props.esFilterQuery &&
    HelperService.doesObjHaveKeys(sanitizeQuery(props.esFilterQuery), DEFAULT_QUERY_KEYS);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setKeyword(event.target.value);

    if (onChange) {
      onChange(event.target.value);
      props.$scope.$apply();
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (onSubmit) onSubmit(keyword);
  }

  function handleFilterClick() {
    onFilterClick();
    props.$scope.$apply();
  }

  return (
    <form className={style.searchBarContainer} onSubmit={handleSubmit}>
      <button type="submit" className={style.searchButton}>
        <span className="icon-search text-xl text-ink-100" />
      </button>

      <input
        id={props.esId}
        placeholder={props.esPlaceholder}
        className={clsx(style.inputElement, { [style.inputElementWithFilter]: props.esFilter })}
        value={keyword}
        onChange={handleChange}
      />

      {props.esFilter && (
        <button
          type="button"
          className={clsx(style.filterButton, { filtered: queryHasKeys })}
          onClick={handleFilterClick}
        >
          {props.esIsFiltered ? 'Filtered' : 'Filters'}
        </button>
      )}
    </form>
  );
};

export default EsSearchBarReactComponent;
