ProductListingRoutes.$inject = ['$stateProvider'];
function ProductListingRoutes($stateProvider) {
  $stateProvider.state('app.product-listing', {
    url: '^/product-listing?productshelp',
    component: 'productListing',
    data: {
      requiredUserRight: 'menu.products',
    },
  });
}

export { ProductListingRoutes };
