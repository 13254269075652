import angular from 'angular';

import registrationService from './registration';
import stepsNavigationService from './steps-navigation';
import shopifyAuthService from './shopify-auth';
import bigcommerceAuthService from './bigcommerce-auth';
import tradegeckoAuthService from './tradegecko-auth';
import weeblyAuthService from './weebly-auth';

const MODULE_NAME = 'auth.global.services';

angular.module(MODULE_NAME, [
  registrationService,
  stepsNavigationService,
  shopifyAuthService,
  bigcommerceAuthService,
  tradegeckoAuthService,
  weeblyAuthService,
]);

export default MODULE_NAME;
