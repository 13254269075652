'use strict';
import template from './signup-confirm.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('home.auth.signup-confirm', {
      url: 'confirm/:emailConfirmationToken',
      template,
      controller: 'SignupConfirmCtrl',
      controllerAs: 'vm',
    });
  },
]);
