import angular from 'angular';

import esDimensions from '../dimensions';

import { DefaultDimensionsInputComponent } from './default-dimensions-input.component';

const MODULE_NAME = 'app.global.dashboard-component-library.default-dimensions-input';

angular
  .module(MODULE_NAME, [esDimensions])
  .component('esDefaultDimensionsInput', DefaultDimensionsInputComponent);

export default MODULE_NAME;
