import angular from 'angular';
import { OnboardingService } from './onboarding.service';
import { OnboardingResource } from './onboarding.resource';

const MODULE_NAME = 'onboarding.services';

angular
  .module(MODULE_NAME, [])
  .service('OnboardingService', OnboardingService)
  .service('OnboardingResource', OnboardingResource);

export default MODULE_NAME;
