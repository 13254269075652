import { ICreditCardData } from 'typings/payment-source';
import { IComponentController } from 'angular';
import { PaymentSourceService } from '@client/src/global/services/payment-source/payment-source.service';
import { toastSuccess } from '@client/core/components/react/Toastify';
import style from './confirm-delete-payment-modal-card.module.scss';
import template from './confirm-delete-payment-modal-card.html?raw';

class ConfirmDeletePaymentModalCard implements IComponentController {
  style = style;
  busyDelete = false;
  esSelectedCardId: string | null = null;
  creditCard: ICreditCardData | null = null;
  esCloseTitleAction() {
    //
  }

  static $inject = ['$translate', 'PaymentSourceService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private PaymentSourceService: PaymentSourceService
  ) {}

  $onInit(): void {
    if (this.esSelectedCardId) {
      this.PaymentSourceService.getCreditCard(this.esSelectedCardId)
        .then((res) => {
          if (res && res.payment_source) {
            this.creditCard = res.payment_source;
          } else {
            this.esCloseTitleAction();
          }
        })
        .catch(() => {
          this.esCloseTitleAction();
        });
    }
  }

  confirmDeleteAction(): void {
    if (this.esSelectedCardId) {
      this.busyDelete = true;
      this.PaymentSourceService.deactivatePaymentSource(this.esSelectedCardId)
        .then(() => {
          toastSuccess(
            this.$translate.instant('toast.delete-success', {
              noun: this.$translate.instant('global.credit-card').toLowerCase(),
            })
          );
          this.esCloseTitleAction();
        })
        .finally(() => {
          this.busyDelete = false;
        });
    }
  }
}

const ConfirmDeletePaymentModalCardComponent: ng.IComponentOptions = {
  controller: ConfirmDeletePaymentModalCard,
  template,
  bindings: {
    esCloseTitleAction: '&',
    esConfirmDeleteCardAction: '&',
    esLeftButtonAction: '&',
    esSelectedCardId: '<',
  },
};

export { ConfirmDeletePaymentModalCardComponent };
