import angular from 'angular';

import { InsuranceClaimComponent } from './insurance-claim.component';
import { SelectClaimTypeComponent } from './select-claim-type/select-claim-type.component';
import { ClaimDetailsComponent } from './claim-details/claim-details.component';
import { ClaimSuccessComponent } from './claim-success/claim-success.component';

const MODULE_NAME = 'app.global.components.insurance-claim';

angular
  .module(MODULE_NAME, [])
  .component('esInsuranceClaim', InsuranceClaimComponent)
  .component('esSelectClaimType', SelectClaimTypeComponent)
  .component('esClaimDetails', ClaimDetailsComponent)
  .component('esClaimSuccess', ClaimSuccessComponent);

export default MODULE_NAME;
