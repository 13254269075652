import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './single-store-api-details.html?raw';

(function () {
  angular.module('easyshipDashboardApp').directive('singleStoreApiDetails', singleStoreApiDetails);

  function singleStoreApiDetails() {
    const directive = {
      template,
      restrict: 'E',
      controller,
      controllerAs: 'vm',
      scope: {
        environment: '@',
        apiKeyDetails: '=',
      },
    };

    return directive;
  }

  controller.$inject = ['$scope', '$timeout', '$q', 'StoreService', '$translate'];

  function controller($scope, $timeout, $q, StoreService, $translate) {
    const vm = this;

    vm.environment = $scope.environment;
    vm.apiKeyDetails = $scope.apiKeyDetails;
    vm.$translate = $translate;

    vm.edit = {};
    vm.busy = {};
    vm.wrappers = {
      blue(chunk) {
        return `<span class="text-blue-700">${chunk}</span>`;
      },
    };

    vm.apiKeyDetails.formattedScopes = createActivatedScopeArray(vm.apiKeyDetails.scopes);

    vm.toggleEditMode = function (field) {
      vm.edit[field] = !vm.edit[field];
      vm.apiKeyDetailsCopy = angular.copy(vm.apiKeyDetails);
    };

    vm.copyToken = function () {
      vm.showCopied = true;

      $timeout(function () {
        vm.showCopied = false;
      }, 750);
    };

    vm.onCheckboxChange = function (scope) {
      vm.apiKeyDetailsCopy.scopes[scope.toLowerCase()] =
        !vm.apiKeyDetailsCopy.scopes[scope.toLowerCase()];
    };

    vm.update = function (apiKeyDetails, environment, field) {
      vm.busy[field] = true;
      updateApiKey(apiKeyDetails, environment, field)
        .then(function (response) {
          // eslint-disable-next-line no-param-reassign
          StoreService.activeStore.oauth_applications = response.oauth_applications;
          vm.apiKeyDetails = response.oauth_applications[environment];
          vm.apiKeyDetails.formattedScopes = createActivatedScopeArray(
            StoreService.activeStore.oauth_applications[environment].scopes
          );

          vm.edit[field] = false;
          vm.busy[field] = false;
          toastSuccess($translate.instant('store.notifications.updated'));
        })
        .catch(function () {
          vm.busy[field] = false;
          toastError($translate.instant('store.notifications.update-failed'));
        });
    };

    function updateApiKey(apiKeyDetails, environment, field) {
      return $q(function (resolve, reject) {
        const storeId = StoreService.activeStore.id;
        const oauthApplications = {};

        // Purpose of sanitizedObject is to remove all unnecessary properties from the
        // object before sending it accross to the Endpoint
        oauthApplications[environment] = _.pick(apiKeyDetails, field);

        StoreService.updateOauthApplications(storeId, oauthApplications)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    }

    function createActivatedScopeArray(scopes) {
      return _.reduce(
        scopes,
        function (arr, value, scope) {
          if (value) arr.push(scope);
          return arr;
        },
        []
      );
    }
  }
})();
