import { toastError } from '@client/core/components/react/Toastify';
import template from './shipment-status-cell.html?raw';
import style from './shipment-status-cell.module.scss';

const CANCELLATION_REQUESTED_STATUS_ID = 197;
const AWAITING_SCAN_FROM_COURIER_STATUS_ID = 107;
const LABEL_REJECTED_EDITABLE_STATUS_ID = 120;
const EXCEPTION_STATUS_ID = 16;
const CANCELLED_PENDING_REFUND_STATUS_ID = 102;

class ShipmentStatusCell {
  static $inject = [
    '$state',
    'API',
    'HelperService',
    'UserSession',
    'FixMonkeyService',
    'fixShipmentModal',
    'CheckoutService',
    'ReportService',
    'ShipmentListManageService',
    'PickupsPageService',
  ];

  constructor(
    $state,
    API,
    HelperService,
    UserSession,
    FixMonkeyService,
    fixShipmentModal,
    CheckoutService,
    ReportService,
    ShipmentListManageService,
    PickupsPageService
  ) {
    this.$state = $state;
    this.style = style;
    this.API = API;
    this.HelperService = HelperService;
    this.UserSession = UserSession;
    this.FixMonkeyService = FixMonkeyService;
    this.fixShipmentModal = fixShipmentModal;
    this.CheckoutService = CheckoutService;
    this.ReportService = ReportService;
    this.ShipmentListManageService = ShipmentListManageService;
    this.PickupsPageService = PickupsPageService;

    this.isReturnPage = false;
    this.timeFormat = { hour: 'numeric', minute: 'numeric', hour12: false, timeZone: undefined };
    this.isEditModalActive = false;
  }

  $onInit() {
    this.isReturnPage = this.$state.current.name === 'app.returns';

    // Backward compatibility
    this._updateStatusMessage();
  }

  $onChanges(changesObj) {
    const { esStatusMessage } = changesObj;
    if (
      esStatusMessage.previousValue.id &&
      esStatusMessage.previousValue.id !== esStatusMessage.currentValue.id
    ) {
      this._updateStatusMessage();
    }
  }

  _updateStatusMessage() {
    if (this.esStatusMessage.action_name && this.esStatusMessage.action_message) {
      this.statusAction = this._buildStatusAction(this.esStatusMessage);
    } else {
      this.statusAction = this._buildAction(this.esStatusMessage && this.esStatusMessage.id);
    }
    this.showCancellingStatus =
      this.esShipmentState === 'cancelling' &&
      this.esStatusMessage.id !== CANCELLATION_REQUESTED_STATUS_ID;

    this.isPickupScheduled = this.esStatusMessage.id === AWAITING_SCAN_FROM_COURIER_STATUS_ID;
    this.isShipmentLate = this._isShipmentLate(this.esEtaDate);
  }

  hideEditModal() {
    this.isEditModalActive = false;
  }

  // Example of statusMessage format
  // {
  //   id:120,
  //   name: "Label Rejected",
  //   subtitle: null,
  //   tooltip: "The courier detected an error with your shipment and couldn't generate a label. Please edit your shipment and try again.",
  //   status_color:"red",
  //   status_group:"exception"
  // }

  // List of current actions:
  // - action-edit-shipment
  // - action-reschedule-pickup
  // - learn-more-exception
  // - action-download-documents
  // - action-dropoff-url
  _buildStatusAction(statusMessage) {
    if (!statusMessage) return null;

    const statusAction = {
      content: statusMessage.action_message,
      action: () => {}, // eslint-disable-line no-empty-function
    };

    switch (statusMessage.action_name) {
      case 'action-edit-pickup':
        statusAction.action = () => {
          // Prepare and go to edit pickup
          this._editPickupInfo();
        };
        break;
      case 'action-edit-shipment':
        statusAction.action = () => {
          // Prepare and open fix shipment modal
          this._editShipment();
        };
        break;
      case 'action-pickup-url':
      case 'action-reschedule-pickup':
        statusAction.action = () => {
          // Prepare and go to reschedule pickup
          this._reschedulePickup();
        };
        break;
      case 'learn-more-exception':
        statusAction.action = () => {
          this.HelperService.openNewTab(
            `${this.API.help}/hc/en-us/articles/360000487952-Shipment-Status-Exception`
          );
        };
        break;
      case 'action-download-documents':
        if (this.isReturnPage) {
          // eslint-disable-next-line consistent-return
          return;
        }

        statusAction.action = () => {
          this._downloadShippingDocs();
        };
        break;
      case 'action-dropoff-url':
        // Link to dropoff url, add if statement since the BE key is not always present yet
        if (this.esDropOffUrl) {
          statusAction.action = () => {
            this.HelperService.openNewTab(this.esDropOffUrl);
          };
        } else {
          statusAction.action = null;
        }
        break;
      case 'learn-more-expired':
        statusAction.action = () => {
          this.HelperService.openNewTab(
            `${this.API.help}/hc/en-us/articles/360052239672-Identifying-Liability-Couriers-on-Easyship`
          );
        };
        break;
      default:
        statusAction.action = () => {}; // eslint-disable-line no-empty-function
    }

    return statusAction;
  }

  _buildAction(statusId) {
    let action;

    // Label rejected
    if (statusId === LABEL_REJECTED_EDITABLE_STATUS_ID) {
      action = {
        content: 'Edit Shipment',
        action: () => {
          // Prepare and open fix shipment modal
          this._editShipment();
        },
      };
    }
    // Exception
    else if (statusId === EXCEPTION_STATUS_ID) {
      action = {
        content: 'Contact Support',
        action: () => {
          this.HelperService.openNewTab(this.API.help);
        },
      };
    }
    // Pickup expired or pending
    else if (
      (this.showPickupExpired || statusId === AWAITING_SCAN_FROM_COURIER_STATUS_ID) &&
      this.esEligibleToReschedule
    ) {
      action = {
        content: 'Reschedule Pickup',
        action: () => {
          // Prepare and go to reschedule pickup
          this._reschedulePickup();
        },
      };
    }
    // Pending refund
    else if (statusId === CANCELLED_PENDING_REFUND_STATUS_ID) {
      action = {
        content: 'Learn More',
        action: () => {
          this.HelperService.openNewTab(`${this.API.help}/hc/en-us/articles/360028822711-Refund`);
        },
      };
    }

    return action;
  }

  _reschedulePickup() {
    if (this.actionBusy) return;

    this.actionBusy = true;

    this.CheckoutService.singleReschedulePickup(this.esShipmentId).finally(() => {
      this.actionBusy = false;
    });
  }

  _editShipment() {
    if (this.actionBusy) return;

    this.actionBusy = true;

    // Include http_response to fetch error message from failed shipment
    const shipmentDetails = {
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: this.esShipmentId,
      include: 'shipment_items,http_response',
    };

    this.FixMonkeyService.prepareShipment(shipmentDetails)
      .then(() => {
        this.FixMonkeyService.goToEditShipmentStep();
        this.fixShipmentModal.open();
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.actionBusy = false;
      });
  }

  _isShipmentLate(eta) {
    // https://github.com/moment/moment/issues/1407
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const etaDate = moment(new Date(eta)).format('YYYY-MM-DD');

    return moment(todayDate).diff(etaDate, 'days') > 0;
  }

  _downloadShippingDocs() {
    this.ShipmentListManageService.getLabel(this.esShipmentId, 'Manage Shipment / Status');
  }

  _editPickupInfo() {
    if (this.actionBusy) return;

    this.actionBusy = true;

    const shipmentDetails = {
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: this.esShipmentId,
    };

    // fetch the shipment details
    this.FixMonkeyService.prepareShipment(shipmentDetails)
      .then(({ shipment }) => {
        // get the pickup details for this shipment
        this.PickupsPageService.editPickupInfo(shipment.pickup.id)
          .then(({ pickup }) => {
            this.pickupToEdit = pickup;
            this.isEditModalActive = true;
          })
          .catch(() => {
            toastError(this.$translate.instant('toast.default-error'));
          })
          .finally(() => {
            this.actionBusy = false;
          });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }
}

const ShipmentStatusCellComponent = {
  controller: ShipmentStatusCell,
  template,
  bindings: {
    esShipmentId: '<',
    esShipmentState: '<',
    esStatusMessage: '<',
    esDropOffUrl: '<',
    esEtaDate: '<',
    esEligibleToReschedule: '<',
    esSelectedDate: '<',
    esSelectedFromTime: '<',
    esSelectedToTime: '<',
  },
};

export { ShipmentStatusCellComponent };
