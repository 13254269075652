import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    '& .MuiIconButton-root': {
      padding: 0,
    },

    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },

    '& .MuiTypography-h2': {
      marginBottom: 0,
    },

    '& .MuiSvgIcon-root': {
      fontSize: 30,
    },
  },
  gotItButton: {
    width: 150,

    '& .MuiButton-label': {
      justifyContent: 'center',
    },
  },
  noBorder: {
    border: 'none',
  },
});
