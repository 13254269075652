import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './discount-rates.html?raw';
import style from './discount-rates.module.scss';

class DiscountRatesController {
  static $inject = [
    '$timeout',
    '$translate',
    'DiscountRateService',
    'CountryService',
    'StoreService',
    'ShippingDiscountsService',
    'MixpanelService',
    'UserSession',
    'API',
  ];

  constructor(
    $timeout,
    $translate,
    DiscountRateService,
    CountryService,
    StoreService,
    ShippingDiscountsService,
    MixpanelService,
    UserSession,
    API
  ) {
    this.$timeout = $timeout;
    this.$translate = $translate;
    this.DiscountRateService = DiscountRateService;
    this.CountryService = CountryService;
    this.StoreService = StoreService;
    this.ShippingDiscountsService = ShippingDiscountsService;
    this.MixpanelService = MixpanelService;
    this.UserSession = UserSession;
    this.API = API;

    this.style = style;
    this.loading = {};
  }

  _setFormDefaultValue() {
    this.form.destination_country_alpha2s = this.form.destination_country_alpha2s || [];
    this.form.min_spend_currency =
      this.form.min_spend_currency || this.UserSession.company.currency;
    this.form.max_discount_currency =
      this.form.max_discount_currency || this.UserSession.company.currency;
    this.form.include_tax =
      typeof this.form.include_tax !== 'undefined' ? this.form.include_tax : true;
  }

  _getCountries() {
    if (!this.form.destination_country_alpha2s.length) {
      this.CountryService.getCountries()
        .then((response) => {
          this.form.destination_country_alpha2s = response.countries.map((country) => {
            return country.alpha2;
          });
        })
        .catch(() => {
          toastError(this.$translate.instant('toast.default-error'));
        });
    }
  }

  $onInit() {
    this.error = false;

    this._setFormDefaultValue();
    this._getCountries();
  }

  onSettingNameChange(value) {
    this.updateForm({ field: 'name', value });
  }

  onMinimumOrderPriceChange(value) {
    this.updateForm({ field: 'min_spend_value', value });
  }

  onMinimumOrderPriceCurrencyChange(value) {
    this.updateForm({ field: 'min_spend_currency', value });
  }

  onMaximumShippingDiscountChange(value) {
    this.updateForm({ field: 'max_discount_value', value });
  }

  onMaximumShippingDiscountCurrencyChange(value) {
    this.updateForm({ field: 'max_discount_currency', value });
  }

  onTaxesAndDutiesChange(value) {
    this.updateForm({ field: 'include_tax', value });
  }

  onCancel() {
    if (this.loading.delete || this.loading.save) return;
    this.close();
    this.MixpanelService.track('Connect - Store - Settings - Free Shipping - Back');
  }

  onDelete() {
    this.loading.delete = true;

    this.ShippingDiscountsService.delete(this.form.id, this.StoreService.activeStore.id)
      .then(() => {
        this.DiscountRateService.deleteShippingDiscount(this.form.id);
        this.close();
        toastSuccess(this.$translate.instant('toast.delete-success', { noun: this.form.name }));
      })
      .catch(() => {
        this.loading.delete = false;
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  onSubmit() {
    if (this.discountRatesForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading.save = true;

    const payload = {
      ...this.form,
      ...{
        min_spend_value: this.DiscountRateService.getPriceValue(this.form.min_spend_value),
        max_discount_value: this.DiscountRateService.getPriceValue(this.form.max_discount_value),
      },
    };

    if (this.edit) {
      this.ShippingDiscountsService.update(this.form.id, this.StoreService.activeStore.id, payload)
        .then((response) => {
          this.DiscountRateService.updateShippingDiscount(response.id, response);
          this.close();
          toastSuccess(
            this.$translate.instant(
              'toast.update-success',
              { noun: response.name },
              'messageformat'
            )
          );
          this.MixpanelService.track(
            'Connect - Store - Settings - Free Shipping - Edit Rule - Success'
          );
        })
        .catch(() => {
          this.loading.save = false;
          toastError(this.$translate.instant('toast.default-error'));
          this.MixpanelService.track(
            'Connect - Store - Settings - Free Shipping - Edit Rule - Failure'
          );
        });
    } else {
      this.ShippingDiscountsService.create(this.StoreService.activeStore.id, payload)
        .then((response) => {
          this.StoreService.activeStore.shipping_discounts.push(response);
          this.close();
          toastSuccess(this.$translate.instant('toast.create-success', { noun: this.form.name }));
          this.MixpanelService.track('Connect - Store - Settings - Free Shipping - Success');
        })
        .catch(() => {
          this.loading.save = false;
          toastError(this.$translate.instant('toast.default-error'));
          this.MixpanelService.track('Connect - Store - Settings - Free Shipping - Fail');
        });
    }
  }
}

const DiscountRatesComponent = {
  template,
  controller: DiscountRatesController,
  bindings: {
    close: '&',
    form: '<',
    edit: '<',
    updateForm: '&',
    onAdd: '&',
  },
};

export { DiscountRatesComponent };
