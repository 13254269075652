import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { useInviteeGateway } from '../../providers/InviteeGatewayProvider';

export default function useDeleteInviteeMutation() {
  const queryClient = useQueryClient();
  const { inviteeGateway } = useInviteeGateway();
  const { company } = useUserSession();

  return useMutation({
    mutationFn: (id: string) =>
      inviteeGateway.deleteCompanyInvitee({
        id,
        companyId: company.id,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['currentSubscription'],
      });
      queryClient.invalidateQueries({
        queryKey: ['companyInvitees'],
      });
    },
  });
}
