(function () {
  'use strict';

  ShipmentItem.$inject = ['$resource', 'API'];
  function ShipmentItem($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/companies/:company_id/shipments/:shipment_id/shipment_items/:id',
      {
        company_id: '@_company_id',
        shipment_id: '@_shipment_id',
        id: '@_id',
      },
      {
        query: {
          method: 'GET',
        },
        fetch: {
          method: 'GET',
        },
      }
    );
  }

  angular.module('app.factory.ShipmentItem', []).factory('ShipmentItem', ShipmentItem);
})();
