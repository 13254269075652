import template from './shipment-handover-cell.html?raw';

class ShipmentHandoverCell {
  constructor() {}
}

const ShipmentHandoverCellComponent = {
  controller: ShipmentHandoverCell,
  template,
  bindings: {
    courierHandoverOptions: '<',
  },
};

export { ShipmentHandoverCellComponent };
