PrintLabel.$inject = ['$resource', 'API'];
function PrintLabel($resource, API) {
  const endpointUrl = API.endpoint;
  return $resource(
    `${endpointUrl}/cloud/companies/:company_id/shipments/print_label`,
    {
      company_id: '@_company_id',
    },
    { create: { method: 'POST' } }
  );
}

export { PrintLabel };
