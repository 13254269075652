import template from './address-form-nl.html?raw';

(function () {
  'use strict';

  var addressFormNl = {
    template,
    bindings: {
      address: '=',
      fieldValidators: '<',
    },
    controller: 'addressFormNlCtrl as ctrl',
  };

  angular.module('app.component.address-form-nl').component('addressFormNl', addressFormNl);
})();
