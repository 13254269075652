import { IInputGroupRadioModel, IInputGroupValue } from 'typings/form';

import { MultiselectComponentModelValue } from '@client/src/global/services/shipment-list/data/multi-select';
import { IComponentController } from 'angular';
import template from './states-input-group.html?raw';
import style from './states-input-group.module.scss';
import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';

class StatesInputGroup extends MultiSelectInputGroup<any> implements IComponentController {
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput: string | null = null;
  esModelStates: string[] = [];
  esModelOptions = [];

  style = style;
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];

  static $inject = ['$scope', '$translate'];
  constructor($scope: ng.IScope, private $translate: angular.translate.ITranslateService) {
    super($scope);
  }

  $onInit(): void {
    const noun = this.$translate.instant('global.state');
    const $t = (key: string) => {
      return this.$translate.instant(
        `global.relation.prefixed.${key}`,
        { noun },
        undefined,
        undefined,
        'escape'
      );
    };

    this.radioModel = [
      {
        title: $t('is_any_of'),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: $t('is_none_of'),
        value: MultiselectComponentModelValue.NoneOf,
      },
      {
        title: $t('is_known'),
        value: MultiselectComponentModelValue.Known,
      },
      {
        title: $t('is_unknown'),
        value: MultiselectComponentModelValue.Unknown,
      },
    ];
    this.assignDefaultRadioModel();
  }

  onStateSelectorChange(value: string[]): void {
    this.esModelStates = value;

    this.onValuesChange({
      options: value,
    });
  }

  onRadioChange(value: IInputGroupValue<MultiselectComponentModelValue>): void {
    this.assignModelRadioInputValue(value);
    this.onValuesChange({ options: this.esModelStates });
  }
}

const StatesInputGroupComponent: ng.IComponentOptions = {
  controller: StatesInputGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelStates: '<',
    esOnChange: '&',
  },
};

export { StatesInputGroupComponent };
