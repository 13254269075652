import React, { lazy, Suspense } from 'react';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { react2angular } from 'react2angular';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';

const EmptyPickupPage = lazy(() => import('@/pages/pickups/components/EmptyPickupPage'));

function WrappedEmptyPickupPage() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={null}>
          <EmptyPickupPage />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularEmptyPickupPage = react2angular(WrappedEmptyPickupPage, []);
