import {
  BillingInterval,
  DefinedSubscriptionPlanId,
  FlexibleSubscriptionPlanId,
  SCALE_PLAN_IDS,
} from '@client/data/subscription';
import {
  IApiPrice,
  IPlansDetail,
  IUserPlan,
  PlanRanking,
  IPlan as IApiPlan,
  SubscriptionPeriod,
} from 'typings/subscription';
import { sortBy } from 'lodash';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { ButtonTranslationKey, IPlan } from './types';

/** the data will be "Custom" for enterprise plan */
export function isCustom(data: unknown): data is string {
  return typeof data === 'string' && data === 'Custom';
}

/**
 * Scale Plan
 */
function isScalePlan(planId: number): boolean {
  return SCALE_PLAN_IDS.includes(planId);
}

export function getSmallestRankingInScalePlans(scalePlans: IPlan[]): PlanRanking {
  const sortedScalePlans = sortBy(scalePlans, 'ranking');
  return sortedScalePlans[0]?.ranking;
}

export function getDefaultRankingInScalePlans(
  currentSubscription: SubscriptionService['currentSubscription'],
  scalePlans: IPlan[]
): PlanRanking {
  const smallestRankingInScalePlans = getSmallestRankingInScalePlans(scalePlans);

  if (!currentSubscription?.plan?.id) {
    return smallestRankingInScalePlans;
  }

  return isScalePlan(currentSubscription.plan.id)
    ? currentSubscription.plan.ranking
    : smallestRankingInScalePlans;
}

/**
 * Rate (Price)
 */

const getRatePriceMapper = (price: IApiPrice) => ({
  [BillingInterval.Month]: price.monthly,
  [BillingInterval.Year]: price.yearly,
});

interface ISubscriptionPrice {
  price: IApiPrice;
  billingInterval: BillingInterval;
}
export function getRate({ price, billingInterval }: ISubscriptionPrice): number {
  const rate = getRatePriceMapper(price)[billingInterval];

  if (isCustom(rate)) {
    return 0;
  }

  return rate;
}

export function isCustomRate({ price, billingInterval }: ISubscriptionPrice): boolean {
  const rate = getRatePriceMapper(price)[billingInterval];

  return isCustom(rate);
}

/**
 * Tax
 */
export function getTaxName(taxName: IPlansDetail['sales_tax_name']): string {
  if (!taxName || isCustom(taxName)) {
    return '';
  }
  return taxName;
}

export function getTaxAmount({ price, billingInterval }: ISubscriptionPrice): number {
  const tax = {
    [BillingInterval.Month]: price.monthly_sales_tax,
    [BillingInterval.Year]: price.yearly_sales_tax,
  }[billingInterval];

  if (!tax || isCustom(tax)) {
    return 0;
  }

  return tax;
}

/**
 * Action
 */
export function isActionAnUpgrade(action: ButtonTranslationKey): boolean {
  switch (action) {
    case ButtonTranslationKey.Upgrade:
    case ButtonTranslationKey.LetsTalk:
    case ButtonTranslationKey.Continue:
    case ButtonTranslationKey.TryForFree:
      return true;
    case ButtonTranslationKey.Downgrade:
    case ButtonTranslationKey.Current:
    default:
      return false;
  }
}

interface IGetButtonAction {
  currentSubscribedPlan: IUserPlan;
  currentSubscriptionPeriod: SubscriptionPeriod | undefined;
  plan: IApiPlan;
  billingInterval: BillingInterval;
  isLegacyTrialEnd: boolean;
}

export function getActionButtonLabel({
  currentSubscribedPlan,
  currentSubscriptionPeriod,
  plan,
  billingInterval,
  isLegacyTrialEnd,
}: IGetButtonAction): ButtonTranslationKey {
  /*
    If the `plan` ...
  */
  const isCurrentlySubscribed = plan.id === currentSubscribedPlan.id;
  const isFree = plan.id === DefinedSubscriptionPlanId.Free;
  const hasHigherRankingThanSubscribed = plan.ranking > currentSubscribedPlan.ranking;
  const isEnterprise = plan.id === FlexibleSubscriptionPlanId.Enterprise;

  if (isLegacyTrialEnd && isCurrentlySubscribed) {
    return ButtonTranslationKey.Continue;
  }

  if (isEnterprise && !isCurrentlySubscribed) {
    return ButtonTranslationKey.LetsTalk;
  }

  switch (currentSubscriptionPeriod) {
    case 'TRIAL':
      if (isCurrentlySubscribed) {
        return ButtonTranslationKey.Current;
      }

      if (isFree) {
        return ButtonTranslationKey.EndTrial;
      }

      return ButtonTranslationKey.TryForFree;

    case 'END_OF_TRIAL':
      if (isCurrentlySubscribed) {
        return currentSubscribedPlan.interval === billingInterval
          ? ButtonTranslationKey.Continue
          : ButtonTranslationKey.Change;
      }

      if (hasHigherRankingThanSubscribed) {
        return ButtonTranslationKey.Upgrade;
      }

      return ButtonTranslationKey.Downgrade;

    default:
      if (isCurrentlySubscribed) {
        return currentSubscribedPlan.interval === billingInterval
          ? ButtonTranslationKey.Current
          : ButtonTranslationKey.Change;
      }

      if (hasHigherRankingThanSubscribed) {
        return ButtonTranslationKey.Upgrade;
      }

      return ButtonTranslationKey.Downgrade;
  }
}

interface IsCurrentSubscribedPlan {
  planId: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  currentPlanId: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  currentInterval: BillingInterval | undefined;
  billingInterval: BillingInterval;
  isLegacyTrialEnd: boolean;
}
export function isCurrentSubscribedPlan({
  planId,
  currentPlanId,
  currentInterval,
  billingInterval,
  isLegacyTrialEnd,
}: IsCurrentSubscribedPlan): boolean {
  if (isLegacyTrialEnd) {
    return planId === FlexibleSubscriptionPlanId.Enterprise;
  }

  const isSameBillingInterval = currentInterval === billingInterval;
  const isSamePlanId = planId === currentPlanId;

  return isSameBillingInterval && isSamePlanId;
}
