import { toastError } from '@client/core/components/react/Toastify';

(function () {
  angular.module('easyshipDashboardApp').controller('QuoteCtrl', QuoteCtrl);

  QuoteCtrl.$inject = [
    '$scope',
    '$stateParams',
    '$timeout',
    '$q',
    '$translate',
    'API',
    'UserSession',
    'CountryService',
    'RateCalculator',
    'AddressService',
    'PostalCodeService',
    'ItemCategoryService',
    'MixpanelService',
    'BoxService',
    'FlatRateBoxService',
    'OnboardingChecklistService',
  ];

  function QuoteCtrl(
    $scope,
    $stateParams,
    $timeout,
    $q,
    $translate,
    API,
    UserSession,
    CountryService,
    RateCalculator,
    AddressService,
    PostalCodeService,
    ItemCategoryService,
    MixpanelService,
    BoxService,
    FlatRateBoxService,
    OnboardingChecklistService
  ) {
    const vm = this;

    vm.API = API;
    vm.UserSession = UserSession;
    vm.CountryService = CountryService;
    vm.BoxService = BoxService;
    vm.AUSTRALIA_ID = 14;
    vm.CANADA_ID = 39;
    vm.UNITED_STATES_ID = 234;
    vm.NEW_ZEALAND_ID = 172;
    vm.DOCUMENT_MAX_WEIGHT = 2.5;
    vm.weightUnit = null;
    vm.weightInput = '';
    vm.weightInputError = 'default';

    vm.allowForeignAddress = false;
    vm.originCityOptions = [];
    vm.destinationCityOptions = [];
    vm.postalCodeSpecs = {
      origin: { has: true, required: false },
      destination: { has: true, required: false },
    };
    vm.postalCodeInvalid = {
      origin: null,
      originNotFound: false,
      destination: null,
      destinationNotFound: false,
      submitted: false,
    };
    vm.allBoxes = [];
    vm.incoterms = true;
    vm.busy = {
      request: false,
      fetching: false,
      findingState: false,
    };
    vm.categoryHsCodeSelectedValue = null;
    vm.fixedPriceAmountInputSettings = {
      maxDecimals: 2,
      allowNegative: false,
    };

    (function init() {
      vm.originCountries = [];
      vm.destinationCountries = [];
      vm.currencies = [];
      vm.itemCategories = [];
      vm.enquiry = $stateParams.enquiry || {
        useExistingAddress: true,
        origin_country_id: null,
        origin_postal_code: '',
        origin_address_id: null,
        incoterms: 'DDU',
        is_insured: false,
        items: [{}],
        residential_surcharge: {
          apply_to_total_charge: false,
        },
      };
      vm.enquiry.items[0].declared_currency = vm.UserSession.company.currency;
      vm.allowForeignAddress =
        vm.UserSession.getCompanyDashboardSettings().beta_feature_global_account;

      vm.wrappers = {
        a(chunk) {
          return `<a href="https://support.easyship.com/hc/en-us/articles/360000183211-Volumetric-Weight-and-Dimensions" target="_blank" rel="noopener">${chunk}</a>`;
        },
      };

      vm.busy.fetching = true;

      $q.all([
        vm.CountryService.getCountries(),
        vm.CountryService.getShippingSupportedCountries(),
        vm.CountryService.getUSStates(),
        getItemCategories(),
        getReusableShippingAddresses(),
      ]).then(function (data) {
        vm.destinationCountries = data[0].countries;
        vm.originCountries = data[1].countries;
        vm.currencies = vm.CountryService.currencies;

        // Domestic shipping settings on load
        // ---------------------------------------------------------------------------------
        // Find and pre-fill destination country with company country (favor domestic)
        const defaultPickupAddress = AddressService.getDefaultPickupAddress();
        vm.enquiry.origin_country_id = defaultPickupAddress
          ? defaultPickupAddress.country_id
          : UserSession.company.country_id;
        vm.enquiry.destination_country_id = vm.enquiry.origin_country_id;

        if (!defaultPickupAddress) {
          vm.enquiry.useExistingAddress = false;
          vm.hideAddressToggle = true;
        }

        // Manually set postal code field requirements
        initOriginPostalCodeInputState();
        initDestinationPostalCodeInputState();

        _buildBoxOptions();

        vm.busy.fetching = false;
      });
    })();

    function _buildBoxOptions() {
      $q.all([
        BoxService.getCustomBoxesSelectOptions(),
        FlatRateBoxService.getFlatRateBoxesSelectOptions(),
      ]).then(function (boxes) {
        vm.allBoxes = boxes[0].concat(boxes[1]);

        vm.enquiry.box = BoxService.selectBox(vm.allBoxes);
      });
    }

    function getItemCategories() {
      return $q(function (resolve, reject) {
        ItemCategoryService.getItemCategories()
          .then(function (itemCategories) {
            vm.itemCategories = _.filter(itemCategories, function (itemCategory) {
              if (itemCategory.id < 100) {
                return itemCategory;
              }
            });

            resolve();
          })
          .catch(function () {
            reject();
          });
      });
    }

    function getReusableShippingAddresses() {
      return AddressService.getAddresses().then(function (addresses) {
        vm.reusableShippingAddresses = addresses.reusableShippingAddresses;
        vm.enquiry.useExistingAddress = addresses.reusableShippingAddresses.length > 0;
      });
    }

    function doesCountryHavePostalCode(param) {
      return PostalCodeService.doesCountryHavePostalCode(param);
    }

    function doesCountryHaveRegex(param) {
      return PostalCodeService.doesCountryHavePostalCodeRegex(param);
    }

    // Moved to service by setting vm.enquiry and vm.quoteForm to be arguements for said function
    function resetPostalCodeInputState() {
      vm.enquiry.postal_code = null;
      vm.enquiry.destination_state = null;
      vm.enquiry.destination_city = null;

      vm.quoteForm.postalCode.$setPristine();
      vm.quoteForm.postalCode.$setUntouched();

      vm.postalCodeInvalid.destination = null;

      // Does this need to be tracked in PostalCodeService?
      vm.inputError = false;
    }

    function _identifyPostalCode(enquiry, field) {
      const countryIdByField = {
        origin_postal_code: enquiry.origin_country_id,
        postal_code: enquiry.destination_country_id,
      };

      vm.busy.findingState = true;

      PostalCodeService.validateAndLookup(enquiry[field], countryIdByField[field]).then(function (
        postalCodeResults
      ) {
        if (!postalCodeResults) {
          toastError($translate.instant('toast.default-error'));
          return;
        }

        // eslint-disable-next-line default-case
        switch (field) {
          // Add details to Origin
          case 'origin_postal_code':
            vm.postalCodeInvalid.origin = postalCodeResults.invalid_format;
            vm.postalCodeInvalid.originNotFound = postalCodeResults.notFound;

            vm.enquiry.origin_city =
              postalCodeResults.city ||
              (postalCodeResults.cities && postalCodeResults.cities[0]) ||
              (postalCodeResults.suburbs && postalCodeResults.suburbs[0]);

            vm.enquiry.origin_state =
              postalCodeResults.state || (postalCodeResults.states && postalCodeResults.states[0]);

            vm.originCityOptions = postalCodeResults.suburbs || postalCodeResults.cities || [];
            vm.originStateOptions = postalCodeResults.states;
            break;

          // Add details to Destination
          case 'postal_code':
            vm.postalCodeInvalid.destination = postalCodeResults.invalid_format;
            vm.postalCodeInvalid.destinationNotFound = postalCodeResults.notFound;

            vm.enquiry.destination_city =
              postalCodeResults.city ||
              (postalCodeResults.cities && postalCodeResults.cities[0]) ||
              (postalCodeResults.suburbs && postalCodeResults.suburbs[0]);

            vm.enquiry.destination_state =
              postalCodeResults.state || (postalCodeResults.states && postalCodeResults.states[0]);

            vm.destinationCityOptions = postalCodeResults.suburbs || postalCodeResults.cities || [];
            vm.destinationStateOptions = postalCodeResults.states;
            break;
        }

        vm.busy.findingState = false;
      });
    }

    function makeRequestToRateCalculator(payload) {
      RateCalculator.save(
        payload,
        function (data) {
          if (vm.rates) vm.reloading = true;

          let itemCategory;
          const originCountry = CountryService.findCountryName(payload.origin_country_id);
          const categoryId = payload.items[0].category_id;

          if (categoryId) {
            itemCategory = _.find(vm.itemCategories, { id: payload.items[0].category_id });
          }

          vm.unavailableServices = data.unavailable_services;
          vm.rates = data.rates;
          vm.rateOrder = 'value_for_money_rank';
          vm.showRates = true;
          vm.busy.request = false;

          $timeout(function () {
            vm.reloading = false;
          }, 350);

          if (data.rates.length) {
            OnboardingChecklistService.updateOnboarding('get_quote');

            setTimeout(() => {
              document
                .querySelector('customer-shipment-rates')
                ?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
            }, 500);
          }
        },
        function () {
          vm.showRates = false;
          vm.busy.request = false;
          toastError(
            $translate.instant('toast.fetch-error', {
              noun: $translate
                .instant('global.pluralize.quote', { COUNT: 1 }, 'messageformat')
                .toLowerCase(),
            })
          );
        }
      );
    }

    function clearQuotedRates() {
      vm.showRates = false;
    }

    function validateCategoryWeight() {
      const actualWeight = vm.enquiry.items[0].actual_weight;
      const resetActualWeight = function () {
        vm.quoteForm.actualWeight.$setPristine();
        vm.quoteForm.actualWeight.$setUntouched();
      };

      if (vm.isDocument() && actualWeight > vm.DOCUMENT_MAX_WEIGHT) {
        vm.enquiry.items[0].actual_weight = '';
        resetActualWeight();
      } else if (!vm.isDocument() && actualWeight) {
        resetActualWeight();
      }
    }

    vm.getOriginCountry = function () {
      return vm.CountryService.findCountry(vm.enquiry.origin_country_id);
    };

    vm.getDestinationCountry = function () {
      return vm.CountryService.findCountry(vm.enquiry.destination_country_id);
    };

    vm.onReceiverInfoInputChange = function (value, field) {
      vm.postalCodeInvalid.submitted = false;
      if (vm.quoteForm.originPostalCode) {
        vm.quoteForm.originPostalCode.$setValidity('origin_regex', true);
      }
      if (vm.quoteForm.postalCode) {
        vm.quoteForm.postalCode.$setValidity('destination_regex', true);
      }

      vm.enquiry[field] = value;

      // eslint-disable-next-line default-case
      switch (field) {
        case 'destination_country_id':
          initDestinationPostalCodeInputState();
          break;

        case 'postal_code':
        case 'origin_postal_code':
          _identifyPostalCode(vm.enquiry, field);
          break;

        case 'incoterms':
          vm.enquiry[field] = value ? 'DDU' : 'DDP';
          break;
      }
    };

    vm.onOriginCountrySelected = function (value) {
      vm.enquiry.origin_country_id = value;

      initOriginPostalCodeInputState();
    };

    vm.onOriginAddressSelected = function (id, address) {
      vm.enquiry.origin_address_id = id;
      vm.enquiry.origin_country_id = address.country_id;
    };

    vm.onWeightChange = function (unit, value) {
      vm.weightUnit = unit;
      vm.weightInput = value;
      vm.weightInputError = 'default';

      if (unit === 'oz') {
        vm.enquiry.items[0].actual_weight = parseFloat(vm.weightInput, 10) / 16;
      } else if (unit === 'g') {
        vm.enquiry.items[0].actual_weight = parseFloat(vm.weightInput, 10) / 1000;
      } else {
        vm.enquiry.items[0].actual_weight = vm.weightInput;
      }

      vm.enquiry.items[0].display_weight_unit = vm.weightUnit;

      $scope.$apply();
    };

    vm.onOrderSummaryInputChange = function (value, field) {
      if (field === 'box') {
        vm.enquiry.box = value;
        return;
      }

      if (field === 'set_as_residential') {
        // Maintain state of this value to display the toggle
        vm.enquiry.set_as_residential = value;

        // BE Requirements
        vm.enquiry.residential_surcharge = {
          apply_to_total_charge: value,
        };
      }

      vm.enquiry.items[0][field] = value;
    };

    vm.onCategorySelect = function (value) {
      vm.categoryHsCodeSelectedValue = value;

      vm.enquiry.items[0].category_id = value.categoryId;
      vm.enquiry.items[0].hs_code = null;

      validateCategoryWeight();

      $scope.$apply();
    };

    vm.onHsCodeSelect = function (value) {
      vm.categoryHsCodeSelectedValue = value;

      vm.enquiry.items[0].hs_code = value.hsCodeNumber;
      vm.enquiry.items[0].category_id = null;

      $scope.$apply();
    };

    /**
     * [initOriginPostalCodeInputState] Function is used to trigger checks for whether origin country requires postal code and reset the zipcode message if 'required'
     * @return {N/A}
     */
    function initOriginPostalCodeInputState() {
      vm.enquiry.origin_postal_code = null;
      vm.enquiry.origin_state = null;
      vm.enquiry.origin_city = null;

      const originCountry = vm.CountryService.findCountry(vm.enquiry.origin_country_id);
      const destinationCountry = vm.CountryService.findCountry(vm.enquiry.destination_country_id);

      vm.postalCodeSpecs.origin.has = originCountry.requirements.has_postal_code !== false;
      vm.postalCodeSpecs.origin.required = vm.enquiry.origin_country_id === vm.NEW_ZEALAND_ID;

      // Update the validation requirements of destination based on the new country selected
      vm.postalCodeSpecs.destination.required =
        (destinationCountry.requirements.postal_code_mandatory_from_origin || []).indexOf(
          originCountry.id
        ) >= 0;

      vm.postalCodeInvalid.destination = vm.postalCodeSpecs.destination.required || null;
    }

    /**
     * [initDestinationPostalCodeInputState] Function is used to trigger checks for whether destination country requires postal code and reset the zipcode message if 'required'
     * @return {N/A}
     */
    function initDestinationPostalCodeInputState() {
      vm.enquiry.postal_code = null;
      vm.enquiry.destination_state = null;
      vm.enquiry.destination_city = null;

      const originCountry = vm.CountryService.findCountry(vm.enquiry.origin_country_id);
      const destinationCountry = vm.CountryService.findCountry(vm.enquiry.destination_country_id);

      vm.postalCodeSpecs.destination.has = destinationCountry.requirements.has_postal_code;
      vm.postalCodeSpecs.destination.required =
        (destinationCountry.requirements.postal_code_mandatory_from_origin || []).indexOf(
          originCountry.id
        ) >= 0;

      vm.postalCodeInvalid.destination = vm.postalCodeSpecs.destination.required || null;
    }

    vm.toggleOriginForm = function () {
      vm.enquiry.useExistingAddress = !vm.enquiry.useExistingAddress;

      if (vm.enquiry.useExistingAddress) {
        vm.enquiry.origin_postal_code = '';
        vm.enquiry.origin_city = '';
        vm.enquiry.origin_state = '';
      } else {
        vm.enquiry.origin_address_id = null;
      }
    };

    vm.getQuote = function (enquiry) {
      vm.postalCodeInvalid.submitted = true;
      if (!enquiry.items[0].actual_weight) {
        vm.weightInputError = 'error';
        return;
      }
      if (vm.quoteForm.originPostalCode) {
        vm.quoteForm.originPostalCode.$setValidity(
          'origin_regex',
          vm.enquiry.origin_postal_code
            ? !vm.postalCodeInvalid.origin && !vm.postalCodeInvalid.originNotFound
            : !vm.postalCodeSpecs.origin.required
        );
      }

      if (vm.quoteForm.postalCode) {
        vm.quoteForm.postalCode.$setValidity(
          'destination_regex',
          vm.enquiry.postal_code
            ? !vm.postalCodeInvalid.destination && !vm.postalCodeInvalid.destinationNotFound
            : !vm.postalCodeSpecs.destination.required
        );
      }

      if (vm.quoteForm.$invalid) return;

      // Prevents the function from running when user is in the middle of finding a
      // state with postal code - this situation happens when user enters a valid
      // postal code, then changes it to an invalid one and immediately clicking on
      // get quote
      if (vm.busy.findingState) return;

      vm.busy.request = true;

      const payload = angular.copy(enquiry);
      payload.company_id = UserSession.company.id;

      if (payload.destination_state) {
        payload.destination_state = payload.destination_state.abbr;
      }

      if (payload.origin_state && payload.origin_state.abbr) {
        payload.origin_state = payload.origin_state.abbr;
      }

      if (payload.postal_code) {
        payload.destination_postal_code = payload.postal_code;
      }

      if (!vm.showCustomsValue()) {
        payload.items[0].declared_customs_value = null;
      }

      if (vm.isDocument()) {
        payload.items[0].length = null;
        payload.items[0].width = null;
        payload.items[0].height = null;
      }

      payload.excluded_courier_admin_names = ['AWX_HK', 'AWX_CN'];

      // If destination country doesn't have a postal code then just make the request
      if (!doesCountryHavePostalCode(payload)) {
        makeRequestToRateCalculator(payload);
      }
      // Logic for when a country has a regex to validate the postal code
      else if (doesCountryHaveRegex(payload)) {
        // Handling routes where postal code is required
        if (vm.postalCodeSpecs.destination.required) {
          if (payload.destination_state) {
            makeRequestToRateCalculator(payload);
          } else {
            vm.busy.request = false;
            clearQuotedRates();
          }
        }

        // Handles where postal code isnt required but there is a regex available to validate
        // it
        if (!vm.getOriginCountry().postalCodeRequired) {
          if (!vm.postalCodeInvalid.destination && !vm.postalCodeInvalid.destinationNotFound) {
            makeRequestToRateCalculator(payload);
          } else if (!payload.postal_code) {
            makeRequestToRateCalculator(payload);
            resetPostalCodeInputState();
          } else {
            vm.busy.request = false;
            clearQuotedRates();
          }
        }

        // Handles when country has a postal code but there is no regex to validate it, in which case
        // you will just make a request
      } else {
        makeRequestToRateCalculator(payload);
      }
    };

    vm.isInternationalShipment = function () {
      return vm.enquiry.origin_country_id !== vm.enquiry.destination_country_id;
    };

    vm.isDocument = function () {
      return vm.enquiry.items && vm.enquiry.items[0].category_id === 16;
    };

    vm.showCustomsValue = function () {
      return vm.isInternationalShipment() || vm.enquiry.is_insured;
    };

    vm.isUSShipments = function () {
      return vm.enquiry.destination_country_id === vm.UNITED_STATES_ID;
    };

    // eslint-disable-next-line func-names, consistent-return
    vm.isHsError = function () {
      if (vm.quoteForm && vm.quoteForm.HsCode) {
        return (
          !vm.quoteForm.HsCode.$pristine &&
          vm.quoteForm.HsCode.$dirty &&
          !vm.categoryHsCodeSelectedValue
        );
      }
    };
  }
})();
