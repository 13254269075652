import template from './enter-dimensions-modal.component.html?raw';
import style from './enter-dimensions-modal.module.scss';
import image from './enter-dimensions-modal.svg?svgo';

class EnterDimensionsModalController {
  static $inject = [
    '$state',
    'UserSession',
    'WalkthroughService',
    'MixpanelService',
    'CheckoutService',
    'API',
    'ShipmentListAdvancedService',
  ];

  constructor(
    $state,
    UserSession,
    WalkthroughService,
    MixpanelService,
    CheckoutService,
    API,
    ShipmentListAdvancedService
  ) {
    this.style = style;
    this.$state = $state;
    this.UserSession = UserSession;
    this.WalkthroughService = WalkthroughService;
    this.MixpanelService = MixpanelService;
    this.CheckoutService = CheckoutService;
    this.ShipmentListAdvancedService = ShipmentListAdvancedService;
    this.API = API;
    this.image = image;

    this.ORDER_SYNC_STEP = 'order-sync-successful';
    this.BEFORE_YOU_SHIP_STEP = 'before-you-ship';
  }

  $onInit() {
    if (this.step === this.ORDER_SYNC_STEP) {
      this.MixpanelService.track('Missing dimensions - Sync tutorial - Show Modal');
    } else {
      this.MixpanelService.track('Missing dimensions - Ship - Show Modal');
    }
  }

  filterByMissingBoxDimensions() {
    this.MixpanelService.track('Create Shipments - Missing Box Dimensions Modal', {
      action: 'see_shipments',
    });

    this.ShipmentListAdvancedService.updateFilter({
      missing_field: {
        options: [
          {
            options: [
              {
                value: 'is_missing_dimensions',
                selected: true,
              },
            ],
          },
        ],
      },
    });

    this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter();
    if (this.doNotShowAgain) this._markWalkthroughAsDone();
    this.close();
  }

  proceed() {
    this.MixpanelService.track('Create Shipments - Missing Box Dimensions Modal', {
      action: 'continue_anyway',
    });

    if (this.step === this.ORDER_SYNC_STEP) {
      this.MixpanelService.track('Missing dimensions - Sync tutorial - Got it');
    } else {
      this.MixpanelService.track('Missing dimensions - Ship - Got it');
    }

    if (this.doNotShowAgain) this._markWalkthroughAsDone();

    this._goToRequestPickupStep();
  }

  toggleDoNotShowAgain(value) {
    this.doNotShowAgain = value;
  }

  _markWalkthroughAsDone() {
    // Closing the modal marks the walkthrough as done
    this.WalkthroughService.goToNextStep(
      'order-sync-with-missing-dimensions',
      'order-sync-with-missing-dimensions-tutorial'
    );
  }

  _goToRequestPickupStep() {
    // Go to checkout
    this.$state.go(this.CheckoutService.addonsSkipped ? 'app.order-summary' : 'app.request-pickup');
  }
}

const EnterDimensionsModalComponent = {
  template,
  controller: EnterDimensionsModalController,
  bindings: {
    close: '&',
    changeStep: '&',
    step: '<',
  },
};

export { EnterDimensionsModalComponent };
