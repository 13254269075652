import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './advertising.html?raw';
import style from './advertising.module.scss';

class Advertising {
  translations = {};

  static $inject = [
    '$translate',
    'AdvertisingModal',
    'CompanyService',
    'UserSession',
    'API',
    'MixpanelService',
    'OnboardingChecklistService',
  ];

  constructor(
    $translate,
    AdvertisingModal,
    CompanyService,
    UserSession,
    API,
    MixpanelService,
    OnboardingChecklistService
  ) {
    this.style = style;
    this.AdvertisingModal = AdvertisingModal;
    this.CompanyService = CompanyService;
    this.API = API;
    this.MixpanelService = MixpanelService;
    this.OnboardingChecklistService = OnboardingChecklistService;
    this.$translate = $translate;

    this.company = angular.copy(UserSession.company);
    this.loading = { form: false, upload: false };
    this.error = { form: null, upload: null };

    // NOTE: These should only be replaced with translations when the text sent by the backend is localized
    this.ctaOptions = [
      { label: 'Book Now', id: 0 },
      { label: 'Buy Now', id: 1 },
      { label: 'Call Now', id: 2 },
      { label: 'Contact Us', id: 3 },
      { label: 'Learn More', id: 4 },
      { label: 'See Offer', id: 5 },
      { label: 'Sign Up', id: 6 },
      { label: 'Shop Now', id: 7 },
      { label: 'Use App', id: 8 },
      { label: 'Visit Us', id: 9 },
      { label: 'Leave a Review', id: 10 },
      { label: 'Leave a Comment', id: 11 },
      { label: 'None', id: 12 },
    ];
  }

  // available types: tracking_email_ad, tracking_page_ad
  $onInit() {
    this.advertisingType = `${this.type}vertising`;
    this.$translate([
      'global.setting',
      'sidebar.customer-emails',
      'sidebar.tracking-page',
      'advertising-modal.tracking-page',
      'advertising-modal.tracking-email',
    ]).then((translations) => {
      this.translations = translations;
      this.pageName =
        translations[
          this.type === 'tracking_email_ad' ? 'sidebar.tracking-page' : 'sidebar.tracking-email'
        ];
      this.typeName =
        translations[
          this.type === 'tracking_email_ad'
            ? 'advertising-modal.tracking-page'
            : 'advertising-modal.tracking-email'
        ];
    });

    if (this.formData && this.formData[this.advertisingType]) {
      this._setCTAValue(this.formData);
    } else {
      this.formData[this.advertisingType] = {
        call_to_action: null,
        link_url: null,
      };
    }
  }

  onLinkChange(value) {
    this.formData[this.advertisingType].link_url = value;
  }

  onCallToActionChange(value) {
    this.formData[this.advertisingType].call_to_action = value;
  }

  linkRequired() {
    return (
      this.formData &&
      this.formData[this.advertisingType] &&
      this.formData[this.advertisingType].call_to_action &&
      this.formData[this.advertisingType].call_to_action !== 'None' &&
      this.formData[this.advertisingType].call_to_action !== '' &&
      this.company[this.type].asset_url
    );
  }

  setUploadAdUrl() {
    return `${this.API.endpoint}/companies/${this.company.id}/upload_asset?type=${this.type}`;
  }

  onStartUploadAd() {
    this.loading.upload = true;
  }

  onFinishUploadAd(error, response) {
    this.loading.upload = false;

    if (error) {
      this.error.upload = error.data && error.data.status;
      return;
    }

    this.error.upload = null;
    this.company[this.type].asset_url = response.data && response.data.asset_url; // sets the image preview
    this.company[this.type].confirm_asset_id = response.data && response.data.asset_id; // prepares the post payload
    this.company[this.type].delete_asset_id = this.company[this.type].asset_id;

    this.MixpanelService.track(`Settings - ${this.pageName} - Advertising - Upload Image`);
  }

  onDeleteAd() {
    this.company[this.type].asset_url = null;
    this.company[this.type].confirm_asset_id = null; // avoid sending both confirm and delete
    this.company[this.type].delete_asset_id = this.company[this.type].asset_id; // prepares the post payload

    this.MixpanelService.track(`Settings - ${this.pageName} - Advertising - Delete Image`);
  }

  httpValidation(url) {
    const httpRegex = /^((http|https|ftp):\/\/)/;

    return httpRegex.test(url);
  }

  onSubmit() {
    if (this.loading.upload) {
      toastError(this.$translate.instant('toast.upload-busy'));
      return;
    }

    if (this.advertisingForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading.form = true;

    this._setCTAValue(this.formData);

    if (!this.httpValidation(this.formData[this.advertisingType].link_url)) {
      this.formData[this.advertisingType].link_url = `http://${
        this.formData[this.advertisingType].link_url
      }`;
    }

    this.CompanyService.updateCompany({
      [this.type]: {
        asset_url: this.company[this.type].asset_url,
        confirm_asset_id: this.company[this.type].confirm_asset_id,
        delete_asset_id: this.company[this.type].delete_asset_id,
      },
      [this.advertisingType]: {
        call_to_action: this.formData[this.advertisingType].call_to_action,
        link_url: this.formData[this.advertisingType].link_url,
      },
    })
      .then((response) => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations['global.setting'].toLowerCase() },
            'messageformat'
          )
        );
        this.AdvertisingModal.close(response);
        this.OnboardingChecklistService.updateOnboarding('setup_branding');
      })
      .catch((error) => {
        this.error.form = this.$translate.instant('toast.update-error', {
          values: {
            noun: this.translations['global.setting'].toLowerCase(),
            details: error.data && error.data.status,
          },
        });
      })
      .finally(() => {
        this.loading.form = false;
      });
  }

  // Manually change selected value of 'None' to empty string as
  _setCTAValue(formData) {
    if (formData[this.advertisingType].call_to_action === 'None') {
      formData[this.advertisingType].call_to_action = '';
    } else if (formData[this.advertisingType].call_to_action === '') {
      formData[this.advertisingType].call_to_action = 'None';
    }
  }
}

const AdvertisingComponent = {
  template,
  controller: Advertising,
  bindings: {
    type: '<',
    formData: '<',
  },
};

export { AdvertisingComponent };
