import React from 'react';
import type { SVGProps } from 'react';

function SvgTrackingExcellent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      {...props}
    >
      <path
        d="M0.5 1.5C0.5 0.671573 1.17157 0 2 0C2.82843 0 3.5 0.671573 3.5 1.5V10.5C3.5 11.3284 2.82843 12 2 12C1.17157 12 0.5 11.3284 0.5 10.5V1.5Z"
        fill="#50D4A4"
      />
      <path
        d="M5.83325 1.5C5.83325 0.671573 6.50482 0 7.33325 0C8.16168 0 8.83325 0.671573 8.83325 1.5V10.5C8.83325 11.3284 8.16168 12 7.33325 12C6.50482 12 5.83325 11.3284 5.83325 10.5V1.5Z"
        fill="#50D4A4"
      />
      <path
        d="M11.1667 1.5C11.1667 0.671573 11.8383 0 12.6667 0C13.4951 0 14.1667 0.671573 14.1667 1.5V10.5C14.1667 11.3284 13.4951 12 12.6667 12C11.8383 12 11.1667 11.3284 11.1667 10.5V1.5Z"
        fill="#50D4A4"
      />
      <path
        d="M16.5 1.5C16.5 0.671573 17.1715 0 18 0C18.8284 0 19.5 0.671573 19.5 1.5V10.5C19.5 11.3284 18.8284 12 18 12C17.1715 12 16.5 11.3284 16.5 10.5V1.5Z"
        fill="#50D4A4"
      />
    </svg>
  );
}

export default SvgTrackingExcellent;
