import { IUserSession } from 'typings/user-session';
import { IWalkthroughService } from 'typings/walkthrough';

import { IComponentController } from 'angular';
import template from './adjustments-modal.html?raw';

class AdjustmentsModal implements IComponentController {
  pendingState = 'pending_next_settlement';

  static $inject = ['$state', '$window', 'UserSession', 'WalkthroughService'];
  constructor(
    private $state: ng.ui.IStateService,
    private $window: ng.IWindowService,
    private UserSession: IUserSession,
    private WalkthroughService: IWalkthroughService
  ) {}

  private markAsViewed = () => {
    this.WalkthroughService.updateUserFlowRecords('walkthrough', 'adjustment').then(() => {
      this.UserSession.user.flow_records.walkthrough.adjustment = true;
    });
  };

  onCloseModal = () => {
    this.markAsViewed();
  };

  onSeeDetails = () => {
    this.markAsViewed();
    this.$state.go('app.account.billing', { settlement_state: this.pendingState });
  };

  onContactSupport = () => {
    this.$window.zE?.('messenger', 'open');
  };
}

const AdjustmentsModalComponent: ng.IComponentOptions = {
  controller: AdjustmentsModal,
  template,
  bindings: {},
};

export { AdjustmentsModalComponent };
