import angular from 'angular';

import { TransactionRecord } from './transaction-record.service';
import { TransactionRecordResource } from './transaction-record.resource';
import { FinanceDocumentsResource } from './finance-documents.resource';
import { PaymentResource } from './payment.resource';

angular
  .module('app.global.transaction-record', ['app.factory.UserSession', 'ngResource'])
  .service('TransactionRecord', TransactionRecord)
  .service('TransactionRecordResource', TransactionRecordResource)
  .service('FinanceDocumentsResource', FinanceDocumentsResource)
  .service('PaymentResource', PaymentResource);
