import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input } from 'easyship-components';

interface ConfirmationProps {
  onChange: (signature: string) => void;
  hasError: boolean;
}

export default function Confirmation({ onChange, hasError }: ConfirmationProps) {
  const intl = useIntl();

  return (
    <div className="my-4">
      <p className="mb-5 text-ink-900 text-base">
        <FormattedMessage id="churn-reasons-flow.close-account-confirmation.sub-header" />
      </p>
      <Input
        type="password"
        label={intl.formatMessage({
          id: 'churn-reasons-flow.close-account-confirmation.input-placeholder',
        })}
        onChange={(e) => onChange(e.currentTarget.value)}
        status={hasError ? 'error' : 'default'}
      />
    </div>
  );
}
