import template from './star-rating.html?raw';
import style from './star-rating.module.scss';

class StarRating {
  constructor() {
    this.style = style;
  }

  $onInit() {
    this.calculatedWidth = this.calculateWidth(this.esRating);
  }

  calculateWidth(rating) {
    const absoluteWidth = (rating / 5) * 110;
    // 88px is 4 stars
    if (absoluteWidth >= 88) {
      return `${absoluteWidth + 4}px`;
    }
    return absoluteWidth;
  }
}

const StarRatingComponent = {
  controller: StarRating,
  template,
  bindings: {
    esRating: '<',
    esCount: '<',
  },
};

export { StarRatingComponent };
