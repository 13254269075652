import { IRatesOrderType, IRateItem } from 'typings/rates';
import { IShipmentListItem } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import style from './courier-shipment-rates-table.module.scss';
import template from './courier-shipment-rates-table.html?raw';

class CourierShipmentRatesTable implements IComponentController {
  style = style;
  esShipment: IShipmentListItem | null = null;
  isEfulAccount = false;
  flatRateBoxId: string | null = null;
  readonly valueForMoney: IRatesOrderType = 'value_for_money_rank';
  readonly deliveryTime: IRatesOrderType = 'delivery_time_rank';
  readonly courierSurveysRating: IRatesOrderType = '-courier_surveys_rating';
  readonly totalCharge: IRatesOrderType = 'total_charge';
  rateOrder: string = this.totalCharge;
  isDomestic = false;

  static $inject = ['UserSession', 'MixpanelService'];
  constructor(private UserSession: IUserSession, private MixpanelService: MixpanelService) {}

  $onInit() {
    this.isEfulAccount = this.UserSession.isCompanyEfulfilment();
    if (this.esShipment?.destination_country_id === this.esShipment?.origin_country_id)
      this.isDomestic = true;
    this.flatRateBoxId =
      this.esShipment?.parcels_attributes.reduce<string | null>(
        (agg, parcel) => (parcel.packageable_type === 'FlatRateBox' ? parcel.packageable_id : agg),
        null
      ) || null;
  }

  sortCouriersBy(rateType: IRatesOrderType): void {
    if (
      this.esShipment &&
      this.esShipment.price_comparison &&
      this.esShipment.price_comparison.rates.length > 0
    ) {
      this.rateOrder = rateType;
    }

    this.MixpanelService.track('Sort Courier By', {
      sort_courier_by: rateType,
    });
  }

  isIconActive(rateType: IRatesOrderType): boolean {
    if (
      this.rateOrder === rateType &&
      this.esShipment &&
      this.esShipment.price_comparison &&
      this.esShipment.price_comparison.rates.length > 0
    ) {
      return true;
    }

    return false;
  }

  isHoverable(rateItem: IRateItem): boolean {
    if (this.esShipment) {
      return rateItem.courier_id !== this.esShipment.courier_id;
    }

    return false;
  }

  get isIconDisabled(): boolean {
    if (
      this.esShipment &&
      this.esShipment.price_comparison &&
      this.esShipment.price_comparison.rates.length === 0
    ) {
      return true;
    }

    return false;
  }

  get rates(): IRateItem[] | null {
    if (this.esShipment && this.esShipment.price_comparison) {
      return this.esShipment.price_comparison.rates;
    }

    return null;
  }

  get showUpsTrademarkMessage(): boolean {
    if (this.rates) {
      return this.rates.some((rate) => {
        return rate.courier_logo === 'ups';
      });
    }
    return false;
  }
}

const CourierShipmentRatesTableComponent: ng.IComponentOptions = {
  controller: CourierShipmentRatesTable,
  template,
  bindings: {
    esShipment: '<',
    esQuotePage: '<',
    esOnSelect: '&',
  },
};

export { CourierShipmentRatesTableComponent };
