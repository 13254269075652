import angular from 'angular';
import { IUserSession } from 'typings/user-session';

const MODULE_NAME = 'app.filter.intlCurrency';

intlCurrency.$inject = ['UserSession'];
function intlCurrency(UserSession: IUserSession) {
  return (value = 0, currency?: string, options?: Intl.NumberFormatOptions): string => {
    if (Intl && 'NumberFormat' in Intl) {
      return new Intl.NumberFormat(UserSession.user.dashboard_settings?.language?.code || 'en', {
        ...options,
        style: 'currency',
        currency,
      }).format(value);
    }

    // https://caniuse.com/mdn-javascript_builtins_intl_numberformat_format
    return `${currency} ${value.toFixed(2)}`;
  };
}

angular.module(MODULE_NAME, []).filter('intlCurrency', intlCurrency);

export default MODULE_NAME;
