(function () {
  Rule.$inject = [
    '$translate',
    'AutomationRule',
    'PlatformService',
    'StoreService',
    'ItemCategoryService',
    'CountryService',
    'HelperService',
    'MixpanelService',
  ];
  function Rule(
    $translate,
    AutomationRule,
    PlatformService,
    StoreService,
    ItemCategoryService,
    CountryService,
    HelperService,
    MixpanelService
  ) {
    const Rule = this;

    Rule.automation_rules_copy = [];
    Rule.options = {};
    Rule.show = {};
    Rule.show.chooseRule = true;
    let automation_rules = [];
    let tempID = 0;

    Rule.RULE_IDS = {
      MatchCategory: 1,
      MatchCountry: 2,
      MatchOperation: 3, // customs value
      MatchCustomsValue: 3,
      MatchPlatformNameOrStore: 4,
      // 5 is not used?
      MatchAll: 6,
      // 7 is not used?
      MatchItemsCount: 8,
      MatchWeight: 9,
      IncludeOrderTagName: 10,
      MatchState: 12,
      MatchSku: 13,
      MatchZipcode: 14,
    };

    const forcedSort = [
      'Automation::Rules::MatchAll',
      'Automation::Rules::MatchCountry',
      'Automation::Rules::MatchState',
      'Automation::Rules::MatchZipcode',
      'Automation::Rules::MatchCategory',
      'Automation::Rules::MatchSku',
      'Automation::Rules::IncludeOrderTagName',
      'Automation::Rules::MatchPlatformNameOrStore',
      'Automation::Rules::MatchBuyerSelectedCourierName',
      'Automation::Rules::MatchItemsCount',
      'Automation::Rules::MatchOperation',
      'Automation::Rules::MatchWeight',
    ];

    Rule.orderTagsSelected = [];

    ItemCategoryService.getItemCategories()
      .then(function () {
        Rule.item_categories = ItemCategoryService.itemCategories;
      })
      .catch(function () {
        // TODO: set fallback
      });

    CountryService.getCountries()
      .then(function () {
        Rule.countries = _.sortBy(CountryService.countries, 'id');
      })
      .catch(function () {
        // TODO: set fallback
      });

    Rule.platformNames = PlatformService.getPlatformNames();

    Rule.buildStoresAndPlatformsArray = function () {
      Rule.storesAndPlatformsArray = [];

      _.forEach(StoreService.stores, function (store) {
        if (store.platform.actions.automations)
          Rule.storesAndPlatformsArray.push({
            type: $translate.instant('automations.sales-channel-groups.store'),
            name: store.name,
            id: store.id,
          });
      });

      _.forEach(Rule.platformNames, function (platform) {
        Rule.storesAndPlatformsArray.push({
          type: $translate.instant('automations.sales-channel-groups.platform'),
          name: platform,
          id: null,
        });
      });
    };

    // TO BE SET AS A CONSTANT
    // Note: the values are not just English text to show the users,
    // these are what the BE looks for when executing shipping rules
    Rule.signs = [
      {
        value: 'greater than',
        display: $translate.instant('global.relation.is_greater_than'),
      },
      {
        value: 'greater than or equal to',
        display: $translate.instant('global.relation.is_greater_than_or_equal_to'),
      },
      {
        value: 'less than',
        display: $translate.instant('global.relation.is_less_than'),
      },
      {
        value: 'less than or equal to',
        display: $translate.instant('global.relation.is_less_than_or_equal_to'),
      },
      {
        value: 'equal to',
        display: $translate.instant('global.relation.is_equal_to'),
      },
    ];

    Rule.skuOptions = ['contains', 'does not contain', 'only contains'];

    Rule.anyNoneOptions = ['is_any_of', 'is_none_of', 'starts_with'].map(function (option) {
      return { value: option, label: $translate.instant(`global.relation.${option}`) };
    });

    // TO BE SET AS A CONSTANT
    Rule.currencies = [
      'AED',
      'ARS',
      'AUD',
      'BGN',
      'BND',
      'BOB',
      'BRL',
      'CAD',
      'CHF',
      'CLP',
      'CNY',
      'COP',
      'CZK',
      'DKK',
      'EGP',
      'EUR',
      'FJD',
      'GBP',
      'HKD',
      'HRK',
      'HUF',
      'IDR',
      'ILS',
      'INR',
      'JPY',
      'KES',
      'KRW',
      'LTL',
      'MAD',
      'MXN',
      'MYR',
      'NGN',
      'NOK',
      'NZD',
      'PEN',
      'PHP',
      'PKR',
      'PLN',
      'RON',
      'RSD',
      'RUB',
      'SAR',
      'SEK',
      'SGD',
      'THB',
      'TRY',
      'TWD',
      'UAH',
      'USD',
      'VEF',
      'VND',
      'ZAR',
    ];

    AutomationRule.query(
      {},
      {},
      function (data) {
        automation_rules = data.automation_rules
          .sort(function (a, b) {
            return (
              forcedSort.indexOf(a.automation_rule_name) -
              forcedSort.indexOf(b.automation_rule_name)
            );
          })
          .map(function (rule) {
            return Object.assign(rule, {
              translation: $translate.instant(
                `automations.condition-labels.${Rule.abbr(rule.automation_rule_name)}`
              ),
            });
          });

        // Create a deep copy to be able to modify and reset the rules using Rule.filterRules()
        angular.copy(automation_rules, Rule.automation_rules_copy);
        Rule.automation_rules = Rule.automation_rules_copy;
      },
      function () {}
    );

    Rule.abbr = function (conditionName) {
      return conditionName.replace('Automation::Rules::', '');
    };

    /**
     * Remove the rule "All shipments" if other rules are already created
     */
    Rule.filterRules = function () {
      if (Rule.show.hasRules) {
        Rule.automation_rules = _.reject(automation_rules, { automation_rule_id: 6 });
      } else {
        Rule.automation_rules = automation_rules;
      }
    };

    /**
     * Show addRule directly if match all is selected (no options are needed)
     */
    Rule.matchAll = function () {
      Rule.show.addRule = true;
    };

    /**
     * Display the rule options and define the right array of options
     * These functions are called by $scope.displayRuleOptions in the shipping-rules.controller
     */
    Rule.matchCategory = function () {
      Rule.options.matchCategoryOptions = true;
      Rule.categoriesSelected = [];
    };

    Rule.matchCountry = function () {
      Rule.options.matchCountryOptions = true;
      Rule.countriesSelected = [];
    };

    Rule.matchState = function () {
      Rule.options.matchStateOptions = true;
      Rule.statesSelected = [];
    };

    Rule.matchOrderTags = function () {
      Rule.options.matchOrderTagsOptions = true;
      Rule.orderTagsSelected = [];
    };

    Rule.matchPlatformName = function () {
      Rule.options.matchPlatformNameOptions = true;
      Rule.platformsAndStores = [];
    };

    Rule.matchOperation = function () {
      Rule.options.matchOperationOptions = true;
    };

    Rule.matchItems = function () {
      Rule.options.matchItemsOptions = true;
    };

    Rule.matchWeight = function () {
      Rule.options.matchWeightOptions = true;
    };

    Rule.serviceType = function () {
      Rule.options.matchServiceTypeOptions = true;
      Rule.serviceTypeRuleSelected = [];
    };

    Rule.matchBuyerSelectedSolution = function () {
      Rule.options.matchBuyerSelectedSolution = true;
      Rule.newBuyerSelectedSolutionPattern = '';
      Rule.newBuyerSelectedSolutionRelation = 'contains';
    };

    Rule.matchSku = function () {
      Rule.options.matchSkuOption = true;
      Rule.skuCondition = {
        select: 'contains',
        tags: [],
      };
    };

    Rule.matchZipCode = function () {
      Rule.options.matchZipCodeOption = true;
      Rule.zipCodeCondition = {
        operator: 'is_any_of',
        zipcodes: [],
      };
    };

    /**
     * Clear every selections if the user select another rule without adding(saving) it
     */
    Rule.clearAllRulesSelected = function () {
      Rule.options = {};
      Rule.categoriesSelected = [];
      Rule.countriesSelected = [];
      Rule.statesSelected = [];
      Rule.serviceTypeRuleSelected = [];
      Rule.platformsAndStores = [];
      Rule.newSignToAdd = null;
      Rule.newValueToAdd = null;
      Rule.newCurrencyToAdd = null;
      Rule.newItemsSignToAdd = null;
      Rule.newItemsValueToAdd = null;
      Rule.newWeightSignToAdd = null;
      Rule.newWeightValueToAdd = null;
    };

    /**
     * - Populates the arrays of selected options
     * - Clear ng-model
     * - Show the 'save condition' button
     * Note: Match operation does not need array and get the value   from ng-model
     */
    Rule.addCategoryToOptions = function () {
      if (Rule.categoriesSelected.indexOf(Rule.newCategoryToAdd) < 0) {
        Rule.categoriesSelected.push(Rule.newCategoryToAdd);
      }
      Rule.newCategoryToAdd = null;
      Rule.show.addRule = showAddRuleButton(Rule.categoriesSelected);
    };

    Rule.addCountries = function (value) {
      Rule.countriesSelected = value;
      Rule.show.addRule = showAddRuleButton(Rule.countriesSelected);
    };

    Rule.addStates = function (value) {
      Rule.statesSelected = value;
      Rule.show.addRule = showAddRuleButton(Rule.statesSelected);
    };

    Rule.addOrderTags = function (value) {
      Rule.orderTagsSelected = value;
      Rule.show.addRule = showAddRuleButton(Rule.orderTagsSelected);
    };

    Rule.addPlatformNameToOptions = function () {
      // prevent user from selecting a second time
      if (
        Rule.newPlatformToAdd.type === 'Platforms'
          ? !HelperService.hasItemWithName(Rule.platformsAndStores, Rule.newPlatformToAdd.name)
          : !HelperService.hasItemWithId(Rule.platformsAndStores, Rule.newPlatformToAdd.id)
      ) {
        Rule.platformsAndStores.push(Rule.newPlatformToAdd);
      }
      Rule.newPlatformToAdd = null;
      Rule.show.addRule = showAddRuleButton(Rule.platformsAndStores);
    };

    // deprecated
    Rule.addServiceTypeToOptions = function () {
      Rule.serviceTypeRuleSelected.push(Rule.newServiceTypeToAdd);
      Rule.newServiceTypeToAdd = null;
      Rule.show.addRule = showAddRuleButton(Rule.serviceTypeRuleSelected);
    };

    Rule.addSkuCondition = function (value) {
      Rule.skuCondition = value;
      Rule.show.addRule = showAddRuleButton(Rule.skuCondition.tags);
    };

    Rule.modifyZipCodeCondition = function (value, remainder, key) {
      Rule.zipCodeCondition[key] = value;
      if (remainder !== null) Rule.zipCodeCondition.remainder = remainder;
      Rule.show.addRule = showAddRuleButton(Rule.zipCodeCondition.zipcodes);
    };

    /**
     * Delete option from an action using splice
     * Check if there is stilll some option to show or not 'Save condition'
     *
     * @param  {integer} index
     * @param  {array}   array of options
     */
    Rule.deleteOptionRule = function (index, array) {
      array.splice(index, 1);
      Rule.show.addRule = showAddRuleButton(array);
    };

    /**
     * Shows the Add Condition button if at least one option is added
     * @param  {array}
     * @param  {integer,string} a,b,c (only for MatchOperation)
     */
    function showAddRuleButton(array, a, b, c) {
      if (array) {
        return array.length;
      }
      // Check if all match operation fields exist
      if (a && b && c) {
        return a && b && c;
      }
      return false;
    }

    /**
     * call showAddRuleButton for match operation
     * @param  {array}
     * @param  {integer,string} a,b,c
     */
    Rule.checkMatchOperation = function (array, a, b, c) {
      Rule.show.addRule = showAddRuleButton(array, a, b, c);
    };

    /**
     * call showAddRuleButton for match operation
     * @param  {array}
     * @param  {integer,string} a,b,c
     */
    Rule.checkMatchItems = function (array, a, b, c) {
      Rule.show.addRule = showAddRuleButton(array, a, b, c);
    };

    /**
     * call showAddRuleButton for match operation
     * @param  {array}
     * @param  {integer,string} a,b,c
     */
    Rule.checkMatchWeight = function (array, a, b, c) {
      Rule.show.addRule = showAddRuleButton(array, a, b, c);
    };

    /**
     * call showAddRuleButton for match buyer-preffered service
     * @param  {string} pattern
     */
    Rule.checkMatchBuyerSelectedSolutionPattern = function (pattern) {
      Rule.show.addRule = pattern && pattern.length > 0;
    };

    /**
     * Check if 'Match all' condition can be available
     * Show the condition selector
     * Hide 'add another condition' button
     */
    Rule.addAnotherRule = function () {
      Rule.filterRules();
      Rule.show.chooseRule = true;
      Rule.show.addAnotherRule = false;
    };

    /**
     * Builds a rule object with the selected options and the type of rule and pushes it to automation.rules_attributes
     */
    Rule.addRule = function (arrayOfAttributes) {
      // Increment the tempID
      tempID++;
      let rule = {};
      if (Rule.show.editRule) {
        rule = Rule.newRuleName;
      } else {
        rule.automation_rule_id = Rule.newRuleName.automation_rule_id;
        rule.name = Rule.newRuleName.automation_rule_name;
        rule.slug = Rule.newRuleName.automation_rule_slug;
        // Attribute a temporary ID to be able to identify the rule when editing if it has not been saved in the db yet
        rule.tempID = tempID;
      }
      rule.options = {};

      if (Rule.options.matchCategoryOptions) {
        rule.options.category_ids = Rule.categoriesSelected;
      }

      if (Rule.options.matchCountryOptions) {
        rule.options.country_ids = Rule.countriesSelected;
      }

      if (Rule.options.matchStateOptions) {
        rule.options.states = Rule.statesSelected;
      }

      if (Rule.options.matchOrderTagsOptions) {
        rule.options.order_tag_list = Rule.orderTagsSelected.toString();
      }

      if (Rule.options.matchPlatformNameOptions) {
        // rule.options = formatStoresAndPlatformsArray(Rule.platformsAndStores);
        rule.options.platformsAndStores = Rule.platformsAndStores;
      }

      if (Rule.options.matchServiceTypeOptions) {
        rule.options.service_type_ids = Rule.serviceTypeRuleSelected;
      }

      if (Rule.options.matchOperationOptions) {
        rule.options.sign = Rule.newSignToAdd;
        rule.options.value = Rule.newValueToAdd;
        rule.options.currency = Rule.newCurrencyToAdd.toString();
      }

      if (Rule.options.matchItemsOptions) {
        rule.options.sign = Rule.newItemsSignToAdd;
        rule.options.shipment_items_count = Rule.newItemsValueToAdd;
      }

      if (Rule.options.matchWeightOptions) {
        rule.options.sign = Rule.newWeightSignToAdd;
        rule.options.total_actual_weight = Rule.newWeightValueToAdd;
      }

      if (Rule.options.matchBuyerSelectedSolution) {
        rule.options.buyer_selected_courier_name = Rule.newBuyerSelectedSolutionPattern;
        rule.options.sign = Rule.newBuyerSelectedSolutionRelation;
      }

      if (Rule.options.matchSkuOption) {
        rule.options.sign = Rule.skuCondition.select;
        rule.options.shipment_items_sku = Rule.skuCondition.tags;
      }

      if (Rule.options.matchZipCodeOption) {
        if (Rule.zipCodeCondition.remainder) {
          Rule.zipCodeCondition.zipcodes.push(Rule.zipCodeCondition.remainder);
          Rule.zipCodeCondition.remainder = '';
        }
        rule.options.operator = Rule.zipCodeCondition.operator;
        rule.options.zipcodes = Rule.zipCodeCondition.zipcodes.join(',');
      }

      if (!Rule.show.editRule) {
        arrayOfAttributes.push(rule);
      }

      Rule.hasRules(arrayOfAttributes);

      // Does not allow to add more rules if the user selects Every Shipments (MatchAll)
      if (rule.name === 'Automation::Rules::MatchAll') {
        Rule.show.chooseRule = false;
        Rule.show.addAnotherRule = false;
      }

      MixpanelService.track('Shipping Rules - Add Condition', { name: rule.name });
    };

    /**
     * Format the rule object's content for editing
     * @param {object} rule
     */
    Rule.editRule = function (rule) {
      // Use the actual id to identify the rule to edit if it has been saved in the db already
      // otherwise use the temporary ID
      if (rule.id) {
        Rule.ruleToEdit = rule.id;
      } else {
        Rule.ruleToEdit = rule.tempID;
      }
      Rule.newRuleName = rule;
      Rule.show.editRule = true;
      Rule.show.chooseRule = false;
      Rule.show.addAnotherRule = false;
      Rule.options = {};

      switch (rule.name) {
        case 'Automation::Rules::MatchCategory':
          Rule.options.matchCategoryOptions = true;
          Rule.categoriesSelected = rule.options.category_ids;
          break;

        case 'Automation::Rules::MatchCountry':
          Rule.options.matchCountryOptions = true;
          Rule.countriesSelected = rule.options.country_ids;
          break;

        case 'Automation::Rules::MatchState':
          Rule.options.matchStateOptions = true;
          Rule.statesSelected = rule.options.states;
          break;

        case 'Automation::Rules::IncludeOrderTagName':
          Rule.options.matchOrderTagsOptions = true;
          Rule.orderTagsSelected = rule.options.order_tag_list.split(',');
          break;

        case 'Automation::Rules::MatchPlatformNameOrStore':
          Rule.options.matchPlatformNameOptions = true;
          Rule.platformsAndStores = formatStoresAndPlatformsArrayToEdit(rule.options);
          break;

        case 'Automation::Rules::MatchServiceType':
          Rule.options.matchServiceTypeOptions = true;
          Rule.serviceTypeRuleSelected = rule.options.service_type_ids;
          break;

        case 'Automation::Rules::MatchOperation':
          Rule.options.matchOperationOptions = true;
          Rule.newValueToAdd = parseInt(rule.options.value);
          Rule.newSignToAdd = rule.options.sign;
          Rule.newCurrencyToAdd = rule.options.currency;
          break;

        case 'Automation::Rules::MatchItemsCount':
          Rule.options.matchItemsOptions = true;
          Rule.newItemsValueToAdd = parseInt(rule.options.shipment_items_count);
          Rule.newItemsSignToAdd = rule.options.sign;
          break;

        case 'Automation::Rules::MatchWeight':
          Rule.options.matchWeightOptions = true;
          Rule.newWeightValueToAdd = parseFloat(rule.options.total_actual_weight);
          Rule.newWeightSignToAdd = rule.options.sign;
          break;

        case 'Automation::Rules::MatchBuyerSelectedCourierName':
          Rule.options.matchBuyerSelectedSolution = true;
          Rule.newBuyerSelectedSolutionPattern = rule.options.buyer_selected_courier_name;
          Rule.newBuyerSelectedSolutionRelation = rule.options.sign;
          break;

        case 'Automation::Rules::MatchSku':
          Rule.options.matchSkuOption = true;
          Rule.skuCondition = {
            select: rule.options.sign,
            tags: rule.options.shipment_items_sku,
          };
          break;

        case 'Automation::Rules::MatchZipcode':
          Rule.options.matchZipCodeOption = true;
          Rule.zipCodeCondition = {
            operator: rule.options.operator,
            zipcodes: rule.options.zipcodes.split(','),
          };
          break;
      }
    };

    /**
     * Check if the automation has rules.
     * If so, display the right elements
     * The back end uses nested attributes: the 'deleted' rules will still be present in the array (until the automation is saved ) but with an extra key called _destroy
     *
     * @param  {array} rulesAttributes
     */
    Rule.hasRules = function (arrayOfAttributes) {
      // Check if all rules are set as to be destroyed
      const allToDestroy = _.every(arrayOfAttributes, '_destroy', true);

      if (arrayOfAttributes.length === 0 || allToDestroy) {
        Rule.show.hasRules = false;
        Rule.show.chooseRule = true;
        Rule.show.addAnotherRule = false;
      } else {
        Rule.show.hasRules = true;
      }
    };

    /**
     * Format the rule options from the database to use it as arrays
     *
     * @param  {object} rule
     */
    Rule.formatRule = function (rule) {
      if (rule.options.country_ids) {
        rule.options.country_ids = rule.options.country_ids.split(',').map(function (countryId) {
          return JSON.parse(countryId);
        });
      }

      if (rule.options.states) {
        rule.options.states = rule.options.states.split(',');
      }

      if (rule.options.category_ids) {
        rule.options.category_ids = rule.options.category_ids.split(',');
      }

      if (rule.options.service_type_ids) {
        rule.options.service_type_ids = rule.options.service_type_ids.split(',');
      }

      if (rule.options.platform_names || rule.options.store_ids) {
        rule.options.platformsAndStores = formatStoresAndPlatformsArrayToEdit(rule.options);
      }

      return rule;
    };

    /**
     * [formatStoresAndPlatformsArrayToEdit]
     * @return {Object}
     */
    function formatStoresAndPlatformsArrayToEdit(optionObject) {
      let arrayOfObjects = [];

      if (optionObject.store_ids) {
        const arrayOfStoreIds = optionObject.store_ids.split(',');

        _.forEach(arrayOfStoreIds, function (storeId) {
          const store = _.find(StoreService.stores, { id: storeId });

          if (store) {
            arrayOfObjects.push({ type: 'Your Stores', name: store.name, id: store.id });
          }
        });
      }

      if (optionObject.platform_names) {
        const arrayOfPlatforms = optionObject.platform_names.split(',');

        _.forEach(arrayOfPlatforms, function (platform) {
          arrayOfObjects.push({ type: 'Platforms', name: platform, id: null });
        });
      }

      if (!arrayOfObjects.length && optionObject.platformsAndStores) {
        arrayOfObjects = optionObject.platformsAndStores;
      }

      return arrayOfObjects;
    }

    Rule.ruleIsCategory = function (rule) {
      return rule.automation_rule_id === 1;
    };

    Rule.ruleIsCountry = function (rule) {
      return rule.automation_rule_id === 2;
    };

    Rule.ruleIsCustomsValue = function (rule) {
      return rule.automation_rule_id === 3;
    };

    Rule.ruleIsPlatformOrStore = function (rule) {
      return rule.automation_rule_id === 4;
    };

    Rule.ruleIsItemsCount = function (rule) {
      return rule.automation_rule_id === 8;
    };

    Rule.ruleIsWeight = function (rule) {
      return rule.automation_rule_id === 9;
    };

    Rule.ruleIsOrderTags = function (rule) {
      return rule.automation_rule_id === 10;
    };

    Rule.ruleIsBuyerSelectedShippingSolution = function (rule) {
      return rule.automation_rule_id === 11;
    };

    Rule.ruleIsState = function (rule) {
      return rule.automation_rule_id === 12;
    };

    Rule.ruleIsSku = function (rule) {
      return rule.automation_rule_id === 13;
    };
  }

  angular.module('app.service.Rule', []).service('Rule', Rule);
})();
