import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import COLORS from '@client/src/colors';
import Button from '@client/core/components/react/Button';
import MaskOverlay from '@client/core/components/react/MaskOverlay';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';

interface ProductDetailsResetConfirmationProps {
  children: JSX.Element;
  visible: boolean;
  isUpdating: boolean;
  onBack: () => void;
  onDiscard: () => void;
  onSave: () => void;
}

export default function ProductDetailsResetConfirmation({
  children,
  visible,
  isUpdating,
  onBack,
  onDiscard,
  onSave,
}: ProductDetailsResetConfirmationProps): ReactElement<ProductDetailsResetConfirmationProps> {
  const userSession = useUserSession();
  const canEditProduct = !!userSession.user.userRights['products.edit'];

  return (
    <MaskOverlay
      height="100%"
      display="flex"
      flexDirection="column"
      maskProps={{ zIndex: 1101 }}
      visible={visible}
      innerContent={
        <Box height="100%" clone>
          <Grid container justifyContent="center" alignItems="center">
            <Grid container spacing={6} direction="column">
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ color: COLORS.inkDarkest, fontSize: 16, textAlign: 'center' }}
                >
                  <FormattedMessage id="forms.save-changes" />
                </Typography>
              </Grid>
              <Grid container item alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item>
                  <Button
                    style={{ color: COLORS.inkNormal }}
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={onBack}
                  >
                    <FormattedMessage id="forms.back-to-edit" />
                  </Button>
                </Grid>
                <Grid item>
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="default"
                        onClick={onDiscard}
                        style={{ minWidth: 130 }}
                        disabled={isUpdating}
                      >
                        <FormattedMessage id="forms.discard-edits" />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSave}
                        style={{ width: 130 }}
                        loading={isUpdating}
                        disabled={!canEditProduct}
                      >
                        <FormattedMessage id="global.save" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    >
      {children}
    </MaskOverlay>
  );
}
