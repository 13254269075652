import {
  IClaim,
  IClaimsParams,
  IUpdateClaimPayload,
  ISubmitClaimPayload,
  IClaimResponse,
} from 'typings/insurance';
import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';

class InsuranceResource {
  resource: any;

  static $inject = ['$resource', 'API', 'UserSession'];
  constructor(
    $resource: ng.resource.IResourceService,
    private API: IApiConfig,
    private UserSession: IUserSession
  ) {
    this.UserSession = UserSession;
    this.resource = $resource(
      `${this.API.endpoint}/companies/:company_id/policies/:policy_id/claims/:controller`,
      {},
      {
        create: {
          method: 'POST',
          params: {
            company_id: this.UserSession.company.id,
          },
        },
        update: {
          method: 'PATCH',
          params: {
            controller: 'update_claim_type',
            company_id: this.UserSession.company.id,
          },
        },
        submit: {
          method: 'PATCH',
          params: {
            controller: 'submit',
            company_id: this.UserSession.company.id,
          },
        },
        respondClaim: {
          method: 'PATCH',
          params: {
            controller: 'update_state',
            company_id: this.UserSession.company.id,
          },
        },
      }
    );
  }

  createClaim(params: IClaimsParams, payload: IUpdateClaimPayload): ng.IPromise<{ claim: IClaim }> {
    return this.resource.create(params, payload).$promise;
  }

  updateClaim(params: IClaimsParams, payload: IUpdateClaimPayload): ng.IPromise<{ claim: IClaim }> {
    return this.resource.update(params, payload).$promise;
  }

  submitClaim(params: IClaimsParams, payload: ISubmitClaimPayload): ng.IPromise<{ claim: IClaim }> {
    return this.resource.submit(params, payload).$promise;
  }

  respondClaim(params: IClaimsParams, payload: IClaimResponse): ng.IPromise<{ claim: IClaim }> {
    return this.resource.respondClaim(params, payload).$promise;
  }
}

export { InsuranceResource };
