import { IAddress, AddressDefaultValueKey, IAddressService } from 'typings/address';

import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import template from './address-default-for.html?raw';
import style from './address-default-for.module.scss';

class AddressDefaultForController implements IComponentController {
  style = style;
  busy = false;
  esAddress?: IAddress;

  static $inject = ['AddressService'];
  constructor(private AddressService: IAddressService) {}

  get selections() {
    return this.esAddress?.default_values;
  }

  setDefaultAs(useCase: AddressDefaultValueKey) {
    if (!this.esAddress || this.esAddress?.default_values[useCase]) return;

    this.busy = true;

    this.AddressService.setAddressAsDefault(this.esAddress, useCase)
      .catch((error) => {
        toastError(error.data && error.data.status);
      })
      .finally(() => {
        this.busy = false;
      });
  }
}

const AddressDefaultForComponent: ng.IComponentOptions = {
  controller: AddressDefaultForController,
  template,
  bindings: { esAddress: '<' },
};

export { AddressDefaultForComponent };
