import { ProductListingModal } from '@client/src/data/product-listing';
import { IProductListingProducts } from 'typings/dashboard/services/product-listing';

import { IComponentController } from 'angular';
import template from './product-listing.html?raw';
import style from './product-listing.module.scss';
import { ProductListingService } from './product-listing.service';

class ProductListingController implements IComponentController {
  style = style;
  loading = true;
  selectedProduct: IProductListingProducts | null = null;
  drawerSelectedIndex = -1;

  static $inject = ['$scope', 'ProductListingService'];
  constructor(private $scope: ng.IScope, private ProductListingService: ProductListingService) {
    this.closeDrawer = this.closeDrawer.bind(this);
    this.handleDrawerClickAway = this.handleDrawerClickAway.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  $onInit(): void {
    this.ProductListingService.getProductList().then(() => {
      this.loading = false;
    });
  }

  $onDestroy(): void {
    this.ProductListingService.resetState();
  }

  get products(): IProductListingProducts[] {
    return this.ProductListingService.products;
  }

  get totalProductCount(): number {
    return this.ProductListingService.totals.total_products_count;
  }

  get activeModal(): ProductListingModal | null {
    return this.ProductListingService.activeModal;
  }

  get isAddProductsModalActive(): boolean {
    return this.activeModal === ProductListingModal.AddProducts;
  }

  get isEditProductsModalActive(): boolean {
    return this.activeModal === ProductListingModal.EditProducts;
  }

  get isEditProductsWithMissingInformationModalActive(): boolean {
    return this.activeModal === ProductListingModal.EditProductsMissingInformation;
  }

  get isDeleteProductModalActive(): boolean {
    return this.activeModal === ProductListingModal.DeleteProduct;
  }

  get isFiltered(): boolean {
    return this.ProductListingService.isFiltered;
  }

  hideModal(): void {
    this.ProductListingService.activeModal = null;
  }

  openDrawer(productIndex: number): void {
    this.drawerSelectedIndex = productIndex;

    // set with the summary from product#list
    this.selectedProduct = this.ProductListingService.products[this.drawerSelectedIndex];

    // replace with the summary from product#show
    this.ProductListingService.getProduct(this.selectedProduct.id).then((product) => {
      this.selectedProduct = product;
    });
  }

  handleDrawerClickAway(e: Event): void {
    const targetElement = e.target as HTMLElement;
    const isTargetOutsideTable = !targetElement.closest('easyship-table-row:not(:first-child)');

    if (isTargetOutsideTable) this.closeDrawer();
  }

  closeDrawer(): void {
    this.selectedProduct = null;
    this.drawerSelectedIndex = -1;
    this.$scope.$apply();
  }

  handleNavigation(change: number): void {
    if (
      this.drawerSelectedIndex + change < 0 ||
      this.drawerSelectedIndex + change >= this.ProductListingService.products.length
    )
      return;

    this.drawerSelectedIndex += change;
    this.selectedProduct =
      this.ProductListingService.products[Math.max(0, this.drawerSelectedIndex)];
    this.$scope.$apply();
  }
}

const ProductListingComponent = {
  controller: ProductListingController,
  template,
};

export { ProductListingComponent };
