(function () {
  'use strict';

  TradegeckoService.$inject = [
    '$q',
    '$window',
    'PlatformApi',
    'UserSession',
    'StoreService',
    'Auth',
  ];
  function TradegeckoService($q, $window, PlatformApi, UserSession, StoreService, Auth) {
    var service = this;

    service.getTradegeckoUrl = function (store) {
      return PlatformApi.tradegecko
        .get({ company_id: UserSession.company.id })
        .$promise.then(function (response) {
          StoreService.saveStoreValueToLocal(store);
          return response.url;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.saveTradegeckoToken = function (tradegeckoGranted) {
      return $q(function (resolve, reject) {
        var store = angular.merge(
          {
            code: tradegeckoGranted.code,
          },
          StoreService.getStorePayloadFromLocalStorage()
        );

        //Ping the session to be sure that UserSession exists
        Auth.retrieveSession().then(function () {
          //Save store
          PlatformApi.tradegecko
            .save({ company_id: UserSession.company.id }, { trade_gecko: store })
            .$promise.then(function (response) {
              StoreService.removeStoreValueFromLocal();
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      });
    };
  }

  angular
    .module('app.service.TradegeckoService', [])
    .service('TradegeckoService', TradegeckoService);
})();
