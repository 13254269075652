import angular from 'angular';

import { ContactSupportModalComponent } from './contact-support-modal.component';

const MODULE_NAME = 'app.pickups.contact-support-modal';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', 'app.global.helper'])
  .component('esContactSupportModal', ContactSupportModalComponent);

export default MODULE_NAME;
