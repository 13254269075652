import template from './card.html?raw';
import style from './card.module.scss';

class Card {
  static $inject = ['$transclude'];

  constructor($transclude) {
    this.style = style;
    this.$transclude = $transclude;
  }

  $onInit() {
    this.isTitleFilled = this.$transclude.isSlotFilled('title');
  }
}

const CardComponent = {
  controller: Card,
  template,
  transclude: {
    title: '?cardTitle',
  },
  bindings: {
    padding: '<',
  },
};

export { CardComponent };
