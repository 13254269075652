import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './sort-by.html?raw';
import style from './sort-by.module.scss';

class SortBy implements IComponentController {
  style = style;
  isOpen = false;
  esSortBy: string | null = null;
  esPageName = '';

  static $inject = ['MixpanelService'];
  constructor(private MixpanelService: MixpanelService) {}

  // eslint-disable-next-line
  esOnApply(data: { key: string }): void {
    // esOnApply expression bindings, need to add this in order for typescript to successfully compile
  }

  onApplySorting(sortKey: string): void {
    const isManageShipmentsPage = this.esPageName === 'app.shipments';
    const isCreateShipmentsAdvancedPage = this.esPageName === 'app.multiple';

    if (isManageShipmentsPage) {
      this.MixpanelService.track(`Manage Shipments - Sort - ${sortKey}`);
    } else if (isCreateShipmentsAdvancedPage) {
      this.MixpanelService.track(`Create Shipments - Sort - ${sortKey}`);
    }

    this.isOpen = false;
    this.esOnApply({
      key: sortKey,
    });
  }

  onToggle(isOpen: boolean): void {
    this.isOpen = isOpen;
  }

  get buttonType(): string | null {
    return this.esSortBy ? 'teal' : null;
  }
}

const SortByComponent: ng.IComponentOptions = {
  controller: SortBy,
  template,
  bindings: {
    esSortBy: '<',
    esSortDirection: '<',
    esOptions: '<',
    esOnApply: '&',
    esPageName: '<',
  },
};

export { SortByComponent };
