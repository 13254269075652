import { COUNTRY_ALPHA2 } from '@client/data/country';

const UPS_DAP_ADDITIONAL_SERVICES_COUNTRIES: string[] = [
  COUNTRY_ALPHA2.UnitedKingdom,
  COUNTRY_ALPHA2.UnitedStates,
  COUNTRY_ALPHA2.Canada,
];

export function isUpsDapAllowedCountry(countryAlpha2: string) {
  return UPS_DAP_ADDITIONAL_SERVICES_COUNTRIES.includes(countryAlpha2);
}
