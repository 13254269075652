import { toastError } from '@client/core/components/react/Toastify';

(() => {
  angular.module('easyshipDashboardApp').controller('SsoCtrl', SsoCtrl);

  SsoCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    '$window',
    'Auth',
    'AUTH_EVENTS',
    'Sso',
    'ApiKeys',
    'vcRecaptchaService',
    'UserSession',
    'UserService',
  ];

  function SsoCtrl(
    $scope,
    $state,
    $stateParams,
    $translate,
    $window,
    Auth,
    AUTH_EVENTS,
    Sso,
    ApiKeys,
    vcRecaptchaService,
    UserSession,
    UserService
  ) {
    const vm = this;
    vm.ApiKeys = ApiKeys;

    vm.user = {};
    vm.busy = {
      page: true,
      login: false,
    };

    vm.emailResent = {
      success: false,
      error: false,
    };

    // Check if user is logged in, redirect user back to client
    // Otherwise, load sign-in form
    (function init() {
      const sessionToken = Auth.getCredentials();

      if (sessionToken) {
        // Must ping the server to set headers
        Auth.retrieveSession();
      } else {
        vm.busy.page = false;
      }
    })();

    vm.onInputChange = (value, key) => {
      vm.user[key] = value;
    };

    /**
     * [login] Triggers to login function allowing user to enter the dashboard
     * @return {N/A}
     */
    vm.login = () => {
      vm.busy.login = true;

      if (vm.loginForm.$error.required) {
        vm.busy.login = false;
        toastError($translate.instant('toast.incomplete-form'));
        return;
      }

      if (vm.loginForm.$error.recaptcha) {
        vm.busy.login = false;
        vcRecaptchaService.execute();
      }
    };
    // Set the recaptcha widget id once loaded, this can be use to reload the widget
    vm.setRecaptchaWidgetId = (widgetId) => {
      vm.widgetId = widgetId;
    };

    vm.authenticate = (recaptchaResponse) => {
      vm.busy.login = true;

      Auth.login(vm.user, null, recaptchaResponse).catch(function () {
        toastError($translate.instant('toast.default-error'));
        vm.busy.login = false;
      });
    };

    function redirectToSSOWithJWT(ssoUrl, jwtToken, returnToUrl) {
      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', ssoUrl);

      const tokenInput = document.createElement('input');
      tokenInput.setAttribute('type', 'hidden');
      tokenInput.setAttribute('name', 'jwt');
      tokenInput.setAttribute('value', jwtToken);

      const returnToInput = document.createElement('input');
      returnToInput.setAttribute('type', 'hidden');
      returnToInput.setAttribute('name', 'return_to');
      returnToInput.setAttribute('value', returnToUrl);

      form.appendChild(tokenInput);
      form.appendChild(returnToInput);
      document.body.appendChild(form);
      form.submit();
    }

    // retrieves url from client and then redirect
    function successLoginCallback() {
      Sso.fetchWithSessionToken({
        client: $stateParams.client,
        return_to: $stateParams.return_to,
      })
        .$promise.then((response) => {
          if ($stateParams.client === 'zendesk') {
            const url = new URL(response.sso_url);
            const ssoUrl = `${url.origin}${url.pathname}`;
            const jwtToken = url.searchParams.get('jwt');
            const returnToUrl = url.searchParams.get('return_to');
            redirectToSSOWithJWT(ssoUrl, jwtToken, returnToUrl);
            return;
          }

          // eslint-disable-next-line no-param-reassign
          $window.location = response.sso_url;
        })
        .catch((err) => {
          if (err.status === 401) {
            // if 401 -> show if email confirmed is false
            vm.confirmationFailed = true;
          } else {
            vm.ssoFail = true;
          }
        })
        .finally(() => {
          vm.busy.page = false;
        });
    }

    vm.resendConfirmationLink = () => {
      vm.busy.resend = true;

      UserService.sendConfirm(UserSession.getUserId())
        .then(() => {
          vm.emailResent.success = true;
        })
        .catch(() => {
          vm.emailResent.error = true;
        })
        .finally(() => {
          vm.busy.resend = false;
        });
    };

    function loginFailFallback(err) {
      vm.busy.login = false;
      vm.loginFail = true;
      vm.errors = err.response.data; // i18n TBD
      vcRecaptchaService.reload(vm.widgetId);
    }

    // /**
    //  * [login] Triggers to login function allowing user to enter the dashboard
    //  * @return {N/A}
    //  */
    // vm.login = function logUserIn() {
    //   vm.busy.login = true;
    //   Auth.login(vm.user);
    // };

    /**
     * [goToResetPage] Redirects user to reset password page and pass email if applicable
     */
    vm.goToResetPage = function directToResetPageAndPassEmail() {
      $state.go('home.auth.password-reset', { userEmail: vm.user.email });
    };

    // Intercept the failed event and shows the errors.
    // @param {Object} e
    // @param {Object} response
    $scope.$on(AUTH_EVENTS.loginFailed, (_e, err) => {
      loginFailFallback(err);
    });

    // Redirect back to client after login
    $scope.$on(AUTH_EVENTS.loginSuccess, () => {
      successLoginCallback();
    });

    $scope.$on(AUTH_EVENTS.fullLoginSuccess, () => {
      successLoginCallback();
    });
  }
})();
