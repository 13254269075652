import React, { Suspense, lazy } from 'react';
import ReactRootProviders from '@app/contexts/providers/ReactRootProviders';
import { UserSessionProvider } from '@app/contexts/providers/UserSessionProvider';
import { react2angular } from 'react2angular';
import { CompleteModalContentProps } from '@app/components/CompleteModalContent/types';

const CompleteModalContent = lazy(() => import('@app/components/CompleteModalContent'));

export function WrappedCompleteModalContent(props: CompleteModalContentProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={null}>
          <CompleteModalContent {...props} />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularCompleteModalContent = react2angular(WrappedCompleteModalContent, [
  'title',
  'image',
  'description',
  'onClose',
]);
