import { IUserSession } from 'typings/user-session';

import {
  IShipmentBulkCancelPayload,
  IShipmentBulkCancelTotalsSuccessResponse,
  IShipmentBulkCancelConfirmSuccessResponse,
  IShipmentCancelSuccessResponse,
} from 'typings/shipment';
import { ShipmentListResource } from '../shipment-list/shipment-list.resource';

class BulkCancelService {
  static $inject = ['UserSession', 'ShipmentListResource'];
  constructor(
    private UserSession: IUserSession,
    private ShipmentListResource: ShipmentListResource
  ) {}

  private get baseParams() {
    return {
      company_type: this.UserSession.getCompanyType(),
      company_id: this.UserSession.getCompanyId(),
    };
  }

  cancelShipment(shipmentId: string): ng.IPromise<IShipmentCancelSuccessResponse> {
    const params = {
      ...this.baseParams,
      id: shipmentId,
    };

    return this.ShipmentListResource.cancel(params);
  }

  getTotals(
    payload: IShipmentBulkCancelPayload
  ): ng.IPromise<IShipmentBulkCancelTotalsSuccessResponse> {
    return this.ShipmentListResource.bulkCancelTotals(this.baseParams, payload);
  }

  cancelShipments(
    payload: IShipmentBulkCancelPayload
  ): ng.IPromise<IShipmentBulkCancelConfirmSuccessResponse> {
    payload = {
      ...payload,
      by_search: true,
    };

    return this.ShipmentListResource.bulkCancelConfirm(this.baseParams, payload);
  }
}

export { BulkCancelService };
