import angular from 'angular';

import { TagsService } from './tags.service';
import { TagsResource } from './tags.resource';

const MODULE_NAME = 'app.global.services.tags';

angular
  .module(MODULE_NAME, [])
  .service('TagsService', TagsService)
  .service('TagsResource', TagsResource);

export default MODULE_NAME;
