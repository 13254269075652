import { IInsuredState, IClaimFeedback } from 'typings/insurance';

export enum InsuredStateKey {
  Insured = 'insured',
  NotInsured = 'not-insured',
  InsuranceFallbackNotInsured = 'insurance-fallback-not-insured',
  InsuranceFallbackCourierLiability = 'insurance-fallback-courier-liability',
  CourierLiability = 'courier-liability',
  LyocNotInsured = 'lyoc-not-insured',
}

export enum ClaimStateKey {
  NotShipped = 'not_shipped',
  NotFiled = 'not_filed',
  FiledByClient = 'filed_by_client',
  FiledToCourier = 'filed_to_courier',
  Rejected = 'rejected',
  Accepted = 'accepted',
}

export enum ClaimTypeKey {
  DamagedOrMissing = 'damaged_or_missing',
  Lost = 'lost',
}

export enum ClaimStatus {
  Submitted = 'submitted',
  Success = 'Success',
  Approved = 'Approved',
  ClaimReceived = 'Claim Received',
  Closed = 'Closed',
  ClosedDenied = 'Closed - Denied',
  ClosedPaid = 'Closed - Paid',
  Completed = 'Completed',
  Denied = 'Denied',
  Paid = 'Paid',
  PaymentInfoRequested = 'Payment Info Request',
  PendingDenial = 'Pending Denial',
  UnderReview = 'Under Review',
  WaitingOnDocuments = 'Waiting On Documents',
}

export const InsuredStates: IInsuredState[] = [
  {
    key: InsuredStateKey.NotInsured,
    insured: false,
    badgeText: 'Not Insured',
    badgeColor: 'danger',
    content:
      'No insurance was purchased for this shipment, and this courier does not provide any coverage in the event of lost or damaged parcels.',
    helpPath: '/hc/en-us/articles/360037031852',
    showFullInsuredAmount: false,
  },
  {
    key: InsuredStateKey.InsuranceFallbackNotInsured,
    insured: false,
    badgeText: 'Not Insured',
    badgeColor: 'danger',
    content:
      'You requested premium insurance for this shipment, but we are unable to insure shipments this country and/or with the product categories selected. No insurance fee was charged. Also, this courier does not provide any coverage in the event of lost or damaged parcels.',
    helpPath: '/hc/en-us/articles/360037031852',
    showFullInsuredAmount: false,
  },
  {
    key: InsuredStateKey.Insured,
    insured: true,
    badgeText: 'Premium Insurance',
    badgeColor: 'valid',
    content:
      'You purchased insurance for this shipment, which covers you in case of lost or damaged parcels. The claim has to be filed within 90 days after the shipping date.',
    coverage: 'Limited to USD 5,000',
    helpPath: '/hc/en-us/articles/360012274532-Insurance-Claim',
    claimNote:
      'Already made a claim? Please check your claim status using the link provided on the email sent by InsureShip.',
    showFullInsuredAmount: true,
  },
  {
    key: InsuredStateKey.CourierLiability,
    insured: true,
    badgeText: 'Basic Courier Insurance',
    badgeColor: 'warning',
    content:
      'No insurance was purchased for this shipment, but this courier is offering a limited coverage in the event of lost or damaged parcels.',
    helpPath: '/hc/en-us/articles/360036401891',
    claimNote:
      'If you have already filed a claim, we will update the claim status as soon as we hear back from the courier.',
    showFullInsuredAmount: false,
  },
  {
    key: InsuredStateKey.InsuranceFallbackCourierLiability,
    insured: true,
    badgeText: 'Basic Courier Insurance',
    badgeColor: 'warning',
    content:
      'You requested premium insurance for this shipment, but we are unable to insure shipments this country and/or with the product categories selected. No insurance fee was charged. This courier, however, is offering a limited coverage in the event of lost or damaged parcels.',
    helpPath: '/hc/en-us/articles/360036401891',
    claimNote:
      'If you have already filed a claim, we will update the claim status as soon as we hear back from the courier.',
    showFullInsuredAmount: false,
  },
  {
    key: InsuredStateKey.LyocNotInsured,
    insured: false,
    badgeText: 'Your Own Courier Account',
    badgeColor: 'info',
    content:
      "No insurance was purchased for this shipment, and you shipped using our own courier account. Please contact the courier's customer service team for any inquiries in the event of lost or damaged parcels.",
    showFullInsuredAmount: false,
  },
];

export const ClaimFeedbacks: IClaimFeedback[] = [
  {
    state: ClaimStateKey.FiledToCourier,
    type: ClaimTypeKey.DamagedOrMissing,
    displayName: 'Claim Filed - Damaged',
    tooltipContent:
      'We have submitted the reimbursement claim to the courier. We will update the status as soon as we hear back from the courier which is usually within 45 days. The claim approval rate for damaged parcels from a courier is usually quite low, we recommend purchasing shipping insurance for future shipments. Our premium insurance is fast and transparent.',
  },
  {
    state: ClaimStateKey.FiledToCourier,
    type: ClaimTypeKey.Lost,
    displayName: 'Claim Filed - Lost',
    tooltipContent:
      'We have submitted the reimbursement claim to the courier. We will update the status as soon as we hear back from the courier which is usually within 45 days. The claim approval rate for lost shipments from a courier is usually quite low. We recommend purchasing shipping insurance for future shipments. Our premium insurance is fast and transparent.',
  },
  {
    state: ClaimStateKey.Accepted,
    type: ClaimTypeKey.DamagedOrMissing,
    displayName: 'Claim Accepted and Refunded',
    tooltipContent:
      'Your claim has been accepted by the courier. You have already been refunded on your Easyship account.',
  },
  {
    state: ClaimStateKey.Accepted,
    type: ClaimTypeKey.Lost,
    displayName: 'Claim Accepted and Refunded',
    tooltipContent:
      'Your claim has been accepted by the courier. You have already been refunded on your Easyship account.',
  },
  {
    state: ClaimStateKey.Rejected,
    type: ClaimTypeKey.DamagedOrMissing,
    displayName: 'Claim Rejected',
    tooltipContent:
      'The courier has rejected your reimbursement claim for loss / damage and will not accept any liability at this time.',
  },
  {
    state: ClaimStateKey.Rejected,
    type: ClaimTypeKey.Lost,
    displayName: 'Claim Rejected',
    tooltipContent:
      'The courier has rejected your reimbursement claim for loss / damage and will not accept any liability at this time.',
  },
];

export const NotInsured = InsuredStates[0];
export const NotFiled = { state: ClaimStateKey.NotFiled };

export enum ClaimStep {
  Select = 'select',
  Lost = 'lost',
  Damaged = 'damaged_or_missing',
  Success = 'success',
}
