import { IComponentController } from 'angular';
import template from './notification-centre-row.html?raw';
import style from './notification-centre-row.module.scss';

enum MessageType {
  Info = 'info',
  Warning = 'warning',
}

class NotificationCentreRow implements IComponentController {
  style = style;
  esType = MessageType.Info;
  isExpand = false;

  static $inject = ['$transclude'];
  constructor(private $transclude: ng.ITranscludeFunction) {}

  esOnCtaClick(): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  esOnDelete(): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  esOnExpand(): void {
    // esOnChange expression bindings, need to add this in order for typescript to successfully compile
  }

  toggleExpand(): void {
    this.isExpand = !this.isExpand;
    if (this.isExpand) {
      this.esOnExpand();
    }
  }

  onCtaClick(e: Event): void {
    e.stopPropagation();
    this.esOnCtaClick();
  }

  onDelete(e: Event): void {
    e.stopPropagation();
    this.esOnDelete();
  }

  onMessageLongClick(e: Event): void {
    e.stopPropagation();
  }

  get isWarning(): boolean {
    return this.esType === MessageType.Warning;
  }

  get hasMessageLong(): boolean {
    return this.$transclude.isSlotFilled('messageLong');
  }
}

const NotificationCentreRowComponent: ng.IComponentOptions = {
  controller: NotificationCentreRow,
  template,
  transclude: {
    messageLong: '?messageLong',
  },
  bindings: {
    esMessage: '<',
    esCta: '<',
    esOnCtaClick: '&',
    esCreatedAt: '<',
    esReadAt: '<',
    esType: '<',
    esOnDelete: '&',
    esOnExpand: '&',
  },
};

export { NotificationCentreRowComponent };
