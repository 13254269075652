import React from 'react';
import { FormattedMessage } from 'react-intl';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ItemCategory } from '@client/core/corelogic/models/ItemCategory';
import {
  formatHsCodeNumber,
  sortSuggestedHsCodes,
  SuggestedHsCodes,
} from '@client/core/corelogic/models/HsCode';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import Link from '@client/core/components/react/Link';

import { CategoryHsCodeSelection } from './types';

interface CategoryListProps {
  value: CategoryHsCodeSelection;
  categories: ItemCategory[];
  suggestedHsCodes: SuggestedHsCodes;
  onCategorySelect: (categoryId: number, category: ItemCategory) => void;
  onHsCodeSelect: (hsCodeNumber: string, hsCodeDescription: string) => void;
  showSearchHsCode: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  searchHsCode: {
    cursor: 'pointer',
    padding: 12,
    '&:focus': {
      backgroundColor: theme.palette.sky.light,
      // @ts-ignore borderRadius returning undefined error
      borderRadius: theme.overrides?.MuiPaper?.rounded?.borderRadius,
      outline: 'none',
    },
  },
  backToCategory: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function CategoryList({
  value,
  categories,
  suggestedHsCodes,
  onCategorySelect,
  onHsCodeSelect,
  showSearchHsCode,
}: CategoryListProps) {
  const classes = useStyles();
  const sortedSuggestedHsCodes = sortSuggestedHsCodes(
    suggestedHsCodes,
    value && 'hsCodeNumber' in value ? value.hsCodeNumber : null
  );
  const hasSelectedItem =
    value &&
    !!(
      ('categoryId' in value && value.categoryId) ||
      ('hsCodeNumber' in value && value.hsCodeNumber)
    );

  const switchToHsCodes = () => {
    showSearchHsCode(true);
  };

  const handleSearchHsCodeKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      switchToHsCodes();
    }
  };

  return (
    <>
      <MenuList autoFocusItem variant="selectedMenu" role="listbox">
        {sortedSuggestedHsCodes.map(([number, description]) => (
          <MenuItem
            key={number}
            disableGutters
            selected={!!value && 'hsCodeNumber' in value && number === value.hsCodeNumber}
            onClick={() => onHsCodeSelect(number, description)}
          >
            <Typography variant="inherit" noWrap>
              {`${formatHsCodeNumber(number)} - ${description}`}
            </Typography>
          </MenuItem>
        ))}

        {categories.map((category, i) => (
          <MenuItem
            key={i}
            disableGutters
            selected={!!value && 'categoryId' in value && category.id === value.categoryId}
            onClick={() => onCategorySelect(category.id, category)}
          >
            {category.fallbackName}
          </MenuItem>
        ))}
      </MenuList>

      <Divider />

      <Typography
        variant="body2"
        classes={{ root: classes.searchHsCode }}
        tabIndex={0}
        onClick={switchToHsCodes}
        onKeyDown={handleSearchHsCodeKeyDown}
      >
        <FormattedMessage
          id="shipments.category-hs-codes.cannot-find-right-one"
          values={{
            a: (chunks: string) => <Link>{chunks}</Link>,
          }}
        />
      </Typography>
    </>
  );
}
