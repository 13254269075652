import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { HelpContactSupportFeature } from '../types';
import { Country } from './constants';

interface ICallDescriptionProps {
  countries: Country[];
  feature: HelpContactSupportFeature;
}
export default function CallDescription({ countries, feature }: ICallDescriptionProps) {
  const { formatList } = useIntl();
  const countryNames = countries.map((country) => {
    return <b key={country.alpha2}>{country.name}</b>;
  });

  return (
    <FormattedMessage
      id={`help-guide.contact-support.${feature}.description`}
      values={{
        countries: formatList(countryNames, { type: 'conjunction' }),
      }}
    />
  );
}
