/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from '@client/core/components/react/Link';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { IconButton } from 'easyship-components';
import { CrossLarge } from 'easyship-components/icons';
import React, { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import splitShipmentOption1 from '@assets/images/dashboard/shipping-rules/split-shipment-1.svg';
import splitShipmentOption2 from '@assets/images/dashboard/shipping-rules/split-shipment-2.svg';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '520px',
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function SplitShipmentExplanationDialog() {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleModal(): void {
    setIsOpen((prevState) => {
      return !prevState;
    });
  }

  return (
    <>
      <Link className="text-[13px]" onClick={handleToggleModal}>
        See Example
      </Link>
      <Dialog
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 400 }}
        fullWidth
        open={isOpen}
        className={classes.dialog}
      >
        <div className="bg-white rounded-md">
          <div className="flex items-center justify-between w-full py-5 pr-5 border-b border-sky-300">
            <p className="mb-0 ml-12 text-xl leading-7">
              {formatMessage({ id: 'automations.split-order.modal.title' })}
            </p>
            <IconButton
              flat
              tooltipProps={{
                label: 'Close',
                usePortal: true,
                autoPlacement: ['top'],
              }}
              className=" p-0 top-[4%] right-[4%]"
              onClick={handleToggleModal}
              aria-label="Close"
            >
              <CrossLarge />
            </IconButton>
          </div>
          <div className="px-12 py-8 text-[13px]">
            <p className="mb-2">
              {formatMessage({ id: 'automations.split-order.modal.condition' })}
              <span className="px-[6px] py-[2px] mx-1 text-white uppercase bg-blue-500 rounded-sm">
                A
              </span>
              {formatMessage({ id: 'global.or' })}
              <span className="px-[6px] py-[2px] mx-1 text-white uppercase bg-red-500 rounded-sm">
                B
              </span>
            </p>
            <p className="mb-12">{formatMessage({ id: 'automations.split-order.modal.action' })}</p>
            <p className="mb-12">
              {formatMessage({ id: 'automations.split-order.modal.example' })}
              <span className="px-[6px] py-[2px] mx-1 text-white uppercase bg-blue-500 rounded-sm">
                A
              </span>
              <span className="px-[6px] py-[2px] mx-1 text-white uppercase bg-blue-500 rounded-sm">
                A
              </span>
              <span className="px-[6px] py-[2px] mx-1 text-white uppercase bg-red-500 rounded-sm">
                B
              </span>
              <span className="px-[6px] py-[2px] mx-1 text-white uppercase bg-green-500 rounded-sm">
                C
              </span>
              <span className="px-[6px] py-[2px] mx-1 text-white uppercase rounded-sm bg-ink-300">
                D
              </span>
            </p>
            <p className="mb-5">
              {formatMessage({ id: 'automations.split-order.modal.applying' })}
            </p>
            <p className="mb-3">
              {formatMessage({ id: 'automations.split-order.modal.option-1' })}
            </p>
            <img className="mb-5" src={splitShipmentOption1} alt="split-shipment-1" />
            <p className="mb-3">
              {formatMessage({ id: 'automations.split-order.modal.option-2' })}
            </p>
            <img src={splitShipmentOption2} alt="split-shipment-2" />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export function WrapperSplitShipmentExplanationModal(): ReactElement {
  return (
    <ReactRootProviders>
      <SplitShipmentExplanationDialog />
    </ReactRootProviders>
  );
}

export const AngularSplitShipmentExplanationModal = react2angular(
  WrapperSplitShipmentExplanationModal
);
