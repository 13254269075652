import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@client/core/components/react/Chip';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { Link, makeStyles } from '@material-ui/core';

import {
  SubscriptionServiceProvider,
  useSubscriptionService,
} from '@client/src/global/context/SubscriptionProvider';
import { FEATURE_KEY } from '@client/data/subscription';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import LearnMore from '@client/src/global/dashboard-component-library/learn-more/index';

interface RateExplainerDialogProps extends DialogProps {
  onClose: () => void;
  onLinkClick: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  dialogContent: {
    padding: '40px 80px',
  },
}));

export default function RateExplainerDialog({
  onLinkClick,
  onClose,
  ...props
}: RateExplainerDialogProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { getPlanNameByFeatureKey, isPlanBadgeVisible } = useSubscriptionService();

  const planLabel = useMemo(() => {
    return getPlanNameByFeatureKey(FEATURE_KEY.RatesAtCheckout);
  }, []);

  return (
    <Dialog {...props} className={classes.root} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <span className="text-xl">
                <FormattedMessage id="store.checkout.heading" />
              </span>
            </Grid>
            {isPlanBadgeVisible(FEATURE_KEY.RatesAtCheckout) && (
              <Chip label={planLabel} color="teal" variant="rounded" size="small" />
            )}
          </Grid>
          <Grid item>
            <IconButton aria-label="close modal" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column" spacing={4}>
          <Grid container item justifyContent="space-between">
            <Grid item xs={6}>
              <p className="mb-4 leading-6">
                <FormattedMessage id="store.rate-explain-dialog.content" />
              </p>
              <Grid container spacing={1}>
                <Grid item>
                  <p className="leading-6 font-bold">
                    <FormattedMessage id="store.rate-explain-dialog.related-feature" />:
                  </p>
                </Grid>
                <Grid item>
                  <Link onClick={onLinkClick} className="leading-6">
                    <FormattedMessage id="store.tax-duty-dialog.header" />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <p className="mb-4 leading-6 font-bold">
                <FormattedMessage id="store.rate-explain-dialog.cross-platform-management-title" />
              </p>
              <p className="leading-6">
                <FormattedMessage id="store.rate-explain-dialog.cross-platform-management-content" />
              </p>
            </Grid>
          </Grid>
          <Grid container item justifyContent="center">
            <img
              alt="rate checkout"
              src="/images/dashboard/single-store/insurance-checkout/rate-checkout.png"
              width="100%"
            />
          </Grid>
          <Grid item>
            <LearnMore
              esSectionName={formatMessage({
                id: 'subscription.compare.feature.rates_at_checkout',
              })}
              esSupportLink="/hc/en-us/articles/115003453151-Checkout"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export function WrappedRateExplainerDialog(props: RateExplainerDialogProps) {
  return (
    <ReactRootProviders>
      <SubscriptionServiceProvider>
        <RateExplainerDialog {...props} />
      </SubscriptionServiceProvider>
    </ReactRootProviders>
  );
}
