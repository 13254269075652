import { IItemCategoryService, IItemCategory } from 'typings/item-category';
import { IRateItem } from 'typings/rates';
import { IShipmentListItem } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';
import { IFlatRateBoxService } from 'typings/boxes';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';

import { isEmpty } from 'lodash';
import { ICountryService } from 'typings/auth/services/country';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import template from './shipment-list-row-table.html?raw';
import style from './shipment-list-row-table.module.scss';

class ShipmentListRowTable implements IComponentController {
  style = style;
  esShipment: IShipmentListItem | null = null;
  esIndex: number | null = null;

  static $inject = [
    '$state',
    '$timeout',
    '$q',
    '$document',
    'UserSession',
    'ItemCategoryService',
    'FlatRateBoxService',
    'ShipmentListAdvancedService',
    'MixpanelService',
    'CountryService',
    'FlatRatesModal',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $q: ng.IQService,
    private $document: ng.IDocumentService,
    private UserSession: IUserSession,
    private ItemCategoryService: IItemCategoryService,
    private FlatRateBoxService: IFlatRateBoxService,
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private MixpanelService: MixpanelService,
    private CountryService: ICountryService,
    private FlatRatesModal: any
  ) {}

  $onInit(): void {
    if (
      this.esShipment &&
      this.ShipmentListAdvancedService.updatedShipments &&
      this.ShipmentListAdvancedService.updatedShipments[this.esShipment.id]
    ) {
      this.ShipmentListAdvancedService.updatedShipments[this.esShipment.id].shown = true;
    }
  }

  $onDestroy(): void {
    this.clearUpdatedShipmentList();
  }

  offClickCallback(): void {
    const enableOffClick =
      this.$document.find(`.modal, .tippy-box, .MuiTooltip-popper, #categoryHsCodePopper`)
        .length === 0;

    enableOffClick && this.closeAllShipmentField();
  }

  refreshList(): void {
    this.ShipmentListAdvancedService.fetchShipmentEntitiesWithFilter('orders_all');
  }

  closeEditCourierModal(isClearData = true): void {
    this.closeAllShipmentField(isClearData);
    this.ShipmentListAdvancedService.clearIsCourierModalOpen();
  }

  onCourierSelect(rate: IRateItem): void {
    const flatRateBoxIds =
      this.selectedShipment?.parcels_attributes?.reduce<string[]>(
        (agg, parcel) =>
          parcel.packageable_type === 'FlatRateBox' && parcel.packageable_id
            ? [...agg, parcel.packageable_id]
            : agg,
        []
      ) || [];
    const flatRateBoxes = flatRateBoxIds.map((id) => this.FlatRateBoxService.getBox(id));
    const showFlatRatesModal =
      flatRateBoxes.some(function (flatRateBox) {
        return flatRateBox?.logo_url !== rate.courier_logo;
      }) && !rate.courier_service_code.includes('USPS_Lightweight');

    if (showFlatRatesModal) {
      this.ShipmentListAdvancedService.closeAllField(this.esIndex, false);

      this.$timeout(() => {
        if (this.selectedShipment && this.selectedShipment.courier) {
          this.FlatRatesModal.open({
            changeCourier: () => {
              return this.overwriteCourier(rate);
            },
            openShipmentRatesModal: () => {
              if (this.esIndex || this.esIndex === 0) {
                this.ShipmentListAdvancedService.toggleCourierModal(this.esIndex);
              }
            },
          });
        }
      }, 300);
    } else {
      this.overwriteCourier(rate);
    }
  }

  get showMissingDimensionsWarning(): boolean {
    /* The warning border always shows if 'display_missing_dimensions_warning' is true,
       but inside the form, we need to toggle it on/off while the user enters dimensions. */
    const hasParcelWithoutDimensions = !!(
      this.selectedShipment?.parcels_attributes?.some(
        (parcel) =>
          !(parcel.package_data.height && parcel.package_data.length && parcel.package_data.width)
      ) && this.esShipment?.display_missing_dimensions_warning
    );

    return (
      !!this.esShipment?.display_missing_dimensions_warning &&
      (!this.esShipment?.showOrder || hasParcelWithoutDimensions)
    );
  }

  get showMissingOrderTagError(): boolean {
    return (
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_require_order_tag &&
      !!this.esShipment?.display_missing_tags_warning
    );
  }

  get isResidentialAddressMismatch(): boolean {
    if (typeof this.esShipment?.identified_as_residential !== 'boolean') {
      return false;
    }
    return this.esShipment?.set_as_residential !== this.esShipment?.identified_as_residential;
  }

  get isMissingReceiverEmail(): boolean {
    const isDomesticShipment =
      this.esShipment?.origin_country_id === this.esShipment?.destination_country_id;

    const isReceiverEmailRequired =
      (!isDomesticShipment &&
        this.esShipment?.effective_incoterms === 'DDU' &&
        this.esShipment?.is_above_threshold &&
        this.esShipment?.courier?.supported_incoterms?.includes('DDU')) ??
      false;

    return isReceiverEmailRequired && !this.esShipment?.email_address;
  }

  private overwriteCourier(rate: IRateItem): ng.IPromise<void> {
    if (this.selectedShipment) {
      rate.busy = true;
      return this.ShipmentListAdvancedService.overwriteCourier(
        this.selectedShipment.id,
        rate.courier_id
      )
        .then(({ shipment }) => {
          this.MixpanelService.track('Edit Shipment Courier - Success', {
            easyship_shipment_id: shipment.easyship_shipment_id,
            old_courier: this.selectedShipment?.courier?.name,
            old_price: this.selectedShipment?.shipment_charge_total,
            new_courier: shipment.courier?.name,
            new_price: shipment.shipment_charge_total,
          });

          this.ShipmentListAdvancedService.fetchShipmentTotalWithFilter();

          if (this.esIndex || this.esIndex === 0) {
            this.ShipmentListAdvancedService.modifyShipmentAfterUpdate(this.esIndex, shipment);
          }
          this.closeEditCourierModal();
        })
        .catch(({ data }) => {
          if (data) {
            this.MixpanelService.track('Edit Shipment Courier - Failure', {
              easyship_shipment_id: this.selectedShipment?.easyship_shipment_id,
              error: data.errors,
            });
          }
        })
        .finally(() => {
          rate.busy = false;
        });
    }

    return this.$q((resolve, reject) => {
      reject();
    });
  }

  private closeAllShipmentField(isClearData = true): void {
    this.ShipmentListAdvancedService.closeAllField(this.esIndex, isClearData);
  }

  get highlightUpdatedShipment(): boolean {
    if (
      !this.ShipmentListAdvancedService.updatedShipments ||
      isEmpty(this.ShipmentListAdvancedService.updatedShipments) ||
      !this.esShipment
    ) {
      return false;
    }

    return !!this.ShipmentListAdvancedService.updatedShipments[this.esShipment.id];
  }

  get consigneeName(): string {
    if (!this.esShipment) return '';

    if (
      this.UserSession.company.easyship_company_id === 'CNL360360' &&
      this.esShipment.destination_company_name
    ) {
      return this.esShipment.destination_company_name;
    }
    return this.esShipment.destination_name;
  }

  get isCourierAvailable(): boolean {
    return !!(this.esShipment?.courier?.display_name && this.esShipment.courier_id);
  }

  get showShipmentEstimates(): boolean {
    return !!(
      this.isCourierAvailable &&
      !this.isShipmentCalculating &&
      !this.esShipment?.showReceiver &&
      !this.esShipment?.showOrder
    );
  }

  get isCompanyEfulfilment(): boolean {
    return this.UserSession.isCompanyEfulfilment();
  }

  get isTaxCollectEnabled(): boolean {
    return !!this.UserSession.company.dashboard_settings.show_tax_collect_filter;
  }

  get hasEasyshipServices(): boolean {
    return this.UserSession.hasEasyshipServices;
  }

  get hasCompanyCourierAccounts(): boolean {
    return this.UserSession.hasCompanyCourierAccounts;
  }

  get isShipmentCalculating(): boolean {
    return this.esShipment?.shipment_state === 'calculating';
  }

  get showRateTooltip(): boolean {
    if (!this.esShipment) {
      return false;
    }

    return this.esShipment.total_charge !== null && this.esShipment.total_charge > 0;
  }

  get itemCategories(): IItemCategory[] {
    return this.ItemCategoryService.itemCategories;
  }

  get selectedShipment(): IShipmentListItem | null {
    return this.ShipmentListAdvancedService.selectedShipment;
  }

  get isQuotePage(): boolean {
    return this.$state.current.name === 'app.quote';
  }

  get isMissingSuggestedTaxId(): boolean {
    if (!this.esShipment?.destination_country_id || !this.esShipment.courier?.umbrella_name) {
      return false;
    }
    const receiverCountry = this.CountryService.findCountry(this.esShipment.destination_country_id);
    if (!receiverCountry) return false;

    const isTaxIdSuggested =
      receiverCountry.requirements.consignee_tax_id_suggested_umbrella_names.includes(
        this.esShipment.courier.umbrella_name
      );

    return isTaxIdSuggested && !this.esShipment.consignee_tax_id;
  }

  private clearUpdatedShipmentList(): void {
    if (this.ShipmentListAdvancedService.updatedShipments && this.esShipment) {
      const updatedShipment = this.ShipmentListAdvancedService.updatedShipments[this.esShipment.id];

      if (updatedShipment && updatedShipment.shown) {
        delete this.ShipmentListAdvancedService.updatedShipments[this.esShipment.id];
      }
    }
  }
}

const ShipmentListRowTableComponent: ng.IComponentOptions = {
  controller: ShipmentListRowTable,
  template,
  bindings: {
    esShipment: '<',
    esIndex: '<',
    esIsSelected: '<',
    esOnCheckboxChange: '&',
    esOnOrderCellClick: '&',
    esOnReceiverCellClick: '&',
    esOnCourierCellClick: '&',
  },
};

export { ShipmentListRowTableComponent };
