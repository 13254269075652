import { IComponentController } from 'angular';
import template from './credit-card-element.html?raw';
import style from './credit-card-element.module.scss';

class CreditCardElement implements IComponentController {
  style = style;
}

const CreditCardElementComponent: ng.IComponentOptions = {
  controller: CreditCardElement,
  template,
  bindings: {
    esCssBrand: '<',
    esIsExpire: '<',
    esIsSelected: '<',
    esExpireDate: '<',
    esLastDigits: '<',
  },
};

export { CreditCardElementComponent };
