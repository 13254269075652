Export.$inject = ['$resource', 'API'];
function Export($resource, API) {
  const endpointUrl = API.endpoint;

  return $resource(
    `${endpointUrl}/export/:id`,
    {
      id: '@_id',
    },
    {
      create: {
        method: 'POST',
      },
    }
  );
}

export { Export };
