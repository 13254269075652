/* eslint-disable max-classes-per-file */
import template from './courier-create.html?raw';

class CourierCreateCtrl {
  static $inject = [
    'CourierCreate',
    'MixpanelService',
    'selectedCourier',
    'selectedCountry',
    'showUpgradeModal',
    'courierData',
    'isLyocOnly',
  ];

  constructor(
    CourierCreate,
    MixpanelService,
    selectedCourier,
    selectedCountry,
    showUpgradeModal,
    courierData,
    isLyocOnly
  ) {
    this.CourierCreate = CourierCreate;
    this.MixpanelService = MixpanelService;
    this.selectedCourier = selectedCourier;
    this.selectedCountry = selectedCountry;
    this.showUpgradeModal = showUpgradeModal;
    this.courierData = courierData;
    this.isLyocOnly = isLyocOnly;
  }

  close() {
    // Track closing of the modal only when user manually leaves the modal
    this.MixpanelService.track('Courier - Add Service - Close');
    this.CourierCreate.close();
  }
}

class CourierCreate {
  static $inject = ['$state', '$uibModal', 'MixpanelService'];

  constructor($state, $uibModal, MixpanelService) {
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.MixpanelService = MixpanelService;
  }

  open({ selectedCourier, selectedCountry, showUpgradeModal, courierData, isLyocOnly }) {
    this.MixpanelService.track('Courier - Click Add Service');

    this.modalReference = this.$uibModal.open({
      template,
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      controller: CourierCreateCtrl,
      controllerAs: '$ctrl',
      bindToController: true,
      resolve: {
        selectedCourier() {
          return selectedCourier;
        },
        selectedCountry() {
          return selectedCountry;
        },
        showUpgradeModal() {
          return showUpgradeModal;
        },
        courierData() {
          return courierData;
        },
        isLyocOnly() {
          return isLyocOnly;
        },
      },
    });
  }

  close() {
    if (this.modalReference) {
      this.modalReference.close();
    }

    this.$state.go('app.couriers', {
      courier_id: null,
      show_step: null,
    });
  }
}

export { CourierCreate };
