import template from './box-modal-main-component.html?raw';

class BoxModalMainController {
  static $inject = ['BoxModalService'];

  constructor(BoxModalService) {
    this.BoxModalService = BoxModalService;
  }

  close(box) {
    this.BoxModalService.close(box);
  }

  dismiss() {
    this.BoxModalService.dismiss();
  }

  onDelete() {
    this.step = 'delete';
  }

  onCancel() {
    this.step = 'update';
  }
}

const BoxModalMainComponent = {
  template,
  controller: BoxModalMainController,
  bindings: {
    step: '<',
    box: '<',
  },
};

export { BoxModalMainComponent };
