import { IApiConfig } from 'typings/core/config/api';
import {
  IProductListingProducts,
  IProductListingParams,
  IStoreProductListingParams,
  IProductListingGetPayload,
  IProductListingGetSuccessResponse,
  IProductListingSavePayload,
  IProductListingUpdatePayload,
  IProductListingDeletePayload,
  IProductListingStoreSyncResponse,
  IProductListingStoreSyncPayload,
  IProductListingExportPayload,
  ProductListingSuggestResponse,
  IProductListingSuggestPayload,
} from 'typings/dashboard/services/product-listing';
import { IUserSession } from 'typings/user-session';

const DEFAULT_PARAMS = { v: 'v1' };

class ProductListingResource {
  productsResource: any;
  storeProductsResource: any;

  static $inject = ['$resource', '$http', 'API', 'UserSession'];
  constructor(
    private $resource: ng.resource.IResourceService,
    private $http: ng.IHttpService,
    private API: IApiConfig,
    private UserSession: IUserSession
  ) {
    this.productsResource = this.$resource(
      `${this.API.baseEndpoint}/:v/companies/:company_id/products/:method`,
      DEFAULT_PARAMS,
      {
        update: { method: 'PATCH' },
        delete: { method: 'POST' },
        suggest: { method: 'POST' },
      }
    );

    this.storeProductsResource = this.$resource(
      `${this.API.endpoint}/companies/:company_id/stores/:store_id/products`,
      {},
      {
        update: { method: 'PATCH' },
        delete: { method: 'POST' },
      }
    );
  }

  get(
    params: IProductListingParams,
    payload: IProductListingGetPayload
  ): ng.IPromise<IProductListingGetSuccessResponse> {
    return this.productsResource.save({ ...params, method: 'list' }, payload).$promise;
  }

  show(productId: string): ng.IPromise<IProductListingProducts> {
    return this.productsResource.get({
      company_id: this.UserSession.getCompanyId(),
      method: productId,
    }).$promise;
  }

  save(
    params: IProductListingParams,
    payload: IProductListingSavePayload
  ): ng.IPromise<IProductListingProducts> {
    return this.productsResource.save(params, payload).$promise;
  }

  saveStoreProduct(
    params: IStoreProductListingParams,
    payload: IProductListingSavePayload
  ): ng.IPromise<IProductListingProducts> {
    return this.storeProductsResource.save(params, payload).$promise;
  }

  update(params: IProductListingParams, payload: IProductListingUpdatePayload): ng.IPromise<null> {
    return this.productsResource.update(params, payload).$promise;
  }

  delete(params: IProductListingParams, payload: IProductListingDeletePayload): ng.IPromise<void> {
    return this.productsResource.delete({ ...params, method: 'bulk_destroy' }, payload).$promise;
  }

  syncStore(
    params: IProductListingParams,
    payload: IProductListingStoreSyncPayload
  ): ng.IPromise<IProductListingStoreSyncResponse> {
    return this.productsResource.save({ ...params, method: 'api_upload' }, payload).$promise;
  }

  export(
    params: IProductListingParams,
    payload: IProductListingExportPayload
  ): ng.IPromise<{ data: string }> {
    return this.$http.post(
      `${this.API.endpoint}/companies/${params.company_id}/products/export.csv`,
      payload
    );
  }

  suggest(
    params: IProductListingParams,
    payload: IProductListingSuggestPayload
  ): ng.IPromise<{ products: ProductListingSuggestResponse[] }> {
    return this.productsResource.suggest(
      {
        ...params,
        v: 'v2',
        method: 'product_suggestions',
      },
      payload
    ).$promise;
  }
}

export { ProductListingResource };
