import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import COLORS from '@client/src/colors';
import { API } from '@client/core/config/api.constant';
import { Platform } from '@client/data/platform';
import { useOnboarding } from '../../hooks/OnboardingProvider';

interface PlatformButtonProps {
  displayName: string;
  platformName: Platform;
}

const useStyles = makeStyles((theme) => ({
  platformBottom: {
    maxWidth: 210,
    height: 70,
    margin: 15,
    backgroundColor: '#FFF',
    border: `1px solid ${COLORS.skyDark}`,
    borderRadius: 8,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 163,
      height: 63,
      margin: 5,
    },
    '&:hover': {
      boxShadow: '0px 8px 20px #284E661A',
    },
    '&.selected': {
      border: `1px solid ${COLORS.tealNormal}`,
    },
  },
}));

function PlatformButton({ displayName, platformName }: PlatformButtonProps) {
  const { selectedPlatforms, onPlatformClick } = useOnboarding();

  const selected = selectedPlatforms.includes(platformName);

  const classes = useStyles();
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      item
      xs={6}
      md={4}
      spacing={2}
      alignItems="center"
      wrap="nowrap"
      container
      component="button"
      className={clsx(classes.platformBottom, { selected })}
      onClick={() => onPlatformClick(platformName)}
    >
      <Grid item>
        <img
          src={`${
            API.easyship_storage
          }/platform-logos/color/${platformName.toLowerCase()}-color.svg`}
          alt={platformName}
          width={isScreenSm ? '30' : '48'}
        />
      </Grid>
      <Grid item>
        <Typography align="left">{displayName}</Typography>
      </Grid>
    </Grid>
  );
}

export default PlatformButton;
