import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { ShipmentsTableHeaderCellProps } from './d';

export default function ShipmentsTableHeaderCell({
  className,
  style,
  children,
}: PropsWithChildren<ShipmentsTableHeaderCellProps>) {
  return (
    <div
      className={twMerge(
        'h-full flex flex-grow flex-shrink-0 items-center text-base font-bold min-w-[100px] bg-white',
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}
