import datepickerPopupTemplate from './popup/popup.html?raw';
import datepickerWidgetTemplate from './widget/widget.html?raw';
import datepickerDayViewTemplate from './widget/day-view.html?raw';
import datepickerMonthViewTemplate from './widget/month-view.html?raw';
import datepickerYearViewTemplate from './widget/year-view.html?raw';
import './popup/popup.scss';
import './widget/widget.scss';

DatepickerRun.$inject = ['$templateCache'];
function DatepickerRun($templateCache) {
  $templateCache.put('easyship-datepicker-popup', datepickerPopupTemplate);
  $templateCache.put('easyship-datepicker-widget', datepickerWidgetTemplate);
  $templateCache.put('easyship-datepicker-dayview', datepickerDayViewTemplate);
  $templateCache.put('easyship-datepicker-monthview', datepickerMonthViewTemplate);
  $templateCache.put('easyship-datepicker-yearview', datepickerYearViewTemplate);
}

export { DatepickerRun };
