import angular from 'angular';
import { PrintingOptionsComponent } from './printing-options.component';
import { PrintingOptionsFormComponent } from './form/form.component';
import { PrintingOptionsPreviewComponent } from './preview/preview.component';
import { ThermalInvoiceModalContentComponent } from './thermal-invoice-modal/thermal-invoice-modal-content.component';
import { ThermalInvoiceModal } from './thermal-invoice-modal/thermal-invoice-modal.service';
import labelFooterCustomization from './label-footer-customization';
import commercialInvoice from './commercial-invoice';

angular
  .module('app.tools.printing-options', [
    'app.factories',
    'app.services',
    'ui.router',
    'core.components',
    labelFooterCustomization,
    commercialInvoice,
  ])
  .component('printingOptions', PrintingOptionsComponent)
  .component('printingOptionsForm', PrintingOptionsFormComponent)
  .component('printingOptionsPreview', PrintingOptionsPreviewComponent)
  .component('thermalInvoiceModalContent', ThermalInvoiceModalContentComponent)
  .service('ThermalInvoiceModal', ThermalInvoiceModal);
