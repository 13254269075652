import { ICountryService } from 'typings/auth/services/country';
import { IPlatformService } from 'typings/platform';
import { IPostalCodeService } from 'typings/postal-code';
import {
  IShipmentListItem,
  IShipmentParcelAttributes,
  IShipmentListLogsPriceCalculationItem,
  ShipmentPropertyErrors,
} from 'typings/shipment';
import { IUserSession } from 'typings/user-session';
import { IApiConfig } from 'typings/core/config/api';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';

import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import template from './shipment-list-order-form.html?raw';
import style from './shipment-list-order-form.module.scss';

interface IShipmentValidationErrors extends ShipmentPropertyErrors {
  tags?: boolean;
}

class ShipmentListOrderForm implements IComponentController {
  style = style;
  orderForm: ng.IFormController | null = null;
  isProtectedOrder = false;
  busy = false;
  esIndex: number | null = null;
  shipmentValidationErrors: IShipmentValidationErrors = {};

  static $inject = [
    'UserSession',
    'PostalCodeService',
    'ShipmentListAdvancedService',
    'PlatformService',
    'CountryService',
    'API',
  ];
  constructor(
    private UserSession: IUserSession,
    private PostalCodeService: IPostalCodeService,
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private PlatformService: IPlatformService,
    private CountryService: ICountryService,
    private API: IApiConfig
  ) {}

  $onInit(): void {
    if (this.selectedShipment) {
      this.shipmentValidationErrors =
        (this.selectedShipment.logs.price_calculation as IShipmentListLogsPriceCalculationItem)
          .shipment || {};
    }

    this.CountryService.getUSStates();

    const amazonPlatformIds = [200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212];
    this.isProtectedOrder =
      !!this.selectedShipment?.store.platform_id &&
      amazonPlatformIds.includes(this.selectedShipment?.store.platform_id);
  }

  updateShipment(): void {
    if (!this.selectedShipment) return;

    if (
      this.selectedShipment?.additional_services.includes('australia_post_extra_cover') &&
      Number(this.selectedShipment?.order_data?.australia_post_extra_cover) > 500 &&
      !this.selectedShipment?.additional_services.includes('australia_post_signature_on_delivery')
    ) {
      return;
    }

    this.shipmentValidationErrors.tags =
      this.UserSession.getCompanyDashboardSettings().beta_feature_require_order_tag &&
      (!this.selectedShipment.order_tag_list || this.selectedShipment.order_tag_list.length === 0);

    if (this.orderForm?.$invalid || this.shipmentValidationErrors.tags) return;

    if (!this.PostalCodeService.validator.multipleShipment) {
      this.checkBackendErrorMessage();
      return;
    }

    this.selectedShipment.parcels_attributes.forEach((parcel: IShipmentParcelAttributes) => {
      // @ts-expect-error: set total_actual_weight to null when box_weight_oz is enabled
      parcel.total_actual_weight = null;
    });
    this.busy = true;
    this.selectedShipment.recalculate_shipment = false;
    this.ShipmentListAdvancedService.updateShipmentByOrderForm(this.selectedShipment)
      .then((data) => {
        if (this.esIndex || this.esIndex === 0) {
          this.ShipmentListAdvancedService.modifyShipmentAfterUpdate(this.esIndex, data.shipment);
        }
      })
      .finally(() => {
        this.busy = false;
      });
  }

  closeAllShipmentField(isClearData = true): void {
    this.ShipmentListAdvancedService.closeAllField(this.esIndex, isClearData);
  }

  checkBackendErrorMessage(): void {
    if (!this.ShipmentListAdvancedService.selectedShipment) {
      return;
    }

    if (Array.isArray(this.ShipmentListAdvancedService.selectedShipment.logs.price_calculation)) {
      const shipmentErrors =
        this.ShipmentListAdvancedService.selectedShipment.logs.price_calculation.filter(
          (error) => error.category === 'shipment' || error.category === 'shipment_items'
        );
      if (!shipmentErrors) {
        return;
      }

      // Since we no longer ask for weight input, this will check for BE error and skip showing it
      // Can delete after all older shipments become shipped/deleted as new shipments no longer have this error message
      const errors = shipmentErrors.filter(
        (error) => !this.ShipmentListAdvancedService.shipmentItemFields.includes(error.field)
      );

      errors.forEach((err) => {
        toastError(err.message);
      });
    }
  }

  saveInformation(shipment: IShipmentListItem): void {
    this.ShipmentListAdvancedService.updateSelectedShipment(shipment);
  }

  saveItems(parcels: IShipmentParcelAttributes[], preserveCourier: boolean): void {
    if (this.selectedShipment) {
      this.selectedShipment.parcels_attributes = parcels;
      this.selectedShipment.preserveCourier = preserveCourier;
    }
  }

  get selectedShipment(): IShipmentListItem | null {
    return this.ShipmentListAdvancedService.selectedShipment;
  }

  get platformNames(): string[] {
    return this.PlatformService.getPlatformNames();
  }
}

const ShipmentListOrderFormComponent: ng.IComponentOptions = {
  controller: ShipmentListOrderForm,
  template,
  bindings: {
    esIndex: '<',
    esShowMissingDimensionsWarning: '<',
  },
};

export { ShipmentListOrderFormComponent };
