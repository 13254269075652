import { IUserSession } from 'typings/user-session';
import { ICourierAccountsService } from 'typings/courier';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError } from '@client/core/components/react/Toastify';
import style from './terms-and-conditions.module.scss';
import template from './terms-and-conditions.html?raw';

class TermsAndConditions implements IComponentController {
  style = style;
  userReadAndAcceptedTnc = false;
  loadingAgreement = true;
  downloadingAgreement = true;

  static $inject = [
    '$translate',
    'CourierAccounts',
    'CourierAccountsResource',
    'UserSession',
    'MixpanelService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private CourierAccounts: ICourierAccountsService,
    private CourierAccountsResource: any,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService
  ) {}

  $onInit(): void {
    this.loadingAgreement = true;

    this.CourierAccounts.getAccessLicenseAgreement()
      .then((data: { access_license_agreement: string }) => {
        this.CourierAccounts.accessLicenseAgreement = data.access_license_agreement;
      })
      .catch(() => {
        toastError(this.$translate.instant('courier.connect.form.ups.license-error'));
      })
      .finally(() => {
        this.loadingAgreement = false;
      });
  }

  toggleReadAndAcceptCheckbox(value: boolean): void {
    this.userReadAndAcceptedTnc = value;
  }

  downloadCTA(): void {
    this.CourierAccountsResource.printAccessLicenseAgreement({
      company_id: this.UserSession.company.id,
    })
      .then((response: any) => {
        const pdf = new Blob([response.data], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');

        downloadLink.download = `ups-access-license-agreement.pdf`;
        downloadLink.href = window.URL.createObjectURL(pdf);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
      .catch(() => {
        toastError(this.$translate.instant('courier.connect.form.ups.license-error'));
      });

    this.MixpanelService.track('Connect UPS - CTA - Download');
  }
}

const TermsAndConditionsComponent: ng.IComponentOptions = {
  controller: TermsAndConditions,
  template,
  bindings: {
    esOnAccept: '&',
    esOnBack: '&',
    esCourier: '<',
  },
};

export { TermsAndConditionsComponent };
