(function () {
  'use strict';

  WelcomeService.$inject = [
    '$timeout',
    '$state',
    'UserSession',
    'Auth',
    'WalkthroughService',
    'MixpanelService',
  ];
  function WelcomeService(
    $timeout,
    $state,
    UserSession,
    Auth,
    WalkthroughService,
    MixpanelService
  ) {
    var service = this;

    var NEW_SIGNUP_FLOW_KEYS = ['easyship_20230501', 'easyship_20230701'];

    service.init = function () {
      Auth.retrieveSession().then(function () {
        $timeout(function () {
          if (UserSession.getActionableSelectedPlatform()) {
            service.runHomepageWalkthrough();
          } else {
            service.runOnboardingPhase();
          }
        });
      });
    };

    service.runOnboardingPhase = function () {
      if (
        NEW_SIGNUP_FLOW_KEYS.includes(UserSession.getCompanySignupFlow()) &&
        (!UserSession.getUserPersona() || UserSession.isPlatformRequired()) &&
        !UserSession.admin_email
      ) {
        $state.go('app.onboarding');
      }
    };

    service.runHomepageWalkthrough = function () {
      if (
        UserSession.getActionableSelectedPlatform() &&
        UserSession.company.connect_store_incentive_amount
      ) {
        var platformName = UserSession.getActionableSelectedPlatform();
        var incentiveAmount = UserSession.company.connect_store_incentive_amount;

        WalkthroughService.runModalWalkthrough('connect-store-incentive', null, {
          platform: platformName,
          incentiveAmount: incentiveAmount,
          cta: 'Connect to redeem',
          ctaAction: function () {
            return $state.go('app.connect');
          },
        });
      }
    };
  }

  angular.module('app.service.WelcomeService', []).service('WelcomeService', WelcomeService);
})();
