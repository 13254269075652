import { ICountryService } from 'typings/auth/services/country';
import { States } from 'typings/states';

import { IComponentController } from 'angular';
import template from './state-selector.html?raw';
import style from './state-selector.module.scss';
import { StatesService } from '../../services/states/states.service';

interface IState extends States.IState {
  selected: boolean;
  isDisplay: boolean;
}

interface IStates {
  [key: string]: {
    selected: boolean;
    options: IState[];
  };
}

class StateSelector implements IComponentController {
  esModel: string[] = [];
  esOnChange?(value: any) {}

  style = style;
  busy = {
    gettingCountries: true,
  };
  searchQuery = '';
  states: IStates = {};
  filteredStates: IStates = {};

  static $inject = ['$q', 'CountryService', 'StatesService'];
  constructor(
    private $q: ng.IQService,
    private CountryService: ICountryService,
    private StatesService: StatesService
  ) {}

  $onInit() {
    this.esModel = this.esModel || [];

    this.$q
      .all([this.CountryService.getCountries(), this.StatesService.getStates()])
      .then(([, { states }]) => {
        this.busy.gettingCountries = false;

        this.states = states.reduce((accumulator, state) => {
          const countryName = this.CountryService.getCountryNameById(state.country_id);

          if (!accumulator[countryName]) {
            accumulator[countryName] = {
              selected: true,
              options: [],
            };
          }

          const { id, country_id, name, abbr } = state;

          if (accumulator[countryName].selected) {
            accumulator[countryName].selected = this.esModel.includes(`${country_id}-${abbr}`);
          }

          accumulator[countryName].options.push({
            id,
            country_id,
            name,
            abbr,
            selected: this.esModel.includes(`${country_id}-${abbr}`),
            isDisplay: true,
          });

          return accumulator;
        }, {} as IStates);

        this.filteredStates = this.states;
      });
  }

  onSearchBarChange = (query: string) => {
    this.searchQuery = query;

    this.filteredStates = Object.keys(this.filteredStates).reduce((accumulator, country) => {
      accumulator[country] = {
        selected: this.filteredStates[country].selected,
        options: this.states[country].options.filter((state) => {
          return state.name.toLowerCase().includes(query.toLowerCase());
        }),
      };

      return accumulator;
    }, {} as IStates);
  };

  onMultiSelectCheckboxChange() {
    const value = Object.keys(this.states).reduce((accumulator, country) => {
      return [
        ...accumulator,
        ...this.states[country].options
          .filter((state) => {
            return state.selected;
          })
          .map(({ country_id, abbr }) => {
            return `${country_id}-${abbr}`;
          }),
      ];
    }, [] as string[]);

    this.esOnChange && this.esOnChange({ value });
  }
}

const StateSelectorComponent: ng.IComponentOptions = {
  controller: StateSelector,
  template,
  bindings: {
    esModel: '<',
    esOnChange: '&',
  },
};

export { StateSelectorComponent };
