import angular from 'angular';
import { Platform } from '@client/core/corelogic/models/Platform';
import IPlatformGateway from '@client/core/corelogic/ports/platform.interface';
import { IPlatformService, IAllActivePlatforms } from 'typings/platform';
import { IStorePlatform } from 'typings/store';

export default class NgPlatformGateway implements IPlatformGateway {
  private platformService: IPlatformService;

  constructor() {
    this.platformService = angular
      .element(document.body)
      .injector()
      .get<IPlatformService>('PlatformService');
  }

  getActivePlatforms(): Promise<IAllActivePlatforms> {
    return this.platformService.getAllActivePlatforms();
  }

  all(): Promise<Platform[]> {
    return new Promise((resolve) => {
      resolve(
        this.platformService.getPlatformNames().map((platformName) => ({ name: platformName }))
      );
    });
  }

  getAllPlatforms(): Promise<IStorePlatform[]> {
    return this.platformService.getAllPlatforms();
  }
}
