import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal, Input, Alert, RadioGroup, Radio } from 'easyship-components';
import { IUser, IUserRole, IUserRoleAndPermission } from 'typings/user-session';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { EMAIL_REGEX } from '@client/src/data/regex';
import { ConfirmModal } from '@client/src/global/components/confirm-modal/ConfirmModal';
import useUpdateUserMutation from '@client/src/account/profile/hooks/queries/useUpdateUser';
import useUserRolesQuery from '../hooks/queries/useUserRolesQuery';
import useUserRolesMutation from '../hooks/mutations/useUpdateUserRoles';
import useSendInviteMutation from '../hooks/mutations/useSendInviteMutation';

enum UserRoleRadioValue {
  NO = '0',
  YES = '1',
}

interface TeamMemberModalProps {
  canUseTeamRightsAndPermission: boolean;
  member?: IUser;
  onClose: () => void;
  onPermissionsSaved?: () => void;
  onMemberRemoved?: () => void;
  onInviteSent?: (email: string) => void;
  userIsOwner: boolean;
  userRoleList: IUserRole[];
}

export function TeamMemberModal({
  canUseTeamRightsAndPermission,
  member,
  onClose,
  onPermissionsSaved,
  onMemberRemoved,
  onInviteSent,
  userIsOwner,
  userRoleList,
}: TeamMemberModalProps) {
  const { formatMessage } = useIntl();
  const t = (id: string) => formatMessage({ id });

  const isAddNewMember = !member;
  const name = isAddNewMember
    ? t('account.team.add-new-member')
    : `${member.first_name} ${member.last_name}`;
  const isMemberTheOwner = !isAddNewMember && member.role.id === 10;
  const isAccountOwnerMember = userIsOwner && isMemberTheOwner;

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = useUpdateUserMutation();
  const { data: userRoles = userRoleList, isFetching: isFetchingUserRoles } = useUserRolesQuery(
    isAddNewMember || isAccountOwnerMember || !canUseTeamRightsAndPermission ? '' : member.id
  );
  const { mutateAsync: updateUserRoles, isLoading: isUpdatingUserRoles } = useUserRolesMutation();
  const { mutateAsync: sendInvite, isLoading: isSendingInvite } = useSendInviteMutation();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState<boolean>(false);
  const [userRoleMap, setUserRoleMap] = useState<
    Partial<Record<IUserRoleAndPermission | string, UserRoleRadioValue>>
  >({});
  const [email, setEmail] = useState<string>(member?.email ?? '');
  const [emailInputHasError, setEmailInputHasError] = useState<boolean>(false);
  const [emailInputStatusText, setEmailInputStatusText] = useState<string>('');

  const isBusy = isUpdatingUser || isFetchingUserRoles || isUpdatingUserRoles || isSendingInvite;
  const canInviteNewMember = canUseTeamRightsAndPermission ? userIsOwner : true;

  const getUserRoles = () =>
    Object.entries(userRoleMap).map(([id, value]) => {
      const isActive = value === UserRoleRadioValue.YES;
      return isAddNewMember
        ? {
            role_id: parseInt(id, 10),
            is_active: isActive,
          }
        : {
            slug: id as IUserRoleAndPermission,
            is_active: isActive,
          };
    });

  const getUserRoleMapId = useCallback(
    (userRole: IUserRole) => (isAddNewMember ? userRole.role_id : userRole.slug),
    [isAddNewMember]
  );

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  // ----- Send Invite ----- //

  const isValidEmail = (email: string): boolean => {
    if (!email) {
      setEmailInputHasError(true);
      toastError(t('account.team.error.required-field'), {
        toastId: 'account.team.error.required-field',
      });
      return false;
    }
    if (!EMAIL_REGEX.test(email)) {
      setEmailInputHasError(true);
      setEmailInputStatusText(t('account.team.team-member.invalid-email'));
      return false;
    }
    if (emailInputHasError) {
      setEmailInputHasError(false);
      setEmailInputStatusText('');
    }

    return true;
  };

  const handleSendInvite = async () => {
    if (!isValidEmail(email) || !canInviteNewMember || isBusy) {
      return;
    }

    try {
      await sendInvite({
        email,
        /** @ts-expect-error: FIXME */
        userRoles: getUserRoles(),
      });

      toastSuccess(
        formatMessage(
          { id: 'account.team.invite-success' },
          {
            email,
          }
        )
      );

      onInviteSent?.(email);
    } catch (error) {
      const { data } = error;
      toastError(
        formatMessage(
          { id: 'account.team.invite-failed' },
          {
            details:
              (data && data.error_code
                ? t(`account.team.error.${data.error_code}`)
                : data.status) || '',
          }
        )
      );
    } finally {
      handleOnClose();
    }
  };

  // ----- Save Permissions ----- //

  const handleSavePermissions = async () => {
    if (!userIsOwner || isBusy || !member) {
      return;
    }

    try {
      await updateUserRoles({
        userId: member.id,
        userRoles: getUserRoles(),
      });

      toastSuccess(
        formatMessage(
          { id: 'account.team.team-member.save-permissions-success' },
          {
            name,
          }
        )
      );

      onPermissionsSaved?.();
    } catch (error) {
      toastError(
        formatMessage(
          { id: 'account.team.team-member.save-permissions-failed' },
          {
            name,
          }
        )
      );
    } finally {
      handleOnClose();
    }
  };

  // ----- Remove Member ----- //

  const handleCancelRemoveMember = () => {
    setIsConfirmRemoveOpen(false);
    setIsOpen(true);
  };

  const handleRemoveMember = () => {
    setIsConfirmRemoveOpen(true);
    setIsOpen(false);
  };

  const handleConfirmRemoveMember = async () => {
    if (!userIsOwner || isBusy || !member) {
      return;
    }

    try {
      await updateUser({
        id: member.id,
        is_active: false,
      });

      toastSuccess(
        formatMessage(
          { id: 'account.team.block-success' },
          {
            name,
          }
        )
      );

      onMemberRemoved?.();
    } catch (error) {
      toastError(
        formatMessage(
          { id: 'account.team.remove-failed' },
          {
            name,
          }
        )
      );
    } finally {
      setIsConfirmRemoveOpen(false);
      handleOnClose();
    }
  };

  useEffect(() => {
    setUserRoleMap(
      userRoles.reduce(
        (obj, userRole) =>
          ({
            ...obj,
            [getUserRoleMapId(userRole)]: userRole.is_active
              ? UserRoleRadioValue.YES
              : UserRoleRadioValue.NO,
          } as Record<string, UserRoleRadioValue>),
        {} as Record<string, UserRoleRadioValue>
      )
    );
  }, [getUserRoleMapId, isAddNewMember, userRoleList, userRoles]);

  return (
    <>
      {isOpen && (
        <Modal id="team-member-modal" open onClose={handleOnClose}>
          <Modal.Header className="items-center" closeButtonAriaLabel="close" title={name} />
          <Modal.Content className="flex flex-col gap-5 max-w-[520px]">
            <Input
              label={t('global.email-address')}
              value={email}
              readOnly={!isAddNewMember}
              status={emailInputHasError ? 'error' : undefined}
              statusText={emailInputStatusText}
              onChange={(event) => {
                const { value } = event.target;
                isValidEmail(value);
                setEmail(value);
              }}
            />
            {isAccountOwnerMember && (
              <Alert className="items-center">
                {t('account.team.team-member.change-email')}{' '}
                <a href="/account/profile">{t('sidebar.profile')}</a>
              </Alert>
            )}
            {canUseTeamRightsAndPermission && !isMemberTheOwner && !isFetchingUserRoles && (
              <>
                <h4 className="pt-5 m-0 text-lg">
                  {t('account.team.team-member.team-permissions')}
                </h4>
                {!userIsOwner && (
                  <Alert className="items-center">
                    {t('account.team.team-member.team-permissions-not-editable')}
                  </Alert>
                )}
                {userRoles.map((userRole) => (
                  <div key={userRole.slug} className="flex items-center justify-between gap-2">
                    <div>
                      <h5 className="font-bold">
                        {t(`account.team.team-member.${userRole.slug}.title`) || userRole.name}
                      </h5>
                      <p className="text-ink-500">
                        {userRole.slug
                          ? t(`account.team.team-member.${userRole.slug}.subtitle`)
                          : ''}
                      </p>
                    </div>
                    <div>
                      <RadioGroup
                        name={userRole.slug}
                        orientation="horizontal"
                        value={userRoleMap[getUserRoleMapId(userRole)]}
                        onChange={(event) => {
                          if (userIsOwner) {
                            setUserRoleMap({
                              ...userRoleMap,
                              [getUserRoleMapId(userRole)]: event.target
                                .value as UserRoleRadioValue,
                            });
                          }
                        }}
                        disabled={!userIsOwner || isBusy}
                      >
                        <Radio label={t('global.no')} value={UserRoleRadioValue.NO} />
                        <Radio label={t('global.yes')} value={UserRoleRadioValue.YES} />
                      </RadioGroup>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Modal.Content>
          {isAddNewMember && (
            <Modal.Footer>
              {/* Send Invite */}
              <Button
                color="primary"
                onClick={handleSendInvite}
                disabled={!canInviteNewMember || isBusy}
                className="min-w-[158px]"
              >
                {t('account.team.team-member.send-invite')}
              </Button>
            </Modal.Footer>
          )}
          {!isMemberTheOwner && !isAddNewMember && (
            <Modal.Footer>
              {/* Remove Member */}
              <Button
                color="danger"
                flat
                onClick={handleRemoveMember}
                disabled={!userIsOwner || isBusy}
                className="min-w-[134px]"
              >
                {t('account.team.team-member.remove-member')}
              </Button>
              {/* Save Permissions */}
              {canUseTeamRightsAndPermission && (
                <Button
                  color="primary"
                  onClick={handleSavePermissions}
                  disabled={!userIsOwner || isBusy}
                  className="min-w-[145px]"
                >
                  {t('account.team.team-member.save-permissions')}
                </Button>
              )}
            </Modal.Footer>
          )}
        </Modal>
      )}
      <ConfirmModal
        open={isConfirmRemoveOpen}
        header={`${t('account.team.team-member.remove')} ${name}`}
        content={`${name} ${t('account.team.team-member.confirm-remove-message')}`}
        confirmText={t('account.team.team-member.confirm-removal')}
        confirmClassName="min-w-[199px]"
        onCancel={handleCancelRemoveMember}
        onConfirm={handleConfirmRemoveMember}
      />
    </>
  );
}
