(function () {
  'use strict';

  ShipmentCache.$inject = ['$q', 'EndpointService'];
  function ShipmentCache($q, EndpointService) {
    var Cache = this;

    Cache.busy = false;
    Cache.totals = {};
    Cache.pages = {};
    Cache.selected = {
      all: false,
      is_valid_to_ship: 0,
      is_valid_to_print: 0,
      is_valid_to_reschedule_pickup: 0,
    };

    // Filled when user is editing a shipment in the orders page
    // Contains:
    //  - shipment that is being edited
    //  - pageNum of original shipment
    //  - index of orginal shipment
    Cache.clipboard = {};

    Cache.reset = function () {
      Cache.busy = false;
      Cache.totals = {};
      Cache.pages = {};
      Cache.clipboard = {};
      Cache.selected = {
        all: false,
        is_valid_to_ship: 0,
        is_valid_to_print: 0,
        is_valid_to_reschedule_pickup: 0,
      };
    };

    Cache.fetchPage = function (pageNum) {
      return $q(function (resolve, reject) {
        Cache.calcShipmentRange(pageNum);
        Cache.pages[pageNum];
        Cache.busy = false;
        resolve();
      });
    };

    Cache.createPage = function (params) {
      return $q(function (resolve, reject) {
        Cache.totals = params.totals;
        Cache.pages[params.page] = {
          shipments: params.shipments,
          isGlobalChecked: params.isGlobalChecked,
        };
        Cache.calcShipmentRange(params.page);
        Cache.busy = false;
        Cache.selected.shipmentValidKey = params.shipmentValidKey;
        Cache.selected.shipmentReschedulableKey = params.shipmentReschedulableKey;
        resolve();
      });
    };

    Cache.removePage = function (pageNum) {
      delete Cache.pages[pageNum];
    };

    Cache.calcShipmentRange = function (pageNum) {
      Cache.startRange = (pageNum - 1) * EndpointService.params.limit + 1;
      Cache.endRange = Cache.startRange + (Cache.pages[pageNum].shipments.length - 1);
    };

    Cache.increaseValidCount = function (count) {
      Cache.selected[Cache.selected.shipmentValidKey] += count;
    };

    Cache.decreaseValidCount = function (count) {
      Cache.selected[Cache.selected.shipmentValidKey] -= count;
    };

    Cache.increaseReschedulableCount = function (count) {
      Cache.selected[Cache.selected.shipmentReschedulableKey] += count;
    };

    Cache.decreaseReschedulableCount = function (count) {
      Cache.selected[Cache.selected.shipmentReschedulableKey] -= count;
    };
  }

  angular.module('app.service.ShipmentCache', []).service('ShipmentCache', ShipmentCache);
})();
