import angular from 'angular';
import { IStoreService } from '@typings/store';
import { StoreGateway } from '@client/core/corelogic/ports/store.interface';

export default class NgStoreGateway implements StoreGateway {
  async getStores() {
    const service = angular.element(document.body).injector().get('StoreService') as IStoreService;
    const result = await service.getStores();
    return Promise.resolve(result.stores);
  }
}
