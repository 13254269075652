import angular from 'angular';
import uibModal from 'angular-ui-bootstrap/src/modal';

import '@client/src/global/services/shipping-discounts';
import './sync-orders';

import { StorePageComponent } from './store-page.controller';
import { StoreSettingsOrderSyncCardComponent } from './sync-orders/sync-order-card/sync-order-card.component';
import { StoreSettingsCheckoutCardComponent } from './checkout/checkout-card/checkout-card.component';
import { StoreSettingsInsuranceCardComponent } from './insurance/insurance-card/insurance-card.component';
import { StoreSettingsProductsCardComponent } from './products/products-card.component';
import { DiscountRatesComponent } from './checkout/discount-rates/discount-rates.component';
import { ExcludeCountriesComponent } from './checkout/exclude-countries/exclude-countries.component';
import { CheckoutModalMainComponent } from './checkout/checkout-modal-main/checkout-modal-main.component';
import { DiscountRateService } from './checkout/discount-rates/discount-rates.service';
import { CheckoutModalService } from './checkout/checkout-modal/checkout-modal.service';
import { StoreSettingsInsuranceActivationModalComponent } from './insurance/insurance-activation-modal/insurance-activation-modal.component';
import { StoreSettingsInsuranceDeactivationModalComponent } from './insurance/insurance-deactivation-modal/insurance-deactivation-modal.component';
import { StoreSettingsInsuranceExampleModalComponent } from './insurance/insurance-example-modal/insurance-example-modal.component';
import { AngularRateExplainerDialog } from './RateExplainerDialog';
import { AngularTaxAndDutyExplainerDialog } from './TaxAndDutyExplainerDialog';
import { AngularFallbackSettingsDialog } from './FallbackSettingsDialog';

angular
  .module('app.connnect.store', [
    uibModal,
    'app.global.shipping-discounts',
    'app.connnect.store.sync-orders',
  ])
  .component('esStoreSettingsOrderSyncCard', StoreSettingsOrderSyncCardComponent)
  .component('esStoreSettingsCheckoutCard', StoreSettingsCheckoutCardComponent)
  .component('esStoreSettingsInsuranceCard', StoreSettingsInsuranceCardComponent)
  .component(
    'esStoreSettingsInsuranceActivationModal',
    StoreSettingsInsuranceActivationModalComponent
  )
  .component(
    'esStoreSettingsInsuranceDeactivationModal',
    StoreSettingsInsuranceDeactivationModalComponent
  )
  .component('esStoreSettingsInsuranceExampleModal', StoreSettingsInsuranceExampleModalComponent)
  .component('esStoreSettingsProductsCard', StoreSettingsProductsCardComponent)
  .component('discountRates', DiscountRatesComponent)
  .component('excludeCountries', ExcludeCountriesComponent)
  .component('checkoutModalMain', CheckoutModalMainComponent)
  .service('DiscountRateService', DiscountRateService)
  .service('CheckoutModalService', CheckoutModalService)
  .component('esStoreSettingsPage', StorePageComponent)
  .component('esRateExplainerDialog', AngularRateExplainerDialog)
  .component('esTaxAndDutyExplainerDialog', AngularTaxAndDutyExplainerDialog)
  .component('esFallbackSettingsDialog', AngularFallbackSettingsDialog);
