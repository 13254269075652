'use strict';
import template from './receiver-info.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('receiver-info', {
      url: '/receiver-info',
      parent: 'app.single',
      template,
      controller: 'SingleShipmentReceiverInfoCtrl',
      controllerAs: 'vm',
      params: {
        shipment: null,
      },
    });
  },
]);
