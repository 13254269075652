import template from './merge-selected-shipment.html?raw';
import style from './merge-selected-shipment.module.scss';

class MergeSelectedShipment {
  constructor() {
    this.style = style;
  }

  $onInit() {
    this.contactInfo = this._getContactInfo();
    this.fullAddress = this._getFullAddress();
  }

  _getContactInfo() {
    const { phone_number, email_address } = this.shipment;

    return [phone_number, email_address].filter((field) => field).join(', ');
  }

  _getFullAddress() {
    const {
      address_line_1,
      address_line_2,
      address_line_3,
      city,
      postal_code,
      state,
      country = {},
    } = this.shipment;

    return [address_line_1, address_line_2, address_line_3, city, postal_code, state, country.name]
      .filter((field) => field)
      .join(', ');
  }
}

const MergeSelectedShipmentComponent = {
  template,
  controller: MergeSelectedShipment,
  bindings: {
    shipment: '<',
    selected: '<',
    ngClick: '&',
  },
};

export { MergeSelectedShipmentComponent };
