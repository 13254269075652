import { IAdjustmentsParams, IAdjustmentsData } from 'typings/adjustments';
import { AdjustmentsResource } from './adjustments.resource';

class AdjustmentsService {
  private _adjustmentsData: IAdjustmentsData | null = null;

  static $inject = ['AdjustmentsResource'];
  constructor(private AdjustmentsResource: AdjustmentsResource) {}

  get adjustmentsData(): IAdjustmentsData | null {
    return this._adjustmentsData;
  }

  getAdjustmentsData(params: IAdjustmentsParams): ng.IPromise<IAdjustmentsData> {
    return this.AdjustmentsResource.adjustmentsData(params)
      .then((data: IAdjustmentsData) => {
        if (data) {
          this._adjustmentsData = data;
        }

        return data;
      })
      .catch((error: unknown) => {
        throw error;
      });
  }
}

export { AdjustmentsService };
