import { ISubscriptionObject, IShowChangePlanObject } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import { IApiConfig } from 'typings/core/config/api';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';
import { FeatureKey, FEATURE_KEY } from '@client/data/subscription';

import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './tracking-page.html?raw';
import style from './tracking-page.module.scss';

interface IUpdateTrackingData {
  [key: string]: boolean | string;
}

interface IFormData {
  tracking_page_ad: {
    asset_id: string;
    created_at: string;
    file_content_type: string;
    file_file_name: string;
    file_file_size: number;
    file_updated_at: string;
    id: string;
    is_active: boolean;
    owner_id: string;
    owner_type: string;
    updated_at: string;
  };
  tracking_page_advertising: {
    call_to_action: string;
    link_url: string;
  };
  tracking_page_footnote: string;
  tracking_page_show_contact: boolean;
  tracking_page_show_header: boolean;
}

class TrackingPage {
  style = style;
  formData: any;
  trackingPageForm: ng.IFormController | null = null;
  loading = {
    form: false,
    settings: false,
  };
  onToggleValue = {
    upgradePlan: 'tracking_page_upgrade_plan',
  };
  showModal: Partial<IShowChangePlanObject> = {
    compare: false,
    upgrade: false,
    downgrade: false,
    enterpriseCall: false,
    callScheduledCard: false,
  };
  hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  buttonBackgroundColor = '';
  buttonTextColor = '';
  translations: Record<string, string> = {};

  static $inject = [
    '$translate',
    'AdvertisingModal',
    'UserSession',
    'CompanyService',
    'MixpanelService',
    'SubscriptionService',
    'OnboardingChecklistService',
    'API',
    '$q',
    'UserRightsService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private AdvertisingModal: any,
    private UserSession: IUserSession,
    private CompanyService: any,
    private MixpanelService: MixpanelService,
    private SubscriptionService: SubscriptionService,
    private OnboardingChecklistService: OnboardingChecklistService,
    private API: IApiConfig,
    private $q: ng.IQService,
    private UserRightsService: UserRightsService
  ) {}

  $onInit() {
    this.loading.settings = true;
    this.$translate(['global.setting']).then((translations) => {
      this.translations = translations;
    });

    const companyId = this.UserSession.getCompanyId();
    if (companyId) {
      this.$q
        .all([
          this.CompanyService.getTrackingPageBrandingData(),
          this.SubscriptionService.fetchCurrentSubscription({ company_id: companyId }),
        ])
        .then(([trackingPageBrandingData]) => {
          const overrides = {
            tracking_page_show_header: false,
            tracking_page_show_estimated_dlt: false,
            tracking_page_show_status_icon: false,
            tracking_page_show_contact_courier: false,
            tracking_page_show_contact: false,
            tracking_page_footnote: '',
          };

          this.formData = this.isBrandedTrackingAvailable
            ? trackingPageBrandingData
            : { ...trackingPageBrandingData, ...overrides };

          this._initDefaultButtonColors();
        })
        .catch(() => {
          toastError(this.$translate.instant('toast.default-error'));
        })
        .finally(() => {
          this.loading.settings = false;
        });
    }
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get isBrandedTrackingAvailable(): boolean {
    return this.SubscriptionService.isFeatureAccessible(FEATURE_KEY.BrandedTrackingExperience);
  }

  get isSubscriptionAvailable(): boolean {
    return this.SubscriptionService.isSubscriptionAvailable;
  }

  isPlanBadgeVisible(featureKey: FeatureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  mixpanelTrackSendAllData() {
    this.MixpanelService.track('Settings - Tracking Page - Customize Tracking Page', {
      toggle_header: this.formData.tracking_page_show_header,
      toggle_dlt: this.formData.tracking_page_show_estimated_dlt,
      toggle_icon: this.formData.tracking_page_show_status_icon,
      toggle_contact_courier: this.formData.tracking_page_show_contact_courier,
      contact_courier_cta_background: `#${this.buttonBackgroundColor}`,
      contact_courier_cta_text: `#${this.buttonTextColor}`,
      toggle_contact_us: this.formData.tracking_page_show_contact,
      has_contact_url: !!this.UserSession.company.company_url,
      has_contact_email: !!this.UserSession.company.contact_email,
      has_contact_phone: !!this.UserSession.company.phone_number,
      has_facebook: !!this.UserSession.company.facebook_url,
      has_twitter: !!this.UserSession.company.twitter_url,
      has_instagram: !!this.UserSession.company.instagram_url,
      has_youtube: !!this.UserSession.company.youtube_url,
      has_ad_image: !!this.formData.tracking_page_ad.asset_id,
      has_ad_cta: !!this.formData.tracking_page_advertising.call_to_action,
      has_ad_link: !!this.formData.tracking_page_advertising.link_url,
      has_footnote: !!this.formData.tracking_page_footnote,
    });
  }

  viewPreview(): void {
    const previewLink = `${
      this.API.tracking
    }/shipment-tracking/preview/${this.UserSession.getCompanyId()}`;

    window.open(previewLink, '_blank');
    this.MixpanelService.track('Settings - Tracking Page - Tracking Page Preview');
  }

  onToggleChange(value: boolean, model: string): void {
    if (this.isBrandedTrackingAvailable) {
      const data = { [model]: value };

      this.formData[model] = value;
      this._updateTrackingPage(data);
      this.mixpanelTrackSendAllData();
    }
  }

  onOpenCompareModal(): void {
    this.showModal.compare = true;
    this.MixpanelService.track('Subscription - Plans - Open', {
      trigger_source: 'Branded Tracking Page',
    });
  }

  onTextareaChange(value: string, model: string): void {
    if (this.isBrandedTrackingAvailable) {
      this.formData[model] = value;
    }
  }

  onButtonStyleChange(value: string, type: string): void {
    if (typeof value === 'string') {
      value = `#${value.replace(/^#+/, '')}`;

      if (type === 'background') {
        this.buttonBackgroundColor = value.substr(1);
      }

      if (type === 'text') {
        this.buttonTextColor = value.substr(1);
      }

      if (this.hexColorRegex.test(value)) {
        this._updateCompany({
          tracking_page_btn_background_color: `#${this.buttonBackgroundColor}`,
          tracking_page_btn_text_color: `#${this.buttonTextColor}`,
        });
      }
    }
  }

  resetDefaultColors() {
    if (this.isEditBackgroundColorDisabled) return;

    this.buttonBackgroundColor = '50D4A4';
    this.buttonTextColor = 'FFFFFF';

    this._updateCompany({
      tracking_page_btn_background_color: `#${this.buttonBackgroundColor}`,
      tracking_page_btn_text_color: `#${this.buttonTextColor}`,
    });
  }

  openAdvertisingModal(): void {
    if (this.isBrandedTrackingAvailable) {
      this.AdvertisingModal.open('tracking_page_ad', this.formData).then(
        (response: { company: IFormData }) => {
          if (!response) {
            return;
          }
          this.MixpanelService.track('Settings - Tracking Page - Advertize your business - Save', {
            has_image: !!this.UserSession.company.tracking_page_ad.asset_id,
            has_button_label: !!this.formData.tracking_page_advertising.call_to_action,
            has_cta_link: !!this.formData.tracking_page_advertising.link_url,
          });

          this.mixpanelTrackSendAllData();
        }
      );

      this.MixpanelService.track('Settings - Tracking Page - Advertize your business - Edit');
    }
  }

  onSubmitForm(): void {
    if (this.trackingPageForm && this.trackingPageForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    const data = { tracking_page_footnote: this.formData.tracking_page_footnote };

    this.loading.form = true;
    this._updateTrackingPage(data);
    this.MixpanelService.track('Settings - Tracking Page - Footnote - Save');
    this.mixpanelTrackSendAllData();
  }

  _initDefaultButtonColors() {
    this.buttonBackgroundColor = !this.formData?.tracking_page_btn_background_color
      ? '50D4A4'
      : this.formData?.tracking_page_btn_background_color.substr(1);
    this.buttonTextColor = !this.formData?.tracking_page_btn_text_color
      ? 'FFFFFF'
      : this.formData?.tracking_page_btn_text_color.substr(1);
  }

  _updateCompany(data: IUpdateTrackingData) {
    this.CompanyService.updateCompany(data)
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations['global.setting'].toLowerCase() },
            'messageformat'
          )
        );
      })
      .catch((error: any) => {
        toastError(
          this.$translate.instant(error.data?.status || 'toast.update-error', {
            noun: this.translations['global.setting'].toLowerCase(),
          })
        );
      })
      .finally(() => {
        this.loading.form = false;
      });
  }

  _updateTrackingPage(data: IUpdateTrackingData): void {
    this._updateCompany(data);

    this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.SetupBranding);
  }

  get isEditBackgroundColorDisabled(): boolean {
    return !this.UserRightsService.canEditTrackingPage;
  }

  get isTrackingPageFormDisabled(): boolean {
    return (
      (!this.isBrandedTrackingAvailable && this.isSubscriptionAvailable) ||
      this.isEditBackgroundColorDisabled
    );
  }
}

const TrackingPageComponent = {
  template,
  controller: TrackingPage,
};

export { TrackingPageComponent };
