import { IComponentController, IOnChangesObject } from 'angular';
import {
  MultiselectAttributes,
  MultiselectComponentModelValue,
} from '@client/src/global/services/shipment-list/data/multi-select';
import { IGroupedArray } from 'typings/helper';
import { IInputGroupRadioModel } from 'typings/form';
import { IAdvancedSearchMultiselectObject } from 'typings/advanced-search';

import { MultiSelectInputGroup } from '../abstract-class/multi-select-input-group.abstract';
import template from './fulfillment-status-multi-select-group.html?raw';

enum StoreFulfillmentState {
  None = 'none',
  WillNotFulfil = 'will_not_fulfil',
  WaitingForBatch = 'waiting_for_batch',
  Pending = 'pending',
  Delayed = 'delayed',
  Fulfilled = 'fulfilled',
  AlreadyFulfliled = 'already_fulfilled',
  FulfillableQuantityMismatch = 'fulfillable_quantity_mismatch',
  Cancelled = 'cancelled',
  Failed = 'failed',
  CancelFailed = 'cancel_failed',
}

class FulfillmentStatusMultiSelectGroup
  extends MultiSelectInputGroup<IAdvancedSearchMultiselectObject>
  implements IComponentController
{
  radioModel: IInputGroupRadioModel<MultiselectComponentModelValue>[] = [];
  esModelRadio: MultiselectComponentModelValue | null = null;
  esModelInput = '';
  esModelOptions: IGroupedArray<IAdvancedSearchMultiselectObject>[] = [];

  static $inject = ['$scope', '$translate'];
  constructor($scope: ng.IScope, private $translate: angular.translate.ITranslateService) {
    super($scope);
  }

  $onInit(): void {
    this.assignDefaultRadioModel();
    const noun = this.$translate.instant('shipments.filter.store_state.noun');
    this.radioModel = [
      {
        title: this.$translate.instant('global.relation.prefixed.is_any_of', { noun }),
        value: MultiselectComponentModelValue.AnyOf,
      },
      {
        title: this.$translate.instant('global.relation.prefixed.is_none_of', { noun }),
        value: MultiselectComponentModelValue.NoneOf,
      },
    ];

    if (!this.esModelOptions?.length) {
      this.esModelOptions = this.buildOptions();
    }

    this.watchAndTrigger(MultiselectAttributes.Value);
  }

  $onChanges(changes: IOnChangesObject): void {
    this.triggerChangeIfKnowOrUnknowModel(changes);
    this.assignDefaultSelectValue(this.esModelOptions);
  }

  buildOptions(): IGroupedArray<IAdvancedSearchMultiselectObject>[] {
    return [
      {
        display: this.$translate.instant('global.all'),
        value: '',
        options: Object.values(StoreFulfillmentState).map((store_state) => ({
          value: store_state as string,
          display: this.$translate.instant(`enum.store_state.${store_state}`),
          // Hide already_fulfilled and fulfillable_quantity_mismatch in the short-term, will expand them in the future
          isDisplay: ![
            StoreFulfillmentState.AlreadyFulfliled,
            StoreFulfillmentState.FulfillableQuantityMismatch,
          ].includes(store_state),
        })),
      },
    ];
  }
}

const FulfillmentStatusMultiSelectGroupComponent: ng.IComponentOptions = {
  controller: FulfillmentStatusMultiSelectGroup,
  template,
  bindings: {
    esModelRadio: '<',
    esModelInput: '<',
    esModelOptions: '<',
    esOnChange: '&',
  },
};

export { FulfillmentStatusMultiSelectGroupComponent };
