export enum DrawerStatus {
  Menu = 'menu',
  Content = 'content',
  Advanced = 'advanced',
  Manage = 'manage',
  All = 'all',
  ShipmentsAll = 'shipments-all',
}

export enum DrawerMenuKey {
  OrderStatus = 'order_status',
  MissingField = 'missing_field',
  ShipmentCreationDate = 'creation_date',
  OrderPlacedDate = 'order_placed_date',
  LabelPaymentDate = 'label_payment_date',
  NumberOfItems = 'number_of_items',
  Weight = 'total_actual_weight',
  SkuCount = 'unique_sku_count',
  TBC = 'tbc',
  Insurance = 'insurance',
  WarehouseStatus = 'warehouse_status',
  Pickups = 'pickups',
  Batches = 'batches',
  Manifests = 'manifests',
  Boxes = 'boxes',
  DestinationCountry = 'countries',
  DestinationState = 'states',
  Couriers = 'couriers',
  Origins = 'origins',
  Incoterms = 'effective_incoterms',
  ShipmentStatus = 'shipment_status',
  FulfillmentStatus = 'store_state',
  LabelStatus = 'label_status',
  StoresOrPlatform = 'store_platform',
  ProductSKU = 'product_sku',
  Tags = 'tags',
  EstimatedDeliveryDate = 'eta_date',
  ItemDescription = 'item_description',
  CollectState = 'collect_state',
  OrderNumber = 'order_number',
  PickLocation = 'pick_location',
  ShipmentId = 'easyship_shipment_id',
}
