import template from './address-form-ca.html?raw';

(function () {
  'use strict';

  var addressFormCa = {
    template,
    bindings: {
      address: '=',
      fieldValidators: '<',
    },
    controller: 'addressFormCaCtrl as ctrl',
  };

  angular.module('app.component.address-form-ca').component('addressFormCa', addressFormCa);
})();
