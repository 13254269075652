import angular from 'angular';
import { ICountryService } from 'typings/auth/services/country';
import {
  IAdvancedSearchCourierObject,
  IAdvancedSearchMultiselectObject,
  IAdvancedSearchPickupObject,
  IAdvancedSearchBatchObject,
  IAdvancedSearchManifestObject,
  IAdvancedSearchBoxObject,
  IAdvancedSearchIncotermsObject,
  IAdvancedSearchStatusMessageObject,
  IAdvancedSearchStorePlatformOption,
  IAdvancedSearchObject,
} from 'typings/advanced-search';
import { AdvancedSearchComponentApiNormalize } from 'typings/advanced-search-component-api-normalize';
import { IGroupedArray } from 'typings/helper';
import { IPlatformGroup } from 'typings/platform';
import { IStore, IStorePlatformOption } from 'typings/store';

import { NormalizeFilterKey, NormalizeMultiselectAttr, FilterOrGroupId } from './data/normalize';
import { NumberOfItemsInputOptionsValue } from './data/number-input';
import {
  MultiselectComponentModelValue,
  EqualityModelValue,
  MultiSelectGroupKey,
} from './data/multi-select';
import { StringOptionsValue } from './data/string-input';
import { OrderStatusRadioModelValue, TbcRadioModelValue } from './data/radio-group';
import { DrawerMenuKey } from './data/drawer';
import { AdvancedSearchComponentApiNormalizeService } from '../advanced-search-component-api-normalize/advanced-search-component-api-normalize.service';

type NumberNull = number | null;

export interface INumberInputGroupObject {
  select: NumberOfItemsInputOptionsValue | null;
  from: NumberNull;
  to: NumberNull;
  equal: NumberNull;
}

interface ICommaSeparatedGroupObject {
  select: MultiselectComponentModelValue;
  tags: string[];
}

export interface ICountriesGroupObject {
  radio: MultiselectComponentModelValue;
  options: string[];
}

interface IStringInputGroupObject {
  select: StringOptionsValue;
  input: string;
}

interface INormalizeBooleanData {
  type: NormalizeMultiselectAttr.Boolean;
  operator: EqualityModelValue;
  values: [boolean | '']; // 1 value
}

interface INormalizeNumberData {
  type: NormalizeMultiselectAttr.Number;
  operator: NumberOfItemsInputOptionsValue | null;
  values: number[];
}

interface INormalizeCommaSeparatedData {
  type: NormalizeMultiselectAttr.MultiSelect;
  operator: MultiselectComponentModelValue;
  values: string[] | null;
}

interface INormalizeStringData {
  type: NormalizeMultiselectAttr.String;
  operator: StringOptionsValue;
  values: string[] | null;
}

export interface ICouriersGroupedArray extends IGroupedArray<IAdvancedSearchCourierObject> {
  logo_url?: string;
}

export interface IShipmentListFiltersData {
  [DrawerMenuKey.OrderStatus]?: OrderStatusRadioModelValue | null;
  [DrawerMenuKey.MissingField]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchMultiselectObject> | null;
  [DrawerMenuKey.ShipmentCreationDate]?: AdvancedSearchComponentApiNormalize.IDateInputGroupObject | null;
  [DrawerMenuKey.OrderPlacedDate]?: AdvancedSearchComponentApiNormalize.IDateInputGroupObject | null;
  [DrawerMenuKey.LabelPaymentDate]?: AdvancedSearchComponentApiNormalize.IDateInputGroupObject | null;
  [DrawerMenuKey.NumberOfItems]?: INumberInputGroupObject | null;
  [DrawerMenuKey.SkuCount]?: INumberInputGroupObject | null;
  [DrawerMenuKey.Weight]?: INumberInputGroupObject | null;
  [DrawerMenuKey.TBC]?: TbcRadioModelValue | null;
  [DrawerMenuKey.Insurance]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchMultiselectObject> | null;
  [DrawerMenuKey.WarehouseStatus]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchMultiselectObject> | null;
  [DrawerMenuKey.Pickups]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchPickupObject> | null;
  [DrawerMenuKey.Batches]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchBatchObject> | null;
  [DrawerMenuKey.Manifests]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchManifestObject> | null;
  [DrawerMenuKey.Boxes]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchBoxObject> | null;
  [DrawerMenuKey.DestinationCountry]?: ICountriesGroupObject | null;
  [DrawerMenuKey.DestinationState]?: ICountriesGroupObject | null;
  [DrawerMenuKey.Couriers]?: AdvancedSearchComponentApiNormalize.ICourierGroupObject | null;
  [DrawerMenuKey.Origins]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchMultiselectObject> | null;
  [DrawerMenuKey.Incoterms]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchIncotermsObject> | null;
  [DrawerMenuKey.ShipmentStatus]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStatusMessageObject> | null;
  [DrawerMenuKey.FulfillmentStatus]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStatusMessageObject> | null;
  [DrawerMenuKey.LabelStatus]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchMultiselectObject> | null;
  [DrawerMenuKey.StoresOrPlatform]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStorePlatformOption> | null;
  [DrawerMenuKey.ProductSKU]?: ICommaSeparatedGroupObject | null;
  [DrawerMenuKey.Tags]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchObject> | null;
  [DrawerMenuKey.EstimatedDeliveryDate]?: AdvancedSearchComponentApiNormalize.IDateInputGroupObject | null;
  [DrawerMenuKey.ItemDescription]?: IStringInputGroupObject | null;
  [DrawerMenuKey.CollectState]?: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchMultiselectObject> | null;
  [DrawerMenuKey.OrderNumber]?: ICommaSeparatedGroupObject | null;
  [DrawerMenuKey.ShipmentId]?: ICommaSeparatedGroupObject | null;
  [DrawerMenuKey.PickLocation]?: IStringInputGroupObject | null;
}

export interface INormalizeFiltersData {
  [NormalizeFilterKey.IsValidToShip]?: INormalizeBooleanData;
  [NormalizeFilterKey.CreatedAt]?: AdvancedSearchComponentApiNormalize.INormalizeDateData;
  [NormalizeFilterKey.OrderCreatedAt]?: AdvancedSearchComponentApiNormalize.INormalizeDateData;
  [NormalizeFilterKey.LabelPaidAt]?: AdvancedSearchComponentApiNormalize.INormalizeDateData;
  [NormalizeFilterKey.ShipmentItemsCount]?: INormalizeNumberData;
  [NormalizeFilterKey.SkuCount]?: INormalizeNumberData;
  [NormalizeFilterKey.Weight]?: INormalizeNumberData;
  [NormalizeFilterKey.IsReturn]?: INormalizeBooleanData;
  [NormalizeFilterKey.InsuranceScope]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.WarehouseState]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.PickupId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.BatchId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.ManifestId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.PackageId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.DestinationCountryId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.DestinationStateAbbreviation]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.CourierId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.OriginAddressId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.Incoterms]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.LastStatusMessageId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.StoreFulfillmentState]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  // TODO TBC the key name
  // [NormalizeFilterKey.LabelState]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData | null;
  [NormalizeFilterKey.StoreId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.PlatformName]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.Sku]?: INormalizeCommaSeparatedData;
  [NormalizeFilterKey.Tags]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.EtaDate]?: AdvancedSearchComponentApiNormalize.INormalizeDateData;
  [NormalizeFilterKey.ItemDescription]?: INormalizeStringData;
  [NormalizeFilterKey.CollectState]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.OrderNumber]?: INormalizeCommaSeparatedData;
  [NormalizeFilterKey.ShipmentId]?: INormalizeCommaSeparatedData;
  [NormalizeFilterKey.PickLocation]?: INormalizeStringData;

  [NormalizeFilterKey.MissingDimensions]?: INormalizeBooleanData;
  [NormalizeFilterKey.MissingParcelWeight]?: INormalizeNumberData;
  [NormalizeFilterKey.MissingItemDescription]?: INormalizeBooleanData;
  [NormalizeFilterKey.MissingItemCategoryId]?: INormalizeBooleanData;
  [NormalizeFilterKey.MissingItemDeclaredCustomsValue]?: INormalizeBooleanData;
  [NormalizeFilterKey.MissingShipmentItemsCount]?: INormalizeNumberData;

  [NormalizeFilterKey.MissingDestinationName]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.MissingPhoneNumber]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.MissingEmail]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.MissingPostalCode]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.MissingCity]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.MissingState]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.MissingAddress]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
  [NormalizeFilterKey.MissingReceiverTaxId]?: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData;
}

export interface IShipmentListPayloadFilter extends INormalizeFiltersData {
  keyword?: string;
}

export class ShipmentListNormalizeService {
  static $inject = ['CountryService', 'AdvancedSearchComponentApiNormalizeService'];
  constructor(
    public CountryService: ICountryService,
    private AdvancedSearchComponentApiNormalizeService: AdvancedSearchComponentApiNormalizeService
  ) {}

  normalizeFilterData(data: IShipmentListFiltersData): INormalizeFiltersData {
    const normalizeData: INormalizeFiltersData = {};

    if (data.eta_date) {
      const etaDate = angular.copy(data.eta_date);
      normalizeData.eta_date =
        this.AdvancedSearchComponentApiNormalizeService.normalizeDate(etaDate);
    }

    if (data.order_status) {
      normalizeData.is_valid_to_ship = {
        type: NormalizeMultiselectAttr.Boolean,
        operator: EqualityModelValue.EqualTo,
        values: [this.normalizeOrderStatus(angular.copy(data.order_status))],
      };
    }

    if (data.missing_field) {
      const selectedKeys: NormalizeFilterKey[] =
        data.missing_field.options?.reduce((groupAgg: NormalizeFilterKey[], groupCur) => {
          return groupAgg.concat(
            groupCur.options?.reduce((fieldAgg: NormalizeFilterKey[], fieldCur) => {
              if (fieldCur.selected && fieldCur.value) {
                fieldAgg.push(fieldCur.value as NormalizeFilterKey);
              }
              return fieldAgg;
            }, []) || []
          );
        }, []) || [];

      const typeBooleanSelectedValue = {
        type: 'boolean' as NormalizeMultiselectAttr.Boolean,
        operator: EqualityModelValue.EqualTo,
        values: [true] as [boolean | ''],
        or_group: FilterOrGroupId.MissingFields,
      };

      const typeNumberSelectedValue = {
        type: 'number' as NormalizeMultiselectAttr.Number,
        operator: NumberOfItemsInputOptionsValue.EqualTo,
        values: [0] as number[],
        or_group: FilterOrGroupId.MissingFields,
      };

      const typeMultiSelectSelectedValue = {
        type: 'multi_select' as NormalizeMultiselectAttr.MultiSelect,
        operator: MultiselectComponentModelValue.Unknown,
        values: [] as [],
        or_group: FilterOrGroupId.MissingFields,
      };

      if (selectedKeys.includes(NormalizeFilterKey.MissingDimensions)) {
        normalizeData[NormalizeFilterKey.MissingDimensions] = typeBooleanSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingParcelWeight)) {
        normalizeData[NormalizeFilterKey.MissingParcelWeight] = typeNumberSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingItemDescription)) {
        normalizeData[NormalizeFilterKey.MissingItemDescription] = typeBooleanSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingItemCategoryId)) {
        normalizeData[NormalizeFilterKey.MissingItemCategoryId] = typeBooleanSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingItemDeclaredCustomsValue)) {
        normalizeData[NormalizeFilterKey.MissingItemDeclaredCustomsValue] =
          typeBooleanSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingShipmentItemsCount)) {
        normalizeData[NormalizeFilterKey.MissingShipmentItemsCount] = typeNumberSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingDestinationName)) {
        normalizeData[NormalizeFilterKey.MissingDestinationName] = typeMultiSelectSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingPhoneNumber)) {
        normalizeData[NormalizeFilterKey.MissingPhoneNumber] = typeMultiSelectSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingEmail)) {
        normalizeData[NormalizeFilterKey.MissingEmail] = typeMultiSelectSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingPostalCode)) {
        normalizeData[NormalizeFilterKey.MissingPostalCode] = typeMultiSelectSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingCity)) {
        normalizeData[NormalizeFilterKey.MissingCity] = typeMultiSelectSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingState)) {
        normalizeData[NormalizeFilterKey.MissingState] = typeMultiSelectSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingAddress)) {
        normalizeData[NormalizeFilterKey.MissingAddress] = typeMultiSelectSelectedValue;
      }

      if (selectedKeys.includes(NormalizeFilterKey.MissingReceiverTaxId)) {
        normalizeData[NormalizeFilterKey.MissingReceiverTaxId] = typeMultiSelectSelectedValue;
      }
    }

    if (data.creation_date) {
      const creationDate = angular.copy(data.creation_date);
      normalizeData.created_at =
        this.AdvancedSearchComponentApiNormalizeService.normalizeDate(creationDate);
    }

    if (data.order_placed_date) {
      const orderPlacedDate = angular.copy(data.order_placed_date);
      normalizeData.order_created_at =
        this.AdvancedSearchComponentApiNormalizeService.normalizeDate(orderPlacedDate);
    }

    if (data.label_payment_date) {
      const labelPaymentDate = angular.copy(data.label_payment_date);
      normalizeData.label_paid_at =
        this.AdvancedSearchComponentApiNormalizeService.normalizeDate(labelPaymentDate);
    }

    if (data.number_of_items) {
      const numberOfItems = angular.copy(data.number_of_items);
      normalizeData.shipment_items_count = this.formatNumberObject(numberOfItems);
    }

    if (data.total_actual_weight) {
      const totalActualWeight = angular.copy(data.total_actual_weight);
      normalizeData.total_actual_weight = this.formatNumberObject(totalActualWeight);
    }

    if (data.unique_sku_count) {
      const skuCount = angular.copy(data.unique_sku_count);
      normalizeData.unique_sku_count = this.formatNumberObject(skuCount);
    }

    if (data.tbc) {
      normalizeData.is_return = {
        type: NormalizeMultiselectAttr.Boolean,
        operator: EqualityModelValue.EqualTo,
        values: [this.normalizeTBC(angular.copy(data.tbc))],
      };
    }

    if (data.insurance) {
      const insurance = angular.copy(data.insurance);
      normalizeData.insurance_scope = this.formatMultiselectObject(insurance);
    }

    if (data.warehouse_status) {
      const warehouseStatus = angular.copy(data.warehouse_status);
      normalizeData.warehouse_state = this.formatMultiselectObject(warehouseStatus);
    }

    if (data.pickups) {
      const pickups = angular.copy(data.pickups);
      normalizeData.pickup_id = this.formatMultiselectObject(
        pickups,
        NormalizeMultiselectAttr.PickupId
      );
    }

    if (data.batches) {
      const batches = angular.copy(data.batches);
      normalizeData.batch_id = this.formatMultiselectObject(batches, NormalizeMultiselectAttr.Id);
    }

    if (data.manifests) {
      const manifests = angular.copy(data.manifests);
      normalizeData.manifest_id = this.formatMultiselectObject(
        manifests,
        NormalizeMultiselectAttr.Id
      );
    }

    if (data.boxes) {
      const boxes = angular.copy(data.boxes);
      normalizeData.package_id = this.formatMultiselectObject(boxes, NormalizeMultiselectAttr.Id);
    }

    if (data.countries) {
      const countriesData = angular.copy(data.countries);
      const { radio, options } = countriesData;
      const countries: number[] = [];
      if (options) {
        options.forEach((alpha) => {
          const country = this.CountryService.findCountryByAlpha2(alpha);
          if (country) {
            countries.push(country.id);
          }
        });
      }

      normalizeData.destination_country_id = {
        type: NormalizeMultiselectAttr.MultiSelect,
        operator: radio,
        values: countries,
      };
    }

    if (data.states) {
      const { radio, options } = data.states;

      normalizeData.country_state_abbr = {
        type: NormalizeMultiselectAttr.MultiSelect,
        operator: radio,
        values: options,
      };
    }

    if (data.couriers) {
      normalizeData.courier_id = this.AdvancedSearchComponentApiNormalizeService.normalizeCourier(
        data.couriers
      );
    }

    if (data.origins) {
      normalizeData[NormalizeFilterKey.OriginAddressId] = {
        type: NormalizeMultiselectAttr.MultiSelect,
        operator: data.origins.radio,
        values: this.AdvancedSearchComponentApiNormalizeService.normalizeMultiselectData(
          data.origins.options,
          'id'
        ),
      };
    }

    if (data.effective_incoterms) {
      const effectiveIncoterms = angular.copy(data.effective_incoterms);
      normalizeData.incoterms_scope = this.formatMultiselectObject(effectiveIncoterms);
    }

    if (data.shipment_status) {
      const shipmentStatus = angular.copy(data.shipment_status);

      normalizeData.last_status_message_id = this.formatMultiselectObject(
        shipmentStatus,
        NormalizeMultiselectAttr.Id
      );

      const messageId = normalizeData.last_status_message_id;

      if (messageId && messageId.values && messageId.values.length > 0) {
        normalizeData.last_status_message_id.values = messageId.values
          .join(',')
          .split(',')
          .map((id) => (Number.isNaN(Number(id)) ? id : Number(id)));
      }
    }

    if (data.store_state) {
      const storeStates = angular.copy(data.store_state);
      normalizeData.store_state = this.formatMultiselectObject(storeStates);
    }

    if (data.label_status) {
      // TODO Implement later when data is confirmed
      // const labelStatus = angular.copy(data.label_status);
      // normalizeData.label_state = this.formatMultiselectObject(
      //   labelStatus,
      //   NormalizeMultiselectAttr.Value
      // );
    }

    if (data.store_platform) {
      const storePlatform = angular.copy(data.store_platform);
      const storeData: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStorePlatformOption> =
        angular.copy(this.returnStorePlatformMultiSelectData(storePlatform));
      const platformData: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStorePlatformOption> =
        angular.copy(this.returnStorePlatformMultiSelectData(storePlatform));

      if (storePlatform.options) {
        const { options } = storePlatform;

        options.forEach((storePlatformOption) => {
          if (storePlatformOption.display === MultiSelectGroupKey.YourStores && storeData.options) {
            storeData.options.push(angular.copy(storePlatformOption));
          }
          if (
            storePlatformOption.display === MultiSelectGroupKey.Platforms &&
            platformData.options
          ) {
            platformData.options.push(angular.copy(storePlatformOption));
          }
        });
      }

      const storeId = this.formatMultiselectObject(storeData, NormalizeMultiselectAttr.Id);
      if (storeId.values && storeId.values.length > 0) {
        normalizeData.store_id = storeId;
      }

      const platformName = this.formatMultiselectObject(platformData, NormalizeMultiselectAttr.Id);
      if (platformName.values && platformName.values.length > 0) {
        normalizeData.platform_name = platformName;
      }
    }

    if (data.product_sku) {
      const productSku = angular.copy(data.product_sku);
      const { select, tags } = productSku;

      if (tags.length > 0) {
        normalizeData.sku = {
          type: NormalizeMultiselectAttr.MultiSelect,
          operator: select,
          values: tags,
        };
      }
    }

    if (data.order_number) {
      const orderNumber = angular.copy(data.order_number);
      const { select, tags } = orderNumber;

      if (tags.length > 0) {
        normalizeData.order_number = {
          type: NormalizeMultiselectAttr.MultiSelect,
          operator: select,
          values: tags,
        };
      }
    }

    if (data.easyship_shipment_id) {
      const shipmentId = angular.copy(data.easyship_shipment_id);
      const { select, tags } = shipmentId;

      if (tags.length > 0) {
        normalizeData.easyship_shipment_id = {
          type: NormalizeMultiselectAttr.MultiSelect,
          operator: select,
          values: tags,
        };
      }
    }

    if (data.tags) {
      const tags = angular.copy(data.tags);
      normalizeData.order_tags = this.formatMultiselectObject(tags, NormalizeMultiselectAttr.Value);
    }

    if (data.item_description) {
      const { select, input } = data.item_description;

      normalizeData.item_description = {
        type: NormalizeMultiselectAttr.String,
        operator: select,
        values: [input],
      };
    }

    if (data.collect_state) {
      const collect_state = angular.copy(data.collect_state);
      normalizeData.collect_state = this.formatMultiselectObject(collect_state);
    }

    if (data.order_number) {
      const orderNumber = angular.copy(data.order_number);
      const { select, tags } = orderNumber;

      if (tags.length > 0) {
        normalizeData.order_number = {
          type: NormalizeMultiselectAttr.MultiSelect,
          operator: select,
          values: tags,
        };
      }
    }

    if (data.pick_location) {
      const { select, input } = data.pick_location;

      normalizeData.pick_location = {
        type: NormalizeMultiselectAttr.String,
        operator: select,
        values: [input],
      };
    }

    return normalizeData;
  }

  prepareStorePlatFormData(
    res: [{ stores: IStore[] }, IPlatformGroup[]],
    storePlatformId?: string
  ): (IStore | IStorePlatformOption)[] {
    return [
      ...res[0].stores.map((store) => ({
        ...store,
        selected: storePlatformId === store.id,
        group: MultiSelectGroupKey.YourStores,
      })),
      ...res[1].map((platform) => {
        return {
          id: platform.display,
          name: platform.display,
          platform: {
            css_class: platform.css_class,
          },
          group: MultiSelectGroupKey.Platforms,
          selected: storePlatformId === platform.display,
        };
      }),
    ];
  }

  static injectLogoUrlToCourierObject(couriers: ICouriersGroupedArray[]): ICouriersGroupedArray[] {
    return couriers.map((courier) => {
      const modifyCourier = courier;
      if (courier.options) {
        modifyCourier.logo_url = courier.options[0].logo_url;
      }

      return modifyCourier;
    });
  }

  private normalizeOrderStatus(status: OrderStatusRadioModelValue): '' | boolean {
    switch (status) {
      case OrderStatusRadioModelValue.ReadyToShipped:
        return true;
      case OrderStatusRadioModelValue.NotReadyToShipped:
        return false;
      case OrderStatusRadioModelValue.AllShipments:
      default:
        return '';
    }
  }

  private formatNumberObject(data: INumberInputGroupObject): INormalizeNumberData {
    const copyData = angular.copy(data);
    const { select, from, to, equal } = copyData;
    const normalizeNumber: INormalizeNumberData = {
      type: NormalizeMultiselectAttr.Number,
      operator: select,
      values: [],
    };

    if (from || from === 0) {
      normalizeNumber.values.push(from);
    }

    if (to || to === 0) {
      normalizeNumber.values.push(to);
    }

    if (equal || equal === 0) {
      normalizeNumber.values.push(equal);
    }

    return normalizeNumber;
  }

  private normalizeTBC(status: TbcRadioModelValue): '' | boolean {
    switch (status) {
      case TbcRadioModelValue.Return:
        return true;
      case TbcRadioModelValue.Shipment:
        return false;
      case TbcRadioModelValue.All:
      default:
        return '';
    }
  }

  private formatMultiselectObject<T>(
    data: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<T>,
    attr?: NormalizeMultiselectAttr
  ): AdvancedSearchComponentApiNormalize.INormalizeMultiselectData {
    const copyData = angular.copy(data);
    const { radio, options } = copyData;
    const normalizeData: AdvancedSearchComponentApiNormalize.INormalizeMultiselectData = {
      type: NormalizeMultiselectAttr.MultiSelect,
      operator: radio,
      values: [],
    };

    if (
      options &&
      radio !== MultiselectComponentModelValue.Known &&
      radio !== MultiselectComponentModelValue.Unknown
    ) {
      normalizeData.values =
        this.AdvancedSearchComponentApiNormalizeService.normalizeMultiselectData(options, attr);
    }

    return normalizeData;
  }

  private returnStorePlatformMultiSelectData(
    data: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStorePlatformOption>
  ): AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchStorePlatformOption> {
    return {
      input: data.input,
      radio: data.radio,
      options: [],
    };
  }
}
