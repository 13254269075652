import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export function ChevronDown(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <rect
        id="_20x20"
        data-name="20x20"
        width="20"
        height="20"
        transform="translate(20 20) rotate(180)"
        fill="none"
      />
      <path
        d="M12.107,6.847a.522.522,0,0,0,.74-.737L6.87.153a.524.524,0,0,0-.74,0L.153,6.11a.522.522,0,1,0,.74.737L6.5,1.414Z"
        transform="translate(16.5 13.5) rotate(180)"
        fill="#919eab"
      />
    </SvgIcon>
  );
}
