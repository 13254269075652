User.$inject = ['$resource', 'API'];
function User($resource, API) {
  const endpointUrl = API.endpoint;

  function getToken(req) {
    const { token } = req.params;
    delete req.params.token;
    return token;
  }

  return $resource(
    `${endpointUrl}/users/:id/:controller`,
    {
      id: '@_id',
    },
    {
      fetch: {
        method: 'GET',
      },
      getCurrent: {
        method: 'GET',
        params: {
          controller: 'get_current_user',
        },
      },
      create: {
        method: 'POST',
      },
      confirmEmail: {
        method: 'POST',
        params: {
          controller: 'confirm',
        },
      },
      sendConfirm: {
        method: 'POST',
        params: {
          controller: 'send_confirmation_email',
        },
      },
      resetPassword: {
        method: 'PATCH',
        headers: { 'recaptcha-token': getToken },
        params: {
          controller: 'password_reset',
        },
      },
      checkResetToken: {
        method: 'GET',
        params: {
          controller: 'check_password_reset_token',
          reset_password_token: '@reset_password_token',
        },
      },
      setNewPassword: {
        method: 'PATCH',
        params: {
          controller: 'password_set_new',
        },
      },
      updatePassword: {
        method: 'PATCH',
        params: {
          controller: 'password_update',
        },
      },
      update: {
        method: 'PATCH',
      },
      signup: {
        method: 'POST',
        params: {
          controller: 'signup',
        },
      },
      scheduleCall: {
        method: 'POST',
        params: {
          controller: 'schedule_call',
        },
      },
      updateFlowRecords: {
        method: 'POST',
        params: {
          controller: 'flow_records',
        },
      },
      getNotificationSettings: {
        method: 'GET',
        params: {
          controller: 'notification_settings',
        },
      },
      getHubspotVisitorToken: {
        method: 'GET',
        params: {
          controller: 'hubspot_visitor_token',
        },
      },
      validateEmail: {
        method: 'POST',
        params: {
          controller: 'email_validation',
        },
      },
    }
  );
}

export { User };
