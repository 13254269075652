import template from './drawer.html?raw';
import style from './drawer.module.scss';

class Drawer {
  static $inject = ['$transclude'];

  constructor($transclude) {
    this.style = style;
    this.$transclude = $transclude;
  }

  $onInit() {
    this.isTitleFilled = this.$transclude.isSlotFilled('title');
  }
}

const DrawerComponent = {
  template,
  controller: Drawer,
  transclude: {
    title: '?drawerTitle',
  },
  bindings: {
    esOpen: '<',
    esDim: '<',
    esIsTransitionBar: '<',
    esIsFullHeight: '<',
    esPadding: '<',
    onClose: '&',
  },
};

export { DrawerComponent };
