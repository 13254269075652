import template from './address-form-default.html?raw';

(function () {
  'use strict';

  var addressFormDefault = {
    template,
    bindings: {
      address: '=',
      fieldValidators: '<',
    },
    controller: 'addressFormDefaultCtrl as ctrl',
  };

  angular
    .module('app.component.address-form-default')
    .component('addressFormDefault', addressFormDefault);
})();
