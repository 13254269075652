import { IUserSession } from 'typings/user-session';

import {
  ShipmentListSectionName,
  isShipmentListSectionName,
} from '@client/src/manage-shipments/constants';
import { UserRight } from './type';

const SHIPMENT_LIST_SECTION_NAME_AND_USER_RIGHT_MAP: Record<ShipmentListSectionName, UserRight> = {
  'shipments-all': 'menu.manageShipments.all',
  pending: 'menu.manageShipments.pending',
  rejected: 'menu.manageShipments.rejected',
  to_download: 'menu.manageShipments.toDownload',
};

class UserRightsService {
  static $inject = ['UserSession', '$state'];
  constructor(private UserSession: IUserSession, private $state: ng.ui.IStateService) {}

  get canCreateBox(): boolean {
    return this.UserSession.isAllowedByUserRight('box.create');
  }

  get canPurchaseLabel(): boolean {
    return this.UserSession.isAllowedByUserRight('shipments.confirmLabel');
  }

  get canCreateShipmentByUpload(): boolean {
    return this.UserSession.isAllowedByUserRight('shipments.uploadCsv');
  }

  get canCreateShipmentBySync(): boolean {
    return this.UserSession.isAllowedByUserRight('shipments.syncOrders');
  }

  get canCreateShippingRule(): boolean {
    return this.UserSession.isAllowedByUserRight('automation.saveAutomation');
  }

  get canCreateProduct(): boolean {
    return this.UserSession.isAllowedByUserRight('products.create');
  }

  get canCheckout(): boolean {
    return this.UserSession.isAllowedByUserRight('checkout.enable');
  }

  get canCreateReturnLabel(): boolean {
    return this.UserSession.isAllowedByUserRight('returns.create');
  }

  get canSaveFilterView(): boolean {
    return this.UserSession.isAllowedByUserRight('views.create');
  }

  get canDeleteBox(): boolean {
    return this.UserSession.isAllowedByUserRight('box.delete');
  }

  get canEditBox(): boolean {
    return this.UserSession.isAllowedByUserRight('box.edit');
  }

  get canEditSyncFrequency(): boolean {
    return this.UserSession.isAllowedByUserRight('store.editSyncSettings');
  }

  get canDeactivateRatesAtCheckout(): boolean {
    return this.UserSession.isAllowedByUserRight('checkout.disable');
  }

  get canEditRatesAtCheckout(): boolean {
    return this.UserSession.isAllowedByUserRight('checkout.editSettings');
  }

  get canEditTrackingPage(): boolean {
    return this.UserSession.isAllowedByUserRight('trackingPage.editSettings');
  }

  get canEditTrackingEmail(): boolean {
    return this.UserSession.isAllowedByUserRight('trackingEmail.editSettings');
  }

  get canUpdateFilterView(): boolean {
    return this.UserSession.isAllowedByUserRight('views.update');
  }

  get canDeleteProduct(): boolean {
    return this.UserSession.isAllowedByUserRight('products.delete');
  }

  get canEditPackingSlipSetting(): boolean {
    return this.UserSession.isAllowedByUserRight('packingSlip.editSettings');
  }

  get canDeleteAutomation(): boolean {
    return this.UserSession.isAllowedByUserRight('automation.deleteAutomation');
  }

  get canAddCredit(): boolean {
    return this.UserSession.isAllowedByUserRight('payments.addCredit');
  }

  get canRefundCredit(): boolean {
    return this.UserSession.isAllowedByUserRight('payments.refundCredit');
  }

  get canGeneratePickList(): boolean {
    return this.UserSession.isAllowedByUserRight('pickList.generate');
  }

  get canAddPaymentMethod(): boolean {
    return this.UserSession.isAllowedByUserRight('payments.addPaymentMethod');
  }

  get canEditProduct(): boolean {
    return this.UserSession.isAllowedByUserRight('products.edit');
  }

  get fallbackRedirectPage() {
    // if a user lacks the necessary permissions to access a particular page,
    // they will be redirected to alternative pages in the following sequence
    const FALLBACK_PAGE_SEQUENCE: [string, any][] = [
      ['app.home', null],
      ['app.multiple', null],
      ['app.shipments', { sectionName: 'shipments-all' }],
    ];

    for (let i = 0; i < FALLBACK_PAGE_SEQUENCE.length; i++) {
      const [stateName, stateParams] = FALLBACK_PAGE_SEQUENCE[i];
      if (this.canNavigateTo(stateName, stateParams)) {
        return stateName;
      }
    }

    // Normally, all users should be able to reach the profile page
    return 'app.account.profile';
  }

  canNavigateTo(stateName: string, stateParams?: any): boolean {
    const stateDeclaration = this.$state.get(stateName);

    let requiredUserRight: UserRight | undefined;
    if (stateName === 'app.shipments') {
      requiredUserRight = this.getShipmentListSectionUserRight(stateParams);
    } else {
      requiredUserRight = stateDeclaration.data?.requiredUserRight;
    }

    if (!requiredUserRight) {
      return true;
    }

    return !!this.UserSession.getUserRights()[requiredUserRight];
  }

  // NOTE: The 'manage shipment' pages uses the `sectionName` to decide which shipment list to display.
  // Therefore, its logic for determining the requiredUserRight is different.
  private getShipmentListSectionUserRight(stateParams?: any): UserRight | undefined {
    const sectionName = stateParams?.sectionName;

    if (!isShipmentListSectionName(sectionName)) {
      return undefined;
    }

    return SHIPMENT_LIST_SECTION_NAME_AND_USER_RIGHT_MAP[sectionName];
  }
}

export { UserRightsService };
