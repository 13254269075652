import { IComponentController } from 'angular';
import style from './addressy-toggle.module.scss';
import template from './addressy-toggle.html?raw';

class AddressyToggleController implements IComponentController {
  style = style;
  esId = 'addressy-toggle';
}

const AddressyToggleComponent: ng.IComponentOptions = {
  controller: AddressyToggleController,
  template,
  bindings: {
    esId: '<',
    esUsingAddressy: '<',
    esChange: '&',
  },
};

export { AddressyToggleComponent };
