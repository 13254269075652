import { Persona } from '@client/data/persona';
import { NoPlatform, OtherCases } from '@client/data/platform';
import { WelcomeAction, WelcomeActions } from '@client/data/welcome-action';
import { IWelcomeAction } from 'typings/dashboard/components/welcome-action';
import { IStore, IStoreService } from 'typings/store';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { isPresent } from '@client/src/utils/type-guards';
import template from './get-started-actions.html?raw';
import style from './get-started-actions.module.scss';
import { CallToActionVariant } from './types';

const INVITEE_ACTIONS = [
  WelcomeAction.CreateShipment,
  WelcomeAction.CheckStatements,
  WelcomeAction.ManageShipment,
];

const CROWDFUNDING_ACTIONS = [
  WelcomeAction.ImportOrders,
  WelcomeAction.ImportProducts,
  WelcomeAction.GetQuote,
];
class GetStartedActions implements IComponentController {
  style = style;
  esInvitee = false;

  busy = {
    gettingStores: false,
  };
  translations: angular.translate.ITranslationTable = {};
  persona: Persona;
  platformKey: string | null;
  firstPlatform: string | null;
  isHighVolumeCompany: boolean;
  suggestedActions: (IWelcomeAction | undefined)[] = [];
  showBookMeetingCTA: boolean;
  stores: IStore[];

  static $inject = [
    '$rootScope',
    '$translate',
    'UserSession',
    'MixpanelService',
    'StoreService',
    '$state',
  ];
  constructor(
    private $rootScope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService,
    private StoreService: IStoreService,
    private $state: ng.ui.IStateService
  ) {
    this.platformKey =
      this.UserSession.company.marketing_settings?.platforms &&
      this.UserSession.company.marketing_settings.platforms.length > 0
        ? this.UserSession.company.marketing_settings.platforms[0]
        : null;
    if ([NoPlatform, OtherCases.None, OtherCases.Skip].includes(this.platformKey as string)) {
      this.platformKey = null;
    }

    this.persona = UserSession.getUserPersona();

    this.stores = [];

    this.isHighVolumeCompany = UserSession.isHighVolumeCompany();
    this.firstPlatform = this.platformKey
      ? this.platformKey
          .split(/[\s-]/)
          .map((wrd) => wrd.charAt(0).toUpperCase() + wrd.substr(1).toLowerCase())
          .join(' ')
      : 'eCommerce';

    this.showBookMeetingCTA = UserSession.showBookMeetingCTA();
    this._filterBookMeetingAction = this._filterBookMeetingAction.bind(this);
  }

  $onInit(): void {
    this.$translate(['welcome']).then((translations: angular.translate.ITranslationTable) => {
      this.translations = translations;
    });

    this.busy.gettingStores = true;
    this.StoreService.getStores()
      .then(({ stores }) => {
        this.stores = stores;
        this.generateSuggestActions();
      })
      .finally(() => {
        this.busy.gettingStores = false;
      });

    this.$rootScope.$on('onboarding-updated', this.generateSuggestActions);
  }

  get apiAccessTokenAction(): IWelcomeAction | undefined {
    return WelcomeActions.find((action) => action.key === WelcomeAction.GetApiAccessToken);
  }

  get callToActionVariant(): CallToActionVariant | null {
    switch (this.persona) {
      case Persona.Merchant:
        return 'chat';
      case Persona.Crowdfunding:
      case Persona.Kickstarter:
      case Persona.Indiegogo:
        return 'chat-crowdfunding';

      default:
        return null;
    }
  }

  get applyFourColumnsLayout(): boolean {
    return this.suggestedActions.length <= 4 && this.callToActionVariant === null;
  }

  handleRedirectSingleStore(): void {
    this.$state.go('app.single-store', { id: this.stores[0].id });
    this.reportClick(WelcomeAction.GetApiAccessToken);
  }

  getInviteeActionsByTeamPermission(): WelcomeAction[] {
    const hasBetaKeyTeamPermission =
      this.UserSession.getCompanyDashboardSettings().beta_feature_team_rights_and_permissions;

    if (hasBetaKeyTeamPermission && !this.UserSession.hasUserRole('subscription_and_billing')) {
      return INVITEE_ACTIONS.filter((action) => action !== WelcomeAction.CheckStatements);
    }
    return INVITEE_ACTIONS;
  }

  suggestActions(): WelcomeAction[] {
    if (this.UserSession.isKickstarter() || this.UserSession.isIndiegogo())
      return CROWDFUNDING_ACTIONS;

    let actions: WelcomeAction[] = [];

    const hasStore = this.StoreService.stores.length > 0;

    switch (this.persona) {
      case Persona.Merchant:
        // A store has already been connected
        if (hasStore) {
          actions = [
            WelcomeAction.SyncOrders,
            WelcomeAction.GetQuote,
            WelcomeAction.ConnectCourier,
            WelcomeAction.BookMeeting,
          ];
          break;
        }

        // ENTERPRISE
        if (this.isHighVolumeCompany) {
          actions = [
            WelcomeAction.ConnectStore,
            WelcomeAction.CreateShipment,
            WelcomeAction.ConnectCourier,
          ];
          break;
        }

        // STORE
        if (this.platformKey) {
          actions = [
            WelcomeAction.ConnectStore,
            WelcomeAction.CreateShipment,
            WelcomeAction.ConnectCourier,
            WelcomeAction.BookMeeting,
          ];
          break;
        }

        // SME
        actions = [
          WelcomeAction.CreateShipment,
          WelcomeAction.ConnectCourier,
          WelcomeAction.GetQuote,
          WelcomeAction.BookMeeting,
        ];
        break;

      case Persona.Crowdfunding:
        actions = CROWDFUNDING_ACTIONS;
        break;

      case Persona.Developer:
        actions = [
          WelcomeAction.CreateApi,
          WelcomeAction.CreateWebhook,
          WelcomeAction.ConnectStore,
          WelcomeAction.BookMeeting,
        ];
        break;

      case Persona.Personal:
        actions = [WelcomeAction.CreateShipment, WelcomeAction.GetQuote, WelcomeAction.BookMeeting];
        break;

      case Persona.Duda:
        actions = [WelcomeAction.AddPaymentMethod, WelcomeAction.AddABox];
        break;

      default:
        if (hasStore) {
          actions = [
            WelcomeAction.SyncOrders,
            WelcomeAction.GetQuote,
            WelcomeAction.ConnectCourier,
            WelcomeAction.BookMeeting,
          ];
          break;
        }

        actions = this.getInviteeActionsByTeamPermission();
    }

    return actions.filter(this._filterBookMeetingAction);
  }

  generateSuggestActions(): void {
    const suggestedKeys = this.suggestActions();
    this.suggestedActions = suggestedKeys
      .map((suggestedKey) => {
        const action = WelcomeActions.filter((action) => {
          if (!action.requiredUserRole) return true;
          return this.UserSession.hasUserRole(action.requiredUserRole);
        }).find(({ key }) => key === suggestedKey);

        if (!action) return undefined;

        if (action.key === WelcomeAction.GetApiAccessToken) {
          const storeSref = `app.single-store({id: ${this.stores[0].id}})`;
          action.sref = storeSref;
        }

        action.sref = action.sref.replace(
          /platform:"shopify"/,
          `platform:"${this.platformKey || 'eCommerce'}"`
        );

        return action;
      })
      .filter(isPresent);
  }

  reportClick(actionKey: string): void {
    if (actionKey === WelcomeAction.BookMeeting) {
      this.MixpanelService.track('Book Onboarding Meeting - Click', { origin: 'home_page' });
      this.UserSession.openOnboardingMeetingLink();
    }
  }

  _filterBookMeetingAction(action: WelcomeAction): boolean {
    if (!this.showBookMeetingCTA && action === WelcomeAction.BookMeeting) {
      return false;
    }

    return true;
  }
}

const GetStartedActionsComponent: ng.IComponentOptions = {
  controller: GetStartedActions,
  template,
  bindings: {
    esInvitee: '<',
    esSuggestedActions: '<',
  },
};

export { GetStartedActionsComponent };
