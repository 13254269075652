import angular from 'angular';

import { AdjustmentsService } from './adjustments.service';
import { AdjustmentsResource } from './adjustments.resource';

const MODULE_NAME = 'app.global.services.adjustments';

angular
  .module(MODULE_NAME, [])
  .service('AdjustmentsService', AdjustmentsService)
  .service('AdjustmentsResource', AdjustmentsResource);

export default MODULE_NAME;
