import template from './split-modal-item.html?raw';
import style from './split-modal-item.module.scss';

class SplitModalItem {
  constructor() {
    this.style = style;
  }
}

const SplitModalItemComponent = {
  template,
  controller: SplitModalItem,
  bindings: {
    item: '<',
    ngChange: '&',
    ngModel: '<',
  },
};

export { SplitModalItemComponent };
