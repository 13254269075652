import angular, { IComponentController, IFormController } from 'angular';
import { IAuthService } from 'typings/core/services/auth';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './change-password-modal.module.scss';
import template from './change-password-modal.html?raw';

type FormField = 'password' | 'oldPassword';

class ChangePasswordModal implements IComponentController {
  style = style;
  formData = { password: '', oldPassword: '' };
  error = '';
  translations: Record<string, string> = {};
  loading = false;
  form: IFormController | undefined;

  static $inject = ['Auth', 'MixpanelService', '$translate'];
  constructor(
    private Auth: IAuthService,
    private MixpanelService: MixpanelService,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit() {
    this.$translate(['profile.update-password-failed']).then((translations) => {
      this.translations = translations;
    });
  }

  esClose(): void {
    this.esClose();
  }

  onInputChange(value: string, key: FormField) {
    this.formData[key] = value;
  }

  onSubmit() {
    if (this.form?.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.loading = true;

    this.Auth.updatePassword(this.formData)
      .then(() => {
        toastSuccess(this.$translate.instant('profile.update-password-success'));
        this.error = '';
        this.MixpanelService.track('Account - Profile - Password');
        this.esClose();
      })
      .catch((reason) => {
        this.error =
          (reason.data && reason.data.status) ||
          this.translations['profile.update-password-failed'];
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

const ChangePasswordModalComponent: ng.IComponentOptions = {
  controller: ChangePasswordModal,
  template,
  bindings: {
    esClose: '&',
  },
};

export { ChangePasswordModalComponent };
