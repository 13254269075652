import { AdvancedSearchComponentApiNormalize } from 'typings/advanced-search-component-api-normalize';
import { NormalizeMultiselectAttr } from '@client/src/global/services/shipment-list/data/normalize';
import {
  IAdvancedSearchAddressObject,
  IAdvancedSearchCourierObject,
  IAdvancedSearchPickupHandlersObject,
} from 'typings/advanced-search';
import { IGroupedArray } from 'typings/helper';

class AdvancedSearchComponentApiNormalizeService {
  normalizeCourier(
    couriers: AdvancedSearchComponentApiNormalize.ICourierGroupObject,
    attr = NormalizeMultiselectAttr.Id
  ): AdvancedSearchComponentApiNormalize.INormalizeMultiselectData {
    const { radio, easyshipCouriers, companyCouriers } = couriers;
    const easyshipCouriersIds = this.normalizeMultiselectData(easyshipCouriers, attr) || [];
    const companyCouriersIds = this.normalizeMultiselectData(companyCouriers, attr) || [];
    const courier_ids: string[] = easyshipCouriersIds
      .concat(companyCouriersIds)
      .reduce((ids: string[], joinedIds: string) => ids.concat(joinedIds.split(',')), []);

    return {
      type: NormalizeMultiselectAttr.MultiSelect,
      operator: radio,
      values: courier_ids,
    };
  }

  normalizeHandler(
    handler: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<
      IAdvancedSearchPickupHandlersObject | IAdvancedSearchCourierObject
    >
  ): AdvancedSearchComponentApiNormalize.INormalizeMultiselectData {
    const { radio, options } = handler;

    return {
      type: NormalizeMultiselectAttr.MultiSelect,
      operator: radio,
      values: this.normalizeMultiselectData(options, 'handler_name'),
    };
  }

  normalizeDate(
    date: AdvancedSearchComponentApiNormalize.IDateInputGroupObject
  ): AdvancedSearchComponentApiNormalize.INormalizeDateData {
    const { select, fromDate, toDate, daysAgo, daysAhead, onDate } = date;

    const normalizeDate: AdvancedSearchComponentApiNormalize.INormalizeDateData = {
      type: NormalizeMultiselectAttr.Date,
      operator: select,
      values: [],
    };

    if (fromDate) {
      normalizeDate.values.push(fromDate);
    }

    if (toDate) {
      normalizeDate.values.push(toDate);
    }

    if (daysAgo) {
      normalizeDate.values.push(daysAgo);
    }

    if (daysAhead) {
      normalizeDate.values.push(daysAhead);
    }

    if (onDate) {
      normalizeDate.values.push(onDate);
    }

    return normalizeDate;
  }

  normalizeAddress(
    address: AdvancedSearchComponentApiNormalize.IMultiSelectGroupObject<IAdvancedSearchAddressObject>
  ): AdvancedSearchComponentApiNormalize.INormalizeMultiselectData {
    const { radio, options } = address;

    return {
      type: NormalizeMultiselectAttr.MultiSelect,
      operator: radio,
      values: this.normalizeMultiselectData(options, 'id'),
    };
  }

  normalizeMultiselectData<T>(
    groupedArray: IGroupedArray<T>[] | null,
    attr?: string
  ): string[] | null {
    if (!groupedArray) {
      return null;
    }

    const dataArray: string[] = [];

    groupedArray.forEach((group: any) => {
      if (!group.options) {
        if (group.selected) {
          if (Array.isArray(group.value)) {
            group.value.forEach((element: any) => {
              dataArray.push(element);
            });
          } else {
            dataArray.push(group.value);
          }
        }
      } else {
        group.options.forEach((option: any) => {
          if (option.selected) {
            dataArray.push(attr ? option[attr] : option.value);
          }
        });
      }
    });

    return dataArray;
  }
}

export { AdvancedSearchComponentApiNormalizeService };
