/* eslint-disable max-classes-per-file */
import template from './resend-emails-modal.html?raw';

class ResendEmailsModalCtrl {
  static $inject = [
    'shipments',
    'firstCount',
    'reminderCount',
    'confirmCount',
    'skippedCount',
    'MixpanelService',
    'ResendEmailsModal',
  ];

  constructor(
    shipments,
    firstCount,
    reminderCount,
    confirmCount,
    skippedCount,
    MixpanelService,
    ResendEmailsModal
  ) {
    this.shipments = shipments;
    this.emailsCount = {
      survey: firstCount,
      reminder: reminderCount,
      confirmation: confirmCount,
      skipped: skippedCount,
    };
    this.MixpanelService = MixpanelService;
    this.ResendEmailsModal = ResendEmailsModal;
  }

  close() {
    // this.MixpanelService.track('Multiple - Resend Emails - Cancel');
    this.ResendEmailsModal.close();
  }
}

class ResendEmailsModal {
  static $inject = ['$uibModal'];

  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open({ shipments, first_count, reminder_count, confirm_count, skipped_count }) {
    this.modalReference = this.$uibModal.open({
      template,
      controller: ResendEmailsModalCtrl,
      controllerAs: '$ctrl',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        shipments: () => shipments,
        firstCount: () => first_count,
        reminderCount: () => reminder_count,
        confirmCount: () => confirm_count,
        skippedCount: () => skipped_count,
      },
    });
  }

  close() {
    this.modalReference.close();
  }
}

export { ResendEmailsModal };
