class FixMonkeyService {
  static $inject = [
    'FixMonkey',
    'Shipment',
    'UserSession',
    'CompanyService',
    'PaymentSourceService',
  ];

  constructor(FixMonkey, Shipment, UserSession, CompanyService, PaymentSourceService) {
    this.FixMonkey = FixMonkey;
    this.Shipment = Shipment;
    this.UserSession = UserSession;
    this.CompanyService = CompanyService;
    this.PaymentSourceService = PaymentSourceService;

    this.busy = false;
    this.shipmentData = {};
    this.paymentPayload = {};

    /**
     * Step data
     * {
     *  action: string
     * }
     */
    this.step = {};

    /**
     * Pricing comparison
     * {
     *  old_rate: {},
     *  new_rate: {},
     *  difference: {},
     *  company: {},
     *  total_to_top_up: number
     * }
     */
    this.priceComparison = {};
  }

  /*
   * [FixMonkeyService]
   * Holds all data and routes the payload
   * Keeps track of the steps in the fix monkey flow
   *
   * Flow:
   * 1. Store data of shipment for persistence between steps
   * 2a. Save shipment data after edit
   * 2b. Report error
   * 3a. Route to payment if there is updated pricing
   * 3b. Cancel shipment if no shipping solution available
   * 5. Generate Label
   * 6. Show success / fail
   */

  /**
   * [prepareShipment]
   * Initiates edit shipment step by preparing the data to persist the receiver and item(s) information form
   * @param {Object} shipment Data for the shipment details to fetch
   * @return {Promise}
   */
  prepareShipment(shipment) {
    return this.Shipment.query(shipment)
      .$promise.then((data) => {
        this.shipmentData = data.shipment;
        this.preventRedirectToRejected = false;
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * [saveShipment] "Edit" shipment step
   * Saves shipment data to service to persist when returning to edit shipment step
   * Calls fix_monkey#fix_and_generate_label to check:
   *   1) Updated pricing, route to "Payment" step and check total_to_top_up:
   *   2) No shipping solution available, route to "Cancel" step
   *   3) No updated pricing, directly generate label
   * @param {Object} shipment Data from the updated shipment details
   * @return {Promise}
   */
  saveShipment(shipment) {
    this.busy = true;
    this.shipmentData = shipment;

    return this.FixMonkey.checkAndConfirm
      .generate({ company_id: this.UserSession.company.id, id: this.shipmentData.id }, { shipment })
      .$promise.then((data) => {
        this.busy = false;

        // Store pricing difference data if user needs to confirm payment / refund
        if (data.difference && data.courier_available) {
          this.priceComparison = {
            old_rate: data.old_rate,
            new_rate: data.new_rate,
            difference: data.difference,
            total_to_top_up: data.total_to_top_up,
            company: data.company,
          };
        }

        // Ensure error message is updated
        if (data.http_response) {
          this.shipmentData.http_response = data.http_response;
        }

        return data;
      })
      .catch((err) => {
        this.busy = false;
        throw err;
      });
  }

  /**
   * [payShipment] "Payment" shipment step
   * Send a payment object (if user needs to pay the difference) and a shipment object to fix_monkeys#fix_and_generate_label and returns the status of the generated label
   * @param {string} token A payment source ID
   * @return {Promise}
   */
  payShipment(token) {
    this.busy = true;

    if (token) {
      this.paymentPayload = {
        ...this.paymentPayload,
        ...this._setPaymentParams(token),
      };
    }

    return this.FixMonkey.checkAndConfirm
      .generate(
        {
          company_id: this.UserSession.company.id,
          id: this.shipmentData.id,
        },
        {
          payment: this.paymentPayload,
          shipment: this.shipmentData,
          options: {
            force_generate: true,
          },
        }
      )
      .$promise.then((data) => {
        this.busy = false;
        this.paymentPayload = {};
        this.shipmentData.http_response = data.http_response;
        return data;
      })
      .catch((err) => {
        this.busy = false;
        this.paymentPayload = {};
        throw err;
      });
  }

  /**
   * [reportShipment] "Report" shipment step. Sends message to support team updates label status to "Reported".
   */
  reportShipment(message) {
    this.busy = true;

    return this.FixMonkey.report
      .update(
        {
          company_id: this.UserSession.company.id,
          id: this.shipmentData.id,
        },
        message
      )
      .$promise.then((data) => {
        this.busy = false;
        return data;
      })
      .catch((err) => {
        this.busy = false;
        throw err;
      });
  }

  /**
   * [cancelShipment] "Cancel" shipment step. Cancels and refunds the shipment to the user, then moves the shipment details to Create Shipments page.
   */
  cancelShipment() {
    this.busy = true;

    return this.FixMonkey.cancelAndRecreate
      .update(
        {
          company_id: this.UserSession.company.id,
          id: this.shipmentData.id,
          include: 'shipments_items',
        },
        {}
      )
      .$promise.then((data) => {
        this.busy = false;
        return data;
      })
      .catch((err) => {
        this.busy = false;
        throw err;
      });
  }

  goToEditShipmentStep() {
    this.step = { action: 'edit' };
  }

  goToPayShipmentStep() {
    this.PaymentSourceService.selectedCard = null;
    this.step = { action: 'payment' };
  }

  goToCancelShipmentStep(params = {}) {
    this.step = { action: 'cancel', afterRetry: params.afterRetry };
  }

  goToReportShipmentStep() {
    this.step = { action: 'report' };
  }

  goToSuccessMonkeyStep() {
    this.step = { action: 'success' };
  }

  goToFailMonkeyStep() {
    this.step = { action: 'fail' };
  }

  /**
   * _setPaymentParams: build payment object with necessary Stripe infos
   * @param {string} token The payment source ID
   * @return payment object
   */
  _setPaymentParams(token) {
    return {
      token,
      total: this.priceComparison.total_to_top_up,
      currency: this.UserSession.company.currency,
      description: `${this.UserSession.company.currency}${this.priceComparison.total_to_top_up}`,
      platform: 'Stripe',
    };
  }
}

export { FixMonkeyService };
