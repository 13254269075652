import { IntlShape } from 'react-intl';
import {
  DefinedSubscriptionPlanId,
  FeatureKey,
  FEATURE_KEY,
  FlexibleSubscriptionPlanId,
  SCALE_PLAN_IDS,
} from '@client/data/subscription';
import { IFeature, PlanRanking } from 'typings/subscription';
import { IPlan } from '../types';

export function mapTranslationId(translationId: string): string {
  switch (translationId) {
    case 'freemium-pricing.features.global-fulfilment-network-toggle.disable':
    case 'freemium-pricing.features.account-manager-toggle.disable':
      return '';
    case 'freemium-pricing.features.global-fulfilment-network-toggle.enable':
    case 'freemium-pricing.features.account-manager-toggle.enable':
      return 'subscription.table.on-request';
    case 'freemium-pricing.table.unlimited':
      return 'global.unlimited';
    case 'freemium-pricing.table.contact_us':
      return 'global.contact-us';
    case 'Coming soon':
      return 'global.coming-soon';
    default:
      return translationId;
  }
}

/**
 * Highlight the cell for specific features in specific plans
 */
function isCellHighlight({
  featureKey,
  data,
  plan,
  smallestRankingInScalePlans,
}: {
  featureKey: FeatureKey;
  data: string | number | boolean;
  plan: IPlan;
  smallestRankingInScalePlans: PlanRanking;
}) {
  const { id: planId, ranking: planRanking } = plan;

  switch (featureKey) {
    case FEATURE_KEY.ShipmentsPerMonth:
    case FEATURE_KEY.TeamMembers: {
      const isScalePlan = SCALE_PLAN_IDS.includes(planId);
      const isNotSmallestScalePlan = planRanking > smallestRankingInScalePlans;
      return isScalePlan && isNotSmallestScalePlan;
    }
    case FEATURE_KEY.Lyoc:
    case FEATURE_KEY.LyocLabellingFee: {
      return typeof data === 'string' && data.includes('contact_us');
    }
    default:
      return false;
  }
}

interface IMapFeatureWithPlan {
  plans: IPlan[];
  features: IFeature[];
  smallestRankingInScalePlans: PlanRanking;
}

interface IGetTranslationValuesContext {
  intl: IntlShape;
  currency: string;
}

interface ITransformedFeaturePlan {
  id: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  data: string | number | boolean;
  isCurrent: boolean;
  isHighlight: boolean;
  getTranslationValues?: (context: IGetTranslationValuesContext) => Record<string, React.ReactNode>;
}

interface ITransformedFeature {
  key: FeatureKey;
  plans: ITransformedFeaturePlan[];
}

function transformLyocLabellingFeePlanData(
  dataValue: number,
  planId: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId
): {
  data: string;
  getTranslationValues: (context: IGetTranslationValuesContext) => Record<string, React.ReactNode>;
} {
  const getTranslationValues = (context: IGetTranslationValuesContext) => {
    const { intl, currency } = context;
    return {
      price: intl.formatNumber(dataValue, {
        currency,
        style: 'currency',
        minimumFractionDigits: 0,
      }),
    };
  };

  let data = '';

  if (planId === FlexibleSubscriptionPlanId.Enterprise) {
    data = 'freemium-pricing.table.contact_us';
  } else if (dataValue) {
    data = 'subscription.table.labelling-fee';
  }

  return { data, getTranslationValues };
}

export function mapFeatureWithPlan({
  plans,
  features,
  smallestRankingInScalePlans,
}: IMapFeatureWithPlan): ITransformedFeature[] {
  return features.map((feature) => ({
    key: feature.key,
    plans: plans.map((plan) => {
      const dataValue = feature.data[plan.ranking];
      const transformedFeaturePlan: ITransformedFeaturePlan = {
        id: plan.id,
        data: dataValue,
        isCurrent: plan.isCurrent,
        isHighlight: false,
      };

      if (feature.key === FEATURE_KEY.LyocLabellingFee && typeof dataValue === 'number') {
        const { data, getTranslationValues } = transformLyocLabellingFeePlanData(
          dataValue,
          plan.id
        );
        transformedFeaturePlan.data = data;
        transformedFeaturePlan.getTranslationValues = getTranslationValues;
      }

      transformedFeaturePlan.isHighlight = isCellHighlight({
        featureKey: feature.key,
        data: transformedFeaturePlan.data,
        plan,
        smallestRankingInScalePlans,
      });

      return transformedFeaturePlan;
    }),
  }));
}
