import template from './address-form-us-addressy.html?raw';

(function () {
  'use strict';

  var addressFormUsAddressy = {
    template,
    bindings: {
      address: '=',
      showContactInfo: '<',
      addressyInfo: '=',
      addressValid: '=?',
    },
    controller: 'addressFormUsAddressyCrtl as ctrl',
  };

  angular
    .module('app.component.address-form-us-addressy')
    .component('addressFormUsAddressy', addressFormUsAddressy);
})();
