export enum DateInputOptionsValue {
  EqualTo = 'is_equal_to', // one date field
  Before = 'is_before', // one date field
  After = 'is_after', // one date field
  Between = 'is_between', // two date fields
  DaysAgo = 'days_ago', // input box
  DaysAhead = 'days_ahead', //input box
  // TODO Disable until we support it in the future
  // Known = 'is_known',
  // Unknown = 'is_unknown',
}
