class StatusMessageService {
  static $inject = ['$q', 'StatusMessage', 'UserSession', 'HelperService'];

  constructor($q, StatusMessage, UserSession, HelperService) {
    this.$q = $q;
    this.StatusMessage = StatusMessage;
    this.UserSession = UserSession;
    this.HelperService = HelperService;

    // Status mapping
    // *Arrived at Consolidation Center 20
    // *Awaiting Pickup (To be updated when implemented)
    // *Awaiting Scan From Courier 107
    // *Cancelled - [Cancelled, & Cancelled (No Refund)] 102, 103, 160
    // *Delivered - [Delivered & Delivered Expected (End of Updates)] 15, 22
    // *Exception 16
    // *Failed Delivery Attempts 14
    // *Pending Tracking Event 10
    // *In Transit 12
    // *In Transit to Consolidation Center 19
    // *Label Rejected [Label Rejected, Label Rejected (Awaiting Courier) & Label Rejected (Reported)] 120, 121, 122
    // *Label Pending 104
    // *Label Ready 105
    // *Lost by Courier 31
    // *Out For Delivery 13
    // *Processing at Consolidation Center 21
    // *Return to Shipper 32
    // *Shipped (No Tracking) 18
    // *To be Dropped Off 140

    this.individualStatuses = [10, 12, 13, 14, 16, 18, 19, 20, 21, 31, 32, 104, 105, 107, 140];

    this.individualGroupedStatuses = [
      { id: '102,103,160', displayableName: 'Cancelled', group: 'Individual Statuses' },
      { id: '15,22', displayableName: 'Delivered', group: 'Individual Statuses' },
      { id: '120,121,122', displayableName: 'Label Rejected', group: 'Individual Statuses' },
      // Have to remove this since it’s not part of status message id
      // { id: 'pickup-expired', displayableName: 'Pickup Expired', group: 'Individual Statuses' },
      { id: '32', displayableName: 'Returned To Shipper', group: 'Individual Statuses' },
      { id: '31', displayableName: 'Lost by Courier', group: 'Individual Statuses' },
    ];

    this.groupedStatuses = [
      { id: '', displayableName: 'All', group: 'Grouped Statuses' },
      {
        id: '105,107,140,120,121,122',
        displayableName: 'Shipment On Hand',
        group: 'Grouped Statuses',
      },
      {
        id: '12,19,20,21,13,14',
        displayableName: 'Shipment In Transit',
        group: 'Grouped Statuses',
      },
      { id: '18,15,22', displayableName: 'Shipment Completed', group: 'Grouped Statuses' },
    ];

    this.statusMessages = [];
    this.statusMessagesMapping = {};
  }

  getStatusMessages() {
    if (this.statusMessages.length !== 0) return this.$q.resolve(this.statusMessages);

    // Use the analytics mapping to get status messages that match the graphs in the analytics
    return this.getAnalyticsMapping()
      .then((res) => {
        const transformedIndividualStatuses = this.individualGroupedStatuses
          .concat(this._formatMappingObject(res))
          .sort(this._sortAlphabetically);

        this.statusMessages = this.groupedStatuses.concat(transformedIndividualStatuses);

        return this.statusMessages;
      })
      .catch((err) => {
        throw err;
      });
  }

  getNewStatusMessages() {
    return this.StatusMessage.query().$promise;
  }

  _formatMappingObject(mappingObject) {
    return Object.keys(mappingObject).reduce((acc, statusName) => {
      acc.push({
        displayableName: statusName,
        id: mappingObject[statusName].toString(),
        group: 'Individual Statuses',
      });

      return acc;
    }, []);
  }

  getAnalyticsMapping() {
    if (Object.keys(this.statusMessagesMapping).length) {
      return this.$q.resolve(this.statusMessagesMapping);
    }

    return this.StatusMessage.getAnalyticsMapping({
      country_id: this.UserSession.getCompanyCountryId(),
    })
      .$promise.then((res) => {
        this.statusMessagesMapping = res.toJSON();
        return this.statusMessagesMapping;
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * [_transformStatuses] Returns a filtered array with additional properties for advanced search filter
   * @param {Array} statuses
   * @return {Array}
   */
  _transformStatuses(statuses) {
    return statuses.reduce((accum, current) => {
      if (this.individualStatuses.indexOf(current.id) > -1) {
        const displayableName = this._buildDisplayableName(current);
        const status = { ...current, group: 'Individual Statuses', displayableName };

        accum.push(status);
      }

      return accum;
    }, []);
  }

  /**
   * [_buildDisplayableName] Adds a displayableName parameter to a status object
   * @param {Object} statusMessage
   */
  _buildDisplayableName(statusMessage) {
    return statusMessage.secondary_name
      ? `${statusMessage.name} (${statusMessage.secondary_name})`
      : statusMessage.name;
  }

  /**
   * [_sortAlphabetically] Use with .sort function by passing it as a callback
   * @return {Number}
   */
  _sortAlphabetically(first, second) {
    const firstName = first.displayableName.toUpperCase();
    const secondName = second.displayableName.toUpperCase();

    if (firstName < secondName) return -1;
    if (firstName > secondName) return 1;

    return 0;
  }

  _groupStatusMessageBykey(statusMessages, key, statusIds) {
    const groupedMessage = this.HelperService.groupArrayByKey(statusMessages, key);
    const filterMessage = [];

    groupedMessage.forEach((group) => {
      if (group.options.length <= 1 && group.options[0] && group.options[0].filter_group) {
        const option = group.options[0];
        const message = {
          id: [option.id],
          name: option.name,
          filter_group: option.filter_group,
        };

        if (statusIds && statusIds.includes(option.id)) {
          message.selected = true;
        }
        filterMessage.push(message);
        return;
      }

      const mergeMessage = {};
      group.options.forEach((option) => {
        if (mergeMessage.id) {
          mergeMessage.id.push(option.id);
        } else {
          mergeMessage.id = [option.id];
        }
        mergeMessage.name = option.name;
        mergeMessage.filter_group = option.filter_group;

        if (statusIds && statusIds.includes(option.id)) {
          mergeMessage.selected = true;
        }
      });

      if (mergeMessage.filter_group) {
        filterMessage.push(mergeMessage);
      }
    });

    return filterMessage;
  }

  generateNewFilterStatusMessageObject(statusMessages, key, statusIds) {
    return this.HelperService.groupArrayByKey(
      this._groupStatusMessageBykey(statusMessages, key, statusIds),
      'filter_group',
      {
        isDisplay: true,
      }
    );
  }
}

export { StatusMessageService };
