import {
  CollectDataResponse,
  CollectEventsResponse,
  CollectLinkResponse,
  CollectResendEmailResponse,
  CollectEmailParams,
  CollectParams,
} from 'typings/collect';
import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';

class CollectResource {
  resource: any;

  static $inject = ['$resource', 'API', 'UserSession'];
  constructor(
    $resource: ng.resource.IResourceService,
    private API: IApiConfig,
    private UserSession: IUserSession
  ) {
    this.UserSession = UserSession;
    const endpointUrl = this.API.endpoint.replace('/api/', '/collect_api/');
    const HEADERS = { Authorization: `Bearer ${this.UserSession.session_token}` };

    this.resource = $resource(
      `${endpointUrl}/companies/:company_id/shipments/:shipment_id/:controller`,
      {},
      {
        getData: {
          method: 'GET',
          headers: HEADERS,
        },
        getLink: {
          method: 'POST',
          headers: HEADERS,
          params: {
            controller: 'survey_url',
          },
        },
        getEvents: {
          method: 'GET',
          headers: HEADERS,
          params: {
            controller: 'events',
          },
        },
        resendEmail: {
          method: 'POST',
          headers: HEADERS,
          params: {
            controller: 'email',
          },
        },
      }
    );
  }

  getData(params: CollectParams): Promise<CollectDataResponse> {
    return this.resource.getData(params).$promise;
  }

  getLink(params: CollectParams): Promise<CollectLinkResponse> {
    return this.resource.getLink(params, {}).$promise;
  }

  getEvents(params: CollectParams): Promise<CollectEventsResponse> {
    return this.resource.getEvents(params).$promise;
  }

  resendEmail(params: CollectEmailParams): Promise<CollectResendEmailResponse> {
    return this.resource.resendEmail(
      { shipment_id: params.shipment_id, company_id: params.company_id },
      { bcc: params.bcc, type: params.type }
    ).$promise;
  }
}

export { CollectResource };
