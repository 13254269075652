import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import COLORS from '@client/src/colors';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: '0 0 48px 0',
    '&:first-child': {
      marginTop: '0',
    },
    '&.Mui-expanded': {
      margin: '0 0 48px 0',
    },

    '&::before': {
      display: 'none',
    },
  },
  header: {
    minHeight: '32px',
    '&.Mui-expanded': {
      minHeight: 'auto',
      '&::before': {
        content: '""',
        whiteSpace: 'pre',
        backgroundColor: COLORS.tealNormal,
        minWidth: '10px',
        height: '32px',
        borderRadius: '5px',
        boxShadow: '0px 5px 15px rgba(165, 228, 230, 1)',
        position: 'relative',
        left: '-25px',
        marginRight: '-10px',
      },
    },
    '& > .MuiAccordionSummary-content': {
      margin: '0',
    },
  },
}));

interface AccordionProps extends Omit<MuiAccordionProps, 'children' | 'title'> {
  title: ReactNode;
  children: ReactNode;
}

export default function Accordion({
  expanded,
  title,
  id,
  children,
  ...props
}: AccordionProps): JSX.Element {
  const classes = useStyles();

  return (
    <MuiAccordion expanded={expanded} {...props} className={classes.root}>
      <AccordionSummary
        className={clsx(classes.header, 'text-lg font-bold')}
        style={{ color: expanded ? COLORS.tealDark : COLORS.inkDark }}
        aria-controls={id}
        id={id}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
}
