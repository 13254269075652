import Input from '@client/core/components/react/Input';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from 'easyship-components';
// eslint-disable-next-line import/no-cycle
import { IUpsReadyFormValues } from '../UpsReadyLyocForm';

interface IUpsReadyInputFields {
  isError: boolean;
  errorMessage: string;
  defaultValue: IUpsReadyFormValues;
}

function UpsReadyInputFields({ isError, errorMessage, defaultValue }: IUpsReadyInputFields) {
  const { formatMessage } = useIntl();
  const { control, setValue } = useFormContext<IUpsReadyFormValues>();

  useEffect(() => {
    setValue('accountName', defaultValue.accountName);
    setValue('accountNumber', defaultValue.accountNumber);
  }, [defaultValue.accountName, defaultValue.accountNumber, setValue]);

  const accountNameSubscript = formatMessage({
    id: 'courier.lyoc-courier.ups-ready.account-name-subscript',
  });
  const accountNumberSubscript = formatMessage({
    id: 'courier.lyoc-courier.ups-ready.account-number-subscript',
  });

  const accountNameRules = {
    required: {
      value: true,
      message: formatMessage({
        id: 'courier.lyoc-courier.ups-ready.account-name-required',
      }),
    },
    minLength: {
      value: 3,
      message: formatMessage({
        id: 'courier.lyoc-courier.ups-ready.account-name-too-short',
      }),
    },
    maxLength: {
      value: 15,
      message: formatMessage({
        id: 'courier.lyoc-courier.ups-ready.account-name-too-long',
      }),
    },
  };

  const accountNumberRules = {
    required: {
      value: true,
      message: formatMessage({
        id: 'courier.lyoc-courier.ups-ready.account-number-required',
      }),
    },
    minLength: {
      value: 6,
      message: formatMessage({
        id: 'courier.lyoc-courier.ups-ready.account-number-too-short',
      }),
    },
    maxLength: {
      value: 6,
      message: formatMessage({
        id: 'courier.lyoc-courier.ups-ready.account-number-too-long',
      }),
    },
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Controller
        control={control}
        name="accountName"
        rules={accountNameRules}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            label={<FormattedMessage id="courier.lyoc-courier.ups-ready.account-name" />}
            placeholder={formatMessage({
              id: 'courier.lyoc-courier.ups-ready.account-name-placeholder',
            })}
            helperText={fieldState.error ? fieldState.error.message : accountNameSubscript}
            error={!!fieldState.error}
            maxLength={15}
          />
        )}
      />

      <Controller
        control={control}
        name="accountNumber"
        rules={accountNumberRules}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            label={<FormattedMessage id="courier.lyoc-courier.ups-ready.account-number" />}
            helperText={fieldState.error ? fieldState.error.message : accountNumberSubscript}
            error={!!fieldState.error}
            maxLength={6}
          />
        )}
      />

      {isError && <Alert severity="error">{errorMessage}</Alert>}
    </div>
  );
}

export default UpsReadyInputFields;
