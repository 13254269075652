import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Uploader from '@client/src/global/dashboard-component-library/uploader';
import Button from '@client/core/components/react/Button';

import { IClaimPhoto, IClaimMessage, ReviewAction, PaymentAction } from 'typings/insurance';
import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { InsuranceResource } from '@client/src/global/services/insurance/insurance.resource';
import { InsurancePhotoResource } from '@client/src/global/services/insurance-photo/insurance-photo.resource';

interface ClaimResponseProps {
  policyId: string;
  claimId: string;
  latestMessage?: IClaimMessage;
  nextAction: ReviewAction | PaymentAction;
  onClose: () => void;
  $injector: angular.auto.IInjectorService;
}

export default function ClaimResponse({
  policyId,
  claimId,
  latestMessage,
  nextAction,
  onClose,
  $injector,
}: ClaimResponseProps) {
  const { formatMessage } = useIntl();

  const InsuranceResource = $injector.get<InsuranceResource>('InsuranceResource');
  const InsurancePhotoResource = $injector.get<InsurancePhotoResource>('InsurancePhotoResource');
  const UserSession = $injector.get('UserSession') as IUserSession;
  const API = $injector.get('API') as IApiConfig;
  const [response, setResponse] = useState('');
  const [photoBusy, setPhotoBusy] = useState(false);
  const [photos, setPhotos] = useState([] as IClaimPhoto[]);
  const photoUploadEndpoint = `${API.endpoint}/companies/${UserSession.company.id}/policies/${policyId}/claim_photos`;

  const onStartUpload = () => {
    setPhotoBusy(true);
  };

  const submitResponse = () => {
    if (!policyId || !nextAction) return;
    if (!response) {
      toastError(formatMessage({ id: 'toast.incomplete-form' }));
      return;
    }

    InsuranceResource.respondClaim(
      { policy_id: policyId },
      {
        id: claimId,
        event: nextAction,
        message: response,
      }
    )
      .then(() => {
        toastSuccess(
          formatMessage(
            { id: 'toast.update-success' },
            { noun: formatMessage({ id: 'global.claim' }) }
          )
        );
        onClose();
      })
      .catch(() => toastError(formatMessage({ id: 'toast.default-error' })));
  };

  const onFinishUpload = (photo: IClaimPhoto) => {
    setPhotos((photos) => [...photos, photo]);
    setPhotoBusy(false);
  };

  const onUploadError = (message: string) => {
    toastError(message || formatMessage({ id: 'insurance-claim.uploader-failed' }));
    setPhotoBusy(false);
  };

  const onDeletePhoto = (previewIndex: number) => {
    if (!policyId || !photos.length) return;
    setPhotoBusy(true);

    InsurancePhotoResource.deletePhoto({
      policy_id: policyId,
      photo_id: photos[previewIndex].id,
      company_id: UserSession.company.id,
    }).then(() => {
      setPhotos((photos) => photos.filter((_photo, i) => i !== previewIndex));
      setPhotoBusy(false);
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          <FormattedMessage id="insurance-claim.claim-dialog.latest-message" />
        </Typography>
        <Typography
          variant="body2"
          style={{
            wordBreak: 'break-word',
            overflowY: 'scroll',
            maxHeight: 80,
            whiteSpace: 'pre-line',
          }}
        >
          {latestMessage?.content || <FormattedMessage id="global.not-available" />}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1" gutterBottom>
            <FormattedMessage id="insurance-claim.claim-dialog.your-reply" />
          </Typography>
          <Typography variant="body2" gutterBottom>
            ({response.length}/350)
          </Typography>
        </div>
        <TextField
          hiddenLabel
          multiline
          rows={5}
          inputProps={{ maxLength: 350 }}
          placeholder={formatMessage({
            id: 'insurance-claim.claim-dialog.textbox-placeholder',
          })}
          variant="outlined"
          onChange={(e) => setResponse(e.currentTarget.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          <FormattedMessage id="insurance-claim.claim-dialog.file-upload" />
        </Typography>
        <Uploader
          endpoint={photoUploadEndpoint}
          onSelection={onStartUpload}
          onRemove={onDeletePhoto}
          onResponse={onFinishUpload}
          onError={onUploadError}
          $injector={$injector}
        />
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <Button
          onClick={submitResponse}
          variant="contained"
          color="primary"
          style={{ minWidth: 150 }}
          disabled={photoBusy}
        >
          <FormattedMessage id="global.submit" />
        </Button>
      </Grid>
    </Grid>
  );
}
