import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { IUserSession } from 'typings/user-session';
import template from './payment-failed-card.html?raw';
import style from './payment-failed-card.module.scss';
import image from './payment-failed-card.svg?svgo';

class PaymentFailedCard implements IComponentController {
  style = style;
  image: string = image;
  userCanUpdatePaymentMethod = true;

  static $inject = ['$translate', 'SubscriptionService', 'UserSession'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession
  ) {}

  $onInit(): void {
    const companyId = this.UserSession.getCompanyId();
    if (!this.SubscriptionService.currentSubscription && companyId) {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: companyId,
      });
    }

    if (this.UserSession.hasTeamRightsAndPermissionsBetaKey()) {
      this.userCanUpdatePaymentMethod = this.UserSession.hasUserRole('subscription_and_billing');
    }
  }

  get rightButtonText(): string {
    if (this.userCanUpdatePaymentMethod) {
      return this.$translate.instant('subscription.buttons.update-billing');
    }

    return this.$translate.instant('global.got-it');
  }
}

const PaymentFailedCardComponent: ng.IComponentOptions = {
  controller: PaymentFailedCard,
  template,
  bindings: {
    esRightAction: '&',
    esLeftAction: '&',
    esCloseAction: '&',
    esShowCloseButton: '<',
    esLeftButtonLoading: '<',
  },
};

export { PaymentFailedCardComponent };
