import { IApiConfig } from 'typings/core/config/api';
import { Restrictions } from 'typings/dashboard/services/restrictions';

import { IComponentController } from 'angular';
import template from './tax-id-message.html?raw';

interface IWrappers {
  a: (chunk: string) => string;
}

class TaxIdMessageController implements IComponentController {
  esTaxId = '';
  esRequirements: Restrictions.IReceiverTaxIdRestrictions = {
    require: 'no_need',
    regexes: [],
  };
  esTaxIdFieldValid = false;
  wrappers = {} as IWrappers;

  static $inject = ['API'];
  constructor(private API: IApiConfig) {
    this.API = API;
  }

  $onInit() {
    this.wrappers.a = (chunk) =>
      `<a href="${this.API.help}/hc/en-us/articles/4404856577421-Tax-ID-Guide-Format-Restrictions-" target="_blank" rel="noopener">${chunk}</a>`;
  }

  get showTaxIdMessage() {
    return (
      (['mandatory', 'suggested'].includes(this.esRequirements.require) &&
        this.esTaxIdFieldValid === false) ||
      (this.esRequirements.require === 'suggested' && !this.esTaxId)
    );
  }

  get alertType() {
    if (
      this.esRequirements.require === 'mandatory' ||
      (this.esRequirements.require === 'suggested' && this.esTaxIdFieldValid === false)
    ) {
      return 'danger';
    }

    return 'primary';
  }

  get translationSlug() {
    const prefix = 'receiver-form.receiver-tax-id.';

    if (this.esRequirements.require === 'mandatory' && !this.esTaxId) {
      return `${prefix}required`;
    }

    if (
      ['mandatory', 'suggested'].includes(this.esRequirements.require) &&
      this.esTaxIdFieldValid === false
    ) {
      return `${prefix}incorrect`;
    }

    if (this.esRequirements.require === 'suggested' && !this.esTaxId) {
      return `${prefix}suggested`;
    }
  }
}

const TaxIdMessageComponent = {
  controller: TaxIdMessageController,
  template,
  bindings: {
    esTaxId: '<',
    esRequirements: '<',
    esTaxIdFieldValid: '<',
  },
};

export { TaxIdMessageComponent };
