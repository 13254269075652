(function () {
  'use strict';

  ShoplineService.$inject = ['$q', 'PlatformApi', 'UserSession', 'Auth', 'MixpanelService'];
  function ShoplineService($q, PlatformApi, UserSession, Auth, MixpanelService) {
    var service = this;

    service.authenticate = function (url) {
      //Ping the session to be sure that UserSession exists
      return Auth.retrieveSession().then(function () {
        return PlatformApi.shopline
          .authenticate({ company_id: UserSession.company.id }, { url: url })
          .$promise.then(function (response) {
            return response;
          });
      });
    };

    service.createCarrierService = function (store) {
      return $q(function (resolve, reject) {
        createCarrierService(store)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    };

    function createCarrierService(store) {
      return PlatformApi.shopline
        .createCarrierService(
          {
            company_id: UserSession.company.id,
          },
          {
            id: store.id,
            is_rates_enabled: true,
            store: store,
          }
        )
        .$promise.then(function (response) {
          if (_.get(response.data, 'store.is_rates_enabled')) {
            MixpanelService.track('Dynamic Rate - Success', {
              platform: _.get(response.data, 'store.platform.css_class'),
            });
          } else {
            MixpanelService.track('Dynamic Rate - Unavailable', {
              platform: _.get(response.data, 'store.platform.css_class'),
            });
          }

          return response;
        })
        .catch(function (error) {
          MixpanelService.track('Dynamic Rate - Failed', {
            platform: _.get(error.data, 'store.platform.css_class'),
          });

          throw error;
        });
    }

    service.deleteCarrierService = function (store) {
      return $q(function (resolve, reject) {
        PlatformApi.shopline.deleteCarrierService(
          {
            company_id: UserSession.company.id,
            id: store.id,
          },
          {},
          function (response) {
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };
  }

  angular.module('app.service.ShoplineService', []).service('ShoplineService', ShoplineService);
})();
