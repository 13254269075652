import { IUserSession } from 'typings/user-session';
import { CollectBulkResponse } from 'typings/collect';
import { CollectBulkResource } from './collect-bulk.resource';

class CollectBulkService {
  static $inject = ['CollectBulkResource', 'UserSession'];
  constructor(
    private CollectBulkResource: CollectBulkResource,
    private UserSession: IUserSession
  ) {}

  resendEmailsPrepare(shipmentIds: string[]): Promise<CollectBulkResponse> {
    return new Promise((resolve, reject) => {
      this.CollectBulkResource.resendEmailsPrepare(
        {
          company_id: this.UserSession.getCompanyId(),
        },
        { shipment_ids: shipmentIds }
      )
        .then(resolve)
        .catch(reject);
    });
  }

  resendEmailsConfirm(shipmentIds: string[]): Promise<CollectBulkResponse> {
    return new Promise((resolve, reject) => {
      this.CollectBulkResource.resendEmailsConfirm(
        {
          company_id: this.UserSession.getCompanyId(),
        },
        { shipment_ids: shipmentIds }
      )
        .then(resolve)
        .catch(reject);
    });
  }
}

export { CollectBulkService };
