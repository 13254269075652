import angular from 'angular';
import { MultiSelectCheckboxTreeComponent } from './multi-select-checkbox-tree.component';

const MODULE_NAME = 'core.components.multi-select-checkbox-tree';

angular
  .module(MODULE_NAME, [])
  .component('esMultiSelectCheckboxTree', MultiSelectCheckboxTreeComponent);

export default MODULE_NAME;
