(function () {
  'use strict';

  function analyticsDetailsModalCtrl(params, AnalyticsHelpers) {
    var vm = this;

    vm.params = params;
    vm.AnalyticsHelpers = AnalyticsHelpers;

    vm.goToFilter = function (field, value) {
      AnalyticsHelpers.goToFilter(field, value, vm.params.selectedDate);
    };
  }

  analyticsDetailsModalCtrl.$inject = ['params', 'AnalyticsHelpers'];

  angular
    .module('app.service.analyticsDetailsModal')
    .controller('analyticsDetailsModalCtrl', analyticsDetailsModalCtrl);
})();
