(function () {
  RedirectStorePlatformApi.$inject = ['$resource', 'API'];
  function RedirectStorePlatformApi($resource, API) {
    const endpointUrl = API.endpoint;

    return $resource(`${endpointUrl}/companies/:company_id/stores/:platform/:controller`, {
      company_id: '@_company_id',
      platform: '@_platform',
    });
  }
  angular
    .module('app.factory.RedirectStorePlatformApi', [])
    .factory('RedirectStorePlatformApi', RedirectStorePlatformApi);
})();
