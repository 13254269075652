import angular from 'angular';

import { AddProductsModalImportComponent } from './add-products-modal-import.component';

const MODULE_NAME = 'app.product-listing.add-products-modal.import';

angular
  .module(MODULE_NAME, [])
  .component('esProductListingAddProductsModalImport', AddProductsModalImportComponent);

export default MODULE_NAME;
