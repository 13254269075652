import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps as MuiAlertProps } from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';

import COLORS from '@client/src/colors';

interface HeaderBannerProps extends MuiAlertProps {
  show: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    top: 0,
    paddingLeft: 210,
    zIndex: 26,
    height: 60,
    width: '100%',
    position: 'fixed',
    backgroundColor: `${COLORS.tealLight} !important`,

    '& .MuiAlert-icon': {
      alignItems: 'center',
      color: COLORS.tealNormal,
    },

    '& .MuiSvgIcon-root': {
      fontSize: 17,
    },

    '& .MuiAlert-message': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default function HeaderBanner({ show, children }: HeaderBannerProps) {
  const classes = useStyles();

  useEffect(() => {
    if (show) document.body.classList.add('show-announcement');
  }, []);

  if (!show) return null;

  return (
    <MuiAlert icon={<ErrorIcon />} className={classes.root}>
      <Typography variant="body2">{children}</Typography>
    </MuiAlert>
  );
}
