export enum ProductListingModal {
  AddProducts = 'add-products',
  EditProducts = 'edit-products',
  EditProductsMissingInformation = 'edit-products-missing-information',
  DeleteProduct = 'delete-product',
}

export enum ProductListingMissingFieldValue {
  All = 'all',
  ProductName = 'product_name',
  Category = 'category',
  Dimensions = 'dimensions',
  Weight = 'weight',
}
