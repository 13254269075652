import angular from 'angular';
import { react2angular } from 'react2angular';

import CountrySelector, { WrappedCountrySelector } from './CountrySelector';

export default CountrySelector;
export const AngularCountrySelector = react2angular(
  WrappedCountrySelector,
  ['alpha2', 'options', 'onChange'],
  []
);
export const countrySelectorModuleName = 'app.components.country-selector';

angular
  .module(countrySelectorModuleName, [])
  .component('esCountrySelector', AngularCountrySelector);
