import { IApiConfig } from 'typings/core/config/api';
import { IUserSession } from 'typings/user-session';
import { IShowChangePlanObject } from 'typings/subscription';
import { HELP_GUIDE_CONTENT, HelpGuideTopic } from '@client/data/help-guide';

import {
  DefinedSubscriptionPlanId,
  FeatureKey,
  FlexibleSubscriptionPlanId,
} from '@client/data/subscription';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import helpArticleImageUrl from '@assets/images/dashboard/help-guide/help-article.svg';
import bookMeetingImageUrl from '@assets/images/dashboard/help-guide/icon-bookameeting-solid-withbg.svg';
import style from './help-guide.module.scss';
import template from './help-guide.html?raw';
import { SubscriptionService } from '../global/services/subscription/subscription.service';

class HelpGuideController implements IComponentController {
  style = style;
  helpGuideContent = HELP_GUIDE_CONTENT;
  helpArticleImageUrl = helpArticleImageUrl;
  bookMeetingImageUrl = bookMeetingImageUrl;
  showModal: Partial<IShowChangePlanObject> = {
    enterpriseCall: false,
    compare: false,
  };
  suggestedPlanId?: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;

  static $inject = [
    'API',
    '$scope',
    '$window',
    'UserSession',
    'MixpanelService',
    'SubscriptionService',
  ];
  constructor(
    private API: IApiConfig,
    private $scope: ng.IScope,
    private $window: ng.IWindowService,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService,
    private SubscriptionService: SubscriptionService
  ) {
    this.contactSupport = this.contactSupport.bind(this);
    this.showSubscriptionModal = this.showSubscriptionModal.bind(this);
    this.isPlanBadgeVisible = this.isPlanBadgeVisible.bind(this);
    this.getPlanNameByFeatureKey = this.getPlanNameByFeatureKey.bind(this);

    // Filter out topics that require a specific user role
    this.helpGuideContent = Object.fromEntries(
      Object.entries(HELP_GUIDE_CONTENT).map(([section, topics]) => {
        const filteredTopics = topics.filter((topic: HelpGuideTopic) => {
          if (!topic.requiredUserRole) return true;
          return UserSession.hasUserRole(topic.requiredUserRole);
        });
        return [section, filteredTopics];
      })
    );
  }

  isPlanBadgeVisible(featureKey: FeatureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  get allFeatures() {
    return this.SubscriptionService.currentSubscription?.all_features;
  }

  get showBookMeetingCTA(): boolean {
    return this.UserSession.showBookMeetingCTA();
  }

  showSubscriptionModal(featureKey: FeatureKey): void {
    if (
      this.SubscriptionService.currentSubscription?.plan?.id ===
      FlexibleSubscriptionPlanId.Enterprise
    ) {
      this.showModal.enterpriseCall = true;
    } else {
      this.suggestedPlanId = this.SubscriptionService.getSuggestedPlanIdByFeatureKey(featureKey);
      this.showModal.upgrade = true;
    }

    this.$scope.$apply();
  }

  contactSupport(): void {
    if (!this.$window.zE) return;

    this.$window.zE('messenger', 'open');
    this.MixpanelService.track('Help Guide - Contact Support - Chat');
  }

  goToHelpCentre(): void {
    window.open(this.API.help, '_blank', 'noopener');

    this.MixpanelService.track('Help Guide - Help Articles');
  }

  goToBookMeeting(): void {
    this.MixpanelService.track('Book Onboarding Meeting - Click', { origin: 'help_page' });
    this.UserSession.openOnboardingMeetingLink();
  }
}

const HelpGuideComponent = {
  controller: HelpGuideController,
  template,
};

export { HelpGuideComponent };
