import { toastSuccess } from '@client/core/components/react/Toastify';
import template from './delete-courier.html?raw';
import style from './delete-courier.module.scss';

class DeleteCourier {
  static $inject = [
    '$translate',
    'CourierAccounts',
    'CourierDetails',
    'CourierService',
    'MixpanelService',
  ];

  constructor($translate, CourierAccounts, CourierDetails, CourierService, MixpanelService) {
    this.style = style;
    this.CourierAccounts = CourierAccounts;
    this.CourierDetails = CourierDetails;
    this.CourierService = CourierService;
    this.MixpanelService = MixpanelService;
    this.$translate = $translate;
  }

  handleToEditStep() {
    this.error = null;
    this.toEdit();
  }

  onDeleteCourier() {
    const payload = { id: this.courier.id };

    this.loading = true;
    this.CourierAccounts.deleteCourier(payload)
      .then(() => {
        toastSuccess(
          this.$translate.instant('toast.delete-success', { noun: this.courier.nickname })
        );
        this.CourierService.getCouriers({ refetch: true });
        this.CourierDetails.close();
        this.MixpanelService.track('Courier - Service - Delete Service', {
          courier_name: this.courier.umbrella_name,
        });
      })
      .catch((error) => {
        this.error =
          (error.data && error.data.status) || this.$translate.instant('toast.default-error');
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

const DeleteCourierComponent = {
  controller: DeleteCourier,
  template,
  bindings: {
    courier: '<',
    toEdit: '&',
  },
};

export { DeleteCourierComponent };
