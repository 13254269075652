import template from './table-body.html?raw';
import style from './table-body.module.scss';

class TableBody {
  constructor() {
    this.style = style;
  }
}

const TableBodyComponent = {
  template,
  controller: TableBody,
  bindings: {},
};

export { TableBodyComponent };
