import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'easyship-components';
import { HandoverOption } from '@client/core/corelogic/models/HandoverOption';
import CellLayout from './CellLayout';
import PaidPickUp from './paid-pick-up.svg';
import { CellProps } from './d';

export const HandoverCell = memo(({ row }: CellProps) => {
  const { formatMessage } = useIntl();

  const shipment = row.original;
  const handOverOptions = (shipment.available_handover_options ?? '').split(
    ','
  ) as HandoverOption[];
  const hasDropOff = handOverOptions.includes('dropoff');
  const hasFreePickUp = handOverOptions.includes('free_pickup');
  const hasPaidPickUp = handOverOptions.includes('paid_pickup');

  return (
    <CellLayout>
      <div className="flex px-2 min-w-[100px] gap-2 items-center">
        {hasDropOff && (
          <Tooltip
            className="h-[20px]"
            label={formatMessage({ id: 'shipments.list.handover-options.dropoff' })}
          >
            <i className="icon-dropoff text-xl text-ink-500"> </i>
          </Tooltip>
        )}
        {hasFreePickUp && (
          <Tooltip
            className="h-[20px]"
            label={formatMessage({ id: 'shipments.list.handover-options.free_pickup' })}
          >
            <div className="transform scale-x-[-1]">
              <i className="icon-delivery text-xl" />
            </div>
          </Tooltip>
        )}
        {hasPaidPickUp && (
          <Tooltip
            className="h-[20px]"
            label={formatMessage({ id: 'shipments.list.handover-options.paid_pickup' })}
          >
            <img src={PaidPickUp} className="w-[20px]" alt="Paid Pick Up" />
          </Tooltip>
        )}
      </div>
    </CellLayout>
  );
});
