import template from './pick-and-pack.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('app.pick-and-pack', {
      url: '^/pick-and-pack',
      template,
      data: {
        requiredUserRight: 'menu.createShipments.pickAndPack',
      },
    });
  },
]);
