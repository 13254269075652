import React, { createContext, useContext, useMemo } from 'react';
import { NgOnboardingGateway } from './NgOnboardingGateway';
import { IOnboardingGateway } from '../../ports/onboarding';

export interface OnboardingGatewayContextData {
  onboardingGateway: IOnboardingGateway;
}

const OnboardingGatewayContext = createContext<OnboardingGatewayContextData | undefined>(undefined);

interface OnboardingGatewayProviderProps {
  children: React.ReactNode;
}

function OnboardingGatewayProvider({ children }: OnboardingGatewayProviderProps) {
  const data: OnboardingGatewayContextData = useMemo(
    () => ({
      onboardingGateway: new NgOnboardingGateway(),
    }),
    []
  );

  return (
    <OnboardingGatewayContext.Provider value={data}>{children}</OnboardingGatewayContext.Provider>
  );
}

interface FakeOnboardingGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<OnboardingGatewayContextData>;
}

function FakeOnboardingGatewayProvider({ children, value }: FakeOnboardingGatewayProviderProps) {
  const data: OnboardingGatewayContextData = useMemo(
    () => ({
      onboardingGateway: new NgOnboardingGateway(),
      ...value,
    }),
    [value]
  );

  return (
    <OnboardingGatewayContext.Provider value={data}>{children}</OnboardingGatewayContext.Provider>
  );
}

function useOnboardingGateway() {
  const onboardingGatewayContext = useContext(OnboardingGatewayContext);

  if (onboardingGatewayContext === undefined) {
    throw new Error('useOnboardingGateway must be used within a OnboardingGatewayProvider');
  }
  return onboardingGatewayContext;
}

export { OnboardingGatewayProvider, FakeOnboardingGatewayProvider, useOnboardingGateway };
