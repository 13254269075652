import React, { ReactElement, useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '@client/core/components/react/Button';
import Input from '@client/core/components/react/Input';
import Select from '@client/core/components/react/Select';
import Alert from '@client/core/components/react/Alert';
import COLORS from '@client/src/colors';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

interface CourierDetails {
  nickname: string;
  username: string;
  password: string;
  apg_origin_code: string;
  apg_sender_code: string;
}

interface Fields {
  nickname: string;
  username: string;
  password: string;
  originCode: string;
  senderCode: string;
}

interface ConnectCourierFormsApgProps {
  courierDetails: CourierDetails;
  editMode: boolean;
  error: string | null;
  onSubmit: (courierDetails: CourierDetails) => Promise<void>;
  onDelete: () => Promise<void>;
  onBack: () => void;
}

export function ConnectCourierFormsApg({
  courierDetails,
  editMode,
  error,
  onSubmit,
  onBack,
  onDelete,
}: ConnectCourierFormsApgProps): ReactElement<ConnectCourierFormsApgProps> {
  const { formatMessage } = useIntl();
  const { control, formState, handleSubmit } = useForm<Fields>({
    defaultValues: {
      nickname: courierDetails.nickname || '',
      username: courierDetails.username || '',
      password: courierDetails.password || '',
      originCode: courierDetails.apg_origin_code || '',
      senderCode: courierDetails.apg_sender_code || '',
    },
  });
  const originCodes = ['JFK', 'LAX', 'MIA', 'ORD'];
  const [connectingCourier, setConnectingCourier] = useState(false);
  const [deletingCourier, setDeletingCourier] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (Object.keys(formState.errors).length) {
      setErrorMessage(formatMessage({ id: 'toast.incomplete-form' }));
    } else if (error) {
      setErrorMessage(error);
    }
  }, [error, formState]);

  const submit: SubmitHandler<Fields> = ({
    nickname,
    username,
    password,
    originCode,
    senderCode,
  }) => {
    setConnectingCourier(true);

    onSubmit({
      nickname,
      username,
      password,
      apg_origin_code: originCode,
      apg_sender_code: senderCode,
    }).finally(() => {
      setConnectingCourier(false);
    });
  };

  const handleDelete = () => {
    setDeletingCourier(true);

    onDelete().finally(() => {
      setDeletingCourier(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box px={6.25} py={2.5} clone>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="nickname"
              rules={{ required: true, minLength: 3, maxLength: 15 }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: 'courier.connect.field.account-name.label' })}
                  placeholder={formatMessage({
                    id: 'courier.connect.field.account-name.placeholder',
                  })}
                  helperText={formatMessage({
                    id: 'courier.connect.field.account-name.help',
                  })}
                  error={!!fieldState.error}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="username"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: 'global.username' })}
                  error={!!fieldState.error}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Input
                  type="password"
                  {...field}
                  label={formatMessage({ id: 'global.password' })}
                  error={!!fieldState.error}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="originCode"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  native
                  label={formatMessage({ id: 'courier.connect.field.origin-code.label' })}
                  error={!!fieldState.error}
                >
                  <option value="" disabled>
                    {formatMessage({ id: 'courier.connect.field.origin-code.placeholder' })}
                  </option>
                  {originCodes.map((originCode) => (
                    <option value={originCode} key={originCode}>
                      {originCode}
                    </option>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="senderCode"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: 'courier.connect.field.sender-code.label' })}
                  error={!!fieldState.error}
                />
              )}
            />
          </Grid>

          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box color={COLORS.inkLight} fontStyle="italic" clone>
              <Typography variant="body2">
                <FormattedMessage
                  id="courier.connect.apg-remarks"
                  values={{
                    a: (chunks: string) => <a href="mailto:support@easyship.com">{chunks}</a>,
                  }}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* TODO: Move this into a new component - CardFooterAction */}
      <Box px={6.25} py={2.5} border={`1px solid ${COLORS.skyNormal}`} clone>
        <Grid container>
          <Grid item xs={6}>
            <Button variant="text" onClick={onBack}>
              <FormattedMessage id="global.back" />
            </Button>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Grid item>
              {editMode && (
                <Button
                  variant="contained"
                  color="error"
                  loading={deletingCourier}
                  onClick={handleDelete}
                  style={{ width: 100 }}
                >
                  <FormattedMessage id="global.delete" />
                </Button>
              )}

              {!editMode && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={connectingCourier}
                  style={{ width: 100 }}
                >
                  <FormattedMessage id="global.connect" />
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

function WrappedConnectCourierFormsApg(
  props: ConnectCourierFormsApgProps
): ReactElement<ConnectCourierFormsApgProps> {
  return (
    <ReactRootProviders>
      <ConnectCourierFormsApg {...props} />
    </ReactRootProviders>
  );
}

export const AngularConnectCourierFormsApg = react2angular(WrappedConnectCourierFormsApg, [
  'courierDetails',
  'editMode',
  'error',
  'onSubmit',
  'onDelete',
  'onBack',
]);
