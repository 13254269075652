import Input from '@client/core/components/react/Input';
import React, { FormEvent } from 'react';
import { useIntl } from 'react-intl';
import { OtpFormData } from '../../models/payload/verification-payload';

interface OtpFormProps {
  defaultData?: Partial<OtpFormData>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  error: string;
}

function OtpForm(
  { defaultData, error, onSubmit }: OtpFormProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { formatMessage } = useIntl();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    onSubmit(e);
  }

  return (
    <form ref={ref} onSubmit={handleSubmit}>
      <Input
        label={formatMessage({ id: 'global.otp' })}
        name="code"
        error={error.length > 0}
        maxLength={6}
        defaultValue={defaultData?.code}
        placeholder={formatMessage({ id: 'auth.otp-modal.otp-form-placeholder' })}
      />
    </form>
  );
}

export default React.forwardRef(OtpForm);
