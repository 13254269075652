// TODO: Change the name to CompanyShipmentResource?
ShipmentResource.$inject = ['$resource', 'API'];
function ShipmentResource($resource, API) {
  const endpointUrl = API.endpoint;

  return $resource(
    `${endpointUrl}/:company_type/companies/:company_id/shipments/:id/:controller/:step`,
    {
      company_type: '@_company_type',
      company_id: '@_company_id',
      id: '@_id',
    },
    {
      query: {
        method: 'GET',
        params: {
          controller: '',
        },
      },
      unavailableRates: {
        method: 'GET',
        params: {
          controller: 'get_unavailable_rates',
        },
      },
      update: {
        method: 'PATCH',
        params: {
          controller: '',
        },
      },
      overwriteCourier: {
        method: 'PATCH',
        params: {
          controller: 'overwrite_courier',
        },
      },
      delete: {
        method: 'DELETE',
        params: {
          controller: '',
        },
      },
      deleteSelected: {
        method: 'POST',
        params: {
          controller: 'delete_selected',
        },
      },
      getLastDraft: {
        method: 'GET',
        params: {
          controller: 'last_draft',
        },
      },
      discardDrafts: {
        method: 'POST',
        params: {
          controller: 'discard_drafts',
        },
      },
      apiUpload: {
        method: 'POST',
        params: {
          controller: 'api_upload',
        },
      },
      splitPrepare: {
        method: 'POST',
        params: {
          controller: 'split_order',
          step: 'prepare',
        },
      },
      splitConfirm: {
        method: 'POST',
        params: {
          controller: 'split_order',
          step: 'confirm',
        },
      },
      mergeSelectedPrepare: {
        method: 'POST',
        params: {
          controller: 'merge_selected',
          step: 'prepare',
        },
      },
      mergeSelectedConfirm: {
        method: 'POST',
        params: {
          controller: 'merge_selected',
          step: 'confirm',
        },
      },
      sendConsigneeEmail: {
        method: 'POST',
        params: {
          controller: 'send_consignee_email',
        },
      },
      mergeAllPrepare: {
        method: 'GET',
        params: {
          controller: 'merge_all',
          step: 'prepare',
        },
      },
      mergeAllConfirm: {
        method: 'POST',
        params: {
          controller: 'merge_all',
          step: 'confirm',
        },
      },
      itemsBulkEditPrepare: {
        method: 'POST',
        params: {
          controller: 'items_bulk_edit',
          step: 'prepare',
        },
      },
      itemsBulkEditConfirm: {
        method: 'POST',
        params: {
          controller: 'items_bulk_edit',
          step: 'confirm',
        },
      },
      createShippingDocuments: {
        method: 'POST',
        hasBody: false,
        params: {
          controller: 'print_shipping_documents',
        },
      },
      markShipmentAsPrinted: {
        method: 'POST',
        hasBody: false,
        params: {
          controller: 'mark_shipment_printed',
        },
      },
      retryFulfillment: {
        method: 'POST',
        params: {
          controller: 'retry_fulfillment',
        },
      },
    }
  );
}

export { ShipmentResource };
