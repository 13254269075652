import { Brokers } from 'typings/brokers';
import { BrokersResource } from './brokers.resource';

class BrokersService {
  static $inject = ['BrokersResource'];
  constructor(private BrokersResource: BrokersResource) {}

  createBroker(payload: Brokers.ICreateFedexBrokersPayload): ng.IPromise<Brokers.IBroker> {
    return this.BrokersResource.createBroker(payload)
      .then((response: Brokers.IBroker) => {
        return response;
      })
      .catch((e: unknown) => {
        throw e;
      });
  }

  updateBroker(
    params: Brokers.IFedexBrokersParams,
    payload: Brokers.IBrokerPayload
  ): ng.IPromise<Brokers.IBroker> {
    return this.BrokersResource.updateBroker(params, payload)
      .then((response: Brokers.IBroker) => {
        return response;
      })
      .catch((e: unknown) => {
        throw e;
      });
  }

  deactivateBroker(params: Brokers.IFedexBrokersParams): ng.IPromise<void> {
    return this.BrokersResource.deactivateBroker(params).catch((e: unknown) => {
      throw e;
    });
  }

  getBrokers(params: Brokers.IFedexGetBrokersParams): ng.IPromise<Brokers.IBrokers> {
    return this.BrokersResource.getBrokers(params)
      .then((brokers: Brokers.IBrokers) => {
        return brokers;
      })
      .catch((e: unknown) => {
        throw e;
      });
  }
}

export { BrokersService };
