import { toastSuccess } from '@client/core/components/react/Toastify';
import { CourierLogo, CourierAccountState, CourierAuthState } from '@client/data/courier';
import template from './courier-create-main.html?raw';
import style from './courier-create-main.module.scss';

class CourierCreateMain {
  static $inject = [
    '$stateParams',
    '$timeout',
    '$scope',
    '$translate',
    'MixpanelService',
    'ContactSalesModal',
    'CourierCreate',
    'CourierDetails',
    'CourierService',
    'UserSession',
    'HelperService',
    'CourierAccounts',
  ];

  constructor(
    $stateParams,
    $timeout,
    $scope,
    $translate,
    MixpanelService,
    ContactSalesModal,
    CourierCreate,
    CourierDetails,
    CourierService,
    UserSession,
    HelperService,
    CourierAccounts
  ) {
    this.style = style;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$translate = $translate;
    this.MixpanelService = MixpanelService;
    this.ContactSalesModal = ContactSalesModal;
    this.CourierCreate = CourierCreate;
    this.CourierDetails = CourierDetails;
    this.CourierService = CourierService;
    this.UserSession = UserSession;
    this.HelperService = HelperService;
    this.CourierAccounts = CourierAccounts;

    this.onBackToChooseCourier = this.onBackToChooseCourier.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onDeleteSuccess = this.handleDeleteSuccess.bind(this);
    this.onChooseCourier = this.onChooseCourier.bind(this);
    this.onCloseCourierCreateModal = this.onCloseCourierCreateModal.bind(this);
    this.onShowUpgradeModal = this.onShowUpgradeModal.bind(this);
    this.esOnContactUsClick = this.esOnContactUsClick.bind(this);

    this.STEP_1 = 'choose';
    this.STEP_2 = 'connect';
    this.STEP_3 = 'finish';
    this.STEP_CONTACT = 'contact sales';
    this.STEP_TNC = 'tnc';
    this.STEP_UPS_READY_2024_FLOW = 'ups-ready-2024';

    this.courierData = {};

    this.step = this.STEP_1;
    this.isUpsReady2024BackButtonEnabled = true;
  }

  $onInit() {
    if (this.esSelectedCourier) {
      this.onChooseCourier(this.esSelectedCourier, this.esSelectedCountry);
    }

    if (this.esCourierData) {
      this.onChooseCourier(
        this.HelperService.sanitizedSlug(this.esCourierData.umbrella_name),
        this.esSelectedCountry
      );
    }

    if (this.$stateParams.show_step === 'success') {
      this.linkedCourier = this.esCourierData;
      this.step = this.STEP_3;
      this.MixpanelService.track('Courier - Add Service - Connect - Success', {
        courier_name: this.linkedCourier.umbrella_name,
      });
    }
  }

  onCloseCourierCreateModal() {
    this.esClose();
  }

  onShowUpgradeModal() {
    this.esShowUpgradeModal();
  }

  esOnContactUsClick() {
    this.CourierCreate.close();

    this.$timeout(() => {
      this.ContactSalesModal.open('Link courier');
    }, 200);
  }

  // TBD: Fix typing so selectedCourier is no longer ILyocAvailability | string
  onChooseCourier(selectedCourier, selectedCountry) {
    this.MixpanelService.track('Courier - Add Service - Select Service', {
      courier_name: selectedCourier.umbrellaName,
    });

    if (selectedCourier.contact_sales) {
      this.CourierCreate.close();

      this.$timeout(() => {
        this.ContactSalesModal.open('Link courier', selectedCourier.umbrellaName);
      }, 200);

      return;
    }

    this.country = selectedCountry;
    if (this.esCourierData) {
      const { umbrella_name, logo_url, account, id, errors, status } = this.esCourierData;
      this.courier = {
        visible: true,
        contact_sales: false,
        name: umbrella_name,
        umbrellaName: umbrella_name,
        logoUrl: logo_url,
        id,
        account,
        status,
        errors,
        country_id: selectedCountry.id,
      };
    } else {
      this.courier = { ...selectedCourier, country_id: selectedCountry.id };
    }

    if (this.courier.logoUrl === CourierLogo.Ups) {
      this.step = this.STEP_UPS_READY_2024_FLOW;
    } else {
      this.step = this.STEP_2;
    }

    this.$scope.$evalAsync();
  }

  onBackToChooseCourier(step) {
    if (step === this.STEP_TNC) {
      this.MixpanelService.track('Courier - UPS Terms and Conditions - Back');
    } else {
      this.MixpanelService.track('Courier - Add Service - Back');
    }

    if (this.esIsLyocOnly) {
      this.esClose();
      return;
    }

    this.step = this.STEP_1;

    this.$scope.$evalAsync();
  }

  handleDeleteSuccess() {
    toastSuccess(
      this.$translate.instant('toast.delete-success', { noun: this.courier.account.nickname })
    );
    this.esClose();
  }

  handleCloseModal() {
    this.CourierCreate.close();
  }

  onConnectCourier(linkedCourier) {
    this.MixpanelService.track('Courier - Add Service - Connect - Success', {
      courier_name: linkedCourier.umbrella_name,
    });

    if (this._hasPendingCertification(linkedCourier)) {
      this.CourierCreate.close();

      this.$timeout(() => {
        this.CourierDetails.open(linkedCourier);
      }, 200);
    }

    this.linkedCourier = linkedCourier;
    this.step = this.STEP_3;
  }

  acceptTermsAndConditions() {
    this.MixpanelService.track('Courier - UPS Terms and Conditions - Accepted');
    this.step = this.STEP_2;
  }

  _hasPendingCertification(courier = {}) {
    return (
      courier.auth_state === CourierAuthState.PartiallyVerified &&
      courier.account_state === CourierAccountState.Pending &&
      ['FedEx', 'Royal Mail'].includes(courier.umbrella_name)
    );
  }
}

const CourierCreateMainComponent = {
  controller: CourierCreateMain,
  template,
  bindings: {
    esSelectedCourier: '<',
    esSelectedCountry: '<',
    esCourierData: '<',
    esShowUpgradeModal: '&',
    esIsLyocOnly: '<',
    esClose: '&',
  },
};

export { CourierCreateMainComponent };
