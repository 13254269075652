import { IComponentController } from 'angular';
import { toastSuccess } from '@client/core/components/react/Toastify';
import template from './subscription-modal-controller.html?raw';
import style from './subscription-modal-controller.module.scss';

class SubscriptionModalController implements IComponentController {
  style = style;

  static $inject = ['$location', '$translate'];
  constructor(
    private $location: ng.ILocationService,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit() {
    const { upgrade_plan: upgradePlan, downgrade_plan: downgradePlan } = this.$location.search();
    if (!upgradePlan && !downgradePlan) return;
    toastSuccess(
      this.$translate.instant(
        `subscription.${upgradePlan ? 'upgrade-modal' : 'downgrade-modals'}.success`,
        { plan: upgradePlan || downgradePlan }
      )
    );
  }
}

const SubscriptionModalControllerComponent: ng.IComponentOptions = {
  controller: SubscriptionModalController,
  template,
  bindings: {},
};

export { SubscriptionModalControllerComponent };
