import angular from 'angular';

import statesService from './states';
import changePlanIntegrationSharedService from './change-plan';
import onboardingChecklistService from './onboarding-checklist';
import tagsService from './tags';
import uploadErrorModalService from './upload-error-modal';
import reportsService from './reports';
import printingOptionsService from './printing-options';
import bulkEditService from './bulk-edit';
import picklistService from './picklist';
import packingSlipService from './packing-slip';
import collectBulkService from './collect-bulk';
import advancedSearchComponentApiNormalizeService from './advanced-search-component-api-normalize';
import pickupHandlers from './pickup-handlers';
import bulkCancel from './bulk-cancel';
import restrictions from './restrictions';
import platforms from './platforms';
import userRole from './user-role';

angular.module('app.global.services', [
  'core.config',
  'ngResource',
  userRole,
  statesService,
  onboardingChecklistService,
  tagsService,
  uploadErrorModalService,
  reportsService,
  printingOptionsService,
  bulkEditService,
  picklistService,
  packingSlipService,
  collectBulkService,
  advancedSearchComponentApiNormalizeService,
  changePlanIntegrationSharedService,
  pickupHandlers,
  bulkCancel,
  restrictions,
  platforms,
]);
