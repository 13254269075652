import angular from 'angular';

import claimCta from './claim-cta';
import originBreakdown from './origin-breakdown';
import insuranceBreakdown from './insurance-breakdown';
import claimSummary from './claim-summary';
import shipmentCosts from './shipment-costs';
import returnLabel from './return-label';

const MODULE_NAME = 'app.manage-shipments.shipment-information';

angular.module(MODULE_NAME, [
  claimCta,
  originBreakdown,
  insuranceBreakdown,
  claimSummary,
  shipmentCosts,
  returnLabel,
]);

export default MODULE_NAME;
