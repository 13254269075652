import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import { Alert, Button, Card, CardContent, Chip } from 'easyship-components';
import { Plus } from 'easyship-components/icons';
import { IInvitee, IUser } from 'typings/user-session';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
// eslint-disable-next-line import/no-named-as-default
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import { IGoogleAnalyticsService } from 'typings/core/services/google-analytics';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { useCurrentSubscriptionQuery } from '@client/src/global/hooks/useCurrentSubscription';
import { FEATURE_KEY } from '@client/data/subscription';
import {
  isFeatureMaxLimitReached,
  isSubscriptionAvailable,
} from '@client/src/account/subscription/utils/business-logic';
import { ConfirmModal } from '@client/src/global/components/confirm-modal/ConfirmModal';
import useCompanyUsersQuery from '@client/src/account/companyAccount/hooks/queries/useGetCompanyUsers';
import { TeamMemberModal } from './TeamMemberModal';
import useCompanyInviteesQuery from '../hooks/queries/useCompanyInviteesQuery';
import useDeleteInviteeMutation from '../hooks/mutations/useDeleteInviteeMutation';
import { InviteeGatewayProvider } from '../providers/InviteeGatewayProvider';
import { UserRoleGatewayProvider } from '../providers/UserRoleGatewayProvider';

interface ManageTeamProps {
  onUpgradePlan: () => void;
  googleAnalytics: IGoogleAnalyticsService;
}

function MemberChip({ member }: { member: IUser }) {
  const { formatMessage } = useIntl();
  const t = (id: string) => formatMessage({ id });

  if (member.user_state === 'suspended') {
    return <Chip color="red">{t('global.inactive')}</Chip>;
  }

  if (member.role.id === 10) {
    return <Chip rounded>{t('global.owner')}</Chip>;
  }

  return null;
}

function ManageTeamListComponent({
  onUpgradePlan,
  googleAnalytics: GoogleAnalytics,
}: ManageTeamProps) {
  const { formatMessage } = useIntl();
  const t = (id: string) => formatMessage({ id });

  const {
    user: currentUser,
    features: { canUseTeamRightsAndPermission },
  } = useUserSession();
  const { data: currentSubscription, isLoading: isCurrentSubscriptionLoading } =
    useCurrentSubscriptionQuery();
  const { data: companyUsers = [], isLoading: isCompanyUsersLoading } = useCompanyUsersQuery();
  const { data: companyInvitees = [], isLoading: isCompanyInviteesLoading } =
    useCompanyInviteesQuery();
  const { mutateAsync: deleteInvitee, isLoading: isDeletingInvitee } = useDeleteInviteeMutation();

  const hasReachedTeamMemberLimit = useMemo(
    () =>
      isFeatureMaxLimitReached(currentSubscription, FEATURE_KEY.TeamMembers) &&
      isSubscriptionAvailable({
        currentUser,
        currentSubscription,
      }),
    [currentSubscription, currentUser]
  );

  const [addNewMemberIsOpen, setAddNewMemberIsOpen] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<IUser | null>(null);
  const [selectedInvitee, setSelectedInvitee] = useState<IInvitee | null>(null);

  const isBusy =
    isCurrentSubscriptionLoading ||
    isCompanyUsersLoading ||
    isCompanyInviteesLoading ||
    isDeletingInvitee;

  const canInviteNewMember =
    !hasReachedTeamMemberLimit &&
    // if beta key is enabled, only account owner can invite, otherwise,
    // the existing behavior is team member can also invite.
    (canUseTeamRightsAndPermission ? currentUser.isOwner : true);

  // The list of user roles to use when adding a new member
  const userRoleList = currentUser.userRoles.map((role) => {
    return {
      ...role,
      is_active: true,
    };
  });

  const onAddNewMemberClick = () => {
    setAddNewMemberIsOpen(true);
  };

  const onMemberClick = (user: IUser) => {
    if (!isBusy) {
      setSelectedMember(user);
    }
  };

  const onInviteeClick = (invitee: IInvitee) => {
    if (!isBusy) {
      setSelectedInvitee(invitee);
    }
  };

  const onTeamMemberModalClose = () => {
    setSelectedMember(null);
    setAddNewMemberIsOpen(false);
  };

  const onInviteSent = (email: string) => {
    setAddNewMemberIsOpen(false);

    GoogleAnalytics.createGAClickButton('Invites Sent');
    MixpanelService.track('Invite Team Member', {
      invitee_email: email,
    });
  };

  const onPermissionsSaved = () => {
    onTeamMemberModalClose();
  };

  /**
   * Callback if member is successfully removed.
   */
  const onMemberRemoved = () => {
    setSelectedMember(null);

    GoogleAnalytics.createGAClickButton('Block User');
  };

  const onDeleteInviteeCancel = () => {
    setSelectedInvitee(null);
  };

  const onDeleteInviteeConfirm = async () => {
    if (!currentUser.isOwner || !selectedInvitee) {
      return;
    }

    try {
      await deleteInvitee(selectedInvitee.id);

      toastSuccess(t('account.team.delete-success'));
    } catch (error) {
      toastError(t('account.team.delete-failed'));
    } finally {
      setSelectedInvitee(null);
    }
  };

  useEffect(() => {
    MixpanelService.track('Team - Visit page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-col gap-8 w-[572px]">
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-normal text-ink-900">{t('account.team.header')}</h1>

          {canInviteNewMember && (
            <Button
              color="primary"
              leftIcon={<Plus />}
              disabled={addNewMemberIsOpen || isBusy}
              onClick={onAddNewMemberClick}
            >
              {t('account.team.add-new-member')}
            </Button>
          )}
        </div>

        {hasReachedTeamMemberLimit && (
          <Alert
            action={
              <Button onClick={onUpgradePlan} className="w-max">
                {t('account.team.upgrade-plan')}
              </Button>
            }
            className="w-full"
          >
            {t('account.team.maximum-team-members-reached-message')}
          </Alert>
        )}

        <div>
          {companyUsers.map((member: IUser) => (
            <Card
              key={member.id}
              className="w-full h-[72px] mb-3"
              onClick={() => onMemberClick(member)}
              variant="normal"
              selected={member.id === selectedMember?.id}
            >
              <CardContent className="flex items-center justify-between px-5 py-4">
                <div className="flex items-center gap-3">
                  <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-blue-700 text-white text-base font-bold">
                    {member.first_name[0].toUpperCase()}
                  </div>
                  <span>{`${member.first_name} ${member.last_name}`}</span>
                </div>
                <MemberChip member={member} />
              </CardContent>
            </Card>
          ))}
          {companyInvitees.map((invitee: IInvitee) => (
            <Card
              key={invitee.id}
              className="w-full h-[72px] mb-3"
              onClick={() => onInviteeClick(invitee)}
              variant="normal"
              selected={invitee.id === selectedInvitee?.id}
            >
              <CardContent className="flex items-center justify-between px-5 py-4">
                <div className="flex items-center gap-3">
                  <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-blue-700 text-white text-base font-bold">
                    {invitee.email[0].toUpperCase()}
                  </div>
                  <span>{invitee.email}</span>
                </div>
                <Chip color="yellow" rounded>
                  {t('account.team.pending-invite')}
                </Chip>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      {addNewMemberIsOpen && (
        <TeamMemberModal
          canUseTeamRightsAndPermission={canUseTeamRightsAndPermission}
          onClose={onTeamMemberModalClose}
          onInviteSent={onInviteSent}
          userIsOwner={currentUser.isOwner}
          userRoleList={userRoleList}
        />
      )}
      {selectedMember && (
        <TeamMemberModal
          canUseTeamRightsAndPermission={canUseTeamRightsAndPermission}
          member={selectedMember}
          onClose={onTeamMemberModalClose}
          onPermissionsSaved={onPermissionsSaved}
          onMemberRemoved={onMemberRemoved}
          userIsOwner={currentUser.isOwner}
          userRoleList={userRoleList}
        />
      )}
      {selectedInvitee && (
        <ConfirmModal
          open
          header={selectedInvitee.email}
          content={<Alert>{t('account.team.team-member.confirm-delete-invite-message')}</Alert>}
          confirmText={t('account.team.team-member.confirm-delete-invite')}
          confirmClassName="min-w-[169px]"
          onCancel={onDeleteInviteeCancel}
          onConfirm={onDeleteInviteeConfirm}
          confirmDisabled={!currentUser.isOwner || isBusy}
        />
      )}
    </>
  );
}

function WrappedManageTeamListComponent(props: ManageTeamProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <UserRoleGatewayProvider>
          <InviteeGatewayProvider>
            <ManageTeamListComponent {...props} />
          </InviteeGatewayProvider>
        </UserRoleGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularManageTeamListComponent = react2angular(
  WrappedManageTeamListComponent,
  ['onUpgradePlan', 'googleAnalytics'],
  []
);
