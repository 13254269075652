import { IComponentController } from 'angular';
import template from './claim-success.html?raw';
import style from '../insurance-claim.module.scss';
import checkImageUrl from '@assets/images/dashboard/insurance-claim/check.svg';

class ClaimSuccess implements IComponentController {
  style = style;
  checkImageUrl = checkImageUrl;
}

const ClaimSuccessComponent: ng.IComponentOptions = {
  controller: ClaimSuccess,
  template,
  bindings: {
    esClose: '&',
    esGoShipmentDetails: '&',
    esShipment: '<',
  },
};

export { ClaimSuccessComponent };
