import moment from 'moment';
import inputTemplate from './datepicker-input.html?raw';
import buttonTemplate from './datepicker-button.html?raw';
import style from './datepicker.module.scss';
import { convertToUIB, convertToMoment } from './moment-uibdate-map';

const DEFAULT_UIB_DATEFORMAT = 'yyyy-MM-dd';

class Datepicker {
  constructor($element) {
    this.$element = $element;
    this.style = style;
    this.datepickerOpen = false;
    this.appendToBody = true;
  }

  $onChanges(changesObj) {
    if (changesObj.ngModel) {
      if (moment.isMoment(changesObj.ngModel.currentValue)) {
        this.ngModel = changesObj.ngModel.currentValue.toDate();
      } else if (typeof changesObj.ngModel.currentValue === 'string') {
        this.ngModel = new Date(changesObj.ngModel.currentValue);
      }

      this._validate();

      if (!changesObj.ngModel.isFirstChange()) {
        this.$ngModelController.$setDirty();
      }
    }

    if (
      (changesObj.esMax && changesObj.esMax.currentValue) ||
      (changesObj.esMin && changesObj.esMin.currentValue) ||
      (changesObj.esAllowedDates && changesObj.esAllowedDates.currentValue) ||
      (changesObj.esDayModeOnly && changesObj.esAllowedDates.esDayModeOnly)
    ) {
      this._setOptions();
    }
  }

  $onInit() {
    this._validateBindings();

    this._setOptions();

    this.dateFormat =
      (this.esDateFormat && convertToUIB(this.esDateFormat)) || DEFAULT_UIB_DATEFORMAT;
    this.momentFormat = convertToMoment(this.dateFormat);

    if (this.esAppendToBody === false) {
      this.appendToBody = false;
    }
  }

  _setOptions() {
    this.datepickerOptions = {
      ...(this.esMax ? { maxDate: this.esMax } : {}),
      ...(this.esMin ? { minDate: this.esMin } : {}),
      ...(this.esAllowedDates
        ? { dateDisabled: (dateObj) => this._dateIsDisallowed(dateObj) }
        : {}),
      ...(this.esDayModeOnly ? { maxMode: 'day' } : {}),
    };
  }

  onInputFocus() {
    if (!this.esReadOnly) {
      this.datepickerOpen = true;
    }
  }

  onInputChange() {
    this.ngChange({ value: this.ngModel });
    this.$ngModelController.$setDirty();
  }

  onButtonClick() {
    this.datepickerOpen = !this.datepickerOpen;
  }

  getFormattedDate() {
    return this.ngModel ? moment(this.ngModel).format(this.momentFormat) : '';
  }

  hasLabel() {
    return !!angular.element('ng-transclude', this.$element).html();
  }

  onKeyDown($event) {
    if (this.esDisabledKeyboard) {
      $event.preventDefault();
    }
  }

  /**
   * See dateDisabled in http://angular-ui.github.io/bootstrap/versioned-docs/1.3.3/#/datepickerPopup
   * @param {object} dateObj {date: date, mode: mode}
   * @returns {boolean}
   */
  _dateIsDisallowed(dateObj) {
    const allowed = this.esAllowedDates.some((date) => moment(date).isSame(dateObj.date, 'day'));
    return !allowed;
  }

  _validate() {
    if (this.esMin) {
      const selectedDate = moment(this.ngModel);
      const valid = selectedDate.isAfter(this.esMin) || selectedDate.isSame(this.esMin, 'day');
      this.$ngModelController.$setValidity('min', valid);
    }

    if (this.esMax) {
      const selectedDate = moment(this.ngModel);
      const valid = selectedDate.isBefore(this.esMax) || selectedDate.isSame(this.esMax, 'day');
      this.$ngModelController.$setValidity('max', valid);
    }

    if (this.esAllowedDates) {
      const valid = !this._dateIsDisallowed({ date: this.ngModel });

      this.$ngModelController.$setValidity('allowedDates', valid);
    }
  }

  _validateBindings() {
    if (typeof this.esId !== 'string' || !this.esId.length) {
      throw new Error('EasyshipDatepicker: An es-id binding must be provided.');
    }
  }
}
Datepicker.$inject = ['$element'];

const template = ($element, $attrs) =>
  $attrs.esType === 'button' ? buttonTemplate : inputTemplate;

const DatepickerComponent = {
  controller: Datepicker,
  template,
  require: {
    $ngModelController: 'ngModel',
  },
  transclude: true,
  bindings: {
    esId: '@',
    esDateFormat: '@',
    esPlaceholder: '<',
    esMin: '<',
    esMax: '<',
    esAllowedDates: '<',
    esDayModeOnly: '<',
    ngModel: '<',
    ngChange: '&',
    ngDisabled: '<',
    esReadOnly: '<',
    esDisabledKeyboard: '<',
    esAppendToBody: '<',
  },
};
template.$inject = ['$element', '$attrs'];

export { DatepickerComponent };
