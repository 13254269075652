import angular from 'angular';

import { CancelModalComponent } from './cancel-modal.component';

const MODULE_NAME = 'app.pickups.cancel-modal';

angular
  .module(MODULE_NAME, ['app.pickups'])
  .component('esPickupsCancelModal', CancelModalComponent);

export default MODULE_NAME;
