import { ShipmentsTableColumnKey } from 'typings/user-session';

export type ColumnVisibilityMap = Record<ShipmentsTableColumnKey, boolean>;

export const ShipmentsTableColumn = {
  ORDER: 'order_column',
  PACKAGE: 'package_column',
  ITEMS: 'items_column',
  RECEIVER: 'receiver_column',
  COURIER: 'courier_column',
  TAGS: 'tags_column',
  TRACKING: 'tracking_column',
  HANDOVER: 'handover_column',
  REMARKS: 'remarks_column',
  TAX_DUTY: 'import_tax_and_duty_column',
  COLLECT_STATUS: 'collection_status_column',
  TOTAL_COST: 'total_cost_column',
} as const;

export const SHIPMENTS_TABLE_ALL_COLUMNS = Object.values(ShipmentsTableColumn);
export const getMapOfAllColumns = (
  defaultVisibility = false,
  isTaxCollectEnabled?: boolean
): ColumnVisibilityMap =>
  SHIPMENTS_TABLE_ALL_COLUMNS.reduce((acc, column) => {
    if (!isTaxCollectEnabled && column === ShipmentsTableColumn.COLLECT_STATUS) {
      return acc;
    }
    return { ...acc, [column]: defaultVisibility };
  }, {} as ColumnVisibilityMap);
