import React, { ElementType } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { twMerge } from 'tailwind-merge';

const useStyles = makeStyles(() => ({
  none: {
    borderRadius: '4px',
    overflow: 'hidden',
  },
  md: {
    width: 25,
    height: 19,
    borderRadius: '4px',
    overflow: 'hidden',

    '& > img': {
      width: 25,
      height: 19,
    },
  },
  sm: {
    width: 20,
    height: 16,
    borderRadius: '2px',
    overflow: 'hidden',

    '& > img': {
      width: 20,
      height: 16,
    },
  },
  lg: {
    width: 40,
    height: 40,
    borderRadius: '8px',
    overflow: 'hidden',
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > img': {
      width: 28,
      height: 28,
    },
  },
}));

interface CourierLogoProps {
  slug: string;
  size?: 'md' | 'sm' | 'lg' | 'none';
  component?: ElementType;
  className?: string;
  classImage?: string;
}

export default function CourierLogo({
  slug,
  size = 'md',
  component,
  className,
  classImage,
}: CourierLogoProps) {
  const classes = useStyles();

  return (
    <Box
      component={component}
      className={twMerge(
        `flex justify-center items-center`,
        classes[size],
        slug === 'ups' && '!overflow-visible',
        className
      )}
      flexShrink={0}
    >
      <img
        src={`https://assets.easyship.com/app/courier-logos/${slug}-mini.svg`}
        alt={slug}
        style={{
          objectFit: 'contain',
          maxWidth: 'unset',
        }}
        className={twMerge(slug === 'ups' ? '!h-[24px]' : '', classImage)}
      />
    </Box>
  );
}
