import template from './uploader.html?raw';
import style from './uploader.module.scss';
import fileImage from './assets/file.svg?svgo';

class Uploader {
  static $inject = ['Upload', '$sce', '$element', '$timeout', '$translate', 'UserSession'];

  constructor(Upload, $sce, $element, $timeout, $translate, UserSession) {
    this.style = style;
    this.Upload = Upload;
    this.$sce = $sce;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$translate = $translate;
    this.translations = {};
    this.id = Math.floor(Math.random() * 10000);
    this.buttonId = `uploader-button-${this.id}`;
    this.previewId = `uploader-preview-${this.id}`;
    this.UserSession = UserSession;
    this.esHasUserRight = true;
  }

  $onInit() {
    this.fileImageMarkup = this.$sce.trustAsHtml(fileImage);

    this.$translate(['uploader.csv-type', 'uploader.image-type']).then((translations) => {
      this.translations = translations;
    });
  }

  uploadFile(selectedFile) {
    if (selectedFile && this._validateFile(selectedFile)) {
      this.esOnStartUploadFile();

      const focusedButton = document.querySelector(`#${this.buttonId}`);
      focusedButton.blur();

      this.Upload.upload({
        url: this.esUploadUrl,
        data: { file: selectedFile },
      })
        .then((response) => {
          this.esError = null;
          this.esOnFinishUploadFile({ response });
        })
        .catch((error) => {
          this.esOnFinishUploadFile({ error });
        });
    }
  }

  _validateFile(file) {
    const unit = this.esFileSize < 1 ? 'kB' : 'MB';
    const size = this.esFileSize < 1 ? Math.round(this.esFileSize * 1024) : this.esFileSize;

    const validate = {
      csv: {
        rule: /[.](xls|csv|xlsx|ods)$/i,
        error: this.translations['uploader.csv-type'],
      },
      image: {
        rule: /[.](jpg|jpeg|png)$/i,
        error: this.translations['uploader.image-type'],
      },
      size: {
        limit: this.esFileSize * 1024 * 1024, // in bytes
        error: this.$translate.instant(
          'uploader.size-error',
          { name: file.name, size, unit },
          'messageformat'
        ),
      },
    };

    if (this.esMimeType && !validate[this.esMimeType].rule.test(file.name)) {
      this.esError = validate[this.esMimeType].error;
      return false;
    }

    if (this.esFileSize && file.size > validate.size.limit) {
      this.esError = validate.size.error;
      return false;
    }

    return true;
  }

  onDelete() {
    this.esOnDeleteFile();
    this.esError = null;
  }

  // set the maximum preview image size to ensure that the size matches the rendered container when all ng-classes have been applied. Setting the preview container $onInit is not enough as angular has not interpolated all the expressions (including ones in ng-class) resulting in incorrect previewContainer dimensions being set. Although this would be run in every digest cycle, the dom query is cached to minimize the performance hit.
  getMaxImageHeight() {
    this.previewContainer = this.previewContainer || document.querySelector(`#${this.previewId}`);
    return `${this.previewContainer ? this.previewContainer.offsetHeight : 150}px`;
  }

  getMaxImageWidth() {
    this.previewContainer = this.previewContainer || document.querySelector(`#${this.previewId}`);
    return `${this.previewContainer ? this.previewContainer.offsetWidth : 400}px`;
  }

  get isUploadDisabled() {
    return this.esImageUrl || this.esLoading || !this.esHasUserRight;
  }
}

const UploaderComponent = {
  controller: Uploader,
  template,
  transclude: {
    information: '?uploaderInformation',
  },
  bindings: {
    esLabel: '@',
    esButton: '@',
    esLoading: '<',
    esHasUserRight: '<?',
    esUploadUrl: '<',
    esImageUrl: '<',
    esOnStartUploadFile: '&',
    esOnFinishUploadFile: '&',
    esOnDeleteFile: '&',
    esMimeType: '@',
    esFileSize: '@',
    esError: '<',
  },
};

export { UploaderComponent };
