import angular from 'angular';

import { BatteryServicesComponent } from './battery-services.component';

const MODULE_NAME = 'app.create-shipments.basic.battery-services';

angular
  .module(MODULE_NAME, [])
  .component('esBatteryServices', BatteryServicesComponent);

export default MODULE_NAME;
