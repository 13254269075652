import { IAddress, AddressDefaultValueKey } from 'typings/address';

import { IComponentController } from 'angular';
import template from './select-address.html?raw';
import style from './select-address.module.scss';

class SelectAddressController implements IComponentController {
  style = style;
  esOptions = [] as IAddress[];
  esSelectedId?: string;
  esFallback?: AddressDefaultValueKey;
  esNameOnly = false;
  esTooltip = false;
  esOnChange?: (payload: { id: string; address?: IAddress }) => void;
  isDropdownOpen = false;
  hasLabel = false;
  selectedAddress?: IAddress;

  static $inject = ['$transclude'];
  constructor(private $transclude: ng.ITranscludeFunction) {}

  $onInit(): void {
    this.$transclude((clone) => {
      if (!clone || clone.length === 0) return;
      if (clone.length === 1 && clone[0].textContent?.trim() === '') return;

      this.hasLabel = true;
    });
  }

  $onChanges(): void {
    if (this.esOptions?.length) {
      this.selectedAddress = (this.esOptions.find(
        (address: IAddress) => address.id === this.esSelectedId
      ) ||
        this.esOptions.find(
          // If the address that was selected previously (e.g. for label)
          // has since been deactivated, force the selection of a new address
          (address: IAddress) =>
            address.default_values[(this.esFallback || 'sender') as AddressDefaultValueKey]
        )) as IAddress;

      if (this.esOnChange && this.selectedAddress) {
        this.esOnChange({ id: this.selectedAddress.id, address: this.selectedAddress });
      }
    }
  }

  onToggle(isOpen: boolean) {
    this.isDropdownOpen = isOpen;
  }

  onOptionSelected(selectedAddress: IAddress) {
    this.isDropdownOpen = false;

    if (this.esOnChange) {
      this.esOnChange({ id: selectedAddress.id, address: selectedAddress });
    }
  }

  concatenatedAddress(address: IAddress): string {
    if (!address) return '';

    const {
      line_1,
      line_2,
      city,
      postal_code,
      state,
      contact_name,
      company_name,
      contact_phone,
      contact_email,
    } = address;
    const addressFields = `${line_1 || ''} ${line_2 || ''} ${city || ''} ${postal_code || ''} ${
      state || ''
    }`;
    const contactFields = `${contact_name || ''}, ${company_name || ''}, ${contact_phone || ''}, ${
      contact_email || ''
    }`;

    return ` ${addressFields} - ${contactFields}`.replace(/\s*,?(?:\s+|$)/g, ' ');
  }
}

const SelectAddressComponent = {
  controller: SelectAddressController,
  template,
  transclude: true,
  bindings: {
    esOptions: '<',
    esSelectedId: '<',
    esFallback: '@',
    esNameOnly: '<',
    esTooltip: '<',
    esOnChange: '&',
  },
};

export { SelectAddressComponent };
