import angular, { IComponentController } from 'angular';
import { ICompanyService } from 'typings/company';
import { IFormData } from 'typings/tracking-email';
import { TrackingUpdateStatuses } from '@client/data/tracking-status';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './tracking-updates-modal.module.scss';
import template from './tracking-updates-modal.html?raw';

class TrackingUpdatesModalController implements IComponentController {
  style = style;
  esOpen = false;
  esFormData: IFormData = {
    send_receiver_survey: false,
    send_receiver_tracking: false,
    tracking_email_ad: {
      id: '',
    },
    tracking_email_advertising: {
      call_to_action: '',
      link_url: '',
    },
    tracking_email_delay_hours: 0,
    tracking_email_delay_method: '',
    tracking_email_footnote: '',
    tracking_email_show_company_as_sender: false,
    tracking_email_show_contact: false,
    tracking_email_show_header: false,
    tracking_email_send_by_statuses: [],
    tracking_email_btn_background_color: '',
    tracking_email_btn_text_color: '',
  };
  options = Object.values(TrackingUpdateStatuses);
  busy = false;
  statusesChosen: TrackingUpdateStatuses[] = [];

  static $inject = ['CompanyService', 'MixpanelService', '$translate'];
  constructor(
    private CompanyService: ICompanyService,
    private MixpanelService: MixpanelService,
    private $translate: angular.translate.ITranslateService
  ) {}

  $onInit(): void {
    this.statusesChosen = angular.copy(this.esFormData.tracking_email_send_by_statuses) || [];
  }

  getCheckboxState(status: TrackingUpdateStatuses): boolean {
    return this.statusesChosen.includes(status);
  }

  onCheckboxChange(status: TrackingUpdateStatuses): void {
    if (this.statusesChosen.includes(status)) {
      this.statusesChosen = this.statusesChosen.filter((s) => s !== status);
    } else {
      this.statusesChosen.push(status);
    }
  }

  save(): void {
    this.busy = true;

    this.CompanyService.updateCompany({
      tracking_email_send_by_statuses: this.statusesChosen,
    })
      .then(() => {
        this.closeModal();
        toastSuccess(this.$translate.instant('settings.customer-emails.tracking-updates-updated'));
        this.esFormData.tracking_email_send_by_statuses = this.statusesChosen;
      })
      .catch((error: any) => {
        toastError(
          this.$translate.instant(
            (error.data && error.data.status) ||
              'settings.customer-emails.tracking-updates-update-failed'
          )
        );
      })
      .finally(() => {
        this.busy = false;
        this.MixpanelService.track('Settings - Customer Emails - Send Tracking Updates - Save', {
          statuses: this.statusesChosen,
        });
      });
  }

  closeModal(): void {
    this.esOpen = false;
  }
}

const TrackingUpdatesModalComponent = {
  controller: TrackingUpdatesModalController,
  template,
  bindings: {
    esOpen: '=',
    esFormData: '<',
  },
};

export { TrackingUpdatesModalComponent };
