import { IApiConfig } from 'typings/core/config/api';
import { Reports } from 'typings/reports';

class ReportsResource {
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(private $resource: ng.resource.IResourceService, private API: IApiConfig) {
    this.resource = this.$resource(
      `${this.API.endpoint}/companies/:company_id/reports/:report_id/:controller`,
      {},
      {
        patch: { method: 'PATCH' },
      }
    );
  }

  get(params: Reports.IApiGetParams): ng.IPromise<Reports.IApiGetSuccessResponse> {
    return this.resource.get(params).$promise;
  }

  printed(params: Reports.IApiPrintedParams): ng.IPromise<Reports.IApiPrintedSuccessResponse> {
    return this.resource.patch(
      {
        controller: 'printed',
        ...params,
      },
      {}
    ).$promise;
  }
}

export { ReportsResource };
