import { IComponentController } from 'angular';
import template from './pop-button.html?raw';
import style from './pop-button.module.scss';

class PopButton implements IComponentController {
  style = style;

  constructor() {
    this.style = style;
  }
}

const PopButtonComponent: ng.IComponentOptions = {
  controller: PopButton,
  template,
  transclude: true,
  bindings: { esImgSrc: '@', esSvg: '@' },
};

export { PopButtonComponent };
