import { IComponentController } from 'angular';
import template from './help-guide-section.html?raw';
import style from './help-guide-section.module.scss';

class HelpGuideSectionController implements IComponentController {
  style = style;
}

const HelpGuideSectionComponent = {
  controller: HelpGuideSectionController,
  template,
  bindings: {
    esTopics: '<',
    esTitle: '@',
  },
};

export { HelpGuideSectionComponent };
