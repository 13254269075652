import { IApiConfig } from 'typings/core/config/api';

interface IApiGetTransactionsParams {
  company_id: string;
  invoice_id?: string;
  is_settled?: boolean;
  offset: number;
  limit: number;
  created_at_from?: string;
  created_at_to?: string;
  payment_recipient?: string;
  keyword?: string;
  transactionable_types?: string[];
  balance_type?: string;
  finance_document_id?: string;
  settlement_state?: string;
}

export interface IApiExportParams {
  company_id: string;
}

export interface IApiExportPayload {
  transactionable_types?: string[];
  balance_type?: string;
  keyword: string;
  created_at_from?: string;
  created_at_to?: string;
  payment_recipient?: string;
  finance_document_id?: string;
}

interface IApiDownloadParams {
  company_id: string;
  id: string;
}

class TransactionRecordResource {
  endpoint: string;
  resource: any;

  static $inject = ['$resource', 'API'];
  constructor(public $resource: ng.resource.IResourceService, public API: IApiConfig) {
    this.endpoint = `${API.endpoint}/companies/:company_id/transaction_records/:controller/:id`;
    this.resource = $resource(
      this.endpoint,
      {},
      {
        export: {
          method: 'POST',
          params: {
            controller: 'export',
          },
        },
        download: {
          method: 'GET',
          params: {
            controller: 'export',
          },
          responseType: 'blob',
          transformResponse(response) {
            return { data: response };
          },
        },
      }
    );
  }

  getTransactions(params: IApiGetTransactionsParams) {
    const transactionableTypes = params.transactionable_types;
    const newParams = { ...params, 'transactionable_types[]': transactionableTypes };
    delete newParams.transactionable_types;
    return this.resource.get(newParams).$promise;
  }

  export(params: IApiExportParams, payload: IApiExportPayload) {
    return this.resource.export(params, payload).$promise;
  }

  download(params: IApiDownloadParams) {
    return this.resource.download(params).$promise;
  }
}

export { TransactionRecordResource };
