import angular from 'angular';
// import tippy from 'tippy.js';
// import axios from 'axios';

import 'bootstrap';

// // TODO: Move to component/service level dependencies
import 'angular-ui-bootstrap/src/accordion';
// import 'angular-ui-bootstrap/src/collapse';
import 'angular-ui-bootstrap/src/datepicker';
import 'angular-ui-bootstrap/src/datepickerPopup';
import 'angular-ui-bootstrap/src/modal';
import 'angular-ui-bootstrap/src/progressbar';
import 'angular-ui-bootstrap/src/tooltip';
import 'bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'angular-daterangepicker/js/angular-daterangepicker';
// import 'element-closest-polyfill';

// import 'moment-timezone';

import '@uirouter/angularjs';
import '@uirouter/angularjs/release/stateEvents';
import 'angular-animate';
import 'angular-cookies';
import 'angular-drag-and-drop-lists';
import 'angular-dynamic-number';
import 'angular-recaptcha';
import 'angular-resource';
import 'angular-sanitize';
import 'ngclipboard';
import 'ng-file-upload';
import 'angular-bind-html-compile';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'messageformat';
import 'angular-translate-interpolation-messageformat';

import '@client/app/app';
import '@client/app/routes/tools/automations/components';
import '@client/app/app.scss';
import '@client/src/account';
import '@client/src/create-shipments';
import '@client/src/global';
import '@client/src/initializers';
import '@client/src/couriers';
import '@client/src/webhooks';
import '@client/src/manage-shipments';
import '@client/src/redirection';
import '@client/src/log-in-as';
import '@client/src/tools';
import '@client/src/reschedule-pickup';
import '@client/src/connect';
import '@client/src/analytics';
import '@client/src/product-listing';
import '@client/src/pickups';
import '@client/src/help-guide';
import '@client/src/data-collect-flows';
import '@client/src/onboarding';

/* -- Auth imports */
// import '@client/auth/global';
import 'oclazyload';
import '@client/core/config';
import '@client/auth/plugins/axios';
// import '@client/core/services/survey-sparrow';
// import '@client/app/factories/Session/Session.service';
// import '@client/app/factories/Auth/Auth.service';
// import '@client/app/factories/GoogleAnalytics/GoogleAnalytics.service';
// import '@client/app/factories/Invitee/Invitee.service';
// import '@client/core/filters/index';
// import '@client/src/global/User';
// import '@client/src/global/user-status';
// import '@client/core/services/mixpanel';
// import '@client/app/factories/UserSession/UserSession.service';
// import '@client/core/services/stripe';
// import '@client/src/global/hubspot';
// import '@client/app/services/EndpointService/EndpointService.service';
/* -- Auth imports */

import { AuthRoutes } from '@client/auth/index.routes';
import { DashboardRoutes } from '@client/src/index.routes';

import './client';

angular.module('easyshipDashboardApp').config(AuthRoutes).config(DashboardRoutes);
angular.bootstrap(document.body, ['easyshipDashboardApp'], { strictDi: true });
