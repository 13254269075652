import { IUrlConfig } from 'typings/core/config/url';
import {
  EasyshipLeadStateName,
  PlatformLeadStateName,
  RegistrationFlowStep,
  RegistrationFlowStateName,
  StateParamsPlatform,
  MobileTips,
  InvalidStep,
} from '@client/auth/data/state';
import { IDeviceService } from 'typings/core/services/device';
import { IAuthService } from 'typings/core/services/auth';

export interface IFieldBySteps {
  [key: string]: string[];
}

enum StepModifier {
  Back = -1,
  Next = 1,
}

enum FieldValue {
  Country = 'country',
}

// Add delay to increase success rate of google tag manager http scripts
const SIGNUP_REDIRECTION_DELAY = 1500;

class StepsNavigationService {
  accessibleSteps: string[] = [];
  fieldBySteps: IFieldBySteps = {
    user: [],
    company: [],
    address: [],
    'courier-account': [],
    settings: [],
  };
  private _lastStepReached = '';

  static $inject = [
    '$window',
    '$cookies',
    '$state',
    '$timeout',
    'Auth',
    'UrlPath',
    'DeviceService',
  ];
  constructor(
    private $window: ng.IWindowService,
    private $cookies: angular.cookies.ICookiesService,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private Auth: IAuthService,
    private UrlPath: IUrlConfig,
    private DeviceService: IDeviceService
  ) {}

  getStepIndex(name: string | undefined): number {
    return this.accessibleSteps.findIndex((stepName) => {
      return `${RegistrationFlowStateName}.${stepName}` === name;
    });
  }

  get currentStepIndex(): number {
    return this.getStepIndex(this.$state.current.name);
  }

  get currentStepNumber(): number {
    return this.currentStepIndex + 1;
  }

  get currentStepFields(): string[] {
    return this.fieldBySteps[this.$state.params.step];
  }

  get isLastStep(): boolean {
    return this.currentStepNumber === this.accessibleSteps.length;
  }

  get lastStepReached(): string {
    return this._lastStepReached;
  }

  // TODO: Update backend response and remove `welcome` and `success` steps
  set lastStepReached(lastStepReached: string) {
    this._lastStepReached =
      lastStepReached === 'welcome' ? RegistrationFlowStep.User : lastStepReached;
  }

  back(): void {
    if (this.currentStepNumber === 1) {
      this.goToLead();
    } else {
      this.goToRegistrationFlow(StepModifier.Back);
    }
  }

  backToUserForm(): void {
    let stateUrl: RegistrationFlowStep | null = null;
    if (this.fieldBySteps.address.find((address) => address === FieldValue.Country)) {
      stateUrl = RegistrationFlowStep.Address;
    }

    if (this.fieldBySteps.company.find((company) => company === FieldValue.Country)) {
      stateUrl = RegistrationFlowStep.Company;
    }

    if (this.fieldBySteps.settings.find((setting) => setting === FieldValue.Country)) {
      stateUrl = RegistrationFlowStep.Settings;
    }

    if (this.fieldBySteps.user.find((user) => user === FieldValue.Country)) {
      stateUrl = RegistrationFlowStep.User;
    }

    if (stateUrl) {
      this.goToState(`${RegistrationFlowStateName}.${stateUrl}`);
    }
  }

  next(fieldBySteps: IFieldBySteps): void {
    this.updateAccessibleAndFieldBySteps(fieldBySteps);
    this.goToRegistrationFlow(StepModifier.Next);
  }

  updateAccessibleAndFieldBySteps(fieldBySteps: IFieldBySteps): void {
    const accessibleSteps = [];

    this.fieldBySteps = fieldBySteps;
    const registrationFlowStep: any = RegistrationFlowStep;

    for (const name in registrationFlowStep) {
      if (
        this.fieldBySteps[registrationFlowStep[name]] &&
        this.fieldBySteps[registrationFlowStep[name]].length
      ) {
        accessibleSteps.push(registrationFlowStep[name]);
      }
    }

    this.accessibleSteps = accessibleSteps;
  }

  goToDashboard(redirectParam?: string): void {
    this.$timeout(() => {
      const isDtqAbTest =
        this.$cookies.getObject('AB_TESTS')?.direct_to_quote === 'direct_to_quote_flow';
      this.$window.location.href = `${this.UrlPath.DASHBOARD}?${
        isDtqAbTest ? 'onboarding=true&' : ''
      }welcome${redirectParam || ''}`;
    }, SIGNUP_REDIRECTION_DELAY);
  }

  goToQuote(): void {
    this.$timeout(() => {
      this.$window.location.href = `/quote?onboarding=true`;
    }, SIGNUP_REDIRECTION_DELAY);
  }

  goToAuthenticatedPage(sessionToken: string, redirectParam?: string): void {
    // TODO: Create a cookie service
    this.Auth.setCredentials(sessionToken);
    this.$cookies.putObject('ES_USED_DASHBOARD', true, { domain: 'easyship.com' });

    if (this.DeviceService.isDesktop()) {
      if (this.$cookies.getObject('AB_TESTS')?.direct_to_quote === 'direct_to_quote_flow') {
        this.goToQuote();
      } else {
        this.goToDashboard(redirectParam);
      }
    } else {
      this.$state.go(MobileTips);
    }
  }

  goToInvalidCountryState(countryId: string | string[] | number): void {
    this.$state.go(`${RegistrationFlowStateName}.${InvalidStep.Country}`, {
      countryId,
      plan: this.$state.params.plan,
      billingFrequency: this.$state.params.billing_frequency,
    });
  }

  private goToLead(): void {
    let leadStateName;
    const stateParams: ng.ui.IStateParamsService = {};

    if (this.$state.params.platform !== StateParamsPlatform.Dashboard) {
      leadStateName = PlatformLeadStateName;
    } else {
      leadStateName = EasyshipLeadStateName;
      stateParams.plan = this.$state.params.plan;
      stateParams.billing_frequency = this.$state.params.billingFrequency;
    }

    this.goToState(leadStateName, stateParams);
  }

  private goToRegistrationFlow(stepModifier: StepModifier): void {
    const currentStateIndex = this.currentStepIndex;
    const stepName = this.accessibleSteps[currentStateIndex + stepModifier];
    const stateName = `${RegistrationFlowStateName}.${stepName}`;

    const stateParams = {
      plan: this.$state.params.plan,
      billingFrequency: this.$state.params.billingFrequency,
    };

    this.goToState(stateName, stateParams);
  }

  private goToState(name: string, stateParams: ng.ui.IStateParamsService = {}): void {
    this.$state.go(name, {
      ...stateParams,
      platform: this.$state.params.platform,
      id: this.$state.params.id,
    });
  }
}

export { StepModifier, SIGNUP_REDIRECTION_DELAY, StepsNavigationService };
