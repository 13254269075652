import angular from 'angular';

import subscriptionService from '@client/src/global/services/subscription';
import paymentService from '@client/src/global/payment';

import { PaymentFailedModalComponent } from './modal/modal.component';
import { TrialEndCardComponent } from './trial-end-card/trial-end-card.component';
import { PaymentFailedCardComponent } from './payment-failed-card/payment-failed-card.component';
import { UpgradeBillingInfoComponent } from './upgrade-billing-info/upgrade-billing-info.component';

const MODULE_NAME = 'app.global.components.subscription-payment-failed';

angular
  .module(MODULE_NAME, ['app.factory.UserSession', subscriptionService, paymentService])
  .component('esSubscriptionPaymentFailedModal', PaymentFailedModalComponent)
  .component('esTrialEndCard', TrialEndCardComponent)
  .component('esSubscriptionPaymentFailedCard', PaymentFailedCardComponent)
  .component('esSubscriptionUpgradeBillingInfoCard', UpgradeBillingInfoComponent);

export default MODULE_NAME;
