import { IUserSession } from 'typings/user-session';
import {
  IPickupsPickups,
  IPickupsTotals,
  IPickupsPayload,
  IPickupsSupportInfoSuccessResponse,
  IPickupsReportPayload,
  IPickupsReportSuccessResponse,
  IPickupsMarkAsResolvedSuccessResponse,
  IPickupsCancelSuccessResponse,
  IPickupsEditPickupInfoGetSuccessResponse,
  IPickupsEditPickupInfoPayload,
  IPickupsEditPickupInfoPatchSuccessResponse,
  PickupGroup,
  IPickupsFilters,
  IPickupsPageBusy,
} from 'typings/pickup';
import { toastError } from '@client/core/components/react/Toastify';
import { AdvancedSearchComponentApiNormalizeService } from '../global/services/advanced-search-component-api-normalize/advanced-search-component-api-normalize.service';
import { PickupResource } from '../global/services/pickup/pickups.resource';

const DEFAULT_ITEMS_PER_PAGE = 10;
// Payload that we always need to send to the BE
const FILTER_BASE_PAYLOAD = {
  handover_type: {
    type: 'multi_select',
    operator: 'is_any_of',
    values: ['pickup'],
  },
};

class PickupsPageService {
  busy: IPickupsPageBusy = {
    gettingPickups: true,
  };
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
  selectedState: PickupGroup | null = null;
  currentPage = 1;
  filters: IPickupsFilters = { address: null, couriers: null, date: null };
  isFiltered = false;
  pickups: IPickupsPickups[] = [];
  totals: IPickupsTotals | null = null;

  static $inject = [
    '$translate',
    'UserSession',
    'PickupResource',
    'AdvancedSearchComponentApiNormalizeService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private PickupResource: PickupResource,
    private AdvancedSearchComponentApiNormalizeService: AdvancedSearchComponentApiNormalizeService
  ) {
    this.itemsPerPage = this.UserSession.getItemsPerPageValue('pickups') || DEFAULT_ITEMS_PER_PAGE;
  }

  getPickups(): ng.IPromise<void> {
    this.busy.gettingPickups = true;

    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.get(
      { company_id: companyId },
      {
        ...this.filtersPayload,
        limit: this.itemsPerPage,
        offset: (this.currentPage - 1) * this.itemsPerPage,
      }
    )
      .then(({ pickups }) => {
        this.pickups = pickups;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.busy.gettingPickups = false;
      });
  }

  updateTotals(): ng.IPromise<void> {
    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.totals(
      { company_id: companyId },
      {
        ...this.filtersPayload,
      }
    )
      .then(({ totals }) => {
        this.totals = totals;
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  getSupportInfo(pickupId: string): ng.IPromise<IPickupsSupportInfoSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.getSupportInfo({
      company_id: companyId,
      pickup_id: pickupId,
    });
  }

  report(
    pickupId: string,
    payload: IPickupsReportPayload
  ): ng.IPromise<IPickupsReportSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.report(
      {
        company_id: companyId,
        pickup_id: pickupId,
      },
      payload
    );
  }

  markAsResolved(pickupId: string): ng.IPromise<IPickupsMarkAsResolvedSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.markAsResolved({
      company_id: companyId,
      pickup_id: pickupId,
    });
  }

  cancel(pickupId: string): ng.IPromise<IPickupsCancelSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.cancel({
      company_id: companyId,
      pickup_id: pickupId,
    });
  }

  editPickupInfo(pickupId: string): ng.IPromise<IPickupsEditPickupInfoGetSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.editPickupInfo({
      company_id: companyId,
      pickup_id: pickupId,
    });
  }

  updatePickupInfo(
    pickupId: string,
    payload: IPickupsEditPickupInfoPayload
  ): ng.IPromise<IPickupsEditPickupInfoPatchSuccessResponse> {
    const companyId = this.UserSession.getCompanyId();

    return this.PickupResource.updatePickupInfo(
      {
        company_id: companyId,
        pickup_id: pickupId,
      },
      payload
    );
  }

  private get filtersPayload() {
    const payload: IPickupsPayload = {};

    if (this.selectedState) {
      payload.scope = this.selectedState;
    }

    return {
      ...FILTER_BASE_PAYLOAD,
      ...payload,
      ...this.normalizeFiltersPayload,
    };
  }

  private get normalizeFiltersPayload() {
    const payload: IPickupsPayload = {};

    if (this.filters.couriers) {
      payload.handler_name = this.AdvancedSearchComponentApiNormalizeService.normalizeHandler(
        this.filters.couriers
      );
    }

    if (this.filters.date) {
      payload.selected_date = this.AdvancedSearchComponentApiNormalizeService.normalizeDate(
        this.filters.date
      );
    }

    if (this.filters.address) {
      payload.address_id = this.AdvancedSearchComponentApiNormalizeService.normalizeAddress(
        this.filters.address
      );
    }

    return payload;
  }
}

export { PickupsPageService };
