import template from './address-form-contact-info.html?raw';

(function () {
  'use strict';

  var addressFormContactInfo = {
    template,
    bindings: {
      address: '=',
      companyNameMaxLength: '<',
    },
    controller: 'addressFormContactInfoCrtl as ctrl',
  };

  angular
    .module('app.component.address-form-contact-info')
    .component('addressFormContactInfo', addressFormContactInfo);
})();
