import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { API } from '@client/core/config/api.constant';
import Tooltip from '@client/core/components/react/Tooltip';
import Link from '@client/core/components/react/Link';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

export interface CategoryHsCodeLabelProps {
  color?: string;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    width: 200,
  },
}));

function CategoryHsCodeLabel({ color }: CategoryHsCodeLabelProps) {
  const theme = useTheme();
  const classes = useStyles();

  const helpUrl = `${API.help}/hc/en-us/articles/360054370412-Product-Descriptions-Categories-Dangerous-Goods`;

  const fontColor = color || theme.palette.ink.main;

  return (
    <Grid container alignItems="center">
      <Typography variant="body2" style={{ color: fontColor }}>
        <FormattedMessage id="shipments.category-hs-codes.label" />
      </Typography>

      <Box ml={0.625} clone>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <Typography variant="body2">
              <FormattedMessage id="shipments.category-hs-codes.hs-codes-tooltip" />{' '}
              <Typography variant="body2" component="span">
                <Link href={helpUrl} target="_blank">
                  <FormattedMessage id="global.learn-more" />
                </Link>
              </Typography>
            </Typography>
          }
          interactive
        />
      </Box>
    </Grid>
  );
}

export default function WrappedCategoryHsCodeLabel(props: CategoryHsCodeLabelProps) {
  return (
    <ReactRootProviders>
      <CategoryHsCodeLabel {...props} />
    </ReactRootProviders>
  );
}
