(function () {
  'use strict';

  CountryCMS.$inject = ['$resource', 'API'];
  function CountryCMS($resource, API) {
    var endpointUrl = API.cms_api;

    return $resource(
      endpointUrl + '/countries/:slug',
      {
        slug: '@_slug',
      },
      {
        query: {
          method: 'GET',
          params: {
            // id: 'me'
          },
        },
      }
    );
  }

  angular.module('app.factory.CountryCMS', []).factory('CountryCMS', CountryCMS);
})();
