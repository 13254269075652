import template from './store-select-card.html?raw';

(function () {
  'use strict';

  angular.module('easyshipDashboardApp').directive('storeSelectCard', storeSelectCard);

  function storeSelectCard() {
    var directive = {
      template,
      restrict: 'EA',
      scope: {
        platformName: '@',
        platformCss: '@',
      },
      controller: controller,
    };

    return directive;
  }

  function controller() {}
})();
