/* eslint-disable no-param-reassign */
import { toastError } from '@client/core/components/react/Toastify';

(function () {
  ShipmentHelpers.$inject = ['ShipmentCache', 'UserSession', 'Shipment', '$translate'];
  function ShipmentHelpers(ShipmentCache, UserSession, Shipment, $translate) {
    const service = this;

    service.editMode = { order: false, receiver: false };
    service.loading = {
      order: false,
      receiver: false,
    };

    /**
     * [saveToClipboard] Store a shipment, an index and a pageNum in the cache
     * Need ShipmentCache service
     * @param  {Object} shipment
     * @param  {Integer} pageNum
     * @param  {Integer} index
     */
    service.saveToClipboard = function (pageNum, index) {
      const shipment = ShipmentCache.pages[pageNum].shipments[index];

      // Save copy into the clipboard
      ShipmentCache.clipboard.shipment = angular.copy(shipment);

      // Save localisation if the shipment into the clipboard
      ShipmentCache.clipboard.pageNum = pageNum;
      ShipmentCache.clipboard.index = index;
    };

    // Empty the clipboard object
    service.clearClipboard = function () {
      ShipmentCache.clipboard = {};
    };

    service.openField = function (shipment, index, errorKeyName, fieldToOpen) {
      const selectedShipment = ShipmentCache.pages[ShipmentCache.currentPage].shipments[index];
      if (fieldToOpen === 'showOrder') {
        service.loading.order = true;
        selectedShipment.loading = {
          order: true,
        };
      }

      if (fieldToOpen === 'showReceiver') {
        service.loading.receiver = true;
        selectedShipment.loading = {
          receiver: true,
        };
      }

      Shipment.query(
        {
          company_id: UserSession.company.id,
          company_type: UserSession.company.type,
          id: shipment.id,
          include: 'shipment_items,list_of_couriers,orders_totals,store',
        },
        function (data) {
          if (data && data.shipment) {
            ShipmentCache.pages[ShipmentCache.currentPage].shipments[index] = angular.merge(
              shipment,
              data.shipment
            );

            const field = fieldToOpen.replace('show', '');

            // If there are errors, turn on the editMode on open
            shipment.logs && shipment.logs.price_calculation[errorKeyName]
              ? service.setEditMode(true, field)
              : service.setEditMode(false, field);

            // Open the dropdown of the designated field
            shipment[fieldToOpen] = true;

            // Save a new shipment with his pageNum and his index in the clipboard
            service.saveToClipboard(ShipmentCache.currentPage, index);
          } else {
            toastError($translate.instant('toast.default-error'));
          }

          service.loading.order = false;
          service.loading.receiver = false;

          if (selectedShipment.loading) {
            delete selectedShipment.loading;
          }
        },
        function () {
          toastError($translate.instant('toast.default-error'));
          service.loading.order = false;
          service.loading.receiver = false;

          if (selectedShipment.loading) {
            delete selectedShipment.loading;
          }
        }
      );
    };

    service.closeField = function (shipment, fieldToOpen) {
      // Close this dropdown
      shipment[fieldToOpen] = false;

      // Clear clipboard
      service.clearClipboard();
    };

    service.closeAllFields = function (shipment) {
      shipment.showOrder = false;
      shipment.showReceiver = false;

      // Clear clipboard
      service.clearClipboard();
    };

    /**
     * [toggle] Open/Close dropdowns in the orders page
     * @param  {String} field   name of the dropdown to open: 'Order' or 'Receiver'
     *                          /!\ First letter has to be capital
     * @param  {Integer} pageNum of the shipment that has been clicked on
     * @param  {Integer} index of the shipment that has been clicked on
     */
    service.toggle = function (field, index, errorKeyName) {
      // Find the shipment with the pageNum and index in the cache
      const shipment = ShipmentCache.pages[ShipmentCache.currentPage].shipments[index];

      // Build show string
      const fieldToOpen = `show${field}`;

      // One of the dropdown if opened and not on editMode
      if (shipment.showOrder || shipment.showReceiver) {
        if (shipment[fieldToOpen]) {
          service.closeField(shipment, fieldToOpen);
        } else if (!shipment[fieldToOpen]) {
          service.closeAllFields(shipment);
          service.openField(shipment, index, errorKeyName, fieldToOpen);
        }
      }
      // None of the dropdown are open
      else {
        service.openField(shipment, index, errorKeyName, fieldToOpen);
      }
    };

    /**
     * @param {Boolean} value the value to set as the property in editMode
     * @param {String} key the property to be set in editMode. If none are passed, we set all of the keys at once
     */
    service.setEditMode = function (value, key) {
      if (!key) {
        service.editMode = { order: value, receiver: value };
        return;
      }

      const lowercaseKey = key.slice(0, 1).toLowerCase() + key.slice(1);

      service.editMode[lowercaseKey] = value;
    };

    /**
     * @param {String} key the property value in editMode to be returned. If none are passed, we return true if any of the property is set to true
     * @return {Boolean}
     */
    service.getEditMode = function (key) {
      if (key) {
        const lowercaseKey = key.slice(0, 1).toLowerCase() + key.slice(1);

        return service.editMode[lowercaseKey];
      }

      return Object.values(service.editMode).some((value) => value);
    };
  }

  angular
    .module('app.service.ShipmentHelpers', ['app.service.ShipmentCache'])
    .service('ShipmentHelpers', ShipmentHelpers);
})();
