import { IComponentController } from 'angular';
import template from './confirm-delete-member-card.html?raw';
import style from './confirm-delete-member-card.module.scss';

class ConfirmDeleteMemberCard implements IComponentController {
  style = style;
}

const ConfirmDeleteMemberCardComponent: ng.IComponentOptions = {
  controller: ConfirmDeleteMemberCard,
  template,
  bindings: {
    onDelete: '&',
    onClose: '&',
  },
};

export { ConfirmDeleteMemberCardComponent };
