import angular from 'angular';

import { AutoRechargeService } from './auto-recharge.service';
import { AutoRechargeResource } from './auto-recharge.resource';

const MODULE_NAME = 'app.global.services.auto-recharge';

angular
  .module(MODULE_NAME, [])
  .service('AutoRechargeService', AutoRechargeService)
  .service('AutoRechargeResource', AutoRechargeResource);

export default MODULE_NAME;
