import angular from 'angular';
import { IUserSession } from 'typings/user-session';

const MODULE_NAME = 'app.filter.intlNumber';

intlNumber.$inject = ['$filter', 'UserSession'];
function intlNumber($filter: ng.IFilterService, UserSession: IUserSession) {
  return (
    value: number | string = 0,
    decimalCount?: number,
    options?: Intl.NumberFormatOptions
  ): string => {
    const valueAsNumber = Number(value) || 0;

    if (Intl && 'NumberFormat' in Intl) {
      const decimalOptions = decimalCount
        ? {
            minimumFractionDigits: decimalCount,
            maximumFractionDigits: decimalCount,
          }
        : {};
      return new Intl.NumberFormat(UserSession.user.dashboard_settings?.language?.code || 'en', {
        ...decimalOptions,
        ...options,
      }).format(valueAsNumber);
    }

    // https://caniuse.com/mdn-javascript_builtins_intl_numberformat_format
    return $filter('number')(valueAsNumber, decimalCount);
  };
}

angular.module(MODULE_NAME, []).filter('intlNumber', intlNumber);

export default MODULE_NAME;
