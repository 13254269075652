import { riskifiedPageView } from './state-change-success/riskified-pageview';

stateChangeSuccess.$inject = ['$transitions', '$location', '$window'];
function stateChangeSuccess($transitions, $location, $window) {
  $transitions.onSuccess({}, () => {
    riskifiedPageView($window, $location);
  });
}

export { stateChangeSuccess };
