import angular from 'angular';
import uibModal from 'angular-ui-bootstrap/src/modal';
import { ModalInstanceComponent } from './modal-instance.component';
import { ModalInstanceDirective } from './modal-instance.directive';

const MODULE_NAME = 'core.components.modal-instance';

angular
  .module(MODULE_NAME, [uibModal])
  .component('easyshipModalInstance', ModalInstanceComponent)
  .directive('modalInstance', ModalInstanceDirective);

export default MODULE_NAME;
