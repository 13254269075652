(function () {
  'use strict';

  MessagingService.$inject = ['$q', 'Message'];
  function MessagingService($q, Message) {
    var service = this;

    service.Message = Message;

    service.sendSlackAlert = function (message) {
      return $q(function (resolve, reject) {
        service.Message.create(
          { controller: 'send_slack_alert' },
          { message: message },
          function () {
            resolve();
          },
          function () {
            reject();
          }
        );
      });
    };
  }

  angular
    .module('app.service.MessagingService', ['app.factory.Message'])
    .service('MessagingService', MessagingService);
})();
