import template from './fix-shipment.html?raw';

(function () {
  'use strict';

  fixShipmentModal.$inject = ['$uibModal'];
  function fixShipmentModal($uibModal) {
    var service = this;

    service.open = function () {
      service.modalInstance = $uibModal.open({
        template,
        controller: 'fixShipmentCtrl as vm',
        size: 'lg',
      });
    };

    service.close = function () {
      service.modalInstance.close();
    };
  }

  angular.module('app.service.fix-shipment-modal').service('fixShipmentModal', fixShipmentModal);
})();
