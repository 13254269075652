(function () {
  PostalCodeService.$inject = [
    '$q',
    'PostalCode',
    'CountryService',
    'AddressService',
    'HelperService',
  ];
  function PostalCodeService($q, PostalCode, CountryService, AddressService, HelperService) {
    const service = this;

    service.searchableCountries = [14, 39, 158, 234, 172, 183]; // AU, CA, MX, US, NZ, PR

    service.validator = {
      addressUsa: true,
      multipleShipment: true,
    };

    /**
     *
     * PostalCodeService.validateAndLookup()
     * @param {string} postal_code
     * @param {number} country_id
     * @returns {IPostalCodeResults}
     */
    service.validateAndLookup = function (postal_code, country_id) {
      const country = CountryService.findCountry(country_id);

      return $q(function (resolve, reject) {
        if (!country) return reject(`No country found with id ${country_id}`);

        let invalid_format = null;

        if (country.requirements.postal_code_regex && postal_code) {
          const regex = new RegExp(country.requirements.postal_code_regex, 'g');
          invalid_format = !regex.test(postal_code);
        }

        const results = {
          has_postal_code: country.requirements.has_postal_code,
          postal_code_mandatory_from_origin:
            country.requirements.postal_code_mandatory_from_origin || [],
          postal_code_regex: country.requirements.postal_code_regex,
          invalid_format,
          postal_code_examples: country.requirements.postal_code_examples,
        };

        if (!postal_code || !service.isSearchableCountry(country_id) || results.invalid_format) {
          return resolve(results);
        }

        const params = {
          country_id,
          postal_code,
        };

        function callback(postalCodeDetails) {
          if (
            !postalCodeDetails ||
            (!postalCodeDetails.state &&
              postalCodeDetails.states &&
              postalCodeDetails.states.length === 0) ||
            (postalCodeDetails.cities && !postalCodeDetails.cities.length === 0)
          ) {
            results.notFound = true;
            return resolve(results);
          }

          // Input fields
          results.city = postalCodeDetails && postalCodeDetails.city;
          results.state = postalCodeDetails && postalCodeDetails.state;

          const isAustralia = country_id === 14;
          // Select fields options
          if (isAustralia) {
            const capitalizedSuburbs =
              postalCodeDetails &&
              postalCodeDetails.suburbs.map(function (suburb) {
                return HelperService.capitalizeAllCaps(suburb);
              });
            results.suburbs = capitalizedSuburbs;
          } else {
            results.suburbs = postalCodeDetails && postalCodeDetails.suburbs;
          }
          results.states = postalCodeDetails && postalCodeDetails.states;
          results.cities = postalCodeDetails && postalCodeDetails.cities;

          return resolve(results);
        }

        function onReject() {
          results.notFound = true;
          return resolve(results);
        }

        return PostalCode.query(params, callback, onReject);
      });
    };

    // On init service needs to get the US states
    service.getState = function (params) {
      return $q(function (resolve, reject) {
        PostalCode.query(
          {
            country_id: params.country_id,
            postal_code: params.postal_code,
          },
          function (res) {
            service.validator.addressUsa = true;
            if (params.country_id === 14) {
              res = _parseAUSuburbs(res);
            }
            resolve(res);
          },
          function (err) {
            service.validator.addressUsa = false;
            reject(err);
          }
        );
      });
    };

    service.doesPostalCodeMatchRegex = function (shipment) {
      const countryRegex = _.get(shipment, 'destination_country.requirements.postal_code_regex');
      const postalCode = shipment.postal_code;
      const regex = new RegExp(countryRegex, 'g');

      // This is included to allow no postalCode to be passed for country with regex as
      // handling of whether postal code is required is dealt with seperately
      if (!postalCode) {
        return true;
      }
      return regex.test(postalCode);
    };

    /**
     * [doesCountryHavePostalCode] Checks whether the destination country uses the postal code system or not
     * @param  {Object} param  [Shipment object]
     * @return {Boolean}       [Returns true or false]
     */
    service.doesCountryHavePostalCode = function (param) {
      return _.get(param, 'destination_country.requirements.has_postal_code', false);
    };

    /**
     * [doesCountryHavePostalCodeRegex] Checks and see's if this destination has a regex to check if postal
     *                                  code is in correct format
     * @param  {Object} country  [Shipment Object]
     * @return {Boolean}       [returns true or false]
     */
    service.doesCountryHavePostalCodeRegex = function (param) {
      return !!_.get(param, 'destination_country.requirements.postal_code_regex');
    };

    /**
     * [isPostalCodeRequiredForDestination] Checks if the route from origin to destination require the postal
     *                                      code for shipment
     * @param  {Object}  shipment [Shipment Object]
     * @return {Boolean}          [returns true or false]
     */
    service.isPostalCodeRequiredForDestination = function (shipment) {
      return $q(function (resolve, reject) {
        const requiredCountryIds =
          shipment.destination_country.requirements.postal_code_mandatory_from_origin || [];

        const requirePostalCodeFromAllCountry = requiredCountryIds.length === 0;
        if (requirePostalCodeFromAllCountry) {
          resolve(true);
        }
        if (shipment.origin_country_id) {
          resolve(requiredCountryIds.indexOf(shipment.origin_country_id) > -1);
          return;
        }

        if (shipment.origin_address && shipment.origin_address.country_id) {
          resolve(requiredCountryIds.indexOf(shipment.origin_address.country_id) > -1);
        } else {
          AddressService.queryById(shipment.origin_address_id)
            .then(function (address) {
              resolve(requiredCountryIds.indexOf(address.country_id) > -1);
            })
            .catch(reject);
        }
      });
    };

    /**
     * [isCountryStateInDatabase] Checks whether the destination country is in the searchableCountries and returns true if it is
     * @param  {Object}  shipment [Shipment object]
     * @return {Boolean}
     */
    service.isCountryStateInDatabase = function (shipment) {
      const country = CountryService.findCountry(shipment.destination_country.id);

      return !!country?.requirements?.has_postal_code;
    };

    /**
     * [isSearchableCountry] Checks whether the destination country is in the searchableCountries and returns true if it is
     * @param  {number}  country_id [Country identifier]
     * @return {Boolean}
     */
    service.isSearchableCountry = function (country_id) {
      return service.searchableCountries.indexOf(country_id) >= 0;
    };

    function _capitalizeAndSort(array) {
      return array
        .map(function (suburb) {
          const capitalizedName = HelperService.capitalizeAllCaps(suburb);
          return capitalizedName;
        }, [])
        .sort(function (a, b) {
          return a - b;
        });
    }

    function _parseAUSuburbs(object) {
      const AuData = object;

      AuData.suburbs = _capitalizeAndSort(AuData.suburbs);
      AuData.states.forEach(function (state) {
        state.suburbs = _capitalizeAndSort(state.suburbs);
      });
      return AuData;
    }
  }

  angular
    .module('app.service.PostalCodeService', [])
    .service('PostalCodeService', PostalCodeService);
})();
