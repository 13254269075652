import angular from 'angular';
import ngResource from 'angular-resource';

import { User } from './User.resource';
import { UserService } from './User.service';

const MODULE_NAME = 'app.global.user';

angular
  .module(MODULE_NAME, ['core.config', ngResource])
  .factory('User', User)
  .service('UserService', UserService);

export default MODULE_NAME;
