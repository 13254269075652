import angular from 'angular';
import { AngularUberFreightLoginPage } from './UberFreightLoginPage/index';
import { AngularUberFreightForgetPasswordPage } from './UberFreightForgetPasswordPage/index';

const MODULE_NAME = 'app.auth.pages.auth-pages';

angular
  .module(MODULE_NAME, [])
  .component('esUberFreightLoginPage', AngularUberFreightLoginPage)
  .component('esUberFreightForgetPasswordPage', AngularUberFreightForgetPasswordPage);

export default MODULE_NAME;
