import { FeatureKey } from '@client/data/subscription';
import { IApiConfig } from 'typings/core/config/api';
import { CompanyLabelCustomization, IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './label-footer-customization.html?raw';
import style from './label-footer-customization.module.scss';

class LabelFooterCustomization implements IComponentController {
  esOptions: CompanyLabelCustomization[] = [];
  esOnChange(value: { options: CompanyLabelCustomization[] }) {}
  esDisabled = false;

  style = style;
  optionsLeft: CompanyLabelCustomization[] = [
    'easyship_shipment_id',
    'order_number',
    'skus',
    'include_sku_quantities',
  ];
  optionsRight: CompanyLabelCustomization[] = ['warehouse_location', 'seller_notes', 'buyer_notes'];
  previewUrl = '';

  static $inject = ['API', 'UserSession', 'SubscriptionService'];
  constructor(
    private API: IApiConfig,
    private UserSession: IUserSession,
    private SubscriptionService: SubscriptionService
  ) {}

  $onInit() {
    this.esOptions = this.esOptions || [];
    this.previewUrl = `${this.API.endpoint}/companies/${this.UserSession.company.id}/preview_document?type=label_customization`;
  }

  get cannotUseLabelCustomizationFeature(): boolean {
    const { currentSubscription } = this.SubscriptionService;
    return !currentSubscription?.all_features.label_customization?.is_accessible;
  }

  get showCharacterLimitMessage() {
    return this.esOptions.some((option) =>
      ['skus', 'seller_notes', 'buyer_notes'].includes(option)
    );
  }

  isPlanBadgeVisible(featureKey: FeatureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  getOptionCheckboxState(option: CompanyLabelCustomization) {
    return this.esOptions.includes(option);
  }

  showOptions(option: CompanyLabelCustomization) {
    if (option === 'include_sku_quantities' && !this.esOptions.includes('skus')) return false;

    return true;
  }

  onOptionChange(option: CompanyLabelCustomization) {
    if (this.esOptions.includes(option)) {
      const optionsToRemove = [option];

      // If sku is selected, remove sku related options
      if (option === 'skus') optionsToRemove.push('include_sku_quantities');

      this.esOptions = this.esOptions.filter((o) => {
        return !optionsToRemove.includes(o);
      });
    } else {
      this.esOptions.push(option);
    }

    // Sort the options before sending to the BE - Order matters!
    const optionsOrder = [...this.optionsLeft, ...this.optionsRight];
    this.esOptions.sort((a, b) => {
      return optionsOrder.indexOf(a) - optionsOrder.indexOf(b);
    });

    this.esOnChange({ options: this.esOptions });
  }
}

const LabelFooterCustomizationComponent: ng.IComponentOptions = {
  controller: LabelFooterCustomization,
  template,
  bindings: {
    esOptions: '<',
    esOnChange: '&',
    esDisabled: '<',
  },
};

export { LabelFooterCustomizationComponent };
