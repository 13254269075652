import * as React from 'react';
import { memo, Suspense, useSyncExternalStore } from 'react';
import store, { ModalsType } from '@/utils/modals';
import { Pulse } from 'easyship-components';

export const ModalContainer = memo(() => {
  const modals = useSyncExternalStore(store.subscribe, () => store.getModals());
  if (!modals.length) return null;
  return (
    <>
      {modals.map((m: ModalsType) => {
        const { component: ModalComponent, data = {}, guid, close } = m;
        return (
          <Suspense fallback={<Pulse show className="z-[9999]" />} key={guid}>
            <ModalComponent key={guid} {...(data as object)} handleClose={close} isOpen />;
          </Suspense>
        );
      })}
    </>
  );
});

export default ModalContainer;
