import angular from 'angular';

import { HelperService } from './helper.service';

import deviceService from '../device';

const MODULE_NAME = 'app.global.helper';

angular.module(MODULE_NAME, [deviceService]).service('HelperService', HelperService);

export default MODULE_NAME;
