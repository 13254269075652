import angular from 'angular';
import translate from 'angular-translate';

import { SelectComponent } from './select.component';

const MODULE_NAME = 'core.components.select';

angular.module(MODULE_NAME, [translate]).component('easyshipSelect', SelectComponent);

export default MODULE_NAME;
