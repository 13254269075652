import angular from 'angular';

import { PrintingOptionsResource } from './printing-options.resource';
import { PrintingOptionsService } from './printing-options.service';

const MODULE_NAME = 'app.global.services.printing-options';

angular
  .module(MODULE_NAME, ['app.global.services.reports'])
  .service('PrintingOptionsResource', PrintingOptionsResource)
  .service('PrintingOptionsService', PrintingOptionsService);

export default MODULE_NAME;
