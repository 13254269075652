import template from './edit-shipment.html?raw';

(function () {
  'use strict';

  var fixShipmentModalBodyEditShipment = {
    template,
    bindings: {
      shipmentData: '<',
      globalBusy: '<',
    },
    controller: 'fixShipmentModalBodyEditShipmentCtrl as ctrl',
  };

  fixShipmentModalBodyEditShipmentCtrl.$inject = [
    '$scope',
    '$q',
    'CountryService',
    'PostalCodeService',
    'FixMonkeyService',
    'RestrictionsService',
    'AppCuesService',
  ];

  function fixShipmentModalBodyEditShipmentCtrl(
    $scope,
    $q,
    CountryService,
    PostalCodeService,
    FixMonkeyService,
    RestrictionsService,
    AppCuesService
  ) {
    var ctrl = this;

    ctrl.CountryService = CountryService;

    ctrl.busy = {
      gettingTaxIdRestriction: false,
    };
    ctrl.error = '';

    ctrl.fieldLengths = {
      destination_name: {
        pattern: [0, 50],
        valid: true,
      },
      phone_number: {
        pattern: [0, 25],
        valid: true,
      },
      email_address: {
        pattern: [0, 50],
        valid: true,
      },
      address_line_1: {
        pattern: [0, 100],
        valid: true,
      },
      address_line_2: {
        pattern: [0, 100],
        valid: true,
      },
      postal_code: {
        pattern: [0, 20],
        valid: true,
      },
      city: {
        pattern: [0, 40],
        valid: true,
      },
      state: {
        pattern: [0, 30],
        valid: true,
      },
    };

    ctrl.messages = {
      showEmptyPostalCode: false,
      showRegexMismatch: false,
      showStateMismatch: false,
    };

    ctrl.showReturnInfoMessage = false;
    ctrl.triggerImmediateTaxIdValidation = false;
    ctrl.taxIdRequirements = {
      require: 'no_need',
      regexes: [],
    };
    ctrl.isTaxIdFieldValid = false;

    ctrl.wrappers = {
      a: function (text) {
        return (
          '<a class="report-shipment" ng-class="{\'report-shipment--busy\': ctrl.globalBusy}" ng-click="ctrl.reportShipment()" tabindex=0>' +
          text +
          '</a>'
        );
      },
      support: function (text) {
        return (
          '<a href="https://support.easyship.com/hc/en-us/articles/360038954952-Manage-Rejected-Shipments" target="_blank" rel="noopener">' +
          text +
          '</a>'
        );
      },
    };

    ctrl.$onInit = function () {
      ctrl.busy.modal = true;
      ctrl.courierName = _.get(ctrl.shipmentData, 'courier.name');
      ctrl.courierErrorMessages = _.get(ctrl.shipmentData, 'http_response.messages');

      $q.all([CountryService.getCountries(), CountryService.getUSStates()]).then(function () {
        ctrl.shipmentData.destination_country = CountryService.findCountry(
          ctrl.shipmentData.destination_country_id
        );

        if (['US', 'AU'].indexOf(_.get(ctrl.shipmentData, 'destination_country.alpha2')) > -1) {
          checkIfPostalCodeIsRequiredForDestination(ctrl.shipmentData);
          ctrl.showInputErrorMessage('postal_code');
          ctrl.validatePostalCode(ctrl.shipmentData);
        }

        _checkTaxIdRestrictions();

        ctrl.busy.modal = false;

        $scope.$watch(
          'ctrl.shipmentData',
          function (newVal, oldVal) {
            if (!angular.equals(newVal, oldVal)) {
              ctrl.showReturnInfoMessage = true;
            }
          },
          true
        );
      });

      ctrl.triggerImmediateTaxIdValidation =
        ctrl.shipmentData.logs.price_calculation.address &&
        !!ctrl.shipmentData.logs.price_calculation.address.consignee_tax_id;
    };

    ctrl.isDomesticShipment = function () {
      return ctrl.shipmentData.destination_country_id === ctrl.shipmentData.origin_country_id;
    };

    ctrl.onFieldChange = function (value, field) {
      ctrl.shipmentData[field] = value;
    };

    ctrl.onValidityChange = function (valid) {
      ctrl.isTaxIdFieldValid = valid;
    };

    /**
     * [saveShipment]
     * Calls FixMonkeyService to check which step to route to next and persists the shipment data. Then redirects to either "Pay Shipment" step or "Success / Fail" if label is directly generated
     */
    ctrl.saveShipment = function () {
      if (
        ctrl.messages.showEmptyPostalCode ||
        ctrl.messages.showRegexMismatch ||
        ctrl.messages.showStateMismatch ||
        ctrl.busy.findingState ||
        (!ctrl.isDomesticShipment() &&
          ctrl.taxIdRequirements === 'mandatory' &&
          !ctrl.shipmentData.consignee_tax_id)
      ) {
        return;
      }

      AppCuesService.track('Manage | Attempt Fix Rejected Label');

      /**
       * Fix issue where the shipment doubling the items after editing the shipment,
       * need to pass the `shipment_items_attributes` as the BE need more time to
       * check why passing `parcels_attributes` still causing the issue above.
       */
      delete ctrl.shipmentData.parcels_attributes;

      FixMonkeyService.saveShipment(ctrl.shipmentData)
        .then(function (data) {
          if (data.courier_available === false) {
            FixMonkeyService.goToCancelShipmentStep({ afterRetry: true });
          } else if (data.difference) {
            FixMonkeyService.goToPayShipmentStep();
          } else if (data.label_state === 'generated') {
            FixMonkeyService.goToSuccessMonkeyStep();
          } else {
            FixMonkeyService.goToFailMonkeyStep();
          }
        })
        .catch(function (err) {
          ctrl.error = err.data ? err.data.message : 'toast.default-error';
        });
    };

    ctrl.reportShipment = function () {
      if (ctrl.globalBusy) return;
      FixMonkeyService.goToReportShipmentStep();
    };

    ctrl.showInputErrorMessage = function (field) {
      if (ctrl.shipmentData[field].length < ctrl.fieldLengths[field].pattern[0]) {
        ctrl.fieldLengths[field].valid = false;
      } else if (ctrl.shipmentData[field].length > ctrl.fieldLengths[field].pattern[1]) {
        ctrl.fieldLengths[field].valid = false;
      } else {
        ctrl.fieldLengths[field].valid = true;
      }
    };

    // Functions related to Postal Code validation
    ctrl.initOnCountryChange = function (shipment) {
      ctrl.shipmentData.destination_country_id = shipment.destination_country.id;
      checkIfPostalCodeIsRequiredForDestination(shipment);
      clearStatesInput();
      showTargetErrorMessage();

      if (shipment.postal_code) clearPostalCodeInput();
      if (!ctrl.isDomesticShipment()) shipment.automated_return_requested = false;

      _checkTaxIdRestrictions();
    };

    ctrl.validatePostalCode = function (shipment) {
      PostalCodeService.isPostalCodeRequiredForDestination(shipment).then(function (
        postalCodeRequiredForDestination
      ) {
        if (PostalCodeService.doesCountryHavePostalCodeRegex(shipment)) {
          checkIfPostalCodeMatchesRegex(shipment);
        }

        showAlertMessageIfPostalCodeIsMissing(
          shipment.postal_code,
          postalCodeRequiredForDestination
        );
      });
    };

    ctrl.openCancelAndRecreateModal = function () {
      FixMonkeyService.goToCancelShipmentStep({ afterRetry: false });
    };

    function checkIfPostalCodeIsRequiredForDestination(shipment) {
      ctrl.destinationNeedsPostalCode = PostalCodeService.isCountryStateInDatabase(shipment);
    }

    function checkIfPostalCodeMatchesRegex(shipment) {
      if (PostalCodeService.doesPostalCodeMatchRegex(shipment)) {
        if (ctrl.destinationNeedsPostalCode) findStateWithPostalCode();
        ctrl.messages.showRegexMismatch = false;
      } else {
        if (ctrl.destinationNeedsPostalCode) clearStatesInput();
        showTargetErrorMessage('showRegexMismatch');
      }
    }

    function showAlertMessageIfPostalCodeIsMissing(postalCode, postalCodeRequiredForDestination) {
      if (postalCodeRequiredForDestination && !postalCode) {
        showTargetErrorMessage('showEmptyPostalCode');
      } else if (!postalCodeRequiredForDestination) {
        ctrl.messages.showEmptyPostalCode = false;
      }
    }

    function showTargetErrorMessage(target) {
      Object.keys(ctrl.messages).forEach(function (key) {
        ctrl.messages[key] = target === key ? true : false;
      });
    }

    function findStateWithPostalCode() {
      ctrl.busy.findingState = true;

      var params = {
        postal_code: ctrl.shipmentData.postal_code,
        country_id: ctrl.shipmentData.destination_country.id,
      };

      PostalCodeService.getState(params)
        .then(function (res) {
          if (params.country_id === 14) {
            processAUPostalCodeResponse(res);
          } else {
            ctrl.shipmentData.state = res.state.name;

            if (res.city) {
              ctrl.shipmentData.city = res.city;
            }
            if (res.cities && res.cities.length > 0) {
              ctrl.shipmentData.city = res.cities[0];
            }

            ctrl.busy.findingState = false;
            showTargetErrorMessage();
          }
        })
        .catch(function () {
          ctrl.busy.findingState = false;
          clearStatesInput();
          showTargetErrorMessage('showStateMismatch');
        });
    }

    function processAUPostalCodeResponse(res) {
      if (res.suburbs.length > 0) {
        ctrl.shipmentData.state = res.state.name;
        ctrl.shipmentData.city = res.suburbs[0];
        ctrl.busy.findingState = false;
        showTargetErrorMessage();
      } else {
        ctrl.busy.findingState = false;
        clearStatesInput();
        showTargetErrorMessage('showStateMismatch');
      }
    }

    function clearStatesInput() {
      ctrl.shipmentData.state = null;
    }

    function clearPostalCodeInput() {
      ctrl.shipmentData.postal_code = null;
    }

    function _checkTaxIdRestrictions() {
      if (ctrl.isDomesticShipment()) {
        ctrl.shipmentData.consignee_tax_id = '';

        return;
      }

      ctrl.busy.gettingTaxIdRestriction = true;

      RestrictionsService.getShipmentRestrictions({
        courier_id: ctrl.shipmentData.courier_id,
        origin_country_id: ctrl.shipmentData.origin_country_id,
        destination_country_id: ctrl.shipmentData.destination_country_id,
      })
        .then(function (response) {
          ctrl.taxIdRequirements = response.shipment.destination_address.consignee_tax_id;
        })
        .catch(function () {
          this.taxIdRequirements = {
            require: 'no_need',
            regexes: [],
          };
        })
        .finally(function () {
          ctrl.busy.gettingTaxIdRestriction = false;
        });
    }
  }

  angular
    .module('app.component.fix-shipment-modal-body-edit-shipment')
    .component('fixShipmentModalBodyEditShipment', fixShipmentModalBodyEditShipment)
    .controller('fixShipmentModalBodyEditShipmentCtrl', fixShipmentModalBodyEditShipmentCtrl);
})();
