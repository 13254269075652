import React, { ReactElement, useState, useEffect } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardHeader, { CardHeaderProps as MuiCardHeaderProps } from '@material-ui/core/CardHeader';
import CardContent, {
  CardContentProps as MuiCardContentProps,
} from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import Switch from '@client/core/components/react/Switch';
import Tooltip from '@client/core/components/react/Tooltip';
import Select from '@client/core/components/react/Select';
import { BatteryType } from 'typings/dashboard/services/product-listing';
import { ProductFormData } from '../reset-confirmation/ProductFormData';

interface ProductItemDeclarationCardProps {
  CardHeaderProps: MuiCardHeaderProps;
  CardContentProps: MuiCardContentProps;
}

export default function ProductItemDeclarationCard({
  CardHeaderProps,
  CardContentProps,
}: ProductItemDeclarationCardProps): ReactElement {
  const { formatMessage } = useIntl();
  const { control, setValue } = useFormContext<ProductFormData>();
  const batteryType = useWatch({
    control,
    name: 'batteryType',
  });
  const [containsBattery, setContainsBattery] = useState(!!batteryType);

  useEffect(() => {
    setContainsBattery(!!batteryType);
  }, [batteryType]);

  function handleContainsBatteriesToggleChange() {
    setValue('batteryType', '', { shouldDirty: true });
    setContainsBattery(!containsBattery);
  }

  return (
    <Card>
      <CardHeader {...CardHeaderProps} />
      <CardContent {...CardContentProps}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Controller
              render={({ field }) => (
                <Switch
                  label={formatMessage({ id: 'product-listing.details.contains-liquid' })}
                  {...field}
                  checked={field.value}
                  onChange={field.onChange}
                  helperIcon={<ContainsLiquidsTooltip />}
                />
              )}
              control={control}
              name="contains_liquids"
            />
          </Grid>
          <Grid item>
            <Switch
              label={formatMessage({ id: 'product-listing.details.contains-battery' })}
              checked={containsBattery}
              onChange={handleContainsBatteriesToggleChange}
              helperIcon={<ContainsBatteriesTooltip />}
            />
          </Grid>
          {containsBattery && (
            <Grid item>
              <Controller
                render={({ field, fieldState }) => (
                  <Select
                    native
                    label={formatMessage({ id: 'product-listing.details.battery-classification' })}
                    onChange={field.onChange}
                    helperIcon={<BatteryClassificationsTooltip />}
                    value={batteryType}
                    error={!!fieldState.error}
                  >
                    <option value={-1} disabled={!!batteryType}>
                      {formatMessage({ id: 'global.choose-one' })}
                    </option>
                    <option value="packed_battery">
                      {formatMessage({ id: 'product-listing.details.battery-packed' })}
                    </option>
                    <option value="contained_battery">
                      {formatMessage({ id: 'product-listing.details.battery-contained' })}
                    </option>
                  </Select>
                )}
                control={control}
                name="batteryType"
                rules={{
                  required: 'Please choose a battery type',
                }}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

function ContainsBatteriesTooltip() {
  return (
    <Tooltip
      interactive
      placement="top"
      PopperProps={{ style: { maxWidth: 200 } }}
      title={
        <>
          <FormattedMessage id="product-listing.details.contains-battery-tooltip" />{' '}
          <a
            href="https://www.easyship.com/blog/how-to-ship-dangerous-goods-dg"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="global.learn-more" />
          </a>
        </>
      }
    />
  );
}

function ContainsLiquidsTooltip() {
  return (
    <Tooltip
      interactive
      placement="top"
      PopperProps={{ style: { maxWidth: 200 } }}
      title={
        <>
          <FormattedMessage id="product-listing.details.contains-liquid-tooltip" />{' '}
          <a
            href="https://www.easyship.com/blog/how-to-ship-dangerous-goods-dg"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="global.learn-more" />
          </a>
        </>
      }
    />
  );
}

function BatteryClassificationsTooltip() {
  return (
    <Tooltip
      interactive
      placement="top"
      PopperProps={{ style: { maxWidth: 200 } }}
      title={
        <>
          <FormattedMessage id="product-listing.details.battery-classifications-tooltip" />{' '}
          <a
            href="https://www.easyship.com/blog/how-to-ship-dangerous-goods-dg"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="global.learn-more" />
          </a>
        </>
      }
    />
  );
}
