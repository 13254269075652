import { IShipmentListLogsPriceCalculation } from 'typings/shipment';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { ShipmentFields } from '../../../services/shipment-list/shipment-list-abstract.service';
import template from './shipment-list-order-cell.html?raw';
import style from './shipment-list-order-cell.module.scss';

class ShipmentListOrderCell implements IComponentController {
  style = style;
  esShopName: string | null = null;
  esOrderNumber: number | null = null;
  esType = '';
  esLogoUrl: string | null = null;
  esPlatformName: string | null = null;
  esItemsCount: number | null = null;
  esParcelsCount: number | null = null;
  esDropdown = false;
  esDisabled = false;
  esErrorLogs: IShipmentListLogsPriceCalculation | null = null;
  esOrderTagError = false;
  esShowOrder = false;
  esShipmentIndex: number | null = null;
  readonly itemFields = [
    ShipmentFields.Height,
    ShipmentFields.Length,
    ShipmentFields.Width,
    ShipmentFields.ActualWeight,
  ];
  readonly shipmentsType = 'manage';

  static $inject = ['UserSession'];
  constructor(private UserSession: IUserSession) {}

  esOnEditClick(value: any): void {
    // esOnEditClick expression bindings, need to add this in order for typescript to successfully compile
  }

  onEditClick($event: MouseEvent): void {
    if (!this.esDropdown || this.esDisabled) return;

    this.esOnEditClick({ event: $event, index: this.esShipmentIndex });
  }

  isShopNameTruncated(): boolean {
    if (this.esShopName) {
      return this.esShopName.length >= 15;
    }
    return false;
  }

  isPlatformNameTruncated(): boolean {
    if (this.esPlatformName && !this.esShopName) {
      return this.esPlatformName.length > 16;
    }
    return false;
  }

  isOrderNumberTruncated(): boolean {
    if (this.esOrderNumber) {
      return this.esOrderNumber.toString().length > 15;
    }
    return false;
  }

  get getTooltipText(): string {
    let html = '';

    if (this.isPlatformNameTruncated()) {
      html += `${this.esPlatformName}<br>`;
    }
    if (this.isShopNameTruncated()) {
      html += `${this.esShopName}<br>`;
    }

    if (this.isOrderNumberTruncated()) {
      html += `${this.esOrderNumber}`;
    }

    return html.replace(/'/g, '&#39;');
  }

  get isTooltipEnable(): boolean {
    if (
      this.isOrderNumberTruncated() ||
      this.isShopNameTruncated() ||
      this.isPlatformNameTruncated()
    ) {
      return true;
    }

    return false;
  }

  get isInvalid(): boolean {
    if (this.esOrderTagError) return true;
    if (!this.esErrorLogs) return false;
    if (Array.isArray(this.esErrorLogs)) {
      const shipmentErrors = this.esErrorLogs.filter(
        (log) => log.category === 'shipment_items' || log.category === 'shipment'
      );

      return shipmentErrors.length > 0 && !this.esShowOrder;
    }

    return (
      !!this.esErrorLogs.shipment_items || !!this.esErrorLogs.shipment || !!this.esErrorLogs.parcels
    );
  }

  get isShipmentPanelViewActive(): boolean {
    return !!this.UserSession.showAdvancedShipmentPanelView();
  }
}

const ShipmentListOrderCellComponent: ng.IComponentOptions = {
  controller: ShipmentListOrderCell,
  template,
  bindings: {
    esShopName: '<',
    esOrderNumber: '<',
    esLogoUrl: '<',
    esType: '<',
    esIsMerged: '<',
    esPlatformName: '<',
    esItemsCount: '<',
    esParcelsCount: '<',
    esDropdown: '<',
    esDisabled: '<',
    esShowOrder: '<',
    esErrorLogs: '<',
    esDimensionsWarning: '<',
    esOrderTagError: '<',
    esShipmentIndex: '<',
    esOnEditClick: '&',
  },
};

export { ShipmentListOrderCellComponent };
