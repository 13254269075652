import angular from 'angular';

import onboardingChecklistService from '@client/src/global/services/onboarding-checklist';
import { ProductDimensionsComponent } from './product-dimensions/product-dimensions.component';
import { FillDimensionsMessageComponent } from './fill-dimensions-message/fill-dimensions-message.component';
import { EnterDimensionsModalComponent } from './enter-dimensions-modal/enter-dimensions-modal.component';
import { OptionalDimensionsModalService } from './optional-dimensions-modal-main/optional-dimensions-modal.service';

const MODULE_NAME = 'app.global.optional-dimensions';

angular
  .module(MODULE_NAME, ['core.components', 'app.global.services', onboardingChecklistService])
  .component('optionalDimensionsProductDimensions', ProductDimensionsComponent)
  .component('esFillDimensionsMessage', FillDimensionsMessageComponent)
  .component('optionalDimensionsEnterDimensionsModal', EnterDimensionsModalComponent)
  .service('OptionalDimensionsModalService', OptionalDimensionsModalService);

export default MODULE_NAME;
