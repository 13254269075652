import { toastError } from '@client/core/components/react/Toastify';
import { FEATURE_KEY } from '@client/data/subscription';
import template from './form.html?raw';
import style from './form.module.scss';

const PRINTING_FORMAT_4x6 = '4x6';

class PrintingOptionsForm {
  static $inject = [
    'UserSession',
    'API',
    'AddressService',
    'PrintingOptionsService',
    'ThermalInvoiceModal',
    'SubscriptionService',
  ];

  constructor(
    UserSession,
    API,
    AddressService,
    PrintingOptionsService,
    ThermalInvoiceModal,
    SubscriptionService,
    $translate
  ) {
    this.$translate = $translate;
    this.style = style;
    this.UserSession = UserSession;
    this.AddressService = AddressService;
    this.PrintingOptionsService = PrintingOptionsService;
    this.ThermalInvoiceModal = ThermalInvoiceModal;
    this.SubscriptionService = SubscriptionService;
    this.paperSizes = [];
    this.printableDocuments = ['label', 'packing_slip', 'commercial_invoice'];
    this.picklistTemplates = ['by_sku', 'by_shipment', 'by_pick_location'];
    this.picklistSortBy = ['sku', 'quantity'];
    this.documentGrouping = ['split', 'combine'];
    this.picklistSupportUrl = `${API.help}/hc/en-us/articles/360054110571-Pick-List-`;
    this.SubscriptionService = SubscriptionService;
    this.FEATURE_KEY = FEATURE_KEY;
    this.mixPanelPrintingFormatTriggerSource = '4x6 Printing Format';
    this.mixPanelPickListTriggerSource = 'Pick List';
    this.isPrintingFormat4x6Accessible = this.SubscriptionService.isFeatureAccessible(
      FEATURE_KEY.PrintingFormat4x6
    );
  }

  $onInit() {
    this.busy.gettingSizes = true;

    this.AddressService.hasSenderAddressInCountry('US').then((hasUsAddress) => {
      this.hasUsAddress = hasUsAddress;
    });

    this.PrintingOptionsService.getSizes()
      .then(({ printing_options }) => {
        printing_options.sort(function (a, b) {
          return a.name < b.name ? -1 : 1;
        });
        this.paperSizes = printing_options;
      })
      .finally(() => {
        this.busy.gettingSizes = false;
      });

    this.hasCommercialInvoiceRemarks =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_commercial_invoice_remark;

    this.hasNoUserRole =
      !!this.UserSession.getCompanyDashboardSettings().beta_feature_team_rights_and_permissions &&
      !this.UserSession.hasUserRole('product_listing');

    this.wrappers = {
      a: (chunk) => {
        return this.hasNoUserRole
          ? `<span>${chunk}</span>`
          : ` <a ui-sref='app.product-listing'>${chunk}</a>`;
      },
    };
  }

  $onChanges(changesObj) {
    const printingOptions =
      changesObj && changesObj.printingOptions && changesObj.printingOptions.currentValue;
    Object.assign(this, printingOptions || {});
  }

  thermalCommercialInvoiceSelected() {
    return this.commercial_invoice_format === PRINTING_FORMAT_4x6;
  }

  onFormatChange(type, value) {
    if (
      type === 'commercial_invoice' &&
      value === PRINTING_FORMAT_4x6 &&
      this.isPrintingFormat4x6Accessible
    ) {
      this.ThermalInvoiceModal.open();
    }
    const propertyName = `${type}_format`;
    this[propertyName] = value;
    this.onChange({
      data: { [propertyName]: value },
    }).catch(() => {
      this.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
        FEATURE_KEY.PrintingFormat4x6,
        this.mixPanelPrintingFormatTriggerSource
      );
    });
  }

  onDownloadFormatChange(value) {
    this.download_format = value;
    this.onChange({
      data: { download_format: value },
    });
  }

  onGenerateManifestsChange(value) {
    this.auto_generate_manifests = value;
    this.onChange({
      data: { auto_generate_manifests: value },
    });
  }

  onAutoDownloadChange(value) {
    this.auto_download_zip = value;
    this.onChange({
      data: { auto_download_zip: value },
    });
  }

  onGroupBySkuChange(value) {
    this.group_by_sku = value;
    this.onChange({
      data: { group_by_sku: value },
    });
  }

  getPicklistTemplateCheckboxState(template) {
    return this.pick_list_templates?.includes(template);
  }

  onPicklistTemplatesClick($event, template) {
    if (this.pick_list_templates.length === 1 && this.pick_list_templates.includes(template)) {
      $event.preventDefault();
      toastError(
        this.$translate.instant('printing-options.picklist.select-atleast-one-template-message')
      );
    }
  }

  onPicklistTemplatesChange(template) {
    if (this.pick_list_templates.includes(template)) {
      this.pick_list_templates = this.pick_list_templates.filter((t) => t !== template);
    } else {
      this.pick_list_templates.push(template);
    }

    this.onChange({
      data: { pick_list_templates: this.pick_list_templates },
    }).catch(() => {
      this.pick_list_templates.pop();
      this.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
        FEATURE_KEY.PickList,
        this.mixPanelPickListTriggerSource
      );
    });
  }

  onPicklistSortingChange(sortBy) {
    this.pick_list_sort_by = sortBy;

    this.onChange({
      data: { pick_list_sort_by: this.pick_list_sort_by },
    }).catch(() => {
      this.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
        FEATURE_KEY.PickList,
        this.mixPanelPickListTriggerSource
      );
    });
  }

  onLabelCustomizationChange(options) {
    this.label_customization = options;

    this.onChange({
      data: { label_customization: this.label_customization },
    });
  }

  isPlanBadgeVisible(featureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }
}

const PrintingOptionsFormComponent = {
  controller: PrintingOptionsForm,
  template,
  bindings: {
    printingOptions: '<',
    onChange: '&',
    busy: '<',
  },
};

export { PrintingOptionsFormComponent };
