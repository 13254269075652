/* eslint-disable max-classes-per-file */
import template from './create-shipment-modal.html?raw';
import style from './create-shipment-modal.module.scss';

class CreateShipmentModalCtrl {
  static $inject = ['CreateShipmentModal', 'modalParams'];

  constructor(CreateShipmentModal, modalParams) {
    this.CreateShipmentModal = CreateShipmentModal;
    this.style = style;
    this.modalParams = modalParams;

    this.baseShipment = {};
  }

  $onInit() {
    this.baseShipment = this.modalParams?.baseShipment;
    this.mixpanelEvent = this.modalParams?.mixpanelEvent;
  }

  close() {
    this.CreateShipmentModal.close();
  }
}

class CreateShipmentModal {
  static $inject = ['$uibModal', '$state'];

  constructor($uibModal, $state) {
    this.$uibModal = $uibModal;
    this.$state = $state;
  }

  open(modalParams) {
    this.modalReference = this.$uibModal.open({
      template,
      controller: CreateShipmentModalCtrl,
      controllerAs: '$ctrl',
      size: 'lg',
      keyboard: false,
      openedClass: 'easyship-modal-no-padding',
      resolve: {
        modalParams: () => {
          return modalParams;
        },
      },
    });
  }

  close() {
    this.modalReference.close();
    this.$state.go('app.multiple');
  }
}

export { CreateShipmentModal };
