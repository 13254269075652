import angular from 'angular';
import { authFullLoginSuccess } from './auth-full-login-success.run';
import { authLogoutSuccess } from './auth-logout-success.run';
import { stateChangeSuccess } from './state-change-success.run';
import { stateChangeStart } from './state-change-start.run';
import { stateChangeBefore } from './state-change-before.run';
import { deviceListener } from './device-listener';
import { sentryInit } from './sentry';

angular
  .module('app.initializers', ['easyshipDashboardApp'])
  .run(sentryInit)
  .run(authFullLoginSuccess)
  .run(authLogoutSuccess)
  .run(stateChangeBefore)
  .run(stateChangeStart)
  .run(stateChangeSuccess)
  .run(deviceListener);
