import angular from 'angular';

import { CourierShipmentRatesTableComponent } from './courier-shipment-rates-table/courier-shipment-rates-table.component';

const MODULE_NAME = 'app.global.components.courier';

angular
  .module(MODULE_NAME, [])
  .component('esCourierShipmentRatesTable', CourierShipmentRatesTableComponent);

export default MODULE_NAME;
