import template from './preview.html?raw';
import style from './preview.module.scss';

import backgroundMarkup from '../assets/background.svg?raw';
import printerMarkup from '../assets/printer.svg?raw';
import thermalPrinterMarkup from '../assets/thermal-printer.svg?raw';
import a4LabelMarkup from '../assets/a4-label.svg?raw';
import a4PackingslipMarkup from '../assets/a4-packingslip.svg?raw';
import a4HalfPackingslipMarkup from '../assets/a4-half-packingslip.svg?raw';
import a4LabelPackingslipMarkup from '../assets/a4-label-packingslip.svg?raw';
import thermalLabelMarkup from '../assets/thermal-label.svg?raw';
import thermalPackingslipMarkup from '../assets/thermal-packingslip.svg?raw';
import pdfCombinedMarkup from '../assets/pdf-combined.svg?raw';
import pdfSeparateMarkup from '../assets/pdf-separate.svg?raw';

class PrintingOptionsPreview {
  static $inject = ['$sce'];

  constructor($sce) {
    this.style = style;

    this.background = $sce.trustAsHtml(backgroundMarkup);
    this.printer = $sce.trustAsHtml(printerMarkup);
    this.thermalPrinter = $sce.trustAsHtml(thermalPrinterMarkup);
    this.a4Label = $sce.trustAsHtml(a4LabelMarkup);
    this.a4Packingslip = $sce.trustAsHtml(a4PackingslipMarkup);
    this.a4HalfPackingslip = $sce.trustAsHtml(a4HalfPackingslipMarkup);
    this.a4LabelPackingslip = $sce.trustAsHtml(a4LabelPackingslipMarkup);
    this.thermalLabel = $sce.trustAsHtml(thermalLabelMarkup);
    this.thermalPackingslip = $sce.trustAsHtml(thermalPackingslipMarkup);
    this.pdfCombined = $sce.trustAsHtml(pdfCombinedMarkup);
    this.pdfSeparate = $sce.trustAsHtml(pdfSeparateMarkup);
  }

  $onChanges(changesObj) {
    const printingOptions = changesObj.printingOptions && changesObj.printingOptions.currentValue;
    this.animationKey = this._getAnimationKey(printingOptions);
  }

  _shippingDocsChanged() {
    return this.lastChangedOption === 'download_format';
  }

  _getAnimationKey(printingOptions) {
    const { label_format, packing_slip_format, download_format } = printingOptions;
    const shippingDocsChanged = this._shippingDocsChanged();

    if (shippingDocsChanged && download_format === 'combine') {
      return this.style.animationPdfCombined;
    }

    if (shippingDocsChanged && download_format === 'split') {
      return this.style.animationPdfSeparate;
    }

    if (this._isLargeFormat(label_format) && this._isNotIncluded(packing_slip_format)) {
      return this.style.animationA4LabelOnly;
    }

    if (this._isLargeFormat(label_format) && this._isLargeFormat(packing_slip_format)) {
      return this.style.animationA4LabelA4Packingslip;
    }

    if (this._isLargeFormat(label_format) && this._isLargeHalfFormat(packing_slip_format)) {
      return this.style.animationA4LabelA4HalfPackingslip;
    }

    if (this._isLargeFormat(label_format) && this._isThermalFormat(packing_slip_format)) {
      return this.style.animationA4LabelThermalPackingslip;
    }

    if (this._isThermalFormat(label_format) && this._isNotIncluded(packing_slip_format)) {
      return this.style.animationThermalLabelOnly;
    }

    if (this._isThermalFormat(label_format) && this._isLargeFormat(packing_slip_format)) {
      return this.style.animationThermalLabelA4Packingslip;
    }

    if (this._isThermalFormat(label_format) && this._isLargeHalfFormat(packing_slip_format)) {
      return this.style.animationThermalLabelA4HalfPackingslip;
    }

    if (this._isThermalFormat(label_format) && this._isThermalFormat(packing_slip_format)) {
      return this.style.animationThermalLabelThermalPackingslip;
    }

    return 'none';
  }

  _isLargeFormat(format) {
    const largeFormats = ['A4', 'A5', 'Letter'];

    return largeFormats.includes(format);
  }

  _isThermalFormat(format) {
    return format === '4x6';
  }

  _isLargeHalfFormat(format) {
    const largeHalfFormats = ['A4_half', 'A5_half', 'Letter_half'];

    return largeHalfFormats.includes(format);
  }

  _isNotIncluded(format) {
    return format === 'none';
  }
}

const PrintingOptionsPreviewComponent = {
  controller: PrintingOptionsPreview,
  template,
  bindings: {
    printingOptions: '<',
    lastChangedOption: '<',
  },
};

export { PrintingOptionsPreviewComponent };
