import { IAuthService } from 'typings/core/services/auth';
import angular from 'angular';
import { IUserSession, SignupPayload } from 'typings/user-session';
import { LocalStorageExpiry } from '@client/core/helpers/LocalStorageExpiry';
import { IUserService } from 'typings/user-service';
import { AuthPort } from '../../ports/auth';
import { EmailValidationPayload, SignupFormData } from '../../Signup/SignupForm.types';
import { ResetPasswordPayload } from '../../platforms/uber-freight/ForgetPassword.types';

export class NgAuthGateway implements AuthPort {
  private authService: IAuthService;
  private userService: IUserService;

  constructor() {
    this.authService = angular.element(document.body).injector().get('Auth');
    this.userService = angular.element(document.body).injector().get('UserService');
  }

  login(email: string, password: string, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const userPayload = {
        email,
        password,
      };
      this.authService.login(userPayload, null, token).catch((reason) => {
        reject(reason);
      });
    });
  }

  signup(payload: SignupFormData, token: string): Promise<IUserSession> {
    const localStorageExpiry = new LocalStorageExpiry();

    const irClickId = localStorageExpiry.get('irClickId');
    const mediaId = localStorageExpiry.get('mediaId');

    const newPayload: SignupPayload = {
      email: payload.email,
      password: payload.password,
      first_name: payload.firstName,
      last_name: payload.lastName,
      country_id: payload.countryId,
      impact_irclick_id: irClickId ?? undefined,
      impact_media_id: mediaId ?? undefined,
    };
    if (payload.companyName) newPayload.company_name = payload.companyName;
    return this.authService.signup(newPayload, token);
  }

  resetPassword(payload: ResetPasswordPayload, token: string): Promise<any> {
    return this.userService.resetPassword(payload, token);
  }

  validateEmail(payload: EmailValidationPayload): Promise<any> {
    return this.userService.validateEmail(payload);
  }
}
