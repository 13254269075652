/* eslint-disable @typescript-eslint/naming-convention */
import { ICountryService, ICountry } from 'typings/auth/services/country';
import { Brokers } from 'typings/brokers';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { BrokersService } from '@client/src/global/services/brokers/brokers.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './add-edit-delete-fedex-broker-modal.html?raw';
import style from './add-edit-delete-fedex-broker-modal.module.scss';

type Busy = {
  gettingData: boolean;
  updatingBroker: boolean;
};

class AddEditDeleteFedexBrokerModal implements IComponentController {
  style = style;
  brokerPayload: Brokers.IBrokerPayload = {
    contact_name: '',
    company_name: '',
    contact_phone: '',
    address_line_1: '',
    address_line_2: '',
    postal_code: '',
    state: '',
    city: '',
    country_id: null,
    account_number: null,
    broker_type: 'IMPORT',
  };
  countries: ICountry[] = [];
  esStep = '';
  esCourierId = '';
  types = [
    {
      name: 'Import',
      value: 'IMPORT',
    },
    {
      name: 'Export',
      value: 'EXPORT',
    },
  ];
  busy: Busy = {
    gettingData: false,
    updatingBroker: false,
  };
  busyUpdating = false;
  countryName = '';
  translations: Record<string, string> = {};

  static $inject = [
    '$translate',
    'CountryService',
    'CourierDetails',
    'BrokersService',
    'UserSession',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private CountryService: ICountryService,
    private CourierDetails: any,
    private BrokersService: BrokersService,
    private UserSession: IUserSession
  ) {
    this.translations = {
      settings: this.$translate.instant('global.setting').toLowerCase(),
    };
  }

  $onInit(): void {
    this.busy.gettingData = true;

    this.CountryService.getCountries()
      .then((data) => {
        this.countries = data.countries;

        if (this.showEdit || this.showDelete) {
          this.populateFormFields();

          if (this.showDelete) {
            this.countryName = this.CountryService.getCountryNameById(
              this.CourierDetails.selectedBroker.country_id
            );
          }
        } else {
          this.clearFormFields();
        }
      })
      .finally(() => {
        this.busy.gettingData = false;
      });
  }

  esOnBack(): void {
    // esOnBack expression binding, need for typescript to compile successfully.
  }

  clearFormFields(): void {
    this.brokerPayload = {
      contact_name: '',
      company_name: '',
      contact_phone: '',
      address_line_1: '',
      address_line_2: '',
      postal_code: '',
      state: '',
      city: '',
      country_id: null,
      account_number: '',
      broker_type: 'IMPORT',
    };
  }

  populateFormFields(): void {
    const {
      contact_name,
      company_name,
      contact_phone,
      address_line_1,
      address_line_2,
      postal_code,
      state,
      city,
      country_id,
      account_number,
      broker_type,
    } = this.CourierDetails.selectedBroker;

    this.brokerPayload = {
      contact_name,
      company_name,
      contact_phone,
      address_line_1,
      address_line_2,
      postal_code,
      state,
      city,
      country_id,
      account_number,
      broker_type,
    };
  }

  onChange(value: string | boolean | number | ICountry, key: keyof Brokers.IBrokerPayload): void {
    this.brokerPayload[key] = value as never;
  }

  get selectedBroker() {
    return this.CourierDetails.selectedBroker;
  }

  get showDelete() {
    return this.CourierDetails.fedexBrokerFormShow === 'delete-fedex-broker';
  }

  get showEdit() {
    return this.CourierDetails.fedexBrokerFormShow === 'edit-fedex-broker';
  }

  get showCreate() {
    return this.CourierDetails.fedexBrokerFormShow === 'create-fedex-broker';
  }

  addBroker(): void {
    this.busy.updatingBroker = true;

    this.BrokersService.createBroker({
      company_id: this.UserSession.getCompanyId() as string,
      courier_account_id: this.esCourierId,
      broker: this.brokerPayload,
    })
      .then((broker) => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.settings },
            'messageformat'
          )
        );
        this.CourierDetails.fedexBrokers.unshift(broker);
        this.esOnBack();
      })
      .catch((e) => {
        toastError(e.data && e.data.message);
      })
      .finally(() => {
        this.busy.updatingBroker = false;
      });
  }

  updateBroker(): void {
    this.busy.updatingBroker = true;

    this.BrokersService.updateBroker(
      { broker_id: this.CourierDetails.selectedBroker.id },
      this.brokerPayload
    )
      .then((response) => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.settings },
            'messageformat'
          )
        );

        const index = this.CourierDetails.fedexBrokers.findIndex((broker: Brokers.IBroker) => {
          return broker.id === response.id;
        });

        if (index > -1) {
          this.CourierDetails.fedexBrokers[index] = response;
        }

        this.esOnBack();
      })
      .catch((e) => {
        toastError(e.data && e.data.message);
      })
      .finally(() => {
        this.busy.updatingBroker = false;
      });
  }

  deactivateBroker(): void {
    this.busy.updatingBroker = true;

    this.BrokersService.deactivateBroker({ broker_id: this.CourierDetails.selectedBroker.id })
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations.settings },
            'messageformat'
          )
        );

        this.CourierDetails.fedexBrokers = this.CourierDetails.fedexBrokers.reduce(
          (activeBrokers: Brokers.IBroker[], broker: Brokers.IBroker) => {
            if (broker.id === this.CourierDetails.selectedBroker.id) {
              // eslint-disable-next-line no-param-reassign
              broker.is_active = false;
              return activeBrokers;
            }
            return [...activeBrokers, broker];
          },
          []
        );

        this.esOnBack();
      })
      .catch((e) => {
        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.translations.settings,
            details: e.data?.message,
          })
        );
      })
      .finally(() => {
        this.busy.updatingBroker = false;
      });
  }

  action(form: ng.IFormController): void {
    if (this.showDelete) {
      this.deactivateBroker();
    } else {
      if (form.$invalid) {
        toastError(this.$translate.instant('toast.incomplete-form'));
        return;
      }

      if (this.showCreate) {
        this.addBroker();
      } else if (this.showEdit) {
        this.updateBroker();
      }
    }
  }
}

const AddEditDeleteFedexBrokerModalComponent: ng.IComponentOptions = {
  controller: AddEditDeleteFedexBrokerModal,
  template,
  bindings: {
    esOnBack: '&',
    esStep: '<',
    esCourierId: '<',
  },
};

export { AddEditDeleteFedexBrokerModalComponent };
