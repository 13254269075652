import React, { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ShipmentsTableRowCellProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: MouseEvent | null, index: number) => void;
  rowIndex?: number;
  style?: CSSProperties;
}

export default function ShipmentsTableRowCell({
  children,
  className,
  onClick,
  rowIndex,
  style,
}: ShipmentsTableRowCellProps) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={-1}
      onClick={() => rowIndex !== undefined && onClick?.(null, rowIndex)}
      className={twMerge(
        'h-full flex flex-grow flex-shrink-0 items-center text-base font-bold min-w-[100px]',
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}
