import successImageUrl from '@assets/images/dashboard/fix-shipment-modal/success.svg';
import template from './success-monkey.html?raw';

(function () {
  const fixShipmentModalBodySuccessMonkey = {
    template,
    bindings: {},
    controller: 'fixShipmentModalBodySuccessMonkeyCtrl as ctrl',
  };

  function fixShipmentModalBodySuccessMonkeyCtrl(
    $state,
    ShipmentList,
    fixShipmentModal,
    FixMonkeyService
  ) {
    const ctrl = this;
    ctrl.successImageUrl = successImageUrl;

    /**
     * [close] Close modal, update status, and refresh the listing
     * @return {N/A}
     */
    ctrl.close = function () {
      fixShipmentModal.close();
      ShipmentList.reset();

      if (FixMonkeyService.preventRedirectToRejected) {
        return;
      }

      $state.go(
        'app.shipments',
        { sectionName: 'rejected', page: 1, query: { scope: 'rejected' } },
        { reload: true }
      );
    };
  }

  fixShipmentModalBodySuccessMonkeyCtrl.$inject = [
    '$state',
    'ShipmentList',
    'fixShipmentModal',
    'FixMonkeyService',
  ];

  angular
    .module('app.component.fix-shipment-modal-body-success-monkey')
    .component('fixShipmentModalBodySuccessMonkey', fixShipmentModalBodySuccessMonkey)
    .controller('fixShipmentModalBodySuccessMonkeyCtrl', fixShipmentModalBodySuccessMonkeyCtrl);
})();
