import React from 'react';
import { escapeRegExp } from 'lodash';

interface BoldMatchProps {
  text: string;
  pattern: string;
  unescaped?: boolean;
  disable?: boolean;
}

export default function BoldMatch({
  text,
  pattern,
  unescaped,
  disable,
}: BoldMatchProps): JSX.Element {
  const regexp = new RegExp(
    unescaped ? pattern : escapeRegExp(pattern.replace(/[-\/\\^$*+?.()|[\]{}]/, '$&')),
    'gi'
  );
  const unmatched = text.split(regexp);
  const matched = text.match(regexp) || ([] as string[]);

  return (
    <>
      {disable
        ? text
        : unmatched.map((_substring, index) => (
            <span key={index}>
              {index ? <span className="font-bold">{matched[index - 1]}</span> : ''}
              {unmatched[index]}
            </span>
          ))}
    </>
  );
}
