import React, { useState } from 'react';
import { react2angular } from 'react2angular';

import { IFeature, IPlan as IApiPlan, PlanRanking } from 'typings/subscription';
import { BillingInterval } from '@client/data/subscription';
import {
  SubscriptionServiceProvider,
  useSubscriptionService,
} from '@client/src/global/context/SubscriptionProvider';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import TableHeader from './TableHeader';
import usePreprocessPlans from './usePreprocessPlans';
import TableBody from './TableBody';
import { getDefaultRankingInScalePlans } from './utils';

interface ComparePlanTableProps {
  plans: IApiPlan[];
  features: IFeature[];
  billingInterval: BillingInterval;
  onPlanSelect: (planId: number) => void;
  taxName: string;
}

export default function ComparePlanTable({
  plans: apiPlans,
  features,
  billingInterval,
  onPlanSelect,
  taxName,
}: ComparePlanTableProps) {
  const { currentSubscription } = useSubscriptionService();
  const { plans, categorizedPlan, smallestRankingInScalePlans } = usePreprocessPlans({
    apiPlans,
    billingInterval,
    taxName,
  });

  const [selectedRankingInScalePlans, setSelectedRakingInScalePlans] = useState<PlanRanking>(() =>
    getDefaultRankingInScalePlans(currentSubscription, categorizedPlan.scalePlans)
  );

  function onScaleChange(planRanking: PlanRanking) {
    setSelectedRakingInScalePlans(planRanking);
  }

  const selectedScalePlan = categorizedPlan.scalePlans.find(
    (scalePlan) => scalePlan.ranking === selectedRankingInScalePlans
  );

  return (
    <table>
      <TableHeader
        plans={plans}
        categorizedPlan={categorizedPlan}
        selectedScalePlan={selectedScalePlan}
        billingInterval={billingInterval}
        onPlanSelect={onPlanSelect}
        onScaleChange={onScaleChange}
        smallestRankingInScalePlans={smallestRankingInScalePlans}
      />

      <TableBody
        features={features}
        standardPlans={categorizedPlan.standardPlans}
        selectedScalePlan={selectedScalePlan}
        smallestRankingInScalePlans={smallestRankingInScalePlans}
      />
    </table>
  );
}

function WrappedComparePlanTable(props: ComparePlanTableProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <SubscriptionServiceProvider>
          <ComparePlanTable {...props} />
        </SubscriptionServiceProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularComparePlanTable = react2angular(
  WrappedComparePlanTable,
  ['onPlanSelect', 'plans', 'features', 'billingInterval', 'taxName'],
  []
);
