import { BoxBaseService } from './box.base.service';

class BoxService extends BoxBaseService {
  static $inject = ['$q', '$translate', 'UserSession', 'BoxResource', 'EndpointService'];

  constructor($q, $translate, UserSession, BoxResource, EndpointService) {
    super();

    this.$q = $q;
    this.$translate = $translate;
    this.UserSession = UserSession;
    this.BoxResource = BoxResource;
    this.EndpointService = EndpointService;

    this.boxes = null;
    this.noBoxSelectOption = {};
    this.customBoxSelectOption = {};
    this.noBoxID = '-empty-';
  }

  getBoxes(params = {}) {
    if (this.boxes?.length) return this.$q((resolve) => resolve({ boxes: this.boxes }));

    return this.BoxResource.query(
      {
        company_id: this.UserSession.company.id,
        ...params,
      },
      {}
    ).$promise.then((response) => {
      this.boxes = response.boxes.filter((box) => box.is_active);

      return {
        boxes: this.boxes,
        numberOfShipmentsWithNoBox: response.totals.number_of_shipments_with_no_box,
      };
    });
  }

  getFilterBoxesSelectOptions() {
    return this.getBoxes({ scope: this.EndpointService.params.scope }).then(
      ({ boxes, numberOfShipmentsWithNoBox }) => {
        this.noBoxSelectOption = this._buildNoBoxSelectOption(numberOfShipmentsWithNoBox);
        const accountBoxType = this.$translate.instant('shipments.filter.box.groups.account');

        return [
          ...boxes.map((box) => {
            const { id, name, number_of_shipments } = box;

            return {
              id,
              name: this.$translate.instant(
                'shipments.filter.box.option',
                {
                  name,
                  dimensions: this.getUnitsDisplayText(box),
                  count: number_of_shipments,
                },
                'messageformat'
              ),
              type: accountBoxType,
            };
          }),
        ];
      }
    );
  }

  _buildNoBoxSelectOption(numberOfShipmentsWithNoBox) {
    const noBoxType = this.$translate.instant('shipments.filter.box.groups.unspecified');
    return {
      id: this.noBoxID,
      value: this.noBoxID,
      number_of_shipments: numberOfShipmentsWithNoBox,
      name: this.$translate.instant(
        'shipments.filter.box.option',
        {
          name: noBoxType,
          dimensions: this.$translate.instant('global.na'),
          count: numberOfShipmentsWithNoBox,
        },
        'messageformat'
      ),
      type: noBoxType,
    };
  }

  getNoBoxSelectOption() {
    return this.noBoxSelectOption;
  }

  getCustomBoxesSelectOptions(box) {
    this.customBoxSelectOption = this._buildCustomBoxSelectOption(box);

    const mergeCustomAndSavedBoxes = (boxes) => {
      return [this.customBoxSelectOption, ...this._groupBoxesSelectOptionsByType(boxes)];
    };

    if (this.boxes) {
      return this.$q((resolve) => {
        resolve(mergeCustomAndSavedBoxes(this.boxes));
      });
    }

    return this.getBoxes().then((response) => {
      return mergeCustomAndSavedBoxes(response.boxes);
    });
  }

  _groupBoxesSelectOptionsByType(boxes) {
    const accountBoxType = this.$translate.instant('shipments.filter.box.groups.account');
    return boxes.map((box) => {
      const { id, name, outer_height, outer_length, outer_width } = box;

      return {
        id,
        name: this.$translate.instant(
          'shipments.filter.box.option-no-count',
          {
            name,
            dimensions: this.getUnitsDisplayText(box),
          },
          'messageformat'
        ),
        height: outer_height,
        length: outer_length,
        width: outer_width,
        type: accountBoxType,
      };
    });
  }

  _buildCustomBoxSelectOption(box = {}) {
    return {
      name: this.$translate.instant('global.custom-box'),
      height: box.height || null,
      width: box.width || null,
      length: box.length || null,
      is_box_fitted: box.is_box_fitted || false,
    };
  }

  /**
   *
   * @param {Object} boxes Boxes object instance
   * @param {Object|string} Box object instance or box id
   */
  selectBox(boxes, box) {
    const currentBoxId = (box && (box.id || box.flat_rate_box_id)) || box;

    return (
      boxes.find((b) => {
        return (
          ((b.id || b.id === '') && b.id === currentBoxId) ||
          ((b.flat_rate_box_id || b.flat_rate_box_id === '') && b.flat_rate_box_id === currentBoxId)
        );
      }) || this.customBoxSelectOption
    );
  }

  createBox(payload) {
    return this.BoxResource.create(
      {
        company_id: this.UserSession.company.id,
      },
      payload
    ).$promise.then((response) => {
      this.boxes = [...this.boxes, response.box];

      return response;
    });
  }

  updateBox(id, payload) {
    return this.BoxResource.update(
      { company_id: this.UserSession.company.id, id },
      payload
    ).$promise.then((response) => {
      this.boxes = this.boxes.map((box) => {
        if (box.id === response.box.id) {
          box = response.box;
        }

        return box;
      });

      return response;
    });
  }

  deleteBox(boxId) {
    return this.BoxResource.delete({
      company_id: this.UserSession.company.id,
      id: boxId,
    }).$promise.then((response) => {
      this.boxes = this.boxes.filter((box) => {
        return box.id !== response.box.id;
      });

      return response;
    });
  }

  getVolumeDisplayText(box) {
    const unit = this.UserSession.isCompanyUsingImperialDimensions() ? 'inch' : 'centimeter';
    const locale = this.UserSession.user.dashboard_settings?.language?.code ?? 'en';
    const volumeWithUnit = new Intl.NumberFormat(locale, { style: 'unit', unit }).format(
      Math.floor(box.volume)
    );
    return volumeWithUnit;
  }
}

export { BoxService };
