import { IComponentController } from 'angular';
import template from './shipment-list-tracking-quality-cell.html?raw';
import style from './shipment-list-tracking-quality-cell.module.scss';

class ShipmentListTrackingQualityCell implements IComponentController {
  style = style;
}

const ShipmentListTrackingQualityCellComponent: ng.IComponentOptions = {
  controller: ShipmentListTrackingQualityCell,
  template,
  bindings: {
    esRating: '<',
  },
};

export { ShipmentListTrackingQualityCellComponent };
