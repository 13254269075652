import { IStoreService } from 'typings/store';
import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { ContactSalesModal } from '@client/src/global/contact-sales-modal/contact-sales-modal.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import {
  IOnboardingChecklist,
  OnboardingChecklistService,
} from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import template from './checklist-modal.html?raw';
import style from './checklist-modal.module.scss';
import bookMeetingImageUrl from '@assets/images/dashboard/checklist-modal/icon-bookameeting-solid-withbg.svg';

interface IChecklistContent {
  [key: string]: {
    title: string;
    description: string;
    routeName?: string;
    url?: string;
  };
}

const CHECKLIST_CONTENT: IChecklistContent = {
  [OnboardingChecklist.ShippingAddress]: {
    title: 'onboarding-checklist.shipping-address.title',
    description: 'onboarding-checklist.shipping-address.description',
    routeName: 'app.account.addresses',
  },
  [OnboardingChecklist.ConnectStore]: {
    title: 'onboarding-checklist.connect-store.title',
    description: 'onboarding-checklist.connect-store.description',
    routeName: 'app.connect',
  },
  [OnboardingChecklist.SyncOrders]: {
    title: 'onboarding-checklist.sync-orders.title',
    description: 'onboarding-checklist.sync-orders.description',
    routeName: 'app.multiple',
  },
  [OnboardingChecklist.InstallCheckout]: {
    title: 'onboarding-checklist.checkout.title',
    description: 'onboarding-checklist.checkout.description',
  },
  [OnboardingChecklist.InputDimensions]: {
    title: 'onboarding-checklist.input-dimensions.title',
    description: 'onboarding-checklist.input-dimensions.description',
    routeName: 'app.product-listing',
  },
  [OnboardingChecklist.CreateShippingRule]: {
    title: 'onboarding-checklist.shipping-rules.title',
    description: 'onboarding-checklist.shipping-rules.description',
    routeName: 'automations',
  },
  [OnboardingChecklist.SetupBranding]: {
    title: 'onboarding-checklist.branding.title',
    description: 'onboarding-checklist.branding.description',
    routeName: 'customer-emails',
  },
  [OnboardingChecklist.GenerateLabel]: {
    title: 'onboarding-checklist.generate-label.title',
    description: 'onboarding-checklist.generate-label.description',
    routeName: 'app.multiple',
  },
  [OnboardingChecklist.GetQuote]: {
    title: 'onboarding-checklist.get-quote.title',
    description: 'onboarding-checklist.get-quote.description',
    routeName: 'app.quote',
  },
  [OnboardingChecklist.CreateShipment]: {
    title: 'onboarding-checklist.create-shipment.title',
    description: 'onboarding-checklist.create-shipment.description',
    routeName: 'receiver-info',
  },
  [OnboardingChecklist.BookMeeting]: {
    title: 'onboarding-checklist.book-meeting.title',
    description: 'onboarding-checklist.book-meeting.description',
  },
  [OnboardingChecklist.DownloadCsv]: {
    title: 'onboarding-checklist.download-csv.title',
    description: 'onboarding-checklist.download-csv.description',
    routeName: 'fileUpload',
  },
  [OnboardingChecklist.UploadOrders]: {
    title: 'onboarding-checklist.upload-orders.title',
    description: 'onboarding-checklist.upload-orders.description',
    routeName: 'fileUpload',
  },
  [OnboardingChecklist.TrackShipment]: {
    title: 'onboarding-checklist.track-shipment.title',
    description: 'onboarding-checklist.track-shipment.description',
    routeName: 'app.shipments',
  },
  [OnboardingChecklist.WarehousePartners]: {
    title: 'onboarding-checklist.warehouse.title',
    description: 'onboarding-checklist.warehouse.description',
    url: 'https://pages.easyship.com/partners/warehouses',
  },
  [OnboardingChecklist.Lyoc]: {
    title: 'onboarding-checklist.connect-courier.title',
    description: 'onboarding-checklist.connect-courier.description',
    routeName: 'app.couriers',
  },
  [OnboardingChecklist.AddBoxes]: {
    title: 'onboarding-checklist.boxes.title',
    description: 'onboarding-checklist.boxes.description',
    routeName: 'boxes',
  },
};

class ChecklistModal implements IComponentController {
  style = style;
  bookMeetingImageUrl = bookMeetingImageUrl;
  checklistContent = CHECKLIST_CONTENT;

  static $inject = [
    '$state',
    '$window',
    'MixpanelService',
    'StoreService',
    'ContactSalesModal',
    'OnboardingChecklistService',
    'AppCuesService',
    'UserSession',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $window: ng.IWindowService,
    private MixpanelService: MixpanelService,
    private StoreService: IStoreService,
    private ContactSalesModal: ContactSalesModal,
    private OnboardingChecklistService: OnboardingChecklistService,
    private AppCuesService: AppCuesService,
    private UserSession: IUserSession
  ) {}

  $onInit(): void {
    this.AppCuesService.track('All | Open Onboarding Checklist');
  }

  $onDestroy(): void {
    // Update the previous progress state in order to prevent from animating the progress bar
    this.OnboardingChecklistService.previousProgressPercentage =
      this.OnboardingChecklistService.currentProgressPercentage;
  }

  get previousProgressPercentage(): number {
    return this.OnboardingChecklistService.previousProgressPercentage;
  }
  get currentProgressPercentage(): number {
    return this.OnboardingChecklistService.currentProgressPercentage;
  }
  get checklist(): IOnboardingChecklist | undefined {
    return this.OnboardingChecklistService.checklist;
  }

  get showBookMeetingCTA(): boolean {
    return this.UserSession.showBookMeetingCTA();
  }

  esClose(): void {
    this.esClose();
  }

  // Component binding, add this method as we're having issue on TS compilation issue.
  // It is purposely empty as the functionalities of this method will be replaced
  // by the component binding during runtime
  // TODO: Add better way to handle this scenario
  esShowConfirmationModal(): void {}

  showConfirmationModal(): void {
    this.MixpanelService.track('Checklist - Dont show');

    this.esShowConfirmationModal();
  }

  onClick(checklist: OnboardingChecklist): void {
    const url = this.checklistContent[checklist].url || '';
    const routeName = this.checklistContent[checklist].routeName || '';

    this.MixpanelService.track('Checklist - Click Action', {
      action_id: checklist,
    });

    this.esClose();

    if (checklist === OnboardingChecklist.BookMeeting) {
      this.ContactSalesModal.open('New signup');
    } else if (checklist === OnboardingChecklist.InstallCheckout) {
      const storePlatform = this.StoreService.stores.find((store: any) => {
        return store.platform;
      });

      if (storePlatform) {
        this.$state.go('app.single-store', { id: storePlatform.id });
      } else {
        this.$state.go('app.connect');
      }
    } else if (routeName) {
      this.$state.go(routeName);
    } else if (url) {
      this.OnboardingChecklistService.updateOnboarding(
        this.OnboardingChecklistService.checklistData.WarehousePartners
      );

      this.$window.open(url, '_blank');
    }
  }

  bookMeeting(): void {
    this.MixpanelService.track('Book Onboarding Meeting - Click', {
      origin: 'onboarding_checklist_modal',
    });

    this.UserSession.openOnboardingMeetingLink();
  }
}

const ChecklistModalComponent: ng.IComponentOptions = {
  controller: ChecklistModal,
  template,
  bindings: {
    esClose: '&',
    esShowConfirmationModal: '&',
  },
};

export { ChecklistModalComponent };
