import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CourierServiceRateData } from '@client/core/corelogic/models/CourierServiceRate';
import { ShipmentData } from '@client/core/corelogic/models/Shipment';
import { useGateways } from '@client/src/global/context/GatewayProvider';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import { IPreferences, useUserSession } from '@client/src/global/context/UserSessionProvider';
import { invalidateQueries } from '@/utils/tanstack';
import { GET_PICK_AND_PACK_ORDER_KEY } from '@/hooks/queries/useGetPickAndPackOrderQuery/types';
import { toastError } from '@client/core/components/react/Toastify';
import { useUnavailableRateQuery } from '../../useUnavailableRateQuery';
import { sendSpecificModifiedFieldToMixpanel } from '../../mixpanel';

export enum CourierSort {
  None = 'none',
  DeliveryTime = 'delivery_time_rank ',
  Rating = 'courier_surveys_rating',
  TotalCost = 'total_charge',
  TrackingQuality = 'tracking_quality',
}

function getSortComparer(sortBy: CourierSort) {
  return (rateA: CourierServiceRateData, rateB: CourierServiceRateData): number => {
    switch (sortBy) {
      case CourierSort.DeliveryTime:
        return (
          rateA.courierService.deliveryTime.max - rateB.courierService.deliveryTime.max ||
          rateA.courierService.deliveryTime.min - rateB.courierService.deliveryTime.min ||
          rateA.totalCharges - rateB.totalCharges
        );
      case CourierSort.Rating:
        if (rateA.courierService.rating.count === 0) {
          return 1;
        }
        if (rateB.courierService.rating.count === 0) {
          return -1;
        }
        return (
          rateB.courierService.rating.value - rateA.courierService.rating.value ||
          rateB.courierService.rating.count - rateA.courierService.rating.count
        );
      case CourierSort.TotalCost:
        return rateA.totalCharges - rateB.totalCharges;
      case CourierSort.TrackingQuality:
        return rateB.courierService.trackingQuality - rateA.courierService.trackingQuality;
      default:
        return 0;
    }
  };
}

export function useCourierOptions(
  shipment: ShipmentData,
  { sortBy, includeSelected }: { sortBy: CourierSort; includeSelected: boolean }
) {
  return useMemo(
    () =>
      shipment.rates
        .filter(
          (rate) => includeSelected || rate.courierService.id !== shipment.courierServiceSelectedId
        )
        .sort(getSortComparer(sortBy)),
    [shipment, sortBy, includeSelected]
  );
}

interface ShipmentCourierMutationParams {
  shipmentId: string;
  courierServiceId: string;
}

function useShipmentCourierMutation() {
  const queryClient = useQueryClient();
  const { shipmentGateway } = useGateways();

  return useMutation({
    mutationFn: ({ shipmentId, courierServiceId }: ShipmentCourierMutationParams) =>
      shipmentGateway.updateCourier(shipmentId, courierServiceId),
    onMutate: async ({ shipmentId, courierServiceId }: ShipmentCourierMutationParams) => {
      await queryClient.cancelQueries({ queryKey: ['shipment', shipmentId] });

      const prevShipment = queryClient.getQueryData<ShipmentData>(['shipment', shipmentId]);
      const courierData = prevShipment?.rates.find(
        ({ courierService }) => courierService.id === courierServiceId
      );

      queryClient.setQueryData<ShipmentData>(['shipment', shipmentId], (data) => {
        if (data === undefined) {
          return undefined;
        }

        if (courierData === undefined) {
          return {
            ...data,
            courierServiceSelectedId: courierServiceId,
          };
        }

        return {
          ...data,
          courierServiceSelectedId: courierServiceId,
          totalCharge: courierData.totalCharges,
          currencyCode: courierData.currency,
          deliveryTime: courierData.courierService.deliveryTime,
        };
      });

      return { prevShipment };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, { shipmentId }: ShipmentCourierMutationParams, context) => {
      queryClient.setQueryData(['shipment', shipmentId], context?.prevShipment);
    },
  });
}

export function useCourierSelect(shipment: ShipmentData): {
  selectedCourier: CourierServiceRateData | null;
  onChange: (courierServiceId: string) => void;
  isLoading: boolean;
} {
  const { company } = useUserSession();
  const { mutateAsync, isLoading } = useShipmentCourierMutation();
  const queryClient = useQueryClient();

  const selectedCourier = shipment.rates.find(
    (rate) => rate.courierService.id === shipment.courierServiceSelectedId
  );

  const onChange = useCallback(
    (courierServiceId: string) => {
      if (isLoading) {
        // To avoid race conditions
        return;
      }

      const currentCourier = shipment.rates.find(
        ({ courierService }) => courierService.id === shipment.courierServiceSelectedId
      );
      const newCourier = shipment.rates.find(
        ({ courierService }) => courierService.id === courierServiceId
      );

      mutateAsync({ courierServiceId, shipmentId: shipment.id })
        .then((data) => {
          sendSpecificModifiedFieldToMixpanel({
            newShipmentData: data,
            companyId: company.easyshipCompanyId,
            fieldModified: 'Courier / Selected',
          });
          queryClient.setQueryData(['shipment', shipment.id], {
            ...data,
            courierServiceSelectedId: courierServiceId,
          });
          invalidateQueries([GET_PICK_AND_PACK_ORDER_KEY])
            .then()
            .catch((e) => toastError(e.response?.data?.errors?.join(',') || e.message));
          MixpanelService.track('Edit Shipment Courier - Success', {
            easyship_shipment_id: shipment.easyshipId,
            old_courier: currentCourier?.courierService?.name,
            old_price: currentCourier?.totalCharges,
            new_courier: newCourier?.courierService?.name,
            new_price: newCourier?.totalCharges,
          });
        })
        .catch(({ data }) => {
          MixpanelService.track('Edit Shipment Courier - Failure', {
            easyship_shipment_id: shipment.easyshipId,
            error: data.errors,
          });
        });
    },
    [mutateAsync, isLoading, queryClient, shipment, company.easyshipCompanyId]
  );

  return { selectedCourier: selectedCourier ?? null, onChange, isLoading };
}

type UserPreferencesMutationParams = IPreferences;

export function useUserPreferencesMutation() {
  const { userGateway } = useGateways();

  return useMutation({
    mutationFn: (preferences: UserPreferencesMutationParams) =>
      userGateway.updatePreferences(preferences),
  });
}

export function useUnavailableRates(shipmentId: string) {
  const { data: unavailableRates, isFetching } = useUnavailableRateQuery({
    shipmentId,
    onSuccess: (data) => {
      MixpanelService.track('Unavailable Rates - List Shown', { count: data.length });
    },
  });

  return { unavailableRates, isFetching };
}
