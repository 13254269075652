import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import modalInstance from '@client/core/components/modal/modal-instance';

import emptyPage from './empty-page';
import addProducts from './add-products';
import addProductsModal from './add-products-modal';
import editProductsModal from './edit-products-modal';
import deleteProductModal from './delete-product-modal';
import productsPage from './products-page';
import advancedSearch from './advanced-search';
import fileUploader from './file-uploader';
import { AngularProductDetailsDrawer } from './product-details-drawer';

import { ProductListingRoutes } from './index.routes';
import { ProductListingComponent } from './product-listing.component';
import { ProductListingResource } from './product-listing.resource';
import { ProductListingService } from './product-listing.service';

const MODULE_NAME = 'app.product-listing';

angular
  .module(MODULE_NAME, [
    uiRouter,
    modalInstance,
    emptyPage,
    addProducts,
    addProductsModal,
    editProductsModal,
    deleteProductModal,
    productsPage,
    advancedSearch,
    fileUploader,
  ])
  .config(ProductListingRoutes)
  .component('productListing', ProductListingComponent)
  .component('esProductDetailsDrawer', AngularProductDetailsDrawer)
  .service('ProductListingResource', ProductListingResource)
  .service('ProductListingService', ProductListingService);

export default MODULE_NAME;
