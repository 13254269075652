import React, { createContext, useContext, useMemo } from 'react';
import NgShipmentGateway from '@client/core/adapters/shipment/ngShipment';
import IShipmentGateway from '@client/core/corelogic/ports/shipment.interface';

export interface ShipmentGatewayContextData {
  shipmentGateway: IShipmentGateway;
}

export const ShipmentGatewayContext = createContext<ShipmentGatewayContextData | undefined>(
  undefined
);

interface ShipmentGatewayProviderProps {
  children: React.ReactNode;
}

function ShipmentGatewayProvider({ children }: ShipmentGatewayProviderProps) {
  const data: ShipmentGatewayContextData = useMemo(
    () => ({
      shipmentGateway: new NgShipmentGateway(),
    }),
    []
  );

  return <ShipmentGatewayContext.Provider value={data}>{children}</ShipmentGatewayContext.Provider>;
}

function useShipmentGateway() {
  const shipmentGatewayContext = useContext(ShipmentGatewayContext);

  if (shipmentGatewayContext === undefined) {
    throw new Error('useShipmentGateway must be used within a ShipmentGatewayProvider');
  }
  return shipmentGatewayContext;
}

export { ShipmentGatewayProvider, useShipmentGateway };
