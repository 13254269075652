class Webhooks {
  static $inject = ['UserSession', 'WebhooksResource'];

  constructor(UserSession, WebhooksResource) {
    this.UserSession = UserSession;
    this.WebhooksResource = WebhooksResource;

    this.connectedWebhooks = [];
    this.availableEvents = [];
    this.company_id = this.UserSession.company.id;
  }

  getWebhooks() {
    return this.WebhooksResource.getListing({ company_id: this.company_id }).then((response) => {
      this.connectedWebhooks = response.webhooks.reverse();
      this.availableEvents = Object.keys(response.supported_event_types).sort();
      return response;
    });
  }

  createWebhook({ event_types, endpoint }) {
    return this.WebhooksResource.create(
      { company_id: this.company_id },
      { endpoint, event_types }
    ).then((response) => {
      this.connectedWebhooks.unshift(response);
      return response;
    });
  }

  getWebhookDetails(id) {
    return this.WebhooksResource.getDetails({ company_id: this.company_id, id });
  }

  updateWebhookDetails({ event_types, endpoint, is_active, id }) {
    return this.WebhooksResource.update(
      { company_id: this.company_id, id },
      { event_types, endpoint, is_active }
    ).then((response) => {
      const webhookIndex = this.connectedWebhooks.findIndex((webhook) => {
        return webhook.id === response.id;
      });
      this.connectedWebhooks[webhookIndex] = response;
      return response;
    });
  }

  deleteWebhook({ id }) {
    return this.WebhooksResource.delete({ company_id: this.company_id, id }).then(() => {
      this.connectedWebhooks = this.connectedWebhooks.filter((webhook) => webhook.id !== id);
    });
  }

  testWebhookEvent({ id, event_type }) {
    return this.WebhooksResource.test(
      {
        company_id: this.company_id,
        id,
      },
      { event_type }
    );
  }
}

export { Webhooks };
