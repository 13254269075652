(function () {
  'use strict';

  EndpointService.$inject = ['UserSession'];
  function EndpointService(UserSession) {
    var service = this;

    var endpointParams;

    service.setEndpointParams = function (scope) {
      var scope = scope || 'shipments_all';

      endpointParams = {
        multiple: {
          include: 'shipment_items,list_of_couriers,orders_totals,store',
          scope: 'orders_all',
        },
        shipments: {
          include: 'shipment_items,shipments_totals,checkpoints,store',
          scope: scope,
        },
        pickups: { include: '', scope: 'pickups_all' },
        reschedulePickups: { include: '', scope: 'reschedule_pickups_all' },
      };
    };

    service.params = {};
    service.availableScopes = ['shipments_all', 'pending', 'rejected', 'to_download'];

    service.reset = function (page) {
      service.params = {};
      service.params.company_id = UserSession.company.id;
      service.params.company_type = 'cloud';
      service.params.offset = 0;
      service.params.limit = UserSession.getItemsPerPageValue(page);
    };

    service.set = function (origin, scope) {
      service.reset(origin);
      service.setEndpointParams(scope);
      service.params.include = endpointParams[origin].include;
      service.params.scope = endpointParams[origin].scope;
    };

    service.loadQueryIntoParams = function (query) {
      var clonedQuery = angular.copy(query) || {};

      // ensure we include the full day
      if (clonedQuery.order_created_at_to) {
        clonedQuery.order_created_at_to = moment(clonedQuery.order_created_at_to)
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .toDate();
      }

      // ensure we include the full day
      if (clonedQuery.created_at_to) {
        clonedQuery.created_at_to = moment(clonedQuery.created_at_to)
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .toDate();
      }

      // ensure we include the full day
      if (clonedQuery.label_paid_at_to) {
        clonedQuery.label_paid_at_to = moment(clonedQuery.label_paid_at_to)
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .toDate();
      }

      for (var key in clonedQuery) {
        if (key !== 'scope' && key !== 'limit') service.params[key] = clonedQuery[key];
      }
    };
  }

  angular.module('app.service.EndpointService', []).service('EndpointService', EndpointService);
})();
